import type { RecordProject } from "@biteable/network-model";
import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import { Placeholder } from "client/components/project-content-bar/panels/scenes/stack/component";
import TrackingEvents from "client/events";
import { ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import getStyleNamespace from "client/lib/get-style-namespace";
import { Lazy } from "client/lib/lazy";
import { SimpleRumTimer } from "client/lib/rum-timer";
import ContentTemplate from "client/models/content-template";
import Favorite from "client/models/favorite";
import type FolderContent from "client/models/folder-content";
import SelectableAsset from "client/models/selectable-asset";
import type CurrentUserService from "client/services/current-user";
import type ProjectContentBarService from "client/services/project-content-bar";
import type TrackingService from "client/services/tracking";

type Params = Record<string, string | number | undefined>;
type Result = SelectableAsset | RecordProject | FolderContent | Favorite | ContentTemplate | Placeholder;
type ResultsLayout = "masonry" | "grid";
type NumberOfColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface Args {
  loadResults(params: Params): Promise<ArrayProxy<Result>>;
  selectAsset?(asset: Result): unknown;
  loadPlaceholders?(): Promise<Placeholder[]>;
  columns?: NumberOfColumns;
  layout?: ResultsLayout;
  params: Params;
  showFavorite?: boolean;
  teamFavorite?: boolean;
  emptyComponent?: string;
  onAllLoaded?(): unknown;
  onPlaceholdersLoaded?(): unknown;
  shouldLoadAllPages: boolean;
}

const DEFAULT_PAGINATION_PAGE_SIZE = 20;
export const DEFAULT_CONTENT_BAR_COLUMNS = 3;

class AssetThumbnailDelegate {
  public ready: DeferredPromise<HTMLImageElement> = defer();
  public readonly image: HTMLImageElement;
  private readonly asset: SelectableAsset;
  private setAspectRatio;

  constructor(asset: SelectableAsset, setAspectRatio = false) {
    this.asset = asset;
    this.image = new Image();
    this.setAspectRatio = setAspectRatio;

    const url = asset.thumbImageUrl;

    if (url) {
      this.image.onerror = (): void => {
        this.ready.resolve(this.image);
      };

      setTimeout(() => {
        if (this.ready.loading) {
          this.ready.resolve(this.image);
        }
      }, 10 * 1000);

      Object.assign(this.image, {
        onload: this.onload.bind(this),
        src: url
      });
    } else {
      this.ready.resolve(this.image);
    }
  }

  onload(): void {
    this.asset.thumbnailImageWidth = this.image.width;
    this.asset.thumbnailImageHeight = this.image.height;
    if (this.setAspectRatio) {
      this.asset.thumbAspectRatio = `${this.image.width}:${this.image.height}`;
    }

    this.ready.resolve(this.image);
  }
}

export default class ProjectContentBarResults extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare tracking: TrackingService;

  @tracked
  loading = false;

  @tracked
  resultsColumns: Result[][] = new TrackedArray([]);

  @tracked
  page = 1;

  @tracked
  totalResults = -1;

  @tracked
  error = false;

  /*
   * Each time a request is triggered store a unique identifier. When the response from the server is resolved we can
   * then determine whether the request has become stale.
   */
  private requestId?: number;
  /*
   * the height of each columns, used to allocate new item into the shortest column
   */
  private columnHeights: number[] = [];
  /*
   * column width, used to calculate the height (aspectRatio * columnWidth)
   */
  private columnWidth?: number;

  private resultsElement?: HTMLElement;

  styleNamespace = getStyleNamespace("project-content-bar/results");

  @action
  paramsDidChange(): void {
    void this.loadFirstPage();
  }

  @action
  async refreshShouldLoadAllPages(): Promise<void> {
    if (this.args.shouldLoadAllPages && this.hasNextPage) {
      await this.loadAllPages();
    }
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this.resultsElement = element;

    if (this.args.shouldLoadAllPages) {
      await this.loadAllPages();
    } else {
      void this.loadFirstPage();
    }
  }

  @tracked
  isInitialLoad = true;

  @action
  async loadFirstPage(): Promise<void> {
    this.resetState();

    await this.doLoadResults(true);
  }

  @action
  async loadNextPage(): Promise<void> {
    if (this.loading || !this.hasNextPage) {
      return;
    }

    this.page++;
    await this.doLoadResults();
  }

  get columns(): number {
    return this.args.columns ?? DEFAULT_CONTENT_BAR_COLUMNS;
  }

  get hasNextPage(): boolean {
    // eslint-disable-next-line camelcase
    const { page, per_page } = this.getParams();

    // eslint-disable-next-line camelcase
    return (page as number) * ((per_page || DEFAULT_PAGINATION_PAGE_SIZE) as number) < this.totalResults;
  }

  get emptyResults(): boolean {
    return !this.loading && (this.totalResults === 0 || this.resultsColumns.every((column) => column.length === 0));
  }

  get hasError(): boolean {
    return this.error;
  }

  private async loadAllPages(): Promise<void> {
    this.resetState();
    await this.loadPlaceholders();

    while (this.hasNextPage || this.isInitialLoad) {
      if (this.isInitialLoad) {
        await this.doLoadResults();
        this.isInitialLoad = false;
      } else {
        this.page++;
        await this.doLoadResults();
      }
    }
  }

  private async loadPlaceholders(): Promise<void> {
    try {
      this.loading = true;
      this.updateColumnWidthAndHeights();

      const placeholders = await this.args.loadPlaceholders?.();

      if (placeholders) {
        placeholders.forEach((placeholder) => this.addPlaceholder(placeholder));
      }

      this.args.onPlaceholdersLoaded?.();
    } finally {
      this.loading = false;
    }
  }

  private addPlaceholder(placeholder: Placeholder): void {
    const index = this.findShortestColumn();
    this.resultsColumns[index]?.push(placeholder);
    this.columnHeights[index] += this.heightAtSameWidth(placeholder);
  }

  private get masonryLayout(): boolean {
    return this.args.layout === "masonry";
  }

  private getParams(): Params {
    return {
      ...this.args.params,
      page: this.page
    };
  }

  private getResultAsset(result: Result): SelectableAsset | undefined {
    if (result instanceof SelectableAsset) {
      return result;
    } else if (result instanceof Favorite) {
      return result.favorable as unknown as SelectableAsset;
    } else if (result instanceof ContentTemplate) {
      return result.sag;
    }

    return undefined;
  }

  private async preloadAssetThumbnails(results: Result[]): Promise<void> {
    const assets = results.map((result) => this.getResultAsset(result));

    await Promise.all(
      assets.map((asset) => {
        if (asset) {
          const delegate = new Lazy(() => new AssetThumbnailDelegate(asset, this.masonryLayout));
          return delegate.get().ready;
        }
        return;
      })
    );
  }

  private async allocateResults(requestId: number, results: Array<Result> | undefined): Promise<void> {
    if (results) {
      await this.preloadAssetThumbnails(results);

      if (!this.isActiveRequest(requestId)) {
        return;
      }

      this.updateColumnWidthAndHeights();

      results.forEach((result) => {
        const index = this.findShortestColumn();

        this.removePlaceholderIfPresent(index);
        this.resultsColumns[index]?.push(result);
        this.columnHeights[index] += this.heightAtSameWidth(result);
      });
    }
  }

  private removePlaceholderIfPresent(shortestColumnIndex: number): void {
    const { columnIndex, rowIndex } = this.getPlaceholderPosition(shortestColumnIndex);

    if (this.indexValid(columnIndex) && this.indexValid(rowIndex)) {
      const placeholder = this.resultsColumns[columnIndex]?.[rowIndex];
      this.resultsColumns[columnIndex]?.splice(rowIndex, 1);

      if (placeholder) {
        this.columnHeights[columnIndex] -= this.heightAtSameWidth(placeholder);
      }
    }
  }

  private getPlaceholderPosition(shortestColumnIndex: number): { columnIndex: number; rowIndex: number } {
    const placeholderIndex = this.findPlaceholderIndexInColumn(shortestColumnIndex);

    if (this.indexValid(placeholderIndex)) {
      return { columnIndex: shortestColumnIndex, rowIndex: placeholderIndex! };
    }

    return { ...this.firstPlaceholderPosition };
  }

  private get firstPlaceholderPosition(): { columnIndex: number; rowIndex: number } {
    const columnIndex = this.resultsColumns.findIndex((innerArray) =>
      innerArray.some((item) => item instanceof Placeholder)
    );

    let rowIndex = -1;
    if (this.indexValid(columnIndex)) {
      rowIndex = this.findPlaceholderIndexInColumn(columnIndex) ?? -1;
    }

    return { columnIndex, rowIndex };
  }

  private findPlaceholderIndexInColumn(columnIndex: number): number | undefined {
    return this.resultsColumns[columnIndex]?.findIndex((result) => result instanceof Placeholder);
  }

  private indexValid(index: number | undefined): boolean {
    return index !== undefined && index > -1 && !!index;
  }

  private async doLoadResults(isFirstPage = false): Promise<void> {
    const timer = new SimpleRumTimer("content-panel-loading");
    timer.meta = {
      userId: this.currentUser.user?.id,
      panel: this.projectContentBar.panel,
      page: this.page,
      span: "api"
    };

    this.loading = true;
    try {
      const requestId = this.createRequestId();
      // @ts-expect-error
      const results = (await this.args.loadResults(this.getParams())) as Result[];
      timer.sample();

      if (this.isActiveRequest(requestId)) {
        // @ts-expect-error
        this.totalResults = results.meta["total-count"] ?? results.meta.totalCount;
        await this.allocateResults(requestId, results);
      }

      if (this.shouldTrackSearch(isFirstPage)) {
        this.trackSearch(this.totalResults);
      }

      if (!this.hasNextPage) {
        this.args.onAllLoaded?.();
      }
    } catch (err) {
      this.error = true;
    } finally {
      this.loading = false;

      timer.meta["span"] = "page";
      timer.sample(true);
    }
  }

  private createRequestId(): number {
    this.requestId = Date.now();

    return this.requestId;
  }

  private clearRequestId(): void {
    this.requestId = undefined;
  }

  private isActiveRequest(requestId: number): boolean {
    return this.requestId === requestId;
  }

  private resetState(): void {
    this.clearRequestId();
    this.page = 1;
    this.totalResults = -1;
    this.error = false;
    this.resetResultColumns();
  }

  private findShortestColumn(): number {
    const minHeight = Math.min(...this.columnHeights);
    return Math.max(0, this.columnHeights.indexOf(minHeight));
  }

  private heightAtSameWidth(result: Result): number {
    if (this.masonryLayout) {
      let ratio = 9 / 16;
      const asset = this.getResultAsset(result);
      if (asset && asset.thumbnailImageHeight && asset.thumbnailImageWidth) {
        ratio = asset.thumbnailImageHeight / asset.thumbnailImageWidth;
      } else if (result instanceof Placeholder) {
        ratio = result.thumbnailHeight / result.thumbnailWidth;
      }

      return ratio * (this.columnWidth ?? 1);
    } else {
      return 1;
    }
  }

  private updateColumnWidthAndHeights(): void {
    if (this.masonryLayout) {
      if (this.resultsElement && this.resultsColumns.some((r) => r.length > 0)) {
        this.columnWidth = Array.from(this.resultsElement.children)[0]?.clientWidth;

        this.columnHeights = Array.from(this.resultsElement.children).map((child) => {
          const lastChild = child.children.item(child.children.length - 1) as HTMLElement;
          return lastChild ? lastChild.offsetTop + lastChild.offsetHeight : 0;
        });
      }
    } else {
      this.columnHeights = this.resultsColumns.map((col) => col.length);
    }
  }

  private resetResultColumns(): void {
    this.resultsColumns = new TrackedArray([]);
    this.columnHeights = [];
    this.columnWidth = undefined;

    for (let i = 0; i < this.columns; i++) {
      this.resultsColumns.push(new TrackedArray([]));
      this.columnHeights.push(0);
    }
  }

  private get query(): string {
    return (this.args.params["query"] || this.args.params["search"] || this.args.params["search_query"]) as string;
  }

  private shouldTrackSearch(isFirstPage: boolean): boolean {
    return isFirstPage && !!this.query?.length;
  }

  private trackSearch(total: number): void {
    const trackingHelper = new ContentTrackingHelper(
      {
        search: this.query,
        searchFrom: SEARCH_LOCATION.CONTENT_BAR
      },
      this.projectContentBar.panel,
      total,
      window.location.href
    );

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_SEARCH_CONDUCTED, trackingHelper);
  }
}
