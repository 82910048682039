import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { AnimationOptions } from "client/components/app/animation-dropdown/component";
import type { Element, EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getAnimation, getAnimationOptions, saveElement, setAnimation } from "client/lib/timeline/element";
import { transaction } from "client/lib/transaction";

interface Args {
  eventRegister: EventRegister;
  element: Element;
  scene?: Scene;
}

export default class ToolbarEffectComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/effect");

  @tracked
  targetElement?: HTMLElement;

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  get animations(): AnimationOptions | undefined {
    return getAnimationOptions(this.args.element);
  }

  get animation(): string | undefined {
    return getAnimation(this.args.element);
  }

  @action
  @transaction
  async setAnimation(name: string): Promise<void> {
    const { eventRegister, element, scene } = this.args;

    setAnimation(eventRegister, element, name);

    await saveElement(eventRegister, element, scene);
  }
}
