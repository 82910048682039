import type { AspectRatio } from "@biteable/network-model";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { InfiniteParams } from "client/components/infinite/component";
import cmsUrl from "client/lib/cms-url";
import getStyleNamespace from "client/lib/get-style-namespace";
import { rumAction } from "client/lib/rum-action";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectScene from "client/models/project-scene";
import type ProjectScenePreview from "client/models/project-scene-preview";
import type ProjectSceneStack from "client/models/project-scene-stack";

interface ProjectSceneGroupArgs {
  title: string;
  stack: ProjectSceneStack;
  projectAspectRatio?: AspectRatio;
  selectScene: (projectScene: ProjectScene) => void;
  onEmptyGroupLoaded?(): unknown;
}

export default class ProjectSceneGroupComponent extends Component<ProjectSceneGroupArgs> {
  @service
  declare store: Store;

  @tracked
  hidden = false;

  styleNamespace = getStyleNamespace("project-scene-group");

  get cmsUrl(): string {
    return cmsUrl(getOwner(this)!, `/project-scene-stack/${this.args.stack.id}`);
  }

  @action
  @rumAction("project-scene-group.load-scenes")
  async getResults(params: InfiniteParams): Promise<Array<ProjectScenePreview>> {
    const { stack } = this.args;

    const query: Record<string, string | number | undefined> = {
      /* eslint-disable camelcase */
      page: params.page,
      per_page: params.perPage,
      aspect_ratio: this.args.projectAspectRatio ? this.args.projectAspectRatio.id : WIDE_ASPECT_RATIO_ID,
      stack: stack.code
      /* eslint-enable camelcase */
    };

    // @ts-expect-error
    const results = (await this.store.query("projectScenePreview", query)) as ArrayWithMeta<ProjectScenePreview>;

    const totalCount = results.meta?.["total-count"];

    if (totalCount === 0) {
      this.args.onEmptyGroupLoaded?.();

      this.hidden = true;
    }

    return results;
  }
}
