import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum UIButtonColors {
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WARNING = "warning",
  DANGER = "danger",
  MUTED = "muted"
}

export enum UIButtonSizes {
  SMALL = "small",
  REGULAR = "regular",
  LARGE = "large"
}

interface UIButtonArgs {
  type?: string;
  disabled?: boolean;
  loading?: boolean;
  span?: number;
  color?: UIButtonColors;
  size?: UIButtonSizes;
  action?: (event: Event) => void;
}

export default class UIButtonComponent extends Component<UIButtonArgs> {
  styleNamespace = getStyleNamespace("ui/ui-button");

  get type(): string {
    return this.args.type ?? "button";
  }

  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  get span(): number {
    return this.args.span ?? 3;
  }

  get color(): UIButtonColors {
    return this.args.color ?? UIButtonColors.DEFAULT;
  }

  get loading(): boolean {
    return this.args.loading ?? false;
  }

  get size(): UIButtonSizes {
    return this.args.size ?? UIButtonSizes.REGULAR;
  }

  get colorClassName(): string {
    return this.color !== UIButtonColors.DEFAULT ? `-${this.color}` : "";
  }

  get sizeClassName(): string {
    return this.size !== UIButtonSizes.REGULAR ? `-${this.size}` : "";
  }

  @action
  click(event: Event): void {
    if (this.args.action && !this.disabled) {
      this.args.action(event);
    }
  }
}
