import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface RadioToggleArgs {
  value?: any;
  selectedValue?: any;
  onSelect(value: any): unknown;
}

export default class RadioToggleOptionComponent extends Component<RadioToggleArgs> {
  styleNamespace = getStyleNamespace("north-star/radio-toggle/option");

  get checked(): boolean {
    return this.args.value === this.args.selectedValue;
  }
}
