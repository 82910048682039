import type { Element } from "../../element";
import type { Caption } from "../../scene";
import { StrictMutation } from "./mutation";

export class MoveLayerMutation extends StrictMutation<void> {
  grouped = false;

  private originalLayerOrders: { element: Element; layerOrder: number }[];

  constructor(private element: Element, private caption: Caption, private direction: -1 | 1) {
    super();

    this.originalLayerOrders = caption.elementsSortedByLayer.map((element) => ({
      element,
      layerOrder: element.layerOrder
    }));
  }

  run(): void {
    const { elementsSortedByLayer } = this.caption;

    for (const [layerOrder, element] of elementsSortedByLayer.entries()) {
      element._layerOrder = layerOrder;
    }

    const { element, direction } = this;
    const { layerOrder } = element;

    const nextLayerOrder = layerOrder + direction;
    const nextElement = elementsSortedByLayer[nextLayerOrder];

    if (nextElement) {
      element._layerOrder = nextLayerOrder;
      nextElement._layerOrder = layerOrder;
    }
  }

  revert(): void {
    for (const { element, layerOrder } of this.originalLayerOrders) {
      element._layerOrder = layerOrder;
    }
  }
}
