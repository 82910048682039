import { getOwner } from "@ember/application";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import TrackingEvents from "client/events";
import BrandApplier from "client/lib/brand-applier";
import type { Media, Scene } from "client/lib/editor-domain-model";
import { TimelineFactory } from "client/lib/editor-domain-model";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import { EmberTimelineBuilder } from "client/lib/timeline/ember-timeline-builder";
import type Project from "client/models/project";
import type ProjectTemplate from "client/models/project-template";
import type SelectableAsset from "client/models/selectable-asset";
import { leaveToPreviousClient } from "client/routes/leave-to-previous-client";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";
import type LayersService from "client/services/layers";
import type MobileService from "client/services/mobile";
import type NotificationsService from "client/services/notifications";
import type OnboardService from "client/services/onboard";
import type PermissionsService from "client/services/permissions";
import type ProjectScenesService from "client/services/project-scenes";
import type ProjectsService from "client/services/projects";
import type TrackingService from "client/services/tracking";
import type UndoService from "client/services/undo";
import type VideoAssistantService from "client/services/video-assistant";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export interface PopulateTemplateCopyRouteParams {
  projectId: string;
}

export default class PopulateTemplateCopyRoute extends Route {
  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare layers: LayersService;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare projects: ProjectsService;

  @service
  declare tracking: TrackingService;

  @service
  declare permissions: PermissionsService;

  @service
  declare undo: UndoService;

  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare onboard: OnboardService;

  @service
  declare mobile: MobileService;

  declare projectTemplate: ProjectTemplate;

  async model({ projectId }: PopulateTemplateCopyRouteParams): Promise<Project | undefined> {
    try {
      const project =
        this.store.peekRecord("project", projectId) ?? (await this.store.findRecord("project", projectId));
      const projectCopy = await this.projects.copyProject(project);

      if (project.template) {
        this.projectTemplate = await project.projectTemplate;

        // At this point, video scripts are not persisted so this should avoid the network request.
        const videoAssistant = this.store.peekRecord("videoAssistant", 1);

        if (videoAssistant && this.videoAssistant.scriptIsValid(videoAssistant.script)) {
          const { script: videoScript, mediaKeywords } = videoAssistant;

          await this.applyVideoScriptToScenes(projectCopy, project.templateBrandable, videoScript, mediaKeywords);
        } else {
          await this.applyVideoScriptToScenes(projectCopy, project.templateBrandable, this.fallbackScript);

          this.notifications.error(
            "Open AI is experiencing high volumes. We've provided an easy-to-edit template and script. Try again later for a customized script.",
            {
              timeout: 10000
            }
          );
        }

        const timelineFactory = this.timelineFactory(projectCopy);
        const timeline = await timelineFactory.buildTimeline(projectCopy.id);
        await timelineFactory.eventRegister.save(timeline);
      }

      return projectCopy;
    } catch (err) {
      this.notifications.error("Shucks! There was a problem populating the video script template");
      // eslint-disable-next-line no-console
      console.error("Error in the PopulateTemplateCopyRoute", err);

      if (err instanceof Error) {
        this.honeybadger.notify(err);
      }
    }

    return;
  }

  async afterModel(project: Project, transition: Transition): Promise<void> {
    const referer = transition.from?.queryParams?.["referer"];

    await this.trackProjectCreated(this.projectTemplate, project, {
      referer: referer
    });

    if (this.onboard.onboarding) {
      this.onboard.end();
    }

    if (referer === "onboarding" && this.mobile.isMobile) {
      await this.router.transitionTo("authenticated.publish", project.id);
    } else {
      void this.router.replaceWith("authenticated.project", project.id);
    }
  }

  private timelineFactory(project: Project): TimelineFactory {
    return new TimelineFactory(
      new EmberTimelineBuilder(
        this.ajax,
        this.notifications,
        this.store,
        this.projects,
        this.projectScenes,
        this.layers,
        this.zymbolGroups,
        this.router,
        project
      )
    );
  }

  private async applyVideoScriptToScenes(
    project: Project,
    applyBrand: boolean,
    scriptsByScene: string[],
    mediaKeywordsByScene: string[] = []
  ): Promise<void> {
    const timelineFactory = this.timelineFactory(project);
    const timeline = await timelineFactory.buildTimeline(project.id);
    const eventRegister = timelineFactory.eventRegister;
    const brand = await project.getBrandStyle();
    const brandable = !!brand && this.permissions.has("feature_brand_apply") && applyBrand;
    if (brandable) {
      await brand.style.assetsLoaded;
    }
    const brandApplier = new BrandApplier({ eventRegister });
    const allMedias = timeline.scenes.map((scene) => this.mediasForReplacement(scene));
    const allVideos = await this.videosForAllScenes(timeline.scenes, allMedias, mediaKeywordsByScene);

    timeline.scenes.forEach(async (scene, i) => {
      this.videoAssistant.applyVideoScriptToScene(eventRegister, scene, scriptsByScene[i]);

      if (brandable) {
        brandApplier.applyBrandToScene(scene, brand.style);
      }

      const mediasToReplace = allMedias[i] ?? [];
      const videosToInject = allVideos.get(scene.id);
      if (videosToInject) {
        const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister);

        await Promise.all(
          videosToInject.map(async (mediaToInject, index) => {
            if (mediasToReplace[index]) {
              await modifier.applyAssetToMedia(mediaToInject, scene, mediasToReplace[index]!);
            }
          })
        );
      }
    });
    await eventRegister.save(timeline);
  }

  private async videosForAllScenes(
    scenes: Scene[],
    medias: Array<Media[]>,
    mediaKeywordsByScene: string[]
  ): Promise<Map<string, SelectableAsset[]>> {
    const videos = new Map<string, SelectableAsset[]>();
    await Promise.all(
      medias
        .map((m) => m.length)
        .map(async (length, index) => {
          if (length > 0) {
            const videosToInject = await this.videosForKeywords(mediaKeywordsByScene[index], length);
            videos.set(scenes[index]!.id, videosToInject);
          }
        })
    );

    return videos;
  }

  private mediasForReplacement(scene: Scene): Media[] {
    let logoCaptions = scene.captions.flatMap((caption) => caption.logos);
    if (scene.background.hasContent) {
      logoCaptions = logoCaptions.concat(scene.background);
    }

    return logoCaptions.filter((logo) => logo.mediaReplaceable);
  }

  private async videosForKeywords(keywords: string | undefined, count: number): Promise<SelectableAsset[]> {
    if (!keywords) {
      return [];
    }

    try {
      return (await this.store.query("zymbolFootage", {
        per_page: count, // eslint-disable-line camelcase
        query: keywords,
        page: 1
      })) as unknown as SelectableAsset[];
    } catch (error) {
      if (error instanceof Error) {
        this.honeybadger.notify(error, "AVA:populate-template: video search API error");
      }

      return [];
    }
  }

  private get fallbackScript(): string[] {
    const generator = this.videoAssistant?.currentGenerator;
    const fallbackScript = generator?.fallbackScript || "";

    return this.videoAssistant?.parseVideoScriptToArray(fallbackScript) || [];
  }

  private async trackProjectCreated(template: ProjectTemplate, project: Project, eventProperties = {}): Promise<void> {
    /* eslint-disable camelcase */
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_VIDEO_ASSISTANT_EDITOR_OPENED, {
      template_id: template?.id,
      project_id: project?.id,
      ...eventProperties
    });
    /* eslint-enable camelcase */
  }
}
