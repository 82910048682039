import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { ValidationResult } from "client/lib/validation";
import { validatePassword } from "client/lib/validation";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";

export default class UpdatePasswordController extends Controller<User> {
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @tracked
  currentPassword = "";

  @tracked
  newPassword = "";

  @tracked
  repeatPassword = "";

  @tracked
  repeatPasswordDirty = false;

  @tracked
  passwordValidation?: ValidationResult;

  @tracked
  loading = false;

  get disabled(): boolean {
    return !this.currentPassword.length || !this.passwordsMatch || !this.passwordValid;
  }

  get showPasswordMismatchWarning(): boolean {
    return this.repeatPasswordDirty && !this.passwordsMatch;
  }

  private get passwordValid(): boolean {
    return !!this.newPassword.length || this.passwordValidation?.isValid === true;
  }

  get user(): User {
    return this.model;
  }

  get passwordsMatch(): boolean {
    return this.repeatPassword === this.newPassword;
  }

  @action
  setRepeatPasswordDirty(): void {
    this.repeatPasswordDirty = true;
  }

  @action
  validatePassword({ target }: InputEvent): void {
    const password = (target as HTMLInputElement).value;

    this.passwordValidation = validatePassword({ email: this.user.email, password });
  }

  @action
  cancel(): void {
    void this.router.transitionTo("authenticated.account.profile");
  }

  @action
  async updatePassword(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.loading || this.disabled) {
      return;
    }

    if (this.passwordValidation?.isValid) {
      this.loading = true;

      const { newPassword, currentPassword } = this;

      try {
        await this.store
          .createRecord("passwordUpdateRequest", {
            newPassword,
            currentPassword
          })
          .save();

        this.notifications.success("Your password has been updated");
        void this.auth.logout();
      } catch (err) {
        this.notifications.error("There was a problem updating your password");
      } finally {
        this.loading = false;
      }
    }
  }

  public resetTransientState(): void {
    this.currentPassword = "";
    this.newPassword = "";
    this.repeatPassword = "";
    this.repeatPasswordDirty = false;
    this.passwordValidation = undefined;
    this.loading = false;
  }
}
