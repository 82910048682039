import Model, { attr, belongsTo } from "@ember-data/model";
import type Containable from "./containable";
import type Folder from "./folder";
import type FolderContent from "./folder-content";

export enum MediaAssetTypes {
  FOLDER = "Folder",
  PROJECT = "Project",
  TEAM_ASSET = "TeamAsset",
  USER_ASSET = "UserAsset"
}

export enum OwnerTypes {
  TEAM = "team",
  USER = "user"
}

export default class MediaAsset extends Model {
  @attr("string")
  declare name: string;

  @attr("string")
  declare mediaAssetId?: number;

  @attr("string")
  declare mediaAssetType: MediaAssetTypes;

  @attr("boolean", { defaultValue: true })
  declare showInLibrary: boolean;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("containable", { async: true, inverse: null, polymorphic: true })
  declare containable: Containable;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("folder", { async: true, inverse: null })
  declare folder: Folder;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("folderContent", { async: true, inverse: null })
  declare folderContent: FolderContent;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    mediaAsset: MediaAsset;
  }
}
