import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import type { FoldersLibraryRouteModel } from "./route";
import PaginatedController, { CONTROLLER_QUERY_PARAMS } from "client/controllers/paginated";
import { ContributorTypes } from "client/models/folder";
import { FolderRouteSuffix } from "client/services/folders";

export default class FoldersLibraryController extends PaginatedController<FoldersLibraryRouteModel> {
  queryParams: any = [
    ...CONTROLLER_QUERY_PARAMS,
    {
      order: {
        scope: "controller"
      },
      search: {
        scope: "controller"
      }
    }
  ];

  @tracked
  order?: string;

  @tracked
  search?: string;

  folderRouteSuffix = FolderRouteSuffix;

  get title(): string {
    const { type } = this.model;

    switch (type) {
      case ContributorTypes.USER:
        return "Personal library";
      case ContributorTypes.TEAM:
        return "Team library";
    }
  }

  @action
  onChangeOrder(order: string): void {
    this.order = order;
  }

  @action
  onSearch(query: string): void {
    this.search = query;
  }
}
