import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface PillComponentArgs {
  active?: boolean;
  size?: "normal" | "small";
  onClick?(): unknown;
}

export default class PillComponent extends Component<PillComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/pill");

  @action
  onClick(): void {
    this.args.onClick?.();
  }

  get selectable(): boolean {
    return !!this.onClick;
  }
}
