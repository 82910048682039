import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ColorPreset, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";

interface ToolbarColorComboComponentArgs {
  currentScene: Array<Scene>;
  brandStyle?: BrandStyle;
  abridgedText?: boolean;
  abridgedTextToolbar?: boolean;
  onClose?: () => void;
  onClick?: () => void;
}

export default class ToolbarColorComboComponent extends Component<ToolbarColorComboComponentArgs> {
  @tracked
  open = false;

  @tracked
  targetElement?: HTMLElement;

  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("toolbar/color-combo");

  @action
  onClose(): void {
    this.args.onClose?.();
    this.open = false;
  }

  @action
  toggleMenu(): void {
    this.args.onClick?.();
    this.open = !this.open;
  }

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  get colorComboText(): string {
    if (this.args.abridgedText) {
      return "Combo";
    } else if (this.args.abridgedTextToolbar) {
      return "Colors";
    } else {
      return "Color combos";
    }
  }

  get selectedPreset(): ColorPreset | undefined {
    return this.args.currentScene[0]?.colorPreset;
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get disableBranding(): boolean {
    return this.selectedPreset === undefined;
  }
}
