import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Caption, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedTimingService from "client/services/advanced-timing";
import type PermissionsService from "client/services/permissions";

interface Args {
  caption: Caption;
  scene: Scene;
}

export default class ProjectTimelineActionsGroupAdvancedTimingComponent extends Component<Args> {
  @service
  declare advancedTiming: AdvancedTimingService;

  @service
  declare permissions: PermissionsService;

  styleNamespace = getStyleNamespace("project-timeline/actions-group/advanced-timing");

  @action
  async editTiming(event: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    void this.advancedTiming.open(this.args.caption, this.args.scene);
  }
}
