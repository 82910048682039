import Component from "@glimmer/component";
import { DEFAULT_CONTENT_BAR_COLUMNS } from "../results/component";
import getStyleNamespace from "client/lib/get-style-namespace";

type NumberOfColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface Args {
  columns?: NumberOfColumns;
}

export default class PanelContentGrid extends Component<Args> {
  styleNamespace = getStyleNamespace("project-content-bar/content-grid");

  get columns(): number {
    return this.args.columns ?? DEFAULT_CONTENT_BAR_COLUMNS;
  }
}
