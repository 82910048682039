import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type MobileService from "client/services/mobile";
import type OnboardService from "client/services/onboard";

export default class SurveyDecideController extends Controller {
  @service
  declare mobile: MobileService;

  @service
  declare onboard: OnboardService;

  @service
  declare router: RouterService;

  get isMobile(): boolean {
    return this.mobile.isMobile;
  }

  beforeModel(): void {
    this.onboard.end();
  }

  @action
  goBack(): void {
    void this.router.transitionTo("authenticated.survey.onboarding");
  }
}
