import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type HoneybadgerService from "client/services/honeybadger";
import type StripeService from "client/services/stripe";

interface StripeCardFormArgs {
  onCancel?: () => unknown;

  onTokenCreated(token: stripe.Token): unknown;

  onError?(error?: string): unknown;

  loading?: boolean;
  error?: string;
  submitButtonLabel?: string;
}

const STRIPE_PROPS: Partial<stripe.elements.ElementsOptions> = {
  style: {
    base: {
      color: "#000a47",
      fontFamily: `"Brown", "Helvetica Neue", Helvetica, sans-serif`,
      fontSize: "16px",
      fontWeight: 400,
      iconColor: "#9a9b9c"
    },
    empty: {
      color: "#989bbb"
    }
  }
};

enum CardFields {
  NUMBER = "cardNumber",
  CVC = "cardCvc",
  EXPIRY = "cardExpiry"
}

export default class StripeCardFormComponent extends Component<StripeCardFormArgs> {
  @service
  declare stripe: StripeService;

  @service
  declare honeybadger: HoneybadgerService;

  @tracked
  loading = false;

  @tracked
  cardName = "";

  @tracked
  elementID = guidFor(this);

  styleNamespace = getStyleNamespace("purchase/stripe-card-form");

  @tracked
  private error?: string;

  @tracked
  private formErrors = new Map<CardFields, string>();

  private cardCvc?: stripe.elements.Element;
  private cardNumber?: stripe.elements.Element;
  private cardExpiration?: stripe.elements.Element;

  private stripeInstance: stripe.Stripe;
  private elements: stripe.elements.Elements;

  constructor(owner: object, args: StripeCardFormArgs) {
    super(owner, args);

    this.stripeInstance = this.stripe.getInstance();
    this.elements = this.stripeInstance.elements();
  }

  private get formCreated(): boolean {
    return !!(this.cardCvc && this.cardNumber && this.cardExpiration);
  }

  private get cardNameValid(): boolean {
    return !!this.cardName.length;
  }

  get cardNumberError(): string | undefined {
    return this.formErrors.get(CardFields.NUMBER);
  }

  get cardCvcError(): string | undefined {
    return this.formErrors.get(CardFields.CVC);
  }

  get cardExpiryError(): string | undefined {
    return this.formErrors.get(CardFields.EXPIRY);
  }

  get errorMessage(): string | undefined {
    return this.args.error ?? this.error;
  }

  get showCancelButton(): boolean {
    return !!this.args.onCancel;
  }

  get isDisabled(): boolean {
    return !!(this.loading || this.args.loading);
  }

  get isLoading(): boolean {
    return this.loading || this.args.loading || false;
  }

  @action
  didInsertCardNumber(element: HTMLElement): void {
    this.cardNumber = this.createStripeElement(element, CardFields.NUMBER, { showIcon: true });
  }

  @action
  didInsertExpiry(element: HTMLElement): void {
    this.cardExpiration = this.createStripeElement(element, CardFields.EXPIRY);
  }

  @action
  didInsertCvc(element: HTMLElement): void {
    this.cardCvc = this.createStripeElement(element, CardFields.CVC);
  }

  @action
  cancel(): void {
    this.args.onCancel?.();
  }

  @action
  async createToken(event: Event): Promise<void> {
    event.preventDefault();

    if (!this.loading && this.formCreated && this.cardNumber && this.cardNameValid) {
      try {
        this.loading = true;
        this.error = undefined;

        const { token, error } = await this.stripeInstance.createToken(this.cardNumber, {
          name: this.cardName
        });

        if (error || !token) {
          this.handleError(error ?? Error("There was an error with your card"));
        } else {
          this.args.onTokenCreated(token);
        }
      } finally {
        this.loading = false;
      }
    }
  }

  private handleError(error: Error | stripe.Error): void {
    this.honeybadger.notify("Error creating stripe token", {
      context: {
        error
      },
      name: this.constructor.name
    });

    this.error = error.message;
  }

  private createStripeElement(
    element: HTMLElement,
    type: CardFields,
    options: Partial<stripe.elements.ElementsOptions> = {}
  ): stripe.elements.Element {
    const stripeElement = this.elements.create(type, { ...STRIPE_PROPS, ...options });
    stripeElement.mount(element);
    stripeElement.on("change", (response?: stripe.elements.ElementChangeResponse) => {
      const error = response?.error?.message;

      if (error) {
        this.formErrors.set(type, error);
      } else {
        this.formErrors.delete(type);
      }
    });

    return stripeElement;
  }
}
