import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isValidHexColor } from "client/lib/rgb-hex";
import type CollapsibleService from "client/services/collapsible";

interface ColorPickerCustomArgs {
  onChange?: (color?: string) => unknown;
  selectedColor?: string;
  resetColor?: string;
  showOpacitySlider: boolean;
}

const formatHex = (hash: string): string => {
  return (hash.startsWith("#") ? hash : `#${hash}`).toUpperCase();
};

export default class ColorPickerCustomComponent extends Component<ColorPickerCustomArgs> {
  @service
  declare collapsible: CollapsibleService;

  @tracked
  customColor?: string;

  styleNamespace = getStyleNamespace("color-picker-branded/custom-colors");

  @action
  toggleSection(event: MouseEvent): void {
    event.preventDefault();

    this.collapsible.toggleCustom();
  }

  @action
  resetDefaultColor(): void {
    this.customColor = this.args.resetColor ? formatHex(this.args.resetColor) : undefined;

    this.selectColor(this.customColor);
  }

  get expanded(): string {
    return String(this.collapsible.customColor);
  }

  get color(): string | undefined {
    const color = this.customColor ?? this.args.selectedColor;

    return color?.toUpperCase();
  }

  get colorNumeral(): string | undefined {
    return this.color?.replace("#", "");
  }

  @action
  onSelectedColorDidChange(): void {
    this.customColor = undefined;
  }

  @action
  onInputChange(event: InputEvent): void {
    const input = event.target as HTMLInputElement;

    const hex = formatHex(input.value);

    // If user enters an invalid hex-color then reset the value
    if (!isValidHexColor(hex)) {
      if (this.color) {
        input.value = this.colorNumeral || "";
      }

      return;
    }

    this.customColor = hex;
    this.selectColor(this.customColor);
  }

  @action
  onColorChange(color: string): void {
    this.customColor = color;
    this.selectColor(this.customColor);
  }

  @action
  selectNone(): void {
    this.selectColor(undefined);
  }

  selectColor(color: string | undefined): void {
    if (this.args.onChange) {
      this.args.onChange(color);
    }
  }
}
