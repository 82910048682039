import Model, { attr, belongsTo } from "@ember-data/model";
import type Store from "@ember-data/store";
import type { AudioClipCategory, RenderAudioClip } from "renderer-engine";
import { makeCORSUrl } from "renderer-engine";
import type Audible from "./audible";
import type Project from "./project";
import { restoreModel } from "client/lib/restore-model";
import type AjaxService from "client/services/ajax";
import type NotificationsService from "client/services/notifications";

export default class AudioClip extends Model implements RenderAudioClip {
  @attr("number")
  declare startTime: number;

  @attr("number")
  declare duration: number;

  @attr("number")
  declare trimStart: number;

  @attr("number")
  declare trimDuration: number | null;

  @attr("boolean")
  declare loop: boolean;

  @attr("number")
  declare volume: number;

  @attr("boolean")
  declare mute: boolean;

  @attr("boolean")
  declare fadeIn: boolean;

  @attr("boolean")
  declare fadeOut: boolean;

  @attr("number")
  declare audioDuckingLevel: number;

  @attr("string")
  declare category: AudioClipCategory;

  @belongsTo("project", { async: true, inverse: "audioClips" })
  declare project: Project;

  @attr("string")
  declare url: string;

  @attr("string")
  declare name: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("audible", { polymorphic: true, async: true, inverse: null })
  declare audible: Audible;

  @attr("string")
  declare audibleType: string;

  @attr("string")
  declare audibleId: string;

  static async restore(
    ajax: AjaxService,
    notifications: NotificationsService,
    store: Store,
    id: string
  ): Promise<AudioClip> {
    try {
      return await restoreModel(ajax, store, "audioClip", id);
    } catch (err) {
      notifications.error("There was a problem restoring the audio clip");
      throw err;
    }
  }

  get cdnUrl(): string {
    return makeCORSUrl(this.url);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    audioClip: AudioClip;
  }
}
