import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import TrackingEvents from "client/events";
import type Plan from "client/models/plan";
import type Trial from "client/models/trial";
import AnalyticsService from "client/services/analytics";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";
import type PlansService from "client/services/plans";
import type ScreenDoorService from "client/services/screen-door";
import type TrackingService from "client/services/tracking";

interface TrialStartIndexModel {
  plan: Plan;
  trial?: Trial;
  hasExistingBrand: boolean;
}

export default class TrialStartIndexRoute extends Route<TrialStartIndexModel> {
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare plans: PlansService;

  @service
  declare screenDoor: ScreenDoorService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  async model(): Promise<TrialStartIndexModel> {
    const plan = this.modelFor("authenticated.trial") as Plan;

    const [trial, hasExistingBrand] = await Promise.all([this.createTrial(plan), this.hasExistingBrand()]);

    return {
      plan,
      trial,
      hasExistingBrand
    };
  }

  async afterModel({ plan, trial }: TrialStartIndexModel): Promise<void> {
    if (trial) {
      // Send a Segment specific SaaS event to help with tracking through funnels across tools
      const subscription = await trial.subscription;
      AnalyticsService.track(TrackingEvents.EVENT_TRIAL_STARTED, {
        trialPlanId: plan.id,
        trialPlanName: plan.name,
        trialStartDate: subscription?.trialStart,
        trialEndDate: subscription?.trialEnd
      });

      await this.tracking.sendAnalytics(TrackingEvents.EVENT_START_TRIAL, {
        trialPlanId: plan.id
      });
      await this.auth.reload();
    } else {
      this.notifications.error("There was a problem starting your trial. Please contact support");
    }
  }

  private async createTrial(plan: Plan): Promise<Trial | undefined> {
    try {
      const trial = this.store.createRecord("trial");

      trial.setProperties({
        planId: plan.id
      });

      return await trial.save();
    } catch {
      return undefined;
    }
  }

  private async hasExistingBrand(): Promise<boolean> {
    try {
      /* eslint-disable camelcase */
      const brands = await this.store.query("brandStyle", { per_page: 1 });
      /* eslint-enable camelcase */
      return !!brands.length;
    } catch {
      return false;
    }
  }
}
