import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TagArgs {
  onClickClose?: () => void;
}

export default class Tag extends Component<TagArgs> {
  styleNamespace = getStyleNamespace("north-star/tag");

  @action
  onClickTag(event: MouseEvent): void {
    event.stopPropagation();
  }
}
