import { SceneTransition } from "renderer-engine";
import * as DomainModel from "client/lib/editor-domain-model";
import { toColorBrandKey } from "client/lib/editor-domain-model";
import type BackgroundFactory from "client/lib/scene/background-factory";
import type CaptionFactory from "client/lib/scene/caption-factory";
import type ProjectScene from "client/models/project-scene";
import type ZymbolGroup from "client/models/zymbol-group";

export class SceneFactory {
  constructor(private backgroundFactory: BackgroundFactory, private captionFactory: CaptionFactory) {}

  async createScene(projectScene: ProjectScene, zymbolGroups: ZymbolGroup[]): Promise<DomainModel.Scene> {
    const captions: DomainModel.Caption[] = [];
    const [width, height] = projectScene.aspectRatioName.split(":").map(Number);
    const scene = new DomainModel.Scene(
      projectScene.id,
      projectScene.duration,
      [width!, height!, projectScene.aspectRatioDimensions.width, projectScene.aspectRatioDimensions.height],
      await this.backgroundFactory.createBackground(projectScene),
      captions,
      projectScene.backgroundColor || "#FFFFFF",
      projectScene.filterColor,
      new DomainModel.Transition(projectScene.transition ?? SceneTransition.NONE),
      projectScene.isBrandable,
      projectScene.colorPreset,
      toColorBrandKey(projectScene.backgroundColorBrandKey)
    );
    captions.push(...(await this.captionFactory.createCaptions(scene, zymbolGroups)));
    return scene;
  }
}
