import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ProjectTimelineScaleComponentArgs {
  value: number;
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
}

const DEFAULT_SCALE_INCREMENT = 10;

export default class ProjectTimelineScaleComponent extends Component<ProjectTimelineScaleComponentArgs> {
  @tracked
  target!: HTMLElement;

  @tracked
  isOpen = false;

  styleNamespace = getStyleNamespace("project-timeline/scale");

  @action
  decrementScale(): void {
    this.args.onChange(Math.max(this.args.value - DEFAULT_SCALE_INCREMENT, this.args.minValue));
  }

  @action
  incrementScale(): void {
    this.args.onChange(Math.min(this.args.value + DEFAULT_SCALE_INCREMENT, this.args.maxValue));
  }

  @action
  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  @action
  closeMenu(): void {
    this.isOpen = false;
  }

  @action
  setTarget(element: HTMLElement): void {
    this.target = element;
  }
}
