import { get } from "@ember/object";
import { compare } from "@ember/utils";
import type Model from "@ember-data/model";

export function sortBy<T extends Model>(array: Array<T>, ...sortKeys: Array<keyof T>): Array<T> {
  return array.sort((a: T, b: T) => {
    for (let i = 0; i < sortKeys.length; i++) {
      const key = sortKeys[i];
      if (key) {
        const propA = get(a, key);
        const propB = get(b, key);
        const compareValue = compare(propA, propB);

        if (compareValue) {
          return compareValue;
        }
      }
    }
    return 0;
  });
}

export function uniq<T>(array: Array<T>): Array<T> {
  return array.filter((item: T, index: number, all: T[]) => all.indexOf(item) === index);
}
