import { debounce, throttle } from "@ember/runloop";
import type Model from "@ember-data/model";
import Honeybadger from "@honeybadger-io/js";
import type { Observer } from "rxjs";
import { Subject } from "rxjs";

const MAX_SAVE_DELAY = 10000;

export default class CommitManager {
  private pendingModels: Model[] = [];
  private pendingSaves: Promise<void[]> | undefined;

  subject = new Subject<void>();

  async createSave(): Promise<(model: Model) => void> {
    await this.pendingSaves;

    return (model: Model): void => {
      if (model.get("hasDirtyAttributes")) {
        if (!this.pendingModels.includes(model)) {
          this.pendingModels.push(model);
        }
      }
    };
  }

  async commit(delay = 0): Promise<void> {
    this.subject.next();
    if (delay) {
      debounce(this, this.doCommit, delay);
      throttle(this, this.doCommit, MAX_SAVE_DELAY, false);
    } else {
      await this.doCommit();
    }
  }

  async doCommit(): Promise<void> {
    const pendingModels = this.pendingModels;
    this.pendingModels = [];

    await (this.pendingSaves = Promise.all(
      pendingModels.map(async (model) => {
        if (model.get("isSaving")) {
          this.pendingModels.push(model);
          // @ts-expect-error
          debounce(this, this.commit, MAX_SAVE_DELAY);
          Honeybadger.logger.warn("Saving blocked by another save call");
        }
        if (model.get("hasDirtyAttributes")) {
          await model.save();
        }
      })
    ));
  }

  subscribe(observer: Observer<void>): void {
    this.subject.subscribe(observer);
  }
}
