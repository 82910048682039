import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  content?: string;
}

export default class ContentDividerComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("north-star/content-divider");
}
