import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";

interface ProjectPreviewActionsArgs {
  projectPreview: ProjectPreview;
  deleteProject(): unknown;
  duplicateProject(): unknown;
  toggleProjectTeamShared(): unknown;
  openProjectFolder(): unknown;
  addToFolder(): unknown;
  editProjectSettings(): unknown;
  editTemplateSettings(): unknown;
}

export default class ProjectPreviewActionsComponent extends Component<ProjectPreviewActionsArgs> {
  @service
  auth!: AuthService;

  @service
  permissions!: PermissionsService;

  styleNamespace = getStyleNamespace("discovery/project-preview/actions");

  get teamShared(): boolean {
    return this.args.projectPreview.teamShared;
  }

  private get canShareProject(): boolean {
    return this.args.projectPreview.isOwner || this.permissions.has("global_project_edit");
  }

  private get canUnshareProject(): boolean {
    return this.canShareProject || this.args.projectPreview.isTeamOwner;
  }

  get canEnableTeamEditing(): boolean {
    return !this.teamShared && this.canShareProject;
  }

  get canDisableTeamEditing(): boolean {
    return this.teamShared && this.canUnshareProject;
  }

  get inFolder(): boolean {
    return this.args.projectPreview.inFolder;
  }
}
