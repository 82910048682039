import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type Honeybadger from "@honeybadger-io/js";
import { Crisp } from "crisp-sdk-web";
import type { InvoiceModel } from "./route";
import type Charge from "client/models/charge";
import type User from "client/models/user";
import type NotificationsService from "client/services/notifications";

export class InvoiceDetails {
  @tracked
  email = "";

  @tracked
  contactPersonName = "";

  @tracked
  organizationAddress = "";

  @tracked
  organizationName = "";

  @tracked
  vat = "";

  constructor(props: Partial<InvoiceDetails> = {}) {
    Object.assign(this, props);
  }
}

export default class InvoiceController extends Controller<InvoiceModel> {
  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: Honeybadger;

  @tracked
  invoice = new InvoiceDetails();

  @tracked
  loading = false;

  get disabled(): boolean {
    return this.loading;
  }

  @action
  contactSupport(): void {
    Crisp.chat.show();
    Crisp.chat.open();
  }

  @action
  async submit(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.loading) {
      return;
    }

    if (ev.target instanceof HTMLFormElement && !ev.target.reportValidity()) {
      return;
    }

    this.loading = true;

    try {
      await this.generateInvoice(this.model.charge, this.model.user, this.invoice);
      this.notifications.success("Your invoice will be sent to the supplied email address");
    } catch (error: any) {
      this.notifications.error("There was a problem creating your invoice", {
        button: {
          label: "Please contact support",
          onClick: this.contactSupport
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.honeybadger.notify(error);
    } finally {
      this.loading = false;
    }
  }

  private async generateInvoice(charge: Charge, user: User, invoice: InvoiceDetails): Promise<void> {
    await this.store
      .createRecord("receipt", {
        id: new Date().getTime(),
        stripeAccount: charge.stripeAccount,
        chargeId: charge.id,
        userId: user.id,
        orgName: invoice.organizationName,
        contactName: invoice.contactPersonName,
        contactEmail: invoice.email,
        orgAddress: invoice.organizationAddress,
        vat: invoice.vat
      })
      .save();
  }
}
