import { getOwner } from "@ember/application";
import { attr, hasMany } from "@ember-data/model";
import type { Asset, SvgAssetConfig, SvgConfig } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type ContentTemplate from "./content-template";
import type { ManyArray } from "./ember-data-types";
import type { FavoriteMetadata } from "./favorable";
import { favorable, FAVORABLE_TYPES } from "./favorable";
import SelectableMediaAsset from "./selectable-media-asset";
import cmsUrl from "client/lib/cms-url";

@favorable
export default class Svg extends SelectableMediaAsset {
  @hasMany("content-template", { async: false, inverse: "svg" })
  declare contentTemplates: ManyArray<ContentTemplate>;

  @attr("json")
  cfg!: SvgAssetConfig;

  @attr("string")
  name!: string;

  @attr("date")
  updatedAt!: Date;

  @attr("string")
  declare thumbVideoUrl: string;

  get thumbImageUrl(): string {
    return this.previewImageUrl;
  }

  get previewImageUrl(): string {
    return `${this.cfg.previewImageUrl}?lastmod=${this.updatedAt.getTime()}`;
  }

  get cmsUrl(): string {
    return cmsUrl(getOwner(this), `/svg/${this.id}`);
  }

  readonly zymbolCategory = ZymbolCategory.SVG;
  protected _thumbAspectRatio = "1:1";

  asZymbolConfig(): Partial<SvgConfig> & { asset: Asset } {
    return {
      ...this.cfg,
      asset: {
        id: this.id,
        type: "svg"
      }
    };
  }

  get trackUsageData(): object {
    return {
      elementId: this.id,
      type: "svg",
      name: this.name
    };
  }

  readonly trackUsageEvent = undefined;

  readonly favorableType = FAVORABLE_TYPES.SVGS;

  get favorableProviderAssetId(): string {
    return this.id;
  }

  metadataForFavorite: FavoriteMetadata = {};
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    svg: Svg;
  }
}
