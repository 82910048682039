// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from "ember-data";
import { pluralize } from "ember-inflector";
import ApplicationAdapter from "client/adapters/application";
import config from "client/config/environment";
import { toKebabCase } from "client/lib/restore-model";
import Project from "client/models/project";
import VideoPage from "client/models/video-page";

interface AdapterOptions {
  projectId: string;
  videoPageId?: string;
}

export default class CommentAdapter extends ApplicationAdapter {
  urlForQuery(query: { "project-id": string }, modelName: any): string {
    return this.buildUrl(modelName as string, {
      projectId: query["project-id"]
    });
  }

  urlForCreateRecord(modelName: any, snapshot: DS.Snapshot): string {
    return this.urlForCRUDRecord(modelName, snapshot);
  }

  urlForDeleteRecord(id: string, modelName: any, snapshot: any): string {
    return `${this.urlForCRUDRecord(modelName, snapshot as DS.Snapshot)}/${id}`;
  }

  private urlForCRUDRecord(modelName: any, snapshot: DS.Snapshot): string {
    const { adapterOptions = {} }: { adapterOptions: any } = snapshot;
    Object.assign(adapterOptions, {
      projectId: adapterOptions.projectId || snapshot.attr("projectId") || snapshot.record.project.get("id")
    });
    return this.buildUrl(modelName as string, adapterOptions as AdapterOptions);
  }

  private buildUrl(modelName: string, adapterOptions: AdapterOptions): string {
    let parentResource;
    if (adapterOptions.videoPageId) {
      parentResource = `${toKebabCase(pluralize(VideoPage.modelName as string))}/${adapterOptions.videoPageId}/`;
    } else {
      parentResource = `${pluralize(Project.modelName as string)}/${adapterOptions.projectId}/`;
    }
    return `${config.apiUrl}/${parentResource}${pluralize(modelName)}`;
  }
}
