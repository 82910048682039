import { action, setProperties } from "@ember/object";
import type { UnwrapComputedPropertySetters } from "@ember/object/-private/types";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import { ContributorTypes } from "client/models/folder";
import type Project from "client/models/project";
import type ProjectOwner from "client/models/project-owner";
import type ProjectPreview from "client/models/project-preview";
import type CollaborationService from "client/services/collaboration";
import type DragAndDropService from "client/services/drag-and-drop";
import type FoldersService from "client/services/folders";
import type GalleryService from "client/services/gallery";
import type NotificationsService from "client/services/notifications";
import type ProjectTemplateSettingsModalService from "client/services/project-template-settings-modal";
import { PROJECT_DUPLICATION_FAILURE_NOTIFICATION } from "client/services/projects";
import type TeamService from "client/services/team";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

interface ProjectPreviewArgs {
  projectPreview: ProjectPreview;
}

export default class ProjectPreviewComponent extends Component<ProjectPreviewArgs> {
  @service
  declare collaboration: CollaborationService;

  @service
  declare dragAndDrop: DragAndDropService;

  @service
  declare folders: FoldersService;

  @service
  declare gallery: GalleryService;

  @service
  declare notifications: NotificationsService;

  @service
  declare projectTemplateSettingsModal: ProjectTemplateSettingsModalService;

  @service
  declare router: RouterService;

  @service
  declare tracking: TrackingService;

  @service
  declare team: TeamService;

  @service
  declare upgrade: UpgradeService;

  private clonedItem?: HTMLElement;

  @tracked
  showDeleteConfirmation = false;

  @tracked
  showTeamSharedEditConfirmation = false;

  @tracked
  deleting = false;

  @tracked
  deleted = false;

  @tracked
  dragging = false;

  @tracked
  duplicating = false;

  styleNamespace = getStyleNamespace("discovery/project-preview");

  get title(): string {
    return this.projectPreview.title;
  }

  get duration(): number {
    return this.projectPreview.duration;
  }

  get projectId(): string {
    return this.projectPreview.id;
  }

  get rendered(): boolean {
    return this.projectPreview.rendered;
  }

  get updatedAt(): Date {
    return this.projectPreview.updatedAt;
  }

  get projectPreview(): ProjectPreview {
    return this.args.projectPreview;
  }

  get teamShared(): boolean {
    return this.args.projectPreview.teamShared;
  }

  get owner(): ProjectOwner | undefined {
    return this.projectPreview.owner;
  }

  get folderId(): string | undefined {
    return this.projectPreview.folderId;
  }

  @action
  async editProjectSettings(): Promise<void> {
    await this.router.transitionTo("authenticated.project.settings", this.projectId);
  }

  @action
  async editProject(): Promise<void> {
    if (this.teamShared && !this.showTeamSharedEditConfirmation) {
      this.editTeamSharedProject();
    } else {
      this.trackEditClick();
      await this.router.transitionTo("authenticated.project", this.projectId);
    }
  }

  @action
  deleteProject(): void {
    this.showDeleteConfirmation = true;
  }

  @action
  async confirmDeleteProject(): Promise<void> {
    if (this.deleting) {
      return;
    }

    try {
      this.deleting = true;
      await this.projectPreview.deleteProject();
      this.notifications.success("Your video has been deleted");
      this.deleted = true;
    } catch (err) {
      this.notifications.error("Oh no! There was a problem deleting your video");
    } finally {
      this.deleting = false;
    }
  }

  @action
  cancelDeleteProject(): void {
    this.showDeleteConfirmation = false;
  }

  @action
  openProjectFolder(): Promise<void> {
    if (this.folderId) {
      return this.router.transitionTo("authenticated.folders.folder", this.folderId).then();
    } else {
      return this.router
        .transitionTo(
          "authenticated.folders.library",
          this.projectPreview.teamShared ? ContributorTypes.TEAM : ContributorTypes.USER
        )
        .then();
    }
  }

  @action
  showDuplicateProject(): void {
    this.duplicating = true;
  }

  @action
  cancelDuplicateProject(): void {
    this.duplicating = false;
  }

  @action
  async createDuplicateProject(
    project: Project,
    properties: Pick<UnwrapComputedPropertySetters<Project>, keyof Project>
  ): Promise<void> {
    try {
      const copy = await project.duplicate();
      setProperties(copy, properties);
      await copy.save();
      await this.router.transitionTo("authenticated.project", copy.id);
    } catch (err) {
      this.notifications.error(PROJECT_DUPLICATION_FAILURE_NOTIFICATION);
    }
  }

  @action
  async shareProject(): Promise<void> {
    await this.projectPreview.shareProject();
    this.trackEvent(TrackingEvents.EVENT_TEAM_SHARE_PROJECT, { source: "project-menu" });

    if (this.router.currentRouteName.startsWith("authenticated.folders.library")) {
      await this.folders.reloadContent();
    }

    this.notifications.success(`1 video successfully moved to Team`, {
      button: {
        label: "View folder",
        onClick: () => {
          void this.openProjectFolder();
        }
      }
    });
  }

  @action
  async unshareProject(): Promise<void> {
    await this.projectPreview.unshareProject();
    this.trackEvent(TrackingEvents.EVENT_TEAM_UNSHARE_PROJECT);

    if (this.router.currentRouteName.startsWith("authenticated.folders.library")) {
      await this.folders.reloadContent();
    }

    this.notifications.success(`1 video successfully moved to Personal`, {
      button: {
        label: "View folder",
        onClick: () => {
          void this.openProjectFolder();
        }
      }
    });
  }

  @action
  async toggleProjectTeamShared(): Promise<void> {
    if (this.teamShared) {
      await this.unshareProject();
    } else if (this.team.hasMultipleMembers) {
      await this.shareProject();
    } else {
      this.invitePeople();
    }
  }

  @action
  async editTemplateSettings(): Promise<void> {
    await this.projectTemplateSettingsModal.open(this.projectId);
  }

  @action
  editTeamSharedProject(): void {
    this.showTeamSharedEditConfirmation = true;
  }

  @action
  cancelEditTeamSharedProject(): void {
    this.showTeamSharedEditConfirmation = false;
  }

  @action
  onClickWatchMetrics(): void {
    this.trackEvent(TrackingEvents.EVENT_CLICK_ANALYTICS_PROJECT);
  }

  @action
  onClickCommentCount(): void {
    this.trackEvent(TrackingEvents.EVENT_CLICK_COMMENT_COUNT);
  }

  @action
  trackShareClick(): void {
    this.trackEvent(TrackingEvents.EVENT_CLICK_SHARE_PROJECT);
  }

  @action
  onDrag(event: DragEvent): void {
    this.dragAndDrop.onDragScroll(event, "[class$=_Content]");
  }

  @action
  onDragStart(_: object, event: DragEvent): void {
    this.dragging = true;
    this.gallery.dragging = true;
    const target = event.target as HTMLElement;

    this.clonedItem = target.cloneNode(true) as HTMLElement;
    const { width, height } = target.getBoundingClientRect();

    Object.assign(this.clonedItem.style, {
      width: `${width}px`,
      height: `${height}px`
    });
    this.clonedItem.classList.add(`${this.styleNamespace}__DragImage`);
    document.body.appendChild(this.clonedItem);
    event.dataTransfer?.setDragImage(this.clonedItem, 0, 0);
  }

  @action
  onDragEnd(): void {
    this.dragging = false;
    this.gallery.dragging = false;
    this.clonedItem?.remove();
  }

  private invitePeople(): void {
    this.collaboration.showTeamInviteModal("project preview - toggle shared editing");
  }

  private trackEvent(event: TrackingEvents, opts = {}): void {
    void this.tracking.sendAnalytics(event, {
      projectId: this.projectId,
      ...opts
    });
  }

  private trackEditClick(): void {
    this.trackEvent(TrackingEvents.EVENT_CLICK_EDIT_PROJECT);
  }
}
