import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { pluralize } from "ember-inflector";
import timeBetween from "client/lib/time-between";
import type StorageService from "client/services/storage";

export const FAILED_LOGIN_ATTEMPTS = "failedLoginAttempts";
const FAILED_ATTEMPTS_LIMIT = 5;
const BLOCKED_PERIOD_LENGTH = 7200000;

export default class FailedLoginAttemptsService extends Service {
  @service
  private declare storage: StorageService;

  @tracked
  private attempts: number[] = [];

  @tracked
  loggingBlocked = false;

  @tracked
  remainingAttempts = FAILED_ATTEMPTS_LIMIT;

  withAnonymousKey = true;

  initialize(): void {
    this.initializeAttempts();
    this.loggingBlocked = this.isLoggingBlocked;
    this.remainingAttempts = this.calculateRemainingAttempts();
  }

  get attemptsExceeded(): boolean {
    return this.attempts.length >= FAILED_ATTEMPTS_LIMIT;
  }

  get warning(): string {
    return this.isLoggingBlocked ? this.blockedWarning : this.remainingAttemptsWarning;
  }

  add(timestamp: number): void {
    this.attempts.push(timestamp);
    this.storage.setItem(FAILED_LOGIN_ATTEMPTS, JSON.stringify(this.attempts), this.withAnonymousKey);
    this.loggingBlocked = this.isLoggingBlocked;
    this.remainingAttempts = this.calculateRemainingAttempts();
  }

  reset(): void {
    this.storage.clearItem(FAILED_LOGIN_ATTEMPTS, this.withAnonymousKey);
    this.attempts = [];
    this.loggingBlocked = false;
    this.remainingAttempts = FAILED_ATTEMPTS_LIMIT;
  }

  private get isLoggingBlocked(): boolean {
    return this.attemptsExceeded && !this.blockingTimeExceeded;
  }

  private get blockingTimeExceeded(): boolean {
    return this.timeSinceLatestAttempt > BLOCKED_PERIOD_LENGTH;
  }

  private get timeSinceLatestAttempt(): number {
    if (this.latestLoginAttempt) {
      const now = new Date();
      const latestAttempt = new Date(this.latestLoginAttempt);

      return timeBetween(latestAttempt, now, "millisecond", true);
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  private get latestLoginAttempt(): number | undefined {
    return this.attempts.slice(-1)[0];
  }

  private calculateRemainingAttempts(): number {
    return FAILED_ATTEMPTS_LIMIT - this.attempts.length;
  }

  private initializeAttempts(): void {
    const attempts = this.storage.getItem(FAILED_LOGIN_ATTEMPTS, this.withAnonymousKey);

    if (attempts) {
      this.attempts = JSON.parse(attempts);

      if (this.blockingTimeExceeded) {
        this.reset();
      }
    }
  }

  private get remainingAttemptsWarning(): string {
    return `Warning: you have ${pluralize(
      this.remainingAttempts,
      "attempt"
    )} remaining. For security reasons after too many failed login attempts, you’ll have to wait two hours before trying again.`;
  }

  private get blockedWarning(): string {
    const remainingBlockingTime = new Date(BLOCKED_PERIOD_LENGTH - this.timeSinceLatestAttempt).toISOString();

    return `Your account has been temporarily locked due to too many failed login attempts. Please try again in
     ${pluralize(parseInt(remainingBlockingTime.substring(11, 13)), "hour")} and ${pluralize(
      parseInt(remainingBlockingTime.substring(14, 16)),
      "minute"
    )}.`;
  }
}
