import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { SkuCode, PlanInterval } from "client/models/plan";
import type UpgradeService from "client/services/upgrade";

interface UpgradeLinkArgs {
  context: string;
  planSku?: SkuCode;
  planInterval?: PlanInterval;
  onClick?(): unknown;
}

export default class UpgradeLinkComponent extends Component<UpgradeLinkArgs> {
  @service
  declare upgrade: UpgradeService;

  styleNamespace = getStyleNamespace("upgrade-link");

  @action
  onClick(): void {
    if (this.args.planSku) {
      void this.upgrade.selectTopTierPlan({
        context: this.args.context
      });
    } else {
      void this.upgrade.open({ analytics: { ctaContext: this.args.context } });
    }

    this.args.onClick?.();
  }
}
