import Model, { attr, belongsTo } from "@ember-data/model";
import type Overlay from "client/models/overlay";
import type Project from "client/models/project";

export default class VideoPage extends Model {
  @attr("string")
  declare embedUrl: string;

  @attr("string")
  declare title: string;

  @attr("string")
  declare url: string;

  @attr("string")
  declare sourceProjectId: string;

  @attr("boolean")
  declare premiumFeatures: boolean;

  @attr("boolean")
  declare hidden: boolean;

  @attr("string")
  declare sourceProjectType: string;

  @attr("boolean")
  declare titleHidden: boolean;

  @belongsTo("project", { async: true, inverse: "videoPage" })
  declare sourceProject: Project;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("overlay", { async: true, inverse: null })
  declare overlay: Overlay;

  get projectId(): string | undefined {
    // @ts-expect-error
    return this.belongsTo("sourceProject").id();
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    videoPage: VideoPage;
  }
}
