import type { UserAsset } from "@biteable/network-model";
import Network from "@biteable/network-model";
import { getOwner } from "@ember/application";
import { tracked } from "@glimmer/tracking";
import Vibrant from "node-vibrant";
import { Shape } from "renderer-engine";
import { getDefaultAnimation } from "../timeline/image";
import { Frame, Image } from "client/lib/editor-domain-model";
import type ActiveStorageService from "client/services/active-storage";

export interface BrandLogoJSON {
  userAssetId?: string;
  frame?: Partial<Frame>;
  defaultLogo?: boolean;
}

interface BrandLogoProps {
  frame?: Frame;
  defaultLogo?: boolean;
}

const getDefaultFrame = (): Frame => {
  return new Frame(Shape.NONE, "#FFFFFF", 1, 0, 0);
};

interface LogoAsset {
  previewUrl?: string;
  filestackUrl?: string;
  id?: string;
  save(...props: Array<unknown>): Promise<LogoAsset>;
}

const MAX_LOGO_COLORS = 3;

export default class BrandLogo {
  @tracked
  _userAsset: LogoAsset;

  @tracked
  _image: Image;

  @tracked
  _defaultLogo = false;

  constructor(userAsset: LogoAsset, { frame = getDefaultFrame(), defaultLogo = false }: BrandLogoProps = {}) {
    this._userAsset = userAsset;

    const { previewUrl, filestackUrl } = userAsset;

    this._image = new Image(getDefaultAnimation(), undefined, filestackUrl, previewUrl, frame);
    this._defaultLogo = defaultLogo;
  }

  get image(): Image {
    return this._image;
  }

  get userAssetId(): string | undefined {
    return this.userAsset?.id;
  }

  public toJSON(): BrandLogoJSON {
    const { userAssetId, defaultLogo } = this;
    const { frame } = this.image;
    const { shape, color, scale, offsetX, offsetY } = frame ?? {};

    const json = {
      userAssetId,
      defaultLogo,
      frame: {
        shape,
        color,
        scale,
        offsetX,
        offsetY
      }
    };

    return json;
  }

  static async fromJSON(json: BrandLogoJSON): Promise<BrandLogo> {
    const { userAssetId, frame, defaultLogo } = json;

    if (userAssetId) {
      const userAsset = await Network.store.find<UserAsset>("userAsset", userAssetId);

      if (userAsset) {
        return new BrandLogo(userAsset, { frame: Object.assign(getDefaultFrame(), frame), defaultLogo });
      }
    }

    throw Error("Could not extract BrandLogo from JSON");
  }

  set defaultLogo(defaultLogo: boolean) {
    this._defaultLogo = defaultLogo;
  }

  get defaultLogo(): boolean {
    return this._defaultLogo;
  }

  get userAsset(): LogoAsset {
    return this._userAsset;
  }

  public async saveUserAsset(): Promise<void> {
    if (this.userAsset.id === undefined) {
      this._userAsset = await this.userAsset.save();
    }
  }

  public async transferToBiteableCDN(owner: object): Promise<void> {
    const { userAsset } = this;

    if (!userAsset.filestackUrl) {
      return;
    }

    const activeStorageService: ActiveStorageService = getOwner(owner)?.lookup(
      "service:active-storage"
    ) as ActiveStorageService;
    const file = await activeStorageService.createFileFromURL(userAsset.filestackUrl);
    const blob = await activeStorageService.silentRawUploadFile(file);

    Object.assign(userAsset, {
      filestackUrl: undefined,
      fileSignedId: blob.signed_id,
      name: blob.filename,
      mimeType: blob.content_type
    });
  }

  public updateFrame(frame: Frame): void {
    this._image._frame = frame;
  }

  public async getLogoColors(): Promise<Array<string>> {
    const { previewUrl, filestackUrl } = this.userAsset;
    const logoUrl = filestackUrl || previewUrl;

    if (!logoUrl) {
      return [];
    }

    const vibrant = new Vibrant(`${logoUrl}?nocache`, {
      colorCount: 4
    });
    const palette = await vibrant.getPalette();

    if (palette) {
      return Object.values(palette)
        .sort((a, b) => {
          return (b?.population ?? 0) - (a?.population ?? 0);
        })
        .map((swatch) => swatch?.hex)
        .filter(Boolean)
        .slice(0, MAX_LOGO_COLORS) as Array<string>;
    } else {
      return [];
    }
  }
}
