import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { ContributorTypes } from "client/models/folder";

export default class AssetLibraryIndexRoute extends Route {
  @service
  declare router: RouterService;

  beforeModel(): void {
    void this.router.replaceWith("authenticated.asset-library.library", ContributorTypes.USER);
  }
}
