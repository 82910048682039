import { clamp, snap } from "../../math";
import type { Caption } from "../../scene/caption";
import type { StrictMutation } from "./mutation";
import { InlineMutation, SUPPRESS_WARNING } from "./vanilla-mutation";

export const MINIMUM = 0.5;
export const STEP = 0.5;

export class CaptionDurationMutationsFactory {
  constructor(private caption: Caption) {}

  createMutations(duration: number): StrictMutation[] {
    const delta = snap(clamp(duration, MINIMUM, Infinity), STEP) - this.caption._duration;

    return [
      new InlineMutation(this.caption, (caption) => caption._setDuration(caption._duration + delta), SUPPRESS_WARNING),
      new InlineMutation(this.caption._scene, (scene) => scene._setDuration(scene._duration + delta), SUPPRESS_WARNING),
      ...this.caption._scene._captions
        .filter((caption) => caption._offset > this.caption._offset)
        .map(
          (caption) => new InlineMutation(caption, (caption) => caption._setOffset(caption._offset + delta)),
          SUPPRESS_WARNING
        )
    ];
  }
}
