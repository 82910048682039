import { applyToPoint, translate } from "transformation-matrix";
import { Vector2 } from "./vector2";

export class Rect {
  constructor(public position: Vector2, public size: Vector2) {}

  public overlaps(other: Rect): boolean {
    return (
      this.x + this.width >= other.x &&
      this.x <= other.x + other.width &&
      this.y + this.height >= other.y &&
      this.y <= other.y + other.height
    );
  }

  public equals(other: Rect): boolean {
    return this.x === other.x && this.y === other.y && this.width === other.width && this.height === other.height;
  }

  public contains(point: Vector2): boolean {
    const { x, y, width, height } = this;
    const xInRange = point.x >= x && point.x <= x + width;
    const yInRange = point.y >= y && point.y <= y + height;

    return xInRange && yInRange;
  }

  public static translate(rect: Rect, vec: Vector2): Rect {
    const { x, y, width, height } = rect;
    const [newX, newY] = applyToPoint(translate(vec.x, vec.y), [x, y]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return new Rect(new Vector2(newX, newY), new Vector2(width, height));
  }

  public get x(): number {
    return this.position.x;
  }

  public get y(): number {
    return this.position.y;
  }

  public get width(): number {
    return this.size.x;
  }

  public get height(): number {
    return this.size.y;
  }

  public get corners(): Vector2[] {
    const topLeft = this.position;
    const topRight = new Vector2(topLeft.add(this.size).x, topLeft.y);
    const bottomRight = new Vector2(topRight.x, topRight.add(this.size).y);
    const bottomLeft = new Vector2(bottomRight.subtract(this.size).x, bottomRight.y);
    return [topLeft, topRight, bottomRight, bottomLeft];
  }

  public get intersectionPoints(): Vector2[] {
    const top = new Vector2(this.x + this.width / 2, this.y);
    const right = new Vector2(this.x + this.width, this.y + this.height / 2);
    const bottom = new Vector2(this.x + this.width / 2, this.y + this.height);
    const left = new Vector2(this.x, this.y + this.height / 2);

    return [...this.corners, top, right, bottom, left];
  }

  static fromRectLike({ x, y, width, height }: Pick<Rect, "x" | "y" | "width" | "height">): Rect {
    return new Rect(new Vector2(x, y), new Vector2(width, height));
  }
}
