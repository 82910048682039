import type { Project } from "@biteable/network-model";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import moment from "moment";
import { Locations } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Comment from "client/models/comment";
import type AuthService from "client/services/auth";
import type CommentService from "client/services/comment";
import type VideoPlayerService from "client/services/video-player";

interface ExportCommentsThreadArgs {
  comment: Comment;
  hasReplyForm?: boolean;
  hasTimestamp?: boolean;
  parentCommentId?: string;
  project: Project;
  renderRequired: boolean;
  isRendering: boolean;
}

export default class ExportCommentsThreadComponent extends Component<ExportCommentsThreadArgs> {
  commentElement!: HTMLElement;
  teamInviteElement!: HTMLElement;

  @service
  declare auth: AuthService;

  @service
  declare comment: CommentService;

  @service
  declare videoPlayer: VideoPlayerService;

  @tracked
  isReplyFormHidden = true;

  @tracked
  isTeamInviteHidden = true;

  styleNamespace = getStyleNamespace("export-comments/thread");

  get createdAt(): string {
    const date = new Date(this.args.comment.createdAt);
    return moment(date).fromNow();
  }

  get hasReplyForm(): boolean {
    return this.args.hasReplyForm ?? true;
  }

  get hasTimestamp(): boolean {
    return this.args.hasTimestamp ?? true;
  }

  get isTimestampDisabled(): boolean {
    return this.args.renderRequired || this.args.isRendering;
  }

  get teamInviteTrackingLocation(): string {
    return Locations.LOCATION_EXPORT_COMMENTS;
  }

  @action
  setCommentElement(element: HTMLElement): void {
    this.commentElement = element;
  }

  @action
  setTeamInviteElement(element: HTMLElement): void {
    this.teamInviteElement = element;
  }

  @action
  isOnCurrentTeam(email: string): boolean {
    return Boolean(this.auth.currentTeam?.emails.includes(email));
  }

  @action
  onClickAddTeamMember(email: string): void {
    this.isTeamInviteHidden = false;

    setTimeout(() => {
      const input = this.teamInviteElement.querySelector("input");
      if (input) {
        input.value = email;
      }
    }, 50);
  }

  @action
  closeTeamInvite(): void {
    this.isTeamInviteHidden = true;
  }

  @action
  onClickReply(): void {
    this.isReplyFormHidden = false;
    this.videoPlayer.autoPause();
    setTimeout(() => {
      this.commentElement.querySelector("input")?.focus();
    }, 50);
  }

  @action
  onClickTimestamp(): void {
    this.videoPlayer.player.tabIndex = 0;
    this.videoPlayer.player.focus();
    this.videoPlayer.setCurrentTime(this.args.comment.timestamp);
    setTimeout(async () => {
      await this.videoPlayer.play();
    }, 500);
  }

  @action
  onDeleteComment(): void {
    void this.comment.delete(this.args.comment);
  }

  @action
  onSubmitReply(): void {
    this.isReplyFormHidden = true;
  }
}
