import Service, { service } from "@ember/service";
import config from "client/config/environment";
import type AjaxHeadersService from "client/services/ajax-headers";
import type AuthService from "client/services/auth";

export interface IdentifiablePayload {
  data: {
    id: string;
  };
}

export default class AjaxService extends Service {
  @service
  declare ajaxHeaders: AjaxHeadersService;

  @service
  declare auth: AuthService;

  get headers(): Record<string, string> {
    return this.ajaxHeaders.headers;
  }

  async api(path: string, args: RequestInit = {}, responseType: XMLHttpRequestResponseType = "json"): Promise<any> {
    const { headers = {}, mode = "cors" } = args;
    Object.assign(headers, this.headers);

    const url = config.apiUrl + path;
    const response = await fetch(url, { headers, mode, ...args });

    if (response.status === 204 || response.status === 202) {
      return;
    }

    const responseBody = responseType === "json" ? response.json() : response.text();

    if (this.isProblem(response.status)) {
      throw await responseBody;
    }

    return responseBody;
  }

  isProblem = (status: number): boolean => status >= 400 && status <= 599;
}
