import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { AnimationFit } from "renderer-engine";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { EventRegister, Frame, Media, Scene, Timeline } from "client/lib/editor-domain-model";
import {
  ChangeAssetOffsetMutation,
  ChangeObjectFitMutation,
  ChangePositionMutation,
  Image,
  UpdateFrameMutation,
  VideoClip,
  Watermark
} from "client/lib/editor-domain-model";
import { getResetPosition } from "client/lib/timeline/media";
import { transaction } from "client/lib/transaction";
import type BrandStyle from "client/models/brand-style";
import type TimelineEventsService from "client/services/timeline-events";

interface AdjustFrameModalComponentArgs {
  eventRegister: EventRegister;
  media: Media;
  scene: Scene;
  brandStyle: BrandStyle;
  onClose: () => void;
  timeline: Timeline;
}

export default class AdjustFrameModalComponent extends Component<AdjustFrameModalComponentArgs> {
  @service
  declare timelineEvents: TimelineEventsService;

  @tracked
  brandConfig?: BrandStyleConfig;

  @action
  async didInsert(): Promise<void> {
    const { asset } = this.args.media;

    if (!(asset instanceof Image || asset instanceof VideoClip)) {
      this.args.onClose();
      throw Error("Asset is not an image or video clip");
    }

    if (!asset.hasContent) {
      this.args.onClose();
      throw Error("Asset has no content");
    }

    this.brandConfig = this.args.brandStyle?.style;
  }

  @action
  @transaction
  async save(frame: Frame): Promise<void> {
    const { scene, media, eventRegister } = this.args;

    if (!(media.asset instanceof Image || media.asset instanceof VideoClip)) {
      return;
    }

    eventRegister.fire(new UpdateFrameMutation(media.asset, frame));
    eventRegister.fire(new ChangeObjectFitMutation(media.asset, AnimationFit.FIT));

    eventRegister.fire(
      new ChangePositionMutation(
        media,
        await getResetPosition(media, scene, { upperSizeLimit: media.position, centerOnPosition: true })
      )
    );
    eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));

    if (media instanceof Watermark) {
      await eventRegister.facade.saveWatermark(media);
    } else {
      await eventRegister.facade.saveScene(scene);
    }
  }
}
