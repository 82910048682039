import type Store from "@ember-data/store";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from "ember-data";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from "ember-data";
import RSVP from "rsvp";
import ApplicationAdapter from "./application";
import config from "client/config/environment";

const VIDEO_BLOCKS_TYPE = "video-blocks";

export default class ZymbolFootageAdapter extends ApplicationAdapter {
  pathForType(): string {
    return "zymbol-footage";
  }

  /**
   * Extend findRecord to also pass through `type` query parameter
   */
  findRecord(_store: Store, type: ModelSchema, id: string, _snapshot: DS.Snapshot): RSVP.Promise<any> {
    // eslint-disable-next-line import/no-named-as-default-member
    return new RSVP.Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${config.apiUrl}/${type.modelName}/${id}?type=${VIDEO_BLOCKS_TYPE}`);
        const data = response.json();
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }
}
