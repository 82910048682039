import Model, { attr } from "@ember-data/model";

export default class ProjectSceneRender extends Model {
  @attr("string")
  projectSceneId!: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectSceneRender: ProjectSceneRender;
  }
}
