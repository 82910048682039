import type { Scene } from "../../scene";

export interface SceneState {
  sceneDuration: number;
  captionsState: CaptionStateKey;
}

export interface CaptionStateKey {
  [key: string]: CaptionState;
}

export interface CaptionState {
  offset: number;
  duration: number;
}

export function createState(scene: Scene): SceneState {
  const captionsState: CaptionStateKey = {};
  for (const caption of scene.captions) {
    captionsState[caption.id] = {
      duration: caption.duration,
      offset: caption.offset
    };
  }
  return {
    sceneDuration: scene.duration,
    captionsState
  };
}
