import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type StripeService from "client/services/stripe";

interface CheckoutPlanCardComponentArgs {
  card?: stripe.elements.Element;
  error?: string;
  name: string;
  setCard: (card: stripe.elements.Element) => void;
  setError: (error?: string) => void;
}

export default class CheckoutPlanCardComponent extends Component<CheckoutPlanCardComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/checkout-plan-card");

  cardCvc!: stripe.elements.Element;
  cardNumber!: stripe.elements.Element;
  cardExpiration!: stripe.elements.Element;

  @service
  declare stripe: StripeService;

  @tracked
  cardExpirationError?: string;

  @tracked
  cardCvcError?: string;

  @tracked
  cardNumberError?: string;

  private _stripeProps = {
    style: {
      base: {
        color: "#000a47",
        fontFamily: `"Brown", "Helvetica Neue", Helvetica, sans-serif`,
        fontSize: "16px",
        fontWeight: 400,
        iconColor: "#9a9b9c"
      },
      empty: {
        color: "#989bbb"
      }
    }
  };

  @action
  async setup(): Promise<void> {
    const stripe = this.stripe.getInstance();
    const elements = stripe.elements();

    this.cardNumber = elements.create("cardNumber", { ...this._stripeProps, showIcon: true });
    this.cardNumber.mount("#card-number");
    this.cardNumber.on("change", (response?: stripe.elements.ElementChangeResponse) => {
      this.cardNumberError = response?.error?.message;
    });
    this.cardNumber.on("focus", () => {
      this.cardNumberError = undefined;
      this.args.setError();
    });

    this.cardExpiration = elements.create("cardExpiry", this._stripeProps);
    this.cardExpiration.mount("#card-expiration");
    this.cardExpiration.on("change", (response?: stripe.elements.ElementChangeResponse) => {
      this.cardExpirationError = response?.error?.message;
    });
    this.cardExpiration.on("focus", () => {
      this.cardExpirationError = undefined;
      this.args.setError();
    });

    this.cardCvc = elements.create("cardCvc", this._stripeProps);
    this.cardCvc.mount("#card-cvc");
    this.cardCvc.on("change", (response?: stripe.elements.ElementChangeResponse) => {
      this.cardCvcError = response?.error?.message;
    });
    this.cardCvc.on("focus", () => {
      this.cardCvcError = undefined;
      this.args.setError();
    });

    this.args.setCard(this.cardNumber);
  }
}
