import { action } from "@ember/object";
import Evented from "@ember/object/evented";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type AjaxService from "./ajax";
import type { IdentifiablePayload } from "./ajax";
import type PlansService from "./plans";
import TrackingEvents from "client/events";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import type { PlanInterval } from "client/models/plan";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type { Phase } from "client/models/subscription-schedule";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type ValidatedCoupon from "client/models/validated-coupon";
import AnalyticsService from "client/services/analytics";
import type AuthService from "client/services/auth";
import type ScreenDoorService from "client/services/screen-door";
import type TrackingService from "client/services/tracking";

export enum PlanSelectContext {
  WWW = "www",
  APP = "app"
}

interface UpgradeServiceSelectPlanArgs {
  context: string;
  selectedPlan: Plan;
  couponCode?: string;
  showCouponField?: boolean;
}

export enum UpgradeEvents {
  SUBSCRIPTION_PURCHASED = "SUBSCRIPTION_PURCHASED"
}

export default class UpgradeService extends Service.extend(Evented) {
  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @service
  declare tracking: TrackingService;

  @service
  declare screenDoor: ScreenDoorService;

  @service
  declare plans: PlansService;

  @service
  declare store: Store;

  @service
  declare ajax: AjaxService;

  @tracked
  selectedPlan?: Plan;

  @tracked
  upgrading = false;

  @tracked
  couponCode?: string;

  @tracked
  planImmediatelyChanged = false;

  @tracked
  showCouponField = false;

  @tracked
  upgradeMessage?: string;

  private transitionWithRouter = false;

  private finished?: DeferredPromise<void>;

  get subscribedPlan(): Plan | undefined {
    return this.auth.currentFullSubscription?.plan;
  }

  get canUpgrade(): boolean {
    return this.auth.canManageSubscription && (this.subscribedPlan?.upgradeable ?? true);
  }

  get subscription(): Subscription | undefined {
    return this.auth.currentFullSubscription;
  }

  get subscriptionSchedule(): SubscriptionSchedule | undefined {
    return this.subscription?.subscriptionSchedule;
  }

  @action
  public close(): void {
    if (!this.upgrading) {
      return;
    }

    if (this.router.currentRouteName.startsWith("authenticated.plans")) {
      void this.router.transitionTo(this.screenDoor.enforceEditOnlyMode ? "edit-only-mode" : "folders");
    }

    this.reset();

    if (this.finished) {
      this.finished.resolve(undefined);
      this.finished = undefined;
    }
  }

  private reset(): void {
    this.couponCode = undefined;
    this.showCouponField = false;
    this.upgrading = false;
    this.selectedPlan = undefined;
    this.transitionWithRouter = false;
    this.upgradeMessage = undefined;
    this.planImmediatelyChanged = false;
  }

  public open({
    transitionWithRouter = false,
    analytics
  }: {
    transitionWithRouter?: boolean;
    analytics?: object;
  } = {}): Promise<void> {
    if (analytics) {
      void this.trackEvent({ analytics });
    }

    this.upgrading = true;
    this.selectedPlan = undefined;
    this.transitionWithRouter = transitionWithRouter;

    return this.setupPromise();
  }

  private async trackEvent({ analytics, event }: { analytics: { [key: string]: any }; event?: string }): Promise<void> {
    analytics["referrer"] = this.router.currentRouteName;

    const _event =
      event ?? this.auth.currentUser?.subscribed
        ? TrackingEvents.EVENT_VIEW_INCREASE_PLANS
        : TrackingEvents.EVENT_CLICK_UPGRADE_LINK;

    await this.tracking.sendAnalytics(_event, analytics);
  }

  public async selectPlan({
    context,
    selectedPlan,
    couponCode,
    showCouponField
  }: UpgradeServiceSelectPlanArgs): Promise<void> {
    if (this.transitionWithRouter) {
      await this.router.transitionTo("authenticated.plans.plan", selectedPlan.id, {
        queryParams: { showCouponField, couponCode }
      });
    }

    if (showCouponField) {
      this.showCouponField = showCouponField;
    }

    if (couponCode) {
      this.couponCode = couponCode;
    }

    this.sendEvent(context, selectedPlan);
    this.upgrading = true;
    this.selectedPlan = selectedPlan;

    return this.setupPromise();
  }

  public async selectTopTierPlan({ context, interval }: { context: string; interval?: PlanInterval }): Promise<void> {
    const plan = await this.plans.getTopTierPlan(interval);

    if (plan) {
      await this.selectPlan({
        selectedPlan: plan,
        context
      });
    }
  }

  public async onPlanPurchased(): Promise<void> {
    await this.auth.reload();
  }

  public async purchasePlan({
    token,
    plan,
    coupon
  }: {
    token: stripe.Token;
    plan: Plan;
    coupon?: ValidatedCoupon;
  }): Promise<Subscription> {
    const { currentSubscription } = this.auth;

    let purchasedSubscription: Subscription;

    if (currentSubscription?.isTrialingPlan(plan)) {
      purchasedSubscription = await this.purchaseTrialPlan({
        token,
        plan,
        coupon
      });
    } else {
      if (currentSubscription?.trialing) {
        await this.destroyTrial();
      }

      purchasedSubscription = await this.createSubscription({
        token,
        plan,
        coupon
      });
    }

    this.trigger(UpgradeEvents.SUBSCRIPTION_PURCHASED, purchasedSubscription);

    return purchasedSubscription;
  }

  public async removeSubscriptionSchedule(subscriptionSchedule: SubscriptionSchedule): Promise<void> {
    if (subscriptionSchedule) {
      await subscriptionSchedule.destroyRecord();
    }
  }

  public async scheduleSubscription({
    plan,
    subscription
  }: {
    plan: Plan;
    subscription: Subscription;
  }): Promise<SubscriptionSchedule> {
    const phases = this.generatePhases(plan, subscription);
    const subscriptionSchedule = await subscription.subscriptionSchedule;

    if (subscriptionSchedule) {
      return this.updateSubscriptionSchedule(phases, subscriptionSchedule);
    } else {
      return this.createSubscriptionSchedule(phases, subscription);
    }
  }

  private getTimestamp(date: Date, milliseconds = 0): number {
    return (date.getTime() + milliseconds) / 1000;
  }

  private generatePhases(newPlan: Plan, subscription: Subscription): Array<Phase> {
    const { currentPeriodStart: startDate, currentPeriodEnd: endDate } = subscription;

    return [
      {
        items: [{ plan: subscription.planId }],
        startDate: this.getTimestamp(startDate),
        endDate: this.getTimestamp(endDate)
      },
      {
        items: [{ plan: newPlan.id }],
        startDate: this.getTimestamp(endDate),
        endDate: this.getTimestamp(endDate, 1000) // releases the schedule a second after second iteration starts
      }
    ];
  }

  private updateSubscriptionSchedule(
    phases: Array<Phase>,
    subscriptionSchedule: SubscriptionSchedule
  ): Promise<SubscriptionSchedule> {
    subscriptionSchedule.phases = phases;
    return subscriptionSchedule.save();
  }

  private async createSubscriptionSchedule(
    phases: Array<Phase>,
    subscription: Subscription
  ): Promise<SubscriptionSchedule> {
    return this.store
      .createRecord("subscriptionSchedule", {
        subscription: subscription,
        phases: phases
      })
      .save();
  }

  public shouldSchedule(newPlan: Plan): boolean {
    if (!this.subscribedPlan) {
      return false;
    } else if (this.subscribedPlan.isHigherTier(newPlan)) {
      return false;
    } else if (this.subscribedPlan.isSameTier(newPlan)) {
      return this.subscribedPlan.isYearly;
    } else {
      return true;
    }
  }

  private async destroyTrial(): Promise<void> {
    await this.ajax.api("/subscriptions/trials", {
      method: "DELETE"
    });
  }

  public async changeSubscriptionPlan({
    subscription,
    coupon,
    plan
  }: {
    subscription: Subscription;
    plan: Plan;
    coupon?: ValidatedCoupon;
  }): Promise<Subscription> {
    subscription.changePlan(plan);
    const subscriptionSchedule = await subscription.subscriptionSchedule;

    if (subscriptionSchedule) {
      await this.removeSubscriptionSchedule(subscriptionSchedule);
    }

    subscription.applyCoupon(coupon?.code);

    try {
      await subscription.save();
      this.planImmediatelyChanged = true;

      return subscription;
    } catch (err) {
      subscription.rollbackAttributes();
      throw err;
    }
  }

  private async createSubscription({
    token,
    plan,
    coupon
  }: {
    token: stripe.Token;
    plan: Plan;
    coupon?: ValidatedCoupon;
  }): Promise<Subscription> {
    const subscription = this.store.createRecord("subscription");

    subscription.setProperties({
      planId: plan.id,
      stripeToken: token.id,
      coupon: coupon?.code
    });

    await subscription.save();

    this.onSubscriptionCreated(subscription);

    return subscription;
  }

  private onSubscriptionCreated(subscription: Subscription): void {
    AnalyticsService.track(TrackingEvents.BITEABLE_ORDER_COMPLETED, subscription.buildOrderCompleteEvent());
  }

  private sendEvent(context: string, selectedPlan: Plan): void {
    if (this.subscribedPlan && this.selectedPlan) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_VIEW_INCREASE_PLANS, {
        ctaContext: context,
        planId: selectedPlan.id
      });
    } else {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_SELECT_SUBSCRIPTION_PLAN, {
        context,
        planId: selectedPlan.id
      });
    }
  }

  private setupPromise(): Promise<void> {
    this.finished ??= defer();

    return this.finished;
  }

  async purchaseTrialPlan({
    token,
    plan,
    coupon
  }: {
    plan: Plan;
    token?: stripe.Token;
    coupon?: ValidatedCoupon;
  }): Promise<Subscription> {
    const payload = (await this.ajax.api("/subscriptions/trials", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          attributes: {
            "plan-id": plan.id,
            "stripe-token-id": token?.id,
            coupon: coupon?.code
          }
        }
      })
    })) as IdentifiablePayload;

    if (payload.data.id) {
      this.store.pushPayload(payload);
      return this.store.peekRecord("subscription", payload.data.id)!;
    } else {
      throw Error("Subscription `id` is missing");
    }
  }
}
