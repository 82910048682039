import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { Invitation } from "client/models/team";
import type AuthService from "client/services/auth";

export default class TeamInvitationComponent extends Component {
  @service
  declare auth: AuthService;

  @tracked
  invitation: Invitation;

  teamOwnerInvitation = Invitation.TEAM_OWNER;
  teamMembersInvitation = Invitation.ALL_TEAM_MEMBERS;

  styleNamespace = getStyleNamespace("account/team/invitation");

  constructor(owner: any, args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(owner, args);
    this.invitation = this.auth.currentTeam?.preferences?.invitation ?? Invitation.ALL_TEAM_MEMBERS;
  }

  private setTeamPreferences(): void {
    if (this.auth.currentTeam && this.invitation !== this.auth.currentTeam.preferences?.invitation) {
      this.auth.currentTeam.preferences = { invitation: this.invitation };
      void this.auth.currentTeam.save();
    }
  }

  @action
  onSelectAllTeamMembers(): void {
    this.invitation = Invitation.ALL_TEAM_MEMBERS;
    this.setTeamPreferences();
  }

  @action
  onSelectTeamOwner(): void {
    this.invitation = Invitation.TEAM_OWNER;
    this.setTeamPreferences();
  }
}
