import type { Caption, Logo } from "../../scene";
import { Text } from "../../scene";
import type { TimelineFacade } from "../../timeline";
import { StrictMutation } from "./mutation";

export class DuplicateElementMutation extends StrictMutation<Logo | Text | void> {
  grouped = false;
  element?: Logo | Text;

  /**
   * @param parentCaption - The caption that the element is going to be attached to
   * @param elementId - The ID of the element that is being duplicated
   * @param sourceCaptionId - Optional: The ID of the original parent caption of the duplicated element
   */
  constructor(private parentCaption: Caption, private elementId: string, private sourceCaptionId?: string) {
    super();
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    this.element = await facade.duplicateElement(this.elementId, this.parentCaption.id);
  }

  run(): Logo | Text | void {
    if (this.element) {
      if (this.element instanceof Text) {
        this.parentCaption._appendTextElement(this.element);
      } else {
        this.parentCaption._appendLogoElement(this.element);
      }

      if (this.parentCaption.id === this.sourceCaptionId) {
        this.element._centerElement();
      }
      return this.element;
    }
  }

  revert(): void {
    if (this.element) {
      if (this.element instanceof Text) {
        this.parentCaption._removeTextElement(this.element);
      } else {
        this.parentCaption._removeLogoElement(this.element);
      }
    }
  }
}
