import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class SoundtrackService extends Service {
  @tracked
  visible = false;

  @action
  open(): void {
    this.visible = true;
  }

  @action
  close(): void {
    this.visible = false;
  }
}
