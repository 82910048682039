import type {
  Shape,
  ImageAnimations,
  RgbaColor,
  ImageConfig,
  ImageMod,
  SagConfig,
  SagLayer,
  SagLayerMode,
  SvgConfig,
  VideoConfig,
  ZymbolConfigType,
  AnimationFit
} from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import * as DomainModel from "client/lib/editor-domain-model";

export default class ZymbolConfigFactory {
  createZymbolConfig(asset: DomainModel.Asset | undefined): Partial<ZymbolConfigType> {
    if (asset instanceof DomainModel.Animation) {
      return this.createZymbolSagConfig(asset);
    } else if (asset instanceof DomainModel.VideoClip) {
      return this.createZymbolVideoConfig(asset);
    } else if (asset instanceof DomainModel.Image) {
      return this.createZymbolImageConfig(asset);
    } else if (asset instanceof DomainModel.Svg) {
      return this.createZymbolSvgConfig(asset);
    }

    return {};
  }

  getCategory(asset: DomainModel.Asset | undefined): ZymbolCategory {
    if (asset instanceof DomainModel.Animation) {
      return ZymbolCategory.SAG;
    } else if (asset instanceof DomainModel.VideoClip) {
      return ZymbolCategory.VIDEO;
    } else if (asset instanceof DomainModel.Image) {
      return ZymbolCategory.IMAGE;
    } else if (asset instanceof DomainModel.Svg) {
      return ZymbolCategory.SVG;
    }

    return ZymbolCategory.BLANK;
  }

  createZymbolSagConfig(animation: DomainModel.Animation): Partial<SagConfig> {
    const volumeProperties: Partial<SagConfig> = {};

    Object.assign(volumeProperties, {
      previewImageUrl: animation.previewImageUrl,
      previewVideoUrl: animation.previewVideoUrl,
      invertX: animation.mirror,
      animationFit: animation.objectFit,
      uid: animation.uid,
      loopable: animation.loopable,
      horizontalFlipping: animation.horizontalFlipping,
      sizingOptions: animation.sizingOptions,
      colorOptions: animation.colorOptions,
      asset: animation.originalAsset,
      animation: animation.animation,
      playbackSpeed: animation.playbackSpeed
    });

    if (animation.trim) {
      Object.assign(volumeProperties, {
        startTime: animation.trim.startOffset,
        duration: animation.trim.duration
      });
    }

    const animationColors: RgbaColor[] = [];
    const layers: SagLayer[] = [];
    const layerModes: SagLayerMode[] = [];
    for (const [i, clip] of animation.clips.entries()) {
      animationColors[i] = { rgb: clip.style.color, alpha: 1 };
      layers[i] = { url: clip.lowResVideoUrl, srcUrl: clip.id };
      layerModes[i] = clip.blendMode as SagLayerMode;
    }

    Object.assign(volumeProperties, {
      animationColors,
      layers,
      layerModes
    });

    return volumeProperties;
  }

  createZymbolVideoConfig(clip: DomainModel.VideoClip): Partial<VideoConfig> {
    const volumeProperties: Partial<VideoConfig> = {};

    Object.assign(volumeProperties, {
      hasAudio: clip.hasAudio,
      fadeIn: clip.fadeIn,
      fadeOut: clip.fadeOut,
      audioDuckingLevel: clip.audioDuckingLevel,
      mute: clip.mute,
      volume: clip.volume,
      url: clip.asset.url,
      downsizedUrlBase: clip.asset.downsizedUrlBase,
      previewImageUrl: clip.asset.previewImageUrl,
      previewVideoUrl: clip.asset.previewVideoUrl,
      lowResVideoUrl: clip.asset.lowResVideoUrl,
      encodingLevel: clip.asset.encodingLevel,
      name: clip.asset.name,
      invertX: clip.mirror,
      animationFit: clip.objectFit,
      animation: clip.animation,
      loopable: clip.loopable,
      asset: clip.originalAsset,
      license: clip.license,
      imageMod: undefined
    });

    if (clip.frame) {
      const { shape, color, scale, offsetX: x, offsetY: y, colorBrandKey } = clip.frame;
      volumeProperties["imageMod"] = {
        backingShape: shape as Shape,
        color,
        scale,
        frame: {
          x,
          y
        },
        colorBrandKey
      };
    }

    if (clip.trim) {
      Object.assign(volumeProperties, {
        startTime: clip.trim.startOffset,
        duration: clip.trim.duration
      });
    }

    return volumeProperties;
  }

  createZymbolImageConfig(image: DomainModel.Image): Partial<ImageConfig> {
    const {
      previewUrl: previewImageUrl,
      sourceUrl: url,
      name,
      opacity,
      frame,
      animation,
      mirror: invertX,
      objectFit: animationFit,
      originalAsset: asset,
      license,
      rotation
    } = image;
    const properties: Partial<ImageConfig> = {
      previewImageUrl,
      url,
      name,
      opacity: opacity ?? 1,
      animation: animation as ImageAnimations,
      animationFit: animationFit as AnimationFit,
      invertX,
      asset,
      license,
      imageMod: this.createImageMod(frame),
      rotation
    };

    return properties;
  }

  createZymbolSvgConfig(svg: DomainModel.Svg): Partial<SvgConfig> {
    const { animation, originalAsset: asset, previewUrl: previewImageUrl, sourceUrl: url, name, opacity, color } = svg;

    return {
      asset,
      animation: animation as ImageAnimations,
      previewImageUrl,
      url,
      name,
      color,
      opacity: opacity ?? 1
    };
  }

  createImageMod(frame: DomainModel.Frame | undefined): ImageMod | undefined {
    if (frame) {
      const { shape, color, scale, offsetX: x, offsetY: y, colorBrandKey } = frame;
      return {
        backingShape: shape as Shape,
        color,
        scale,
        frame: {
          x,
          y
        },
        colorBrandKey
      };
    } else {
      return undefined;
    }
  }
}
