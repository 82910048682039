import { service } from "@ember/service";
import Component from "@glimmer/component";
import type AuthService from "client/services/auth";
import type CurrentUserService from "client/services/current-user";

export default class TheImpersonationBannerComponent extends Component {
  @service
  declare auth: AuthService;

  @service
  declare currentUser: CurrentUserService;

  get impersonating(): boolean | undefined {
    return this.auth.currentAuthData.impersonating;
  }
}
