import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import unionBy from "lodash/unionBy";
import { EMOJI_FONT_FAMILY, FALLBACK_FONT_FAMILY } from "renderer-engine";
import { load } from "webfontloader";
import type AdvancedEditorService from "./advanced-editor";
import type HoneybadgerService from "./honeybadger";
import config from "client/config/environment";
import type { ManyArray } from "client/models/ember-data-types";
import type Font from "client/models/font";
import type Project from "client/models/project";
import type AuthService from "client/services/auth";

const googleFonts = config["googleFonts"];
const googleFontsUrl = `${googleFonts.urlBase}?key=${googleFonts.key}&sort=`;
const sortPopular = "popularity";
const sortAlpha = "alpha";
const popularFontsCount = 20;
const pageCount = 10;

const FONT_WEIGHT_MAPPING: { [key: string]: string } = {
  100: "Thin",
  "100normal": "Thin",
  200: "Extra Light",
  "200normal": "Extra Light",
  300: "Light",
  "300normal": "Light",
  400: "Normal",
  "400normal": "Normal",
  regular: "Normal",
  500: "Medium",
  "500normal": "Medium",
  600: "Semi Bold",
  "600normal": "Semi Bold",
  700: "Bold",
  "700normal": "Bold",
  800: "Extra Bold",
  "800normal": "Extra Bold",
  900: "Black",
  "900normal": "Black",
  "100italic": "Thin Italic",
  "200italic": "Extra Light Italic",
  "300italic": "Light Italic",
  "400italic": "Italic",
  "500italic": "Medium Italic",
  "600italic": "Semi Bold Italic",
  "700italic": "Bold Italic",
  "800italic": "Extra Bold Italic",
  "900italic": "Black Italic",
  italic: "Italic"
};

export interface GoogleWebFontMeta {
  family: string;
  variants: Array<string>;
  subsets: Array<string>;
  files: object;
}

export interface GoogleWebFontList {
  kind: string;
  items: Array<GoogleWebFontMeta>;
}

export default class FontsService extends Service {
  @tracked
  declare googleFonts: Array<Font>;

  @tracked
  declare customFonts: Array<Font>;

  @tracked
  popularFonts = new Array<Font>();

  @tracked
  searchedFonts = new Array<Font>();

  @tracked
  unknownFonts = new Set<String>();

  @service
  declare store: Store;

  @service
  declare auth: AuthService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare honeybadger: HoneybadgerService;

  @tracked
  page = 1;

  @tracked
  noSearchResults = false;

  private popularGoogleFontList: GoogleWebFontList = new Object() as GoogleWebFontList;
  private googleFontList: GoogleWebFontList = new Object() as GoogleWebFontList;

  search(searchString: string): void {
    this.noSearchResults = false;
    if (searchString.length === 0) {
      this.searchedFonts = [];
      return;
    }

    const regex = new RegExp(`^${searchString}`, "i");
    const searchResults = this.googleFontList.items.filter(({ family }) => regex.exec(family)).slice(0, 10);
    if (searchResults.length > 0) {
      this.searchedFonts = this.registerFonts(searchResults);
    } else {
      this.noSearchResults = true;
      this.searchedFonts = [];
    }
  }

  clearSearchResults(): void {
    this.searchedFonts = [];
  }

  async loadAllFonts(project?: Project): Promise<void> {
    await this.loadGoogleFonts();
    await Promise.all([this.loadPopularFonts(), this.loadCustomFonts(), this.loadBaselineFonts()]);
    if (project) {
      await Promise.all([this.loadBrandFonts(project), this.loadProjectFonts(project)]);
    }
  }

  async loadMoreFonts(): Promise<void> {
    this.page++;
    await this.loadGoogleFonts();
  }

  async loadPopularFonts(): Promise<Array<Font>> {
    const popFonts = await this.loadGoogleFontList();

    const registeredPopularFonts = this.registerFonts(popFonts.items.slice(0, popularFontsCount));
    this.popularFonts = unionBy(this.popularFonts, registeredPopularFonts, "name");

    return this.popularFonts;
  }

  async loadGoogleFonts(): Promise<Array<Font>> {
    const currentStart = (this.page - 1) * pageCount;
    const currentEnd = this.page * pageCount;
    if (this.googleFonts === undefined) {
      this.googleFonts = new Array<Font>();
    }

    const gFonts = await this.loadGoogleFontList(sortAlpha);
    const registeredFonts = this.registerFonts(gFonts.items.slice(currentStart, currentEnd));
    this.googleFonts = unionBy(this.googleFonts, registeredFonts, "name");

    return this.googleFonts;
  }

  async loadCustomFonts(): Promise<Array<Font>> {
    this.customFonts = this.customFonts ?? new Array<Font>();

    if (this.auth.currentTeam?.fonts) {
      return this.registerCustomFonts(this.auth.currentTeam.fonts);
    }
    return new Array<Font>();
  }

  async loadBrandFonts(project?: Project): Promise<void> {
    project = project ?? (await this.getProject());
    const brand = await project?.getBrandStyle();

    if (brand !== undefined) {
      await this.loadFont(brand.style.fontFamily);
    }
  }

  async loadBaselineFonts(): Promise<void> {
    load({
      custom: {
        families: [FALLBACK_FONT_FAMILY, EMOJI_FONT_FAMILY],
        urls: [this.fallbackFontUrl]
      }
    });
  }

  /**
   * This method is iterating through the project fonts.
   * This should be a small set of fonts. If this set grows to
   * an unmaintainable list, we'll need to refactor
   * @param project The ember project object
   */
  async loadProjectFonts(project: Project): Promise<void> {
    const fonts = project.zymbols
      .filter((x) => x.isText)
      .flatMap((x) => (x.isText && x.cfg.text?.content ? JSON.parse(x.cfg.text.content) : []))
      .flatMap((x) => (x.attributes?.font !== undefined ? x.attributes.font : []));

    const projectFonts = [...new Set<string>(fonts)];

    if (projectFonts.length > 20) {
      this.honeybadger.notify(`Project ${project.id} contains ${projectFonts.length} fonts.`);
    }

    await this.loadFonts(projectFonts);
  }

  /**
   * This method is currently created to provide parity to the
   * old font loading. It's not suggested to use this method as it
   * will currently iterate through the array and be slow.
   * For most scenarios, the pre-loaded fonts will have the font you
   * need. For one-off misses, load the font using loadFont().
   * @param fontFamilies String array of font family names
   */
  async loadFonts(fontFamilies: string[]): Promise<void> {
    fontFamilies.forEach(async (f) => {
      await this.loadFont(f);
    });
  }

  async loadFont(fontFamily: string): Promise<void> {
    if (this.customFonts?.find((customFont) => customFont.name === fontFamily)) {
      return;
    }

    if (this.googleFonts?.find((googleFont) => googleFont.name === fontFamily)) {
      return;
    }

    await this.loadGoogleFontList(sortAlpha);

    const newFont = this.googleFontList.items?.find((googleFont) => {
      return googleFont.family === fontFamily.split(":")[0];
    });

    if (newFont !== undefined) {
      load({ google: { families: [`${newFont.family}:${newFont.variants.join(",")}`] } });
      this.googleFonts?.push(
        this.store.createRecord("font", {
          name: newFont.family,
          variants: newFont.variants,
          subsets: ["latin"]
        })
      );
    } else {
      this.unknownFonts.add(fontFamily);
    }
  }

  getVariantNames(fontFamily: string): Array<string> {
    const font = this.find(fontFamily);
    if (font && font.variants) {
      return this.numbersToDescriptions(font.variants);
    }
    return new Array<string>();
  }

  isFontMissing(fontFamily: string): boolean {
    if (this.unknownFonts === undefined || this.unknownFonts.size === 0) {
      return false;
    }

    return this.unknownFonts?.has(fontFamily);
  }

  private async getProject(): Promise<Project | undefined> {
    return this.advancedEditor.timeline
      ? await this.store.findRecord("project", this.advancedEditor.timeline.id)
      : undefined;
  }

  private async loadGoogleFontList(sort = sortPopular): Promise<GoogleWebFontList> {
    const fontCache = sort === sortPopular ? this.popularGoogleFontList : this.googleFontList;

    if (!fontCache?.items || fontCache.items.length <= 0) {
      const fetchUrl = `${googleFontsUrl}${sort}`;
      const result = (await (await fetch(fetchUrl)).json()) as GoogleWebFontList;

      result.items = result.items.filter((f) => !f.family.includes("Material"));

      if (sort === sortPopular) {
        this.popularGoogleFontList = result;
      } else {
        this.googleFontList = result;
      }

      return result;
    }

    return fontCache;
  }

  private registerFonts(fonts: Array<GoogleWebFontMeta>): Array<Font> {
    const fontRequest = new Array<string>();
    const fontCollection = new Array<Font>();

    fonts.forEach((gwFont) => {
      if (!fontCollection.find((font) => font.name === gwFont.family)) {
        fontCollection.push(
          this.store.createRecord("font", {
            name: gwFont.family,
            variants: gwFont.variants,
            subsets: ["latin"]
          })
        );
      }
      fontRequest.push(`${gwFont.family}:${gwFont.variants.join(",")}`);
    });

    load({ google: { families: fontRequest } });

    return fontCollection;
  }

  private async registerCustomFonts(fonts: ManyArray<Font>): Promise<Array<Font>> {
    fonts.forEach((f) => {
      if (!this.customFonts.find((x) => x.name === f.name)) {
        load({
          custom: {
            families: [f.name],
            urls: [FontsService.getCustomFontUrl(f.name)]
          }
        });
        this.customFonts.push(f);
      }
    });

    return this.customFonts;
  }

  private get fallbackFontUrl(): string {
    return `${config["fontHost"]}/fonts/${FALLBACK_FONT_FAMILY}/style.css`;
  }

  private find(fontFamily: string): Font | undefined {
    const font = this.googleFonts?.find((font) => font.name === fontFamily);
    if (!font) {
      return [...this.popularFonts, ...this.searchedFonts, ...this.customFonts].find(
        (font) => font.name === fontFamily
      );
    }
    return font;
  }

  private numbersToDescriptions = (variants: string[]): string[] =>
    variants.map((variant) => this.numberToDescription(variant));

  private numberToDescription = (variant: string): string => FONT_WEIGHT_MAPPING[variant] ?? variant;

  static getCustomFontUrl(fontFamily: string): string {
    const dasherizedLowercaseFontFamily = fontFamily.toLowerCase().split(" ").join("-");
    return `${config["fontHost"]}/fonts/custom-prototype/${dasherizedLowercaseFontFamily}/style.css`;
  }
}
