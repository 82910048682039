import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import type BrandLogo from "client/lib/brand/brand-logo";
import BrandStyleConfig, { MAX_COLOR_PRESET_COUNT, MIN_COLOR_PRESET_COUNT } from "client/lib/brand/brand-style-config";
import type ColorPreset from "client/lib/brand/color-preset";
import ColorPresetGenerator from "client/lib/brand/color-preset-generator";
import type { Frame } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";
import type ConfirmService from "client/services/confirm";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type OnboardService from "client/services/onboard";
import type PermissionsService from "client/services/permissions";
import type TrackingService from "client/services/tracking";

interface BrandEditArgs {
  brandStyle: BrandStyle;
  showFetch?: boolean;
  onSave(brandStyle: BrandStyle): Promise<void>;
  onCancel(): void;
}

export default class BrandEditComponent extends Component<BrandEditArgs> {
  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare permissions: PermissionsService;

  @service
  private declare router: RouterService;

  @service
  private declare store: Store;

  @service
  private declare tracking: TrackingService;

  @service
  private declare onboard: OnboardService;

  @service
  private declare confirm: ConfirmService;

  @tracked
  loading = false;

  @tracked
  logoToEdit?: BrandLogo;

  @tracked
  fetchModal = this.args.showFetch ?? false;

  @tracked
  saving = false;

  @tracked
  validPresetsTracking = true;

  @tracked
  addLogo = false;

  @tracked
  duplicating = false;

  @tracked
  brandStyleConfig: BrandStyleConfig;

  @tracked
  declare generator: ColorPresetGenerator;

  private logoColors: Array<string> = [];

  maxPresets = BrandStyleConfig.MAX_COLOR_PRESETS;

  styleNamespace = getStyleNamespace("brand/edit");

  constructor(owner: object, args: BrandEditArgs) {
    super(owner, args);

    this.brandStyleConfig = args.brandStyle.style.clone();

    this.generator = new ColorPresetGenerator(this.brandStyleConfig);
  }

  @action
  didInsert(): void {
    void this.generateIdeas();
  }

  @action
  showFetchModal(): void {
    this.fetchModal = true;
  }

  @action
  hideFetchModal(): void {
    this.fetchModal = false;
  }

  @action
  async onBrandFetch(brandStyleConfig: BrandStyleConfig): Promise<void> {
    this.loading = true;

    try {
      this.brandStyleConfig = brandStyleConfig;

      await this.extractLogoColors();

      this.generator = new ColorPresetGenerator(brandStyleConfig);
      await this.generateIdeas();
      this.fillColorPresets();
    } finally {
      this.loading = false;
    }
  }

  @action
  async save(): Promise<void> {
    const removed = this.brandStyleConfig.removeDuplicateColorPresets();

    if (removed) {
      this.notifications.warning("A duplicate color combo was removed from your brand");
      return;
    }

    void this.saveBrand();
  }

  @action
  async cancel(): Promise<void> {
    this.args.onCancel();
  }

  @action
  async ideasInserted(): Promise<void> {
    // Wait for logo assets to load
    await this.brandStyleConfig.getDefaultLogo();
    await this.generateIdeas();

    if (this.isNewBrand) {
      this.fillColorPresets();
    }
  }

  @action
  updateBrandColor(oldColor: string, newColor: string): void {
    try {
      this.brandStyleConfig.replaceBrandColor(oldColor, newColor);

      void this.generateIdeas();
    } catch (error) {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.notifications.error(error.message);
    }
  }

  @action
  async duplicateBrand(): Promise<void> {
    this.duplicating = true;

    try {
      const duplicate = await this.args.brandStyle.duplicate();

      // This is a bit weird:
      // Route to the index then back to the edit route so that way it feels more like the brand is created.
      // Without this no route change is triggered and it doesn't really feel like the brand is properly duplicated.
      await this.router.transitionTo("authenticated.brand.index");
      await this.router.transitionTo("authenticated.brand.edit", duplicate.id);

      this.notifications.success("Your brand has been duplicated");
    } finally {
      this.duplicating = false;
    }
  }

  @action
  async removeBrandColor(color?: string): Promise<void> {
    const confirmed = await this.confirm.open({
      title: "Are you sure?",
      message: "Any combos using this color will also be deleted",
      confirmLabel: "Yes, delete"
    });

    if (confirmed) {
      this.brandStyleConfig.removeBrandColor(color);
    }

    void this.generateIdeas();
  }

  @action
  addBlankBrandColor(): void {
    this.brandStyleConfig.addBrandColor(undefined);
  }

  @action
  updateTextColorLight(color: string): void {
    this.brandStyleConfig.textColorLight = color;
  }

  @action
  updateTextColorDark(color: string): void {
    this.brandStyleConfig.textColorDark = color;
  }

  @action
  changeFontFamily(font: string): void {
    this.brandStyleConfig.setFontFamily(font);
  }

  @action
  editLogo(logo: BrandLogo): void {
    this.logoToEdit = logo;
  }

  @action
  deleteLogo(logo: BrandLogo): void {
    this.brandStyleConfig.removeLogo(logo);
    this.notifications.success("Your logo has been removed");

    if (logo.defaultLogo) {
      this.setDefaultLogo(this.getFirstLogo());
    }
  }

  @action
  setDefaultLogo(logo?: BrandLogo): void {
    if (this.defaultLogo) {
      this.defaultLogo.defaultLogo = false;
    }

    if (logo) {
      logo.defaultLogo = true;
    }
  }

  @action
  removeColorPreset(colorPreset: ColorPreset): void {
    this.brandStyleConfig.removeColorPreset(colorPreset);
  }

  @action
  async addColorPreset(): Promise<void> {
    const colorPreset = this.generator.getUnusedColorPreset();

    if (colorPreset) {
      this.brandStyleConfig.addColorPreset(colorPreset);
    }
  }

  @action
  shuffleColorPreset(oldColorPreset: ColorPreset): void {
    const newColorPreset = this.generator.getUnusedColorPreset();

    if (newColorPreset) {
      this.brandStyleConfig.switchColorPreset(oldColorPreset, newColorPreset);
    }
  }

  @action
  addBlankColorPreset(): void {
    this.brandStyleConfig.addBlankColorPreset();
  }

  @action
  cancelEditLogo(): void {
    this.logoToEdit = undefined;
  }

  @action
  updateLogoFrame(logo: BrandLogo, frame: Frame): void {
    logo.updateFrame(frame);
  }

  @action
  addLogoToBrand(logo: BrandLogo): void {
    this.brandStyleConfig.addDefaultLogo(logo);
    void this.extractLogoColors();
  }

  @action
  showAddLogo(): void {
    this.addLogo = true;
  }

  @action
  hideAddLogo(): void {
    this.addLogo = false;
  }

  get emptyColorPresets(): boolean {
    return this.generator.unusedColorPresets.length === 0;
  }

  get saveButtonLabel(): string {
    if (!this.isOnboarding) {
      return "Save brand";
    } else {
      return "Continue";
    }
  }

  get isOnboarding(): boolean {
    return this.onboard.onboarding;
  }

  get isNewBrand(): boolean {
    return !!this.args.brandStyle.isNew;
  }
  get disabled(): boolean {
    return this.brandStyleInvalid || this.saving;
  }

  get insufficientColorPresets(): boolean {
    return !this.loading && this.brandStyleConfig.colorPresets.length < MIN_COLOR_PRESET_COUNT;
  }

  get showDuplicate(): boolean {
    return this.permissions.has("feature_multiple_brands") && !this.args.brandStyle.isNew;
  }

  private get brandStyleInvalid(): boolean {
    if (this.brandStyleConfig && this.validPresetsTracking) {
      return this.brandStyleConfig.validColorPresets.length < MIN_COLOR_PRESET_COUNT;
    }

    return true;
  }

  private get defaultLogo(): BrandLogo | undefined {
    return this.brandStyleConfig.defaultLogo;
  }

  private get colorComboSlotsFilled(): number {
    return this.brandStyleConfig.colorPresets.length;
  }

  private get colorComboSlotsFilledContrastInvalid(): number {
    return this.brandStyleConfig.colorPresets.filter((colorPreset) => colorPreset.insufficientContrast).length;
  }

  private async generateIdeas(): Promise<void> {
    await this.generator.generate();
  }

  private getFirstLogo(): BrandLogo | undefined {
    return this.brandStyleConfig.logos[0];
  }

  private async saveBrand(): Promise<void> {
    const [error] = this.brandStyleConfig.validate();

    if (error) {
      this.notifications.error(error.message);

      return;
    }

    this.saving = true;

    try {
      await this.brandStyleConfig.saveLogoUserAssets(this);

      this.args.brandStyle.style = this.brandStyleConfig;

      await this.args.brandStyle.save();

      await this.trackBrandSave();
      this.notifications.success("Brand saved");

      await this.args.onSave(this.args.brandStyle);
    } catch {
      this.notifications.error("There was a problem saving this brand");
    } finally {
      this.saving = false;
    }
  }

  private async trackBrandSave(): Promise<void> {
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_BRAND_SAVE, {
      colorComboSlotsFilled: this.colorComboSlotsFilled,
      colorComboSlotsFilledInvalid: this.colorComboSlotsFilledContrastInvalid,
      colorComboSlotsAvailable: BrandStyleConfig.MAX_COLOR_PRESETS - this.colorComboSlotsFilled
    });
  }

  private async extractLogoColors(): Promise<void> {
    const logo = await this.brandStyleConfig.getDefaultLogo();

    if (logo) {
      this.removeLogoColors();
      const logoColors = await logo.getLogoColors();

      this.addLogoColors(logoColors);
    }
  }

  private removeLogoColors(): void {
    this.logoColors.forEach((color) => {
      this.brandStyleConfig.removeBrandColor(color);
    });

    this.logoColors = [];
  }

  private fillColorPresets(): void {
    const colorPresets = this.generator.getUnusedColorPresets(MAX_COLOR_PRESET_COUNT);

    this.brandStyleConfig.addColorPresets(colorPresets);
  }

  private addLogoColors(logoColors: Array<string>): void {
    // Set the logo colors so we know what to remove if the user switches the "default" logo
    this.logoColors = logoColors;

    const addedColors = this.brandStyleConfig.addDistinctColors(logoColors);

    if (addedColors.length) {
      void this.generateIdeas();
    }
  }
}
