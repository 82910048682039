import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import { Crisp } from "crisp-sdk-web";
import type HoneybadgerService from "./honeybadger";
import TrackingEvents, { CTAContext } from "client/events";
import { isProdEnv } from "client/lib/env";
import AnalyticsEvent from "client/models/analytics-event";
import type User from "client/models/user";
import type AjaxService from "client/services/ajax";
import AnalyticsService from "client/services/analytics";
import type AuthService from "client/services/auth";
import type CurrentUserService from "client/services/current-user";
import type MobileService from "client/services/mobile";
import OpenReplayTracker from "client/services/open-replay-tracker";

export default class TrackingService extends Service {
  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare mobile: MobileService;

  get impersonating(): boolean | undefined {
    return this.auth.currentAuthData.impersonating;
  }

  private analyticsTraits(user: User): { [key: string]: string | undefined } {
    return {
      email: user?.email,
      full_name: user?.fullName, // eslint-disable-line camelcase
      name: user?.fullName
    };
  }

  identify(user: User): void {
    if (AnalyticsService.disabled) {
      return;
    }

    if (!user) {
      this.honeybadger.notify("Failed to identify user. `user` is undefined", "TrackingService");
      return;
    }

    if (!this.impersonating) {
      this.identifyToCrisp(user);
      this.identifyToCustomerIo(user);
      this.identifyToOpenReplay(user);
    }

    const { id: userId } = user;
    try {
      const traits = this.analyticsTraits(user);
      traits["last_login"] = new Date().toISOString();
      AnalyticsService.identify(userId, traits);
    } catch {
      // eslint-disable-next-line no-empty
    }
  }

  async sendAnalytics(
    event: TrackingEvents | string,
    propertiesOverrides = {},
    integrationsOverrides = {}
  ): Promise<void> {
    if (AnalyticsService.disabled) {
      return;
    }

    const user = this.currentUser.user;
    const properties: { [key: string]: any } = {
      date: new Date().toISOString(),
      ...propertiesOverrides
    };

    if (user) {
      properties["email"] = user.email;
      properties["teamId"] = (await user.team)?.id;
    }

    properties["device"] = this.mobile.isMobile ? "mobile" : "desktop";

    const integrations = { ...integrationsOverrides };

    try {
      const analyticsEvent = new AnalyticsEvent({ event, properties, integrations });
      const body = analyticsEvent.toJsonApi();
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json"
      };

      await this.ajax.api(`/analytics`, { method: "POST", body, headers }, "text");
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err, "TrackingService");
    }
  }

  /**
   * @deprecated use upgrade service "upgrade.trackEvent()" or "upgrade.open()" instead. This method does not use the right
   * context and asks for the "subscribed" parameter. The upgrade service internally checks for "subscribed" and encourages passing a
   * context. The context tells us where the upgrade was initiated from. This methods claims it was initiated from a banner.
   * We need to use the correct CTA context or in the future create a proper default CTA context in the upgrade service
   * @param subscribed boolean is a user subscribed
   */
  trackUpgradeEvent(subscribed = false): void {
    const eventName = subscribed ? TrackingEvents.EVENT_VIEW_INCREASE_PLANS : TrackingEvents.EVENT_CLICK_UPGRADE_LINK;

    const ctaContext = subscribed
      ? CTAContext.CTA_CONTEXT_BUTTON_BANNER_UPGRADE_NOW
      : CTAContext.CTA_CONTEXT_BUTTON_BANNER_SUBSCRIBE;

    void this.sendAnalytics(eventName, {
      referrer: this.router.currentRouteName,
      cta_context: ctaContext // eslint-disable-line camelcase
    });
  }

  identifyToCrisp(user: User): void {
    Crisp.user.setNickname(user.fullName);
    Crisp.user.setEmail(user.email);
    Crisp.session.setData({
      user_id: user.id || "", // eslint-disable-line camelcase
      customer_id: user.stripeCustomerId || "" // eslint-disable-line camelcase
    });
  }

  identifyToCustomerIo(user: User): void {
    const cio = (window as any)._cio;

    if (!cio) {
      return;
    }

    cio.identify({
      id: user.id,
      created_at_unix_epoch: Math.floor(user.createdAt.getTime() / 1000), // eslint-disable-line camelcase
      ...this.analyticsTraits(user)
    });

    void this.ajax.api("/identify", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      }
    });
  }

  identifyToOpenReplay(user: User): void {
    if (isProdEnv()) {
      OpenReplayTracker.tracker.setUserID(user.email);
    }
  }
}
