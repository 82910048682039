import Model, { attr, belongsTo } from "@ember-data/model";
import type Favorite from "client/models/favorite";
import type Team from "client/models/team";

export default class TeamFavorite extends Model {
  @attr("string")
  declare favorableType: string;

  @attr("string")
  declare favorableProviderAssetId: string;

  @attr("string")
  declare creatorId: string;

  @belongsTo("favorite", { async: false, inverse: "teamFavorite" })
  declare favorite: Favorite;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("team", { async: true, inverse: null })
  declare team: Team;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    teamFavorite: TeamFavorite;
  }
}
