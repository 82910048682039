import { getOwner } from "@ember/application";
import Model from "@ember-data/model";
import type Favorite from "./favorite";
import type TeamFavorite from "client/models/team-favorite";
import type FavoritesService from "client/services/favorites";
import type NotificationsService from "client/services/notifications";

export enum FAVORABLE_TYPES {
  PROJECT_SCENES = "project-scenes",
  UNSPLASH = "unsplash-photos",
  PEXELS = "pexels-photos",
  PEXELS_VIDEOS = "pexels-videos",
  SHUTTERSTOCK = "shutterstock-footages",
  SAGS = "sags",
  SVGS = "svgs",
  VIDEO_BLOCKS = "video-blocks"
}

export interface FavoriteMetadata {
  [key: string]: any;
}

export const SCENE_FAVORITING_FAILURE = "There was a problem adding this scene to your favorites";

interface Favorable {
  readonly favorableProviderAssetId: string;
  readonly favorableType: string;

  favorite(team?: boolean): Promise<Favorite>;

  unfavorite(): Promise<void>;

  isCreator(userId: string): boolean;

  favoriteRecord?: Favorite;
  favorited: boolean;
  teamFavorited: boolean;
  metadataForFavorite: FavoriteMetadata;
}

abstract class Favorable extends Model {
  static trueFor(model: any): model is Favorable {
    return "favorableType" in model && "favorableProviderAssetId" in model;
  }
}

export default Favorable;

export function favorable<
  T extends {
    new (...args: any[]): Model & Pick<Favorable, "favorableProviderAssetId" | "favorableType" | "metadataForFavorite">;
  }
>(constructor: T): { new (...args: any[]): Favorable } & T {
  return class extends constructor implements Favorable {
    get favorites(): FavoritesService {
      return getOwner(this).lookup("service:favorites") as FavoritesService;
    }

    get favorited(): boolean {
      return !!this.favoriteRecord;
    }

    get teamFavorited(): boolean {
      return this.favorited && !!this.favoriteRecord?.teamFavorite;
    }

    get notifications(): NotificationsService {
      return getOwner(this).lookup("service:notifications") as NotificationsService;
    }

    get favoriteRecord(): Favorite | undefined {
      return this.favorites.find(this);
    }

    isCreator(userId: string): boolean {
      return this.favoriteRecord?.teamFavorite?.creatorId === userId;
    }

    async favorite(team = false): Promise<Favorite> {
      try {
        const model = team ? "teamFavorite" : "favorite";
        const object = await this.store
          .createRecord(model, {
            favorableType: this.favorableType,
            favorableProviderAssetId: this.favorableProviderAssetId,
            metadata: this.metadataForFavorite
          })
          .save();

        const favorite = team ? (object as TeamFavorite).favorite : (object as Favorite);
        return favorite;
      } catch (err) {
        this.notifications.error(SCENE_FAVORITING_FAILURE);
        throw err;
      }
    }

    async unfavorite(): Promise<void> {
      await this.favoriteRecord?.unfavorite();
    }
  };
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    favorable: Favorable;
  }
}
