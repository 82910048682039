import type { Attributor, Blot, StyleAttributor, Registry } from "parchment";
import Quill from "quill";
import OriginalBlock from "quill/blots/block";
import OriginalListItem, { ListContainer as OriginalListContainer } from "quill/formats/list";
import { FontStyles, TextTransform, FALLBACK_FONT_STRING, DEFAULT_LINE_HEIGHT } from "renderer-engine";
import { rgbaToHex } from "client/lib/rgb-hex";
import { stripQuotes } from "client/lib/text/fonts";

const Parchment = Quill.import("parchment");

export const LINE_MARK = "data-line";

class FallbackFontAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("font", "font-family", { scope: Parchment.Scope.BLOCK_ATTRIBUTE });
  }

  add(node: HTMLElement, value: any): boolean {
    return super.add(node, `"${value}"` + FALLBACK_FONT_STRING);
  }

  canAdd(node: HTMLElement, value: any): boolean {
    return super.canAdd(node, value.replace(FALLBACK_FONT_STRING, ""));
  }

  value(node: HTMLElement): string | null {
    const value = super.value(node);

    if (typeof value === "string") {
      return stripQuotes(value.replace(FALLBACK_FONT_STRING, ""));
    }

    return value;
  }
}

class ColorAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("color", "color", { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }

  value(node: HTMLElement): string | null {
    const value = super.value(node);

    if (typeof value === "string" && value.startsWith("rgb")) {
      return rgbaToHex(value);
    }

    return value;
  }
}

class WeightAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("weight", "font-weight", {
      scope: Parchment.Scope.INLINE_ATTRIBUTE,
      whitelist: ["100", "200", "300", "400", "500", "600", "700", "800", "900"]
    });
  }
}

class CapitalizeAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("capitalize", "text-transform", {
      scope: Parchment.Scope.INLINE_ATTRIBUTE,
      whitelist: Object.values(TextTransform)
    });
  }
}

class MarkLinesAttributor extends Parchment.Attributor {
  constructor() {
    super("line", LINE_MARK, { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }
}

class LineHeightAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("lineHeight", "line-height", { scope: Parchment.Scope.BLOCK_ATTRIBUTE });
  }
}

class TextAlignAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("textAlign", "text-align", {
      scope: Parchment.Scope.BLOCK_ATTRIBUTE,
      whitelist: ["left", "center", "right"]
    });
  }
}

class WhiteSpaceAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("whiteSpace", "white-space", { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }
}

class PaddingAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("padding", "padding", { scope: Parchment.Scope.BLOCK_ATTRIBUTE });
  }
}

class BrandColorKeyAttributor extends Parchment.Attributor {
  constructor() {
    super("colorBrandKey", "color-brand-key", { scope: Parchment.Scope.INLINE_ATTRIBUTE });
  }
}

class FontStyleAttributor extends Parchment.StyleAttributor {
  constructor() {
    super("fontStyle", "font-style", {
      scope: Parchment.Scope.INLINE_ATTRIBUTE,
      whitelist: [FontStyles.NORMAL, FontStyles.ITALIC]
    });
  }
}

class Block extends OriginalBlock {
  static create(): HTMLElement {
    const node = super.create();
    node.setAttribute(
      "style",
      `-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; position: relative; margin: 0px; line-height: ${DEFAULT_LINE_HEIGHT};`
    );
    return node;
  }
}

Block["blotName"] = "block";
Block["tagName"] = "p";
Block["defaultChild"] = OriginalBlock["defaultChild"];
Block["allowedChildren"] = OriginalBlock["allowedChildren"];

class ListContainer extends OriginalListContainer {
  static tagName = ["OL", "UL"];
  static defaultTag = "OL";

  static create(value: string): HTMLElement {
    return document.createElement(this.getTag(value));
  }

  static getTag(val: string): string {
    const map: { [key: string]: string } = {
      bullet: "UL",
      ordered: "OL"
    };
    return map[val] || this.defaultTag;
  }

  checkMerge(): boolean {
    return super.checkMerge() && this.domNode.tagName === this.next?.domNode.tagName;
  }
}

class ListItem extends OriginalListItem {
  static requiredContainer = ListContainer;

  static register(): void {
    Quill.register(OriginalListContainer, true);
  }

  get statics(): Blot["statics"] {
    return super.statics;
  }

  optimize(context: { [key: string]: any }): void {
    if (this.statics.requiredContainer && !(this.parent instanceof this.statics.requiredContainer)) {
      this.wrap(this.statics.requiredContainer.blotName, ListItem.formats(this.domNode));
    }
    super.optimize(context);
  }

  format(name: string, value: string): void {
    if (this.statics.requiredContainer && name === ListItem.blotName && value !== ListItem.formats(this.domNode)) {
      this.wrap(this.statics.requiredContainer.blotName, value);
    }
    super.format(name, value);
  }
}

const Scroll = Quill.import("blots/scroll") as Attributor;
const Break = Quill.import("blots/break") as Attributor;
const Cursor = Quill.import("blots/cursor") as Attributor;
const Inline = Quill.import("blots/inline") as Attributor;
const Text = Quill.import("blots/text") as Attributor;

export const registerStyles = (plain: boolean): Registry => {
  const registry = new Parchment.Registry();

  const optional = (attributor: StyleAttributor): Attributor => {
    if (plain) {
      return new Parchment.Attributor(attributor.attrName, "hidden-format-" + attributor.keyName, {
        scope: attributor.scope,
        whitelist: attributor.whitelist
      });
    }
    return attributor;
  };

  const Weight = new WeightAttributor();
  const Color = new ColorAttributor();
  const Font = new FallbackFontAttributor();
  const Capitalize = new CapitalizeAttributor();
  const FontStyle = new FontStyleAttributor();
  const MarkLines = new MarkLinesAttributor();
  const LineHeight = new LineHeightAttributor();
  const TextAlign = new TextAlignAttributor();
  const WhiteSpace = new WhiteSpaceAttributor();
  const Padding = new PaddingAttributor();
  const BrandColorKey = new BrandColorKeyAttributor();

  registry.register(
    Scroll,
    Break,
    Cursor,
    Inline,
    Text,
    Weight,
    FontStyle,
    BrandColorKey,
    optional(Color),
    optional(Font),
    optional(Capitalize),
    optional(MarkLines),
    optional(LineHeight),
    optional(TextAlign),
    optional(WhiteSpace),
    optional(Padding),
    Block,
    ListItem,
    ListContainer
  );

  Quill.debug("error");

  return registry;
};
