import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { type RectInfo } from "moveable/declaration/types";
import type { Bounds, Size } from "renderer-engine";
import type { EventRegister } from "client/lib/editor-domain-model";
import { convertRect, intersection, toCoOrdinates, unitGeometry } from "client/lib/geometry";
import getStyleNamespace from "client/lib/get-style-namespace";
import { clamp } from "client/lib/quick-math";
import { transaction } from "client/lib/transaction";
import type Layer from "client/models/zymbols/layer-order";

interface Args {
  elementBounds: Bounds;
  canvasBounds: Size;
  boundingBox?: RectInfo | undefined;
  eventRegister: EventRegister;
  layer: Layer;
  canDuplicate?: boolean;
  hasRemovableBackground?: boolean;

  onRemove(): void;
  onDuplicate(): void;
  onOpenRightClickMenu(e: PointerEvent): void;
}

export default class CanvasActionsComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("canvas/actions");

  @action
  @transaction
  async onMoveLayerToFront(): Promise<void> {
    await this.args.layer?.moveLayerToFront();
  }

  @action
  @transaction
  async onMoveLayerToBack(): Promise<void> {
    await this.args.layer?.moveLayerToBack();
  }

  @action
  @transaction
  async onMoveLayerBackward(): Promise<void> {
    await this.args.layer?.moveLayerBackward();
  }

  @action
  @transaction
  async onMoveLayerForward(): Promise<void> {
    await this.args.layer?.moveLayerForward();
  }

  @action
  onRemove(): void {
    this.args.onRemove?.();
  }

  @action
  async onDuplicate(): Promise<void> {
    this.args.onDuplicate?.();
  }

  @action
  async onDuplicateShortcut(event: Event): Promise<void> {
    event.preventDefault();

    if (this.args.canDuplicate) {
      await this.onDuplicate();
    }
  }

  @action
  async onMoveLayerBackwardShortcut(event: Event): Promise<void> {
    event.preventDefault();

    if (this.canLayer && !this.lowestLayer) {
      await this.onMoveLayerBackward();
    }
  }

  @action
  async onMoveLayerForwardShortcut(event: Event): Promise<void> {
    event.preventDefault();

    if (this.canLayer && !this.highestLayer) {
      await this.onMoveLayerForward();
    }
  }

  // Layers are moveable unless specified otherwise.
  get canLayer(): boolean {
    return !!this.args.layer;
  }

  get canRemove(): boolean {
    if (this.args.hasRemovableBackground !== undefined) {
      return this.args.hasRemovableBackground;
    } else {
      return !!this.args.onRemove;
    }
  }

  get highestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.highestLayer();
    }
    return false;
  }

  get lowestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.lowestLayer();
    }
    return false;
  }

  get actionsPosition(): SafeString {
    const [, topClip, rightClip] = this.clippedElementBounds;
    const buttonHeightPx = 40;

    const canvasWidth = this.args.canvasBounds.width;
    const canvasHeight = this.args.canvasBounds.height;

    const elementHeightPx = this.args.elementBounds.height * canvasHeight;
    const elementWidthPx = this.args.elementBounds.width * canvasWidth;

    const clippedElementHeightPx = topClip * elementHeightPx;
    // Percentage of the height of the action buttons to shift downwards. Responsible for keeping the buttons on the
    // canvas bounds, even if the top of the element is off canvas. -100% is above the bounds, 0 is below bounds
    // Defaults to -100 if the element height/width is missing
    const offsetPercentage =
      canvasHeight === 0 ? -100 : clamp((clippedElementHeightPx / buttonHeightPx) * 100, -100, 0);
    const leftOffsetPx = (1 - Math.max(rightClip, 0)) * elementWidthPx;
    const topOffsetPx = (topClip >= 0 ? topClip : 0) * elementHeightPx;

    if (this.args.boundingBox?.rotation) {
      return this.actionsPositionOnRotation(
        this.args.boundingBox.rotation,
        leftOffsetPx,
        topOffsetPx,
        offsetPercentage
      );
    } else {
      return htmlSafe(
        `top: 0%; transform: translate(calc(-100% - ${leftOffsetPx}px), calc(${offsetPercentage}% + ${
          topOffsetPx - 10
        }px));`
      );
    }
  }

  private get clippedElementBounds(): [number, number, number, number] {
    const { x, width } = this.args.elementBounds;
    const clippedBounds = intersection({ x, width, y: 0, height: 1 }, unitGeometry);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return toCoOrdinates(convertRect(clippedBounds, this.args.elementBounds, unitGeometry));
  }

  private actionsPositionOnRotation(
    rotation: number,
    leftOffsetPx: number,
    topOffsetPx: number,
    offsetPercentage: number
  ): SafeString {
    const TOP_OFFSET_BUFFER = 20;
    const DEFAULT_POSITION = `
      top: 0%;
      transform: translate(calc(-100% - ${leftOffsetPx}px),
        calc(${offsetPercentage}% + ${topOffsetPx - 10}px
        )
      );
    `;
    const TOP_OFFSET = `
      top: 0%;
      transform: translate(calc(-100% - ${leftOffsetPx}px),
        calc(${offsetPercentage}% + ${topOffsetPx - 30}px
        )
      );
    `;
    const RIGHT_OFFSET = `
      top: 0%;
      transform: translate(calc(-100% - ${leftOffsetPx - 130}px),
        calc(${offsetPercentage}% + ${topOffsetPx - TOP_OFFSET_BUFFER}px
        )
      );
    `;
    const LEFT_OFFSET = `
      top: 0%;
      transform: translate(calc(-100% - ${leftOffsetPx + 160}px),
        calc(${offsetPercentage}% + ${topOffsetPx - TOP_OFFSET_BUFFER}px
        )
      );
    `;

    if ((rotation >= 0 && rotation < 45) || (rotation >= 315 && rotation <= 360)) {
      return htmlSafe(DEFAULT_POSITION);
    } else if ((rotation >= 45 && rotation < 90) || (rotation >= 110 && rotation < 155)) {
      return htmlSafe(RIGHT_OFFSET);
    } else if (
      (rotation >= 90 && rotation < 110) ||
      (rotation >= 155 && rotation < 205) ||
      (rotation >= 250 && rotation < 270)
    ) {
      return htmlSafe(TOP_OFFSET);
    } else if ((rotation >= 270 && rotation < 315) || (rotation >= 205 && rotation < 250)) {
      return htmlSafe(LEFT_OFFSET);
    }

    return htmlSafe(DEFAULT_POSITION);
  }
}
