import { attr } from "@ember-data/model";
import type { ImageConfig, Asset } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import TrackingEvents from "client/events";
import { favorable, FAVORABLE_TYPES } from "client/models/favorable";
import SelectableMediaAsset from "client/models/selectable-media-asset";

@favorable
export default class PexelsPhoto extends SelectableMediaAsset {
  @attr("string")
  declare description: string;

  @attr("string")
  declare downloadUrl: string;

  @attr("number")
  declare height: number;

  @attr("string")
  declare thumbImageUrl: string;

  @attr("json")
  declare attribution: object;

  @attr("number")
  declare width: number;

  readonly zymbolCategory = ZymbolCategory.IMAGE;

  readonly thumbVideoUrl = undefined;

  get favorableProviderAssetId(): string {
    return this.id;
  }

  get trackUsageData(): object {
    return {
      provider: "pexels",
      providerAssetId: this.id,
      type: "image",
      name: this.description
    };
  }

  protected get assetHeight(): number {
    return this.height;
  }

  protected get assetWidth(): number {
    return this.width;
  }

  asZymbolConfig(): Partial<ImageConfig> & { asset: Asset } {
    const { id, thumbImageUrl, downloadUrl, description: name } = this;

    return {
      asset: {
        id,
        type: "pexels-photo"
      },
      previewImageUrl: thumbImageUrl,
      url: downloadUrl,
      name
    };
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_STOCK_CONTENT;

  readonly favorableType = FAVORABLE_TYPES.PEXELS;

  metadataForFavorite = {};
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    pexelsPhoto: PexelsPhoto;
  }
}
