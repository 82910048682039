import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type User from "client/models/user";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type ProjectTemplateSettingsModalService from "client/services/project-template-settings-modal";
import { PROJECT_SETTINGS_UPDATE_FAILURE_NOTIFICATION } from "client/services/projects";

export default class ProjectTemplateSettingsComponent extends Component {
  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare projectTemplateSettingsModal: ProjectTemplateSettingsModalService;

  @tracked
  saving = false;

  checkboxId = guidFor(this);
  styleNamespace = getStyleNamespace("project-template-settings");

  get modalElement(): HTMLElement | null {
    return document.getElementById("modal");
  }

  get isOpen(): boolean {
    return this.projectTemplateSettingsModal.visible;
  }

  get canPublishContent(): boolean {
    return this.permissions.has("publish_template_content");
  }

  get canToggleTemplate(): boolean {
    return this.projectTemplateSettingsModal.canToggleTemplate;
  }

  get disabled(): boolean {
    return !this.canToggleTemplate;
  }

  get projectOwner(): User {
    return this.projectTemplateSettingsModal.projectOwner;
  }

  get template(): boolean {
    return this.projectTemplateSettingsModal.template;
  }

  set template(template: boolean) {
    this.projectTemplateSettingsModal.template = template;
  }

  @action
  async save(): Promise<void> {
    try {
      this.saving = true;
      await this.projectTemplateSettingsModal.save();
      this.notifications.success("Template settings updated");
      this.projectTemplateSettingsModal.close();
    } catch (err) {
      this.notifications.error(PROJECT_SETTINGS_UPDATE_FAILURE_NOTIFICATION);
      throw err;
    } finally {
      this.saving = false;
    }
  }

  @action
  cancel(): void {
    return this.projectTemplateSettingsModal.close();
  }

  @action
  toggleTemplate(): void {
    this.projectTemplateSettingsModal.toggleTemplate();
  }
}
