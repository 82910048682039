import config from "client/config/environment";
import MessageBus from "message-bus-client";

const initialize = (): void => {
  MessageBus.ajax = (options: any): void => {
    const XHRImpl = (MessageBus && MessageBus.xhrImplementation) || XMLHttpRequest;
    const xhr = new XHRImpl();
    xhr.dataType = options.dataType;
    xhr.open("POST", options.url);
    for (const name in options.headers) {
      xhr.setRequestHeader(name, options.headers[name]);
    }
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    if (options.messageBus.chunked) {
      options.messageBus.onProgressListener(xhr);
    }
    xhr.onreadystatechange = (): void => {
      if (xhr.readyState === 4) {
        const status = xhr.status;
        if ((status >= 200 && status < 300) || status === 304) {
          options.success(xhr.responseText);
        } else {
          options.error(xhr, xhr.statusText);
        }
        options.complete();
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    xhr.send(new URLSearchParams(options.data).toString());
    return xhr;
  };

  MessageBus.baseUrl = `${config.apiUrl}/`;
  MessageBus.start();
};

export default {
  initialize
};
