import { StrictMutation } from "./mutation";

type Options = {
  suppressWarning?: boolean;
};

export const SUPPRESS_WARNING = { suppressWarning: true };

type Change<T> = {
  key: keyof T;
  value: any;
};

export abstract class VanillaMutation<T extends Object, U = any> extends StrictMutation<U> {
  grouped = false;
  readonly undoable = true;

  private undoChanges: Change<T>[] = [];
  private result?: U;

  constructor(private target: T, private options?: Options) {
    super();
  }

  abstract apply(target: T): U;

  run(): U {
    if (this.undoChanges.length) {
      // We don't re-record changes because with the coalescing, we'll record
      // different values. This shouldn't be an issue, but it's safer no to
      // mess up the undo state
      this.result = this.apply(this.target);
    } else {
      this.undoChanges = [];
      this.result = this.apply(new Proxy(this.target, new Handler(this.undoChanges)));
      if (!this.undoChanges.length) {
        // Tip: double check that you're applying changes to the proxy object passed to apply
        // rather than directly
        if (!this.options?.suppressWarning) {
          console.log("No changes applied", this.target);
        }
      }
    }
    return this.result;
  }

  revert(): U {
    this.undoChanges.reverse().forEach((c) => (this.target[c.key] = c.value));
    return this.result!;
  }

  isSupercededBy(other: StrictMutation<any>): boolean {
    // Deduplicating mutations prevents us from propagating large numbers of
    // events on the same value. This would normally happen for text edits and
    // drag events where we make put multiple changes together in a single
    // transaction.
    return (
      other instanceof VanillaMutation &&
      this.target === other.target &&
      this.undoChanges.every((thisChange) =>
        other.undoChanges.find((otherChange) => thisChange.key === otherChange.key)
      )
    );
  }
}

export class InlineMutation<T extends Object> extends VanillaMutation<T> {
  constructor(target: T, private f: (target: T) => void, options?: Options) {
    super(target, options);
  }

  apply(target: T): void {
    this.f(target);
  }
}

class Handler<T extends Object> implements ProxyHandler<T> {
  constructor(private undoChanges: Change<T>[]) {}

  get(target: T, p: string | symbol, _receiver: any): any {
    return target[p as keyof T];
  }

  set(target: T, p: string | symbol, value: any, _receiver: any): boolean {
    const key = p as keyof T;

    this.undoChanges.push({ key, value: target[key] });

    target[key] = value;
    return true;
  }
}
