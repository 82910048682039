import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type { ProjectSceneWatermarkRouteModel } from "../route";

export default class ProjectSceneWatermarkTrimController extends Controller<ProjectSceneWatermarkRouteModel> {
  @service
  declare router: RouterService;

  @action
  onCancel(): void {
    void this.router.transitionTo("authenticated.project.scene.watermark");
  }
}
