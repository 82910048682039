import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ContentTypeOption } from "../../../search/component";
import type { ContentQueryParams } from "../../base/component";
import TrackingEvents from "client/events";
import { ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type SelectableAsset from "client/models/selectable-asset";
import { ContentType } from "client/services/contents-curator";
import type TrackingService from "client/services/tracking";

interface Args {
  query: string;
  type: ContentTypeOption;
}

const DEFAULT_PER_PAGE = 25;

export default class ContentSearchIndividualComponent extends Component<Args> {
  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @tracked
  emptyResults = false;

  @action
  didUpdateParams(): void {
    this.emptyResults = false;
  }

  @action
  async loadResults(params: ContentQueryParams): Promise<ArrayProxy<SelectableAsset>> {
    // eslint-disable-next-line camelcase
    const { page, per_page, query } = params;

    const results = await this.store.query(
      this.contentSearchType === ContentType.GRAPHICS ? "sag" : this.contentSearchType,
      {
        // eslint-disable-next-line camelcase
        per_page,
        page,
        [this.searchKey]: query
      }
    );

    // @ts-expect-error
    const totalCount = results.meta["total-count"] as number;

    this.emptyResults = totalCount === 0;

    this.track(page, query, totalCount);

    return results;
  }

  get params(): ContentQueryParams {
    return {
      // eslint-disable-next-line camelcase
      per_page: DEFAULT_PER_PAGE,
      query: this.args.query,
      type: this.args.type
    };
  }

  private track(page: number | undefined, query: string | undefined, total: number): void {
    const trackingHelper = this.createTrackingHelper(query, total, page);

    if (page === 1) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_SEARCH_CONDUCTED, trackingHelper);
    } else {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_DISCOVER_LOAD_MORE, trackingHelper);
    }
  }

  private createTrackingHelper(
    query: string | undefined,
    total: number,
    page: number | undefined
  ): ContentTrackingHelper {
    return new ContentTrackingHelper(
      {
        search: query,
        searchFrom: SEARCH_LOCATION.CONTENT_DISCOVERY,
        pageNumber: page
      },
      this.args.type.label,
      total
    );
  }

  private get contentSearchType(): ContentType {
    return this.args.type.values[0]!;
  }

  private get searchKey(): string {
    let queryKey = "query";

    if (this.contentSearchType === ContentType.GRAPHICS) {
      queryKey = "search";
    } else if (this.contentSearchType === ContentType.PROJECT_SCENE) {
      queryKey = "search_query";
    }

    return queryKey;
  }
}
