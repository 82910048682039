import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type StartTrialOrUpgradeService from "client/services/start-trial-or-upgrade";

export default class AppHeaderUpgradeLinkComponent extends Component {
  @service
  declare startTrialOrUpgrade: StartTrialOrUpgradeService;

  styleNamespace = getStyleNamespace("app-header/upgrade-link");

  @action
  onClick(): void {
    this.startTrialOrUpgrade.start("link - nav-header - upgrade");
  }
}
