import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { ProjectScenesRouteModel } from "client/authenticated/project/scenes/route";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type UserAsset from "client/models/user-asset";
import type FoldersService from "client/services/folders";
import { DEFAULT_FILTER_ORDER } from "client/services/folders";
import type ProjectContentBarService from "client/services/project-content-bar";

export default class ScenesAssetsLibraryController extends Controller {
  queryParams = ["order"];

  @service
  declare folders: FoldersService;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @tracked
  order = DEFAULT_FILTER_ORDER;

  declare scenesRouteModel: ProjectScenesRouteModel;

  @action
  async selectUserAsset(asset: UserAsset): Promise<void> {
    const { scene, afterScene, timeline, eventRegister } = this.scenesRouteModel;
    const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene);

    if (this.projectContentBar.isReplacing) {
      await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
    } else {
      await modifier.applyAsset(asset);
    }
  }

  @action
  onChangeOrder(order: string): void {
    this.order = order;
    this.folders.filterOrder = order;
  }
}
