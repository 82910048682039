import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Range } from "quill/core/selection";
import { TextConfig, DEFAULT_RIBBON_PADDING } from "renderer-engine";
import type { Caption, EventRegister, TextStyleProperties } from "client/lib/editor-domain-model";
import {
  CharacterRange,
  CleanupAction,
  Text,
  TextContentMutation,
  TextStyle,
  TextStylesMutation
} from "client/lib/editor-domain-model";
import { CoalescingTransactionManager } from "client/lib/editor-domain-model/events/coalescing-transaction-manager";
import getStyleNamespace from "client/lib/get-style-namespace";
import AssetFactory from "client/lib/scene/asset-factory";
import CaptionFactory from "client/lib/scene/caption-factory";
import type { EditableText } from "client/lib/text/text-editor";
import { BasicTextEditor } from "client/lib/text/text-editor";
import { createTextZymbolConfig } from "client/lib/text/text-styles";

interface TextEditorArgs {
  text: Text;
  caption: Caption;
  eventRegister: EventRegister;
  disableOnInit: boolean;
  plain: boolean;
  registerEditable(text: Text, editable: EditableText): void;
  shiftFocus?: (direction: -1 | 1) => void;
  onBlur?: (focusOut: boolean) => Promise<void>;
}

const COMMIT_DELAY_MS = 1000;

export default class TextEditorComponent extends Component<TextEditorArgs> implements EditableText {
  editor?: BasicTextEditor;
  editorElement?: HTMLElement;

  private manager = new CoalescingTransactionManager(this.args.eventRegister);

  @tracked
  selection?: CharacterRange;

  styleNamespace = getStyleNamespace("text-editor");

  get copyOfText(): Text {
    // This is used to trigger updates, but there might be a better way of doing it.
    return new Text({
      id: this.text.id,
      position: this.text.position,
      content: this.text.content,
      styles: this.text.styles.map((s) => new TextStyle(s)),
      customTimingOffset: this.text.customTimingOffset,
      customTimingDuration: this.text.customTimingDuration
    });
  }

  get text(): Text {
    return this.args.text;
  }

  get caption(): Caption {
    return this.args.caption;
  }

  getTextStyleFor<T>(get: (s: TextStyle) => T): T | undefined {
    const range = this.selection?.length ? this.selection : new CharacterRange(this.selection?.startOffset || 0, 1);
    return TextStyle.getStylePropertyForContent(this.text.styles, get, "line", this.text.content, range);
  }

  get textStyle(): TextStyle {
    return new TextStyle({
      fontFamily: this.getTextStyleFor((s) => s.fontFamily) ?? "",
      fontVariant: {
        weight: this.getTextStyleFor((s) => s.fontVariant?.weight) ?? "",
        style: this.getTextStyleFor((s) => s.fontVariant?.style) ?? ""
      },
      list: this.getTextStyleFor((s) => s.list) ?? "",
      color: this.getTextStyleFor((s) => s.color) ?? "",
      ribbonColor: this.getTextStyleFor((s) => s.ribbonColor),
      lineHeight: this.getTextStyleFor((s) => s.lineHeight),
      fontSize: this.getTextStyleFor((s) => s.fontSize),
      capitalize: this.getTextStyleFor((s) => s.capitalize),
      alignment: this.getTextStyleFor((s) => s.alignment),
      yAlignment: this.getTextStyleFor((s) => s.yAlignment),
      defaultWeight: this.getTextStyleFor((s) => s.defaultWeight)
    });
  }

  @action
  async didInsertEditorDiv(element: HTMLElement): Promise<void> {
    this.editorElement = element;
    this.editor = new BasicTextEditor(element, {
      onTextChange: this.handleTextChange.bind(this),
      onSelectionChange: (range: Range | undefined): void => {
        this.selection = range ? new CharacterRange(range.index, range.length) : undefined;
      },
      onBlur: this.onBlur.bind(this),
      getText: (): Text => this.copyOfText,
      shiftFocus: this.shiftFocus,
      disableOnInit: this.args.disableOnInit,
      plain: this.args.plain
    });
    this.args.registerEditable(this.text, this);
  }

  async focus(caretPosition?: "start" | "end"): Promise<void> {
    const editor = this.editor;
    if (!editor) {
      return;
    }

    editor.focus();

    requestAnimationFrame(() => {
      if (caretPosition === "start") {
        editor.selectedText = new CharacterRange(0, 0);
      } else if (caretPosition === "end") {
        editor.setSelectionToEnd();
      }
    });
  }

  async handleTextChange(contents: string): Promise<void> {
    await this.saveContents(contents);
  }

  async saveContents(contents: string): Promise<void> {
    await this.manager.transaction(async () => {
      const textConfig = new TextConfig(createTextZymbolConfig(this.text));
      textConfig.content = contents;
      const elementArgs = CaptionFactory.textToElement(this.text);
      const text: Text = new CaptionFactory(new AssetFactory()).textConfigToText(elementArgs, textConfig);

      this.args.eventRegister.fire(new TextStylesMutation(this.text, text.styles));
      this.args.eventRegister.fire(new TextContentMutation(this.text, text.content));

      await this.args.eventRegister.facade.saveScene(this.caption.scene, { delayCommitMs: COMMIT_DELAY_MS });

      this.args.eventRegister.addCleanup(new CleanupAction(this.forceSettingsUpdate));
    });
  }

  @action
  applyStyle(style: TextStyleProperties): void {
    this.manager.new();
    void this.modifyBlockStyle(style);
    if (style.fontFamily) {
      this.editor?.format({ font: style.fontFamily }, "api");
    }
    if (style.fontVariant) {
      // We want to use undefined instead of an empty string so that bold and
      // italics don't affect each other
      if (style.fontVariant.weight) {
        this.editor?.format({ weight: style.fontVariant.weight }, "api");
      }
      if (style.fontVariant.style) {
        this.editor?.format({ fontStyle: style.fontVariant.style }, "api");
      }
    }
    if (style.list !== undefined) {
      this.editor?.format({ list: style.list }, "api", true);
    }
    if (style.color) {
      this.editor?.format({ color: style.color }, "api");
    }
    if ("ribbonColor" in style) {
      this.editor?.format({ padding: style.ribbonColor ? `0 ${DEFAULT_RIBBON_PADDING}em` : false }, "api");
    }
    if (style.lineHeight !== undefined) {
      this.editor?.format({ lineHeight: String(style.lineHeight) }, "api");
    }
    if (style.capitalize !== undefined) {
      this.editor?.format({ capitalize: style.capitalize && "uppercase" }, "api");
    }
    if (style.alignment) {
      this.editor?.format({ textAlign: style.alignment }, "api");
    }
  }

  private async modifyBlockStyle(textStyle: TextStyleProperties): Promise<void> {
    const styles = this.text.styles.map((style) => {
      if (style.range) {
        return style;
      }
      const newStyle = new TextStyle(style);
      if ("ribbonColor" in textStyle) {
        newStyle._ribbonColor = textStyle.ribbonColor;
      }
      if (textStyle.fontSize) {
        newStyle._fontSize = textStyle.fontSize;
      }
      if (textStyle.alignment) {
        newStyle._alignment = textStyle.alignment;
      }
      if (textStyle.yAlignment) {
        newStyle._yAlignment = textStyle.yAlignment;
      }
      return newStyle;
    });

    await this.manager.transaction(async () => {
      this.args.eventRegister.fire(new TextStylesMutation(this.text, styles));
      await this.args.eventRegister.facade.saveScene(this.caption.scene);
    });
  }

  async onBlur(focusOut: boolean): Promise<void> {
    this.manager.new();
    await this.args.onBlur?.(focusOut);
  }

  @action
  async didUpdateText(): Promise<void> {
    this.editor?.pushUpdate();
  }

  @action
  private forceSettingsUpdate(): void {
    this.editor?.pushUpdate(true);
  }

  @action
  shiftFocus(direction: -1 | 1): void {
    this.args.shiftFocus?.(direction);
  }

  enable(isEnabled: boolean): void {
    this.editor?.enable(isEnabled);
  }
}
