import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import type { SafeString } from "handlebars";
import ColorPreset from "client/lib/brand/color-preset";
import BrandApplier from "client/lib/brand-applier";
import { isColorDark } from "client/lib/color";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ColorComboChooserComboButtonArgs {
  colorPreset: ColorPreset;
  eventRegister: EventRegister;
  save?: () => void;
  onChange?: () => void;
  selectedScenes: Array<Scene>;
  hideSelection?: boolean;
}

export default class BrandColorComboChooserComboButton extends Component<ColorComboChooserComboButtonArgs> {
  styleNamespace = getStyleNamespace("color-combo-chooser");

  constructor(owner: Object, args: ColorComboChooserComboButtonArgs) {
    super(owner, args);
  }

  get isLight(): boolean {
    return this.backgroundColor !== undefined && !isColorDark(this.backgroundColor);
  }

  get backgroundColor(): string | undefined {
    return this.args.colorPreset.background;
  }

  get backgroundColorStyle(): SafeString {
    return htmlSafe(`background-color: ${this.backgroundColor}`);
  }

  get primaryColorStyle(): SafeString {
    return htmlSafe(`color: ${this.args.colorPreset.primary}`);
  }

  get primaryColorBackgroundStyle(): SafeString {
    return htmlSafe(`background-color: ${this.args.colorPreset.primary}`);
  }

  get secondaryColorBackgroundStyle(): SafeString {
    return htmlSafe(`background-color: ${this.args.colorPreset.secondary}`);
  }

  get textColorStyle(): SafeString {
    return htmlSafe(`color: ${this.args.colorPreset.text}`);
  }

  get isSelected(): boolean {
    return (
      !this.args.hideSelection &&
      !!this.args.selectedScenes.some((scene) => ColorPreset.equal(this.args.colorPreset, scene.colorPreset))
    );
  }

  @action
  applyComboToScene(): void {
    const { colorPreset, eventRegister, save, onChange, selectedScenes } = this.args;

    if (save) {
      for (const scene of selectedScenes) {
        const colorPresetJson = colorPreset.toJSON();

        if (colorPresetJson) {
          const brandApplier = new BrandApplier({
            eventRegister,
            computeColorBrandKeys: !scene.colorBrandKey,
            referenceColorPreset: scene.colorPreset
          });

          brandApplier.applyColorPresetToScene(scene, colorPresetJson);
        }
      }

      save();
    }
    onChange?.();
  }
}
