import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import round from "lodash/round";
import type { Asset, EventRegister, Media, VideoClip, VolumeOptions } from "client/lib/editor-domain-model";
import { UpdateVideoVolumeOptionsMutation } from "client/lib/editor-domain-model/events/mutations/update-video-volume-options-mutation";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioClip from "client/models/audio-clip";

interface Args {
  media: Media;
  eventRegister: EventRegister;
  onClose: () => void;
  save: () => void;
}

export default class ToolbarVolumeComponent extends Component<Args> {
  @tracked
  targetElement?: HTMLElement;

  styleNamespace = getStyleNamespace("toolbar/volume");

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  get hasAudio(): boolean {
    return !!(this.asset as VideoClip)?.hasAudio;
  }

  get isVideoClip(): boolean {
    if (this.asset as VideoClip) {
      return true;
    }

    return false;
  }

  get formattedValue(): number {
    if (this.audioClipVolume?.mute) {
      return 0;
    } else {
      return round((this.audioClipVolume?.volume || 0) / 0.2);
    }
  }

  get isMuted(): boolean {
    return this.audioClipVolume?.mute || this.audioClipVolume?.volume === 0;
  }

  @action
  onVolumeChange(volume: number): void {
    if (!this.hasAudio || isNaN(volume) || !this.isVideoClip) {
      return;
    }

    const video = this.asset as VideoClip;
    const { fadeIn, fadeOut } = video;

    this.updateVolume(video, { volume, mute: false, fadeIn, fadeOut });
  }

  @action
  toggleFadeOut(): void {
    if (!this.hasAudio || this.isMuted || !this.isVideoClip) {
      return;
    }

    const video = this.asset as VideoClip;
    const { volume, mute, fadeIn } = video;

    this.updateVolume(video, { volume, mute, fadeIn, fadeOut: !video.fadeOut });
  }

  @action
  toggleFadeIn(): void {
    if (!this.hasAudio || this.isMuted || !this.isVideoClip) {
      return;
    }

    const video = this.asset as VideoClip;
    const { volume, mute, fadeOut } = video;

    this.updateVolume(video, { volume, mute, fadeIn: !video.fadeIn, fadeOut });
  }

  @action
  onMuteChange(mute: boolean): void {
    if (!this.hasAudio || !this.isVideoClip) {
      return;
    }

    const video = this.asset as VideoClip;
    const { volume, fadeIn, fadeOut } = video;

    this.updateVolume(video, { volume, mute, fadeIn, fadeOut });
  }

  @action
  toggleMute(): void {
    if (!this.hasAudio || !this.isVideoClip) {
      return;
    }

    const video = this.asset as VideoClip;
    const { volume, fadeIn, fadeOut, mute } = video;

    this.updateVolume(video, { volume, mute: !mute, fadeIn, fadeOut });
  }

  get asset(): Asset | undefined {
    return this.args.media.asset;
  }

  @action
  updateVolume(video: VideoClip, { volume, mute, fadeIn, fadeOut }: VolumeOptions): void {
    this.args.eventRegister.fire(new UpdateVideoVolumeOptionsMutation(video, { volume, mute, fadeIn, fadeOut }));
    this.args.save();
  }

  get audioClipVolume(): Partial<Pick<AudioClip, "volume" | "mute" | "fadeIn" | "fadeOut">> | undefined {
    if (!this.hasAudio) {
      return;
    }

    const { volume, mute, fadeIn, fadeOut } = this.asset as VideoClip;

    return {
      volume,
      mute,
      fadeIn,
      fadeOut
    };
  }
}
