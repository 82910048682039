import Component from "@glimmer/component";
import dedup from "client/lib/dedup";
import getStyleNamespace from "client/lib/get-style-namespace";

interface BrandColorPresetColorPickerArgs {
  colors: string[];
  colorPresetColors: string[];
  selectedColor: string;
  onSelectColor(color: string): unknown;
}

const WHITE_COLOR = "#FFFFFF";

export default class BrandColorPresetColorPickerComponent extends Component<BrandColorPresetColorPickerArgs> {
  styleNamespace = getStyleNamespace("brand/color-preset/color-picker");

  get colors(): string[] {
    const colors = [...this.args.colors, WHITE_COLOR].map((c) => c.toUpperCase());

    return dedup(colors);
  }
}
