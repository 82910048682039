import { action } from "@ember/object";
import Component from "@glimmer/component";
import type BrandLogo from "client/lib/brand/brand-logo";
import type { Frame } from "client/lib/editor-domain-model";

interface EditLogoFrameArgs {
  logo: BrandLogo;
  onSave(logo: BrandLogo, frame: Frame): unknown;
  onCancel(): unknown;
}

export default class EditLogoFrame extends Component<EditLogoFrameArgs> {
  @action
  onSave(frame: Frame): void {
    this.args.onSave(this.args.logo, frame);
  }
}
