import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { DEFAULT_FRAME_RATE } from "renderer-engine";

export default class VideoPlayerService extends Service {
  declare player: HTMLVideoElement;

  @tracked
  autoPaused = false;

  @tracked
  currentTime = 0;

  @tracked
  declare isPlaying: boolean;

  // get | set
  get currentFrame(): number {
    return Math.floor(this.player.currentTime * DEFAULT_FRAME_RATE);
  }

  setCurrentTime(time?: number): void {
    if (time !== undefined && time !== this.player.currentTime) {
      this.player.currentTime = time;
    }
    if (this.currentTime !== this.player.currentTime) {
      this.currentTime = this.player.currentTime;
    }
  }

  private setTracked(): void {
    this.isPlaying = !this.player.paused;
    this.setCurrentTime();
  }

  // actions
  autoPause(): void {
    if (this.isPlaying) {
      this.autoPaused = true;
      this.pause();
    }
  }

  async autoResume(): Promise<void> {
    if (this.autoPaused) {
      this.autoPaused = false;
      await this.play();
    }
  }

  pause(): void {
    this.player.pause();
    this.setTracked();
  }

  async play(): Promise<void> {
    await this.player.play();
    this.setTracked();
  }

  reset(): void {
    this.player.currentTime = 0;
    this.player.pause();
    this.setTracked();
  }

  async togglePlayback(): Promise<void> {
    if (this.player.paused) {
      await this.player.play();
    } else {
      this.player.pause();
    }
    this.setTracked();
  }
}
