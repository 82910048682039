import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import toPercent from "client/lib/to-percent";

interface ScaleInputComponentArgs {
  onChange?: (value: number) => void;
  hideIncrement: boolean;
  value: number;
  min?: number;
  max?: number;
}

export default class AppScaleInputComponent extends Component<ScaleInputComponentArgs> {
  step = 0.01;

  @tracked
  percent = "100%";

  styleNamespace = getStyleNamespace("app/scale-input");

  get canIncrement(): boolean {
    return this.args.value < this.max;
  }

  get canDecrement(): boolean {
    return this.args.value > this.min;
  }

  get decimal(): number {
    return parseInt(this.percent, 10) / 100;
  }

  get max(): number {
    return this.args.max ?? Infinity;
  }

  get min(): number {
    return this.args.min ?? 0;
  }

  @action
  didReceiveAttr(): void {
    const { value } = this.args;

    if (this.decimal !== value) {
      this.percent = toPercent(value);
    }
  }

  @action
  valueDidChange(): void {
    if (isNaN(this.decimal)) {
      this.percent = toPercent(this.args.value);
      return;
    }

    if (this.decimal > this.max) {
      this.percent = toPercent(this.max);
    }

    if (this.decimal < this.min) {
      this.percent = toPercent(this.min);
    }

    this.percent = toPercent(this.decimal);

    if (this.args.onChange) {
      this.args.onChange(this.decimal);
    }
  }

  @action
  increment(): void {
    if (this.canIncrement) {
      const newValue = Math.min(this.max, this.decimal + this.step);
      this.percent = toPercent(newValue);
      this.valueDidChange();
    }
  }

  @action
  decrement(): void {
    if (this.canDecrement) {
      const newValue = Math.max(this.min, this.decimal - this.step);
      this.percent = toPercent(newValue);
      this.valueDidChange();
    }
  }
}
