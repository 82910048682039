import { tracked } from "@glimmer/tracking";
import type { Timeline, TimelineFacade } from "../timeline";
import type { StrictMutation } from "./mutations";
import { ExplicitTransaction } from "./mutations/explicit-transaction";
import { RouteAction } from "./mutations/route-action";
import { CoalescingSaveAction, SaveAction } from "./mutations/save-action";

export class EventRegister {
  @tracked
  private undoOps: StrictMutation[] = [];
  @tracked
  private redoOps: StrictMutation[] = [];

  private transactionInfo?: {
    transaction: ExplicitTransaction;
    mutations: StrictMutation[];
    saveCallbackMutations: StrictMutation[];
    startUrl: string;
    depth: number;
    resolve: () => void;
  };
  private busyProcessing = false;

  public hasAppliedMutation = false;
  public facade: TimelineFacade;
  public queue: Promise<void> = new Promise((resolve) => resolve());

  constructor(public readonly facadeWithoutProxy: TimelineFacade) {
    this.facade = new Proxy(facadeWithoutProxy, new SaveActionProxyHandler(this));
  }

  saveActionOf(f: (...args: any[]) => Promise<any>): (...args: any[]) => Promise<any> {
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    if (this.busyProcessing) {
      return (...args: any) => this.transaction(() => this.process(() => f(...args)));
    }
    return (...args: any[]) => this.transaction(() => this.fire(new CoalescingSaveAction(() => f(...args), ...args)));
    /* eslint-enable @typescript-eslint/no-unsafe-argument */
  }

  get canUndo(): boolean {
    return this.undoOps.length > 0;
  }

  get undoDepth(): number {
    return this.undoOps.filter((m) => !m.grouped).length;
  }

  get currentTransaction(): ExplicitTransaction | undefined {
    return this.transactionInfo?.transaction;
  }

  intersect(mutations: StrictMutation[]): StrictMutation[] {
    return mutations.filter((mutation) => this.undoOps.find((m) => m === mutation));
  }

  hasAny(mutations: StrictMutation[]): boolean {
    return !!mutations.find((mutation) => this.undoOps.find((m) => m === mutation));
  }

  get canRedo(): boolean {
    return this.redoOps.length > 0;
  }

  clearUndo() {
    this.redoOps = [];
    this.undoOps = [];
  }

  async process<T>(f: () => Promise<T>): Promise<T> {
    try {
      this.busyProcessing = true;
      return await f();
    } finally {
      this.busyProcessing = false;
    }
  }

  async undo(): Promise<void> {
    await this.queue;
    await (this.queue = (async (): Promise<void> => {
      try {
        await this.process(() => this.undoMutation(this.undoOps.pop()));
      } finally {
        this.updateOps();
      }
    })());
  }

  private async undoMutation(op?: StrictMutation): Promise<void> {
    if (op) {
      this.redoOps.push(op);
      if (op.executionPhase === "update") {
        try {
          await op.revert();
        } catch (err) {
          console.error(err);
        }
      }
      if (op.grouped) {
        await this.undoMutation(this.undoOps.pop());
      }
      if (op.executionPhase !== "update") {
        try {
          await op.revert();
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  async redo(): Promise<void> {
    await this.queue;
    await (this.queue = (async (): Promise<void> => {
      try {
        await this.process(() => this.redoMutation(this.redoOps.pop()));
      } finally {
        this.updateOps();
      }
    })());
  }

  private async redoMutation(op?: StrictMutation): Promise<void> {
    if (op) {
      try {
        await op.run();
      } catch (err) {
        console.error(err);
      }
      this.undoOps.push(op);
      if (this.redoOps.length > 0 && this.redoOps.slice(-1)[0]?.grouped) {
        await this.redoMutation(this.redoOps.pop());
      }
    }
  }

  fire<T>(mutation: StrictMutation<T>): T {
    if (this.busyProcessing) {
      throw new Error("fire() can't be called within an undo/redo mutation");
    }

    if (!mutation.undoable) {
      this.undoOps = [];
      return mutation.run();
    }

    if (!this.hasAppliedMutation) {
      this.removeSaveCallbackMutations();
      this.hasAppliedMutation = true;
    }
    const startUrl = this.facade.currentURL;

    try {
      this.busyProcessing = true;
      const result = mutation.run();

      this.addMutation(mutation);
      if (!this.transactionInfo) {
        this.addMutation(new RouteAction(this.facade, startUrl));
      }

      return result;
    } finally {
      this.busyProcessing = false;
    }
  }

  /** This is a workaround that allows us to add cleanup that occurs only
   * during undo/redo actions and not during the normal transaction */
  public addCleanup(mutation: StrictMutation): void {
    this.addMutation(mutation);
  }

  private addMutation(mutation: StrictMutation): void {
    if (this.transactionInfo) {
      this.transactionInfo.mutations.push(mutation);
    } else {
      this.redoOps = [];
      this.undoOps.push(mutation);
      if (this.undoOps.length > 10000) {
        this.undoOps = this.undoOps.slice(Math.max(this.undoOps.length - 10000, 0));
      }
    }
    this.updateOps();
  }

  async saveCallback(...mutations: StrictMutation[]): Promise<void> {
    // We don't create an automatic transaction if we've already seen a
    // mutation inside the current transaction. This prevents unintended
    // automatic mutations being created inside transactions with multiple
    // model saves. At the same time as allowing transactions to wrap
    // automatically generated model edits.
    if (this.hasAppliedMutation) {
      if (!this.transactionInfo) {
        this.hasAppliedMutation = false;
      }
    } else {
      if (!this.busyProcessing) {
        await this.transaction(async () => {
          mutations.forEach((mutation) => {
            this.redoOps = [];

            this.addMutation(mutation);
          });
          const routeAction = new RouteAction(this.facade);
          this.addMutation(routeAction);
          this.transactionInfo?.saveCallbackMutations.push(...mutations, routeAction);
        });
      }
    }
  }

  private removeSaveCallbackMutations() {
    if (!this.transactionInfo) {
      return;
    }
    this.transactionInfo.mutations = this.transactionInfo.mutations.filter(
      (m) => !this.transactionInfo?.saveCallbackMutations.find((scm) => m === scm)
    );
  }

  private updateOps(): void {
    this.undoOps = this.undoOps; /* eslint-disable-line no-self-assign */
    this.redoOps = this.redoOps; /* eslint-disable-line no-self-assign */
  }

  private async startTransaction(target: ExplicitTransaction): Promise<void> {
    if (this.transactionInfo) {
      this.transactionInfo.depth++;
    } else {
      await this.queue;

      this.queue = new Promise((resolve) => {
        this.transactionInfo = {
          transaction: target,
          mutations: [],
          saveCallbackMutations: [],
          startUrl: this.facade.currentURL,
          depth: 1,
          resolve
        };
      });
    }
  }

  private endTransaction(): void {
    if (!this.transactionInfo) {
      return;
    }

    this.transactionInfo.depth--;

    if (this.transactionInfo.depth === 0) {
      const info = this.transactionInfo;
      this.transactionInfo = undefined;

      if (info.mutations.filter((m) => m.executionPhase !== "cleanup").length) {
        const wasEmpty = info.transaction.isEmpty();
        info.transaction.append(info.mutations);
        if (info.mutations.filter((m) => m.executionPhase !== "cleanup").length && wasEmpty) {
          info.transaction.append([new RouteAction(this.facade, info.startUrl)]);
          this.addMutation(info.transaction);
        }
      }
      this.hasAppliedMutation = false;
      info.resolve();
    }
  }

  async appendTransaction<T>(transaction: ExplicitTransaction, f: () => Promise<T>): Promise<T> {
    if (transaction === this.transactionInfo?.transaction) {
      this.transactionInfo.saveCallbackMutations = [];
    }

    await this.startTransaction(transaction);
    try {
      return await f();
    } finally {
      this.endTransaction();
    }
  }

  async transaction<T>(f: () => Promise<T>): Promise<T> {
    return this.appendTransaction(new ExplicitTransaction(), f);
  }

  async makeTransaction(f: () => Promise<void>): Promise<ExplicitTransaction> {
    return this.transaction(async () => {
      await f();
      return this.currentTransaction!;
    });
  }

  overwriteLastUndo(mutation: StrictMutation): void {
    this.undoOps.pop();
    this.addMutation(mutation);
  }

  async save(timeline: Timeline): Promise<void> {
    await this.fire(
      new SaveAction(
        async () =>
          await Promise.all([
            ...timeline.scenes.map(async (s) => this.facadeWithoutProxy.saveScene(s)),
            this.facadeWithoutProxy.saveAudioClips(timeline),
            this.facadeWithoutProxy.saveSceneOrder(timeline)
          ])
      )
    );
  }
}

class SaveActionProxyHandler implements ProxyHandler<TimelineFacade> {
  constructor(private eventRegister: EventRegister) {}

  get(target: TimelineFacade, p: string | symbol, _receiver: any): any {
    const value = target[p as keyof TimelineFacade] as () => any;
    if (p.toString().startsWith("save")) {
      return this.eventRegister.saveActionOf(value.bind(target));
    }
    return value;
  }
}
