import Model, { attr } from "@ember-data/model";

export default class Font extends Model {
  @attr("string")
  declare name: string;

  @attr()
  declare variants: string[];

  @attr()
  declare subsets: string[];
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    font: Font;
  }
}
