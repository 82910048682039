import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum NotificationTypes {
  warning = "warning",
  info = "info",
  error = "error",
  error_banner = "error-banner",
  warning_banner = "warning-banner"
}

export interface NotificationArgs {
  title?: string;
  type: NotificationTypes;
}

export default class NotificationComponent extends Component<NotificationArgs> {
  styleNamespace = getStyleNamespace("north-star/notification");

  get type(): NotificationTypes {
    return this.args.type ?? NotificationTypes.info;
  }
}
