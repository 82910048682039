export default class DomUtils {
  static removeEmptyDivs(element: HTMLElement): void {
    const childElements = [...element.children] as HTMLElement[];
    childElements.forEach((element) => {
      if (element.childElementCount === 0) {
        element.remove();
      }
    });
  }

  static removeElementById = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      element.remove();
    }
  };

  static addWrapperStyles = (element: HTMLElement): void => {
    Object.assign(element.style, {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      pointerEvents: "none"
    });
  };

  static createDivWithId = (id: string): HTMLDivElement => {
    const div = document.createElement("div");
    div.id = id;

    return div;
  };

  static createChildDiv = (parent: HTMLElement, childId?: string): HTMLDivElement => {
    const div = childId ? DomUtils.createDivWithId(childId) : document.createElement("div");
    parent.appendChild(div);

    return div;
  };

  static createChildWrapper = (parent: HTMLElement, childId?: string): HTMLDivElement => {
    const childDiv = DomUtils.createChildDiv(parent, childId);
    DomUtils.addWrapperStyles(childDiv);

    return childDiv;
  };
}
