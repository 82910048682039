import type { SvgAssetContainer } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type { Svg } from "client/lib/editor-domain-model";

export const svgToSvgAssetContainer = (svg: Svg, sceneId?: string): SvgAssetContainer => {
  const { sourceUrl: url, previewUrl: previewImageUrl } = svg;
  return {
    cfg: {
      svg: {
        url,
        previewImageUrl
      }
    },
    category: ZymbolCategory.SVG,
    sceneId
  };
};
