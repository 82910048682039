import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Placement } from "@popperjs/core";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { FontFamilyOption } from "client/models/font-family";
import type FontsService from "client/services/fonts";

interface Args {
  fonts?: FontFamilyOption[];
  position?: Placement;
  value: string | string[];
  updateFont: (font: string) => void;
  disabled?: boolean;
}

export default class FontFamilyDropdown extends Component<Args> {
  @service
  declare fonts: FontsService;

  @tracked
  open = false;

  @tracked
  menuToggleElement?: HTMLElement;

  styleNamespace = getStyleNamespace("font-family-dropdown");

  get fontFamilyStyle(): SafeString {
    return htmlSafe(`font-family: ${this.args.value}`);
  }

  get values(): string[] {
    return this.args.value instanceof Array ? this.args.value : [this.args.value];
  }

  get errorText(): string | undefined {
    const failedFonts = this.values.filter((s) => this.fonts.isFontMissing(s));
    return failedFonts.length ? `Failed to load ${failedFonts.join(", ")}` : undefined;
  }

  get tooltip(): string {
    return this.errorText ?? "Font family";
  }

  get valuesLabel(): string {
    return this.values.join(", ");
  }

  get disabled(): boolean {
    return this.args.disabled || false;
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this.menuToggleElement = element;
  }

  @action
  openMenu(): void {
    if (this.open) {
      return;
    }

    if (!this.disabled) {
      this.open = true;
    }
  }

  @action
  closeMenu(): void {
    this.open = false;
  }
}
