import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type FoldersService from "client/services/folders";
import { DEFAULT_FILTER_ORDER } from "client/services/folders";
export default class AssetLibraryFolderController extends Controller {
  queryParams = ["order"];

  @service
  declare folders: FoldersService;

  @tracked
  order = DEFAULT_FILTER_ORDER;

  @tracked
  reload = false;

  @action
  refresh(): void {
    this.reload = !this.reload;
  }

  @action
  onChangeOrder(order: string): void {
    this.order = order;
    this.folders.filterOrder = order;
  }
}
