import Transform from "@ember-data/serializer/transform";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import BrandStyleConfig from "client/lib/brand/brand-style-config";

export default class BrandStyleConfigTransform extends Transform {
  serialize(config: BrandStyleConfig): object {
    const data = config.toJSON();

    return snakecaseKeys(data, { deep: true });
  }

  deserialize(serialized?: object): BrandStyleConfig {
    if (serialized) {
      const data = camelcaseKeys(serialized, { deep: true });

      return BrandStyleConfig.fromJSON(data);
    } else {
      return new BrandStyleConfig();
    }
  }
}

declare module "ember-data/types/registries/transform" {
  export default interface TransformRegistry {
    brandStyleConfig: BrandStyleConfigTransform;
  }
}
