import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { debounce } from "@ember/runloop";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { AnimationFit, toggleDuckedLevel } from "renderer-engine";
import type { AnimationOptions } from "client/components/app/animation-dropdown/component";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import cmsUrl from "client/lib/cms-url";
import { applyScale } from "client/lib/dimensions";
import type {
  EventRegister,
  Scene,
  VolumeOptions,
  AnimatedLayer,
  Media,
  Asset,
  Caption
} from "client/lib/editor-domain-model";
import {
  CaptionDurationMutationsFactory,
  ChangeAssetMirrorMutation,
  ChangeAssetOffsetMutation,
  ChangePositionMutation,
  getDefaultRect,
  Image,
  Rect,
  VideoClip,
  Animation,
  AnimationColorMutation,
  ChangeImageOpacityMutation,
  ResetAssetMutation,
  Background,
  Logo,
  Svg,
  Watermark,
  ChangeObjectFitMutation,
  ToggleLoopableMutation,
  UpdateFrameMutation
} from "client/lib/editor-domain-model";
import { ResetMediaMutation } from "client/lib/editor-domain-model/events/mutations/reset-media-mutation";
import { UpdateVideoVolumeOptionsMutation } from "client/lib/editor-domain-model/events/mutations/update-video-volume-options-mutation";
import getStyleNamespace from "client/lib/get-style-namespace";
import { alphaHexToDecimal, decimalToAlphaHex } from "client/lib/rgb-hex";
import {
  canAnimate,
  getAnimation,
  getAnimationOptions,
  getIcon,
  getTypeLabel,
  saveElement,
  setAnimation
} from "client/lib/timeline/element";
import { defaultLowerSizeLimit, defaultUpperSizeLimit, getResetPosition } from "client/lib/timeline/media";
import { transaction } from "client/lib/transaction";
import type AudioClip from "client/models/audio-clip";
import type ProjectContentBarService from "client/services/project-content-bar";

interface AnimationColor {
  name: string;
  clips: AnimatedLayer[];
  colors: string[];
}

interface ProjectEditMediaArgs {
  media: Media;
  caption: Caption;
  scene: Scene;
  eventRegister: EventRegister;
  brandConfig?: BrandStyleConfig;
  projectColors: string[];
  routeName: string;
  isBackground: boolean;
  isWatermark: boolean;
}

export default class ProjectEditMediaComponent extends Component<ProjectEditMediaArgs> {
  @tracked
  sagThumbnailFrame?: number;

  @tracked
  sagPreviewSubmitting = false;

  @tracked
  mediaResetting = false;

  @service
  declare store: Store;

  @service
  declare projectContentBar: ProjectContentBarService;

  styleNamespace = getStyleNamespace("tidal/project-edit/media");

  get mediaType(): string {
    const { media } = this.args;

    if (media instanceof Background) {
      return "background";
    } else if (media instanceof Logo) {
      return "logo";
    } else if (media instanceof Watermark) {
      return "watermark";
    } else {
      return "media";
    }
  }

  get hasAsset(): boolean {
    return !!this.asset?.hasContent;
  }

  get hasImage(): boolean {
    return this.hasAsset && this.asset instanceof Image;
  }

  get hasSvg(): boolean {
    return this.hasAsset && this.asset instanceof Svg;
  }

  get hasOpacity(): boolean {
    return this.hasImage || this.hasSvg;
  }

  get hasVideo(): boolean {
    return this.hasAsset && this.asset instanceof VideoClip;
  }

  get hasAnimation(): boolean {
    return this.hasAsset && this.asset instanceof Animation;
  }

  get hasFrame(): boolean {
    return (this.asset instanceof Image || this.asset instanceof VideoClip) && !!this.asset.frame;
  }

  get hasAudio(): boolean {
    return this.hasVideo && !!(this.asset as VideoClip)?.hasAudio;
  }

  get icon(): string {
    return getIcon(this.args.media);
  }

  get panelTitle(): string {
    return getTypeLabel(this.args.media);
  }

  get addAndReplaceAssetTypeLabel(): string {
    if (this.args.isBackground) {
      return "background";
    } else if (this.args.isWatermark) {
      return "watermark";
    } else {
      return "element";
    }
  }

  get asset(): Asset | undefined {
    return this.args.media.asset;
  }

  get canAdjustFrame(): boolean {
    return (
      (this.args.media instanceof Logo || this.args.media instanceof Watermark) && (this.hasImage || this.hasVideo)
    );
  }

  get disabled(): boolean {
    return !this.hasAsset || this.hasSvg;
  }

  get alphaHex(): string | undefined {
    if (!this.hasOpacity) {
      return;
    }

    const image = this.asset as Image;
    const alphaHex = decimalToAlphaHex(image.opacity ?? 1);
    return `#000000${alphaHex}`;
  }

  @action
  addOrReplace(event: Event): void {
    // so the content-bar won't be auto-closed
    event?.stopPropagation();
    void this.projectContentBar.startAddOrReplaceMedia(this.args.media);
  }

  @action
  onOpacityChange(value: string): void {
    const { eventRegister } = this.args;

    if (!this.hasOpacity) {
      return;
    }

    const image = this.asset as Image;
    const opacity = alphaHexToDecimal(value);
    eventRegister.fire(new ChangeImageOpacityMutation(image, opacity));
    this.save();
  }

  get effectLabel(): string {
    if (this.args.isBackground) {
      return "Background effect";
    } else if (this.hasVideo) {
      return "Video effect";
    } else if (this.hasAnimation || this.hasSvg) {
      return "Graphic effect";
    } else {
      return "Image effect";
    }
  }

  get canAnimate(): boolean {
    return canAnimate(this.args.media);
  }

  get animations(): AnimationOptions | undefined {
    return getAnimationOptions(this.args.media);
  }

  get animation(): string | undefined {
    return getAnimation(this.args.media);
  }

  get animationColors(): AnimationColor[] {
    const animationColors = [] as AnimationColor[];

    if (!this.hasAnimation) {
      return animationColors;
    }

    const { clips } = this.asset as Animation;

    for (const clip of clips) {
      if (!clip.style.name) {
        continue;
      }

      const animationColor = animationColors.find((color) => color.name === clip.style.name);

      if (animationColor) {
        animationColor.clips.push(clip);
        animationColor.colors.push(clip.style.color);
      } else {
        animationColors.push({
          name: clip.style.name,
          clips: [clip],
          colors: [clip.style.color]
        });
      }
    }

    return animationColors;
  }

  @action
  updateVolume(video: VideoClip, { volume, mute, fadeIn, fadeOut, audioDuckingLevel }: VolumeOptions): void {
    this.args.eventRegister.fire(
      new UpdateVideoVolumeOptionsMutation(video, { volume, mute, fadeIn, fadeOut, audioDuckingLevel })
    );
    this.save();
  }

  @action
  save(): void {
    debounce(this, this.doSave, 200, false);
  }

  @action
  doSave(): void {
    const { eventRegister, scene, media } = this.args;

    void saveElement(eventRegister, media, scene);
  }

  @action
  async remove(): Promise<void> {
    const { media, eventRegister } = this.args;

    if (!media.asset) {
      return;
    }

    eventRegister.fire(new ResetAssetMutation(media.asset));

    if (media instanceof Background) {
      eventRegister.fire(new ChangePositionMutation(media, getDefaultRect()));
      eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));
      eventRegister.fire(new ResetMediaMutation(media));
    }

    this.save();
  }

  @action
  @transaction
  async removeFrame(): Promise<void> {
    const { media, eventRegister, scene } = this.args;

    if (!(media.asset instanceof Image || media.asset instanceof VideoClip)) {
      return;
    }

    eventRegister.fire(new UpdateFrameMutation(media.asset, undefined));
    eventRegister.fire(new ChangeObjectFitMutation(media.asset, AnimationFit.FILL));

    const position = await getResetPosition(media, scene, {
      upperSizeLimit: defaultUpperSizeLimit,
      lowerSizeLimit: defaultLowerSizeLimit,
      centerOnPosition: true
    });
    await eventRegister.fire(new ChangePositionMutation(media, position));

    this.save();
  }

  @action
  mirror(): void {
    const { eventRegister } = this.args;
    const { asset } = this;

    if (asset) {
      eventRegister.fire(new ChangeAssetMirrorMutation(asset, !asset.mirror));
    } else {
      return;
    }

    this.save();
  }

  @action
  @transaction
  async resetPosition(): Promise<void> {
    const { eventRegister, media, scene } = this.args;

    this.mediaResetting = true;

    if ((media instanceof Logo || media instanceof Watermark) && this.hasFrame) {
      await this.removeFrame();
    }

    let position;
    if (media instanceof Watermark) {
      position = await getResetPosition(media, scene, {
        upperSizeLimit: defaultUpperSizeLimit,
        lowerSizeLimit: defaultLowerSizeLimit
      });
    } else if (media instanceof Background) {
      position = await getResetPosition(media, scene);
    } else {
      position = await getResetPosition(media, scene, {
        alignMediaToCenter: true,
        upperSizeLimit: defaultUpperSizeLimit,
        lowerSizeLimit: defaultLowerSizeLimit
      });
    }

    eventRegister.fire(new ChangePositionMutation(media, position));
    eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));
    this.save();

    this.mediaResetting = false;
  }

  @action
  onScaleChange(scale: number): void {
    const { eventRegister, media } = this.args;
    const { position } = media;

    const dimensions = applyScale(position, scale);
    eventRegister.fire(new ChangePositionMutation(media, Rect.fromRect(dimensions)));
    this.save();
  }

  get scale(): number {
    const { width, height } = this.args.media.position;
    return Math.min(width, height);
  }

  get audioClipVolume():
    | Partial<Pick<AudioClip, "volume" | "mute" | "fadeIn" | "fadeOut" | "audioDuckingLevel">>
    | undefined {
    if (!this.hasAudio) {
      return;
    }

    const { volume, mute, fadeIn, fadeOut, audioDuckingLevel } = this.asset as VideoClip;

    return {
      volume,
      audioDuckingLevel,
      mute,
      fadeIn,
      fadeOut
    };
  }

  @action
  onVolumeChange(volume: number): void {
    if (!this.hasAudio) {
      return;
    }

    const video = this.asset as VideoClip;
    if (isNaN(volume) || !video) {
      return;
    }

    const { mute, fadeIn, fadeOut, audioDuckingLevel } = video;
    this.updateVolume(video, { volume, mute, fadeIn, fadeOut, audioDuckingLevel });
  }

  @action
  onAudioDuckingLevelChange(): void {
    if (!this.hasAudio) {
      return;
    }

    const video = this.asset as VideoClip;
    if (!video) {
      return;
    }

    const { mute, fadeIn, fadeOut, audioDuckingLevel, volume } = video;
    this.updateVolume(video, {
      volume,
      mute,
      fadeIn,
      fadeOut,
      audioDuckingLevel: toggleDuckedLevel(audioDuckingLevel)
    });
  }

  @action
  onMuteChange(mute: boolean): void {
    if (!this.hasAudio) {
      return;
    }

    const video = this.asset as VideoClip;
    if (!video) {
      return;
    }

    const { volume, fadeIn, fadeOut, audioDuckingLevel } = video;
    this.updateVolume(video, { volume, mute, fadeIn, fadeOut, audioDuckingLevel });
  }

  @action
  toggleFadeIn(): void {
    if (!this.hasAudio) {
      return;
    }

    const video = this.asset as VideoClip;
    if (!video) {
      return;
    }

    const { volume, mute, fadeOut, audioDuckingLevel } = video;
    this.updateVolume(video, { volume, mute, fadeIn: !video.fadeIn, fadeOut, audioDuckingLevel });
  }

  @action
  toggleFadeOut(): void {
    if (!this.hasAudio) {
      return;
    }

    const video = this.asset as VideoClip;
    if (!video) {
      return;
    }

    const { volume, mute, fadeIn, audioDuckingLevel } = video;
    this.updateVolume(video, { volume, mute, fadeIn, fadeOut: !video.fadeOut, audioDuckingLevel });
  }

  @action
  setAnimation(name: string): void {
    const { eventRegister, media } = this.args;

    setAnimation(eventRegister, media, name);

    this.save();
  }

  @action
  saveColor(clips: AnimatedLayer[], color: string): void {
    const { eventRegister } = this.args;

    for (const clip of clips) {
      eventRegister.fire(new AnimationColorMutation(clip, color));
    }

    this.save();
  }

  private get sagId(): string | undefined {
    if (!this.hasAnimation) {
      return;
    }

    const { originalSagId } = this.asset as Animation;
    return originalSagId;
  }

  get cmsUrl(): string | undefined {
    if (!this.sagId) {
      return;
    }

    return cmsUrl(getOwner(this)!, `/sag/${this.sagId}`);
  }

  @action
  async rebuildSagPreview(): Promise<void> {
    this.sagPreviewSubmitting = true;

    try {
      await this.store
        .createRecord("projectSceneForSagRender", {
          projectSceneId: this.args.scene.id,
          sagId: this.sagId,
          thumbnailFrame: this.sagThumbnailFrame
        })
        .save();
    } finally {
      this.sagPreviewSubmitting = false;
    }
  }

  @action
  @transaction
  async fitContent(): Promise<void> {
    const { asset } = this.args.media;
    if (asset instanceof VideoClip && asset.trim) {
      const zymbolObject = await this.store.peekRecord("zymbol", this.args.media.id);
      const adjustedDuration = asset.trim.duration + (zymbolObject?.customTimingOffset ?? 0);

      const factory = new CaptionDurationMutationsFactory(this.args.caption);
      const mutations = factory.createMutations(adjustedDuration);
      for (const mutation of mutations) {
        this.args.eventRegister.fire(mutation);
      }
      await this.args.eventRegister.facade.saveScene(this.args.scene);
    }
  }

  get canToggleLoopable(): boolean {
    return this.hasAnimation || this.hasVideo;
  }

  @action
  @transaction
  async toggleLoopable(): Promise<void> {
    const { eventRegister, scene, media } = this.args;

    if (!(media.asset instanceof Animation || media.asset instanceof VideoClip)) {
      return;
    }

    const mutation = new ToggleLoopableMutation(media.asset);
    eventRegister.fire(mutation);
    await saveElement(eventRegister, media, scene);
  }
}
