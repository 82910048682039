import BaseEventService from "client/services/base-events";

export enum PlaybackEvents {
  TIME_UPDATE = "TIME_UPDATE"
}

export default class PlaybackEventsService extends BaseEventService {
  eventLogPrefix = "PLAYBACK";

  publish(eventName: PlaybackEvents, time: number): void {
    super.publish(eventName, time);
  }
}
