import ApplicationAdapter from "./application";
import config from "client/config/environment";

export default class MediaAssetAdapter extends ApplicationAdapter {
  urlForQuery(query: { ownerType?: string }): string {
    const { ownerType } = query;

    if (ownerType) {
      return `${config.apiUrl}/media-assets/${ownerType}`;
    }

    return `${config.apiUrl}/media-assets/user`;
  }
}
