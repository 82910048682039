import { action } from "@ember/object";
import Component from "@glimmer/component";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ExportActionsBuildFailedArgs {
  denyRebuild: boolean;
  renderProject: (ctaContext: string) => Promise<void>;
  renderStarting: boolean;
}

export default class ExportActionsBuildFailedComponent extends Component<ExportActionsBuildFailedArgs> {
  styleNamespace = getStyleNamespace("export-actions/build-failed");

  @action
  async onRebuild(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_REBUILD_FAILED);
  }
}
