import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { ALL_CONTENTS, ContentType } from "client/services/contents-curator";

export interface ContentTypeOption {
  label: string;
  values: Array<ContentType>;
}

export const CONTENT_LABELS = ["all", "scenes", "stock", "graphics"];
export const INDIVIDUAL_CONTENT_LABELS = ["scenes"];
export const COMPOUND_CONTENT_LABELS = ["all", "stock", "graphics"];

interface ContentSearchArgs {
  type: ContentTypeOption;
  editor?: boolean;
  query?: string;
  onSelectType(type: ContentTypeOption): void;
  updateQuery(query: string): void;
}

export default class ContentSearchComponent extends Component<ContentSearchArgs> {
  styleNamespace = getStyleNamespace("discovery/content/search");

  get hideHeader(): boolean {
    return !!this.args.query || !!this.args.editor;
  }

  get contentTypes(): ContentTypeOption[] {
    return [
      { label: "all", values: ALL_CONTENTS },
      { label: "scenes", values: [ContentType.PROJECT_SCENE] },
      { label: "stock", values: [ContentType.PEXELS_PHOTO, ContentType.PEXELS_VIDEO, ContentType.STORYBLOCKS_VIDEO] },
      { label: "graphics", values: [ContentType.GRAPHICS] }
    ];
  }

  get placeholder(): string {
    return `Search ${this.args.type.label} content`;
  }
}
