import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  disabled?: boolean;
}

export default class NorthStarRightClickMenuItemComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("north-star/right-click-menu/item");
}
