import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { AvaUseCases, PRIMARY_USE_CASES, AvaReferer } from "client/authenticated/survey/data";
import type {
  UseCaseDetails,
  VideoAssistantUseCaseModel
} from "client/authenticated/survey/video-assistant/use-case/route";
import type { TrackingProperties } from "client/components/survey/nav/component";
import type VideoAssistantService from "client/services/video-assistant";

export default class VideoAssistantUseCaseController extends Controller<VideoAssistantUseCaseModel> {
  queryParams = ["referer", "generatorId"];

  @tracked
  referer?: string;

  @tracked
  generatorId?: string;

  @tracked
  loading = false;

  @tracked
  showSecondary = false;

  @service
  declare router: RouterService;

  @service
  declare videoAssistant: VideoAssistantService;

  @action
  async continue(): Promise<void> {
    this.loading = true;

    try {
      await this.router.transitionTo("authenticated.survey.video-assistant.description", {
        queryParams: { referer: this.referer, generatorId: this.generatorId }
      });
    } finally {
      this.loading = false;
    }
  }

  @action
  async back(): Promise<void> {
    if (this.referer === AvaReferer.Onboarding) {
      await this.router.transitionTo("authenticated.survey.decide");
    } else {
      await this.router.transitionTo("authenticated.home");
    }
  }

  @action
  select(value: string): void {
    this.videoAssistant.useCase = value;
    this.generatorId = undefined;
  }

  @action
  toggleSecondary(): void {
    this.showSecondary = !this.showSecondary;
  }

  get selectedUseCase(): string | undefined {
    return this.videoAssistant.useCase;
  }

  get primaryUseCases(): UseCaseDetails[] {
    return this.model.useCaseDetails.filter((details: UseCaseDetails) =>
      PRIMARY_USE_CASES.includes(details.useCase as AvaUseCases)
    );
  }

  get secondaryUseCases(): UseCaseDetails[] {
    return this.model.useCaseDetails
      .filter((details: UseCaseDetails) => !PRIMARY_USE_CASES.includes(details.useCase as AvaUseCases))
      .sort((a, b) => this.prioritySort(a, b, AvaUseCases.Template));
  }

  get trackingProperties(): TrackingProperties {
    return {
      type: this.selectedUseCase,
      referer: this.referer
    };
  }

  private prioritySort(
    { useCase: useCaseA }: UseCaseDetails,
    { useCase: useCaseB }: UseCaseDetails,
    lastUseCase: AvaUseCases
  ): number {
    const getMainWord = (str: string): string => str.replace(/^(a|an|the)\s+/i, "");

    const wordA = getMainWord(useCaseA).toLowerCase();
    const wordB = getMainWord(useCaseB).toLowerCase();

    if (wordA === lastUseCase && wordB !== lastUseCase) {
      return 1;
    } else if (wordA !== lastUseCase && wordB === lastUseCase) {
      return -1;
    } else {
      return wordA.localeCompare(wordB);
    }
  }
}
