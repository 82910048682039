import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import delay from "client/lib/delay";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ContentCollection from "client/models/content-collection";
import type PermissionsService from "client/services/permissions";

interface Args {
  selectStack(collectionName: string, stack: any): unknown;
  search(key: string): void;
}

export default class GraphicsCollectionsPanel extends Component<Args & ProjectContentBarPanelArgs> {
  private headerElement?: HTMLElement;

  @tracked
  collections: ContentCollection[] = [];

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  @tracked
  loading = false;

  @tracked
  currentCollectionId?: string;

  styleNamespace = getStyleNamespace("project-content-bar/panels/graphics/collections-panel");

  @action
  didInsert(elem: HTMLElement): void {
    this.headerElement = elem;
    void this.loadCollections();
  }

  @action
  setCurrentCollection(collection: ContentCollection): void {
    this.currentCollectionId = collection.id;
  }

  private async loadCollections(): Promise<void> {
    this.loading = true;

    try {
      const collections = (await this.store.query("contentCollection", {
        /* eslint-disable camelcase */
        per_page: 100,
        include_unpublished: this.permissions.has("publish_template_content")
        /* eslint-enable camelcase */
      })) as ArrayProxy<ContentCollection>;

      await Promise.all(
        collections.map((collection: ContentCollection) => {
          return collection.contentStacks;
        })
      );
      this.collections = collections.map((collection) => collection);
    } finally {
      this.loading = false;
      void this.updateScrollMarginTop();
    }
  }

  private async updateScrollMarginTop(): Promise<void> {
    // delay until the CB width transition finishes (0.5s)
    await delay(1000);
    const outerHeader = this.headerElement?.parentElement;

    if (outerHeader) {
      const height = outerHeader.offsetHeight;
      document.querySelectorAll(`.${this.styleNamespace} .graphics-collection-stack-header`).forEach((elem) => {
        (elem as HTMLElement).style.setProperty("scroll-margin-top", `${height}px`);
      });
    }
  }
}
