import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewShareDialogArgs {
  projectPreview: ProjectPreview;
}

export default class ProjectPreviewShareDialogComponent extends Component<ProjectPreviewShareDialogArgs> {
  styleNamespace = getStyleNamespace("discovery/project-preview/watch-metrics");

  get majorMetric(): number {
    return this.args.projectPreview.countOfViews ?? 0;
  }
}
