import { set } from "@ember/object";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { getZymbolConfig, ZymbolCategory, SceneTransition } from "renderer-engine";
import type NotificationsService from "./notifications";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type Sag from "client/models/sag";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type { IdentifiablePayload } from "client/services/ajax";
import type AuthService from "client/services/auth";
import { USER_FORBIDDEN_NOTIFICATION } from "client/services/current-user";
import type LayersService from "client/services/layers";
import type PermissionsService from "client/services/permissions";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export const PROJECT_SCENE_TEMPLATE_UPDATE_FAILURE_NOTIFICATION = "There was a problem updating this scene";

export default class ProjectScenesService extends Service {
  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare layers: LayersService;

  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare store: Store;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  async recalculateProjectSceneDuration(projectScene: ProjectScene): Promise<void> {
    const [textLayer, backgroundZymbolGroup] = await Promise.all([
      projectScene.textLayer,
      projectScene.backgroundZymbolGroup
    ]);

    const textLayerDuration = await textLayer.duration;

    if (backgroundZymbolGroup && textLayerDuration > backgroundZymbolGroup.duration) {
      backgroundZymbolGroup.set("duration", textLayerDuration);
      await backgroundZymbolGroup.save();
    }
  }

  async updateDuration(projectScene: ProjectScene, newDuration: number, save: boolean): Promise<void> {
    const background = await projectScene.backgroundZymbolGroup;
    if (background) {
      background.set("duration", newDuration);
      if (save) {
        await background.save();
      }
    }
  }

  async updateProjectSceneOrder(projectScene: ProjectScene, startTime: number): Promise<void> {
    const [prev, next, project] = await Promise.all([
      this.getPrevProjectScene(projectScene),
      this.getNextProjectScene(projectScene),
      projectScene.project
    ]);

    // Swap with previous scene
    if (project && prev && startTime <= prev.startTime) {
      project.swapScenes(projectScene, prev);
    } else if (project && next && startTime >= next.startTime) {
      project.swapScenes(projectScene, next);
    }
  }

  async reorderProjectScene(project: Project, projectScene: ProjectScene, order: number): Promise<void> {
    const newSceneOrder = [...project.projectSceneOrder];
    const currentOrder = newSceneOrder.indexOf(Number(projectScene.id));

    if (currentOrder === -1 || currentOrder === order) {
      return;
    }

    newSceneOrder.splice(order, 0, ...newSceneOrder.splice(currentOrder, 1));

    set(project, "projectSceneOrder", newSceneOrder);
    await project.save();
  }

  async getNextProjectScene(projectScene: ProjectScene): Promise<ProjectScene | undefined> {
    const projectScenes = await (await projectScene.project).sortedProjectScenes;
    return projectScenes.find(({ order }) => order > projectScene.order);
  }

  /**
   * Get all of the non-deleted following `ProjectScene`s of a given `ProjectScene` in a `Project` sorted by
   * their `startTime`
   */
  async getFollowingProjectScenes(projectScene: ProjectScene): Promise<ProjectScene[]> {
    const projectScenes = await (await projectScene.project).sortedProjectScenes;

    if (!projectScenes) {
      return [];
    }

    return projectScenes.filter(({ order }) => order > projectScene.order);
  }

  /**
   * Get the previous `ProjectScene` of a given `ProjectScene` if there is one.
   */
  async getPrevProjectScene(projectScene: ProjectScene): Promise<ProjectScene | undefined> {
    const projectScenes = await (await projectScene.project).sortedProjectScenes;
    return projectScenes.reverse().find((ps) => ps.order < projectScene.order);
  }

  /**
   * Update a `ProjectScene`'s template property
   */
  async setProjectSceneAsTemplate(projectScene: ProjectScene, template = true): Promise<void> {
    if (!this.permissions.has("publish_template_content")) {
      this.notifications.error(USER_FORBIDDEN_NOTIFICATION);
      return;
    }

    try {
      projectScene.set("template", template);
      await projectScene.save();
      const notification = template ? "Your scene has been published" : "Your scene has been unpublished";
      this.notifications.success(notification);
    } catch (err) {
      this.notifications.error(PROJECT_SCENE_TEMPLATE_UPDATE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  /**
   * Update a `ProjectScene`'s transition property
   */
  async setProjectSceneTransition(projectScene: ProjectScene, transition: SceneTransition): Promise<void> {
    const sceneTransition = transition === SceneTransition.NONE ? undefined : transition;
    projectScene.set("transition", sceneTransition);
    await projectScene.save();
  }

  /*
   * Duplicate a `ProjectScene`, or import one from a template. The new `ProjectScene` will be appended after the original `ProjectScene`
   */
  async duplicateProjectScene(
    projectScene: ProjectScene,
    {
      destinationProject: project,
      afterScene
    }: {
      destinationProject?: Project;
      afterScene?: ProjectScene;
    }
  ): Promise<ProjectScene> {
    let url = `/project-scenes/${projectScene.id}/copy`;

    if (project) {
      url += `?project_id=${project.id}`;
    }

    const data = (await this.ajax.api(url, {
      method: "POST"
    })) as IdentifiablePayload;

    if (!data) {
      throw Error("Could not duplicate `ProjectScene`");
    }

    this.store.pushPayload("projectScene", data);
    const duplicateProjectScene = this.store.peekRecord("projectScene", data.data.id);

    if (!duplicateProjectScene) {
      throw Error("Could not duplicate `ProjectScene`");
    }

    const destinationProject = await (await duplicateProjectScene.project).reload();

    if (afterScene) {
      await this.reorderProjectScene(destinationProject, duplicateProjectScene, afterScene.order + 1);
    }

    if (duplicateProjectScene.aspectRatioName !== projectScene.aspectRatioName) {
      const backgroundZymbol = await duplicateProjectScene.backgroundZymbol;

      if (backgroundZymbol) {
        await backgroundZymbol.resetBackgroundDimensions();
      }
    }

    return duplicateProjectScene;
  }

  async update(projectScene: ProjectScene, props: Partial<ProjectScene>): Promise<void> {
    Object.assign(projectScene, props);
    await projectScene.save();
  }

  async getOrCreateBackgroundZymbol(projectScene: ProjectScene): Promise<Zymbol> {
    const { backgroundZymbol } = await projectScene.reload();

    if (backgroundZymbol) {
      return backgroundZymbol;
    } else {
      const layer = await projectScene.backgroundLayer;
      const zymbolGroups = (await layer.zymbolGroups) as unknown as Array<ZymbolGroup>;
      const zymbolGroup = zymbolGroups?.[0] || (await this.layers.addTextZymbolGroup(layer, 0, projectScene.duration));

      return this.zymbolGroups.createBackgroundZymbol(zymbolGroup);
    }
  }

  async addSagToBackground(projectScene: ProjectScene, sag: Sag): Promise<void> {
    const backgroundZymbol = projectScene.backgroundZymbol;

    await backgroundZymbol.replaceConfig(ZymbolCategory.SAG, {
      ...sag.cfg,
      animationColors: [],
      startTime: 0
    });

    await backgroundZymbol.resetBackgroundDimensions();
  }

  async updateProjectSceneFromTemplate(projectScene: ProjectScene, sceneTemplate: ProjectScene): Promise<void> {
    await sceneTemplate.reload();
    const templateBackground = await sceneTemplate.backgroundZymbol;

    if (!templateBackground) {
      return;
    }

    const sceneBackground = await projectScene.backgroundZymbol;

    if (!sceneBackground) {
      throw Error("There was an error retrieving the background `Zymbol` of a `ProjectScene`");
    }

    await sceneBackground.clearBackground();
    const categoryConfig =
      templateBackground.cfg[templateBackground.category] || getZymbolConfig(templateBackground.category);
    await sceneBackground.replaceConfig(templateBackground.category, categoryConfig);
    const { width, height, x, y } = templateBackground;
    sceneBackground.setProperties({
      width,
      height,
      x,
      y
    });
    await sceneBackground.save();
  }
}
