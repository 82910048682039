import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type TrackingService from "client/services/tracking";

interface EndscreenComponentArgs {
  disabled: boolean;
  hasEndscreen: boolean;
  onOpen: () => void;
  projectId: number;
}

export default class EndscreenComponent extends Component<EndscreenComponentArgs> {
  trackingSent = false;

  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("export-actions/endscreen");

  @action
  onClick(): void {
    if (!this.args.hasEndscreen && !this.trackingSent) {
      this.trackAddEvent();
    }

    if (!this.args.disabled) {
      this.args.onOpen();
    }
  }

  trackAddEvent(): void {
    this.trackingSent = true;

    void this.tracking.sendAnalytics(TrackingEvents.CALL_TO_ACTION_ADD, {
      // eslint-disable-next-line camelcase
      project_id: this.args.projectId
    });
  }
}
