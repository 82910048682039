import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AppInfiniteLoaderArgs {
  action(): unknown;
}

export default class AppInfiniteLoaderComponent extends Component<AppInfiniteLoaderArgs> {
  styleNamespace = getStyleNamespace("app/infinite-loader");

  @action
  didInsert(element: HTMLElement): void {
    new IntersectionObserver(([entry]) => {
      if (entry?.isIntersecting) {
        this.args.action?.();
      }
    }).observe(element);
  }
}
