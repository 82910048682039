import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { FontFamilyOption } from "client/models/font-family";

interface FontFamilyMenuItemArgs {
  font: FontFamilyOption;
  selected: FontFamilyOption;
  selectFont?: (font: FontFamilyOption) => void;
}

export default class FontFamilyMenuItem extends Component<FontFamilyMenuItemArgs> {
  styleNamespace = getStyleNamespace("font-family-dropdown/font-family-menu-item");

  @action
  loadImage(image: HTMLImageElement, src: string): void {
    const parentEl = image.parentElement;
    if (parentEl) {
      parentEl.classList.add("-loading");
      image.onload = (): void => parentEl.classList.remove("-loading");
    }
    image.src = src;
  }

  get fontStyleAttr(): SafeString | undefined {
    const fontHasImagePreview = !!this.args.font.imageUrl;
    if (!fontHasImagePreview) {
      return htmlSafe(`font-family: ${this.args.font.name};`);
    }

    return;
  }
}
