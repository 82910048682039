import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CollaborationService from "client/services/collaboration";

export default class AssetManagementTeamInviteComponent extends Component {
  @service
  declare collaboration: CollaborationService;

  styleNamespace = getStyleNamespace("asset-management/team-invite");

  @action
  invitePeople(): void {
    this.collaboration.showTeamInviteModal("assets - team folder - invite people");
  }
}
