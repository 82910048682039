import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface HeadingArgs {
  tag: keyof HTMLElementTagNameMap;
  margin?: boolean;
  size: "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large" | "xx-large";
}

export default class HeadingComponent extends Component<HeadingArgs> {
  styleNamespace = getStyleNamespace("north-star/heading");

  get tag(): string {
    return this.args.tag ?? "span";
  }

  get margin(): boolean {
    return this.args.margin ?? true;
  }
}
