import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type TrackingService from "client/services/tracking";

export const SCREEN_DOOR_EXCLUDED_ROUTES = [
  "authenticated.logout",
  "authenticated.account",
  "authenticated.edit-only-mode",
  "authenticated.default",
  "authenticated.plans",
  "authenticated.onboarding",
  "authenticated.trial",
  "authenticated.video-assistant.populate-template",
  "authenticated.loading",
  "authenticated.survey"
];

export const INACTIVE_MODE_EXCLUDED_ROUTES = ["authenticated.logout", "authenticated.loading"];

const SHOW_INTERVAL_HOURS = 24;

export default class ScreenDoorService extends Service {
  @service
  declare auth: AuthService;

  @service
  declare router: RouterService;

  @service
  declare permissions: PermissionsService;

  @service
  declare tracking: TrackingService;

  public get enforceInactiveTeamMemberMode(): boolean {
    return this.permissions.has("feature_inactive_team_member_notifications");
  }

  public get enforceEditOnlyMode(): boolean {
    return this.permissions.has("feature_edit_only_mode_notifications") && this.showScreenDoor();
  }

  public setNextShow(): void {
    localStorage.setItem(this.storageKey, (Date.now() + 3600000 * SHOW_INTERVAL_HOURS).toString());
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_EDIT_ONLY_MODE_CONFIRM, {
      isTeamOwner: this.auth.isTeamOwner
    });
  }

  private shouldRedirectToHome(routeName: string): boolean {
    const isScreenDoorRoute = routeName.startsWith("edit-only-mode");
    const isBaseRoute = routeName === "authenticated.index";

    return (!this.enforceEditOnlyMode && isScreenDoorRoute) || isBaseRoute;
  }

  private shouldRedirectToScreenDoor(routeName: string): boolean {
    const isExcludedRoute = SCREEN_DOOR_EXCLUDED_ROUTES.find((excludedRoute) => routeName.startsWith(excludedRoute));

    return this.enforceEditOnlyMode && !isExcludedRoute;
  }

  public handleRedirecting(currentRouteName: string): void {
    if (this.shouldRedirectToScreenDoor(currentRouteName)) {
      void this.router.transitionTo("authenticated.edit-only-mode");
    } else if (this.shouldRedirectToHome(currentRouteName)) {
      void this.router.transitionTo("authenticated.home");
    }
  }

  public redirectToInactiveTeamMemberMode(currentRouteName: string): void {
    const isExcludedRoute = INACTIVE_MODE_EXCLUDED_ROUTES.find((excludedRoute) =>
      currentRouteName.startsWith(excludedRoute)
    );

    if (this.enforceInactiveTeamMemberMode && !isExcludedRoute) {
      void this.router.transitionTo("authenticated.inactive-team-member-mode");
    }
  }

  private showScreenDoor(): boolean {
    const itemValue = localStorage.getItem(this.storageKey);

    if (itemValue) {
      return parseInt(itemValue) <= Date.now();
    }

    return true;
  }

  private get storageKey(): string {
    return `${config.localStoragePersistentPrefix}::${this.auth.currentUser?.id}::edit_only_screen_next_show_at`;
  }
}
