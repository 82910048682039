import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TextButtonComponentArgs {
  onClick?: (event: Event) => unknown;
  onMouseEnter?: () => unknown;
  disabled?: boolean;
}

export default class TextButtonComponent extends Component<TextButtonComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/text-button");

  @action
  handleClick(event: MouseEvent): void {
    if (this.args.onClick) {
      this.args.onClick(event);
    }
  }

  @action
  handleMouseEnter(): void {
    if (this.args.onMouseEnter) {
      this.args.onMouseEnter();
    }
  }
}
