import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type AdvancedEditorService from "./advanced-editor";
import type PlaybackEventsService from "./playback-events";
import type { Scene, Timeline } from "client/lib/editor-domain-model";
import type PlaybackService from "client/services/playback";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export default class TimelineService extends Service {
  @service
  declare store: Store;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare playbackEvents: PlaybackEventsService;

  @service
  declare playback: PlaybackService;

  @service
  declare advancedEditor: AdvancedEditorService;

  get timeline(): Timeline | undefined {
    return this.advancedEditor.timeline;
  }

  currentScene?: Scene;

  pixelsPerSecond?: number;

  async onPlaybackTimeUpdated(playbackTime: number): Promise<void> {
    const currentScene = this.timeline?.getSceneByTime(playbackTime);

    if (!this.playback.disabled && currentScene !== this.currentScene) {
      this.currentScene = currentScene;
      this.timelineEvents.publish(TimelineEvents.ACTIVE_SCENE_CHANGED, { scene: currentScene });
    }
  }

  initialize(): void {
    this.onPlaybackTimeUpdated = this.onPlaybackTimeUpdated.bind(this);
    // this.playbackEvents.subscribe(PlaybackEvents.TIME_UPDATE, this.onPlaybackTimeUpdated);
  }
}

declare module "@ember/service" {
  interface Registry {
    timeline: TimelineService;
  }
}
