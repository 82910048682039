import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type PexelsPhoto from "client/models/pexels-photo";
import type { LibraryContentModel } from "client/routes/library-content";
import type { LibrarySearchRouteParams } from "client/routes/library-search";
import LibrarySearchRoute from "client/routes/library-search";

export default class ProjectScenesLibraryPexelsSearchRoute extends LibrarySearchRoute<
  LibraryContentModel<PexelsPhoto>
> {
  @service
  declare store: Store;

  searchParentRouteName = "authenticated.project.scenes.library";
  type = "image";
  provider = "pexels";

  errorNotificationMessage = "There was a problem retrieving images";

  async getResults(params: LibrarySearchRouteParams): Promise<PexelsPhoto[]> {
    // @ts-expect-error
    return this.store.query("pexelsPhoto", params) as PexelsPhoto[];
  }
}
