import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import type LoadingController from "client/controllers/loading";

export default abstract class LoadingRoute<T> extends Route<T> {
  @action
  loading(transition: Transition): boolean | void {
    if (this.isRouteChange(transition)) {
      return true;
    } else {
      const controller = this.routeController;
      if (controller) {
        controller.set("currentlyLoading", true);
        transition.finally(() => {
          controller.set("currentlyLoading", false);
        });
      }
    }
  }

  isRouteChange(transition: Transition): boolean {
    if (!transition.from || !transition.to) {
      return true;
    }

    return transition.from.name !== transition.to.name;
  }

  get routeController(): LoadingController {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    return this.controllerFor<LoadingController>(this.routeName);
  }
}
