export default (arr: any, property: string): any => {
  const uniqueValues: Record<string, boolean> = {};
  const result: any = [];

  for (const obj of arr) {
    const value = obj[property];

    if (!uniqueValues[value]) {
      result.push(obj);
      uniqueValues[value] = true;
    }
  }

  return result;
};
