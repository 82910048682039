import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Category from "client/models/category";
import type TemplateCategories from "client/services/template-categories";

export default class TemplateFiltersCategoriesComponent extends Component<{
  aspectRatio?: string;
  updateAspectRatio(aspectRatioId: string): unknown;
}> {
  @service
  declare templateCategories: TemplateCategories;

  @tracked
  categories: Category[] = [];

  @tracked
  loading = false;

  topLinkTitle = "Popular templates";
  styleNamespace = getStyleNamespace("discovery/template-filters/categories");

  get nonEmptyCategories(): Category[] {
    return this.categories.filter((category) => category.title !== this.topLinkTitle);
  }

  @action
  async didInsert(): Promise<void> {
    this.loading = true;
    try {
      void this.templateCategories.load();
      this.categories = await this.templateCategories.nonEmptyCategories();
    } finally {
      this.loading = false;
    }
  }
}
