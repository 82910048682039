import { service } from "@ember/service";
import Component from "@glimmer/component";
import type ProjectContentBarService from "client/services/project-content-bar";

interface Args {
  onSelectPanel(panel: string): void;
}

export default class PanelRecordingHeader extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  get panel(): string | undefined {
    return this.projectContentBar.panel;
  }
}
