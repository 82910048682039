import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import shuffle from "lodash/shuffle";
import DynamicIslandActionComponent from "../../dynamic-island/action/component";
import getColumnCount from "client/lib/get-column-count";
import getStyleNamespace from "client/lib/get-style-namespace";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import type ProjectTemplate from "client/models/project-template";
import type CurrentUserService from "client/services/current-user";

const DEFAULT_TAG = "announcements";

export default class SuggestedTemplatesComponent extends DynamicIslandActionComponent {
  @service
  declare store: Store;

  @service
  declare currentUser: CurrentUserService;

  @tracked
  projectTemplates: ProjectTemplate[] = [];

  @tracked
  loading = false;

  @tracked
  columnCount = 0;

  actionName = "suggested templates";

  styleNamespace = getStyleNamespace("discovery/dashboard/primary-actions/suggested-templates");

  get visibleProjectTemplates(): ProjectTemplate[] {
    return shuffle(this.projectTemplates).slice(0, this.columnCount);
  }

  get templateTag(): string {
    return this.currentUser.targetingData?.getSuggestedTemplatesTag() ?? DEFAULT_TAG;
  }

  @action
  didInsert(): void {
    super.didInsert();
    void this.loadProjectTemplates();
  }

  @action
  didInsertGrid(element: HTMLElement): void {
    new ResizeObserver(() => requestAnimationFrame(() => this.calculateColumnCount(element))).observe(element);

    void this.calculateColumnCount(element);
  }

  @action
  onTemplateSelect(): void {
    this.onInteraction();
  }

  calculateColumnCount(element: HTMLElement): void {
    this.columnCount = getColumnCount(element);
  }

  private async loadProjectTemplates(): Promise<void> {
    this.loading = true;

    try {
      // @ts-expect-error
      this.projectTemplates = (await this.store.query("projectTemplate", {
        /* eslint-disable camelcase */
        per_page: 12,
        aspect_ratio_id: WIDE_ASPECT_RATIO_ID,
        tag: this.templateTag
        /* eslint-enable camelcase */
      })) as ProjectTemplate[];
    } finally {
      this.loading = false;
    }
  }
}
