import Component from "@glimmer/component";
import type { ColorTypes } from "../component";
import type ColorPreset from "client/lib/brand/color-preset";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  colorPreset: ColorPreset;
  colors: string[];
  updateColor(color: string, type: ColorTypes): void;
}

export default class BrandColorPresetEditColor extends Component<Args> {
  styleNamespace = getStyleNamespace("brand/color-preset/edit-colors");

  get colorPresetColors(): string[] {
    return this.args.colorPreset.colors;
  }
}
