import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { tracked } from "@glimmer/tracking";
import type ForgotController from "client/unauthenticated/forgot/controller";

export class ForgotRouteModel {
  @tracked
  isSubmitted = false;

  @tracked
  email = "";
}

export default class ForgotRoute extends Route {
  model(): ForgotRouteModel {
    return new ForgotRouteModel();
  }

  resetController(controller: ForgotController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);
  }
}
