import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export interface MenuItemArgs {
  disabled?: boolean;
  onClick?(): unknown;
}

export default class MenuItem extends Component<MenuItemArgs> {
  styleNamespace = getStyleNamespace("north-star/menu-item");

  @action
  onClick(): void {
    if (!this.args.disabled) {
      this.args.onClick?.();
    }
  }
}
