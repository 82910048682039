import { action } from "@ember/object";
import { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";

export class DragHelper {
  element?: HTMLElement;

  private state?: {
    target: HTMLElement;
    offset: { x: number; y: number };
    holdOffTime: Date;
    transaction: ExplicitTransaction;
  };

  get isDragging(): boolean {
    return !!this.state;
  }

  constructor(
    private update: (transaction: ExplicitTransaction, updateType: "before" | "after") => Promise<void>,
    private options = {
      reorderDelayMs: 500,
      scrollDelayMs: 300
    }
  ) {}

  @action
  onDragStart(event: DragEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const bounds = event.target.getBoundingClientRect();
    this.state = {
      target: event.target,
      offset: {
        x: bounds.x - event.x,
        y: bounds.y - event.y
      },
      holdOffTime: new Date(0),
      transaction: new ExplicitTransaction()
    };
    document.addEventListener("dragover", this.onDragOver);
  }

  @action
  async onDragOver(event: DragEvent): Promise<void> {
    event.preventDefault();

    const root = this.root;
    if (
      !this.state ||
      !root?.parentElement ||
      new Date().getTime() < this.state.holdOffTime.getTime() + this.options.reorderDelayMs
    ) {
      return;
    }

    const sibling = Array.from(root.parentElement.children).find(
      (e) => event.target instanceof Node && e.contains(event.target)
    );
    if (sibling === root || !(sibling instanceof HTMLElement)) {
      return;
    }

    const order = parseInt(root.style.order);
    const siblingOrder = parseInt(sibling.style.order);
    const orderDelta = siblingOrder - order;

    const siblingBounds = sibling.getBoundingClientRect();
    const rootBounds = root.getBoundingClientRect();
    const height = this.state.target.getBoundingClientRect().height;
    const y = event.y + this.state.offset.y;

    const lowThreshold = siblingBounds.top + rootBounds.height - height / 2;
    const highThreshold = siblingBounds.bottom - rootBounds.height - height / 2;

    if ((orderDelta < 0 && y < lowThreshold) || orderDelta < -1) {
      await this.performUpdate("before");
    } else if ((orderDelta > 0 && y > highThreshold) || orderDelta > 1) {
      await this.performUpdate("after");
    }
  }

  private async performUpdate(change: "before" | "after"): Promise<void> {
    const root = this.root;
    if (!root?.parentElement || !this.state) {
      return;
    }

    this.state.holdOffTime = new Date();

    // Fix scroll position in pixels to prevent scrolling from tracking a
    // specific element
    root.parentElement.scrollTo(0, root.parentElement.scrollTop);

    await this.update(this.state.transaction, change);

    setTimeout(() => {
      root.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, this.options.scrollDelayMs);
  }

  private get root(): HTMLElement | undefined {
    if (!this.element) {
      return undefined;
    }

    let root = this.element;
    while (!root.style.order) {
      if (!(root.parentElement instanceof HTMLElement)) {
        return undefined;
      }
      root = root.parentElement;
    }
    return root;
  }

  @action
  onDragEnd(): void {
    if (!this.state) {
      return;
    }
    document.removeEventListener("dragover", this.onDragOver);
    this.state = undefined;
  }
}
