import { tracked } from "@glimmer/tracking";
import type { Element } from "../../element";
import type { Logo } from "../logo";
import type { Scene } from "../scene";
import type { Text } from "../text";
import { VideoClip } from "../video";

export const durationModes = ["match text", "custom"] as const;
export type DurationMode = (typeof durationModes)[number];

/**
 * Also known as an element block.
 */
export class Caption {
  /** @internal */
  @tracked
  _texts: Text[] = [];

  /** @internal */
  @tracked
  _logos: Logo[] = [];

  /** @internal */
  @tracked
  _offset: number;

  /** @internal */
  @tracked
  _duration: number;

  /** @internal */
  @tracked
  _scene: Scene;

  /** @internal */
  @tracked
  _durationMode?: DurationMode;

  constructor(
    public readonly id: string,
    scene: Scene,
    texts: Text[] = [],
    offset: number,
    duration: number,
    logos: Logo[] = [],
    durationMode?: DurationMode
  ) {
    this._texts = texts;
    this._duration = duration;
    this._offset = offset;
    this._scene = scene;
    this._logos = logos;
    this._durationMode = durationMode;
  }

  public get captionColors(): string[] {
    const captionColorArray = new Set<string>();

    for (const text of this._texts) {
      for (const color of text.colors) {
        captionColorArray.add(color);
      }
    }

    for (const logo of this._logos) {
      for (const color of logo.colors) {
        captionColorArray.add(color);
      }
    }

    return [...captionColorArray];
  }

  get texts(): Text[] {
    return this._texts;
  }

  /** @internal */
  _appendTextElement(element: Text): void {
    this._texts = [...this._texts, element];
  }

  /** @internal */
  _removeTextElement(element: Text) {
    this._texts = this._texts.filter((e) => e !== element);
  }

  get text(): string {
    return this.texts
      .map((t) => t.content)
      .filter(Boolean)
      .join(" ");
  }

  get logos(): Logo[] {
    return this._logos;
  }

  /** @internal */
  _appendLogoElement(element: Logo): void {
    this._logos = [...this._logos, element];
  }

  /** @internal */
  _removeLogoElement(element: Logo) {
    this._logos = this._logos.filter((e) => e !== element);
  }

  get elements(): Element[] {
    return [...this.texts, ...this.logos];
  }

  get elementsSortedByLayer(): Element[] {
    return this.elements.sort((a, b) => {
      return a.layerOrder - b.layerOrder || Number(a.id) - Number(b.id);
    });
  }

  get duration(): number {
    return Number(this._duration.toFixed(2));
  }

  get durationMode(): DurationMode | undefined {
    return this._durationMode;
  }

  get computedDurationMode(): DurationMode {
    if (this._durationMode && this.durationModeIsValid(this._durationMode)) {
      return this._durationMode;
    } else {
      return this.defaultDurationMode;
    }
  }

  get offset(): number {
    return Number(this._offset.toFixed(2));
  }

  get end(): number {
    return Number((this.offset + this.duration).toFixed(2));
  }

  get scene(): Scene {
    return this._scene;
  }

  get hasContent(): boolean {
    return [...this.texts, ...this.logos].some((element) => element.hasContent);
  }

  public durationModeIsValid(durationMode: DurationMode): boolean {
    if (durationMode === "match text") {
      return this.hasText;
    } else {
      return durationMode === "custom";
    }
  }

  /** @internal */
  _setDuration(newDuration: number): void {
    if (this._duration !== newDuration) {
      this._duration = newDuration;
    }
  }

  /** @internal */
  _setOffset(newValue: number): void {
    if (this._offset !== newValue) {
      this._offset = newValue;
    }
  }

  /** @internal */
  _update(caption: Caption): void {
    const { _texts, _logos, _offset, _duration } = caption;
    Object.assign(this, { _texts, _logos, _offset, _duration });
  }

  private get defaultDurationMode(): DurationMode {
    if (this.hasText && !this.hasAudio) {
      return "match text";
    } else {
      return "custom";
    }
  }

  private get hasAudio(): boolean {
    return this._scene.background.asset instanceof VideoClip && !!this._scene.background.asset.hasAudio;
  }

  private get hasText(): boolean {
    return this._texts.length > 0;
  }
}
