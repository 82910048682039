import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type Folder from "client/models/folder";
import type FoldersService from "client/services/folders";

interface FolderTreeArgs {
  folders: Folder[];
  level: number;
  activeFolder: Folder;
  onDeleteFolder: (folder: Folder) => void;
  onAddFolder: (parent: Folder) => void;
  refresh: () => void;
  onExpandPanel: (panelName: string) => void;
  parentRouteName: string;
}

export default class FolderTreeComponent extends Component<FolderTreeArgs> {
  @service
  declare folders: FoldersService;

  elementId = guidFor(this);

  @tracked
  foldersToRender: { folder: Folder }[] = [];

  constructor(owner: object, args: FolderTreeArgs) {
    super(owner, args);
    this.setFoldersToRender(args.folders);
  }

  @action
  async refresh(): Promise<void> {
    this.setFoldersToRender(this.args.folders);
  }

  private setFoldersToRender(folders: Folder[]): void {
    this.foldersToRender = folders.map((folder) => ({
      folder
    }));
  }
}
