import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type LatestProjectPreviewsService from "client/services/latest-project-previews";

export default class HomeRoute extends Route {
  @service
  declare latestProjectPreviews: LatestProjectPreviewsService;

  afterModel(): void {
    void this.latestProjectPreviews.load();
  }
}
