import Serializer from "@ember-data/serializer";
import type Store from "@ember-data/store";
import type { ModelSchema } from "ember-data"; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export interface PexelsVideoQueryPayload {
  next_page: string;
  page: number;
  per_page: number;
  videos: PexelsVideoPayload[];
  total_results: number;
}

export interface PexelsVideoPayload {
  id: number;
  url: string;
  image: string;
  user: {
    name: string;
    url: string;
  };
  src: {
    original: string;
    large: string;
  };
  video_files: { link: string; quality: "sd" | "hd" }[];
}

enum RequestTypes {
  QUERY = "query",
  FIND_RECORD = "findRecord"
}

export default class PexelsVideoSerializer extends Serializer {
  // eslint-disable-next-line camelcase
  normalize(_: ModelSchema, { id, user, image, video_files }: PexelsVideoPayload): any {
    const hdVideoLink = video_files.find(({ quality }) => quality === "hd")?.link; // eslint-disable-line camelcase
    const sdVideoLink = video_files.find(({ quality }) => quality === "sd")?.link; // eslint-disable-line camelcase

    return {
      id,
      type: "pexels-video",
      attributes: {
        thumbImageUrl: image,
        thumbVideoUrl: sdVideoLink,
        previewImageUrl: image,
        previewVideoUrl: hdVideoLink || sdVideoLink,
        lowResVideoUrl: sdVideoLink,
        attribution: {
          author: user.name,
          authorUrl: user.url,
          provider: "Pexels"
        }
      }
    };
  }

  normalizeResponse(
    _: Store,
    model: ModelSchema,
    payload: PexelsVideoQueryPayload | PexelsVideoPayload | PexelsVideoPayload[],
    __: string | number,
    requestType: string
  ): any {
    if (requestType === RequestTypes.QUERY) {
      if (payload instanceof Array) {
        const data = payload.map((item) => this.normalize(model, item));
        return { data, included: [] };
      }

      const meta = {
        "total-count": (payload as PexelsVideoQueryPayload).total_results
      };

      const data = (payload as PexelsVideoQueryPayload).videos.map((item) => {
        return this.normalize(model, item);
      });

      return { data, included: [], meta };
    } else if (requestType === RequestTypes.FIND_RECORD) {
      const serialized = this.normalize(model, payload as PexelsVideoPayload);

      return { data: serialized };
    }
  }
}
