import { action } from "@ember/object";
import Quill from "quill";
import { Delta } from "quill/core";
import type { EmitterSource } from "quill/core/emitter";
import type { Range } from "quill/core/selection";
import type Op from "quill-delta/dist/Op";
import { descriptionToNumber } from "./fonts";
import DomUtils from "client/lib/dom-utils";
import type { Caption, Text, TextStyle, TextStyleProperties } from "client/lib/editor-domain-model";
import { CharacterRange } from "client/lib/editor-domain-model";
import { registerStyles } from "client/lib/text/editor/attributors";
import { setEditorStyles } from "client/lib/text/editor/styles";
import { convertTextToQuillContent } from "client/lib/text/text-styles";

const BLOCK_LEVEL_STYLES = ["font", "lineHeight", "textAlign", "padding"];

export const PLACEHOLDER_TEXT = "Click here to edit";

export interface EditableText {
  caption: Caption;
  text: Text;
  textStyle: TextStyle;
  applyStyle: (style: TextStyleProperties) => void;
  focus: (caretPosition?: "start" | "end") => void;
  enable: (isEnabled: boolean) => void;
}

interface IOptions {
  onTextChange: (contents: string) => void;
  onSelectionChange: (range: Range | undefined) => void;
  onBlur: (focusOut: boolean) => void;
  getText: () => Text;
  shiftFocus: (direction: -1 | 1) => void;
  plain: boolean;
  disableOnInit: boolean;
}

export class BasicTextEditor {
  public container: HTMLElement;
  public quill!: Quill;
  private oldContents = "";

  private savedFormats: Record<string, unknown> = {};

  constructor(parent: HTMLElement, private opts: IOptions) {
    this.container = DomUtils.createChildDiv(parent);
    this.quill = this.createEditor(this.container, opts.plain);

    this.spacebarFixForFirefox();
    this.quill.root.setAttribute("spellcheck", "false");

    this.registerKeys();

    this.setContent();

    this.addListeners(this.container);

    setEditorStyles(this.container, this.opts.plain, this.text);

    if (opts.disableOnInit) {
      this.quill.disable();
    }
  }

  private registerKeys(): void {
    const keys = {
      up: "up",
      down: "down",
      pageUp: 33,
      pageDown: 34
    };

    this.quill.keyboard.addBinding({ key: keys.up }, () => {
      if (this.quill.getSelection()?.index === 0) {
        this.opts.shiftFocus(-1);
        return false;
      }
      return true;
    });

    this.quill.keyboard.addBinding({ key: keys.down }, () => {
      if (this.quill.getSelection()?.index === this.quill.getLength() - 1) {
        this.opts.shiftFocus(1);
        return false;
      }
      return true;
    });

    this.quill.keyboard.addBinding({ key: keys.pageUp }, () => this.opts.shiftFocus(-1));
    this.quill.keyboard.addBinding({ key: keys.pageDown }, () => this.opts.shiftFocus(1));
  }

  get text(): Text {
    return this.opts.getText();
  }

  get selectedTextFormat(): Record<string, unknown> | undefined {
    if (!this.quill) {
      return;
    }
    if (this.quill.getLength() <= 1) {
      return this.savedFormats;
    }

    let selection = this.quill.getSelection() ?? undefined;

    while (selection) {
      const format = this.quill.getFormat(selection);
      if (format["color"]) {
        return format;
      }
      selection = selection.index >= 1 ? { index: selection.index - 1, length: 0 } : undefined;
    }
    return this.getAllFormatting();
  }

  public format(
    properties: Record<string, unknown>,
    source: EmitterSource = "api",
    forceFormatSelection = false
  ): void {
    this.applyFormat(properties, source, forceFormatSelection);
    this.saveFormats();
  }

  private applyFormat(properties: Record<string, unknown>, source: EmitterSource, forceFormatSelection = false): void {
    for (const property of Object.keys(properties)) {
      if (!forceFormatSelection && this.getShouldFormatAllText(property)) {
        this.quill.formatText(0, this.quill.getLength(), property, properties[property], source);
      } else {
        this.quill.format(property, properties[property], source);
      }
    }
  }

  private createEditor(container: HTMLElement, plain = false): Quill {
    return new Quill(container, {
      registry: registerStyles(plain),
      modules: {
        history: {
          maxStack: 0,
          userOnly: true
        },
        toolbar: false,
        keyboard: {
          bindings: {
            "list autofill": false,
            tab: {
              key: "",
              handler: (): boolean => true
            }
          }
        },
        clipboard: {
          matchers: [
            [Node.TEXT_NODE, this.savedFormatMatcher],
            [Node.ELEMENT_NODE, this.savedFormatMatcher]
          ]
        }
      }
    });
  }

  private addListeners(container: HTMLElement): void {
    this.quill.on(Quill.events.TEXT_CHANGE, this.handleTextChange);

    this.quill.on(Quill.events.SELECTION_CHANGE, this.handleSelectionChange.bind(this));

    const qlEditor = container.querySelector(".ql-editor") as HTMLElement;
    qlEditor.addEventListener("click", this.setSelectionIfNull.bind(this));
    qlEditor.addEventListener("focusout", this.onFocusOut);
  }

  private setSelectionIfNull(): void {
    if (this.getIsEmpty()) {
      const selection = window.getSelection();

      if (!selection) {
        return;
      }

      selection.setPosition(selection.focusNode, 1);
    }
  }

  private onFocusOut = (): void => {
    if (this.isEnabled()) {
      this.opts.onBlur(true);
    }
  };

  @action
  private handleSelectionChange(range: Range | undefined): void {
    this.opts.onSelectionChange(range);
    if (!range) {
      this.opts.onBlur(false);
    }
  }

  private handleTextChange = async (delta: Delta): Promise<void> => {
    const ops = delta.ops;

    // Adding an element to an empty editor
    if (ops.length === 1) {
      const op = ops[0];
      if (op?.insert) {
        this.applySavedFormats();
      }
    }

    // Replacing the contents of the editor with a single character. We see
    // can drop formatting when replacing "abcdef" with "c" because it retains
    // the letter "c"
    else if (
      this.quill.getLength() > 1 &&
      ops.find((op) => op.delete) &&
      ops.reduce((a, op) => a + (typeof op.retain === "number" ? op.retain : 0), 0) >= this.quill.getLength() - 1
    ) {
      this.applySavedFormats();
    }

    // Replacing the contents of the editor with a single without retaining
    // anything, like replacing "abcdef" with "z". Strangely, we only seem to
    // hit this case in Chrome but not Firefox
    else if (this.quill.getLength() > 1 && ops.length === 2 && ops[0]?.insert && ops[1]?.delete) {
      this.applySavedFormats();
    }

    // Regular typing
    else if (
      ops.length === 2 &&
      typeof ops[0]?.retain === "number" &&
      ops[0]?.retain &&
      ops[1]?.insert &&
      !ops[1].attributes?.["color"]
    ) {
      this.applySavedFormats({
        index: ops[0].retain,
        length: ops[1].insert.toString().length
      });
    }

    await this.applyText();
  };

  private applySavedFormats(range: Range = { index: 0, length: this.quill.getLength() }): void {
    Object.entries(this.savedFormats).forEach(([key, value]) => {
      this.quill.formatText(range, key, value, "api");
    });
  }

  private isEnabled(): boolean {
    return this.quill.root.isContentEditable;
  }

  public focus(): void {
    this.quill.focus();
  }

  public enable(isEnabled: boolean): void {
    if (this.isEnabled() !== isEnabled) {
      this.quill.enable(isEnabled);

      if (isEnabled) {
        this.quill.focus();
        this.setContent();
      } else {
        this.quill.blur();
      }
    }
  }

  public getAllFormatting(): Record<string, unknown> {
    return this.quill.getFormat(0, this.quill.getLength());
  }

  public setSelectionToEnd(): void {
    this.quill.setSelection(this.quill.getLength() - 1, 1);
  }

  get selectedText(): CharacterRange {
    const selection = this.quill.getSelection();
    return new CharacterRange(selection?.index ?? 0, selection?.length ?? 0);
  }

  set selectedText(selectedText: CharacterRange) {
    if (this.quill.hasFocus()) {
      this.quill.setSelection(selectedText.startOffset, selectedText.length);
    }
  }

  public pushUpdate(force = false): void {
    if (!this.quill.hasFocus() || force) {
      this.setContent();
    }
  }

  public setContent(): void {
    const content = convertTextToQuillContent(this.text);

    const delta = new Delta(content);
    const { selectedText } = this;
    this.quill.setContents(delta, "silent");
    this.selectedText = selectedText;
    this.oldContents = JSON.stringify(this.quill.getContents().ops);
    this.saveFormats();
  }

  private spacebarFixForFirefox(): void {
    this.quill.keyboard.addBinding(
      { key: " " },
      {
        suffix: /^$/
      },
      function (this: BasicTextEditor, range: Range): boolean {
        this.quill.insertText(range.index, " ", "user");
        return true;
      }
    );
  }

  private getShouldFormatAllText(property: string): boolean {
    return this.getIsSelectionEmpty() || BLOCK_LEVEL_STYLES.includes(property);
  }

  private saveFormats(): void {
    if (this.quill.getLength() > 1) {
      const formats = this.selectedTextFormat;
      if (formats) {
        if (formats["weight"]) {
          formats["weight"] = descriptionToNumber(formats["weight"] as string);
        }

        this.savedFormats = formats;
      }
    }
  }

  private getIsEmpty(): boolean {
    return this.quill.getLength() === 1;
  }

  private getIsSelectionEmpty(): boolean {
    return !(this.quill.getSelection(true) && this.quill.getSelection(true).length);
  }

  private async applyText(): Promise<void> {
    const contents = JSON.stringify(this.quill.getContents().ops);
    if (contents === this.oldContents) {
      return;
    }
    this.oldContents = contents;
    this.opts.onTextChange(contents);
  }

  @action
  private savedFormatMatcher(_node: any, delta: Delta): Delta {
    const ops: Op[] = [];
    delta.ops.forEach((op: Op) => {
      if (op.insert && typeof op.insert === "string") {
        ops.push({
          insert: op.insert,
          attributes: this.savedFormats
        });
      }
    });
    delta.ops = ops;
    return delta;
  }
}
