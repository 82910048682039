import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

interface DebugRenderMenuComponentArgs {
  renderProject: () => void;
  project: Project;
}

export default class DebugRenderMenuComponent extends Component<DebugRenderMenuComponentArgs> {
  @tracked
  debugMenuOpen = false;

  @tracked
  menuToggleElement?: HTMLElement;

  styleNamespace = getStyleNamespace("debug-render-menu");

  @action
  setMenuToggle(element: HTMLElement): void {
    this.menuToggleElement = element;
  }

  @action
  toggleDebugMenu(): void {
    this.debugMenuOpen ? this.closeDebugMenu() : this.openDebugMenu();
  }

  openDebugMenu(): void {
    this.debugMenuOpen = true;
  }

  @action
  closeDebugMenu(): void {
    this.debugMenuOpen = false;
  }
}
