import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type ProjectPreview from "client/models/project-preview";
import type VideoPage from "client/models/video-page";
import type ConfirmService from "client/services/confirm";
import type TrackingService from "client/services/tracking";

interface ExportActionsShareArgs {
  project: Project;
  projectPreview: ProjectPreview;
  disabled?: boolean;
}

export default class ExportActionsShareComponent<
  A extends ExportActionsShareArgs = ExportActionsShareArgs
> extends Component<A> {
  @service
  declare confirm: ConfirmService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @tracked
  videoPage?: VideoPage;

  @tracked
  loading = false;

  styleNamespace = getStyleNamespace("export-actions/share");

  get videoPageUrl(): string | undefined {
    return this.videoPage?.url;
  }

  get published(): boolean {
    return this.project.published;
  }

  get toggleSliderMessage(): string {
    return `Link ${this.published ? "enabled" : "disabled"}`;
  }

  get project(): Project {
    return this.args.project ?? this.args.projectPreview;
  }

  @action
  async didInsert(): Promise<void> {
    this.videoPage ??= await this.getVideoPage();
  }

  @action
  async toggleLinkEnabled(): Promise<void> {
    if (this.videoPage) {
      await this.unpublish();
    } else {
      await this.publish();
    }
  }

  get showComments(): boolean {
    return this.project.showComments;
  }

  @action
  async toggleCommentsEnabled(): Promise<void> {
    this.project.showComments = !this.project.showComments;
    await this.project.save();
  }

  get toggleCommentsMessage(): string {
    return this.showComments ? "Comments enabled" : "Comments disabled";
  }

  @action
  async toggleTitleHidden(): Promise<void> {
    if (this.videoPage) {
      this.videoPage.titleHidden = !this.videoPage.titleHidden;
      await this.videoPage.save();
    }
  }

  @action
  async updateProjectTitle(): Promise<void> {
    if (this.args.project) {
      await this.args.project.save();
    }
  }

  get titleHiddenTooltip(): string {
    if (this.videoPage?.titleHidden) {
      return "Show title";
    }
    return "Hide title";
  }

  private async publish(): Promise<void> {
    this.videoPage = await this.project.publish();
  }

  private async unpublish(): Promise<void> {
    if (this.videoPage && (await this.confirmUnpublish())) {
      await this.project.unpublish(this.videoPage);
      this.videoPage = undefined;
    }
  }

  private confirmUnpublish(): Promise<boolean> {
    return this.confirm.open({
      title: "Disable share link?",
      confirmLabel: "Disable",
      cancelLabel: "Cancel",
      message: `This link will no longer work for anyone who has it. This includes sharing to social platforms and embed codes. You can re-enable it later.`
    });
  }

  private async getVideoPage(): Promise<VideoPage | undefined> {
    this.loading = true;

    try {
      return await this.project.videoPage;
    } finally {
      this.loading = false;
    }
  }
}
