import type Project from "client/models/project";
import type ShutterstockCredits from "client/models/shutterstock-credits";

export default class ShutterstockPurchases {
  constructor(
    public project: Project,
    public shutterstockCredits: ShutterstockCredits,
    public hasPayPerClip: boolean
  ) {}

  public get creditsSystemExceeded(): boolean {
    return this.hasCreditsSystemOnly && this.shutterstockCredits?.denyBuild;
  }

  public get paymentRequired(): boolean {
    return this.hasPayPerClip && this.unpaidClipsCount > 0;
  }

  // clips not covered by remaining credits counts
  public get unpaidClipsCount(): number {
    return Math.max(this.project.unpaidLicensesCost - this.remainingCreditsCount, 0);
  }

  public get mixedSystemCreditsExceeded(): boolean {
    return this.hasMixedSystem && this.shutterstockCredits?.denyBuild;
  }

  public get denyBuild(): boolean {
    return this.creditsSystemExceeded || this.paymentRequired;
  }

  private get hasCreditsSystemOnly(): boolean {
    return !this.hasPayPerClip;
  }

  private get hasMixedSystem(): boolean {
    return this.hasPayPerClip && this.remainingCreditsCount > 0;
  }

  private get remainingCreditsCount(): number {
    return this.shutterstockCredits?.remainingCreditsCount || 0;
  }
}
