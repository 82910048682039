import { service } from "@ember/service";
import { attr } from "@ember-data/model";
import type { ImageConfig, Asset } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import TrackingEvents from "client/events";
import type { Timeline } from "client/lib/editor-domain-model";
import { favorable, FAVORABLE_TYPES } from "client/models/favorable";
import SelectableMediaAsset from "client/models/selectable-media-asset";
import type HoneybadgerService from "client/services/honeybadger";
import type UnsplashService from "client/services/unsplash";

@favorable
export default class UnsplashPhoto extends SelectableMediaAsset {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare unsplash: UnsplashService;

  @attr("string")
  description!: string;

  @attr("string")
  color!: string;

  @attr("number")
  width!: number;

  @attr("number")
  height!: number;

  @attr("string")
  imageThumbUrl!: string;

  @attr("string")
  imageSmallUrl!: string;

  @attr("string")
  imageRegularUrl!: string;

  @attr("string")
  imageFullUrl!: string;

  @attr("string")
  imageRawUrl!: string;

  @attr("string")
  downloadUrl!: string;

  @attr("json")
  attribution!: object;

  @attr("string")
  authorFirstName!: string;

  @attr("string")
  authorLastName!: string;

  readonly thumbVideoUrl = undefined;

  readonly zymbolCategory = ZymbolCategory.IMAGE;

  readonly favorableType = FAVORABLE_TYPES.UNSPLASH;

  metadataForFavorite = {};

  get author(): string {
    return [this.authorFirstName, this.authorLastName].filter((v) => v).join(" ");
  }

  get thumbImageUrl(): string {
    return this.imageThumbUrl;
  }

  get favorableProviderAssetId(): string {
    return this.id;
  }

  asZymbolConfig(): Partial<ImageConfig> & { asset: Asset } {
    const { id, imageThumbUrl: previewImageUrl, description: name, imageFullUrl: url } = this;

    return {
      asset: {
        id,
        type: "unsplash-photo"
      },
      previewImageUrl,
      url,
      name
    };
  }

  async trackAddToTimeline(timeline: Timeline, params = {}): Promise<void> {
    try {
      await this.unsplash.markUnsplashPhotoDownloaded(this);
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
    }

    await super.trackAddToTimeline(timeline, params);
  }

  protected get assetHeight(): number {
    return this.height;
  }

  protected get assetWidth(): number {
    return this.width;
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_STOCK_CONTENT;

  get trackUsageData(): object {
    return {
      provider: "unsplash",
      providerAssetId: this.id,
      type: "image",
      name: this.description
    };
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    unsplashPhoto: UnsplashPhoto;
  }
}
