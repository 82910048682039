import Model, { attr } from "@ember-data/model";
import type { StripeAccount } from "client/services/stripe";

export default class Charge extends Model {
  @attr("string")
  declare amount: string;

  @attr("date")
  declare created: Date;

  @attr("string")
  declare currency: string;

  @attr("string")
  declare description: string;

  @attr("string")
  declare receiptNumber: string;

  @attr("string")
  declare stripeAccount: StripeAccount;

  @attr("string")
  declare invoiceNumber: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    charge: Charge;
  }
}
