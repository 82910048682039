import type ApplicationInstance from "@ember/application/instance";
import type { UnwrapComputedPropertySetters } from "@ember/object/-private/types";
import type routerService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { ZymbolGroupLayer, DEFAULT_PROJECT_SCENE_DURATION, AudioClipCategory } from "renderer-engine";
import { DEFAULT_DUCKED_LEVEL } from "renderer-engine/dist/src/lib/audio/volume";
import type AuthService from "./auth";
import type Audible from "client/models/audible";
import type AudioClip from "client/models/audio-clip";
import type { ExportRenderOptions } from "client/models/export-render-options";
import type Project from "client/models/project";
import type ProjectExport from "client/models/project-export";
import type ProjectRender from "client/models/project-render";
import type ProjectScene from "client/models/project-scene";
import type ZymbolGroup from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type FontsService from "client/services/fonts";
import type LayersService from "client/services/layers";
import type NotificationsService from "client/services/notifications";
import type ProjectScenesService from "client/services/project-scenes";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type ZymbolGroupsService from "client/services/zymbol-groups";

const RENDER_POLL_INTERVAL = 2500;

const PROJECT_DOWNLOAD_FAILURE_NOTIFICATION = "Dang! There was a problem generating your video";
export const PROJECT_SETTINGS_UPDATE_FAILURE_NOTIFICATION = "Oh nuts! There was a problem updating your video";
export const PROJECT_DUPLICATION_FAILURE_NOTIFICATION = "Oh dear! There was a problem duplicating your video";
export const PROJECT_SCENE_APPEND_FAILURE_NOTIFICATION = "There was a problem adding a new scene to your project";
export const PROJECT_SCENE_APPEND_SUCCESS_NOTIFICATION = "Your scene has been added";

export type ProjectSettings = Pick<UnwrapComputedPropertySetters<Project>, keyof Project>;

export interface NewProjectSceneOptions {
  project: Project;
  afterScene?: ProjectScene;
  duration?: number;
}

export default class ProjectsService extends Service {
  @service
  declare store: Store;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare ajax: AjaxService;

  @service
  declare router: routerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare layers: LayersService;

  @service
  declare auth: AuthService;

  @service
  declare fonts: FontsService;

  constructor(owner: ApplicationInstance) {
    super(owner);
  }

  async getProjectCount(): Promise<number> {
    // @ts-expect-error
    const { meta } = await this.store.query("project", { per_page: 1 }); // eslint-disable-line camelcase

    return meta?.["total-count"] ?? 0;
  }

  async getProject(projectId: string): Promise<Project> {
    return this.store.peekRecord("project", projectId) ?? this.store.findRecord("project", projectId);
  }

  async getCurrentUsersProjects(): Promise<Project[]> {
    return (await this.store.findAll("project", { reload: true })) as unknown as Project[];
  }

  async updateProjectTitle(project: Project, title: string): Promise<Project> {
    project.title = title;

    try {
      await project.save();
      this.notifications.success("Your project's title has been updated");
      return project;
    } catch (err) {
      this.notifications.error("There was a problem updating your project's title");
      throw err;
    }
  }

  async updateProjectSettings(project: Project, settings: ProjectSettings): Promise<Project> {
    const oldAspectRatio = project.aspectRatio.get("id");
    const oldBrandStyle = project.brandStyleId;

    project.setProperties(settings);

    if (settings.aspectRatio && settings.aspectRatio.id !== oldAspectRatio) {
      await this.resetBackgroundLayers(project);
    }

    try {
      await project.save();

      if (settings.aspectRatio && settings.aspectRatio.id !== oldAspectRatio) {
        this.timelineEvents.publish(TimelineEvents.ASPECT_RATIO_CHANGED, settings.aspectRatio);
      }

      if (settings.brandStyleId !== oldBrandStyle) {
        this.timelineEvents.publish(TimelineEvents.BRAND_STYLE_CHANGED, settings.brandStyleId);
      }

      return project;
    } catch (err) {
      this.notifications.error(PROJECT_SETTINGS_UPDATE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  private async resetBackgroundLayers(project: Project): Promise<void> {
    const backgrounds = await project.backgrounds;
    await Promise.all(
      backgrounds.map((background: ZymbolGroup) => {
        return background.firstZymbol!.resetBackgroundDimensions();
      })
    );
  }

  async createAudioClipForProject(
    audible: Audible,
    project: Project,
    startTime: number,
    duration: number,
    category = AudioClipCategory.MUSIC
  ): Promise<AudioClip> {
    const audioClip = this.store.createRecord("audioClip", {
      audibleType: audible.type,
      audibleId: audible.id,
      project,
      volume: 1,
      startTime: startTime ?? 0,
      duration: duration ?? -1,
      trimStart: 0,
      loop: category === AudioClipCategory.MUSIC,
      mute: false,
      fadeIn: false,
      fadeOut: category === AudioClipCategory.MUSIC,
      category,
      audioDuckingLevel: DEFAULT_DUCKED_LEVEL
    });

    await audioClip.save();

    return audioClip;
  }

  async renderProject(project: Project, options: ExportRenderOptions): Promise<ProjectRender> {
    const projectExport = await this.createProjectExport(project, options);
    const projectRender = await projectExport.projectRender;
    await project.reload();
    if (options.progressMonitored !== false) {
      void this.monitorRenderProgress(projectRender);
    }

    return projectRender;
  }

  private async createProjectExport(
    project: Project,
    { cacheReadEnabled = true, thumbnailOptions }: ExportRenderOptions
  ): Promise<ProjectExport> {
    try {
      const projectExport = this.store.createRecord("projectExport", {
        project,
        cacheReadEnabled,
        thumbnailFrame: thumbnailOptions?.frame,
        thumbnailPath: thumbnailOptions?.path
      });
      return projectExport.save();
    } catch (err) {
      this.notifications.error(PROJECT_DOWNLOAD_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async monitorRenderProgress(projectRender: ProjectRender): Promise<void> {
    const project = await projectRender.project;
    let isLatest = true;

    while (!projectRender.completed && isLatest) {
      await new Promise((resolve) => setTimeout(resolve, RENDER_POLL_INTERVAL));
      await project.reload();
      await project.latestRender;
      isLatest = project.latestRender?.get("id") === projectRender.id;

      if (isLatest) {
        await projectRender.reload();
      }
    }

    // Reload the user as credits (such as Shutterstock credits) may have been used by the
    // render and we want to get the latest values.
    await Promise.all([this.auth.reloadUser(), project.reloadStockLicenses()]);

    if (isLatest) {
      if (projectRender.successful) {
        this.notifications.success("Your download is ready");
        return;
      }

      if (projectRender.failed) {
        this.notifications.error(PROJECT_DOWNLOAD_FAILURE_NOTIFICATION);
        return;
      }

      this.notifications.error("Your download failed to render in time");
    }
  }

  async copyProject(project: Project): Promise<Project> {
    try {
      const copy = await project.duplicate();

      return copy;
    } catch (err) {
      const notification = project.template
        ? "There was a problem adding this video to your account"
        : PROJECT_DUPLICATION_FAILURE_NOTIFICATION;
      this.notifications.error(notification);
      throw err;
    }
  }

  async addBlankProjectScene(options: NewProjectSceneOptions): Promise<ProjectScene> {
    const newProjectScene = this.store.createRecord("projectScene", {
      project: options.project
    });

    if (!newProjectScene) {
      this.notifications.error(PROJECT_SCENE_APPEND_FAILURE_NOTIFICATION);
      throw Error("Failed to add new `ProjectScene` to `Project`");
    }

    await newProjectScene.save();

    await this.addProjectScene(newProjectScene, options);

    return newProjectScene;
  }

  async addProjectScene(newProjectScene: ProjectScene, { project, afterScene }: NewProjectSceneOptions): Promise<void> {
    if (afterScene) {
      await this.projectScenes.reorderProjectScene(project, newProjectScene, afterScene.order + 1);
    } else {
      await project.reload();
    }

    this.notifications.success(PROJECT_SCENE_APPEND_SUCCESS_NOTIFICATION);
  }

  /**
   * Add a `ProjectScene` at a given time. It will insert a blank `ZymbolGroup` for each `Layer` on the `ProjectScene`
   */
  async addProjectSceneWithDefaults({
    project,
    afterScene,
    duration = DEFAULT_PROJECT_SCENE_DURATION
  }: NewProjectSceneOptions): Promise<ProjectScene> {
    const projectScene = await this.addBlankProjectScene({ project, afterScene });
    const layers = await projectScene.layers;
    const nonTextLayers = layers.filter((layer) => layer.name !== ZymbolGroupLayer.TEXT);
    await Promise.all(nonTextLayers.map((layer) => this.layers.addTextZymbolGroup(layer, 0, duration)));

    return projectScene;
  }

  async appendSceneWithBackground({
    project,
    afterScene,
    duration = DEFAULT_PROJECT_SCENE_DURATION
  }: NewProjectSceneOptions): Promise<ProjectScene> {
    const projectScene = await this.addProjectSceneWithDefaults({ project, afterScene, duration });
    await this.projectScenes.getOrCreateBackgroundZymbol(projectScene);
    return projectScene;
  }
}
