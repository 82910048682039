import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type AuthService from "./auth";
import { AuthEvents } from "./auth";
import defer from "client/lib/defer";
import formatMoney from "client/lib/format-money";
import type Plan from "client/models/plan";
import {
  MIDDLE_TIER,
  PlanCurrencies,
  PlanInterval,
  DEFAULT_PLAN_INTERVAL,
  SkuCode,
  TOP_TIER
} from "client/models/plan";

export default class PlansService extends Service {
  @service
  declare store: Store;

  @service
  declare auth: AuthService;

  @tracked
  plans: Array<Plan> = [];

  loaded = defer<Plan[]>();

  // Called by app/initializers/plans.ts not Ember framework instantiating an object
  initialize(): void {
    this.auth.on(AuthEvents.USER_LOADED, this.loadPlans);
  }

  @action
  private async loadPlans(): Promise<void> {
    // @ts-expect-error
    this.plans = await this.store.findAll("plan", { reload: true });
    this.loaded.resolve(this.plans);
  }

  public currentPlan(): SkuCode {
    if (this.auth.currentFullSubscription) {
      return this.auth.currentFullSubscription?.plan?.skuCode;
    } else if (this.auth.currentSubscription) {
      return this.auth.currentSubscription?.plan?.skuCode;
    } else {
      return SkuCode.FREE;
    }
  }

  public getCorrespondingPlan(plan: Plan): Plan | undefined {
    // plan from the current plans set with the same SKU code and interval, but different ID
    const siblingPlan = this.getSiblingPlan(plan, false);

    if (siblingPlan) {
      return siblingPlan;
    } else {
      const correspondingPlanSku = this.getCorrespondingPlanSku(plan);
      // plan from the current plans set with the same interval and SKU code of the same tier
      return this.correspondingPlan(correspondingPlanSku, plan.interval);
    }
  }

  public getSiblingPlan(plan: Plan, oppositeInterval = true): Plan | undefined {
    return this.plans.find(({ id, skuCode, interval }) => {
      return id !== plan.id && skuCode === plan.skuCode && interval === this.getSiblingInterval(plan, oppositeInterval);
    });
  }

  public getTopTierPlan(interval?: PlanInterval): Plan | undefined {
    return this.getPlanForTier(TOP_TIER, interval);
  }

  public getMiddleTierPlan(interval?: PlanInterval): Plan | undefined {
    return this.getPlanForTier(MIDDLE_TIER, interval);
  }

  public getHigherTierPlan(plan: Plan | undefined): Plan | undefined {
    if (plan?.isMiddleTierPlan) {
      return this.getTopTierPlan(plan.interval);
    } else {
      return undefined;
    }
  }

  private getPlanForTier(tierSkus: SkuCode[], planInterval?: PlanInterval): Plan | undefined {
    const interval = planInterval ?? DEFAULT_PLAN_INTERVAL;
    return this.plans.find((plan) => tierSkus.includes(plan.skuCode) && plan.interval === interval);
  }

  public async getDefaultTrialPlan(): Promise<Plan> {
    await this.loaded;
    const trialPlans = this.plans.filter(({ skuCode }) => TOP_TIER.includes(skuCode));

    const monthlyTrialPlan = trialPlans.find(({ interval }) => interval === PlanInterval.MONTH);
    const yearlyTrialPlan = trialPlans.find(({ interval }) => interval === PlanInterval.YEAR);
    const defaultTrialPlan = monthlyTrialPlan ?? yearlyTrialPlan;

    if (!defaultTrialPlan) {
      throw "No default trial Plan found";
    }

    return defaultTrialPlan;
  }

  public getFreePlan(): Plan | undefined {
    return this.plans.find(({ skuCode }) => skuCode === SkuCode.FREE);
  }

  public getCurrency(): string {
    return this.plans[0]?.currency ?? PlanCurrencies.USD;
  }

  public getDiscountAmount(costlierPlan: Plan, cheaperPlan: Plan): string {
    const discount = this.calculateDiscount(costlierPlan, cheaperPlan);
    return formatMoney(costlierPlan.currencySymbol, discount, costlierPlan.currencyIndicator);
  }

  public findPlan(skuCode: SkuCode, interval?: PlanInterval): Plan | undefined {
    interval ??= DEFAULT_PLAN_INTERVAL;

    return this.plans.find((plan) => {
      return plan.skuCode === skuCode && plan.interval === interval;
    });
  }

  public findPlanById(id: string): Plan | undefined {
    return this.plans.find((plan) => plan.id === id);
  }

  private calculateDiscount(costlierPlan: Plan, cheaperPlan: Plan): number {
    const costlierPlanYearlyAmount = this.getPlanYearlyAmount(costlierPlan);
    const cheaperPlanYearlyAmount = this.getPlanYearlyAmount(cheaperPlan);

    if (costlierPlan.isYearly) {
      return cheaperPlanYearlyAmount - costlierPlanYearlyAmount;
    } else {
      return costlierPlanYearlyAmount - cheaperPlanYearlyAmount;
    }
  }

  private getPlanYearlyAmount({ monetaryUnits, isYearly }: Plan): number {
    return monetaryUnits * (isYearly ? 1 : 12);
  }

  private getSiblingInterval(plan: Plan, oppositeInterval: boolean): PlanInterval {
    if (oppositeInterval) {
      return plan.isYearly ? PlanInterval.MONTH : PlanInterval.YEAR;
    } else {
      return plan.interval;
    }
  }

  private getCorrespondingPlanSku(plan: Plan): string | undefined {
    if (plan.isTopTierPlan) {
      return this.getTopTierPlan(plan.interval)?.skuCode;
    } else if (plan.isMiddleTierPlan) {
      return this.getMiddleTierPlan(plan.interval)?.skuCode;
    } else {
      return undefined;
    }
  }

  private correspondingPlan(skuCode: string | undefined, interval: PlanInterval): Plan | undefined {
    return this.plans.find((plan) => plan.skuCode === skuCode && plan.interval === interval);
  }
}

declare module "@ember/service" {
  interface Registry {
    plans: PlansService;
  }
}
