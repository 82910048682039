import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewDuplicateArgs {
  projectPreview: ProjectPreview;
  cancel(): unknown;
  duplicate(project: Project, properties: Partial<Project>): unknown;
}

export default class ProjectPreviewDuplicateComponent extends Component<ProjectPreviewDuplicateArgs> {
  styleNamespace = getStyleNamespace("discovery/project-preview/duplicate");

  @tracked
  loading = false;

  @tracked
  project?: Project;

  @action
  async loadProject(): Promise<void> {
    this.loading = true;
    try {
      this.project ??= await this.args.projectPreview.project;
    } finally {
      this.loading = false;
    }
  }
}
