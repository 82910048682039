import { action } from "@ember/object";
import { service } from "@ember/service";
import { isPresent } from "@ember/utils";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Instance, ModifierArguments, Options, OptionsGeneric, Placement, VirtualElement } from "@popperjs/core";
import { createPopper } from "@popperjs/core";
import maxSize from "popper-max-size-modifier";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PopoverService from "client/services/popover";

const ESCAPE_KEY = "Escape";

const MAX_SIZE_CLEARANCE = 24; // 1.5 rem in px

const applyMaxSize = {
  name: "applyMaxSize",
  enabled: true,
  phase: "write",
  requires: ["maxSize"],
  fn({ state }: ModifierArguments<Options>): void {
    // @ts-expect-error
    const { height } = state.modifiersData.maxSize;

    state.elements.popper.style.maxHeight = `${height - MAX_SIZE_CLEARANCE}px`;
  }
};

export const DEFAULT_POPPER_OPTIONS: Partial<OptionsGeneric<object>> = {
  placement: "bottom-start",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 20]
      }
    },
    {
      name: "preventOverflow",
      options: {
        padding: 10
      }
    },
    applyMaxSize,
    maxSize
  ],
  strategy: "fixed"
};

export interface PopoverInstanceComponentArgs {
  bindEventsToTarget?: boolean;
  dismissOnClick?: boolean;
  position?: Placement;
  open: boolean;
  padding?: boolean;
  arrow?: boolean;
  onOpen?(): unknown;
  onClose(): unknown;
  scrollable?: boolean;
  offset?: [number, number];
  role?: string;
  target?: HTMLElement | VirtualElement;
}

export default class PopoverElementComponent extends Component<PopoverInstanceComponentArgs> {
  @service
  declare popover: PopoverService;

  @tracked
  popperInstance?: Instance;

  private bound = false;

  private _element?: HTMLElement;

  styleNamespace = getStyleNamespace("north-star/popover/instance");

  get hidden(): boolean {
    return !this.created;
  }

  get created(): boolean {
    return isPresent(this.popperInstance);
  }

  get target(): HTMLElement | VirtualElement | undefined {
    return this.args.target ?? this._element?.parentElement ?? undefined;
  }

  get position(): string | undefined {
    return this.args.position ?? DEFAULT_POPPER_OPTIONS.placement;
  }

  get role(): string {
    return this.args.role ?? "dialog";
  }

  get bindEventsToTarget(): boolean {
    return this.args.bindEventsToTarget ?? true;
  }

  private getPopperOptions(): Partial<OptionsGeneric<object>> {
    const options = { ...DEFAULT_POPPER_OPTIONS };

    if (this.args.position) {
      options.placement = this.args.position;
    }

    if (this.args.scrollable) {
      options.modifiers = options.modifiers?.concat([maxSize, applyMaxSize]);
    }

    if (this.args.offset) {
      const offset: any = options.modifiers?.find((mod: any) => {
        return mod.name === "offset";
      });
      if (offset) {
        offset.options.offset = this.args.offset;
      }
    }
    return options;
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this._element = element;

    // Close all other popover instances
    this.popover.trigger("close");

    // Wait for any existing clicks to resolve. This is an issue when the popper is triggered programatically
    requestAnimationFrame(() => {
      this.createPopperInstance(element);
      this.bindEventListeners();
    });
  }

  @action
  onClick(): void {
    if (this.args.dismissOnClick) {
      this.close();
    }
  }

  willDestroy(): void {
    if (!this.args.dismissOnClick) {
      this.close();
    }
    super.willDestroy();
  }

  createPopperInstance(element: HTMLElement): void {
    if (this.created) {
      return;
    }

    if (!this.target) {
      throw Error("Missing required `target`");
    }

    const options = this.getPopperOptions();

    if (this.target) {
      this.popperInstance = createPopper(this.target, element, options);
    }

    this.args.onOpen?.();
  }

  private destroyPopperInstance(): void {
    this.popperInstance?.destroy();
    this.popperInstance = undefined;
  }

  private bindEventListeners(): void {
    if (this.bound || !this.created) {
      return;
    }

    document.addEventListener("keydown", this.onDocumentKeydown);
    document.addEventListener("click", this.onDocumentClick);
    if (this.bindEventsToTarget && this.target instanceof HTMLElement) {
      this.target?.addEventListener("click", this.onDocumentClick);
    }
    this.bound = true;
  }

  private unbindEventListeners(): void {
    if (this.bound) {
      document.removeEventListener("keydown", this.onDocumentKeydown);
      document.removeEventListener("click", this.onDocumentClick);
      if (this.bindEventsToTarget && this.target instanceof HTMLElement) {
        this.target?.removeEventListener("click", this.onDocumentClick);
      }
      this.bound = false;
    }
  }

  @action
  close(): void {
    this.destroyPopperInstance();
    this.unbindEventListeners();

    if (this.args.onClose) {
      this.args.onClose();
    } else {
      throw Error("`onClose` argument is required for the popover instance");
    }
  }

  @action
  onDocumentClick(ev: MouseEvent): void {
    if (!this.containsElement(ev.target as HTMLElement)) {
      ev.preventDefault();
      ev.stopPropagation();
      this.close();
    }
  }

  @action
  onDocumentKeydown(ev: KeyboardEvent): void {
    if (ev.key === ESCAPE_KEY) {
      this.close();
    }
  }

  private containsElement(element: HTMLElement): boolean {
    return !!this._element?.contains(element);
  }
}
