import { action } from "@ember/object";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type StorageService from "client/services/storage";

export class LoginRouteModel {
  @tracked
  email = "";

  @tracked
  password = "";

  constructor(email?: string) {
    this.email = email ?? "";
  }
}

export const UNAUTHENTICATED_EMAIL_KEY = "unauthenticated.email";

export default class LoginRoute extends Route<LoginRouteModel> {
  @service
  private declare storage: StorageService;

  model(): LoginRouteModel {
    return new LoginRouteModel(this.storage.getItem(UNAUTHENTICATED_EMAIL_KEY, true));
  }

  @action
  willTransition(): boolean {
    const model = this.modelFor("unauthenticated.login") as LoginRouteModel;
    this.storage.setItem(UNAUTHENTICATED_EMAIL_KEY, model.email, true);

    return true;
  }

  /* eslint-disable camelcase */
  queryParams = {
    utm_source: {
      refreshModel: false
    },
    utm_medium: {
      refreshModel: false
    },
    utm_campaign: {
      refreshModel: false
    },
    utm_content: {
      refreshModel: false
    }
  };
  /* eslint-enable camelcase */
}
