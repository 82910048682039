import type { Position, Bounds, Size } from "renderer-engine";
export { bottomRight, intersection, intersects, extents, translate } from "renderer-engine";
import type { Rect } from "client/lib/editor-domain-model";

/** The identity for geometry where 0,0 is the top-left corner and 1,1 is the
 * bottom right. */
export const unitGeometry: Bounds = { x: 0, y: 0, width: 1, height: 1 };

/** Convert a position from one co-ordinate space to another, see `convertRect` */
export function convertPosition(point: Position, from: Bounds, to: Bounds): Position {
  return {
    x: ((point.x - from.x) / from.width) * to.width + to.x,
    y: ((point.y - from.y) / from.height) * to.height + to.y
  };
}

/** Convert a size from one co-ordinate space to another, see `convertRect` */
export function convertSize(size: Size, from: Bounds, to: Bounds): Size {
  return {
    width: (size.width / from.width) * to.width,
    height: (size.height / from.height) * to.height
  };
}

/** Convert a rectangle from one co-ordinate space to another. This maintains
 * the same relative position within that co-ordinate space. You can think of
 * these co-ordinate spaces as different numerical representations of the same
 * position in the same rectangle.
 *
 * Perhaps the most useful case is converting things into and out of
 * `unitGeometry` which is used by the rendering canvas. An example is that
 */
export function convertRect(rect: Bounds, from: Bounds, to: Bounds): Bounds {
  return {
    ...convertPosition(rect, from, to),
    ...convertSize(rect, from, to)
  };
}

/** Convert from co-ordinates with top-left (x1, y1), and bottom-right (x2, y2)
 *  to a rectangle with width and height */
export function fromCoOrdinates(x1: number, y1: number, x2: number, y2: number): Bounds {
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1
  };
}

/** Convert from a rectangle with width and height to top-left (x1, y1) and
 * bottom-right (x2,y2) points */
export function toCoOrdinates(rect: Bounds): [number, number, number, number] {
  return [rect.x, rect.y, rect.x + rect.width, rect.y + rect.height];
}

export const getCenterCoords = (rect: Rect): Position => {
  const x = rect.x + rect.width / 2;
  const y = rect.y + rect.height / 2;

  return { x, y };
};

export const calculateTopLeftFromCenterCoords = (centerCoords: Position, rect: Size): Position => {
  const x = centerCoords.x - rect.width / 2;
  const y = centerCoords.y - rect.height / 2;

  return {
    x,
    y
  };
};

/** Move a point */
export function translatePosition(position: Position, offset: Position): Position {
  return {
    x: position.x + offset.x,
    y: position.y + offset.y
  };
}

/** Scale a point */
export function scalePosition(position: Position, scale: Position | number): Position {
  return {
    x: position.x * (typeof scale === "number" ? scale : scale.x),
    y: position.y * (typeof scale === "number" ? scale : scale.y)
  };
}

/** Scale a size */
export function scaleSize(size: Size, scale: Size | number): Size {
  return {
    width: size.width * (typeof scale === "number" ? scale : scale.width),
    height: size.height * (typeof scale === "number" ? scale : scale.height)
  };
}

/** Scale a size */
export function addSize(size1: Size, size2: Size): Size {
  return {
    width: size1.width + size2.width,
    height: size1.height + size2.height
  };
}
