import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";
import type UserAsset from "client/models/user-asset";

interface AssetThumbnailActionsArgs {
  asset: SelectableAsset;
  downloadable?: boolean;
  previewable?: boolean;
  onRemove?: () => void;
  openPreview?: () => void;
}

export default class AssetThumbnailActionsComponent extends Component<AssetThumbnailActionsArgs> {
  @tracked
  optionsMenuAnchor?: HTMLElement;

  @tracked
  isOptionsMenuOpen = false;

  styleNamespace = getStyleNamespace("asset-thumbnail/actions");

  @action
  setOptionsMenuAnchor(element?: HTMLElement): void {
    if (element) {
      this.optionsMenuAnchor = element;
    }
  }

  @action
  toggleOptionsMenu(): void {
    this.isOptionsMenuOpen = !this.isOptionsMenuOpen;
  }

  @action
  closeOptionsMenu(): void {
    this.isOptionsMenuOpen = false;
  }

  @action
  handleRemove(ev: MouseEvent): void {
    ev.stopPropagation();
    ev.preventDefault();
    this.args.onRemove?.();
  }

  @action
  download(): void {
    const win = window.open(this.downloadUrl, "_blank", "download,noopener,noreferrer");
    if (win) {
      win.opener = undefined;
    }
  }

  get canRemove(): boolean {
    return !!this.args.onRemove;
  }

  get downloadUrl(): string | undefined {
    if (this.args.downloadable) {
      return (this.args.asset as UserAsset).filestackUrl;
    } else {
      return undefined;
    }
  }
}
