import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { OnboardingOptions } from "client/authenticated/survey/data";
import TrackingEvents from "client/events";
import type { UserTargetingEventProperties, OnboardingOrgTypes } from "client/models/user-targeting-data";
import AnalyticsService from "client/services/analytics";

interface OnboardingOption {
  value: OnboardingOrgTypes;
  label: string;
  iconSrc: string;
}

export default class SurveyOnboardingController extends Controller {
  @tracked
  orgType?: string;

  @tracked
  loading = false;

  @service
  declare router: RouterService;

  @action
  async continue(): Promise<void> {
    this.loading = true;

    try {
      await this.model.submit();
      await this.router.transitionTo("authenticated.survey.decide");
    } finally {
      this.loading = false;
    }
  }

  @action
  async select(value: OnboardingOrgTypes): Promise<void> {
    this.orgType = value;
    this.model.set("userType", value);
    this.trackSelect();
  }

  get data(): OnboardingOption[] {
    return OnboardingOptions;
  }

  private trackSelect(): void {
    AnalyticsService.track(TrackingEvents.EVENT_SELECT_ORG_TYPE, this.onboardingTrackingProperties);
  }

  private get onboardingTrackingProperties(): Pick<UserTargetingEventProperties, "org_type"> {
    return {
      /* eslint-disable camelcase */
      org_type: this.orgType
      /* eslint-enable camelcase */
    };
  }
}
