import Model, { attr } from "@ember-data/model";

export default class StockPurchase extends Model {
  @attr("string")
  declare stockId: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    stockPurchase: StockPurchase;
  }
}
