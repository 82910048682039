import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import shuffle from "lodash/shuffle";
import type TemplateCategories from "client/services/template-categories";

const CATEGORY_COUNT = 1;

export default class TemplatesEmptyCardSuggestionsComponent extends Component {
  @service
  declare templateCategories: TemplateCategories;

  @tracked
  categories: string[] = [];

  @action
  async didInsert(): Promise<void> {
    this.categories = shuffle(await this.templateCategories.featuredCategorySlugs()).slice(0, CATEGORY_COUNT);
  }
}
