export default (seconds: number, alwaysShowMinutes = false, alwaysShowUnit = true): string => {
  const mins = Math.floor(seconds / 60);

  if (mins === 0 && !alwaysShowMinutes) {
    return `${formatSeconds(seconds, false)}s`;
  } else {
    return `${mins}:${formatSeconds(seconds % 60, true)}${alwaysShowUnit ? "s" : ""}`;
  }
};

const formatSeconds = (seconds: number, pad: boolean): string => {
  const s = Number(seconds.toFixed(1));

  if (pad && s < 10) {
    return `0${s}`;
  } else {
    return String(s);
  }
};
