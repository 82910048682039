import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type BrandStyle from "client/models/brand-style";
import type BrandEditModalService from "client/services/brand-edit-modal";

interface Args {
  brandStyle?: BrandStyle;
}

export default class BrandEditModalComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @service
  declare brandEditModal: BrandEditModalService;

  @action
  edit(): void {
    if (this.args.brandStyle) {
      this.brandEditModal.show();
    } else {
      void this.router.transitionTo("authenticated.brand.new", {
        queryParams: { returnPath: this.router.currentURL }
      });
    }
  }

  @action
  hide(): void {
    this.brandEditModal.hide();
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }
}
