import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type { TextStyle } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";

interface Args {
  label: string;
  labelStyleAttr: string;
  style: TextStyle;
  selectAsset(asset: SelectableAsset): unknown;
}

export default class TextContentItem extends Component<Args> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/text/content-item");

  get label(): SafeString {
    return htmlSafe(this.args.label);
  }

  get labelStyleAttr(): SafeString {
    return htmlSafe(this.args.labelStyleAttr);
  }
}
