import { service } from "@ember/service";
import Model, { attr, belongsTo } from "@ember-data/model";
import timeBetween from "client/lib/time-between";
import type Discount from "client/models/discount";
import type { ProductOrder } from "client/models/order-complete-event";
import OrderCompleteEvent from "client/models/order-complete-event";
import type { PlanInterval, SkuCode } from "client/models/plan";
import type Plan from "client/models/plan";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type User from "client/models/user";
import type AjaxService from "client/services/ajax";

export enum SubscriptionStatus {
  CANCELED = "canceled",
  TRIALING = "trialing",
  PAST_DUE = "past_due",
  ACTIVE = "active"
}

export default class Subscription extends Model implements ProductOrder {
  @service
  declare ajax: AjaxService;

  @attr("string")
  declare interval: PlanInterval;

  @attr("string")
  declare status: SubscriptionStatus;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("user", { async: true, inverse: null })
  declare user: User;

  @belongsTo("discount", { async: false, inverse: "subscription" })
  declare discount?: Discount;

  @belongsTo("subscription-schedule", { async: false, inverse: "subscription" })
  declare subscriptionSchedule?: SubscriptionSchedule;

  @attr("string")
  declare planName: string;

  @attr("string")
  declare coupon?: string;

  @attr("string")
  declare planId: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("plan", { async: false, inverse: null })
  declare plan: Plan;

  @attr("string")
  declare subscriptionId: string;

  @attr("string")
  declare stripeCustomerId: string;

  @attr("string")
  declare churnkeyAuthHash?: string;

  @attr("string")
  declare stripeToken?: string;

  @attr("date")
  declare currentPeriodStart: Date;

  @attr("date")
  declare currentPeriodEnd: Date;

  @attr("date")
  declare endedAt?: Date;

  @attr("date")
  declare trialEnd?: Date;

  @attr("date")
  declare trialStart?: Date;

  @attr("boolean")
  declare cancelAtPeriodEnd: boolean;

  get orderId(): string {
    return this.subscriptionId;
  }

  get active(): boolean {
    return this.status === SubscriptionStatus.ACTIVE;
  }

  get trialing(): boolean {
    return this.status === SubscriptionStatus.TRIALING;
  }

  get ended(): boolean {
    return !this.active && !!this.endedAt;
  }

  get pastDue(): boolean {
    return this.status === SubscriptionStatus.PAST_DUE;
  }

  get canceled(): boolean {
    return this.canceledStatus || this.cancelAtPeriodEnd;
  }

  private get canceledStatus(): boolean {
    return this.status === SubscriptionStatus.CANCELED;
  }

  get monetaryUnits(): number {
    return this.plan.monetaryUnits;
  }

  get currency(): string {
    return this.plan.currency;
  }

  get productId(): string {
    return this.plan.id;
  }

  get name(): string {
    return this.plan.name;
  }

  get sku(): SkuCode {
    return this.plan.skuCode;
  }

  get daysTillTrialEnd(): number | undefined {
    if (this.trialEnd) {
      const currentDate = new Date();
      return this.trialEnd >= currentDate ? timeBetween(currentDate, this.trialEnd, "days") + 1 : 0;
    }

    return;
  }

  public changePlan(plan: Plan): void {
    this.planId = plan.id;
  }

  public applyCoupon(couponCode: string | undefined): void {
    this.coupon = couponCode;
  }

  public isPlan(sku: SkuCode): boolean {
    return this.sku === sku;
  }

  category = "Subscription";

  public async cancel(): Promise<void> {
    await this.ajax.api(`/subscriptions/${this.id}/cancellation`, {
      method: "POST"
    });

    await this.reload();
  }

  public async reactivateActiveSubscription(): Promise<void> {
    await this.ajax.api(`/subscriptions/${this.id}/cancellation`, {
      method: "DELETE"
    });

    await this.reload();
  }

  public isTrialingPlan(plan: Plan): boolean {
    return this.trialing && this.isPlan(plan.skuCode);
  }

  public get justTrialed(): boolean {
    return this.isTrialOnly && this.canceledStatus;
  }

  private get isTrialOnly(): boolean {
    const trialEndTime = this.trialEnd?.getTime();
    const currentPeriodEndTime = this.currentPeriodEnd.getTime();
    const trialOffset = 2000;

    return !!this.trialStart && !!trialEndTime && currentPeriodEndTime - trialEndTime <= trialOffset;
  }

  buildOrderCompleteEvent(): object {
    return new OrderCompleteEvent(this).properties();
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    subscription: Subscription;
  }
}
