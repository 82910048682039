import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ConfirmDialog } from "client/services/confirm";
import type ConfirmService from "client/services/confirm";

export default class TheConfirmComponent extends Component {
  @service
  declare confirm: ConfirmService;

  styleNamespace = getStyleNamespace("the-confirm");

  get dialog(): ConfirmDialog | undefined {
    return this.confirm.dialog;
  }

  @action
  onCancel(ev: MouseEvent | undefined): void {
    ev?.stopPropagation();
    this.confirm.close(false);
  }

  @action
  onConfirm(ev: MouseEvent | undefined): void {
    ev?.stopPropagation();
    this.confirm.close(true);
  }

  @action
  onClick(ev: MouseEvent): void {
    ev.stopPropagation();
  }
}
