import { action } from "@ember/object";
import { service } from "@ember/service";
import { dasherize } from "@ember/string";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Card from "client/models/card";
import type Discount from "client/models/discount";
import type Subscription from "client/models/subscription";
import type UpcomingInvoice from "client/models/upcoming-invoice";
import type PaymentSourceService from "client/services/payment-source";

interface Args {
  subscription?: Subscription;
  discount?: Discount;
  upcomingInvoice: UpcomingInvoice;
}

export default class AccountSubscriptionPaymentComponent extends Component<Args> {
  @service
  declare paymentSource: PaymentSourceService;

  @tracked
  loading = false;

  styleNamespace = getStyleNamespace("account/subscription/payment");

  @action
  async didInsert(): Promise<void> {
    this.loading = true;

    try {
      await this.paymentSource.getCard();
    } finally {
      this.loading = false;
    }
  }

  get creditCard(): Card | undefined {
    return this.paymentSource.card;
  }

  get show(): boolean {
    return this.paymentSource.canAddCard;
  }

  get showDiscountInfo(): boolean {
    return !!this.discount && !this.isDiscountForever;
  }

  get creditCardBrand(): string {
    return dasherize(this.creditCard?.brand ?? "");
  }

  private get discount(): Discount | undefined {
    return this.args.discount;
  }

  private get isDiscountForever(): boolean {
    return !!this.discount?.isForever;
  }
}
