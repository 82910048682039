import { tracked } from "@glimmer/tracking";
import shuffle from "lodash/shuffle";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import ColorPreset from "client/lib/brand/color-preset";
import { filterForContrast, isColorDark, WCAGContrast } from "client/lib/color";

export const getUnusedColors = (colors: Array<string>, usedColors: Array<string>): Array<string> => {
  return colors.filter((color) => !usedColors.includes(color));
};

export default class ColorPresetGenerator {
  @tracked
  public colorPresetsByBackground: Map<string, ColorPreset[]> = new Map();

  public get colorPresets(): ColorPreset[] {
    const colorPresets: ColorPreset[] = [];

    this.colorPresetsByBackground.forEach((items) => {
      colorPresets.push(...items);
    });

    return shuffle(colorPresets);
  }

  public get unusedColorPresets(): ColorPreset[] {
    return this.colorPresets.filter((cp) => this.isUnused(cp));
  }

  public get usedColorPresets(): ColorPreset[] {
    return this.brandStyleConfig.colorPresets;
  }

  private get colors(): string[] {
    return this.brandStyleConfig.nonEmptyColorPalette;
  }

  constructor(private brandStyleConfig: BrandStyleConfig) {}

  public getUnusedColorPresets(count: number): ColorPreset[] {
    return this.colorPresets.slice(0, count);
  }

  public getUnusedColorPreset(): ColorPreset | undefined {
    const { length } = this.unusedColorPresets;

    const index = Math.floor(Math.random() * length);

    return this.unusedColorPresets[index];
  }

  public async generate(): Promise<ColorPreset[]> {
    this.colorPresetsByBackground = await this.generatePresetsByBackground();

    return this.colorPresets;
  }

  private async generatePresetsByBackground(): Promise<Map<string, ColorPreset[]>> {
    const colorPresets: Map<string, ColorPreset[]> = new Map<string, ColorPreset[]>();

    for (const background of this.colors) {
      const backgroundPresets = new Array<ColorPreset>();
      await this.iteratePrimaryColorsForBackground(backgroundPresets, background);
      colorPresets.set(background, shuffle(backgroundPresets));
    }

    return colorPresets;
  }

  private async iteratePrimaryColorsForBackground(combinations: ColorPreset[], background: string): Promise<void> {
    const primaryColors = this.getUnusedColors(background);

    for (const primary of primaryColors) {
      await this.iterateSecondaryColorsForBackground(combinations, background, primary);
    }
  }

  private async iterateSecondaryColorsForBackground(
    combinations: ColorPreset[],
    background: string,
    primary: string
  ): Promise<void> {
    const secondaryColors = this.getUnusedColors(background, primary);

    for (const secondary of secondaryColors) {
      const colorPreset = new ColorPreset(this.brandStyleConfig, {
        background,
        primary,
        secondary
      });

      // Only add combination to list if it hasn't already been used
      combinations.push(colorPreset);
    }
  }

  private getUnusedColors(background: string, ...usedColors: string[]): string[] {
    const colors = filterForContrast(background, this.colors, WCAGContrast.CUSTOM);
    const textColor = this.getTextColorForBackground(background);

    return getUnusedColors(colors, [...usedColors, textColor]);
  }

  private getTextColorForBackground(background: string): string {
    if (isColorDark(background)) {
      return this.brandStyleConfig.textColorLight;
    } else {
      return this.brandStyleConfig.textColorDark;
    }
  }

  private isUnused(colorPreset: ColorPreset): boolean {
    return !this.usedColorPresets.find((used) => ColorPreset.equal(used, colorPreset));
  }
}
