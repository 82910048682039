import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Caption, EventRegister, Text, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { DEFAULT_BACKGROUND_COLOR } from "client/lib/text/editor/styles";
import type { EditableText } from "client/lib/text/text-editor";
import type CopyPasteService from "client/services/copy-paste";
import type NotificationsService from "client/services/notifications";
import type PlaybackService from "client/services/playback";

const DRAG_THRESHOLD = 5;

interface ProjectCanvasTextFieldArgs {
  remove: () => void;
  text: Text;
  caption: Caption;
  selected?: boolean;
  timeline?: Timeline;
  eventRegister: EventRegister;
  editables: Map<string, EditableText>;
}

export default class ProjectCanvasTextField extends Component<ProjectCanvasTextFieldArgs> {
  @service
  declare playback: PlaybackService;

  @service
  declare copyPaste: CopyPasteService;

  @service
  declare notifications: NotificationsService;

  @tracked
  isEditing = false;

  wasSelectedAtStart = false;
  startX = 0;
  startY = 0;
  xDelta = 0;
  yDelta = 0;

  styleNamespace = getStyleNamespace("tidal/project-canvas-text-field");

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.text.id);
  }

  @action
  async handleSelected(): Promise<void> {
    if (!this.args.selected) {
      this.editableText?.enable(false);
    }
  }

  get backgroundColor(): string {
    return this.text.ribbonColor ?? DEFAULT_BACKGROUND_COLOR;
  }

  get color(): string {
    // This will make the bullet points match first character in the editor.
    // Obviously wrong but probably good enough for most people. Sigh.
    return this.text.bulletColor ?? "";
  }

  get fontSize(): number {
    return this.text.fontSize ?? 1;
  }

  get styleAttr(): SafeString {
    const { backgroundColor, color, fontSize } = this;

    return htmlSafe(`
      background-color: ${backgroundColor};
      color: ${color};
      font-size: ${fontSize}em;
    `);
  }

  get caption(): Caption {
    return this.args.caption;
  }

  get text(): Text {
    return this.args.text;
  }

  @action
  handleMouseDown(ev: MouseEvent): void {
    if (this.isEditing) {
      ev.stopPropagation();
    }

    this.wasSelectedAtStart = this.args.selected || false;
    this.startX = ev.pageX;
    this.startY = ev.pageY;
    this.xDelta = 0;
    this.yDelta = 0;
  }

  @action
  handleMouseMove(ev: MouseEvent): void {
    this.xDelta = ev.pageX - this.startX;
    this.yDelta = ev.pageY - this.startY;
  }

  @action
  handleMouseUp(): void {
    const dragMagnitude = Math.sqrt(this.xDelta * this.xDelta + this.yDelta * this.yDelta);
    if (dragMagnitude < DRAG_THRESHOLD && this.wasSelectedAtStart) {
      this.startEditing();
    }
  }

  @action
  async handleBlur(focusOut: boolean): Promise<void> {
    await this.stopEditing(focusOut);
  }

  @action
  async focusOut(): Promise<void> {
    if (!this.args.selected) {
      this.editableText?.enable(false);
    }
  }

  async stopEditing(focusOut = false): Promise<void> {
    if (!focusOut) {
      if (this.text.content.length) {
        if (!this.isDestroyed && !this.isDestroying) {
          this.isEditing = false;
        }

        if (this.playback.player) {
          this.playback.player.includeZymbolInRender(this.zymbolIdentifier);
        }
      } else {
        this.args.remove();
      }
    }
    this.copyPaste.unfreezeCopyPaste();
  }

  startEditing(): void {
    if (!this.isDestroyed && !this.isDestroying) {
      this.isEditing = true;
    }

    this.editableText?.enable(true);

    if (this.playback.player) {
      this.playback.player.excludeZymbolInRender(this.zymbolIdentifier);
    }
    this.copyPaste.freezeCopyPaste();
  }

  get ariaSelected(): string {
    return this.isEditing ? "true" : "false";
  }

  get zymbolIdentifier(): string {
    return `Zymbol(${this.text.id})`;
  }

  @action
  registerEditable(text: Text, editable: EditableText): void {
    this.args.editables.set(text.id, editable);
  }
}
