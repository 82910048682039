import type { RecordProject, RecordProjectPrompt, RecordProjectResponse } from "@biteable/network-model";
import Network from "@biteable/network-model";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked, TrackedArray } from "tracked-built-ins";
import type { NetworkModelQueryResult } from "client/models/network-model-query-result";
import type UserAsset from "client/models/user-asset";
import type { InfiniteModel } from "client/routes/infinite";
import InfiniteRoute from "client/routes/infinite";

interface RecordProjectResponseAndUserAsset {
  userAsset: UserAsset;
  response: RecordProjectResponse;
}

export default class AssetLibraryMyRecordingsRoute extends InfiniteRoute<InfiniteModel> {
  @service
  declare store: Store;

  @tracked
  results = new TrackedArray<RecordProjectResponseAndUserAsset>([]);

  async model(): Promise<InfiniteModel<RecordProjectResponseAndUserAsset>> {
    const results = await this.loadResults();
    this.results = new TrackedArray<RecordProjectResponseAndUserAsset>(results);
    return { results: this.results };
  }

  async loadResults(): Promise<Array<RecordProjectResponseAndUserAsset>> {
    const { per_page, page = 1 } = this.routeController; // eslint-disable-line camelcase

    const nemRecordProjects = (await Network.store.findAll(
      "recordProject",
      { per_page, page, record_type: "self" }, // eslint-disable-line camelcase
      { force: true }
    )) as NetworkModelQueryResult<RecordProject>;

    if (nemRecordProjects.meta) {
      this.routeController.meta = nemRecordProjects.meta;
    }

    const recordProjectResponses = await this.responseForAllProjects(nemRecordProjects.data);
    return recordProjectResponses.flat();
  }

  async responseForAllProjects(nemRecordProjects: RecordProject[]): Promise<RecordProjectResponseAndUserAsset[][]> {
    return Promise.all(
      nemRecordProjects.map(async (recordProject: RecordProject) => {
        if (recordProject.recordProjectPrompts) {
          const recordProjectResponses = await Promise.all(
            recordProject.recordProjectPrompts
              .map((rpr: RecordProjectPrompt) => (rpr.recordProjectResponses ? rpr.recordProjectResponses : []))
              .flat()
          );

          return this.findOrCreateAssetsForEachResponse(recordProjectResponses);
        } else {
          return [];
        }
      })
    );
  }

  async findOrCreateAssetsForEachResponse(
    recordProjectResponses: RecordProjectResponse[]
  ): Promise<RecordProjectResponseAndUserAsset[]> {
    return Promise.all(
      recordProjectResponses.map(async (rpr: RecordProjectResponse) => {
        const edUserAsset = await this.store.findRecord("userAsset", rpr.userAssetId);

        if (edUserAsset) {
          return { userAsset: edUserAsset, response: rpr };
        } else {
          const createdUserAsset = await this.store.createRecord("userAsset", { ...rpr.userAsset });
          return { userAsset: await createdUserAsset.save(), response: rpr };
        }
      })
    );
  }
}
