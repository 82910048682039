import type ApplicationInstance from "@ember/application/instance";
import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type CurrentUserService from "./current-user";
import type StorageService from "./storage";

const LAST_VIEWED_AT_KEY = "teams_last_viewed_at";

export default class TeamProjectActivityService extends Service {
  @service
  private declare storage: StorageService;

  @service
  private declare currentUser: CurrentUserService;

  @tracked
  lastViewedAt = new Date();

  constructor(owner: ApplicationInstance) {
    super(owner);

    this.lastViewedAt = this.getDate();
  }

  setLastViewedAt(date: Date): void {
    this.lastViewedAt = date;
    this.storage.setItem(LAST_VIEWED_AT_KEY, date.getTime().toString());
  }

  private getDate(): Date {
    const value = this.storage.getItem(LAST_VIEWED_AT_KEY);

    if (value) {
      return new Date(parseInt(value));
    }

    if (this.currentUser.user) {
      return this.currentUser.user.createdAt;
    }

    return new Date(0);
  }
}
