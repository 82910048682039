import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CollaborationService from "client/services/collaboration";
import type TeamService from "client/services/team";
import type UpgradeService from "client/services/upgrade";

export default class AppHeaderSupport extends Component {
  @service
  declare upgrade: UpgradeService;

  @service
  declare collaboration: CollaborationService;

  @service
  declare team: TeamService;

  @tracked
  menuTarget!: HTMLElement;

  @tracked
  open = false;

  styleNamespace = getStyleNamespace("app-header/support");

  get showUpgradeLink(): boolean {
    return this.upgrade.canUpgrade;
  }

  get showInvitePeople(): boolean {
    return this.team.canInviteTeamMembers;
  }

  @action
  toggleMenu(): void {
    this.open = !this.open;
  }

  @action
  closeMenu(): void {
    this.open = false;
  }

  @action
  setMenuTarget(element: HTMLElement): void {
    this.menuTarget = element;
  }

  @action
  invitePeople(): void {
    this.collaboration.showTeamInviteModal("top nav - invite people");
  }
}
