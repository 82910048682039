import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { PlanInterval } from "client/models/plan";
import type Plan from "client/models/plan";
import type ValidatedCoupon from "client/models/validated-coupon";

interface PriceArgs {
  coupon?: ValidatedCoupon;
  externalInterval?: PlanInterval;
  plan: Plan;
}

export default class PriceComponent extends Component<PriceArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-info-tile/price");

  get hideExactPrice(): boolean {
    const { externalInterval, plan } = this.args;
    return !!externalInterval && externalInterval !== plan.interval;
  }

  get billingInfo(): string | undefined {
    const { plan, coupon } = this.args;

    if (plan && coupon) {
      return coupon.futurePrice;
    } else {
      return plan.billingInterval;
    }
  }

  get price(): string | undefined {
    const { coupon } = this.args;

    if (coupon) {
      return coupon.price;
    } else {
      return this.fullPrice;
    }
  }

  private get fullPrice(): string {
    return this.plan.monthlyPrice;
  }

  get priceInterval(): string | undefined {
    const { coupon } = this.args;

    if (coupon) {
      return coupon.durationDescription;
    } else {
      return "per month";
    }
  }

  private get plan(): Plan {
    return this.args.plan;
  }
}
