import type { AudioClip, AudioAsset } from "../../audio/audio-clip";
import { InlineMutation } from "./vanilla-mutation";

export class AudioClipAssetMutation extends InlineMutation<AudioClip> {
  constructor(public audioClip: AudioClip, asset: AudioAsset) {
    super(audioClip, (a) => {
      a._asset = asset;
      a._trimStart = 0;
    });
  }
}
