import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { TeamMembershipRoleName } from "client/models/team-membership";
import type TeamMembership from "client/models/team-membership";
import { TeamMembershipStatus, TeamMembershipRole } from "client/models/team-membership";
import type AuthService from "client/services/auth";
import type ConfirmService from "client/services/confirm";
import type NotificationsService from "client/services/notifications";
import type SubscriptionsService from "client/services/subscriptions";
import type TeamService from "client/services/team";
import type UpgradeService from "client/services/upgrade";

interface TeamMemberRowArgs {
  teamMember: TeamMembership;
  canManageUser: boolean;
}

const Tooltips = {
  admin: "The owner can administer account and publish videos. To change owner, contact support",
  publisher: "Can create and publish videos",
  editor: "Can create videos but not publish",
  trial: "This user has 7-days of Publisher access. Then they'll switch to an Editor role",
  remove: "Remove account access"
};

export default class TeamMemberRowComponent extends Component<TeamMemberRowArgs> {
  @tracked
  changing = false;

  @service
  declare auth: AuthService;

  @service
  declare confirm: ConfirmService;

  @service
  declare notifications: NotificationsService;

  @service
  declare subscriptions: SubscriptionsService;

  @service
  declare team: TeamService;

  @service
  declare upgrade: UpgradeService;

  styleNamespace = getStyleNamespace("account/team/member-row");

  get teamMember(): TeamMembership {
    return this.args.teamMember;
  }

  get showEmailAddress(): boolean {
    return this.teamMember.fullName !== this.teamMember.email;
  }

  get currentRole(): TeamMembershipRoleName {
    return this.teamMember.roleName;
  }

  get inTrial(): boolean {
    return this.teamMember.publisherTrial ?? false;
  }

  get currentRoleIcon(): string | undefined {
    return this.inTrial ? "icon/clock2" : undefined;
  }

  get currentRoleTip(): string {
    return Tooltips[this.teamMember.role];
  }

  get isOwner(): boolean {
    return this.currentRole === "Owner";
  }

  get actionsList(): any[] {
    if (!this.args.canManageUser || this.isOwner) {
      return [];
    }

    const list = [];
    if (this.teamMember.role !== TeamMembershipRole.PUBLISHER) {
      list.push({
        label: "Publisher",
        action: TeamMembershipRole.PUBLISHER,
        tip: Tooltips[TeamMembershipRole.PUBLISHER],
        icon: "icon/video"
      });
    }

    if (this.teamMember.role !== TeamMembershipRole.EDITOR && this.teamMember.role !== TeamMembershipRole.TRIAL) {
      list.push({
        label: "Editor",
        action: TeamMembershipRole.EDITOR,
        tip: Tooltips[TeamMembershipRole.EDITOR],
        icon: "icon/edit"
      });
    }

    list.push({
      label: "Remove",
      action: "deactivate",
      tip: Tooltips["remove"],
      icon: "icon/delete"
    });

    return list;
  }

  @action
  async selectAction(action: string): Promise<void> {
    if (action === "deactivate") {
      return this.updateTeamMemberStatus(TeamMembershipStatus.INACTIVE);
    } else {
      return this.updateTeamMemberRole(action as TeamMembershipRole);
    }
  }

  private async updateTeamMemberRole(role: TeamMembershipRole): Promise<void> {
    this.changing = true;
    const roleFrom = this.teamMember.role;

    const result = await this.team.updateTeamMemberRole(this.teamMember, role);
    if (result === "failure") {
      if (this.subscriptions.isTrialing || !this.subscriptions.currentSubscription) {
        this.triggerUpgrade();
      } else {
        this.notifications.error("There was a problem changing user's role");
      }
    } else {
      this.team.trackTeamMemberRoleChange(this.teamMember, roleFrom, role);
    }

    this.changing = false;
  }

  @action
  async activate(): Promise<void> {
    return this.updateTeamMemberStatus(TeamMembershipStatus.ACTIVE);
  }

  private triggerUpgrade(): void {
    this.upgrade.upgradeMessage = "Upgrade your plan to get more publisher seats";
    void this.upgrade.open();
  }

  private async updateTeamMemberStatus(status: TeamMembershipStatus): Promise<void> {
    this.changing = true;

    try {
      let updated = false;

      if (status === TeamMembershipStatus.INACTIVE) {
        updated = await this.deactivateTeamMember();
      } else {
        updated = await this.activateTeamMember();
      }

      if (updated) {
        this.team.trackTeamMemberStatusChange(this.teamMember);
      }
    } finally {
      this.changing = false;
    }
  }

  private async deactivateTeamMember(): Promise<boolean> {
    const confirmed = await this.confirm.open({
      message:
        "They will lose access to Biteable, including videos in their Personal folder.\n\nYou will still be able to access their shared Team videos.",
      title: `Remove access for ${this.teamMember.fullName}?`,
      confirmLabel: "Remove access"
    });

    if (confirmed) {
      const result = await this.team.updateTeamMemberStatus(this.teamMember, TeamMembershipStatus.INACTIVE);
      if (!result) {
        this.notifications.error("There was a problem removing team access");
      }
      return result;
    }

    return false;
  }

  private async activateTeamMember(): Promise<boolean> {
    const confirmed = await this.confirm.open({
      message: "This action reinstates access to their existing videos, and all Team assets and projects.",
      title: `Reactivate ${this.teamMember.fullName}'s account?`,
      confirmLabel: "Reactivate"
    });

    if (confirmed) {
      const result = await this.team.updateTeamMemberStatus(this.teamMember, TeamMembershipStatus.ACTIVE);
      if (!result) {
        this.notifications.error("There was a problem reactivating team access");
      }
      return result;
    }

    return false;
  }
}
