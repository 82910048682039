import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioTrack from "client/models/audio-track";
import type { UserAssetTypes } from "client/models/user-asset";
import type UserAsset from "client/models/user-asset";
import type ActiveStorageService from "client/services/active-storage";
import { FILE_UPLOAD_ERROR_NOTIFICATION } from "client/services/active-storage";
import { ASSET_LIBRARY_IMAGE_MIMETYPES } from "client/services/asset-library";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";

interface AppFileUploadArgs {
  onupload: (asset: UserAsset | AudioTrack) => void;
  label?: string;
  accept?: string;
  variant?: string;
  autoOpenPicker?: boolean;
  assetType?: UserAssetTypes;
}

export default class AppFileUploadComponent extends Component<AppFileUploadArgs> {
  @service
  notifications!: NotificationsService;

  @service
  activeStorage!: ActiveStorageService;

  @service
  router!: RouterService;

  @service
  private declare permissions: PermissionsService;

  removeDropCallback?: () => void;

  @tracked
  dragging = false;

  styleNamespace = getStyleNamespace("app/active-storage/file-upload");

  get label(): string {
    return this.args.label ?? "Upload file";
  }

  get accept(): string {
    if (this.permissions.has("feature_svg_upload")) {
      return this.args.accept ?? ASSET_LIBRARY_IMAGE_MIMETYPES.join(",");
    } else {
      return this.args.accept ?? ASSET_LIBRARY_IMAGE_MIMETYPES.filter((e) => e !== "image/svg+xml").toString();
    }
  }

  @action
  async selectFile(): Promise<void> {
    const files = await this.activeStorage.showFilePicker({ accept: this.accept });
    await this.uploadFiles(files);
  }

  @action
  async uploadFiles(files: FileList | File[]): Promise<void> {
    try {
      const total = files.length;
      let i = 1;
      let asset;

      for (const file of files) {
        const msg = total > 1 ? `(${i++}/${total})` : "";
        if (!this.args.assetType) {
          asset = await this.activeStorage.createMusicTrack(file);
        } else {
          asset = await this.activeStorage.createUserAsset(file, this.args.assetType, msg);
        }
      }
      if (asset && total === 1) {
        this.args.onupload(asset);
      } else {
        const currentRouteName = this.router.currentRouteName;
        // @ts-expect-error
        getOwner(this).lookup(`route:${currentRouteName}`).refresh();
      }
    } catch (err) {
      this.failed();
    }
  }

  @action
  setupDrop(): void {
    this.removeDropCallback = this.activeStorage.setupDrop(
      (dragging) => (this.dragging = dragging),
      (files) => this.uploadFiles(files),
      this.accept
    );
  }

  @action
  removeDrop(): void {
    if (this.removeDropCallback) {
      this.removeDropCallback();
      this.removeDropCallback = undefined;
    }
  }

  failed(): void {
    this.notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
  }
}
