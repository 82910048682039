interface AnalyticsEventData {
  data: {
    type: string;
    attributes: {
      event: string;
    };
    relationships?: {
      [key: string]: any;
    };
  };
  included?: any[];
}

export default class AnalyticsEvent {
  event = "";
  properties: object = {};
  integrations: object = {};

  constructor({ event, properties, integrations }: any) {
    Object.assign(this, { event, properties, integrations });
  }

  toJsonApi(): string {
    const data: AnalyticsEventData = {
      data: {
        type: "analytics",
        attributes: {
          event: this.event
        }
      }
    };

    this.addRelationships(data);
    return JSON.stringify(data);
  }

  addRelationships(data: AnalyticsEventData): void {
    if (this.properties || this.integrations) {
      data.data.relationships = {};
      data.included = [];

      this.buildProperties(data);
      this.buildIntegrations(data);
    }
  }

  buildProperties(data: AnalyticsEventData): void {
    this.buildRelationship(data, this.properties, "properties");
    this.buildIncludes(data, this.properties, "properties");
  }

  buildIntegrations(data: AnalyticsEventData): void {
    this.buildRelationship(data, this.integrations, "integrations");
    this.buildIncludes(data, this.integrations, "integrations");
  }

  buildRelationship(data: AnalyticsEventData, obj: {}, name: string): void {
    if (obj && data.data.relationships) {
      const list: any[] = [];
      Object.keys(obj).forEach((key) => {
        list.push({ type: name, id: key });
      });
      data.data.relationships[name] = { data: list };
    }
  }

  buildIncludes(data: AnalyticsEventData, obj: {}, name: string): void {
    if (obj) {
      Object.keys(obj).forEach((key) => {
        const include = {
          type: name,
          id: key,
          attributes: {
            value: (obj as any)[key]
          }
        };
        data.included?.push(include);
      });
    }
  }
}
