import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

interface DropdownComponentArgs {
  button?: Component;
  popover?: Component;
}

export default class DropdownComponent extends Component<DropdownComponentArgs> {
  @tracked
  declare buttons?: NodeListOf<HTMLButtonElement>;

  @tracked
  declare open: boolean;

  styleNamespace = getStyleNamespace("north-star/dropdown");

  constructor(owner: object, args: DropdownComponentArgs) {
    super(owner, args);
    this.open = this.open ?? false;
  }

  @action
  didInsert(element: HTMLElement): void {
    this.buttons = element.querySelectorAll(`.${this.styleNamespace} > button`);
    this.setButtonListeners();
    this.setGlobalListeners();
  }

  @action
  togglePopover(): void {
    this.open = !this.open;
  }

  @action
  closePopover(): void {
    this.open = false;
  }

  @action
  onButtonClick(): void {
    this.togglePopover();
  }

  @action
  onGlobalClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    let containsButton = true;
    if (target) {
      this.buttons?.forEach((button) => {
        containsButton &&= button.contains(target);
      });
      if (!containsButton) {
        this.closePopover();
      }
    }
  }

  @action
  onGlobalKeyDown(event: KeyboardEvent): void {
    if (event.code === EventKeys.ESC) {
      this.closePopover();
    }
  }

  private setButtonListeners(): void {
    if (this.buttons) {
      for (const button of this.buttons) {
        button.addEventListener("click", this.onButtonClick);
      }
    }
  }
  private removeButtonListeners(): void {
    if (this.buttons) {
      for (const button of this.buttons) {
        button.removeEventListener("click", this.onButtonClick);
      }
    }
  }

  private setGlobalListeners(): void {
    document.addEventListener("click", this.onGlobalClick);
    document.addEventListener("keydown", this.onGlobalKeyDown);
  }

  private removeGlobalListeners(): void {
    document.removeEventListener("click", this.onGlobalClick);
    document.removeEventListener("keydown", this.onGlobalKeyDown);
  }

  // lifecycle
  willDestroy(): void {
    super.willDestroy();
    this.removeButtonListeners();
    this.removeGlobalListeners();
  }
}
