export class TrackedBounds {
  private observer?: ResizeObserver;

  rect?: DOMRect;

  constructor(callback: () => void, root: HTMLElement | undefined, element?: HTMLElement | undefined | null) {
    if (!element || !root) {
      return;
    }

    this.observer = new ResizeObserver(() => {
      this.rect = element.getBoundingClientRect();
      callback();
    });
    this.observer.observe(element);

    const observe = (e: Element): void => {
      this.observer?.observe(e);
      Array.from(e.children).forEach(observe);
    };
    observe(root);
  }

  disconnect(): void {
    this.observer?.disconnect();
  }
}
