// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from "ember-data";
import ApplicationAdapter from "./application";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from "ember-data/types/registries/model";

export default class FolderAdapter extends ApplicationAdapter {
  urlForDeleteRecord<K extends keyof ModelRegistry>(id: string, modelName: K, snapshot: DS.Snapshot<K>): string {
    const {
      adapterOptions: { data }
    } = snapshot;

    const queryString = data
      ? "?" +
        Object.keys(data)
          // @ts-expect-error
          .map((prop) => `${prop}=${data[prop]}`)
          .join("&")
      : "";

    return super.urlForDeleteRecord(id, modelName, snapshot) + queryString;
  }
}
