import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import type AccountRoute from "../route";
import type CommunicationsController from "./controller";
import type User from "client/models/user";

export interface CommunicationsModel {
  user: User;
}

export default class CommunicationsRoute extends Route<CommunicationsModel> {
  async model(): Promise<CommunicationsModel> {
    const user = this.modelFor<AccountRoute>("authenticated.account");

    return {
      user
    };
  }

  resetController(controller: CommunicationsController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    // Undo any unsaved changes to the User
    controller.model.user.rollbackAttributes();
  }
}
