import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type ProjectController from "client/authenticated/project/controller";
import TrackingEvents from "client/events";
import type { EventRegister, Timeline } from "client/lib/editor-domain-model";
import { TimelineFactory } from "client/lib/editor-domain-model";
import type { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";
import { EmberTimelineBuilder } from "client/lib/timeline/ember-timeline-builder";
import type AspectRatio from "client/models/aspect-ratio";
import type BrandStyle from "client/models/brand-style";
import type Favorable from "client/models/favorable";
import type Font from "client/models/font";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AjaxService from "client/services/ajax";
import type EditorHeartbeatService from "client/services/editor-heartbeat";
import type FavoritesService from "client/services/favorites";
import type FontsService from "client/services/fonts";
import type LayersService from "client/services/layers";
import type NotificationsService from "client/services/notifications";
import type PendingService from "client/services/pending";
import type PermissionsService from "client/services/permissions";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ProjectScenesService from "client/services/project-scenes";
import type ProjectThumbnailGeneratorService from "client/services/project-thumbnail-generator";
import type ProjectsService from "client/services/projects";
import type { SceneFavoriteChangedEventProperties } from "client/services/timeline-events";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type TrackingService from "client/services/tracking";
import type UndoService from "client/services/undo";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export interface ProjectRouteModel {
  project: Project;
  timeline: Timeline;
  aspectRatio: AspectRatio;
  brandStyle?: BrandStyle;
  eventRegister: EventRegister;
  timelineFactory: TimelineFactory;
  fonts: Font[];
}

export interface ProjectRouteParams {
  projectId: string;
}

export default class ProjectRoute extends Route<ProjectRouteModel> {
  @service
  declare projects: ProjectsService;

  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare layers: LayersService;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare router: RouterService;

  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare pending: PendingService;

  @service
  declare editorHeartbeat: EditorHeartbeatService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare tracking: TrackingService;

  @service
  declare undo: UndoService;

  @service
  declare projectThumbnailGenerator: ProjectThumbnailGeneratorService;

  @service
  declare fonts: FontsService;

  @service
  private declare favorites: FavoritesService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare permissions: PermissionsService;

  @action
  loading(transition: Transition): boolean {
    // WARNING: Only bubble up the loading event for the ProjectRoute so that we can show loading state
    // only when the Project loads. All child routes cannot leave the ProjectRoute to avoid breaking the canvas.
    const isChildRoute = transition.from?.name.startsWith(this.routeName) ?? false;

    return !isChildRoute;
  }

  @action
  async updateTimeline(options: { transaction: ExplicitTransaction }): Promise<void> {
    await this.rebuildTimeline(options.transaction);
  }

  async rebuildTimeline(transaction: ExplicitTransaction): Promise<void> {
    const { timeline, timelineFactory } = this.modelFor("authenticated.project") as ProjectRouteModel;
    await timelineFactory.eventRegister.appendTransaction(transaction, async () => {
      await timelineFactory.fireRebuildTimeline(timeline);
    });
  }

  @action
  async reloadModel(): Promise<void> {
    await this.refresh();
  }

  async model({ projectId }: ProjectRouteParams): Promise<ProjectRouteModel> {
    const project = await this.projects.getProject(projectId);
    // @ts-expect-error
    const fonts = (await this.store.findAll("font")) as Font[];

    const [{ timeline, eventRegister, timelineFactory }, aspectRatio, brandStyle] = await Promise.all([
      this.createTimeline(project),
      project.aspectRatio,
      project.getBrandStyle()
    ]);

    return {
      project,
      aspectRatio,
      brandStyle,
      timeline,
      eventRegister,
      timelineFactory,
      fonts
    };
  }

  async createTimeline(
    project: Project
  ): Promise<{ timeline: Timeline; eventRegister: EventRegister; timelineFactory: TimelineFactory }> {
    const timelineFactory = new TimelineFactory(
      new EmberTimelineBuilder(
        this.ajax,
        this.notifications,
        this.store,
        this.projects,
        this.projectScenes,
        this.layers,
        this.zymbolGroups,
        this.router,
        project
      )
    );
    this.undo.eventRegister = timelineFactory.eventRegister;

    const timeline = await timelineFactory.buildTimeline(project.id);
    const eventRegister = timelineFactory.eventRegister;

    return {
      timeline,
      eventRegister,
      timelineFactory
    };
  }

  async afterModel({ project, timeline }: ProjectRouteModel, transition: Transition): Promise<void> {
    this.advancedEditor.setTimeline(timeline);
    await this.fonts.loadAllFonts(project);
    this.projectThumbnailGenerator.setProject(project);
    this.projectThumbnailGenerator.generate();

    await Promise.all([this.editorHeartbeat.updateActiveEditors(project.id), this.favorites.peekOrLoad()]);

    void project.checkForPendingRender();
    void this.pending.push(Promise.resolve(project.loadZymbolsToRenderDependencies()));

    if (project.teamShared) {
      void this.trackOpeningSharedProject(project, transition);
      this.editorHeartbeat.start(Number(project.id));
    }

    await this.markFavorites(project, timeline);
  }

  async trackOpeningSharedProject(project: Project, transition: Transition): Promise<void> {
    // Regardless of tracking we need the team loaded for reference in component getters via belongsTo().value()
    const projectTeam = await project.team;
    if (transition.from && project.teamShared) {
      /* eslint-disable camelcase */
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEAM_VIEW_PROJECT, {
        projectId: project.id,
        team_id: projectTeam?.id,
        team_owner_id: projectTeam?.ownerId,
        team_size: projectTeam?.memberCount
      });
      /* eslint-enable camelcase */
    }
  }

  async setupController(
    controller: ProjectController,
    model: ProjectRouteModel,
    transition: Transition
  ): Promise<void> {
    super.setupController(controller, model, transition);

    Object.assign(controller, {
      showSharedProjectDialog: model.project.teamShared,
      isExternalNavigation: !transition.from
    });
  }

  @action
  async sceneFavoriteChanged({
    favorited,
    teamFavorited,
    projectScene
  }: SceneFavoriteChangedEventProperties): Promise<void> {
    const { timeline } = this.modelFor("authenticated.project") as ProjectRouteModel;

    const scene = timeline.scenes.find((scene) => scene.id === projectScene.favorableProviderAssetId);
    if (scene) {
      if (favorited) {
        scene.favorite({ favorited, teamFavorited });
      } else {
        scene.unfavorite();
      }
    }
  }

  private async markFavorites(project: Project, timeline: Timeline): Promise<void> {
    const projectScenes = await project.projectScenes;
    for (const scene of timeline.scenes) {
      const projectScene = projectScenes.find((ps: ProjectScene) => {
        return ps.id === scene.id;
      }) as ProjectScene & Favorable;
      if (projectScene && projectScene.faved) {
        scene.favorite({ favorited: projectScene.favorited, teamFavorited: projectScene.teamFavorited });
      }
    }
  }

  activate(): void {
    this.timelineEvents.on(TimelineEvents.TIMELINE_UPDATED, this.updateTimeline);
    this.timelineEvents.on(TimelineEvents.SCENE_FAVORITE_CHANGED, this.sceneFavoriteChanged);
    this.timelineEvents.on(TimelineEvents.BRAND_STYLE_CHANGED, this.reloadModel);
    this.timelineEvents.on(TimelineEvents.ASPECT_RATIO_CHANGED, this.reloadModel);
  }

  deactivate(): void {
    this.projectThumbnailGenerator.generate();
    this.projectThumbnailGenerator.clearProject();
    this.advancedEditor.unsetTimeline();
    this.editorHeartbeat.stop();

    this.timelineEvents.off(TimelineEvents.TIMELINE_UPDATED, this.updateTimeline);
    this.timelineEvents.off(TimelineEvents.SCENE_FAVORITE_CHANGED, this.sceneFavoriteChanged);
    this.timelineEvents.off(TimelineEvents.BRAND_STYLE_CHANGED, this.reloadModel);
    this.timelineEvents.off(TimelineEvents.ASPECT_RATIO_CHANGED, this.reloadModel);
  }
}
