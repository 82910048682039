import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectScenesRouteModel } from "../route";
import type ScenesRoute from "client/authenticated/project/scenes/route";
import type StockLicense from "client/models/stock-license";

export interface ProjectScenesFavoritesRouteModel extends ProjectScenesRouteModel {
  stockLicenses: Array<StockLicense>;
}

export default class FavoritesRoute extends Route<ProjectScenesFavoritesRouteModel> {
  @service
  declare store: Store;

  async model(): Promise<ProjectScenesFavoritesRouteModel> {
    const model = this.modelFor<ScenesRoute>("authenticated.project.scenes");

    const stockLicenses = await this.store.query("stockLicense", {
      // eslint-disable-next-line camelcase
      per_page: 500
    });

    return { ...model, stockLicenses: stockLicenses };
  }
}
