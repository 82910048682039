import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Project from "client/models/project";
import type AdvancedEditorService from "client/services/advanced-editor";
import type NotificationsService from "client/services/notifications";
import { PROJECT_DUPLICATION_FAILURE_NOTIFICATION } from "client/services/projects";

export default class ProjectCopyController extends Controller {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @action
  async createDuplicate(project: Project, properties: object): Promise<void> {
    try {
      const copy = await project.duplicate();
      Object.assign(copy, properties);
      await copy.save();
      this.advancedEditor.unsetTimeline();
      void this.router.transitionTo("authenticated.project", copy.id);
    } catch (err) {
      this.notifications.error(PROJECT_DUPLICATION_FAILURE_NOTIFICATION);
    }
  }

  @action
  cancel(): void {
    void this.router.transitionTo("authenticated.project");
  }
}
