import type { RecordProject } from "@biteable/network-model";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  recordProject: RecordProject;
}

export default class EmptyResponsesResults extends Component<Args> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/recordings/request/empty-responses");
}
