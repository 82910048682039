import type { AudioClip } from "../../audio/audio-clip";
import { TimelineMutation } from "./mutation";

export class AudioClipTrimStartMutation extends TimelineMutation<AudioClip> {
  private originalTrimStart: number;
  private originalOffset: number;
  private originalDuration: number;

  constructor(
    public grouped: boolean,
    public audioClip: AudioClip,
    private trimStart: number,
    private projectDuration: number,
    private bufferDuration: number
  ) {
    super();
    this.originalTrimStart = audioClip.trimStart;
    this.originalOffset = audioClip.offset;
    this.originalDuration = audioClip.duration;
  }

  private get duration(): number {
    return this.audioClip.duration === -1 ? this.projectDuration : this.audioClip.duration;
  }

  run(): void {
    const delta = this.trimStart - this.audioClip.trimStart;

    const deltaMin = Math.max(0 - this.audioClip.trimStart, 0 - this.audioClip.offset);
    const deltaMax = Math.min(this.duration - 0.5, this.bufferDuration - this.audioClip.trimStart - 0.5);

    const clampedDelta = this.clamp(delta, deltaMin, deltaMax);

    this.audioClip._trimStart = this.audioClip.trimStart + clampedDelta;
    this.audioClip._offset = this.audioClip.offset + clampedDelta;
    this.audioClip._duration = this.duration - clampedDelta;
  }

  revert(): void {
    this.audioClip._trimStart = this.originalTrimStart;
    this.audioClip._offset = this.originalOffset;
    this.audioClip._duration = this.originalDuration;
  }
}
