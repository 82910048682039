import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  isSelected: boolean;
}

export default class ProjectTimelineActionsGroupComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("project-timeline/actions-group");
}
