import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ControlBarComponentArgs {
  currentTime: number;
  duration: number;
  isLoading: boolean;
  isMuted: boolean;
  isPlaying: boolean;
  setCurrentTime: (event: Event) => void;
  setVolume: (event: Event) => void;
  toggleMute: () => void;
  togglePlayback: () => void;
  volume: number;
}

export default class ControlBarComponent extends Component<ControlBarComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/video-player/control-bar");
}
