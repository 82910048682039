import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Category from "client/models/category";
import type ProjectTemplate from "client/models/project-template";

export default class TemplatesCategoryPreviewComponent extends Component<{
  category: string;
  hideCategory?: boolean;
}> {
  @service
  declare store: Store;

  @tracked
  loading = false;

  @tracked
  projectTemplates: ProjectTemplate[] = [];

  @tracked
  category?: Category;

  styleNamespace = getStyleNamespace("discovery/templates/category-preview");

  @action
  async didInsert(): Promise<void> {
    this.loading = true;

    try {
      await Promise.all([this.loadProjectTemplates(), this.loadCategory()]);
    } finally {
      this.loading = false;
    }
  }

  async loadCategory(): Promise<void> {
    if (!this.args.hideCategory) {
      this.category = await this.store.findRecord("category", this.args.category);
    }
  }

  async loadProjectTemplates(): Promise<void> {
    // @ts-expect-error
    this.projectTemplates = await this.store.query("projectTemplate", {
      /* eslint-disable camelcase */
      per_page: 8,
      category: this.args.category
      /* eslint-enable camelcase */
    });
  }
}
