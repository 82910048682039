import { set } from "@ember/object";
import Model, { attr, belongsTo } from "@ember-data/model";
import { cached } from "@glimmer/tracking";
import { isEqual } from "lodash";
import type { Op } from "quill/core";
import type {
  Bounds,
  DrawableAsset,
  SceneTransition,
  RenderZymbol,
  ZymbolConfig,
  AssetOffset,
  RenderAudioClip
} from "renderer-engine";
import { ZymbolCategory, getZymbolConfig, ZymbolGroupLayer, TextConfig, parseTextContent } from "renderer-engine";
import type Layer from "./layer";
import { calculateDefaultBackgroundDimensions, getAssetWidthAndHeight } from "client/lib/dimensions";
import hashObject from "client/lib/hash-object";
import { getTextContentAttributes } from "client/lib/text/text-styles";
import type AspectRatio from "client/models/aspect-ratio";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type ZymbolGroup from "client/models/zymbol-group";

const FORMATTED_CATEGORIES: { [key: string]: string } = {
  sag: "animation",
  image: "image",
  video: "video",
  text: "text",
  blank: "empty",
  color: "color",
  svg: "svg"
};

export enum FixedPosition {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right"
}

export const PLACEHOLDER_TEXT = "Click here to edit";

type BoundsAndRenderZymbol = Bounds & RenderZymbol;
export default class Zymbol extends Model implements BoundsAndRenderZymbol {
  @attr("json")
  cfg!: ZymbolConfig;

  @attr("json")
  audioClip?: RenderAudioClip;

  @attr("string")
  category!: ZymbolCategory;

  @attr("number")
  x!: number;

  @attr("number")
  y!: number;

  @attr("number")
  width!: number;

  @attr("number")
  height!: number;

  @attr("boolean")
  watermark!: boolean;

  @attr("number")
  layerOrder!: number;

  @attr("number")
  customTimingOffset!: number | undefined;

  @attr("number")
  customTimingDuration!: number | undefined;

  @attr("tuple")
  assetOffset!: AssetOffset | undefined;

  @belongsTo("zymbol-group", { async: true, inverse: "zymbols" })
  zymbolGroup!: ZymbolGroup;

  get project(): Project {
    // @ts-expect-error
    const zymbolGroup = this.belongsTo("zymbolGroup").value() as ZymbolGroup;
    return zymbolGroup?.belongsTo("project").value() as Project;
  }

  get layer(): Layer {
    // @ts-expect-error
    const zymbolGroup = this.belongsTo("zymbolGroup").value() as ZymbolGroup;
    return zymbolGroup.belongsTo("layer").value() as Layer;
  }

  get layerName(): ZymbolGroupLayer {
    return this.layer.name;
  }

  get aspectRatio(): AspectRatio {
    return this.project.belongsTo("aspectRatio").value() as AspectRatio;
  }

  @attr("string")
  fixedPosition?: FixedPosition;

  // Duplicates the called getter but is needed in ZymbolLike type uses
  get sceneId(): string {
    return this.projectSceneId;
  }

  get zymbolGroupStartTime(): number {
    // @ts-expect-error
    return (this.belongsTo("zymbolGroup").value() as ZymbolGroup).startTime;
  }

  get zymbolGroupDuration(): number {
    // @ts-expect-error
    return (this.belongsTo("zymbolGroup").value() as ZymbolGroup).duration;
  }

  get projectSceneStartTime(): number {
    return this.projectScene.startTime;
  }

  get projectSceneDuration(): number {
    return this.projectScene.duration;
  }

  get startTime(): number {
    return this.zymbolGroupStartTime + this.projectSceneStartTime;
  }

  @cached
  get projectScene(): ProjectScene {
    // @ts-expect-error
    const group = this.belongsTo("zymbolGroup").value() as ZymbolGroup;
    return group.belongsTo("projectScene").value() as ProjectScene;
  }

  get duration(): number {
    return this.layerName === ZymbolGroupLayer.BACKGROUND ? this.projectSceneDuration : this.zymbolGroupDuration;
  }

  get customEndTime(): number | undefined {
    // eslint-disable-next-line eqeqeq, no-null/no-null
    if (this.customTimingDuration == null) {
      return;
    }
    return (this.customTimingOffset ?? 0) + this.customTimingDuration;
  }

  get endTogether(): boolean {
    return (
      this.startTime + (this.customEndTime ?? this.duration) === this.projectSceneStartTime + this.projectSceneDuration
    );
  }

  get startTogether(): boolean {
    return this.projectSceneStartTime === this.startTime;
  }

  get firstScene(): boolean {
    return this.projectSceneStartTime === 0;
  }

  get transition(): SceneTransition | undefined {
    return this.projectScene.transition;
  }

  get sceneTransitionIn(): SceneTransition | undefined {
    if (!this.firstScene && this.startTogether) {
      return this.transition;
    }

    return;
  }

  get sceneTransitionOut(): SceneTransition | undefined {
    if (this.endTogether) {
      return this.nextSceneTransition;
    }

    return;
  }

  get sortedProjectScenes(): ProjectScene[] {
    return this.project.sortedProjectScenes;
  }

  get nextSceneTransition(): SceneTransition | undefined {
    return this.projectScene.nextSceneTransition;
  }

  get projectSceneId(): string {
    return this.projectScene.id;
  }

  get zymbolGroupId(): string {
    // @ts-expect-error
    return (this.belongsTo("zymbolGroup").value() as ZymbolGroup).id;
  }

  get identifier(): string {
    if (this.watermark) {
      return `Watermark(${this.id})`;
    }
    return `Zymbol(${this.id})`;
  }

  @cached
  get zymbolToRender(): RenderZymbol {
    return {
      identifier: this.identifier,
      cfg: this.cfg,
      audioClip: this.audioClip,
      duration: this.duration,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      layerOrder: this.layerOrder,
      customTimingOffset: this.customTimingOffset,
      customTimingDuration: this.customTimingDuration,
      assetOffset: this.assetOffset,
      category: this.category,
      endsAtSceneEnd: this.endTogether,
      sceneTransitionIn: this.sceneTransitionIn,
      sceneTransitionOut: this.sceneTransitionOut,
      sceneId: this.projectSceneId,
      startTime: this.startTime,
      zymbolGroupId: this.zymbolGroupId,
      layerName: this.layerName,
      assets: [] as DrawableAsset[]
    };
  }

  assets: DrawableAsset[] = [];

  get formattedCategory(): string {
    if (!(this.category in FORMATTED_CATEGORIES)) {
      throw Error(`A formatted category for ${this.category} does not exist`);
    }
    return FORMATTED_CATEGORIES[this.category]!;
  }

  get shutterstockId(): string {
    return this.cfg?.video?.asset?.type === "shutterstock-footage" ? this.cfg.video.asset.id : "";
  }

  get pexelsVideoId(): string {
    return this.cfg?.video?.asset?.type === "pexels-video" ? this.cfg.video.asset.id : "";
  }

  get textContent(): string {
    if (!this.cfg || !this.cfg.text || !this.cfg.text.content) {
      return "";
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const content = parseTextContent(this.cfg.text.content);

    return content
      .map((op: Op) => {
        return typeof op.insert === "string" ? op.insert.replace(/\s\s+/g, " ") : "";
      })
      .filter((text: string) => text.length)
      .join("");
  }

  get defaultTextConfig(): TextConfig {
    if (!this.cfg || !this.cfg.text || !this.cfg.text.content) {
      return new TextConfig();
    }

    const text = this.cfg.text;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const { fontAttributes, styleAttributes } = getTextContentAttributes(parseTextContent(text.content));
    const content = JSON.stringify([
      { insert: PLACEHOLDER_TEXT, attributes: styleAttributes },
      { insert: "\n", attributes: fontAttributes }
    ]);

    return { ...new TextConfig(), ...text, content };
  }

  get fillCanvas(): boolean {
    return this.category === ZymbolCategory.COLOR;
  }

  get contain(): boolean {
    return [ZymbolCategory.TEXT, ZymbolCategory.COLOR].includes(this.category);
  }

  get mediaReplaceable(): boolean | undefined {
    switch (this.category) {
      case ZymbolCategory.IMAGE:
      case ZymbolCategory.SAG:
      case ZymbolCategory.VIDEO:
        return this.cfg?.[this.category]?.mediaReplaceable;
      default:
        return undefined;
    }
  }

  set mediaReplaceable(value: boolean | undefined) {
    switch (this.category) {
      case ZymbolCategory.IMAGE:
      case ZymbolCategory.SAG:
      case ZymbolCategory.VIDEO:
        if (this.cfg?.[this.category]) {
          this.cfg[this.category]!.mediaReplaceable = value;
        }
        break;
      default:
        return;
    }
  }

  get isSAG(): boolean {
    return this.category === ZymbolCategory.SAG;
  }

  get isText(): boolean {
    return this.category === ZymbolCategory.TEXT;
  }

  get assetOffsetX(): number | undefined {
    return this.assetOffset?.[0];
  }

  get assetOffsetY(): number | undefined {
    return this.assetOffset?.[1];
  }

  get isOnlyChild(): boolean {
    // @ts-expect-error
    const zymbolGroup = this.belongsTo("zymbolGroup").value() as ZymbolGroup;
    // @ts-expect-error
    const zymbols = zymbolGroup.hasMany("zymbols").value() as Zymbol[];
    return zymbols?.length === 1;
  }

  /**
   * The zymbol's size relative to the canvas
   */
  get canvasRatio(): number {
    return Math.min(this.width, this.height);
  }

  /**
   * Determine whether this Zymbol has content corresponding to its category
   */
  get hasContent(): boolean {
    const { cfg } = this;

    switch (this.category) {
      case ZymbolCategory.IMAGE: {
        return !!(cfg.image && cfg.image.url);
      }
      case ZymbolCategory.VIDEO: {
        return !!(cfg.video && cfg.video.url);
      }
      case ZymbolCategory.TEXT: {
        return !!this.textContent.length;
      }
      case ZymbolCategory.SAG: {
        return !!(cfg.sag && cfg.sag.layers && cfg.sag.layers.length);
      }
      default: {
        return false;
      }
    }
  }

  async resetBackgroundDimensions(): Promise<void> {
    const aspectRatio = this.aspectRatio;

    const dimensions = calculateDefaultBackgroundDimensions(
      await getAssetWidthAndHeight(this, aspectRatio),
      aspectRatio
    );

    const assetOffset = undefined;

    Object.assign(this, { assetOffset, ...dimensions });
    await this.save();
  }

  async clearBackground(): Promise<void> {
    await this.resetConfig(false);
    await this.resetBackgroundDimensions();
  }

  /**
   * Whether setting `this.cfg` to `newConfig` would change its saved value.
   */
  private configWouldChange(newConfig: ZymbolConfig): boolean {
    const configJson = JSON.stringify(this.cfg);
    const newConfigJson = JSON.stringify(newConfig);

    return !isEqual(JSON.parse(configJson), JSON.parse(newConfigJson));
  }

  public async resetConfig(save = true): Promise<void> {
    const newConfig = {
      [this.category]: {
        ...getZymbolConfig(this.category)
      }
    };

    if (this.configWouldChange(newConfig)) {
      set(this, "cfg", newConfig);
    }

    if (save) {
      await this.save();
    }
  }

  public async configHash(): Promise<string> {
    if (!this.cfg || !this.cfg[this.category]) {
      return hashObject({});
    }

    const existingConfig = JSON.parse(JSON.stringify(this.cfg?.[this.category]));
    const newConfig = {
      [this.category]: {
        ...getZymbolConfig(this.category),
        ...existingConfig
      }
    };
    return hashObject(newConfig);
  }

  public async replaceConfig<T extends ZymbolCategory>(
    category: T,
    config: Partial<ZymbolConfig[T]>,
    save = true
  ): Promise<void> {
    const newConfig = {
      [category]: {
        ...getZymbolConfig(category),
        ...config
      }
    };

    if (this.configWouldChange(newConfig)) {
      set(this, "category", category);
      set(this, "cfg", newConfig);
    }

    if (save) {
      await this.save();
    }
  }

  public async updateConfig<T extends ZymbolCategory>(
    category: T,
    config: Partial<ZymbolConfig[T]>,
    save = true
  ): Promise<void> {
    const { cfg } = this;
    const newConfig = {
      [category]: {
        ...getZymbolConfig(category),
        ...cfg?.[category],
        ...config
      }
    };

    if (this.configWouldChange(newConfig)) {
      set(this, "category", category);
      set(this, "cfg", newConfig);
    }

    if (save) {
      await this.save();
    }
  }

  async removeZymbol(): Promise<Zymbol> {
    // @ts-expect-error
    if (this.isSaving || this.isDeleted) {
      return this;
    }

    const { isOnlyChild } = this;

    const zymbolGroup = await this.zymbolGroup;
    await this.destroyRecord();

    if (isOnlyChild && zymbolGroup) {
      await zymbolGroup.removeZymbolGroup();
    }

    return this;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    zymbol: Zymbol;
  }
}
