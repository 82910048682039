import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import DynamicIslandActionComponent from "../../dynamic-island/action/component";
import getColumnCount from "client/lib/get-column-count";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import type Favorable from "client/models/favorable";
import type Favorite from "client/models/favorite";
import Project from "client/models/project";
import type BrandStyleService from "client/services/brand-style";
import type FavoritesService from "client/services/favorites";
import type NotificationsService from "client/services/notifications";

export default class FavoritesComponent extends DynamicIslandActionComponent {
  @service
  private declare store: Store;

  @service
  private declare router: RouterService;

  @service
  private declare brandStyle: BrandStyleService;

  @service
  private declare favorites: FavoritesService;

  @service
  declare notifications: NotificationsService;

  @tracked
  loading = false;

  @tracked
  private columnCount = 0;

  @tracked
  private loadedFavorites: Favorite[] = [];

  actionName = "favorites";

  styleNamespace = getStyleNamespace("discovery/dashboard/primary-actions/favorites");

  get visibleFavorites(): Favorite[] | undefined {
    return this.loadedFavorites.slice(0, this.columnCount);
  }

  @action
  async didInsert(): Promise<void> {
    super.didInsert();

    try {
      this.loading = true;

      await Promise.all([
        this.loadFavorites(),
        // Have to load the brand into the store to allow the brandable scenes to be branded
        this.loadBrand()
      ]);
    } finally {
      this.loading = false;
    }
  }

  @action
  didInsertGrid(element: HTMLElement): void {
    new ResizeObserver(() => requestAnimationFrame(() => this.calculateColumnCount(element))).observe(element);

    void this.calculateColumnCount(element);
  }

  @action
  async onSelect(favorable: Favorable): Promise<void> {
    this.onInteraction();

    try {
      const favorite = await this.favorites.loadAndFind(favorable);
      if (favorite) {
        const project = await this.createProject();
        await this.router.transitionTo("authenticated.project.import-favorite", project.id, favorite.id);
      } else {
        this.notifications.error("Well that is weird we could not load the favorite to import it");
      }
    } finally {
      this.loading = false;
    }
  }

  private async createProject(): Promise<Project> {
    const aspectRatio = await this.wideAspectRatio();

    return Project.createWithDefaults(this.store, {
      aspectRatio
    }).save();
  }

  private calculateColumnCount(element: HTMLElement): void {
    this.columnCount = getColumnCount(element);
  }

  private async loadBrand(): Promise<void> {
    this.store.peekAll("brandStyle").length || (await this.brandStyle.getBrand());
  }

  private async wideAspectRatio(): Promise<AspectRatio> {
    return this.store.findRecord("aspectRatio", WIDE_ASPECT_RATIO_ID);
  }

  private async loadFavorites(): Promise<void> {
    const aspectRatioId = (await this.wideAspectRatio()).id;

    // @ts-expect-error
    this.loadedFavorites = (await this.store.query("favorite", {
      /* eslint-disable camelcase */
      per_page: 12,
      aspect_ratio_id: aspectRatioId,
      include: ["favorable"]
      /* eslint-enable camelcase */
    })) as Favorite[];
  }
}
