import type { ZymbolConfigType, ZymbolCategory } from "renderer-engine";
import {
  DEFAULT_PROJECT_SCENE_DURATION,
  ImageAnimations,
  getZymbolConfig,
  ImageConfig,
  SvgConfig,
  VideoConfig
} from "renderer-engine";
import SelectableAsset from "./selectable-asset";
import type { Timeline, EventRegister, Scene, Media } from "client/lib/editor-domain-model";
import {
  ChangePositionMutation,
  Background,
  ChangeAssetOffsetMutation,
  Image,
  Svg,
  UpdateAssetMutation,
  VideoClip,
  AddSceneMutation,
  Watermark
} from "client/lib/editor-domain-model";
import AssetFactory from "client/lib/scene/asset-factory";
import ZymbolConfigFactory from "client/lib/scene/zymbol-config-factory";
import { defaultLowerSizeLimit, defaultUpperSizeLimit, getResetPosition } from "client/lib/timeline/media";

export default abstract class SelectableMediaAsset extends SelectableAsset {
  protected abstract asZymbolConfig(): Partial<ZymbolConfigType> | Promise<Partial<ZymbolConfigType>>;
  protected abstract get zymbolCategory(): ZymbolCategory;

  async addToTimeline(
    timeline: Timeline,
    eventRegister: EventRegister,
    order?: number,
    duration = DEFAULT_PROJECT_SCENE_DURATION
  ): Promise<Scene> {
    const mutation = new AddSceneMutation(timeline, duration, order);
    await mutation.prepare(eventRegister.facade);
    const newScene = eventRegister.fire(mutation) as Scene;

    await this.addToMedia(newScene, newScene.background, eventRegister);

    return newScene;
  }

  async addToMedia(scene: Scene, media: Media, eventRegister: EventRegister): Promise<void> {
    const previouslyEmpty = !media.asset?.originalAsset;
    const defaultConfig = getZymbolConfig(this.zymbolCategory);

    if (defaultConfig instanceof SvgConfig) {
      if (media.asset instanceof Svg) {
        const existingConfig = new ZymbolConfigFactory().createZymbolSvgConfig(media.asset);

        defaultConfig.asset = existingConfig.asset;
        defaultConfig.opacity = existingConfig.opacity;
        defaultConfig.color = existingConfig.color;
      }
    }

    if (defaultConfig instanceof ImageConfig) {
      if (media instanceof Background) {
        defaultConfig.animation = ImageAnimations.ZOOM_IN;
      }

      if (media.asset instanceof Image) {
        const existingConfig = new ZymbolConfigFactory().createZymbolImageConfig(media.asset);

        defaultConfig.invertX = existingConfig.invertX!;
        defaultConfig.animationFit = existingConfig.animationFit!;
        defaultConfig.animation = existingConfig.animation!;
        defaultConfig.imageMod = existingConfig.imageMod;
        defaultConfig.opacity = existingConfig.opacity;
      }

      if (media.asset instanceof VideoClip) {
        const existingConfig = new ZymbolConfigFactory().createZymbolVideoConfig(media.asset);
        defaultConfig.imageMod = existingConfig.imageMod;
      }
    }

    if (defaultConfig instanceof VideoConfig) {
      if (media.asset instanceof VideoClip) {
        const existingConfig = new ZymbolConfigFactory().createZymbolVideoConfig(media.asset);
        defaultConfig.animation = existingConfig.animation!;
        defaultConfig.imageMod = existingConfig.imageMod;
      }
      if (media.asset instanceof Image) {
        const existingConfig = new ZymbolConfigFactory().createZymbolImageConfig(media.asset);
        defaultConfig.imageMod = existingConfig.imageMod;
      }
    }

    const config = {
      ...defaultConfig,
      ...(await this.asZymbolConfig())
    };

    const domainAsset = new AssetFactory().createAssetFromConfig(config, this.zymbolCategory);
    eventRegister.fire(new UpdateAssetMutation(media, domainAsset));
    eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));

    // Frame mutations
    if (media.asset instanceof Image && media.asset.frame) {
      const position = await getResetPosition(media, scene, {
        upperSizeLimit: media.position
      });
      eventRegister.fire(new ChangePositionMutation(media, position));
      eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));
    } else {
      if (media instanceof Background && media.position.fillsCanvas) {
        const position = await getResetPosition(media, scene);
        eventRegister.fire(new ChangePositionMutation(media, position));
      } else if (previouslyEmpty) {
        let scaledRect;
        if (media instanceof Watermark) {
          scaledRect = await getResetPosition(media, scene, {
            upperSizeLimit: defaultUpperSizeLimit,
            lowerSizeLimit: defaultLowerSizeLimit
          });
        } else {
          scaledRect = await getResetPosition(media, scene, {
            alignMediaToCenter: true,
            upperSizeLimit: defaultUpperSizeLimit,
            lowerSizeLimit: defaultLowerSizeLimit
          });
        }

        eventRegister.fire(new ChangePositionMutation(media, scaledRect));
      }
    }
  }
}
