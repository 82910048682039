import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class BrandNewController extends Controller {
  queryParams: any = ["returnPath"];

  @tracked
  declare returnPath: string | undefined;

  @service
  declare router: RouterService;

  @action
  onSave(): void {
    this.transitionAway();
  }

  @action
  onCancel(): void {
    this.transitionAway();
  }

  private transitionAway(): void {
    const nextRoute = this.returnPath || "authenticated.brand.index";
    void this.router.transitionTo(nextRoute);
  }

  resetReturnPath(): void {
    this.returnPath = undefined;
  }
}
