import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, Timeline, Text, Logo } from "client/lib/editor-domain-model";
import { Animation, Media, TimeSlice, VideoClip } from "client/lib/editor-domain-model";
import ElementTimingModifier from "client/lib/element-timing-modifier";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type AdvancedTimingService from "client/services/advanced-timing";

interface Args {
  timeline: Timeline;
  eventRegister: EventRegister;
  element: Text | Logo;
  project: Project;
}

export default class AdvancedTimingLayerComponent extends Component<Args> {
  @service
  declare advancedTiming: AdvancedTimingService;

  @tracked
  timeSlice = new TimeSlice(0, 0);

  styleNamespace = getStyleNamespace("advanced-timing/layer");

  elementBorder?: HTMLElement;

  @action
  onChange(trim: TimeSlice): void {
    this.timeSlice = trim;
  }

  @action
  onDragEnd(): void {
    const modifier = new ElementTimingModifier(
      getOwner(this)!,
      this.args.eventRegister,
      this.args.element,
      this.timeSlice,
      this.args.project
    );
    void modifier.modifyCustomTiming();
  }

  @action
  showLayerBorder(): void {
    const playerWrapper = document.querySelector("#TimingCanvas");
    const elementBounds = this.args.element.position;

    if (playerWrapper) {
      const playerBounds = playerWrapper.getBoundingClientRect();
      this.elementBorder = document.createElement("div");
      const top = elementBounds.y * playerBounds.height,
        left = elementBounds.x * playerBounds.width,
        width = elementBounds.width * playerBounds.width,
        height = elementBounds.height * playerBounds.height;

      this.elementBorder.className = `${this.styleNamespace}__previewLayerBorder`;
      this.elementBorder.style.cssText = `top: ${top}px; left: ${left}px; height: ${height}px; width: ${width}px;`;

      document.querySelector("#TimingCanvas")?.appendChild(this.elementBorder);
    }
  }

  @action
  removeLayerBorder(): void {
    document.querySelectorAll(`.${this.styleNamespace}__previewLayerBorder`).forEach((x) => x.remove());
  }

  @action
  async didInsert(): Promise<void> {
    const duration = this.args.element.customTimingDuration;
    const offset = this.args.element.customTimingOffset;
    this.timeSlice = new TimeSlice(offset || 0, duration || this.advancedTiming.caption.duration - (offset || 0));
  }

  get assetDuration(): number | undefined {
    const { element } = this.args;

    if (!(element instanceof Media)) {
      return;
    }

    const { asset } = element;

    if (!(asset instanceof VideoClip || asset instanceof Animation)) {
      return;
    }

    if (asset.loopable) {
      return;
    }

    const duration = asset.trim?.duration;

    if (!duration) {
      return;
    }

    const playbackSpeed = asset instanceof Animation ? asset.playbackSpeed : 1;

    return duration / playbackSpeed;
  }
}
