export default class LibraryCategory {
  constructor(public name: string, public title: string, public image: string, private _label?: string) {}

  get query(): string {
    return this.name;
  }

  get label(): string {
    return this._label ?? this.title;
  }
}
