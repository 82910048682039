import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  onRemove(): void;
}

export default class MediaActionsDeleteComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("media-actions/delete");
}
