import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";

export default class PlanController extends Controller {
  queryParams = ["couponCode", "showCouponField"];

  @tracked
  couponCode?: string;

  @tracked
  showCouponField?: boolean;

  public resetTransientState(): void {
    this.couponCode = undefined;
    this.showCouponField = undefined;
  }
}
