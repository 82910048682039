import Model, { attr } from "@ember-data/model";

export default class AudioTrackGenre extends Model {
  @attr
  count?: number;

  get name(): string {
    return this.capitalize(this.id.replace("_", " "));
  }

  capitalize(str: string): string {
    return str.replace(/(?:^|\s|["'([{])+\S/g, (match: string) => match.toUpperCase());
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    audioTrackGenre: AudioTrackGenre;
  }
}
