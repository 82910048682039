import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { AudioClipCategory } from "renderer-engine";
import type ProjectEditAudioSelectComponent from "client/components/tidal/project-edit/audio/select/component";
import type { AudioAsset } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { SaveAction } from "client/lib/editor-domain-model/events/mutations/save-action";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Narration from "client/models/narration";
import NarrationVoice from "client/models/narration-voice";
import { PlanInterval } from "client/models/plan";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PropertiesPanelService from "client/services/properties-panel";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type UpgradeService from "client/services/upgrade";
import type { VoiceoverSelectPresenter } from "client/services/voiceover-select-modal";

interface NarrationComponentArgs {
  model: VoiceoverSelectPresenter;
  currentNarrationId: string | undefined;
  modal: ProjectEditAudioSelectComponent;
}

export default class NarrationComponent extends Component<NarrationComponentArgs> {
  @service
  declare store: Store;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @service
  declare auth: AuthService;

  @tracked
  declare narrationText?: string;

  @tracked
  voice?: NarrationVoice;

  @tracked
  generatingNarration = false;

  @tracked
  declare narration: Narration;

  @tracked
  declare voices: Array<NarrationVoice>;

  @service
  declare upgrade: UpgradeService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @tracked
  generationUpdateNeeded = false;

  private declare previewElement: HTMLAudioElement;

  styleNamespace = getStyleNamespace("ai/narration");

  constructor(owner: object, args: NarrationComponentArgs) {
    super(owner, args);

    void this.loadDependencies();
  }

  @action
  async playPreview(event: Event): Promise<void> {
    event.preventDefault();
    document.querySelectorAll(".playing").forEach((i) => i.classList.remove("playing"));

    const buttonElement = <HTMLElement>event.currentTarget;
    const src = buttonElement.getAttribute("preview-src");

    // eslint-disable-next-line no-null/no-null
    if (src !== null) {
      if (this.previewElement !== undefined) {
        this.previewElement.pause();
        this.previewElement.currentTime = 0;

        if (this.previewElement.getAttribute("src") === src) {
          this.previewElement.setAttribute("src", "");
          return;
        }
      }

      this.previewElement = new Audio(src);
      void this.previewElement.play();
      buttonElement.classList.add("playing");

      this.previewElement.addEventListener("ended", () => {
        buttonElement.classList.remove("playing");
      });
    }
  }

  @action
  async createNarration(event: Event): Promise<void> {
    event.preventDefault();

    if (this.narrationText !== undefined) {
      this.generatingNarration = true;
      const narration = this.store.createRecord("narration");
      narration.text = this.narrationText;
      narration.voice = this.voice?.name || this.voices?.[0]?.name || "Kendra";
      narration.project = this.args.model.project;
      await narration
        .save()
        .then(async (result) => {
          this.narration = result;
          this.generationUpdateNeeded = false;
        })
        .catch((error) => {
          this.honeybadger.notify("Error creating narration", {
            context: {
              error
            },
            name: this.constructor.name
          });
        });
    }

    this.generatingNarration = false;
  }

  @action
  async addToTimeline(): Promise<void> {
    if (this.narration !== undefined) {
      const { eventRegister, timeline, sceneId } = this.args.model;
      const projectScene = await this.store.findRecord("projectScene", sceneId!);

      let offset = projectScene.startTime ?? 0;
      if (timeline.voiceovers.length > 0) {
        const lastClip = timeline.voiceovers.reduce((a, b) => (a.endTime > b.endTime ? a : b));
        offset = Math.max(lastClip.offset + lastClip.duration, 0);
      }

      const asset = new Object() as AudioAsset;
      asset.id = this.narration.id;
      asset.name = this.narration.name;
      asset.type = "narration";
      asset.url = this.narration.url;

      const mutation = new DomainModel.AddAudioClipMutation(timeline, asset, AudioClipCategory.VOICEOVER, offset, -1);
      await mutation.prepare(eventRegister.facade);
      this.mutate(mutation);
      await this.save().then(() => {
        this.advancedEditor.expandTimeline();
        this.propertiesPanel.open();
        this.notifications.success("Your voice-over has been added to the timeline");
        this.args.modal.cancel();
      });
    }
  }

  @action
  async updateNarration(event: Event): Promise<void> {
    if (this.narrationText !== undefined) {
      const { eventRegister, timeline } = this.args.model;

      await this.createNarration(event);

      const asset = new Object() as AudioAsset;
      asset.id = this.narration.id;
      asset.name = this.narration.name;
      asset.type = "narration";
      asset.url = this.narration.url;

      const clip = timeline.audioClips.find((clip) => clip.audibleId === this.args.currentNarrationId);
      if (clip) {
        const deleteMutation = new DomainModel.DeleteAudioClipMutation(timeline, clip);
        const addMutation = new DomainModel.AddAudioClipMutation(
          timeline,
          asset,
          AudioClipCategory.VOICEOVER,
          clip.offset,
          -1
        );
        this.mutate(deleteMutation);
        await addMutation.prepare(eventRegister.facade);
        this.mutate(addMutation);
        await this.save().then(() => {
          this.propertiesPanel.open();
          this.notifications.success("Your voice-over has been updated in the timeline");
          this.args.modal.cancel();
        });
      }
    }
  }

  @action
  selectVoice(voice: NarrationVoice): void {
    if (this.voice !== voice) {
      this.voice = voice;
      this.generationUpdateNeeded = true;
      sessionStorage.setItem("voice", voice.name);
    }
  }

  @action
  async narrationTextUpdated(): Promise<void> {
    if (this.narration) {
      this.generationUpdateNeeded = true;
    }
  }

  @action
  async onUpgrade(): Promise<void> {
    this.args.modal.cancel();

    void this.upgrade.selectTopTierPlan({ context: "", interval: PlanInterval.MONTH });
  }

  get readyToGenerate(): boolean {
    return !!this.voice && !!this.narrationText;
  }

  get showUpgradeOption(): boolean {
    return (
      !this.auth.currentFullOrTrialSubscription?.trialing && !this.auth.currentFullSubscription?.plan.isTopTierPlan
    );
  }

  get disabledAddToTimeline(): boolean {
    return this.generationUpdateNeeded || this.showUpgradeOption;
  }

  mutate<T>(mutation: DomainModel.StrictMutation<T>): T | void {
    const { eventRegister } = this.args.model;
    eventRegister?.fire<T | void>(mutation);
  }

  private async loadDependencies(): Promise<void> {
    // @ts-expect-error
    this.voices = ((await this.store.findAll("narrationVoice")) as NarrationVoice[])
      .slice()
      .sort((a, b) => a.order - b.order);

    this.voice = sessionStorage.getItem("voice")
      ? this.voices.find((savedVoice) => savedVoice.name === sessionStorage.getItem("voice"))
      : this.voices[0];

    if (this.args.currentNarrationId) {
      this.narration = await this.store.findRecord("narration", this.args.currentNarrationId);
      this.narrationText = this.narration.text;
      this.selectVoice(
        this.voices.find((voice) => voice.name === this.narration.voice) || new NarrationVoice({ name: "Kendra" })
      );
    }
  }

  private async save(): Promise<void> {
    const { eventRegister, timeline } = this.args.model;

    await eventRegister.facade.saveAudioClips(timeline);
    void eventRegister.fire(new SaveAction(() => this.timelineEvents.publish(TimelineEvents.AUDIO_CHANGED)));
  }
}
