import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type NotificationsService from "client/services/notifications";
import type PaymentSourceService from "client/services/payment-source";
import type PlansService from "client/services/plans";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

interface LatestSubscriptionActionsComponentArgs {
  topAction?: boolean;
  actionTitle: string;
  subscription?: Subscription;
}

export default class LatestSubscriptionActionsComponent extends Component<LatestSubscriptionActionsComponentArgs> {
  @service
  declare notifications: NotificationsService;

  @service
  declare plans: PlansService;

  @service
  declare router: RouterService;

  @service
  declare tracking: TrackingService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare paymentSource: PaymentSourceService;

  @tracked
  reactivating = false;

  @tracked
  hasCreditCard = false;

  styleNamespace = getStyleNamespace("account/subscription/latest-subscription/actions");

  @action
  async didInsert(): Promise<void> {
    this.hasCreditCard = !!(await this.paymentSource.getCard());
  }

  @action
  openUpgrade(): void {
    void this.upgrade.open();
  }

  @action
  async handleReactivation(): Promise<void> {
    if (this.subscriptionTrialing || !this.subscriptionCanceled) {
      this.notifications.error("Your subscription cannot be reactivated. Please contact support.");
    }

    if (this.subscriptionEnded) {
      await this.handleEndedSubscriptionReactivation();
    } else {
      await this.reactivateActiveSubscription();
    }
  }

  get canReactivate(): boolean {
    return this.subscriptionCanceled && !(this.subscriptionTrialing || this.subscriptionJustTrialed);
  }

  get planOffered(): boolean {
    return !!this.plan?.offered;
  }

  get planDiscontinued(): boolean {
    return !!this.plan?.discontinued;
  }

  get subscriptionCanceled(): boolean {
    return !!this.subscription?.canceled;
  }

  get subscriptionEnded(): boolean {
    return !!this.subscription?.ended;
  }

  get subscriptionTrialing(): boolean {
    return !!this.subscription?.trialing;
  }

  get subscriptionJustTrialed(): boolean {
    return !!this.subscription?.justTrialed;
  }

  private get plan(): Plan | undefined {
    return this.args.subscription?.plan ?? this.plans.getFreePlan();
  }

  private get subscription(): Subscription | undefined {
    return this.args.subscription;
  }

  private async handleEndedSubscriptionReactivation(): Promise<void> {
    if (this.plan?.offered) {
      if (this.hasCreditCard) {
        await this.redirectToEndedSubscriptionReactivation();
      } else if (this.plan) {
        await this.upgradeToPlan();
      }
    } else {
      void this.upgrade.open();
    }
  }

  private async upgradeToPlan(): Promise<void> {
    await this.upgrade.selectPlan({ context: "link - account->subscription - subscribe", selectedPlan: this.plan! });
  }

  private async reactivateActiveSubscription(): Promise<void> {
    if (this.reactivating) {
      return;
    }

    try {
      this.reactivating = true;
      await this.args.subscription?.reactivateActiveSubscription();

      this.trackReactivation();
      this.notifications.success("Your subscription has been reactivated");
    } catch (err) {
      this.notifications.error("There was a problem reactivating your subscription. Please contact support.");
    } finally {
      this.reactivating = false;
    }
  }

  private trackReactivation(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_REACTIVATE_ACTIVE_SUBSCRIPTION, {
      subscriptionId: this.subscription?.id,
      planId: this.plan?.id
    });
  }

  private async redirectToEndedSubscriptionReactivation(): Promise<void> {
    await this.router.transitionTo("authenticated.account.subscription.reactivate", this.plan!.id);
  }
}
