import type { Scene } from "../../scene";
import type { CaptionOptionArgs, TimelineFacade } from "../../timeline";
import { AddCaptionMutation } from "./add-caption-mutation";

export class AddNewCaptionMutation extends AddCaptionMutation {
  constructor(
    scene: Scene,
    private offset: number,
    private duration: number,
    private withLogo = false,
    private withText = true
  ) {
    super(scene);
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    const captionOptions: Partial<CaptionOptionArgs> = { withText: this.withText, withLogo: this.withLogo };

    this.caption = await facade.newCaption(this.scene, this.offset, this.duration, captionOptions);
  }
}
