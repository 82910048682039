import type { Element } from "../../element";
import type { Caption } from "../../scene";
import { StrictMutation } from "./mutation";

export class SetLayerOrderMutation extends StrictMutation<void> {
  grouped = false;

  private originalLayerOrders: { element: Element; layerOrder: number }[];

  constructor(private element: Element, caption: Caption, private newLayerOrder: number) {
    super();

    this.originalLayerOrders = caption.elementsSortedByLayer.map((element) => ({
      element,
      layerOrder: element.layerOrder
    }));
  }

  run(): void {
    const { element, newLayerOrder } = this;

    element._layerOrder = newLayerOrder;
  }

  revert(): void {
    for (const { element, layerOrder } of this.originalLayerOrders) {
      element._layerOrder = layerOrder;
    }
  }
}
