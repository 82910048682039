import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type MobileService from "client/services/mobile";

export default class TheMobileSupportModalComponent extends Component {
  @service
  declare mobile: MobileService;

  @action
  dismissModal(): void {
    this.mobile.dismissMobileModal();
  }

  get showMobileWarning(): boolean {
    return this.mobile.showMobileWarning;
  }
}
