import Model, { attr } from "@ember-data/model";

export default class ProjectTranscriptionCaptionUrl extends Model {
  @attr("string")
  declare vttReadUrl: string;

  @attr("string")
  declare vttWriteUrl: string;

  @attr("string")
  declare srtWriteUrl: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectTranscriptionCaptionUrl: ProjectTranscriptionCaptionUrl;
  }
}
