import Controller from "@ember/controller";
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import moment from "moment";
import type { PublishModel } from "./route";
import config from "client/config/environment";
import TrackingEvents, { Locations } from "client/events";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type ProjectTranscription from "client/models/project-transcription";
import type ShutterstockCredits from "client/models/shutterstock-credits";
import type ShutterstockPurchases from "client/models/shutterstock-purchases";
import type VideoWatchEventSummary from "client/models/video-watch-event-summary";
import type Zymbol from "client/models/zymbol";
import type AuthService from "client/services/auth";
import type CommentService from "client/services/comment";
import type CurrentUserService from "client/services/current-user";
import type ExportService from "client/services/export";
import type HoneybadgerService from "client/services/honeybadger";
import type MobileService from "client/services/mobile";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type ProjectsService from "client/services/projects";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export const DOWNLOAD_TYPE = {
  PAID: "paid"
};

export interface ThumbnailOptions {
  frame?: number;
  path?: string;
}

export interface RenderOptions {
  cacheReadEnabled?: boolean;
  progressMonitored?: boolean;
  thumbnailOptions?: ThumbnailOptions;
}

export default class PublishController extends Controller<PublishModel> {
  @service
  declare auth: AuthService;

  @service
  declare comment: CommentService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare export: ExportService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare projects: ProjectsService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare mobile: MobileService;

  @tracked
  analyticsLoading = false;

  @tracked
  analyticsTimePeriod = "Infinity";

  @tracked
  renderStarting = false;

  @tracked
  showTeamInviteModal = false;

  @tracked
  videoWatchEventSummary?: VideoWatchEventSummary;

  @tracked
  shutterstockCredits?: ShutterstockCredits;

  @tracked
  shutterstockPurchases?: ShutterstockPurchases;

  @tracked
  failedStockLicensesPurchase = false;

  @tracked
  latestRender?: ProjectRender;

  @tracked
  projectTranscription?: ProjectTranscription;

  @tracked
  overlayDismissed = false;

  @tracked
  reloadingCaptions = false;

  // Feedback that a rebuild has been requested is too slow due to all the network work
  @tracked
  rebuildRequested = false;

  get renderRequired(): boolean {
    return this.renderFailed || (this.renderSucceeded && this.project.renderOutdated);
  }

  get orderChangeRequired(): boolean {
    return (this.renderSucceeded || this.isRendering) && this.mobile.isMobile;
  }

  get isMaxDurationExceeded(): boolean {
    return this.project.duration > this.maxDuration;
  }

  get project(): Project {
    return this.model.project;
  }

  get isRendering(): boolean {
    return this.latestRender?.rendering ?? false;
  }

  get renderFailed(): boolean {
    return this.latestRender?.failed ?? false;
  }

  get renderSucceeded(): boolean {
    return this.latestRender?.successful ?? false;
  }

  get showComments(): boolean {
    return this.project.showComments ?? false;
  }

  get showTeamInvite(): boolean {
    return Boolean(this.auth.currentTeam?.canInviteTeamMembers && !this.currentUser.user?.addMembersSeenAt);
  }

  get teamInviteTrackingLocation(): string {
    return Locations.LOCATION_EXPORT_PAGE;
  }

  get showWatermarkUpgradeCta(): boolean {
    if (this.auth.currentSubscription?.trialing && !this.auth.currentFullSubscription) {
      return true;
    }
    return false;
  }

  get headerText(): string {
    if (this.showRenderFailed) {
      return "Something went wrong";
    } else {
      return "Publish your video";
    }
  }

  get showOverlay(): boolean {
    return (
      !this.renderSucceeded ||
      this.renderFailed ||
      this.isRendering ||
      (this.renderRequired && !this.overlayDismissed) ||
      this.export.isChooseThumbnail ||
      this.rebuildRequested
    );
  }

  get subheaderText(): string {
    if (this.showRenderFailed) {
      return "Sorry, there was a problem building your video.";
    } else if (!this.isRendering && !this.renderSucceeded) {
      return "Build your video. Then share or download.";
    } else {
      return "";
    }
  }

  private get showRenderFailed(): boolean {
    return this.renderFailed && this.canRenderProject;
  }

  get denyBuild(): boolean {
    return !!this.shutterstockPurchases?.denyBuild;
  }

  get upgradeRequired(): boolean {
    return !this.canRenderProject;
  }

  get showRebuildActions(): boolean {
    return !this.rebuildRequested && this.renderSucceeded && !this.denyBuild && this.canRenderProject;
  }

  get showShutterstockPurchaseNotification(): boolean {
    return (
      this.canRenderProject && (this.shutterstockPurchases?.creditsSystemExceeded || this.failedStockLicensesPurchase)
    );
  }

  get canRenderProject(): boolean {
    return this.permissions.has("feature_project_render");
  }

  get canManageSubscription(): boolean {
    return this.auth.canManageSubscription;
  }

  get maxDuration(): number {
    if (this.auth.currentSubscription?.plan?.isTopTierPlan) {
      return config.maxVideoDuration.teams;
    }
    return config.maxVideoDuration.others;
  }

  get maxDurationMinutes(): number {
    return this.maxDuration / 60;
  }

  get comments(): Comment[] {
    // @ts-expect-error
    return this.comment.comments;
  }

  get updateButtonsDisabled(): boolean {
    return this.renderRequired && !this.overlayDismissed;
  }

  @action
  async closeTeamInvite(): Promise<void> {
    this.showTeamInviteModal = false;
    if (this.latestRender) {
      void this.projects.monitorRenderProgress(this.latestRender);
    }
  }

  @action
  onUpgrade(): void {
    if (!this.permissions.has("feature_long_videos")) {
      void this.upgrade.selectTopTierPlan({
        interval: this.auth.currentSubscription?.plan?.interval,
        context: "banner - export before render - unlock longer video limit"
      });
    } else {
      void this.upgrade.open();
    }
  }

  @action
  setFailedStockLicensesPurchase(value: boolean): void {
    this.failedStockLicensesPurchase = value;
  }

  @action
  async onChangeAnalyticsTimePeriod(newTimePeriod: string): Promise<void> {
    if (newTimePeriod !== this.analyticsTimePeriod) {
      let startDate;

      if (isFinite(parseInt(newTimePeriod, 10))) {
        startDate = new Date(moment().subtract(newTimePeriod, "days").toDate()).toISOString();
      }

      try {
        this.analyticsLoading = true;

        this.videoWatchEventSummary = await this.store.queryRecord("videoWatchEventSummary", {
          // eslint-disable-next-line camelcase
          project_id: this.project.id,
          // eslint-disable-next-line camelcase
          start_date: startDate
        });

        this.analyticsTimePeriod = newTimePeriod;
      } finally {
        this.analyticsLoading = false;
      }
    }
  }

  @action
  async renderProject(ctaContext: string, thumbnailOptions?: ThumbnailOptions): Promise<void> {
    try {
      this.rebuildRequested = true;
      this.trackRenderEvent(ctaContext);

      await this.handleUpdatingPexelsUrls();

      await this.renderProjectRaw(thumbnailOptions);
    } finally {
      this.rebuildRequested = false;
    }
  }

  @action
  async renderProjectRaw(thumbnailOptions?: ThumbnailOptions): Promise<void> {
    await this.render({ thumbnailOptions });
  }

  @action
  async renderProjectWithoutCache(): Promise<void> {
    await this.render({ cacheReadEnabled: false });
  }

  @action
  async downloadGif(): Promise<void> {
    try {
      if (this.latestRender) {
        await this.trackDownloadGifEvent(this.latestRender);
        window.location.href = this.latestRender.url.slice(0, -3) + "gif";
      }
    } catch (error: unknown) {
      this.honeybadger.notify(error as Error, "ProjectDownloadModal/Download");
    }
  }

  @action
  dismissOverlay(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.overlayDismissed = true;
  }

  @action
  async onCaptionsEdited(): Promise<void> {
    // Forces a reload of the video element and therefore the captions track
    this.reloadingCaptions = true;
    next(() => {
      this.reloadingCaptions = false;
    });
  }

  private async render(options: RenderOptions): Promise<void> {
    if (!this.canRenderProject) {
      this.notifications.error("Dang you cannot render videos, you need to upgrade");
      return;
    }

    this.showTeamInviteModal = this.showTeamInvite;
    if (this.showTeamInviteModal) {
      options.progressMonitored = false;
    }

    this.renderStarting = true;

    try {
      this.latestRender = await this.projects.renderProject(this.project, options);
      this.projectTranscription = await this.latestRender.projectTranscription;
    } finally {
      this.renderStarting = false;
    }
  }

  private trackRenderEvent(ctaContext: string): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_BUILD_VIDEO, {
      downloadType: DOWNLOAD_TYPE.PAID,
      projectId: this.project.id,
      ctaContext
    });
  }

  private async trackDownloadGifEvent(render: ProjectRender): Promise<void> {
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_DOWNLOAD_GIF, await render.buildDownloadEvent());
  }

  private async handleUpdatingPexelsUrls(): Promise<void> {
    for (const zymbol of this.project.zymbols) {
      if (this.hasIncorrectPexelsUrl(zymbol)) {
        await this.updatePexelsUrls(zymbol);
      }
    }

    this.alertOnIncorrectPexelsUrls();
  }

  private async updatePexelsUrls(zymbol: Zymbol): Promise<void> {
    if (zymbol.pexelsVideoId) {
      const video = await this.store.findRecord("pexelsVideo", zymbol.pexelsVideoId);

      zymbol.cfg.video!.url = video.previewVideoUrl;
      zymbol.cfg.video!.lowResVideoUrl = video.lowResVideoUrl;
      zymbol.cfg.video!.previewVideoUrl = video.previewVideoUrl;
      zymbol.cfg.video!.previewImageUrl = video.previewImageUrl;

      await zymbol.save();
    }
  }

  private hasIncorrectPexelsUrl(zymbol: Zymbol): boolean {
    return !!(zymbol.cfg.video?.url?.includes("player.vimeo.com/external") && zymbol.pexelsVideoId);
  }

  private alertOnIncorrectPexelsUrls(): void {
    const incorrectZymbols = this.project.zymbols.filter((zymbol) => this.hasIncorrectPexelsUrl(zymbol));

    if (incorrectZymbols.length > 0) {
      const mappedZymbols = incorrectZymbols.map(
        (zymbol) => `[sceneId: ${zymbol.sceneId}, pexelsVideoId: ${zymbol.pexelsVideoId}]`
      );

      const message =
        `PexelsVideos: could not fetch correct url for project ${this.project.id}: ` + mappedZymbols.join(", ");

      this.honeybadger.notify(message);
    }
  }
}
