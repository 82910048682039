import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type NotificationsService from "client/services/notifications";
import type UpgradeService from "client/services/upgrade";

interface PlanInfoArgs {
  plan?: Plan;
}

export default class PlanInfoComponent extends Component<PlanInfoArgs> {
  @service
  private declare notifications: NotificationsService;

  @service
  private declare upgrade: UpgradeService;

  styleNamespace = getStyleNamespace("export/video-overlay/plan-info");

  get isTopTierPlan(): boolean {
    return this.plan?.isTopTierPlan ?? false;
  }

  @action
  public getPlan(): void {
    if (this.plan) {
      void this.upgrade.selectPlan({
        context: "button - edit_only_video_overlay - get plan",
        selectedPlan: this.plan
      });
    } else {
      this.notifications.error("Oh nuts. Something went wrong attempting to upgrade. Please contact Support");
    }
  }

  @action
  public seePlans(): void {
    void this.upgrade.open();
  }

  private get plan(): Plan | undefined {
    return this.args.plan;
  }
}
