import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type { Bounds, Size } from "renderer-engine";
import type ElementManipulationSources from "../bounding-box/component";
import type { Element, EventRegister } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Layer from "client/models/zymbols/layer-order";
import type ScriptWorkstationService from "client/services/script-workstation";

interface Args {
  element: Element;
  elementBounds: Bounds;
  canvasBounds: Size;
  eventRegister: EventRegister;
  selected?: boolean;
  layer?: Layer;
  canDuplicate?: boolean;
  hasRemovableBackground?: boolean;
  isBackground?: boolean;

  onSelect?: () => void;
  onDoubleClick?: () => void;
  onRotate: (rotation: number) => void;
  onDrag?: (position: Bounds) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onResize?: (source: ElementManipulationSources, position: Bounds) => void;
  onResizeStart?: () => void;
  onResizeEnd?: () => void;
  onScale?: (source: ElementManipulationSources, position: Bounds) => void;
  onScaleStart?: () => void;
  onScaleEnd?: () => void;
  onRemove?: () => void;
  onDuplicate?: () => void;
  onCopy?: (e: Event, origin: string) => Promise<void>;
  onPaste?: (e: Event, origin: string) => Promise<void>;
  onReplace?: () => void;
  onMoveToBackground?: () => void;
  onSwapWithBackground?: () => void;
  onResetAspectRatio?: () => void;
  onFitToClip?: () => void;
  onEditTiming?: () => void;
}

export default class CanvasElementComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("canvas/element");

  @service
  private declare scriptWorkstation: ScriptWorkstationService;

  @action
  mouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.args.onSelect) {
      this.args.onSelect();
    }
  }

  @action
  doubleClick(ev: MouseEvent): void {
    const dblClickable = (ev.target as HTMLElement)?.nodeName !== "svg";

    if (this.args.onDoubleClick && dblClickable) {
      this.args.onDoubleClick();
    }
  }

  @action
  onRotate(rotation: number): void {
    this.args.onRotate(rotation);
  }

  @action
  onRotateEnd(): void {
    return;
  }

  @action
  onDragStart(): void {
    this.args.onDragStart?.();
  }

  @action
  onDragEnd(): void {
    this.args.onDragEnd?.();
  }

  @action
  onResizeStart(): void {
    this.args.onResizeStart?.();
  }

  @action
  onResizeEnd(): void {
    this.args.onResizeEnd?.();
  }

  @action
  onScaleStart(): void {
    this.args.onScaleStart?.();
  }

  @action
  onScaleEnd(): void {
    this.args.onScaleEnd?.();
  }

  get styleAttr(): SafeString {
    const { x, y, width, height } = this.args.elementBounds;

    return htmlSafe(`
      top: ${y * 100}%;
      left: ${x * 100}%;
      width: ${width * 100}%;
      height: ${height * 100}%;
    `);
  }
}
