import { tracked } from "@glimmer/tracking";
import type { ColorBrandKey } from "../../brand";

export class AnimationColor {
  /** @internal */
  @tracked
  _color: string;

  /** @internal */
  @tracked
  _name: string;

  /** @internal */
  @tracked
  _role: string;

  /** @internal */
  @tracked
  _colorBrandKey?: ColorBrandKey;

  constructor(name: string, role: string, color: string, colorBrandKey?: ColorBrandKey) {
    this._name = name;
    this._role = role;
    this._color = color;
    this._colorBrandKey = colorBrandKey;
  }

  public copy(): AnimationColor {
    return new AnimationColor(this.name, this.role, this.color, this.colorBrandKey);
  }

  public get color(): string {
    return this._color;
  }

  public get name(): string {
    return this._name;
  }

  public get role(): string {
    return this._role;
  }

  public get colorBrandKey(): ColorBrandKey | undefined {
    return this._colorBrandKey;
  }
}
