import Service, { service } from "@ember/service";
import type AuthService from "client/services/auth";

export type PermissionName =
  | "global_project_copy"
  | "global_project_edit"
  | "publish_template_content"
  | "content_unpublished_view"
  | "project_render_debug"
  | "feature_brand_apply"
  | "feature_brand_edit"
  | "feature_multiple_brands"
  | "feature_premium_tracks"
  | "feature_edit_only_mode_notifications"
  | "feature_editor_role_notifications"
  | "feature_project_download"
  | "feature_project_render"
  | "feature_custom_fonts"
  | "feature_inactive_team_member_notifications"
  | "feature_ava_use_cases"
  | "feature_foreground_videos"
  | "feature_seats_purchase"
  | "feature_captions"
  | "feature_long_videos"
  | "feature_text_to_speech"
  | "feature_hidden_browser_notification"
  | "feature_style_panel"
  | "feature_animate_panel"
  | "feature_svg_upload"
  | "feature_element_manipulation";

export default class PermissionsService extends Service {
  @service
  declare auth: AuthService;

  public has(permissionName: PermissionName): boolean {
    return this.auth.permissions.includes(permissionName);
  }
}
