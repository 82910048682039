import TrackingEvents from "client/events";
import type PlaybackService from "client/services/playback";
import type TrackingService from "client/services/tracking";

export default class PlaybackController {
  constructor(private playback: PlaybackService, private tracking: TrackingService) {}

  get isPlaying(): boolean {
    return this.playback.playing;
  }

  get isLoading(): boolean {
    return this.playback.buffering || this.playback.readying;
  }

  async play(startTime: number): Promise<void> {
    await this.playback.updatePreviewTime(this.playback.currentTime);
    await this.playback.play(startTime, Infinity);
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_PLAY_PREVIEW);
  }

  async stop(): Promise<void> {
    await this.playback.stop();
  }

  async playTimeRange(startTime: number, endTime: number): Promise<void> {
    await this.playback.play(startTime, endTime);
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_PREVIEW_SCENE);
  }
}
