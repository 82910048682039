import AnalyticsService from "client/services/analytics";

const initialize = (): void => {
  AnalyticsService.initialize();
};

export default {
  name: "analytics",
  initialize
};
