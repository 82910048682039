import Evented from "@ember/object/evented";
import Service from "@ember/service";

type EventNames = "close";

export default class PopoverService extends Service.extend(Evented) {
  publish(eventName: EventNames, ...args: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.trigger(eventName, ...args);
  }

  subscribe(eventName: EventNames, handler: (...args: any[]) => void): void {
    this.on(eventName, handler);
  }

  unsubscribe(eventName: EventNames, handler: (...args: any[]) => void): void {
    this.off(eventName, handler);
  }
}
