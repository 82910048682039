import type { RecordProjectResponse } from "@biteable/network-model";
import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import type Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { RecordRequestRouteModel } from "./route";
import { RECORD_ASSET_DELETE_FAILURE_NOTIFICATION } from "client/models/record-project";
import type NotificationsService from "client/services/notifications";

export default class AssetLibraryRecordRequestController extends Controller<RecordRequestRouteModel> {
  @service
  declare notifications: NotificationsService;

  get hasRespondents(): boolean {
    return this.model.numOfRespondents > 0;
  }

  @action
  async removeRecording(requestResponse: RecordProjectResponse): Promise<void> {
    try {
      await requestResponse.destroy();
      await this.reloadResults();
    } catch (error: any) {
      this.notifications.error(RECORD_ASSET_DELETE_FAILURE_NOTIFICATION);
      throw error;
    }
  }

  async reloadResults(): Promise<void> {
    await (getOwner(this).lookup("route:authenticated.asset-library.record-requests.record") as Route)?.refresh();
  }
}
