import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import { PlanInterval } from "client/models/plan";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

interface YearlyPromptArgs {
  oldPlan?: Plan;
  newPlan: Plan;
}

export default class YearlyPromptComponent extends Component<YearlyPromptArgs> {
  styleNamespace = getStyleNamespace("purchase/yearly-prompt");

  @service
  declare upgrade: UpgradeService;

  @service
  declare plans: PlansService;

  get show(): boolean {
    return this.showDuringPlanChange || this.showDuringPlanCheckout;
  }

  get yearlyDiscountCTA(): string {
    if (this.showDuringPlanCheckout) {
      return `Would you like it for ${this.yearlyDiscount} less?`;
    }
    return `Did you know, you would save  ${this.yearlyDiscount} each year when you pay yearly?`;
  }

  get showSavedAmount(): boolean {
    return this.showDuringPlanCheckout && this.newPlan.isYearly;
  }

  get savedAmount(): string | void {
    const monthlyPlan = this.plans.findPlan(this.newPlan.skuCode, PlanInterval.MONTH);

    if (this.newPlan.isYearly && monthlyPlan) {
      return this.plans.getDiscountAmount(monthlyPlan, this.newPlan);
    }
  }

  get showYearlyDiscount(): boolean {
    return this.showDuringPlanChange || this.showDuringMonthlyPlanCheckout;
  }

  private get oldPlan(): Plan | undefined {
    return this.args.oldPlan;
  }

  private get newPlan(): Plan {
    return this.args.newPlan;
  }

  private get yearlyDiscount(): string | void {
    const yearlyPlan = this.plans.findPlan(this.newPlan.skuCode, PlanInterval.YEAR);

    if (yearlyPlan) {
      return this.plans.getDiscountAmount(this.newPlan, yearlyPlan);
    }
  }

  private get showDuringPlanCheckout(): boolean {
    return !this.oldPlan && !!this.yearlyUpgradePlan;
  }

  private get showDuringMonthlyPlanCheckout(): boolean {
    return this.showDuringPlanCheckout && this.newPlan.isMonthly;
  }

  private get showDuringPlanChange(): boolean {
    const { newPlan, oldPlan } = this.args;

    if (oldPlan && this.yearlyUpgradePlan && newPlan.isMonthly) {
      return this.skuChanged || !oldPlan.isYearly;
    }
    return false;
  }

  private get skuChanged(): boolean {
    return this.newPlan.skuCode !== this.oldPlan?.skuCode;
  }

  private get yearlyUpgradePlan(): Plan | undefined {
    return this.plans.findPlan(this.newPlan.skuCode, PlanInterval.YEAR);
  }

  @action
  selectYearly(): void {
    if (this.yearlyUpgradePlan) {
      void this.upgrade.selectPlan({
        context: "link - purchase subscriptions - change to yearly",
        selectedPlan: this.yearlyUpgradePlan,
        couponCode: this.upgrade.couponCode,
        showCouponField: this.upgrade.showCouponField
      });
    }
  }
}
