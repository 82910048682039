import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { Grid } from "client/lib/grid-guides";

interface GridGuidesComponentArgs {
  bounds: { width: number; height: number };
  grid: Grid;
}

export default class GridGuidesComponent extends Component<GridGuidesComponentArgs> {
  styleNamespace = getStyleNamespace("app/grid-guides");

  get marginStyle(): SafeString {
    return htmlSafe(this.args.grid.getMarginStyles(this.args.bounds.width));
  }

  get gridStyle(): SafeString {
    return htmlSafe(this.args.grid.getGridStyles(this.args.bounds.width));
  }
}
