import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type ActiveStorageService from "./active-storage";
import type FoldersService from "./folders";
import UploadValidator from "./upload-validator";
import type AudioTrack from "client/models/audio-track";
import type UserAsset from "client/models/user-asset";
import type AjaxService from "client/services/ajax";
import type NotificationsService from "client/services/notifications";

const now = (): number => new Date().getTime();

const PREVIEW_GENERATION_TIMEOUT = 30 * 1000; // ms
const PREVIEW_GENERATION_INTERVAL = 3 * 1000; // ms

const DEFAULT_FILTER_ORDER = "created_at:desc";

export const ASSET_LIBRARY_VIDEO_MIMETYPES = ["video/mp4", "video/quicktime"];
export const ASSET_LIBRARY_IMAGE_MIMETYPES = ["image/jpeg", "image/png", "image/svg+xml"];
export const ASSET_LIBRARY_AUDIO_MIMETYPES = ["audio/mpeg", "audio/wav", "audio/x-m4a"];
export const ASSET_LIBRARY_THUMBNAIL_MIMETYPES = [
  "image/bmp",
  "image/heic",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "image/x-icon"
];

export default class AssetLibraryService extends Service {
  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare activeStorage: ActiveStorageService;

  _filterOrder: string = DEFAULT_FILTER_ORDER;

  set filterOrder(order: string) {
    if (!order || this.filterOrder === order) {
      return;
    }

    this._filterOrder = order;
  }

  get filterOrder(): string {
    return this._filterOrder;
  }

  get acceptMimeTypes(): string {
    return ASSET_LIBRARY_VIDEO_MIMETYPES.concat(
      ASSET_LIBRARY_IMAGE_MIMETYPES,
      ASSET_LIBRARY_AUDIO_MIMETYPES
    ).toString();
  }

  async uploadFile(files: FileList | File[], modelName = "user"): Promise<void | UserAsset | AudioTrack> {
    if (files.length === 1 && files[0]) {
      const file = files[0];
      const validator = new UploadValidator(file, this.acceptMimeTypes);

      if (!validator.isValid) {
        this.notifications.warning(validator.errorMessage);
        return;
      }

      if (file.type.includes("audio/")) {
        return await this.activeStorage.createMusicTrack(file);
      } else {
        if (modelName === "team") {
          return await this.activeStorage.createTeamAsset(file);
        } else {
          return await this.activeStorage.createUserAsset(file);
        }
      }
    }
  }

  async uploadFiles(files: FileList | File[], modelName = "user"): Promise<(UserAsset | AudioTrack)[]> {
    const total = files.length;
    let i = 1;
    const assets = [];

    for (const file of files) {
      const msg = total > 1 ? `(${i++}/${total})` : "";
      const validator = new UploadValidator(file, this.acceptMimeTypes);

      if (!validator.isValid) {
        this.notifications.warning(validator.errorMessage);
        break;
      }

      if (file.type.includes("audio/")) {
        assets.push(await this.activeStorage.createMusicTrack(file));
      } else {
        if (modelName === "team") {
          assets.push(await this.activeStorage.createTeamAsset(file, undefined, msg));
        } else {
          assets.push(await this.activeStorage.createUserAsset(file, undefined, msg));
        }
      }
    }

    return assets;
  }

  async waitForAssetLibraryPreviewUrl(asset: UserAsset): Promise<void> {
    const end = now() + PREVIEW_GENERATION_TIMEOUT;
    do {
      if (asset.previewUrl && asset.previewUrl !== asset.filestackUrl) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, PREVIEW_GENERATION_INTERVAL));
      await asset.reload();
    } while (now() < end);
    throw Error("Timed-out while generating `userAsset` `previewUrl`");
  }

  async hideUserAsset(userAsset: UserAsset): Promise<void> {
    try {
      userAsset.showInLibrary = false;
      await userAsset.save();
      await this.folders.reloadContent();
    } catch (err) {
      this.notifications.error("Whoops! There was a problem deleting your uploaded file");
      throw err;
    }
  }
}
