import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum TagPosition {
  LEFT_TOP = "left-top",
  LEFT_BOTTOM = "left-bottom"
}

interface ShutterstockPriceTagArgs {
  licensed: boolean;
  price?: string;
  position?: TagPosition;
}

export default class ShutterstockPriceTagComponent extends Component<ShutterstockPriceTagArgs> {
  styleNamespace = getStyleNamespace("shutterstock-price-tag");

  get position(): TagPosition {
    return this.args.position ?? TagPosition.LEFT_BOTTOM;
  }

  get description(): string {
    return this.args.licensed ? "You purchased this clip. Use it for free." : this.priceDescription;
  }

  private get priceDescription(): string {
    const priceDesc = this.args.price ? `${this.args.price} or 1 credit. ` : "";
    return priceDesc + "Try this clip for free. We’ll remind you to purchase or remove it before export.";
  }
}
