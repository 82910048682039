import Model, { attr, hasMany } from "@ember-data/model";
import type ContentStack from "./content-stack";
import type { ManyArray } from "./ember-data-types";

export default class ContentCollection extends Model {
  @hasMany("content-stack", { async: false, inverse: "contentCollection" })
  declare contentStacks: ManyArray<ContentStack>;

  @attr("string")
  declare name: string;

  @attr("number")
  declare displayOrder: number;

  @attr("number")
  declare publishedStackCount: number;

  @attr("boolean")
  declare published: boolean;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    contentCollection: ContentCollection;
  }
}
