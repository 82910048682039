import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, Timeline, TimingInfo } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type AdvancedTimingService from "client/services/advanced-timing";
import type SmartEditorService from "client/services/smart-editor";
import type SmartPlaybackService from "client/services/smart-playback";

interface Args {
  timeline: Timeline;
  eventRegister: EventRegister;
  project: Project;
}

interface IntervalPrecisionRange {
  zoomStart: number; // pixels per second
  zoomEnd: number; // pixels per second
  precision: number; // seconds
  label: number; // seconds
}

const DEFAULT_INTERVAL_PRECISION_RANGE = { zoomStart: 60, zoomEnd: Infinity, precision: 0.5, label: 1 };

const intervalPrecisionRanges: IntervalPrecisionRange[] = [
  { zoomStart: 0, zoomEnd: 15, precision: 5, label: 10 },
  { zoomStart: 15, zoomEnd: 60, precision: 1, label: 5 }
];

export default class AdvancedTimingComponent extends Component<Args> {
  @service
  declare smartPlayback: SmartPlaybackService;

  @service
  private declare smartEditor: SmartEditorService;

  @service
  declare advancedTiming: AdvancedTimingService;

  @tracked
  trackWidth = 0;

  styleNamespace = getStyleNamespace("advanced-timing");

  @action
  async previewCaption(): Promise<void> {
    if (this.smartPlayback.playing) {
      await this.smartPlayback.stop();
    } else {
      await this.playCaption();
    }
  }

  @action
  async playCaption(startTime = 0): Promise<void> {
    await this.smartPlayback.play(this.timing.startTime + startTime, this.timing.endTime, false);
  }

  @action
  async stopCaption(): Promise<void> {
    await this.smartPlayback.stop();
  }

  get timing(): TimingInfo {
    const { timeline } = this.args;
    const caption = this.advancedTiming.caption || this.smartEditor.activeAsCaption();
    const timing = timeline.getCaptionTiming(caption);
    return timing;
  }

  get currentTime(): number {
    return this.smartPlayback.currentTime - this.timing.startTime;
  }

  @action
  didInsertTrackElement(element: HTMLElement): void {
    const resizeObserver = new ResizeObserver(() => (this.trackWidth = element.clientWidth));
    resizeObserver.observe(element);
  }

  get intervalPrecisionRange(): IntervalPrecisionRange {
    return (
      intervalPrecisionRanges.find((r) => r.zoomStart < this.pixelsPerSecond && r.zoomEnd >= this.pixelsPerSecond) ||
      DEFAULT_INTERVAL_PRECISION_RANGE
    );
  }

  get pixelsPerSecond(): number {
    return this.trackWidth / this.advancedTiming.caption.duration;
  }
}
