import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { sortBy } from "lodash";
import { AvaStyles } from "client/authenticated/survey/data";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import { WIDE_ASPECT_RATIO_SLUG } from "client/models/aspect-ratio";
import Project from "client/models/project";

interface ProjectSettingsArgs {
  project?: Project;
  duplicating?: boolean;
  save(project: Project | undefined, properties: Partial<Project>): Promise<unknown>;
  cancel(): unknown;
}

const DUPLICATE_SUFFIX = " (copy)";

export default class ProjectSettingsComponent extends Component<ProjectSettingsArgs> {
  styleNamespace = getStyleNamespace("project/project-settings");

  @service
  declare store: Store;

  @tracked
  saving = false;

  @tracked
  title = Project.generateProjectName();

  @tracked
  style = this.args.project?.style;

  @tracked
  aspectRatio?: AspectRatio;

  @tracked
  userWatermarkVisible = false;

  @tracked
  brandStyleId = this.args.project?.brandStyleId;

  @tracked
  private aspectRatios: AspectRatio[] = [];

  @action
  didInsert(): void {
    void this.loadAspectRatios();
  }

  private async loadAspectRatios(): Promise<void> {
    // @ts-expect-error
    this.aspectRatios = sortBy(await this.store.findAll("aspectRatio", { reload: true }), ["id"]);

    if (!this.aspectRatio) {
      this.aspectRatio = this.aspectRatios.find((aspectRatio) => aspectRatio.slug === WIDE_ASPECT_RATIO_SLUG);
    }
  }

  @action
  async projectDidChange(_: HTMLElement, [project]: [Project | undefined]): Promise<void> {
    if (project) {
      this.title = this.args.duplicating ? `${project.title}${DUPLICATE_SUFFIX}` : project.title;
      this.aspectRatio = project.aspectRatio;
      this.userWatermarkVisible = project.userWatermarkVisible;
    }
  }

  @action
  updateAspectRatio(aspectRatio: AspectRatio): void {
    this.aspectRatio = aspectRatio;
  }

  @action
  updateBrandStyleId(brandStyleId: string): void {
    this.brandStyleId = brandStyleId;
  }

  @action
  async save(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.saving) {
      return;
    }

    const { aspectRatio, userWatermarkVisible, title, brandStyleId, style } = this;

    this.saving = true;

    try {
      await this.args.save(this.args.project, {
        aspectRatio,
        userWatermarkVisible,
        title,
        brandStyleId,
        style
      });
    } finally {
      this.saving = false;
    }
  }

  @action
  cancel(): void {
    this.args.cancel();
  }

  private get aspectRatioChanged(): boolean {
    return this.aspectRatio?.id !== this.args.project?.aspectRatio.id;
  }

  private get isNewProject(): boolean {
    return !this.args.project || !!this.args.project.isNew;
  }

  @action
  onStyleChange(style: string): void {
    this.style = style;
  }

  get styles(): AvaStyles[] {
    return Object.values(AvaStyles);
  }

  get submitLabel(): string {
    if (this.isNewProject) {
      return "Create";
    }

    // If a project has its aspect-ratio changed then it can only be duplicated
    if (this.aspectRatioChanged || this.args.duplicating) {
      return "Duplicate";
    }

    return "Save";
  }
}
