import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import type SelectableAsset from "client/models/selectable-asset";

interface Args {
  selectAsset(asset: SelectableAsset): void;
  searchKey?: string;
}

export default class StockVideosPanel extends Component<Args> {
  @service
  declare store: Store;

  get params(): Record<string, string | number | undefined> {
    return {
      /* eslint-disable camelcase */
      query: this.args.searchKey,
      per_page: 24
      /* eslint-enable camelcase */
    };
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<SelectableAsset>> {
    // @ts-expect-error
    return this.store.query("zymbolFootage", params) as Array<SelectableAsset>;
  }
}
