import Controller from "@ember/controller";
import { service } from "@ember/service";
import type LoaderService from "client/services/loader";
import type PromptsService from "client/services/prompts";
import type SessionService from "client/services/session";

export default class ApplicationController extends Controller {
  @service
  declare session: SessionService;

  @service
  declare prompts: PromptsService;

  @service
  declare loader: LoaderService;

  get isAuthenticated(): boolean {
    return this.session.isAuthenticated;
  }
}
