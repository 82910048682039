import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PermissionsService from "client/services/permissions";

interface ExportActionsBuildArgs {
  projectId: string;
  renderProject: (ctaContext: string) => Promise<void>;
  renderStarting: boolean;
}

export default class ExportActionsBuild extends Component<ExportActionsBuildArgs> {
  @service
  private declare permissions: PermissionsService;

  styleNamespace = getStyleNamespace("export-actions/build");

  @action
  async onBuild(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_BUILD_VIDEO);
  }

  get actionHeaderText(): string {
    if (this.permissions.has("feature_project_render")) {
      return "Publish your video in HD 1080p quality.";
    } else if (this.permissions.has("feature_edit_only_mode_notifications")) {
      return "Publish and sharing are unavailable in Edit-only mode.";
    } else {
      return "As an Editor, you can create videos but not publish. Let your Account Owner know you're ready to publish videos 🎉";
    }
  }
}
