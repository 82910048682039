import Service, { service } from "@ember/service";
import config from "client/config/environment";
import type CurrentUserService from "client/services/current-user";

export default class StorageService extends Service {
  @service
  declare currentUser: CurrentUserService;

  setItem(key: string, value: string, isKeyAnonymous = false): void {
    localStorage.setItem(this.getKey(key, isKeyAnonymous), value);
  }

  getItem(key: string, isKeyAnonymous = false): string | undefined {
    return localStorage.getItem(this.getKey(key, isKeyAnonymous)) ?? undefined;
  }

  clearItem(key: string, isKeyAnonymous = false): void {
    localStorage.removeItem(this.getKey(key, isKeyAnonymous));
  }

  setSessionItem(key: string, value: string): void {
    localStorage.setItem(this.getSessionKey(key), value);
  }

  getSessionItem(key: string): string | undefined {
    return localStorage.getItem(this.getSessionKey(key)) ?? undefined;
  }

  removeSessionItem(key: string): void {
    localStorage.removeItem(this.getSessionKey(key));
  }

  clear(): void {
    Object.keys(localStorage)
      .filter((key) => !key.startsWith(config.localStoragePersistentPrefix))
      .forEach((key) => localStorage.removeItem(key));
  }

  private getKey(key: string, isKeyAnonymous = false): string {
    if (isKeyAnonymous) {
      return [config.localStoragePersistentPrefix, key].join("::");
    } else {
      return [config.localStoragePersistentPrefix, this.currentUser.user?.id, key].join("::");
    }
  }

  private getSessionKey(key: string): string {
    return [config.localStorageSessionPrefix, this.currentUser.user?.id, key].join("::");
  }
}
