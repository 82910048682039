import { tracked } from "@glimmer/tracking";
import LibraryContentController from "./library-content";
import type { LibraryContentModel } from "client/routes/library-content";

export default abstract class LibrarySearchController<
  M extends LibraryContentModel = LibraryContentModel
> extends LibraryContentController<M> {
  queryParams = ["query"];

  @tracked
  query = "";
}
