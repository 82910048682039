import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type Folder from "client/models/folder";

const MIN_SIDEBAR_TOP_Y_POSITION_TO_AUTO_SCROLL = 115;

export default class GalleryService extends Service {
  @tracked
  dragging = false;

  @tracked
  sidebarScrolled = false;

  @tracked
  draggedFolder?: Folder;

  @action
  setDraggedFolder(folder: Folder): void {
    this.draggedFolder = folder;
  }

  @action
  autoScrollSidebar(event: DragEvent): void {
    const folderSidebar = document.querySelectorAll("[data-id=autoscroll-sidebar]").item(0);

    if (folderSidebar && !this.sidebarScrolled && folderSidebar?.clientHeight < event.clientY) {
      folderSidebar?.scroll({
        top: folderSidebar.scrollHeight,
        behavior: "smooth"
      });
      this.sidebarScrolled = true;
    }

    if (folderSidebar && this.sidebarScrolled && event.clientY < MIN_SIDEBAR_TOP_Y_POSITION_TO_AUTO_SCROLL) {
      folderSidebar?.scroll({
        top: 0,
        behavior: "smooth"
      });
      this.sidebarScrolled = false;
    }
  }
}
