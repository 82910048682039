import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface CheckboxArgs {
  checked: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => unknown;
}

export default class CheckboxComponent extends Component<CheckboxArgs> {
  styleNamespace = getStyleNamespace("north-star/checkbox");

  @action
  handleChange(): void {
    this.args.onChange?.(!this.args.checked);
  }
}
