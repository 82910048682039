import type { QueryOptions } from "../../controller";
import FavoritesBaseController from "../../controller";

export default class FavoritesTeamAllController extends FavoritesBaseController {
  protected teamFavorite = true;

  get otherQueryOptions(): QueryOptions {
    return {
      filter: "team"
    };
  }
}
