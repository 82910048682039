import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface UIThumbnailArgs {
  active?: boolean;
  aspectRatio: string;
  label?: string;
  loadingMessage?: string;
  preload: string;
  previewImageUrl?: string;
  previewVideoUrl?: string;
  onremove?: () => void;
  onselect?: () => void;
}

export default class UIThumbnailComponent extends Component<UIThumbnailArgs> {
  private _element!: HTMLElement;

  videoElement?: HTMLVideoElement;
  progressElement?: HTMLElement;

  @tracked
  isPlaying = false;

  @tracked
  playProgress = 0;

  @tracked
  imageLoading = false;

  @tracked
  videoLoading = false;

  styleNamespace = getStyleNamespace("ui/ui-thumbnail");

  get active(): boolean {
    return this.args.active ?? false;
  }

  get aspectRatio(): string {
    return this.args.aspectRatio ?? "1:1";
  }

  get preload(): string {
    return this.args.preload ?? "metadata";
  }

  get selectable(): boolean {
    return !!this.args.onselect;
  }

  get hasImage(): boolean {
    return !!this.args.previewImageUrl;
  }

  get hasVideo(): boolean {
    return !!this.args.previewVideoUrl;
  }

  get isLoading(): boolean {
    return this.videoLoading || this.imageLoading;
  }

  @action
  click(): void {
    if (this.args.onselect) {
      this.args.onselect();
    }
  }

  @action
  handleMouseEnter(): void {
    if (this.isPlaying) {
      return;
    }

    if (this.hasVideo) {
      void this.playVideoPreview();
    }
  }

  @action
  handleMouseLeave(): void {
    if (!this.isDestroyed && !this.isDestroying) {
      this.videoLoading = false;
      this.stopVideoPreview();
    }
  }

  onVideoTimeUpdate(ev: Event): void {
    const video = ev.target as HTMLVideoElement;

    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.videoLoading) {
      this.videoLoading = false;
    }

    const progress = video.currentTime / video.duration;
    window.requestAnimationFrame(() => {
      if (this.progressElement) {
        this.progressElement.style.transform = `scaleX(${progress}`;
      }
    });
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
    this._element.addEventListener("mouseenter", this.handleMouseEnter);
    this._element.addEventListener("mouseleave", this.handleMouseLeave);

    if (this.hasImage) {
      const imageElement = this._element.querySelector("img") as HTMLImageElement;
      if (!imageElement.complete || imageElement.naturalHeight === 0) {
        this.imageLoading = true;
        imageElement.onload = (): void => {
          if (!this.isDestroyed && !this.isDestroying) {
            this.imageLoading = false;
          }
        };
        imageElement.onerror = (): void => {
          if (!this.isDestroyed && !this.isDestroying) {
            imageElement.src = "";
          }
        };
      }
    }

    if (!this.hasVideo) {
      return;
    }

    this.videoElement = this._element.querySelector("video") as HTMLVideoElement;
    this.videoElement.addEventListener("timeupdate", this.onVideoTimeUpdate.bind(this));
    this.videoElement.muted = true;
    this.progressElement = this._element.querySelector(`.${(this as any).styleNamespace}__Progress`) as HTMLElement;
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    this._element.removeEventListener("mouseenter", this.handleMouseEnter);
    this._element.removeEventListener("mouseleave", this.handleMouseLeave);
    if (this.videoElement) {
      this.videoElement.removeAttribute("src");
      this.videoElement.load();
      this.videoElement.remove();
    }
  }

  async playVideoPreview(): Promise<void> {
    if (!this.hasVideo || !this.videoElement) {
      return;
    }

    this.videoLoading = true;
    this.isPlaying = true;
    await this.videoElement.play();
  }

  stopVideoPreview(): void {
    if (!this.videoElement) {
      return;
    }

    this.videoElement.pause();
    this.videoElement.currentTime = 0;

    this.playProgress = 0;
    this.isPlaying = false;
  }
}
