import { modifier } from "ember-modifier";

/**
 * While this is called analytics-target it is mostly used in Fullstory.
 * Renaming in code while useful will have the side effect of forcing
 * Fullstory users to update dashboards and views which is probably too
 * much onus on them so this comment is here to explain the purpose so
 * it can still be used and its purpose understood
 */
export default modifier((element, [targetName]: unknown[]) => {
  if (targetName === undefined) {
    delete (element as HTMLElement).dataset["intercomTarget"];
  } else {
    (element as HTMLElement).dataset["intercomTarget"] = targetName as string;
  }
});
