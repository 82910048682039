import Service from "@ember/service";
import config from "client/config/environment";
import type UnsplashPhoto from "client/models/unsplash-photo";

export default class UnsplashService extends Service {
  /**
   * Hit the Unsplash `downloadUrl` for API compliance so that Unsplash can track the usage of photos
   */
  async markUnsplashPhotoDownloaded(unsplashPhoto: UnsplashPhoto): Promise<void> {
    await fetch(unsplashPhoto.downloadUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        authorization: `Client-ID ${config.unsplashAccessKey}`
      }
    });
  }
}
