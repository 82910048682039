import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { AudioClipCategory } from "renderer-engine";
import type { AudioClip } from "client/lib/editor-domain-model";
import { SaveAction } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import type UserAsset from "client/models/user-asset";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AssetLibraryService from "client/services/asset-library";
import type NotificationsService from "client/services/notifications";
import type PendingService from "client/services/pending";
import type PropertiesPanelService from "client/services/properties-panel";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type { VoiceoverSelectPresenter } from "client/services/voiceover-select-modal";
import type VoiceoverSelectModalService from "client/services/voiceover-select-modal";

export default class VoiceoverSelectModal extends Component {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare assetLibrary: AssetLibraryService;

  @service
  declare notifications: NotificationsService;

  @service
  declare pending: PendingService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @service
  declare router: RouterService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  private declare voiceoverSelectModal: VoiceoverSelectModalService;

  get modalElement(): HTMLElement | null {
    return document.getElementById("modal");
  }

  get isVoiceOverSelectModalOpen(): boolean {
    return this.voiceoverSelectModal.visible;
  }

  get replacedClipId(): string | undefined {
    return this.voiceoverSelectModal.replacedClipId;
  }

  get audioUserAssets(): UserAsset[] | undefined {
    return this.voiceoverSelectModal.audioUserAssets;
  }

  get voiceovers(): AudioClip[] | undefined {
    return this.model.timeline.audioClips.filter((clip: AudioClip) => clip.category === "voiceover");
  }

  get loadingMore(): boolean {
    return this.voiceoverSelectModal.loadingMore;
  }

  get currentlyLoading(): boolean {
    return this.voiceoverSelectModal.currentlyLoading;
  }

  get isLastPage(): boolean {
    return this.voiceoverSelectModal.isLastPage;
  }

  get currentNarrationId(): string | undefined {
    return this.replacedClip?.audibleType === "Narration" ? this.replacedClip.audibleId : undefined;
  }

  private get model(): VoiceoverSelectPresenter {
    return this.voiceoverSelectModal.model!;
  }

  private get replacedClip(): AudioClip | undefined {
    return this.audioClips.find((clip) => clip.id === this.replacedClipId);
  }

  private get audioClips(): AudioClip[] {
    return this.model.timeline.audioClips.filter((clip: AudioClip) => clip.category === "voiceover");
  }

  @action
  onCancel(): void {
    const { timeline } = this.model;
    this.voiceoverSelectModal.close();
    if (timeline && !timeline.scenes.length) {
      void this.router.transitionTo("authenticated.project");
    } else {
      void this.router.transitionTo("authenticated.project.scene.voiceover", {
        queryParams: { audioClipId: undefined }
      });
    }
  }

  @action
  async uploaded(): Promise<void> {
    await this.voiceoverSelectModal.refreshAssets();
  }

  @action
  async selectUserAssets(userAssets: UserAsset[]): Promise<void> {
    if (userAssets.length > 0 || this.replacedClip) {
      if (userAssets.length > 0) {
        for (let i = 0; i < userAssets.length; i++) {
          await this.pending.push(this.addAudioClip(userAssets[i]!, this.insertedAt + i * 2, -1));
        }
      }
      if (this.replacedClip) {
        await this.pending.push(this.removeAudioClip(this.replacedClip));
      }
      this.propertiesPanel.open();
      this.notifications.success("Your project's voice-over has been updated");
    }
  }

  @action
  async removeUserAsset(asset: UserAsset): Promise<void> {
    await this.assetLibrary.hideUserAsset(asset);
    await this.voiceoverSelectModal.removeTrack(asset);
  }

  private async addAudioClip(asset: UserAsset, offset: number, duration: number): Promise<void> {
    const { eventRegister, timeline } = this.model;
    const mutation = new DomainModel.AddAudioClipMutation(
      timeline,
      asset,
      AudioClipCategory.VOICEOVER,
      offset,
      duration
    );
    await mutation.prepare(eventRegister.facade);
    this.mutate(mutation);
    void this.save();
  }

  mutate<T>(mutation: DomainModel.StrictMutation<T>): T | void {
    const { eventRegister } = this.model;
    eventRegister?.fire<T | void>(mutation);
  }

  private async save(): Promise<void> {
    const { eventRegister, timeline } = this.model;

    await eventRegister.facade.saveAudioClips(timeline);
    this.advancedEditor.expandTimeline();
    void eventRegister.fire(new SaveAction(() => this.timelineEvents.publish(TimelineEvents.AUDIO_CHANGED)));
  }

  private get insertedAt(): number {
    // where the new added clips are inserted on timeline (seconds)
    if (this.voiceoverSelectModal.addedAt) {
      return parseFloat(this.voiceoverSelectModal.addedAt);
    } else if (this.replacedClip) {
      return this.replacedClip.offset;
    } else if (this.voiceoverSelectModal.projectScene?.startTime) {
      return this.voiceoverSelectModal.projectScene.startTime;
    }

    return 0;
  }

  private async removeAudioClip(clip: AudioClip): Promise<void> {
    const { timeline } = this.model;
    this.mutate(new DomainModel.DeleteAudioClipMutation(timeline, clip));
    void this.save();
  }
}
