import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Background, EventRegister, Text } from "client/lib/editor-domain-model";
import {
  Alignments,
  ChangeAssetMirrorMutation,
  ChangePositionMutation,
  Rect,
  TextStyle,
  TextStylesMutation
} from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isText } from "client/lib/timeline/caption";
import { transaction } from "client/lib/transaction";
import type AdvancedEditorService from "client/services/advanced-editor";

export default class FlipLayoutComponent extends Component<{ eventRegister: EventRegister }> {
  @service
  declare advancedEditor: AdvancedEditorService;

  styleNamespace = getStyleNamespace("app-header/project/flip-layout");

  @action
  @transaction
  async onClick(): Promise<void> {
    if (!this.advancedEditor.scene) {
      return;
    }

    const activeScene = this.advancedEditor.scene;

    if (activeScene) {
      const { background, captions } = activeScene;

      this.flipContainer(background);
      this.flipBackgroundAsset(background);

      for (const caption of captions) {
        const { logos, texts } = caption;

        for (const content of [...logos, ...texts]) {
          this.flipContainer(content);

          if (isText(content)) {
            this.flipTextStyles(content);
          }
        }
      }

      await this.args.eventRegister.facade.saveScene(activeScene);
    }
  }

  private flipContainer(container: { _position: Rect; position: Rect }): void {
    const { x, y, width, height } = container.position;

    const flippedX = 1 - (x + width);
    const flippedPosition = Rect.fromRect({ x: flippedX, y, width, height });

    this.args.eventRegister.fire(new ChangePositionMutation(container, flippedPosition));
  }

  private flipBackgroundAsset(background: Background): void {
    const { eventRegister } = this.args;
    const { asset } = background;

    if (asset) {
      eventRegister.fire(new ChangeAssetMirrorMutation(asset, !asset.mirror));
    }
  }

  private flipTextStyles(text: Text): void {
    const flippedStyles = text.styles.map((style) => {
      const { alignment } = style;

      const flippedStyle = new TextStyle(style);

      if (alignment === Alignments.LEFT) {
        flippedStyle._alignment = Alignments.RIGHT;
      } else if (alignment === Alignments.RIGHT) {
        flippedStyle._alignment = Alignments.LEFT;
      }

      return flippedStyle;
    });

    this.args.eventRegister.fire(new TextStylesMutation(text, flippedStyles));
  }
}
