import { service } from "@ember/service";
import { attr } from "@ember-data/model";
import type { VideoConfig, Asset } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import { favorable, FAVORABLE_TYPES } from "./favorable";
import SelectableMediaAsset from "./selectable-media-asset";
import TrackingEvents from "client/events";
import type { Timeline } from "client/lib/editor-domain-model";
import { getVideoDuration } from "client/lib/video";
import type FootageService from "client/services/footage";

interface StockItemUsageData {
  provider: string;
  providerAssetId: string;
  elementId: string;
  type: string;
  name: string;
}

@favorable
export default class ZymbolFootage extends SelectableMediaAsset {
  @service
  declare footage: FootageService;

  @attr("string")
  name!: string;

  @attr("string")
  previewImageUrl!: string;

  @attr("string")
  previewVideoUrl!: string;

  @attr("string")
  lowResVideoUrl!: string;

  @attr("string")
  url!: string;

  @attr()
  tags!: string[];

  @attr("string")
  downsizedUrlBase?: string;

  readonly favorableType = FAVORABLE_TYPES.VIDEO_BLOCKS;

  metadataForFavorite = {};

  get favorableProviderAssetId(): string {
    return this.id;
  }

  get thumbVideoUrl(): string {
    return this.previewVideoUrl;
  }

  get thumbImageUrl(): string {
    return this.previewImageUrl;
  }

  readonly zymbolCategory = ZymbolCategory.VIDEO;

  async asZymbolConfig(): Promise<Partial<VideoConfig> & { asset: Asset }> {
    const { id, previewImageUrl, previewVideoUrl, lowResVideoUrl, url, name, downsizedUrlBase } = this;
    const duration = await getVideoDuration(previewVideoUrl);

    return {
      asset: {
        id,
        type: "zymbol-footage"
      },
      previewImageUrl,
      previewVideoUrl,
      lowResVideoUrl,
      url,
      name,
      duration,
      downsizedUrlBase,
      startTime: 0
    };
  }

  async trackAddToTimeline(timeline: Timeline, params = {}): Promise<void> {
    const storyblock = await this.footage.getStoryBlocksRecord(this, timeline.id);

    Object.assign(this, {
      url: storyblock.url,
      previewVideoUrl: this.previewVideoUrl,
      lowResVideoUrl: storyblock.previewUrl,
      downsizedUrlBase: storyblock.downsizedUrlBase
    });

    await super.trackAddToTimeline(timeline, params);
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_STOCK_CONTENT;

  get trackUsageData(): StockItemUsageData {
    return {
      provider: "storyblocks",
      providerAssetId: this.id,
      elementId: this.id,
      type: "video",
      name: this.name
    };
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    zymbolFootage: ZymbolFootage;
  }
}
