import * as DomainModel from "client/lib/editor-domain-model";
import { Rect, getDefaultRect } from "client/lib/editor-domain-model";
import type AssetFactory from "client/lib/scene/asset-factory";
import type ProjectScene from "client/models/project-scene";

export const BACKGROUND_DEFAULT_ID = "-1";

export default class BackgroundFactory {
  constructor(private assetFactory: AssetFactory) {}

  async createBackground(ps: ProjectScene): Promise<DomainModel.Background> {
    const { backgroundZymbol: zymbol } = ps;

    if (!zymbol) {
      return new DomainModel.Background({ id: BACKGROUND_DEFAULT_ID, position: getDefaultRect() });
    }

    const asset = this.assetFactory.createAssetFromZymbol(zymbol);
    const position = Rect.fromRect(zymbol);

    return new DomainModel.Background({
      id: zymbol.id,
      position,
      customTimingOffset: zymbol.customTimingOffset,
      customTimingDuration: zymbol.customTimingDuration,
      layerOrder: zymbol.layerOrder,
      asset,
      assetOffset: zymbol.assetOffset,
      mediaReplaceable: zymbol.mediaReplaceable
    });
  }
}
