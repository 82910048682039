import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ThumbnailOptions } from "client/authenticated/publish/controller";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ExportService from "client/services/export";
import type NotificationsService from "client/services/notifications";
import type VideoPlayerService from "client/services/video-player";

interface VideoOverlayChooseThumbnailArgs {
  renderProject: (ctaContext: string, thumbnailOptions?: ThumbnailOptions) => Promise<void>;
}

export default class VideoOverlayChooseThumbnailComponent extends Component<VideoOverlayChooseThumbnailArgs> {
  @service
  declare export: ExportService;

  @service
  declare notifications: NotificationsService;

  @service
  declare videoPlayer: VideoPlayerService;

  @tracked
  isFrameSelected = false;

  styleNamespace = getStyleNamespace("export/video-overlay/choose-thumbnail");

  @action
  close(): void {
    this.export.hideChooseThumbnail();
    this.videoPlayer.reset();
  }

  @action
  selectFrame(): void {
    this.isFrameSelected = true;
    this.videoPlayer.pause();
  }

  @action
  async saveFrame(): Promise<void> {
    this.isFrameSelected = false;
    this.export.hideChooseThumbnail();
    await this.args.renderProject(CTAContext.CTA_CONTEXT_AFTER_THUMBNAIL_SELECT, {
      frame: this.videoPlayer.currentFrame
    });
    this.videoPlayer.reset();
    this.notifications.success("Thumbnail successfully updated");
  }

  @action
  togglePlayback(): void {
    void this.videoPlayer.togglePlayback();
    this.isFrameSelected = true;
  }
}
