import { guidFor } from "@ember/object/internals";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

const degreesToRadians = (degrees: number): number => (degrees * Math.PI) / 180;

interface ColorSwatchArgs {
  colors?: string[];
  mergeColors?: boolean;
}

export default class ColorSwatchComponent extends Component<ColorSwatchArgs> {
  uid: string = guidFor(this);

  styleNamespace = getStyleNamespace("color-swatch");

  get colors(): string[] {
    if (!this.args.colors) {
      return [];
    }

    const colors = this.args.colors.map((c) => c.toUpperCase());
    return this.args.mergeColors ? Array.from(new Set(colors)) : colors;
  }

  get segmentAngle(): number {
    const segments = this.colors.length;

    if (!segments) {
      return 360;
    }

    return 360 / segments;
  }

  get segmentEndCoordinate(): { x: number; y: number } {
    const radians = degreesToRadians(this.segmentAngle);

    return {
      x: Number(Math.sin(-radians).toFixed(3)),
      y: Number(Math.cos(-radians).toFixed(3))
    };
  }
}
