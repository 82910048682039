import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { SafeString } from "handlebars";
import type { AudioClip, EventRegister, Timeline, TimelineMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

interface AudioTrackArgs {
  audioClips: AudioClip[];
  category?: string;
  projectDuration: number;
  pixelsPerSecond: number;
  timeline: Timeline;
  eventRegister: EventRegister;
  project: Project;
  sceneId?: string;
  isSelected?: boolean;
  mutate: (mutation: TimelineMutation) => void;
  save: (audioClip: AudioClip) => void;
  enableDragging: boolean;
  enableResizing: boolean;
}

export default class AudioTrackComponent extends Component<AudioTrackArgs> {
  @service
  declare router: RouterService;

  @tracked
  audioClipsSelected: boolean[] = [];

  @tracked
  audioClipsMasked: boolean[] = [];

  @tracked
  private _isResizing = false;

  private _element?: HTMLElement;

  private _countOfClips = this.args.audioClips.length;

  styleNamespace = getStyleNamespace("app/audio-track");

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  didUpdateClips(): void {
    // `didUpdate` is fired up somehow even there is no change of the array
    // What we want is it's fired only on # of clips change, which is either add, remove or replace (add then remove)
    if (this.args.audioClips.length !== this._countOfClips) {
      this._countOfClips = this.args.audioClips.length;
      this.audioClipsSelected = Array(this._countOfClips).fill(false);
      this.audioClipsMasked = [];
      if (this._countOfClips > 0) {
        // This is the best guess: the latest added clip is always the last
        this.audioClipsSelected[this._countOfClips - 1] = true;
      }
    }
  }

  @action
  async onMouseUp(ev: any): Promise<void> {
    const { category, sceneId } = this.args;
    const addedAt = ev.offsetX / this.pixelsPerSecond;

    if (ev.target === this._element && !this.atAnyClip(addedAt) && !this.resizing && sceneId) {
      this.onSelected(undefined);

      await this.router.transitionTo(`authenticated.project.scene.${category}.select`, sceneId, {
        queryParams: { addedAt: addedAt.toFixed(2), replacedClipId: undefined, audioClipId: undefined }
      });
    }
  }

  @action
  onSelected(index: number | undefined): void {
    this.audioClipsSelected = this.audioClips.map((_clip, idx) => {
      return index === idx;
    });
  }

  @action
  onHover(index: number | undefined): void {
    if (index !== undefined && this.audioClips[index]) {
      const hovered = this.audioClips[index];
      this.audioClipsMasked = this.audioClips.map((clip) => {
        return this.fullyMasked(hovered!, clip);
      });
    }
  }

  @action
  onResize(resizing: boolean): void {
    this._isResizing = resizing;
  }

  private get resizing(): boolean {
    return this._isResizing;
  }

  private fullyMasked(hovered: AudioClip, clip: AudioClip): boolean {
    if (hovered.id === clip.id) {
      return false;
    }
    if (hovered.offset > clip.offset) {
      return false;
    }

    const hoveredDuration = hovered.duration > 0 ? hovered.duration : this.projectDuration;
    const clipDuration = clip.duration > 0 ? clip.duration : this.projectDuration;
    return hovered.offset + hoveredDuration >= clip.offset + clipDuration;
  }

  get audioClips(): AudioClip[] {
    return this.args.audioClips;
  }

  get pixelsPerSecond(): number {
    return this.args.pixelsPerSecond;
  }

  get projectDuration(): number {
    return this.args.projectDuration;
  }

  get duration(): number {
    const finishAt = this.audioClips.map((clip) => {
      return clip.duration < 0 ? this.projectDuration : (clip.offset ?? 0) + clip.duration;
    });

    return Math.max(...finishAt, this.projectDuration);
  }

  get inlineStyle(): SafeString {
    return htmlSafe(`width:${(this.duration * this.pixelsPerSecond).toFixed(2)}px;`);
  }

  private atAnyClip(timeSecondes: number): boolean {
    return this.audioClips.some((clip) => {
      return timeSecondes >= clip.offset && timeSecondes <= clip.offset + clip.duration;
    });
  }
}
