import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type DragAndDropService from "client/services/drag-and-drop";
import type GalleryService from "client/services/gallery";

interface DraggableObjectAndTargetArgs {
  content: object;

  onDragStart(event: DragEvent): unknown;
  onDragEnd(event: DragEvent): unknown;

  draggedOnto(): unknown;
  draggedOver(): unknown;
  draggedOut(): unknown;
}

/**
 * This component combines DraggableObject and DraggableObjectTarget
 */
export default class DraggableObjectAndTarget extends Component<DraggableObjectAndTargetArgs> {
  @service
  declare dragAndDrop: DragAndDropService;

  @service
  declare gallery: GalleryService;

  @tracked
  dragging = false;

  get isDragTarget(): boolean {
    // Prevent an element from dragging onto itself
    return !this.dragging && this.gallery.dragging;
  }

  @action
  onDrag(event: DragEvent): void {
    this.dragAndDrop.onDragScroll(event, "[class$=_Content]");
  }

  @action
  onDragStart(_: object, event: DragEvent): void {
    this.dragging = true;
    this.args.onDragStart(event);
  }

  @action
  onDragEnd(_: object, event: DragEvent): void {
    this.dragging = false;
    this.args.onDragEnd(event);
  }
}
