import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { StoredFootage } from "client/database/footage";
import BiteableSocialFootageDatabase from "client/database/footage";
import type Storyblock from "client/models/storyblock";
import type ZymbolFootage from "client/models/zymbol-footage";
import type PendingService from "client/services/pending";
import type PlaybackEventsService from "client/services/playback-events";

const db = new BiteableSocialFootageDatabase();

export default class FootageService extends Service {
  @service
  declare store: Store;

  @service
  declare playbackEvents: PlaybackEventsService;

  @service
  declare pending: PendingService;

  async storeFootage(footage: ZymbolFootage, attrs: Partial<StoredFootage> = {}): Promise<void> {
    const { id } = footage;

    const existingRecord = await this.findFootageById(id);
    const data = {
      ...(footage.toJSON({}) as ZymbolFootage),
      ...attrs,
      id
    };

    if (existingRecord) {
      await db.footage.update(existingRecord.uid!, data);
    } else {
      await db.footage.add(data);
    }
  }

  storeSelectedFootage(footage: ZymbolFootage): Promise<void> {
    return this.storeFootage(footage, { selectedAt: new Date() });
  }

  async findFootageById(id: string): Promise<StoredFootage | undefined> {
    return db.footage.where("id").equals(id).limit(1).first();
  }

  async getSelectedFootage(count: number): Promise<ZymbolFootage[]> {
    const footage = new Array<ZymbolFootage>();

    await db.footage
      .orderBy("selectedAt")
      .reverse()
      .limit(count)
      .each((record) => {
        const data = { ...record };
        const existingRecord = this.store.peekRecord("zymbolFootage", record.id!);

        footage.push(existingRecord || this.store.createRecord("zymbolFootage", data));
      });

    return footage;
  }

  async getStoryBlocksRecord({ id }: ZymbolFootage, projectId: string): Promise<Storyblock> {
    let storyblock = this.store.peekRecord("storyblock", id);

    if (!storyblock) {
      storyblock = this.store.createRecord("storyblock", { id, projectId });
      await storyblock.save();
    }

    return storyblock;
  }
}
