import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  onClick?: () => void;
  disableHover?: boolean;
}

export default class ToolbarCorePopoverPanelComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/core/popover/panel");

  get disableHover(): boolean {
    return this.args.disableHover || false;
  }

  @action
  onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.args.onClick?.();
  }
}
