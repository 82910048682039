import { service } from "@ember/service";
import Component from "@glimmer/component";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

interface DeleteAccountArgs {
  user: User;
}

export default class DeleteAccountComponent extends Component<DeleteAccountArgs> {
  @service
  declare auth: AuthService;

  get hasTeamMembers(): boolean {
    return this.auth.teamPreventsDeletion;
  }

  get hasActiveSubscription(): boolean {
    return this.auth.subscriptionPreventsDeletion;
  }

  get deleteAccountDisabled(): boolean {
    return !this.auth.canDestroyCurrentUser;
  }
}
