import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";

export interface Prompt {
  title: string;
  required?: boolean;
  inputLabel?: string;
  inputPlaceholder?: string;
  submitLabel?: string;
}

export default class PromptsService extends Service {
  @tracked
  prompt?: Prompt;

  @tracked
  private promise?: DeferredPromise<string | undefined>;

  @action
  submit(value: string): void {
    this.promise?.resolve(value);
    this.hide();
  }

  show(prompt: Prompt): Promise<string | undefined> {
    if (this.prompt) {
      return Promise.reject("A prompt is already displayed");
    }

    this.prompt = prompt;
    this.promise = defer();

    return this.promise;
  }

  hide(): void {
    this.prompt = undefined;
    this.promise = undefined;
  }
}
