import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";
import type { UserResponsesGroupedByRespondent } from "client/services/record-projects";
import type RecordProjectsService from "client/services/record-projects";

interface Args {
  recordProject: RecordProject;
  exitRequestResponses(): void;
  selectAsset(asset: SelectableAsset): void;
}

export default class RecordingRequestPanel extends Component<Args & ProjectContentBarPanelArgs> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/recordings/request");

  @service
  declare recordProjects: RecordProjectsService;

  @tracked
  requestsGroupedByRespondent?: UserResponsesGroupedByRespondent;

  @tracked
  loading = false;

  @tracked
  error = false;

  @action
  async didInsert(): Promise<void> {
    this.loading = true;
    try {
      this.requestsGroupedByRespondent = await this.recordProjects.loadResponsesGroupedByEmail(this.args.recordProject);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  get emptyResults(): boolean {
    return !this.requestsGroupedByRespondent || Object.keys(this.requestsGroupedByRespondent).length === 0;
  }
}
