export interface ColorPreset {
  primary: string;
  secondary: string;
  background: string;
  text: string;
}

const colorBrandKeys = ["none", "background", "primary", "secondary", "text"] as const;
export type ColorBrandKey = (typeof colorBrandKeys)[number];

const textStyleBrandKeys = ["none", "body", "header", "subheader", "title"] as const;
export type TextStyleBrandKey = (typeof textStyleBrandKeys)[number];

const isColorBrandKey = (string: string): string is ColorBrandKey => colorBrandKeys.includes(string as ColorBrandKey);
const isTextStyleBrandKey = (string: string): string is TextStyleBrandKey =>
  textStyleBrandKeys.includes(string as TextStyleBrandKey);

export const toColorBrandKey = (string: string | undefined): ColorBrandKey | undefined => {
  if (string == undefined /* eslint-disable-line eqeqeq */ || isColorBrandKey(string)) {
    return string;
  }

  throw new Error(`Unsupported color brand key string: ${string}`);
};

export const toTextStyleBrandKey = (string: string | undefined): TextStyleBrandKey | undefined => {
  if (string == undefined /* eslint-disable-line eqeqeq */ || isTextStyleBrandKey(string)) {
    return string;
  }

  throw new Error(`Unsupported text style brand key string: ${string}`);
};
