import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type AccountSubscriptionRoute from "../route";
import type SubscriptionReactivateController from "client/authenticated/account/subscription/reactivate/controller";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";

export interface SubscriptionReactivateModel {
  plan: Plan;
  fullSubscription?: Subscription;
}

export interface SubscriptionReactivateParams {
  planId: string;
}

export default class SubscriptionReactivateRoute extends Route<SubscriptionReactivateModel> {
  @service
  declare store: Store;

  async model({ planId }: SubscriptionReactivateParams): Promise<SubscriptionReactivateModel> {
    const plan = await this.store.findRecord("plan", planId);
    const { fullSubscription } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    return { plan, fullSubscription };
  }

  resetController(controller: SubscriptionReactivateController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.error = undefined;
    }
  }
}
