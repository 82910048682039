import Model, { attr, belongsTo } from "@ember-data/model";
import type ProjectRender from "./project-render";
import type CaptionBurnRequest from "client/models/caption-burn-request";
import { CaptionBurnStatus } from "client/models/caption-burn-request";

export enum ProjectTranscriptionStatus {
  completed = "completed",
  failed = "failed"
}

export default class ProjectTranscription extends Model {
  @attr("string")
  declare vttUrl: string;

  @attr("string")
  declare srtUrl: string;

  @attr("string")
  declare status: ProjectTranscriptionStatus;

  @attr("string")
  declare detectedLanguage: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("caption-burn-request", { async: true, inverse: "projectTranscription" })
  declare captionBurnRequest: CaptionBurnRequest;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-render", { async: true, inverse: null })
  declare projectRender: ProjectRender;

  get isCompleted(): boolean {
    return this.status === ProjectTranscriptionStatus.completed;
  }

  get isFailed(): boolean {
    return this.status === ProjectTranscriptionStatus.failed;
  }

  get isBurning(): boolean {
    // @ts-expect-error
    const captionBurnRequest = this.belongsTo("captionBurnRequest").value() as CaptionBurnRequest;
    return captionBurnRequest?.status === CaptionBurnStatus.requested;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectTranscription: ProjectTranscription;
  }
}
