import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { LoginRouteModel } from "./route";
import type FailedLoginAttemptsService from "client/services/failed-login-attempts";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type SessionService from "client/services/session";
import { CONTACT_SUPPORT_MESSAGE } from "client/support/messages";

export default class LoginController extends Controller<LoginRouteModel> {
  @service
  private declare store: Store;

  @service
  private declare session: SessionService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare failedLoginAttempts: FailedLoginAttemptsService;

  @tracked
  errorMessage?: string;

  @tracked
  private authenticating = false;

  @tracked
  private error: any;

  get disabled(): boolean {
    return this.failedLoginAttempts.loggingBlocked;
  }

  get loading(): boolean {
    return this.authenticating || this.handlingAuthentication;
  }

  get showWarning(): boolean {
    return this.failedLoginAttempts.remainingAttempts <= 3;
  }

  get warning(): string {
    return this.failedLoginAttempts.warning;
  }

  @action
  async handleSubmit(ev: Event): Promise<void> {
    this.error = undefined;
    ev.preventDefault();
    await this.login();
  }

  private async login(): Promise<void> {
    if (this.authenticating) {
      return;
    }

    this.authenticating = true;

    try {
      const { email, password } = this.model;
      await this.session.authenticate("authenticator:biteable", email, password);
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err, {
        name: "Login error",
        context: { email: this.model.email, online: navigator.onLine }
      });
      this.handleError(err);
    } finally {
      this.authenticating = false;
    }
  }

  private get handlingAuthentication(): boolean {
    return this.session.handlingAuthentication;
  }

  private handleError(err: any): void {
    this.error = err;
    this.failedLoginAttempts.add(Date.now());

    if (this.tooManyRequests) {
      this.notifications.error("Oops! Too many failed login attempts. Try again in a few minutes");
    } else if (this.unauthorized) {
      this.notifications.error("Either your username or password is incorrect");
    } else {
      this.notifications.error(CONTACT_SUPPORT_MESSAGE, {
        button: {
          label: "Contact us",
          onClick: this.redirectToHelpPage
        }
      });
    }
  }

  private redirectToHelpPage(): void {
    window.open("https://help.biteable.com/", "_blank", "noopener");
  }

  private get tooManyRequests(): boolean {
    return this.status === 429;
  }

  private get unauthorized(): boolean {
    return this.status === 401;
  }

  private get status(): number | undefined {
    return this.error.errors[0].status ?? undefined;
  }
}
