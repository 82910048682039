import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import type ProjectTemplate from "client/models/project-template";
import type ProjectTemplateSet from "client/models/project-template-set";
import type TrackingService from "client/services/tracking";

interface TemplatePreviewModalArgs {
  projectTemplate: ProjectTemplate;
  onClose?(): unknown;
  edit(): unknown;
}

export default class TemplatePreviewModalComponent extends Component<TemplatePreviewModalArgs> {
  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("discovery/template-preview/modal");

  private previewed = false;
  private currentTime = 0;
  private duration = 0;

  @tracked
  loading = false;

  @tracked
  error = false;

  @tracked
  templateSet?: ProjectTemplateSet;

  @tracked
  projectTemplate = this.args.projectTemplate;

  get showAspectRatios(): boolean {
    if (this.templateSet) {
      // @ts-expect-error
      const projectTemplates = (this.templateSet.hasMany("projectTemplates").value() as ProjectTemplate[]) || [];
      return projectTemplates.length > 1;
    }
    return false;
  }

  get loaded(): boolean {
    return !!this.projectTemplate;
  }

  get description(): string {
    return this.projectTemplate.projectTemplateSetDescription ?? "";
  }

  get videoUrl(): string {
    return this.projectTemplate.lowResUrl;
  }

  get posterUrl(): string {
    return this.projectTemplate.lowResThumbUrl;
  }

  get aspectRatio(): AspectRatio {
    return this.projectTemplate.aspectRatio;
  }

  get aspectRatioName(): string {
    return this.projectTemplate.aspectRatio.get("name");
  }

  get videoWidth(): number {
    return this.projectTemplate.aspectRatio.get("dimensions").width;
  }

  get videoHeight(): number {
    return this.projectTemplate.aspectRatio.get("dimensions").height;
  }

  get platforms(): string[] {
    switch (this.aspectRatioName) {
      case "1:1": {
        return ["Facebook", "Instagram", "Twitter"];
      }
      case "4:3": {
        return ["Instagram stories", "Facebook stories", "Snapchat stories"];
      }
      case "16:9": {
        return ["YouTube", "Your website"];
      }
      case "9:16": {
        return ["Facebook mobile ads", "Instagram mobile ads"];
      }
      default: {
        return [];
      }
    }
  }

  get showClose(): boolean {
    return !!this.args.onClose;
  }

  @action
  switchTemplate(projectTemplate: ProjectTemplate): void {
    this.projectTemplate = projectTemplate;
  }

  @action
  didInsert(): void {
    void this.loadTemplateSet();
    document.addEventListener("keydown", this.onKeyup);
  }

  @action
  onKeyup({ code }: KeyboardEvent): void {
    if (code === EventKeys.ESC) {
      this.close();
    }
  }

  @action
  close(): void {
    this.args.onClose?.();
  }

  @action
  edit(): void {
    this.args.edit();
  }

  @action
  onTimeupdate({ target }: Event): void {
    this.previewed = true;

    if (target instanceof HTMLVideoElement) {
      const { currentTime, duration } = target;

      this.currentTime = currentTime;
      this.duration = duration;
    }
  }

  @action
  onPause(): void {
    this.trackPreview();
  }

  private trackPreview(): void {
    const { previewed, duration, currentTime } = this;

    if (previewed && duration > 0 && currentTime > 0) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEMPLATE_PREVIEW, {
        currentTime,
        duration,
        ...this.projectTemplate.eventProperties
      });
    }
  }

  willDestroy(): void {
    super.willDestroy();

    this.trackPreview();
    document.removeEventListener("keydown", this.onKeyup);
  }

  private async loadTemplateSet(): Promise<void> {
    this.loading = true;
    try {
      this.templateSet ??= await this.projectTemplate.projectTemplateSet;
    } catch (err) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
