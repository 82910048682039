import { service } from "@ember/service";
import Component from "@glimmer/component";
import formatMoney from "client/lib/format-money";
import type Favorable from "client/models/favorable";
import { FAVORABLE_TYPES } from "client/models/favorable";
import type Favorite from "client/models/favorite";
import type AuthService from "client/services/auth";

interface Args {
  item: Favorite;
  unfavorite?: (favorable: Favorable) => Promise<void>;
}

export default class ProjectContentBarPanelsFavoritesContentItemComponent extends Component<Args> {
  @service
  declare auth: AuthService;

  get assetPrice(): string | undefined {
    if (this.args.item.favorableType === FAVORABLE_TYPES.SHUTTERSTOCK) {
      const user = this.auth.currentUser!;
      return formatMoney(" ", user.shutterstockPrice, user.shutterstockCurrency);
    }
    return undefined;
  }
}
