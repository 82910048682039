import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type VideoPage from "client/models/video-page";
import type TrackingService from "client/services/tracking";

interface ShareEmbedArgs {
  videoPage: VideoPage;
  disabled?: boolean;
}

export default class ShareEmbedComponent extends Component<ShareEmbedArgs> {
  @service
  declare tracking: TrackingService;

  @tracked
  isPopoverOpen = false;

  styleNamespace = getStyleNamespace("export-actions/share/embed");

  @action
  togglePopover(): void {
    this.isPopoverOpen ? this.closePopover() : this.openPopover();
  }

  @action
  openPopover(): void {
    this.isPopoverOpen = true;

    this.trackClickEmbedVideoEvent();
  }

  @action
  closePopover(): void {
    this.isPopoverOpen = false;
  }

  private trackClickEmbedVideoEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_EMBED_VIDEO, {
      project_id: this.args.videoPage.projectId // eslint-disable-line camelcase
    });
  }
}
