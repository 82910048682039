import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type StorageService from "./storage";

export default class VisibilityChangeService extends Service {
  @service
  private declare storage: StorageService;

  @tracked
  lastHiddenAt?: number;

  @tracked
  lastActiveAt?: number;

  initialize(): void {
    this.addVisibilityChangeListeners();
    this.lastActiveAt = Number(this.storage.getItem("visibility::last_hidden_at")) || undefined;
    this.lastHiddenAt = Number(this.storage.getItem("visibility::last_active_at")) || undefined;
  }

  get hiddenPeriod(): number | undefined {
    return this.lastActiveAt && this.lastHiddenAt ? this.lastActiveAt - this.lastHiddenAt : undefined;
  }

  private addVisibilityChangeListeners(): void {
    document.addEventListener(
      "visibilitychange",
      () => {
        setTimeout(() => {
          const now = Date.now();

          if (document.hidden) {
            this.lastHiddenAt = now;
            this.storage.setItem("visibility::last_hidden_at", now.toString());
          } else {
            this.lastActiveAt = now;
            this.storage.setItem("visibility::last_active_at", now.toString());
          }
        }, 500);
      },
      false
    );
  }
}
