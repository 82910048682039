import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import type AuthData from "client/models/auth-data";
import type AjaxService from "client/services/ajax";
import Base from "ember-simple-auth/authenticators/base";

export default class BiteableAuthenticator extends Base {
  @service
  declare ajax: AjaxService;

  async authenticate(email: string, password: string, firstSession = false): Promise<void> {
    const response = await this.ajax.api("/login", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({ username: email, password })
    });

    return {
      provider: ["biteable"],
      ...response,
      first_session: firstSession // eslint-disable-line camelcase
    };
  }

  restore(data: AuthData): Promise<AuthData> {
    return this.validate(data) ? Promise.resolve(data) : Promise.reject();
  }

  private validate(data: AuthData): boolean {
    return !isEmpty(data["access_token"]);
  }
}
