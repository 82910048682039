import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import type { RouteQueryParam } from "@ember/routing/types";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type PlanController from "client/authenticated/plans/plan/controller";
import ucfirst from "client/helpers/ucfirst";
import type Plan from "client/models/plan";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";
import type UpgradeService from "client/services/upgrade";
import { PlanSelectContext } from "client/services/upgrade";

export interface PlansPlanRouteParams {
  planId: string;
}

export interface PlansPlanRouteModel {
  plan: Plan;
  currentPlan?: Plan;
  coupon?: string;
}

export default class PlansPlanRoute extends Route {
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare upgrade: UpgradeService;

  async model({ planId }: PlansPlanRouteParams): Promise<PlansPlanRouteModel> {
    const plan = await this.store.findRecord("plan", planId);
    const currentPlan = this.auth.currentFullSubscription?.plan;

    return {
      plan,
      currentPlan
    };
  }

  afterModel({ plan, currentPlan }: PlansPlanRouteModel, transition: Transition): void {
    if (plan) {
      const { discontinued, skuCode } = plan;
      const currentlySubscribed = currentPlan && plan.id === currentPlan.id;

      if (discontinued) {
        this.redirectToPlans(`That ${ucfirst(skuCode)} plan is no longer available.`);
      } else if (currentlySubscribed) {
        this.redirectToPlans(`You're already on this plan.`);
      } else {
        void this.upgrade.selectPlan({
          context: PlanSelectContext.APP,
          selectedPlan: plan,
          couponCode: this.couponCode(transition),
          showCouponField: this.showCouponField(transition)
        });
      }
    }
  }

  resetController(controller: PlanController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    controller.resetTransientState();
  }

  private redirectToPlans(problem: String): void {
    this.notifications.warning(`${problem} See our current offerings below.`);

    void this.router.transitionTo("authenticated.plans.index");
  }

  private couponCode(transition: Transition): string | undefined {
    return transition.to.queryParams["couponCode"];
  }

  private showCouponField(transition: Transition): boolean | undefined {
    const showCouponField = transition.to.queryParams["showCouponField"];

    return showCouponField === "true";
  }

  queryParams: { [key: string]: RouteQueryParam } = {
    couponCode: { refreshModel: false },
    showCouponField: { refreshModel: false }
  };
}
