import Component from "@glimmer/component";
import type Subscription from "client/models/subscription";

interface AccountSubscriptionPastDueArgs {
  subscription?: Subscription;
}

export default class AccountSubscriptionPastDueComponent extends Component<AccountSubscriptionPastDueArgs> {
  get show(): boolean {
    return !!this.args.subscription && this.args.subscription.pastDue;
  }

  get failedPaymentDate(): Date | undefined {
    return this.args.subscription?.currentPeriodStart;
  }
}
