import Service from "@ember/service";
import Honeybadger from "@honeybadger-io/js";

export type ErrorContext = Record<string, unknown>;

export default class HoneybadgerService extends Service {
  notify(...args: Parameters<typeof Honeybadger.notify>): void {
    notify(...args);
  }

  setContext(context: ErrorContext): void {
    setContext(context);
  }
}

export const notify = (...args: Parameters<typeof Honeybadger.notify>): void => {
  try {
    Honeybadger.notify(...args);
  } catch {
    // eslint-disable-next-line no-empty
  }
};

export const setContext = (context: ErrorContext): void => {
  try {
    Honeybadger.setContext(context);
  } catch {
    // eslint-disable-next-line no-empty
  }
};
