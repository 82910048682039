import type { RecordProject } from "@biteable/network-model";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type AssetLibraryRecordRequestsRoute from "./route";
import InfiniteController from "client/controllers/infinite";
import type HoneybadgerService from "client/services/honeybadger";
import type NewVideoActionsService from "client/services/new-video-actions";
import type NotificationsService from "client/services/notifications";

export default class AssetLibraryRecordRequestsController extends InfiniteController {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare newVideoActions: NewVideoActionsService;

  // eslint-disable-next-line camelcase
  per_page = 20;

  @action
  async onRename(recordProject: RecordProject, name: string): Promise<void> {
    try {
      recordProject.title = name;
      await recordProject.save();
    } catch (error) {
      this.notifications.error("Uh oh! There was a problem renaming the record project");
      // @ts-expect-error
      this.honeybadger.notify(error);
    }
  }
  @action
  async onDestroy(recordProject: RecordProject): Promise<void> {
    try {
      await recordProject.destroy();
      await this.refresh();
    } catch (error) {
      this.notifications.error("Shucks! There was a problem deleting the record project");
      // @ts-expect-error
      this.honeybadger.notify(error);
    }
  }

  async refresh(): Promise<void> {
    this.page = 1;
    await (
      getOwner(this).lookup(
        "route:authenticated.asset-library.record-requests.index"
      ) as AssetLibraryRecordRequestsRoute
    )?.refresh();
  }
}
