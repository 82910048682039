import { action } from "@ember/object";
import Component from "@glimmer/component";

interface StarsIconBaseComponentArgs {
  hasGradient?: boolean;
}

let counter = 0;

export default class StarsIconBaseComponent extends Component<StarsIconBaseComponentArgs> {
  get gradientId(): string {
    return `gradient${++counter}`;
  }

  @action
  didInsert(element: SVGElement): void {
    if (this.args.hasGradient) {
      const linearGradient = element.getElementsByTagName("linearGradient")[0];
      if (linearGradient) {
        element.setAttribute("fill", `url(#${linearGradient.id})`);
      }
    }
  }
}
