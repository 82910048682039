import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface VideoOverlayContainerComponentArgs {
  close?: () => void;
}

export default class VideoOverlayContainerComponent extends Component<VideoOverlayContainerComponentArgs> {
  styleNamespace = getStyleNamespace("export/video-overlay/container");
}
