import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import BaseParentPanel from "../base-parent/component";
import type ContentStack from "client/models/content-stack";
import type SelectableAsset from "client/models/selectable-asset";

export default class GraphicsPanel extends BaseParentPanel {
  @tracked
  private currentCollection?: string;

  @tracked
  private currentStack?: ContentStack;

  @tracked
  private searchKey?: string;

  @action
  async selectAsset(asset: SelectableAsset): Promise<void> {
    this.args.selectAsset(asset, {
      collection: this.currentCollection,
      stack: this.currentStack?.name,
      search: this.searchKey
    });
  }

  @action
  selectStack(collection: string, stack: ContentStack): void {
    this.currentCollection = collection;
    this.currentStack = stack;
  }

  @action
  search(key: string): void {
    this.searchKey = key;
  }

  @action
  selectCollection(): void {
    this.currentCollection = undefined;
    this.currentStack = undefined;
  }

  get stackView(): boolean {
    return !!this.currentStack;
  }

  get searchView(): boolean {
    return !!this.searchKey;
  }

  get currentCollectionName(): string | undefined {
    return this.currentCollection;
  }
}
