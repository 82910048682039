import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectOwner from "client/models/project-owner";

interface ProjectPreviewOwnerArgs {
  owner: ProjectOwner;
}

export default class ProjectPreviewOwnerComponent extends Component<ProjectPreviewOwnerArgs> {
  styleNamespace = getStyleNamespace("discovery/project-preview/owner");

  get userName(): string {
    return this.args.owner.fullName;
  }

  get userId(): string {
    return this.args.owner.id;
  }
}
