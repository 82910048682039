import Component from "@glimmer/component";

interface TeamOutlineArgs {
  fillColor?: string;
}

export default class TeamOutline extends Component<TeamOutlineArgs> {
  get fillColor(): string {
    return this.args.fillColor ?? "currentColor";
  }
}
