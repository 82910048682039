import Serializer from "@ember-data/serializer";
import type Store from "@ember-data/store";
import type { ModelSchema } from "ember-data"; // eslint-disable-line ember/use-ember-data-rfc-395-imports

export interface PexelsPhotoQueryPayload {
  next_page: string;
  page: number;
  per_page: number;
  photos: PexelsPhotoPayload[];
  total_results: number;
}

export interface PexelsPhotoPayload {
  id: number;
  alt: string;
  width: number;
  height: number;
  url: string;
  photographer: string;
  photographer_url: string;
  src: {
    original: string;
    large: string;
  };
}

enum RequestTypes {
  QUERY = "query",
  FIND_RECORD = "findRecord"
}

export default class PexelsPhotoSerializer extends Serializer {
  // eslint-disable-next-line camelcase
  normalize(_: ModelSchema, { id, alt, width, height, src, photographer, photographer_url }: PexelsPhotoPayload): any {
    return {
      id,
      type: "pexels-photo",
      attributes: {
        description: alt,
        downloadUrl: src.original,
        height,
        thumbImageUrl: src.large,
        width,
        attribution: {
          author: photographer,
          authorUrl: photographer_url, // eslint-disable-line camelcase
          provider: "Pexels"
        }
      }
    };
  }

  normalizeResponse(
    _: Store,
    model: ModelSchema,
    payload: PexelsPhotoQueryPayload | PexelsPhotoPayload | PexelsPhotoPayload[],
    __: string | number,
    requestType: string
  ): any {
    if (requestType === RequestTypes.QUERY) {
      if (payload instanceof Array) {
        const data = payload.map((item) => this.normalize(model, item));

        return { data, included: [] };
      }

      const meta = {
        "total-count": (payload as PexelsPhotoQueryPayload).total_results
      };

      const data = (payload as PexelsPhotoQueryPayload).photos.map((item) => {
        return this.normalize(model, item);
      });

      return { data, included: [], meta };
    } else if (requestType === RequestTypes.FIND_RECORD) {
      const serialized = this.normalize(model, payload as PexelsPhotoPayload);

      return { data: serialized };
    }
  }
}
