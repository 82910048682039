import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Media } from "client/lib/editor-domain-model";
import type ProjectContentBarService from "client/services/project-content-bar";

interface Args {
  media: Media;
  onClose: () => void;
}

export default class ToolbarBackgroundComponent extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @action
  async replace(event: Event): Promise<void> {
    event?.stopPropagation();
    this.args.onClose();
    await this.projectContentBar.startAddOrReplaceMedia(this.args.media);
  }
}
