import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import type TrackingService from "client/services/tracking";

interface ShareCopyLinkButtonComponentArgs {
  disabled: boolean;
  videoPageUrl: string;
  projectId: number;
}

const NOTIFICATION_DURATION = 3000;

export default class ShareCopyLinkButtonComponent extends Component<ShareCopyLinkButtonComponentArgs> {
  @service
  store!: Store;

  @service
  tracking!: TrackingService;

  @tracked
  showLinkCopiedNotification?: boolean = false;

  @action
  async onCopyToClipboard(): Promise<void> {
    await this.copyToClipboard();
    this.trackClickCopyLinkEvent();
  }

  async copyToClipboard(): Promise<void> {
    const { clipboard } = navigator;

    if (clipboard) {
      await clipboard.writeText(this.args.videoPageUrl);
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = this.args.videoPageUrl;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }

    this.toggleCopyButtonMessage();
  }

  copyButtonMessageTimeout?: NodeJS.Timeout;

  trackClickCopyLinkEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_COPY_LINK, {
      projectId: this.args.projectId
    });
  }

  toggleCopyButtonMessage(): void {
    this.showLinkCopiedNotification = true;

    if (this.copyButtonMessageTimeout) {
      clearTimeout(this.copyButtonMessageTimeout);
    }

    this.copyButtonMessageTimeout = setTimeout(() => {
      this.showLinkCopiedNotification = false;
    }, NOTIFICATION_DURATION);
  }
}
