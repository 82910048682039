import { StrictMutation } from "./mutation";

/**
 * Use this to ensure that a transaction does not get grouped to another.
 *
 * Longer explanation:
 *
 * ExplicitTransaction makes it so that grouped is no loger needed, however I
 * haven't tried to remove it altogether. SaveActions are grouped so you can
 * use a single mutation and a save without needing a transaction.
 *
 * Adding a transaction does not change the behaviour other than creating a
 * single mutation. For this reason, grouped needs to be propagated when all
 * mutations have the grouped flag. After some thought, UngroupedEmptyMutation
 * turned out to be the easiest way to guarantee that the transaction is not
 * grouped into previous mutations.
 */
export class UngroupedEmptyMutation extends StrictMutation<void> {
  grouped = false;
  run(): void {
    /* empty */
  }
  revert(): void {
    /* empty */
  }
}
