import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class ProjectScenesFavoritesIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    await this.router.replaceWith("authenticated.project.scenes.favorites.my.current");
  }
}
