import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import { TimeSlice } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type TrackingService from "client/services/tracking";

interface ProjectEditVideoTrimArgs {
  trim?: TimeSlice;
  videoDuration: number;
  videoPreviewUrl: string;
  posterPreviewUrl?: string;
  muted?: boolean;
  hasAudio?: boolean;
  onSave: (trim: TimeSlice) => void;
  onCancel: () => void;
}

export default class ProjectEditVideoTrimComponent extends Component<ProjectEditVideoTrimArgs> {
  @service
  declare tracking: TrackingService;

  @tracked
  saving = false;

  @tracked
  trim = this.args.trim ?? new TimeSlice(0, this.args.videoDuration);

  styleNamespace = getStyleNamespace("project-edit/video-trim");

  @action
  onChange(trim: TimeSlice): void {
    this.trim = trim;
  }

  @action
  async onSave(): Promise<void> {
    if (!this.trim) {
      return;
    }

    this.saving = true;

    try {
      await this.args.onSave(this.trim);
      this.args.onCancel();
    } finally {
      this.saving = false;
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_BACKGROUND_TRIM_VIDEO);
    }
  }
}
