import { tracked } from "@glimmer/tracking";
import type { ElementArgs } from "../../element";
import { Element } from "../../element";
import type { TextStyle } from "./text-style";

export interface TextArgs extends ElementArgs {
  content: string;
  styles?: TextStyle[];
  animation?: string;
}

export class Text extends Element {
  /** @internal */
  @tracked
  _content: string;

  /** @internal */
  @tracked
  _styles: TextStyle[] = [];

  /** @internal */
  @tracked
  _animation: string;

  constructor(args: TextArgs) {
    super(args);

    const { content, styles, animation } = args;
    this._content = content;
    if (styles) {
      this._styles = styles;
    }
    this._animation = animation ?? "NONE";
  }

  get styles(): TextStyle[] {
    return this._styles;
  }

  get content(): string {
    return this._content;
  }

  get animation(): string {
    return this._animation;
  }

  get bulletColor(): string | undefined {
    return this.blockStyle?._color;
  }

  private get blockStyle(): TextStyle | undefined {
    return this.styles.find((style) => !style.range);
  }

  get fontSize(): number | undefined {
    return this.blockStyle?._fontSize;
  }

  get ribbonColor(): string | undefined {
    return this.blockStyle?._ribbonColor;
  }

  get alignment(): string | undefined {
    return this.blockStyle?._alignment;
  }

  get yAlignment(): string | undefined {
    return this.blockStyle?._yAlignment;
  }

  get hasContent(): boolean {
    return this._content.length > 0;
  }

  get colors(): string[] {
    const colors = new Set<string>();
    for (const { color, ribbonColor } of this.styles) {
      if (color) {
        colors.add(color.substring(0, 7).toUpperCase());
      }

      if (ribbonColor) {
        colors.add(ribbonColor.substring(0, 7).toUpperCase());
      }
    }

    return [...colors];
  }

  get canResize(): boolean {
    return true;
  }

  get category(): string {
    return "text";
  }

  get formattedCategory(): string {
    return "text";
  }
}
