import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum EmptyFolderTypes {
  ASSETS = "assets",
  PROJECTS = "projects"
}
interface EmptyFolderComponentArgs {
  type: EmptyFolderTypes;
}

export default class EmptyFolderComponent extends Component<EmptyFolderComponentArgs> {
  styleNamespace = getStyleNamespace("folder-content/empty-folder");
}
