import { action } from "@ember/object";
import { later } from "@ember/runloop";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { formatHex } from "client/lib/rgb-hex";

interface ColorPickerArgs {
  color?: string;
  size?: string;
  onChange(color?: string): unknown;
  onRemove?(color?: string): unknown;
  advanced?: boolean;
  opacity?: boolean;
}

export default class ColorPickerComponent extends Component<ColorPickerArgs> {
  @tracked
  open = false;

  @tracked
  color = "#";

  styleNamespace = getStyleNamespace("north-star/color-picker");

  get colorDidChange(): boolean {
    return this.color !== this.args.color;
  }

  get isValidColor(): boolean {
    return /^#[0-9A-F]{6}$/i.test(this.color);
  }

  get colorHex(): string | undefined {
    return this.isValidColor ? this.color : undefined;
  }

  get canRemove(): boolean {
    return !!this.args.onRemove;
  }

  @action
  didInsert(): void {
    if (this.args.color === undefined) {
      this.open = true;
    }
  }

  @action
  didInsertInputField(element: HTMLInputElement): void {
    if (document.activeElement && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    // Delay focusing the element to ensure it has had enough time to animate in
    later(() => element.focus(), 35);
  }

  @action
  toggleOpen(ev: MouseEvent): void {
    ev.stopPropagation();
    this.open = !this.open;
  }

  @action
  removeColor(): void {
    this.args.onRemove?.(this.args.color);
  }

  @action
  updateColor(): void {
    this.color = this.args.color ?? "";
  }

  @action
  onClose(): void {
    if (!this.open) {
      return;
    }

    this.open = false;

    if (this.colorDidChange && this.isValidColor) {
      this.args.onChange(this.color);

      // Reset color value back to its original value to protect against the `onChange`
      // failing on the parent component. If it succeeds the updated value will be passed
      // back down when it's updated.
      this.updateColor();
    } else {
      // If no valid color is defined then reset the color
      this.color = this.args.color ?? "";
    }
  }

  @action
  onSubmit(ev: Event): void {
    ev.preventDefault();
    this.onClose();
  }

  @action
  onColorChange(color: string): void {
    this.color = color;
  }

  @action
  onInputChange({ target }: InputEvent): void {
    const color = (target as HTMLInputElement).value;

    this.color = formatHex(color);
  }
}
