import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectSceneRouteModel } from "client/authenticated/project/scene/route";
import type ProjectSceneRoute from "client/authenticated/project/scene/route";
import type { Background, Caption } from "client/lib/editor-domain-model";
import type Project from "client/models/project";
import type Zymbol from "client/models/zymbol";
import type AdvancedEditorService from "client/services/advanced-editor";

export interface ProjectSceneBackgroundRouteModel extends ProjectSceneRouteModel {
  backgroundZymbol: Zymbol;
  background: Background;
  project: Project;
  caption?: Caption;
}

export default class ProjectSceneBackgroundRoute extends Route<ProjectSceneBackgroundRouteModel> {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare store: Store;

  // This template name appears superfluous because it is the automatic name
  // for the template that this route would use but this is needed for routes
  // that inherit from this one that do not have a template specified like
  // authenticated.project.scene.caption.background found this out the hard
  // way
  templateName = "authenticated.project.scene.background";

  async model(): Promise<ProjectSceneBackgroundRouteModel> {
    const { projectScene, scene, ...model } = this.modelFor<ProjectSceneRoute>("authenticated.project.scene");
    const { backgroundZymbol } = projectScene;
    const project = await projectScene.project;
    const { background } = scene;

    return {
      ...model,
      scene,
      projectScene,
      backgroundZymbol,
      background,
      project
    };
  }

  afterModel({ background }: ProjectSceneBackgroundRouteModel): void {
    const query = this.paramsFor(this.routeName) as { showSelection: boolean };
    this.advancedEditor.setElement(background, !!query?.showSelection);
  }

  queryParams = { showSelection: { refreshModel: true } };
}
