import type ApplicationInstance from "@ember/application/instance";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type AccountSetupService from "./account-setup";
import type AuthService from "./auth";
import type BrandStyleService from "./brand-style";
import type PermissionsService from "./permissions";
import type ProjectsService from "./projects";
import type StorageService from "./storage";
import config from "client/config/environment";

const ONBOARDING_KEY = "onboarding";

export default class OnboardService extends Service {
  @service
  private declare accountSetup: AccountSetupService;

  @service
  private declare router: RouterService;

  @service
  private declare permissions: PermissionsService;

  @service
  private declare projects: ProjectsService;

  @service
  private declare store: Store;

  @service
  private declare brandStyle: BrandStyleService;

  @service
  private declare auth: AuthService;

  @service
  private declare storage: StorageService;

  async start(): Promise<void> {
    this.onboarding = true;
    this.accountSetup.setup();
    await this.next();
  }

  async next(): Promise<void> {
    await this.redirect();
  }

  end(): void {
    this.onboarding = false;
  }

  @tracked
  _onboarding = false;

  get onboarding(): boolean {
    return this._onboarding;
  }

  set onboarding(onboarding: boolean) {
    this._onboarding = onboarding;
    this.storage.setItem(ONBOARDING_KEY, String(onboarding));
  }

  // if another tab is doing onboarding
  get otherOnboarding(): boolean {
    return this.storage.getItem(ONBOARDING_KEY) === String(true);
  }

  constructor(owner: ApplicationInstance) {
    super(owner);
    this._onboarding = this.storage.getItem(ONBOARDING_KEY) === String(true);
  }

  private async redirect(): Promise<void> {
    // If a user isn't onboarding then skip the remaining steps
    if (!this.onboarding) {
      await this.transitionToDefaultRoute();
      return;
    }

    // Complete the onboarding survey
    if (!this.auth.userSurveyComplete) {
      void this.router.transitionTo("authenticated.survey.onboarding");
      return;
    }

    // Wait for account setup to resolve before determining next route
    await this.accountSetup.completed;

    if (await this.hasNoProjects()) {
      void this.router.transitionTo("authenticated.survey.decide");
    } else {
      await this.transitionToDefaultRoute();
    }
  }

  private transitionToDefaultRoute(): void {
    this.end();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    void this.router.transitionTo(config["ember-simple-auth"].routeAfterAuthentication);
  }

  private async hasNoProjects(): Promise<boolean> {
    const result = await this.store.query("project", {
      per_page: 1 // eslint-disable-line camelcase
    });

    // @ts-expect-error
    return result.meta["total-count"] === 0;
  }
}

declare module "@ember/service" {
  interface Registry {
    onboarding: OnboardService;
  }
}
