import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { AvaReferer } from "client/authenticated/survey/data";
import DynamicIslandActionComponent from "client/components/discovery/dashboard/dynamic-island/action/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class DashboardSecondaryActionsVideoAssistant extends DynamicIslandActionComponent {
  referer = AvaReferer.Manual;

  @service
  declare router: RouterService;

  actionName = "video assistant";

  styleNamespace = getStyleNamespace("discovery/dashboard/secondary-actions/video-assistant");

  @action
  createFromVideoAssistant(): void {
    this.onInteraction();
    void this.router.transitionTo("authenticated.survey.video-assistant.use-case", {
      queryParams: { referer: this.referer }
    });
  }
}
