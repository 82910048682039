import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { InfiniteParams } from "../infinite/component";
import TrackingEvents from "client/events";
import { CONTENT_TRACKING_TYPES, ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type { EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { rumAction } from "client/lib/rum-action";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type Project from "client/models/project";
import type ProjectScenePreview from "client/models/project-scene-preview";
import type PermissionsService from "client/services/permissions";
import type ProjectContentBarService from "client/services/project-content-bar";
import type TrackingService from "client/services/tracking";

interface SceneSearchParams extends InfiniteParams {
  query: string;
}

interface SceneSearchArgs {
  category: string;
  project: Project;
  scene: Scene;
  afterScene: Scene;
  timeline: Timeline;
  eventRegister: EventRegister;
}

export default class SceneSearch extends Component<SceneSearchArgs> {
  @tracked
  loading = false;

  @tracked
  searchQuery = "";

  @service
  declare tracking: TrackingService;

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  @service
  private declare projectContentBar: ProjectContentBarService;

  styleNamespace = getStyleNamespace("scene-search");

  @action
  async searchScene(query: string): Promise<void> {
    this.searchQuery = query;
  }

  @action
  @rumAction("scene-search.search")
  async getResults(params: SceneSearchParams): Promise<Array<ProjectScenePreview>> {
    const query: Record<string, string | number> = {
      /* eslint-disable camelcase */
      page: params.page,
      per_page: params.perPage,
      search_query: params.query,
      aspect_ratio: this.args.project.aspectRatio.id // Filter by the aspect ratio of the current project
      /* eslint-enable camelcase */
    };

    // @ts-expect-error
    const results = (await this.store.query("projectScenePreview", query)) as ProjectScenePreview[];

    if (params.page === 1) {
      // @ts-expect-error
      const total = (results.meta["total-count"] as number) ?? 0;
      this.trackSearch(total);
    }

    return results;
  }

  private trackSearch(total: number): void {
    const trackingHelper = new ContentTrackingHelper(
      {
        search: this.searchQuery,
        searchFrom: SEARCH_LOCATION.ADD_SCENE
      },
      CONTENT_TRACKING_TYPES.PROJECT_SCENES,
      total
    );

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_SEARCH_CONDUCTED, trackingHelper);
  }

  @action
  clearSearch(): void {
    this.searchQuery = "";
  }

  @action
  async selectScene(asset: ProjectScenePreview): Promise<void> {
    if (this.loading) {
      return;
    }

    const { scene, afterScene, timeline, eventRegister } = this.args;

    this.loading = true;
    try {
      const fullAsset = await asset.getFullAsset();
      fullAsset.trackedSearchQuery = this.searchQuery;

      const modifier = new SceneAssetModifier(getOwner(this)!, timeline, eventRegister, scene, afterScene);

      if (this.projectContentBar.isReplacing) {
        await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
      } else {
        await modifier.applyAsset(asset);
      }
    } finally {
      this.loading = false;
    }
  }

  get categoryPlaceholder(): string {
    return "Search scenes";
  }

  get showSearchbar(): boolean {
    const { category } = this.args;

    return category.includes("authenticated.project.scenes.categories");
  }
}
