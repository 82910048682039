import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { FolderMessages } from "client/lib/data/folder-messages";
import { isFolderInFolderTree } from "client/lib/folders";
import type Folder from "client/models/folder";
import { ContributorTypes, FolderCategoryTypes } from "client/models/folder";
import type FoldersService from "client/services/folders";
import { DEFAULT_FOLDER_NAME } from "client/services/folders";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export default class FoldersController extends Controller {
  @service
  declare router: RouterService;

  @service
  declare folders: FoldersService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  folderCategoryTypes = FolderCategoryTypes;

  @tracked
  personalFolders: Array<Folder> = [];

  @tracked
  teamFolders: Array<Folder> = [];

  @action
  async refresh(): Promise<void> {
    this.personalFolders = await this.getPersonalFolders();
    this.teamFolders = await this.getTeamFolders();
  }

  @action
  async updateFolders(type: ContributorTypes): Promise<void> {
    switch (type) {
      case ContributorTypes.USER:
        this.personalFolders = await this.getPersonalFolders();
        return;
      case ContributorTypes.TEAM:
        this.teamFolders = await this.getTeamFolders();
        return;
    }
  }

  @action
  async onAddFolder(
    type?: ContributorTypes,
    category: FolderCategoryTypes = FolderCategoryTypes.PROJECTS,
    parent?: Folder
  ): Promise<void> {
    try {
      const contributable = parent?.contributable ?? type;
      const unsavedFolder = this.store.createRecord("folder", {
        parent,
        name: DEFAULT_FOLDER_NAME,
        contributable,
        category
      });

      if (!parent) {
        if (type === ContributorTypes.USER) {
          this.personalFolders = [...this.personalFolders, unsavedFolder];
        } else if (type === ContributorTypes.TEAM) {
          this.teamFolders = [...this.teamFolders, unsavedFolder];
        }
      }
    } catch (err) {
      this.notifications.error(FolderMessages.FOLDER_CREATE_FAILURE);
      throw err;
    }
  }

  @action
  async onDeleteFolder(folder: Folder): Promise<void> {
    const contributable = folder.contributable as ContributorTypes;
    const { activeFolder } = this.folders;
    const isActive = activeFolder === folder || (activeFolder && isFolderInFolderTree(folder, activeFolder));

    try {
      await this.folders.delete(folder);
    } catch (err) {
      this.notifications.error(FolderMessages.FOLDER_DELETE_FAILURE);
      return;
    }

    /** This is temporary and might have side-effects if we ever move
     *  this to a Component, follow AssetLibrary Route / Component style
     */
    await this.updateFolders(contributable);

    if (isActive) {
      await this.router.transitionTo("authenticated.folders.library", contributable);
    }
  }

  get activeType(): string | undefined {
    const { params } = this.router.currentRoute;

    if (!params?.["type"]) {
      return;
    }

    const { type } = params;
    return type === "personal" ? "user" : type;
  }

  @action
  async getPersonalFolders(): Promise<Array<Folder>> {
    return await this.getFolders(ContributorTypes.USER, FolderCategoryTypes.PROJECTS);
  }

  @action
  async getTeamFolders(): Promise<Array<Folder>> {
    return await this.getFolders(ContributorTypes.TEAM, FolderCategoryTypes.PROJECTS);
  }

  private async getFolders(contributable: ContributorTypes, category: string): Promise<Array<Folder>> {
    try {
      // @ts-expect-error
      return (await this.store.query("folder", {
        page: 1,
        // eslint-disable-next-line camelcase
        per_page: 500,
        order: "name:asc",
        category,
        contributable
      })) as Folder[];
    } catch (error) {
      this.notifications.error(FolderMessages.FOLDER_RESULTS_FAILURE);

      if (error instanceof Error) {
        this.honeybadger.notify(error, "FoldersRoute");
      }

      return [];
    }
  }
}
