import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { Media, EventRegister } from "client/lib/editor-domain-model";
import { Animation } from "client/lib/editor-domain-model";
import { ChangePlaybackSpeedMutation } from "client/lib/editor-domain-model/events/mutations/change-playback-speed-mutation";

interface PlaybackSpeedArgs {
  media: Media;
  eventRegister: EventRegister;
  onSave(): void;
}

export default class PlaybackSpeedComponent extends Component<PlaybackSpeedArgs> {
  @action
  selectSpeed(value: number): void {
    const { asset } = this.args.media;

    if (asset instanceof Animation) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.args.eventRegister.fire(new ChangePlaybackSpeedMutation(asset, value));
      this.args.onSave();
    }
  }

  get playbackSpeed(): number {
    const { asset } = this.args.media;
    if (asset instanceof Animation) {
      return asset.playbackSpeed;
    } else {
      return 0;
    }
  }
}
