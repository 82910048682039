import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

interface AccountSubscriptionSwitchToYearlyArgs {
  fullSubscription?: Subscription;
  subscription?: Subscription;
}

export default class AccountSubscriptionSwitchToYearlyComponent extends Component<AccountSubscriptionSwitchToYearlyArgs> {
  @service
  declare plans: PlansService;

  @service
  declare upgrade: UpgradeService;

  get show(): boolean {
    return this.subscribed && !this.canceled && this.isMonthlyPlan && !!this.siblingPlan;
  }

  get showPlanName(): boolean {
    return !!this.args.subscription?.trialing;
  }

  get planName(): string | undefined {
    return this.args.fullSubscription?.planName;
  }

  get subscribed(): boolean {
    return !!this.args.fullSubscription;
  }

  get canceled(): boolean {
    return !!this.args.fullSubscription?.canceled;
  }

  get trialingSubscription(): boolean {
    return !!this.args.fullSubscription?.trialing;
  }

  private get siblingPlan(): Plan | undefined {
    if (this.args.fullSubscription) {
      return this.plans.getSiblingPlan(this.args.fullSubscription.plan);
    } else {
      return undefined;
    }
  }

  private get isMonthlyPlan(): boolean {
    return !!this.args.fullSubscription?.plan.isMonthly;
  }

  @action
  switchToYearlyInterval(): void {
    if (this.siblingPlan) {
      void this.upgrade.selectPlan({
        context: "button - account - switch to yearly",
        selectedPlan: this.siblingPlan
      });
    }
  }
}
