import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class ScenesIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    const { scene = undefined } = this.paramsFor("authenticated.project.scenes") as { [key: string]: string };

    if (scene) {
      void this.router.replaceWith("authenticated.project.scenes.library");
    } else {
      void this.router.replaceWith("authenticated.project.scenes.categories");
    }
  }
}
