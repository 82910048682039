import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import type { Timeline, EventRegister, AudioClip } from "client/lib/editor-domain-model";
import type { PaginationMeta } from "client/models/pagination";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type UserAsset from "client/models/user-asset";

export interface VoiceoverSelectPresenter {
  project: Project;
  timeline: Timeline;
  eventRegister: EventRegister;
  audioClip?: AudioClip;
  replacedClipId?: string;
  addedAt?: string;
  sceneId?: string;
}

export default class VoiceoverSelectModalService extends Service {
  @service
  declare store: Store;

  @tracked
  visible = false;

  @tracked
  loadingMore = false;

  @tracked
  currentlyLoading = false;

  @tracked
  audioUserAssets = new TrackedArray<UserAsset>([]);

  @tracked
  model?: VoiceoverSelectPresenter;

  page = 1;
  perPage = 16;
  meta?: PaginationMeta;

  get replacedClipId(): string | undefined {
    return this.model?.replacedClipId;
  }

  get addedAt(): string | undefined {
    return this.model?.addedAt;
  }

  get isLastPage(): boolean {
    if (!this.meta) {
      return true;
    }

    const totalCount = this.meta["totalCount"] ?? this.meta["total-count"];
    return this.page * this.perPage >= totalCount;
  }

  setModel(model: VoiceoverSelectPresenter): void {
    this.model = model;
  }

  get projectScene(): ProjectScene | undefined {
    // @ts-expect-error
    return (this.model?.project?.hasMany("projectScenes").value() as ProjectScene[]).find(
      (scene) => scene.id === this.model?.sceneId
    );
  }

  async open(): Promise<void> {
    if (!this.model) {
      throw "The voiceover select modal needs a VoiceoverSelectPresenter model before it will open";
    }
    this.visible = true;
    await this.loadPage();
  }

  close(): void {
    this.visible = false;
    this.model = undefined;
    this.audioUserAssets = new TrackedArray<UserAsset>([]);
  }

  async loadPage(): Promise<void> {
    // @ts-expect-error
    const results = (await this.store.query("userAsset", {
      per_page: this.perPage, // eslint-disable-line camelcase
      page: this.page,
      type: "audio"
    })) as UserAsset[];

    // @ts-expect-error
    if (results.meta) {
      // @ts-expect-error
      this.meta = results.meta;
    }

    this.audioUserAssets.push(...results);
  }

  async loadNextPage(): Promise<void> {
    if (this.loadingMore || this.isLastPage) {
      return;
    }

    this.loadingMore = true;
    this.page += 1;

    try {
      await this.loadPage();
    } finally {
      this.loadingMore = false;
    }
  }

  async refreshAssets(): Promise<void> {
    this.audioUserAssets = new TrackedArray<UserAsset>([]);
    await this.loadPage();
  }

  addTrack(asset: UserAsset): void {
    this.audioUserAssets.push(asset);
  }

  removeTrack(asset: UserAsset): void {
    const index = this.audioUserAssets.findIndex((a) => a.id === asset.id);
    this.audioUserAssets.splice(index, 1);
  }
}
