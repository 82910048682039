import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import AudioController from "client/controllers/audio";
import type { AudioClip } from "client/lib/editor-domain-model";
import type { AudioRouteModel } from "client/routes/audio";
import type PropertiesPanelService from "client/services/properties-panel";
import type VoiceoverSelectModalService from "client/services/voiceover-select-modal";

export default class VoiceoverController extends AudioController<AudioRouteModel> {
  queryParams = ["audioClipId"];

  @tracked
  audioClipId?: string | undefined;

  @service
  declare router: RouterService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @service
  declare voiceoverSelectModal: VoiceoverSelectModalService;

  @action
  async onAdd(): Promise<void> {
    const { project, audioClip, timeline, eventRegister, projectScene } = this.model;
    this.voiceoverSelectModal.setModel({
      project,
      audioClip,
      timeline,
      eventRegister,
      replacedClipId: this.audioClipId,
      sceneId: projectScene.id
    });
    await this.voiceoverSelectModal.open();
  }

  @action
  async onRemove(): Promise<void> {
    await this.remove();
    void this.router.transitionTo("authenticated.project.scene.voiceover", { queryParams: { audioClipId: undefined } });
  }

  get audioClip(): AudioClip | undefined {
    return this.audioClips.find((clip) => clip.id === this.audioClipId);
  }
}
