import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { SafeString } from "handlebars";
import type { ResizeDelta } from "../resize-element-handle/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum ResizePositions {
  TOP_LEFT = "tl",
  TOP = "t",
  TOP_RIGHT = "tr",
  RIGHT = "r",
  BOTTOM_RIGHT = "br",
  BOTTOM = "b",
  BOTTOM_LEFT = "bl",
  LEFT = "l"
}

export enum ResizeType {
  UNIT = "unit",
  GROUP = "group"
}

interface AppResizeElementArgs {
  resizeStart?: () => void;
  resize?: (position: ResizePositions, coords: ResizeDelta) => void;
  resizeEnd?: () => void;
  resizeX: boolean;
  resizeY: boolean;
  resizeType?: ResizeType;
  locked?: boolean;
  clippedBounds?: [number, number, number, number];
}

export default class AppResizeElementComponent extends Component<AppResizeElementArgs> {
  @tracked
  resizing = false;

  styleNamespace = getStyleNamespace("app/resize-element");

  @action
  handleResizeStart(): void {
    this.resizing = true;

    if (this.args.resizeStart) {
      this.args.resizeStart();
    }
  }

  @action
  handleResizeEnd(): void {
    this.resizing = false;

    if (this.args.resizeEnd) {
      this.args.resizeEnd();
    }
  }

  get boundsStyleAttr(): SafeString {
    if (!this.args.clippedBounds) {
      return htmlSafe("");
    }

    const [x1, y1, x2, y2] = this.args.clippedBounds;

    const percent = (n: number): string => `${Number(n * 100)}%`;

    const path = [
      [x1, y1],
      [x2, y1],
      [x2, y2],
      [x1, y2]
    ]
      .map((pos) => pos.map(percent).join(" "))
      .join(", ");

    return htmlSafe(`clip-path: polygon(${path}); -webkit-clip-path: polygon(${path})`);
  }

  get visibleResizeHandles(): string[] {
    if (this.args.locked || !this.args.resize) {
      return [];
    }

    const { resizeX, resizeY } = this;

    const handles = [
      ResizePositions.TOP_LEFT,
      ResizePositions.TOP_RIGHT,
      ResizePositions.BOTTOM_RIGHT,
      ResizePositions.BOTTOM_LEFT
    ];

    if (resizeY) {
      handles.push(ResizePositions.TOP, ResizePositions.BOTTOM);
    }

    if (resizeX) {
      handles.push(ResizePositions.LEFT, ResizePositions.RIGHT);
    }

    return handles;
  }

  get isGroup(): boolean {
    return this.args.resizeType === ResizeType.GROUP;
  }

  get resizeX(): boolean {
    return this.args.resizeX ?? true;
  }

  get resizeY(): boolean {
    return this.args.resizeY ?? true;
  }
}
