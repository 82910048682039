import Model, { attr, belongsTo } from "@ember-data/model";
import type Project from "./project";

export default class Narration extends Model {
  @attr("string")
  declare text: string;

  @attr("string")
  declare url: string;

  @attr("string")
  declare name: string;

  @attr("string")
  declare voice: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  project?: Project;

  type: string = Narration.modelName.toString();
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    narration: Narration;
  }
}
