export class RumTimer {
  static instances = new Map<string, number>();

  public meta = {};
  private instance;
  private time;
  private children: RumTimer[] = [];

  constructor(private name: string, private references: RumTimer[] = [], meta: Object = {}) {
    this.instance = RumTimer.instances.get(name) ?? 0;
    RumTimer.instances.set(name, this.instance + 1);

    this.meta = meta;
    if (!references.length) {
      this.time = new Date().getTime();
    }
    references.forEach((r) => {
      r.children.push(this);
    });
  }

  sample(reentry = false): void {
    if (!reentry && this.time) {
      return;
    }

    this.time = new Date().getTime();
    this.references.forEach((r) => {
      if (r.time) {
        r.children = r.children.filter((c) => c !== this);
        this.record(r);
      }
    });
    this.children.forEach((c) => {
      if (c.time) {
        c.references = c.references.filter((r) => r !== this);
        c.record(this);
      }
    });
  }

  private collectTimers(): RumTimer[] {
    if (this.references[0]) {
      return [...this.references[0].collectTimers(), this];
    }
    return [this];
  }

  private record(reference: RumTimer): void {
    // Convert a name like ["a", "b", "c"] to
    //
    // "a",
    // {
    //   b: {
    //     c: {
    //       duration: <n>
    //     }
    //   }
    //   ...meta
    // }

    const timers = [...reference.collectTimers(), this];

    const context = timers.reduceRight<{ [key: string]: any }>(
      (context, timer) => {
        const newData: { [key: string]: any } = {};
        newData[timer.name] = context;
        return newData;
      },
      {
        duration: (this.time! - reference.time!) / 1000
      }
    );

    const metaContext = context[timers[0]!.name]!;
    timers.forEach((t) => {
      if (!metaContext[t.name]) {
        metaContext[t.name] = {};
      }
      metaContext[t.name].instance = t.instance;
      Object.assign(metaContext[t.name], t.meta);
    });

    window.DD_RUM?.addAction(timers.map((t) => t.name).join("."), context);
  }
}

export class SimpleRumTimer {
  reference: RumTimer;
  value: RumTimer;

  constructor(name: string) {
    this.reference = new RumTimer("metrics");
    this.reference.sample();
    this.value = new RumTimer(name, [this.reference]);
  }

  get meta(): { [key: string]: any } {
    return this.value.meta;
  }

  set meta(meta: { [key: string]: any }) {
    this.value.meta = meta;
  }

  sample(reentry = false): void {
    this.value.sample(reentry);
  }
}
