import { toFixed, Vector2 } from "../math";

export class Cells {
  // Get grid snap points. The corners of each cell in the grid are considered snap points.
  public static getGridPoints(gridCells: { x: number; y: number }): Vector2[] {
    const points: Vector2[] = [];
    for (let column = 0; column <= gridCells.x; column++) {
      for (let row = 0; row <= gridCells.y; row++) {
        const point = new Vector2(toFixed(column * (1 / gridCells.x)), toFixed(row * (1 / gridCells.y)));
        points.push(point);
      }
    }

    return points;
  }
}
