import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { tracked } from "@glimmer/tracking";

export class ResetRouteModel {
  @tracked
  id: string;

  @tracked
  email: string;

  @tracked
  password = "";

  constructor(id: string, email: string) {
    this.id = id;
    this.email = email;
  }
}

export default class ResetRoute extends Route<ResetRouteModel> {
  @service
  declare router: RouterService;

  beforeModel(transition: Transition): void {
    const { id, email } = transition.to.queryParams;

    if (isEmpty(id) || isEmpty(email)) {
      void this.router.transitionTo("unauthenticated.login");
    }
  }

  model(_: {}, transition: Transition): ResetRouteModel {
    const { id, email } = transition.to.queryParams;

    return new ResetRouteModel(id!, email!);
  }

  queryParams = {
    id: {},
    email: {}
  };
}
