import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type TrackingService from "client/services/tracking";

const SHOW_EVENT_NAME = "show-di-action";
const START_EVENT_NAME = "start-di-action";

interface DynamicIslandActionArgs {
  onClose?: () => void;
  reload?: () => void;
}

export default abstract class DynamicIslandActionComponent extends Component<DynamicIslandActionArgs> {
  @service
  declare tracking: TrackingService;

  abstract actionName: string;

  @action
  didInsert(): void {
    this.triggerShowEvent();
  }

  @action
  onInteraction(): void {
    this.triggerStartEvent();
  }

  private triggerShowEvent(): void {
    const { actionName } = this;

    void this.tracking.sendAnalytics(SHOW_EVENT_NAME, {
      actionName
    });
  }

  private triggerStartEvent(): void {
    const { actionName } = this;

    void this.tracking.sendAnalytics(START_EVENT_NAME, {
      actionName
    });
  }
}
