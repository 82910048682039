import { action } from "@ember/object";
import Component from "@glimmer/component";
import type BrandLogo from "client/lib/brand/brand-logo";
import getStyleNamespace from "client/lib/get-style-namespace";

interface BrandLogoArgs {
  logo: BrandLogo;
  onEdit(brandLogo: BrandLogo): unknown;
  onDelete(brandLogo: BrandLogo): unknown;
  setDefault(brandLogo: BrandLogo): unknown;
}

export default class BrandLogoComponent extends Component<BrandLogoArgs> {
  styleNamespace = getStyleNamespace("brand/logo");

  get isDefaultLogo(): boolean {
    return this.args.logo.defaultLogo;
  }

  @action
  setDefault(): void {
    if (!this.isDefaultLogo) {
      this.args.setDefault(this.args.logo);
    }
  }
}
