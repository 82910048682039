import Component from "@glimmer/component";

interface AdminEditIconArgs {
  url: string;
  label?: string;
}

export default class AdminEditIconComponent extends Component<AdminEditIconArgs> {
  get label(): string {
    return this.args.label ?? "Edit";
  }
}
