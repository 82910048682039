import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import delay from "client/lib/delay";
import type AuthService from "client/services/auth";
import type FailedLoginAttemptsService from "client/services/failed-login-attempts";
import type OnboardService from "client/services/onboard";
import type StorageService from "client/services/storage";
import Session from "ember-simple-auth/services/session";

const FIRST_SESSION_STATE = "first_session_state";

enum FirstSessionState {
  HANDLED = "handled",
  HANDLING = "handling"
}

export default class SessionService extends Session {
  @service
  private declare router: RouterService;

  @service
  private declare storage: StorageService;

  @service
  private declare auth: AuthService;

  @service
  private declare failedLoginAttempts: FailedLoginAttemptsService;

  @service
  private declare onboard: OnboardService;

  @tracked
  handlingAuthentication = false;

  private get isFirstSession(): boolean {
    return this.data.authenticated.first_session ?? false;
  }

  async handleAuthentication(routeAfterAuthentication: string): Promise<void> {
    try {
      this.handlingAuthentication = true;
      await this.auth.loadCurrentUser();
    } catch {
      await this.invalidate();
      this.handlingAuthentication = false;
    }

    try {
      this.failedLoginAttempts.reset();

      if (this.firstSessionNotHandled) {
        await this.handleFirstSession();
      } else {
        if (await this.waitFirstSessionHandled()) {
          await this.auth.reload();
        }

        // eslint-disable-next-line no-console
        console.log("Transition from", this.attemptedTransition?.from, "to", this.attemptedTransition?.to);

        // Check the `intent` of the attemptedTransition to ensure that it doesn't try and treat the naked
        // route (`/`) as an attempted transition. This was causing the route after login to be incorrect.
        if (
          this.attemptedTransition &&
          // @ts-expect-error
          this.attemptedTransition.intent?.url &&
          // @ts-expect-error
          this.attemptedTransition.intent.url !== "/"
        ) {
          await this.handleAttemptedTransition();
        } else {
          this.handlingAuthentication = false;
          await this.handleAfterAuthentication(routeAfterAuthentication);
        }
      }
    } finally {
      this.handlingAuthentication = false;
    }
  }

  private async handleFirstSession(): Promise<void> {
    try {
      this.storage.setSessionItem(FIRST_SESSION_STATE, FirstSessionState.HANDLING);
      await this.onboard.start();
      this.storage.setSessionItem(FIRST_SESSION_STATE, FirstSessionState.HANDLED);
    } catch {
      this.storage.removeSessionItem(FIRST_SESSION_STATE);
    }
  }

  private get firstSessionNotHandled(): boolean {
    return this.isFirstSession && this.storage.getSessionItem(FIRST_SESSION_STATE) === undefined;
  }

  private get firstSessionHandling(): boolean {
    return this.isFirstSession && this.storage.getSessionItem(FIRST_SESSION_STATE) === FirstSessionState.HANDLING;
  }

  private async waitFirstSessionHandled(): Promise<boolean> {
    let concurrentSession = false;

    /* eslint-disable-next-line no-constant-condition */
    while (true) {
      if (this.firstSessionHandling || this.onboard.otherOnboarding) {
        await delay(5_000);
        concurrentSession = true;
      } else {
        return concurrentSession;
      }
    }
  }

  private async handleAttemptedTransition(): Promise<void> {
    try {
      if (this.attemptedTransition) {
        void this.attemptedTransition.retry();
        this.attemptedTransition = undefined;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      void this.router.replaceWith("authenticated.home");
    }
  }

  private async handleAfterAuthentication(routeAfterAuthentication: string): Promise<void> {
    void this.router.transitionTo(routeAfterAuthentication);
  }
}
