import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface EmptyRecordComponentArgs {
  buttonAction: () => Promise<void>;
  descriptionText: string;
  titleText: string;
  buttonText: string;
}

export default class EmptyRecordComponent extends Component<EmptyRecordComponentArgs> {
  styleNamespace = getStyleNamespace("asset-management/empty-record");
}
