import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type TemplatesController from "../controller";
import type TemplateCategories from "client/services/template-categories";

export interface TemplatesPopularModel {
  categories: string[];
}

export default class TemplatesPopularRoute extends Route<TemplatesPopularModel> {
  @service
  declare templateCategories: TemplateCategories;

  beforeModel(): void {
    // Popular templates can't have any filters against it
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor<TemplatesController>("authenticated.templates").resetFilters();
  }

  async model(): Promise<TemplatesPopularModel> {
    const categories = await this.templateCategories.featuredCategorySlugs();
    return {
      categories
    };
  }
}
