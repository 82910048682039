import type { Caption, Scene } from "../../scene";
import type { TimelineFacade } from "../../timeline";
import { AddCaptionMutation } from "./add-caption-mutation";

export class DuplicateCaptionMutation extends AddCaptionMutation {
  constructor(scene: Scene, private originalCaption: Caption) {
    super(scene);
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    this.caption = await facade.duplicateCaption(this.scene, this.originalCaption);
  }
}
