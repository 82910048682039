import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type MobileService from "client/services/mobile";
import type TrackingService from "client/services/tracking";
import type VideoAssistantService from "client/services/video-assistant";

export interface TrackingProperties {
  job?: string;
  type?: string;
  style?: string;
  aspectRatioId?: string;
  referer?: string;
  [key: string]: string | undefined;
}

interface SurveyNavArgs {
  onNextButtonClick: () => Promise<void>;
  onBackButtonClick: () => Promise<void>;
  trackingProperties: TrackingProperties;
  loading: boolean;
  nextButtonDisabled: boolean;
  nextButtonType: string;
  buildVideo: boolean;
}

export default class SurveyNavComponent extends Component<SurveyNavArgs> {
  styleNamespace = getStyleNamespace("survey/nav");

  @service
  declare mobile: MobileService;

  @service
  declare tracking: TrackingService;

  @service
  declare videoAssistant: VideoAssistantService;

  @action
  async onNextButtonClick(): Promise<void> {
    this.track();
    await this.args.onNextButtonClick();
  }

  get isMobile(): boolean {
    return this.mobile.isMobile;
  }

  get showIcon(): boolean {
    return !this.isMobile && this.args.buildVideo;
  }

  get nextButtonLabel(): string {
    if (this.args.buildVideo) {
      return "Build video";
    } else {
      return this.isMobile ? "Next" : "Continue";
    }
  }

  get hasBackButton(): boolean {
    return !!this.args.onBackButtonClick;
  }

  get buttonType(): string {
    return this.args.nextButtonType ?? "button";
  }

  private track(): void {
    void this.tracking.sendAnalytics(
      TrackingEvents.EVENT_VIDEO_ASSISTANT_WIZARD_CHOICE_MADE,
      this.args.trackingProperties
    );
  }
}
