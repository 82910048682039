import type { Rect } from "../math";
import { toFixed } from "../math";
import { Axis } from "./axis";
import { Line } from "./line";

export class Lines {
  // Get grid lines expressed as straight line equations (e.g. a vertical edge would be x=constant, y=n)
  public static getGridLines(gridCells: { x: number; y: number }, snapThreshold?: number): Line[] {
    const lines: Line[] = [];
    for (let column = 1; column < gridCells.x; column++) {
      lines.push(new Line(toFixed(column * (1 / gridCells.x)), 0, Axis.VERTICAL, snapThreshold));
    }

    for (let row = 1; row < gridCells.y; row++) {
      lines.push(new Line(0, toFixed(row * (1 / gridCells.y)), Axis.HORIZONTAL, snapThreshold));
    }

    return lines;
  }

  // Get margin lines expressed as straight line equations (e.g. a vertical edge would be x=constant, y=n)
  public static getMarginLines(
    marginCells: { x: number; y: number },
    gridCells: { x: number; y: number },
    snapThreshold?: number
  ): Line[] {
    const yCellHeight = 1 / gridCells.y;
    const marginY = marginCells.y * yCellHeight;
    const horizontalLines = [
      new Line(0, toFixed(marginY), Axis.HORIZONTAL, snapThreshold),
      new Line(0, toFixed(1 - marginY), Axis.HORIZONTAL, snapThreshold)
    ];

    const xCellWidth = 1 / gridCells.x;
    const marginX = marginCells.x * xCellWidth;
    const verticalLines = [
      new Line(toFixed(marginX), 0, Axis.VERTICAL, snapThreshold),
      new Line(toFixed(1 - marginX), 0, Axis.VERTICAL, snapThreshold)
    ];

    return [...horizontalLines, ...verticalLines];
  }

  public static rectLines({ x, y, width, height }: Rect, snapThreshold?: number): Line[] {
    const horizontalEdges = [
      new Line(0, y, Axis.HORIZONTAL, snapThreshold),
      new Line(0, y + height, Axis.HORIZONTAL, snapThreshold),
      new Line(0, y + height / 2, Axis.HORIZONTAL, snapThreshold)
    ];
    const verticalEdges = [
      new Line(x, 0, Axis.VERTICAL, snapThreshold),
      new Line(x + width, 0, Axis.VERTICAL, snapThreshold),
      new Line(x + width / 2, 0, Axis.VERTICAL, snapThreshold)
    ];
    return [...horizontalEdges, ...verticalEdges];
  }
}
