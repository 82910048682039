import type { AspectRatio } from "@biteable/network-model";
import { action } from "@ember/object";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { baseFontSize } from "renderer-engine";
import type { Caption, EventRegister, Scene, StrictMutation, Timeline } from "client/lib/editor-domain-model";
import { AddNewCaptionMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import PlaybackController from "client/lib/playback/playback";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type Team from "client/models/team";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AdvancedTimingService from "client/services/advanced-timing";
import type AuthService from "client/services/auth";
import type BrandStyleService from "client/services/brand-style";
import type GridService from "client/services/grid";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type PlaybackService from "client/services/playback";
import type PropertiesPanelService from "client/services/properties-panel";
import type TrackingService from "client/services/tracking";

interface ProjectEditorArgs {
  project: Project;
  timeline: Timeline;
  eventRegister: EventRegister;
  aspectRatio: AspectRatio;
  scene: Scene;
}

export const DEFAULT_CAPTION_DURATION = 3; // seconds

export const generateNewCaption = async (scene: Scene, eventRegister: EventRegister): Promise<Caption | undefined> => {
  const mutation = new AddNewCaptionMutation(scene, 0, scene.duration, false, false);
  await mutation.prepare(eventRegister.facade);
  return eventRegister.fire(mutation as StrictMutation<Caption | undefined>);
};

export default class ProjectEditorComponent extends Component<ProjectEditorArgs> {
  @service
  declare advancedTiming: AdvancedTimingService;

  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @service
  declare tracking: TrackingService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare notifications: NotificationsService;

  @service
  declare playback: PlaybackService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @service
  declare store: Store;

  @service
  declare brandStyle: BrandStyleService;

  @service
  declare grid: GridService;

  @tracked
  currentUserId!: number;

  @tracked
  canvasBox = new DOMRect(0, 0, 0, 0);

  playbackController: PlaybackController;

  styleNamespace = getStyleNamespace("tidal/project-editor");

  constructor(owner: Owner, args: ProjectEditorArgs) {
    super(owner, args);
    this.playbackController = new PlaybackController(this.playback, this.tracking);
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this.trackCanvasBounds(element);
    this.grid.initialize(this.args.project.aspectRatio);
    this.currentUserId = Number(await this.auth.currentUser!.id);
  }

  @action
  async toggleScenePlay(): Promise<void> {
    if (this.playback.playing) {
      await this.playbackController.stop();
    } else {
      await this.startScenePlay();
    }
  }

  @action
  async onClick({ target }: MouseEvent): Promise<void> {
    if (target instanceof HTMLElement && target.classList.contains("-deselect-background")) {
      await this.advancedEditor.transitionToBackground(false);
    }
  }

  get aspectRatio(): Project["aspectRatio"] {
    return this.args.project.aspectRatio;
  }

  get canvasBounds(): DOMRect {
    const width = Math.min(this.canvasBox.width, this.canvasBox.height * this.aspectRatio.ratioY);
    const height = Math.min(this.canvasBox.height, this.canvasBox.width * this.aspectRatio.ratioX);
    return new DOMRect((this.canvasBox.width - width) / 2, (this.canvasBox.height - height) / 2, width, height);
  }

  get playButtonStyle(): SafeString {
    return htmlSafe(`transform: translate(${-this.canvasBounds.x}px, ${-this.canvasBounds.y}px);`);
  }

  get canvasBoxStyle(): SafeString {
    const b = this.canvasBounds;
    const fontSize = baseFontSize(b.width, b.height);

    return htmlSafe(
      `position: absolute; left: ${b.x}px; top: ${this.canvasBox.y + b.y}px; width: ${b.width}px; height: ${
        b.height
      }px; font-size: ${fontSize}`
    );
  }

  get canvasHeaderStyle(): SafeString {
    return htmlSafe(`transform: translate(0px, ${this.canvasBounds.y}px);`);
  }

  get projectScene(): ProjectScene | undefined {
    return this.findProjectScene();
  }

  get isPlaying(): boolean {
    return this.playback.playing;
  }

  get teamShared(): boolean {
    return this.args.project.teamShared;
  }

  get hasOtherEditors(): boolean {
    return this.args.project.hasOtherEditors;
  }

  get displaySharedProjectWarning(): boolean {
    return this.teamShared && this.hasOtherEditors;
  }

  get showOwnerWarning(): boolean {
    const { isOwner, teamShared } = this.args.project;
    const team = this.args.project.belongsTo("team").value() as Team;

    if (isOwner) {
      return false;
    }

    const editingExpected = teamShared && team?.isTeamMember;
    return !editingExpected;
  }

  get canCopy(): boolean {
    return this.permissions.has("global_project_copy");
  }

  trackCanvasBounds(element: HTMLElement): void {
    const canvasBoxElement = element.querySelector(`.${this.styleNamespace}__CanvasBox`);
    if (!canvasBoxElement) {
      throw Error("Can't find canvas element");
    }

    const parentElement = canvasBoxElement.parentElement;
    if (!parentElement) {
      return;
    }

    const box = canvasBoxElement.getBoundingClientRect();
    const parent = parentElement.getBoundingClientRect();
    this.canvasBox = new DOMRect(box.x - parent.x, box.y - parent.y, box.width, box.height);

    const observer = new ResizeObserver(() => {
      const box = canvasBoxElement.getBoundingClientRect();
      const parent = parentElement.getBoundingClientRect();
      this.canvasBox = new DOMRect(box.x - parent.x, box.y - parent.y, box.width, box.height);
    });

    observer.observe(canvasBoxElement);
    observer.observe(parentElement);
  }

  async startScenePlay(): Promise<void> {
    const { startTime, endTime } = this.args.timeline.getSceneTiming(this.args.scene);

    await this.playbackController.playTimeRange(startTime, endTime);
  }

  private findProjectScene(): ProjectScene | undefined {
    // @ts-expect-error
    const projectScenes = this.args.project.hasMany("projectScenes").value() as ProjectScene[];
    return projectScenes.find((projectScene) => projectScene.id === this.args.scene.id) as ProjectScene;
  }
}
