import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";

interface BrandStyleTileArgs {
  brand: BrandStyle;
  setAsDefault: (brand: BrandStyle) => Promise<void>;
  editBrand: (brand: BrandStyle) => void;
  deleteBrand: (brand: BrandStyle) => Promise<void>;
}

export default class BrandStyleTileComponent extends Component<BrandStyleTileArgs> {
  @service
  private declare notifications: NotificationsService;

  @service
  private declare permissions: PermissionsService;

  @tracked
  actionsMenuOpen = false;

  styleNamespace = getStyleNamespace("brand/brand-style-tile");

  @action
  async setBrandAsDefault(): Promise<void> {
    if (!this.isDefaultBrand) {
      await this.args.setAsDefault(this.args.brand);
    }
  }

  @action
  editBrand(): void {
    this.args.editBrand(this.args.brand);
  }

  @action
  async deleteBrand(): Promise<void> {
    await this.args.deleteBrand(this.args.brand);
  }

  @action
  async duplicateBrand(): Promise<void> {
    const duplicate = await this.args.brand.duplicate();

    this.notifications.success("Your brand has been duplicated");

    this.args.editBrand(duplicate);
  }

  @action
  onMouseLeave(): void {
    this.actionsMenuOpen = false;
  }

  get hasMultipleBrandsFeature(): boolean {
    return this.permissions.has("feature_multiple_brands");
  }

  get showDefaultAction(): boolean {
    return !this.isDefaultBrand;
  }

  get isDefaultBrand(): boolean {
    return this.hasMultipleBrandsFeature && this.args.brand.isDefault;
  }

  get showDeleteAction(): boolean {
    return !this.isDefaultBrand;
  }
}
