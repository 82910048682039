import { tracked } from "@glimmer/tracking";
import EmberPageTitleService from "ember-page-title/services/page-title";

export default class PageTitleService extends EmberPageTitleService {
  @tracked
  title = "";

  titleDidUpdate(title: string): void {
    this.title = title;
  }
}
