import type { VideoAssetContainer } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type { VideoClip } from "client/lib/editor-domain-model";

export const videoToVideoAssetContainer = (video: VideoClip, sceneId?: string): VideoAssetContainer => {
  const { url, previewImageUrl } = video.asset;
  return {
    cfg: {
      video: {
        url,
        previewImageUrl,
        downsizedUrlBase: undefined,
        previewVideoUrl: undefined,
        lowResVideoUrl: undefined,
        encodingLevel: undefined
      }
    },
    category: ZymbolCategory.VIDEO,
    sceneId
  };
};
