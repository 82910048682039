import Model, { attr, belongsTo } from "@ember-data/model";
import type AspectRatio from "client/models/aspect-ratio";
import type Project from "client/models/project";
import ProjectDownloadEvent from "client/models/project-download-event";
import type ProjectTranscription from "client/models/project-transcription";

export interface IProjectRenderStatus {
  message: string;
}

export default class ProjectRender extends Model {
  @attr("string")
  declare url: string;

  @attr("string")
  declare thumbnailUrl: string;

  @attr("date")
  declare createdAt: Date;

  @attr("boolean", { allowNull: true })
  declare successful?: boolean | null;

  @attr("boolean")
  declare watermarked: boolean;

  @belongsTo("project", { async: true, inverse: "latestRender" })
  declare project: Project;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("aspect-ratio", { async: true, inverse: null })
  declare aspectRatio: AspectRatio;

  @attr("array")
  declare status: IProjectRenderStatus[];

  @attr("number")
  declare failurePart?: number;

  @attr("string")
  declare failureTime?: string;

  @attr("string")
  declare contentHash?: string;

  @attr("string")
  declare slugifiedName?: string;

  @attr("string")
  declare captionBurnRequestVideoUrl?: string;

  @belongsTo("project-transcription", { async: true, inverse: "projectRender" })
  declare projectTranscription?: ProjectTranscription;

  get rendering(): boolean {
    // eslint-disable-next-line no-null/no-null
    return this.successful === null;
  }

  get completed(): boolean {
    return this.rendering === false;
  }

  get failed(): boolean {
    return this.successful === false;
  }

  get statusText(): string {
    const [renderMessage, failureMessage] = [...this.status].reverse();

    if (this.rendering) {
      return renderMessage?.message ?? "";
    } else if (this.failed) {
      return failureMessage?.message ?? renderMessage?.message ?? "";
    } else {
      return "";
    }
  }

  async buildDownloadEvent(): Promise<object | undefined> {
    return new ProjectDownloadEvent(this).properties();
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectRender: ProjectRender;
  }
}
