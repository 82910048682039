import type RouterService from "@ember/routing/router-service";
import type Model from "@ember-data/model";
import type Store from "@ember-data/store";
import type Ember from "ember";
import type { ProportionalCoords, RenderZymbol, SagConfig, TextConfig, VideoConfig } from "renderer-engine";
import {
  CanvasUtils,
  getCoords,
  DEFAULT_FRAME_RATE,
  createRenderableLines,
  ZymbolCategory,
  ZymbolGroupLayer,
  AudioClipCategory
} from "renderer-engine";
import ColorPreset from "../brand/color-preset";
import CaptionFactory from "../scene/caption-factory";
import ZymbolConfigFactory from "../scene/zymbol-config-factory";
import { getWeightAndStyleAsVariant } from "../text/fonts";
import type { CaptionContent } from "./caption";
import CommitManager from "./commit-manager";
import type { Watermark, Logo, Text, CaptionOptionArgs } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { Rect } from "client/lib/editor-domain-model";
import { ProjectThumbnailBuilder } from "client/lib/project-thumbnail-builder";
import { rumAction } from "client/lib/rum-action";
import AssetFactory from "client/lib/scene/asset-factory";
import BackgroundFactory from "client/lib/scene/background-factory";
import { SceneFactory } from "client/lib/scene/scene-factory";
import { convertTextConfigToTextStyles, createTextZymbolConfig } from "client/lib/text/text-styles";
import SceneToZymbols from "client/lib/timeline/scene-to-zymbols";
import type Audible from "client/models/audible";
import AudioClip from "client/models/audio-clip";
import type Layer from "client/models/layer";
import type Project from "client/models/project";
import ProjectScene from "client/models/project-scene";
import type TextLayer from "client/models/text-layer";
import type Zymbol from "client/models/zymbol";
import ZymbolGroup from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type LayersService from "client/services/layers";
import type NotificationsService from "client/services/notifications";
import type ProjectScenesService from "client/services/project-scenes";
import type ProjectsService from "client/services/projects";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export type ClipConfig = SagConfig | VideoConfig;

export const BASE_CANVAS_SIZE = 384;

export class EmberTimelineBuilder implements DomainModel.TimelineFacade {
  private declare assetFactory: AssetFactory;
  private declare backgroundFactory: BackgroundFactory;
  private declare sceneFactory: SceneFactory;
  private declare captionFactory: CaptionFactory;
  private declare zymbolConfigFactory: ZymbolConfigFactory;

  private commitManager = new CommitManager();

  constructor(
    private ajax: AjaxService,
    private notifications: NotificationsService,
    private store: Store,
    private projects: ProjectsService,
    private projectScenes: ProjectScenesService,
    private layers: LayersService,
    private zymbolGroups: ZymbolGroupsService,
    private router: RouterService,
    private project: Project
  ) {
    this.assetFactory = new AssetFactory();
    this.captionFactory = new CaptionFactory(this.assetFactory);
    this.backgroundFactory = new BackgroundFactory(this.assetFactory);
    this.sceneFactory = new SceneFactory(this.backgroundFactory, this.captionFactory);
    this.zymbolConfigFactory = new ZymbolConfigFactory();
    this.commitManager.subscribe({
      next: () => this.project.triggerProjectChangeEvent(),
      error: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      complete: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
    });
  }

  async getAudioClips(): Promise<DomainModel.AudioClip[]> {
    const project = this.project;

    const audioClips = await project.audioClips;
    if (!audioClips || !audioClips.length) {
      return [];
    }

    return await Promise.all(
      audioClips.map(async (ac) => {
        const asset: DomainModel.AudioAsset = {
          url: ac.url,
          name: ac.name,
          type: (await ac.audible)?.type,
          id: (await ac.audible)?.id
        };
        return this.createDomainModelAudioClip(ac, asset);
      })
    );
  }

  async newAudioClip(
    asset: DomainModel.AudioAsset,
    category: string,
    offset: number,
    duration: number
  ): Promise<DomainModel.AudioClip> {
    if (!this.checkCategory(category)) {
      throw new Error(`Unsupported category: ${category}`);
    }

    const audible = (await this.store.findRecord(asset.type, asset.id)) as Audible;
    const emberAudioClip = await this.projects.createAudioClipForProject(
      audible,
      this.project,
      offset,
      duration,
      category
    );
    return this.createDomainModelAudioClip(emberAudioClip, asset);
  }

  checkCategory(category: string): category is AudioClipCategory {
    return category.toUpperCase() in AudioClipCategory;
  }

  getAspectRatio(): [width: number, height: number, widthPx: number, heightPx: number] {
    const { width, height, dimensions } = this.project.aspectRatio;
    return [width, height, dimensions.width, dimensions.height];
  }

  async getWatermark(): Promise<DomainModel.Watermark | undefined> {
    const watermark = await this.project.userWatermark;

    if (!watermark) {
      return;
    }

    return new DomainModel.Watermark({
      id: watermark.id,
      position: Rect.fromRect(watermark),
      asset: this.assetFactory.createAssetFromZymbol(watermark),
      assetOffset: watermark.assetOffset,
      mediaReplaceable: watermark.mediaReplaceable
    });
  }

  async getCustomFonts(): Promise<DomainModel.FontFamily[]> {
    const fonts = await this.store.findAll("font");
    return fonts.map(
      (f) => new DomainModel.FontFamily(f.id, f.name, f.variants.map(getWeightAndStyleAsVariant), f.subsets)
    );
  }

  getBrandStyleId(): string {
    return this.project.brandStyleId;
  }

  getAspectRatioId(): string {
    return this.project.aspectRatio.id;
  }

  async newText(caption: DomainModel.Caption, style?: DomainModel.TextStyle): Promise<DomainModel.Text> {
    const zymbolGroup = await this.getZymbolGroup(caption.id);
    const textConfig = this.applyStyleToDefault(zymbolGroup.defaultTextConfig, style);
    const newZymbol = await this.zymbolGroups.appendTextZymbol(zymbolGroup, undefined, textConfig, true);

    return CaptionFactory.zymbolToText(newZymbol);
  }

  private applyStyleToDefault(
    defaultTextConfig: TextConfig,
    style?: DomainModel.TextStyle
  ): Partial<TextConfig> | undefined {
    if (style) {
      const [defaultContent, defaultStyles, defaultAnimation] = convertTextConfigToTextStyles(defaultTextConfig);
      const newStyles = defaultStyles.map((newStyle) => {
        newStyle._fontSize = style.fontSize;
        newStyle._fontVariant = style.fontVariant;
        newStyle._list = style.list;
        newStyle._fontFamily = style.fontFamily;

        return newStyle;
      });

      return createTextZymbolConfig({
        content: defaultContent,
        styles: newStyles,
        animation: defaultAnimation
      });
    } else {
      return undefined;
    }
  }

  async newLogo(caption: DomainModel.Caption): Promise<DomainModel.Logo> {
    const zymbolGroup = await this.getZymbolGroup(caption.id);

    const newZymbol = await this.zymbolGroups.appendLogoZymbol(zymbolGroup, true);

    return this.captionFactory.zymbolToLogo(newZymbol);
  }

  async newCaption(
    scene: DomainModel.Scene,
    offset: number,
    duration: number,
    captionOptionArgs: CaptionOptionArgs
  ): Promise<DomainModel.Caption> {
    const { withLogo, withText, centered } = captionOptionArgs;

    const projectScene = await this.getProjectScene(scene.id);
    let zymbolGroup: ZymbolGroup;
    if (withText) {
      zymbolGroup = await this.layers.addTextZymbolGroup(projectScene.textLayer, offset, duration, withLogo);
    } else if (withLogo) {
      zymbolGroup = await this.layers.addLogoZymbolGroup(projectScene.textLayer, offset, duration, centered);
    } else {
      zymbolGroup = await this.layers.addEmptyZymbolGroup(projectScene.textLayer, offset, duration);
    }

    return this.createCaption(scene, zymbolGroup);
  }

  async newScene(duration: number): Promise<DomainModel.Scene> {
    const projectScene = await this.createNewProjectScene(duration);
    return await this.createScene(projectScene);
  }

  async duplicateScene(sceneId: string): Promise<DomainModel.Scene> {
    const projectScene = await this.getProjectScene(sceneId);
    const newProjectScene = await this.projectScenes.duplicateProjectScene(projectScene, {
      destinationProject: this.project
    });

    return this.createScene(newProjectScene);
  }

  async duplicateCaption(scene: DomainModel.Scene, caption: DomainModel.Caption): Promise<DomainModel.Caption> {
    const zymbolGroup = await this.getZymbolGroup(caption.id);
    const newZymbolGroup = await this.zymbolGroups.duplicateZymbolGroup(zymbolGroup);

    return this.createCaption(scene, newZymbolGroup);
  }

  async duplicateElement(elementId: string, parentCaptionId?: string): Promise<Logo | Text> {
    const zymbol = await this.getZymbol(elementId);
    const newZymbol = await this.zymbolGroups.duplicateZymbol(zymbol, parentCaptionId);

    if (newZymbol.category === ZymbolCategory.TEXT) {
      return CaptionFactory.zymbolToText(newZymbol);
    } else {
      return this.captionFactory.zymbolToLogo(newZymbol);
    }
  }

  async transitionTo(url: string): Promise<void> {
    await this.router.transitionTo(url);
  }

  get currentURL(): string {
    return this.router.currentURL;
  }

  async getCaptionThumbnail(caption: DomainModel.Caption): Promise<HTMLImageElement> {
    const scene = caption.scene;
    const startTime = caption.offset + caption.duration / 2;

    return this.generateThumbnail({ scene, startTime });
  }

  async getThumbnail(scene: DomainModel.Scene): Promise<HTMLImageElement> {
    return this.generateThumbnail({ scene, filterZymbols: (z) => this.showZymbolOnTimeline(z) });
  }

  @rumAction("ember-timeline-builder.generate-thumbnail")
  private async generateThumbnail({
    scene,
    filterZymbols,
    startTime
  }: {
    scene: DomainModel.Scene;
    filterZymbols?: (zymbol: RenderZymbol) => boolean;
    startTime?: number;
  }): Promise<HTMLImageElement> {
    const projectScene = await this.getProjectScene(scene.id);
    if (projectScene) {
      const project = await projectScene.project;
      if (!project) {
        return new Image();
      }

      const canvas = CanvasUtils.newCanvasContext({ width: BASE_CANVAS_SIZE, height: BASE_CANVAS_SIZE }).canvas;
      const aspectRatio = project.aspectRatio;
      const frame = Math.round((startTime ?? scene.duration / 2) * DEFAULT_FRAME_RATE);

      const projectThumbnailBuilder = new ProjectThumbnailBuilder({
        canvas,
        project,
        frameNumber: frame,
        canvasDimensions: [BASE_CANVAS_SIZE / aspectRatio.ratioX, BASE_CANVAS_SIZE],
        filterZymbols,
        zymbolsToRender: new SceneToZymbols(scene, frame).getZymbols()
      });
      await projectThumbnailBuilder.generate();
      return projectThumbnailBuilder.getImage();
    }

    return new Image();
  }

  private showZymbolOnTimeline(zymbol: RenderZymbol): boolean {
    return zymbol.layerName !== ZymbolGroupLayer.TEXT && !zymbol.identifier.includes("Watermark");
  }

  async createNewProjectScene(duration: number): Promise<ProjectScene> {
    const project = this.project;
    return this.projects.addProjectSceneWithDefaults({ project, duration });
  }

  async saveScene(scene: DomainModel.Scene, saveOptions?: { delayCommitMs?: number }): Promise<void> {
    const save = await this.commitManager.createSave();
    const projectScene = await this.getProjectScene(scene.id);

    if (!projectScene || !!projectScene.isLoading) {
      return;
    }

    if (projectScene.duration !== scene.duration) {
      projectScene.backgroundZymbolGroup?.set("duration", scene.duration);
    }

    if (projectScene.filterColor !== scene.filterColor) {
      projectScene.set("filterColor", scene.filterColor);
    }

    if (projectScene.backgroundColor !== scene.color) {
      projectScene.set("backgroundColor", scene.color);
    }

    if (projectScene.transition !== scene.transition?.name) {
      projectScene.set("transition", scene.transition?.name);
    }

    if (!ColorPreset.equal(projectScene.colorPreset, scene.colorPreset)) {
      projectScene.set("colorPreset", scene.colorPreset);
    }

    if (projectScene.backgroundColorBrandKey !== scene.colorBrandKey) {
      projectScene.backgroundColorBrandKey = scene.colorBrandKey;
    }

    const { backgroundZymbol } = projectScene;

    if (backgroundZymbol) {
      await this.updateZymbolFromElement(backgroundZymbol, scene.background);

      save(backgroundZymbol);
    }

    const zymbolGroups = projectScene.textLayerZymbolGroups;

    await this.performDestroyAndRestore(scene.captions, zymbolGroups, {
      destroy: (zg) => zg.removeZymbolGroup(),
      restore: async (c) => {
        const zymbolGroup = await ZymbolGroup.restore(this.ajax, this.notifications, this.store, c.id);
        await Promise.all([...c.texts, ...c.logos].map(async ({ id }) => zymbolGroup.restoreZymbol(id)));
        return zymbolGroup;
      }
    });

    await Promise.all(
      scene.captions.map(async (caption) => {
        const zymbolGroup = await this.getZymbolGroup(caption.id);
        // @ts-expect-error
        const zymbols = zymbolGroup.hasMany("zymbols").value() as Array<Zymbol>;

        if (!zymbolGroup || !!zymbolGroup.isLoading) {
          return;
        }

        if (zymbolGroup.startTime !== caption.offset) {
          zymbolGroup.set("startTime", caption.offset);
        }

        if (zymbolGroup.duration !== caption.duration) {
          zymbolGroup.set("duration", caption.duration);
        }

        if (zymbolGroup.durationMode !== caption.durationMode) {
          zymbolGroup.set("durationMode", caption.durationMode);
        }

        const textsAndLogos = [...caption.texts, ...caption.logos];
        await this.performDestroyAndRestore(textsAndLogos, zymbols, {
          destroy: (z: Zymbol) => z.removeZymbol(),
          restore: async ({ id }) => zymbolGroup.restoreZymbol(id)
        });

        await Promise.all(
          textsAndLogos.map(async (content: CaptionContent) => {
            const zymbol = await this.getZymbol(content.id);

            if (!zymbol || !!zymbol.isLoading) {
              return;
            }

            await this.updateZymbolFromElement(zymbol, content);

            save(zymbol);
          })
        );

        save(zymbolGroup);
      })
    );

    if (projectScene.backgroundZymbolGroup) {
      save(projectScene.backgroundZymbolGroup);
    }

    save(projectScene);

    await this.commitManager.commit(saveOptions?.delayCommitMs ?? 0);
  }

  async saveSceneOrder(timeline: DomainModel.Timeline): Promise<void> {
    const project = this.project;
    const scenes = timeline.scenes;

    await this.performDestroyAndRestore(scenes, await this.getProjectScenes(project), {
      destroy: (ps) => ps.destroyRecord(),
      restore: async ({ id }) => ProjectScene.restore(this.ajax, this.notifications, this.store, id)
    });

    const newSceneOrder = scenes.map(({ id }) => id);
    project.projectSceneOrder = newSceneOrder.map(Number);
    await project.save();
  }

  async performDestroyAndRestore<M extends Model, DM extends { id: string }>(
    domainModels: DM[],
    models: M[] | Ember.Array<M>,
    methods: {
      destroy: (model: M) => Promise<M>;
      restore: (domainModel: DM) => Promise<M>;
    }
  ): Promise<void> {
    const newModels = domainModels.filter(({ id }) => !models.find((m) => m.id === id));
    const restorePromises = newModels.map((dm) => methods.restore(dm));

    const toDestroy = models.filter(({ id }) => !domainModels.find((dm) => dm.id === id));
    const destroyPromises = toDestroy.map((m) => methods.destroy(m));

    await Promise.all([...destroyPromises, ...restorePromises]);
  }

  async saveAudioClips(timeline: DomainModel.Timeline): Promise<void> {
    const project = this.project;
    const domainAudioClips = timeline.audioClips;

    await this.performDestroyAndRestore(domainAudioClips, await project.audioClips, {
      destroy: (clip) => clip.destroyRecord(),
      restore: async ({ id }) => AudioClip.restore(this.ajax, this.notifications, this.store, id)
    });

    await Promise.all(
      domainAudioClips.map(async (domainAudioClip) => {
        const audioClip = await this.getAudioClip(domainAudioClip.id);

        if (audioClip.duration !== domainAudioClip.duration) {
          audioClip.set("duration", domainAudioClip.duration);
        }

        if (audioClip.audioDuckingLevel !== domainAudioClip.audioDuckingLevel) {
          audioClip.set("audioDuckingLevel", domainAudioClip.audioDuckingLevel);
        }

        if (audioClip.trimStart !== domainAudioClip.trimStart) {
          audioClip.set("trimStart", domainAudioClip.trimStart);
        }

        if (audioClip.fadeIn !== domainAudioClip.fadeIn) {
          audioClip.set("fadeIn", domainAudioClip.fadeIn);
        }

        if (audioClip.fadeOut !== domainAudioClip.fadeOut) {
          audioClip.set("fadeOut", domainAudioClip.fadeOut);
        }

        if (audioClip.loop !== domainAudioClip.loop) {
          audioClip.set("loop", domainAudioClip.loop);
        }

        if (audioClip.mute !== domainAudioClip.mute) {
          audioClip.set("mute", domainAudioClip.mute);
        }

        if (audioClip.startTime !== domainAudioClip.offset) {
          audioClip.set("startTime", domainAudioClip.offset);
        }

        if (audioClip.url !== domainAudioClip.sourceUrl) {
          if (domainAudioClip.asset) {
            const { id: audibleId, type: audibleType } = domainAudioClip.asset;
            audioClip.setProperties({ audibleId, audibleType });
          }

          audioClip.set("url", domainAudioClip.sourceUrl);
          await audioClip.save();
        }

        if (audioClip.volume !== domainAudioClip.volume) {
          audioClip.set("volume", domainAudioClip.volume);
        }

        if (audioClip.get("hasDirtyAttributes")) {
          await audioClip.save();
        }
      })
    );
  }

  async saveWatermark(watermark: Watermark, saveOptions?: { delayCommitMs?: number }): Promise<void> {
    const zymbol = await this.project.userWatermark!;

    await this.updateZymbolFromElement(zymbol, watermark);

    const save = await this.commitManager.createSave();
    save(zymbol);
    await this.commitManager.commit(saveOptions?.delayCommitMs ?? 0);
  }

  async getScenes(): Promise<DomainModel.Scene[]> {
    const projectScenes = await this.getProjectScenes(this.project);
    const sortedProjectScenes = projectScenes.filter((ps) => ps.hasZymbolGroups).sort((a, b) => a.order - b.order);

    return Promise.all(
      sortedProjectScenes.map(async (ps) => {
        return this.createScene(ps);
      })
    );
  }

  private createDomainModelAudioClip(ac: AudioClip, asset: DomainModel.AudioAsset): DomainModel.AudioClip {
    const args = {
      id: ac.id,
      category: ac.category,
      duration: ac.duration,
      fadeIn: !!ac.fadeIn,
      fadeOut: !!ac.fadeOut,
      loop: !!ac.loop,
      mute: !!ac.mute,
      offset: ac.startTime,
      trimStart: ac.trimStart,
      audioDuckingLevel: ac.audioDuckingLevel,
      asset,
      volume: ac.volume,
      waveform: [],
      audibleType: ac.audibleType,
      audibleId: ac.audibleId
    };
    return new DomainModel.AudioClip(args);
  }

  public async createScene(ps: ProjectScene): Promise<DomainModel.Scene> {
    const zymbolGroups = await this.getTextZymbolGroups(ps);
    return this.sceneFactory.createScene(ps, zymbolGroups);
  }

  public async createCaption(scene: DomainModel.Scene, zymbolGroup: ZymbolGroup): Promise<DomainModel.Caption> {
    return this.captionFactory.createCaption(scene, zymbolGroup);
  }

  private async updateZymbolFromText(zymbol: Zymbol, text: DomainModel.Text): Promise<void> {
    const category = ZymbolCategory.TEXT;
    const partialConfig = createTextZymbolConfig(text);
    await zymbol.updateConfig(category, partialConfig, false);
  }

  private async updateZymbolFromElement(zymbol: Zymbol, element: DomainModel.Element): Promise<void> {
    if (!Rect.fromRect(zymbol).equals(element.position)) {
      zymbol.setProperties({ ...element.position });
    }

    if (zymbol.layerOrder !== element.layerOrder) {
      zymbol.set("layerOrder", element.layerOrder);
    }

    if (element instanceof DomainModel.Text) {
      await this.updateZymbolFromText(zymbol, element);
    } else if (element instanceof DomainModel.Media) {
      await this.updateZymbolFromMedia(zymbol, element);
    }

    if (zymbol.customTimingOffset !== element.customTimingOffset) {
      zymbol.set("customTimingOffset", element.customTimingOffset);
    }

    if (zymbol.customTimingDuration !== element.customTimingDuration) {
      zymbol.set("customTimingDuration", element.customTimingDuration);
    }
  }

  private async updateZymbolFromMedia(zymbol: Zymbol, media: DomainModel.Media): Promise<void> {
    // eslint-disable-next-line no-null/no-null
    if (zymbol.assetOffset !== (media.assetOffset ?? null)) {
      // eslint-disable-next-line no-null/no-null
      zymbol.set("assetOffset", media.assetOffset ?? null);
    }

    if (zymbol.mediaReplaceable !== media.mediaReplaceable) {
      zymbol.set("mediaReplaceable", !!media.mediaReplaceable);
    }

    await this.updateZymbolFromAsset(zymbol, media.asset);
  }

  private async updateZymbolFromAsset(zymbol: Zymbol, asset: DomainModel.Asset | undefined): Promise<void> {
    const category = this.zymbolConfigFactory.getCategory(asset);
    const partialConfig = this.zymbolConfigFactory.createZymbolConfig(asset);
    await zymbol.updateConfig(category, partialConfig, false);
  }

  private async getProjectScene(sceneId: string): Promise<ProjectScene> {
    return this.store.findRecord("projectScene", sceneId);
  }

  private async getZymbolGroup(zymbolGroupId: string): Promise<ZymbolGroup> {
    return this.store.findRecord("zymbolGroup", zymbolGroupId);
  }

  private async getZymbol(zymbolId: string): Promise<Zymbol> {
    return this.store.findRecord("zymbol", zymbolId);
  }

  private async getAudioClip(audioClipId: string): Promise<AudioClip> {
    return this.store.findRecord("audioClip", audioClipId);
  }

  private async getTextLayer(textLayerId: string): Promise<TextLayer> {
    return this.store.findRecord("textLayer", textLayerId);
  }

  private async getTextZymbolGroups(projectScene: ProjectScene): Promise<ZymbolGroup[]> {
    const id = projectScene.belongsTo("textLayer").id();
    const textLayer = await this.getTextLayer(id);
    return this.getZymbolGroups(textLayer);
  }

  private async getZymbolGroups(layer: Layer): Promise<ZymbolGroup[]> {
    return await Promise.all(
      layer
        .hasMany("zymbolGroups")
        .ids()
        .map((zgi) => this.getZymbolGroup(zgi))
    );
  }

  private async getProjectScenes(project: Project): Promise<ProjectScene[]> {
    return await Promise.all(
      project
        .hasMany("projectScenes")
        .ids()
        .map((psi) => this.getProjectScene(psi))
    );
  }

  async checkForTextOverflow(caption: DomainModel.Caption, context: CanvasRenderingContext2D): Promise<boolean> {
    let textIsOverflowing = false;

    for (const text of caption.texts) {
      const textZymbol = await this.getZymbol(text.id);

      const coords = getCoords(context.canvas, [
        textZymbol.x,
        textZymbol.y,
        textZymbol.width,
        textZymbol.height
      ] as ProportionalCoords);

      const { lines, bounds } = createRenderableLines(context, textZymbol.cfg.text!, coords);
      const textHeight = bounds.height;
      const singleLineHeight = textHeight / lines.length;
      const heightBuffer = singleLineHeight / 4;

      if (textHeight - heightBuffer > coords.height) {
        textIsOverflowing = true;
      }
    }

    return textIsOverflowing;
  }
}
