import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

interface Args {
  project: Project;
}

export default class AppHeaderProjectReturnToEditor extends Component<Args> {
  styleNamespace = getStyleNamespace("app-header/project/return-to-editor");
}
