import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CollapsibleService from "client/services/collapsible";

interface ColorPickerBrandColorsArgs {
  selectColor: (color?: string) => void;
  close: () => void;
}

const DEFAULT_COLORS = ["#0D0D0D", "#005996", "#FF5050", "#FFDA00", "#211DC9"];

export default class ColorPickerBrandedComponent extends Component<ColorPickerBrandColorsArgs> {
  @service
  declare collapsible: CollapsibleService;

  styleNamespace = getStyleNamespace("color-picker-branded/brand-cta");

  @action
  toggleSection(event: MouseEvent): void {
    event.preventDefault();

    this.collapsible.toggleCta();
  }

  @action
  selectColor(color?: string): void {
    this.args.selectColor(color);
  }

  get open(): boolean {
    return this.collapsible.ctaOpen;
  }

  get sampleColors(): string[] {
    return DEFAULT_COLORS;
  }

  get expanded(): string {
    return String(this.collapsible.ctaOpen);
  }
}
