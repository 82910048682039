import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectContentBarService from "client/services/project-content-bar";
import { ContentBarPanelType } from "client/services/project-content-bar";

interface Args {
  eventRegister: EventRegister;
  afterScene: Scene;
}

export default class ScriptWorkstationAddSceneComponent extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  styleNamespace = getStyleNamespace("script-workstation/add-scene");

  @action
  onClick(ev: MouseEvent): void {
    ev.stopPropagation();
    this.projectContentBar.addMediaToNewScene = true;
    this.projectContentBar.afterScene = this.args.afterScene;
    this.projectContentBar.show(ContentBarPanelType.SCENES);
  }
}
