import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NewVideoActionsService from "client/services/new-video-actions";

export default class DashboardEmptyComponent extends Component {
  @service
  declare newVideoActions: NewVideoActionsService;

  styleNamespace = getStyleNamespace("discovery/dashboard/empty");
}
