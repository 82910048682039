import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { VirtualElement } from "@popperjs/core";
import { type RectInfo } from "moveable/declaration/types";
import type { Bounds, Size, ResizeFrom, ScaleFrom } from "renderer-engine";
import {
  calculateUpdatedRelativePosition,
  calculateUpdatedRelativeBounds,
  calculateScaledPosition
} from "renderer-engine";
import { Logo, Image, type Element, type EventRegister } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { transaction } from "client/lib/transaction";
import type Layer from "client/models/zymbols/layer-order";
import type CopyPasteService from "client/services/copy-paste";
import { MouseEventButtons } from "client/services/mouse";

export enum ElementManipulationSources {
  RESIZER = "resizer",
  SCALER = "scaler",
  MOVER = "mover"
}

interface Args {
  element: Element;
  elementBounds: Bounds;
  canvasBounds: Size;
  eventRegister: EventRegister;
  selected: boolean;
  layer?: Layer;
  canDuplicate?: boolean;
  hasRemovableBackground?: boolean;
  isBackground?: boolean;

  onRotate: (rotation: number) => void;
  onRotateEnd: () => void;
  onDrag?: (position: Bounds) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onResize?: (source: ElementManipulationSources, position: Bounds) => void;
  onResizeStart?: () => void;
  onResizeEnd?: () => void;
  onScale?: (source: ElementManipulationSources, position: Bounds) => void;
  onScaleStart?: () => void;
  onScaleEnd?: () => void;
  onRemove?: () => void;
  onDuplicate?: () => void;
  onCopy?: (e: Event, origin: string) => Promise<void>;
  onPaste?: (e: Event, origin: string) => Promise<void>;
  onMoveToBackground?: () => void;
  onSwapWithBackground?: () => void;
  onResetAspectRatio?: () => void;
  onFitToClip?: () => void;
  onEditTiming?: () => void;
}

export default class CanvasBoundingBoxComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("canvas/bounding-box");

  @service
  declare copyPaste: CopyPasteService;

  @tracked
  boundingBoxElement?: HTMLElement;

  @tracked
  moveableRect?: RectInfo;

  @tracked
  latestDragPositionX = 0;

  @tracked
  latestDragPositionY = 0;

  @tracked
  latestResizePositionX = 0;

  @tracked
  latestResizePositionY = 0;

  @tracked
  latestScalePositionX = 0;

  @tracked
  latestScalePositionY = 0;

  @tracked
  isRotating = false;

  @tracked
  isRightClickMenuOpen = false;

  @tracked
  copyPasteOrigin = "right-click-menu";

  @tracked
  virtualElement?: VirtualElement;

  @action
  didInsert(htmlElement: HTMLElement): void {
    this.boundingBoxElement = htmlElement;
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    this.boundingBoxElement?.removeEventListener("contextmenu", this.contextMenu);
  }

  @action
  mouseDown(ev: MouseEvent): void {
    if (ev.button === MouseEventButtons.SECONDARY) {
      this.boundingBoxElement?.addEventListener("contextmenu", this.contextMenu);
      this.isRightClickMenuOpen = true;

      return;
    }
  }

  @action
  onRotateStart(): void {
    this.isRotating = true;
  }

  @action
  onRotate(rotation: number, moveableRect: RectInfo): void {
    this.moveableRect = moveableRect;

    if (this.args.onRotate) {
      this.args.onRotate(rotation);
    }
  }

  @action
  onRotateEnd(): void {
    this.isRotating = false;

    if (this.args.onRotateEnd) {
      this.args.onRotateEnd();
    }
  }

  @action
  onDragStart(clientX: number, clientY: number): void {
    this.latestDragPositionX = clientX;
    this.latestDragPositionY = clientY;

    if (this.args.onDragStart) {
      this.args.onDragStart();
    }
  }

  @action
  onDrag(clientX: number, clientY: number): void {
    const xMouseDelta = clientX - this.latestDragPositionX;
    const yMouseDelta = clientY - this.latestDragPositionY;

    const updatedPosition = calculateUpdatedRelativePosition(
      this.args.canvasBounds,
      this.args.elementBounds,
      xMouseDelta,
      yMouseDelta
    );

    if (this.args.onDrag) {
      this.args.onDrag(updatedPosition);
    }

    this.latestDragPositionX = clientX;
    this.latestDragPositionY = clientY;
  }

  @action
  onResizeStart(clientX: number, clientY: number): void {
    this.latestResizePositionX = clientX;
    this.latestResizePositionY = clientY;

    if (this.args.onResizeStart) {
      this.args.onResizeStart();
    }
  }

  @action
  onResize(clientX: number, clientY: number, direction: ResizeFrom): void {
    const xMouseDelta = clientX - this.latestResizePositionX;
    const yMouseDelta = clientY - this.latestResizePositionY;

    if (xMouseDelta === 0 && yMouseDelta === 0) {
      return;
    }

    const updatedBounds = calculateUpdatedRelativeBounds(
      direction,
      this.args.canvasBounds,
      this.args.elementBounds,
      xMouseDelta,
      yMouseDelta
    );

    if (this.args.onResize) {
      this.args.onResize(ElementManipulationSources.RESIZER, updatedBounds);
    }

    this.latestResizePositionX = clientX;
    this.latestResizePositionY = clientY;
  }

  @action
  onScaleStart(clientX: number, clientY: number): void {
    this.latestScalePositionX = clientX;
    this.latestScalePositionY = clientY;

    if (this.args.onScaleStart) {
      this.args.onScaleStart();
    }
  }

  @action
  onScale(clientX: number, clientY: number, direction: ScaleFrom): void {
    const xMouseDelta = clientX - this.latestScalePositionX;
    const yMouseDelta = clientY - this.latestScalePositionY;

    if (xMouseDelta === 0 && yMouseDelta === 0) {
      return;
    }

    const updatedBounds = calculateScaledPosition(
      direction,
      this.args.canvasBounds,
      this.args.elementBounds,
      xMouseDelta,
      yMouseDelta
    );

    if (this.args.onScale) {
      this.args.onScale(ElementManipulationSources.SCALER, updatedBounds);
    }

    this.latestScalePositionX = clientX;
    this.latestScalePositionY = clientY;
  }

  @action
  async onCopy(e: Event): Promise<void> {
    if (this.args.onCopy) {
      await this.args.onCopy(e, this.copyPasteOrigin);
      this.copyPasteOrigin = "right-click-menu";
    }
  }

  @action
  async onPaste(e: Event): Promise<void> {
    if (this.args.onPaste && !this.pasteDisabled) {
      await this.args.onPaste(e, this.copyPasteOrigin);
      this.copyPasteOrigin = "right-click-menu";
    }
  }

  @action
  @transaction
  async onMoveLayerForward(): Promise<void> {
    await this.args.layer?.moveLayerForward();
  }

  @action
  @transaction
  async onMoveLayerBackward(): Promise<void> {
    await this.args.layer?.moveLayerBackward();
  }

  @action
  @transaction
  async onMoveLayerToFront(): Promise<void> {
    await this.args.layer?.moveLayerToFront();
  }

  @action
  @transaction
  async onMoveLayerToBack(): Promise<void> {
    await this.args.layer?.moveLayerToBack();
  }

  @action
  openRightClickMenu(e: PointerEvent): void {
    this.isRightClickMenuOpen = true;
    this.contextMenu(e);
  }

  @action
  contextMenu(e: MouseEvent): void {
    e.preventDefault();

    const { clientX: x, clientY: y } = e;
    const virtualElement = {
      getBoundingClientRect: (): any => {
        return {
          width: 0,
          height: 0,
          top: y,
          right: x,
          bottom: y,
          left: x
        };
      }
    };

    this.virtualElement = virtualElement;
  }

  @action
  closeMenu(): void {
    this.isRightClickMenuOpen = false;
  }

  get highestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.highestLayer();
    }
    return false;
  }

  get lowestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.lowestLayer();
    }
    return false;
  }

  get moveableBoundingBox(): RectInfo | undefined {
    return this.moveableRect;
  }

  get pasteDisabled(): boolean {
    return !this.copyPaste.copiedObject || !this.copyPaste.allowCopyPaste;
  }

  get showActionButtons(): boolean {
    return this.args.selected && !this.isRotating;
  }

  get ariaSelected(): string {
    return this.args.selected ? "true" : "false";
  }

  get styleAttr(): SafeString {
    if (this.args.element instanceof Logo && this.args.element.asset instanceof Image) {
      return htmlSafe(`
        transform: rotate(${this.args.element.asset.rotation}deg);
      `);
    }

    return htmlSafe("");
  }
}
