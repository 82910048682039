import { Axis } from "../grid/axis";
import type { Line } from "../grid/line";
import type { Rect } from "../math";
import type { Vector2 } from "../math/vector2";

export class Guides {
  public static getIntersections(rects: Rect[], snapLines: Line[]): Vector2[] {
    const intersections: Vector2[] = [];
    for (const rect of rects) {
      for (const point of rect.intersectionPoints) {
        for (const snapLine of snapLines) {
          if (
            (snapLine.axis === Axis.VERTICAL && point.x === snapLine.x) ||
            (snapLine.axis === Axis.HORIZONTAL && point.y === snapLine.y)
          ) {
            intersections.push(point);
            break;
          }
        }
      }
    }

    return intersections;
  }

  public static getGuideLines(lines: Line[], snapLines: Line[]): Line[] {
    const guideLines: Line[] = [];
    for (const line of lines) {
      for (const snapLine of snapLines) {
        if (this.inRange(line, snapLine)) {
          guideLines.push(snapLine);
          break;
        }
      }
    }

    return guideLines;
  }

  public static inRange(line: Line, snapLine: Line): Line | undefined {
    const snapVector = snapLine.subtract(line);
    let snap;

    if (snapLine.isHorizontal && line.isHorizontal) {
      snap = snapVector.y;
    } else if (snapLine.isVertical && line.isVertical) {
      snap = snapVector.x;
    }

    if (snap === 0) {
      return line;
    }

    return undefined;
  }
}
