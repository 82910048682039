import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Text } from "client/lib/editor-domain-model";
import { Alignments } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { EditableText } from "client/lib/text/text-editor";

interface Args {
  media: Text;
  onClose: () => void;
  editables: Map<string, EditableText>;
}

export default class ToolbarFontBoldComponent extends Component<Args> {
  @tracked
  targetElement?: HTMLElement;

  styleNamespace = getStyleNamespace("toolbar/font/alignment");

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  get xAlignment(): Alignments {
    return this.editableText?.textStyle.alignment ?? Alignments.LEFT;
  }

  get xCenterAligned(): boolean {
    return this.xAlignment === Alignments.CENTER;
  }

  get xRightAligned(): boolean {
    return this.xAlignment === Alignments.RIGHT;
  }

  get xLeftAligned(): boolean {
    return this.xAlignment === Alignments.LEFT;
  }

  get yAlignment(): Alignments {
    return this.editableText?.textStyle.yAlignment ?? Alignments.TOP;
  }

  @action
  async setTextAlignment(axis: "x" | "y", alignment: Alignments): Promise<void> {
    if (axis === "x") {
      this.editableText?.applyStyle({
        alignment: alignment
      });
    } else if (axis === "y") {
      this.editableText?.applyStyle({
        yAlignment: alignment
      });
    }
  }

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.media.id);
  }
}
