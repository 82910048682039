import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectScenesRouteModel } from "client/authenticated/project/scenes/route";
import type ProjectScenesRoute from "client/authenticated/project/scenes/route";
import type ProjectSceneStack from "client/models/project-scene-stack";

export interface ProjectScenesCategoryRouteParams {
  categorySlug: string;
}

export interface ProjectScenesCategoryRouteModel extends ProjectScenesRouteModel {
  stacks: Array<ProjectSceneStack>;
}

export default class ProjectScenesCategoryRoute extends Route {
  @service
  declare store: Store;

  async model({ categorySlug }: ProjectScenesCategoryRouteParams): Promise<ProjectScenesCategoryRouteModel> {
    const model = this.modelFor<ProjectScenesRoute>("authenticated.project.scenes");

    const stacks = await this.store.query("projectSceneStack", {
      category_slug: categorySlug // eslint-disable-line camelcase
    });

    return {
      ...model,
      stacks
    };
  }
}
