import { isDevEnv } from "client/lib/env";

export default function debug(...args: any[]): void {
  if (isDevEnv()) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-console
    console.log(args[0], ...args.slice(1));
  }
}

export function whoami(context: any): void {
  if (isDevEnv()) {
    // eslint-disable-next-line no-console
    console.log(context.constructor?.name, context);
  }
}
