import Service, { service } from "@ember/service";
import type AuthService from "./auth";
import type Subscription from "client/models/subscription";
import type SessionService from "client/services/session";

export default class SubscriptionsService extends Service {
  @service
  private declare auth: AuthService;

  @service
  private declare session: SessionService;

  get hasTrialed(): boolean {
    return !!this.auth.latestTrial;
  }

  get isTrialing(): boolean {
    return !!this.auth.currentSubscription?.trialing;
  }

  get isNewlyTrialing(): boolean {
    const trialStart = this.auth.currentSubscription?.trialStart;

    if (!trialStart) {
      return false;
    }

    const THREE_DAYS = 3 * 24 * 60 * 60 * 1000;
    const trialAge = Date.now() - trialStart?.getTime();

    return this.isTrialing && trialAge < THREE_DAYS;
  }

  get canManage(): boolean {
    return this.auth.canManageSubscription;
  }

  get hasActiveSubscription(): boolean {
    return !!this.auth.currentFullSubscription;
  }

  get currentSubscription(): Subscription | undefined {
    return this.auth.currentSubscription;
  }

  get currentFullSubscription(): Subscription | undefined {
    return this.auth.currentFullSubscription;
  }
}
