import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum Icons {
  MUSIC = "music",
  LAYOUT = "layout",
  DUPLICATE = "duplicate",
  DELETE = "delete",
  TEXT = "text",
  SAG = "sag",
  BLANK = "blank",
  COLOR = "color",
  IMAGE = "image",
  VIDEO = "video",
  ADD = "add",
  INVERT_X = "invertX",
  MUTED = "muted",
  PAUSE = "pause",
  PAUSE_CIRCLE = "pauseCircle",
  PLAY = "play",
  PLAY_CIRCLE = "playCircle",
  SUCCESS = "success",
  REMOVE = "remove",
  SEARCH = "search",
  EDIT = "edit",
  VIDEO_TIMING = "videoTiming",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  TWITTER = "twitter",
  YOUTUBE = "youtube",
  ACTION_AFFIRMATIVE = "actionAffirmative",
  ACTION_NEGATIVE = "actionNegative",
  PADLOCK = "padlock",
  MENU = "menu",
  PLUS = "plus",
  IBAR = "ibar",
  HIGHLIGHT = "highlight",
  PERSON = "person",
  EMAIL = "email",
  STOP = "stop"
}

interface UIIconArgs {
  type?: Icons;
  icon?: Icons;
}

export default class UIIconComponent extends Component<UIIconArgs> {
  styleNamespace = getStyleNamespace("ui/ui-icon");

  get iconName(): Icons | undefined {
    return this.args.icon ?? this.args.type;
  }
}
