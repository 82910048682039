import Controller from "@ember/controller";
import type { HomeIndexModel } from "./route";

export default class DashboardHomeIndexController extends Controller<HomeIndexModel> {
  get hasMoreProjects(): boolean {
    if (this.model.projects.meta) {
      const { "total-count": totalCount, page, "per-page": perPage } = this.model.projects.meta;
      return totalCount > perPage * page;
    }
    return false;
  }
}
