import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CollapsibleService from "client/services/collapsible";

interface ColorPickerProjectArgs {
  projectColors: string[];
  colorPalette: (string | undefined)[];
  selectColor: (color?: string) => void;
}

export default class ColorPickerProjectComponent extends Component<ColorPickerProjectArgs> {
  @service
  declare collapsible: CollapsibleService;

  styleNamespace = getStyleNamespace("color-picker-branded/project-colors");

  @action
  toggleSection(event: MouseEvent): void {
    event.preventDefault();

    this.collapsible.toggleProject();
  }

  @action
  selectColor(color?: string): void {
    this.args.selectColor(color);
  }

  get expanded(): string {
    return String(this.collapsible.projectColor);
  }

  get projectColors(): string[] {
    const projectColorsWithoutBrand = this.args.projectColors.filter((x) => !this.args.colorPalette?.includes(x));

    return projectColorsWithoutBrand.sort();
  }
}
