import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type TemplatesIndexController from "../index/controller";
import type { TemplatesIndexModel } from "../index/route";
import TemplatesIndexRoute, { loadProjectTemplates } from "../index/route";
import type TemplatesController from "client/authenticated/templates/controller";
import type Category from "client/models/category";
import type ProjectTemplate from "client/models/project-template";

interface TemplatesCategoryParams {
  category: string;
}

interface TemplatesCategoryModel extends TemplatesIndexModel {
  category: Category;
}

export default class TemplatesCategoryRoute extends TemplatesIndexRoute {
  templateName = "authenticated.templates.index";

  @service
  declare store: Store;

  async beforeModel(): Promise<void> {
    // Prevent the search param while in a category
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor<TemplatesController>("authenticated.templates").search = "";
  }

  async model({ category: categorySlug }: TemplatesCategoryParams): Promise<TemplatesCategoryModel> {
    const [projectTemplates, category] = await Promise.all([
      this.loadCategoryTemplates(categorySlug),
      this.loadCategory(categorySlug)
    ]);

    return {
      category,
      projectTemplates
    };
  }

  setupController(controller: TemplatesIndexController, model: TemplatesCategoryModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    controller.category = model.category.slug;
  }

  private loadCategory(slug: string): Promise<Category> {
    return this.store.findRecord("category", slug);
  }

  private async loadCategoryTemplates(categorySlug: string): Promise<Array<ProjectTemplate>> {
    // @ts-expect-error
    return loadProjectTemplates(this.store, {
      ...this.getTemplateParams(),
      category: categorySlug
    }) as Array<ProjectTemplate>;
  }
}
