import { tracked } from "@glimmer/tracking";
import type { Frame } from "../scene/image/frame";

export interface OriginalAsset {
  id: string;
  type: string;
}

export interface License {
  licensor: string;
  id: string;
  metadata?: { [key: string]: string | number | undefined };
}

export abstract class Asset {
  /** @internal */
  @tracked
  _mirror: boolean;

  /** @internal */
  @tracked
  _objectFit: string;

  /** @internal */
  @tracked
  _originalAsset?: OriginalAsset;

  /** @internal */
  @tracked
  _license?: License;

  /** @internal */
  @tracked
  _frame?: Frame;

  /** @internal */
  @tracked
  _rotation: number;

  constructor(
    mirror: boolean,
    objectFit: string,
    originalAsset?: OriginalAsset,
    license?: License,
    frame?: Frame,
    rotation?: number
  ) {
    this._mirror = mirror;
    this._objectFit = objectFit;
    this._originalAsset = originalAsset;
    this._license = license;
    this._frame = frame;
    this._rotation = rotation ?? 0;
  }

  abstract get hasContent(): boolean;

  abstract get category(): string;

  abstract get formattedCategory(): string;

  abstract get previewImageUrl(): string | undefined;

  get mirror(): boolean {
    return this._mirror;
  }

  get objectFit(): string {
    return this._objectFit;
  }

  get originalAsset(): OriginalAsset | undefined {
    return this._originalAsset;
  }

  get license(): License | undefined {
    return this._license;
  }

  get colors(): string[] {
    return [];
  }

  get frame(): Frame | undefined {
    return this._frame;
  }

  get rotation(): number {
    return this._rotation;
  }

  _reset(): void {
    this._originalAsset = undefined;
    this._license = undefined;
  }
}
