import { modifier } from "ember-modifier";

export default modifier((element: HTMLElement, [aspectRatio]: string[]) => {
  if (aspectRatio) {
    const [width, height] = aspectRatio.split(":").map((v) => parseFloat(v));
    const value = width && height ? `${width}/${height}` : "auto";

    element.style.setProperty("aspect-ratio", value);
  }
});
