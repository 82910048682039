import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type UserPreference from "client/models/user-preference";
import type CurrentUserService from "client/services/current-user";

export default class UserPreferenceService extends Service {
  @service
  private declare store: Store;

  @service
  private declare currentUser: CurrentUserService;

  @tracked
  preference?: UserPreference;

  public async setValue<K extends keyof UserPreference>(key: K, value: UserPreference[K]): Promise<void> {
    this.preference ??= await this.getPreference();

    if (this.preference) {
      this.preference.set(key, value);

      await this.preference.save();
    }
  }

  public async load(): Promise<UserPreference | undefined> {
    this.preference ??= await this.getPreference();

    return this.preference;
  }

  private async getPreference(): Promise<UserPreference | undefined> {
    try {
      if (this.currentUser.user) {
        return await this.store.queryRecord("userPreference", {});
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }
}
