import { action } from "@ember/object";
import { TrackedBounds } from "./tracked-bounds";

export class Follower {
  private follower: TrackedBounds;
  private followerParent: TrackedBounds;
  private leader: TrackedBounds;
  public top = NaN;

  constructor(private callback?: () => void, root?: HTMLElement, follower?: HTMLElement, leader?: HTMLElement) {
    this.follower = new TrackedBounds(this.didCallback, root, follower);
    this.followerParent = new TrackedBounds(this.didCallback, root, follower?.parentElement);
    this.leader = new TrackedBounds(this.didCallback, root, leader);
  }

  disconnect(): void {
    this.follower.disconnect();
    this.followerParent.disconnect();
    this.leader.disconnect();
    this.top = NaN;
  }

  @action
  didCallback(): void {
    this.top = this.calculateTop();
    this.callback?.();
  }

  private calculateTop(): number {
    if (!this.follower.rect || !this.followerParent.rect) {
      return NaN;
    }

    if (!this.leader.rect || !this.leader.rect.height) {
      return (this.followerParent.rect.height - this.follower.rect.height) / 2;
    }

    return this.leader.rect.top - this.followerParent.rect.top;
  }
}
