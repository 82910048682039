import Serializer from "@ember-data/serializer";
import type Store from "@ember-data/store";
import type { ModelSchema } from "ember-data"; // eslint-disable-line ember/use-ember-data-rfc-395-imports
import UnsplashPhoto from "../models/unsplash-photo";

export interface UnsplashPhotoQueryPayload {
  total: number;
  total_pages: number;
  results: UnsplashPhotoPayload[];
}

export interface UnsplashUserPayload {
  id: string;
  username: string;
  name: string;
  first_name: string;
  last_name: string;
  instagram_username: string;
  twitter_username: string;
  portfolio_url: string;
  links: {
    self: string;
    html: string;
    photos: string;
    likes: string;
  };
  profile_image: {
    small: string;
    medium: string;
    large: string;
  };
}

export interface UnsplashPhotoPayload {
  id: number;
  description: string;
  created_at: string;
  likes: number;
  width: number;
  height: number;
  color: string;
  urls: {
    raw: string;
    full: string;
    regular: string;
    small: string;
    thumb: string;
  };
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
  user: UnsplashUserPayload;
}

enum RequestTypes {
  QUERY = "query",
  FIND_RECORD = "findRecord",
  FIND_ALL = "findAll"
}

export default class UnsplashPhotoSerializer extends Serializer {
  normalize(_: ModelSchema, { id, urls, width, height, user, links, description, color }: UnsplashPhotoPayload): any {
    return {
      data: {
        id,
        type: UnsplashPhoto.modelName,
        attributes: {
          description,
          color,
          width,
          height,
          imageThumbUrl: urls.thumb,
          imageSmallUrl: urls.small,
          imageRegularUrl: urls.regular,
          imageFullUrl: urls.full,
          imageRawUrl: urls.raw,
          authorFirstName: user.first_name,
          authorLastName: user.last_name,
          downloadUrl: links.download_location,
          attribution: {
            author: `${user.first_name} ${user.last_name}`,
            authorUrl: user.links.html,
            provider: "Unsplash"
          }
        }
      }
    };
  }

  normalizeResponse(
    _: Store,
    model: ModelSchema,
    payload: UnsplashPhotoQueryPayload | UnsplashPhotoPayload | UnsplashPhotoPayload[],
    __: string | number,
    requestType: string
  ): any {
    if (requestType === RequestTypes.FIND_RECORD) {
      return this.normalize(model, payload as UnsplashPhotoPayload);
    }

    if (requestType === RequestTypes.FIND_ALL) {
      const data = (payload as UnsplashPhotoPayload[]).map((item) => this.normalize(model, item).data);

      return { data, included: [] };
    }

    if (requestType === RequestTypes.QUERY) {
      if (payload instanceof Array) {
        const data = payload.map((item) => this.normalize(model, item).data);

        return { data, included: [] };
      }

      const meta = {
        "total-count": (payload as UnsplashPhotoQueryPayload).total
      };

      const data = (payload as UnsplashPhotoQueryPayload).results.map((item) => this.normalize(model, item).data);

      return { data, included: [], meta };
    }
  }
}
