import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type { AccountSubscriptionRouteModel } from "client/authenticated/account/subscription/route";
import type Plan from "client/models/plan";
import type PlansService from "client/services/plans";

export default class AccountSubscriptionCancelIndexRoute extends Route {
  @service
  declare plans: PlansService;

  @service
  declare router: RouterService;

  beforeModel(): void {
    const { fullSubscription } = this.modelFor("authenticated.account.subscription") as AccountSubscriptionRouteModel;

    if (this.showCancellationWarning(fullSubscription?.plan)) {
      this.redirectToCancellationWarning();
    } else {
      this.redirectToCancellationConfirmation();
    }
  }

  private showCancellationWarning(plan: Plan | undefined): boolean {
    if (!plan) {
      return false;
    }

    const correspondingPlan = this.plans.getCorrespondingPlan(plan);

    if (!correspondingPlan) {
      return !plan.offered;
    }

    return correspondingPlan.monetaryUnits > plan.monetaryUnits;
  }

  private redirectToCancellationWarning(): void {
    void this.router.replaceWith("authenticated.account.subscription.cancel.warning");
  }

  private redirectToCancellationConfirmation(): void {
    void this.router.replaceWith("authenticated.account.subscription.cancel.confirmation");
  }
}
