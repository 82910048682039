import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

interface Options {
  upgrade?: boolean;
  position: string;
  keyboardShortcut?: string;
}

export default class TooltipService extends Service {
  @tracked
  target?: HTMLElement;

  @tracked
  message?: string;

  @tracked
  position = "top";

  @tracked
  upgrade = false;

  @tracked
  keyboardShortcut?: string;

  get visible(): boolean {
    return !!this.target && !!this.message;
  }

  show(target: HTMLElement, message: string, options?: Options): void {
    // If the element has no parent it's not in the DOM
    if (!target.parentElement) {
      return;
    }

    this.target = target;
    this.message = message;
    this.position = options?.position ?? "top";
    this.upgrade = options?.upgrade ?? false;
    this.keyboardShortcut = options?.keyboardShortcut;
  }

  hide(): void {
    this.target = undefined;
    this.message = undefined;
    this.position = "top";
    this.upgrade = false;
  }
}
