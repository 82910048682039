import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";

interface FolderPreviewComponentArgs {
  folder: Folder;
  content: FolderContent;
  name: string;
  parentRouteName?: string;
  setFolder: (folder: Folder) => void;
  refresh: () => void;
  size?: string;
}

export default class FolderPreviewComponent extends Component<FolderPreviewComponentArgs> {
  icon?: HTMLElement;

  styleNamespace = getStyleNamespace("folders/folder-preview");

  @action
  setIconElement(element: HTMLElement): void {
    this.icon = element;
  }
}
