import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { DEFAULT_SNAP_AMOUNT } from "../../resizer/component";
import type { TimelineMutation, Caption, EventRegister, Scene } from "client/lib/editor-domain-model";
import { CaptionOffsetMutationFactory } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type MouseService from "client/services/mouse";
import { MouseEvents } from "client/services/mouse";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

interface Args {
  caption: Caption;
  scene: Scene;
  pixelsPerSecond: number;
  isSelected: boolean;
  mutate: (mutation: TimelineMutation) => void;
  save: (caption: Caption) => void;
  onSelect?: (caption: Caption) => void;
  onModify?: (enabled?: boolean) => void;
  eventRegister: EventRegister;
}

export default class ProjectTimelineSceneCaptionComponent extends Component<Args> {
  @service
  declare mouse: MouseService;

  @service
  declare timelineEvents: TimelineEventsService;

  _element?: HTMLElement;

  factory?: CaptionOffsetMutationFactory;

  @tracked
  private _isResizing = false;

  @tracked
  private _isDragging = false;

  @tracked
  draggedOffset?: number;

  originalOffset!: number;
  isSubscribedToDragEvents = false;

  styleNamespace = getStyleNamespace("project-timeline/scene/caption");

  constructor(owner: object, args: Args) {
    super(owner, args);

    this.timelineEvents.subscribe(TimelineEvents.ACTIVE_CAPTION_CHANGED, this.onActiveCaptionChanged);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;

    const { caption, isSelected } = this.args;
    if (isSelected) {
      this.onActiveCaptionChanged({ id: caption.id });
    }
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    try {
      this.timelineEvents.unsubscribe(TimelineEvents.ACTIVE_CAPTION_CHANGED, this.onActiveCaptionChanged);
    } catch (err) {
      // Ignore.
    }

    this.unsubscribeFromDragEvents();
  }

  @action
  subscribeToDragEvents(): void {
    if (this.isModifying) {
      return;
    }

    this.mouse.subscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
    this.mouse.subscribe(MouseEvents.DRAG, this.onMouseDrag);
    this.mouse.subscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
    this.isSubscribedToDragEvents = true;
  }

  @action
  unsubscribeFromDragEvents(): void {
    if (!this.isSubscribedToDragEvents) {
      return;
    }

    this.mouse.unsubscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
    this.mouse.unsubscribe(MouseEvents.DRAG, this.onMouseDrag);
    this.mouse.unsubscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
    this.isSubscribedToDragEvents = false;
  }

  @action
  onMouseUp(event: MouseEvent): void {
    this.mouse.onMouseUp(event);
    this.unsubscribeFromDragEvents();
  }

  @action
  onMouseDragStart(): void {
    if (!this._element) {
      return;
    }

    this.isDragging = true;

    this.factory = new CaptionOffsetMutationFactory(this.args.caption);
    this.originalOffset = this.args.caption.offset;
  }

  @action
  onMouseDrag(event: MouseEvent): void {
    this.doDrag(event);
  }

  @action
  doDrag(event: MouseEvent): void {
    if (!this.mouse.mouseDownX || !this.factory) {
      return;
    }

    const mouseDelta = event.pageX - this.mouse.mouseDownX;

    this.draggedOffset = this.originalOffset * this.args.pixelsPerSecond + mouseDelta;
    this.args.mutate(this.factory.createMutation(this.originalOffset + mouseDelta / this.args.pixelsPerSecond));
  }

  @action
  async onMouseDragEnd(): Promise<void> {
    this.unsubscribeFromDragEvents();

    if (this.factory) {
      this.args.mutate(this.factory.createMutation(this.args.caption.offset, DEFAULT_SNAP_AMOUNT));
      await this.args.save(this.args.caption);
    }

    next(this, () => {
      this.isDragging = false;
    });
  }

  get isResizing(): boolean {
    return this._isResizing;
  }

  set resizeDirection(direction: string | undefined) {
    this._isResizing = !!direction;
    this.mouse.resizeDirection = direction;

    if (this.args.onModify) {
      this.args.onModify(!!direction);
    }
  }

  get isDragging(): boolean {
    return this._isDragging;
  }

  set isDragging(isDragging: boolean) {
    this._isDragging = isDragging;
    this.mouse.isDragging = isDragging;

    if (this.args.onModify) {
      this.args.onModify(isDragging);
    }
  }

  get isModifying(): boolean {
    return this.isResizing || this.isDragging;
  }

  @action
  onActiveCaptionChanged({ id }: { id: string }): void {
    if (this.isDestroyed || this.isDestroying || id !== this.args.caption.id) {
      return;
    }

    this.makeVisible();
  }

  makeVisible(): void {
    if (!this._element) {
      return;
    }

    this._element.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth"
    });
  }

  @action
  onResize(direction?: string): void {
    this.resizeDirection = direction;
  }

  @action
  select(event: Event): void {
    if (this.isModifying) {
      return;
    }

    event.stopPropagation();
    const { onSelect } = this.args;
    if (onSelect) {
      void onSelect(this.args.caption);
    }
  }

  get hasLogo(): boolean {
    return !!this.args.caption.logos.length;
  }

  get pixelWidth(): number {
    return this.args.caption.duration * this.args.pixelsPerSecond - 4;
  }

  get pixelOffset(): number {
    return this.args.caption.offset * this.args.pixelsPerSecond;
  }

  get inlineStyle(): SafeString {
    let styles = `width: ${this.pixelWidth}px; left: ${this.pixelOffset}px;`;

    if (this.isDragging) {
      styles = styles.concat(`left: ${this.draggedOffset ?? 0}px;`);
    }

    return htmlSafe(styles);
  }

  get silhouetteStyle(): SafeString {
    return htmlSafe(`width: ${this.pixelWidth}px; left: ${this.pixelOffset}px;`);
  }
}
