import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectRouteModel } from "../route";
import type ProjectRoute from "../route";
import type ScenesController from "./controller";
import type { Scene } from "client/lib/editor-domain-model";
import type ProjectSceneStackCategory from "client/models/project-scene-stack-category";
import type AjaxService from "client/services/ajax";

export interface ProjectScenesRouteModel extends ProjectRouteModel {
  scene?: Scene;
  afterScene?: Scene;
  categories: ProjectSceneStackCategory[];
}

export default class ProjectScenesRoute extends Route<ProjectScenesRouteModel> {
  routeOnCancel = "authenticated.project";

  @service
  declare store: Store;

  @service
  declare ajax: AjaxService;

  async model({
    scene: sceneId,
    afterScene: afterSceneId
  }: {
    scene?: string;
    afterScene?: string;
  }): Promise<ProjectScenesRouteModel> {
    const model = this.modelFor<ProjectRoute>("authenticated.project");
    const categories = await this.getSceneStackCategories();
    const scene = sceneId ? model.timeline.getSceneById(sceneId) : undefined;
    const afterScene = afterSceneId ? model.timeline.getSceneById(afterSceneId) : undefined;

    return {
      ...model,
      scene,
      afterScene,
      categories
    };
  }

  resetController(controller: ScenesController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.resetTransientState();
    }
  }

  private async getSceneStackCategories(): Promise<ProjectSceneStackCategory[]> {
    // @ts-expect-error
    return (await this.store.query("projectSceneStackCategory", {
      published_only: true // eslint-disable-line camelcase
    })) as ProjectSceneStackCategory[];
  }

  queryParams = {
    afterScene: {
      refreshModel: true
    },
    scene: {
      refreshModel: true
    }
  };
}
