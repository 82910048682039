import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Crisp } from "crisp-sdk-web";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  menuTarget: HTMLElement;
}

export default class AppHeaderSupportSupportMenuComponent extends Component<Args> {
  @tracked
  open = false;

  @tracked
  showBadge = Crisp.chat.unreadCount() > 0;

  styleNamespace = getStyleNamespace("app-header/support/support-menu");

  @action
  showMessenger(): void {
    Crisp.chat.show();
    Crisp.chat.open();
  }

  @action
  toggleMenu(): void {
    this.open = !this.open;
  }

  @action
  closeMenu(): void {
    this.open = false;
  }
}
