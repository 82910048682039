import { service } from "@ember/service";
import type AjaxService from "client/services/ajax";
import Torii from "ember-simple-auth/authenticators/torii";

export default class ToriiAuthenticator extends Torii {
  @service
  declare ajax: AjaxService;

  @service
  declare torii: any;

  async authenticate(...args: any[]): Promise<any> {
    const { provider, authorizationCode: code } = await super.authenticate(args);

    return {
      provider,
      ...(await this.ajax.api(`/auth/${provider}/callback`, {
        method: "POST",
        body: new URLSearchParams({ provider, code })
      }))
    };
  }
}
