import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class DashboardNavItem extends Component {
  @tracked
  open = false;

  @tracked
  menuTarget!: HTMLElement;

  styleNamespace = getStyleNamespace("discovery/dashboard/nav/new-video");

  @action
  toggle(): void {
    this.open = !this.open;
  }

  @action
  close(): void {
    this.open = false;
  }

  @action
  setMenuTarget(element: HTMLElement): void {
    this.menuTarget = element;
  }
}
