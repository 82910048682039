export class Lazy<T> {
  protected value?: T;

  constructor(private factory: () => T) {}

  get(): T {
    if (!this.value) {
      this.value = this.factory();
    }
    return this.value;
  }

  getOptional(): T | undefined {
    return this.value;
  }

  clear(): void {
    if (this.value) {
      this.value = undefined;
    }
  }
}
