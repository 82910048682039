import { action } from "@ember/object";
import type { RouteModel } from "@ember/routing/route";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TabLinkArgs {
  route?: string;
  model?: RouteModel;
  query?: object;
  onClick?: (event: MouseEvent) => void;
}

export default class TabLinkComponent extends Component<TabLinkArgs> {
  styleNamespace = getStyleNamespace("north-star/tab-link");

  @action
  handleClick(ev: MouseEvent): void {
    this.args.onClick?.(ev);
  }
}
