import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type User from "client/models/user";
import type ValidatedCoupon from "client/models/validated-coupon";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

interface ChangePlanArgs {
  newPlan: Plan;
  oldPlan: Plan;
  couponCode?: string;
  showCouponField?: boolean;
  onPurchase(subscription: Subscription): unknown;
}

export default class ChangePlanComponent extends Component<ChangePlanArgs> {
  styleNamespace = getStyleNamespace("purchase/change-plan");

  @tracked
  error?: Error;

  @tracked
  loading = false;

  @tracked
  coupon?: ValidatedCoupon;

  @tracked
  oldPlanCoupon?: ValidatedCoupon;

  @tracked
  newPlanPreviousCoupon?: ValidatedCoupon;

  @tracked
  newPlanCoupon?: ValidatedCoupon;

  @tracked
  validatingCoupon = false;

  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare store: Store;

  @service
  declare upgrade: UpgradeService;

  @service
  declare plans: PlansService;

  @action
  async didInsert(): Promise<void> {
    this.newPlanPreviousCoupon = await this.getPreviousCoupon(this.args.newPlan.id);
    this.newPlanCoupon = this.coupon || this.newPlanPreviousCoupon;

    this.oldPlanCoupon = await this.getPreviousCoupon(this.args.oldPlan.id);
  }

  @action
  async didUpdateCoupon(): Promise<void> {
    this.newPlanCoupon = this.coupon || this.newPlanPreviousCoupon;
  }

  @action
  async onUpdateSubscription(): Promise<void> {
    this.loading = true;

    try {
      let subscription;

      if (this.shouldSchedule) {
        const subscriptionSchedule = await this.scheduleSubscription();
        subscription = await subscriptionSchedule.subscription;
      } else if (this.purchasingTrialedPlan) {
        subscription = await this.purchaseTrialPlan();
      } else {
        subscription = await this.changeSubscriptionPlan();
      }

      this.args.onPurchase(subscription);
    } catch (err) {
      this.error = err as Error;
    } finally {
      this.loading = false;
    }
  }

  @action
  onCancel(): void {
    this.upgrade.close();
  }

  @action
  setCoupon(coupon: ValidatedCoupon | undefined): void {
    this.coupon = coupon;
    this.upgrade.couponCode = coupon?.code;
  }

  get showTransferableCouponNotice(): boolean {
    return !!this.newPlanPreviousCoupon && !this.validatingCoupon && !this.coupon;
  }

  get showUntransferableCouponNotice(): boolean {
    return this.hasPreviousCoupon && !this.newPlanPreviousCoupon && !this.coupon && !this.validatingCoupon;
  }

  get shouldSchedule(): boolean {
    return this.upgrade.shouldSchedule(this.args.newPlan);
  }

  get userNotAuthorised(): boolean {
    return !this.auth.currentUser?.canManageSubscription;
  }

  get currentSubscription(): Subscription | undefined {
    return this.auth.currentSubscription;
  }

  get currentFullSubscription(): Subscription | undefined {
    return this.auth.currentFullSubscription;
  }

  get currentUser(): User {
    return this.auth.currentUser!;
  }

  get showCouponField(): boolean {
    const { couponCode, showCouponField } = this.args;

    return !this.shouldSchedule && !!(couponCode || showCouponField);
  }

  private get hasPreviousCoupon(): boolean {
    return !!this.currentFullSubscription?.discount;
  }

  private get purchasingTrialedPlan(): boolean {
    return !!this.currentSubscription?.isTrialingPlan(this.args.newPlan);
  }

  private async scheduleSubscription(): Promise<SubscriptionSchedule> {
    return this.upgrade.scheduleSubscription({ plan: this.args.newPlan, subscription: this.currentFullSubscription! });
  }

  private async purchaseTrialPlan(): Promise<Subscription> {
    return this.upgrade.purchaseTrialPlan({
      plan: this.args.newPlan,
      coupon: this.coupon
    });
  }

  private async changeSubscriptionPlan(): Promise<Subscription> {
    return this.upgrade.changeSubscriptionPlan({
      subscription: this.currentFullSubscription!,
      plan: this.args.newPlan,
      coupon: this.coupon
    });
  }

  private async getPreviousCoupon(planId: string): Promise<ValidatedCoupon | undefined> {
    if (!this.hasPreviousCoupon) {
      return undefined;
    }

    this.validatingCoupon = true;

    // @ts-expect-error
    const [coupon] = await this.store.query("validatedCoupon", {
      validate_previous_coupon: true, // eslint-disable-line camelcase
      plan_id: planId // eslint-disable-line camelcase
    });

    this.validatingCoupon = false;

    return coupon;
  }
}
