import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import type Project from "client/models/project";
import type StockLicense from "client/models/stock-license";
import type { StockLicenseDetails } from "client/models/stock-license";
import type CurrentUserService from "client/services/current-user";
import type TrackingService from "client/services/tracking";

interface Args {
  project: Project;
}

export default class AppHeaderPublish extends Component<Args> {
  @service
  declare currentUser: CurrentUserService;

  @service
  declare tracking: TrackingService;

  @service
  declare router: RouterService;

  @action
  async trackClickPublishStep(): Promise<void> {
    const { project } = this.args;

    const currentUser = this.currentUser.user;

    await project.stockLicenses.reload();
    const stockLicenses = await project.stockLicenses;

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_PUBLISH_STEP, {
      projectId: project.id,
      creditsInPlan: 0,
      remainingShutterstockCreditsCount: currentUser?.remainingShutterstockCreditsCount,
      // @ts-expect-error
      stockLicenses: this.mapLicenses(stockLicenses),
      unpaidLicenses: this.mapLicenses(project.unpaidLicenses),
      userCredits: currentUser?.shutterstockCreditsCount
    });
  }

  get isExportRoute(): boolean {
    return this.router.currentRouteName.includes("authenticated.publish");
  }

  private mapLicenses(licenses: StockLicense[]): StockLicenseDetails[] {
    return licenses.map(({ licensed, purchased, stockAssetId }) => ({ licensed, purchased, stockAssetId }));
  }

  get disableNonAddSteps(): boolean {
    return !this.args.project.hasNonEmptyProjectScenes;
  }
}
