import Model, { attr, belongsTo } from "@ember-data/model";
import TrackingEvents from "client/events";
import type User from "client/models/user";
import AnalyticsService from "client/services/analytics";

export const NON_BUSINESS_USER_TYPES = ["Personal", "Student"];

export interface UserTargetingEventProperties {
  /* eslint-disable camelcase */
  onboarding_role?: string;
  onboarding_role_description?: string;
  org_type?: string;
  org_type_description?: string;
  org_size?: string;
  email: string;
  user_id: string;
  /* eslint-enable camelcase */
}

export enum OnboardingRoles {
  CEO = "CEO or owner",
  Communications = "Communications",
  Consultant = "Consultant",
  CustomerSuccess = "Customer Success/Service",
  HumanResources = "Human Resources/People Operations",
  IT = "IT & Support",
  Leadership = "Leadership",
  Learning = "Learning & Development",
  Legal = "Legal & Compliance",
  Marketing = "Marketing",
  Operations = "Operations/Administration",
  Product = "Product & Design",
  Recruiting = "Recruiting",
  Sales = "Sales enablement",
  AccountManagement = "Sales/Account Management",
  Other = "Other"
}

export enum OnboardingOrgTypes {
  Business = "Business",
  Agency = "Agency",
  Teacher = "Teacher",
  Student = "Student",
  Government = "Government",
  NonProfit = "Non-profit",
  Personal = "Personal",
  Other = "Other"
}

enum TemplateTags {
  Teacher = "role:teaching",
  Personal = "role:personal",
  Sales = "role:sales",
  HumanResources = "role:hr",
  Operations = "role:operations"
}

export default class UserTargetingData extends Model {
  @belongsTo("user", { async: true, inverse: "userTargetingData" })
  declare user: User;

  @attr("string", { allowNull: true })
  declare role?: string;

  @attr("string", { allowNull: true })
  declare roleDescription?: string;

  @attr("string", { allowNull: true })
  declare userType?: string;

  @attr("string", { allowNull: true })
  declare userTypeDescription?: string;

  @attr("string", { allowNull: true })
  declare orgSize?: string;

  public async saveTargetingData(): Promise<void> {
    await this.save();
  }

  public async submit(): Promise<void> {
    await this.saveTargetingData();
    await this.trackSubmit();
  }

  public get complete(): boolean {
    return !!this.userType;
  }

  public getCreateProfileEventProperties(user: Pick<User, "id" | "email">): UserTargetingEventProperties {
    const { role, roleDescription, userType, userTypeDescription, orgSize } = this;

    return {
      /* eslint-disable camelcase */
      onboarding_role: role || undefined,
      onboarding_role_description: roleDescription || undefined,
      org_type: userType || undefined,
      org_type_description: userTypeDescription || undefined,
      org_size: orgSize || undefined,
      user_id: user.id,
      email: user.email
      /* eslint-enable camelcase */
    };
  }

  private async trackSubmit(): Promise<void> {
    const user = await this.user;
    this.identifyWithTraits(user);
    AnalyticsService.track(TrackingEvents.EVENT_CREATE_PROFILE, this.getCreateProfileEventProperties(user));
  }

  private identifyWithTraits(user: User): void {
    const { id: userId } = user;
    AnalyticsService.identify(userId, this.getCreateProfileEventProperties(user));
  }

  getSuggestedTemplatesTag(): string {
    switch (this.userType) {
      case OnboardingOrgTypes.Teacher: {
        return TemplateTags.Teacher;
      }
      case OnboardingOrgTypes.Personal:
      case OnboardingOrgTypes.Student: {
        return TemplateTags.Personal;
      }
    }

    switch (this.role) {
      case OnboardingRoles.CEO: {
        return TemplateTags.Sales;
      }
      case OnboardingRoles.Communications:
      case OnboardingRoles.Leadership:
      case OnboardingRoles.Learning:
      case OnboardingRoles.Legal:
      case OnboardingRoles.Operations:
      case OnboardingRoles.Other:
      case OnboardingRoles.Consultant: {
        return TemplateTags.Operations;
      }
      case OnboardingRoles.Product:
      case OnboardingRoles.Marketing: {
        return TemplateTags.Sales;
      }
      case OnboardingRoles.HumanResources: {
        return TemplateTags.HumanResources;
      }
      default: {
        return TemplateTags.Personal;
      }
    }
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    userTargetingData: UserTargetingData;
  }
}
