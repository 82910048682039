import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class AnimatedHeight extends Component {
  @tracked
  rect = new DOMRect();

  styleNamespace = getStyleNamespace("generic/animated-height");

  @action
  didInsert(element: HTMLElement): void {
    new ResizeObserver(() => {
      this.rect = element.getBoundingClientRect();
    }).observe(element);
  }

  get style(): SafeString {
    return htmlSafe(`height: ${this.rect.height}px;`);
  }
}
