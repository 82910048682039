import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";

export default class DeleteAccountRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(transition: Transition): Promise<void> {
    const { token } = transition.to.queryParams;

    if (isEmpty(token)) {
      await this.router.transitionTo("unauthenticated.login");
    }
  }

  queryParams = {
    token: {}
  };
}
