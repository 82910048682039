import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import isMobileDevice from "client/helpers/is-mobile-device";

const MOBILE_WARNING_DISMISSED_KEY = "biteable:mobileWarningDismissed";

export default class MobileService extends Service {
  @tracked
  isMobileWarningDismissed = !!localStorage?.getItem(MOBILE_WARNING_DISMISSED_KEY);

  @tracked
  isEditorMobileWarningDismissed = false;

  dismissEditorMobileWarning(): void {
    this.isEditorMobileWarningDismissed = true;
  }

  dismissMobileModal(): void {
    localStorage?.setItem(MOBILE_WARNING_DISMISSED_KEY, String(true));
    this.isMobileWarningDismissed = true;
  }

  get showMobileWarning(): boolean {
    return !this.isMobileWarningDismissed && this.isMobile;
  }

  get isMobile(): boolean {
    return isMobileDevice();
  }
}
