import type moment from "moment";
import { utc } from "moment";

export default (
  startDate: Date,
  endDate: Date,
  unitOfTime: moment.unitOfTime.DurationConstructor = "days",
  precise = false
): number => {
  if (startDate > endDate) {
    return 0;
  }

  const start = utc(startDate.getTime());
  const end = utc(endDate.getTime());

  return end.diff(start, unitOfTime, precise);
};
