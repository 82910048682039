import type Op from "quill-delta/dist/Op";
import type {
  Alignment,
  YAlignment,
  RgbaColor,
  TextConfig,
  TextAnimationId,
  TextNodeConfig,
  ZymbolConfig
} from "renderer-engine";
import {
  FontStyles,
  DEFAULT_COLOR,
  DEFAULT_FONT_FAMILY,
  DEFAULT_RIBBON_PADDING,
  parseTextContent
} from "renderer-engine";
import type { BulletList, Caption, ColorBrandKey, Text, TextStyleProperties } from "client/lib/editor-domain-model";
import { CharacterRange, TextStyle, toColorBrandKey } from "client/lib/editor-domain-model";

type InsertOpStringAttributes =
  | "font"
  | "weight"
  | "fontStyle"
  | "color"
  | "lineHeight"
  | "textAlign"
  | "yAlignment"
  | "padding"
  | "defaultWeight";

export type InsertOpAttributeMap = {
  [key in InsertOpStringAttributes]?: string;
} & {
  capitalize?: "uppercase";
  colorBrandKey?: ColorBrandKey;
  list?: BulletList;
};

export interface InsertOp {
  insert: string;
  attributes?: InsertOpAttributeMap;
}

interface BlockTextStyle extends TextStyle {
  range: undefined;
}

interface LineTextStyle {
  list?: BulletList;
  range?: CharacterRange;
}

interface InlineTextStyle extends TextStyle {
  range: CharacterRange;
}

interface ITextContentAttributes {
  fontAttributes: Partial<TextNodeConfig>;
  styleAttributes: Partial<TextNodeConfig>;
}

const NEWLINE_CHARACTER = "\n";

export const isInsertOp = (op: Op): op is InsertOp => typeof op.insert === "string";
export const isBlockTextStyle = (style: TextStyle): style is BlockTextStyle => !style.range;
export const isInlineTextStyle = (style: TextStyle): style is InlineTextStyle => !!style.range;

export const NORMAL_WEIGHT = 400;
export const SEMI_BOLD_WEIGHT = 600;
export const BOLD_WEIGHT = 700;

// We consider anything semi-bold or heavier to be bold because it would
// usually show as such in any font that only has normal and bold.
export const isNormalWeight = (weight: string): boolean => parseInt(weight) < SEMI_BOLD_WEIGHT;
export const isBoldWeight = (weight: string): boolean => parseInt(weight) >= SEMI_BOLD_WEIGHT;

export const getTextContentAttributes = (content: Op[]): ITextContentAttributes => {
  const lastFont = content.reverse().find((op) => {
    return op.attributes && op.attributes["font"];
  }) || { attributes: {} };

  const lastStyle = content.reverse().find((op) => {
    return op.attributes && op.attributes["fontStyle"];
  }) || { attributes: {} };

  return { fontAttributes: lastFont.attributes!, styleAttributes: lastStyle.attributes! };
};

export const convertTextZymbolConfigToTextStyles = (
  config: ZymbolConfig
): [content: string, styles: TextStyle[], animation: string] => {
  if (!config.text) {
    const textStyles: TextStyle[] = [];
    const textContent = "";
    return [textContent, textStyles, "NONE"];
  }
  return convertTextConfigToTextStyles(config.text);
};

export const convertTextConfigToTextStyles = (
  text: TextConfig
): [content: string, styles: TextStyle[], animation: string] => {
  const textStyles: TextStyle[] = [];
  let textContent = "";

  const {
    content,
    animationColors,
    alignment,
    yAlignment,
    animationColorBrandKeys,
    contentColorBrandKey,
    list,
    defaultWeight,
    animation
  } = text;

  const ops = parseTextContent(content);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { fontAttributes, styleAttributes } = getTextContentAttributes(ops);
  const insertOps = ops.filter(isInsertOp);

  const defaults: Partial<TextStyleProperties> = {
    fontFamily: fontAttributes.font ?? DEFAULT_FONT_FAMILY,
    fontSize: text?.fontSize ?? 1,
    color: styleAttributes.color ?? DEFAULT_COLOR,
    ribbonColor: animationColors.one.rgb,
    alignment,
    yAlignment,
    colorBrandKey: toColorBrandKey(contentColorBrandKey),
    ribbonColorBrandKey: toColorBrandKey(animationColorBrandKeys?.one),
    list: list as any as BulletList,
    defaultWeight: updateDefaultWeight(defaultWeight, insertOps)
  };

  let blockStyle;
  const blockStyleOp = insertOps.pop();

  if (blockStyleOp) {
    blockStyle = convertInsertOpToTextStyle(blockStyleOp, defaults, undefined);

    if (blockStyleOp.insert.length > 1) {
      insertOps.push({ insert: blockStyleOp.insert.slice(0, -1), attributes: blockStyleOp.attributes });
    }
  }

  let offset = 0;
  for (const op of insertOps) {
    const defaults = { colorBrandKey: toColorBrandKey(contentColorBrandKey) };

    textStyles.push(convertInsertOpToTextStyle(op, defaults, new CharacterRange(offset, op.insert.length)));

    textContent += op.insert;
    offset += op.insert.length;
  }

  if (blockStyle) {
    const finalLineStyle = new TextStyle({ list: blockStyle._list, range: new CharacterRange(offset, 1) });
    blockStyle._list = undefined;

    textStyles.push(finalLineStyle);
    textStyles.push(blockStyle);
  }

  return [textContent, textStyles, animation.id];
};

const convertInsertOpToTextStyle = (
  op: InsertOp,
  defaults: Partial<TextStyleProperties>,
  range?: CharacterRange
): TextStyle => {
  const { attributes } = op;

  return new TextStyle({
    fontFamily: attributes?.font ?? defaults.fontFamily,
    fontVariant: {
      weight: attributes?.weight ?? String(NORMAL_WEIGHT),
      style: attributes?.fontStyle ?? FontStyles.NORMAL
    },
    color: attributes?.color ?? defaults.color,
    fontSize: defaults.fontSize,
    range,
    ribbonColor: defaults.ribbonColor,
    lineHeight: attributes?.lineHeight ? Number(attributes.lineHeight) : undefined,
    capitalize: attributes?.capitalize === "uppercase",
    alignment: defaults.alignment,
    yAlignment: defaults.yAlignment,
    colorBrandKey: defaults.colorBrandKey,
    ribbonColorBrandKey: defaults.ribbonColorBrandKey,
    list: attributes?.list,
    defaultWeight: defaults.defaultWeight
  });
};

const updateDefaultWeight = (defaultWeight: string | undefined, insertOps: Op[]): string => {
  const normalWeights = insertOps.map((op) => op?.attributes?.["weight"] as string).filter(isNormalWeight);
  const consistentNormalWeight = normalWeights.every((w) => w === normalWeights[0]) ? normalWeights[0] : undefined;
  return consistentNormalWeight ?? defaultWeight ?? normalWeights[0] ?? String(NORMAL_WEIGHT);
};

export const convertTextToQuillContent = ({ content, styles }: Pick<Text, "content" | "styles">): Op[] => {
  const blockStyle = styles.find(isBlockTextStyle);
  const inlineTextStyles = [...styles].filter(isInlineTextStyle);

  const sortedTextStyles = inlineTextStyles.sort(
    (a, b) => (a.range?.startOffset ?? Infinity) - (b.range?.startOffset ?? Infinity)
  );

  let offset = 0;
  const ops: Op[] = [];

  // Walk through the text content, splitting it into "inserts" to which different styles are applied.
  while (offset < content.length) {
    // Is there a style which applies to the current offset?
    const style = sortedTextStyles.find(({ range }) => offset >= range.startOffset && offset < range.endOffset);

    let insert = "";
    if (style) {
      // Remove the style that we're applying.
      sortedTextStyles.splice(sortedTextStyles.indexOf(style), 1);
      // This insert starts at the current offset and runs the length of the style's range (removing any overlap at the beginning, which was in the previous insert).
      insert = content.substring(offset, style.range.endOffset);
    } else {
      // If no inline style applies, output this insert with an empty style. (Note `style` is `undefined` in this case.)
      const nextStyle = sortedTextStyles.find(({ range }) => range.startOffset > offset);
      // This insert ends at the start offset of the next style, or the end of the content if there isn't another style.
      insert = content.substring(offset, nextStyle?.range.startOffset ?? content.length);
    }

    // Convert the insert to a Quill `Op`. https://quilljs.com/docs/delta/
    if (/^\n+$/.test(insert)) {
      // If the insert contains only newlines, this is the end of one or more blocks, so apply the block-level styles for the caption (rather than the inline style for this character range).
      ops.push(convertTextStyleToInsertOp(insert, blockStyle, undefined, style));
    } else {
      ops.push(convertTextStyleToInsertOp(insert, style, blockStyle));
    }

    offset += insert.length;
  }

  const finalStyle = sortedTextStyles.find(({ range }) => offset >= range.startOffset && offset < range.endOffset);

  if (blockStyle) {
    ops.push(convertTextStyleToInsertOp(NEWLINE_CHARACTER, blockStyle, undefined, finalStyle));
  }

  return ops;
};

const convertTextStyleToInsertOp = (
  insert: string,
  style?: TextStyle,
  blockStyle?: BlockTextStyle,
  lineStyle?: LineTextStyle
): InsertOp => {
  if (!style) {
    return { insert };
  }

  const attributes: InsertOpAttributeMap = {};
  const set = <T extends keyof InsertOpAttributeMap>(
    key: T,
    value: InsertOpAttributeMap[T] | false | undefined
  ): void => {
    if (value) {
      attributes[key] = value;
    }
  };

  set("font", style.fontFamily !== blockStyle?.fontFamily && style.fontFamily);
  set("lineHeight", style.lineHeight !== blockStyle?.lineHeight && !!style.lineHeight && String(style.lineHeight));
  set("textAlign", style.alignment !== blockStyle?.alignment && style.alignment);
  set("yAlignment", style.yAlignment !== blockStyle?.yAlignment && style.yAlignment);
  set("padding", style.ribbonColor ? `0 ${DEFAULT_RIBBON_PADDING}em` : false);
  set("color", style.color);
  set("weight", style.weight);
  set("fontStyle", style.fontStyle);
  set("capitalize", style.capitalize && "uppercase");
  set("colorBrandKey", style.colorBrandKey);
  set("list", lineStyle?.list);
  set("defaultWeight", style.defaultWeight !== blockStyle?.defaultWeight && style.defaultWeight);

  if (!Object.keys(attributes).length) {
    return { insert };
  }
  return { attributes, insert };
};

export const getTextStylesFromCaption = (caption: Caption): TextStyle[] =>
  ([] as TextStyle[]).concat(...caption.texts.map(({ styles }) => styles));

export const createTextZymbolConfig = ({
  content,
  styles,
  animation
}: Pick<Text, "content" | "styles" | "animation">): Partial<TextConfig> => {
  const ops = convertTextToQuillContent({ content, styles });

  let configContent;
  if (ops.length) {
    configContent = JSON.stringify(ops);
  }

  const fontSize = styles.map((s) => s.fontSize).find((a) => a);
  const alignment = styles.map((s) => s.alignment as Alignment).find((a) => a);
  const yAlignment = styles.map((s) => s.yAlignment as YAlignment).find((a) => a);
  const blockStyle = styles.find(isBlockTextStyle);

  const animationColors: { one: RgbaColor; two: RgbaColor } = {
    one: { rgb: undefined, alpha: 1 },
    two: { rgb: undefined, alpha: 1 }
  };
  if (blockStyle?.ribbonColor || blockStyle) {
    animationColors.one.rgb = blockStyle.ribbonColor;
  }
  const animationColorBrandKeys = blockStyle?.ribbonColorBrandKey && {
    one: blockStyle.ribbonColorBrandKey,
    two: "none"
  };
  const contentColorBrandKey = blockStyle?.colorBrandKey;
  return {
    alignment,
    yAlignment,
    animationColors,
    content: configContent,
    fontSize,
    animationColorBrandKeys,
    contentColorBrandKey,
    defaultWeight: blockStyle?.defaultWeight,
    animation: { id: animation as TextAnimationId }
  };
};
