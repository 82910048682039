import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewDatesArgs {
  projectPreview: ProjectPreview;
}

export default class ProjectPreviewDatesComponent extends Component<ProjectPreviewDatesArgs> {
  popoverTarget!: HTMLElement;

  @tracked
  expanded = false;

  styleNamespace = getStyleNamespace("discovery/project-preview/dates");

  @action
  show(): void {
    this.expanded = true;
  }

  @action
  hide(): void {
    this.expanded = false;
  }

  @action
  didInsert(element: HTMLElement): void {
    this.popoverTarget = element;
  }

  get createdDate(): Date {
    return this.args.projectPreview.createdAt;
  }

  get editedDate(): Date {
    return this.args.projectPreview.updatedAt;
  }

  get renderedDate(): Date | undefined {
    return this.args.projectPreview.renderedAt;
  }
}
