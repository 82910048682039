import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AccountSubscriptionRoute from "../route";

export default class InvoicesRoute extends Route {
  @service
  declare router: RouterService;

  beforeModel(): void {
    const { user } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    if (!user.hasCharges) {
      void this.router.replaceWith("authenticated.account.subscription");
    }
  }
}
