import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface BrandColorPresetBlankArgs {
  index: number;
  total: number;
  addColorPreset(): unknown;
  disabled?: boolean;
}

export default class BrandColorPresetBlankComponent extends Component<BrandColorPresetBlankArgs> {
  styleNamespace = getStyleNamespace("brand/color-preset-blank");

  @action
  addColorPreset(): void {
    if (!this.args.disabled) {
      this.args.addColorPreset();
    }
  }
}
