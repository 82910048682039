import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type ScenesAssetsLibraryController from "./controller";
import type ProjectScenesRoute from "client/authenticated/project/scenes/route";
import type FoldersService from "client/services/folders";

export interface Model {
  order: string;
}

interface RouteParams {
  order: string;
}

export default class ProjectScenesAssetsLibraryRoute extends Route<Model> {
  @service
  declare folders: FoldersService;

  async model({ order }: RouteParams): Promise<Model> {
    order ??= this.folders.filterOrder;

    return {
      order
    };
  }

  async setupController(
    controller: ScenesAssetsLibraryController,
    model: Model,
    transition: Transition
  ): Promise<void> {
    super.setupController(controller, model, transition);

    const scenesRouteModel = this.modelFor<ProjectScenesRoute>("authenticated.project.scenes");

    controller.scenesRouteModel = scenesRouteModel;
  }

  queryParams = {
    order: {
      refreshModel: true
    }
  };
}
