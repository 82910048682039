import Model, { attr } from "@ember-data/model";

export default abstract class Audible extends Model {
  @attr("string")
  name!: string;

  abstract type: string | number;

  get url(): string {
    // @ts-expect-error
    throw `url getter not implemented on ${this.modelName}`;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    audible: Audible;
  }
}
