const ASPECT_RATIO_NAMES = ["9:16", "3:4", "4:5", "1:1", "5:4", "4:3", "16:9"];
const ASPECT_RATIOS = [9 / 16, 3 / 4, 4 / 5, 1 / 1, 5 / 4, 4 / 3, 16 / 9];
/**
 * Convert a width and height to a closest aspect ratio name (eg: 1920 x 1080 => "16:9")
 */
export const closestAspectRatioName = (width: number, height: number): string => {
  const givenRatio = width / height;

  for (let i = 0; i < ASPECT_RATIOS.length; i++) {
    const ratio = ASPECT_RATIOS[i]!;

    if (givenRatio <= ratio) {
      return ASPECT_RATIO_NAMES[i]!;
    }
  }

  return ASPECT_RATIO_NAMES[ASPECT_RATIO_NAMES.length - 1]!;
};
