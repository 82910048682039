import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { FontStyles } from "renderer-engine";
import BaseParentPanel from "../base-parent/component";
import { TextStyle } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { BOLD_WEIGHT, NORMAL_WEIGHT, SEMI_BOLD_WEIGHT } from "client/lib/text/text-styles";
import SelectableTextStyle from "client/models/selectable-text-style";

const defaultFontFamilies = `"Brown", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

export default class TextPanel extends BaseParentPanel {
  styleNamespace = getStyleNamespace("project-content-bar/panels/text");

  @tracked
  stylePresets: { label: string; labelStyleAttr: string; style: SelectableTextStyle }[] | undefined;

  @action
  async populateStylePresets(): Promise<void> {
    const brandStyle = await this.args.project.getBrandStyle();
    let brandedFontFamily: string | undefined;

    if (brandStyle) {
      brandedFontFamily = brandStyle.style.textStyles.body.fontFamily;
    }

    this.stylePresets = [
      {
        label: "Title",
        labelStyleAttr: `font-size: 30px; font-weight: ${String(BOLD_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 25 / 16,
            fontVariant: { weight: String(BOLD_WEIGHT), style: FontStyles.NORMAL }
          })
        )
      },
      {
        label: "Header",
        labelStyleAttr: `font-size: 20px; font-weight: ${String(BOLD_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 18 / 16,
            fontVariant: { weight: String(BOLD_WEIGHT), style: FontStyles.NORMAL }
          })
        )
      },
      {
        label: "Sub-header",
        labelStyleAttr: `font-size: 20px; font-weight: ${String(NORMAL_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 10 / 16,
            fontVariant: { weight: String(SEMI_BOLD_WEIGHT), style: FontStyles.NORMAL }
          })
        )
      },
      {
        label: "Body text",
        labelStyleAttr: `font-size: 16px; font-weight: ${String(NORMAL_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 8 / 16,
            fontVariant: { weight: String(NORMAL_WEIGHT), style: FontStyles.NORMAL }
          })
        )
      },
      {
        label: "<ul><li>Bullet list</li></ul>",
        labelStyleAttr: `font-size: 16px; font-weight: ${String(NORMAL_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 8 / 16,
            fontVariant: { weight: String(NORMAL_WEIGHT), style: FontStyles.NORMAL },
            list: "bullet"
          })
        )
      },
      {
        label: "<ol><li>Number list</li></ol>",
        labelStyleAttr: `font-size: 16px; font-weight: ${String(NORMAL_WEIGHT)}; font-style: ${
          FontStyles.NORMAL
        }; font-family: ${brandedFontFamily}, ${defaultFontFamilies};`,
        style: new SelectableTextStyle(
          getOwner(this)!,
          new TextStyle({
            fontFamily: brandedFontFamily,
            fontSize: 8 / 16,
            fontVariant: { weight: String(NORMAL_WEIGHT), style: FontStyles.NORMAL },
            list: "ordered"
          })
        )
      }
    ];
  }
}
