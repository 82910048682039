import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Discount from "client/models/discount";
import type Plan from "client/models/plan";
import { SkuCode } from "client/models/plan";
import type Subscription from "client/models/subscription";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

interface AccountLatestSubscriptionArgs {
  subscription?: Subscription;
  discount?: Discount;
}

export default class AccountSubscriptionLatestSubscriptionComponent extends Component<AccountLatestSubscriptionArgs> {
  @service
  declare plans: PlansService;

  @service
  declare upgrade: UpgradeService;

  styleNamespace = getStyleNamespace("account/subscription/latest-subscription");

  @action
  changePlan(): void {
    void this.upgrade.open();
  }

  get actionTitle(): string {
    return this.justTrialed ? "Subscribe" : "Reactivate";
  }

  get heading(): string {
    if (this.subscriptionEnded) {
      return this.justTrialed ? "Your plan" : "Your previous plan";
    } else {
      return "Current plan";
    }
  }

  get interval(): string | undefined {
    if (this.args.discount) {
      return this.discountInterval;
    } else {
      return "per month";
    }
  }

  get plan(): Plan | undefined {
    return this.subscription?.plan ?? this.plans.getFreePlan();
  }

  get planAlternatePrice(): string | undefined {
    if (this.args.discount) {
      return this.discountedPlanAlternatePrice;
    } else {
      return this.plan?.billingInterval ?? "";
    }
  }

  get planHighlights(): string[] {
    return this.plan?.highlights ?? [];
  }

  get planName(): string {
    if (this.trialing) {
      return "Biteable trial";
    } else if (this.justTrialed) {
      return "Edit-only mode";
    } else {
      const name = `${this.plan?.name ?? "Free"} plan`;
      return this.isFreePlan ? `${name} (retired)` : name;
    }
  }

  get planPrice(): string | undefined {
    if (this.discountedPrice) {
      return this.discountedPrice;
    } else {
      return this.monthlyPlanPrice ?? "$0";
    }
  }

  get planRetired(): boolean {
    return !!this.plan?.skuRetired;
  }

  get planSku(): SkuCode {
    return this.plan?.skuCode ?? SkuCode.FREE;
  }

  get showAlternatePrice(): boolean {
    return !!this.planAlternatePrice && !this.isFreePlan;
  }

  get showCancelledMessage(): boolean {
    return this.subscriptionCanceled && !(this.trialing || this.justTrialed);
  }

  get showFreePlanEndingWarning(): boolean {
    return this.isFreePlan;
  }

  get showPrice(): boolean {
    return !this.showFreePlanEndingWarning && !(this.trialing || this.justTrialed);
  }

  get showSubscriptionEndDate(): boolean {
    return this.subscriptionCanceled && !this.subscriptionEnded && !this.trialing;
  }

  get showTopAction(): boolean {
    return (this.subscriptionCanceled && this.subscriptionEnded && this.planOffered) || this.justTrialed;
  }

  get subscriptionEndDate(): Date | undefined {
    return this.subscription?.currentPeriodEnd;
  }

  get subscriptionSchedule(): SubscriptionSchedule | undefined {
    return this.subscription?.subscriptionSchedule;
  }

  private get discountInterval(): string | undefined {
    if (this.args.discount) {
      const { durationDescription } = this.args.discount;

      return durationDescription;
    }

    return undefined;
  }

  private get discountedPrice(): string | undefined {
    if (this.args.discount) {
      return this.args.discount.price;
    }

    return undefined;
  }

  private get discountedPlanAlternatePrice(): string | undefined {
    if (this.args.discount) {
      return this.args.discount.futurePrice;
    }

    return undefined;
  }

  private get isFreePlan(): boolean {
    return !this.plan || this.plan.isFreePlan;
  }

  private get monthlyPlanPrice(): string | undefined {
    if (!this.plan) {
      return;
    }

    return this.plan.monthlyPrice;
  }

  private get planOffered(): boolean {
    return !!this.plan?.offered;
  }

  private get subscription(): Subscription | undefined {
    return this.args.subscription;
  }

  private get subscriptionCanceled(): boolean {
    return !!this.subscription?.canceled;
  }

  private get subscriptionEnded(): boolean {
    return !!this.subscription?.ended;
  }

  private get trialing(): boolean {
    return !!this.subscription?.trialing;
  }

  private get justTrialed(): boolean {
    return !!this.subscription?.justTrialed;
  }
}
