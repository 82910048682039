import { tracked } from "@glimmer/tracking";
import type { EventRegister } from "./event-register";
import { StrictMutation } from "./mutations";
import { ExplicitTransaction } from "./mutations/explicit-transaction";
import { InlineMutation } from "./mutations/vanilla-mutation";

export class ResetGroup {
  @tracked
  private mutations: StrictMutation[] = [];

  constructor(private eventRegister: EventRegister) {}

  track<T>(mutation: StrictMutation<T>): void {
    this.mutations = this.mutations.concat(mutation);
  }

  async reset(): Promise<void> {
    await this.eventRegister.fire(
      new ExplicitTransaction(
        ...this.eventRegister
          .intersect(this.mutations)
          .reverse()
          .map((m) => new ReverseMutation(m)),
        new InlineMutation(this, (t) => {
          t.mutations = [];
        })
      )
    );
  }

  get canReset(): boolean {
    return this.eventRegister.hasAny(this.mutations);
  }
}

export class ReverseMutation<T> extends StrictMutation<T> {
  grouped = false;
  constructor(private mutation: StrictMutation<T>) {
    super();
  }

  run(): T {
    return this.mutation.revert();
  }

  revert(): T {
    return this.mutation.run();
  }
}
