import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import { ChangeSceneFilterColorMutation } from "client/lib/editor-domain-model";
import { transaction } from "client/lib/transaction";
import type PendingService from "client/services/pending";

interface ProjectSceneFilterArgs {
  eventRegister: EventRegister;
  scene: Scene;
}

export default class ProjectSceneFilterComponent extends Component<ProjectSceneFilterArgs> {
  @service
  declare pending: PendingService;

  get filterColors(): string[] {
    return this.args.scene.filterColor ? [this.args.scene.filterColor] : [];
  }

  @action
  @transaction
  async onChange(color?: string): Promise<void> {
    const { eventRegister, scene } = this.args;

    eventRegister.fire(new ChangeSceneFilterColorMutation(scene, color));

    await this.pending.push(eventRegister.facade.saveScene(scene));
  }
}
