import { tracked } from "@glimmer/tracking";
import type { AnimationColor } from "./animation-color";

export class AnimatedLayer {
  /** @internal */
  @tracked
  _blendMode: string;

  /** @internal */
  @tracked
  _style: AnimationColor;

  constructor(
    public readonly id: string,
    public readonly lowResVideoUrl: string,
    blendMode: string,
    style: AnimationColor
  ) {
    this._blendMode = blendMode;
    this._style = style;
  }

  public get blendMode(): string {
    return this._blendMode;
  }

  public get style(): AnimationColor {
    return this._style.copy();
  }
}
