import type { ExecutionPhase } from "./mutation";
import { StrictMutation } from "./mutation";

export class RouteAction extends StrictMutation<Partial<Promise<any>>> {
  grouped = true;
  undoable = true;
  executionPhase: ExecutionPhase = "route";

  private finalUrl: string;

  constructor(
    private router: {
      transitionTo: (url: string) => Partial<Promise<any>>;
      currentURL: string;
    },
    private startUrl: string = router.currentURL
  ) {
    super();
    this.finalUrl = router.currentURL;
  }

  run(): Partial<Promise<any>> {
    return this.router.transitionTo(this.finalUrl);
  }

  revert(): Partial<Promise<any>> {
    return this.router.transitionTo(this.startUrl);
  }
}
