import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type UndoService from "client/services/undo";

export default class ProjectUndoRedoComponent extends Component {
  @service
  declare undo: UndoService;

  styleNamespace = getStyleNamespace("app-header/project/undo-redo");

  constructor(owner: object, args: {}) {
    super(owner, args);
    document.addEventListener("keydown", this.handleDocumentKeydown);
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener("keydown", this.handleDocumentKeydown);
  }

  @action
  async handleDocumentKeydown(event: KeyboardEvent): Promise<void> {
    const macDetected = navigator.userAgent.toLowerCase().includes("mac");
    if ((!macDetected && event.ctrlKey) || (macDetected && event.metaKey)) {
      if ((event.key === "z" && event.shiftKey) || event.key === "y") {
        await this.redoAction();
      } else if (event.key === "z") {
        await this.undoAction();
      }
    }
  }

  @action
  async undoAction(): Promise<void> {
    if (!this.undoDisabled) {
      await this.undo.eventRegister?.undo();
    }
  }

  get undoDisabled(): boolean {
    return !this.undo.eventRegister?.canUndo;
  }

  @action
  async redoAction(): Promise<void> {
    if (!this.redoDisabled) {
      await this.undo.eventRegister?.redo();
    }
  }

  get redoDisabled(): boolean {
    return !this.undo.eventRegister?.canRedo;
  }
}
