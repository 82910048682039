import type { Bounds, Size } from "renderer-engine";

export default class AssetCrop {
  public static fillContainer(bounds: Bounds, containerBounds: Bounds): Bounds {
    if (bounds.x > containerBounds.x) {
      bounds.x = containerBounds.x;
    } else if (bounds.x < containerBounds.x) {
      if (bounds.x + bounds.width < containerBounds.x + containerBounds.width) {
        bounds.x = containerBounds.x + containerBounds.width - bounds.width;
      }
    }

    if (bounds.y > containerBounds.y) {
      bounds.y = containerBounds.y;
    } else if (bounds.y < containerBounds.y) {
      if (bounds.y + bounds.height < containerBounds.y + containerBounds.height) {
        bounds.y = containerBounds.y + containerBounds.height - bounds.height;
      }
    }

    return bounds;
  }

  public static getAssetSize(containerBounds: Bounds, assetSize: Size, canvasSize: Size): Size {
    const canvasHeight = canvasSize.height;
    const canvasWidth = canvasSize.width;

    const imageWidth = assetSize.width;
    const imageHeight = assetSize.height;

    const resizeRatio = Math.max(
      (containerBounds.width * canvasWidth) / imageWidth,
      (containerBounds.height * canvasHeight) / imageHeight
    );

    const resizeWidth = imageWidth * resizeRatio;
    const resizeHeight = imageHeight * resizeRatio;

    const relativeImageWidth = resizeWidth / canvasWidth;
    const relativeImageHeight = resizeHeight / canvasHeight;

    return { width: relativeImageWidth, height: relativeImageHeight };
  }

  public static getAssetBounds(
    containerBounds: Bounds,
    assetSize: Size,
    canvasSize: Size,
    offset: [x: number, y: number]
  ): Bounds {
    const { width, height } = AssetCrop.getAssetSize(containerBounds, assetSize, canvasSize);

    const x = containerBounds.x - offset[0];
    const y = containerBounds.y - offset[1];

    return AssetCrop.fillContainer({ x, y, width, height }, containerBounds);
  }
}
