import type VideoAssistantGeneratorField from "client/models/video-assistant-generator-field";

export default class VideoAssistantPromptField {
  public value: string | undefined;
  public input: VideoAssistantGeneratorField | undefined;
  public isValid = true;

  public constructor(init?: Partial<VideoAssistantPromptField>) {
    Object.assign(this, init);
  }
}
