import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { pluralize } from "ember-inflector";
import uniq from "lodash/uniq";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ViewLocationInfo } from "client/models/video-watch-event-summary";
import type VideoWatchEventSummary from "client/models/video-watch-event-summary";

interface AnalyticsDetailsComponentArgs {
  data?: VideoWatchEventSummary;
  hasEndscreen: boolean;
  onChange: (value: string) => void;
  selectedValue: string;
}

interface LocationsWithCount {
  count: string;
  location: string;
}

export default class AnalyticsDetailsComponent extends Component<AnalyticsDetailsComponentArgs> {
  formatter = Intl.NumberFormat();
  options = {
    "All-time": "Infinity",
    "28 days": "28",
    "7 days": "7"
  };
  placeholder = "--";
  toggleAllThreshold = 3;

  styleNamespace = getStyleNamespace("analytics-details");

  @tracked
  showAll = false;

  get ctaContext(): string {
    return CTAContext.CTA_CONTEXT_BUTTON_EXPORT_GET_FULL_ANALYTICS;
  }

  clickRateHelper(clicks = 0, views = 0): string {
    if (this.args.hasEndscreen && clicks > 0) {
      const percentage = 100 * (clicks / views);

      return `${this.formatter.format(clicks)} (${percentage.toFixed()}%)`;
    } else {
      return this.placeholder;
    }
  }

  completionRateHelper(views = 0, completions = 0): string {
    if (views > 0) {
      const percentage = 100 * (completions / views);

      return `${this.formatter.format(completions)} (${percentage.toFixed()}%)`;
    } else {
      return this.placeholder;
    }
  }

  @action
  toggleShowAll(): void {
    this.showAll = !this.showAll;
  }

  get completionRate(): string {
    return this.completionRateHelper(this.args?.data?.uniqueViews, this.args?.data?.completionRate);
  }

  get clickRate(): string {
    return this.clickRateHelper(this.args.data?.totalClicks, this.args.data?.uniqueViews);
  }

  get fiftyPercentCompletionRate(): string {
    return this.completionRateHelper(this.args?.data?.uniqueViews, this.args?.data?.fiftyPercentCompletionRate);
  }

  get knownLocations(): LocationsWithCount[] {
    const map = ({ country, city, count }: ViewLocationInfo): LocationsWithCount => ({
      location: `${city}, ${country}`,
      count: `${count} ${pluralize(count, "view", { withoutCount: true })}`
    });

    const filter = ({ country = "", city = "" }: Partial<ViewLocationInfo>): boolean =>
      !isEmpty(country) && !isEmpty(city);

    const locations = this.args?.data?.viewLocations?.filter(filter).map(map);

    if (locations && locations.length > 0) {
      return locations;
    } else {
      return [];
    }
  }

  get locationCount(): number {
    return this.args?.data?.viewLocations?.length ?? 0;
  }

  get locations(): LocationsWithCount[] {
    let end;

    if (!this.showAll) {
      end = this.toggleAllThreshold;
    }

    if (this.locationCount <= 0) {
      return [
        {
          location: this.placeholder,
          count: this.placeholder
        }
      ];
    } else {
      return [...this.knownLocations, ...this.unknownLocations].slice(0, end);
    }
  }

  get showToggleAll(): boolean {
    return this.locationCount > this.toggleAllThreshold;
  }

  get selectedValue(): string {
    return this.args.selectedValue ?? this.options["All-time"];
  }

  get totalViews(): string {
    if (!this.args?.data?.totalViews) {
      return this.placeholder;
    } else {
      return this.formatter.format(this.args?.data?.totalViews);
    }
  }

  get uniqueViews(): string {
    if (!this.args?.data?.uniqueViews) {
      return this.placeholder;
    } else {
      const uniqueViews = this.args?.data?.uniqueViews;
      const formattedViews = this.formatter.format(uniqueViews);

      return `${formattedViews} ${pluralize(uniqueViews, "person", { withoutCount: true })}`;
    }
  }

  get unknownLocations(): LocationsWithCount[] {
    const map = ({ count }: ViewLocationInfo): LocationsWithCount => ({
      location: `Unknown`,
      count: `${count} ${pluralize(count, "view", { withoutCount: true })}`
    });

    const filter = ({ country = "", city = "" }: Partial<ViewLocationInfo>): boolean =>
      isEmpty(country) && isEmpty(city);

    const locations = uniq(this.args?.data?.viewLocations?.filter(filter).map(map));

    if (locations && locations.length > 0) {
      return locations;
    } else {
      return [];
    }
  }
}
