import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { AudioClipCategory } from "renderer-engine";
import type { Timeline, StrictMutation, AudioClip, EventRegister } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { DEFAULT_PIXELS_PER_SECOND } from "client/lib/timeline/timeline-dimensions";
import type Project from "client/models/project";
import type AdvancedEditorService from "client/services/advanced-editor";
import type PlaybackService from "client/services/playback";
import type TimelineService from "client/services/timeline";

interface ProjectTimelineCollapsedArgs {
  timeline: Timeline;
  eventRegister: EventRegister;
  selectedSceneId?: string;
  selectedCaptionId?: string;
  project: Project;
}

export default class ProjectTimelineCollapsedComponent extends Component<ProjectTimelineCollapsedArgs> {
  styleNamespace = getStyleNamespace("project-timeline/collapsed");

  @tracked
  pixelsPerSecond = DEFAULT_PIXELS_PER_SECOND;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service("timeline")
  declare timelineState: TimelineService;

  @service
  declare playback: PlaybackService;

  constructor(owner: object, args: ProjectTimelineCollapsedArgs) {
    super(owner, args);

    this.pixelsPerSecond = this.timelineState.pixelsPerSecond ?? DEFAULT_PIXELS_PER_SECOND;
  }

  get soundtracks(): AudioClip[] {
    return this.args.timeline.audioClips.filter((ac) => ac.category === AudioClipCategory.MUSIC);
  }

  get isProjectEmpty(): boolean {
    return !this.args.timeline.scenes.length;
  }

  @action
  mutate<T>(mutation: StrictMutation<T>): T | void {
    return this.args.eventRegister.fire<T>(mutation);
  }

  @action
  async onClickPlay(): Promise<void> {
    const { timeline } = this.args;

    if (this.args.selectedSceneId) {
      const scene = this.args.timeline.getSceneById(this.args.selectedSceneId);
      if (scene) {
        const timing = timeline.getSceneTiming(scene);
        void this.playback.play(timing.startTime, timeline.duration, false);
      }
    }
  }

  @action
  async onClickStop(): Promise<void> {
    await this.playback.stop();
  }

  get audioClip(): AudioClip | undefined {
    return this.args.timeline.audioClips.find((audioClip) => audioClip.category === AudioClipCategory.MUSIC);
  }

  get soundtrackIsPresent(): boolean {
    return !!this.audioClip?.asset;
  }

  get collapsedTimeline(): boolean {
    return this.advancedEditor.collapsed;
  }
}
