import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";

export default class TeamMembersListComponent extends Component {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("collaboration/team-members-list");

  get teamOwner(): boolean {
    return !!this.auth.currentUser?.teamOwner;
  }
}
