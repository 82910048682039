import ArrayProxy from "@ember/array/proxy";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import shuffle from "lodash/shuffle";
import sum from "lodash/sum";
import defer from "client/lib/defer";
import type ContentTemplate from "client/models/content-template";
import type SelectableAsset from "client/models/selectable-asset";
import type HoneybadgerService from "client/services/honeybadger";

export enum ContentType {
  GRAPHICS = "contentTemplate",
  PROJECT_SCENE = "projectScenePreview",
  PEXELS_PHOTO = "pexelsPhoto",
  PEXELS_VIDEO = "pexelsVideo",
  STORYBLOCKS_VIDEO = "zymbolFootage"
}

export const ALL_CONTENTS: ContentType[] = [
  ContentType.GRAPHICS,
  ContentType.PROJECT_SCENE,
  ContentType.PEXELS_PHOTO,
  ContentType.PEXELS_VIDEO,
  ContentType.STORYBLOCKS_VIDEO
];

const DEFAULT_PAGES = 100;
const CONTENT_PER_PAGE = 10;
const ImagesKeywords = "technology computer laptop mobile cell phone";
const PexelsVideosKeywords = "backgrounds";
const StoryblocksKeywords = "teamwork productivity teams";

const CURATION_PARAMS: Record<ContentType, Record<string, string | boolean>> = {
  [ContentType.GRAPHICS]: { curated: true },
  [ContentType.PROJECT_SCENE]: { curated: true },
  [ContentType.PEXELS_PHOTO]: { query: ImagesKeywords },
  [ContentType.PEXELS_VIDEO]: { query: PexelsVideosKeywords },
  [ContentType.STORYBLOCKS_VIDEO]: { query: StoryblocksKeywords }
};

class ContentMeta {
  readonly contentType: ContentType;
  private readonly store: Store;
  private _perPage = CONTENT_PER_PAGE;
  totalCount = -1;
  active = true;
  configReady = defer();

  private _pages: number[] = [];
  private _totalPage = DEFAULT_PAGES;

  constructor(contentType: ContentType, store: Store) {
    this.store = store;
    this.contentType = contentType;
    void this.checkTotalCount();
  }

  shuffledPage(index: number): number | undefined {
    return this._pages[index > 0 ? index - 1 : 0];
  }

  get perPage(): number {
    return this._perPage;
  }

  set perPage(value: number) {
    this._perPage = value;
    this.shuffle();
  }

  get totalPage(): number {
    return this._totalPage;
  }

  get params(): Record<string, string | boolean> {
    return CURATION_PARAMS[this.contentType];
  }

  shuffle(): void {
    if (this.totalCount >= 0) {
      this._totalPage = Math.ceil(this.totalCount / this.perPage);
      this._pages = this.randomPageNumbers(this._totalPage);
    }
  }

  private async checkTotalCount(): Promise<void> {
    try {
      // eslint-disable-next-line camelcase
      const results = await this.store.query(this.contentType, { per_page: 1, ...this.params });
      // @ts-expect-error
      this.totalCount = results.meta["total-count"] ?? results.meta.totalCount;
      this.shuffle();
    } finally {
      this.configReady.resolve(true);
    }
  }

  private randomPageNumbers(totalPages: number): number[] {
    if (totalPages <= 0) {
      return [];
    } else {
      // the number of items in the last page can be less than per_page, so it won't be shuffled
      const shuffledArray = shuffle(Array.from({ length: totalPages - 1 }, (_, i: number) => i + 1));
      return [...shuffledArray, totalPages];
    }
  }
}

export default class ContentsCuratorService extends Service {
  @service
  private declare store: Store;

  @service
  private declare honeybadger: HoneybadgerService;

  private _contentMeta = ALL_CONTENTS.map((contentType) => new ContentMeta(contentType, this.store));
  private _loading = false;

  /**
   * Config the curator optionally with contentConfig that specifies:
   * - what content(s) should be included
   * - how many items for a content type should be included in one page
   *
   * For example, the following contentConfig specifies the curator should include 5 graphics, 10 images and 20 pexel videos, no scenes and stroyblocks.
   * {
   *   contentTemplate: 5,
   *   pexelsPhoto: 10,
   *   pexelsVideo: 20
   * }
   *
   * calling `config()` triggers fetch of total counts for each specified content and then reshuffle the pages.
   * It is suggested to call `config()` ASAP before calling the `load()`, for example in `Route model()`.
   *
   * calling `config()` is optionally if the default configuration (10 items for each of 5 content) is expected
   */
  public async config(contentConfig?: { [key in ContentType]?: number }): Promise<void> {
    if (contentConfig) {
      await this.configReady();

      this._contentMeta.forEach((meta) => {
        if (contentConfig[meta.contentType]) {
          meta.perPage = contentConfig[meta.contentType]!;
          meta.active = true;
        } else {
          meta.active = false;
        }
      });
    }
  }

  /**
   * Shuffle the contents (e.g loading a particular page `load(x)` returns a different set of contents)
   */
  public async reshuffle(): Promise<void> {
    await this.configReady();

    this._contentMeta.forEach((meta) => {
      meta.shuffle();
    });
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get totalCounts(): { [key in ContentType]?: number } {
    return Object.fromEntries(this._contentMeta.map((meta) => [meta.contentType, meta.totalCount]));
  }

  private async configReady(): Promise<void> {
    await Promise.all(
      this.activeContentMeta.map(async (meta) => {
        await meta.configReady;
      })
    );
  }

  /**
   * Load the curated contents at the given page
   */
  async load(page = 1): Promise<ArrayProxy<SelectableAsset>> {
    await this.configReady();

    this._loading = true;
    const contents = await Promise.all(
      this.activeContentMeta.map(async (meta: ContentMeta) => {
        const shuffledPage = meta.shuffledPage(page);
        return shuffledPage ? this.fetchContent(meta, shuffledPage) : [];
      })
    );
    this._loading = false;

    const content = shuffle(contents.flat());
    return ArrayProxy.create({
      content,
      meta: { "per-page": content.length, page: page, "total-count": this.totalCount }
    }) as ArrayProxy<SelectableAsset>;
  }

  private async fetchContent(meta: ContentMeta, page: number): Promise<SelectableAsset[]> {
    try {
      const results = await this.store.query(meta.contentType, {
        // eslint-disable-next-line camelcase
        per_page: meta.perPage,
        page,
        ...meta.params
      });

      if (meta.contentType === ContentType.GRAPHICS) {
        return (results as unknown as ContentTemplate[]).map((contentTemplate) => contentTemplate.contentAsset);
      } else {
        return results.map((r) => r) as unknown as SelectableAsset[];
      }
    } catch (err) {
      this.honeybadger.notify(err as Error);
      return [];
    }
  }

  private get totalCount(): number {
    return sum(this.activeContentMeta.map((meta) => meta.totalCount));
  }

  private get activeContentMeta(): ContentMeta[] {
    return this._contentMeta.filter((meta) => meta.active);
  }
}
