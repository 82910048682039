import Transform from "@ember-data/serializer/transform";

export default class ArrayTransform extends Transform {
  serialize(deserialized?: unknown[]): unknown[] {
    return deserialized ?? [];
  }

  deserialize(serialized: unknown[]): unknown[] {
    return serialized;
  }
}

declare module "ember-data/types/registries/transform" {
  export default interface TransformRegistry {
    array: ArrayTransform;
  }
}
