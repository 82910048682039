import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import config from "client/config/environment";
import { isProdEnv } from "client/lib/env";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Subscription from "client/models/subscription";
import type AuthService from "client/services/auth";

interface AccountSubscriptionCancelArgs {
  fullSubscription?: Subscription;
  subscription?: Subscription;
}

export default class AccountSubscriptionCancelComponent extends Component<AccountSubscriptionCancelArgs> {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("account/subscription/cancel");

  get show(): boolean {
    return this.canCancelSubscription;
  }

  get showPlanName(): boolean {
    return !!this.args.subscription?.trialing;
  }

  get planName(): string | undefined {
    return this.args.fullSubscription?.planName;
  }

  private get canCancelSubscription(): boolean {
    const { fullSubscription } = this.args;

    return !!fullSubscription && !fullSubscription.canceled && !fullSubscription.trialing;
  }

  get churnKeyEnabled(): boolean {
    return !!window.churnkey && !!this.args.fullSubscription?.churnkeyAuthHash;
  }

  @action
  async reloadSubscription(): Promise<void> {
    await this.auth.reload();
  }

  @action
  invokeChurnkey(): void {
    if (this.churnKeyEnabled) {
      const { subscriptionId, stripeCustomerId, churnkeyAuthHash } = this.args.fullSubscription!;

      churnkey?.init("show", {
        subscriptionId,
        authHash: churnkeyAuthHash,
        customerId: stripeCustomerId,
        mode: isProdEnv() ? "live" : "test",
        appId: config.churnkeyAppId,
        record: true,
        onTrialExtension: () => this.reloadSubscription(),
        onDiscount: () => this.reloadSubscription(),
        onCancel: () => this.reloadSubscription()
      });
    }
  }
}
