import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type SelectOptionComponent from "./option/component";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum SelectSizes {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large"
}

export enum SelectVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary"
}

interface SelectComponentArgs {
  open?: boolean;
  selectedValue: unknown;
  size?: SelectSizes;
  variant?: SelectVariants;

  onChange?(value: unknown): unknown;
  onClose?(): unknown;
}

export default class SelectComponent extends Component<SelectComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/select");

  @tracked
  open: boolean;

  @tracked
  options: Array<SelectOptionComponent> = [];

  @tracked
  selectedOption?: SelectOptionComponent;

  @tracked
  currentValue: unknown;

  constructor(owner: object, args: SelectComponentArgs) {
    super(owner, args);

    this.currentValue = args.selectedValue;
    this.open = args.open ?? false;
  }

  @action
  didInsert(): void {
    document.addEventListener("click", this.globalClick);
    document.addEventListener("keydown", this.globalKeyDown);
  }

  private selectOption(component: SelectOptionComponent): void {
    this.selectedOption = component;
    this.currentValue = component.args.value;
  }

  @action
  onOptionSelect(component: SelectOptionComponent): void {
    this.selectOption(component);
    this.toggleMenu(false);
  }

  @action
  onOptionInsert(component: SelectOptionComponent): void {
    this.options.push(component);

    if (component.active) {
      this.selectOption(component);
    }
  }

  @action
  onOptionRemove(component: SelectOptionComponent): void {
    const index = this.options.indexOf(component);

    if (index > -1) {
      this.options.splice(index, 1);
    }
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener("click", this.globalClick);
    document.removeEventListener("keydown", this.globalKeyDown);
  }

  private getNextOption(): SelectOptionComponent | undefined {
    if (this.selectedOption) {
      const index = this.options.indexOf(this.selectedOption);
      const next = this.options[index + 1];

      if (next) {
        return next;
      }
    }

    return this.options[0];
  }

  private getPreviousOption(): SelectOptionComponent | undefined {
    if (this.selectedOption) {
      const index = this.options.indexOf(this.selectedOption);
      const previous = this.options[index - 1];

      if (previous) {
        return this.options[index - 1];
      }
    }

    return this.options[this.options.length - 1];
  }

  selectNextValue(): void {
    const next = this.getNextOption();

    if (next) {
      this.selectOption(next);
    }
  }

  selectPreviousValue(): void {
    const previous = this.getPreviousOption();

    if (previous) {
      this.selectOption(previous);
    }
  }

  @action
  globalClick(): void {
    this.toggleMenu(false);
  }

  @action
  globalKeyDown(event: KeyboardEvent): void {
    if (event.code === EventKeys.ESC) {
      this.toggleMenu(false);
    }
  }

  @action
  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();

    this.toggleMenu();
  }

  @action
  onKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case EventKeys.ENTER:
        this.toggleMenu(false);
        break;
      case EventKeys.SPACE:
        this.toggleMenu();
        break;
      case EventKeys.ARROW_UP:
        if (this.open) {
          this.selectPreviousValue();
        } else {
          this.toggleMenu(true);
        }
        break;
      case EventKeys.ARROW_DOWN:
        if (this.open) {
          this.selectNextValue();
        } else {
          this.toggleMenu(true);
        }
        break;
      default:
        break;
    }
  }

  toggleMenu(open = !this.open): void {
    if (this.open !== open) {
      this.open = open;

      if (!open) {
        this.args.onClose?.();
        this.args.onChange?.(this.currentValue);
      }
    }
  }

  get size(): string {
    return this.args.size ?? SelectSizes.DEFAULT;
  }

  get variant(): string {
    return this.args.variant ?? SelectVariants.PRIMARY;
  }
}
