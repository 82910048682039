import type { AudioClip, AudioAsset } from "../../audio/audio-clip";
import type { Timeline, TimelineFacade } from "../../timeline";
import { StrictMutation } from "./mutation";

export class AddAudioClipMutation extends StrictMutation<AudioClip | void> {
  grouped = false;

  audioClip?: AudioClip;

  constructor(
    public timeline: Timeline,
    public asset: AudioAsset,
    public category: string,
    public offset: number = 0,
    public duration: number = -1
  ) {
    super();
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    this.audioClip = await facade.newAudioClip(this.asset, this.category, this.offset, this.duration);
  }

  run(): AudioClip | undefined {
    if (this.audioClip) {
      this.timeline._audioClips = this.timeline._audioClips.concat(this.audioClip);

      return this.audioClip;
    }

    return;
  }

  revert(): AudioClip | void {
    if (this.audioClip) {
      this.timeline._removeAudioClip(this.audioClip);
    }
  }
}
