import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type AuthenticatedController from "./controller";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type ScreenDoorService from "client/services/screen-door";
import type SessionService from "client/services/session";

export default class AuthenticatedRoute extends Route<User> {
  @service
  declare auth: AuthService;

  @service
  declare screenDoor: ScreenDoorService;

  @service
  declare router: RouterService;

  @service
  declare session: SessionService;

  @service
  declare permissions: PermissionsService;

  async beforeModel(transition: Transition): Promise<void> {
    if (this.session.requireAuthentication(transition, this.authRoute(transition))) {
      try {
        await this.auth.loadCurrentUser();
      } catch {
        await this.session.invalidate();
      }
    }
  }

  async afterModel(_: User, transition: Transition): Promise<void> {
    await this.handleRedirection(transition);
  }

  @action
  willTransition(transition: Transition): boolean {
    const willLogout = transition.to.name.startsWith("authenticated.logout");

    if (!willLogout) {
      void this.handleRedirection(transition);
    }

    return false;
  }

  model(): User {
    return this.auth.currentUser as User;
  }

  private authRoute(transition: Transition): string {
    const { signUpAuth } = transition.to.queryParams;

    return signUpAuth ? "unauthenticated.sign-up" : "unauthenticated.login";
  }

  private shouldRedirectToOnboarding(transition: Transition): boolean {
    return !this.isOnboardingTransition(transition) && !this.auth.userSurveyComplete;
  }

  private isOnboardingTransition(transition: Transition): boolean {
    return transition.to.name.startsWith("authenticated.survey.onboarding");
  }

  private async handleRedirection(transition: Transition): Promise<void> {
    if (this.screenDoor.enforceInactiveTeamMemberMode) {
      this.screenDoor.redirectToInactiveTeamMemberMode(transition.to.name);
    } else if (this.isOnboardingTransition(transition)) {
      // Do nothing we are heading to the right spot (probably)
    } else if (this.shouldRedirectToOnboarding(transition)) {
      void this.router.transitionTo("authenticated.survey.onboarding");
    } else {
      this.screenDoor.handleRedirecting(transition.to.name);
    }
  }

  resetController(controller: AuthenticatedController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.create = undefined;
    }
  }

  queryParams = {
    create: {
      refreshModel: false
    }
  };
}
