import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export interface OptionFieldArgs {
  type?: string;
  label: string;
  value?: string | number | boolean;
  selectedValue?: string | number | boolean;
  onSelect?: (value: string | number) => Promise<void> | void;
}

export default class OptionFieldComponent<T extends OptionFieldArgs> extends Component<T> {
  styleNamespace = getStyleNamespace("north-star/option-field");

  @action
  async handleChange({ target }: Event): Promise<void> {
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    if (target.checked) {
      this.loading = true;

      try {
        await this.args.onSelect?.(target.value);
      } finally {
        this.loading = false;
      }
    }
  }

  @action
  handleClick(event: MouseEvent): void {
    if ((event.target as HTMLInputElement).checked) {
      void this.handleChange(event);
    }
  }

  @tracked
  loading = false;

  get selected(): boolean {
    if (this.isCheckbox) {
      return !!this.args.value;
    } else {
      return this.args.selectedValue === this.args.value;
    }
  }

  get inputType(): string {
    return this.args.type ?? "checkbox";
  }

  get isCheckbox(): boolean {
    return this.inputType === "checkbox";
  }
}
