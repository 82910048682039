import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type AdvancedEditorService from "./advanced-editor";
import type { Caption, Scene } from "client/lib/editor-domain-model";
import AssetFactory from "client/lib/scene/asset-factory";
import CaptionFactory from "client/lib/scene/caption-factory";

export default class AdvancedTimingService extends Service {
  @service
  declare store: Store;

  @service
  declare advancedEditor: AdvancedEditorService;

  @tracked
  visible = false;

  @tracked
  declare caption: Caption;

  @action
  async open(caption: Caption, scene: Scene): Promise<void> {
    if (this.advancedEditor.caption) {
      this.caption = this.advancedEditor.caption;
    } else {
      /**
       Re-fetches Caption Group since it can potentially be stale if it's duration
       or offset was changed
      */
      const zymbolGroup = await this.store.findRecord("zymbolGroup", caption.id);
      this.caption = await new CaptionFactory(new AssetFactory()).createCaption(scene, zymbolGroup);
    }

    this.visible = true;
  }

  @action
  async close(): Promise<void> {
    this.visible = false;
  }
}
