import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AccountSubscriptionRoute from "../route";
import type PaymentController from "./controller";
import type User from "client/models/user";
import type PaymentSourceService from "client/services/payment-source";

export interface PaymentRouteModel {
  user: User;
}

export default class PaymentRoute extends Route<PaymentRouteModel> {
  @service
  declare router: RouterService;

  @service
  declare paymentSource: PaymentSourceService;

  async beforeModel(): Promise<void> {
    if (!this.paymentSource.canAddCard) {
      await this.router.replaceWith("authenticated.account.subscription");
    }
  }

  model(): PaymentRouteModel {
    const { user } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    return {
      user
    };
  }

  resetController(controller: PaymentController, isExiting: boolean): void {
    if (isExiting) {
      controller.error = undefined;
      controller.loading = false;
    }
  }
}
