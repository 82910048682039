import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type NotificationsService from "./notifications";
import { ContributorTypes } from "client/models/folder";
import type AjaxService from "client/services/ajax";
import type { IdentifiablePayload } from "client/services/ajax";
import type AuthService from "client/services/auth";
import { notify } from "client/services/honeybadger";

const HEARTBEAT_INTERVAL = 10000;

export default class EditorHeartbeatService extends Service {
  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  projectId: number | undefined;
  userId: number | undefined;

  start(projectId: number): void {
    this.projectId = projectId;
    this.userId = this.currentUserId();

    void this.beat();
  }

  stop(): void {
    this.userId = undefined;
    this.projectId = undefined;
  }

  beating(): boolean {
    return !!this.projectId && !!this.userId;
  }

  redirectAndNotify(error: any): void {
    if (error.errors) {
      const { status } = error.errors[0];

      if (status === 401 || status === 403) {
        this.notifications.error("Oops! It looks like you have not been given access to this project.");
      } else if (status === 404) {
        this.notifications.error("Oops! It looks like this project has been removed.");
      } else {
        this.notifications.error("Oops! It looks like something went wrong accessing this project.");

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        notify(error);
      }

      void this.router.transitionTo("authenticated.folders.library", ContributorTypes.TEAM);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      notify(error);
    }
  }

  async beat(): Promise<void> {
    if (!this.beating()) {
      return;
    }

    try {
      const data = (await this.postToServer()) as IdentifiablePayload;
      this.store.pushPayload("projectEditAccess", data);

      this.expireOldHeartbeats();
    } catch (e) {
      this.redirectAndNotify(e);
    }

    setTimeout(() => this.beat(), HEARTBEAT_INTERVAL);
  }

  currentUserId(): number | undefined {
    const id = this.auth.currentUser?.id;
    return id ? Number(id) : undefined;
  }

  async updateActiveEditors(id: string): Promise<void> {
    try {
      const data: IdentifiablePayload = await this.ajax.api(`/project-edit-accesses?project_id=${id}`);
      this.store.pushPayload("projectEditAccess", data);

      this.expireOldHeartbeats();
    } catch (error) {
      // @ts-expect-error
      notify(error);
    }
  }

  postToServer(): Promise<any> {
    return this.ajax.api("/project-edit-accesses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          type: "project-edit-accesses",
          attributes: {
            "project-id": this.projectId
          }
        }
      })
    });
  }

  expireOldHeartbeats(): void {
    const expiredRecords = this.store
      .peekAll("projectEditAccess")
      .filter((heartbeat) => new Date() >= heartbeat.currentUntil);

    expiredRecords.forEach((record) => this.store.unloadRecord(record));
  }
}
