import { service } from "@ember/service";
import Model, { attr, belongsTo } from "@ember-data/model";
import type Store from "@ember-data/store";
import type { RenderZymbol } from "renderer-engine";
import type Folder from "./folder";
import type ProjectOwner from "./project-owner";
import type ProjectShare from "./project-share";
import type VideoPage from "./video-page";
import type AspectRatio from "client/models/aspect-ratio";
import type Project from "client/models/project";
import type ThumbnailScene from "client/models/thumbnail-scene";
import type User from "client/models/user";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type ProjectThumbnailUpdateService from "client/services/project-thumbnail-update";

export default class ProjectPreview extends Model {
  @service
  declare permissions: PermissionsService;

  @service
  declare auth: AuthService;

  @service
  declare ajax: AjaxService;

  @service
  declare store: Store;

  @service
  declare projectThumbnailUpdate: ProjectThumbnailUpdateService;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("user", { async: true, inverse: null })
  declare user: User;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("aspect-ratio", { async: false, inverse: null })
  declare aspectRatio: AspectRatio;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-owner", { async: false, inverse: null })
  declare owner: ProjectOwner;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-share", { async: false, inverse: null })
  declare projectShare?: ProjectShare;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("video-page", { async: true, inverse: null })
  declare videoPage?: VideoPage;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("thumbnail-scene", { async: true, inverse: null })
  declare thumbnailScene?: ThumbnailScene;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("folder", { async: true, inverse: null })
  declare folder?: Folder;

  @attr("string", { allowNull: true })
  declare posterUrl?: string;

  @attr("string", { allowNull: true })
  declare thumbnailSource?: string;

  @attr("boolean")
  declare teamShared: boolean;

  @attr("number")
  declare duration: number;

  @attr("string")
  declare title: string;

  @attr("string", { allowNull: true })
  declare videoUrl?: string;

  @attr("string", { allowNull: true })
  declare lowResVideoUrl?: string;

  @attr("string", { allowNull: true })
  declare lowResThumbUrl?: string;

  @attr("boolean")
  declare template: boolean;

  @attr("json", { defaultValue: () => [] })
  declare thumbnailZymbolsToRender: RenderZymbol[];

  @attr("boolean")
  declare purchased: boolean;

  @attr("boolean")
  declare isOwner: boolean;

  @attr("boolean")
  declare isTeamOwner: boolean;

  @attr("boolean")
  declare rendered: boolean;

  @attr("date")
  declare createdAt: Date;

  @attr("date")
  declare updatedAt: Date;

  @attr("date")
  declare renderedAt?: Date;

  @attr("boolean")
  declare inFolder: boolean;

  @attr("number", { allowNull: true })
  declare countOfViews?: number;

  @attr("boolean")
  declare published: boolean;

  @attr("boolean")
  declare showComments: boolean;

  @attr("number")
  declare countOfComments: number;

  get projectUserId(): string {
    // @ts-expect-error
    return this.belongsTo("user").id();
  }

  get canUploadThumbnail(): boolean {
    return !this.thumbnailSource || this.thumbnailSource === "browser";
  }

  get zymbolsToRender(): RenderZymbol[] {
    return this.thumbnailZymbolsToRender;
  }

  get folderId(): string | undefined {
    // @ts-expect-error
    return this.belongsTo("folder").id();
  }

  public async deleteProject(): Promise<void> {
    await this.ajax.api(`/projects/${this.id}`, { method: "DELETE" });
  }

  public async loadZymbolsToRenderDependencies(): Promise<void> {
    const scene = await this.thumbnailScene;

    if (scene) {
      this.thumbnailZymbolsToRender = scene.zymbolsToRender;
    }
  }

  public async shareProject(): Promise<void> {
    await this.store
      .createRecord("projectShare", {
        projectId: this.id
      })
      .save();
    await this.reload();
  }

  public async unshareProject(): Promise<void> {
    await this.projectShare?.destroyRecord();
    await this.reload();
  }

  public async publish(): Promise<VideoPage> {
    const videoPage = await this.store
      .createRecord("videoPage", {
        title: this.title,
        sourceProjectId: this.id,
        sourceProjectType: "Project"
      })
      .save();

    await this.reload();

    return videoPage;
  }

  public async unpublish(videoPage: VideoPage): Promise<void> {
    await videoPage.destroyRecord();
    await this.reload();
  }

  public async generateThumbnail(): Promise<string | undefined> {
    if (!this.posterUrl) {
      return this.projectThumbnailUpdate.generateProjectThumbnail(this);
    } else {
      return undefined;
    }
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectPreview: ProjectPreview;
  }
}
