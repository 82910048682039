import { guidFor } from "@ember/object/internals";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface FolderThumbnailArgs {
  image?: string;
  title?: string;
  count?: number;
  label?: string;
  folderLabel?: string;
}

export default class FolderThumbnailComponent extends Component<FolderThumbnailArgs> {
  clipPathId = `${guidFor(this)}__folderClipPath`;

  styleNamespace = getStyleNamespace("app/folder-thumbnail");

  get thumbnailStyle(): SafeString {
    return htmlSafe(`background-image: url('${this.args.image ?? ""}'); clip-path: url(#${this.clipPathId});`);
  }

  get showLabelOrCount(): boolean {
    return this.labelOrCount !== undefined;
  }

  get labelOrCount(): number | string | undefined {
    return this.args.label ?? (this.args.count || undefined);
  }
}
