import { guidFor } from "@ember/object/internals";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface LoaderLogoArgs {
  showOverlay?: boolean;
  message?: string;
  showEllipsis?: boolean;
}

export default class LoaderLogoComponent extends Component<LoaderLogoArgs> {
  elementId = guidFor(this);
  styleNamespace = getStyleNamespace("app/loader-logo");
}
