import { action } from "@ember/object";
import { service } from "@ember/service";
import DynamicIslandActionComponent from "client/components/discovery/dashboard/dynamic-island/action/component";
import type CollaborationService from "client/services/collaboration";

export default class InviteTeamSecondaryActionComponent extends DynamicIslandActionComponent {
  actionName = "invite team";

  @service
  declare collaboration: CollaborationService;

  @action
  inviteTeamMembers(): void {
    this.onInteraction();

    this.collaboration.showTeamInviteModal("dashboard - secondary action - invite team");
  }
}
