import type { Element } from "../../element";
import type { Caption } from "../../scene";
import { Logo, Text } from "../../scene";
import { VanillaMutation } from "./vanilla-mutation";

export class DeleteCaptionElementMutation extends VanillaMutation<Caption> {
  constructor(caption: Caption, private element: Element) {
    super(caption);
  }

  apply(caption: Caption): void {
    if (this.element instanceof Text) {
      caption._removeTextElement(this.element);
    } else if (this.element instanceof Logo) {
      caption._removeLogoElement(this.element);
    }
  }
}
