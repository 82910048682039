import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Provider } from "client/components/auth/social-login-buttons/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SessionService from "client/services/session";

interface SocialLoginButtonArgs {
  provider: Provider;
}

export default abstract class SocialLoginButtonComponent extends Component<SocialLoginButtonArgs> {
  @service
  declare session: SessionService;

  @tracked
  authenticating = false;

  styleNamespace = getStyleNamespace("auth/social-login-button");

  @action
  async authenticate(): Promise<void> {
    this.authenticating = true;

    try {
      await this.session.authenticate("authenticator:torii", this.providerType);
    } catch (e) {
      // remote closed or authorization denied
    } finally {
      if (!this.isDestroyed) {
        this.authenticating = false;
      }
    }
  }

  get label(): string {
    return this.providerName;
  }

  get disabled(): boolean {
    return this.authenticating;
  }

  get iconName(): string {
    return `icon/${this.args.provider.icon}`;
  }

  private get providerType(): string {
    return this.args.provider.type;
  }

  private get providerName(): string {
    return this.args.provider.name;
  }
}
