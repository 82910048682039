import { service } from "@ember/service";
import Model, { attr, hasMany } from "@ember-data/model";
import TrackingEvents, { Locations } from "client/events";
import { uniq } from "client/models/collections";
import type { ManyArray } from "client/models/ember-data-types";
import type Folder from "client/models/folder";
import type Font from "client/models/font";
import type Subscription from "client/models/subscription";
import type TeamMembership from "client/models/team-membership";
import type User from "client/models/user";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type SubscriptionsService from "client/services/subscriptions";
import type TrackingService from "client/services/tracking";

export enum Invitation {
  ALL_TEAM_MEMBERS = "team-members",
  TEAM_OWNER = "team-owner"
}

interface Preferences {
  invitation: Invitation;
}

export default class Team extends Model {
  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @service
  declare subscriptions: SubscriptionsService;

  @service
  declare ajax: AjaxService;

  @service
  declare tracking: TrackingService;

  @attr("array", { defaultValue: () => [] })
  declare emails: string[];

  @attr("string")
  declare ownerId: string;

  @attr("string")
  declare ownerEmail: string;

  @attr("json")
  declare preferences: Preferences;

  @attr("string")
  declare readonly invitePreference: Invitation;

  @attr("boolean")
  declare isTeamOwner: boolean;

  @attr("number")
  declare publisherSeats: number;

  @attr("number")
  declare purchasedSeats: number;

  // eslint-disable-next-line no-null/no-null
  @hasMany("team-membership", { async: false, inverse: null })
  declare teamMemberships: ManyArray<TeamMembership>;

  // eslint-disable-next-line no-null/no-null
  @hasMany("folder", { async: true, inverse: null })
  declare folders: ManyArray<Folder>;

  // eslint-disable-next-line no-null/no-null
  @hasMany("font", { async: true, inverse: null })
  declare fonts: ManyArray<Font>;

  get currentUser(): User | undefined {
    return this.auth.currentUser;
  }

  get memberCount(): number {
    return this.teamMemberships.filter((member) => member?.isActive).length;
  }

  get hasMultipleMembers(): boolean {
    return this.memberCount > 1;
  }

  get sortedActiveMemberList(): Array<TeamMembership> {
    return this.sortedMemberList.filter((member) => member.isActive);
  }

  get sortedInactiveMemberList(): Array<TeamMembership> {
    return this.sortedMemberList.filter((member) => !member.isActive);
  }

  get isTeamMember(): boolean {
    return !!this.teamMemberships.find(({ userId }) => userId === this.currentUser?.id);
  }

  get canInviteTeamMembers(): boolean {
    return this.isTeamOwner || this.invitePreference === Invitation.ALL_TEAM_MEMBERS;
  }

  get canDelete(): boolean {
    return this.isTeamOwner && !this.hasMultipleMembers;
  }

  get canPurchaseSeats(): boolean {
    return (
      this.permissions.has("feature_seats_purchase") &&
      !!this.currentUser?.canManageSubscription &&
      !this.subscriptions.isTrialing
    );
  }

  public async addTeamMember(email: string, withTracking = true): Promise<void> {
    const valid = await this.checkForExistingEmail(email);

    if (!valid) {
      throw Error("This email already belongs to a team");
    }

    try {
      this.emails.push(email);
      await this.save();
      if (withTracking) {
        this.trackAddTeamMember(email);
      }
    } catch (err) {
      this.rollbackAttributes();
      // @ts-expect-error
      const error = err?.errors?.[0]?.detail || err;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(error);
    }
  }

  public async checkForExistingEmail(email: string): Promise<boolean> {
    const params = new URLSearchParams({
      email,
      // eslint-disable-next-line camelcase
      owner_email: this.ownerEmail
    });

    return this.ajax.api(`/teams/member/validate?${params.toString()}`);
  }

  private get fullySubscribed(): boolean {
    return !!this.currentFullSubscription;
  }

  private get currentFullSubscription(): Subscription | undefined {
    return this.subscriptions.currentFullSubscription;
  }

  private get sortedMemberList(): Array<TeamMembership> {
    // @ts-expect-error
    const ids = this.hasMany("teamMemberships").ids();
    const records = ids.map((id: string) => this.store.peekRecord("teamMembership", id) || undefined);
    const teamMemberships = records.filter((member: TeamMembership | undefined): member is TeamMembership => !!member);
    const sortedList = uniq(
      [
        teamMemberships.find((member: TeamMembership) => member.userId === this.ownerId),
        teamMemberships.find((member: TeamMembership) => member.userId === this.currentUser?.id),
        ...teamMemberships.filter(
          (member: TeamMembership) => member.userId !== this.ownerId && member.userId !== this.currentUser?.id
        )
      ].filter((member: TeamMembership | undefined): member is TeamMembership => !!member)
    );

    return sortedList;
  }

  private trackAddTeamMember(email: string): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_INVITE_TEAM_MEMBERS, {
      /* eslint-disable camelcase */
      account_type: this.fullySubscribed ? "paid" : "free",
      location: Locations.LOCATION_TEAM_SETTINGS,
      team_id: this.id,
      team_size: this.memberCount,
      invites_sent: 1,
      members_added: [email]
      /* eslint-enable camelcase */
    });
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    team: Team;
  }
}
