import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Element } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";
import type MouseService from "client/services/mouse";

interface Args {
  element: Element;
}

export default class FloatingPanelsLayerComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panels/layers/layer");

  @service
  private declare advancedEditor: AdvancedEditorService;

  @service
  private declare mouse: MouseService;

  get active(): boolean {
    return this.args.element === this.advancedEditor.element;
  }

  @action
  async onClick(): Promise<void> {
    await this.advancedEditor.transitionToElement(this.args.element);
  }

  @action
  onMouseDown(event: MouseEvent): void {
    this.mouse.onMouseDown(event);
  }
}
