import { tracked } from "@glimmer/tracking";
import { Guides } from "../guides";
import { Vector2 } from "../math";
import { Rect } from "../math/rect";
import { Snapper } from "../snapper";
import type { Line } from "./line";
import { Lines } from "./lines";
import { SNAP_THRESHOLD } from "./snap-threshold";
import { Styles } from "./styles";

/*
  Grid assumes a normalized unit interval coordinate system (e.g. 1x1)
  - Define the grid area by number of cells, e.g. 32 cells by 18 cells would be { x: 32, y: 18 }
  - Define the margin areas by number of grid cells, e.g. 3.5 cells left/right & 2 cells top/bottom would be { x: 3.5, y: 2 }
*/
export class Grid {
  public marginCells: { x: number; y: number };

  @tracked
  public presenting = false;

  @tracked
  public snapLines: Line[];

  private parentSnapLines: Line[];

  @tracked
  public intersections?: Vector2[];

  @tracked
  public guideLines?: Line[];

  readonly parentRect = new Rect(new Vector2(0, 0), new Vector2(1, 1));

  constructor(public cells: { x: number; y: number }, marginCells?: { x: number; y: number }) {
    this.marginCells = marginCells || { x: 0, y: 0 };

    this.snapLines = [...Lines.getGridLines(this.cells), ...Lines.getMarginLines(this.marginCells, this.cells)];
    this.parentSnapLines = Lines.rectLines(this.parentRect);
  }

  public snapRect(
    rect: Rect,
    options: {
      snapLines?: Line[];
      snapToGrid?: boolean;
      gridThreshold?: number;
    }
  ): Rect {
    const snapLines: Line[] = [...this.parentSnapLines];

    if (options.snapToGrid || this.presenting) {
      snapLines.push(...this.getGridSnapLines(options.gridThreshold));
    }
    if (options.snapLines) {
      snapLines.push(...options.snapLines);
    }

    const snapDelta = Snapper.snapToClosestLine(Lines.rectLines(rect), snapLines);
    const snappedRect = new Rect(rect.position.add(snapDelta), rect.size);

    return snappedRect;
  }

  public updateGuides(rect: Rect, options?: { snapLines?: Line[]; rects?: Rect[] }): void {
    const _snapLines: Line[] = this.allSnapLines;
    _snapLines.push(...(options?.snapLines || []));
    this.guideLines = Guides.getGuideLines(Lines.rectLines(rect), _snapLines);
    this.intersections = Guides.getIntersections([rect, ...(options?.rects || [])], this.guideLines);
  }

  public getGridSnapLines(threshold = SNAP_THRESHOLD): Line[] {
    const gridLines: Line[] = Lines.getGridLines(this.cells, threshold);
    const marginLines: Line[] = Lines.getMarginLines(this.marginCells, this.cells, threshold);

    return [...gridLines, ...marginLines];
  }

  public clearGuides(): void {
    this.intersections = undefined;
    this.guideLines = undefined;
  }

  public getMarginStyles(width: number): string {
    return Styles.getMarginStyles(this.marginCells, this.getCellSize(width));
  }

  public getGridStyles(width: number): string {
    return Styles.getGridStyles(this.getCellSize(width));
  }

  private get allSnapLines(): Line[] {
    if (this.presenting) {
      return [...this.snapLines, ...this.parentSnapLines];
    }
    return [...this.parentSnapLines];
  }

  private getCellSize(parentWidth: number): number {
    return parentWidth / this.cells.x + 1 / this.cells.x;
  }
}
