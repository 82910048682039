import Component from "@glimmer/component";
import type { ValidationResult } from "client/lib/validation";

interface PasswordFieldArgs {
  value: string;
  validation?: ValidationResult;
}

export default class PasswordField extends Component<PasswordFieldArgs> {
  get passwordInvalid(): boolean {
    return this.args.validation?.isValid === false;
  }

  get passwordError(): string | undefined {
    if (this.args.validation?.isValid === false) {
      return this.args.validation.error;
    } else {
      return undefined;
    }
  }
}
