import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectContentBarService from "client/services/project-content-bar";
import type { ContentBarPanelType } from "client/services/project-content-bar";

interface Args {
  icon: string;
  label: string;
  panel: ContentBarPanelType;
}

export default class ProjectContentBarNavItem extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  styleNamespace = getStyleNamespace("project-content-bar/nav/item");

  @action
  onClick(ev: MouseEvent): void {
    ev.stopPropagation();

    if (this.isActive) {
      this.projectContentBar.hide();
    } else {
      this.projectContentBar.show(this.args.panel);
    }
  }

  get iconComponent(): string {
    return `icon/${this.args.icon}`;
  }

  get isActive(): boolean {
    return this.projectContentBar.expanded && this.projectContentBar.isCurrentPanel(this.args.panel);
  }
}
