export { trimAlphaHex, alphaHexToDecimal } from "renderer-engine";

const HEX_REGEX = /^(?:#|0x)((?:[\dA-F]{3}){1,2})([\dA-F]{2})?$/i;
const expandShorthandHex = (hex: string): string => {
  return hex
    .split("")
    .map((hex) => hex + hex)
    .join("");
};

export const decimalToAlphaHex = (dec: number): string =>
  Math.round(dec * 255)
    .toString(16)
    .padStart(2, "0")
    .toUpperCase();

export const rgbaToHex = (value: string): string => {
  if (!value.startsWith("rgb")) {
    return value;
  }

  value = value.replace(/^[^\d]+/, "").replace(/[^\d]+$/, "");

  let alphaHex = "FF";
  const rgbaValues = value.split(",");

  if (rgbaValues.length === 4) {
    const alphaFloat = parseFloat(rgbaValues.pop() || "1");
    alphaHex = decimalToAlphaHex(alphaFloat);
  }

  const colorHex = rgbaValues.map((component) => `00${parseInt(component, 10).toString(16)}`.slice(-2)).join("");

  return `#${colorHex}${alphaHex}`;
};

export const isValidHexColor = (hex: string): boolean => {
  return HEX_REGEX.test(hex);
};

export const parseHex = (
  hex?: string,
  expandShorthand = false
): { input: string; value: string; alpha?: string } | undefined => {
  if (!hex) {
    return;
  }

  const matches = HEX_REGEX.exec(hex);

  if (!matches) {
    return;
  }

  let [, value] = matches;
  const [input, , alpha] = matches;

  if (!input || !value) {
    return;
  }

  if (expandShorthand && value.length === 3) {
    value = expandShorthandHex(value);
  }

  return { input, value, alpha };
};

export const formatHex = (color: string): string => {
  if (!color?.length) {
    return "#";
  }

  return `#` + color.replace(/[^0-9A-F]/gi, "").toUpperCase();
};
