import type { Scene } from "../../scene";
import type { Timeline, TimelineFacade } from "../../timeline";
import { VanillaMutation } from "./vanilla-mutation";

export class DuplicateSceneMutation extends VanillaMutation<Timeline> {
  private originalSceneId: string;
  private scene?: Scene;
  private order?: number;

  constructor(timeline: Timeline, sceneId: string, order?: number) {
    super(timeline);
    this.originalSceneId = sceneId;
    this.order = order;
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    this.scene = await facade.duplicateScene(this.originalSceneId);
  }

  apply(timeline: Timeline): Scene | void {
    if (this.scene) {
      if (this.order === undefined) {
        timeline._addScene(this.scene);
      } else {
        timeline._insertScene(this.scene, this.order);
      }
    }
    return this.scene;
  }
}
