import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface LibraryHeaderArgs {
  query?: string;
  searchPlaceholder?: string;
  search: (query: string) => void;
}

export default class LibraryHeaderComponent extends Component<LibraryHeaderArgs> {
  styleNamespace = getStyleNamespace("library-header");
}
