/**
 * Clamp a number to within given min and max values
 */
export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

/**
 * Round a number to a given number of decimal places
 */
export const toFixed = (num: number, precision = 2): number => Number(num.toFixed(precision));

/**
 * Round a number to the nearest step interval (snapping)
 */
export const snap = (num: number, step: number): number => Math.round(num / step) * step;

/**
 * Return linear interpolation between x and y by amount of z.
 */
export const lerp = (x: number, y: number, z: number): number => (1 - z) * x + z * y;
