import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import { OwnerTypes } from "client/models/media-asset";
import type SelectableAsset from "client/models/selectable-asset";
import type ActiveStorageService from "client/services/active-storage";
import { FILE_UPLOAD_ERROR_NOTIFICATION } from "client/services/active-storage";
import type AssetLibraryService from "client/services/asset-library";
import { ASSET_LIBRARY_IMAGE_MIMETYPES, ASSET_LIBRARY_VIDEO_MIMETYPES } from "client/services/asset-library";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type TeamService from "client/services/team";

interface Args {
  showLoaderOnSelect: boolean;
  perPage: number;
  parentRouteName?: string;
  onSelect?: (asset: SelectableAsset) => Promise<void>;
  previewOnSelect?: boolean;
  folder?: Folder;
  ownerType?: OwnerTypes;
  order?: string;
  onChangeOrder?: (order: string) => void;
  title?: string;
  hideToggle?: boolean;
  breadcrumbRoute?: boolean;
  refresh?: () => void;
}

export default class AssetManagementBaseComponent extends Component<Args> {
  @service
  declare assetLibrary: AssetLibraryService;

  @service
  declare activeStorage: ActiveStorageService;

  @service
  declare notifications: NotificationsService;

  @service
  private declare permissions: PermissionsService;

  @service
  declare team: TeamService;

  @tracked
  ownerType: OwnerTypes = this.args.ownerType || OwnerTypes.USER;

  @tracked
  showLoader = false;

  @tracked
  order: string;

  @tracked
  reload = false;

  styleNamespace = getStyleNamespace("asset-management/base");

  constructor(owner: object, args: Args) {
    super(owner, args);

    this.order = args.order || this.assetLibrary.filterOrder;
  }

  @action
  async refreshComponent(): Promise<void> {
    this.reload = !this.reload;
  }

  @action
  async refreshComponentAndParent(): Promise<void> {
    this.reload = !this.reload;
    await this.args.refresh?.();
  }

  @action
  setOwnerType(owner: OwnerTypes): void {
    this.ownerType = this.args.ownerType ? this.args.ownerType : owner;
  }

  @action
  didUpdateOwnerType(_element: HTMLElement, [ownerType]: [OwnerTypes]): void {
    this.setOwnerType(ownerType);
  }

  @action
  async select(asset: SelectableAsset): Promise<void> {
    this.showLoader = true;

    try {
      await this.args.onSelect?.(asset);
    } finally {
      this.showLoader = false;
    }
  }

  @action
  onChangeOrder(order: string): void {
    this.args.onChangeOrder?.(order);
    this.order = order;
  }

  @action
  async onUpload(): Promise<void> {
    const files = await this.activeStorage.showFilePicker({
      accept: this.acceptMimeTypes
    });

    await this.uploadFiles(files);

    await this.refreshComponentAndParent();
  }

  private async uploadFiles(files: FileList | File[]): Promise<void> {
    try {
      const total = files.length;
      let i = 1;

      for (const file of files) {
        const msg = total > 1 ? `(${i++}/${total})` : "";

        if (this.isTeamAssets) {
          await this.activeStorage.createTeamAsset(file, undefined, msg);
        } else {
          await this.activeStorage.createUserAsset(file, undefined, msg);
        }
      }
    } catch (err: any) {
      this.notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
    }
  }

  get acceptMimeTypes(): string {
    let mimeTypes = ASSET_LIBRARY_IMAGE_MIMETYPES;

    if (!this.permissions.has("feature_svg_upload")) {
      mimeTypes = mimeTypes.filter((e) => e !== "image/svg+xml");
    }
    return `${mimeTypes.join(",")},${ASSET_LIBRARY_VIDEO_MIMETYPES.join(",")}`;
  }

  get errorNotificationMessage(): string {
    return this.isTeamAssets
      ? "There was a problem loading team assets"
      : "There was a problem loading your uploaded files";
  }

  get isTeamAssets(): boolean {
    return this.ownerType === OwnerTypes.TEAM;
  }

  get emptyLibraryType(): string {
    return this.isTeamAssets ? "team" : "personal";
  }

  get isInAssetLibrary(): boolean {
    return !!this.args.parentRouteName || false;
  }

  get showInvitePeople(): boolean {
    return !this.canAddFolder && this.team.canInviteTeamMembers;
  }

  private get canAddFolder(): boolean {
    if (this.isTeamAssets) {
      return this.team.hasMultipleMembers;
    } else {
      return true;
    }
  }
}
