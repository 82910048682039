export default (aspectRatio: string): string => {
  const aspectRatioValues = parseAspectRatio(aspectRatio);

  if (!aspectRatioValues) {
    return aspectRatio;
  }

  const { width, height } = aspectRatioValues;

  const gcd = calculateGCD(width, height);
  const aspectRatioWidth = width / gcd;
  const aspectRatioHeight = height / gcd;
  return `${aspectRatioWidth}:${aspectRatioHeight}`;
};

const parseAspectRatio = (aspectRatioString: string): { width: number; height: number } | undefined => {
  const parts = aspectRatioString.split(":");
  if (parts.length !== 2 || !parts[0] || !parts[1]) {
    return undefined;
  }

  return { width: parseInt(parts[0], 10), height: parseInt(parts[1], 10) };
};

const calculateGCD = (a: number, b: number): number => {
  if (b === 0) {
    return a;
  }
  return calculateGCD(b, a % b);
};
