import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ToggleSliderComponentArgs {
  toggle?(event: Event): unknown;
  checked?: boolean;
  disabled?: boolean;
}

export default class ToggleSliderComponent extends Component<ToggleSliderComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/toggle-slider");

  @action
  toggleProperty(ev: MouseEvent): void {
    this.args.toggle?.(ev);
  }
}
