import type { Scene } from "../../scene";
import type { Timeline } from "../../timeline";
import { VanillaMutation } from "./vanilla-mutation";

export class DeleteSceneMutation extends VanillaMutation<Timeline> {
  constructor(timeline: Timeline, private scene: Scene) {
    super(timeline);
  }

  apply(timeline: Timeline): void {
    timeline._removeScene(this.scene);
  }
}
export class DeleteSceneByIdMutation extends VanillaMutation<Timeline> {
  constructor(timeline: Timeline, private id: string) {
    super(timeline);
  }

  apply(timeline: Timeline): void {
    const scene = timeline.getSceneById(this.id);
    if (scene) {
      timeline._removeScene(scene);
    }
  }
}
