import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
export class Provider {
  constructor(public name: string, public type: string, public icon: string) {}
}

interface Args {
  type: string;
}

export default class SocialLoginButtonsComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("auth/social-login-buttons");

  get google(): Provider {
    return new Provider(`${this.labelPrefix} with Google`, "google-oauth2", "google");
  }

  get microsoft(): Provider {
    return new Provider(`${this.labelPrefix} with Microsoft`, "azure-ad2-oauth2", "microsoft");
  }

  private get labelPrefix(): string {
    return this.args.type === "sign_in" ? "Log in" : "Sign up";
  }
}
