import { StrictMutation } from "./mutation";

function coalesce(mutations: StrictMutation[]) {
  return mutations.filter((m, i) => {
    return !mutations.slice(i + 1).find((later) => m.isSupercededBy(later));
  });
}

export class ExplicitTransaction extends StrictMutation<Promise<void>> {
  grouped = false;
  undoable = true;
  private phases: {
    update: StrictMutation<any>[];
    save: StrictMutation<any>[];
    route: StrictMutation<any>[];
    cleanup: StrictMutation<any>[];
  } = { update: [], save: [], route: [], cleanup: [] };

  constructor(...args: StrictMutation[]) {
    super();
    this.append(args);
  }

  isEmpty(): boolean {
    return Object.values(this.phases).every((phase) => !phase.length);
  }

  append(mutations: StrictMutation<any>[]): void {
    mutations.forEach((m) => this.phases[m.executionPhase].push(m));
    this.grouped = Object.values(this.phases).every((phase) => phase.every((m) => m.grouped));
  }

  run(): Promise<void> {
    const mutations = coalesce([
      ...this.phases.update,
      ...this.phases.save,
      ...this.phases.route.slice(0, 1),
      ...this.phases.cleanup
    ]);
    return this.createAsyncSequence(mutations.map((m) => m.run.bind(m)));
  }

  revert(): Promise<void> {
    const mutations = coalesce([
      ...[...this.phases.update].reverse(),
      ...this.phases.save,
      ...this.phases.route.slice(-1),
      ...this.phases.cleanup
    ]);
    return this.createAsyncSequence(mutations.map((m) => m.revert.bind(m)));
  }

  async createAsyncSequence(functions: (() => Promise<any>)[]): Promise<void> {
    for (const f of functions) {
      await f();
    }
  }
}
