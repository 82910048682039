import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type ProjectContentBarService from "./project-content-bar";
import { getCookie, setCookie } from "client/lib/cookie";

export default class ScriptWorkstationService extends Service {
  @service
  private declare projectContentBar: ProjectContentBarService;

  @tracked
  _expandSlides = false;

  constructor(...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(...args);

    if (!getCookie("script_workstation")) {
      setCookie("script_workstation", Date.now().toString());
      this._expandSlides = true;
    }
  }

  @action
  collapse(): void {
    this._expandSlides = false;
  }

  @action
  expand(): void {
    this._expandSlides = true;
  }

  @action
  toggle(): void {
    this._expandSlides = !this._expandSlides;
  }

  get visible(): boolean {
    return !this.projectContentBar.expanded;
  }

  get expanded(): boolean {
    return this._expandSlides;
  }
}
