import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class AppNavNewComponent extends Component {
  styleNamespace = getStyleNamespace("app-nav/new");

  @tracked
  open = false;

  @tracked
  menuTarget!: HTMLElement;

  @action
  closeMenu(): void {
    this.open = false;
  }

  @action
  toggleMenu(): void {
    this.open = !this.open;
  }

  @action
  setMenuTarget(element: HTMLElement): void {
    this.menuTarget = element;
  }
}
