import type { Bounds, Size, AllowableAssetContainer } from "renderer-engine";
import { AssetSizes } from "renderer-engine";
import { svgToSvgAssetContainer } from "./svg";
import AssetCrop from "client/lib/asset-crop";
import {
  calculateDefaultBackgroundDimensions,
  calculateDefaultLogoDimensions,
  calculateDefaultWatermarkDimensions,
  getAssetWidthAndHeight
} from "client/lib/dimensions";
import type { Asset, Media, Scene } from "client/lib/editor-domain-model";
import {
  Svg,
  Animation,
  Background,
  Image,
  Rect,
  VideoClip,
  Watermark,
  getDefaultRect
} from "client/lib/editor-domain-model";
import { animationToAnimationAssetContainer } from "client/lib/timeline/animation";
import { imageToImageAssetContainer } from "client/lib/timeline/image";
import { videoToVideoAssetContainer } from "client/lib/timeline/video";

export const defaultUpperSizeLimit = { width: 0.85, height: 0.85 };
export const defaultLowerSizeLimit = { width: 0.05, height: 0.05 };
const defaultNoAssetSize = { width: 0.2, height: 0.2 };

export interface ResetPositionOptions {
  alignMediaToCenter?: boolean;
  centerOnPosition?: boolean;
  upperSizeLimit?: Size;
  lowerSizeLimit?: Size;
}

export const getResetPosition = async (media: Media, scene: Scene, options?: ResetPositionOptions): Promise<Rect> => {
  const assetContainer = getAssetContainer(media.asset, scene);
  if (!assetContainer) {
    return getDefaultRect();
  }

  const aspectRatio = { width: scene.aspectRatio[0], height: scene.aspectRatio[1] };
  const sceneDimensions = { width: scene.aspectRatio[2], height: scene.aspectRatio[3] };

  let x, y, width, height;
  if (media instanceof Background) {
    const assetDimensions = await getAssetWidthAndHeight(assetContainer, aspectRatio);
    ({ x, y, width, height } = calculateDefaultBackgroundDimensions(assetDimensions, aspectRatio));
  } else if (media instanceof Watermark) {
    ({ x, y, width, height } = calculateDefaultWatermarkDimensions(aspectRatio));
  } else if (media.asset?.frame) {
    ({ width, height } = calculateDefaultLogoDimensions(aspectRatio));
    ({ x, y } = media.position);
  } else {
    ({ x, y, width, height } = await calculateLogoDimensions(media, assetContainer, aspectRatio, sceneDimensions));
  }
  const position = new Rect(x, y, width, height);

  return applyPositionOptions(position, media, options);
};

const calculateLogoDimensions = async (
  media: Media,
  assetContainer: AllowableAssetContainer,
  aspectRatio: Size,
  sceneDimensions: Size
): Promise<Rect> => {
  const assetDimensions = await getAssetWidthAndHeight(assetContainer, aspectRatio, AssetSizes.PREVIEW);
  const assetWidthNormalized = assetDimensions.width / sceneDimensions.width;
  const assetHeightNormalized = assetDimensions.height / sceneDimensions.height;
  let position = new Rect(media.position.x, media.position.y, assetWidthNormalized, assetHeightNormalized);

  if (!media.asset?.hasContent) {
    position = scaleToFill(position, defaultNoAssetSize);
    position = squareDown(position, aspectRatio); // default asset will use project's aspect ratio without this
  }

  return position;
};

const applyPositionOptions = (dimensions: Rect | Bounds, media: Media, options?: ResetPositionOptions): Rect => {
  let alignMediaToCenter, upperSizeLimit, lowerSizeLimit, centerOnPosition;
  if (options) {
    ({ alignMediaToCenter, upperSizeLimit, lowerSizeLimit, centerOnPosition } = options);
  }

  let finalPosition = Rect.fromRect(dimensions);

  if (upperSizeLimit && lowerSizeLimit) {
    if (finalPosition.width > upperSizeLimit.width || finalPosition.height > upperSizeLimit.width) {
      finalPosition = scaleToFit(finalPosition, upperSizeLimit);
    } else if (finalPosition.width < lowerSizeLimit.width || finalPosition.width < lowerSizeLimit.height) {
      finalPosition = scaleToFill(finalPosition, lowerSizeLimit);
    }
  } else if (upperSizeLimit) {
    finalPosition = scaleToFit(finalPosition, upperSizeLimit);
  }

  if (alignMediaToCenter) {
    finalPosition = alignToCenter(finalPosition, getDefaultRect());
  } else if (centerOnPosition) {
    finalPosition = alignToCenter(finalPosition, media.position);
  }

  return finalPosition;
};

export const scaleToFit = (r1: Rect, r2: Size): Rect => {
  const scaling = Math.min(r2.width / r1.width, r2.height / r1.height);
  const width = r1.width * scaling;
  const height = r1.height * scaling;

  return new Rect(r1.x, r1.y, width, height);
};

export const scaleToFill = (r1: Rect, r2: Size): Rect => {
  const scaling = Math.max(r2.width / r1.width, r2.height / r1.height);
  const width = r1.width * scaling;
  const height = r1.height * scaling;

  return new Rect(r1.x, r1.y, width, height);
};

export const squareDown = (r1: Rect, aspectRatio: Size): Rect => {
  const { width, height } = aspectRatio;
  const minNormalizedSideLength = Math.min(r1.width * width, r1.height * height);
  return new Rect(r1.x, r1.y, minNormalizedSideLength / width, minNormalizedSideLength / height);
};

export const alignToCenter = (r1: Rect, r2: Rect): Rect => {
  const x = r2.x + (r2.width - r1.width) / 2;
  const y = r2.y + (r2.height - r1.height) / 2;

  return new Rect(x, y, r1.width, r1.height);
};

export const getClampedAssetOffset = async (
  media: Media,
  assetOffset: [number, number],
  scene: Scene
): Promise<[number, number] | undefined> => {
  const assetContainer = getAssetContainer(media.asset, scene);

  if (!assetContainer) {
    return undefined;
  }

  const { position } = media;
  const [width, height] = scene.aspectRatio;
  const aspectRatio = { width, height };

  const assetDimensions = await getAssetWidthAndHeight(assetContainer, aspectRatio);
  const clampedAssetBounds = AssetCrop.getAssetBounds(position, assetDimensions, aspectRatio, assetOffset);

  return [position.x - clampedAssetBounds.x, position.y - clampedAssetBounds.y];
};

export const getAssetContainer = (asset: Asset | undefined, scene?: Scene): AllowableAssetContainer | undefined => {
  if (asset instanceof VideoClip) {
    return videoToVideoAssetContainer(asset, scene?.id);
  } else if (asset instanceof Image) {
    return imageToImageAssetContainer(asset, scene?.id);
  } else if (asset instanceof Svg) {
    return svgToSvgAssetContainer(asset, scene?.id);
  } else if (asset instanceof Animation) {
    return animationToAnimationAssetContainer(asset, scene?.id);
  } else {
    return;
  }
};
