export interface TimelineEventListener {
  type: TimelineEvent;
  handler: () => void;
  error?: (error: Error) => void;
}

export enum TimelineEvent {
  SCENE_ADDED = "SCENE_ADDED",
  TIMELINE_UPDATED = "TIMELINE_UPDATED"
}

class TimelineRegistry {
  listeners: TimelineEventListener[] = [];

  subscribe(listener: TimelineEventListener): void {
    this.listeners.push(listener);
  }

  unsubscribe(listener: TimelineEventListener): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  publish(event: TimelineEvent): void {
    for (const listener of this.listeners) {
      if (listener.type === event) {
        try {
          listener.handler();
        } catch (e) {
          console.error("Error running callback", e);
          if (listener.error) {
            try {
              listener.error(e as Error);
            } catch (e) {
              /* empty */
            }
          }
        }
      }
    }
  }
}

export const timelineRegistry = new TimelineRegistry();
