import { htmlSafe } from "@ember/template";
import Model, { attr } from "@ember-data/model";
import type { SafeString } from "handlebars";
import { dimensionsFromAspectRatio } from "renderer-engine";

export enum AspectRatios {
  SQUARE = "1:1",
  WIDESCREEN = "16:9",
  MOBILE = "9:16",
  PORTRAIT = "4:5"
}

const titles = new Map<string, string>([
  [AspectRatios.SQUARE, "Square"],
  [AspectRatios.WIDESCREEN, "Wide"],
  [AspectRatios.MOBILE, "Story"],
  [AspectRatios.PORTRAIT, "Mobile"]
]);

const descriptions: { [key in AspectRatios]: string } = {
  [AspectRatios.SQUARE]: "Ideal for Facebook, Instagram, and Twitter.",
  [AspectRatios.WIDESCREEN]: "Great for YouTube and your website.",
  [AspectRatios.MOBILE]: "Perfect for social media stories and Snapchat.",
  [AspectRatios.PORTRAIT]: "Recommended for mobile-first ads."
};

const order = new Map<string, number>([
  [AspectRatios.WIDESCREEN, 1],
  [AspectRatios.MOBILE, 2],
  [AspectRatios.SQUARE, 3],
  [AspectRatios.PORTRAIT, 4]
]);

export const WIDE_ASPECT_RATIO_ID = String(1);
export const WIDE_ASPECT_RATIO_SLUG = "wide";

export default class AspectRatio extends Model {
  @attr("number")
  declare width: number;

  @attr("number")
  declare height: number;

  @attr()
  declare readonly dimensions: {
    width: number;
    height: number;
  };

  @attr("string")
  declare name: AspectRatios;

  @attr("string")
  declare slug: string;

  get canvasDimensions(): [number, number] {
    const { width, height } = this;

    return dimensionsFromAspectRatio(width, height);
  }

  get canvasFormattedDimensions(): { width: number; height: number } {
    const [width, height] = this.canvasDimensions;

    return { width, height };
  }

  get ratioX(): number {
    return this.height / this.width;
  }

  get ratioY(): number {
    return this.width / this.height;
  }

  get title(): string {
    return titles.get(this.name) || this.name;
  }

  get order(): number {
    return order.get(this.name) || -1;
  }

  get description(): string {
    return descriptions[this.name];
  }

  get icon(): SafeString {
    const { width, height } = this;

    const box = 48;
    const stroke = 1;
    const size = box - stroke * 2;

    const w = width >= height ? size : (size * width) / height;
    const h = height >= width ? size : (size * height) / width;
    const vbw = w + stroke * 2;
    const vbh = h + stroke * 2;

    return htmlSafe(`
      <svg viewBox="0 0 ${vbw} ${vbh}" width="${vbw}" height="${vbh}" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="${stroke}"
          y="${stroke}"
          width="${w}"
          height="${h}"
          rx="2"
          vector-effect="non-scaling-stroke"
          stroke="currentColor"
          stroke-width="${stroke}"
          fill="none"
        />
        <line
          x1="0"
          y1="0"
          x2="${w}"
          y2="${h}"
          vector-effect="non-scaling-stroke"
          stroke-width="${stroke}"
        />
      </svg>
    `);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    aspectRatio: AspectRatio;
  }
}
