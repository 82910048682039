import { tracked } from "@glimmer/tracking";
import type { AudioClip } from "../audio";
import type { FontFamily } from "../font";
import type { Caption, Scene } from "../scene";
import type { Watermark } from "../scene/watermark";

export class TimingInfo {
  constructor(
    public startTime: number,
    public duration: number,
    public readonly previewTime = startTime + duration / 2
  ) {}

  get endTime() {
    return this.startTime + this.duration;
  }
}

export class Timeline {
  /** @internal */
  @tracked
  _scenes: Scene[];

  /** @internal */
  @tracked
  public _audioClips: AudioClip[];

  /** @internal */
  @tracked
  public _aspectRatio: [width: number, height: number, widthPx: number, heightPx: number];

  @tracked
  _watermark?: Watermark;

  @tracked
  _customFonts: FontFamily[];

  @tracked
  _brandStyleId?: string;

  @tracked
  _aspectRatioId?: string;

  public get scenes(): Scene[] {
    return this._scenes;
  }

  constructor(
    public id: string,
    scenes: Scene[] = [],
    audioClips: AudioClip[] = [],
    aspectRatio: [width: number, height: number, widthPx: number, heightPx: number],
    watermark: Watermark | undefined,
    customFonts: FontFamily[],
    brandStyleId?: string,
    aspectRatioId?: string
  ) {
    this._scenes = scenes;
    this._audioClips = audioClips;
    this._aspectRatio = aspectRatio;
    this._watermark = watermark;
    this._customFonts = customFonts;
    this._brandStyleId = brandStyleId;
    this._aspectRatioId = aspectRatioId;
  }

  _addAudioClip(audioClip: AudioClip): void {
    this._audioClips = this.audioClips.concat(audioClip);
  }

  _removeAudioClip(target: AudioClip): void {
    this._audioClips = this._audioClips.filter((a) => a !== target);
  }

  _addScene(scene: Scene): void {
    this._scenes = this._scenes.concat(scene);
  }

  _removeScene(target: Scene): void {
    this._scenes = this._scenes.filter((s) => s !== target);
  }

  _insertScene(target: Scene, index: number) {
    this._scenes = [...this._scenes.slice(0, index), target, ...this._scenes.slice(index)];
  }

  public get sceneCount(): number {
    return this._scenes.length;
  }

  public getScene(index: number): Scene {
    return this._scenes[index]!;
  }

  public getSceneById(id: string): Scene | undefined {
    return Array.from(this._scenes).find(({ id: sceneId }) => sceneId === id);
  }

  public getSceneByTime(time: number): Scene | undefined {
    return Array.from(this._scenes).find((scene) => {
      const sceneTiming = this.getSceneTiming(scene);
      const { startTime, endTime } = sceneTiming;

      return time >= startTime && time < endTime;
    });
  }

  public sceneOrder(scene: Scene): number {
    return Array.from(this.scenes).indexOf(scene);
  }

  public get sceneColors(): string[] {
    const timelineColors = new Set<string>();
    for (const scene of this.scenes) {
      for (const color of scene.sceneColors) {
        timelineColors.add(color);
      }
    }
    return [...timelineColors];
  }

  public getCaptionById(id: string): Caption | undefined {
    for (const scene of Array.from(this._scenes)) {
      const caption = scene.captions.find((caption) => caption.id === id);
      if (caption) {
        return caption;
      }
    }
    return undefined;
  }

  public get duration(): number {
    return Array.from(this._scenes).reduce((duration, scene) => duration + scene.duration, 0);
  }

  public sceneStartTime(scene: Scene): number {
    return Array.from(this._scenes)
      .slice(0, this.sceneOrder(scene))
      .reduce((duration, scene) => duration + scene.duration, 0);
  }

  public getSceneTiming(scene: Scene) {
    return new TimingInfo(this.sceneStartTime(scene), scene.duration);
  }

  public getCaptionTiming(caption: Caption) {
    const captionStartTime = this.sceneStartTime(caption.scene) + caption.offset;
    return new TimingInfo(captionStartTime, caption.duration);
  }

  public get audioClips(): AudioClip[] {
    return this._audioClips;
  }

  public get voiceovers(): AudioClip[] {
    return this._audioClips.filter((ac) => {
      return ac.category === "voiceover";
    });
  }

  public get aspectRatio(): [width: number, height: number, widthPx: number, heightPx: number] {
    return this._aspectRatio;
  }

  public get watermark(): Watermark | undefined {
    return this._watermark;
  }

  public get customFonts(): FontFamily[] {
    return this._customFonts;
  }

  public get brandStyleId(): string | undefined {
    return this._brandStyleId;
  }

  public get aspectRatioId(): string | undefined {
    return this._aspectRatioId;
  }
}
