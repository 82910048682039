import { getOwner } from "@ember/application";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectRouteModel } from "client/authenticated/project/route";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type Favorite from "client/models/favorite";
import type SelectableAsset from "client/models/selectable-asset";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export interface ProjectImportFavoriteRouteParams {
  favoriteId: string;
}

export default class ProjectImportFavoriteRoute extends Route {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  async model({ favoriteId }: ProjectImportFavoriteRouteParams): Promise<void> {
    // As we are potentially coming from the new client we need to officially load the favorite again
    // as the favorable is missing in that case
    const favorite = await this.store.findRecord("favorite", favoriteId, { reload: true });

    if (favorite) {
      await this.importFavorite(favorite);
    } else {
      this.notifications.error("There was a problem creating your new project");
    }
  }

  private async importFavorite(favorite: Favorite): Promise<void> {
    const { timeline, eventRegister } = this.modelFor("authenticated.project") as ProjectRouteModel;

    try {
      const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister);
      await modifier.applyAsset(favorite.favorable as unknown as SelectableAsset);
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
    }

    void this.router.replaceWith("authenticated.project");
  }
}
