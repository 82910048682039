import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { Crisp } from "crisp-sdk-web";
import delay from "client/lib/delay";
import { SimpleRumTimer } from "client/lib/rum-timer";
import type AuthData from "client/models/auth-data";
import type Subscription from "client/models/subscription";
import type Team from "client/models/team";
import type User from "client/models/user";
import type UserTargetingData from "client/models/user-targeting-data";
import type AjaxService from "client/services/ajax";
import AnalyticsService from "client/services/analytics";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type { PermissionName } from "client/services/permissions";
import type SessionService from "client/services/session";
import type StorageService from "client/services/storage";
import type TrackingService from "client/services/tracking";
import type UserPreferenceService from "client/services/user-preference";
import type VisibilityChangeService from "client/services/visibility-change";

export enum AuthEvents {
  USER_LOADED = "USER_LOADED",
  AFTER_USER_LOADED = "AFTER_USER_LOADED"
}

type EventHandler = (...args: any[]) => unknown;

const createHandlerRegister = (): Map<AuthEvents, Set<EventHandler>> => {
  const map = new Map<AuthEvents, Set<EventHandler>>();

  for (const event in AuthEvents) {
    map.set(event as AuthEvents, new Set());
  }

  return map;
};

export default class AuthService extends Service {
  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService;

  @service
  declare session: SessionService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @service
  declare storage: StorageService;

  @service
  declare userPreference: UserPreferenceService;

  @service
  declare visibilityChange: VisibilityChangeService;

  /**
   * @deprecated Use CurrentUserService instead
   */
  @tracked
  currentUser?: User;

  @tracked
  currentTeam?: Team;

  @tracked
  currentSubscription?: Subscription;

  @tracked
  currentFullSubscription?: Subscription;

  @tracked
  latestTrial?: Subscription;

  @tracked
  latestSubscription?: Subscription;

  /**
   * @deprecated Use CurrentUserService instead
   */
  @tracked
  userTargetingData?: UserTargetingData;

  private handlers = createHandlerRegister();

  constructor(...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(...args);

    this.one(AuthEvents.USER_LOADED, (user: User) => this.identifyUser(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadCurrentTeam(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadCurrentSubscription(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadCurrentFullSubscription(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadLatestTrial(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadLatestSubscription(user));
    this.on(AuthEvents.USER_LOADED, (user: User) => this.loadUserTargetingData(user));
    this.on(AuthEvents.USER_LOADED, () => this.loadUserPreference());
    this.visibilityChange.addObserver("lastActiveAt", this, "onVisibilityChange");
  }

  onVisibilityChange(): void {
    const { currentRouteName } = this.router;
    const signedIn = !!currentRouteName?.startsWith("authenticated");

    if (signedIn && this.visibilityChange.hiddenPeriod && this.visibilityChange.hiddenPeriod > 30000) {
      void this.checkAndInvalidateSession();
    }
  }

  async checkAndInvalidateSession(): Promise<void> {
    try {
      await this.ajax.api("/login");
    } catch (err: any) {
      if (err.status === 401) {
        this.notifications.warning("Your session may have timed out or your account may have been used elsewhere");

        // Give users time to read notification before logging out
        await delay(3_000);
        void this.session.invalidate();
        this.storage.clear();
      }
    }
  }

  get isAuthenticated(): boolean {
    return this.session.isAuthenticated;
  }

  get currentAuthData(): Partial<AuthData> {
    return this.session.data.authenticated;
  }

  get authToken(): string | undefined {
    return this.currentAuthData.access_token;
  }

  get hasTeam(): boolean {
    return !!this.currentUser?.belongsTo("team").id();
  }

  get permissions(): PermissionName[] {
    return this.currentUser?.permissionNames ?? ([] as PermissionName[]);
  }

  get isTeamOwner(): boolean {
    return this.currentUser?.teamOwner ?? false;
  }

  // currentSubscription returns trial first
  // currentFullOrTrialSubscription returns Full subscription first
  get currentFullOrTrialSubscription(): Subscription | undefined {
    return this.currentFullSubscription ?? this.currentSubscription;
  }

  public async reloadUser(): Promise<User | undefined> {
    return this.currentUser?.reload();
  }

  public async loadCurrentUser(): Promise<void> {
    const timer = new SimpleRumTimer("load-current-user");
    if (!this.isAuthenticated || !!this.currentUser || !this.currentAuthData.user_id) {
      return Promise.resolve();
    }

    const user = await this.store.findRecord("user", this.currentAuthData.user_id);

    if (!user) {
      throw Error("User was not found");
    }

    this.currentUser = user;

    await this.triggerAndAwait(AuthEvents.USER_LOADED, user);
    await this.triggerAndAwait(AuthEvents.AFTER_USER_LOADED);
    timer.meta = {
      userId: user?.id
    };
    timer.sample();
  }

  public async reload(): Promise<void> {
    const user = await this.reloadUser();

    if (user) {
      await this.triggerAndAwait(AuthEvents.USER_LOADED, user);
      await this.triggerAndAwait(AuthEvents.AFTER_USER_LOADED);
    }
  }

  private identifyUser(user: User): void {
    this.checkImpersonating(user);

    this.tracking.identify(user);
  }

  private async loadCurrentTeam(user: User): Promise<void> {
    const team = await user.team;
    this.currentTeam = team;
  }

  private async loadCurrentSubscription(user: User): Promise<void> {
    const subscription = await user.currentSubscription;
    this.currentSubscription = subscription;
  }

  private async loadCurrentFullSubscription(user: User): Promise<void> {
    const subscription = await user.currentFullSubscription;
    this.currentFullSubscription = subscription;
  }

  private async loadLatestTrial(user: User): Promise<void> {
    const trial = await user.latestTrial;
    this.latestTrial = trial;
  }

  private async loadLatestSubscription(user: User): Promise<void> {
    const subscription = await user.latestSubscription;
    this.latestSubscription = subscription;
  }

  private async loadUserTargetingData(user: User): Promise<void> {
    const userTargetingData = await user.userTargetingData;
    this.userTargetingData = userTargetingData;
  }

  private async loadUserPreference(): Promise<void> {
    void this.userPreference.load();
  }

  private checkImpersonating(user: User): void {
    this.setHoneybadgerContext(user);

    if (this.currentAuthData.impersonating) {
      AnalyticsService.disable();
    }
  }

  private setHoneybadgerContext(user: User): void {
    /* eslint-disable camelcase */
    this.honeybadger.setContext({
      user_id: user.id,
      user_email: user.email,
      user: {
        permissions: user.permissionNames
      }
    });
    /* eslint-enable camelcase */
  }

  private getEventHandlers(event: AuthEvents): Set<EventHandler> {
    return this.handlers.get(event) ?? new Set();
  }

  private addEventHandler(event: AuthEvents, handler: EventHandler): void {
    this.handlers.get(event)?.add(handler);
  }

  public one(event: AuthEvents, handler: EventHandler): void {
    const newHandler = (...args: any[]): void => {
      this.off(event, newHandler);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      handler(...args);
    };

    this.addEventHandler(event, newHandler);
  }

  public on(event: AuthEvents, handler: EventHandler): void {
    this.addEventHandler(event, handler);
  }

  public off(event: AuthEvents, handler: EventHandler): void {
    this.getEventHandlers(event).delete(handler);
  }

  get canDestroyCurrentUser(): boolean {
    return !!this.currentUser && !this.teamPreventsDeletion && !this.subscriptionPreventsDeletion;
  }

  get subscriptionPreventsDeletion(): boolean {
    return !!this.currentUser?.subscribed && !this.currentSubscription?.cancelAtPeriodEnd;
  }

  get teamPreventsDeletion(): boolean {
    if (!this.currentTeam) {
      return false;
    }

    if (!this.currentTeam.isTeamOwner) {
      return false;
    }

    return !this.currentTeam.canDelete;
  }

  get userSurveyComplete(): boolean {
    return !!this.userTargetingData?.complete;
  }

  get canManageSubscription(): boolean {
    return this.currentUser?.canManageSubscription ?? false;
  }

  public async logout(): Promise<void> {
    Crisp.chat.hide();

    try {
      await this.ajax.api("/logout");
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
    }

    try {
      await this.session.invalidate();

      this.storage.clear();
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
    }
  }

  public async createTeam(owner: User): Promise<Team> {
    this.currentTeam = await this.store
      .createRecord("team", {
        ownerEmail: owner.email
      })
      .save();

    await this.reload();

    return this.currentTeam;
  }

  private async triggerAndAwait(event: AuthEvents, ...args: any[]): Promise<void> {
    const handlers = Array.from(this.getEventHandlers(event));

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await Promise.all(handlers.map((handler) => handler(...args)));
  }
}
