import { debounce } from "@ember/runloop";
import {
  wrapElement,
  getColumnGap,
  getElement,
  getChildWidthStyle,
  getChildren,
  setParentHeight,
  addBreakElements,
  removeBreakElements,
  resetParentHeight,
  getColumnCount
} from "./lib";

export interface FlexMasonryOptions {
  minColumnWidth: number;
  columns: number;
  shouldInferAspectRatio: boolean;
  extraPaddingForHover?: number;
}

const DEBOUNCE_PERIOD = 10;
const DEFAULT_EXTRA_PADDING_FOR_HOVER = 1;

export class FlexMasonry {
  private parent: HTMLElement;
  private parentWidth = 0;
  private columnGap = 0;
  private breakElements: HTMLElement[] = [];
  private children: HTMLElement[] = [];
  private mutationObserver = new MutationObserver(this.handleMutation.bind(this));
  private resizeObserver = new ResizeObserver(this.handleResize.bind(this));
  private lastRenderAt = 0;

  constructor(elementOrSelector: HTMLElement | string, private options: Partial<FlexMasonryOptions>) {
    this.parent = getElement(elementOrSelector);
    wrapElement(this.parent);
    this.columnGap = getColumnGap(this.parent);

    this.render();
  }

  // Calculate the number of columns
  private get columnCount(): number {
    return this.options.columns ?? getColumnCount(this.parentWidth, this.minColumnWidth, this.columnGap);
  }

  private get minColumnWidth(): number {
    return this.options.minColumnWidth ?? 0;
  }

  // When the child count is mutated trigger rerender
  private handleMutation(): void {
    this.debounceRender();
  }

  // When the parent is resized trigger rerender
  private handleResize(): void {
    requestAnimationFrame(() => {
      const resized = this.parentWidth !== this.parent.clientWidth;

      if (resized) {
        this.render();
      }
    });
  }

  private debounceRender(): void {
    const sinceLastRender = Date.now() - this.lastRenderAt;

    if (sinceLastRender < DEBOUNCE_PERIOD) {
      debounce(this, this.render, DEBOUNCE_PERIOD - sinceLastRender);
    } else {
      this.render();
    }
  }

  // Render the masonry grid
  public render(): void {
    this.lastRenderAt = Date.now();

    // Don't observe while we perform operations
    this.disconnectObservers();

    // Remove any existing column elements while we rerender
    removeBreakElements(this.breakElements);

    // Reset the parent height to `auto`
    resetParentHeight(this.parent);

    // Fetch the parent width (in case it's changed)
    this.parentWidth = this.parent.clientWidth;

    // Set the child elements
    this.children = getChildren(this.parent);

    // Render the column elements
    this.breakElements = addBreakElements(this.parent, this.columnCount);

    // Apply all the styles
    this.applyParentStyles();
    this.applyChildStyles();

    // Fix the parent height
    setParentHeight(this.parent, this.children, this.columnCount);

    // Re-enable observers
    this.connectObservers();
  }

  private connectObservers(): void {
    const options: MutationObserverInit = {
      childList: true,
      subtree: !!this.options.shouldInferAspectRatio
    };

    this.mutationObserver.observe(this.parent, options);
    this.resizeObserver.observe(this.parent);
  }

  private disconnectObservers(): void {
    this.mutationObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  private applyParentStyles(): void {
    const extraPaddingForHover = this.options.extraPaddingForHover ?? DEFAULT_EXTRA_PADDING_FOR_HOVER;

    Object.assign(this.parent.style, {
      columnGap: 0,
      display: "flex",
      flexFlow: "column wrap",
      alignContent: "flex-start",
      padding: `${extraPaddingForHover}px`
    });
  }

  private applyChildStyles(): void {
    const margin = `${this.columnGap}px`;
    const widthStyle = getChildWidthStyle(this.columnCount, this.columnGap);
    const columnHeights: number[] = Array(this.columnCount).fill(0);

    this.children.forEach((element) => {
      if (element instanceof HTMLElement) {
        const column = columnHeights.indexOf(Math.min(...columnHeights)) + 1;
        const isLastColumn = column === this.columnCount;

        Object.assign(element.style, {
          width: widthStyle,
          marginRight: isLastColumn ? 0 : margin,
          order: column
        });

        columnHeights[column - 1] += element.getBoundingClientRect().height;
      }
    });
  }
}
