import { modifier } from "ember-modifier";

interface AutoFocusSignature {
  Element: HTMLElement;
}

export default modifier<AutoFocusSignature>((element: HTMLElement) => {
  const child = element.querySelector("[autofocus]") as HTMLElement;
  if (child) {
    child.focus();
  }
});
