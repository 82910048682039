import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SearchInputArgs {
  search(query: string): unknown;
  query: string;
  size?: "large";
  placeholder?: string;
}

export default class SearchInputComponent extends Component<SearchInputArgs> {
  @tracked
  query = this.args.query ?? "";

  @tracked
  focused = false;

  @tracked
  dirty = false;

  styleNamespace = getStyleNamespace("north-star/search-input");

  get showClearButton(): boolean {
    return !this.focused && !!this.query.length;
  }

  get largeInput(): boolean {
    return this.args.size === "large";
  }

  @action
  onFocus(): void {
    this.focused = true;
  }

  @action
  onBlur(): void {
    this.focused = false;
  }

  @action
  onInput(): void {
    this.dirty = true;
  }

  @action
  onSubmit(event: Event): void {
    event.preventDefault();

    this.search();
  }

  @action
  onSubmitMousedown(ev: MouseEvent): void {
    // Prevent blurring of the input element when search is performed
    ev.stopPropagation();
    ev.preventDefault();

    this.search();
  }

  @action
  onClearMousedown(): void {
    this.query = "";
    this.dirty = true;

    this.search();
  }

  private search(): void {
    if (this.dirty) {
      // Blurs the search component so the clear button is revealed
      if (document.activeElement && document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      this.args.search(this.query);
      this.dirty = false;
    }
  }

  @action
  queryDidUpdate(_: HTMLElement, [query]: [string]): void {
    this.query = query;
    this.dirty = false;
  }
}
