import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface InputFieldComponentArgs {
  disabled?: boolean;
  error?: string;
  forceShowError?: boolean;
  showErrorMessage?: boolean;
  helpText?: string;
  label: string;
  hideLabel?: boolean;
  inputId: string;
  multiline: boolean;
  type?: string;
  value: string;
  variant?: InputFieldVariants;
  icon?: string;
  tooltip?: string;
  invalid?: boolean;
  size?: "small";
  onBlur?: (event: Event) => void;
  onChange?: (event: Event) => void;
  onFocus?: (event: Event) => void;
  onInput?: (event: Event) => void;
}

export enum InputFieldErrorPlacement {
  NONE = "none",
  TOP = "top",
  BOTTOM = "bottom"
}

export enum InputFieldVariants {
  DEFAULT = "default",
  PRIMARY = "primary"
}

export default class InputFieldComponent extends Component<InputFieldComponentArgs> {
  inputId = this.args.inputId ?? `${guidFor(this)}__Input`;
  styleNamespace = getStyleNamespace("north-star/input-field");

  @tracked
  validationMessage?: string;

  @tracked
  dirty = false;

  @tracked
  inputElementInvalid = false;

  @tracked
  focused = false;

  @tracked
  passwordVisible = false;

  @tracked
  changed = false;

  @action
  onBlur(event: Event): void {
    this.args.onBlur?.(event);
    this.focused = false;

    if (this.changed) {
      this.dirty = true;
    }

    this.setElementValidation(event.target as HTMLInputElement);
  }

  @action
  onChange(event: Event): void {
    this.args.onChange?.(event);
  }

  @action
  onFocus(event: Event): void {
    this.args.onFocus?.(event);

    this.focused = true;
  }

  @action
  onInput(event: Event): void {
    this.args.onInput?.(event);
    this.changed = true;
    this.setElementValidation(event.target as HTMLInputElement);
  }

  @action
  setValidity(element: HTMLInputElement | HTMLTextAreaElement): void {
    if (this.args.error) {
      element.setCustomValidity(this.args.error);
    }
  }

  @action
  togglePasswordVisible(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  setElementValidation(element: HTMLInputElement): void {
    this.inputElementInvalid = !element.checkValidity();

    this.validationMessage = element.validationMessage;
  }

  get isDirty(): boolean {
    return this.dirty || !!this.args.forceShowError;
  }

  get isPassword(): boolean {
    return this.args.type === "password";
  }

  get inputType(): string {
    if (this.passwordVisible) {
      return "text";
    } else if (this.args.type) {
      return this.args.type;
    } else {
      return "text";
    }
  }

  get invalid(): boolean {
    return this.inputElementInvalid || !!this.args.invalid;
  }

  get errorMessage(): string | undefined {
    return this.args.error ?? this.validationMessage;
  }

  get showErrorMessage(): boolean {
    return this.invalid && this.isDirty && (this.args.showErrorMessage ?? true);
  }

  get icon(): SafeString {
    return htmlSafe(this.args.icon ?? "");
  }

  get variant(): InputFieldVariants {
    return this.args.variant ?? InputFieldVariants.DEFAULT;
  }
}
