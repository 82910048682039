import type { RecordProject } from "@biteable/network-model";
import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import type NewVideoActionsService from "client/services/new-video-actions";
import type RecordProjectsService from "client/services/record-projects";

interface Args {
  onSelectPanel(panel: string): void;
  enterRequestResponses: (recordProject: RecordProject) => void;
}

export default class RecordingsRequestsPanel extends Component<Args & ProjectContentBarPanelArgs> {
  @service
  declare newVideoActions: NewVideoActionsService;

  @service
  declare recordProjects: RecordProjectsService;

  get params(): Record<string, number> {
    return {
      per_page: 24 // eslint-disable-line camelcase
    };
  }

  @action
  selectRequest(recordProject: RecordProject): void {
    this.args.enterRequestResponses(recordProject);
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<ArrayProxy<RecordProject>> {
    return this.recordProjects.loadCollaborativeRecordProjects(params["page"] as number, params["per_page"] as number);
  }

  @action
  async sendRecordRequest(): Promise<void> {
    return this.newVideoActions.createCollaborativeRecordProject();
  }
}
