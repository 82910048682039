import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type AuthService from "client/services/auth";
import type CurrentUserService from "client/services/current-user";
import type UpgradeService from "client/services/upgrade";
import { PlanSelectContext } from "client/services/upgrade";

interface PlanCallToActionComponentArgs {
  plan: Plan;
}

export default class PlanCallToActionComponent extends Component<PlanCallToActionComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-call-to-action");

  @service
  declare auth: AuthService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare upgrade: UpgradeService;

  @action
  async onClick(): Promise<void> {
    if (this.shouldStartTrial) {
      this.transitionToTrial();
    } else {
      await this.selectPlan();
    }
  }

  get showYourPlanText(): boolean {
    return this.args.plan.id === this.currentFullPlan?.id;
  }

  private get shouldStartTrial(): boolean {
    return this.isTopTierPlan && !this.hasUsedTopTierPlan;
  }

  private get isTopTierPlan(): boolean {
    return this.args.plan.isTopTierPlan;
  }

  private get hasUsedTopTierPlan(): boolean {
    return !!this.currentUser.user?.hasUsedTopTierPlan;
  }

  private transitionToTrial(): void {
    if (this.upgrade.upgrading) {
      this.upgrade.close();
    }
    void this.router.transitionTo("authenticated.trial");
  }

  private async selectPlan(): Promise<void> {
    void this.upgrade.selectPlan({
      context: PlanSelectContext.APP,
      selectedPlan: this.args.plan
    });
  }

  private get currentFullPlan(): Plan | undefined {
    return this.auth.currentFullSubscription?.plan;
  }
}
