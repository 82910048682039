import { setOwner } from "@ember/application";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Honeybadger from "@honeybadger-io/js";
import BrandStyleConfig from "./brand-style-config";
import TrackingEvents from "client/events";
import type BrandQuery from "client/models/brand-query";
import type TrackingService from "client/services/tracking";

interface AnalyticsParams {
  font?: string;
  supportedByUs?: boolean;
  countOfColors?: number;
  countOfLogos?: number;
}

export default class BrandQueryRequest {
  @service
  declare store: Store;

  @service
  declare honeybadger: Honeybadger;

  @service
  declare tracking: TrackingService;

  public brandQuery?: BrandQuery;

  private event = TrackingEvents.EVENT_BRAND_QUERY_USER_REQUESTED;

  constructor(owner: Owner, private url: string) {
    setOwner(this, owner);
  }

  public setEventToAutoQuery(): void {
    this.event = TrackingEvents.EVENT_BRAND_QUERY_AUTO_REQUESTED;
  }

  public async invoke(): Promise<BrandStyleConfig> {
    try {
      this.brandQuery = await this.query(this.url);
      if (!this.canUseBrandFetchResult) {
        throw "Brand has insufficient color count and no logos";
      }
      return this.brandStyleConfigFromBrandQuery(this.brandQuery);
    } catch (error) {
      // @ts-expect-error
      if (error.status !== 404) {
        // 404 is a legit error the brand was missing do not report to Honeybadger in that case
        this.honeybadger.notify("Error querying and parsing brand", {
          context: {
            companyUrl: this.url,
            error
          },
          name: "BrandQueryPage"
        });
      }

      throw error;
    } finally {
      this.sendAnalyticsEvent();
    }
  }

  private async query(url: string): Promise<BrandQuery> {
    return await this.store.findRecord("brandQuery", url);
  }

  private brandStyleConfigFromBrandQuery(brandQuery: BrandQuery): BrandStyleConfig {
    return BrandStyleConfig.fromBrandQuery(brandQuery);
  }

  private get canUseBrandFetchResult(): boolean {
    if (this.brandQuery) {
      return this.brandQuery.colors.length >= 3 || !!this.brandQuery.logos.length;
    }
    return false;
  }

  private getAnalyticsData(): AnalyticsParams {
    const { brandQuery } = this;

    if (brandQuery) {
      return {
        font: brandQuery.mainFont?.name,
        supportedByUs: brandQuery.isMainFontGoogleFont,
        countOfColors: brandQuery.colorHexValues.length,
        countOfLogos: brandQuery.logoImages.length
      };
    } else {
      return {};
    }
  }

  private sendAnalyticsEvent(): void {
    const params = { ...this.getAnalyticsData(), fetchUrl: this.url };

    void this.tracking.sendAnalytics(this.event, params);
  }
}
