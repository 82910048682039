import { tracked } from "@glimmer/tracking";
import type { OriginalAsset } from "../../asset";
import { Asset } from "../../asset";
import type { TimeSlice } from "../video";
import type { AnimatedLayer } from "./animated-layer";

export class Animation extends Asset {
  /** @internal */
  @tracked
  _clips: AnimatedLayer[];

  @tracked
  previewVideoUrl?: string;

  @tracked
  previewImageUrl: string | undefined;

  @tracked
  public trim?: TimeSlice;

  @tracked
  _loopable: boolean;

  @tracked
  _animation: string;

  @tracked
  _playbackSpeed: number;

  /** @internal */
  @tracked
  _uid: string | undefined;

  constructor(
    clips: AnimatedLayer[],
    mirror: boolean,
    objectFit: string,
    uid: string | undefined,
    loopable: boolean,
    public readonly horizontalFlipping: boolean,
    public readonly sizingOptions: string[],
    public readonly colorOptions: unknown,
    animation: string,
    playbackSpeed: number,
    trim?: TimeSlice,
    previewVideoUrl?: string,
    previewImageUrl?: string,
    public readonly originalSagId?: string,
    originalAsset?: OriginalAsset
  ) {
    super(mirror, objectFit, originalAsset);
    this._clips = clips;
    this.trim = trim;
    this.previewVideoUrl = previewVideoUrl;
    this.previewImageUrl = previewImageUrl;
    this._uid = uid;
    this._loopable = loopable;
    this._animation = animation;
    this._playbackSpeed = playbackSpeed;
  }

  get hasContent(): boolean {
    return !!this.clips.length;
  }

  get category(): string {
    return "sag";
  }

  get formattedCategory(): string {
    return "animation";
  }

  public get clips(): AnimatedLayer[] {
    return this._clips;
  }

  get uid(): string | undefined {
    return this._uid;
  }

  get colors(): string[] {
    const colors = new Set<string>();
    for (const animatedLayer of this.clips) {
      colors.add(animatedLayer.style.color.substring(0, 7).toLocaleUpperCase());
    }

    return [...colors];
  }

  get loopable(): boolean {
    return this._loopable;
  }

  get animation(): string {
    return this._animation;
  }

  get playbackSpeed(): number {
    return this._playbackSpeed ?? 1;
  }

  _reset(): void {
    super._reset();
    this._clips = [];
    this.previewVideoUrl = undefined;
    this.previewImageUrl = undefined;
    this._uid = undefined;
  }
}
