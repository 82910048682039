import { action } from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { PlayerErrorEventContext, PlayerEventContext, PlayerOptions } from "renderer-engine";
import { AssetNotLoadedError, AssetSizes, Player, PlayDirections, PlayerEvents } from "renderer-engine";
import defer from "client/lib/defer";
import type { Timeline } from "client/lib/editor-domain-model";
import type AdvancedEditorService from "client/services/advanced-editor";
import type { ErrorContext } from "client/services/honeybadger";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PlaybackEventsService from "client/services/playback-events";
import { PlaybackEvents } from "client/services/playback-events";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export enum PlaybackStates {
  DISABLED = "disabled",
  READYING = "readying",
  BUFFERING = "buffering",
  PLAYING = "playing",
  PAUSED = "paused"
}

export default class PlaybackService extends Service {
  @service
  private declare advancedEditor: AdvancedEditorService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  declare playbackEvents: PlaybackEventsService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare store: Store;

  @service
  private declare timelineEvents: TimelineEventsService;

  @tracked
  private playbackState = PlaybackStates.DISABLED;

  player: Player | undefined = undefined;
  private playerPromise = defer<Player>();

  @tracked
  private _currentTime = 0;

  private endTime = Infinity;
  private previewTime = 0;

  async setup(canvas: HTMLCanvasElement): Promise<void> {
    if (!this.advancedEditor.timeline) {
      console.log("This is being hit");
      return;
    }

    this.playbackState = PlaybackStates.READYING;

    const player = await this.createProjectPlayerInstance(this.advancedEditor.timeline, canvas);

    player.addListener(PlayerEvents.TIMEUPDATE, this.onTimeUpdate);
    player.addListener(PlayerEvents.PLAYEND, this.onPlayEnd);
    player.addListener(PlayerEvents.ERROR, this.onError);
    player.addListener(PlayerEvents.AUDIO_ERROR, this.onAudioError);

    this.player = player;
    this.playerPromise.resolve(player);

    this.playbackState = PlaybackStates.PAUSED;

    this.timelineEvents.subscribe(TimelineEvents.PROJECT_DID_CHANGE, this.onProjectDidChange);
    this.timelineEvents.subscribe(TimelineEvents.AUDIO_CHANGED, this.onAudioChanged);
  }

  @dependentKeyCompat
  get playing(): boolean {
    return this.playbackState === PlaybackStates.PLAYING;
  }

  get paused(): boolean {
    return this.playbackState === PlaybackStates.PAUSED;
  }

  get buffering(): boolean {
    return this.playbackState === PlaybackStates.BUFFERING;
  }

  get readying(): boolean {
    return this.playbackState === PlaybackStates.READYING;
  }

  get disabled(): boolean {
    return this.playbackState === PlaybackStates.DISABLED;
  }

  get currentTime(): number {
    return this._currentTime;
  }

  @action
  private async onProjectDidChange(): Promise<void> {
    await this.stop();
  }

  @action
  private async onAudioChanged(): Promise<void> {
    await this.stop();
  }

  @action
  private async onTimeUpdate({ currentTime }: PlayerEventContext): Promise<void> {
    this._currentTime = currentTime;
    this.playbackEvents.publish(PlaybackEvents.TIME_UPDATE, currentTime);

    if (this.playing && currentTime >= this.endTime) {
      await this.stop();
    }
  }

  @action
  private async onPlayEnd(): Promise<void> {
    await this.stop();
  }

  @action
  private onError({ errorCode, error, ...rest }: PlayerErrorEventContext): void {
    this.reportError(errorCode, error, { ...rest });
  }

  @action
  private onAudioError({ errorCode, error, ...rest }: PlayerErrorEventContext): void {
    this.notifications.error(error.message);
    this.onError({ errorCode, error, ...rest });
  }

  private reportError(category: string, error?: Error, context: ErrorContext = {}): void {
    const message = error?.message;
    if (message && !this.isAssetLoadingError(error)) {
      const name = `PlaybackService - ${category}`;
      const fingerprint = `${name}: ${message}`;

      this.honeybadger.notify(error || "unknown error", { name, message, fingerprint, context });
    }
  }

  private isAssetLoadingError(error?: Error): error is AssetNotLoadedError {
    return error instanceof AssetNotLoadedError;
  }

  async play(startTime?: number, endTime?: number, muted?: boolean): Promise<void> {
    const player = await this.playerPromise;

    this.playbackState = PlaybackStates.BUFFERING;

    if (endTime !== undefined) {
      this.endTime = endTime;
    }

    if (muted !== undefined) {
      player.muted = muted;
    }

    await player.play(startTime ?? this._currentTime);

    this.playbackState = PlaybackStates.PLAYING;
  }

  async stop(previewTime?: number): Promise<void> {
    const player = await this.playerPromise;

    if (previewTime !== undefined) {
      this.previewTime = previewTime;
    }

    await player.setElapsedTime(this.previewTime);

    this.playbackState = PlaybackStates.PAUSED;
  }

  async clear(): Promise<void> {
    const player = await this.playerPromise;

    this.timelineEvents.unsubscribe(TimelineEvents.PROJECT_DID_CHANGE, this.onProjectDidChange);
    this.timelineEvents.unsubscribe(TimelineEvents.AUDIO_CHANGED, this.onAudioChanged);

    this.player = undefined;
    this.playerPromise = defer<Player>();

    this.playbackState = PlaybackStates.DISABLED;

    player.destroy();
  }

  async updatePreviewTime(previewTime: number): Promise<void> {
    const player = await this.playerPromise;

    this.previewTime = previewTime;

    if (this.paused) {
      await player.setElapsedTime(previewTime);
    }
  }

  async moveForwardsFrame(): Promise<void> {
    const player = await this.playerPromise;

    await player.moveFrame(PlayDirections.FORWARDS);

    this.playbackState = PlaybackStates.PAUSED;
  }

  async moveBackwardsFrame(): Promise<void> {
    const player = await this.playerPromise;

    await player.moveFrame(PlayDirections.BACKWARDS);

    this.playbackState = PlaybackStates.PAUSED;
  }

  private async createProjectPlayerInstance(timeline: Timeline, canvas: HTMLCanvasElement): Promise<Player> {
    const project = await this.store.findRecord("project", timeline.id);

    await project.zymbols;

    const [width, height] = project.aspectRatio.canvasDimensions;
    const playerOptions: PlayerOptions = { width, height, assetSize: AssetSizes.PREVIEW };

    return new Player(project, canvas, playerOptions);
  }
}
