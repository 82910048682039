import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";

interface Args {
  eventRegister: EventRegister;
  isHover: boolean;
  scene: Scene;
  thumbnailUrl?: string;
}

export default class ScriptWorkstationThumbnailComponent extends Component<Args> {
  @service
  declare advancedEditor: AdvancedEditorService;

  styleNamespace = getStyleNamespace("script-workstation/thumbnail");

  get isActive(): boolean {
    return this.advancedEditor.scene === this.args.scene;
  }

  get style(): SafeString {
    const [width, height] = this.args.scene.aspectRatio;

    const aspectRatio = width / height;
    const borderRadius = 100 / 9;

    return htmlSafe(`aspect-ratio: ${aspectRatio}; border-radius: ${borderRadius / aspectRatio}% / ${borderRadius}%;`);
  }
}
