import type ApplicationInstance from "@ember/application/instance";
import type MouseService from "client/services/mouse";

const initialize = (app: ApplicationInstance): void => {
  try {
    const service = (app as any).__container__.lookup("service:mouse") as MouseService;
    service.initialize();
  } catch {
    // eslint-disable-next-line no-empty
  }
};

export default {
  name: "mouse",
  initialize
};
