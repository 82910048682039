export enum SEARCH_LOCATION {
  CONTENT_DISCOVERY = "content_discovery",
  ADD_SCENE = "add_scene",
  CONTENT_BAR = "content_bar"
}

export enum CONTENT_TRACKING_TYPES {
  PROJECT_SCENES = "project-scenes",
  PEXELS_IMAGE = "pexels-image",
  PEXELS_VIDEO = "pexels-video",
  STORYBLOCKS = "storyblocks",
  BITEABLE_CONTENT = "biteable-content",
  GRAPHICS = "graphics",
  ALL = "all",
  STOCK = "stock"
}

export class ContentTrackingHelper {
  searchScope?: CONTENT_TRACKING_TYPES;

  search?: string | number;

  results?: Record<CONTENT_TRACKING_TYPES, number>;

  searchFrom?: SEARCH_LOCATION;

  pageNumber?: string | number;

  projectId?: string;

  sceneId?: string;

  constructor(props: Partial<ContentTrackingHelper> = {}, searchScope: string, totalCount?: number, location?: string) {
    props.searchScope = this.format(searchScope);

    if (totalCount && props.searchScope) {
      props.results = { [props.searchScope]: totalCount } as Record<CONTENT_TRACKING_TYPES, number>;
    }

    if (location && this.fromContentBar(props)) {
      props.projectId = this.getProjectId(location);
      props.sceneId = this.getSceneId(location);
    }

    Object.assign(this, props);
  }

  private format(searchScope: string): CONTENT_TRACKING_TYPES | undefined {
    if (Object.values(CONTENT_TRACKING_TYPES).includes(searchScope as CONTENT_TRACKING_TYPES)) {
      return searchScope as CONTENT_TRACKING_TYPES;
    } else {
      if (searchScope.includes("image") || searchScope.includes("photo")) {
        return CONTENT_TRACKING_TYPES.PEXELS_IMAGE;
      } else if (searchScope.includes("pexel")) {
        return CONTENT_TRACKING_TYPES.PEXELS_VIDEO;
      } else if (searchScope.includes("scene")) {
        return CONTENT_TRACKING_TYPES.PROJECT_SCENES;
      } else if (searchScope.includes("video") || searchScope.includes("storyblock")) {
        return CONTENT_TRACKING_TYPES.STORYBLOCKS;
      }
    }
  }

  private fromContentBar(props: Partial<ContentTrackingHelper>): boolean {
    return props.searchFrom === SEARCH_LOCATION.CONTENT_BAR;
  }

  private getProjectId(location: string): string {
    return this.extractIdBetween(location, "/project/", "/scene/");
  }
  private getSceneId(location: string): string {
    return this.extractIdBetween(location, "/scene/", "/");
  }

  private extractIdBetween(str: string, start: string, end: string): string {
    const regex = new RegExp(`${start}(\\d+)${end}`);
    const match = str.match(regex);
    return (match && match[1]) || "";
  }
}
