import type { RecordProject, RecordProjectPrompt, RecordProjectResponse } from "@biteable/network-model";
import Network from "@biteable/network-model";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectScenesRouteModel } from "client/authenticated/project/scenes/route";
import type ScenesRoute from "client/authenticated/project/scenes/route";
import type UserAsset from "client/models/user-asset";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";

export interface ProjectScenesRecordRequestsRecordRouteModel extends ProjectScenesRouteModel {
  requestsGroupedByRespondent: UserResponsesGroupedByRespondent;
  recordProject?: RecordProject;
  numOfRespondents: number;
}

export interface ScenesRecordRequestRouteParams {
  recordProjectToken: string;
}

interface UserResponse {
  response: RecordProjectResponse;
  prompt: RecordProjectPrompt;
  asset: UserAsset;
}

interface UserResponsesGroupedByRespondent {
  [respondent: string]: UserResponse[];
}

export default class ProjectScenesRecordRequestsRecordRoute extends Route<ProjectScenesRecordRequestsRecordRouteModel> {
  @service
  declare store: Store;

  @service
  notifications!: NotificationsService;

  @service
  honeybadger!: HoneybadgerService;

  @service
  declare permissions: PermissionsService;

  async model({
    recordProjectToken
  }: ScenesRecordRequestRouteParams): Promise<ProjectScenesRecordRequestsRecordRouteModel> {
    const model = this.modelFor<ScenesRoute>("authenticated.project.scenes");
    let requestsGroupedByRespondent: UserResponsesGroupedByRespondent = {};

    try {
      const recordProject: RecordProject | undefined = (
        await Network.store.findAll("recordProject", { token: recordProjectToken }, { force: true })
      ).data[0] as RecordProject;

      if (recordProject) {
        const unGroupedRequests = await this.requestsForProject(recordProject);
        requestsGroupedByRespondent = await this.groupResponsesByEmail(unGroupedRequests);
      }

      return {
        ...model,
        requestsGroupedByRespondent,
        recordProject,
        numOfRespondents: Object.keys(requestsGroupedByRespondent).length
      };
    } catch (error) {
      this.notifications.error("There was a problem loading your recordings");
      // @ts-expect-error
      this.honeybadger.notify(error, "ScenesRecordRequestRoute");
      throw error;
    }
  }

  async requestsForProject(nemRecordProject: RecordProject): Promise<UserResponse[]> {
    if (nemRecordProject.recordProjectPrompts) {
      const recordProjectResponses = await Promise.all(
        nemRecordProject.recordProjectPrompts
          .map((rpr: RecordProjectPrompt) => (rpr.recordProjectResponses ? rpr.recordProjectResponses : []))
          .flat()
      );

      return await this.buildResponses(recordProjectResponses);
    } else {
      return [];
    }
  }

  async buildResponses(recordProjectResponsesV2: RecordProjectResponse[]): Promise<UserResponse[]> {
    return Promise.all(
      recordProjectResponsesV2.map(async (rpr: RecordProjectResponse) => {
        const edUserAsset = await this.store.findRecord("userAsset", rpr.userAssetId);

        return {
          response: rpr,
          asset: edUserAsset,
          prompt: rpr.recordProjectPrompt
        };
      })
    );
  }

  async groupResponsesByEmail(responses: UserResponse[]): Promise<UserResponsesGroupedByRespondent> {
    const responsesGroupedByRespondent: UserResponsesGroupedByRespondent = {};

    await Promise.all(
      responses.map((response: UserResponse) => {
        const respondent = response.response.email as string;

        if (!responsesGroupedByRespondent[respondent]) {
          responsesGroupedByRespondent[respondent] = [response];
        } else {
          responsesGroupedByRespondent[respondent] = [...responsesGroupedByRespondent[respondent]!, response];
        }
      })
    );

    return responsesGroupedByRespondent;
  }
}
