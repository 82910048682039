import { action } from "@ember/object";
import { service } from "@ember/service";
import type Model from "@ember-data/model";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ArrayWithMeta } from "client/models/pagination";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

const DEFAULT_PER_PAGE = 36;

export interface InfiniteParams {
  perPage: number;
  page: number;
}

export default class InfiniteComponent extends Component<{
  errorNotificationMessage?: string;
  params?: Record<string, string>;
  reload?: boolean;
  networkModelPayload?: boolean;
  getResults: (params: InfiniteParams) => Promise<Array<Model>>;
  onFirstPageLoaded?: (totalCount: number) => Promise<void>;
  perPage?: number;
  isManual?: boolean;
}> {
  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare notifications: NotificationsService;

  @tracked
  private page = 1;

  @tracked
  private totalCount = 0;

  @tracked
  results = new TrackedArray<Model>([]);

  @tracked
  currentlyLoading = false;

  @tracked
  loadingMore = false;

  styleNamespace = getStyleNamespace("infinite");

  @action
  async loadFirstPage(): Promise<void> {
    this.currentlyLoading = true;
    this.page = 1;

    try {
      const results = await this.loadResults();
      this.results = new TrackedArray(results);
    } finally {
      this.currentlyLoading = false;
    }

    await this.args.onFirstPageLoaded?.(this.totalCount);
  }

  @action
  async loadNextPage(): Promise<void> {
    if (this.loadingMore || this.isLastPage) {
      return;
    }

    this.loadingMore = true;
    this.page += 1;

    try {
      const newResults = await this.loadResults();
      this.results.push(...newResults);
    } finally {
      this.loadingMore = false;
    }
  }

  private async loadResults(): Promise<Array<Model>> {
    let results;

    try {
      results = await this.args.getResults(this.getParamsForResults());
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
      this.notifications.error(this.errorNotificationMessage);

      results = new Array<Model>();
    }

    // @ts-expect-error
    if ("meta" in results || results.meta) {
      const { meta } = results as ArrayWithMeta<Model>;
      this.totalCount = meta ? meta["totalCount"] ?? meta["total-count"] : 0;
    }

    if (this.args.networkModelPayload) {
      // @ts-expect-error
      return results.data.toArray();
    } else {
      return results;
    }
  }

  private getParamsForResults(): InfiniteParams {
    const { page, perPage } = this;

    return {
      ...this.args.params,
      page,
      perPage
    };
  }

  get perPage(): number {
    return this.args.perPage ?? DEFAULT_PER_PAGE;
  }

  get errorNotificationMessage(): string {
    return this.args.errorNotificationMessage ?? "There was a problem retrieving results";
  }

  get hasResults(): boolean {
    return !!this.results.length;
  }

  get isLastPage(): boolean {
    return this.page * this.perPage >= this.totalCount;
  }

  get showNoMoreResults(): boolean {
    return this.page > 1 && this.isLastPage;
  }

  get canLoadMoreResults(): boolean {
    return !this.currentlyLoading && !this.isLastPage;
  }

  get remainingCount(): number {
    return this.totalCount - this.perPage * this.page;
  }
}
