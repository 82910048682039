import type { Bounds, Size, AllowableAssetContainer, RenderZymbol } from "renderer-engine";
import {
  dimensionsToAspectRatio,
  getAssetDimensionsFromHtmlElement,
  ZymbolCategory,
  AssetSizes,
  AssetTypes,
  Assets
} from "renderer-engine";
import { Rect } from "client/lib/editor-domain-model";

export const DEFAULT_LOGO_SIZE = 0.2;

export const calculateDefaultBackgroundDimensions = (assetDimensions: Size, aspectRatio: Size): Bounds => {
  const { width: w, height: h } = assetDimensions;

  const assetRatio = w / h;
  const canvasRatio = aspectRatio.width / aspectRatio.height;
  const zymbolRatio = assetRatio / canvasRatio;
  const width = Math.max(1, zymbolRatio);
  const height = Math.max(1, 1 / zymbolRatio);
  const x = Math.min(0, (width - 1) / -2);
  const y = Math.min(0, (height - 1) / -2);

  return {
    width,
    height,
    x,
    y
  };
};

export const calculateDefaultLogoDimensions = (aspectRatio: Size): Bounds => {
  const canvasRatio = aspectRatio.width / aspectRatio.height;
  const width = DEFAULT_LOGO_SIZE / canvasRatio;
  const height = DEFAULT_LOGO_SIZE;
  const x = 0.5 - width / 2.0;
  const y = 0.5 - height / 2.0;

  return {
    width,
    height,
    x,
    y
  };
};

export const calculateDefaultWatermarkDimensions = (aspectRatio: Size): Bounds => {
  const canvasRatio = aspectRatio.width / aspectRatio.height;
  const width = DEFAULT_LOGO_SIZE / canvasRatio;
  const height = DEFAULT_LOGO_SIZE;
  const x = 1 - width * 1.1;
  const y = 1 - height * 1.1;

  return {
    width,
    height,
    x,
    y
  };
};

export const getAssetWidthAndHeight = async (
  zymbol: RenderZymbol | AllowableAssetContainer,
  aspectRatio: Size,
  overwriteAssetSize?: AssetSizes
): Promise<Size> => {
  const assetSize = zymbol.category === ZymbolCategory.SAG ? AssetSizes.PREVIEW : AssetSizes.THUMBNAIL;
  const [asset] = await Assets.loadAsset(zymbol, overwriteAssetSize || assetSize, AssetTypes.IMAGE);

  if (asset) {
    const source = await asset.waitUntilReady();
    return getAssetDimensionsFromHtmlElement(source, aspectRatio);
  }

  return aspectRatio;
};

export const applyScale = (dimensions: Bounds, factor: number, fillCanvas = false): Bounds => {
  const { width: prevWidth, height: prevHeight, x: prevX, y: prevY } = dimensions;

  const scale = factor / Math.min(prevWidth, prevHeight);
  const width = prevWidth * scale;
  const height = prevHeight * scale;
  const deltaWidth = width - prevWidth;
  const deltaHeight = height - prevHeight;
  const x = prevX - deltaWidth / 2;
  const y = prevY - deltaHeight / 2;

  return {
    width,
    height,
    x: fillCanvas ? Math.max(-width + 1, Math.min(0, x)) : x,
    y: fillCanvas ? Math.max(-height + 1, Math.min(0, y)) : y
  };
};

/**
 * Converts an asset's width and height to a percentage of canvas size, with
 * 1 being 100% and 0 being 0%.
 *
 * @param assetDimentions - x, y, width, and height dimensions of the asset
 * @param sceneAspectRatio - width and height representing the aspect ratio of the scene
 * @param originalToRetain - the static percentage we want the new dimensions to retain
 * @param originalIsWidth  - if the originalToRetain is for width
 * @returns Rect with the adjusted size relative to the canvas
 */
export const convertPixelsToCanvasPercent = (
  assetDimensions: Bounds,
  sceneAspectRatio: Size,
  originalToRetain: number,
  originalIsWidth = true
): Rect => {
  const { x, y, width, height } = assetDimensions;
  const [assetRatioWidth, assetRatioHeight] = dimensionsToAspectRatio(width, height);

  const deltaWidthPercent = assetRatioWidth / sceneAspectRatio.width;
  const deltaHeightPercent = assetRatioHeight / sceneAspectRatio.height;

  if (originalIsWidth) {
    const originalRatio = originalToRetain / deltaWidthPercent;
    const ratioHeight = deltaHeightPercent * originalRatio;
    return new Rect(x, y, originalToRetain, ratioHeight);
  } else {
    const originalRatio = originalToRetain / deltaHeightPercent;
    const ratioWidth = deltaWidthPercent * originalRatio;
    return new Rect(x, y, ratioWidth, originalToRetain);
  }
};
