import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Charge from "client/models/charge";

interface InvoiceIndexModel {
  charges: Array<Charge>;
}

export default class InvoiceIndexRoute extends Route<InvoiceIndexModel> {
  @service
  declare store: Store;

  async model(): Promise<InvoiceIndexModel> {
    const charges = (await this.store.findAll("charge", { reload: true })) as unknown as Array<Charge>;

    return {
      charges
    };
  }
}
