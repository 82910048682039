import Helper from "@ember/component/helper";
import { service } from "@ember/service";
import type { PermissionName } from "client/services/permissions";
import type PermissionsService from "client/services/permissions";

export default class HasPermissionHelper extends Helper {
  @service
  declare permissions: PermissionsService;

  compute([permission]: [PermissionName]): boolean {
    return this.permissions.has(permission);
  }
}
