import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { ResizePositions } from "client/components/app/resize-element/component";

export interface ResizeDelta {
  xDelta: number;
  yDelta: number;
  scale: boolean;
}

interface AppResizeElementHandleArgs {
  position: ResizePositions;
  resizeStart?: () => void;
  resize?: (position: ResizePositions, coords: ResizeDelta) => void;
  resizeEnd?: () => void;
}

export default class AppResizeElementHandleComponent extends Component<AppResizeElementHandleArgs> {
  @action
  mouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (ev.button !== 0) {
      return;
    }

    const startX = ev.pageX;
    const startY = ev.pageY;

    if (this.args.resizeStart) {
      this.args.resizeStart();
    }

    const drag = ({ pageX, pageY, shiftKey: scale }: MouseEvent): void => {
      const xDelta = pageX - startX;
      const yDelta = pageY - startY;

      if (this.args.resize) {
        this.args.resize(this.args.position, { xDelta, yDelta, scale });
      }
    };

    const up = (): void => {
      document.removeEventListener("mousemove", drag);
      document.removeEventListener("mouseup", up);

      if (this.args.resizeEnd) {
        this.args.resizeEnd();
      }
    };

    document.addEventListener("mousemove", drag);
    document.addEventListener("mouseup", up);
  }
}
