import type ArrayProxy from "@ember/array/proxy";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type SelectableAsset from "client/models/selectable-asset";
import UserAsset from "client/models/user-asset";

interface Args {
  onSelect: (asset: SelectableAsset) => Promise<void>;
  previewOnSelect?: boolean;
  contents: ArrayProxy<FolderContent>;
  setFolder: (folder: Folder) => void;
  refresh: () => void;
  parentRouteName?: string;
}

export default class AssetManagementBaseItemComponent extends Component<Args> {
  tyleNamespace = getStyleNamespace("asset-management/base/item");

  get folders(): Array<FolderContent> {
    return this.args.contents.filter((item) => item instanceof Folder);
  }

  get userAssets(): Array<FolderContent> {
    return this.args.contents.filter((item) => item instanceof UserAsset);
  }
}
