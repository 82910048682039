import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import { AvaUseCases, iconSrc, PRIMARY_USE_CASES } from "client/authenticated/survey/data";
import type { VideoAssistantModel } from "client/authenticated/survey/video-assistant/route";
import type VideoAssistantUseCaseController from "client/authenticated/survey/video-assistant/use-case/controller";
import TrackingEvents from "client/events";
import removeObjectDuplicates from "client/lib/remove-object-duplicates";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type CurrentUserService from "client/services/current-user";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";
import type VideoAssistantService from "client/services/video-assistant";

export interface UseCaseDetails {
  useCase: string;

  iconSrc?: string;
  label: string;
}

export interface VideoAssistantUseCaseModel {
  useCaseDetails: UseCaseDetails[];
}

export default class VideoAssistantUseCaseRoute extends Route {
  @service
  declare router: RouterService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare notifications: NotificationsService;

  @service
  declare tracking: TrackingService;

  setupController(
    controller: VideoAssistantUseCaseController,
    model: VideoAssistantUseCaseModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    controller.showSecondary = this.selectedSecondaryUseCase;
  }

  async model(): Promise<VideoAssistantUseCaseModel> {
    const model = this.modelFor("authenticated.survey.video-assistant") as VideoAssistantModel;
    const useCaseDetails = this.generateUseCaseDetails(model);

    if (useCaseDetails.length === 0) {
      this.notifications.error("Something went wrong, please contact support.");
    }

    if (model.preselectedGenerator) {
      this.setInitialValues(model.preselectedGenerator);
    }

    return { useCaseDetails };
  }

  afterModel(_: {}, transition: Transition): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_VIDEO_ASSISTANT_STARTED, {
      referer: transition.from?.queryParams?.["referer"]
    });
  }

  private generateUseCaseDetails({ videoAssistantGenerators }: VideoAssistantModel): UseCaseDetails[] {
    const mappedDetails = videoAssistantGenerators
      .filter(({ useCase }) => useCase)
      .map(({ useCase }) => ({
        useCase,
        iconSrc: iconSrc(useCase),
        label: useCase === AvaUseCases.Template ? AvaUseCases.SomethingElse : useCase
      }));

    return removeObjectDuplicates(mappedDetails, "useCase") as UseCaseDetails[];
  }

  private setInitialValues(generator: VideoAssistantGenerator): void {
    this.videoAssistant.useCase = generator.useCase;
    this.videoAssistant.job = generator.job;
  }

  private get selectedSecondaryUseCase(): boolean {
    return !!this.videoAssistant.useCase && !PRIMARY_USE_CASES.includes(this.videoAssistant.useCase as AvaUseCases);
  }

  queryParams = {
    generatorId: {}
  };
}
