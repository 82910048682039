import { tracked } from "@glimmer/tracking";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import { isColorDark, sufficientContrast, WCAGContrast } from "client/lib/color";

export interface ColorPresetJSON {
  primary: string;
  secondary: string;
  background: string;
  text: string;
}

export default class ColorPreset implements Partial<ColorPresetJSON> {
  @tracked
  primary?: string;

  @tracked
  secondary?: string;

  @tracked
  background?: string;

  get text(): string {
    return this.getTextColor(this.background);
  }

  get valid(): boolean {
    return !!(this.primary && this.secondary && this.background);
  }

  get colors(): Array<string> {
    const { background, primary, secondary, text } = this;

    return [background, primary, secondary, text].filter((color) => typeof color === "string") as Array<string>;
  }

  get insufficientContrast(): boolean {
    if (!this.background || !this.primary || !this.secondary) {
      return false;
    }

    const insufficientPrimaryContrast = !sufficientContrast(this.background, this.primary, WCAGContrast.CUSTOM);
    const insufficientSecondaryContrast = !sufficientContrast(this.background, this.secondary, WCAGContrast.CUSTOM);

    return insufficientPrimaryContrast || insufficientSecondaryContrast;
  }

  constructor(
    private brandStyleConfig: BrandStyleConfig,
    { primary, secondary, background }: Partial<ColorPreset> = {}
  ) {
    Object.assign(this, { primary, secondary, background });
  }

  public updateColor(colorType: "background" | "primary" | "secondary", color: string | undefined): void {
    this[colorType] = color;
  }

  public replaceColor(oldColor: string, newColor: string): void {
    if (this.primary === oldColor) {
      this.primary = newColor;
    }

    if (this.secondary === oldColor) {
      this.secondary = newColor;
    }

    if (this.background === oldColor) {
      this.background = newColor;
    }
  }

  private getTextColor(background?: string): string {
    if (background && isColorDark(background)) {
      return this.brandStyleConfig.textColorLight;
    } else {
      return this.brandStyleConfig.textColorDark;
    }
  }

  public toJSON(): ColorPresetJSON | undefined {
    const { primary, secondary, background, text } = this;

    if (!primary || !secondary || !background || !text) {
      return undefined;
    }

    return { primary, secondary, background, text };
  }

  public static equal(a: Partial<ColorPresetJSON> | undefined, b: Partial<ColorPresetJSON> | undefined): boolean {
    if (!a || !b) {
      return false;
    }

    return a.background === b.background && a.primary === b.primary && a.secondary === b.secondary && a.text === b.text;
  }

  public toString(): string {
    return [this.primary, this.secondary, this.background].join(",");
  }
}
