import type Project from "client/models/project";
import type User from "client/models/user";

export default class ShutterstockCredits {
  constructor(public user: User | undefined, public project: Project) {}

  public get creditCost(): number {
    return this.project.stockLicenseCost;
  }

  public get visibleRemainingCreditsAfterPurchase(): number {
    return Math.max(this.remainingCreditsCount - this.creditCost, 0);
  }

  public get expiresAt(): Date {
    return this.user?.shutterstockCreditsExpireAt || new Date();
  }

  public get denyBuild(): boolean {
    return !!this.creditCost && this.willExceedCreditsLimit;
  }

  public get allowBuild(): boolean {
    return !!this.creditCost && !this.willExceedCreditsLimit;
  }

  public get willReachCreditsLimit(): boolean {
    return this.creditCost > 0 && this.creditCost === this.remainingCreditsCount;
  }

  public get remainingCreditsCount(): number {
    return this.user?.remainingShutterstockCreditsCount || 0;
  }

  private get willExceedCreditsLimit(): boolean {
    return this.creditCost > this.remainingCreditsCount;
  }
}
