import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type BrandStyle from "client/models/brand-style";
import type BrandStyleService from "client/services/brand-style";

export interface BrandIndexModel {
  brandStyles: Array<BrandStyle>;
}

export default class BrandIndexRoute extends Route<BrandIndexModel> {
  @service
  private declare brandStyle: BrandStyleService;

  async model(): Promise<BrandIndexModel> {
    const brandStyles = await this.brandStyle.getBrands();

    return { brandStyles };
  }
}
