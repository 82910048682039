import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { ROOT_FOLDER_ID } from "client/adapters/folder-content";
import type { InfiniteParams } from "client/components/infinite/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import { FolderCategoryTypes } from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import { OwnerTypes } from "client/models/media-asset";
import type SelectableAsset from "client/models/selectable-asset";

interface InfiniteAssetsParams extends InfiniteParams {
  ownerType: OwnerTypes;
  order: string;
  folderId?: string;
}

interface Args {
  showLoaderOnSelect: boolean;
  perPage: number;
  onSelect: (asset: SelectableAsset) => Promise<void>;
  folder?: Folder;
  ownerType: OwnerTypes;
  overrideOrder?: string;
  overrideOrderChange?: (order: string) => void;
  parentRouteName?: string;
  title?: string;
  order: string;
  onUpload: () => Promise<void>;
  reload: boolean;
  breadcrumbRoute?: boolean;
  refresh: () => void;
}

export default class AssetManagementBaseContentComponent extends Component<Args> {
  @service
  declare store: Store;

  @tracked
  private _currentFolder: Folder | undefined;

  styleNamespace = getStyleNamespace("asset-management/base/content");

  get folderId(): string {
    return this.currentFolder?.id || ROOT_FOLDER_ID;
  }

  get currentFolder(): Folder | undefined {
    return this.args.folder || this._currentFolder;
  }

  @action
  setFolder(folder: Folder): void {
    this._currentFolder = folder;
    this.args.refresh();
  }

  @action
  resetCurrentFolder(): void {
    this._currentFolder = undefined;
    this.args.refresh();
  }

  @action
  async getResults(params: InfiniteAssetsParams): Promise<ArrayProxy<FolderContent>> {
    return await this.store.query("folderContent", {
      folderId: params.folderId,
      page: params.page,
      type: params.ownerType === OwnerTypes.TEAM ? "team" : "user",
      per_page: params.perPage, // eslint-disable-line camelcase
      order: params.order,
      category: FolderCategoryTypes.USER_ASSETS
    });
  }

  get query(): Omit<InfiniteAssetsParams, keyof InfiniteParams> {
    return {
      ownerType: this.args.ownerType,
      order: this.args.order,
      folderId: this.folderId
    };
  }
}
