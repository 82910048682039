import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type FontsService from "client/services/fonts";
import type PermissionsService from "client/services/permissions";

export default class BrandRoute extends Route {
  @service
  declare fonts: FontsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    if (!this.permissions.has("feature_brand_edit")) {
      await this.router.replaceWith("authenticated.home");
      return;
    }
    await this.fonts.loadAllFonts();
  }
}
