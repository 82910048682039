import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { PaymentRouteModel } from "./route";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PaymentSourceService from "client/services/payment-source";

export default class PaymentController extends Controller<PaymentRouteModel> {
  @service
  declare router: RouterService;

  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare paymentSource: PaymentSourceService;

  @tracked
  error?: string;

  @tracked
  loading = false;

  @action
  onCardError(error: Error | stripe.Error): void {
    this.error = error.message;
  }

  @action
  cancel(): void {
    void this.router.transitionTo("authenticated.account.subscription");
  }

  @action
  async updateCard(token: stripe.Token): Promise<void> {
    if (this.loading) {
      return;
    }

    this.error = undefined;
    this.loading = true;

    try {
      await this.paymentSource.addCard(token);
      this.notifications.success("Your payment method has been updated");
      this.cancel();
    } catch (err) {
      // @ts-expect-error
      const errorMessage = err.errors[0].detail ?? err.message;
      this.error = errorMessage ?? "Could not update card details";

      // @ts-expect-error
      this.honeybadger.notify(err, {
        message: errorMessage,
        name: "UpdateCardDetails"
      });
    } finally {
      this.loading = false;
    }
  }
}
