import type { Scene } from "../../scene";
import type { Timeline } from "../../timeline";
import { InlineMutation } from "./vanilla-mutation";

export class SceneOrderMutation extends InlineMutation<Timeline> {
  constructor(timeline: Timeline, public scene: Scene, order: number) {
    super(timeline, (t) => {
      t._removeScene(scene);
      t._insertScene(scene, this.clamp(order, 0, timeline.sceneCount));
    });
  }
}
