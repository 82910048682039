import type { RecordProjectResponse } from "@biteable/network-model";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type AssetLibraryMyRecordingsRoute from "./route";
import InfiniteController from "client/controllers/infinite";
import { RECORD_ASSET_DELETE_FAILURE_NOTIFICATION } from "client/models/record-project";
import type NewVideoActionsService from "client/services/new-video-actions";
import type NotificationsService from "client/services/notifications";

export default class AssetLibraryMyRecordingsController extends InfiniteController {
  @service
  declare notifications: NotificationsService;

  @service
  declare newVideoActions: NewVideoActionsService;

  // eslint-disable-next-line camelcase
  per_page = 20;

  @action
  async removeRecording(selfRecording: RecordProjectResponse): Promise<void> {
    try {
      await selfRecording.destroy();

      await this.reloadResults();
    } catch (error: any) {
      this.notifications.error(RECORD_ASSET_DELETE_FAILURE_NOTIFICATION);
      throw error;
    }
  }

  async reloadResults(): Promise<void> {
    await (
      getOwner(this).lookup("route:authenticated.asset-library.my-recordings") as AssetLibraryMyRecordingsRoute
    )?.refresh();
  }
}
