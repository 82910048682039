import Model, { attr } from "@ember-data/model";

export default class ProjectEditAccess extends Model {
  @attr("string")
  public projectId!: string;

  @attr("string")
  public userId!: string;

  @attr("date")
  public currentUntil!: Date;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectEditAccess: ProjectEditAccess;
  }
}
