import type { Project } from "@biteable/network-model";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AppHeaderProjectTitleArgs {
  project: Project;
}

export default class AppHeaderProjectTitle extends Component<AppHeaderProjectTitleArgs> {
  styleNamespace = getStyleNamespace("app-header/project/title");
}
