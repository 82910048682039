import { ImageAnimations, LOGO_ANIMATIONS } from "renderer-engine";

export type ImageAnimationOptions = Record<ImageAnimations, { icon: string; description: string }>;

export enum ImageType {
  BACKGROUND = "background",
  LOGO = "logo"
}

export const getImageAnimationOptions = (imageType: ImageType): ImageAnimationOptions => {
  const animations: ImageAnimations[] = [ImageAnimations.NONE];

  if (imageType === ImageType.BACKGROUND) {
    animations.push(
      ...[
        ImageAnimations.ZOOM_IN,
        ImageAnimations.ZOOM_OUT,
        ImageAnimations.PAN_LEFT,
        ImageAnimations.PAN_RIGHT,
        ImageAnimations.PAN_UP,
        ImageAnimations.PAN_DOWN
      ]
    );
  } else if (imageType === ImageType.LOGO) {
    animations.push(...LOGO_ANIMATIONS);
  }

  return animations.reduce((acc, animation) => {
    acc[animation] = IMAGE_ANIMATIONS[animation];
    return acc;
  }, {} as ImageAnimationOptions);
};

export const IMAGE_ANIMATIONS = {
  [ImageAnimations.NONE]: {
    icon: "icon/animation/none",
    description: "No animation"
  },
  [ImageAnimations.ZOOM_IN]: {
    icon: "icon/animation/background/zoom-in",
    description: "Zoom in"
  },
  [ImageAnimations.ZOOM_OUT]: {
    icon: "icon/animation/background/zoom-out",
    description: "Zoom out"
  },
  [ImageAnimations.PAN_LEFT]: {
    icon: "icon/animation/background/pan-left",
    description: "Pan left"
  },
  [ImageAnimations.PAN_RIGHT]: {
    icon: "icon/animation/background/pan-right",
    description: "Pan right"
  },
  [ImageAnimations.PAN_UP]: {
    icon: "icon/animation/background/pan-up",
    description: "Pan up"
  },
  [ImageAnimations.PAN_DOWN]: {
    icon: "icon/animation/background/pan-down",
    description: "Pan down"
  },
  [ImageAnimations.FADE_IN]: {
    icon: "icon/animation/logo/fade-in",
    description: "Fade in"
  },
  [ImageAnimations.SCALE_UP]: {
    icon: "icon/animation/logo/scale-up",
    description: "Scale up"
  },
  [ImageAnimations.ELEGANT_RISE]: {
    icon: "icon/animation/logo/elegant-rise",
    description: "Elegant rise"
  },
  [ImageAnimations.ROTATE_BOUNCE]: {
    icon: "icon/animation/logo/rotate-bounce",
    description: "Rotate bounce"
  }
};
