import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { ProjectSceneCaptionRouteModel } from "client/authenticated/project/scene/caption/route";
import type { Logo } from "client/lib/editor-domain-model";
import type AdvancedEditorService from "client/services/advanced-editor";

export interface ProjectSceneCaptionLogoRouteModel extends ProjectSceneCaptionRouteModel {
  logo: Logo;
}

export interface ProjectSceneCaptionLogoRouteParams {
  zymbolId: string;
}

export default class ProjectSceneCaptionLogoRoute extends Route<ProjectSceneCaptionLogoRouteModel> {
  @service
  declare advancedEditor: AdvancedEditorService;

  async model({ zymbolId }: ProjectSceneCaptionLogoRouteParams): Promise<ProjectSceneCaptionLogoRouteModel> {
    const model = this.modelFor("authenticated.project.scene.caption") as ProjectSceneCaptionRouteModel;
    const { caption } = model;

    const logo = caption.logos.find(({ id }) => id === zymbolId);

    if (!logo) {
      return Promise.reject(new Error("Requested logo is not in caption"));
    }

    return {
      ...model,
      logo
    };
  }

  afterModel({ logo }: ProjectSceneCaptionLogoRouteModel): void {
    this.advancedEditor.setElement(logo);
  }
}
