import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AssetThumbnailImageViewerComponentArgs {
  imageUrl: string;
}

export default class AssetThumbnailImageViewerComponent extends Component<AssetThumbnailImageViewerComponentArgs> {
  styleNamespace = getStyleNamespace("asset-thumbnail/image-viewer");
}
