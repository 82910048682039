import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SelectMenuComponentArgs {
  id: string;
  onKeyDown(event: KeyboardEvent): unknown;
  onSelect(event: MouseEvent): unknown;
  open: boolean;
  align?: "start" | "end" | "stretch";
}

export default class SelectMenuComponent extends Component<SelectMenuComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/select/menu");

  get align(): string {
    return this.args.align ?? "end";
  }
}
