import Controller from "@ember/controller";
import Component from "@glimmer/component";
import type { EventRegister } from "client/lib/editor-domain-model";
import type { ModelEventRegister } from "client/services/undo";

type ProjectType =
  | Controller<{ eventRegister: EventRegister }>
  | Component<{ eventRegister: EventRegister }>
  | { eventRegister: ModelEventRegister };

export function transaction<T>(
  _target: ProjectType,
  _propertyKey: string,
  descriptor: TypedPropertyDescriptor<(this: ProjectType, ...args: any[]) => Promise<T>>
): PropertyDescriptor {
  const originalMethod = descriptor.value!;
  descriptor.value = async function (this: ProjectType, ...args: any[]): Promise<T> {
    const eventRegister =
      this instanceof Controller
        ? this.model.eventRegister
        : this instanceof Component
        ? this.args.eventRegister
        : this.eventRegister;
    return eventRegister.transaction(async () => await originalMethod.apply(this, args));
  };
  return descriptor;
}
