import Controller from "@ember/controller";
import type { AccountSubscriptionRouteModel } from "./route";

export default class AccountSubscriptionController extends Controller<AccountSubscriptionRouteModel> {
  get canManageSubscription(): boolean {
    return this.model.user.canManageSubscription;
  }

  get teamOwnerEmail(): string | undefined {
    return this.model.team?.ownerEmail;
  }

  get useTrialTile(): boolean {
    return !!this.model.fullSubscription;
  }

  get showInvoices(): boolean {
    return this.model.user.hasCharges;
  }
}
