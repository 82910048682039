import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { OptionFieldArgs } from "../option-field/component";
import OptionFieldComponent from "../option-field/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PermissionsService from "client/services/permissions";

interface AudioTrackComponentArgs extends OptionFieldArgs {
  audioUrl?: string;
  duration?: number;
  premium?: boolean;
  onPreview?: () => void;
  disableRemove?: boolean;
  onRemove?: () => void;
}
const DEFAULT_VOLUME = 0.5;

export default class AudioTrackComponent extends OptionFieldComponent<AudioTrackComponentArgs> {
  @service
  declare permissions: PermissionsService;

  @tracked
  isPlaying = false;

  @tracked
  progress = 0;

  audioElement?: HTMLAudioElement;

  _element!: HTMLElement;

  styleNamespace = getStyleNamespace("north-star/audio-track");

  constructor(owner: object, args: AudioTrackComponentArgs) {
    super(owner, args);

    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  togglePlayback(): void {
    if (this.isPlaying) {
      this.onPause();
    } else {
      this.play();
    }
  }

  @action
  handlePreview(e: MouseEvent): void {
    this.args.onPreview?.();
    if (this.hasTrack) {
      e.preventDefault();
      this.togglePlayback();
    }
  }

  @action
  handleRemove(e: MouseEvent): void {
    e.preventDefault();

    if (this.args.onRemove) {
      this.args.onRemove();
    }
  }

  play(): void {
    if (this.args.audioUrl) {
      const el = this.getAudioElement();
      this.pauseAllAudioElements();
      void el.play();
    }
  }

  onPause(): void {
    if (!this.isDestroyed && !this.isDestroying) {
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
      }

      this.isPlaying = false;
    }
  }

  pauseAllAudioElements(): void {
    Array.from(document.querySelectorAll("audio")).forEach((el) => el.pause());
  }

  getAudioElement(): HTMLAudioElement {
    return this.audioElement || this.createAudioElement();
  }

  createAudioElement(): HTMLAudioElement {
    const el = document.createElement("audio");
    el.addEventListener("timeupdate", this.onTimeUpdate);
    el.addEventListener("play", this.onPlay);
    el.addEventListener("pause", this.onPause);
    el.volume = DEFAULT_VOLUME;
    el.src = this.args.audioUrl || "";
    this._element.appendChild(el);
    this.audioElement = el;
    return el;
  }

  onTimeUpdate(ev: Event): void {
    if (!this.isDestroyed && !this.isDestroying) {
      const target = ev.target as HTMLAudioElement;
      this.progress = target.currentTime / target.duration;
    }
  }

  onPlay(): void {
    if (!this.isDestroyed && !this.isDestroying) {
      this.isPlaying = true;
    }
  }

  get trackEnabled(): boolean {
    return !this.args.premium || this.permissions.has("feature_premium_tracks");
  }

  get formattedDuration(): string {
    if (this.args.duration) {
      const duration = this.args.duration;

      const mins = Math.floor(duration / 60);
      const seconds = (duration % 60).toFixed(0);

      return `${mins}:${seconds.padStart(2, "0")}`;
    }

    return "";
  }

  get hasTrack(): boolean {
    return !!this.args.value && !!this.args.audioUrl;
  }
}
