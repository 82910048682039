import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface RadioToggleArgs {
  selectedValue: any;
  onChange(value: any): unknown;
}

export default class RadioToggleComponent extends Component<RadioToggleArgs> {
  styleNamespace = getStyleNamespace("north-star/radio-toggle");

  get selectedValue(): any {
    return this.args.selectedValue;
  }

  @action
  onOptionSelect(value: any): void {
    this.args.onChange(value);
  }
}
