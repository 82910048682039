import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { EventRegister, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type VoiceoverSelectModalService from "client/services/voiceover-select-modal";

export interface ProjectTimelineArgs {
  timeline: Timeline;
  eventRegister: EventRegister;
  project: Project;
  selectedSceneId: string;
}

export default class ProjectTimelineComponent extends Component<ProjectTimelineArgs> {
  @service
  declare voiceoverSelectModal: VoiceoverSelectModalService;

  styleNamespace = getStyleNamespace("project-timeline/voiceover");

  @action
  async openVoiceoverSelectModal(): Promise<void> {
    const { project, timeline, eventRegister } = this.args;
    this.voiceoverSelectModal.setModel({
      project,
      timeline,
      eventRegister,
      sceneId: this.args.selectedSceneId
    });
    await this.voiceoverSelectModal.open();
  }
}
