import { isEmpty } from "@ember/utils";

const PASSWORD_MIN_CHARS = 8;
const PASSWORD_MAX_BYTES = 72;
const PASSWORD_MIN_UNIQUE_CHARS = 5;

export type ValidationResult = { isValid: false; error?: string } | { isValid: true };

export const validatePassword = (user: { email: string; password: string }): ValidationResult => {
  if (isEmpty(user.password)) {
    return { isValid: false, error: "Please enter a password" };
  }

  // Astral-symbol-aware `split`.
  const chars = Array.from(user.password);
  const bytes = decodeURI(encodeURI(user.password));

  if (chars.length < PASSWORD_MIN_CHARS) {
    return { isValid: false, error: "Please make the password longer" };
  }

  if (bytes.length > PASSWORD_MAX_BYTES) {
    return { isValid: false, error: "Please make the password shorter" };
  }

  if (new Set(chars).size < PASSWORD_MIN_UNIQUE_CHARS) {
    return { isValid: false, error: "Please add more unique characters" };
  }

  if (user.password === user.email) {
    return { isValid: false, error: "Your password can't be the same as your email" };
  }

  return { isValid: true };
};

export const validateEmail = (email: string): ValidationResult => {
  if (isValidEmail(email)) {
    return { isValid: true };
  }

  return { isValid: false, error: "Please enter a valid email address" };
};

export const isValidEmail = (email: string): boolean => {
  return /^\S+@\S+\.\S+$/i.test(email);
};
