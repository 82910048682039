import { action } from "@ember/object";
import type { PositionalArgs, NamedArgs } from "ember-modifier";
import Modifier from "ember-modifier";
import { duration } from "moment";

enum Status {
  COLLAPSED = "--collapsed",
  EXPANDED = "--expanded",
  TRANSITIONING = "--transitioning"
}

interface ExpandCollapseSignature {
  Args: {
    Named: {
      open?: boolean;
    };
    Positional: [string];
  };
}

export default class ExpandCollapseModifier extends Modifier<ExpandCollapseSignature> {
  DURATION_REGEX = /(?<value>[\d.]+)(?<unit>[\S+])/g;
  DURATION_PROPERTY_NAME = "--disclosureTiming";

  element!: Element;
  duration!: number;
  intrinsicHeight!: number;
  keyframes!: Keyframe[];
  rAF?: number;
  start!: number;

  inserted = false;

  modify(
    element: Element,
    [_lengthOfInput]: PositionalArgs<ExpandCollapseSignature>,
    { open }: NamedArgs<ExpandCollapseSignature>
  ): void {
    this.element = element;
    if (!this.inserted) {
      this.handleInsert(element, open);
      this.inserted = true;
    } else {
      this.handleUpdate(open);
    }
  }

  handleInsert(element: Element, open = false): void {
    this.element = element;
    this.duration = this.getDurationFromElementStyles(this.element);
    this.intrinsicHeight = this.element.scrollHeight;
    this.keyframes = [
      { height: "0" },
      {
        height: `${this.intrinsicHeight}px`
      }
    ];

    if (open) {
      this.element.classList.add(Status.EXPANDED);
    } else {
      this.element.classList.add(Status.COLLAPSED);
    }
  }

  handleUpdate(open = false): void {
    if ("animate" in this.element) {
      this.animateWithWebAnimations(open);
    } else {
      if (this.rAF) {
        cancelAnimationFrame(this.rAF);
      }

      this.start = performance.now();

      this.animateWithRequestAnimationFrame(open);
    }
  }

  animateWithRequestAnimationFrame(open: boolean): void {
    if (open) {
      this.onStartExpand();
      requestAnimationFrame(this.expand);
    } else {
      this.onStartCollapse();
      requestAnimationFrame(this.collapse);
    }
  }

  animateWithWebAnimations(open: boolean): void {
    let callback;
    let keyframes;

    if (open) {
      callback = this.onFinishExpand;
      keyframes = this.keyframes;
      this.onStartExpand();
    } else {
      callback = this.onFinishCollapse;
      keyframes = [...this.keyframes].reverse();
      this.onStartCollapse();
    }

    this.element
      .animate(keyframes, {
        duration: this.duration
      })
      .addEventListener("finish", callback, { once: true });
  }

  @action
  collapse(now: number): void {
    const step = (now - this.start) / this.duration;
    const progress = Math.max(1 - step, 0);

    (this.element as HTMLElement).style.setProperty("--height", `${this.intrinsicHeight * progress}px`);

    if (progress > 0) {
      this.rAF = requestAnimationFrame(this.collapse);
    } else {
      this.rAF = undefined;
      this.onFinishCollapse();
    }
  }

  @action
  expand(now: number): void {
    const step = (now - this.start) / this.duration;
    const progress = Math.min(step, 1);

    (this.element as HTMLElement).style.setProperty("--height", `${this.intrinsicHeight * progress}px`);

    if (progress < 1) {
      this.rAF = requestAnimationFrame(this.expand);
    } else {
      this.rAF = undefined;
      this.onFinishExpand();
    }
  }

  @action
  onFinishCollapse(): void {
    this.element.classList.add(Status.COLLAPSED);
    this.element.classList.remove(Status.TRANSITIONING);
    this.element.setAttribute("hidden", "");
  }

  @action
  onFinishExpand(): void {
    this.element.classList.add(Status.EXPANDED);
    this.element.classList.remove(Status.TRANSITIONING);
  }

  onStartCollapse(): void {
    this.element.classList.add(Status.TRANSITIONING);
    this.element.classList.remove(Status.EXPANDED);
  }

  onStartExpand(): void {
    this.element.classList.add(Status.TRANSITIONING);
    this.element.classList.remove(Status.COLLAPSED);
    this.element.removeAttribute("hidden");
  }

  getDurationFromElementStyles(element: Element): number {
    let unit;
    let value;

    if ("computedStyleMap" in element) {
      const raw = element.computedStyleMap().get(this.DURATION_PROPERTY_NAME);
      // @ts-expect-error
      const parsed = CSSNumericValue.parse(raw);

      // @ts-expect-error
      unit = parsed.unit;
      // @ts-expect-error
      value = parsed.value;
    } else {
      const raw = getComputedStyle(element).getPropertyValue(this.DURATION_PROPERTY_NAME);
      const groups = this.DURATION_REGEX.exec(raw)?.groups;

      unit = groups?.["unit"];
      value = groups?.["value"];
    }

    return duration({ [unit]: value }).asMilliseconds();
  }
}
