import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Media, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";

interface Args {
  media: Media;
}

export default class FloatingPanelsMediaComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panels/style/media");

  @service
  private declare advancedEditor: AdvancedEditorService;

  get scene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  get mediaIsBackground(): boolean {
    return this.args.media === this.scene?.background;
  }
}
