import LibraryCategory from "client/models/library-category";

export default [
  new LibraryCategory(
    "happy positive fun",
    "Happy & positive",
    "https://images.unsplash.com/photo-1509909756405-be0199881695?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "money business",
    "Money & business",
    "https://images.unsplash.com/photo-1427751840561-9852520f8ce8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "workplace office",
    "Workplace",
    "https://images.unsplash.com/photo-1573497701175-00c200fd57f0?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "teamwork productivity teams",
    "Teamwork & productivity",
    "https://images.unsplash.com/photo-1557425493-6f90ae4659fc?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "friends family",
    "Friends & family",
    "https://images.unsplash.com/photo-1511632765486-a01980e01a18?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "technology computer laptop mobile cell phone",
    "Technology",
    "https://images.unsplash.com/photo-1601524909162-ae8725290836?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "people person woman man child",
    "People",
    "https://images.unsplash.com/photo-1599140781147-e9a53c8cdd03?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "animal nature world",
    "Animals & nature",
    "https://images.unsplash.com/photo-1598229497688-7b86be36d229?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "food beverage drink",
    "Food & beverage",
    "https://images.unsplash.com/photo-1592243290187-72b7d0e676b8?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "profession jobs career",
    "Professions",
    "https://images.unsplash.com/photo-1555432782-efda97a5088a?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "health medicine wellness",
    "Health & medicine",
    "https://images.unsplash.com/photo-1536064479547-7ee40b74b807?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  ),
  new LibraryCategory(
    "holiday celebration",
    "Holidays & celebration",
    "https://images.unsplash.com/photo-1525373612132-b3e820b87cea?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjU2MDEyfQ"
  )
];
