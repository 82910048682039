import type { Scene } from "../../scene";
import type { Caption } from "../../scene/caption";
import type { TimelineFacade } from "../../timeline";
import { StrictMutation } from "./mutation";

export abstract class AddCaptionMutation extends StrictMutation<Caption | void> {
  grouped = false;
  private originalSceneDuration: number;
  private originalCaptionOffsets: number[];
  caption?: Caption;

  constructor(protected scene: Scene) {
    super();
    this.originalSceneDuration = this.scene.duration;
    this.originalCaptionOffsets = this.scene.captions.map((c) => c.offset);
  }

  abstract prepare(facade: TimelineFacade): Promise<void>;

  run(): Caption | undefined {
    if (this.caption) {
      this.shiftCaptions();

      this.scene._captions = [...this.scene.captions, this.caption];

      this.increaseSceneDuration();

      return this.caption;
    }

    return;
  }

  private increaseSceneDuration(): void {
    if (this.caption) {
      const maxEnd = Math.max(...this.scene.captions.map((c) => c.end));
      if (this.scene.duration < maxEnd) {
        this.scene._duration = maxEnd;
      }
    }
  }

  private shiftCaptions(): void {
    const caption = this.caption;
    if (!caption) {
      return;
    }

    for (const existingCaption of caption.scene.captions) {
      const overlaps = existingCaption.offset < caption.end && caption.offset < existingCaption.end;

      if (overlaps) {
        const diff = caption.end - existingCaption.offset;
        caption.scene.captions
          .filter((c) => c.offset >= existingCaption.offset)
          .forEach((c) => {
            c._offset += diff;
          });
        break;
      }
    }
  }

  revert(): void {
    if (this.caption) {
      this.scene._captions = this.scene._captions.filter((c) => c !== this.caption);

      const captions = this.scene._captions;
      this.originalCaptionOffsets.forEach((offset, i) => {
        if (captions[i]!.offset !== offset) {
          captions[i]!._offset = offset;
        }
      });
      if (this.originalSceneDuration !== this.scene.duration) {
        this.scene._duration = this.originalSceneDuration;
      }
    }
  }
}
