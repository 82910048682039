import type { Project } from "@biteable/network-model";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Comment from "client/models/comment";

interface ExportCommentsArgs {
  comments: Array<Comment>;
  project: Project;
  renderRequired: boolean;
  isRendering: boolean;
}

export default class ExportCommentsComponent extends Component<ExportCommentsArgs> {
  styleNamespace = getStyleNamespace("export-comments");
}
