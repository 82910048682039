import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { MouseEventButtons } from "client/services/mouse";

enum ResizeHandles {
  START = "start",
  END = "end"
}

interface ResizerArgs {
  pixelsPerSecond: number;
  hideStartHandle?: boolean;
  isBackgroundDark?: boolean;
  startValue?: string;
  endValue?: string;
  showValueDown?: boolean;
  startDrag?: () => Promise<void>;
  onDrag?: (handle: ResizeHandles, delta: number) => Promise<void>;
  endDrag?: (handle: ResizeHandles) => Promise<void>;
}

export default class ResizerComponent extends Component<ResizerArgs> {
  @tracked
  _isResizing = false;

  @tracked
  _activeHandle = ResizeHandles.START;

  styleNamespace = getStyleNamespace("generic/resizer");

  get isResizing(): boolean {
    return this._isResizing;
  }

  get activeHandle(): ResizeHandles {
    return this._activeHandle;
  }

  @action
  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  @action
  async startDrag(handle: ResizeHandles, event: MouseEvent): Promise<void> {
    if (event.button !== MouseEventButtons.PRIMARY) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this._activeHandle = handle;

    if (this.args.startDrag) {
      await this.args.startDrag();
    }

    const startX = event.pageX;
    const mousemove = async (event: MouseEvent): Promise<void> => {
      const { pageX } = event;
      const xDelta = pageX - startX;
      const delta = xDelta / this.args.pixelsPerSecond;

      await this.drag(handle, delta);
    };

    const mouseup = async (): Promise<void> => {
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);

      await this.endDrag(handle);
    };

    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);
  }

  @action
  async drag(handle: ResizeHandles, delta: number): Promise<void> {
    if (this.args.onDrag) {
      await this.args.onDrag(handle, delta);
    }
    this._isResizing = true;
  }

  @action
  async endDrag(handle: ResizeHandles): Promise<void> {
    if (this.args.endDrag) {
      await this.args.endDrag(handle);
    }
    this._isResizing = false;
  }
}
