import Component from "@glimmer/component";
import type { ContentTypeOption } from "../../search/component";
import { INDIVIDUAL_CONTENT_LABELS, COMPOUND_CONTENT_LABELS } from "../../search/component";

interface Args {
  query: string;
  type: ContentTypeOption;
}

export default class ContentSearchComponent extends Component<Args> {
  get label(): string {
    return this.args.type.label;
  }

  get individualContentLabels(): string[] {
    return INDIVIDUAL_CONTENT_LABELS;
  }

  get compoundContentLabels(): string[] {
    return COMPOUND_CONTENT_LABELS;
  }
}
