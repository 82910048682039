import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { Grid } from "client/lib/grid-guides";
import type AspectRatio from "client/models/aspect-ratio";
import { AspectRatios } from "client/models/aspect-ratio";

interface GuideConfig {
  cells: number;
  verticalMargin: number;
  horizontalMargin: number;
}

export default class GridService extends Service {
  @tracked
  declare grid: Grid;

  guideConfigs = new Map<string, GuideConfig>([
    [AspectRatios.SQUARE, { cells: 14, verticalMargin: 1.5, horizontalMargin: 1.5 }],
    [AspectRatios.MOBILE, { cells: 9, verticalMargin: 1.5, horizontalMargin: 1 }],
    [AspectRatios.WIDESCREEN, { cells: 32, verticalMargin: 2.5, horizontalMargin: 2 }],
    [AspectRatios.PORTRAIT, { cells: 12, verticalMargin: 2, horizontalMargin: 1.5 }]
  ]);

  @action
  initialize(aspectRatio: AspectRatio): void {
    const guideConfig = this.guideConfigs.get(aspectRatio?.name);
    if (guideConfig) {
      const { cells, horizontalMargin, verticalMargin } = guideConfig;
      const gridCells = { x: cells, y: cells * aspectRatio.ratioX };
      const marginCells = { x: horizontalMargin, y: verticalMargin };

      this.grid = new Grid(gridCells, marginCells);
    }
  }
}
