import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Favorable from "client/models/favorable";
import type ProjectScene from "client/models/project-scene";
import type ProjectScenePreview from "client/models/project-scene-preview";
import type HoneybadgerService from "client/services/honeybadger";

interface ProjectScenePreviewArgs {
  scene: (ProjectScene | ProjectScenePreview) & Favorable;
  disabled?: boolean;
  showTitle?: boolean;
  onSelect?: (scene: ProjectScene | ProjectScenePreview) => void;
  showAutoBrand?: boolean;
}

export default class ProjectScenePreviewComponent extends Component<ProjectScenePreviewArgs> {
  @service
  declare store: Store;

  @service
  declare honeybadger: HoneybadgerService;

  styleNamespace = getStyleNamespace("project-scene-preview");

  @action
  onSelect(): void {
    if (this.args.disabled) {
      return;
    }

    this.args.onSelect?.(this.args.scene);
  }

  @action
  onFavorite(): void {
    void this.args.scene.favorite();
  }

  @action
  onUnfavorite(): void {
    void this.args.scene.unfavorite();
  }

  get isBrandable(): boolean {
    return !!this.args.showAutoBrand && this.args.scene.isBrandableTemplate;
  }

  get favorited(): boolean {
    return this.args.scene.favorited;
  }

  get hasTitle(): boolean {
    return !!this.args.scene.title;
  }

  get showTitle(): boolean {
    return this.hasTitle && (this.args.showTitle ?? true);
  }
}
