import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import { WIDE_ASPECT_RATIO_SLUG } from "client/models/aspect-ratio";

export default class TemplateFiltersAspectRatiosComponent extends Component<{
  aspectRatio?: string;
  disableNonWide?: boolean;
  updateAspectRatio(aspectRatioId: string): unknown;
}> {
  @service
  declare store: Store;

  @tracked
  aspectRatios: AspectRatio[] = [];

  styleNamespace = getStyleNamespace("discovery/template-filters/aspect-ratio");

  @action
  async loadAspectRatios(): Promise<void> {
    // @ts-expect-error
    this.aspectRatios = await this.store.findAll("aspectRatio", { reload: true });
  }

  @action
  updateAspectRatio(aspectRatioId: string, disabled: boolean): void {
    if (!disabled) {
      this.args.updateAspectRatio(aspectRatioId);
    }
  }

  get disabledAspectRatios(): string[] {
    if (this.args.disableNonWide) {
      return this.aspectRatios.filter(({ slug }) => slug !== WIDE_ASPECT_RATIO_SLUG).map((ar) => ar.id);
    } else {
      return [];
    }
  }
}
