import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { debounce } from "@ember/runloop";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";

interface ColorComboChooserArgs {
  eventRegister: EventRegister;
  selectedScenes: Array<Scene>;
  brandStyle: BrandStyle;
}

export default class ColorComboChooser extends Component<ColorComboChooserArgs> {
  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("color-combo-chooser");

  get brandStyleConfig(): BrandStyleConfig {
    return this.args.brandStyle?.style;
  }

  @action
  save(): void {
    debounce(this, this.doSave, 200, false);
  }

  @action
  createBrand(): void {
    void this.router.transitionTo("authenticated.brand.new", {
      queryParams: { returnPath: this.router.currentURL }
    });
  }

  doSave(): void {
    const { eventRegister, selectedScenes } = this.args;

    for (const scene of selectedScenes) {
      void eventRegister.facade.saveScene(scene);
    }
  }
}
