import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { TemplatesIndexModel } from "./route";
import { loadProjectTemplates } from "./route";
import type ProjectTemplate from "client/models/project-template";

export default class TemplatesIndexController extends Controller<TemplatesIndexModel> {
  page = 1;
  search?: string;
  aspectRatio?: string;
  branded?: boolean;
  category?: string;

  @tracked
  loading = false;

  @service
  declare store: Store;

  @tracked
  totalCount = 0;

  @tracked
  perPage = 0;

  @tracked
  projectTemplates: Array<ProjectTemplate> = [];

  get hasNextPage(): boolean {
    return this.totalCount > (this.page + 1) * this.perPage;
  }

  @action
  async loadNextPage(): Promise<void> {
    if (!this.hasNextPage) {
      return;
    }

    this.loading = true;

    try {
      this.page++;

      const { page, search, aspectRatio, branded, category } = this;

      const projectTemplates = await loadProjectTemplates(this.store, {
        category,
        page,
        search,
        aspectRatio,
        branded
      });

      this.projectTemplates = [...this.projectTemplates, ...projectTemplates];
    } finally {
      this.loading = false;
    }
  }
}
