import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { AspectRatios } from "client/models/aspect-ratio";
import type UserAsset from "client/models/user-asset";

interface AssetThumbnailModalContentArgs {
  asset: UserAsset;
  author?: string;
  addToTimeline?: boolean;
  downloadable?: boolean;
  description?: string;
  canAddToTimeline?: boolean;
  image?: string;
  video?: string;
  aspectRatio: string;
  type?: string;
  title?: string;
  isFavorite: boolean;
  handleFavorite?: (asset: UserAsset) => void;
}

const validAspectRatios: Set<string> = new Set([
  AspectRatios.SQUARE,
  AspectRatios.WIDESCREEN,
  AspectRatios.PORTRAIT,
  AspectRatios.MOBILE
]);

export default class AssetThumbnailModalContentComponent extends Component<AssetThumbnailModalContentArgs> {
  styleNamespace = getStyleNamespace("asset-thumbnail/modal-content");

  @tracked
  asset = this.args.asset;

  get isAssetAVideo(): boolean {
    return !!this.args.video;
  }

  get showAspectRatio(): boolean {
    return validAspectRatios.has(this.args.aspectRatio);
  }

  get downloadable(): boolean {
    return this.args.downloadable ?? true;
  }

  get canAddToTimeline(): boolean {
    return this.args.canAddToTimeline ?? true;
  }

  get oneColumnLayout(): boolean {
    return (AspectRatios.WIDESCREEN as string) === this.args.aspectRatio;
  }

  get downloadUrl(): string {
    return this.args.asset.filestackUrl;
  }

  get title(): string {
    return this.args.title ?? this.args.asset.name;
  }

  get type(): string {
    return this.args.type || this.asset.assetType;
  }
}
