import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectSceneRouteModel } from "client/authenticated/project/scene/route";
import type ProjectSceneRoute from "client/authenticated/project/scene/route";
import type { Caption } from "client/lib/editor-domain-model";
import type ZymbolGroup from "client/models/zymbol-group";
import type AdvancedEditorService from "client/services/advanced-editor";
import type PlaybackService from "client/services/playback";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export interface ProjectSceneCaptionRouteParams {
  zymbolGroupId: string;
}

export interface ProjectSceneCaptionRouteModel extends ProjectSceneRouteModel {
  zymbolGroup: ZymbolGroup;
  caption: Caption;
}

export default class ProjectSceneCaptionRoute extends Route<ProjectSceneCaptionRouteModel> {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare playback: PlaybackService;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @action
  error(error: any): void {
    if (!error.errors) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw Error(error);
    }

    const [first] = error.errors;

    if (first && first.status === 404) {
      void this.router.replaceWith("authenticated.project.scene");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw Error(error);
  }

  async model({ zymbolGroupId }: ProjectSceneCaptionRouteParams): Promise<ProjectSceneCaptionRouteModel> {
    const model = this.modelFor<ProjectSceneRoute>("authenticated.project.scene");
    const { scene } = model;

    const zymbolGroup = await this.store.findRecord("zymbolGroup", zymbolGroupId);
    const caption = scene.captions.find(({ id }: ZymbolGroup) => id === zymbolGroupId);

    if (!zymbolGroup || !caption) {
      return Promise.reject(new Error("Requested caption is not in scene"));
    }

    return {
      ...model,
      zymbolGroup,
      caption
    };
  }

  async afterModel({ caption, zymbolGroup }: ProjectSceneCaptionRouteModel): Promise<void> {
    if (!zymbolGroup.hasZymbols) {
      // Protect against empty captions
      await zymbolGroup.removeZymbolGroup();
      void this.router.replaceWith("authenticated.project.scene.background");
      return;
    }

    this.advancedEditor.setCaption(caption);

    void this.playback.stop(await zymbolGroup.defaultPreviewTime);
    this.timelineEvents.publish(TimelineEvents.ACTIVE_CAPTION_CHANGED, zymbolGroup);
  }

  deactivate(): void {
    this.advancedEditor.unsetCaption();
  }
}
