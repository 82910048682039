import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isPresent } from "client/lib/is-present";
import type ProjectRender from "client/models/project-render";
import type ProjectScene from "client/models/project-scene";

interface BuildFailedMessageComponentArgs {
  projectRender: ProjectRender;
}

export default class BuildFailedMessageComponent extends Component<BuildFailedMessageComponentArgs> {
  @tracked
  failedRenderScene?: ProjectScene;

  styleNamespace = getStyleNamespace("export/video-overlay/build-failed-message");

  @action
  async getFailedRenderScene(): Promise<void> {
    if (this.failurePart) {
      const project = await this.args.projectRender.project;

      this.failedRenderScene = await project.getSceneAtTime(this.failurePart);
    }
  }

  get failurePart(): number | undefined {
    return this.args.projectRender?.failurePart;
  }

  get failureTime(): string | undefined {
    return this.args.projectRender?.failureTime;
  }

  get projectId(): string | undefined {
    const project = this.args.projectRender.belongsTo("project").value();
    return project?.id;
  }

  get hasFailureTimestamp(): boolean {
    return isPresent(this.failurePart) && isPresent(this.failureTime);
  }

  get statusText(): string {
    return this.args.projectRender.statusText;
  }
}
