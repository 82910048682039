import type Folder from "client/models/folder";
import type FolderContainable from "client/models/folder-containable";
import ProjectPreview from "client/models/project-preview";

export const isFolderInFolderTree = (root: Folder, target: Folder): boolean => {
  const children = root.hasMany("children").value() || [];
  // @ts-expect-error
  return root === target || children.some((f: Folder) => isFolderInFolderTree(f, target));
};

export const isFolderContainable = (target: any): target is FolderContainable =>
  "containableId" in target && "containableType" in target;

export const getFolderContainableFromDragContent = async (content: any): Promise<FolderContainable | undefined> => {
  if (isFolderContainable(content)) {
    return content;
  } else if (content instanceof ProjectPreview) {
    return await content.project;
  }

  return;
};
