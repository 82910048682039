import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SoundControlComponentArgs {
  isLoading: boolean;
  isMuted: boolean;
  setVolume: (event: Event) => void;
  toggleMute: () => void;
  volume: number;
}

export default class SoundControlComponent extends Component<SoundControlComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/video-player/sound-control");

  @action
  setVolume(event: Event): void {
    if (this.args.isMuted) {
      this.args.toggleMute();
    }

    this.args.setVolume(event);
  }

  get isMuted(): boolean {
    return this.args.isMuted || this.args.volume === 0;
  }

  get volume(): number {
    return this.args.isMuted ? 0 : this.args.volume;
  }
}
