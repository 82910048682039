import { service } from "@ember/service";
import Component from "@glimmer/component";
import sample from "lodash/sample";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";

const GREETINGS = [
  "Welcome",
  "Welcome back",
  "Hello",
  "Hi",
  "Hey",
  "Hey there",
  "Ahoy",
  "Howdy",
  "Heidy-ho",
  "Greetings",
  "Bonjour",
  "Hola",
  "Nǐ hǎo",
  "Namaste",
  "Ciao",
  "Kon'nichiwa",
  "G'day",
  "Kia ora",
  "Cześć"
];

const NON_NAME_GREETINGS = [
  ...GREETINGS,
  "Hello sunshine",
  "Howdy partner",
  "What's kickin' little chicken?",
  "Howdy-doody",
  "Ahoy matey",
  "Greetings and salutations",
  "G'day mate",
  "I've missed you",
  "Do you think about me when you're logged out?",
  "Why don't we see each other more often?",
  "Am I just a tool to you?",
  "Let's render sweet, sweet videos together",
  "No one makes videos like you",
  "Your rage clicking is driving me nuts",
  "When did you last tidy up around here?"
];

export default class DiscoveryDashboardGreeting extends Component {
  @service
  declare auth: AuthService;

  greeting = this.generateGreeting();

  styleNamespace = getStyleNamespace("discovery/dashboard/greeting");

  private generateGreeting(): string {
    if (this.firstName) {
      return `${sample(GREETINGS)} ${this.firstName}`;
    } else {
      return `${sample(NON_NAME_GREETINGS)}`;
    }
  }

  private get firstName(): string | undefined {
    return this.auth.currentUser?.firstName;
  }
}
