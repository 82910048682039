import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";

export default class SidebarComponent extends Component {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("collaboration/sidebar");

  get hasMultipleMembers(): boolean {
    return !!this.auth.currentTeam?.hasMultipleMembers;
  }
}
