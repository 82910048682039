import { tracked } from "@glimmer/tracking";
import type { License, OriginalAsset } from "../../asset";
import { Asset } from "../../asset";
import type { Frame } from "./frame";

export class Image extends Asset {
  @tracked
  _animation: string;

  @tracked
  _name?: string;

  @tracked
  _sourceUrl?: string;

  @tracked
  _previewUrl?: string;

  @tracked
  _opacity?: number;

  @tracked
  _rotation: number;

  constructor(
    animation: string,
    name?: string,
    sourceUrl?: string,
    previewUrl?: string,
    frame?: Frame,
    opacity?: number,
    mirror?: boolean,
    objectFit?: string,
    originalAsset?: OriginalAsset,
    license?: License,
    rotation?: number
  ) {
    super(mirror ?? false, objectFit ?? "fill", originalAsset, license, frame);
    this._animation = animation;
    this._name = name;
    this._sourceUrl = sourceUrl;
    this._previewUrl = previewUrl;
    this._opacity = opacity;
    this._rotation = rotation ?? 0;
  }

  get hasContent(): boolean {
    return !!this.sourceUrl;
  }

  get category(): string {
    return "image";
  }

  get formattedCategory(): string {
    return "image";
  }

  get previewImageUrl(): string | undefined {
    return this._previewUrl;
  }

  get animation(): string {
    return this._animation;
  }

  get name(): string | undefined {
    return this._name;
  }

  get sourceUrl(): string | undefined {
    return this._sourceUrl;
  }

  get previewUrl(): string | undefined {
    return this._previewUrl;
  }

  get opacity(): number | undefined {
    return this._opacity;
  }

  get colors(): string[] {
    const colors = new Set<string>();
    if (this.frame) {
      colors.add(this.frame.color.substring(0, 7).toUpperCase());
    }

    return [...colors];
  }

  get rotation(): number {
    return this._rotation;
  }

  _reset(): void {
    super._reset();
    this._name = undefined;
    this._sourceUrl = undefined;
    this._previewUrl = undefined;
  }
}
