import Model, { attr, belongsTo } from "@ember-data/model";
import type Plan from "client/models/plan";
import type PlanFeature from "client/models/plan-feature";

export default class PlanFeatureValue extends Model {
  @attr("string")
  declare value: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("plan-feature", { async: false, inverse: null })
  declare planFeature: PlanFeature;

  @belongsTo("plan", { async: false, inverse: "planFeatureValues" })
  declare plan: Plan;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    planFeatureValue: PlanFeatureValue;
  }
}
