import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type ColorPreset from "client/lib/brand/color-preset";
import getStyleNamespace from "client/lib/get-style-namespace";
import type FontsService from "client/services/fonts";

const BASE_WIDTH = 208;

interface BrandColorPresetPreviewArgs {
  colorPreset: ColorPreset;
  brandConfig?: BrandStyleConfig;
  heading?: string;
  subheading?: string;
  symbol?: string;
}

export default class BrandColorPresetPreviewComponent extends Component<BrandColorPresetPreviewArgs> {
  @tracked
  scale = 1;

  @tracked
  loadingFont = false;

  @tracked
  loadedFontFamily?: string;

  @service
  declare fonts: FontsService;

  styleNamespace = getStyleNamespace("brand/color-preset/preview");

  get heading(): string {
    return this.args.heading ?? "Primary text";
  }

  get subheading(): string {
    return this.args.subheading ?? "Secondary text";
  }

  get symbol(): string {
    return this.args.symbol ?? "shapes";
  }

  @action
  didInsert(element: HTMLElement): void {
    new ResizeObserver(() => requestAnimationFrame(() => this.scaleText(element))).observe(element);

    this.scaleText(element);
    void this.loadFont();
  }

  @action
  fontFamilyDidChange(): void {
    void this.loadFont();
  }

  private async loadFont(): Promise<void> {
    if (this.fontFamily) {
      try {
        this.loadingFont = true;
        await this.fonts.loadFont(this.fontFamily);
        this.loadedFontFamily = this.fontFamily;
      } finally {
        this.loadingFont = false;
      }
    }
  }

  get colorPreset(): ColorPreset {
    return this.args.colorPreset;
  }

  private scaleText(element: HTMLElement): void {
    this.scale = element.clientWidth / BASE_WIDTH;
  }

  get previewStyles(): SafeString {
    const styles = [`font-size: ${this.scale}rem`];

    if (this.colorPreset.background) {
      styles.push(`background: ${this.colorPreset.background}`);
    }

    if (this.loadedFontFamily) {
      styles.push(`font-family: ${this.loadedFontFamily}`);
    }

    return htmlSafe(styles.join(";"));
  }

  get primaryTextStyles(): SafeString | undefined {
    const styles: string[] = [];

    if (this.colorPreset.primary) {
      styles.push(`color: ${this.colorPreset.primary}`);
    } else {
      styles.push("font-style: italic");
    }

    return htmlSafe(styles.join(";"));
  }

  get bodyTextStyles(): SafeString | undefined {
    const styles: string[] = [];

    if (this.colorPreset.text) {
      styles.push(`color: ${this.colorPreset.text}`);
    } else {
      styles.push("font-style: italic");
    }

    return htmlSafe(styles.join(";"));
  }

  get fontFamily(): string | undefined {
    return this.args.brandConfig?.fontFamily;
  }
}
