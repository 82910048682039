import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { ACCOUNT_ADD_TEAM_MEMBER_SUCCESS_NOTIFICATION } from "client/components/team-invite/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Team from "client/models/team";
import type NotificationsService from "client/services/notifications";
import type TeamService from "client/services/team";

export default class TeamMemberVacantRowComponent extends Component {
  @tracked
  error?: string;

  @tracked
  invalid = false;

  @tracked
  forceShowError = false;

  @tracked
  saving = false;

  @tracked
  email = "";

  styleNamespace = getStyleNamespace("account/team/member-vacant-row");

  @service
  private declare team: TeamService;

  @service
  declare notifications: NotificationsService;

  private get currentTeam(): Team | undefined {
    return this.team.team;
  }

  get errorMessage(): string {
    return this.error ?? "Please enter a valid email address";
  }

  @action
  resetError(): void {
    this.error = undefined;
    this.invalid = false;
    this.forceShowError = false;
  }

  @action
  async invitePeople(event: Event): Promise<void> {
    event.preventDefault();

    if (!(event.target instanceof HTMLFormElement) || !event.target.checkValidity() || !this.currentTeam) {
      return;
    }

    try {
      this.saving = true;
      await this.currentTeam.addTeamMember(this.email);
      this.notifications.success(ACCOUNT_ADD_TEAM_MEMBER_SUCCESS_NOTIFICATION);
      this.email = "";
    } catch (error) {
      const msg = (error as Error).message;
      this.error = msg;
      this.forceShowError = true;
      this.invalid = true;
      this.notifications.error(msg);
    } finally {
      this.saving = false;
    }
  }
}
