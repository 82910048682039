import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewImageArgs {
  project: ProjectPreview;
  aspectRatio: string;
}

const FALLBACK_THUMBNAIL_URI =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8c+7WfwAIVwN1ZWQpkwAAAABJRU5ErkJggg==";

export default class ProjectPreviewImageComponent extends Component<ProjectPreviewImageArgs> {
  styleNamespace = getStyleNamespace("app/project-thumbnail");

  get previewImageUrl(): string {
    return this.args.project.posterUrl ?? FALLBACK_THUMBNAIL_URI;
  }
}
