import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { FlexMasonry } from "client/lib/masonry";

export default class MasonryLayoutComponent extends Component {
  styleNamespace = getStyleNamespace("masonry-layout");

  @action
  didInsert(element: HTMLElement): void {
    new FlexMasonry(element, {
      minColumnWidth: 240,
      shouldInferAspectRatio: true
    });
  }
}
