import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";

export default class EmptyTeamLibraryComponent extends Component {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("folder-content/empty-team-library");

  get canManageTeam(): boolean {
    return !!this.auth.currentUser?.canManageTeam;
  }

  get hasMultipleMembers(): boolean {
    return !!this.auth.currentTeam?.hasMultipleMembers;
  }
}
