import Service from "@ember/service";
import type { SafeString } from "@ember/template/-private/handlebars";
import { tracked } from "@glimmer/tracking";
import type { NotificationArgs } from "client/components/north-star/notification/component";
import { NotificationTypes } from "client/components/north-star/notification/component";

export interface NotificationOptions {
  button?: NotificationButton;
  position?: NotificationPosition;
  timeout?: number;
}

export interface NotificationButton {
  label: string;
  onClick(): unknown;
}

export enum NotificationPosition {
  TopLeft = "top-left",
  Top = "top",
  TopRight = "top-right",
  BottomRight = "bottom-right",
  Bottom = "bottom",
  BottomLeft = "bottom-left"
}

export interface Notification extends NotificationArgs {
  button?: NotificationButton;
  message: string | SafeString;
  position?: NotificationPosition;
  timeout?: number;
}

const NOTIFICATION_DURATION = 3000;
const NOTIFICATION_WITH_BUTTON_DURATION = 5000;

export default class NotificationsService extends Service {
  @tracked
  notification?: Notification;

  public success(message: string, options: NotificationOptions = {}): void {
    this.show({
      message,
      type: NotificationTypes.info,
      ...options
    });
  }

  public error(message: string, options: NotificationOptions = {}): void {
    this.show({
      message,
      type: NotificationTypes.error,
      ...options
    });
  }

  public warning(message: string | SafeString, options: NotificationOptions = {}): void {
    this.show({
      message,
      type: NotificationTypes.warning,
      ...options
    });
  }

  public hide(): void {
    this.notification = undefined;
  }

  private show({
    button,
    message,
    position,
    timeout,
    type
  }: Pick<Notification, "button" | "message" | "position" | "timeout" | "type">): void {
    const notification = {
      button,
      message,
      position: position ?? NotificationPosition.TopRight,
      type
    };

    this.notification = notification;

    timeout ??= timeout ?? button ? NOTIFICATION_WITH_BUTTON_DURATION : NOTIFICATION_DURATION;

    if (timeout !== 0) {
      setTimeout(() => {
        if (this.updateable && Object.is(this.notification, notification)) {
          this.hide();
        }
      }, timeout ?? NOTIFICATION_DURATION);
    }
  }

  private get updateable(): boolean {
    return !this.isDestroyed && !this.isDestroying;
  }
}
