import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Blob } from "@rails/activestorage";
import type { ThumbnailOptions } from "client/authenticated/publish/controller";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import type ActiveStorageService from "client/services/active-storage";
import { ASSET_LIBRARY_THUMBNAIL_MIMETYPES } from "client/services/asset-library";
import type ExportService from "client/services/export";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type VideoPlayerService from "client/services/video-player";

interface ExportActionsThumbnailArgs {
  aspectRatio: AspectRatio;
  posterUrl: string;
  disabled: boolean;
  renderProject: (ctaContext: string, thumbnailOptions?: ThumbnailOptions) => Promise<void>;
}

const THUMBNAIL_UPDATE_SUCCESS = "Thumbnail successfully updated";

export default class ExportActionsThumbnailComponent extends Component<ExportActionsThumbnailArgs> {
  @tracked
  open = false;

  @tracked
  thumbnailHeight = 0;

  @tracked
  thumbnailWidth = 0;

  @tracked
  menuTargetElement!: HTMLElement;

  @service
  declare activeStorage: ActiveStorageService;

  @service
  declare export: ExportService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare videoPlayer: VideoPlayerService;

  styleNamespace = getStyleNamespace("export-actions/thumbnail");

  get ctaContext(): string {
    return CTAContext.CTA_CONTEXT_LINK_THUMBNAIL_UPGRADE;
  }

  @action
  setThumbnailDimensions(element: HTMLElement): void {
    const width = element.clientWidth;
    if (this.args.aspectRatio.ratioX > this.args.aspectRatio.ratioY) {
      this.thumbnailHeight = width;
      this.thumbnailWidth = width * this.args.aspectRatio.ratioY;
    } else {
      this.thumbnailHeight = width * this.args.aspectRatio.ratioX;
      this.thumbnailWidth = width;
    }
  }

  @action
  toggleThumbnailMenu(): void {
    this.open = !this.open;
  }

  @action
  chooseThumbnail(): void {
    this.closeThumbnailMenu();
    this.export.showChooseThumbnail();
    this.videoPlayer.reset();
  }

  @action
  async uploadThumbnail(): Promise<void> {
    const [thumbnail] = await this.activeStorage.showFilePicker({
      accept: ASSET_LIBRARY_THUMBNAIL_MIMETYPES.join(","),
      multiple: false
    });

    if (thumbnail) {
      const blob: Blob & { key?: string } = await this.activeStorage.uploadFile(thumbnail);
      await this.args.renderProject(CTAContext.CTA_CONTEXT_AFTER_THUMBNAIL_UPLOAD, { path: blob.key });
    }
    this.notifications.success(THUMBNAIL_UPDATE_SUCCESS);
    this.export.hideChooseThumbnail();
  }

  @action
  downloadThumbnail(): void {
    try {
      if (this.args.posterUrl) {
        window.open(this.args.posterUrl);
      }
    } catch (error) {
      this.honeybadger.notify(error as Error, "PublishThumbnail/Download");
    }
  }

  @action
  closeThumbnailMenu(): void {
    this.open = false;
  }

  @action
  setMenuThumbnailTarget(element: HTMLElement): void {
    this.menuTargetElement = element;
  }

  get thumbnailStyleDimensions(): SafeString {
    return htmlSafe(`height:${this.thumbnailHeight}px;width:${this.thumbnailWidth}px;`);
  }
}
