import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import { isEmpty } from "@ember/utils";
import Component from "@glimmer/component";
import { isColorDark } from "client/lib/color";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ColorSwatchArgs {
  color?: string;
  opacity?: number;
  size?: "xx-small" | "x-small" | "small" | "medium" | "large";
  disabled?: boolean;
  selected?: boolean;

  onClick?: (ev: MouseEvent) => unknown;
}

export default class ColorSwatchComponent extends Component<ColorSwatchArgs> {
  styleNamespace = getStyleNamespace("north-star/color-swatch");

  @action
  handleClick(ev: MouseEvent): void {
    if (!this.args.disabled) {
      this.args.onClick?.(ev);
    }
  }

  get isDark(): boolean {
    if (this.args.color) {
      try {
        return isColorDark(this.args.color);
      } catch {} // eslint-disable-line no-empty
    }

    return false;
  }

  get clickable(): boolean {
    return !!this.args.onClick && !this.args.disabled;
  }

  get hasColor(): boolean {
    return !isEmpty(this.args.color);
  }

  get opacity(): number {
    if (typeof this.args.opacity === "number") {
      return Math.max(0, Math.min(1, this.args.opacity));
    } else {
      return 1;
    }
  }

  get colorStyles(): SafeString | undefined {
    if (this.hasColor) {
      return htmlSafe(`background-color: ${this.args.color}; opacity: ${this.opacity}`);
    } else {
      return undefined;
    }
  }
}
