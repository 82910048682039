import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface FontSearchInputArgs {
  search(query: string): unknown;
  query: string;
  size?: "large";
}

export default class FontSearchInputComponent extends Component<FontSearchInputArgs> {
  @tracked
  query = this.args.query ?? "";

  @tracked
  focused = false;

  @tracked
  dirty = false;

  @tracked
  changed = false;

  styleNamespace = getStyleNamespace("font-family-dropdown/font-search-input");

  get showClearButton(): boolean {
    return this.query.length > 0;
  }

  @action
  onFocus(): void {
    this.focused = true;
  }

  @action
  onBlur(): void {
    this.focused = false;

    if (this.changed) {
      this.dirty = true;
    }
  }

  @action
  onInput(ev: Event): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.changed = true;
    this.args.search(this.query);
  }

  @action
  onClearMousedown(): void {
    this.query = "";
    this.dirty = true;
    this.args.search(this.query);
  }

  @action
  queryDidUpdate(_: HTMLElement, [query]: [string]): void {
    this.query = query;
    this.dirty = false;
  }
}
