import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type BrandNewController from "./controller";
import type BrandStyle from "client/models/brand-style";

export default class BrandNewRoute extends Route<BrandStyle> {
  @service
  declare store: Store;

  async model(): Promise<BrandStyle> {
    return this.store.createRecord("brandStyle");
  }

  resetController(controller: BrandNewController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.resetReturnPath();
    }
  }
}
