import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { LatestProjectPreviewsArray } from "client/services/latest-project-previews";
import type LatestProjectPreviewsService from "client/services/latest-project-previews";

export interface HomeIndexModel {
  projects: LatestProjectPreviewsArray;
}

export default class HomeIndexRoute extends Route<HomeIndexModel> {
  @service
  declare latestProjectPreviews: LatestProjectPreviewsService;

  async model(): Promise<HomeIndexModel> {
    const projects = await this.latestProjectPreviews.projects();

    return {
      projects
    };
  }
}
