import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PopoverService from "client/services/popover";

interface RecordFolderThumbnailArgs {
  image?: string;
  title?: string;
  count?: number;
  label?: string;
  folderLabel?: string;
  recordProject?: RecordProject;
  onRename?: (name: string) => void;
  onDestroy?: () => void;
}

export default class RecordFolderThumbnailComponent extends Component<RecordFolderThumbnailArgs> {
  @service
  declare popover: PopoverService;

  clipPathId = `${guidFor(this)}__folderClipPath`;

  @tracked
  isOptionsMenuOpen = false;

  @tracked
  optionsMenuAnchor?: HTMLElement;

  @tracked
  isRenaming?: boolean;

  @tracked
  _title?: string;

  _backupTitle?: string;

  styleNamespace = getStyleNamespace("app/record-folder-thumbnail");

  constructor(owner: object, args: RecordFolderThumbnailArgs) {
    super(owner, args);
    this._title = this.args.title || "";
    this._backupTitle = this._title;
  }

  @action
  toggleOptionsMenu(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.isOptionsMenuOpen) {
      this.popover.trigger("close");
    } else {
      this.isOptionsMenuOpen = true;
    }
  }

  @action
  setOptionsMenuAnchor(element?: HTMLElement): void {
    if (element) {
      this.optionsMenuAnchor = element;
    }
  }

  @action
  showRenameInput(event: MouseEvent): void {
    this.stopPropagation(event);
    this.isRenaming = true;
    this.isOptionsMenuOpen = false;
  }

  @action
  onRename(event: MouseEvent): void {
    if (this.isRenaming) {
      this.popover.trigger("close");
      this.stopPropagation(event);

      const trimmedName = this._title?.trim() || "";
      // Is a valid title
      if (trimmedName.length <= 100 && trimmedName.length !== 0) {
        this.args.onRename?.(trimmedName);
        this._backupTitle = trimmedName;
      } else {
        // Not a valid title
        this._title = this._backupTitle;
      }

      this.isRenaming = false;
    }
  }

  @action
  destroyFolder(event: MouseEvent): void {
    this.stopPropagation(event);
    this.args.onDestroy?.();
  }

  @action
  stopPropagation(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @action
  closeOptionsMenu(): void {
    this.isOptionsMenuOpen = false;
  }

  get folderNameReadOnly(): string {
    return this.args.title || "";
  }

  get showOptionsButton(): boolean {
    return !!this.args.onDestroy || !!this.args.onRename;
  }

  get thumbnailStyle(): SafeString {
    return htmlSafe(`background-image: url('${this.args.image ?? ""}'); clip-path: url(#${this.clipPathId});`);
  }

  get showLabelOrCount(): boolean {
    return this.labelOrCount !== undefined;
  }

  get labelOrCount(): number | string | undefined {
    return this.args.label ?? (this.args.count || undefined);
  }
}
