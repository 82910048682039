import { service } from "@ember/service";
import Model, { attr } from "@ember-data/model";
import type Store from "@ember-data/store";
import { Promise as RSVPPromise } from "rsvp";
import BrandStyleConfig from "client/lib/brand/brand-style-config";
import type BrandStyleService from "client/services/brand-style";

export default class BrandStyle extends Model {
  @service
  declare store: Store;

  @service
  private declare brandStyle: BrandStyleService;

  @attr("brandStyleConfig", { defaultValue: () => new BrandStyleConfig() })
  declare style: BrandStyleConfig;

  public async setAsDefault(): Promise<void> {
    this.brandStyle.setDefault(this);
  }

  public async duplicate(): Promise<BrandStyle> {
    const duplicate = this.store.createRecord("brandStyle");

    duplicate.style = this.style.clone();

    return duplicate.save();
  }

  public get isDefault(): boolean {
    return !!this.id && this.brandStyle.defaultBrandId === this.id;
  }

  // This extends `Model.save` to set the brand to the default if it's new.
  save(options?: { adapterOptions?: object | undefined }): RSVPPromise<this> {
    return new RSVPPromise<this>(async (resolve) => {
      const isNew = this.isNew as unknown as boolean;

      await super.save(options);

      if (isNew) {
        await this.setAsDefault();
      }

      resolve(this);
    });
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    brandStyle: BrandStyle;
  }
}
