import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import type { UIDropdownArgs } from "client/components/ui/ui-dropdown/component";
import UIDropdownComponent from "client/components/ui/ui-dropdown/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getWeightAndStyle } from "client/lib/text/fonts";

interface FontWeightsDropdownArgs extends UIDropdownArgs {
  fontFamily: string;
}

export default class FontWeightsDropdownComponent extends UIDropdownComponent<FontWeightsDropdownArgs> {
  styleNamespace = getStyleNamespace("font-weights-dropdown");

  // Local helper and has to be an arrow function for the this context to work
  weightAndStyle = (fontVariant: string): SafeString => {
    const [fontWeight, fontStyle] = getWeightAndStyle(fontVariant);
    return htmlSafe(`font-weight: ${fontWeight}; font-style: ${fontStyle}`);
  };

  get fontFamilyStyle(): SafeString {
    return htmlSafe(`font-family: ${this.args.fontFamily}`);
  }
}
