import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type ConfirmService from "client/services/confirm";
import type NotificationsService from "client/services/notifications";

interface AccountDetailsArgs {
  user: User;
}

export default class AccountDetailsComponent extends Component<AccountDetailsArgs> {
  @service
  declare notifications: NotificationsService;

  @service
  declare confirm: ConfirmService;

  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @tracked
  fullName = "";

  @tracked
  email = "";

  @tracked
  loading = false;

  styleNamespace = getStyleNamespace("account/profile/account-details");

  @action
  didUpdateUser(_element: HTMLElement, [{ fullName, email }]: [User]): void {
    Object.assign(this, { fullName, email });
  }

  @action
  async onSubmit(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.loading) {
      return;
    }

    const confirmed = await this.confirm.open({
      title: "Confirm changes",
      message: `Please confirm you would like to update your account details to:\n\nName: ${this.fullName}\nEmail: ${this.email}`
    });

    if (!confirmed) {
      return;
    }

    this.loading = true;

    const { email, fullName } = this;
    const { user } = this.args;

    const emailChanged = this.email !== user.email;

    try {
      Object.assign(user, { fullName, email });
      await user.save();
      this.notifications.success("Your account details have been updated");

      if (emailChanged) {
        void this.auth.logout();
      }
    } catch (err) {
      // @ts-expect-error
      const errorMessage = (err?.errors?.[0]?.detail as string) || "There was an error updating your account details";
      this.notifications.error(errorMessage);

      // If the save failed then undo changes to the user in the store
      user.rollbackAttributes();
    } finally {
      this.loading = false;
    }
  }

  get disabled(): boolean {
    return !this.changed;
  }

  get changed(): boolean {
    return this.fullName !== this.args.user.fullName || this.email !== this.args.user.email;
  }

  get canChangePassword(): boolean {
    return this.auth.currentUser?.canChangePassword ?? false;
  }
}
