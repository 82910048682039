import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { AnimationFit } from "renderer-engine";
import type { Asset, EventRegister, Media } from "client/lib/editor-domain-model";
import { ChangeObjectFitMutation, UpdateFrameMutation, VideoClip } from "client/lib/editor-domain-model";

interface Args {
  media: Media;
  eventRegister: EventRegister;
  routeName?: string;
  save: () => void;
}

export default class ToolbarAdjustFrameComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @action
  adjustFrame(): void {
    void this.router.transitionTo(`${this.args.routeName}.frame`);
  }

  @action
  async removeFrame(): Promise<void> {
    const { media, eventRegister } = this.args;

    if (!(media.asset instanceof Image || media.asset instanceof VideoClip)) {
      return;
    }

    eventRegister.fire(new UpdateFrameMutation(media.asset, undefined));
    eventRegister.fire(new ChangeObjectFitMutation(media.asset, AnimationFit.FILL));

    this.args.save();
  }

  get hasFrame(): boolean {
    return (this.asset instanceof Image || this.asset instanceof VideoClip) && !!this.asset.frame;
  }

  get asset(): Asset | undefined {
    return this.args.media.asset;
  }
}
