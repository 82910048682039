import Controller from "@ember/controller";
import type { AccountTeamModel } from "./route";

export default class AccountTeamController extends Controller<AccountTeamModel> {
  get isTeamOwner(): boolean {
    return this.model.user.teamOwner;
  }

  get showMemberList(): boolean {
    return this.model.user.canManageTeam || this.model.user.onTeam;
  }
}
