import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ReviewTimelineComponentArgs {
  currentTime: number;
  duration: number;
  isLoading: boolean;
  setCurrentTime: (event: Event) => void;
}

export default class TimeLineComponent extends Component<ReviewTimelineComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/video-player/time-line");
}
