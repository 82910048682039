import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Media } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NewVideoActionsService from "client/services/new-video-actions";
import type ProjectContentBarService from "client/services/project-content-bar";

interface Args {
  media: Media;
  onClose: () => void;
  saveMediaReplaceable?: (value: boolean) => Promise<void>;
}

export default class ToolbarReplaceComponent extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare newVideoActions: NewVideoActionsService;

  @tracked
  targetElement?: HTMLElement;

  @tracked
  mediaReplaceable = this.args.media.mediaReplaceable;

  styleNamespace = getStyleNamespace("toolbar/replace");

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  @action
  setupMediaReplaceable(): void {
    this.mediaReplaceable = this.args.media.mediaReplaceable;
  }

  @action
  async replace(event: Event): Promise<void> {
    event?.stopPropagation();
    this.args.onClose();
    await this.projectContentBar.startAddOrReplaceMedia(this.args.media);
  }

  @action
  async recordVideo(event: Event): Promise<void> {
    event?.stopPropagation();
    this.args.onClose();
    await this.newVideoActions.createSelfRecordProject();
  }

  @action
  async requestRecording(event: Event): Promise<void> {
    event?.stopPropagation();
    this.args.onClose();
    await this.newVideoActions.createCollaborativeRecordProject();
  }

  @action
  async toggleMediaReplaceable(): Promise<void> {
    this.mediaReplaceable = !this.mediaReplaceable;
    void (await this.args.saveMediaReplaceable?.(this.mediaReplaceable));
  }
}
