import type { Project } from "@biteable/network-model";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { RecordProjectTypes } from "client/models/record-project";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type UpgradeService from "client/services/upgrade";

export default class AuthenticatedController extends Controller {
  queryParams = ["signUpAuth", "create", "createRecord", "recordProjectType", "contentBarPanel"];

  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  @service
  declare upgrade: UpgradeService;

  @tracked
  signUpAuth?: boolean;

  @tracked
  create?: true;

  @tracked
  createRecord?: true;

  @tracked
  recordProjectType?: RecordProjectTypes.SELF;

  @tracked
  contentBarPanel?: string;

  get showCreateProjectModal(): boolean {
    return this.create ?? false;
  }

  get showCreateRecordProjectModal(): boolean {
    return this.createRecord ?? false;
  }

  get upgrading(): boolean {
    return this.upgrade.upgrading;
  }

  @action
  cancelModal(): void {
    this.create = undefined;
    this.createRecord = undefined;
    this.recordProjectType = undefined;
    this.contentBarPanel = undefined;
  }

  @action
  async createProject(project: Project): Promise<void> {
    try {
      await project.save();
      const contentBarPanel = this.contentBarPanel;
      this.cancelModal();
      void this.router.transitionTo("authenticated.project", project.id, {
        queryParams: { create: undefined, contentBarPanel: contentBarPanel }
      });
    } catch (err) {
      this.notifications.error("There was a problem creating your new project");
    }
  }
}
