import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum ButtonVariants {
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
  TEAMS = "teams"
}

export enum ButtonSizes {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large"
}

interface ButtonComponentArgs {
  onClick?: (event: MouseEvent) => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  type?: HTMLButtonElement["type"];
  tag?: keyof HTMLElementTagNameMap;
}

export default class ButtonComponent extends Component<ButtonComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/button");

  @action
  handleClick(ev: MouseEvent): void {
    this.args.onClick?.(ev);
  }

  get tagName(): keyof HTMLElementTagNameMap {
    return this.args.tag ?? "button";
  }

  get isDisabled(): boolean {
    return !!this.args.loading || !!this.args.disabled;
  }

  get variant(): ButtonVariants {
    return this.args.variant ?? ButtonVariants.DEFAULT;
  }

  get size(): ButtonSizes {
    return this.args.size ?? ButtonSizes.DEFAULT;
  }

  get type(): string | undefined {
    if (this.tagName === "button") {
      return this.args.type ?? "button";
    } else {
      return undefined;
    }
  }
}
