import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { TextStyle, Timeline, EventRegister } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import {
  DEFAULT_FONT_WEIGHTS,
  getWeightAndStyleAsVariant,
  numberToDescription,
  stringifyVariant
} from "client/lib/text/fonts";
import type { EditableText } from "client/lib/text/text-editor";
import type FontsService from "client/services/fonts";

interface Args {
  timeline: Timeline;
  eventRegister: EventRegister;
  editableText?: EditableText;
  onClose: () => void;
}

export default class ToolbarFontFamilyComponent extends Component<Args> {
  @service
  declare fonts: FontsService;

  @tracked
  fontVariants: string[] = [];

  @tracked
  menuToggleElement?: HTMLElement;

  styleNamespace = getStyleNamespace("toolbar/font/family-menu");

  @action
  setMenuToggle(element: HTMLElement): void {
    this.menuToggleElement = element;
  }

  @action
  onClose(): void {
    this.fonts.clearSearchResults();
    this.args.onClose?.();
  }

  @action
  updateFont(font: string): void {
    this.args.editableText?.applyStyle({
      fontFamily: font,
      fontVariant: getWeightAndStyleAsVariant(this.availableFontVariant)
    });
  }

  get availableFontVariant(): string {
    return !this.fontVariant || !this.isFontWeightAvailable() ? this.getDefaultFontWeight() : this.fontVariant;
  }

  private isFontWeightAvailable(): boolean {
    return !!this.fontVariant && this.fontVariants.includes(this.fontVariant);
  }

  private getDefaultFontWeight(): string {
    for (const weight of DEFAULT_FONT_WEIGHTS) {
      const weightDescription = numberToDescription(weight);

      if (this.fontVariants.includes(weightDescription)) {
        return weightDescription;
      }
    }

    return numberToDescription(DEFAULT_FONT_WEIGHTS[0]!);
  }

  get fontVariant(): string | undefined {
    return this.textStyle?.fontVariant && numberToDescription(stringifyVariant(this.textStyle.fontVariant));
  }

  get fontFamilyStyle(): SafeString {
    return htmlSafe(`font-family: ${this.args.editableText?.textStyle.fontFamily}`);
  }

  get textStyle(): TextStyle | undefined {
    return this.args.editableText?.textStyle;
  }

  get selectedFonts(): string[] {
    return this.textStyle?.fontFamily ? [this.textStyle.fontFamily] : [];
  }

  get errorText(): string | undefined {
    const failedFonts = this.selectedFonts.filter((s) => this.fonts.isFontMissing(s));
    return failedFonts.length ? `Failed to load ${failedFonts.join(", ")}` : undefined;
  }
}
