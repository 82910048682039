import type { ProjectSceneBackgroundRouteModel } from "client/authenticated/project/scene/background/route";
import ProjectSceneBackgroundRoute from "client/authenticated/project/scene/background/route";
import type ProjectSceneRoute from "client/authenticated/project/scene/route";
import type ZymbolGroup from "client/models/zymbol-group";

export default class ProjectSceneCaptionBackgroundRoute extends ProjectSceneBackgroundRoute {
  async model(): Promise<ProjectSceneBackgroundRouteModel> {
    const model = this.modelFor<ProjectSceneRoute>("authenticated.project.scene");
    const { projectScene, scene } = model;
    const { backgroundZymbol } = projectScene;
    const project = await projectScene.project;
    const { background } = scene;
    const params = this.paramsFor("authenticated.project.scene.caption");
    // @ts-expect-error
    const { zymbolGroupId } = params;
    const caption = scene.captions.find(({ id }: ZymbolGroup) => id === zymbolGroupId);

    return { ...model, project, backgroundZymbol, background, caption };
  }
}
