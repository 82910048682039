import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type { LibraryContentModel } from "./library-content";
import LibraryContentRoute from "./library-content";
import type ScenesLibraryController from "client/authenticated/project/scenes/library/controller";
import TrackingEvents from "client/events";
import { ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type SelectableAsset from "client/models/selectable-asset";
import type { PaginatedRouteParams } from "client/routes/paginated";

export type LibrarySearchRouteParams = PaginatedRouteParams & { query: string };

abstract class LibrarySearchRoute<
  T extends LibraryContentModel<SelectableAsset> = LibraryContentModel<SelectableAsset>
> extends LibraryContentRoute<T> {
  @service
  declare router: RouterService;

  // The route name that the search `query` property exists on
  abstract searchParentRouteName: string;

  queryRequired = true;

  abstract type: string;
  abstract provider: string;

  beforeModel(transition: Transition): void {
    if (this.queryRequired && !transition?.to?.queryParams?.["query"]) {
      void this.router.replaceWith(this.parentRouteName);
    }

    super.beforeModel(transition);
  }

  afterModel(model: T): void {
    const searchParentController = this.controllerFor<ScenesLibraryController>(this.searchParentRouteName);

    const { type, provider } = this;
    const { query } = this.paramsFor(this.routeName) as LibrarySearchRouteParams;

    searchParentController.set("query", query);

    this.trackSearch({ totalCount: model.totalCount, type, provider, query });
  }

  trackSearch({
    totalCount,
    type,
    provider,
    query
  }: {
    totalCount: number | undefined;
    type?: string;
    provider?: string;
    query: string;
  }): void {
    const trimmedQuery = query.trim();
    const hasQuery = !!trimmedQuery.length;
    const previousQuery = this.routeController.query?.trim();
    const queryDidChange = trimmedQuery !== previousQuery;

    if (hasQuery && queryDidChange) {
      const trackingHelper = new ContentTrackingHelper(
        {
          search: trimmedQuery,
          searchFrom: SEARCH_LOCATION.ADD_SCENE
        },
        `${provider}-${type}`,
        totalCount
      );

      void this.tracking.sendAnalytics(TrackingEvents.EVENT_SEARCH_CONDUCTED, trackingHelper);
    }
  }

  queryParams = {
    query: {
      refreshModel: true
    }
  };
}

export default LibrarySearchRoute;
