import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectPreview from "client/models/project-preview";
import type TeamProjectActivityService from "client/services/team-project-activity";

export default class TeamActivityCountComponent extends Component {
  @service
  private declare store: Store;

  @service
  private declare teamProjectActivity: TeamProjectActivityService;

  @tracked
  count = 0;

  @tracked
  private queriedDate = new Date(0);

  styleNamespace = getStyleNamespace("discovery/dashboard/team-activity-count");

  get displayCount(): string {
    return this.count >= 10 ? "10+" : this.count.toString();
  }

  get hidden(): boolean {
    return this.count === 0 || this.lastViewedAt.getTime() > this.queriedDate.getTime();
  }

  private get lastViewedAt(): Date {
    return this.teamProjectActivity.lastViewedAt;
  }

  @action
  async didInsert(): Promise<void> {
    this.count = await this.getCount();
  }

  private async getCount(): Promise<number> {
    // Store the queried date so that we know when the count becomes stale
    this.queriedDate = this.lastViewedAt;

    // @ts-expect-error
    const results = (await this.store.query("projectPreview", {
      /* eslint-disable camelcase */
      per_page: 1,
      team_shared_after: this.lastViewedAt.toISOString()
      /* eslint-enable camelcase */
    })) as ArrayWithMeta<ProjectPreview>;

    return results.meta?.["total-count"] || 0;
  }
}
