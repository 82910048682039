import { action } from "@ember/object";
import { service } from "@ember/service";
import DynamicIslandActionComponent from "client/components/discovery/dashboard/dynamic-island/action/component";
import type StorageService from "client/services/storage";

export default class DashboardSecondaryActionsWhatsNew extends DynamicIslandActionComponent {
  actionName = "what's new";

  @service
  declare storage: StorageService;

  @action
  seeUpdates(): void {
    this.onInteraction();

    this.storage.setSessionItem("whats_new_showed_at", Date.now().toString());
    this.args.reload?.();
  }
}
