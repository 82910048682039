import type ApplicationInstance from "@ember/application/instance";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, Scene, Text } from "client/lib/editor-domain-model";
import { CharacterRange, TextContentMutation, TextStylesMutation } from "client/lib/editor-domain-model";
import type VideoAssistantPromptField from "client/lib/scripting/video-assistant-prompt-field";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type StorageService from "client/services/storage";

export default class VideoAssistantService extends Service {
  @tracked
  declare currentGenerator?: VideoAssistantGenerator;

  @tracked
  private _useCase?: string;

  @tracked
  private _job?: string;

  @tracked
  private _fields?: string;

  @tracked
  private _style?: string;

  @tracked
  private _aspectRatioId?: string;

  @service
  declare storage: StorageService;

  @service
  declare store: Store;

  constructor(owner: ApplicationInstance) {
    super(owner);

    this._useCase = this.videoAssistant["useCase"] || "";
    this._job = this.videoAssistant["job"] || "";
    this._fields = this.videoAssistant["fields"] || "";
    this._style = this.videoAssistant["style"] || "";
    this._aspectRatioId = this.videoAssistant["aspectRatioId"] || "";
  }

  public resetVideoAssistant(): void {
    this.storage.removeSessionItem("videoAssistant");
  }

  public set useCase(useCase: string) {
    this._useCase = useCase;
    this._job = "";
    this._fields = "";
    this._style = "";
    this._aspectRatioId = "";

    this.updateVideoAssistant();
  }

  public get useCase(): string | undefined {
    return this._useCase;
  }

  public set job(job: string | undefined) {
    this._job = job;
    this._fields = "";
    this._style = "";
    this._aspectRatioId = "";

    this.updateVideoAssistant();
  }

  public get job(): string | undefined {
    return this._job;
  }

  public set fields(fields: VideoAssistantPromptField[]) {
    const items = fields.reduce((map, f) => {
      if (f.input?.key) {
        map[f.input.key] = f.value;
      }
      return map;
    }, {} as { [key: string]: string | undefined });

    this._fields = JSON.stringify(items);

    this.updateVideoAssistant();
  }

  public get fields(): { [key: string]: string } {
    return this._fields ? JSON.parse(this._fields) : {};
  }

  public set aspectRatioId(aspectRatioId: string | undefined) {
    this._aspectRatioId = aspectRatioId;
    this._style = "";

    this.updateVideoAssistant();
  }

  public get aspectRatioId(): string | undefined {
    return this._aspectRatioId;
  }

  public set style(style: string | undefined) {
    this._style = style;

    this.updateVideoAssistant();
  }

  public get style(): string | undefined {
    return this._style;
  }

  public parseVideoScriptToArray(script: string): string[] {
    let scriptsByScene = [];
    scriptsByScene = this.scriptCleaner(script)
      .replace(/\n/g, "")
      .split(/(?:-\s*)?(?:\.\s*)?Scene[-\s]?\d+:/i)
      .map((scene) => scene.trim());
    scriptsByScene = scriptsByScene.filter((str) => str !== "");

    return scriptsByScene;
  }

  private scriptCleaner(script: string): string {
    const regex = /Scene 1:(.*)/s;
    const match = regex.exec(script);
    return match ? match[0]!.trim() : "";
  }

  public scriptIsValid(videoScript: string[]): boolean {
    return videoScript.length > 0;
  }

  public applyVideoScriptToScene(eventRegister: EventRegister, scene: Scene, script: string | undefined): void {
    if (!script) {
      return;
    }

    for (const caption of scene.captions) {
      for (const text of caption.texts) {
        this.applyVideoScriptToText(eventRegister, text, script);
      }
    }
  }

  private applyVideoScriptToText(eventRegister: EventRegister, text: Text, script: string): void {
    const portStylesWithNewRange = text.styles.map((style) => {
      if (style.range?.startOffset === 0) {
        style._range = new CharacterRange(0, script.length);
      }
      return style;
    });

    if (eventRegister) {
      eventRegister.fire(new TextStylesMutation(text, portStylesWithNewRange));
      eventRegister.fire(new TextContentMutation(text, script));
    }
  }

  private updateVideoAssistant(): void {
    this.storage.setSessionItem(
      "videoAssistant",
      JSON.stringify({
        useCase: this._useCase,
        job: this._job,
        fields: this._fields,
        style: this._style,
        aspectRatioId: this._aspectRatioId
      })
    );
  }

  private get videoAssistant(): {
    useCase?: string;
    job?: string;
    fields?: string;
    style?: string;
    aspectRatioId?: string;
  } {
    const videoAssistant = this.storage.getSessionItem("videoAssistant");

    return videoAssistant
      ? JSON.parse(videoAssistant)
      : { useCase: "", job: "", fields: "", style: "", aspectRatioId: "" };
  }
}

declare module "@ember/service" {
  interface Registry {
    videoAssistant: VideoAssistantService;
  }
}
