import Model, { attr, belongsTo } from "@ember-data/model";
import type { ContributorTypes } from "./folder";
import type Folder from "./folder";
import type FolderContainable from "./folder-containable";

export default class FolderContent extends Model {
  @attr("string")
  declare containableId: string;

  @attr("string")
  declare containableType: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("folder", { async: true, inverse: null })
  declare folder: Folder;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("folder-containable", { polymorphic: true, async: true, inverse: null })
  declare containable: FolderContainable;

  @attr("string")
  contributorType?: ContributorTypes;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    folderContent: FolderContent;
  }
}
