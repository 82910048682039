import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import ProjectPreview from "client/models/project-preview";
import type TeamAsset from "client/models/team-asset";
import UserAsset from "client/models/user-asset";
import type AssetLibraryService from "client/services/asset-library";
import type DragAndDropService from "client/services/drag-and-drop";
import type GalleryService from "client/services/gallery";

interface FolderContentItemArgs {
  content: FolderContent;
  parentRouteName?: string;
  refresh: () => void;
}

export default class FolderContentItemComponent extends Component<FolderContentItemArgs> {
  private clonedItem?: HTMLElement;

  @tracked
  dragging = false;

  @service
  declare gallery: GalleryService;

  @service
  declare dragAndDrop: DragAndDropService;

  @service
  declare assetLibrary: AssetLibraryService;

  styleNamespace = getStyleNamespace("folder-content/item");

  get isFolder(): boolean {
    return this.args.content instanceof Folder;
  }

  get isProject(): boolean {
    return this.args.content instanceof ProjectPreview;
  }

  @action
  async removeAsset(asset: UserAsset | TeamAsset): Promise<void> {
    await this.assetLibrary.hideUserAsset(asset);
    this.args.refresh();
  }

  @action
  onDrag(event: DragEvent): void {
    this.dragAndDrop.onDragScroll(event, "[class$=_Content]");
  }

  @action
  onDragStart(_: object, event: DragEvent): void {
    this.dragging = true;
    this.gallery.dragging = true;
    const target = event.target as HTMLElement;

    this.clonedItem = target.cloneNode(true) as HTMLElement;
    const { width, height } = target.getBoundingClientRect();

    Object.assign(this.clonedItem.style, {
      width: `${width}px`,
      height: `${height}px`
    });
    this.clonedItem.classList.add(`${this.styleNamespace}__DragImage`);
    document.body.appendChild(this.clonedItem);
    event.dataTransfer?.setDragImage(this.clonedItem, 0, 0);
  }

  @action
  onDragEnd(): void {
    this.dragging = false;
    this.gallery.dragging = false;
    this.clonedItem?.remove();
    this.args.refresh?.();
  }

  get isUserAsset(): boolean {
    return this.args.content instanceof UserAsset;
  }
}
