import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PlansService from "client/services/plans";

interface TermsAndConditionsArgs {
  prefix?: string;
  currency?: string;
  type?: string;
  hideCurrency?: boolean;
}

export default class TermsAndConditionsComponent extends Component<TermsAndConditionsArgs> {
  styleNamespace = getStyleNamespace("purchase/terms-and-conditions");

  @service
  declare plans: PlansService;

  get type(): string {
    return this.args.type ?? "default";
  }

  get pricesCurrency(): string {
    return this.args.currency ?? this.plans.getCurrency();
  }
}
