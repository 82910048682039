import Component from "@glimmer/component";
import type { InvoiceDetails } from "client/authenticated/account/subscription/invoices/invoice/controller";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Charge from "client/models/charge";

interface InvoicePreviewArgs {
  invoice: InvoiceDetails;
  charge: Charge;
}

export default class InvoicePreviewComponent extends Component<InvoicePreviewArgs> {
  exampleOrganization = "Acme Co";
  exampleAddress = "123 Example Street\nSydney NSW 2000\nAustralia";
  styleNamespace = getStyleNamespace("account/subscription/invoice-preview");

  get billerAddress(): string {
    return "Biteable LLC\n300 Delaware Ave., Suite 210\nWilmington, DE 19801\nUSA";
  }

  get invoiceNumber(): string {
    return this.args.charge.invoiceNumber ?? this.args.charge.receiptNumber;
  }
}
