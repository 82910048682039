import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { PlanInterval } from "client/models/plan";
import type Plan from "client/models/plan";
import type ValidatedCoupon from "client/models/validated-coupon";
import type AuthService from "client/services/auth";

interface PlanInfoTileArgs {
  coupon?: ValidatedCoupon;
  externalInterval?: PlanInterval;
  plan: Plan;
}

export default class PlanInfoTileComponent extends Component<PlanInfoTileArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-info-tile");

  @service
  declare auth: AuthService;

  @service
  declare store: Store;

  get planSeats(): number {
    return this.args.plan.publisherSeats || 1;
  }
}
