import Component from "@glimmer/component";
import formatDuration from "client/lib/format-duration";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class ProjectTimelineDurationComponent extends Component<{ duration: number }> {
  styleNamespace = getStyleNamespace("project-timeline/duration");

  get formattedDuration(): string {
    return formatDuration(this.args.duration);
  }
}
