import Route from "@ember/routing/route";
import type { ProjectScenesFavoritesRouteModel } from "../../route";
import type ProjectScenesFavoritesRoute from "../../route";

export default class ProjectScenesFavoritesMyCurrentRoute extends Route<ProjectScenesFavoritesRouteModel> {
  model(): ProjectScenesFavoritesRouteModel {
    const model = this.modelFor<ProjectScenesFavoritesRoute>("authenticated.project.scenes.favorites");
    return model;
  }
}
