import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, StrictMutation } from "client/lib/editor-domain-model";
import type { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";

export class DummyEventRegister {
  currentTransaction?: ExplicitTransaction;

  get canUndo(): boolean {
    return false;
  }

  get canRedo(): boolean {
    return false;
  }

  async undo(): Promise<void> {
    return;
  }

  async redo(): Promise<void> {
    return;
  }

  async saveCallback(..._mutations: StrictMutation[]): Promise<void> {
    return;
  }

  async transaction<T>(f: () => Promise<T>): Promise<T> {
    return f();
  }

  async appendTransaction<T>(_transaction: ExplicitTransaction, f: () => Promise<T>): Promise<T> {
    return f();
  }

  fire<T>(mutation: StrictMutation): T {
    return mutation.run();
  }

  addCleanup(_mutation: StrictMutation): void {
    //
  }
}

export type ModelEventRegister = EventRegister | DummyEventRegister;

export default class UndoService extends Service {
  @tracked
  eventRegister: ModelEventRegister = new DummyEventRegister();
}
