import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { Crisp } from "crisp-sdk-web";
import type { CommunicationsModel } from "./route";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type UserPreferenceService from "client/services/user-preference";

export default class CommunicationsController extends Controller<CommunicationsModel> {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare userPreference: UserPreferenceService;

  @tracked
  loading = false;

  @tracked
  changed = false;

  get disabled(): boolean {
    return this.loading || !this.changed;
  }

  get firstViewEmails(): boolean {
    return this.model.user.firstViewEmails;
  }

  set firstViewEmails(enabled: boolean) {
    this.changed = true;
    this.model.user.firstViewEmails = enabled;
  }

  get viewsUpdateEmails(): boolean {
    return this.model.user.viewsUpdateEmails;
  }

  set viewsUpdateEmails(enabled: boolean) {
    this.changed = true;
    this.model.user.viewsUpdateEmails = enabled;
  }

  get receiveCommentNotifications(): boolean {
    return this.userPreference.preference?.receiveCommentNotifications ?? true;
  }

  set receiveCommentNotifications(enabled: boolean) {
    this.changed = true;

    void this.userPreference.setValue("receiveCommentNotifications", enabled);
  }

  @action
  contactSupport(): void {
    Crisp.chat.open();
  }

  @action
  async save(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      await this.model.user.save();
      this.notifications.success("Your communication preferences have been updated");
      this.changed = false;
    } catch (err) {
      this.notifications.error("There was a problem saving your preferences", {
        button: {
          label: "Contact support",
          onClick: this.contactSupport
        }
      });
      this.honeybadger.notify(err as Error);
    } finally {
      this.loading = false;
    }
  }
}
