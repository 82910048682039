import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type UpgradeService from "client/services/upgrade";

export default class AppNavAccountComponent extends Component {
  @service
  declare auth: AuthService;

  @service
  declare upgrade: UpgradeService;

  @tracked
  menuTarget!: HTMLElement;

  @tracked
  open = false;

  styleNamespace = getStyleNamespace("app-nav/account");

  get user(): User | undefined {
    return this.auth.currentUser;
  }

  get canUpgrade(): boolean {
    return this.upgrade.canUpgrade;
  }

  @action
  toggleMenu(): void {
    this.open = !this.open;
  }

  @action
  closeMenu(): void {
    this.open = false;
  }

  @action
  openUpgradeModal(): void {
    void this.upgrade.open();
  }

  @action
  setMenuTarget(element: HTMLElement): void {
    this.menuTarget = element;
  }
}
