import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { AnimationItem } from "lottie-web";
import lottie from "lottie-web";
import getStyleNamespace from "client/lib/get-style-namespace";

interface LottieIconArgs {
  iconSrc: string;
  hovering: boolean;
}

export default class LottieIconComponent extends Component<LottieIconArgs> {
  styleNamespace = getStyleNamespace("lottie-icon");

  lottieInstance: AnimationItem | undefined;

  @action
  didInsert(element: HTMLElement): void {
    this.lottieInstance = lottie.loadAnimation({
      container: element,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: this.args.iconSrc
    });
  }

  @action
  onHover(): void {
    if (this.args.hovering) {
      this.lottieInstance?.play();
    } else {
      this.lottieInstance?.stop();
    }
  }
}
