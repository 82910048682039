import { service } from "@ember/service";
import Component from "@glimmer/component";
import type UpgradeService from "client/services/upgrade";

export default class UpgradeMessageComponent extends Component {
  @service
  declare upgrade: UpgradeService;

  get upgradeMessage(): string | undefined {
    return this.upgrade.upgradeMessage;
  }
}
