import type ApplicationInstance from "@ember/application/instance";
import type PlansService from "client/services/plans";

const initialize = (app: ApplicationInstance): void => {
  try {
    const service = (app as any).__container__.lookup("service:plans") as PlansService;
    service.initialize();
  } catch {
    // eslint-disable-next-line no-empty
  }
};

export default {
  name: "plans",
  initialize
};
