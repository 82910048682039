import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import { tracked } from "@glimmer/tracking";
import DynamicIslandActionComponent from "../../dynamic-island/action/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import nextVideoAction, { VideoStateBasedAction } from "client/lib/video-state-based-action";
import type ProjectPreview from "client/models/project-preview";
import type { LatestProjectPreviewsArray } from "client/services/latest-project-previews";
import type LatestProjectPreviewsService from "client/services/latest-project-previews";

export default class StateBasedActionComponent extends DynamicIslandActionComponent {
  actionName = "state-based-action";

  @service
  declare latestProjectPreviews: LatestProjectPreviewsService;

  @service
  declare router: RouterService;

  @tracked
  latestProjects: LatestProjectPreviewsArray | undefined = undefined;

  styleNamespace = getStyleNamespace("discovery/dashboard/primary-actions/state-based-action");

  @action
  didInsert(): void {
    super.didInsert();
    void this.loadLatestProjects();
  }

  get nextAction(): VideoStateBasedAction | undefined {
    if (this.latestProject) {
      return nextVideoAction(this.latestProject);
    }

    return undefined;
  }

  get actionHeader(): string {
    if (this.nextIsKeepEditing) {
      return "Jump back in";
    } else if (this.nextIsPublishVideo) {
      return "Ready for 👀";
    } else {
      return "";
    }
  }

  get actionBody(): SafeString {
    if (this.nextIsKeepEditing) {
      return htmlSafe(
        `Last time you were here, you started working on <b>${
          this.latestProject!.title
        }</b>, pick up where you left off or start fresh.`
      );
    } else if (this.nextIsPublishVideo) {
      return htmlSafe(
        `Looks like you’ve made progress on <b>${
          this.latestProject!.title
        }</b>. Why not share with your team for feedback?`
      );
    }

    return htmlSafe("");
  }

  get primaryActionTitle(): string {
    if (this.nextIsKeepEditing) {
      return "Keep editing";
    } else if (this.nextIsPublishVideo) {
      return "Publish video";
    } else {
      return "";
    }
  }

  get latestProject(): ProjectPreview | undefined {
    return this.latestProjects?.[0];
  }

  @action
  async primaryAction(): Promise<void> {
    this.onInteraction();

    if (this.nextIsKeepEditing) {
      await this.router.transitionTo("authenticated.project", this.latestProject!.id);
    } else if (this.nextIsPublishVideo) {
      await this.router.transitionTo("authenticated.publish", this.latestProject!.id);
    }
  }

  get secondaryActionTitle(): string {
    if (this.nextIsKeepEditing) {
      return "Not now";
    } else if (this.nextIsPublishVideo) {
      return "Keep editing";
    } else {
      return "";
    }
  }

  @action
  async secondaryAction(): Promise<void> {
    this.onInteraction();

    if (this.nextIsKeepEditing) {
      this.onNotNow();
    } else if (this.nextIsPublishVideo) {
      await this.router.transitionTo("authenticated.project", this.latestProject!.id);
    }
  }

  @action
  onNotNow(): void {
    this.latestProjectPreviews.dismissStateBasedActionTile(this.latestProject!.id, this.nextAction!);
    this.args.onClose?.();
  }

  private get nextIsKeepEditing(): boolean {
    return this.nextAction === VideoStateBasedAction.KeepEditing;
  }

  private get nextIsPublishVideo(): boolean {
    return this.nextAction === VideoStateBasedAction.PublishVideo;
  }

  private async loadLatestProjects(): Promise<void> {
    this.latestProjects = await this.latestProjectPreviews.projects();
  }
}
