import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import sample from "lodash/sample";
import config from "client/config/environment";
import getStyleNamespace from "client/lib/get-style-namespace";

const HEADINGS = ["Nada.", "Zip.", "Zilch."];
const IMAGES = [
  `${config.rootURL}assets/images/discovery/templates/empty/ufo.svg`,
  `${config.rootURL}assets/images/discovery/templates/empty/doorway.svg`,
  `${config.rootURL}assets/images/discovery/templates/empty/drawers.svg`
];

export function emptyCardHeading(): string {
  return sample(HEADINGS) || "Zero.";
}

export function emptyCardImage(): string {
  return sample(IMAGES) || `${config.rootURL}assets/images/discovery/templates/empty/ufo.svg`;
}

export default class NorthStarCardEmpty extends Component {
  styleNamespace = getStyleNamespace("north-star/card/empty");
  heading = emptyCardHeading();
  image = emptyCardImage();

  get imageStyles(): SafeString {
    return htmlSafe(`background-image: url(${this.image})`);
  }
}
