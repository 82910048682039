import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { Blob as DirectUploadBlob } from "@rails/activestorage";
import { DirectUpload } from "@rails/activestorage";
import { Assets } from "renderer-engine";
import config from "client/config/environment";
import { validateSvgFile } from "client/lib/file-utils";
import { isPresent } from "client/lib/is-present";
import type AudioTrack from "client/models/audio-track";
import type TeamAsset from "client/models/team-asset";
import type UserAsset from "client/models/user-asset";
import { UserAssetTypes } from "client/models/user-asset";
import type AssetLibraryService from "client/services/asset-library";
import type AudioTracksService from "client/services/audio-tracks";
import type AuthService from "client/services/auth";
import type LoaderService from "client/services/loader";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import UploadValidator from "client/services/upload-validator";

export const FILE_UPLOAD_ERROR_NOTIFICATION = "Darn it! Your file failed to upload";

export interface UploadOptions {
  accept?: string;
  multiple?: boolean;
}

export default class ActiveStorageService extends Service {
  @service
  declare audioTracks: AudioTracksService;

  @service
  declare auth: AuthService;

  @service
  declare loader: LoaderService;

  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare store: Store;

  @service
  declare assetLibrary: AssetLibraryService;

  async showFilePicker(options: UploadOptions = {}): Promise<FileList> {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = options.multiple ?? true;

    if (options.accept) {
      input.accept = options.accept;
    }
    const promise = new Promise<FileList>((resolve, reject) => {
      input.addEventListener("change", async () => {
        if (input.files && input.files.length) {
          const valid = this.checkFiles(input.files, options.accept);
          if (valid) {
            resolve(input.files);
          } else {
            reject();
          }
        }
      });
    });

    input.click();

    return promise;
  }

  public progress(message: string, showEllipsis = true): void {
    this.loader.show(message.trim(), true, showEllipsis);
  }

  private checkFiles(files: FileList | File[], accept?: string): boolean {
    if (files.length > 10) {
      this.notifications.warning("You can only upload 10 files at a time. Please try again");
      return false;
    }

    for (const file of files) {
      const validator = new UploadValidator(file, accept);
      if (!validator.isValid) {
        this.notifications.warning(validator.errorMessage);
        return false;
      }
    }

    return true;
  }

  public async createTeamAsset(file: File, assetType?: UserAssetTypes, statusMsg = ""): Promise<TeamAsset> {
    return this.createAsset("teamAsset", file, assetType, statusMsg);
  }

  public async createUserAsset(file: File, assetType?: UserAssetTypes, statusMsg = ""): Promise<UserAsset> {
    return this.createAsset("userAsset", file, assetType, statusMsg);
  }

  private async createAsset(
    modelName: string,
    file: File,
    assetType?: UserAssetTypes,
    statusMsg = ""
  ): Promise<UserAsset | TeamAsset> {
    try {
      if (file.type === "image/svg+xml") {
        file = await validateSvgFile(file);
      }

      const blob = await this.rawUploadFile(file, statusMsg);
      this.progress(`Creating asset ${statusMsg}`, !statusMsg);
      assetType = assetType ?? this.getAssetType(blob.content_type);
      const hasAudio =
        assetType === UserAssetTypes.VIDEO ? await this.hasAudio(file) : assetType === UserAssetTypes.AUDIO;

      const asset = this.store.createRecord(modelName, {
        fileSignedId: blob.signed_id,
        name: blob.filename,
        mimeType: blob.content_type,
        assetType,
        hasAudio
      }) as UserAsset;
      await asset.save();
      const saveFileAsset = Assets.saveFileAssetForTranscodeBypass(asset.url, file);
      if ([UserAssetTypes.IMAGE, UserAssetTypes.VIDEO].includes(asset.assetType)) {
        try {
          this.progress(`Generating thumbnail`);
          await this.assetLibrary.waitForAssetLibraryPreviewUrl(asset);
        } catch (err) {
          await asset.destroyRecord();
          this.notifications.error("Uh oh! There was a problem generating the preview of your uploaded file");
          throw err;
        }
      }
      await saveFileAsset;
      return asset;
    } finally {
      this.loader.hide();
    }
  }

  public async createMusicTrack(file: File): Promise<AudioTrack> {
    try {
      const { filename, signed_id: fileSignedId } = await this.rawUploadFile(file);
      this.progress(`Creating track`);
      return await this.audioTracks.createAudioTrack({ fileSignedId, filename });
    } finally {
      this.loader.hide();
    }
  }

  public async uploadFile(file: File, statusMsg = ""): Promise<DirectUploadBlob> {
    try {
      return await this.rawUploadFile(file, statusMsg);
    } catch (err) {
      this.notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
      throw err;
    } finally {
      this.loader.hide();
    }
  }

  private rawUploadFile(file: File, statusMsg = ""): Promise<DirectUploadBlob> {
    const upload = new DirectUpload(file, `${config.apiUrl}/direct_uploads`, {
      directUploadWillCreateBlobWithXHR: (xhr): void => {
        xhr.setRequestHeader("Authorization", `Bearer ${this.auth.authToken}`);
        this.progress(`Preparing ${statusMsg}`);
      },
      directUploadWillStoreFileWithXHR: (xhr): void => {
        xhr.upload.addEventListener("progress", (event) => {
          this.progress(`Uploading…\n${((event.loaded / event.total) * 100).toFixed(0)}%`, false);
        });
      }
    });

    return new Promise((resolve, reject) => {
      upload.create(async (error: Error, blob: DirectUploadBlob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }

  private async hasAudio(file: File): Promise<boolean> {
    try {
      const audioContext = new AudioContext();
      const arrayBuffer = await file.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      return audioBuffer.numberOfChannels > 0;
    } catch (e) {
      return false;
    }
  }

  private eventHasFiles(dataTransfer?: DataTransfer | null): boolean {
    if (!dataTransfer) {
      return false;
    }

    if (dataTransfer.items && dataTransfer.items.length) {
      return [...dataTransfer.items].some((i) => i.kind === "file");
    }

    return dataTransfer.files?.length > 0;
  }

  private getFilesFromEvent(dataTransfer?: DataTransfer | null): File[] {
    if (!dataTransfer) {
      return [];
    }
    if (dataTransfer.items && dataTransfer.items.length) {
      return [...dataTransfer.items]
        .filter((i) => i.kind === "file")
        .map((i) => i.getAsFile())
        .filter(isPresent);
    } else if (dataTransfer.files && dataTransfer.files.length) {
      return [...dataTransfer.files];
    }

    return [];
  }

  public setupDrop(
    isDragging: (dragging: boolean) => void,
    uploadFiles: (files: FileList | File[]) => Promise<void>,
    accept?: string
  ): () => void {
    const handleDrop = (event: DragEvent): void => {
      event.preventDefault();
      hideDragOverlay();
      const files = this.getFilesFromEvent(event.dataTransfer);
      if (files.length) {
        const valid = this.checkFiles(files, accept);
        if (valid) {
          void uploadFiles(files);
        }
      }
    };

    const onDragEnter = (event: DragEvent): void => {
      if (this.eventHasFiles(event.dataTransfer)) {
        showDragOverlay();
      }
    };

    let fullscreenDropOverlay: HTMLDivElement | undefined;

    const showDragOverlay = (): void => {
      fullscreenDropOverlay = document.createElement("div");
      fullscreenDropOverlay.style.position = "absolute";
      fullscreenDropOverlay.style.top = "0";
      fullscreenDropOverlay.style.bottom = "0";
      fullscreenDropOverlay.style.left = "0";
      fullscreenDropOverlay.style.right = "0";
      fullscreenDropOverlay.style.zIndex = "5555";
      document.body.appendChild(fullscreenDropOverlay);

      fullscreenDropOverlay.addEventListener("dragend", stopDragging);
      fullscreenDropOverlay.addEventListener("dragleave", stopDragging);
      fullscreenDropOverlay.addEventListener("dragenter", startDragging);
      fullscreenDropOverlay.addEventListener("dragover", startDragging);
      fullscreenDropOverlay.addEventListener("drop", handleDrop);

      isDragging(true);
    };

    const hideDragOverlay = (): void => {
      fullscreenDropOverlay && fullscreenDropOverlay.remove();
      fullscreenDropOverlay = undefined;

      isDragging(false);
    };

    const removeDrop = (): void => {
      window.removeEventListener("dragenter", onDragEnter);
      if (fullscreenDropOverlay) {
        fullscreenDropOverlay.addEventListener("dragend", stopDragging);
        fullscreenDropOverlay.addEventListener("dragleave", stopDragging);
        fullscreenDropOverlay.addEventListener("dragenter", startDragging);
        fullscreenDropOverlay.addEventListener("dragover", startDragging);
        fullscreenDropOverlay.addEventListener("drop", handleDrop);
      }
    };

    const startDragging = (event: DragEvent): void => {
      event.stopPropagation();
      event.preventDefault();
    };

    const stopDragging = (event: DragEvent): void => {
      event.stopPropagation();
      event.preventDefault();
      hideDragOverlay();
    };

    window.addEventListener("dragenter", onDragEnter);

    return removeDrop;
  }

  public silentRawUploadFile(file: File): Promise<DirectUploadBlob> {
    const upload = new DirectUpload(file, `${config.apiUrl}/direct_uploads`, {
      directUploadWillCreateBlobWithXHR: (xhr): void => {
        xhr.setRequestHeader("Authorization", `Bearer ${this.auth.authToken}`);
      }
    });

    return new Promise((resolve, reject) => {
      upload.create(async (error: Error, blob: DirectUploadBlob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }

  public async createFileFromURL(url: string): Promise<File> {
    const [filename = "upload"] = url.split("/").reverse();
    const response = await fetch(`${url}?nocache`);
    const data = await response.blob();
    const metadata = {
      type: data.type
    };
    const file = new File([data], filename, metadata);

    return file;
  }

  private getAssetType(contentType: string): UserAssetTypes {
    if (contentType.startsWith("image")) {
      return contentType.includes("svg") ? UserAssetTypes.SVG : UserAssetTypes.IMAGE;
    } else {
      return UserAssetTypes.VIDEO;
    }
  }
}
