import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

interface ShareCopyLinkButtonComponentArgs {
  recordProject: RecordProject;
  toggleOptionsMenu: () => void;
}

export default class ShareCopyLinkButtonComponent extends Component<ShareCopyLinkButtonComponentArgs> {
  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @service
  declare notifications: NotificationsService;

  requestLink = [config.recordUrl, "record", "request", this.args.recordProject.token].join("/");

  styleNamespace = getStyleNamespace("app/record-folder-thumbnail/copy-link");

  @action
  onCopyToClipboard(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.copyToClipboard();
    this.trackClickCopyLinkEvent();
    this.args.toggleOptionsMenu();
  }

  copyToClipboard(): void {
    if (!navigator.clipboard) {
      const textarea = document.createElement("textarea");
      textarea.value = this.requestLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } else {
      void navigator.clipboard.writeText(this.requestLink);
    }
    this.notifications.success("Link copied to clipboard");
  }

  trackClickCopyLinkEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_COPY_LINK_RECORD_PROJECT, {
      userId: this.args.recordProject.userId,
      recordProject: this.args.recordProject.id
    });
  }
}
