import { action } from "@ember/object";
import Component from "@glimmer/component";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

interface ExportActionsRebuildComponentArgs {
  denyRebuild: boolean;
  project: Project;
  renderStarting: boolean;
  renderProject: (ctaContext: string) => Promise<void>;
}

export default class ExportActionsRebuildComponent extends Component<ExportActionsRebuildComponentArgs> {
  styleNamespace = getStyleNamespace("export-actions/rebuild");

  _disabled = false;

  get disabled(): boolean {
    return this.args.denyRebuild || this._disabled;
  }

  @action
  async onUpdateVideo(): Promise<void> {
    try {
      this._disabled = true;
      await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_BELOW_PREVIEW_UPDATE);
    } finally {
      this._disabled = false;
    }
  }
}
