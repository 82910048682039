import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type DragAndDropService from "client/services/drag-and-drop";

/**
 * drag and drop
 *  - default => globally prevents new tab from opening on drop
 *  - dragging => ignore inner drag enters and leaves using counter method (alternatives: pointer-events none)
 *  - cursor => custom cursor changes requires preventDefault() on "dragstart" and complete behavior override
 * @argument draggable make draggable (true by default)
 * @argument isDragAccepted validate dragover/drop by event or content (ie by type, content or conditions)
 * @argument showDragOverElement show dragover element (not shown by default)
 * @argument targetable allow element to be a drop target (true by default)
 */
interface DragAndDropArgs {
  content?: any;
  draggable?: boolean;
  targetable?: boolean;
  isDragAccepted?: (event: DragEvent, content: any) => boolean;
  onDrag?: (event: DragEvent) => void;
  onDragEnd?: (event: DragEvent) => void;
  onDragEnter?: (event: DragEvent) => void;
  onDragLeave?: (event: DragEvent) => void;
  onDragOver?: (event: DragEvent) => void;
  onDragStart?: (event: DragEvent) => void;
  onDrop?: (event: DragEvent) => void;
  showDragOverElement?: boolean;
}

export default class DragAndDropComponent extends Component<DragAndDropArgs> {
  @service
  declare dragAndDrop: DragAndDropService;

  @tracked
  dragElement?: HTMLElement;

  @tracked
  dragElementIsChild = false;

  @tracked
  counter = 0;

  @tracked
  isHoveringOver = false;

  @tracked
  isBeingDragged!: boolean;

  @tracked
  isDragAccepted = true;

  @tracked
  draggable = this.args.draggable ?? true;

  @tracked
  showDragOverElement = this.args.showDragOverElement ?? false;

  styleNamespace = getStyleNamespace("drag-and-drop");

  @tracked
  targetable = this.args.targetable ?? true;
  targetableAttribute = "data-drop-target";
  targetableClasses = [`${this.styleNamespace}__DropTarget`, "--valid-target"];

  get isTargetable(): boolean {
    return (
      !this.dragElementIsChild && !this.isBeingDragged && this.isDragAccepted && this.isHoveringOver && this.targetable
    );
  }

  private setIsDragAccepted(event: DragEvent): void {
    this.isDragAccepted = this.args.isDragAccepted?.(event, this.dragAndDrop.content) ?? true;
  }

  // actions
  private removeHoverEffect(target: HTMLElement): void {
    target?.closest(`[${this.targetableAttribute}]`)?.classList.remove(...this.targetableClasses);
  }
  private addHoverEffect(target: HTMLElement): void {
    target?.closest(`[${this.targetableAttribute}]`)?.classList.add(...this.targetableClasses);
  }

  private reset(target: HTMLElement): void {
    this.counter = 0;
    this.isDragAccepted = true;
    this.isBeingDragged = false;
    this.isHoveringOver = false;
    this.dragAndDrop.content = undefined;
    this.dragElement = undefined;
    this.dragElementIsChild = false;
    this.removeHoverEffect(target);
  }

  private setGlobalListeners(): void {
    document.addEventListener("dragover", this.onDragOverGlobal);
    document.addEventListener("drop", this.onDropGlobal);
  }

  private removeGlobalListeners(): void {
    document.removeEventListener("dragover", this.onDragOverGlobal);
    document.removeEventListener("drop", this.onDropGlobal);
  }

  @action
  onDrag(event: DragEvent): void {
    this.dragElement = event.target as HTMLElement;
    this.args.onDrag?.(event);
  }

  @action
  onDragEnter(event: DragEvent): void {
    event.stopPropagation();

    this.counter++;
    this.args.onDragEnter?.(event);
  }

  @action
  onDragLeave(event: DragEvent): void {
    event.stopPropagation();

    this.counter--;
    if (this.counter === 0) {
      this.removeHoverEffect(event.target as HTMLElement);
      this.isHoveringOver = false;
      this.args.onDragLeave?.(event);
    }
  }

  @action
  onDragStart(event: DragEvent): void {
    if (!this.draggable) {
      return;
    }

    event.stopPropagation();
    this.dragAndDrop.content = this.args.content;
    this.isBeingDragged = true;
    this.args.onDragStart?.(event);
  }

  @action
  onDragEnd(event: DragEvent): void {
    if (!this.draggable) {
      return;
    }

    this.reset(event.target as HTMLElement);
    this.args.onDragEnd?.(event);
  }

  @action
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }

    this.setIsDragAccepted(event);
    this.dragElementIsChild = (event.currentTarget as HTMLElement).contains(this.dragElement as HTMLElement);
    this.isHoveringOver = true;
    if (this.isTargetable) {
      this.addHoverEffect(event.target as HTMLElement);
      this.args.onDragOver?.(event);
    }
  }

  @action
  onDrop(event: DragEvent): void {
    if (this.isTargetable) {
      this.args.onDrop?.(event);
    }
    this.reset(event.target as HTMLElement);
  }

  // global
  @action
  onDragOverGlobal(event: DragEvent): void {
    event.preventDefault();
  }

  @action
  onDropGlobal(event: DragEvent): void {
    event.preventDefault();
  }

  // lifecycle
  @action
  didInsert(): void {
    this.setGlobalListeners();
  }

  willDestroy(): void {
    super.willDestroy();
    this.removeGlobalListeners();
  }
}
