import { service } from "@ember/service";
import { attr } from "@ember-data/model";
import type { Asset, ImageConfig, VideoConfig } from "renderer-engine";
import { ZymbolCategory, makeCORSUrl, DEFAULT_PROJECT_SCENE_DURATION } from "renderer-engine";
import Containable from "./containable";
import { FolderContainableTypes } from "./folder-containable";
import { MIN_PROJECT_SCENE_DURATION } from "./project-scene-duration";
import TrackingEvents from "client/events";
import { getVideoDuration } from "client/lib/video";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";

export enum UserAssetTypes {
  IMAGE = "image",
  VIDEO = "video",
  STOCK = "stock",
  LOGO = "logo",
  AUDIO = "audio",
  SVG = "svg"
}

export default class UserAsset extends Containable {
  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @attr("string")
  declare name: string;

  @attr("string")
  declare filestackUrl: string;
  get url(): string {
    return this.filestackUrl;
  }

  set url(url: string) {
    this.filestackUrl = url;
  }

  // For image assets it will be the image, for videos it will be the video
  @attr("string")
  declare previewUrl: string;

  // Should only exist on video assets
  @attr("string")
  declare previewImageUrl: string;

  @attr("string")
  declare mimeType: string;

  @attr("boolean")
  declare stockFootage: boolean;

  @attr("boolean")
  declare hasAudio: boolean;

  @attr("date")
  declare createdAt: Date;

  @attr("date")
  declare deleteAt: Date;

  @attr("string")
  declare assetType: UserAssetTypes;

  @attr("string")
  declare downsizedUrlBase?: string;

  @attr("string")
  declare fileSignedId?: string;

  @attr("boolean", { defaultValue: true })
  declare showInLibrary: boolean;

  @attr("number")
  declare encodingLevel: number;

  type = "user-asset";

  get cdnUrl(): string {
    return makeCORSUrl(this.previewUrl);
  }

  get zymbolCategory(): ZymbolCategory.IMAGE | ZymbolCategory.VIDEO | ZymbolCategory.SVG {
    switch (this.assetType) {
      case UserAssetTypes.LOGO:
      case UserAssetTypes.IMAGE: {
        return ZymbolCategory.IMAGE;
      }
      case UserAssetTypes.SVG: {
        return ZymbolCategory.SVG;
      }
      default: {
        return ZymbolCategory.VIDEO;
      }
    }
  }

  get thumbImageUrl(): string | undefined {
    if (this.previewImageUrl) {
      return this.previewImageUrl;
    } else if (this.isImage || this.isSvg) {
      return this.previewUrl;
    } else {
      return undefined;
    }
  }

  get thumbVideoUrl(): string | undefined {
    return this.isVideo ? this.previewUrl : undefined;
  }

  get isVideo(): boolean {
    return this.zymbolCategory === ZymbolCategory.VIDEO;
  }

  get isImage(): boolean {
    return this.zymbolCategory === ZymbolCategory.IMAGE;
  }

  get isSvg(): boolean {
    return this.zymbolCategory === ZymbolCategory.SVG;
  }

  // Memoized `videoDuration` to avoid having to retrieve multiple times
  private _videoDuration?: number;

  readonly containableType = FolderContainableTypes.USER_ASSET;

  get containableId(): string {
    return this.id;
  }

  private async _getVideoDuration(): Promise<number> {
    if (this.isVideo) {
      return getVideoDuration(this.url);
    } else {
      return 0;
    }
  }

  async getVideoDuration(): Promise<number> {
    this._videoDuration ??= await this._getVideoDuration();

    return this._videoDuration;
  }

  // Project scenes have to have a length divisible by 0.5
  async getDurationForScene(preventLooping = false): Promise<number> {
    const duration = await this.getVideoDuration();

    if (!duration) {
      return DEFAULT_PROJECT_SCENE_DURATION;
    } else {
      // Scene transitions extend the amount of required render time by 1s.
      // This offset prevents the video from looping during the scene transition.
      const loopPreventionOffsetSeconds = preventLooping ? 0.5 : 0;

      const newDuration = Math.floor(duration * 2) / 2 - loopPreventionOffsetSeconds;

      return Math.max(newDuration, MIN_PROJECT_SCENE_DURATION);
    }
  }

  async asZymbolConfig(): Promise<Partial<ImageConfig> | Partial<VideoConfig>> {
    if (this.isImage || this.isSvg) {
      return this.asImageConfig();
    } else if (this.isVideo) {
      return this.asVideoConfig();
    } else {
      return {};
    }
  }

  async asVideoConfig(): Promise<Partial<VideoConfig> & { asset: Asset }> {
    const {
      previewUrl: previewVideoUrl,
      previewImageUrl,
      name,
      url,
      downsizedUrlBase,
      hasAudio,
      encodingLevel,
      id
    } = this;
    const duration = await this.getVideoDuration();

    return {
      asset: {
        id,
        type: "user-asset"
      },
      previewImageUrl,
      previewVideoUrl,
      lowResVideoUrl: undefined,
      url,
      name,
      duration,
      startTime: 0,
      downsizedUrlBase,
      hasAudio,
      volume: hasAudio ? 1 : undefined,
      fadeIn: hasAudio ? false : undefined,
      fadeOut: hasAudio ? false : undefined,
      mute: hasAudio ? false : undefined,
      encodingLevel
    };
  }

  asImageConfig(): Partial<ImageConfig> & { asset: Asset } {
    const { url, name, previewUrl: previewImageUrl, id } = this;

    return {
      asset: {
        id,
        type: "user-asset"
      },
      previewImageUrl,
      url,
      name
    };
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_USER_ASSET;

  get trackUsageData(): {} {
    return {
      elementId: this.id,
      type: this.zymbolCategory,
      name: this.name
    };
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    userAsset: UserAsset;
  }
}
