import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import type { AudioClip } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type UserAsset from "client/models/user-asset";
import type { AudioRouteModel } from "client/routes/audio";
import { ASSET_LIBRARY_AUDIO_MIMETYPES } from "client/services/asset-library";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type UpgradeService from "client/services/upgrade";

interface ProjectEditAudioSelectArgs {
  disableNoOption?: boolean;
  label: string;
  onUpload: () => void;
  onSave: (assets?: UserAsset[]) => Promise<void>;
  onRemove: (asset: UserAsset) => void;
  onCancel: () => void;
  audioUserAssets?: UserAsset[];
  selectedClips?: AudioClip[];
  model: AudioRouteModel;
  currentNarrationId: number | undefined;
  loadingMore: boolean;
  isLastPage: boolean;
  currentlyLoading: boolean;
}

export default class ProjectEditAudioSelectComponent extends Component<ProjectEditAudioSelectArgs> {
  @tracked
  selectedAssets: UserAsset[] = new TrackedArray([]);

  @tracked
  activeTab = "narration";

  @service
  declare store: Store;

  @service
  declare upgrade: UpgradeService;

  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  styleNamespace = getStyleNamespace("tidal/project-edit/audio/select");

  @action
  didInsert(): void {
    this.activeTab = sessionStorage.getItem("activeTab") || this.activeTab;
  }

  get selectedAudioClips(): AudioClip[] {
    return this.args.selectedClips || [];
  }

  get existingAssets(): UserAsset[] {
    return this.args.audioUserAssets?.filter((ua) => this.selectedClipUrls.includes(ua.url)) || [];
  }

  get selectedClipUrls(): string[] {
    return this.selectedAudioClips?.map((clip) => this.clipUrl(clip));
  }

  get selectedAssetsEmpty(): boolean {
    return this.selectedAssets.length === 0;
  }

  private clipUrl(clip: AudioClip): string {
    return clip?.sourceUrl || "";
  }

  @action
  remove(asset: UserAsset): void {
    this.args.onRemove(asset);
    const index = this.selectedAssets.indexOf(asset);
    if (index >= 0) {
      this.selectedAssets.splice(index, 1);
    }
  }

  @action
  preview(asset: UserAsset): void {
    // preview will auto-add the asset, but not auto-remove it
    const index = this.selectedAssets.indexOf(asset);
    if (index < 0) {
      this.selectedAssets.push(asset);
    }
  }

  @action
  select(asset: UserAsset): void {
    if (!asset) {
      this.selectedAssets = new TrackedArray([]);
      return;
    }

    const index = this.selectedAssets.indexOf(asset);
    if (index < 0) {
      this.selectedAssets.push(asset);
    } else {
      this.selectedAssets.splice(index, 1);
    }
  }

  @action
  async save(): Promise<void> {
    const { audioUserAssets } = this.args;

    if (audioUserAssets) {
      const selectedAssetsInOrder = this.selectedAssets.slice().sort((a, b) => {
        return audioUserAssets?.indexOf(a) > audioUserAssets?.indexOf(b) ? 1 : -1;
      });
      await this.args.onSave(selectedAssetsInOrder);
    }

    this.cancel();
  }

  @action
  cancel(): void {
    this.args.onCancel();
  }

  @action
  onTabChange(active: string): void {
    this.activeTab = active;
    sessionStorage.setItem("activeTab", active);
  }

  @action
  onUpgrade(): void {
    void this.upgrade.open();
    this.cancel();
  }

  get isVoiceover(): boolean {
    return this.activeTab === "voiceover";
  }

  get isNarration(): boolean {
    return this.activeTab === "narration";
  }

  get acceptMimeTypes(): string {
    return ASSET_LIBRARY_AUDIO_MIMETYPES.toString();
  }

  get showUpgradeTriggers(): boolean {
    return this.auth.currentFullOrTrialSubscription?.trialing || !this.permissions.has("feature_multiple_brands");
  }
}
