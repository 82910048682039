import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents, { Locations } from "client/events";
import type { Element, EventRegister } from "client/lib/editor-domain-model";
import { MoveLayerMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getLayerOrderTrackingProperties } from "client/lib/timeline/element";
import { transaction } from "client/lib/transaction";
import type AdvancedEditorService from "client/services/advanced-editor";
import type TrackingService from "client/services/tracking";

interface Args {
  eventRegister: EventRegister;
}

export default class FloatingPanelsLayersComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panels/layers");

  @service
  private declare advancedEditor: AdvancedEditorService;

  @service
  private declare tracking: TrackingService;

  get watermarkElements(): Element[] {
    const watermark = this.advancedEditor.timeline?.watermark;
    const watermarkVisible = this.advancedEditor.project?.userWatermarkVisible;

    return watermark && watermarkVisible ? [watermark] : [];
  }

  get captionElements(): Element[] {
    const elements = this.advancedEditor.caption?.elementsSortedByLayer;

    return elements ? [...elements].reverse() : [];
  }

  get backgroundElements(): Element[] {
    const background = this.advancedEditor.scene?.background;

    return background ? [background] : [];
  }

  @action
  @transaction
  async captionOnChange(elements: Element[], draggedElement: Element): Promise<void> {
    if (!this.advancedEditor.timeline || !this.advancedEditor.scene || !this.advancedEditor.caption) {
      return;
    }

    const startIndex = this.captionElements.indexOf(draggedElement);
    const endIndex = elements.indexOf(draggedElement);

    if (startIndex === endIndex) {
      return;
    }

    const direction = startIndex > endIndex ? 1 : -1;

    while (this.captionElements.indexOf(draggedElement) !== endIndex) {
      const mutation = new MoveLayerMutation(draggedElement, this.advancedEditor.caption, direction);
      this.args.eventRegister.fire(mutation);
    }

    await this.args.eventRegister.facade.saveScene(this.advancedEditor.scene);

    const trackingProperties = getLayerOrderTrackingProperties(
      this.advancedEditor.timeline,
      this.advancedEditor.caption,
      draggedElement,
      direction === -1 ? "backward" : "forward",
      Locations.LOCATION_LAYERS_PANEL
    );
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_CHANGE_ELEMENT_LAYERS, trackingProperties);
  }
}
