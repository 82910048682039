import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import BaseParentPanel from "../base-parent/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Sag from "client/models/sag";
import type SelectableAsset from "client/models/selectable-asset";

export default class SagsPanel extends BaseParentPanel {
  @service
  declare store: Store;

  styleNamespace = getStyleNamespace("project-content-bar/panels/sags");

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<SelectableAsset>> {
    // @ts-expect-error
    return this.store.query("sag", params) as Sag[];
  }
}
