import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import type TrackingService from "client/services/tracking";

interface RequestRecordingCopyLinkButtonComponentArgs {
  recordProject: RecordProject;
  variant: string;
}

const NOTIFICATION_DURATION = 3000;

export default class RequestRecordingCopyLinkButtonComponent extends Component<RequestRecordingCopyLinkButtonComponentArgs> {
  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  variant?: string = this.args.variant || "default";

  @tracked
  showLinkCopiedNotification = false;

  requestLink = [config.recordUrl, "record", "request", this.args.recordProject.token].join("/");
  copyButtonMessageTimeout?: any;

  @action
  onCopyToClipboard(): void {
    this.copyToClipboard();
    this.trackClickCopyLinkEvent();
  }

  copyToClipboard(): void {
    if (!navigator.clipboard) {
      const textarea = document.createElement("textarea");
      textarea.value = this.requestLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } else {
      void navigator.clipboard.writeText(this.requestLink);
    }
    this.toggleCopyButtonMessage();
  }

  trackClickCopyLinkEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_COPY_LINK_RECORD_PROJECT, {
      userId: this.args.recordProject.userId,
      recordProject: this.args.recordProject.id
    });
  }

  toggleCopyButtonMessage(): void {
    this.showLinkCopiedNotification = true;

    if (this.copyButtonMessageTimeout) {
      clearTimeout(this.copyButtonMessageTimeout as number);
    }

    this.copyButtonMessageTimeout = setTimeout(() => {
      this.showLinkCopiedNotification = false;
    }, NOTIFICATION_DURATION);
  }
}
