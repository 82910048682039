import type ProjectScene from "client/models/project-scene";
import BaseEventService from "client/services/base-events";

export enum TimelineEvents {
  ACTIVE_CAPTION_CHANGED = "ACTIVE_CAPTION_CHANGED",
  ACTIVE_SCENE_CHANGED = "ACTIVE_SCENE_CHANGED",

  TIMELINE_UPDATED = "TIMELINE_UPDATED",
  SCENE_FAVORITE_CHANGED = "SCENE_FAVORITE_CHANGED",

  PROJECT_DID_CHANGE = "PROJECT_DID_CHANGE",
  ASPECT_RATIO_CHANGED = "ASPECT_RATIO_CHANGED",
  AUDIO_CHANGED = "AUDIO_CHANGED",
  BRAND_STYLE_CHANGED = "BRAND_STYLE_CHANGED",

  CONTENT_DRAG_START = "CONTENT_DRAG_START",
  CONTENT_DRAG_STOP = "CONTENT_DRAG_STOP"
}

export interface ActiveSceneChangedEventProperties {
  scene: { id: string };
  animate?: boolean;
}

export interface SceneFavoriteChangedEventProperties {
  favorited: boolean;
  teamFavorited: boolean;
  projectScene: ProjectScene;
}

export default class TimelineEventsService extends BaseEventService {
  eventLogPrefix = "TIMELINE";

  publish(eventName: TimelineEvents, payload?: any): void {
    super.publish(eventName, payload);
  }
}
