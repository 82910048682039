import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { ContributorTypes } from "client/models/folder";

export default class FoldersIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    await this.router.replaceWith("authenticated.folders.library", ContributorTypes.USER);
  }
}
