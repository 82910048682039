import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type BrandStyleService from "./brand-style";
import type CurrentUserService from "./current-user";
import type HoneybadgerService from "./honeybadger";
import type PlansService from "./plans";
import type SubscriptionsService from "./subscriptions";

export default class AccountSetupService extends Service {
  @service
  private declare currentUser: CurrentUserService;

  @service
  private declare brandStyle: BrandStyleService;

  @service
  private declare subscriptions: SubscriptionsService;

  @service
  private declare plans: PlansService;

  @service
  private declare store: Store;

  @service
  private declare honeybadger: HoneybadgerService;

  completed: Promise<unknown> = Promise.resolve();

  setup(): void {
    this.completed = Promise.all([this.getOrCreateBrand(), this.getOrCreateTrial()]);
  }

  private async getOrCreateTrial(): Promise<void> {
    const { user } = this.currentUser;

    if (user) {
      const latestTrial = await user.latestTrial;
      if (!latestTrial) {
        await this.createTrial();
      }
    }
  }

  private get shouldStartTrial(): boolean {
    return this.subscriptions.canManage;
  }

  private async createTrial(): Promise<void> {
    if (this.shouldStartTrial) {
      try {
        const plan = await this.plans.getDefaultTrialPlan();
        const trial = this.store.createRecord("trial");

        trial.setProperties({
          planId: plan.id
        });

        await trial.save();
        await this.currentUser.reload();
      } catch (err) {
        // @ts-expect-error
        this.honeybadger.notify(err);
      }
    }
  }

  private async getOrCreateBrand(): Promise<void> {
    if (await this.brandStyle.getBrand()) {
      return;
    }
    await this.brandStyle.createDefaultBrand();
  }
}
