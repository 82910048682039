import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type PropertiesPanelService from "client/services/properties-panel";

export default class ToolbarClosePropertiesComponent extends Component {
  @service
  declare router: RouterService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @action
  onClick(): void {
    const newUrl = this.router.currentURL.replace(/\/[^/]*$/, "");
    this.propertiesPanel.close();
    void this.router.transitionTo(newUrl);
  }
}
