import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { ProjectSceneCaptionRouteModel } from "client/authenticated/project/scene/caption/route";
import type { Text } from "client/lib/editor-domain-model";
import type AdvancedEditorService from "client/services/advanced-editor";

export interface ProjectSceneCaptionTextRouteModel extends ProjectSceneCaptionRouteModel {
  text: Text;
}

export interface ProjectSceneCaptionTextRouteParams {
  zymbolId: string;
}

export default class TextRoute extends Route<ProjectSceneCaptionTextRouteModel> {
  @service
  declare advancedEditor: AdvancedEditorService;

  async model({ zymbolId }: ProjectSceneCaptionTextRouteParams): Promise<ProjectSceneCaptionTextRouteModel> {
    const model = this.modelFor("authenticated.project.scene.caption") as ProjectSceneCaptionRouteModel;
    const { caption } = model;

    const text = caption.texts.find(({ id }) => id === zymbolId);

    if (!text) {
      return Promise.reject(new Error("Requested text is not in caption"));
    }

    return {
      ...model,
      text
    };
  }

  afterModel({ text }: ProjectSceneCaptionTextRouteModel): void {
    this.advancedEditor.setElement(text);
  }
}
