import { UserAsset } from "@biteable/network-model";
import { tracked } from "@glimmer/tracking";
import { colorDistance } from "../color";
import type { BrandLogoJSON } from "client/lib/brand/brand-logo";
import BrandLogo from "client/lib/brand/brand-logo";
import type { ColorPresetJSON } from "client/lib/brand/color-preset";
import ColorPreset from "client/lib/brand/color-preset";
import dedup from "client/lib/dedup";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import type { TextStyleProperties } from "client/lib/editor-domain-model";
import { TextStyle, ChangeTextStyleFontMutation } from "client/lib/editor-domain-model";
import removeEmptyProps from "client/lib/remove-empty-props";
import type BrandQuery from "client/models/brand-query";
import { UserAssetTypes } from "client/models/user-asset";

export const MIN_COLOR_PRESET_COUNT = 3;
export const MAX_COLOR_PRESET_COUNT = 6;

// https://zschuessler.github.io/DeltaE/learn/#toc-defining-delta-e
// +---------+----------------------------------------+
// | Delta E |               Perception               |
// +---------+----------------------------------------+
// | <= 1.0  | Not perceptible by human eyes.         |
// | 1 - 2   | Perceptible through close observation. |
// | 2 - 10  | Perceptible at a glance.               |
// | 11 - 49 | Colors are more similar than opposite  |
// | 100     | Colors are exact opposite              |
// +---------+----------------------------------------+
const MIN_COLOR_DISTANCE = 11;

const formatColors = <T>(colors: Array<T>): Array<T> => {
  return colors.map((c) => {
    if (typeof c === "string") {
      return c.toUpperCase();
    } else {
      return c;
    }
  }) as Array<T>;
};

const removeItem = <T>(arr: Array<T>, item: T): Array<T> => {
  const dup = [...arr];
  const index = dup.indexOf(item);

  if (index > -1) {
    dup.splice(index, 1);
  }

  return dup;
};

const replaceItem = <T>(arr: Array<T>, original: T, replacement: T): Array<T> => {
  const dup = [...arr];
  const index = dup.indexOf(original);

  if (index > -1) {
    dup.splice(index, 1, replacement);
  }

  return dup;
};

const findAndReplaceColor = <T extends string | undefined>(colors: Array<T>, oldColor: T, newColor: T): Array<T> => {
  return replaceItem<T>(colors, oldColor, newColor);
};

const removeColor = <T extends string | undefined>(colors: Array<T>, color: T): Array<T> => {
  return removeItem<T>(colors, color);
};

const WHITE_COLOR = "#FFFFFF";

export interface TextStyles {
  [key: string]: TextStyle;

  title: TextStyle;
  header: TextStyle;
  subheader: TextStyle;
  body: TextStyle;
}

const DEFAULT_FONT_FAMILY = "Roboto";

const TEXT_STYLE_DEFAULTS: Readonly<Pick<TextStyle, "fontFamily" | "weight" | "fontStyle" | "provider">> = {
  fontFamily: DEFAULT_FONT_FAMILY,
  weight: "300",
  fontStyle: "normal",
  provider: "google"
};

// This is the data that will be persisted to the server
interface BrandStyleConfigJSON extends Record<string, any> {
  name?: string;
  fetchUrl?: string;
  colorPresets?: Array<ColorPresetJSON>;
  textColorLight?: string;
  textColorDark?: string;
  colorPalette?: Array<string>;
  textStyles?: Record<string, TextStyleProperties>;
  logos?: Array<BrandLogoJSON>;
}

const DEFAULT_COLORS = ["#F2F2F5", "#313035", "#8A898E"];

export default class BrandStyleConfig {
  static MAX_COLOR_PRESETS = 6;

  @tracked
  name = "";

  @tracked
  fetchUrl = "";

  @tracked
  colorPresets: Array<ColorPreset> = [];

  @tracked
  colorPalette: Array<string | undefined> = DEFAULT_COLORS;

  @tracked
  textStyles: TextStyles = {
    title: new TextStyle({
      ...TEXT_STYLE_DEFAULTS,
      fontSize: 1.5625,
      lineHeight: 1.2
    }),
    header: new TextStyle({
      ...TEXT_STYLE_DEFAULTS,
      fontSize: 1.125,
      lineHeight: 1.1
    }),
    subheader: new TextStyle({
      ...TEXT_STYLE_DEFAULTS,
      fontSize: 0.625,
      lineHeight: 1.1
    }),
    body: new TextStyle({
      ...TEXT_STYLE_DEFAULTS,
      fontSize: 0.5,
      lineHeight: 1.1
    })
  };

  @tracked
  textColorLight = "#FAFAFA";

  @tracked
  textColorDark = "#1D1D1D";

  @tracked
  logos: Array<BrandLogo> = [];

  public assetsLoaded?: DeferredPromise<BrandLogo[]>;

  constructor(props?: Partial<BrandStyleConfig>) {
    Object.assign(this, props);
  }

  // For the intitial release only a single font-family is selectable. So just expose the body font
  public get fontFamily(): string {
    return this.textStyles.body.fontFamily ?? DEFAULT_FONT_FAMILY;
  }

  public get defaultLogo(): BrandLogo | undefined {
    return this.logos.find((logo) => logo.defaultLogo);
  }

  public get canAddColorPreset(): boolean {
    return this.colorPresets.length < BrandStyleConfig.MAX_COLOR_PRESETS;
  }

  public get nonEmptyColorPalette(): Array<string> {
    return this.colorPalette.filter((color) => typeof color === "string") as Array<string>;
  }

  public get validColorPresets(): Array<ColorPreset> {
    return this.colorPresets.filter(({ valid }) => valid);
  }

  public get emptyColorPresets(): boolean {
    return !this.colorPresets.length;
  }

  public removeColorPresetsWithColor(color: string): void {
    this.colorPresets = this.colorPresets.filter((cp) => !cp.colors.includes(color));
  }

  public addBrandColor(...colors: Array<string | undefined>): void {
    this.colorPalette = dedup(formatColors([...this.colorPalette, ...colors]));
  }

  public removeLogo(...logosToRemove: Array<BrandLogo>): void {
    this.logos = this.logos.filter((logo) => {
      return !logosToRemove.includes(logo);
    });
  }

  public addLogo(...logosToAdd: Array<BrandLogo>): void {
    this.logos = this.logos.concat(logosToAdd);

    if (!this.defaultLogo && this.logos[0]) {
      this.logos[0].defaultLogo = true;
    }
  }

  public addDistinctColors(newColors: string[]): string[] {
    // Filter the colors for color distance (ie. don't add colors that too closely match existing colors in the brand)
    const colorsToAdd = newColors.filter((newColor) => {
      return !this.nonEmptyColorPalette.some((color) => {
        const distance = colorDistance(newColor, color);

        return distance < MIN_COLOR_DISTANCE;
      });
    });

    if (colorsToAdd.length) {
      this.addBrandColor(...colorsToAdd);
    }

    return colorsToAdd;
  }

  public addDefaultLogo(logo: BrandLogo): void {
    logo.defaultLogo = true;

    if (this.defaultLogo) {
      this.defaultLogo.defaultLogo = false;
    }

    this.addLogo(logo);
  }

  public addColorPresets(colorPresets: ColorPreset[]): void {
    this.colorPresets = [...this.colorPresets, ...colorPresets].slice(0, BrandStyleConfig.MAX_COLOR_PRESETS);
  }

  public addColorPreset(colorPreset: ColorPreset): void {
    if (this.isUsedColorPreset(colorPreset)) {
      throw Error("That color combo has already been added");
    }

    if (!this.canAddColorPreset) {
      throw Error("Cannot add any more");
    }

    this.colorPresets = [...this.colorPresets, colorPreset];
  }

  public addBlankColorPreset(): void {
    const blank = new ColorPreset(this);

    this.colorPresets = [...this.colorPresets, blank];
  }

  public removeBrandColor(color?: string): void {
    if (color) {
      this.colorPalette = removeColor<string | undefined>(this.colorPalette, color);
      this.removeColorPresetsWithColor(color);
    }
  }

  public replaceBrandColor(oldColor: string, newColor: string): void {
    if (newColor === oldColor) {
      return;
    }

    if (this.colorPalette.includes(newColor)) {
      throw Error("This color already exists in your color palette");
    }

    this.colorPalette = findAndReplaceColor<string | undefined>(this.colorPalette, oldColor, newColor);
    this.colorPresets.forEach((cp) => cp.replaceColor(oldColor, newColor));
  }

  private getColorPresetIndex(colorPreset: ColorPreset): number {
    return this.colorPresets.findIndex((cp) => ColorPreset.equal(cp, colorPreset)) ?? -1;
  }

  public removeColorPreset(colorPreset: ColorPreset): void {
    const index = this.getColorPresetIndex(colorPreset);

    if (index > -1) {
      this.colorPresets.splice(index, 1);
    }

    // eslint-disable-next-line no-self-assign
    this.colorPresets = this.colorPresets;
  }

  public switchColorPreset(oldColorPreset: ColorPreset, newColorPreset: ColorPreset): void {
    const index = this.getColorPresetIndex(oldColorPreset);

    if (index > -1) {
      this.colorPresets.splice(index, 1, newColorPreset);
    }

    // eslint-disable-next-line no-self-assign
    this.colorPresets = this.colorPresets;
  }

  public setFontFamily(fontFamily: string): void {
    for (const key in this.textStyles) {
      const textStyle = this.textStyles[key];

      if (textStyle) {
        new ChangeTextStyleFontMutation(textStyle, fontFamily, {
          weight: "400",
          style: "normal"
        }).run();
      }
    }
  }

  public validate(): Array<Error> {
    const errors = [];

    if (!this.name.length) {
      errors.push(new Error("You must give your brand a name"));
    }

    if (this.colorPresets.length < MIN_COLOR_PRESET_COUNT) {
      errors.push(new Error(`You must add at least ${MIN_COLOR_PRESET_COUNT} color combos to your brand`));
    }

    if (this.colorPresets.length > MAX_COLOR_PRESET_COUNT) {
      errors.push(new Error(`You can only add ${MAX_COLOR_PRESET_COUNT} color combos to your brand`));
    }

    return errors;
  }

  private isUsedColorPreset(preset: ColorPreset): boolean {
    return !!this.colorPresets.find((cp) => ColorPreset.equal(preset, cp));
  }

  static fromJSON(data: BrandStyleConfigJSON): BrandStyleConfig {
    const {
      name,
      fetchUrl,
      textColorLight,
      textColorDark,
      colorPresets = [],
      colorPalette = [],
      textStyles = {},
      logos = []
    } = data;

    const props = removeEmptyProps({
      name,
      fetchUrl,
      textColorLight,
      textColorDark,
      colorPalette
    });

    const config = new BrandStyleConfig(props);

    config.assetsLoaded = defer();

    // Construct ColorPresets
    if (colorPresets instanceof Array) {
      config.colorPresets = colorPresets.map((colorPreset) => new ColorPreset(config, colorPreset));
    }

    // Construct Logos
    if (logos instanceof Array) {
      void config.setLogosFromJSON(logos);
    }

    // Construct TextStyles
    if (textStyles) {
      for (const key in textStyles) {
        // Only update known textStyle keys (title, header, subheader, body)
        if (key in config.textStyles) {
          const props = textStyles[key];

          if (props) {
            config.textStyles[key] = new TextStyle(props);
          }
        }
      }
    }

    return config;
  }

  public async getDefaultLogo(): Promise<BrandLogo | undefined> {
    if (!this.logos.length) {
      return undefined;
    }

    await this.assetsLoaded;

    return this.logos.find((logo) => logo.defaultLogo);
  }

  private async setLogosFromJSON(data: Array<BrandLogoJSON>): Promise<void> {
    try {
      const logos = await Promise.all(data.map((logo) => BrandLogo.fromJSON(logo)));
      this.addLogo(...logos);
      this.assetsLoaded?.resolve(logos);
    } catch (err) {
      this.assetsLoaded?.reject(err);
    }
  }

  public toJSON(): BrandStyleConfigJSON {
    const { name, fetchUrl, colorPresets, nonEmptyColorPalette, textColorDark, textColorLight, logos } = this;

    const logosJSON = logos.map((logo) => logo.toJSON()).filter((logo) => !!logo.userAssetId);
    const colorPresetsJSON = colorPresets.map((cp) => cp.toJSON()).filter((cp) => !!cp) as Array<ColorPresetJSON>;
    const colorPaletteJSON = nonEmptyColorPalette.filter((cp) => !!cp);
    const textStylesJSON = this.textStylesToJSON();

    return {
      name,
      fetchUrl,
      textColorDark,
      textColorLight,
      colorPalette: colorPaletteJSON,
      colorPresets: colorPresetsJSON,
      logos: logosJSON,
      textStyles: textStylesJSON
    };
  }

  private textStylesToJSON(): Record<string, object> {
    const json: Record<string, object> = {};

    for (const key in this.textStyles) {
      const object = this.textStyles[key]?.toJSON();

      if (object) {
        json[key] = object;
      }
    }

    return json;
  }

  /**
   * Remove duplicate color presets from a brand style
   * @returns true if duplicates were removed
   */
  public removeDuplicateColorPresets(): boolean {
    const identifiers: string[] = [];

    let removed = false;

    this.colorPresets = this.colorPresets.filter((cp) => {
      const identifier = cp.toString();

      if (identifiers.includes(identifier)) {
        removed = true;
        return false;
      } else {
        identifiers.push(identifier);
        return true;
      }
    });

    return removed;
  }

  public async saveLogoUserAssets(owner: object): Promise<void> {
    await Promise.all(
      this.logos.map(async (logo) => {
        await logo.transferToBiteableCDN(owner);
        await logo.saveUserAsset();
      })
    );
  }

  static fromBrandQuery(brandQuery: BrandQuery): BrandStyleConfig {
    const {
      name,
      domain,
      colorHexValues = [],
      mainFont = undefined,
      isMainFontGoogleFont = false,
      logoImages = []
    } = brandQuery;

    const config = new BrandStyleConfig({
      name: name ?? "Your brand",
      fetchUrl: domain
    });

    config.assetsLoaded = defer();

    const colors = dedup(formatColors([...colorHexValues, WHITE_COLOR]));
    const hasColors = !!colors.length;

    if (hasColors) {
      config.colorPalette = [...colors];
    }

    const logos = logoImages.map(({ src, format }) => {
      const filename = new URL(src).pathname.split("/").pop() ?? `logo.${format}`;

      const userAsset = new UserAsset({
        name: filename,
        filestackUrl: src,
        mimeType: `image/${format}`,
        assetType: UserAssetTypes.LOGO,
        hasAudio: false
      });

      return new BrandLogo(userAsset);
    });

    config.addLogo(...logos);

    config.assetsLoaded?.resolve(logos);

    if (mainFont && isMainFontGoogleFont) {
      for (const key in config.textStyles) {
        const textStyle = config.textStyles[key];

        if (textStyle) {
          textStyle._fontFamily = mainFont.name;
          textStyle.provider = mainFont.origin;
        }
      }
    }

    return config;
  }

  clone(): BrandStyleConfig {
    const { colorPalette, logos, textStyles, textColorLight, textColorDark, fetchUrl, name, colorPresets } = this;

    const clone = new BrandStyleConfig({
      colorPalette,
      logos,
      textStyles,
      textColorLight,
      textColorDark,
      fetchUrl,
      name,
      colorPresets
    });

    return clone;
  }
}
