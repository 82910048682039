import Evented from "@ember/object/evented";
import { next } from "@ember/runloop";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import config from "client/config/environment";

export enum MouseEventButtons {
  PRIMARY = 0,
  AUXILIARY = 1,
  SECONDARY = 2
}

const DRAG_DELTA = 3; // The amount of dragging required (in pixels) to trigger a drag.

export enum MouseEvents {
  DRAG_START = "DRAG_START",
  DRAG = "DRAG",
  DRAG_END = "DRAG_END",
  CLICK = "CLICK"
}

export default class MouseService extends Service.extend(Evented) {
  @tracked
  isDown = false;

  @tracked
  mouseDownTarget?: HTMLElement;

  @tracked
  mouseDownX?: number;

  @tracked
  mouseDownY?: number;

  @tracked
  mouseX?: number;

  @tracked
  mouseY?: number;

  @tracked
  currentEvent?: MouseEvent;

  private _isDragging = false;

  get isDragging(): boolean {
    return this._isDragging;
  }

  set isDragging(isDragging: boolean) {
    this._isDragging = isDragging;

    if (isDragging) {
      document.body.dataset["dragging"] = "dragging";
    } else {
      delete document.body.dataset["dragging"];
    }
  }

  set resizeDirection(direction: string | undefined) {
    if (direction) {
      document.body.dataset["resizing"] = direction;
    } else {
      delete document.body.dataset["resizing"];
    }
  }

  get mouseDownDistanceX(): number {
    if (this.mouseDownX && this.mouseX) {
      return this.mouseX - this.mouseDownX;
    }

    return 0;
  }

  get mouseDownDistanceY(): number {
    if (this.mouseDownY && this.mouseY) {
      return this.mouseY - this.mouseDownY;
    }

    return 0;
  }

  initialize(): void {
    // Don't bind the mouse events in test mode as it can break tests
    if (config.environment !== "test") {
      this.onMouseDown = this.onMouseDown.bind(this);
      this.onMouseUp = this.onMouseUp.bind(this);
      this.onMouseMove = this.onMouseMove.bind(this);
      this.bindMouseEvents();
    }
  }

  public publish(eventName: MouseEvents, ev: MouseEvent): void {
    this.trigger(eventName, ev);
  }

  public subscribe(eventName: MouseEvents, handler: (ev: MouseEvent) => void): void {
    this.on(eventName, handler);
  }

  public unsubscribe(eventName: MouseEvents, handler: (ev: MouseEvent) => void): void {
    this.off(eventName, handler);
  }

  private bindMouseEvents(): void {
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mouseup", this.onMouseUp);
  }

  public onMouseDown({ button, target, pageX, pageY }: MouseEvent): void {
    if (button !== MouseEventButtons.PRIMARY || !target) {
      return;
    }

    this.addGlobalEvents();

    Object.assign(this, {
      isDown: true,
      mouseDownTarget: target as HTMLElement,
      mouseDownX: pageX,
      mouseDownY: pageY
    });
  }

  private publishEvent(ev: MouseEvent): void {
    if (this.isDragging && this.currentEvent === ev) {
      this.publish(MouseEvents.DRAG, ev);
      next(this, () => this.publishEvent(ev));
    }
  }

  private onMouseMove(ev: MouseEvent): void {
    const { mouseDownX, mouseDownY, isDown } = this;
    const { pageX, pageY } = ev;

    Object.assign(this, {
      mouseX: pageX,
      mouseY: pageY
    });

    if (this.isDragging) {
      this.currentEvent = ev;
      this.publishEvent(ev);
      return;
    }

    if (!isDown || !mouseDownX || !mouseDownY) {
      return;
    }

    const dx = Math.abs(mouseDownX - ev.pageX);
    const dy = Math.abs(mouseDownY - ev.pageY);

    const hasBeenDragged = Math.sqrt(dx ** 2 + dy ** 2) > DRAG_DELTA;

    if (hasBeenDragged) {
      this.isDragging = true;
      this.publish(MouseEvents.DRAG_START, ev);
    }
  }

  public onMouseUp(ev: MouseEvent): void {
    if (ev.button !== MouseEventButtons.PRIMARY) {
      return;
    }

    if (this.isDragging) {
      this.publish(MouseEvents.DRAG_END, ev);
    } else {
      this.publish(MouseEvents.CLICK, ev);
    }

    this.removeGlobalEvents();

    Object.assign(this, {
      mouseDownTarget: undefined,
      isDown: false,
      isDragging: false
    });
  }

  private addGlobalEvents(): void {
    document.addEventListener("mousemove", this.onMouseMove);
    document.querySelectorAll("iframe").forEach((iframe) => {
      iframe.style.pointerEvents = "none";
    });
  }

  private removeGlobalEvents(): void {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.querySelectorAll("iframe").forEach((iframe) => {
      iframe.style.pointerEvents = "auto";
    });
  }
}
