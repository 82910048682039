import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type ColorPreset from "client/lib/brand/color-preset";
import getStyleNamespace from "client/lib/get-style-namespace";

const CONTENT: Array<[string, string, string]> = [
  ["Whoa", "This belongs in the Louvre", "shapes"],
  ["A masterpiece", "You’ve outdone yourself", "cube"],
  ["Hello, Picasso", "This is art", "pie-chart"],
  ["Great choice", "One of our favorites", "squares"],
  ["Holy guacamole", "This is a great look", "target"],
  ["Color me impressed", "...get it?", "rating"]
];

interface BrandColorPresetArgs {
  colorPreset: ColorPreset;
  brandConfig: BrandStyleConfig;
  shuffleDisabled: boolean;
  onRemove(): unknown;
  onChange(): unknown;
  onShuffle(): unknown;
  index?: number;
}

export type ColorTypes = "primary" | "background" | "secondary";

export default class BrandColorPresetComponent extends Component<BrandColorPresetArgs> {
  @tracked
  editing = false;

  styleNamespace = getStyleNamespace("brand/color-preset");

  get index(): number {
    return this.args.index ?? 0;
  }

  get content(): { heading: string; subheading: string; symbol: string } {
    const [heading, subheading, symbol] = CONTENT[this.index] as [string, string, string];

    return {
      heading,
      subheading,
      symbol
    };
  }

  get colorPreset(): ColorPreset {
    return this.args.colorPreset;
  }

  get controlStyles(): SafeString {
    return htmlSafe(`color: ${this.colorPreset.text}`);
  }

  get brandColors(): Array<string> {
    return this.args.brandConfig.nonEmptyColorPalette;
  }

  @action
  toggleEditing(): void {
    this.editing = !this.editing;
  }

  @action
  hideEditing(): void {
    this.editing = false;
  }

  @action
  updateColor(colorType: ColorTypes, color: string): void {
    void this.colorPreset.updateColor(colorType, color);

    if (this.colorPreset.valid) {
      this.args.onChange?.();
    }
  }
}
