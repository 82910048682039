import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { Caption, Element } from "client/lib/editor-domain-model";

export default class CopyPasteService extends Service {
  @tracked
  private _copiedObject?: Element;

  @tracked
  private _sourceCaption?: Caption;

  @tracked
  private _allowCopyPaste = true;

  get copiedObject(): Element | undefined {
    return this._copiedObject;
  }

  get sourceCaption(): Caption | undefined {
    return this._sourceCaption;
  }

  get allowCopyPaste(): boolean {
    return this._allowCopyPaste;
  }

  copy(copiedObject: Element, sourceCaption?: Caption): void {
    this._copiedObject = copiedObject;
    this._sourceCaption = sourceCaption;
  }

  freezeCopyPaste(): void {
    this._allowCopyPaste = false;
  }

  unfreezeCopyPaste(): void {
    this._allowCopyPaste = true;
  }
}
