import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { DragHelper } from "client/lib/drag-helper";
import type { EventRegister, FontInfo, Scene, Timeline } from "client/lib/editor-domain-model";
import { SceneOrderMutation } from "client/lib/editor-domain-model";
import { Follower } from "client/lib/follower";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";
import type ScriptWorkstationService from "client/services/script-workstation";

// Starting late prevents a wobble which in appears with everything moving, for
// example:
//   - Select scene 1
//   - Click on second caption of scene 2

const ANIMATION_DURATION_MS = 250;

interface Args {
  eventRegister: EventRegister;
  timeline: Timeline;
  scene: Scene;
  fonts: FontInfo[];
  isAddRouteActive?: boolean;
}

export default class ScriptWorkstationSceneComponent extends Component<Args> {
  @service
  declare scriptWorkstation: ScriptWorkstationService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @tracked
  isHover = false;

  @tracked
  _element?: HTMLElement;

  @tracked
  thumbnail?: HTMLElement;

  @tracked
  menu?: HTMLElement;

  @tracked
  follower = new Follower();

  @tracked
  transitionStart = new Date();

  styleNamespace = getStyleNamespace("script-workstation/scene");

  get multiCaptionLineStyle(): SafeString {
    return htmlSafe(`grid-row-end: span ${this.args.scene.captions.length}`);
  }

  drag = new DragHelper(
    async (transaction, change): Promise<void> =>
      this.args.eventRegister.appendTransaction(transaction, async () => {
        const order = this.args.timeline.sceneOrder(this.args.scene) + (change === "before" ? -1 : +1);
        if (order >= 0 && order < this.args.timeline.scenes.length) {
          this.args.eventRegister.fire(new SceneOrderMutation(this.args.timeline, this.args.scene, order));
          await this.args.eventRegister.facade.saveSceneOrder(this.args.timeline);
        }
      })
  );

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this._element = element;
    this.drag.element = element;
    if (this.isActive) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    this.didUpdateCaptionElement();
  }

  @action
  didInsertMenu(menu: HTMLElement): void {
    this.menu = menu;
    this.didUpdateCaptionElement();
  }

  @action
  didInsertThumbnail(thumbnail: HTMLElement): void {
    this.thumbnail = thumbnail;
    this.didUpdateCaptionElement();
  }

  @action
  didUpdateIsActive(element: HTMLElement): void {
    if (this.isActive) {
      element.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }

  @action
  async onClick(): Promise<void> {
    if (this.args.scene.captions[0]) {
      this.advancedEditor.setScriptWorkstationElement(this.args.scene.captions[0]);
      await this.advancedEditor.transitionToCaption(this.args.scene.captions[0], this.args.timeline);
    } else {
      this.advancedEditor.setScriptWorkstationElement(this.args.scene);
      await this.advancedEditor.transitionToScene(this.args.scene, this.args.timeline);
    }
  }

  @action
  onMouseEnter(): void {
    this.isHover = true;
  }

  @action
  onMouseLeave(): void {
    this.isHover = false;
  }

  @action
  dragStart(event: DragEvent): void {
    this.drag.onDragStart(event);
  }

  @action
  async dragEnd(): Promise<void> {
    this.drag.onDragEnd();
    this.advancedEditor.setScriptWorkstationElement(this.args.scene);
    await this.advancedEditor.transitionToScene(this.args.scene, this.args.timeline);
  }

  @action
  didUpdateCaptionElement(): void {
    this.follower.disconnect();

    this.follower = new Follower(this.didUpdateTop, this._element, this.thumbnail, this.captionElement);
    this.transitionStart = new Date();
  }

  get captionElement(): HTMLElement | undefined {
    let caption = this.advancedEditor.caption;
    if (!caption || !this.args.scene.captions.includes(caption)) {
      caption = this.args.scene.captions[0];
    }
    return this._element?.querySelector(`[caption-component-id='${caption?.id ?? 0}']`) ?? undefined;
  }

  @action
  didUpdateTop(): void {
    requestAnimationFrame(() => {
      if (!this.menu || !this.thumbnail || isNaN(this.follower.top)) {
        return;
      }

      // We're tracking a moving target so our transition time needs to keep
      // getting shorter. Note that this transition is deliberately a little
      // slower than the caption expansion animation.
      const elapsed = new Date().getTime() - this.transitionStart.getTime();
      const style = {
        transform: `translate(0, ${this.follower.top ?? 0}px)`,
        transition: `transform ${ANIMATION_DURATION_MS - elapsed}ms`
      };
      Object.assign(this.thumbnail.style, style);
      Object.assign(this.menu.style, style);
    });
  }

  get isActive(): boolean {
    return this.advancedEditor.scene === this.args.scene;
  }

  get multiCaption(): boolean {
    return this.args.scene.captions.length > 1;
  }
}
