import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type AuthService from "client/services/auth";

interface ApiError {
  status: number;
  detail: string;
}

export default class ErrorController extends Controller {
  @service
  declare auth: AuthService;

  get error(): ApiError | undefined {
    return this.model?.errors?.[0] ?? undefined;
  }

  get errorStatus(): number | undefined {
    return this.error?.status;
  }

  get notFound(): boolean {
    return this.errorStatus === 404;
  }

  get unauthorized(): boolean {
    return this.errorStatus === 401 && this.error?.detail === "Bad authentication";
  }

  get sessionSuperseded(): boolean {
    return this.errorStatus === 401 && this.error?.detail === "Session superseded";
  }

  get forbidden(): boolean {
    return this.errorStatus === 403;
  }

  get serverError(): boolean {
    return !!this.errorStatus && this.errorStatus >= 500 && this.errorStatus <= 599;
  }

  get showErrorCode(): boolean {
    return !!this.errorStatus && !this.sessionSuperseded;
  }

  @action
  async login(): Promise<void> {
    void this.auth.logout();
  }
}
