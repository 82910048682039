import type { Scene } from "../../scene";
import type { Timeline, TimelineFacade } from "../../timeline";
import { VanillaMutation } from "./vanilla-mutation";

export class AddSceneMutation extends VanillaMutation<Timeline> {
  private scene?: Scene;

  constructor(public timeline: Timeline, public duration: number, private order?: number) {
    super(timeline);
  }

  async prepare(facade: TimelineFacade): Promise<void> {
    this.scene = await facade.newScene(this.duration);
  }

  apply(timeline: Timeline): Scene | void {
    if (this.scene) {
      if (this.order === undefined) {
        timeline._addScene(this.scene);
      } else {
        timeline._insertScene(this.scene, this.order);
      }
    }
    return this.scene;
  }
}
