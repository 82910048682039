import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import BaseParentPanel from "../base-parent/component";

export default class RecordingsPanel extends BaseParentPanel {
  @tracked
  currentRecordProject?: RecordProject;

  @action
  exitRequestResponses(): void {
    this.currentRecordProject = undefined;
    this.showPanel("recordings.requests");
  }

  @action
  enterRequestResponses(recordProject: RecordProject): void {
    this.currentRecordProject = recordProject;
    this.showPanel("recordings.request");
  }

  get requestResponsesView(): boolean {
    return !!this.currentRecordProject;
  }

  get myRecordingsView(): boolean {
    return this.childName === "recordings";
  }

  @action
  onSelectPanel(panel: string): void {
    this.showPanel(panel);
  }
}
