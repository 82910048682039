import type { EventRegister } from "./event-register";
import { ExplicitTransaction } from "./mutations/explicit-transaction";

export class CoalescingTransactionManager {
  private currentTransaction = new ManagedTransaction();

  constructor(private eventRegister: EventRegister) {}

  new(): void {
    this.currentTransaction = new ManagedTransaction();
  }

  async transaction<T>(f: () => Promise<T>): Promise<T> {
    if (this.currentTransaction.tainted) {
      this.new();
    }
    return await this.eventRegister.appendTransaction(this.currentTransaction, f);
  }
}

class ManagedTransaction extends ExplicitTransaction {
  public tainted = false;

  async revert(): Promise<void> {
    this.tainted = true;
    await super.revert();
  }
}
