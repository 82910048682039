import { service } from "@ember/service";
import Model from "@ember-data/model";
import type TrackingEvents from "client/events";
import type { EventRegister, Media, Scene, Timeline } from "client/lib/editor-domain-model";
import type TrackingService from "client/services/tracking";

export default abstract class SelectableAsset extends Model {
  @service
  declare tracking: TrackingService;

  abstract get thumbImageUrl(): string | undefined;
  abstract get thumbVideoUrl(): string | undefined;
  abstract get trackUsageEvent(): TrackingEvents | undefined;

  public thumbnailImageWidth?: number;
  public thumbnailImageHeight?: number;

  protected _thumbAspectRatio = "16:9";

  get thumbAspectRatio(): string {
    return this._thumbAspectRatio;
  }

  set thumbAspectRatio(aspectRatio: string) {
    this._thumbAspectRatio = aspectRatio;
  }

  trackedSearchQuery?: string;

  get trackUsageData(): Object {
    return {};
  }

  get isBrandableTemplate(): boolean {
    return false;
  }

  get published(): boolean {
    return true;
  }

  public async trackAddToScene(timeline: Timeline, scene: Scene): Promise<void> {
    await this.trackAddToTimeline(timeline, { sceneId: scene.id });
  }

  public async trackAddToTimeline(timeline: Timeline, params = {}): Promise<void> {
    if (this.trackUsageEvent) {
      const searchParam = this.trackedSearchQuery ? { searchQuery: this.trackedSearchQuery } : {};
      const analytics = { projectId: timeline.id, ...searchParam, ...this.trackUsageData, ...params };
      await this.tracking.sendAnalytics(this.trackUsageEvent, analytics);
    }
  }

  abstract addToTimeline(
    timeline: Timeline,
    eventRegister: EventRegister,
    order?: number,
    duration?: number
  ): Promise<Scene>;

  abstract addToMedia(scene: Scene, media: Media, eventRegister: EventRegister): Promise<void>;
}
