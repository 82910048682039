import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type RouteInfo from "@ember/routing/-private/route-info";
import type RouterService from "@ember/routing/router-service";
import { cancel, later } from "@ember/runloop";
import type { EmberRunTimer } from "@ember/runloop/types";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { SceneTransition, AudioClipCategory, Assets, AssetSizes } from "renderer-engine";
import { getSceneHalfwayPoint, toInlineStyle } from "client/components/project-timeline/component";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import { DEFAULT_COLOR_PRESET } from "client/lib/brand-applier";
import type {
  Timeline,
  AudioClip,
  Scene,
  StrictMutation,
  Caption,
  TimelineFactory
} from "client/lib/editor-domain-model";
import {
  DeleteSceneMutation,
  DuplicateSceneMutation,
  SceneColorPresetMutation,
  SceneDurationMutationFactory,
  SceneOrderMutation,
  VideoClip
} from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type { TimelineDimensionsArgs } from "client/lib/timeline/timeline-dimensions";
import TimelineDimensions, { DEFAULT_PIXELS_PER_SECOND } from "client/lib/timeline/timeline-dimensions";
import { transaction } from "client/lib/transaction";
import { getVideoDuration } from "client/lib/video";
import type Favorable from "client/models/favorable";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type SelectableAsset from "client/models/selectable-asset";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AuthService from "client/services/auth";
import type ConfirmService from "client/services/confirm";
import type NotificationsService from "client/services/notifications";
import type PlaybackService from "client/services/playback";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ProjectScenesService from "client/services/project-scenes";
import type ScriptWorkstationService from "client/services/script-workstation";
import type TimelineService from "client/services/timeline";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export interface ProjectTimelineExpandedArgs {
  timeline: Timeline;
  timelineFactory: TimelineFactory;
  eventRegister: DomainModel.EventRegister;
  saveScene(scene: Scene): Promise<void>;
  saveSceneOrder(): Promise<void>;
  selectedSceneId?: string;
  selectedCaptionId?: string;
  project?: Project;
}

const getNeighbouringScenes = (scene: Scene, timeline: Timeline): [prev?: string, next?: string] => {
  const scenes = Array.from(timeline.scenes);
  const order = scenes.indexOf(scene);

  return [scenes[order - 1]?.id, scenes[order + 1]?.id];
};

const DEFAULT_TIMELINE_SEEK_PADDING_S = 10;
const MAX_DURATION_THRESHOLD = 3;

export default class ProjectTimelineExpandedComponent extends Component<ProjectTimelineExpandedArgs> {
  styleNamespace = getStyleNamespace("project-timeline/expanded");

  @service
  declare router: RouterService;

  @service("timeline")
  declare timelineState: TimelineService;

  @service
  declare playback: PlaybackService;

  @service
  declare store: Store;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare notifications: NotificationsService;

  @service
  declare confirm: ConfirmService;

  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare auth: AuthService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare tracking: TrackingService;

  @service
  private declare scriptWorkstation: ScriptWorkstationService;

  @tracked
  hasScrollLeft = false;

  @tracked
  hasScrollRight = false;

  @tracked
  pixelsPerSecond = DEFAULT_PIXELS_PER_SECOND;

  @tracked
  closestDropSceneIndex = -1;

  @tracked
  contentDragging = false;

  private dropTargetElement?: Element;
  private dragOutTimer?: EmberRunTimer;
  private dragDropping = false;

  timelinePixelWidth?: number;

  existingSceneIds: string[] = [];

  constructor(owner: object, args: ProjectTimelineExpandedArgs) {
    super(owner, args);

    this.pixelsPerSecond = this.timelineState.pixelsPerSecond ?? DEFAULT_PIXELS_PER_SECOND;
    this.initialiseExistingScenes();

    this.timelineEvents.on(TimelineEvents.CONTENT_DRAG_START, this.contentDragStart);
    this.timelineEvents.on(TimelineEvents.CONTENT_DRAG_STOP, this.contentDragStop);
  }

  willDestroy(): void {
    super.willDestroy();
    this.timelineState.pixelsPerSecond = this.pixelsPerSecond;
    this.timelineEvents.off(TimelineEvents.CONTENT_DRAG_START, this.contentDragStart);
    this.timelineEvents.off(TimelineEvents.CONTENT_DRAG_STOP, this.contentDragStop);
  }

  @action
  didUpdateTimeline(): void {
    this.initialiseExistingScenes();
  }

  @action
  initialiseExistingScenes(): void {
    const { scenes } = this.args.timeline;

    this.existingSceneIds = scenes.map(({ id }) => id);
  }

  get isProjectEmpty(): boolean {
    return !this.args.timeline.scenes.length;
  }

  get audioClip(): AudioClip | undefined {
    return this.args.timeline.audioClips.find((audioClip) => audioClip.category === AudioClipCategory.MUSIC);
  }

  get soundtrackIsPresent(): boolean {
    return !!this.audioClip?.asset;
  }

  @action
  handleScroll(element: HTMLElement): void {
    const { scrollWidth: contentWidth, clientWidth: width, scrollLeft } = element;

    this.timelinePixelWidth = width;

    this.hasScrollLeft = !!element.scrollLeft;
    this.hasScrollRight = contentWidth > width + scrollLeft;
  }

  @action
  handleScrollEvent({ target }: Event): void {
    this.handleScroll(target as HTMLElement);
  }

  get timelineDimensions(): TimelineDimensions {
    const args: TimelineDimensionsArgs = {
      duration: this.args.timeline.duration,
      minDuration: this.isAddRouteActive ? 60 : undefined,
      padding: DEFAULT_TIMELINE_SEEK_PADDING_S,
      pixelsPerSecond: this.isAddRouteActive ? undefined : this.pixelsPerSecond,
      width: this.timelinePixelWidth || window.innerWidth
    };

    return new TimelineDimensions(args);
  }

  get seekDuration(): number {
    return this.timelineDimensions.duration;
  }

  @action
  getScenePresenter(scene: Scene): any {
    const { timeline } = this.args;
    const projectScenes = this.args.project?.hasMany("projectScenes").value();
    const projectScene = projectScenes!.find((ps) => ps.id === scene.id);
    const stockId = projectScene?.shutterstockId ?? "";
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const contextMenuDeleteSceneText = this.getContextMenuDeleteSceneText(projectScene);

    return {
      scene,
      order: timeline.sceneOrder(scene),
      offset: timeline.sceneStartTime(scene),
      id: scene.id,
      isNew: this.isNewScene(scene),
      isTemplate: projectScene?.template ?? false,
      isPublished: projectScene?.published ?? false,
      title: projectScene?.title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      licensed: !!this.args.project?.shutterstockLicensed(stockId),
      showCostInfo: !!stockId,
      contextMenuDeleteSceneText
    };
  }

  @action
  mutate<T>(mutation: StrictMutation<T>): T | void {
    return this.args.eventRegister.fire<T>(mutation);
  }

  @action
  @transaction
  async reorderScene(scene: Scene, newStartTime: number): Promise<void> {
    const { timeline } = this.args;
    const currentOrder = timeline.sceneOrder(scene);
    const newEndTime = newStartTime + scene.duration;

    if (currentOrder > 0 && newStartTime < getSceneHalfwayPoint(timeline, timeline.getScene(currentOrder - 1))) {
      await this.mutate(new DomainModel.SceneOrderMutation(timeline, scene, currentOrder - 1));
    } else if (
      currentOrder < timeline.sceneCount - 1 &&
      newEndTime > getSceneHalfwayPoint(timeline, timeline.getScene(currentOrder + 1))
    ) {
      await this.mutate(new DomainModel.SceneOrderMutation(timeline, scene, currentOrder + 1));
    }
  }

  @action
  @transaction
  async deleteScene(scene: Scene): Promise<void> {
    const { timeline } = this.args;
    const projectScene = await this.store.findRecord("projectScene", scene.id);

    if (!projectScene) {
      return;
    }

    const wasSelected = scene === this.advancedEditor.scene;

    let prevSceneId, nextSceneId;

    if (wasSelected) {
      this.advancedEditor.unsetScene();
      [prevSceneId, nextSceneId] = getNeighbouringScenes(scene, timeline);
    }

    this.mutate(new DeleteSceneMutation(timeline, scene));
    await this.saveOrder();

    if (wasSelected) {
      if (prevSceneId) {
        void this.router.transitionTo("authenticated.project.scene.background", prevSceneId);
      } else if (nextSceneId) {
        void this.router.transitionTo("authenticated.project.scene.background", nextSceneId);
      } else {
        void this.router.transitionTo("authenticated.project.index");
      }
    }
  }

  @action
  @transaction
  async duplicateScene(scene: Scene): Promise<void> {
    const { timeline } = this.args;

    const mutation = new DuplicateSceneMutation(timeline, scene.id);
    await mutation.prepare(this.args.eventRegister.facade);
    const newScene = this.mutate(mutation) as Scene;

    if (newScene) {
      // Move new scene to after the current
      this.mutate(new SceneOrderMutation(timeline, newScene, timeline.sceneOrder(scene) + 1));
      await this.saveOrder();

      await this.router.transitionTo("authenticated.project.scene.background", newScene.id);
    } else {
      this.notifications.error("There was a problem duplicating the scene");
    }
  }

  @action
  setDropTargetElement(element: HTMLElement): void {
    const e = Array.from(element.children)[0];
    if (e) {
      this.dropTargetElement = e;
    }
  }

  @action
  async contentDraggedOnto(content: SelectableAsset, { event }: { event: DragEvent }): Promise<void> {
    this.dragDropping = true;

    if (this.dragOutTimer) {
      cancel(this.dragOutTimer);
    }

    // update the drop position to the latest
    this.contentDraggedOver(event);

    try {
      const { timeline, eventRegister } = this.args;
      const scenes = Array.from(timeline.scenes);

      let afterScene, beforeScene;
      if (this.closestDropSceneIndex >= 1) {
        afterScene = scenes[this.closestDropSceneIndex - 1];
      } else {
        beforeScene = scenes[0];
      }

      const modifier = new SceneAssetModifier(
        getOwner(this)!,
        timeline,
        eventRegister,
        undefined,
        afterScene,
        beforeScene
      );
      await modifier.applyAsset(content);
    } finally {
      this.closestDropSceneIndex = -1;
      this.dragDropping = false;
    }
  }

  @action
  contentDraggedOver(event: DragEvent): void {
    if (this.dragOutTimer) {
      cancel(this.dragOutTimer);
    }

    const mouseX = event.clientX;
    const scenesRects = Array.from(this.dropTargetElement!.children).map((child) => child.getBoundingClientRect());
    if (scenesRects.length > 0) {
      const scenesX = scenesRects.map((rect) => rect.x);
      const last = scenesRects[scenesRects.length - 1]!;
      scenesX.push(last.x + last.width);

      const closestX = scenesX.reduce(function (prev, curr) {
        return Math.abs(curr - mouseX) < Math.abs(prev - mouseX) ? curr : prev;
      });

      this.closestDropSceneIndex = scenesX.indexOf(closestX);
    } else {
      this.closestDropSceneIndex = -1;
    }
  }

  @action
  contentDraggedOut(): void {
    if (this.dragDropping) {
      return;
    }

    this.dragOutTimer = later(
      this,
      () => {
        this.closestDropSceneIndex = -1;
      },
      200
    );
  }

  @action
  contentDragStart(): void {
    this.contentDragging = true;
  }

  @action
  contentDragStop(): void {
    this.contentDragging = false;
  }

  @action
  async fitToClip(scene: Scene): Promise<void> {
    if (scene.background.asset instanceof VideoClip) {
      const asset = scene.background.asset.asset;
      const videoUrl = await Assets.getVideoAssetUrl(asset, AssetSizes.PREVIEW);
      let duration = videoUrl ? await getVideoDuration(videoUrl) : undefined;

      if (duration) {
        duration = this.roundDownToIncrement(duration, 0.5);
        duration = this.subtractNextSceneTransition(duration, scene);

        const factory = new SceneDurationMutationFactory(scene);
        const mutation = factory.createMutation(duration);
        this.args.eventRegister.fire(mutation);
        await this.args.saveScene(scene);
      }
    }
  }

  @action
  async toggleSceneTemplate(scene: Scene): Promise<void> {
    const id = scene.id;
    const projectScene = await this.store.findRecord("projectScene", id);

    if (projectScene.template) {
      const confirmed = await this.confirm.open({
        title: "Please confirm",
        message: "Are you sure you want to remove this scene as a template?",
        confirmLabel: "Remove"
      });

      if (!confirmed) {
        return;
      }
    } else {
      await this.saveColorPreset(scene);
    }

    void this.projectScenes.setProjectSceneAsTemplate(projectScene, !projectScene.template);
  }

  @action
  async saveColorPreset(scene: Scene, notifyUser = false): Promise<void> {
    this.args.eventRegister.fire(new SceneColorPresetMutation(scene, DEFAULT_COLOR_PRESET));
    scene.computeColorBrandKey();
    await this.args.saveScene(scene);
    if (notifyUser) {
      this.notifications.success("Color settings saved");
    }
  }

  @action
  async saveCaption(caption: Caption): Promise<void> {
    await this.args.saveScene(caption.scene);
  }

  @action
  async saveOrder(): Promise<void> {
    return this.args.saveSceneOrder();
  }

  @action
  async templateEditorMagicAction(scene: Scene): Promise<void> {
    await this.saveColorPreset(scene, true);
    const projectScene = this.args.project?.projectScenes?.find((ps) => ps.id === scene.id);
    if (projectScene) {
      // Make the scene a template and make sure it is in the correct stack to get the server overrides to kick
      // in during serialization
      await this.projectScenes.setProjectSceneAsTemplate(projectScene, true);
      if (!projectScene.stacks) {
        projectScene.stacks = new Array<string>();
      }

      let addedStack = false;
      if (projectScene.stacks.indexOf("reference-v2-brand-scenes") === -1) {
        projectScene.stacks.push("reference-v2-brand-scenes");
        addedStack = true;
      }
      await projectScene.save();

      // Reload zymbols to get the color brand key override from the server
      for (const zymbolGroup of projectScene.zymbolGroups) {
        const zymbols = await zymbolGroup.zymbols;
        // @ts-expect-error
        for (const zymbol of zymbols) {
          await zymbol.reload();
          await zymbol.save();
        }
      }

      this.notifications.success("Scene updated rolling back template status");
      await this.projectScenes.setProjectSceneAsTemplate(projectScene, false);
      if (addedStack) {
        projectScene.stacks.splice(projectScene.stacks.indexOf("reference-v2-brand-scenes"), 1);
      }
      await projectScene.save();

      // Make sure the Ember Data model changes are applied to domain model objects
      await this.args.timelineFactory.fireRebuildTimeline(this.args.timeline);

      this.notifications.success("Ok that should be it!");
    }
  }

  @action
  upgradeToTopTierPlan(): void {
    void this.upgrade.selectTopTierPlan({
      context: "project timeline - unlock longer video limit"
    });
  }

  get isAddRouteActive(): boolean {
    return !!this.router.currentRoute?.find((item: RouteInfo) => item.name === "authenticated.project.scenes");
  }

  get maxVideoDuration(): number {
    if (this.auth.currentSubscription?.plan?.isTopTierPlan) {
      return config.maxVideoDuration.teams;
    }
    return config.maxVideoDuration.others;
  }

  get shouldShowMaxDuration(): boolean {
    return this.args.timeline.duration + MAX_DURATION_THRESHOLD >= this.maxVideoDuration;
  }

  get maxDurationStyleAttr(): SafeString {
    return toInlineStyle(this.maxVideoDuration * this.pixelsPerSecond, "left");
  }

  get soundtracks(): DomainModel.AudioClip[] {
    return this.args.timeline.audioClips.filter((ac) => ac.category === AudioClipCategory.MUSIC);
  }

  get voiceovers(): DomainModel.AudioClip[] {
    return this.args.timeline.audioClips.filter((ac) => ac.category === AudioClipCategory.VOICEOVER);
  }

  get endOfVideoStyleAttr(): SafeString {
    if (
      this.voiceovers.length > 0 &&
      this.voiceovers.some((vo) => vo.duration + vo.offset > this.args.timeline.duration) &&
      this.args.timeline.duration < this.maxVideoDuration
    ) {
      return htmlSafe(
        `${toInlineStyle(this.args.timeline.duration * this.pixelsPerSecond, "left")} visibility: visible;`
      );
    }
    return htmlSafe("");
  }

  @action
  async saveAudio(): Promise<void> {
    await this.args.eventRegister.facade.saveAudioClips(this.args.timeline);
    this.timelineEvents.publish(TimelineEvents.AUDIO_CHANGED);
  }

  @action
  setScale(pixelsPerSecond: number): void {
    if (typeof pixelsPerSecond === "undefined" || isNaN(Number(pixelsPerSecond)) || pixelsPerSecond <= 0) {
      return;
    }

    this.pixelsPerSecond = pixelsPerSecond;
  }

  @action
  onAddSceneClicked(): void {
    this.scriptWorkstation.collapse();
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_ADD_SCENE, { projectId: this.args.timeline.id });
  }

  isNewScene({ id }: { id: string }): boolean {
    if (!this.existingSceneIds.includes(id)) {
      this.existingSceneIds.push(id);
      return true;
    }

    return false;
  }

  private roundDownToIncrement(duration: number, increment: number): number {
    const f = Math.floor(duration);
    if (duration - f < increment) {
      return f;
    }
    return f + increment;
  }

  private subtractNextSceneTransition(duration: number, scene: Scene): number {
    const { timeline } = this.args;
    const scenes = Array.from(timeline.scenes);
    const nextScene = scenes[scenes.indexOf(scene) + 1];

    if (nextScene?.transition && nextScene.transition.name !== SceneTransition.NONE) {
      return Math.max(0, duration - 0.5);
    } else {
      return duration;
    }
  }

  private getContextMenuDeleteSceneText(projectScene: ProjectScene | undefined): string {
    const favorableScene = projectScene as ProjectScene & Favorable;
    if (favorableScene?.teamFavorited) {
      return "Delete team scene";
    } else if (favorableScene?.favorited) {
      return "Delete favorite scene";
    }
    return "Delete scene";
  }

  get isSelectedSoundtrack(): boolean {
    return this.router.currentRouteName.startsWith("authenticated.project.scene.soundtrack");
  }

  get isSelectedVoiceover(): boolean {
    return this.router.currentRouteName.startsWith("authenticated.project.scene.voiceover");
  }
}
