import type { Image } from "../../scene/image";
import { TimelineMutation } from "./mutation";

export class ChangeImageUrlMutation extends TimelineMutation {
  grouped = false;

  private originalProperties: Pick<Image, "sourceUrl" | "previewUrl" | "name">;

  constructor(private image: Image, private name?: string, private sourceUrl?: string, private previewUrl?: string) {
    super();
    this.originalProperties = image;
  }

  run(): void {
    this.image._name = this.name;
    this.image._sourceUrl = this.sourceUrl;
    this.image._previewUrl = this.previewUrl;
  }

  revert(): void {
    this.image._name = this.originalProperties.name;
    this.image._sourceUrl = this.originalProperties.sourceUrl;
    this.image._previewUrl = this.originalProperties.previewUrl;
  }
}
