import type { RecordProject } from "@biteable/network-model";
import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  asset: RecordProject;
  selectAsset(asset: RecordProject): unknown;
}

export default class PanelRequestContentItem extends Component<Args> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/recordings/requests/request-content-item");

  @action
  selectAsset(): void {
    this.args.selectAsset(this.args.asset);
  }

  get numberOfResponses(): number {
    const recordProject = this.args.asset;
    if (recordProject.recordProjectPrompts) {
      return recordProject.recordProjectPrompts
        .map(({ recordProjectResponses }) => recordProjectResponses?.length || 0)
        .reduce((a, b) => a + b, 0);
    }

    return 0;
  }
}
