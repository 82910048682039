import type { QueryOptions } from "../../controller";
import ProjectScenesFavoritesBaseController from "../../controller";

export default class ProjectScenesFavoritesMyCurrentController extends ProjectScenesFavoritesBaseController {
  get otherQueryOptions(): QueryOptions {
    return {
      /* eslint-disable camelcase */
      aspect_ratio_id: this.model.project.aspectRatio.id,
      include_no_aspect_ratio: true
      /* eslint-enable camelcase */
    };
  }
}
