import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import cmsUrl from "client/lib/cms-url";
import type AuthService from "client/services/auth";

export default class AppHeaderCmsLink extends Component {
  @service
  declare auth: AuthService;

  get token(): string | undefined {
    return this.auth.authToken;
  }

  get cmsUrlWithToken(): string {
    return cmsUrl(getOwner(this));
  }
}
