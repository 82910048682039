import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PlansService from "client/services/plans";

export default class CustomFontUprade extends Component {
  @service
  private declare plans: PlansService;

  styleNamespace = getStyleNamespace("font-family-dropdown/custom-font-upgrade");

  get planName(): string {
    return this.topTierPlanName ? `a ${this.topTierPlanName} plan` : "our best plan";
  }

  private get topTierPlanName(): string | undefined {
    return this.plans.getTopTierPlan()?.name;
  }
}
