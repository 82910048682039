import Model, { attr } from "@ember-data/model";

export default class VideoAssistant extends Model {
  @attr("string")
  declare rawScript: string;

  @attr("array")
  declare script: string[];

  @attr("array")
  declare mediaKeywords: string[];
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    videoAssistant: VideoAssistant;
  }
}
