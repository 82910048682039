import type { Caption } from "../../scene/caption";
import { TimelineMutation } from "./mutation";

export interface DurationChangeConstraints {
  min?: number;
  max?: number;
}

export enum ResizeHandles {
  START = "start",
  END = "end"
}

export class CaptionDurationChangeMutation extends TimelineMutation {
  private originalDuration: number;
  private originalOffset: number;
  private originalSceneDuration: number;

  constructor(
    public grouped: boolean,
    public caption: Caption,
    public duration: number,
    public handle: ResizeHandles = ResizeHandles.END,
    private sceneConstraints: DurationChangeConstraints = {}
  ) {
    super();

    this.duration = this.round(this.duration);
    this.originalDuration = this.round(caption.duration);
    this.originalOffset = this.round(caption.offset);
    this.originalSceneDuration = this.round(this.caption.scene.duration);
  }

  get minDuration(): number {
    return 0.5;
  }

  get maxDuration(): number {
    return this.nextCaptionStart;
  }

  get minOffset(): number {
    return this.previousCaptionEnd;
  }

  private get nextCaptionStart(): number {
    const captions = this.caption.scene.captions;
    const captionIndex = captions.indexOf(this.caption);
    if (captionIndex < captions.length - 1) {
      return captions[captionIndex + 1]!.offset - this.caption.offset;
    }

    return Infinity;
  }

  private get previousCaptionEnd(): number {
    const captions = this.caption.scene.captions;
    const captionIndex = captions.indexOf(this.caption);
    if (captionIndex > 0) {
      return captions[captionIndex - 1]!.end;
    }

    return 0;
  }

  run(): void {
    const endTogether = this.round(this.caption.end) === this.round(this.caption.scene.duration);

    if (this.handle === ResizeHandles.START) {
      this.caption._setOffset(
        this.clamp(
          this.caption._offset + this.originalDuration - this.duration,
          this.minOffset,
          this.originalOffset + this.originalDuration - this.minDuration // max offset
        )
      );
      const offsetDelta = this.originalOffset - this.caption._offset;

      this.caption._setDuration(this.clamp(this.caption._duration + offsetDelta, this.minDuration, this.maxDuration));
    } else {
      this.caption._setDuration(this.clamp(this.duration, this.minDuration, this.maxDuration));
    }

    if (this.handle === ResizeHandles.END && (endTogether || this.caption.end > this.caption.scene.duration)) {
      this.setSceneDuration(this.caption.end);
    }
  }

  revert(): void {
    this.caption._duration = this.originalDuration;
    this.caption._offset = this.originalOffset;
    this.caption.scene._setDuration(this.originalSceneDuration);
  }

  setSceneDuration(duration: number): void {
    this.caption.scene._setDuration(
      this.round(this.clamp(duration, this.sceneConstraints.min || 0.5, this.sceneConstraints.max || Infinity))
    );
  }
}
