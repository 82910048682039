import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { SubscriptionReactivateModel } from "./route";
import TrackingEvents from "client/events";
import type Subscription from "client/models/subscription";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export default class SubscriptionReactivateController extends Controller<SubscriptionReactivateModel> {
  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @service
  declare upgrade: UpgradeService;

  @tracked
  error?: Error;

  @tracked
  loading = false;

  @tracked
  checkout = false;

  @tracked
  subscription?: Subscription;

  @action
  close(): void {
    void this.router.transitionTo("authenticated.account.subscription");
  }

  @action
  async handleReactivation(): Promise<void> {
    try {
      this.loading = true;
      await this.reactivateEndedSubscription();
    } catch (err) {
      // @ts-expect-error
      const errorMessage = err.errors[0].detail ?? err.message;
      this.error = errorMessage ?? "Dang! Something went wrong. Please contact support.";
    } finally {
      this.loading = false;
    }
  }

  get isSubscribed(): boolean {
    return !!this.model.fullSubscription;
  }

  get price(): string {
    return this.model.plan.formattedMonetaryUnits;
  }

  get userNotAuthorised(): boolean {
    return !this.auth.currentUser?.canManageSubscription;
  }

  private async reactivateEndedSubscription(): Promise<void> {
    const subscriptionReactivation = this.store.createRecord("subscriptionReactivation", {
      planId: this.model.plan.id
    });

    await subscriptionReactivation.save();

    const subscription = await this.store.findRecord(
      "subscription",
      subscriptionReactivation.belongsTo("subscription").id()
    );

    if (subscription) {
      this.subscription = subscription;
      await this.auth.reload();
      this.trackReactivation();
      this.checkout = true;
    }
  }

  private trackReactivation(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_REACTIVATE_ENDED_SUBSCRIPTION, {
      subscriptionId: this.subscription?.id,
      planId: this.model.plan?.id
    });
  }
}
