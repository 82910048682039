import { set } from "@ember/object";
import { service } from "@ember/service";
import type { AsyncBelongsTo } from "@ember-data/model";
import Model, { attr, belongsTo } from "@ember-data/model";
import type Store from "@ember-data/store";
import publicEmailDomain from "client/lib/public-email-domain";
import type Card from "client/models/card";
import type Subscription from "client/models/subscription";
import type Team from "client/models/team";
import type UserTargetingData from "client/models/user-targeting-data";
import type { PermissionName } from "client/services/permissions";
import type { StripeAccount } from "client/services/stripe";

export enum AuthProviders {
  GOOGLE = "google-oauth2",
  AZURE = "azure-ad2-oauth2",
  BITEABLE = "biteable"
}

export default class User extends Model {
  @service
  declare store: Store;

  @attr("date")
  declare addMembersSeenAt: Date;

  @attr("string")
  declare email: string;

  @attr("string", { allowNull: true })
  declare password?: string;

  @attr("string")
  declare fullName: string;

  @attr("boolean")
  declare subscribed: boolean;

  @attr("boolean")
  declare hasPaidSubscription: boolean;

  @attr("array")
  declare namedGroups: string[];

  @attr("date")
  declare createdAt: Date;

  @attr("array")
  declare permissionNames: PermissionName[];

  @attr("string")
  declare stripeAccount: StripeAccount;

  @attr("boolean")
  declare teamOwner: boolean;

  @attr("boolean")
  declare onTeam: boolean;

  @attr("number")
  declare shutterstockCreditsCount: number;

  @attr("number")
  declare usedShutterstockCreditsCount: number;

  @attr("number")
  declare remainingShutterstockCreditsCount: number;

  @attr("date")
  declare shutterstockCreditsExpireAt?: Date;

  @attr("number")
  declare shutterstockPrice: number;

  @attr("string")
  declare shutterstockCurrency: string;

  @attr("boolean")
  declare hasUsedTopTierPlan: boolean;

  @attr("array")
  declare authProviders: AuthProviders[];

  @attr("string")
  declare stripeCustomerId?: string;

  @attr("boolean", { defaultValue: true })
  declare firstViewEmails: boolean;

  @attr("boolean", { defaultValue: true })
  declare viewsUpdateEmails: boolean;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: true, inverse: null })
  declare currentFullSubscription?: Subscription;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: true, inverse: null })
  declare currentSubscription?: Subscription;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: true, inverse: null })
  declare latestTrial?: Subscription;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: true, inverse: null })
  declare latestSubscription?: Subscription;

  @belongsTo("user-targeting-data", { async: true, inverse: "user" })
  declare userTargetingData?: UserTargetingData;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("team", { async: true, inverse: null })
  declare team?: Team;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("card", { async: true, inverse: null })
  declare card?: AsyncBelongsTo<Card>;

  // After a new user is created (at signup) we want to remove the password from that record
  clearUserPassword(): void {
    set(this, "password", undefined);
  }

  public async getTargetingData(): Promise<UserTargetingData | undefined> {
    return await navigator.locks.request(
      "user_targeting_data_mutex",
      async (_lock): Promise<UserTargetingData | undefined> => {
        await this.reload();
        const targetingData = await this.userTargetingData;
        if (targetingData) {
          return targetingData;
        } else {
          return await this.createTargetingData();
        }
      }
    );
  }

  async createTargetingData(): Promise<UserTargetingData | undefined> {
    const data = await this.store.createRecord("userTargetingData", {}).save();
    this.userTargetingData = data;

    return data;
  }

  get canChangePassword(): boolean {
    return this.authProviders.includes(AuthProviders.BITEABLE);
  }

  get canManageTeam(): boolean {
    return this.teamOwner;
  }

  get canManageSubscription(): boolean {
    // If a user is part of a team, only the team owner can upgrade
    return this.onTeam ? this.teamOwner : true;
  }

  get emailDomain(): string {
    return this.email.split("@").pop() as string;
  }

  get hasPublicEmailDomain(): boolean {
    return publicEmailDomain(this.emailDomain);
  }

  get hasCharges(): boolean {
    return !!this.stripeCustomerId;
  }

  // When a new user is created we sometimes set their `fullName` to be their `email`
  get nonEmailFullName(): string | undefined {
    if (this.fullName && this.fullName !== this.email) {
      return this.fullName;
    } else {
      return undefined;
    }
  }

  get firstName(): string | undefined {
    return this.nonEmailFullName?.split(" ").shift();
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    user: User;
  }
}
