import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";

interface Args {
  searchKey: string;
  selectAsset(asset: SelectableAsset): void;
  aspectRatioId: string;
  exitSearch(): void;
}

export default class ScenesSearchPanel extends Component<Args & ProjectContentBarPanelArgs> {
  @tracked
  searchKey = this.args.searchKey;

  @service
  declare store: Store;

  styleNamespace = getStyleNamespace("project-content-bar/panels/scenes/search-panel");

  @action
  search(key: string): void {
    if (key) {
      this.searchKey = key;
    } else {
      this.args.exitSearch();
    }
  }

  get params(): Record<string, string | number | boolean | undefined> {
    return {
      /* eslint-disable camelcase */
      search_query: this.searchKey,
      aspect_ratio: this.args.aspectRatioId,
      per_page: 24
      /* eslint-enable camelcase */
    };
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<ArrayProxy<SelectableAsset>> {
    return await this.store.query("projectScenePreview", params);
  }
}
