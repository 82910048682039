import { action } from "@ember/object";
import Component from "@glimmer/component";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type ShutterstockCredits from "client/models/shutterstock-credits";
import type ShutterstockPurchases from "client/models/shutterstock-purchases";

interface ExportActionsShutterstockActionsArgs {
  failedStockLicensesPurchase: boolean;
  isRendering?: boolean;
  project: Project;
  renderProject: (ctaContext: string) => Promise<void>;
  renderRequired?: boolean;
  renderStarting?: boolean;
  renderSucceeded?: boolean;
  setFailedStockLicensesPurchase: (value: boolean) => void;
  shutterstockPurchases?: ShutterstockPurchases;
  shutterstockCredits: ShutterstockCredits;
}

export default class ExportActionsShutterstockActionsComponent extends Component<ExportActionsShutterstockActionsArgs> {
  styleNamespace = getStyleNamespace("export-actions/shutterstock-actions");

  @action
  async onClick(): Promise<void> {
    if (this.args.renderSucceeded) {
      await this.onUpdate();
    } else if (this.args.isRendering) {
      await this.onRestart();
    } else {
      await this.onBuild();
    }
  }

  get buttonLabel(): string {
    if (this.args.renderSucceeded) {
      return this.generateLabel("Update your video");
    } else if (this.args.isRendering) {
      return this.generateLabel("Restart build");
    } else {
      return this.generateLabel("Build video");
    }
  }

  get showPayPerClip(): boolean {
    return !!this.args.shutterstockPurchases?.paymentRequired;
  }

  private generateLabel(label: string): string {
    const confirmationRequired = this.args.shutterstockCredits.visibleRemainingCreditsAfterPurchase === 0;

    return confirmationRequired ? `Confirm and ${label.toLowerCase()}` : label;
  }

  private async onUpdate(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_UPDATE_YOUR_VIDEO);
  }

  private async onRestart(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_RESTART);
  }

  private async onBuild(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_BUILD_VIDEO);
  }
}
