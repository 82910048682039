import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { AccountSubscriptionCancelRouteModel } from "client/authenticated/account/subscription/cancel/route";
import TrackingEvents from "client/events";
import type Subscription from "client/models/subscription";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export default class AccountSubscriptionCancelConfirmationController extends Controller<AccountSubscriptionCancelRouteModel> {
  @service
  declare router: RouterService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare tracking: TrackingService;

  @tracked
  value?: string;

  @tracked
  cancelling = false;

  get disabled(): boolean {
    return !this.value || this.cancelling;
  }

  @action
  close(): void {
    void this.router.transitionTo("authenticated.account.subscription");
  }

  @action
  async cancelSubscription(): Promise<void> {
    if (this.cancelling) {
      return;
    }

    this.cancelling = true;
    const { fullSubscription } = this.model;
    try {
      await fullSubscription.cancel();
      this.trackCancellation(fullSubscription);
      void this.router.transitionTo("authenticated.account.subscription");
      this.notifications.success("Your subscription has been cancelled");
    } catch (err: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.honeybadger.notify(err);
      this.notifications.error("There was a problem cancelling your subscription. Please contact support.");
    } finally {
      this.cancelling = false;
    }
  }

  public resetTransientState(): void {
    this.value = "";
  }

  private trackCancellation(subscription: Subscription): void {
    const subscriptionId = subscription.id;
    const planId = subscription.planId;
    const reason = this.value;

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CANCEL_SUBSCRIPTION, {
      subscriptionId,
      planId,
      reason
    });
  }
}
