import { getOwner } from "@ember/application";
import Model from "@ember-data/model";
import type FolderService from "client/services/folders";

export enum FolderContainableTypes {
  PROJECT = "Project",
  FOLDER = "Folder",
  USER_ASSET = "UserAsset"
}

interface FolderContainable {
  readonly containableId: string;
  readonly containableType: FolderContainableTypes;
}

abstract class FolderContainable extends Model {}

export default FolderContainable;

export function folderContainable<
  T extends {
    new (...args: any[]): Model & FolderContainable;
  }
>(constructor: T): T {
  return class extends constructor {
    get folders(): FolderService {
      return getOwner(this).lookup("service:folders");
    }
  };
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    folderContainable: FolderContainable;
  }
}
