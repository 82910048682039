import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type AccountRoute from "../route";
import type Discount from "client/models/discount";
import type Subscription from "client/models/subscription";
import type Team from "client/models/team";
import type UpcomingInvoice from "client/models/upcoming-invoice";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

export interface AccountSubscriptionRouteModel {
  fullSubscription?: Subscription;
  fullOrTrialOrLatest?: Subscription;
  subscription?: Subscription;

  user: User;
  team?: Team;
  discount?: Discount;
  upcomingInvoice?: UpcomingInvoice;
}

export default class AccountSubscriptionRoute extends Route<AccountSubscriptionRouteModel> {
  @service
  declare auth: AuthService;

  @service
  declare store: Store;

  async model(): Promise<AccountSubscriptionRouteModel> {
    const user = this.modelFor<AccountRoute>("authenticated.account");

    const {
      currentTeam: team,
      currentFullSubscription: fullSubscription,
      latestSubscription,
      currentFullOrTrialSubscription: currentFullOrTrial,
      currentSubscription: subscription
    } = this.auth;

    const [discount, upcomingInvoice] = await Promise.all([
      subscription?.discount,
      this.getUpcomingInvoice(fullSubscription)
    ]);

    return {
      user,
      team,
      fullSubscription,
      fullOrTrialOrLatest: currentFullOrTrial ?? latestSubscription,
      subscription,
      discount,
      upcomingInvoice
    };
  }

  private async getUpcomingInvoice(subscription: Subscription | undefined): Promise<UpcomingInvoice | undefined> {
    if (subscription) {
      try {
        return await this.store.findRecord("upcomingInvoice", subscription.id);
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  }
}
