import type { TextConfig } from "renderer-engine";
import { convertTextConfigToTextStyles, convertTextZymbolConfigToTextStyles } from "../text/text-styles";
import type { ElementArgs, Text } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { getDefaultRect, Rect } from "client/lib/editor-domain-model";
import type AssetFactory from "client/lib/scene/asset-factory";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";

export default class CaptionFactory {
  constructor(private assetFactory: AssetFactory) {}

  async createCaptions(scene: DomainModel.Scene, zymbolGroups: ZymbolGroup[]): Promise<DomainModel.Caption[]> {
    if (!zymbolGroups) {
      return [];
    }

    return Promise.all(
      zymbolGroups.map(async (zymbolGroup) => {
        return this.createCaption(scene, zymbolGroup);
      })
    );
  }

  async createCaption(scene: DomainModel.Scene, zymbolGroup: ZymbolGroup): Promise<DomainModel.Caption> {
    const { id, startTime, duration, durationMode } = zymbolGroup;
    const texts = (await zymbolGroup.orderedTextZymbols).map(CaptionFactory.zymbolToText);
    const logos = (await zymbolGroup.orderedLogoZymbols).map((z) => this.zymbolToLogo(z));
    return new DomainModel.Caption(id, scene, texts, startTime, duration, logos, durationMode);
  }

  static zymbolToText(zymbol: Zymbol): DomainModel.Text {
    const [textContent, textStyles, textAnimation] = convertTextZymbolConfigToTextStyles(zymbol.cfg);
    const elementArgs = CaptionFactory.zymbolToElement(zymbol);
    const textArgs = { content: textContent, styles: textStyles, animation: textAnimation };
    return new DomainModel.Text({ ...elementArgs, ...textArgs });
  }

  public textConfigToText(elementArgs: ElementArgs, text: TextConfig): DomainModel.Text {
    const [textContent, textStyles, textAnimation] = convertTextConfigToTextStyles(text);
    return new DomainModel.Text({ ...elementArgs, content: textContent, styles: textStyles, animation: textAnimation });
  }

  zymbolToLogo(zymbol: Zymbol): DomainModel.Logo {
    const elementArgs = CaptionFactory.zymbolToElement(zymbol);
    const logoArgs = {
      asset: this.assetFactory.createAssetFromZymbol(zymbol),
      assetOffset: zymbol.assetOffset,
      mediaReplaceable: zymbol.mediaReplaceable
    };
    return new DomainModel.Logo({ ...elementArgs, ...logoArgs });
  }

  static zymbolToElement(zymbol: Zymbol): ElementArgs {
    return {
      id: zymbol.id,
      position: Rect.fromRect(zymbol),
      customTimingOffset: zymbol.customTimingOffset,
      customTimingDuration: zymbol.customTimingDuration,
      layerOrder: zymbol.layerOrder
    };
  }

  static textToElement(text: Text): ElementArgs {
    return {
      id: text.id,
      position: getDefaultRect(),
      customTimingOffset: text.customTimingOffset,
      customTimingDuration: text.customTimingDuration,
      layerOrder: text.layerOrder
    };
  }
}
