import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface PlanSummaryTileArgs {
  plan: Plan;
}

export default class PlanSummaryTile extends Component<PlanSummaryTileArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-summary-tile");

  get monthlyPrice(): string {
    return this.args.plan.monthlyPrice;
  }
}
