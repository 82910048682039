import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { getProgress } from "renderer-engine";
import { MIN_WIDTH_FOR_TRANSITIONS } from "client/components/project-timeline/scene/component";
import type { EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { DEFAULT_PIXELS_PER_SECOND } from "client/lib/timeline/timeline-dimensions";
import type AdvancedEditorService from "client/services/advanced-editor";
import type PlaybackService from "client/services/playback";
import type SmartEditorService from "client/services/smart-editor";
import type SmartPlaybackService from "client/services/smart-playback";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

interface Args {
  eventRegister: EventRegister;
  timeline: Timeline;
  scene: Scene;
  disableSceneSelection?: boolean;
  pixelsPerSecond?: number;
  modalView?: boolean;
}

export default class TrackSceneComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @service
  private declare advancedEditor: AdvancedEditorService;

  @service
  private declare smartEditor: SmartEditorService;

  @service
  declare smartPlayback: SmartPlaybackService;

  @service
  private declare timelineEvents: TimelineEventsService;

  @service
  declare playback: PlaybackService;

  styleNamespace = getStyleNamespace("project-timeline/collapsed/track-scene");

  @action
  async onBackgroundClick(): Promise<void> {
    if (!this.args.disableSceneSelection) {
      const { scene } = this.args;

      this.advancedEditor.setScene(scene);
      this.timelineEvents.publish(TimelineEvents.ACTIVE_SCENE_CHANGED, { scene, animate: true });
      await this.router.transitionTo("authenticated.project.scene", scene.id).followRedirects();
    }
  }

  private get startTime(): number {
    const { scene, timeline } = this.args;

    return timeline.sceneStartTime(scene);
  }

  private get isFirst(): boolean {
    const { scene, timeline } = this.args;

    return scene === timeline.scenes[0];
  }

  private get isLast(): boolean {
    const { scene, timeline } = this.args;

    return scene === timeline.scenes[timeline.scenes.length - 1];
  }

  private get isActive(): boolean {
    return this.smartEditor.isActiveScene(this.args.scene);
  }

  get sceneOrder(): number {
    return this.args.timeline.sceneOrder(this.args.scene);
  }

  get pixelWidth(): number {
    return this.args.scene.duration * (this.args.pixelsPerSecond ?? DEFAULT_PIXELS_PER_SECOND) - 2;
  }

  get showTransitionsMenu(): boolean {
    return (
      !this.args.modalView &&
      this.args.scene === this.advancedEditor.scene &&
      this.sceneOrder > 0 &&
      this.pixelWidth >= MIN_WIDTH_FOR_TRANSITIONS &&
      !this.playback.playing
    );
  }

  get style(): SafeString {
    return htmlSafe(`flex: ${this.args.scene.duration};`);
  }

  get backgroundStyle(): SafeString {
    const borderRadiusLeft = this.isFirst ? "9999px" : 0;
    const borderRadiusRight = this.isLast ? "9999px" : 0;

    return htmlSafe(`border-radius: ${borderRadiusLeft} ${borderRadiusRight} ${borderRadiusRight} ${borderRadiusLeft}`);
  }

  get highlightStyle(): SafeString {
    const progress = getProgress(this.startTime, this.args.scene.duration, this.currentPlayback.currentTime, false);

    if (this.currentPlayback.paused && progress.inRange) {
      return htmlSafe("");
    } else if (progress.inRange) {
      return htmlSafe(`right: ${(1 - progress.progress) * 100}%;`);
    } else {
      return htmlSafe("visibility: hidden;");
    }
  }

  get cursorStyle(): SafeString {
    const progress = getProgress(this.startTime, this.args.scene.duration, this.currentPlayback.currentTime, false);

    if (progress.inRange) {
      return htmlSafe(`right: ${(1 - progress.progress) * 100}%;`);
    } else {
      return htmlSafe("visibility: hidden;");
    }
  }

  private get currentPlayback(): PlaybackService {
    return this.args.modalView ? this.smartPlayback : this.playback;
  }
}
