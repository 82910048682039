import Controller from "@ember/controller";
import { action } from "@ember/object";
import type { UnwrapComputedPropertyGetters } from "@ember/object/-private/types";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type Project from "client/models/project";
import type NotificationsService from "client/services/notifications";
import type ProjectThumbnailGeneratorService from "client/services/project-thumbnail-generator";
import type ProjectsService from "client/services/projects";

export default class ProjectSettingsController extends Controller {
  @service
  declare router: RouterService;

  @service
  declare projectThumbnailGenerator: ProjectThumbnailGeneratorService;

  @service
  declare projects: ProjectsService;

  @service
  declare notifications: NotificationsService;

  @tracked
  saving = false;

  @action
  close(): void {
    void this.router.transitionTo("authenticated.project", this.projectId);
  }

  @action
  async save(project: Project, properties: Pick<UnwrapComputedPropertyGetters<Project>, keyof Project>): Promise<void> {
    if (this.saving) {
      return;
    }

    this.saving = true;

    try {
      const aspectRatioChanged = project.aspectRatio !== properties.aspectRatio;

      if (aspectRatioChanged) {
        const duplicatedProject = await this.duplicateProject(project, properties);
        await this.router.transitionTo("authenticated.project", duplicatedProject.id);
      } else {
        await this.updateProject(project, properties);

        this.close();
      }
    } finally {
      this.saving = false;
    }
  }

  private async updateProject(
    project: Project,
    properties: Pick<UnwrapComputedPropertyGetters<Project>, keyof Project>
  ): Promise<void> {
    try {
      await this.projects.updateProjectSettings(project, properties);
      this.notifications.success("Your video's settings have been updated");
    } catch {
      project.rollbackAttributes();
    }
  }

  private async duplicateProject(
    project: Project,
    properties: Pick<UnwrapComputedPropertyGetters<Project>, keyof Project>
  ): Promise<Project> {
    const duplicatedProject = await this.projects.copyProject(project);
    await this.projects.updateProjectSettings(duplicatedProject, properties);
    this.notifications.success("Your video has been copied");

    return duplicatedProject;
  }

  private get projectId(): string {
    return this.model.project.id;
  }
}
