import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface CardArgs {
  heading?: string;
  tag: keyof HTMLElementTagNameMap;
  onClose?: (event: MouseEvent) => void;
}

export default class CardComponent extends Component<CardArgs> {
  styleNamespace = getStyleNamespace("north-star/card");

  get tagName(): string {
    return this.args.tag ?? "section";
  }

  get showClose(): boolean {
    return !!this.args.onClose;
  }
}
