import { underscore } from "@ember/string";
import { OnboardingOrgTypes } from "client/models/user-targeting-data";

export enum AvaUseCases {
  Business = "my business",
  Product = "my product",
  Topic = "a topic",
  Program = "a program",
  Policy = "a policy",
  SomethingElse = "something else",
  Template = "template"
}

export enum AvaJobs {
  Explain = "explain",
  Promote = "promote",
  Celebrate = "celebrate",
  Announce = "announce",
  Introduce = "introduce"
}

export enum AvaReferer {
  Manual = "manual",
  Onboarding = "onboarding"
}

export const PRIMARY_USE_CASES = [AvaUseCases.Business, AvaUseCases.Product, AvaUseCases.Topic];

export enum AvaStyles {
  Corporate = "corporate",
  Basic = "basic",
  Modern = "modern",
  Silk = "silk",
  Watercolor = "watercolor",
  Festive = "festive",
  Civic = "civic",
  Hexagonal = "hexagonal",
  Trade = "trade",
  Doodle = "doodle",
  Neo = "neo"
}

export interface StyleOption {
  name: string;
  tagline: string;
  imageSrc: string;
}

const isValueInEnum = (value: string | undefined, myEnum: typeof AvaJobs | typeof AvaUseCases): boolean => {
  return Object.values(myEnum).includes(value);
};

export const iconSrc = (value: string | undefined): string | undefined => {
  if (isValueInEnum(value, AvaUseCases) && !!value) {
    return `/client/assets/lottie/use_case/${underscore(value)}.json`;
  } else if (isValueInEnum(value, AvaJobs) && !!value) {
    return `/client/assets/lottie/job/${underscore(value)}.json`;
  } else {
    return "/client/assets/lottie/use_case/something_else.json";
  }
};

export const styleOptions = (style: string): StyleOption | undefined => {
  switch (style) {
    case AvaStyles.Modern:
      return {
        name: style,
        tagline: "Fresh and lively",
        imageSrc: `/client/assets/images/styles/modern.png`
      };
    case AvaStyles.Basic:
      return {
        name: style,
        tagline: "Simple and versatile",
        imageSrc: `/client/assets/images/styles/basic.png`
      };
    case AvaStyles.Corporate:
      return {
        name: style,
        tagline: "Sleek and professional",
        imageSrc: `/client/assets/images/styles/corporate.png`
      };
    case AvaStyles.Silk:
      return {
        name: style,
        tagline: "Clean and sophisticated",
        imageSrc: `/client/assets/images/styles/silk.png`
      };
    case AvaStyles.Watercolor:
      return {
        name: style,
        tagline: "Friendly and fun",
        imageSrc: `/client/assets/images/styles/watercolor.png`
      };
    case AvaStyles.Festive:
      return {
        name: style,
        tagline: "Cheerful and bright",
        imageSrc: `/client/assets/images/styles/festive.png`
      };
    case AvaStyles.Civic:
      return {
        name: style,
        tagline: "Modern and polished",
        imageSrc: `/client/assets/images/styles/civic.png`
      };
    case AvaStyles.Hexagonal:
      return {
        name: style,
        tagline: "Dynamic and vibrant",
        imageSrc: `/client/assets/images/styles/hexagonal.png`
      };
    case AvaStyles.Trade:
      return {
        name: style,
        tagline: "Industrial and practical",
        imageSrc: `/client/assets/images/styles/trade.png`
      };
    case AvaStyles.Doodle:
      return {
        name: style,
        tagline: "Crafty and colorful",
        imageSrc: `/client/assets/images/styles/doodle.png`
      };
    case AvaStyles.Neo:
      return {
        name: style,
        tagline: "Clear and refined",
        imageSrc: `/client/assets/images/styles/neo.png`
      };
    default: {
      return {
        name: style,
        tagline: "Streamlined and polished",
        imageSrc: `/client/assets/images/styles/basic.png`
      };
    }
  }
};

export const OnboardingOptions = [
  {
    value: OnboardingOrgTypes.Business,
    label: "Business",
    iconSrc: "/client/assets/lottie/user_type/business.json"
  },
  {
    value: OnboardingOrgTypes.Agency,
    label: "Marketing agency",
    iconSrc: "/client/assets/lottie/user_type/marketing_agency.json"
  },
  {
    value: OnboardingOrgTypes.Teacher,
    label: "Teacher or school admin",
    iconSrc: "/client/assets/lottie/user_type/teacher.json"
  },
  {
    value: OnboardingOrgTypes.Student,
    label: "Student",
    iconSrc: "/client/assets/lottie/user_type/student.json"
  },

  {
    value: OnboardingOrgTypes.Government,
    label: "Government",
    iconSrc: "/client/assets/lottie/user_type/government.json"
  },
  {
    value: OnboardingOrgTypes.NonProfit,
    label: "Non-profit",
    iconSrc: "/client/assets/lottie/user_type/non_profit.json"
  },
  {
    value: OnboardingOrgTypes.Personal,
    label: "Personal",
    iconSrc: "/client/assets/lottie/user_type/personal.json"
  },
  {
    value: OnboardingOrgTypes.Other,
    label: "Other",
    iconSrc: "/client/assets/lottie/user_type/other.json"
  }
];
