import { action } from "@ember/object";
import Component from "@glimmer/component";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AssetThumbnailFullscreenModalArgs {
  onClose(): unknown;
}

export default class AssetThumbnailFullscreenModalComponent extends Component<AssetThumbnailFullscreenModalArgs> {
  styleNamespace = getStyleNamespace("asset-thumbnail/fullscreen-modal");

  @action
  didInsert(): void {
    document.addEventListener("keydown", this.onKeyup);
  }

  @action
  onKeyup({ code }: KeyboardEvent): void {
    if (code === EventKeys.ESC) {
      this.close();
    }
  }

  @action
  close(): void {
    this.args.onClose();
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener("keydown", this.onKeyup);
  }
}
