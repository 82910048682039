import { tracked } from "@glimmer/tracking";

export interface AudioAsset {
  id: string;
  name: string;
  type: string | number;
  url: string;
}

export interface AudioClipArgs {
  id: string;
  category: string;
  duration: number;
  audioDuckingLevel: number;
  fadeIn: boolean;
  fadeOut: boolean;
  loop: boolean;
  mute: boolean;
  offset: number;
  asset: AudioAsset;
  volume: number;
  waveform: number[];
  trimStart: number;
  audibleType: string;
  audibleId: string;
}

export class AudioClip {
  /** @internal */
  @tracked
  _id: string;

  /** @internal */
  @tracked
  _category: string;

  /** @internal */
  @tracked
  _duration: number;

  /** @internal */
  @tracked
  _audioDuckingLevel: number;

  /** @internal */
  @tracked
  _fadeIn: boolean;

  /** @internal */
  @tracked
  _fadeOut: boolean;

  /** @internal */
  @tracked
  _loop: boolean;

  /** @internal */
  @tracked
  _mute: boolean;

  /** @internal */
  @tracked
  _offset: number;

  /** @internal */
  @tracked
  _asset: AudioAsset;

  /** @internal */
  @tracked
  _volume: number;

  /** @internal */
  @tracked
  _waveform: number[];

  /** @internal */
  @tracked
  _trimStart: number;

  /** @internal */
  @tracked
  _audibleType: string;

  /** @internal */
  @tracked
  _audibleId: string;

  constructor(args: AudioClipArgs) {
    this._id = args.id;
    this._category = args.category;
    this._duration = args.duration;
    this._audioDuckingLevel = args.audioDuckingLevel;
    this._fadeIn = args.fadeIn;
    this._fadeOut = args.fadeOut;
    this._loop = args.loop;
    this._mute = args.mute;
    this._offset = args.offset;
    this._asset = args.asset;
    this._volume = args.volume;
    this._waveform = args.waveform;
    this._trimStart = args.trimStart;
    this._audibleType = args.audibleType;
    this._audibleId = args.audibleId;
  }

  get id(): string {
    return this._id;
  }

  get category(): string {
    return this._category;
  }

  get duration(): number {
    return this._duration;
  }

  get audioDuckingLevel(): number {
    return this._audioDuckingLevel;
  }

  get fadeIn(): boolean {
    return this._fadeIn;
  }

  get fadeOut(): boolean {
    return this._fadeOut;
  }

  get loop(): boolean {
    return this._loop;
  }

  get mute(): boolean {
    return this._mute;
  }

  get name(): string {
    return this._asset.name;
  }

  get offset(): number {
    return this._offset;
  }

  get trimStart(): number {
    return this._trimStart;
  }

  get sourceUrl(): string {
    return this._asset.url;
  }

  get asset(): AudioAsset {
    return this._asset;
  }

  get volume(): number {
    return this._volume;
  }

  get waveform(): number[] {
    return this._waveform;
  }

  get audibleType(): string {
    return this._audibleType;
  }

  get audibleId(): string {
    return this._audibleId;
  }

  get endTime(): number {
    return this._offset + this._duration;
  }
}
