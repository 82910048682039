const toDegrees = (radians: number): number => (radians * 180) / Math.PI;

export class Vector2 {
  constructor(readonly x: number, readonly y: number) {}

  add(vec: Vector2): Vector2 {
    return new Vector2(this.x + vec.x, this.y + vec.y);
  }

  subtract(vec: Vector2): Vector2 {
    return new Vector2(this.x - vec.x, this.y - vec.y);
  }

  scaleBy(scaler: number): Vector2 {
    return new Vector2(this.x * scaler, this.y * scaler);
  }

  dotProduct(vec: Vector2): number {
    return this.x * vec.x + this.y * vec.y;
  }

  angleBetween(vec: Vector2): number {
    return toDegrees(Math.acos(this.dotProduct(vec) / (this.length * vec.length)));
  }

  static get zero(): Vector2 {
    return new Vector2(0, 0);
  }

  get length(): number {
    return Math.sqrt(this.dotProduct(this));
  }

  get normalized(): Vector2 {
    return this.scaleBy(1 / this.length);
  }
}
