import Model, { attr } from "@ember-data/model";

export default class ProjectSceneForSagRender extends Model {
  @attr("string")
  projectSceneId!: string;

  @attr("string")
  sagId!: string;

  @attr("number")
  thumbnailFrame?: number;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectSceneForSagRender: ProjectSceneForSagRender;
  }
}
