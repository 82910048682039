export class Styles {
  public static getMarginStyles({ x, y }: { x: number; y: number }, cellSize: number): string {
    return `
      border-top: ${cellSize * y}px solid rgba(255, 200, 230, 0.2);
      border-right: ${cellSize * x}px solid rgba(255, 200, 230, 0.2);
      border-bottom: ${cellSize * y}px solid rgba(255, 200, 230, 0.2);
      border-left: ${cellSize * x}px solid rgba(255, 200, 230, 0.2);
    `;
  }

  public static getGridStyles(cellSize: number): string {
    return `
      background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #E3DFDF 0 1px, transparent 1px 100%);
      background-position: -1px -1px;
      background-size: ${cellSize}px ${cellSize}px;
    `;
  }
}
