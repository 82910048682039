import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Caption, EventRegister, FontInfo, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ScriptWorkstationService from "client/services/script-workstation";

interface Args {
  eventRegister: EventRegister;
  isHover: boolean;
  scene: Scene;
  fonts: FontInfo[];
}

export default class ScriptWorkstationCaptionCollectionComponent extends Component<Args> {
  @service
  declare scriptWorkstation: ScriptWorkstationService;

  styleNamespace = getStyleNamespace("script-workstation/caption-collection");

  get hidden(): boolean {
    return !this.scriptWorkstation.expanded;
  }

  get captions(): Caption[] {
    return this.args.scene.captions;
  }
}
