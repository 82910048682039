import { action } from "@ember/object";
import Route from "@ember/routing/route";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { UNAUTHENTICATED_EMAIL_KEY } from "../login/route";
import TrackingEvents from "client/events";
import AnalyticsService from "client/services/analytics";
import type StorageService from "client/services/storage";

export class SignUpRouteModel {
  @tracked
  name = "";

  @tracked
  email = "";

  @tracked
  password = "";

  constructor(email?: string) {
    this.email = email ?? "";
  }
}

export default class SignUpRoute extends Route {
  trackPageName = TrackingEvents.EVENT_PAGE_SIGN_UP;

  @service
  private declare storage: StorageService;

  model(): SignUpRouteModel {
    return new SignUpRouteModel(this.storage.getItem(UNAUTHENTICATED_EMAIL_KEY, true));
  }

  @action
  willTransition(): boolean {
    const model = this.modelFor("unauthenticated.sign-up") as SignUpRouteModel;
    this.storage.setItem(UNAUTHENTICATED_EMAIL_KEY, model.email, true);

    return true;
  }

  didTransition(): boolean {
    next(this, () => {
      AnalyticsService.page(this.trackPageName);
    });
    return true;
  }

  /* eslint-disable camelcase */
  queryParams = {
    utm_source: {
      refreshModel: false
    },
    utm_medium: {
      refreshModel: false
    },
    utm_campaign: {
      refreshModel: false
    },
    utm_content: {
      refreshModel: false
    }
  };
  /* eslint-enable camelcase */
}
