import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

type AnimationOption = { icon: string; description: string };

export interface AnimationOptions {
  [key: string]: AnimationOption;
}

interface AnimationDropdownArgs {
  animations: AnimationOptions;
  value: string;
  select: (id: string) => void;
}

export default class AppAnimationDropdown extends Component<AnimationDropdownArgs> {
  @tracked
  open = false;

  @tracked
  animations: AnimationOptions = this.args.animations;

  styleNamespace = getStyleNamespace("app/animation-dropdown");

  get displayValue(): string {
    return this.args.animations[this.args.value]?.description || "";
  }

  select?: (id: string) => void;

  @action
  toggleMenu(): void {
    this.open = true;
  }

  @action
  closeMenu(): void {
    this.open = false;
  }

  @action
  onselect(id: string): void {
    this.args.select(id);
  }
}
