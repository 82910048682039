import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { ForgotRouteModel } from "./route";
import type AjaxService from "client/services/ajax";
import type NotificationsService from "client/services/notifications";

export default class ForgotController extends Controller<ForgotRouteModel> {
  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @tracked
  submitting = false;

  @action
  handleSubmit(ev: Event): void {
    ev.preventDefault();
    void this.submit();
  }

  async submit(): Promise<void> {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    try {
      const { email } = this.model;

      await this.ajax.api(
        "/passwords/create",
        {
          method: "POST",
          body: new URLSearchParams({ email })
        },
        "text"
      );

      this.model.isSubmitted = true;
    } catch (err) {
      // @ts-expect-error
      const errorMessage = err.errors?.[0]?.detail as string;

      if (errorMessage) {
        this.notifications.error(errorMessage);
      }
    } finally {
      this.submitting = false;
    }
  }
}
