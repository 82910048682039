import type { RecordProject, RecordProjectPrompt, RecordProjectResponse } from "@biteable/network-model";
import Network from "@biteable/network-model";
import ArrayProxy from "@ember/array/proxy";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { RecordProjectTypes } from "client/models/record-project";
import type SelectableAsset from "client/models/selectable-asset";
import type UserAsset from "client/models/user-asset";
import type HoneybadgerService from "client/services/honeybadger";

export interface RecordProjectResponseAndUserAsset {
  userAsset: UserAsset;
  response: RecordProjectResponse;
}

export interface UserResponse {
  response: RecordProjectResponse;
  prompt: RecordProjectPrompt;
  asset: SelectableAsset;
}

export interface UserResponsesGroupedByRespondent {
  [respondent: string]: UserResponse[];
}

export default class RecordProjectsService extends Service {
  @service
  declare store: Store;

  @service
  declare honeybadger: HoneybadgerService;

  async loadMyRecordingsWithAssets(page = 1, perPage = 24): Promise<ArrayProxy<RecordProjectResponseAndUserAsset>> {
    const nemRecordProjects = await Network.store.findAll(
      "recordProject",
      { per_page: perPage, page, record_type: RecordProjectTypes.SELF }, // eslint-disable-line camelcase
      { force: true }
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const recordProjectResponses = await this.responseForAllProjects(nemRecordProjects.data);

    return ArrayProxy.create({
      content: recordProjectResponses.flat(),
      meta: nemRecordProjects.meta
    }) as ArrayProxy<RecordProjectResponseAndUserAsset>;
  }

  private async responseForAllProjects(
    nemRecordProjects: RecordProject[]
  ): Promise<RecordProjectResponseAndUserAsset[][]> {
    return Promise.all(
      nemRecordProjects.map(async (recordProject: RecordProject) => {
        if (recordProject.recordProjectPrompts) {
          const recordProjectResponsesV2 = await Promise.all(
            recordProject.recordProjectPrompts
              .map((rpr: RecordProjectPrompt) => rpr.recordProjectResponses ?? [])
              .flat()
          );

          return this.findOrCreateAssetsForEachResponse(recordProjectResponsesV2);
        } else {
          return [];
        }
      })
    );
  }

  private async findOrCreateAssetsForEachResponse(
    recordProjectResponsesV2: RecordProjectResponse[]
  ): Promise<RecordProjectResponseAndUserAsset[]> {
    return Promise.all(
      recordProjectResponsesV2.map(async (rpr: RecordProjectResponse) => {
        const edUserAsset = await this.store.findRecord("userAsset", rpr.userAssetId);

        if (edUserAsset) {
          return { userAsset: edUserAsset, response: rpr };
        } else {
          const createdUserAsset = await this.store.createRecord("userAsset", { ...rpr.userAsset });
          return { userAsset: await createdUserAsset.save(), response: rpr };
        }
      })
    );
  }

  async loadCollaborativeRecordProjects(page = 1, perPage = 24): Promise<ArrayProxy<RecordProject>> {
    const result = await Network.store.findAll(
      "recordProject",
      { per_page: perPage, page, record_type: RecordProjectTypes.COLLABORATIVE }, // eslint-disable-line camelcase
      { force: true }
    );

    return ArrayProxy.create({
      content: result.data,
      meta: result.meta
    }) as ArrayProxy<RecordProject>;
  }

  async loadResponsesGroupedByEmail(recordProject: RecordProject): Promise<UserResponsesGroupedByRespondent> {
    const unGroupedRequests = await this.requestsForProject(recordProject);
    return await this.groupResponsesByEmail(unGroupedRequests);
  }

  private async requestsForProject(nemRecordProject: RecordProject): Promise<UserResponse[]> {
    if (nemRecordProject.recordProjectPrompts) {
      const recordProjectResponses = await Promise.all(
        nemRecordProject.recordProjectPrompts
          .map((rpr: RecordProjectPrompt) => (rpr.recordProjectResponses ? rpr.recordProjectResponses : []))
          .flat()
      );

      return await this.buildResponses(recordProjectResponses);
    } else {
      return [];
    }
  }

  private async buildResponses(recordProjectResponsesV2: RecordProjectResponse[]): Promise<UserResponse[]> {
    return Promise.all(
      recordProjectResponsesV2.map(async (rpr: RecordProjectResponse) => {
        const edUserAsset = await this.store.findRecord("userAsset", rpr.userAssetId);

        return {
          response: rpr,
          asset: edUserAsset,
          prompt: rpr.recordProjectPrompt
        };
      })
    );
  }

  private async groupResponsesByEmail(responses: UserResponse[]): Promise<UserResponsesGroupedByRespondent> {
    const responsesGroupedByRespondent: UserResponsesGroupedByRespondent = {};

    await Promise.all(
      responses.map((response: UserResponse) => {
        const respondent = response.response.email as string;

        if (!responsesGroupedByRespondent[respondent]) {
          responsesGroupedByRespondent[respondent] = [response];
        } else {
          responsesGroupedByRespondent[respondent] = [...responsesGroupedByRespondent[respondent]!, response];
        }
      })
    );

    return responsesGroupedByRespondent;
  }
}
