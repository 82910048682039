import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export const trimAlphaHex = (hex: string): string => hex.slice(0, 7);
export const getAlphaFromHex = (hex: string): string | undefined => hex.substring(7);
const hexToDecimal = (hex: string): number => parseInt(hex, 16) / 255;
const decimalToHex = (dec: number): string =>
  Math.round(dec * 255)
    .toString(16)
    .padStart(2, "0")
    .toUpperCase();
const toPercent = (n: number): string => (n * 100).toFixed(0) + "%";

const DEFAULT_ALPHA_HEX = "ff"; // ff = "100%";

interface UIAlphaSliderArgs {
  color: string;
  default?: number;
  max?: number;
  min?: number;
  step?: number;
  onChange?: (color?: string) => void;
}

export default class UIAlphaSliderComponent extends Component<UIAlphaSliderArgs> {
  private _element!: HTMLElement;

  @tracked
  localValue?: number;

  @tracked
  localValuePercent = "100%";

  @tracked
  defaultHex: string = DEFAULT_ALPHA_HEX;

  @tracked
  private color: string;

  styleNamespace = getStyleNamespace("ui/ui-slider-alpha");

  constructor(owner: object, args: UIAlphaSliderArgs) {
    super(owner, args);
    this.color = args.color;
  }

  get min(): number {
    return this.args.min ?? 0;
  }

  get max(): number {
    return this.args.max ?? 100;
  }

  get step(): number {
    return this.args.step ?? 1;
  }

  get value(): number {
    const alphaHex = (this.color && getAlphaFromHex(this.color)) || this.defaultHex;
    return hexToDecimal(alphaHex);
  }

  get decimal(): number {
    return parseInt(this.localValuePercent.toString(), 10) / 100;
  }

  onColorChange(): void {
    if (this._element) {
      const style = this._element.style;
      style.setProperty("--tint-color", trimAlphaHex(this.color));
    }

    if (!this.color) {
      this.localValuePercent = toPercent(0);
    }
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
    if (this.args.default) {
      this.defaultHex = decimalToHex(this.args.default);
    }
    this.color = this.color.padEnd(9, this.defaultHex);
    this.localValue = this.value;

    const { decimal, value } = this;
    if (decimal !== value) {
      this.localValuePercent = toPercent(value);
    }
    this.onColorChange();
  }

  get inputElement(): HTMLInputElement | undefined {
    return this._element.querySelector(`input[type="range"]`) as HTMLInputElement;
  }

  @action
  onInput(): void {
    if (!this.inputElement) {
      return;
    }

    const value = Number(this.inputElement.value);

    if (this.value !== value) {
      this.setValues(value);
    }
  }

  setValues(value: number): void {
    this.localValue = value;
    this.localValuePercent = toPercent(value);

    const colorHex = trimAlphaHex(this.color);
    const alphaHex = decimalToHex(value);

    this.color = colorHex + alphaHex;

    if (this.args.onChange) {
      this.args.onChange(this.color);
    }
  }
}
