import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type BrandLogo from "client/lib/brand/brand-logo";
import type { Frame } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";

interface BrandLogoThumbnailArgs {
  logo: BrandLogo;
}

// This duplicated from lib/zymbol-configs/image to avoid bringing that in for the moment.
enum FrameMaskShape {
  NONE = "NONE",
  RECT = "RECT",
  ROUNDED_RECT = "ROUNDED_RECT",
  CIRCLE = "CIRCLE"
}

export default class BrandLogoThumbnailComponent extends Component<BrandLogoThumbnailArgs> {
  styleNamespace = getStyleNamespace("brand/logo-thumbnail");

  get imageUrl(): string | undefined {
    return this.args.logo.image.previewUrl ?? this.args.logo.image.sourceUrl;
  }

  get frame(): Frame | undefined {
    return this.args.logo.image.frame;
  }

  get imageStyles(): SafeString {
    const { offsetX = 0, offsetY = 0, scale = 1 } = this.frame ?? {};

    return htmlSafe(`transform: scale(${scale}) translate(${-offsetX * 100}%, ${-offsetY * 100}%)`);
  }

  get frameBorderRadius(): string {
    switch (this.frame?.shape) {
      case FrameMaskShape.CIRCLE: {
        return "50%";
      }
      case FrameMaskShape.ROUNDED_RECT: {
        return "4px";
      }
      default: {
        return "none";
      }
    }
  }

  get frameBackground(): string {
    if (this.frame?.color && this.frame.shape !== FrameMaskShape.NONE) {
      return this.frame?.color;
    } else {
      return "transparent";
    }
  }

  get frameOverflow(): string {
    if (!this.frame || this.frame.shape === FrameMaskShape.NONE) {
      return "none";
    } else {
      return "hidden";
    }
  }

  get frameStyles(): SafeString {
    return htmlSafe(`
      border-radius: ${this.frameBorderRadius};
      background-color: ${this.frameBackground};
      overflow: ${this.frameOverflow};
    `);
  }
}
