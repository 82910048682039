import type { ProjectTemplate } from "@biteable/network-model";
import type ArrayProxy from "@ember/array/proxy";
import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import type AspectRatio from "./aspect-ratio";
import type Project from "client/models/project";

export default class ProjectTemplateSet extends Model {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  @attr("string")
  declare brand?: string;

  @attr("string")
  declare lowResThumbUrl: string;

  @attr("string")
  declare lowResUrl: string;

  @attr("string")
  declare title: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("aspect-ratio", { async: false, inverse: null })
  declare aspectRatio: AspectRatio;

  @hasMany("project-template", { async: false, inverse: "projectTemplateSet" })
  declare projectTemplates: ArrayProxy<ProjectTemplate>;

  get projectId(): string {
    // @ts-expect-error
    return this.belongsTo("project").id();
  }

  get isBrandable(): boolean {
    return this.brand === "branded";
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectTemplateSet: ProjectTemplateSet;
  }
}
