import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { VirtualElement } from "@popperjs/core";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  target?: HTMLElement | VirtualElement;
  isOpen?: boolean;
  isBackground?: boolean;
  onClose(): void;
}

export default class NorthStarRightClickMenuComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @tracked
  open = true;

  styleNamespace = getStyleNamespace("north-star/right-click-menu");

  @action
  closeMenu(): void {
    this.open = false;
  }
}
