import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  loading: boolean;
  hasNextPage: boolean;
  emptyResults: boolean;
  hasError: boolean;
  loadNextPage?: () => unknown;
  name?: string;
}

export default class InfiniteDisplayPanelLoadingComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("discovery/content/infinite-display/panel-loading");

  get name(): string {
    return this.args.name ?? "results";
  }
}
