import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import config from "client/config/environment";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CollaborationService from "client/services/collaboration";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TeamService from "client/services/team";

export default class DashboardTeamsEmptyComponent extends Component {
  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService;

  @service
  declare team: TeamService;

  @service
  declare collaboration: CollaborationService;

  styleNamespace = getStyleNamespace("discovery/dashboard/teams-empty");

  @action
  createDefaultVideo(): void {
    this.router.transitionTo("authenticated.template.copy", String(config.newBrandProjectId)).catch((reason) => {
      this.notifications.error("Blast! We could not creat that video project");
      // @ts-expect-error
      this.honeybadger.notify(reason, "DashboardTeamsEmptyComponent/createDefaultVideo");
    });
  }

  @action
  inviteTeamMembers(): void {
    this.collaboration.showTeamInviteModal("dashboard - team activity");
  }

  get showTeamInvitePrompt(): boolean {
    return this.canInviteTeamMembers;
  }

  private get canInviteTeamMembers(): boolean {
    return this.team.canInviteTeamMembers;
  }
}
