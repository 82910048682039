import type { AnimationAssetContainer } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type { Animation } from "client/lib/editor-domain-model";

export const animationToAnimationAssetContainer = (animation: Animation, sceneId?: string): AnimationAssetContainer => {
  const { clips, previewImageUrl } = animation;
  const layers = clips.map((clip) => ({ url: clip.id, srcUrl: clip.id }));
  return {
    cfg: {
      sag: {
        layers,
        previewImageUrl
      }
    },
    category: ZymbolCategory.SAG,
    sceneId
  };
};
