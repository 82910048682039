import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";

interface Args {
  routeName?: string;
}

export default class ToolbarTrimFootageComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @action
  trimTiming(): void {
    void this.router.transitionTo(`${this.args.routeName}.trim`);
  }
}
