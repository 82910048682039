import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TemplateFiltersArgs {
  query?: string;
  aspectRatio?: string;
  branded?: boolean;

  updateSearchQuery(query: string): unknown;
  updateAspectRatio(aspectRatio?: string): unknown;
  updateBranded(branded: boolean): unknown;
}

export default class TemplateFiltersComponent extends Component<TemplateFiltersArgs> {
  styleNamespace = getStyleNamespace("discovery/template-filters");
}
