import { action } from "@ember/object";
import { debounce } from "@ember/runloop";
import Component from "@glimmer/component";
import { cached } from "@glimmer/tracking";
import type { RenderZymbol } from "renderer-engine";
import type { Caption, EventRegister, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import SceneThumbnail from "client/lib/scene-thumbnail";
import SceneToZymbols from "client/lib/timeline/scene-to-zymbols";

interface Args {
  eventRegister: EventRegister;
  scene: Scene;
  caption?: Caption;
}

export default class SceneThumbnailProviderComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("scene-thumbnail-provider");

  private isVisible = false;
  private isDirty = true;

  @action
  didInsert(element: HTMLElement): void {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          this.isVisible = true;
          this.generateThumbnail();
        } else {
          this.isVisible = false;
        }
      }
    });
    observer.observe(element);
  }

  get zymbolsToRender(): RenderZymbol[] {
    return new SceneToZymbols(this.args.scene).getZymbols();
  }

  @action
  didUpdateZymbolsToRender(): void {
    this.isDirty = true;
    debounce(this, this.generateThumbnail, 1000);
  }

  @cached
  private get sceneThumbnail(): SceneThumbnail {
    return new SceneThumbnail(this.args.scene);
  }

  private generateThumbnail(): void {
    if (this.isVisible && this.isDirty) {
      this.isDirty = false;
      this.sceneThumbnail.generateThumbnail(this.args.eventRegister.facade, this.args.caption);
    }
  }

  get isGenerating(): boolean | undefined {
    return this.sceneThumbnail.isGenerating;
  }

  get imageSrc(): string | undefined {
    return this.sceneThumbnail.imageSrc;
  }
}
