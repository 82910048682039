import Model, { attr, belongsTo } from "@ember-data/model";
import type AspectRatio from "./aspect-ratio";
import type ProjectTemplateSet from "./project-template-set";
import type VideoAssistantGenerator from "./video-assistant-generator";
import type Project from "client/models/project";

export default class ProjectTemplate extends Model {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("aspect-ratio", { async: true, inverse: null })
  declare aspectRatio: AspectRatio;

  @belongsTo("project-template-set", { async: true, inverse: "projectTemplates" })
  declare projectTemplateSet?: ProjectTemplateSet;

  @belongsTo("video-assistant-generator", { async: true, inverse: "templates" })
  declare videoAssistantGenerator?: VideoAssistantGenerator;

  @attr("string")
  declare brand?: string;

  @attr("string")
  declare lowResThumbUrl: string;

  @attr("string")
  declare lowResUrl: string;

  @attr("string")
  declare title: string;

  @attr("string")
  declare projectTemplateSetDescription?: string;

  @attr("number")
  declare order?: number;

  @attr("boolean")
  declare owner: boolean;

  @attr("boolean")
  declare isBrandable: boolean;

  @attr("string")
  declare slug: string;

  get projectId(): string {
    // @ts-expect-error
    return this.belongsTo("project").id();
  }

  get eventProperties(): object {
    return {
      templateSlug: this.slug,
      templateId: this.id,
      projectId: this.projectId,
      templateName: this.title
    };
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectTemplate: ProjectTemplate;
  }
}
