import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export default class TrialCountdown extends Component {
  @service
  declare auth: AuthService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("trial-countdown");

  @action
  async onPurchase(): Promise<void> {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_START_TRIAL_PURCHASE, {
      ctaContext: "button - trial countdown - purchase"
    });
    void this.upgrade.open();
  }

  get daysTillTrialEnd(): number {
    return this.auth.currentSubscription?.daysTillTrialEnd ?? 0;
  }

  get showPurchaseButton(): boolean {
    return this.auth.canManageSubscription;
  }

  get showTrialCountdown(): boolean {
    return this.auth.currentSubscription?.trialing ?? false;
  }
}
