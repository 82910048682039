import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface SeatsVisualizationArgs {
  plan: Plan;
}

const MAX_SEATS_TO_DISPLAY = 5;

export default class SeatsVisualization extends Component<SeatsVisualizationArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-info-tile/seats-visualization");

  get planSeatsCount(): number {
    return this.args.plan.publisherSeats ?? 1;
  }

  get seatIconsCount(): number {
    return Math.min(MAX_SEATS_TO_DISPLAY, this.planSeatsCount);
  }
}
