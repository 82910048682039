import { service } from "@ember/service";
import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import type Store from "@ember-data/store";
import { last } from "lodash";
import type { Bounds, RenderZymbol, RenderZymbolGroup } from "renderer-engine";
import { sortPositions, ZymbolCategory, ZymbolGroupLayer, TextConfig } from "renderer-engine";
import type Zymbol from "./zymbol";
import { PLACEHOLDER_TEXT } from "./zymbol";
import config from "client/config/environment";
import type { DurationMode } from "client/lib/editor-domain-model";
import getSavedAttr from "client/lib/get-saved-attr";
import { restoreModel } from "client/lib/restore-model";
import type AspectRatio from "client/models/aspect-ratio";
import type { ManyArray } from "client/models/ember-data-types";
import type Layer from "client/models/layer";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type AjaxService from "client/services/ajax";
import type NotificationsService from "client/services/notifications";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export const DEFAULT_ZYMBOL_GROUP_TIME = 2; // seconds
export const MIN_ZYMBOL_GROUP_DURATION = config.timelineZymbolGroupMinDuration;

const ZYMBOL_GROUP_RESTORE_FAILURE_NOTIFICATION = "There was a problem restoring this item";

export default class ZymbolGroup extends Model implements RenderZymbolGroup {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-scene", { async: true, inverse: null })
  declare projectScene: ProjectScene;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  @belongsTo("layer", { async: true, inverse: "zymbolGroups", polymorphic: true })
  declare layer: Layer;

  @hasMany("zymbol", { async: true, inverse: "zymbolGroup" })
  declare zymbols: ManyArray<Zymbol>;

  @attr("number")
  startTime!: number;

  @attr("number")
  duration!: number;

  @attr("string")
  durationMode?: DurationMode;

  get endTime(): number {
    return this.startTime + this.duration;
  }

  get aspectRatio(): AspectRatio {
    // @ts-expect-error
    const project = this.belongsTo("project").value() as Project;
    return project.belongsTo("aspectRatio").value() as AspectRatio;
  }

  get absoluteStartTime(): number {
    let sceneStartTime = this.startTime;
    // @ts-expect-error
    const projectScene = this.belongsTo("projectScene").value() as ProjectScene;
    if (projectScene) {
      sceneStartTime += projectScene.startTime;
    }

    return sceneStartTime;
  }

  get defaultPreviewTime(): number {
    const absoluteStartTime: number = this.absoluteStartTime;
    return absoluteStartTime + this.duration / 2;
  }

  get absoluteEndTime(): number {
    const absoluteStartTime: number = this.absoluteStartTime;
    return absoluteStartTime + this.duration;
  }

  get x(): number {
    return Math.min(...this.zymbolsSync.map((zymbol) => zymbol.x));
  }

  get y(): number {
    return Math.min(...this.zymbolsSync.map((zymbol) => zymbol.y));
  }

  get width(): number {
    return Math.max(0, Math.max(...this.zymbolsSync.map((zymbol) => zymbol.x + zymbol.width)) - this.x);
  }

  get height(): number {
    return Math.max(0, Math.max(...this.zymbolsSync.map((zymbol) => zymbol.y + zymbol.height)) - this.y);
  }

  get textZymbols(): Zymbol[] {
    return this.zymbolsSync.filter(({ category }) => category === ZymbolCategory.TEXT);
  }

  get logoZymbols(): Zymbol[] {
    return this.zymbolsSync.filter(({ category }) => category !== ZymbolCategory.TEXT);
  }

  get hasZymbols(): boolean {
    return !!this.zymbolsSync.length;
  }

  get orderedTextZymbols(): Array<Zymbol> {
    return this.textZymbols.sort((a: Zymbol, b: Zymbol) => {
      return sortPositions(a, b);
    });
  }

  get orderedLogoZymbols(): Array<Zymbol> {
    return this.logoZymbols.sort((a: Zymbol, b: Zymbol) => {
      return sortPositions(a, b);
    });
  }

  get textContent(): string {
    return this.textContentArray.join(" - ");
  }

  get textContentArray(): string[] {
    return this.orderedTextZymbols.map(({ textContent }) => textContent).filter((text) => text.length);
  }

  get firstTextZymbol(): Zymbol | undefined {
    return this.orderedTextZymbols[0];
  }

  get firstLogoZymbol(): Zymbol | undefined {
    return this.orderedLogoZymbols[0];
  }

  get lastTextZymbol(): Zymbol | undefined {
    return last(this.orderedTextZymbols);
  }

  get isCaption(): boolean {
    // @ts-expect-error
    return (this.belongsTo("layer").value() as Layer)?.name === ZymbolGroupLayer.TEXT;
  }

  get isBackground(): boolean {
    // @ts-expect-error
    return (this.belongsTo("layer").value() as Layer)?.name === ZymbolGroupLayer.BACKGROUND;
  }

  get defaultTextConfig(): TextConfig {
    const { lastTextZymbol: last } = this;

    if (!last || !last.cfg || !last.cfg.text) {
      const content = JSON.stringify([
        {
          attributes: { color: "#000000", fontStyle: "normal", weight: "700", lineHeight: 1 },
          insert: PLACEHOLDER_TEXT
        },
        {
          attributes: { size: "1em", font: "Open Sans" },
          insert: "\n"
        }
      ]);
      const textConfig = new TextConfig();
      return { ...textConfig, content };
    }

    return last.defaultTextConfig;
  }

  get firstZymbol(): Zymbol | undefined {
    // @ts-expect-error
    return this.hasMany("zymbols").value()?.[0];
  }

  getSavedPosition(): Bounds {
    const zymbols = this.zymbolsSync;

    const getSavedCoord = (attr: keyof Bounds): number => {
      switch (attr) {
        case "x":
        case "y": {
          return Math.min(...zymbols.map((zymbol) => getSavedAttr(zymbol, attr)));
        }
        case "width":
        case "height": {
          return Math.max(...zymbols.map((zymbol) => getSavedAttr(zymbol, attr)));
        }
        default: {
          return 0;
        }
      }
    };

    const x = getSavedCoord("x");
    const y = getSavedCoord("y");
    const width = getSavedCoord("width");
    const height = getSavedCoord("height");

    return { x, y, width, height };
  }

  get order(): number {
    if (!this.isCaption) {
      return -1;
    }
    const startTime = this.absoluteStartTime;
    const captions =
      // @ts-expect-error
      (this.belongsTo("project").value() as Project)?.hasMany("captions").value() || new Array<ZymbolGroup>();
    const startTimes = captions.map((caption) => caption.absoluteStartTime);
    return startTimes.filter((time) => time < startTime).length;
  }

  get nextCaption(): ZymbolGroup | undefined {
    // @ts-expect-error
    const { sortedCaptions } = this.belongsTo("projectScene").value() as ProjectScene;

    if (!this.isCaption || !sortedCaptions) {
      return undefined;
    }

    const index = sortedCaptions.findIndex(({ id }) => id === this.id);
    return sortedCaptions[index + 1];
  }

  get maximumDuration(): number {
    const nextCaption = this.nextCaption;
    if (nextCaption) {
      return nextCaption.startTime - this.startTime;
    }
    // @ts-expect-error
    const { endTime } = this.belongsTo("projectScene").value() as ProjectScene;
    return endTime - this.startTime;
  }

  get zymbolsToRender(): RenderZymbol[] {
    return (
      // @ts-expect-error
      this.hasMany("zymbols")
        .value()
        ?.map((z) => z.zymbolToRender) ?? []
    );
  }

  get graphicZymbolsToRender(): RenderZymbol[] {
    return (
      // @ts-expect-error
      this.hasMany("zymbols")
        .value()
        ?.filter((z) => z.cfg.image)
        ?.map((z) => z.zymbolToRender) ?? []
    );
  }

  async removeZymbolGroup(): Promise<ZymbolGroup> {
    const layer = await this.layer;
    await this.destroyRecord();
    await layer.removeZymbolGroup(this);
    return this;
  }

  @service
  declare zymbolGroups: ZymbolGroupsService;

  static async restore(
    ajax: AjaxService,
    notifications: NotificationsService,
    store: Store,
    id: string
  ): Promise<ZymbolGroup> {
    try {
      return await restoreModel(ajax, store, "zymbolGroup", id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      notifications.error(ZYMBOL_GROUP_RESTORE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async restoreZymbol(id: string): Promise<Zymbol> {
    try {
      return await restoreModel(this.zymbolGroups.ajax, this.store, "zymbol", id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.zymbolGroups.notifications.error(ZYMBOL_GROUP_RESTORE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  private get zymbolsSync(): Zymbol[] {
    // @ts-expect-error
    return this.hasMany("zymbols").value() || [];
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    zymbolGroup: ZymbolGroup;
  }
}
