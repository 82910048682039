import Controller from "@ember/controller";
import { action, get } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { PaginationMeta } from "client/models/pagination";
import type { InfiniteModel } from "client/routes/infinite";

interface DataScroll {
  loadResults: (params?: Record<string, string>) => Promise<Array<any>>;
}

const PER_PAGE = 24;

export default abstract class InfiniteController<M extends InfiniteModel = InfiniteModel> extends Controller<M> {
  @service
  declare store: Store;

  @tracked
  currentlyLoading = false;

  @tracked
  loadingMore = false;

  @tracked
  page = 1;

  @tracked
  meta?: Pick<PaginationMeta, "total-count" | "totalCount">;

  queryParams: string[] = [];
  query?: string;

  // eslint-disable-next-line camelcase
  per_page: number = PER_PAGE;

  dataScroll?: DataScroll;

  get queryParamsHash(): Record<string, string> {
    return this.queryParams.reduce((prev, curr) => {
      return { ...prev, [curr]: get(this, curr as any) };
    }, {});
  }

  get hasResults(): boolean {
    return !!this.model.results.length;
  }

  get isLastPage(): boolean {
    if (!this.meta) {
      return true;
    }

    const totalCount = this.meta["totalCount"] ?? this.meta["total-count"];
    return this.page * this.per_page >= totalCount;
  }

  get emptyResults(): boolean {
    return !this.currentlyLoading && !this.hasResults;
  }

  get showNoMoreResults(): boolean {
    return this.page > 1 && this.isLastPage;
  }

  get canLoadMoreResults(): boolean {
    return !this.currentlyLoading && !this.isLastPage;
  }

  @action
  async loadNextPage(): Promise<void> {
    if (this.loadingMore || this.isLastPage) {
      return;
    }

    if (!this.model || !this.model.results || !this.dataScroll) {
      return;
    }

    this.loadingMore = true;
    this.page += 1;
    const params = this.queryParamsHash;

    try {
      const newResults = (await this.dataScroll.loadResults(params)) as M[];
      this.model.results.push(...newResults);
    } finally {
      this.loadingMore = false;
    }
  }
}
