import Model, { attr, belongsTo } from "@ember-data/model";
import type ProjectTranscription from "client/models/project-transcription";

export enum CaptionBurnStatus {
  complete = "complete",
  error = "error",
  canceled = "canceled",
  requested = "requested"
}

export default class CaptionBurnRequest extends Model {
  @attr("string")
  declare status: CaptionBurnStatus;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-transcription", { async: false, inverse: "captionBurnRequest" })
  declare projectTranscription: ProjectTranscription;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    captionBurnRequest: CaptionBurnRequest;
  }
}
