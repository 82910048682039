import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import BaseParentPanel from "../base-parent/component";
import type { ContentTypeOption } from "client/components/discovery/content/search/component";
import delay from "client/lib/delay";
import getStyleNamespace from "client/lib/get-style-namespace";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import type ProjectSceneStack from "client/models/project-scene-stack";
import type ProjectSceneStackCategory from "client/models/project-scene-stack-category";
import { ALL_CONTENTS } from "client/services/contents-curator";
import type PermissionsService from "client/services/permissions";
import type ProjectContentBarService from "client/services/project-content-bar";
import { ContentBarPanelType } from "client/services/project-content-bar";

export default class SearchPanel extends BaseParentPanel {
  private headerElement?: HTMLElement;

  private SCROLL_INTO_VIEW_ATTEMPTS_LIMIT = 30;

  @tracked
  categoriesInHeader: ProjectSceneStackCategory[] = new TrackedArray([]);

  @tracked
  categoryIndex = 0;

  @tracked
  stacksInHeader: ProjectSceneStack[] = new TrackedArray([]);

  @tracked
  loadedStacks: ProjectSceneStack[] = new TrackedArray([]);

  @tracked
  stacksInBody: ProjectSceneStack[] = new TrackedArray([]);

  @tracked
  loadedPlaceholders: ProjectSceneStack[] = new TrackedArray([]);

  @tracked
  searchKey = "";

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @tracked
  loading = false;

  @tracked
  targetStack?: ProjectSceneStack;

  @tracked
  loadingStack?: ProjectSceneStack;

  @tracked
  currentStackId?: string;

  styleNamespace = getStyleNamespace("project-content-bar/panels/scenes");

  @action
  didInsert(elem: HTMLElement): void {
    this.headerElement = elem;
    void this.load();
  }

  @action
  search(key: string): void {
    this.searchKey = key;
  }

  @action
  setCurrentStack(stack: ProjectSceneStack): void {
    this.currentStackId = stack.id;
  }

  @action
  async viewStack(stack: ProjectSceneStack, id: string): Promise<void> {
    this.loadingStack = stack;
    this.setCurrentStack(stack);

    if (this.isStackVisible(stack)) {
      this.loadingStack = undefined;
      this.scrollIntoView(id);
    } else {
      this.targetStack = stack;

      this.loadPrecedingStacks();
      this.loadTargetStack();

      await this.scrollIntoViewWhenReady(id);

      this.loadingStack = undefined;
    }
  }

  @action
  async setCategoryIndex(index: number): Promise<void> {
    this.categoryIndex = index;

    if (this.currentCategory) {
      this.projectContentBar.show(ContentBarPanelType.SCENES + `.${this.currentCategory.slug}`);
    }

    void this.load(false);
  }

  @action
  onStackLoaded(stack: ProjectSceneStack): void {
    if (!this.isStackLoaded(stack)) {
      this.loadedStacks.push(stack);
    }

    if (this.unexposedStacks && this.isStackVisible(stack)) {
      this.addToStacksInBody(stack);
    }
  }

  @action
  async onPlaceholdersLoaded(stack: ProjectSceneStack): Promise<void> {
    this.loadedPlaceholders.push(stack);
  }

  @action
  shouldLoadAllPages(stack: ProjectSceneStack): boolean {
    const stackInHeaderIndex = this.findStackInHeaderIndex(stack.code);

    return (
      stackInHeaderIndex < this.targetStackInHeaderIndex &&
      this.isValidIndex(stackInHeaderIndex) &&
      this.isValidIndex(this.targetStackInHeaderIndex)
    );
  }

  get searchView(): boolean {
    return !!this.searchKey;
  }

  get aspectRatioId(): string {
    return this.projectContentBar.timeline?.aspectRatioId ?? WIDE_ASPECT_RATIO_ID;
  }

  @tracked
  query = "";

  @tracked
  type: ContentTypeOption = { label: "all", values: ALL_CONTENTS };

  @action
  onSelectType(type: ContentTypeOption): void {
    this.type = type;
  }

  @action
  updateQuery(query: string): void {
    this.query = query;
  }

  private async loadCategories(): Promise<void> {
    // @ts-expect-error
    this.categoriesInHeader = this.store.peekAll("projectSceneStackCategory") as ProjectSceneStackCategory[];
    if (this.categoriesInHeader.length === 0) {
      // @ts-expect-error
      this.categoriesInHeader = (await this.store.query("projectSceneStackCategory", {
        published_only: true // eslint-disable-line camelcase
      })) as ProjectSceneStackCategory[];
    }
  }

  private async load(loadCategories = true): Promise<void> {
    this.loading = true;

    try {
      if (loadCategories) {
        await this.loadCategories();
      }

      if (this.childName) {
        const index = this.categoriesInHeader.map((cat) => cat.slug).indexOf(this.childName);
        if (index >= 0) {
          this.categoryIndex = index;
        }
      }

      await this.loadStacks();
    } finally {
      this.loading = false;
      void this.updateScrollMarginTop();
    }
  }

  private async loadStacks(): Promise<void> {
    this.stacksInBody = new TrackedArray([]);
    this.stacksInHeader = new TrackedArray([]);
    this.loadedStacks = new TrackedArray([]);
    this.targetStack = undefined;

    // @ts-expect-error
    let stacks = (await this.store.query("projectSceneStack", {
      category_slug: this.currentCategory?.slug // eslint-disable-line camelcase
    })) as ProjectSceneStack[];

    if (!this.showUnpublished) {
      stacks = stacks.filter((stack) => stack.published);
    }

    const counts = await Promise.all(
      stacks.map(async (stack) => {
        const previews = await this.store.query("projectScenePreview", {
          /* eslint-disable camelcase */
          stack: stack.code,
          aspect_ratio: this.aspectRatioId,
          per_page: 1
          /* eslint-enable camelcase */
        });
        // @ts-expect-error
        return previews.meta["total-count"];
      })
    );

    counts.forEach((count, index) => {
      if (count > 0) {
        this.stacksInHeader.push(stacks[index]!);
      }
    });

    if (this.stacksInHeader[0]) {
      this.currentStackId = this.stacksInHeader[0].id;
      this.stacksInBody.push(this.stacksInHeader[0]);
    }
  }

  private async updateScrollMarginTop(): Promise<void> {
    // delay until the CB width transition finishes (0.5s)
    await delay(1000);
    const outerHeader = this.headerElement?.parentElement;

    if (outerHeader) {
      const height = outerHeader.offsetHeight;
      document.querySelectorAll(`.${this.styleNamespace} .scenes-stack-header`).forEach((elem) => {
        (elem as HTMLElement).style.setProperty("scroll-margin-top", `${height}px`);
      });
    }
  }

  private loadPrecedingStacks(): void {
    if (this.isValidIndex(this.targetStackInHeaderIndex)) {
      this.precedingStacks.forEach((s) => this.stacksInBody.push(s));
    }
  }

  private loadTargetStack(): void {
    if (this.targetStack) {
      this.stacksInBody.push(this.targetStack);
    }
  }

  private scrollIntoView(id: string): void {
    const element = document.getElementById(`scenes-stack-${id}`);

    if (element) {
      element.scrollIntoView();
      void this.updateScrollMarginTop();
    }
  }

  private addToStacksInBody(stack: ProjectSceneStack): void {
    const nextStackIndex = this.findStackInHeaderIndex(stack.code) + 1;

    if (nextStackIndex + 1 <= this.stacksInHeader.length) {
      const nextStack = this.stacksInHeader[nextStackIndex];

      if (nextStack && !this.isStackVisible(nextStack)) {
        this.stacksInBody.push(nextStack);
      }
    }
  }

  private async scrollIntoViewWhenReady(id: string, attempt = 1): Promise<void> {
    const arePlaceholdersLoaded =
      this.loadedPlaceholders.length === this.precedingStacks.length && this.loadedPlaceholders.length > 0;
    const exceededAttempts = attempt >= this.SCROLL_INTO_VIEW_ATTEMPTS_LIMIT;

    if (arePlaceholdersLoaded || exceededAttempts) {
      this.scrollIntoView(id);
    } else {
      await delay(100);
      await this.scrollIntoViewWhenReady(id, attempt + 1);
    }
  }

  private isStackVisible(stack: ProjectSceneStack): boolean {
    return !!this.stacksInBody.find(({ code }) => stack.code === code);
  }

  private isStackLoaded(stack: ProjectSceneStack): boolean {
    return !!this.loadedStacks.find(({ code }) => stack?.code === code);
  }

  private isValidIndex(index: number | undefined): boolean {
    return index !== undefined && index > -1;
  }

  private findStackInHeaderIndex(code: string | undefined): number {
    return this.stacksInHeader.findIndex((stack) => code === stack.code);
  }

  private get currentCategory(): ProjectSceneStackCategory | undefined {
    return this.categoriesInHeader[this.categoryIndex];
  }

  private get showUnpublished(): boolean {
    return this.permissions.has("content_unpublished_view");
  }

  private get precedingStacks(): ProjectSceneStack[] {
    return this.stacksInHeader.slice(0, this.targetStackInHeaderIndex).filter((s) => !this.stacksInBody.includes(s));
  }

  private get targetStackInHeaderIndex(): number {
    return this.findStackInHeaderIndex(this.targetStack?.code);
  }

  private get unexposedStacks(): boolean {
    return this.stacksInHeader.length > this.stacksInBody.length;
  }
}
