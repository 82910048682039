import type Owner from "@ember/owner";
import config from "client/config/environment";
import type AuthService from "client/services/auth";

export default (owner: Owner, path = "/"): string => {
  const { authToken }: AuthService = owner.lookup("service:auth") as AuthService;
  const url = new URL(path, config.cmsUrl);

  if (authToken) {
    url.searchParams.append("token", authToken);
  }

  return url.toString();
};
