import Model, { attr } from "@ember-data/model";

export default class ProjectOwner extends Model {
  @attr("string")
  declare email: string;

  @attr("string")
  declare fullName: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectOwner: ProjectOwner;
  }
}
