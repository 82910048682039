import Model, { attr, belongsTo } from "@ember-data/model";
import type Subscription from "client/models/subscription";

export default class SubscriptionReactivation extends Model {
  @attr("string")
  declare planId: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: true, inverse: null })
  declare subscription?: Subscription;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    subscriptionReactivation: SubscriptionReactivation;
  }
}
