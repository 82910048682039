import { ExplicitTransaction } from "./explicit-transaction";

/** @deprecated Use `EventRegister.addCleanup` instead */

export default class SpecialTransaction extends ExplicitTransaction {
  constructor(private cleanup: () => void) {
    super();
  }

  async run(): Promise<void> {
    await super.run();
    this.cleanup();
  }

  async revert(): Promise<void> {
    await super.revert();
    this.cleanup();
  }
}
