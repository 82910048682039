import Controller from "@ember/controller";
import { service } from "@ember/service";
import type AuthService from "client/services/auth";

export default class InactiveTeamMemberModeController extends Controller {
  @service
  declare auth: AuthService;

  get teamOwnerEmail(): string | undefined {
    return this.auth.currentTeam?.ownerEmail;
  }
}
