import { service } from "@ember/service";
import Component from "@glimmer/component";
import type CopyPasteService from "client/services/copy-paste";

interface Args {
  pasteDisabled?: boolean;
}
export default class MediaActionsDeleteComponent extends Component<Args> {
  @service
  declare copyPaste: CopyPasteService;

  get pasteDisabled(): boolean {
    return !this.copyPaste.copiedObject || !this.copyPaste.allowCopyPaste || !!this.args.pasteDisabled;
  }
}
