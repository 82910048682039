import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface MessageWithButtonComponentArgs {
  buttonDataTestId?: string;
  buttonLabel: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  message: string;
  messageDataTestId?: string;
  onClick: () => {};
}

export default class MessageWithButtonComponent extends Component<MessageWithButtonComponentArgs> {
  styleNamespace = getStyleNamespace("export/video-overlay/message-with-button");
}
