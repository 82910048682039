import Route from "@ember/routing/route";
import type AccountRoute from "../route";
import type User from "client/models/user";

export interface AccountProfileModel {
  user: User;
}

export default class AccountProfileRoute extends Route<AccountProfileModel> {
  async model(): Promise<AccountProfileModel> {
    const user = this.modelFor<AccountRoute>("authenticated.account");

    return {
      user
    };
  }
}
