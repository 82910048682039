import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";

export default class AssetManagementRecordAssetsToggleComponent extends Component {
  @service
  declare router: RouterService;

  get currentRoute(): string {
    return this.router.currentRouteName;
  }

  @action
  onChangeRoute(value: string): void {
    void this.router.transitionTo(value);
  }
}
