import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface DropdownMenuItemArgs {
  onClick?(ev: MouseEvent): unknown;
  route?: string;
}

export default class DropdownMenuItemComponent extends Component<DropdownMenuItemArgs> {
  styleNamespace = getStyleNamespace("north-star/dropdown-menu/item");

  @action
  onClick(ev: MouseEvent): void {
    this.args.onClick?.(ev);
  }
}
