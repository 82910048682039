import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Sag from "client/models/sag";
import LibraryContentRoute from "client/routes/library-content";
import type PermissionsService from "client/services/permissions";
import { PROJECT_SCENE_TEMPLATE_UPDATE_FAILURE_NOTIFICATION } from "client/services/project-scenes";

export default class ProjectScenesAnimationsRoute extends LibraryContentRoute {
  @service
  declare store: Store;

  @service
  declare router: RouterService;

  @service
  declare permissions: PermissionsService;

  errorNotificationMessage = PROJECT_SCENE_TEMPLATE_UPDATE_FAILURE_NOTIFICATION;

  beforeModel(): void {
    if (!this.permissions.has("content_unpublished_view")) {
      void this.router.replaceWith("authenticated.project.scenes");
    }
  }

  async getResults(params: object): Promise<Array<Sag>> {
    // @ts-expect-error
    return this.store.query("sag", params) as Sag[];
  }
}
