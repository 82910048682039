import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import BaseParentPanel from "../base-parent/component";
import { ImagesCategories, VideosCategories } from "client/lib/data/library-categories";
import getStyleNamespace from "client/lib/get-style-namespace";
import type LibraryCategory from "client/models/library-category";
import type SelectableAsset from "client/models/selectable-asset";

export default class StockPanel extends BaseParentPanel {
  @service
  declare store: Store;

  @tracked
  searchKey?: string;

  @tracked
  hasPurchasedStock = false;

  styleNamespace = getStyleNamespace("project-content-bar/panels/stock");

  @action
  async checkHasPurchased(): Promise<void> {
    // eslint-disable-next-line camelcase
    const license = await this.store.query("stockLicense", { provider: "shutterstock", per_page: 1 });
    // @ts-expect-error
    this.hasPurchasedStock = license.meta["total-count"] !== 0;
  }

  @action
  async selectAsset(asset: SelectableAsset): Promise<void> {
    this.args.selectAsset(asset, {
      search: this.searchKey
    });
  }

  @action
  search(query?: string): void {
    this.searchKey = query;
  }

  get searchPlaceholder(): string {
    switch (this.childName) {
      case "images":
        return "Search images";
      case "pexels-videos":
        return "Search Pexels videos";
      default:
        return "Search Storyblocks videos";
    }
  }

  get libraryCategories(): LibraryCategory[] {
    return this.childName === "videos" ? VideosCategories : ImagesCategories;
  }
}
