import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ButtonRowArgs {
  justify: string;
}

export default class ButtonRowComponent extends Component<ButtonRowArgs> {
  styleNamespace = getStyleNamespace("north-star/button-row");

  get justify(): string {
    return this.args.justify ?? "space-between";
  }
}
