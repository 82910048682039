export class DeferredPromise<T> extends Promise<T> {
  resolved = false;
  rejected = false;
  resolve!: (value: T | PromiseLike<T>) => void;
  reject!: (reason?: any) => void;

  get loading(): boolean {
    return !this.resolved && !this.rejected;
  }

  setTimeout(f: () => void, ms: number): void {
    const timeout = setTimeout(f, ms);
    void this.then(() => clearTimeout(timeout));
  }
}

const defer = <T>(): DeferredPromise<T> => {
  let _resolve!: (value: T | PromiseLike<T>) => void;
  let _reject!: (reason?: any) => void;

  const promise = new DeferredPromise<T>((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });

  promise.resolve = (value: T | PromiseLike<T>): void => {
    promise.resolved = true;
    _resolve(value);
  };

  promise.reject = (reason?: any): void => {
    promise.rejected = true;
    _reject(reason);
  };

  return promise;
};

export default defer;
