import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";

export default class EditOnlyModeRoute extends Route {
  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  beforeModel(): void {
    if (!this.permissions.has("feature_edit_only_mode_notifications") || this.auth.currentSubscription) {
      void this.router.replaceWith("authenticated.home");
    }
  }
}
