import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ShutterstockCredits from "client/models/shutterstock-credits";
import type AuthService from "client/services/auth";

interface ShutterstockPurchaseNotificationArgs {
  failedStockLicensesPurchase?: boolean;
  renderRequired?: boolean;
  isRendering?: boolean;
  shutterstockCredits: ShutterstockCredits;
}

export default class ShutterstockPurchaseNotification extends Component<ShutterstockPurchaseNotificationArgs> {
  styleNamespace = getStyleNamespace("shutterstock-purchase-notification");

  @service
  declare auth: AuthService;

  get isPayPerClipError(): boolean {
    return !!this.args.failedStockLicensesPurchase;
  }

  get isTeamAccount(): boolean {
    return this.auth.hasTeam;
  }
}
