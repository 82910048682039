import type { ZymbolConfigType, HexColor, RenderZymbol } from "renderer-engine";
import {
  DEFAULT_FRAME_RATE,
  ZymbolCategory,
  ZymbolConfig,
  getZymbolConfig,
  ColorConfig,
  TextConfig,
  ZymbolGroupLayer,
  FilterConfig
} from "renderer-engine";
import ZymbolConfigFactory from "../scene/zymbol-config-factory";
import { createTextZymbolConfig } from "../text/text-styles";
import type { Caption, Element, Logo, Media, Scene, Text } from "client/lib/editor-domain-model";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";

interface Container {
  x: number;
  y: number;
  width: number;
  height: number;
}

export default class SceneToZymbols {
  constructor(private scene: Scene, private frameToRender?: number) {}

  public getZymbols(): RenderZymbol[] {
    const zymbolsToRender: RenderZymbol[] = [];

    // Background color
    zymbolsToRender.push(this.getBackgroundColorZymbolToRender(this.scene.color));

    // Background
    const backgroundZymbol = this.createZymbolForBackground();
    if (backgroundZymbol) {
      zymbolsToRender.push(backgroundZymbol);
    }

    // Filter
    if (this.scene.filterColor) {
      zymbolsToRender.push(this.getFilterZymbolToRender(this.scene.filterColor));
    }

    // Captions
    zymbolsToRender.push(...this.createZymbolsForCaptions());

    return zymbolsToRender;
  }

  createZymbolsForCaptions(): RenderZymbol[] {
    const zymbolsToRender: RenderZymbol[] = [];

    const captionsInRange = this.scene.captions.filter(this.inRangeFilter.bind(this));
    for (const caption of captionsInRange) {
      for (const text of caption.texts) {
        zymbolsToRender.push(this.createZymbolForText(text, caption));
      }

      for (const logo of caption.logos) {
        zymbolsToRender.push(this.createZymbolForLogo(logo, caption));
      }
    }

    return zymbolsToRender;
  }

  createZymbolForBackground(): RenderZymbol {
    const background = this.scene.background;
    const category = this.getCategory(background);

    return this.createZymbol(
      background.id,
      background.id,
      background.position,
      0,
      this.scene.duration,
      true,
      category,
      ZymbolGroupLayer.BACKGROUND,
      this.createZymbolConfigForMedia(background),
      background.layerOrder,
      background.assetOffset
    );
  }

  createZymbolForLogo(logo: Logo, caption: Caption): RenderZymbol {
    const endsAtSceneEnd = this.doEndTogether(logo, caption);
    const zymbolConfig = this.createZymbolConfigForMedia(logo);
    const zymbol = this.createZymbol(
      logo.id,
      caption.id,
      logo.position,
      caption.offset,
      caption.duration,
      endsAtSceneEnd,
      this.getCategory(logo),
      ZymbolGroupLayer.TEXT,
      zymbolConfig,
      logo.layerOrder,
      logo.assetOffset
    );

    return zymbol;
  }

  createZymbolForText(text: Text, caption: Caption): RenderZymbol {
    const endsAtSceneEnd = this.doEndTogether(text, caption);
    const zymbolConfig = this.createZymbolConfigForText(text);
    const zymbol = this.createZymbol(
      text.id,
      caption.id,
      text.position,
      caption.offset,
      caption.duration,
      endsAtSceneEnd,
      ZymbolCategory.TEXT,
      ZymbolGroupLayer.TEXT,
      zymbolConfig,
      text.layerOrder
    );

    return zymbol;
  }

  createZymbol(
    id: string,
    groupId: string,
    container: Container,
    startTime: number,
    duration: number,
    endsAtSceneEnd: boolean,
    category: ZymbolCategory,
    layerName: ZymbolGroupLayer,
    cfg: ZymbolConfig,
    layerOrder: number,
    assetOffset?: [number, number],
    customTimingOffset?: number,
    customTimingDuration?: number
  ): RenderZymbol {
    return {
      identifier: `Zymbol(${id})`,
      cfg: cfg,
      duration: duration,
      x: container.x,
      y: container.y,
      width: container.width,
      height: container.height,
      layerOrder: layerOrder,
      assetOffset: assetOffset,
      customTimingOffset: customTimingOffset,
      customTimingDuration: customTimingDuration,
      category: category,
      endsAtSceneEnd: endsAtSceneEnd,
      sceneId: this.scene.id,
      startTime: startTime,
      zymbolGroupId: groupId,
      layerName: layerName,
      assets: []
    };
  }

  doEndTogether(element: Element, caption: Caption): boolean {
    return caption.offset + (element.customTimingEnd ?? caption.duration) === this.scene.duration;
  }

  getCategory(media: Media): ZymbolCategory {
    return new ZymbolConfigFactory().getCategory(media.asset);
  }

  inRangeFilter(caption: Caption): boolean {
    if (this.frameToRender) {
      return (
        caption.offset * DEFAULT_FRAME_RATE <= this.frameToRender &&
        caption.end * DEFAULT_FRAME_RATE >= this.frameToRender
      );
    }

    return true;
  }

  containerFor(item: Pick<Zymbol, "x" | "y" | "width" | "height">): Container {
    return {
      x: item.x,
      y: item.y,
      width: item.width,
      height: item.height
    };
  }

  getContainerId(zymbolGroup: ZymbolGroup, zymbol: Zymbol): string {
    if (zymbol.category !== ZymbolCategory.TEXT && zymbol.layerName !== ZymbolGroupLayer.TEXT) {
      return zymbolGroup.id;
    }
    return zymbol.id;
  }

  createZymbolConfigForText(text: Text): ZymbolConfig {
    return {
      [ZymbolCategory.TEXT]: {
        ...new TextConfig(),
        ...createTextZymbolConfig(text)
      }
    };
  }

  createZymbolConfigForMedia(media: Media): ZymbolConfig {
    const category = this.getCategory(media);

    return {
      [category]: {
        ...getZymbolConfig(category),
        ...new ZymbolConfigFactory().createZymbolConfig(media.asset)
      }
    };
  }

  getBackgroundColorZymbolToRender(color: HexColor): RenderZymbol {
    const colorConfig = new ColorConfig();
    colorConfig.backgroundColor = {
      rgb: color || "#FFFFFF",
      alpha: 1
    };

    return this.createZymbolToRender(ZymbolCategory.COLOR, colorConfig, 0, this.scene.duration);
  }

  getFilterZymbolToRender(color: HexColor): RenderZymbol {
    const filterConfig = new FilterConfig();
    filterConfig.color = color;
    filterConfig.composition = "multiply";

    return this.createZymbolToRender(ZymbolCategory.FILTER, filterConfig, 0, this.scene.duration);
  }

  createZymbolToRender(
    category: ZymbolCategory,
    cfg: ZymbolConfigType,
    startTime: number,
    duration: number
  ): RenderZymbol {
    const config = new ZymbolConfig();
    // @ts-ignore - will
    config[category] = cfg;

    return {
      cfg: config,
      startTime: startTime,
      duration: duration,
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      layerOrder: 0,
      customTimingOffset: undefined,
      customTimingDuration: undefined,
      category,
      sceneId: this.scene.id,
      identifier: `ProjectScene(${this.scene.id})`,
      assets: []
    };
  }
}
