import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Plan from "client/models/plan";
import type PlansService from "client/services/plans";

export default class AccountSubscriptionCancelWarningController extends Controller {
  @service
  declare plans: PlansService;

  @service
  declare router: RouterService;

  @action
  close(): void {
    void this.router.transitionTo("authenticated.account.subscription");
  }

  @action
  continue(): void {
    void this.router.transitionTo("authenticated.account.subscription.cancel.confirmation");
  }

  get currentPrice(): string {
    return this.getPlanPrice(this.plan);
  }

  get correspondingPrice(): string {
    return this.getPlanPrice(this.correspondingPlan);
  }

  get currentBillingInterval(): string {
    return this.getPlanBillingInterval(this.plan);
  }

  get correspondingBillingInterval(): string {
    return this.getPlanBillingInterval(this.correspondingPlan);
  }

  private get correspondingPlan(): Plan | undefined {
    return this.plans.getCorrespondingPlan(this.plan);
  }

  private get plan(): Plan {
    return this.model.fullSubscription.plan!;
  }

  private getPlanPrice(plan: Plan | undefined): string {
    if (!plan) {
      return "";
    }

    return plan.monthlyPrice;
  }

  private getPlanBillingInterval(plan: Plan | undefined): string {
    return !plan ? "" : plan.billingInterval;
  }
}
