import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { debounce } from "@ember/runloop";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type {
  Scene,
  Element,
  Asset,
  VideoClip,
  Timeline,
  EventRegister,
  Caption
} from "client/lib/editor-domain-model";
import { Logo, Watermark, Background, Media, Text } from "client/lib/editor-domain-model";
import { CoalescingTransactionManager } from "client/lib/editor-domain-model/events/coalescing-transaction-manager";
import { ChangeMediaReplaceableMutation } from "client/lib/editor-domain-model/events/mutations/change-media-replaceable-mutation";
import getStyleNamespace from "client/lib/get-style-namespace";
import MediaTypes from "client/lib/media-types";
import type { EditableText } from "client/lib/text/text-editor";
import { canAnimate } from "client/lib/timeline/element";
import type BrandStyle from "client/models/brand-style";
import type AdvancedEditorService from "client/services/advanced-editor";
import type BrandEditModalService from "client/services/brand-edit-modal";
import type PropertiesPanelService from "client/services/properties-panel";

interface Args {
  currentScene: Array<Scene>;
  brandStyle?: BrandStyle;
  disableBranding?: boolean;
  timeline?: Timeline;
  scene: Scene;
  editables: Map<string, EditableText>;
  eventRegister: EventRegister;
}

export default class ToolbarComponent extends Component<Args> {
  private manager = new CoalescingTransactionManager(this.args.eventRegister);

  @service
  declare router: RouterService;

  @service
  declare brandEditModal: BrandEditModalService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @tracked
  activeToggle?: string;

  styleNamespace = getStyleNamespace("toolbar");

  @action
  closePropertiesPanel(): void {
    this.propertiesPanel.close();
  }

  @action
  editBrand(): void {
    void this.brandEditModal.show();
  }

  @action
  toggleColorCombo(): void {
    if (!this.activeToggle && this.brandableScene) {
      this.activeToggle = "color_combo";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleReplace(): void {
    if (!this.activeToggle) {
      this.activeToggle = "replace";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleVolume(): void {
    if (!this.activeToggle) {
      this.activeToggle = "volume";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleBackground(): void {
    if (!this.activeToggle) {
      this.activeToggle = "background";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleFontSize(): void {
    if (!this.activeToggle) {
      this.activeToggle = "font_size";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleFontFamily(): void {
    if (!this.activeToggle) {
      this.activeToggle = "font_family";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleFontTextAlignment(): void {
    if (!this.activeToggle) {
      this.activeToggle = "font_text_alignment";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleEffect(): void {
    if (!this.activeToggle) {
      this.activeToggle = "effect";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  toggleOpacity(): void {
    if (!this.activeToggle) {
      this.activeToggle = "opacity";
    } else {
      this.activeToggle = undefined;
    }
  }

  @action
  hideToggle(): void {
    this.activeToggle = undefined;
  }

  get selectedElement(): Element | undefined {
    return this.advancedEditor.element;
  }

  get brandableScene(): boolean {
    return !(this.args.currentScene[0]?.colorPreset === undefined);
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get colorComboActive(): boolean {
    return this.activeToggle === "color_combo";
  }

  get replaceActive(): boolean {
    return this.activeToggle === "replace";
  }

  get volumeActive(): boolean {
    return this.activeToggle === "volume";
  }

  get backgroundActive(): boolean {
    return this.activeToggle === "replace";
  }

  get activeFontSize(): boolean {
    return this.activeToggle === "font_size";
  }

  get activeFontFamily(): boolean {
    return this.activeToggle === "font_family";
  }

  get activeFontTextAlignment(): boolean {
    return this.activeToggle === "font_text_alignment";
  }

  get effectActive(): boolean {
    return this.activeToggle === "effect";
  }

  get opacityActive(): boolean {
    return this.activeToggle === "opacity";
  }

  get isLogo(): boolean {
    return this.selectedElement instanceof Logo;
  }

  get isBackground(): boolean {
    return this.selectedElement instanceof Background;
  }

  get isWatermark(): boolean {
    return this.selectedElement instanceof Watermark;
  }

  get isText(): boolean {
    return this.selectedElement instanceof Text;
  }

  get caption(): Caption | undefined {
    return this.advancedEditor.caption;
  }

  get scene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  get media(): Background | Logo | Watermark | Text | undefined {
    if (this.isLogo) {
      return this.selectedElement as Logo;
    } else if (this.isBackground) {
      return this.scene?.background;
    } else if (this.isWatermark) {
      return this.selectedElement as Watermark;
    } else if (this.isText) {
      return this.selectedElement as Text;
    } else {
      return undefined;
    }
  }

  get routeName(): string {
    if (this.isLogo) {
      return "authenticated.project.scene.caption.logo";
    } else if (this.isBackground) {
      return "authenticated.project.scene.background";
    } else if (this.isWatermark) {
      return "authenticated.project.scene.watermark";
    } else {
      return "";
    }
  }

  get isVoiceover(): boolean {
    return this.router.currentRouteName === "authenticated.project.scene.voiceover.index";
  }

  get isSoundtrack(): boolean {
    return this.router.currentRouteName === "authenticated.project.scene.soundtrack";
  }

  get isNotSoundOrVoice(): boolean {
    return !this.isVoiceover && !this.isSoundtrack;
  }

  get showToolbarAction(): object {
    return {
      replace: this.isNotSoundOrVoice && (this.isLogo || (this.isBackground && this.hasAsset) || this.isWatermark),
      addBackground: this.isNotSoundOrVoice && this.isBackground && !this.hasAsset,
      adjustFrame: this.isNotSoundOrVoice && this.hasAsset && !this.isBackground && !this.isGraphic && !this.isSvg,
      trimFootage: this.isNotSoundOrVoice && this.hasAsset && (this.isVideo || this.isGraphic),
      adjustVolume: this.isNotSoundOrVoice && this.hasAsset && this.isVideo && this.hasAudio,
      effect: this.isNotSoundOrVoice && canAnimate(this.selectedElement),
      opacity: this.isNotSoundOrVoice && this.hasAsset && (this.isImage || this.isSvg),
      graphicColors: this.isNotSoundOrVoice && this.isGraphic,
      svgColors: this.isNotSoundOrVoice && this.isSvg,
      colorCombo: this.isNotSoundOrVoice && this.isBackground,
      backgroundColors: this.isNotSoundOrVoice && this.isBackground,
      fontMenu: this.isNotSoundOrVoice && this.isText,
      showProperties: this.isNotSoundOrVoice,
      showCloseProperties: this.propertiesPanel.visible && (this.isSoundtrack || this.isVoiceover)
    };
  }

  get isToolbarEmpty(): boolean {
    const actions = this.showToolbarAction;
    return Object.values(actions).every((value) => !value);
  }

  get isImage(): boolean {
    return new MediaTypes(this.media).isImage();
  }

  get isGraphic(): boolean {
    return new MediaTypes(this.media).isGraphic();
  }

  get isSvg(): boolean {
    return new MediaTypes(this.media).isSvg();
  }

  get isVideo(): boolean {
    return new MediaTypes(this.media).isVideo();
  }

  get hasAsset(): boolean {
    return !!this.asset?.hasContent;
  }

  get hasAudio(): boolean {
    return !!(this.asset as VideoClip)?.hasAudio;
  }

  get asset(): Asset | undefined {
    if (this.isBackground || this.isLogo || this.isWatermark) {
      return (this.media as Media)?.asset;
    } else {
      return;
    }
  }

  @action
  save(): void {
    debounce(this, this.doSave, 200, false);
  }

  @action
  doSave(): void {
    const { eventRegister, scene } = this.args;

    if (this.media instanceof Watermark) {
      void eventRegister.facade.saveWatermark(this.media);
    } else {
      void eventRegister.facade.saveScene(scene);
    }
  }

  @action
  async saveMediaReplaceable(mediaReplaceable: boolean): Promise<void> {
    const { eventRegister, scene } = this.args;

    if (this.media instanceof Media) {
      await this.manager.transaction(async () => {
        this.args.eventRegister.fire(new ChangeMediaReplaceableMutation(this.media as Media, mediaReplaceable));

        if (this.media instanceof Watermark) {
          void eventRegister.facade.saveWatermark(this.media);
        } else {
          void eventRegister.facade.saveScene(scene);
        }
      });
    }
  }
}
