import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewCommentsArgs {
  projectPreview: ProjectPreview;
}

export default class ProjectPreviewCommentsComponent extends Component<ProjectPreviewCommentsArgs> {
  styleNamespace = getStyleNamespace("discovery/project-preview/comments-count");

  get commentsEnabled(): boolean {
    return this.args.projectPreview.showComments;
  }

  get commentCount(): string {
    return Intl.NumberFormat("en", {
      // @ts-ignore
      notation: "compact"
    }).format(this.args.projectPreview.countOfComments);
  }
}
