import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class LoaderService extends Service {
  @tracked
  message?: string;

  @tracked
  showOverlay?: boolean;

  @tracked
  showing = false;

  @tracked
  showEllipsis = true;

  show(message?: string, showOverlay = false, showEllipsis = true): void {
    this.message = message;
    this.showOverlay = showOverlay;
    this.showEllipsis = showEllipsis;
    this.showing = true;
  }

  hide(): void {
    this.showing = false;
    this.message = undefined;
    this.showOverlay = undefined;
    this.showEllipsis = true;
  }
}
