import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type Project from "client/models/project";
import type SelectableAsset from "client/models/selectable-asset";
import type ProjectContentBarService from "client/services/project-content-bar";
import type { CBSelectAssetTrackingProperties } from "client/services/project-content-bar";

export interface BaseParentArgs {
  selectAsset(asset?: SelectableAsset, trackProperties?: CBSelectAssetTrackingProperties): unknown;
  adding?: boolean;
  scrollTop(): unknown;
  project: Project;
}

export interface PanelQueryParams {
  include: string[];
  scope: string | undefined;
  per_page: number; // eslint-disable-line camelcase
  filter: string | undefined;
}

export default abstract class BaseParentPanel extends Component<BaseParentArgs> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @action
  showPanel(panel: string): void {
    this.projectContentBar.show(panel);
  }

  get panel(): string {
    return this.projectContentBar.panel;
  }

  get currentComponent(): string {
    return `project-content-bar/panels/${this.panel.split(".").join("/")}`;
  }

  get childName(): string | undefined {
    const nameParts = this.panel.split(".");
    return nameParts[nameParts.length - 1];
  }
}
