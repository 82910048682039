import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import { DEFAULT_PLAN_INTERVAL, PlanInterval } from "client/models/plan";

type PlanByInterval = { [key in PlanInterval]: Plan[] };

interface PlanComparisonArgs {
  plans: Plan[];
  currentPlanSkuRetired: Plan;
}

export default class PlansComparisonComponent extends Component<PlanComparisonArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-comparison");

  @tracked
  interval: PlanInterval = DEFAULT_PLAN_INTERVAL;

  @action
  changeInterval(): void {
    this.interval = this.interval === PlanInterval.YEAR ? PlanInterval.MONTH : PlanInterval.YEAR;
  }

  @action
  selectYearlyInterval(): void {
    this.interval = PlanInterval.YEAR;
  }

  get intervalPlans(): Plan[] {
    return this.plansByInterval[this.interval];
  }

  get plansByInterval(): PlanByInterval {
    return {
      [PlanInterval.MONTH]: [...this.args.plans.filter((plan) => plan.isMonthly)],
      [PlanInterval.YEAR]: [...this.args.plans.filter((plan) => plan.isYearly)]
    };
  }
}
