import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { VirtualElement } from "@popperjs/core";
import type { Bounds, Size } from "renderer-engine";
import type { ResizeDelta } from "../app/resize-element-handle/component";
import type { ResizeType } from "client/components/app/resize-element/component";
import { ResizePositions } from "client/components/app/resize-element/component";
import { Sources } from "client/components/tidal/project-canvas/component";
import type { EventRegister, Logo } from "client/lib/editor-domain-model";
import { convertRect, intersection, toCoOrdinates, unitGeometry } from "client/lib/geometry";
import getStyleNamespace from "client/lib/get-style-namespace";
import { clamp, toFixed } from "client/lib/quick-math";
import { transaction } from "client/lib/transaction";
import type Layer from "client/models/zymbols/layer-order";
import type CopyPasteService from "client/services/copy-paste";
import { MouseEventButtons } from "client/services/mouse";
import type PermissionsService from "client/services/permissions";
import type ScriptWorkstationService from "client/services/script-workstation";
import type TrackingService from "client/services/tracking";

const DRAG_THRESHOLD = 3; // Pixels

export enum Points {
  CENTER = 0.5,
  TOP = 0,
  LEFT = 0,
  RIGHT = 1,
  BOTTOM = 1
}

const MIN_DIMENSION = 0.05; // 5%

const isCorner = (resizePosition: ResizePositions): boolean =>
  [
    ResizePositions.TOP_LEFT,
    ResizePositions.TOP_RIGHT,
    ResizePositions.BOTTOM_LEFT,
    ResizePositions.BOTTOM_RIGHT
  ].includes(resizePosition);

interface BoundingPosition {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

const getBoundsFromPosition = (pos: Bounds): BoundingPosition => {
  return {
    top: pos.y,
    left: pos.x,
    right: pos.width + pos.x,
    bottom: pos.height + pos.y
  };
};

interface Args {
  region: Bounds;
  eventRegister: EventRegister;
  layer?: Layer;
  select?: () => void;
  moveStart?: () => void;
  moveEnd?: () => void;
  move?: (position: Bounds) => void;
  selected?: boolean;
  locked?: boolean;
  resizeStart?: () => void;
  resize?: (source: Sources, position: Bounds) => void;
  resizeEnd?: () => void;
  resizeY?: boolean;
  resizeX?: boolean;
  fixedLayer?: boolean;
  append?: () => void;
  remove?: () => void;
  resizeType?: ResizeType;
  contain?: boolean;
  onDoubleClick?: () => void;
  duplicate?: () => void;
  copy?: (e: Event, origin: string) => Promise<void>;
  paste?: (e: Event, origin: string) => Promise<void>;
  canDuplicate?: boolean;
  moveToForeground?: () => void;
  canDetach?: boolean;
  hasRemovableBackground?: boolean;
  backgroundIsEmpty?: boolean;
  replace?: (e: Event) => void;
  resetAspectRatio?: () => void;
  moveToBackground?: () => void;
  fitToClip?: () => void;
  editTiming?: () => void;
  canReplace?: boolean;
  isBackground?: boolean;
  modalCanvas?: boolean;
  justifyContent?: string;
  media: Text | Logo;
}

export default class ProjectCanvasRegionComponent extends Component<Args> {
  @service
  private declare permissions: PermissionsService;

  @service
  declare tracking: TrackingService;

  @service
  declare copyPaste: CopyPasteService;

  @service
  private declare scriptWorkstation: ScriptWorkstationService;

  @tracked
  _element?: HTMLElement;

  @tracked
  isRightClickMenuOpen = false;

  @tracked
  dragging = false;

  @tracked
  copyPasteOrigin = "right-click-menu";

  @tracked
  virtualElement?: VirtualElement;

  styleNamespace = getStyleNamespace("project-canvas-region");

  originalPosition = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };

  get resizeX(): boolean {
    return this.args.resizeX ?? true;
  }

  get resizeY(): boolean {
    return this.args.resizeY ?? true;
  }

  get showActionButtons(): boolean {
    if (this.args.selected) {
      if (this.args.backgroundIsEmpty) {
        return false;
      }
      return this.args.selected;
    }
    return false;
  }

  get canUseRightClickMenu(): boolean {
    if (this.args.backgroundIsEmpty) {
      return false;
    }

    return !this.args.modalCanvas;
  }

  // Layers are moveable unless specified otherwise.
  get canLayer(): boolean {
    return !!this.args.layer && !this.args.fixedLayer;
  }

  get canRemove(): boolean {
    if (this.args.hasRemovableBackground !== undefined) {
      return this.args.hasRemovableBackground;
    } else {
      return !!this.args.remove;
    }
  }

  get canvasDimensions(): Size {
    if (!this._element || !this._element.parentElement) {
      return { width: 0, height: 0 };
    }

    const { width, height } = this._element.parentElement.getBoundingClientRect();

    return { width, height };
  }

  getRelativeX(x: number): number {
    return toFixed(x / this.canvasDimensions.width, 5);
  }

  getRelativeY(y: number): number {
    return toFixed(y / this.canvasDimensions.height, 5);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    this._element?.removeEventListener("contextmenu", this.contextMenu);
  }

  @action
  openRightClickMenu(e: PointerEvent): void {
    this.isRightClickMenuOpen = true;
    this.copyPasteOrigin = "ellipsis menu";
    this.contextMenu(e);
  }

  @action
  contextMenu(e: MouseEvent): void {
    e.preventDefault();

    const { clientX: x, clientY: y } = e;
    const virtualElement = {
      getBoundingClientRect: (): any => {
        return {
          width: 0,
          height: 0,
          top: y,
          right: x,
          bottom: y,
          left: x
        };
      }
    };

    this.virtualElement = virtualElement;
  }

  @action
  doubleClick(ev: MouseEvent): void {
    const dblClickable = (ev.target as HTMLElement)?.nodeName !== "svg";

    if (this.args.onDoubleClick && dblClickable) {
      this.args.onDoubleClick();
      this._element?.removeEventListener("contextmenu", this.contextMenu);
    }
  }

  @action
  mouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (ev.button === MouseEventButtons.SECONDARY) {
      if (this.canUseRightClickMenu) {
        this._element?.addEventListener("contextmenu", this.contextMenu);
        this.isRightClickMenuOpen = true;

        if (this.args.select) {
          this.args.select();
        }
      }

      return;
    } else if (ev.button === MouseEventButtons.AUXILIARY) {
      return;
    }

    if (this.args.select) {
      this.args.select();
    }

    if (this.args.locked) {
      return;
    }

    if (this.args.moveStart) {
      this.args.moveStart();
    }

    const { x, y, width, height } = this.args.region;

    Object.assign(this.originalPosition, {
      x,
      y,
      width,
      height
    });

    const startX = ev.pageX;
    const startY = ev.pageY;

    const drag = ({ pageX, pageY }: MouseEvent): void => {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const xDelta = pageX - startX;
      const yDelta = pageY - startY;

      // Determine if user has dragged mouse enough to initialize dragging
      if (!this.dragging) {
        const coeff = Math.sqrt(xDelta ** 2 + yDelta ** 2);

        if (coeff >= DRAG_THRESHOLD) {
          this.dragging = true;
        } else {
          return;
        }
      }

      const rx = this.getRelativeX(xDelta);
      const ry = this.getRelativeY(yDelta);
      const { originalPosition: op } = this;
      const { contain } = this.args;

      const np = { ...op };

      // Contain the region within the canvas (no more than 50% of it can be off-canvas)
      const obscured = contain ? 0.5 : 1;

      const minX = -op.width * obscured;
      const maxX = 1 - (1 - obscured) * op.width;

      const minY = -op.height * obscured;
      const maxY = 1 - (1 - obscured) * op.height;

      np.x = clamp(op.x + rx, minX, maxX);
      np.y = clamp(op.y + ry, minY, maxY);

      if (this.args.move) {
        this.args.move(np);
      }
    };

    const up = (): void => {
      unbind();
      if (this.dragging) {
        this.dragging = false;
        if (this.args.moveEnd) {
          this.args.moveEnd();
        }
      }
    };

    const unbind = (): void => {
      document.removeEventListener("mousemove", drag);
      document.removeEventListener("mouseup", up);
    };

    document.addEventListener("mousemove", drag);
    document.addEventListener("mouseup", up);
  }

  @action
  closeMenu(): void {
    this.isRightClickMenuOpen = false;
  }

  get justifyContent(): string {
    return this.args.justifyContent ?? "flex-start";
  }

  get clippedStyleAttr(): SafeString {
    const [x1, y1, x2, y2] = this.regionClippedBounds;
    const percent = (n: number): string => `${Number(n * 100)}%`;
    const path = [
      [x1, y1],
      [x2, y1],
      [x2, y2],
      [x1, y2]
    ]
      .map((pos) => pos.map(percent).join(" "))
      .join(", ");

    return htmlSafe(`
      clip-path: polygon(${path}); -webkit-clip-path: polygon(${path}); justify-content: ${this.justifyContent}
    `);
  }

  get styleAttr(): SafeString {
    const { x, y, width, height } = this.args.region;

    return htmlSafe(`
      top: ${y * 100}%;
      left: ${x * 100}%;
      width: ${width * 100}%;
      height: ${height * 100}%;
    `);
  }

  get ariaSelected(): string {
    return this.args.selected ? "true" : "false";
  }

  /**
   * Calculate the visible region bounds relative to the canvas area
   *
   *   [x1,y1]------[x2,y1]
   *      |            |
   *      |   Region   |
   *      |            |
   *   [x1,y2]------[x2,y2]
   */
  get regionClippedBounds(): [number, number, number, number] {
    const { x, width } = this.args.region;
    const clippedRegion = intersection({ x, width, y: 0, height: 1 }, unitGeometry);
    return toCoOrdinates(convertRect(clippedRegion, this.args.region, unitGeometry));
  }

  @action
  handleResizeStart(): void {
    const { x, y, width, height } = this.args.region;

    Object.assign(this.originalPosition, { x, y, width, height });

    if (this.args.resizeStart) {
      this.args.resizeStart();
    }
  }

  @action
  handleResize(resizingFrom: ResizePositions, { xDelta, yDelta }: ResizeDelta): void {
    const isScaling = isCorner(resizingFrom);

    const [rx, ry] = this.getRelativeScales(xDelta, yDelta);
    const position = this.getNewPosition(resizingFrom, rx, ry, isScaling);

    if (this.args.resize) {
      const source = isScaling ? Sources.SCALER : Sources.RESIZER;

      this.args.resize(source, position);
    }
  }

  get actionsPosition(): SafeString {
    const [, topClip, rightClip] = this.regionClippedBounds;
    const buttonHeightPx = 40;
    const regionXInPixels = this.args.region.x * this.canvasDimensions.width;
    const regionYInPixels = this.args.region.y * this.canvasDimensions.height;
    const regionWidthInPixels = this.args.region.width * this.canvasDimensions.width;
    const regionHeightInPixels = this.args.region.height * this.canvasDimensions.height;

    let offsetPercent, leftOffsetPx, topOffsetPx;

    if (this.args.isBackground) {
      // Pins the action buttons to the top right corner
      offsetPercent = 0;
      leftOffsetPx = -1 * (this.canvasDimensions.width - regionXInPixels - regionWidthInPixels);
      topOffsetPx = -1 * regionYInPixels;
    } else {
      // Selected element's clipped area in pixels.
      const yClippedRegionPixels = topClip * regionHeightInPixels;
      // Percentage of the height of the action buttons to shift downwards. Responsible for keeping the buttons on the
      // canvas bounds, even if the top of the element is off canvas. -100% is above the bounds, 0 is below bounds
      // Defaults to -100 if the region height/width is missing
      offsetPercent =
        this.canvasDimensions.height === 0 ? -100 : clamp((yClippedRegionPixels / buttonHeightPx) * 100, -100, 0);

      leftOffsetPx = (1 - Math.max(rightClip, 0)) * regionWidthInPixels;
      topOffsetPx = (topClip >= 0 ? topClip : 0) * regionHeightInPixels;
    }

    return htmlSafe(
      `top: 0%; transform: translate(calc(-100% - ${leftOffsetPx}px), calc(${offsetPercent}% + ${topOffsetPx}px));`
    );
  }

  get highestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.highestLayer();
    }
    return false;
  }

  get lowestLayer(): boolean {
    if (this.args.layer) {
      return this.args.layer.lowestLayer();
    }
    return false;
  }

  @action
  handleResizeEnd(): void {
    this.args.resizeEnd?.();
  }

  @action
  async handleDuplicate(): Promise<void> {
    this.args.duplicate?.();
  }

  @action
  async handleDuplicateShortcut(event: Event): Promise<void> {
    if (this.args.selected) {
      event.preventDefault();

      if (this.args.canDuplicate) {
        await this.handleDuplicate();
      }
    }
  }

  @action
  async handleMoveToForeground(): Promise<void> {
    this.args.moveToForeground?.();
  }

  @action
  async handleReplace(event: Event): Promise<void> {
    this.args.replace?.(event);
  }

  @action
  async handleMoveToBackground(): Promise<void> {
    this.args.moveToBackground?.();
  }

  @action
  async handleResetAspectRatio(): Promise<void> {
    this.args.resetAspectRatio?.();
  }

  @action
  handleRemove(): void {
    this.args.remove?.();
  }

  @action
  handleFitToClip(): void {
    this.args.fitToClip?.();
  }

  @action
  handleEditTiming(): void {
    this.args.editTiming?.();
  }

  @action
  @transaction
  async moveLayerBackward(): Promise<void> {
    await this.args.layer?.moveLayerBackward();
  }

  @action
  async moveLayerBackwardShortcut(event: Event): Promise<void> {
    if (this.args.selected) {
      event.preventDefault();

      if (this.canLayer && !this.lowestLayer) {
        await this.moveLayerBackward();
      }
    }
  }

  @action
  @transaction
  async moveLayerForward(): Promise<void> {
    await this.args.layer?.moveLayerForward();
  }

  @action
  async moveLayerForwardShortcut(event: Event): Promise<void> {
    if (this.args.selected) {
      event.preventDefault();

      if (this.canLayer && !this.highestLayer) {
        await this.moveLayerForward();
      }
    }
  }

  @action
  @transaction
  async moveLayerToFront(): Promise<void> {
    await this.args.layer?.moveLayerToFront();
  }

  @action
  @transaction
  async moveLayerToBack(): Promise<void> {
    await this.args.layer?.moveLayerToBack();
  }

  getRelativeScales(xDelta: number, yDelta: number): [number, number] {
    const ry = this.getRelativeY(yDelta);
    const rx = this.getRelativeX(xDelta);

    return [rx, ry];
  }

  getNewPosition(resizingFrom: ResizePositions, rx: number, ry: number, scale: boolean): Bounds {
    if (scale) {
      return this.applyScale(resizingFrom, rx, ry);
    }

    return this.applyResize(resizingFrom, rx, ry);
  }

  applyResize(resizingFrom: ResizePositions, rx: number, ry: number): Bounds {
    const { originalPosition: origPos } = this;
    const newPos = { ...origPos };

    const y = origPos.y + ry;
    const x = origPos.x + rx;
    const height = origPos.height + ry;
    const width = origPos.width + rx;

    const deltaY = origPos.y - y;
    const deltaX = origPos.x - x;

    // Top or Bottom
    switch (resizingFrom) {
      case ResizePositions.TOP_LEFT:
      case ResizePositions.TOP:
      case ResizePositions.TOP_RIGHT: {
        if (newPos.height + deltaY > MIN_DIMENSION) {
          newPos.y -= deltaY;
        } else {
          newPos.y = origPos.y + origPos.height - MIN_DIMENSION;
        }
        newPos.height = Math.max(newPos.height + deltaY, MIN_DIMENSION);
        break;
      }

      case ResizePositions.BOTTOM_RIGHT:
      case ResizePositions.BOTTOM:
      case ResizePositions.BOTTOM_LEFT: {
        newPos.height = Math.max(height, MIN_DIMENSION);
        break;
      }
    }

    // Left or Right
    switch (resizingFrom) {
      case ResizePositions.TOP_LEFT:
      case ResizePositions.LEFT:
      case ResizePositions.BOTTOM_LEFT: {
        if (newPos.width + deltaX > MIN_DIMENSION) {
          newPos.x -= deltaX;
        } else {
          newPos.x = origPos.x + origPos.width - MIN_DIMENSION;
        }
        newPos.width = Math.max(newPos.width + deltaX, MIN_DIMENSION);
        break;
      }

      case ResizePositions.TOP_RIGHT:
      case ResizePositions.RIGHT:
      case ResizePositions.BOTTOM_RIGHT: {
        newPos.width = Math.max(width, MIN_DIMENSION);
        break;
      }
    }

    return newPos;
  }

  applyScale(resizingFrom: ResizePositions, rx: number, ry: number): Bounds {
    const newPos = this.applyResize(resizingFrom, rx, ry);
    const { originalPosition: origPos } = this;

    const xRatio = origPos.width / origPos.height;
    const yRatio = 1 / xRatio;

    const scaleY = newPos.height / origPos.height;
    const scaleX = newPos.width / origPos.width;
    const factor = (scaleY + scaleX) / 2;

    const { top, left, right, bottom } = getBoundsFromPosition(origPos);

    const minDimension = MIN_DIMENSION;

    const minWidthFromRight = -left * 2;
    const minWidthFromLeft = (right - 1) * 2;

    let minWidth = Math.max(minDimension, minWidthFromLeft, minWidthFromRight);

    const minHeightFromTop = -top * 2;
    const minHeightFromBottom = (bottom - 1) * 2;

    let minHeight = Math.max(minDimension, minHeightFromTop, minHeightFromBottom);

    const minRatio = minWidth / minHeight;

    if (minRatio > xRatio) {
      minHeight = minWidth * yRatio;
    }

    if (minRatio < xRatio) {
      minWidth = minHeight * xRatio;
    }

    switch (resizingFrom) {
      case ResizePositions.TOP_LEFT: {
        newPos.width = Math.max(minWidth, origPos.width * factor);
        newPos.x = origPos.x + (origPos.width - newPos.width);
        newPos.height = Math.max(minHeight, origPos.height * factor);
        newPos.y = origPos.y + (origPos.height - newPos.height);

        break;
      }

      case ResizePositions.TOP_RIGHT: {
        newPos.width = Math.max(minWidth, origPos.width * factor);
        newPos.height = Math.max(minHeight, origPos.height * factor);
        newPos.y = origPos.y + (origPos.height - newPos.height);
        break;
      }

      case ResizePositions.BOTTOM_RIGHT: {
        newPos.width = Math.max(minWidth, origPos.width * factor);
        newPos.height = Math.max(minHeight, origPos.height * factor);
        break;
      }

      case ResizePositions.BOTTOM_LEFT: {
        newPos.width = Math.max(minWidth, origPos.width * factor);
        newPos.x = origPos.x + (origPos.width - newPos.width);
        newPos.height = Math.max(minHeight, origPos.height * factor);
        break;
      }
    }

    return newPos;
  }

  get pasteDisabled(): boolean {
    return !this.copyPaste.copiedObject || !this.copyPaste.allowCopyPaste;
  }

  @action
  async onCopy(e: Event): Promise<void> {
    if (this.args.copy) {
      await this.args.copy(e, this.copyPasteOrigin);
      this.copyPasteOrigin = "right-click-menu";
    }
  }

  @action
  async onPaste(e: Event): Promise<void> {
    if (this.args.paste && !this.pasteDisabled) {
      await this.args.paste(e, this.copyPasteOrigin);
      this.copyPasteOrigin = "right-click-menu";
    }
  }
}
