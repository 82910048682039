import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import type { SafeString } from "handlebars";
import config from "client/config/environment";
import { CTAContext } from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type PermissionsService from "client/services/permissions";
import type VideoPlayerService from "client/services/video-player";

interface VideoOverlayComponentArgs {
  upgradeRequired: boolean;
  hideButton: boolean;
  isButtonDisabled: boolean;
  projectRender?: ProjectRender;
  isRendering: boolean;
  project: Project;
  renderFailed: boolean;
  renderProject: (ctaContext: string) => Promise<void>;
  renderRequired: boolean;
  renderStarting: boolean;
}

export default class VideoOverlayComponent extends Component<VideoOverlayComponentArgs> {
  @service
  declare videoPlayer: VideoPlayerService;

  @service
  declare permissions: PermissionsService;

  renderMessages = config.renderScreenMessages;

  styleNamespace = getStyleNamespace("export/video-overlay");

  @action
  async onBuildVideo(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_BUILD_VIDEO);
  }

  @action
  async onUpdateVideo(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_UPDATE);
  }

  @action
  async onRestartBuild(): Promise<void> {
    await this.args.renderProject(CTAContext.CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_RESTART_BUILD);
  }

  get overlayImage(): SafeString {
    const { height, width } = this.args.project.renderAspectRatio;
    return htmlSafe(`${config.rootURL}assets/images/overlays/${width}x${height}.svg`);
  }

  get restartBuildDisabled(): boolean {
    return this.args.renderStarting || this.args.isButtonDisabled;
  }

  get isDisabled(): boolean {
    return this.args.isButtonDisabled || !this.permissions.has("feature_project_render");
  }
}
