import LibraryCategory from "client/models/library-category";

export const ImagesCategories = [
  new LibraryCategory(
    "happy positive fun",
    "Happy & positive",
    "https://images.pexels.com/photos/136412/pexels-photo-136412.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Happy"
  ),
  new LibraryCategory(
    "money business",
    "Money & business",
    "https://images.pexels.com/photos/4386431/pexels-photo-4386431.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Business"
  ),
  new LibraryCategory(
    "workplace office",
    "Workplace",
    "https://images.pexels.com/photos/1367276/pexels-photo-1367276.jpeg?auto=compress&cs=tinysrgb&h=350"
  ),
  new LibraryCategory(
    "teamwork productivity teams",
    "Teamwork & productivity",
    "https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Teamwork"
  ),
  new LibraryCategory(
    "friends family",
    "Friends & family",
    "https://images.pexels.com/photos/708440/pexels-photo-708440.jpeg?auto=compress&cs=tinysrgb&h=350"
  ),
  new LibraryCategory(
    "technology computer laptop mobile cell phone",
    "Technology",
    "https://images.pexels.com/photos/270694/pexels-photo-270694.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Tech"
  ),
  new LibraryCategory(
    "people person woman man child",
    "People",
    "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&h=350"
  ),
  new LibraryCategory(
    "animal nature world",
    "Animals & nature",
    "https://images.pexels.com/photos/733090/pexels-photo-733090.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Nature"
  ),
  new LibraryCategory(
    "food beverage drink",
    "Food & beverage",
    "https://images.pexels.com/photos/6342179/pexels-photo-6342179.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Food"
  ),
  new LibraryCategory(
    "profession jobs career",
    "Professions",
    "https://images.pexels.com/photos/3761508/pexels-photo-3761508.jpeg?auto=compress&cs=tinysrgb&h=350"
  ),
  new LibraryCategory(
    "health medicine wellness",
    "Health & medicine",
    "https://images.pexels.com/photos/4386466/pexels-photo-4386466.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Health"
  ),
  new LibraryCategory(
    "holiday celebration",
    "Holidays & celebration",
    "https://images.pexels.com/photos/8014841/pexels-photo-8014841.jpeg?auto=compress&cs=tinysrgb&h=350",
    "Holiday"
  )
];

export const PexelsVideosCategories = [
  new LibraryCategory(
    "happy positive fun",
    "Happy & positive",
    "https://images.pexels.com/photos/1263986/pexels-photo-1263986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Happy"
  ),
  new LibraryCategory(
    "money business",
    "Money & business",
    "https://images.pexels.com/photos/12337706/pexels-photo-12337706.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Business"
  ),
  new LibraryCategory(
    "workplace office",
    "Workplace",
    "https://images.pexels.com/photos/2422276/pexels-photo-2422276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  ),
  new LibraryCategory(
    "teamwork productivity teams",
    "Teamwork & productivity",
    "https://images.pexels.com/photos/7551442/pexels-photo-7551442.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Teamwork"
  ),
  new LibraryCategory(
    "friends family",
    "Friends & family",
    "https://images.pexels.com/photos/4260639/pexels-photo-4260639.jpeg?auto=compress&cs=tinysrgb&w=800"
  ),
  new LibraryCategory(
    "technology computer laptop mobile cell phone",
    "Technology",
    "https://images.pexels.com/photos/1476321/pexels-photo-1476321.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Tech"
  ),
  new LibraryCategory(
    "people person woman man child",
    "People",
    "https://images.pexels.com/photos/774866/pexels-photo-774866.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  ),
  new LibraryCategory(
    "animal nature world",
    "Animals & nature",
    "https://images.pexels.com/photos/459326/pexels-photo-459326.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Nature"
  ),
  new LibraryCategory(
    "food beverage drink",
    "Food & beverage",
    "https://images.pexels.com/photos/4393021/pexels-photo-4393021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Food"
  ),
  new LibraryCategory(
    "profession jobs career",
    "Professions",
    "https://images.pexels.com/photos/3771045/pexels-photo-3771045.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  ),
  new LibraryCategory(
    "health medicine wellness",
    "Health & medicine",
    "https://images.pexels.com/photos/3683074/pexels-photo-3683074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Health"
  ),
  new LibraryCategory(
    "holiday celebration",
    "Holidays & celebration",
    "https://images.pexels.com/photos/17796/christmas-xmas-gifts-presents.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "Holiday"
  )
];

export const VideosCategories = [
  new LibraryCategory(
    "happy positive fun",
    "Happy & positive",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/2O0VXeG/happy-party-girls-having-fun-dancing-using-props-in-party-photo-booth_rql83lbrlg__d_thumbnail-180_01.jpg",
    "Happy"
  ),
  new LibraryCategory(
    "money business",
    "Money & business",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/HJpjKMlmgiw923pte/videoblocks-man-hand-holding-coin-dollar-on-money-coin-stack-arranged-as-a-graph-on-wooden-table-hand-of-male-or-female-finger-step-coins-stack-growing-growth-saving-money-business-idea_s2nzdssmz_thumbnail-180_01.jpg",
    "Business"
  ),
  new LibraryCategory(
    "workplace office",
    "Workplace",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/BmI6UBJLWj5dnkc7q/videoblocks-front-view-of-cheerful-good-looking-relaxed-young-black-skinned-male-office-manager-in-stylish-wear-which-using-his-phone-sitting-at-his-workplace-in-office_skjhsl33l_thumbnail-180_01.jpg"
  ),
  new LibraryCategory(
    "teamwork productivity teams",
    "Teamwork & productivity",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/NrM0QFs3gilniuv44/videoblocks-business-team-meeting-brainstorming-sharing-new-ideas-diverse-team-involved-in-teamwork-in-trendy-brick-office-space-steadicam-shot-across-boardroom-table-shared-work-space_szdcoecxg_thumbnail-180_06.jpg",
    "Teamwork"
  ),
  new LibraryCategory(
    "friends family",
    "Friends & family",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/GTYSdDW/videoblocks-slow-motion-of-girl-weared-sunglasses-friends-laughing-in-speed-motorboat_suomnwfmw_thumbnail-180_01.jpg"
  ),
  new LibraryCategory(
    "technology computer laptop mobile cell phone",
    "Technology",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/--DrDo3/young-female-doctor-working-on-smart-phone-and-laptop-in-the-medical-office_htxip1efbl_thumbnail-180_01.jpg",
    "Tech"
  ),
  new LibraryCategory(
    "people person woman man child",
    "People",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/HjH5lgeHeix7kfhup/videoblocks-happy-multiethnic-people-in-3d-glasses-watching-comedy-in-cinema-portrait-of-cheerful-children-and-adults-enjoying-film-in-movie-theatre-positive-children-eating-popcorn-at-front-and-laughing_htaojccfd_thumbnail-180_01.jpg"
  ),
  new LibraryCategory(
    "animal nature world",
    "Animals & nature",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/Vd3bj2jPe/videoblocks-big-fish-in-ocean-aquarium-fish-with-stripes-swimming-in-aquarium-beautiful-underwater-world-tropical-colorful-fish-swimming-in-sea-depth_bqgnt-exrv_thumbnail-180_01.jpg",
    "Nature"
  ),
  new LibraryCategory(
    "food beverage drink",
    "Food & beverage",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/Bc8HsfrgXji2uptsg/videoblocks-glass-with-berry-smoothieand-mint-leaves-healthy-fresh-bio-yogurt-of-berries-top-view-slow-motion-video_bygr-0fuoe_thumbnail-180_01.jpg",
    "Food"
  ),
  new LibraryCategory(
    "profession jobs career",
    "Professions",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/V1xq1AADx/stylish-brunette-working-from-home-in-her-home-office_hy7nlyt_thumbnail-180_01.jpg"
  ),
  new LibraryCategory(
    "health medicine wellness",
    "Health & medicine",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/qmraJpx/videoblocks-young-asian-female-doctor-wearing-scrubs_h_monsjyjq_thumbnail-180_01.jpg",
    "Health"
  ),
  new LibraryCategory(
    "holiday celebration",
    "Holidays & celebration",
    "https://dm0qx8t0i9gc9.cloudfront.net/thumbnails/video/49EwKH5deijotr386/videoblocks-different-cupcakes-with-sparklers-idea-for-holidays-birthdays-anniversaries-celebrations-and-parties_stoiudlwm_thumbnail-180_01.jpg",
    "Holiday"
  )
];

export const ShutterstockCategories = [
  new LibraryCategory(
    "happy positive fun",
    "Happy & positive",
    "https://ak.picdn.net/shutterstock/videos/1043524426/thumb/4.jpg?ip=x480"
  ),
  new LibraryCategory(
    "money business",
    "Money & business",
    "https://ak.picdn.net/shutterstock/videos/1054702004/thumb/1.jpg?ip=x480"
  ),
  new LibraryCategory(
    "workplace office",
    "Workplace",
    "https://ak.picdn.net/shutterstock/videos/1034587589/thumb/8.jpg?ip=x480"
  ),
  new LibraryCategory(
    "teamwork productivity teams",
    "Teamwork & productivity",
    "https://ak.picdn.net/shutterstock/videos/1010436569/thumb/1.jpg?ip=x480"
  ),
  new LibraryCategory(
    "friends family",
    "Friends & family",
    "https://ak.picdn.net/shutterstock/videos/1042559557/thumb/1.jpg?ip=x480"
  ),
  new LibraryCategory(
    "technology computer laptop mobile cell phone",
    "Technology",
    "https://ak.picdn.net/shutterstock/videos/1056029879/thumb/6.jpg?ip=x480"
  ),
  new LibraryCategory(
    "people person woman man child",
    "People",
    "https://ak.picdn.net/shutterstock/videos/1056346322/thumb/8.jpg?ip=x480"
  ),
  new LibraryCategory(
    "animal nature world",
    "Animals & nature",
    "https://ak.picdn.net/shutterstock/videos/1032988694/thumb/10.jpg?ip=x480"
  ),
  new LibraryCategory(
    "food beverage drink",
    "Food & beverage",
    "https://ak.picdn.net/shutterstock/videos/1054912820/thumb/4.jpg?ip=x480"
  ),
  new LibraryCategory(
    "profession jobs career",
    "Professions",
    "https://ak.picdn.net/shutterstock/videos/27800563/thumb/1.jpg?ip=x480"
  ),
  new LibraryCategory(
    "health medicine wellness",
    "Health & medicine",
    "https://ak.picdn.net/shutterstock/videos/1060503751/thumb/1.jpg?ip=x480"
  ),
  new LibraryCategory(
    "holiday celebration",
    "Holidays & celebration",
    "https://ak.picdn.net/shutterstock/videos/1014203564/thumb/11.jpg?ip=x480"
  )
];
