import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type User from "client/models/user";

export default class ProjectTemplateSettingsModalService extends Service {
  @service
  declare store: Store;

  @tracked
  private templateSettingsVisible = false;

  @tracked
  project?: Project;

  @tracked
  template = false;

  get visible(): boolean {
    return this.templateSettingsVisible;
  }

  get canToggleTemplate(): boolean {
    if (this.template) {
      return true;
    }

    return this.latestRenderSuccessful && this.renderIsCurrent;
  }

  get latestRenderSuccessful(): boolean {
    return !!(this.project?.belongsTo("latestRender").value() as ProjectRender)?.successful;
  }

  get renderIsCurrent(): boolean {
    return !!this.project?.renderIsCurrent;
  }

  get projectOwner(): User {
    return this.project?.belongsTo("user").value() as User;
  }

  private get hasTemplate(): boolean {
    return !!this.project?.belongsTo("projectTemplate").value();
  }

  async open(projectId: string): Promise<void> {
    this.project = await this.store.findRecord("project", projectId);
    await Promise.all([
      this.project.projectTemplate,
      this.project.latestRender,
      this.project.projectDigest,
      this.project.user
    ]);
    this.template = this.hasTemplate;
    this.templateSettingsVisible = true;
  }

  close(): void {
    this.templateSettingsVisible = false;
  }

  async save(): Promise<void> {
    if (this.project) {
      if (this.template) {
        if (!this.hasTemplate) {
          await this.store
            .createRecord("projectTemplate", {
              project: this.project
            })
            .save();
        }
      } else if (this.hasTemplate) {
        const projectTemplate = await this.project.projectTemplate;

        if (projectTemplate) {
          await projectTemplate.destroyRecord();
        }
      }

      await this.project.reload();
    }
  }

  toggleTemplate(): void {
    this.template = !this.template;
  }
}
