import { getOwner } from "@ember/application";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type CurrentUserService from "./current-user";
import type PermissionsService from "./permissions";
import type UserPreferenceService from "./user-preference";
import BrandQueryRequest from "client/lib/brand/brand-query-request";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import { MAX_COLOR_PRESET_COUNT } from "client/lib/brand/brand-style-config";
import ColorPresetGenerator from "client/lib/brand/color-preset-generator";
import publicEmailDomain from "client/lib/public-email-domain";
import type BrandStyle from "client/models/brand-style";
import type HoneybadgerService from "client/services/honeybadger";

export default class BrandStyleService extends Service {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  @service
  private declare userPreference: UserPreferenceService;

  @tracked
  defaultBrand?: BrandStyle;

  get defaultBrandId(): string | undefined {
    return this.userPreference.preference?.brandStyleId;
  }

  async getBrand(id: string | undefined = undefined): Promise<BrandStyle | undefined> {
    if (id && this.permissions.has("feature_multiple_brands")) {
      return this.store.findRecord("brandStyle", id, { reload: true });
    } else {
      // eslint-disable-next-line camelcase
      const brands = (await this.store.query("brandStyle", { per_page: 1 })) as unknown as Array<BrandStyle>;
      return (this.defaultBrand ??= brands?.[0]);
    }
  }

  public getDefault(): BrandStyle | undefined {
    return this.defaultBrand;
  }

  public setDefault(BrandStyle: BrandStyle): void {
    this.defaultBrand = BrandStyle;

    void this.userPreference.setValue("brandStyleId", BrandStyle.id);
  }

  // eslint-disable-next-line camelcase
  async getBrands(queryOptions: object = { per_page: 96 }): Promise<Array<BrandStyle>> {
    const brandStyles = this.store.query("brandStyle", queryOptions);
    // @ts-expect-error
    return brandStyles;
  }

  async fetchUniversalBrand(): Promise<BrandStyle | undefined> {
    try {
      const globalBrandStyle = await this.store.findRecord("globalBrandStyle", 1);
      const brandStyle = globalBrandStyle.toBrandStyle();

      return brandStyle;
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, {
        message: "Could not fetch universal brand",
        name: "UniversalBrandFetch"
      });
    }

    return undefined;
  }

  async createDefaultBrand(): Promise<BrandStyle | undefined> {
    if (!this.permissions.has("feature_brand_edit")) {
      return undefined;
    }

    const { user } = this.currentUser;

    if (!user) {
      return undefined;
    }

    const { emailDomain } = user;

    if (publicEmailDomain(emailDomain, true)) {
      return this.createUniversalBrand();
    } else {
      const brandStyle = await this.fetchBrandForDomain(emailDomain);

      if (brandStyle) {
        return brandStyle;
      }
      // Their brand is a miss or does not have enough colours or logos
      else {
        return this.createUniversalBrand();
      }
    }
  }

  private async createUniversalBrand(): Promise<BrandStyle | undefined> {
    const brandStyle = await this.fetchUniversalBrand();

    if (brandStyle) {
      return this.saveBrandStyle(brandStyle);
    } else {
      return undefined;
    }
  }

  private async fetchBrandForDomain(domain: string): Promise<BrandStyle | undefined> {
    try {
      const brandStyle = this.store.createRecord("brandStyle");
      const query = new BrandQueryRequest(getOwner(this), domain);
      query.setEventToAutoQuery();

      const styleConfig = await query.invoke();
      brandStyle.style = styleConfig;

      await this.extractLogoColors(styleConfig);

      await this.setDefaultColorPresets(brandStyle);

      return await this.saveBrandStyle(brandStyle);
    } catch (error) {
      return undefined;
    }
  }

  private async setDefaultColorPresets(brandStyle: BrandStyle): Promise<void> {
    const generator = new ColorPresetGenerator(brandStyle.style);

    await generator.generate();

    // Populate presets when a brand fetch result succeeds but there is no logo eg beacon-hr.com
    brandStyle.style.colorPresets = generator.getUnusedColorPresets(MAX_COLOR_PRESET_COUNT);
  }

  private async extractLogoColors(styleConfig: BrandStyleConfig): Promise<void> {
    const defaultLogo = await styleConfig.getDefaultLogo();

    if (defaultLogo) {
      const logoColors = await defaultLogo.getLogoColors();

      styleConfig.addDistinctColors(logoColors);
    }
  }

  private async saveBrandStyle(brandStyle: BrandStyle): Promise<BrandStyle> {
    await brandStyle.style.saveLogoUserAssets(this);

    return brandStyle.save();
  }
}
