import Model, { attr } from "@ember-data/model";

export default class Storyblock extends Model {
  @attr("string")
  type!: string;

  @attr("string")
  projectId!: string;

  @attr("string")
  url!: string;

  @attr("string")
  previewUrl!: string;

  @attr("string")
  downsizedUrlBase?: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    storyblock: Storyblock;
  }
}
