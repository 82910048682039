export enum ScrollAxis {
  X = "x",
  Y = "y"
}

const getScrollParentElement = (el: HTMLElement, axis = ScrollAxis.Y): HTMLElement | undefined => {
  if (!el) {
    return undefined;
  }

  const styleProperty = axis === ScrollAxis.Y ? "overflow-y" : "overflow-x";
  const overflowStyle = getComputedStyle(el).getPropertyValue(styleProperty);

  if (["auto", "scroll"].includes(overflowStyle)) {
    return el;
  }

  if (el.parentElement) {
    return getScrollParentElement(el.parentElement, axis);
  }

  return undefined;
};

export default getScrollParentElement;
