import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

interface EndscreenPreviewComponentArgs {
  button: string;
  message: string;
  project: Project;
  url: string;
  enableBannerCta: boolean;
  enableEndscreenCta: boolean;
}

export default class EndscreenPreviewComponent extends Component<EndscreenPreviewComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/endscreen-preview");

  get message(): string {
    return this.args.message.length > 60 ? this.args.message.substring(0, 60) + "..." : this.args.message;
  }

  get button(): string {
    return this.args.button.length > 20 ? this.args.button.substring(0, 20) + "..." : this.args.button;
  }
}
