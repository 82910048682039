import type { DrawableAsset, RenderZymbol, AssetSizes } from "renderer-engine";
import { Assets, Player, AssetTypes } from "renderer-engine";
import type Zymbol from "client/models/zymbol";

export const loadZymbolAsset = async (
  zymbol: Zymbol | RenderZymbol,
  assetSize: AssetSizes,
  type: AssetTypes = AssetTypes.VIDEO,
  elapsedTime: number,
  perSceneAssets = false
): Promise<DrawableAsset[]> => {
  try {
    let assetTime = 0;
    const config = Player.getZymbolToRenderConfig(zymbol);
    if ("duration" in config && config.duration) {
      assetTime = Player.getVideoAssetTime(zymbol, config.duration, elapsedTime);
    }
    return await Assets.loadAsset(zymbol, assetSize, type, assetTime, perSceneAssets);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("Error loading asset", err);
    return [];
  }
};
