import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { groupBy } from "client/lib/group-by";
import { sortBy, uniq } from "client/models/collections";
import type Plan from "client/models/plan";
import { PlanInterval } from "client/models/plan";
import type PlanFeature from "client/models/plan-feature";

interface PlansTableFeaturesArgs {
  intervalPlans: Plan[];
  interval: PlanInterval;
  intervalPlanFeatureCategories: any;
}

type PlanFeatureByInterval = { [key in PlanInterval]: PlanFeature[] };

export default class PlansTableFeaturesComponent extends Component<PlansTableFeaturesArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-table-features");

  get columnsCount(): number {
    return this.args.intervalPlans.length + 1;
  }

  get intervalPlanFeatureCategories(): PlanFeature[] {
    return this.planFeatureCategoriesByInterval[this.args.interval];
  }

  get planFeatureCategoriesByInterval(): PlanFeatureByInterval {
    const monthly = sortBy(uniq(this.selectFeatures(this.args.intervalPlans, PlanInterval.MONTH)), "displayOrder");
    const groupedMonthly = groupBy(monthly, "category");

    const yearly = sortBy(uniq(this.selectFeatures(this.args.intervalPlans, PlanInterval.YEAR)), "displayOrder");
    const groupedYearly = groupBy(yearly, "category");

    return {
      [PlanInterval.MONTH]: groupedMonthly,
      [PlanInterval.YEAR]: groupedYearly
    };
  }

  selectFeatures(plans: Plan[], selectedInterval: PlanInterval): PlanFeature[] {
    return plans
      .filter((plan) => plan.interval === selectedInterval)
      .map(({ planFeatureValues }) => planFeatureValues.map((pfv) => pfv.planFeature))
      .reduce((a, b) => a.concat(b), []);
  }
}
