import { service } from "@ember/service";
import Model, { attr, belongsTo } from "@ember-data/model";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type PlansService from "client/services/plans";

export interface Phase {
  coupon?: string;
  startDate: number;
  endDate: number;
  items: Array<{ plan: string }>;
}

export default class SubscriptionSchedule extends Model {
  @service
  declare plans: PlansService;

  @attr("string")
  declare subscriptionScheduleId: string;

  @belongsTo("subscription", { async: false, inverse: "subscriptionSchedule" })
  declare subscription: Subscription;

  @attr("array")
  declare phases: Array<Phase>;

  @attr("date")
  declare scheduledPhaseStartDate?: Date;

  get nextPlan(): Plan | undefined {
    return this.plans.findPlanById(this.nextPlanId);
  }

  private get nextPhase(): Phase {
    return this.phases.slice(-1)[0]!;
  }

  private get nextPlanId(): string {
    return this.nextPhase.items[0]!.plan;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    subscriptionSchedule: SubscriptionSchedule;
  }
}
