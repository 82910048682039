import Tracker from "@openreplay/tracker";
import { isProdEnv } from "client/lib/env";

class OpenReplayTracker {
  private _tracker!: Tracker;

  initialize(): void {
    if (!this._tracker && isProdEnv()) {
      this._tracker = new Tracker({
        projectKey: "GcSMDaYxCtIRuQ03qyam",
        defaultInputMode: 0,
        network: {
          sessionTokenHeader: false,
          failuresOnly: true,
          ignoreHeaders: ["Cookie", "Set-Cookie", "Authorization"],
          capturePayload: false,
          captureInIframes: true
        }
      });
      void this._tracker.start();
    }
  }

  get tracker(): Tracker {
    return this._tracker;
  }
}

export default new OpenReplayTracker();
