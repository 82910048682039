import { set, action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

enum UITooltipPosition {
  RIGHT = "right",
  BOTTOM = "bottom",
  TOP = "top"
}

enum UITooltipSize {
  FIT = "fit",
  FIXED = "fixed"
}

enum UITooltipTheme {
  DARK = "dark",
  LIGHT = "light"
}

interface UITooltipArgs {
  description: string;
  dismissOnClick?: boolean;
  hide?: boolean;
  position?: UITooltipPosition;
  size?: UITooltipSize;
  theme?: UITooltipTheme;
  title: string;
  visible?: boolean;
  interactive?: boolean;
}

export default class UITooltipComponent extends Component<UITooltipArgs> {
  tooltipId = Math.random().toString(36).substr(2, 9);

  tooltipElement?: HTMLElement;

  @tracked
  dismissOnClick: boolean;

  @tracked
  hide: boolean;

  @tracked
  interactive: boolean;

  @tracked
  position?: UITooltipPosition;

  @tracked
  size?: UITooltipSize;

  @tracked
  visible: boolean;

  styleNamespace = getStyleNamespace("ui/ui-tooltip");

  get theme(): UITooltipTheme {
    return this.args.theme ?? UITooltipTheme.DARK;
  }

  get isPositionedRight(): boolean {
    return this.position === UITooltipPosition.RIGHT;
  }

  get isPositionedBottom(): boolean {
    return this.position === UITooltipPosition.BOTTOM;
  }

  get positionClass(): string {
    return `-${this.position}`;
  }

  get themeClass(): string {
    return `-${this.theme}`;
  }

  get sizeClass(): string {
    return `-${this.size}`;
  }

  get interactiveClass(): string {
    return this.interactive ? "-interactive" : "";
  }

  constructor(owner: any, args: UITooltipArgs) {
    super(owner, args);
    this.dismissOnClick = args.dismissOnClick ?? false;
    this.hide = args.hide ?? false;
    this.interactive = args.interactive ?? false;
    set(this, "position", args.position ?? UITooltipPosition.TOP);
    set(this, "size", args.size ?? UITooltipSize.FIT);
    this.visible = args.visible ?? false;

    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  @action
  didInsert(element: HTMLElement): void {
    const el = element.previousElementSibling;

    if (el) {
      this.bindEventsToElement(el);
    }
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    this.hideTooltip();
  }

  bindEventsToElement(element: Element): void {
    element.setAttribute("aria-labelledBy", `${this.tooltipId}`);
    element.addEventListener("mouseenter", this.showTooltip);
    if (!this.interactive) {
      element.addEventListener("mouseleave", this.hideTooltip);
    }
    if (this.dismissOnClick) {
      element.addEventListener("click", this.hideTooltip);
    }
  }

  showTooltip({ target }: Event): void {
    const rect = (target as HTMLElement).getBoundingClientRect();

    this.setTooltipStyles(rect);

    if (this.interactive) {
      document.addEventListener("click", this.hideTooltip);
    }

    this.visible = true;
  }

  hideTooltip(): void {
    this.visible = false;
    if (this.interactive) {
      document.removeEventListener("click", this.hideTooltip);
    }
  }

  setTooltipStyles(rect: DOMRect): void {
    const tooltipElement = document.getElementById(this.tooltipId) as HTMLElement;
    Object.assign(tooltipElement.style, {
      left: `${this.calculateLeftOffset(tooltipElement, rect)}px`,
      top: `${this.calculateTopOffset(tooltipElement, rect)}px`
    });
  }

  calculateLeftOffset({ offsetWidth: tooltipWidth }: HTMLElement, { left, width }: ClientRect): number {
    if (tooltipWidth / 2 > left) {
      left = tooltipWidth / 2;
    }
    return left + (this.isPositionedRight ? width : width / 2);
  }

  calculateTopOffset({ offsetHeight: tooltipHeight }: HTMLElement, { height, top }: ClientRect): number {
    let topOffset = 0;
    if (this.isPositionedRight) {
      const diff = height - tooltipHeight;
      topOffset = (Math.sign(diff) * Math.abs(diff)) / 2;
    } else if (this.isPositionedBottom) {
      topOffset = height;
    }
    return top + topOffset;
  }
}
