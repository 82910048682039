import { tracked } from "@glimmer/tracking";
import type { ColorBrandKey, ColorPreset } from "../brand";
import type { Background } from "./background";
import type { Caption } from "./caption";
import type { Transition } from "./transition";

export class Scene {
  /** @internal */
  @tracked
  _captions: Caption[] = [];

  /** @internal */
  @tracked
  _duration: number;

  /** @internal */
  @tracked
  _filterColor?: string;

  /** @internal */
  @tracked
  _color: string;

  /** @internal */
  @tracked
  _transition?: Transition;

  /** @internal */
  @tracked
  _isBrandable?: boolean;

  /** @internal */
  @tracked
  _colorPreset?: ColorPreset;

  /** @internal */
  @tracked
  _colorBrandKey?: ColorBrandKey;

  @tracked
  public aspectRatio: [width: number, height: number, widthPx: number, heightPx: number];

  @tracked
  public background: Background;

  @tracked
  public favorited = false;

  @tracked
  public teamFavorited = false;

  constructor(
    public id: string,
    duration: number,
    aspectRatio: [width: number, height: number, widthPx: number, heightPx: number],
    background: Background,
    captions: Caption[] = [],
    color = "#FFFFFF",
    filterColor?: string,
    transition?: Transition,
    isBrandable?: boolean,
    colorPreset?: ColorPreset,
    colorBrandKey?: ColorBrandKey
  ) {
    this._duration = duration;
    this.aspectRatio = aspectRatio;
    this.background = background;
    this._captions = captions;
    this._color = color;
    this._filterColor = filterColor;
    this._transition = transition;
    this._isBrandable = isBrandable;
    this._colorPreset = colorPreset;
    this._colorBrandKey = colorBrandKey;
  }

  get captions(): Caption[] {
    return this._captions.sort((a, b) => a.offset - b.offset);
  }

  get minLayerOrder(): number {
    return 0;
  }

  get maxLayerOrder(): number {
    return Math.max(0, ...this.captions.map((caption) => caption.elements.length));
  }

  public get duration(): number {
    return Number(this._duration.toFixed(2));
  }

  public get filterColor(): string | undefined {
    return this._filterColor;
  }

  public get color(): string {
    return this._color;
  }

  public get hasContent(): boolean {
    return [...this.captions, this.background].some((element) => element.hasContent);
  }

  public get transition(): Transition | undefined {
    return this._transition;
  }

  public get isBrandable(): boolean | undefined {
    return this._isBrandable;
  }

  public get colorPreset(): ColorPreset | undefined {
    return this._colorPreset && { ...this._colorPreset };
  }

  public get colorBrandKey(): ColorBrandKey | undefined {
    return this._colorBrandKey;
  }

  public get sceneColors(): string[] {
    const sceneColors = new Set<string>();
    // Get scene background color
    sceneColors.add(this._color.substr(0, 7).toUpperCase());

    for (const backgroundColors of this.background.colors) {
      sceneColors.add(backgroundColors);
    }

    for (const caption of this._captions) {
      for (const captionColors of caption.captionColors) {
        sceneColors.add(captionColors);
      }
    }
    return [...sceneColors];
  }

  public computeColorBrandKey(): void {
    if (this.colorPreset) {
      this._colorBrandKey = Scene.findColorBrandKey(this.color, this.colorPreset);
    } else {
      // This is likely not intended to be a brandable scene
      this._colorBrandKey = undefined;
      this._colorPreset = undefined;
    }
  }

  public favorite(favorites: { teamFavorited?: boolean; favorited?: boolean }): void {
    this.teamFavorited = !!favorites.teamFavorited;
    this.favorited = !!favorites.favorited;
  }

  public unfavorite(): void {
    this.teamFavorited = false;
    this.favorited = false;
  }

  public static findColorBrandKey(color: string, colorPreset: ColorPreset): ColorBrandKey {
    const isReferenceColor = (referenceColor: string): boolean => {
      return color.substring(0, 7).toUpperCase() === referenceColor.substring(0, 7).toUpperCase();
    };

    if (isReferenceColor(colorPreset.background)) {
      return "background";
    } else if (isReferenceColor(colorPreset.primary)) {
      return "primary";
    } else if (isReferenceColor(colorPreset.secondary)) {
      return "secondary";
    } else if (isReferenceColor(colorPreset.text)) {
      return "text";
    }
    return "none";
  }

  _setDuration(newDuration: number): void {
    if (this._duration !== newDuration) {
      this._duration = newDuration;
    }
  }

  /** @internal */
  _update(scene: Scene): void {
    const { _color, _filterColor, _captions, _duration, _transition, aspectRatio, background, _colorPreset } = scene;
    Object.assign(this, { _color, _filterColor, _duration, _transition, aspectRatio, _colorPreset });
    if (this.background && background && Object.getPrototypeOf(this.background) === Object.getPrototypeOf(background)) {
      this.background._update(background);
    } else {
      this.background = background;
    }

    const oldCaptions = [...this._captions];
    this._captions.length = 0;

    for (const caption of _captions) {
      const oldCaption = oldCaptions.find(({ id }) => id === caption.id);
      if (oldCaption) {
        oldCaption._update(caption);
        this._captions.push(oldCaption);
      } else {
        this._captions.push(Object.assign(caption, { _scene: this }));
      }
    }
  }
}
