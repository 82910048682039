import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface CopyLinkButtonArgs {
  link: string;
  showText?: boolean;
  onSendToClipboard?: () => void;
}

export default class CopyLinkButtonComponent extends Component<CopyLinkButtonArgs> {
  @tracked
  isLinkCopied!: boolean;

  @tracked
  copyLinkButtonText!: string;

  styleNamespace = getStyleNamespace("copy-link-button");

  constructor(owner: object, args: CopyLinkButtonArgs) {
    super(owner, args);
    this.resetCopyLink();
  }

  get showText(): boolean {
    return this.args.showText ?? true;
  }

  @action
  private resetCopyLink(): void {
    this.isLinkCopied = false;
    this.copyLinkButtonText = "Copy link";
  }

  @action
  private sendToClipboard(text: string): void {
    if (!navigator.clipboard) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } else {
      void navigator.clipboard.writeText(text);
    }
  }

  @action
  onClickCopyLink(): void {
    if (!this.isLinkCopied) {
      this.args.onSendToClipboard?.();
      this.sendToClipboard(this.args.link);
      this.isLinkCopied = true;
      this.copyLinkButtonText = "Link copied!";
      setTimeout(this.resetCopyLink, 3000);
    }
  }
}
