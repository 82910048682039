import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NewVideoActionsService from "client/services/new-video-actions";

export default class DashboardActionShortcuts extends Component {
  @service
  declare newVideoActions: NewVideoActionsService;

  @tracked
  dropdownTarget!: HTMLElement;

  @tracked
  dropdownOpen = false;

  styleNamespace = getStyleNamespace("discovery/dashboard/action-shortcuts");

  @action
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  @action
  closeDropdown(): void {
    this.dropdownOpen = false;
  }

  @action
  setDropdownTarget(element: HTMLElement): void {
    this.dropdownTarget = element;
  }

  @action
  async startFromVideoAssistant(): Promise<void> {
    await this.newVideoActions.startFromVideoAssistant();
  }

  @action
  async startFromScratch(): Promise<void> {
    await this.newVideoActions.startFromScratch();
  }

  @action
  async startFromTemplate(): Promise<void> {
    await this.newVideoActions.startFromTemplate();
  }

  @action
  async createSelfRecordProject(): Promise<void> {
    return this.newVideoActions.createSelfRecordProject();
  }

  @action
  async createCollaborativeRecordProject(): Promise<void> {
    return this.newVideoActions.createCollaborativeRecordProject();
  }
}
