import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import type { ManyArray } from "./ember-data-types";
import type Project from "client/models/project";

export const MaxMessageLength = 2500;

export default class Comment extends Model {
  // eslint-disable-next-line no-null/no-null
  @hasMany("comments", { async: true, inverse: null })
  declare comments: ManyArray<Comment>;

  @attr("string")
  declare createdAt: string;

  @attr("string")
  declare email: string;

  @attr("string")
  declare message: string;

  @attr("string")
  declare parentCommentId?: string;

  @attr("number")
  declare timestamp: number;

  @attr("string")
  declare projectId: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  get unwrappedComments(): Comment[] {
    // @ts-expect-error
    return this.hasMany("comments").value() as Comment[];
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    comment: Comment;
  }
}
