import Model, { attr } from "@ember-data/model";

export default class Category extends Model {
  @attr("number")
  declare templateCount: number;

  @attr("number")
  declare displayOrder: number;

  @attr("string")
  declare parentSlug?: string;

  @attr("string")
  declare title: string;

  @attr("string")
  declare slug: string;

  @attr("string")
  declare numericalId: string;

  @attr("boolean")
  declare readonly featured: boolean;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    category: Category;
  }
}
