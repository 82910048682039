import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Background, Logo, Text } from "client/lib/editor-domain-model";
import MediaTypes from "client/lib/media-types";
import type HoneybadgerService from "client/services/honeybadger";

interface Args {
  media: Text | Logo | Background;
  isBackground?: boolean;
}

export default class MediaActionsTrimComponent extends Component<Args> {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService;

  @action
  trimTiming(): void {
    const trimRoute = this.args.isBackground
      ? "authenticated.project.scene.background.trim"
      : "authenticated.project.scene.caption.logo.trim";

    this.router.transitionTo(trimRoute).catch((reason) => {
      // @ts-expect-error
      this.honeybadger.notify(reason);
    });
  }

  get trimName(): string {
    const mediaType = new MediaTypes(this.args.media);

    if (mediaType.isGraphic()) {
      return "Trim graphic time";
    } else {
      return "Trim clip";
    }
  }
}
