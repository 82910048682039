import type { RecordProject, RecordProjectPrompt, RecordProjectResponse } from "@biteable/network-model";
import Network from "@biteable/network-model";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type UserAsset from "client/models/user-asset";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export interface RecordRequestRouteModel {
  requestsGroupedByRespondent: UserResponsesGroupedByRespondent;
  recordProject?: RecordProject;
  numOfRespondents: number;
}

interface UserResponse {
  response: RecordProjectResponse;
  prompt: RecordProjectPrompt;
  asset: UserAsset;
}

interface UserResponsesGroupedByRespondent {
  [respondent: string]: UserResponse[];
}

export interface AssetLibraryRequestRouteParams {
  recordProjectToken: string;
}

export default class AssetLibraryRecordRequestRoute extends Route<RecordRequestRouteModel> {
  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  async model({ recordProjectToken }: AssetLibraryRequestRouteParams): Promise<RecordRequestRouteModel> {
    let requestsGroupedByRespondent: UserResponsesGroupedByRespondent = {};

    try {
      const recordProject: RecordProject | undefined = (
        await Network.store.findAll("recordProject", { token: recordProjectToken }, { force: true })
      ).data[0] as RecordProject;

      if (recordProject) {
        const unGroupedRequests = await this.requestsForProject(recordProject);
        requestsGroupedByRespondent = this.groupResponsesByEmail(unGroupedRequests);
      }

      return {
        requestsGroupedByRespondent,
        recordProject,
        numOfRespondents: Object.keys(requestsGroupedByRespondent).length
      };
    } catch (error) {
      this.notifications.error("There was a problem loading your recordings");
      // @ts-expect-error
      this.honeybadger.notify(error, "AssetLibraryRecordRequestRoute");
      throw error;
    }
  }

  async requestsForProject(nemRecordProject: RecordProject): Promise<UserResponse[]> {
    if (nemRecordProject.recordProjectPrompts) {
      const recordProjectResponses = await Promise.all(
        nemRecordProject.recordProjectPrompts.map((rpr: RecordProjectPrompt) => rpr.recordProjectResponses ?? []).flat()
      );

      return await this.buildResponses(recordProjectResponses);
    } else {
      return [];
    }
  }

  async buildResponses(recordProjectResponses: RecordProjectResponse[]): Promise<UserResponse[]> {
    return Promise.all(
      recordProjectResponses.map(async (rpr: RecordProjectResponse) => {
        const edUserAsset = await this.store.findRecord("userAsset", rpr.userAssetId);

        return {
          response: rpr,
          asset: edUserAsset,
          prompt: rpr.recordProjectPrompt
        };
      })
    );
  }

  groupResponsesByEmail(responses: UserResponse[]): UserResponsesGroupedByRespondent {
    const responsesGroupedByRespondent: UserResponsesGroupedByRespondent = {};
    responses.map((response: UserResponse) => {
      const respondent = response.response.email as string;

      if (!responsesGroupedByRespondent[respondent]) {
        responsesGroupedByRespondent[respondent] = [response];
      } else {
        responsesGroupedByRespondent[respondent] = [...responsesGroupedByRespondent[respondent]!, response];
      }
    });

    return responsesGroupedByRespondent;
  }
}
