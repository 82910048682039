import { tracked } from "@glimmer/tracking";
import { Rect } from "client/lib/editor-domain-model/scene/rect";

export interface ElementArgs {
  readonly id: string;
  position: Rect;
  customTimingOffset?: number;
  customTimingDuration?: number;
  layerOrder?: number;
}

export abstract class Element {
  @tracked
  public id: string;

  /** @internal */
  @tracked
  _position: Rect;

  /** @internal */
  @tracked
  _customTimingDuration: number | undefined;

  /** @internal */
  @tracked
  _customTimingOffset: number | undefined;

  /** @internal */
  @tracked
  _layerOrder: number;

  protected constructor(args: ElementArgs) {
    const { id, position, customTimingDuration, customTimingOffset, layerOrder } = args;

    this.id = id;
    this._position = position;
    this._customTimingOffset = customTimingOffset;
    this._customTimingDuration = customTimingDuration;
    this._layerOrder = layerOrder ?? 0;
  }

  abstract get hasContent(): boolean;

  get position(): Rect {
    return Rect.fromRect(this._position);
  }

  get customTimingOffset(): number | undefined {
    return this._customTimingOffset;
  }

  get customTimingDuration(): number | undefined {
    return this._customTimingDuration;
  }

  get customTimingEnd(): number | undefined {
    if (this.customTimingDuration == undefined /* eslint-disable-line eqeqeq */) {
      return;
    }
    return (this.customTimingOffset ?? 0) + this.customTimingDuration;
  }

  get layerOrder(): number {
    return this._layerOrder;
  }

  /** @internal */
  _centerElement(): void {
    const { width, height } = this._position;
    const newX = 0.5 - width / 2;
    const newY = 0.5 - height / 2;
    this._position = new Rect(newX, newY, width, height);
  }

  get colors(): string[] {
    return [];
  }

  abstract get canResize(): boolean;

  abstract get category(): string;

  abstract get formattedCategory(): string;

  /** @internal */
  _update(element: Element): void {
    if (this.id !== element.id) {
      console.error(`Element _update() id mismatch ${this.id} != ${element.id}`);
    }
    this._position = element._position;
  }
}
