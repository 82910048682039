import { set } from "@ember/object";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type Subscription from "client/models/subscription";

export default class ProjectDownloadEvent {
  projectRender!: ProjectRender;
  project?: Project;
  subscription?: Subscription;

  constructor(projectRender: ProjectRender) {
    set(this, "projectRender", projectRender);
  }

  get purchaseType(): string | undefined {
    if (this.subscribed) {
      return this.planId;
    } else if (this.project?.purchased && !this.projectRender.watermarked) {
      return "once";
    } else {
      return "free";
    }
  }

  get subscribed(): boolean {
    return !!this.project?.user?.get("subscribed");
  }

  get planId(): string | undefined {
    return this.subscription?.planId;
  }

  get subscriptionId(): string | undefined {
    return this.subscription?.subscriptionId;
  }

  async properties(): Promise<object> {
    set(this, "project", await this.projectRender.project);
    set(this, "subscription", await this.project!.user?.get("currentSubscription"));

    return {
      projectId: this.project!.id,
      url: this.project!.videoUrl,
      purchaseType: this.purchaseType,
      subscriptionId: this.subscriptionId,
      captions: this.projectRender.projectTranscription?.get("id") ? true : false
    };
  }
}
