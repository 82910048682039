import { TextAnimationId } from "client/lib/data/animation-text-ids";

export const TEXT_SCENE_ANIMATIONS = {
  [TextAnimationId.NONE]: {
    icon: "icon/animation/none",
    description: "No animation"
  },
  [TextAnimationId.LTR_REVEAL]: {
    icon: "icon/animation/text/ltr-reveal",
    description: "Slide in with reveal"
  },
  [TextAnimationId.LTR_SWIPE]: {
    icon: "icon/animation/text/ltr-swipe",
    description: "Swipe from left"
  },
  [TextAnimationId.SCALE_DOWN]: {
    icon: "icon/animation/text/scale-down",
    description: "Scale down"
  },
  [TextAnimationId.FADE_IN]: {
    icon: "icon/animation/text/fade-in",
    description: "Simple fade"
  },
  [TextAnimationId.LINE_RISE]: {
    icon: "icon/animation/text/line-rise",
    description: "Elegant rise"
  },
  [TextAnimationId.LETTER_RISE]: {
    icon: "icon/animation/text/letter-rise",
    description: "Letter by letter scale up rise"
  },
  [TextAnimationId.LETTER_SLIDE_IN]: {
    icon: "icon/animation/text/letter-slide-in",
    description: "Per letter slide in"
  },
  [TextAnimationId.SLIDE_AND_BOUNCE]: {
    icon: "icon/animation/text/slide-and-bounce",
    description: "Slide and bounce"
  }
};

export default TEXT_SCENE_ANIMATIONS;
