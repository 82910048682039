import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import type Project from "client/models/project";
import type TrackingService from "client/services/tracking";

export default class CollaborationService extends Service {
  @service
  declare tracking: TrackingService;

  @service
  declare store: Store;

  @service
  declare router: RouterService;

  @tracked
  project?: Project;

  @tracked
  teamInviteModalShown = false;

  @tracked
  teamInviteTrackingLocation?: string;

  @action
  showTeamInviteModal(trackingLocation?: string): void {
    this.teamInviteModalShown = true;
    this.teamInviteTrackingLocation = trackingLocation;
  }

  @action
  hideTeamInviteModal(): void {
    this.teamInviteModalShown = false;
    this.teamInviteTrackingLocation = undefined;
  }

  async shareProject(project: Project): Promise<void> {
    void this.trackToggleTeamSharing(project, TrackingEvents.EVENT_TEAM_SHARE_PROJECT, { source: "invite-modal" });
    await this.store.createRecord("projectShare", { projectId: project.id, project }).save();
    await this.reloadProject(project);
  }

  async unshareProject(project: Project): Promise<void> {
    void this.trackToggleTeamSharing(project, TrackingEvents.EVENT_TEAM_UNSHARE_PROJECT);
    await this.store.peekRecord("projectShare", project.id)?.destroyRecord();
    await this.reloadProject(project);
  }

  private async trackToggleTeamSharing(project: Project, eventType: string, opts = {}): Promise<void> {
    const projectTeam = await project.team;

    void this.tracking.sendAnalytics(eventType, {
      /* eslint-disable camelcase */
      project_id: project.id,
      team_id: projectTeam?.id,
      team_owner_id: projectTeam?.ownerId,
      team_size: projectTeam?.memberCount,
      ...opts
      /* eslint-enable camelcase */
    });
  }

  private async reloadProject(project: Project): Promise<void> {
    const reloadProject = project.reload();
    const reloadPreview = this.store.peekRecord("projectPreview", project.id)?.reload();

    await Promise.all([reloadProject, reloadPreview].filter((p) => !!p));
  }
}
