import type { PositionalArgs, NamedArgs } from "ember-modifier";
import Modifier from "ember-modifier";

interface InsertElementSignature {
  Args: {
    Named: {};
    Positional: [Element, boolean];
  };
}

export default class InsertElementModifier extends Modifier<InsertElementSignature> {
  modify(
    element: Element,
    [subject, clone]: PositionalArgs<InsertElementSignature>,
    _: NamedArgs<InsertElementSignature>
  ): void {
    if (subject instanceof HTMLElement) {
      element.innerHTML = "";
      if (clone) {
        element.prepend(subject.cloneNode(true));
      } else {
        element.prepend(subject);
      }
    }
  }
}
