import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { Caption, Element, Scene, Timeline } from "client/lib/editor-domain-model";
import { Background, Logo, Text, Watermark } from "client/lib/editor-domain-model";
import type { EditableText } from "client/lib/text/text-editor";
import type Project from "client/models/project";

type SelectableObject = Scene | Caption | EditableText;

export default class AdvancedEditorService extends Service {
  @service
  private declare router: RouterService;

  @service
  private declare store: Store;

  @tracked
  private _timeline?: Timeline;

  @tracked
  private _scene?: Scene;

  @tracked
  private _caption?: Caption;

  @tracked
  private _element?: Element;

  @tracked
  private _scriptWorkstationElement?: SelectableObject;

  @tracked
  private _showSelection = true;

  @tracked
  private collapsedTimeline = false;

  get project(): Project | undefined {
    return this.timeline ? this.store.peekRecord("project", this.timeline.id) ?? undefined : undefined;
  }

  get timeline(): Timeline | undefined {
    return this._timeline;
  }

  public toggleTimelineCollapse(): void {
    this.collapsedTimeline = !this.collapsedTimeline;
  }

  public expandTimeline(): void {
    this.collapsedTimeline = false;
  }

  public get collapsed(): boolean {
    return this.collapsedTimeline;
  }

  setTimeline(timeline: Timeline): void {
    this._timeline = timeline;
  }

  unsetTimeline(): void {
    this._timeline = undefined;
    this._scene = undefined;
    this._caption = undefined;
    this._element = undefined;
    this._scriptWorkstationElement = undefined;
    this._showSelection = true;
  }

  get scene(): Scene | undefined {
    return this._scene;
  }

  setScene(scene: Scene): void {
    this._scene = scene;
    this._scriptWorkstationElement = scene;
  }

  unsetScene(): void {
    this._scene = undefined;
    this._scriptWorkstationElement = undefined;
    this._caption = undefined;
    if (this._element instanceof Background || this._element instanceof Logo || this._element instanceof Text) {
      this._element = undefined;
      this._showSelection = true;
    }
  }

  get caption(): Caption | undefined {
    return this._caption;
  }

  setCaption(caption: Caption): void {
    this._caption = caption;
  }

  unsetCaption(): void {
    this._caption = undefined;
    if (this._element instanceof Logo || this._element instanceof Text) {
      this._element = undefined;
      this._showSelection = true;
    }
  }

  get element(): Element | undefined {
    return this._element;
  }

  get showSelection(): boolean {
    return this._showSelection;
  }

  setElement(element: Element, showSelection = true): void {
    this._element = element;
    this._showSelection = showSelection;
  }

  unsetElement(): void {
    this._element = undefined;
    this._showSelection = true;
  }

  get scriptWorkstationElement(): SelectableObject | undefined {
    return this._scriptWorkstationElement;
  }

  setScriptWorkstationElement(selectableElement: SelectableObject): void {
    this._scriptWorkstationElement = selectableElement;
  }

  unsetScriptWorkstationElement(): void {
    this._scriptWorkstationElement = undefined;
  }

  private getTransitionMethod(history: boolean): RouterService["transitionTo"] | RouterService["replaceWith"] {
    return (history ? this.router.transitionTo : this.router.replaceWith).bind(this.router);
  }

  async transitionToText(caption: Caption, element: Text, history = true): Promise<void> {
    const transitionMethod = this.getTransitionMethod(history);
    void transitionMethod("authenticated.project.scene.caption.text", caption.id, element.id);
  }

  async transitionToLogo(caption: Caption, element: Logo, history = true): Promise<void> {
    const transitionMethod = this.getTransitionMethod(history);
    void transitionMethod("authenticated.project.scene.caption.logo", caption.id, element.id);
  }

  async transitionToCaption(caption: Caption, timeline: Timeline, history = true): Promise<void> {
    const transitionMethod = this.getTransitionMethod(history);
    void transitionMethod("authenticated.project.scene.caption", timeline.id, caption.scene.id, caption.id);
  }

  async transitionToScene(scene: Scene, timeline: Timeline, history = true): Promise<void> {
    const transitionMethod = this.getTransitionMethod(history);
    void transitionMethod("authenticated.project.scene", timeline.id, scene.id);
  }

  async transitionToBackground(showSelection = true): Promise<void> {
    const options = {
      queryParams: {
        showSelection: showSelection
          ? "chur" // truthy value; https://en.wiktionary.org/wiki/chur#Etymology_1
          : undefined
      }
    };
    if (this.caption) {
      void this.router.transitionTo("authenticated.project.scene.caption.background", options).followRedirects();
    } else {
      void this.router.transitionTo("authenticated.project.scene.background", options).followRedirects();
    }
  }

  async transitionToWatermark(): Promise<void> {
    if (this.caption) {
      void this.router.transitionTo("authenticated.project.scene.caption.watermark");
    } else {
      void this.router.transitionTo("authenticated.project.scene.watermark");
    }
  }

  async transitionToElement(element: Element): Promise<void> {
    if (this.caption && element instanceof Text) {
      await this.transitionToText(this.caption, element);
    } else if (this.caption && element instanceof Logo) {
      await this.transitionToLogo(this.caption, element);
    } else if (element instanceof Background) {
      await this.transitionToBackground();
    } else if (element instanceof Watermark) {
      await this.transitionToWatermark();
    }
  }
}
