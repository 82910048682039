import type { Watermark, Background, Logo } from "client/lib/editor-domain-model";
import { Text, VideoClip, Animation, Image, Media, Svg } from "client/lib/editor-domain-model";

export default class MediaTypes {
  constructor(private element: Logo | Text | Background | Watermark | undefined) {}

  get mediaType(): string | undefined {
    switch (true) {
      case this.isImage():
        return "Image";
      case this.isText():
        return "Text";
      case this.isGraphic():
        return "Animation";
      case this.isVideo():
        return "VideoClip";
      default:
        return undefined;
    }
  }

  isImage(): boolean {
    return this.element instanceof Media && this.element.asset instanceof Image;
  }

  isText(): boolean {
    return this.element instanceof Text;
  }

  isGraphic(): boolean {
    return this.element instanceof Media && this.element.asset instanceof Animation;
  }

  isSvg(): boolean {
    return this.element instanceof Media && this.element.asset instanceof Svg;
  }

  isVideo(): boolean {
    return this.element instanceof Media && this.element.asset instanceof VideoClip;
  }
}
