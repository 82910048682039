import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type SelectOptionComponent from "./item/component";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum SelectSizes {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large"
}

export enum SelectVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary"
}

interface SelectComponentArgs {
  open?: boolean;
  closeOnClick?: boolean;
  size?: SelectSizes;
  variant?: SelectVariants;

  onChange?(value: unknown): unknown;
  onClose?(): unknown;
}

export default class SelectComponent extends Component<SelectComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/dropdown-menu");

  @tracked
  open: boolean;

  @tracked
  options: Array<SelectOptionComponent> = [];

  @tracked
  selectedOption?: SelectOptionComponent;

  @tracked
  currentValue: unknown;

  closeOnClick: boolean;
  _element?: HTMLElement;

  constructor(owner: object, args: SelectComponentArgs) {
    super(owner, args);

    this.open = args.open ?? false;
    this.closeOnClick = args.closeOnClick ?? true;
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
    document.addEventListener("click", this.globalClick);
    document.addEventListener("keydown", this.globalKeyDown);
  }

  @action
  openDidChange(_: HTMLElement, [open]: [boolean | undefined]): void {
    this.open = open ?? this.open;
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener("click", this.globalClick);
    document.removeEventListener("keydown", this.globalKeyDown);
  }

  @action
  globalClick(event: Event): void {
    if (this.closeOnClick || !this._element?.contains(event.target as Node)) {
      this.toggleMenu(false);
    }
  }

  @action
  globalKeyDown(event: KeyboardEvent): void {
    if (event.code === EventKeys.ESC) {
      this.toggleMenu(false);
    }
  }

  @action
  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    this.toggleMenu();
  }

  toggleMenu(open = !this.open): void {
    if (this.open !== open) {
      this.open = open;

      if (!open) {
        this.args.onClose?.();
        this.args.onChange?.(this.currentValue);
      }
    }
  }

  get size(): string {
    return this.args.size ?? SelectSizes.DEFAULT;
  }

  get variant(): string {
    return this.args.variant ?? SelectVariants.PRIMARY;
  }
}
