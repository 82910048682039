import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Caption, EventRegister, Timeline } from "client/lib/editor-domain-model";
import { TimingInfo } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SmartEditorService from "client/services/smart-editor";
import type SmartPlaybackService from "client/services/smart-playback";

interface Args {
  eventRegister: EventRegister;
  timeline: Timeline;
  caption: Caption;
  disableSceneSelection?: boolean;
}

export default class AdvancedTimingPlayerComponent extends Component<Args> {
  @service
  declare smartPlayback: SmartPlaybackService;

  @service
  private declare smartEditor: SmartEditorService;

  @tracked
  private canvasWidth = 0;

  private canvasResizeObserver?: ResizeObserver;

  styleNamespace = getStyleNamespace("advanced-timing/player");

  @action
  async didInsertCanvas(canvas: HTMLCanvasElement): Promise<void> {
    this.canvasResizeObserver = new ResizeObserver(() => (this.canvasWidth = canvas.getBoundingClientRect().width));
    this.canvasResizeObserver.observe(canvas);

    await this.smartPlayback.setup(canvas);

    if (this.args.caption) {
      this.smartEditor.activeObject = this.args.caption;
    }

    await this.updatePreviewTime();
  }

  @action
  async willDestroyCanvas(canvas: HTMLElement): Promise<void> {
    this.canvasResizeObserver?.unobserve(canvas);

    await this.smartPlayback.clear();
  }

  @action
  async onClickPlay(): Promise<void> {
    const { timeline } = this.args;

    const caption = this.args.caption || this.smartEditor.activeAsCaption();
    // console.log(this.modalEditor.activeAsCaption());

    const timing = timeline.getCaptionTiming(caption);
    await this.smartPlayback.play(timing.startTime, timing.endTime, false);
  }

  @action
  async onClickStop(): Promise<void> {
    await this.smartPlayback.stop();
  }

  @action
  async didUpdateTiming(): Promise<void> {
    await this.updatePreviewTime();
  }

  private async updatePreviewTime(): Promise<void> {
    if (this.timing) {
      await this.smartPlayback.updatePreviewTime(this.timing.previewTime);
    }
  }

  private get timing(): TimingInfo | undefined {
    const editableText = this.smartEditor.activeAsEditableText();
    if (editableText) {
      return this.args.timeline.getCaptionTiming(editableText.caption);
    }

    const scene = this.smartEditor.activeAsScene();

    if (this.args.caption) {
      const captionTiming = this.args.timeline.getCaptionTiming(this.args.caption);

      return new TimingInfo(captionTiming.startTime, captionTiming.duration, captionTiming.previewTime);
    } else if (scene) {
      const sceneTiming = this.args.timeline.getSceneTiming(scene);
      const caption = scene.captions[0];
      if (!caption) {
        return sceneTiming;
      }

      const captionTiming = this.args.timeline.getCaptionTiming(caption);
      return new TimingInfo(sceneTiming.startTime, sceneTiming.duration, captionTiming.previewTime);
    }

    return undefined;
  }

  get sceneCurrentTime(): number {
    const scene = this.smartEditor.activeAsScene();
    if (scene) {
      const sceneTiming = this.args.timeline.getSceneTiming(scene);

      return Math.max(0, this.smartPlayback.currentTime - sceneTiming.startTime);
    }

    return this.smartPlayback.currentTime;
  }

  get canvasWrapperStyle(): SafeString {
    const [width, height] = this.args.timeline.aspectRatio;

    return htmlSafe(`aspect-ratio: ${width / height};`);
  }

  get canvasStyle(): SafeString {
    const [width, height] = this.args.timeline.aspectRatio;

    return htmlSafe(`aspect-ratio: ${width / height};`);
  }

  get playbackStyle(): SafeString {
    return htmlSafe(`max-width: ${this.canvasWidth}px;`);
  }
}
