import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type AccountProfileRoute from "../route";
import type UpdatePasswordController from "./controller";
import type User from "client/models/user";

export default class UpdatePasswordRoute extends Route<User> {
  @service
  declare router: RouterService;

  model(): User {
    const { user } = this.modelFor<AccountProfileRoute>("authenticated.account.profile");

    if (!user.canChangePassword) {
      void this.router.replaceWith("authenticated.account.profile");
    }

    return user;
  }

  resetController(controller: UpdatePasswordController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.resetTransientState();
    }
  }
}
