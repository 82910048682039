import type ProjectPreview from "client/models/project-preview";

export enum VideoStateBasedAction {
  KeepEditing = "Keep editing",
  PublishVideo = "Publish video"
}

const SHOW_PUBLISH_AFTER_MSECONDS = 5 * 60000;
const SHOW_EDITING_AFTER_MSECONDS = 3 * 60000;

export default (
  project: Pick<ProjectPreview, "createdAt" | "updatedAt" | "rendered">
): VideoStateBasedAction | undefined => {
  if (!project) {
    return undefined;
  }

  const age = project.updatedAt.getTime() - project.createdAt.getTime();
  if (project.rendered) {
    return undefined;
  } else if (age > SHOW_PUBLISH_AFTER_MSECONDS) {
    return VideoStateBasedAction.PublishVideo;
  } else if (age > SHOW_EDITING_AFTER_MSECONDS) {
    return VideoStateBasedAction.KeepEditing;
  }

  return undefined;
};
