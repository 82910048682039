import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { WaveformGenerator, makeCORSUrl } from "renderer-engine";
import type { TimelineMutation } from "client/lib/editor-domain-model";
import { AudioClip, Caption, ResizeHandles, Scene, SceneDurationMutationFactory } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { toFixed } from "client/lib/quick-math";

type Resizeable = Caption | Scene | AudioClip;

interface ProjectTimelineResizerArgs {
  target: Resizeable;
  mutate: (mutation: TimelineMutation) => void;
  save: (target: Resizeable) => Promise<void>;
  onResize: (direction?: string) => void;
  pixelsPerSecond: number;
  projectDuration: number;
}

const isCaption = (target: Resizeable): target is Caption => typeof target !== "undefined" && target instanceof Caption;
const isScene = (target: Resizeable): target is Scene => typeof target !== "undefined" && target instanceof Scene;
const isAudio = (target: Resizeable): target is AudioClip =>
  typeof target !== "undefined" && target instanceof AudioClip;

export const DEFAULT_SNAP_AMOUNT = 0.5;
const snapValue = (value: number, snapAmount: number = DEFAULT_SNAP_AMOUNT): number =>
  toFixed(toFixed(value / snapAmount, 0) * snapAmount);

export default class ProjectTimelineResizerComponent extends Component<ProjectTimelineResizerArgs> {
  factory?: SceneDurationMutationFactory;

  originalTrimStart!: number;
  originalSceneDuration!: number;
  originalDuration!: number;

  @tracked
  _isResizing = false;

  @tracked
  trimAudioStart?: number;
  @tracked
  trimAudioEnd?: number;

  get isResizing(): boolean {
    return this._isResizing;
  }

  get hideStartHandle(): boolean {
    const { target } = this.args;
    return isScene(target) || this.getDuration(target) < 1;
  }

  get audioStartValue(): string | undefined {
    if (this.trimAudioStart === undefined) {
      return;
    }

    return "+" + this.getTrimValueFormatted(this.trimAudioStart);
  }

  get audioEndValue(): string | undefined {
    if (this.trimAudioEnd === undefined) {
      return;
    }

    return "-" + this.getTrimValueFormatted(this.trimAudioEnd);
  }

  getTrimValueFormatted(time: number): string {
    return time.toFixed(2) + "s";
  }

  getDuration(target: Resizeable): number {
    return target.duration === -1 ? this.args.projectDuration : target.duration;
  }

  @action
  async startDrag(): Promise<void> {
    if (this.args.onResize) {
      this.args.onResize("ew-resize");
    }

    const { target } = this.args;
    this.originalDuration = this.getDuration(target);

    if (isCaption(target)) {
      this.originalSceneDuration = target.scene.duration;
    } else if (isScene(target)) {
      this.factory = new SceneDurationMutationFactory(target);
    } else if (isAudio(target)) {
      this.originalTrimStart = target.trimStart;
    }
  }

  @action
  async drag(handle: ResizeHandles, delta: number): Promise<void> {
    const { target } = this.args;

    if (isCaption(target)) {
      const directionalDelta = handle === ResizeHandles.START ? -1 * delta : delta;

      this.args.mutate(
        new DomainModel.CaptionDurationChangeMutation(
          this.isResizing,
          target,
          this.originalDuration + directionalDelta,
          handle,
          {
            min: this.originalSceneDuration
          }
        )
      );
    } else if (isScene(target)) {
      this.args.mutate(this.factory!.createMutation(this.originalDuration + delta));
    } else if (isAudio(target)) {
      const buffer = await WaveformGenerator.getAudioBuffer(makeCORSUrl(target.sourceUrl));
      if (handle === ResizeHandles.START) {
        const newTrim = this.originalTrimStart + delta;
        this.args.mutate(
          new DomainModel.AudioClipTrimStartMutation(
            this.isResizing,
            target,
            newTrim,
            this.args.projectDuration,
            buffer.duration
          )
        );
      } else {
        const newTrimEnd = this.originalDuration + delta;
        const maxTrimEnd = buffer.duration - target.trimStart;
        this.args.mutate(
          new DomainModel.AudioClipDurationMutation(this.isResizing, target, Math.min(newTrimEnd, maxTrimEnd))
        );
      }
      this.trimAudioStart = target.trimStart;
      this.trimAudioEnd = Math.max(buffer.duration - target.trimStart - target.duration, 0);
    }
    this._isResizing = true;
  }

  @action
  async endDrag(handle: ResizeHandles): Promise<void> {
    const { target } = this.args;

    if (isCaption(target)) {
      this.args.mutate(
        new DomainModel.CaptionDurationChangeMutation(this.isResizing, target, snapValue(target.duration), handle)
      );
    } else if (isScene(target)) {
      this.args.mutate(this.factory!.createMutation(target.duration, DEFAULT_SNAP_AMOUNT));
    }

    await this.args.save(target);
    this._isResizing = false;

    if (this.args.onResize) {
      this.args.onResize();
    }
  }

  get targetIsAudio(): boolean {
    return isAudio(this.args.target);
  }
}
