import SelectableMediaAsset from "./selectable-media-asset";

export default abstract class Containable extends SelectableMediaAsset {
  abstract type: string | number;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    containable: Containable;
  }
}
