import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

const isObject = (value: any): boolean => typeof value === "object";

export interface DropdownOption {
  label: string;
  value: number | string | object;
}

type Options = Array<number | string | DropdownOption> | object;

export interface UIDropdownArgs {
  capitalizeLabels?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  editable?: boolean;
  hideLabel?: boolean;
  label?: string;
  max: number;
  menuClass?: string;
  menuFullWidth?: boolean;
  menuScrollable?: boolean;
  min: number;
  options?: Options[];
  placeholder?: boolean;
  span?: number;
  type?: string;
  value?: any;
  onchange?: (value?: any) => void;
}

export default class UIDropdownComponent<T extends UIDropdownArgs = UIDropdownArgs> extends Component<T> {
  elementId = "dropdown-label-" + guidFor(this);

  @tracked
  value?: any = this.args.value;

  styleNamespace = getStyleNamespace("ui/ui-dropdown");

  get inputId(): string {
    return `${this.elementId}-input`;
  }

  get options(): Options[] {
    return this.args.options ?? [];
  }

  get capitalizeLabels(): boolean {
    return this.args.capitalizeLabels ?? true;
  }

  get span(): number {
    return this.args.span ?? 4;
  }

  get type(): string {
    return this.args.type ?? "text";
  }

  get placeholder(): boolean {
    return this.args.placeholder ?? false;
  }

  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  get editable(): boolean {
    return this.args.editable ?? false;
  }

  get menuFullWidth(): boolean {
    return this.args.menuFullWidth ?? true;
  }

  get menuScrollable(): boolean {
    return this.args.menuScrollable ?? true;
  }

  get readonly(): boolean {
    return !this.editable;
  }

  private setInputValue(value: any): void {
    const option = this.optionsArray.find((option) => option.value === this.value);

    if (this.isDefaultValue()) {
      this.inputValue = "";
    } else {
      this.inputValue = (option && option.label) ?? value;
    }
  }

  @tracked
  loading?: boolean;

  @tracked
  open = false;

  @tracked
  inputValue: string | number = "";

  @tracked
  menuToggleElement?: HTMLElement;

  formatValue: (value: number | string | object) => DropdownOption = (value) => ({ label: String(value), value });

  @action
  toggleMenu(ev: MouseEvent): void {
    ev.stopPropagation();

    if (!this.disabled) {
      this.open ? this.closeMenu(ev) : this.openMenu(ev);
    }
  }

  @action
  openMenu(ev: MouseEvent): void {
    ev.stopPropagation();

    if (!this.disabled) {
      this.open = true;
    }
  }

  @action
  closeMenu(ev?: MouseEvent): void {
    ev?.stopPropagation();

    this.open = false;
  }

  @action
  valueDidUpdate(_: Element, [value]: [any]): void {
    this.value = value;

    this.setInputValue(value);
  }

  @action
  selectOption(value: any): void {
    this.value = value;
    this.inputValue = this.value;

    if (this.args.onchange) {
      this.args.onchange(value);
    }
  }

  @action
  inputClick(): void {
    if (!this.disabled && this.readonly) {
      this.open = !this.open;
    } else {
      this.open = false;
    }
  }

  @action
  inputChange(): void {
    if (this.readonly) {
      return;
    }

    let value = this.inputValue;

    if (!this.inputValue && this.args.defaultValue) {
      value = this.args.defaultValue;
    }

    const sanitized = this.sanitizeValue(value);
    this.value = sanitized;

    if (this.args.onchange) {
      this.args.onchange(this.value);
    }
  }

  @action
  didInsert(element: HTMLElement): void {
    this.menuToggleElement = element.querySelector("[data-menu-toggle]") as HTMLElement;

    this.setInputValue(this.value);
  }

  get optionsArray(): DropdownOption[] {
    const { options } = this;
    if (options instanceof Array) {
      const [firstValue] = options;

      if (!isObject(firstValue)) {
        return options.map<DropdownOption>(
          (v: any): DropdownOption => ({
            label: v,
            value: v
          })
        );
      }
    }

    const [firstValue] = Object.values(options);
    if (isObject(options) && !isObject(firstValue)) {
      return Object.entries(options).map(([k, v]) => ({
        label: k,
        value: v
      }));
    }

    return options as DropdownOption[];
  }

  get isCustomValue(): boolean {
    if (this.value === undefined) {
      return false;
    }

    return (
      !!this.value.toString().length &&
      !this.optionsArray.find(({ value }: DropdownOption) => String(value) === String(this.value)) &&
      this.value !== this.args.defaultValue
    );
  }

  get formattedValue(): DropdownOption {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.formatValue(this.value);
  }

  sanitizeValue(value: number | string): string | number {
    const coerced = this.coerceToType(value);

    if (typeof coerced === "string") {
      return coerced;
    }

    let bounded = Number(coerced);

    if (this.args.min) {
      bounded = Math.max(bounded, this.args.min);
    }

    if (this.args.max) {
      bounded = Math.min(bounded, this.args.max);
    }

    return bounded;
  }

  isDefaultValue(): boolean {
    return !isNaN(this.args.defaultValue as number) && String(this.value) === this.args.defaultValue;
  }

  coerceToType(value: number | string): string | number {
    if (this.type === "number") {
      return !isNaN(Number(value)) ? Number(value) : 0;
    } else {
      return String(value);
    }
  }
}
