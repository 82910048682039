import Model, { attr } from "@ember-data/model";

export default class NarrationVoice extends Model {
  @attr("string")
  declare name: string;

  @attr("string")
  declare language: string;

  @attr("string")
  declare dialect: string;

  @attr("string")
  declare previewUrl: string;

  @attr("string")
  declare voiceType: string;

  @attr("number")
  declare order: number;

  @attr("boolean")
  declare active: boolean;

  @attr("string")
  declare avatarUrl: string;

  type: string = NarrationVoice.modelName.toString();
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    narrationVoice: NarrationVoice;
  }
}
