import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type UnsplashPhoto from "client/models/unsplash-photo";
import type { LibraryContentModel } from "client/routes/library-content";
import type { LibrarySearchRouteParams } from "client/routes/library-search";
import LibrarySearchRoute from "client/routes/library-search";

export default class ImagesSearchRoute extends LibrarySearchRoute<LibraryContentModel<UnsplashPhoto>> {
  @service
  declare store: Store;

  searchParentRouteName = "authenticated.project.scenes.library";
  type = "image";
  provider = "unsplash";

  errorNotificationMessage = "There was a problem retrieving images";

  async getResults(params: LibrarySearchRouteParams): Promise<UnsplashPhoto[]> {
    // @ts-expect-error
    return this.store.query("unsplashPhoto", params) as UnsplashPhoto[];
  }
}
