import { action } from "@ember/object";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type AuthenticatedRoute from "client/authenticated/route";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import { AuthEvents } from "client/services/auth";

export default class AccountRoute extends Route {
  @service
  declare auth: AuthService;

  model(): User {
    return this.modelFor<AuthenticatedRoute>("authenticated");
  }

  activate(): void {
    this.auth.on(AuthEvents.AFTER_USER_LOADED, this.reload);
  }

  deactivate(): void {
    this.auth.off(AuthEvents.AFTER_USER_LOADED, this.reload);
  }

  @action
  reload(): void {
    void this.refresh();
  }
}
