import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { clamp } from "client/lib/quick-math";

interface SeekArgs {
  startTime?: number;
  duration: number;
  seekDuration: number;
  pixelsPerSecond: number;
  disabled?: boolean;
  playing: boolean;
  currentTime: number;
  play: (startTime: number) => void;
  stop: () => void;
}

/**
 * Defines the seek bar interval precision for a zoom range
 */
interface IntervalPrecisionRange {
  zoomStart: number; // pixels per second
  zoomEnd: number; // pixels per second
  precision: number; // seconds
  label: number; // seconds
}

const DEFAULT_INTERVAL_PRECISION_RANGE = { zoomStart: 60, zoomEnd: Infinity, precision: 0.5, label: 1 };

const intervalPrecisionRanges: IntervalPrecisionRange[] = [
  { zoomStart: 0, zoomEnd: 0.4, precision: 150, label: 300 },
  { zoomStart: 0.4, zoomEnd: 0.9, precision: 60, label: 120 },
  { zoomStart: 0.9, zoomEnd: 2, precision: 30, label: 60 },
  { zoomStart: 2, zoomEnd: 9, precision: 15, label: 30 },
  { zoomStart: 9, zoomEnd: 15, precision: 5, label: 10 },
  { zoomStart: 15, zoomEnd: 60, precision: 1, label: 5 }
];

export default class SeekComponent extends Component<SeekArgs> {
  _element?: HTMLElement;

  @tracked
  isMouseOver = false;

  @tracked
  mouseLeft?: number;

  styleNamespace = getStyleNamespace("generic/seek");

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  onMouseEnter(): void {
    this.isMouseOver = true;
  }

  @action
  onMouseLeave(): void {
    this.isMouseOver = false;
  }

  @action
  onMouseMove({ pageX }: MouseEvent): void {
    if (!this._element || !this.isMouseOver) {
      return;
    }

    const { left } = this._element.getBoundingClientRect();
    this.mouseLeft = pageX - left;
  }

  get startTime(): number {
    return this.args.startTime ?? 0;
  }

  get mouseTime(): number {
    if (this.mouseLeft === undefined) {
      return 0;
    }

    return clamp(this.mouseLeft / this.args.pixelsPerSecond, this.startTime, this.startTime + this.args.duration);
  }

  @action
  onClick(): void {
    if (this.args.disabled) {
      return;
    }

    if (this.args.playing) {
      return this.args.stop();
    }

    const { mouseTime: startTime } = this;

    if (startTime === undefined || startTime < this.startTime || startTime >= this.startTime + this.args.duration) {
      return;
    }

    this.args.play(startTime);
  }

  get intervalPrecisionRange(): IntervalPrecisionRange {
    return (
      intervalPrecisionRanges.find(
        (r) => r.zoomStart < this.args.pixelsPerSecond && r.zoomEnd >= this.args.pixelsPerSecond
      ) || DEFAULT_INTERVAL_PRECISION_RANGE
    );
  }

  get progressWidthStyle(): SafeString {
    const progressWidth = this.args.currentTime * this.args.pixelsPerSecond;
    return htmlSafe(`width: ${progressWidth.toFixed(2)}px;`);
  }

  get playheadStyle(): SafeString {
    const mouseTimeLeft = this.mouseTime * this.args.pixelsPerSecond;
    return htmlSafe(`left: ${mouseTimeLeft.toFixed(2)}px;`);
  }
}
