import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AdminIconArgs {
  label?: string;
}

export default class AdminIconComponent extends Component<AdminIconArgs> {
  styleNamespace = getStyleNamespace("admin/icon");
}
