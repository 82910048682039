import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { emptyCardHeading, emptyCardImage } from "client/components/north-star/card/empty/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class TemplatesEmptyComponent extends Component {
  styleNamespace = getStyleNamespace("discovery/templates/empty/card");
  heading = emptyCardHeading();
  image = emptyCardImage();

  get imageStyles(): SafeString {
    return htmlSafe(`background-image: url(${this.image})`);
  }
}
