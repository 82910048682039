import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type UpgradeService from "client/services/upgrade";

export default class PlansRoute extends Route {
  @service
  declare upgrade: UpgradeService;

  deactivate(): void {
    this.upgrade.close();
  }
}
