import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectRender from "client/models/project-render";
import type ProjectTranscription from "client/models/project-transcription";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

interface ExportActionsDownloadArgs {
  isRendering: boolean;
  projectRender?: ProjectRender;
}

export default class ExportActionsDownloadComponent extends Component<ExportActionsDownloadArgs> {
  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare tracking: TrackingService;

  @service
  declare upgrade: UpgradeService;

  @tracked
  downloadMenuOpen = false;

  downloadMenuTarget?: HTMLElement;

  styleNamespace = getStyleNamespace("export-actions/download");

  @action
  async downloadVideo(event: Event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    try {
      const render = this.args.projectRender;
      if (render) {
        await this.trackDownloadEvent(render);
        if (this.captionsDownloadable) {
          window.open(render.captionBurnRequestVideoUrl, "_blank");
        } else {
          window.open(render.url, "_blank");
        }
      }
    } catch (error) {
      this.honeybadger.notify(error as Error, "ProjectDownloadModal/Download");
    }
  }

  @action
  onUpgrade(): void {
    void this.upgrade.open();
  }

  @action
  setDownloadMenuTarget(element: HTMLElement): void {
    this.downloadMenuTarget = element;
  }

  @action
  toggleDownloadMenu(): void {
    this.downloadMenuOpen = !this.downloadMenuOpen;
  }

  async trackDownloadEvent(render: ProjectRender): Promise<void> {
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_DOWNLOAD_VIDEO, await render.buildDownloadEvent());
  }

  get isRendering(): boolean {
    return this.args.isRendering || this.isBurningCaptions;
  }

  get disabled(): boolean {
    return !this.permissions.has("feature_project_download") || this.isRendering || this.shouldDisplayPurchaseOption;
  }

  get shouldDisplayPurchaseOption(): boolean {
    if (this.auth.currentSubscription?.trialing && !this.auth.currentFullSubscription) {
      return true;
    }

    return false;
  }

  get hasCaptions(): boolean {
    return !!this.projectTranscription?.isCompleted;
  }

  get captionsDownloadable(): boolean {
    return !this.disabled && this.hasCaptions;
  }

  get isBurningCaptions(): boolean {
    if (this.hasCaptions) {
      return !!this.projectTranscription?.isBurning;
    }
    return false;
  }

  get projectTranscription(): ProjectTranscription | undefined {
    const projectTranscription = this.args.projectRender?.belongsTo("projectTranscription").value();
    if (projectTranscription) {
      return projectTranscription as ProjectTranscription;
    }
    return undefined;
  }

  get uncaptionedVideoUrl(): string | undefined {
    return this.args.projectRender?.url;
  }

  get srtUrl(): string | undefined {
    return this.projectTranscription?.srtUrl;
  }

  get vttUrl(): string | undefined {
    return this.projectTranscription?.vttUrl;
  }
}
