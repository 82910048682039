import Service, { service } from "@ember/service";
import type SessionService from "client/services/-private/session";

export default class AuthenticationTokenService extends Service {
  @service
  declare session: SessionService;

  get authToken(): string | undefined {
    return this.session.data.authenticated.access_token;
  }
}
