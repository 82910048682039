import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, FontInfo, TextStyleProperties, Timeline } from "client/lib/editor-domain-model";
import { Caption, Scene, TextStyle } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { EditableText } from "client/lib/text/text-editor";
import type AdvancedEditorService from "client/services/advanced-editor";

interface Args {
  eventRegister: EventRegister;
  timeline: Timeline;
  isHover: boolean;
  isEmpty: boolean;
  caption?: Caption;
  scene: Scene;
  fonts: FontInfo[];
  selectedCaptionId?: string;
}

export const DEFAULT_CAPTION_DURATION = 3; // seconds

export default class ScriptWorkstationCaptionComponent extends Component<Args> {
  @service
  declare advancedEditor: AdvancedEditorService;

  styleNamespace = getStyleNamespace("script-workstation/caption");

  @action
  async onClick(e: Event): Promise<void> {
    const { caption, timeline, scene } = this.args;
    if (!this.isActive) {
      if (this.args.isEmpty) {
        this.advancedEditor.setScriptWorkstationElement(scene);
        await this.advancedEditor.transitionToScene(scene, timeline);
      } else {
        if (caption) {
          this.advancedEditor.setScriptWorkstationElement(caption);
          await this.advancedEditor.transitionToCaption(caption, timeline);
        }
      }
    }
    e.stopPropagation();
  }

  get isActive(): boolean {
    const scriptWorkstationElement = this.advancedEditor.scriptWorkstationElement;

    if (scriptWorkstationElement instanceof Caption) {
      return scriptWorkstationElement === this.args.caption;
    }

    if (scriptWorkstationElement instanceof Scene) {
      return scriptWorkstationElement === this.args.scene;
    }

    return this.advancedEditor.caption === this.args.caption;
  }

  get textStyle(): TextStyle {
    return (this.advancedEditor.scriptWorkstationElement as EditableText)?.textStyle ?? new TextStyle({});
  }

  @action
  applyStyle(style: TextStyleProperties): void {
    (this.advancedEditor.scriptWorkstationElement as EditableText)?.applyStyle?.(style);
  }

  @tracked
  captionIsHover = false;

  @action
  setCaptionIsHover(newState: boolean): void {
    this.captionIsHover = newState;
  }
}
