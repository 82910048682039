import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type MouseService from "client/services/mouse";
import { MouseEvents } from "client/services/mouse";

interface ProjectPreviewConfirmDeleteArgs {
  deleting: boolean;
  confirm(): unknown;
  cancel(): unknown;
}

export default class ProjectPreviewConfirmDeleteComponent extends Component<ProjectPreviewConfirmDeleteArgs> {
  @service
  declare mouse: MouseService;

  private _element?: HTMLElement | undefined;

  styleNamespace = getStyleNamespace("discovery/project-preview/confirm-delete");

  @action
  onDocumentClick(ev: MouseEvent): void {
    if (!this._element?.contains(ev.target as HTMLElement)) {
      this.args.cancel();
    }
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element ??= element;

    this.mouse.subscribe(MouseEvents.CLICK, this.onDocumentClick);
  }

  willDestroy(): void {
    super.willDestroy();
    this.mouse.unsubscribe(MouseEvents.CLICK, this.onDocumentClick);
  }
}
