import type { ImageMod, ImageConfig, ImageAssetContainer } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import { ImageType } from "../data/animation-image";
import type { Image } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { toColorBrandKey } from "client/lib/editor-domain-model";

export const getDefaultAnimation = (type = ImageType.LOGO): string => {
  switch (type) {
    case ImageType.LOGO:
      return "Background No Animation";

    case ImageType.BACKGROUND:
    default:
      return "Background Zoom In";
  }
};

export const imageConfigToImage = (cfg: ImageConfig): DomainModel.Image => {
  return new DomainModel.Image(
    cfg.animation ?? getDefaultAnimation(),
    cfg.name ?? cfg.url,
    cfg.url,
    cfg.previewImageUrl,
    imageModToFrame(cfg?.imageMod),
    cfg.opacity ?? 1,
    !!cfg.invertX,
    cfg.animationFit,
    cfg.asset,
    cfg.license,
    cfg.rotation
  );
};

export const imageModToFrame = (mod?: ImageMod): DomainModel.Frame | undefined => {
  if (!mod) {
    return;
  }

  return new DomainModel.Frame(
    mod.backingShape,
    mod.color,
    mod.scale,
    mod.frame.x,
    mod.frame.y,
    toColorBrandKey(mod.colorBrandKey)
  );
};

export const imageToImageAssetContainer = (image: Image, sceneId?: string): ImageAssetContainer => {
  const { sourceUrl: url, previewUrl: previewImageUrl } = image;
  return {
    cfg: {
      image: {
        url,
        previewImageUrl
      }
    },
    category: ZymbolCategory.IMAGE,
    sceneId
  };
};
