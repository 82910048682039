import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Favorable from "client/models/favorable";
import { SCENE_FAVORITING_FAILURE } from "client/models/favorable";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";

interface Args {
  userId: number;
  scene: Scene;
  project: Project;
  toggleMyFavorite: () => Promise<void>;
  toggleTeamFavorite: () => Promise<void>;
}

export default class AppHeaderFavoriteSceneToggleComponent extends Component<Args> {
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  @tracked
  open = false;

  @tracked
  menuTarget!: HTMLElement;

  @tracked
  unfavoriting = false;

  @tracked
  persisting = false;

  @tracked
  userId!: number;

  styleNamespace = getStyleNamespace("app-header/project/favorite-scene");

  @action
  async onClick(): Promise<void> {
    try {
      if (!this.projectScene?.favorited) {
        this.open = !this.open;
      } else if (this.projectScene?.teamFavorited) {
        this.unfavoriting = true;
        await this.toggleTeamFavorite();
      } else {
        this.unfavoriting = true;
        await this.toggleMyFavorite();
      }
    } finally {
      this.unfavoriting = false;
    }
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this.menuTarget = element;
    this.userId = Number(await this.auth.currentUser!.id);
  }

  @action
  close(): void {
    this.open = false;
  }

  get disabled(): boolean {
    return (
      (this.projectScene?.teamFavorited && !this?.projectScene.isCreator(String(this.args.userId))) || this.persisting
    );
  }

  get favoritesTooltip(): string {
    return this.projectScene?.favorited ? "Remove from favorites" : "Add to favorites";
  }

  async toggleFavorite(fn: () => Promise<void>): Promise<void> {
    try {
      this.persisting = true;
      this.close();
      await fn();
    } finally {
      this.persisting = false;
    }
  }

  private findProjectScene(): (ProjectScene & Favorable) | undefined {
    // @ts-expect-error
    const projectScenes = this.args.project.hasMany("projectScenes").value() as ProjectScene[];
    return projectScenes.find((projectScene) => projectScene.id === this.args.scene?.id) as ProjectScene & Favorable;
  }

  @action
  async toggleMyFavorite(): Promise<void> {
    const projectScene = this.findProjectScene();
    if (projectScene) {
      const handler = new FavoriteActionHandler(projectScene, this.args.scene, this.store);
      const result = await handler.toggleMyFavorite();
      if (result.succeeded) {
        this.notifications.success(
          result.action === "favorite"
            ? "Your scene was added to my favorites"
            : "Your scene was removed from my favorites"
        );
      } else {
        this.notifications.error(
          result.action === "favorite"
            ? SCENE_FAVORITING_FAILURE
            : "There was a problem removing this scene from your favorites"
        );
      }
    }
  }

  @action
  async toggleTeamFavorite(): Promise<void> {
    const projectScene = this.findProjectScene();
    if (projectScene) {
      const handler = new FavoriteActionHandler(projectScene, this.args.scene, this.store);
      const result = await handler.toggleTeamFavorite();
      if (result.succeeded) {
        this.notifications.success(
          result.action === "favorite"
            ? "Your scene was added to team favorites"
            : "Your scene was removed from team favorites"
        );
      } else {
        this.notifications.error(
          result.action === "favorite"
            ? SCENE_FAVORITING_FAILURE
            : "There was a problem removing this scene from your favorites"
        );
      }
    }
  }

  get projectScene(): (ProjectScene & Favorable) | undefined {
    return this.findProjectScene();
  }
}

type FavoriteAction = "favorite" | "unfavorite";

interface FavoriteResult {
  succeeded: boolean;
  action: FavoriteAction;
}

class FavoriteActionHandler {
  constructor(private projectScene: ProjectScene & Favorable, private scene: Scene, private store: Store) {}

  async toggleMyFavorite(): Promise<FavoriteResult> {
    try {
      if (!this.projectScene.favorited) {
        await this.addMyFavorite();
        this.scene.favorite({ favorited: true });
        return { succeeded: true, action: "favorite" };
      }
      await this.projectScene.unfavorite();
      this.scene.unfavorite();
      return { succeeded: true, action: "unfavorite" };
    } catch (error) {
      return { succeeded: false, action: this.projectScene.favorited ? "unfavorite" : "favorite" };
    }
  }

  private async addMyFavorite(): Promise<void> {
    await this.projectScene.favorite();
    const render = this.store.createRecord("projectSceneRender", { projectSceneId: this.projectScene.id });
    await render.save();
  }

  async toggleTeamFavorite(): Promise<FavoriteResult> {
    try {
      if (!this.projectScene.favorited) {
        await this.addTeamFavorite();
        this.scene.favorite({ favorited: true, teamFavorited: true });
        return { succeeded: true, action: "favorite" };
      }
      await this.projectScene.unfavorite();
      this.scene.unfavorite();
      return { succeeded: true, action: "unfavorite" };
    } catch (error) {
      return { succeeded: false, action: this.projectScene.favorited ? "unfavorite" : "favorite" };
    }
  }

  private async addTeamFavorite(): Promise<void> {
    await this.projectScene.favorite(true);
  }
}
