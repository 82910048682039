import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioClip from "client/models/audio-clip";

interface ProjectEditAudioArgs {
  audioClip: AudioClip;
  title?: string;
  onMuteChange?(value: boolean): void;
  onVolumeChange?(value: number): void;
  onFadeInChange?(): void;
  onFadeOutChange?(): void;
}

export default class ProjectEditAudioComponent extends Component<ProjectEditAudioArgs> {
  styleNamespace = getStyleNamespace("tidal/project-edit/audio");
}
