import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type RecordProject from "client/models/record-project";

interface EmptyRequestComponentArgs {
  recordProject: RecordProject;
  descriptionText: string;
}

export default class EmptyRequestComponent extends Component<EmptyRequestComponentArgs> {
  styleNamespace = getStyleNamespace("asset-management/empty-request");
}
