import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type AdvancedEditorService from "client/services/advanced-editor";
import type CollaborationService from "client/services/collaboration";

export default class TeamInviteModal extends Component {
  styleNamespace = getStyleNamespace("collaboration/team-invite-modal");

  @service
  declare collaboration: CollaborationService;

  @service
  declare router: RouterService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @tracked
  invited = false;

  @tracked
  failedMembers?: Map<string, string>;

  @action
  onInvited(_added: string[], failed: Map<string, string>): void {
    this.invited = true;
    this.failedMembers = failed;
  }

  @action
  close(): void {
    this.invited = false;
    this.failedMembers = undefined;
    this.collaboration.hideTeamInviteModal();
  }

  @action
  async transitionToUserSetting(): Promise<void> {
    this.close();
    await this.router.transitionTo("authenticated.account.team");
  }

  get header(): string {
    return this.invited ? "Invite sent" : "Invite people";
  }

  get anyError(): boolean {
    return !!this.failedMembers && this.failedMembers.size !== 0;
  }

  get trackingLocation(): string | undefined {
    return this.collaboration.teamInviteTrackingLocation;
  }

  get shown(): boolean {
    return this.collaboration.teamInviteModalShown;
  }

  get currentProject(): Project | undefined {
    return this.advancedEditor.project;
  }
}
