import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type BrandEditController from "./controller";
import type BrandStyle from "client/models/brand-style";
import type BrandStyleService from "client/services/brand-style";
import type HoneybadgerService from "client/services/honeybadger";

export default class BrandEditRoute extends Route<BrandStyle> {
  @service
  declare brandStyle: BrandStyleService;

  @service
  declare honeybadger: HoneybadgerService;

  async model({ brandId }: { brandId: string }): Promise<BrandStyle> {
    const model = await this.brandStyle.getBrand(brandId);

    if (model) {
      await model.style.assetsLoaded;
      return model;
    }

    this.honeybadger.notify(`Could not load brand id: ${brandId}`);
    throw "Could not load brand";
  }

  resetController(controller: BrandEditController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.resetReturnPath();
    }
  }
}
