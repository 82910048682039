import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type NotificationsService from "client/services/notifications";

export interface VideoAssistantModel {
  videoAssistantGenerators: Array<VideoAssistantGenerator> | [];
  preselectedGenerator?: VideoAssistantGenerator;
}

export default class SurveyVideoAssistantRoute extends Route {
  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  async model(): Promise<VideoAssistantModel> {
    const generatorId = this.findGeneratorId();

    const videoAssistantGenerators = (await this.store.findAll("videoAssistantGenerator", {
      reload: true
    })) as unknown as VideoAssistantGenerator[];

    if (!videoAssistantGenerators) {
      this.notifications.error("There was a problem fetching generators.");
      throw Error("Failed to fetch generators");
    }

    if (generatorId) {
      const preselectedGenerators = (await this.store.query("videoAssistantGenerator", {
        id: generatorId
      })) as unknown as VideoAssistantGenerator[];

      return { videoAssistantGenerators, preselectedGenerator: preselectedGenerators[0] };
    }

    return { videoAssistantGenerators };
  }

  private findGeneratorId(): string | undefined {
    const subRoutesToCheck = ["use-case", "style", "description"];

    for (const subRoute of subRoutesToCheck) {
      const { generatorId } = this.paramsFor(`${this.routeName}.${subRoute}`) as { generatorId: string | undefined };

      if (generatorId) {
        return generatorId;
      }
    }

    return undefined;
  }
}
