import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { ProjectScenesCategoryRouteModel } from "client/authenticated/project/scenes/categories/category/route";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type ProjectScenePreview from "client/models/project-scene-preview";
import type ProjectContentBarService from "client/services/project-content-bar";

export default class ScenesCategoryController extends Controller<ProjectScenesCategoryRouteModel> {
  @tracked
  loading = false;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @action
  async selectScene(asset: ProjectScenePreview): Promise<void> {
    if (this.loading) {
      return;
    }

    const { scene, afterScene, timeline, eventRegister } = this.model;

    this.loading = true;
    try {
      const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene);

      if (this.projectContentBar.isReplacing) {
        await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
      } else {
        await modifier.applyAsset(asset);
      }
    } finally {
      this.loading = false;
    }
  }
}
