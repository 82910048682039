import Model, { attr, belongsTo } from "@ember-data/model";
import type User from "client/models/user";

export interface OverlayOptions {
  [key: string]: any;
}

export default class Overlay extends Model {
  @attr("string")
  declare ctaText: string;

  @attr("string")
  declare ctaUrl: string;

  @attr("boolean")
  declare enabled: boolean;

  @attr("string")
  declare message: string;

  @attr("string")
  declare name: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("user", { async: true, inverse: null })
  declare user: User;

  @attr("json")
  declare options: OverlayOptions;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    overlay: Overlay;
  }
}
