import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Text } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";

interface Args {
  text: Text;
}

export default class FloatingPanelsTextComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panels/style/text");

  @service
  private declare advancedEditor: AdvancedEditorService;
}
