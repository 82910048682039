import Dexie from "dexie";
import type ZymbolFootage from "client/models/zymbol-footage";

const DATABASE_NAME = "VideoBlocksV2";

export interface StoredFootage extends Partial<ZymbolFootage> {
  selectedAt?: Date;
  uid?: number;
}

export default class FootageDatabase extends Dexie {
  public footage!: Dexie.Table<StoredFootage, number>;

  constructor() {
    super(DATABASE_NAME);
    this.version(1).stores({
      footage: "++uid, id, selectedAt"
    });
  }
}
