import { Rect } from "../../scene";
import { InlineMutation } from "./vanilla-mutation";

export class ChangePositionMutation extends InlineMutation<{ _position: Rect }> {
  constructor(target: { _position: Rect }, position: Rect) {
    super(target, (t) => {
      const { x, y, width, height } = position;
      return (t._position = new Rect(x, y, width, height));
    });
  }
}
