import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import { lumaFromHex } from "client/lib/color";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isValidHexColor, parseHex, decimalToAlphaHex } from "client/lib/rgb-hex";
import type PopoverService from "client/services/popover";

interface ColorPickerBrandedArgs {
  onChange?: (color?: string) => void;
  allowTransparent?: boolean;
  showOpacitySlider?: boolean;
  colors?: (string | undefined)[];
  defaultAlpha?: number;
  size?: string;
  brandConfig?: BrandStyleConfig;
  popoverPosition?: string;
}

export default class ColorPickerBrandedComponent extends Component<ColorPickerBrandedArgs> {
  @service
  declare popover: PopoverService;

  @tracked
  open = false;

  @tracked
  isValidHex = true;

  allowTransparent = this.args.allowTransparent ?? true;
  showOpacitySlider = this.args.showOpacitySlider ?? true;

  styleNamespace = getStyleNamespace("color-picker-branded");

  @action
  toggleOpen(): void {
    if (this.open) {
      this.popover.trigger("close");
    } else {
      this.open = true;
    }
  }

  @action
  close(): void {
    this.open = false;
  }

  @action
  selectColor(color?: string): void {
    if (this.validateHexString(color)) {
      const formatHexString = color ? this.formatHexString(color) : color;

      this.onChange(formatHexString);
    }
  }

  get popoverPosition(): string {
    return this.args.popoverPosition ?? "right";
  }

  get sizeStyle(): string {
    if (this.args.size) {
      return ` -${this.args.size}`;
    }
    return "";
  }

  get hasBrandColors(): boolean {
    return !!this.args.brandConfig?.colorPalette.length;
  }

  get isColorDark(): boolean {
    if (this.args.colors) {
      const hexValue = parseHex(this.args.colors[0])?.value;

      return !!hexValue && lumaFromHex(hexValue) < 0.9;
    }

    return false;
  }

  validateHexString(color: string | undefined): boolean {
    if (!color || isValidHexColor(color)) {
      this.isValidHex = true;

      return true;
    }

    this.isValidHex = false;
    return false;
  }

  onChange(color: string | undefined): void {
    if (this.args.onChange) {
      this.args.onChange(color?.toUpperCase());
    }
  }

  formatHexString(hex: string): string {
    const parsedHex = parseHex(hex, true);

    if (!parsedHex) {
      return hex;
    }

    if (!this.allowTransparent && !this.showOpacitySlider) {
      return "#" + parsedHex.value;
    }

    if (parsedHex.alpha) {
      return parsedHex.input;
    }

    let output = "#" + parsedHex.value;

    const previousColor = parseHex(this.args.colors && this.args.colors[0]);
    if (previousColor?.alpha) {
      // Use previous color's transparency.
      output += previousColor.alpha;
    } else if (typeof this.args.defaultAlpha === "number") {
      // Use default transparency.
      output += decimalToAlphaHex(this.args.defaultAlpha);
    }

    return output;
  }
}
