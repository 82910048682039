import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { isFolderInFolderTree } from "client/lib/folders";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ContributorTypes } from "client/models/folder";
import Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type FoldersService from "client/services/folders";
import { FoldersEvents, AUTO_TOGGLE_DRAGGED_OVER_DELAY } from "client/services/folders";
import type GalleryService from "client/services/gallery";

interface FolderTreeNodeArgs {
  folder: Folder;
  activeFolder?: Folder;
  level: number;
  index: number;
  panel: any;
  onDeleteFolder: (folder: Folder) => void;
  onAddFolder: (parent: Folder) => void;
  refresh?: () => void;
  onExpandPanel: (panelName: string) => void;
  parentRouteName: string;
}

export default class FolderTreeNodeComponent extends Component<FolderTreeNodeArgs> {
  @service
  declare gallery: GalleryService;

  @service
  declare folders: FoldersService;

  @service
  declare router: RouterService;

  // Loads a third-party API panel-actions from ember-collapsible-panel
  @service
  declare panelActions: any;

  @tracked
  isRenaming = false;

  @tracked
  openModal = false;

  @tracked
  showOptions = false;

  @tracked
  isOptionsMenuOpen = false;

  @tracked
  hoveringOverTargetFolder = false;

  @tracked
  disableNode = false;

  @tracked
  interval?: ReturnType<typeof setInterval>;

  @tracked
  optionsMenuAnchor?: HTMLElement;

  @tracked
  declare folderName: string;

  elementId = guidFor(this);

  styleNamespace = getStyleNamespace("folders/tree-node");

  constructor(owner: object, args: FolderTreeNodeArgs) {
    super(owner, args);
    this.folders.subscribe(FoldersEvents.ACTIVE_FOLDER_CHANGED, this.onActiveFolderChanged);
    this.folderName = this.args.folder?.name;
  }

  willDestroy(): void {
    super.willDestroy();
    this.folders.unsubscribe(FoldersEvents.ACTIVE_FOLDER_CHANGED, this.onActiveFolderChanged);
  }

  get isActive(): boolean {
    const { activeFolder } = this.folders;
    return !!activeFolder && isFolderInFolderTree(this.args.folder, activeFolder);
  }

  get panel(): any {
    return this.args.panel;
  }

  get isDraggedOverDisabled(): boolean {
    if (this.disableNode || (this.hoveringOverTargetFolder && this.args.folder.id === this.gallery.draggedFolder?.id)) {
      return true;
    }
    return false;
  }

  get isDraggedOver(): boolean {
    if (this.hoveringOverTargetFolder && this.args.folder.id !== this.gallery.draggedFolder?.id) {
      return true;
    }
    return false;
  }

  @action
  async draggedOver(event: DragEvent): Promise<void> {
    if (this.gallery.draggedFolder) {
      this.disableNode = isFolderInFolderTree(this.gallery.draggedFolder, this.args.folder);
    }

    if (this.args.folder.hasChildren && !this.panel.isOpen) {
      this.interval = setInterval(() => this.expandPanel(), AUTO_TOGGLE_DRAGGED_OVER_DELAY);
    }
    this.hoveringOverTargetFolder = true;
    this.gallery.autoScrollSidebar(event);
  }

  @action
  draggedOut(): void {
    this.disableNode = false;
    if (this.args.folder.hasChildren && this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    this.hoveringOverTargetFolder = false;
  }

  @action
  cancel(): void {
    this.openModal = false;
  }

  @action
  closeMenu(): void {
    this.isOptionsMenuOpen = false;
  }

  @action
  openConfirmationModal(): void {
    this.openModal = true;
  }

  @action
  onActiveFolderChanged(): void {
    this.args.onExpandPanel(this.panel.name as string);

    if (!this.args.folder?.id) {
      this.rename();
    }
  }

  @action
  addFolder(): void {
    this.args.onAddFolder(this.args.folder);
    this.expandPanel();
  }

  @action
  expandPanel(): void {
    this.panelActions.open(this.panel.name);
  }

  @action
  async toggleExpandButton(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    await this.args.folder.children;
    this.panelActions.toggle(this.panel.name);
  }

  @action
  async draggedOnto(content: FolderContent): Promise<void> {
    const { folder } = this.args;
    let validMove = true;

    if (content instanceof Folder) {
      const isFolderAssociatedWithParent = await isFolderInFolderTree(content, folder);
      validMove = !isFolderAssociatedWithParent;
    }

    if (validMove) {
      await this.folders.moveContent(
        folder,
        content,
        folder.contributable as ContributorTypes,
        folder.name,
        this.args.parentRouteName
      );
      await this.args.refresh?.();
    }
  }

  @action
  setShowOptions(show: boolean): void {
    this.showOptions = show;
  }

  @action
  toggleOptionsMenu(): void {
    if (!this.isRenaming) {
      this.isOptionsMenuOpen = !this.isOptionsMenuOpen;
    }
  }

  @action
  setOptionsMenuAnchor(element?: HTMLElement): void {
    if (element) {
      this.optionsMenuAnchor = element;
    }
  }

  @action
  rename(): void {
    this.isRenaming = true;
  }

  @action
  async onBlur(event: Event): Promise<void> {
    const folderNameTrimmed = this.folderName.trim();

    if (!this.isRenaming) {
      // Stops the NorthStar::InputField onBlur from triggering.
      // It was updating the focus on the input field and throwing an exception.
      event.stopImmediatePropagation();

      return;
    }

    this.isRenaming = false;

    if (!this.validMinimumFolderName(folderNameTrimmed)) {
      return;
    }

    await this.saveFolderName(folderNameTrimmed);
  }

  @action
  async onKeyDown(): Promise<void> {
    this.isRenaming = false;

    const folderNameTrimmed = this.folderName.trim();

    if (!this.validMinimumFolderName(folderNameTrimmed)) {
      return;
    }

    await this.saveFolderName(folderNameTrimmed);
  }

  @action
  async saveFolderName(folderName: string): Promise<void> {
    const { folder } = this.args;

    if (!this.validMaximumFolderName) {
      return;
    }

    folder.name = folderName;
    await this.folders.update(folder);
    await this.args.refresh?.();
  }

  validMinimumFolderName(name: string): boolean {
    const { folder } = this.args;

    if (name.length === 0) {
      this.folderName = folder.name;
      this.isRenaming = false;

      return false;
    }

    return true;
  }

  get validMaximumFolderName(): boolean {
    if (this.folderName.trim().length > 100) {
      return false;
    }

    return true;
  }

  get folderNameError(): string {
    if (this.folderName.trim().length > 100) {
      return "Max 100 characters";
    } else {
      return "";
    }
  }

  get indentStyle(): SafeString {
    return htmlSafe(`padding-left: ${this.args.level}.6875rem`);
  }

  get routeName(): string {
    return this.args.parentRouteName + ".folder";
  }

  get isActiveFolder(): boolean {
    const isCurrentRoute = this.router.currentRouteName === this.routeName;
    return isCurrentRoute && this.args.activeFolder?.id === this.args.folder.id;
  }

  get showOptionsButton(): boolean {
    if (this.isRenaming) {
      return false;
    }

    return this.showOptions;
  }
}
