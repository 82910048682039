import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { WaveformGenerator, makeCORSUrl } from "renderer-engine";
import type AudioTrack from "client/models/audio-track";
import type AudioTrackGenre from "client/models/audio-track-genre";
import type Project from "client/models/project";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type ConfirmService from "client/services/confirm";
import type NotificationsService from "client/services/notifications";
import type ProjectsService from "client/services/projects";

const audioTracksConfirmMessage = (count: number, filename: string): string => {
  return `Are you sure you want to delete the following uploaded track: ${filename}?
${
  count
    ? `It is currently used on ${count} of your videos. If you remove it these videos will have their audio removed.`
    : ""
}`;
};

export interface IAudioTrackSettings {
  url?: string;
  filename: string;
  fileSignedId?: string;
}

export interface ApiQueryResult<T> {
  models: Array<T>;
  meta: {
    page: number;
    perPage: number;
    totalCount: number;
  };
}

export default class AudioTracksService extends Service {
  @service
  declare store: Store;

  @service
  auth!: AuthService;

  @service
  ajax!: AjaxService;

  @service
  confirm!: ConfirmService;

  @service
  projects!: ProjectsService;

  @service
  notifications!: NotificationsService;

  genres: AudioTrackGenre[] = [];
  selectedGenres: string[] = ["all"];
  selectedSortOrder = "desc";

  waveformGenerator = new WaveformGenerator();

  async deleteAudioTrack(audioTrack: AudioTrack): Promise<string | undefined> {
    try {
      const projectsWithAudioTrack = await this.getProjectsWithAudioTrack(audioTrack);
      const message = audioTracksConfirmMessage(projectsWithAudioTrack.length, audioTrack.name);
      const confirmed = await this.confirm.open({
        title: "Delete this audio track?",
        message,
        confirmLabel: "Delete it",
        cancelLabel: "Cancel"
      });

      if (confirmed) {
        if (projectsWithAudioTrack.length) {
          await this.removeAudioTrackFromProjects(audioTrack, ...projectsWithAudioTrack);
        }
        const id = audioTrack.id;
        await audioTrack.destroyRecord();
        return id;
      }

      return undefined;
    } catch (err) {
      this.notifications.error("Erm, sorry! There was a problem deleting your audio track");
      throw err;
    }
  }

  async getProjectsWithAudioTrack(audioTrack: AudioTrack): Promise<Project[]> {
    const projects = await this.projects.getCurrentUsersProjects();
    const projectsOrUndefined = ([] as Array<Project | undefined>).concat(
      ...(await Promise.all(
        projects.map(async (project) => {
          const audioClips = await project.audioClipsArray(false);
          if (audioClips.some((ac) => ac.audibleId === audioTrack.id)) {
            return project;
          }
          return undefined;
        })
      ))
    );
    return projectsOrUndefined.filter((v) => v) as Project[];
  }

  removeAudioTrackFromProjects(audioTrack: AudioTrack, ...projects: Project[]): Promise<Project[]> {
    return Promise.all(
      projects.map(async (project) => {
        await project.removeAudioClipsForAudioTrack(audioTrack);
        return project.save();
      })
    );
  }

  async getPublicAudioTracks(
    genres: string[],
    sortBy = "desc",
    pagination: {
      per_page: number;
      page: number;
    } = { per_page: 50, page: 1 } // eslint-disable-line camelcase
  ): Promise<ApiQueryResult<AudioTrack>> {
    let _genres: string[] | undefined = genres;
    if (genres.length === 0 || genres.includes("all")) {
      _genres = undefined;
    }

    // @ts-expect-error
    const results = (await this.store.query("audioTrack", {
      genres: _genres,
      sort: sortBy,
      ...pagination
    })) as AudioTrack[];

    return {
      models: results,
      meta: {
        // @ts-expect-error
        totalCount: results.meta["total-count"],
        page: pagination.page,
        perPage: pagination.per_page
      }
    };
  }

  async getCurrentUsersUploadedTracks(
    pagination: {
      per_page: number;
      page: number;
    } = { per_page: 50, page: 1 } // eslint-disable-line camelcase
  ): Promise<ApiQueryResult<AudioTrack>> {
    const user = this.auth.currentUser;

    if (!user) {
      return {
        models: [],
        meta: {
          page: pagination.page,
          perPage: pagination.per_page,
          totalCount: 0
        }
      };
    }

    const data = await this.ajax.api(`/tracks/user?page=${pagination.page}&per_page=${pagination.per_page}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.store.pushPayload(data);

    const tracks: AudioTrack[] = [];
    data.data.forEach((track: { id: string }) => {
      const trackRecord = this.store.peekRecord("audioTrack", track.id);
      if (trackRecord) {
        tracks.push(trackRecord);
      }
    });

    return {
      models: tracks,
      meta: {
        page: pagination.page,
        perPage: pagination.per_page,
        totalCount: data.meta["total-count"]
      }
    };
  }

  async createAudioTrack({ url, filename, fileSignedId }: IAudioTrackSettings): Promise<AudioTrack> {
    const track = this.store.createRecord("audioTrack", { name: filename, filestackUrl: url, fileSignedId });
    return track.save();
  }

  async fetchGenres(): Promise<AudioTrackGenre[]> {
    if (this.genres.length) {
      return this.genres;
    }

    this.genres = (await this.store.findAll("audioTrackGenre")) as unknown as AudioTrackGenre[];
    return this.genres;
  }

  generateWaveform(url: string): Promise<number[]> {
    return this.waveformGenerator.generateWaveform(makeCORSUrl(url));
  }
}
