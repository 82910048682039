import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { ProjectSceneCaptionRouteModel } from "client/authenticated/project/scene/caption/route";
import type AdvancedEditorService from "client/services/advanced-editor";

export default class ProjectSceneCaptionIndexRoute extends Route {
  @service
  declare advancedEditor: AdvancedEditorService;

  async beforeModel(): Promise<void> {
    const { caption } = this.modelFor("authenticated.project.scene.caption") as ProjectSceneCaptionRouteModel;
    const firstText = caption.texts[0];
    const firstLogo = caption.logos[0];

    if (firstText) {
      void this.advancedEditor.transitionToText(caption, firstText, false);
    } else if (firstLogo) {
      void this.advancedEditor.transitionToLogo(caption, firstLogo, false);
    }
  }
}
