import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import type { HexColor } from "renderer-engine";
import type { ManyArray } from "client/models/ember-data-types";
import { folderContainable, FolderContainableTypes } from "client/models/folder-containable";

export enum ContributorTypes {
  TEAM = "team",
  USER = "user"
}

export enum FolderCategoryTypes {
  PROJECTS = "Projects",
  USER_ASSETS = "UserAssets"
}

@folderContainable
export default class Folder extends Model {
  @attr("string")
  declare name: string;

  @attr("string", { allowNull: true })
  declare backgroundColor?: HexColor;

  @attr("string")
  declare contributorType: ContributorTypes;

  @attr("string")
  declare contributable: string;

  @attr("string")
  declare category: FolderCategoryTypes;

  @attr("number")
  declare contentCount: number;

  @hasMany("folder", { async: true, inverse: "parent" })
  declare children: ManyArray<Folder>;

  @belongsTo("folder", { async: true, inverse: "children" })
  declare parent: Folder;

  readonly containableType = FolderContainableTypes.FOLDER;

  get teamFolder(): boolean {
    return this.contributorType === ContributorTypes.TEAM;
  }

  get hasChildren(): boolean {
    // @ts-expect-error
    return !!this.hasMany("children").ids()?.length;
  }

  get hasContent(): boolean {
    return this.contentCount > 0;
  }

  get isRootFolder(): boolean {
    // @ts-expect-error
    return !this.belongsTo("parent").id();
  }

  get unwrappedChildren(): Folder[] {
    // @ts-expect-error
    return (this.hasMany("children").value() || []) as Folder[];
  }

  get containableId(): string {
    return this.id;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    folder: Folder;
  }
}
