import type { VideoClip, VolumeOptions } from "../../scene";
import { TimelineMutation } from "./mutation";

export class UpdateVideoVolumeOptionsMutation extends TimelineMutation {
  grouped = false;

  private originalOptions?: VolumeOptions;

  constructor(private video: VideoClip, private options: VolumeOptions) {
    super();

    const { volume, mute, fadeIn, fadeOut, audioDuckingLevel } = video;
    this.originalOptions = { volume, mute, fadeIn, fadeOut, audioDuckingLevel };
  }

  run() {
    Object.assign(this.video, this.options);
  }

  revert() {
    Object.assign(this.video, this.originalOptions);
  }
}
