import Model, { attr } from "@ember-data/model";

export interface ViewLocationInfo {
  city: string;
  country: string;
  count: number;
}

export default class VideoWatchEventSummary extends Model {
  @attr("number")
  totalViews?: number;

  @attr("number")
  uniqueViews?: number;

  @attr("number")
  totalClicks?: number;

  @attr("number")
  completionRate?: number;

  @attr()
  viewLocations?: ViewLocationInfo[];

  @attr("number")
  fiftyPercentCompletionRate?: number;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    videoWatchEventSummary: VideoWatchEventSummary;
  }
}
