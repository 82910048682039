import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { SignUpRouteModel } from "./route";
import publicEmailDomain from "client/lib/public-email-domain";
import type { ValidationResult } from "client/lib/validation";
import { validatePassword } from "client/lib/validation";
import type User from "client/models/user";
import type NotificationsService from "client/services/notifications";
import type SessionService from "client/services/session";

export default class SignUpController extends Controller<SignUpRouteModel> {
  @service
  declare session: SessionService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  @tracked
  submitting = false;

  get passwordValidation(): ValidationResult {
    return validatePassword(this.model);
  }

  get disabled(): boolean {
    // Other field validation handled by browser.
    const { isValid } = this.passwordValidation;

    return this.loading || !isValid;
  }

  get loading(): boolean {
    return this.submitting || this.handlingAuthentication;
  }

  private get emailDomain(): string | void {
    const index = this.model.email.lastIndexOf("@");

    if (index > 0) {
      return this.model.email.substring(index + 1);
    }
  }

  private get handlingAuthentication(): boolean {
    return this.session.handlingAuthentication;
  }

  get hasPublicEmailDomain(): boolean {
    const includeBrandableDomains = true;
    return !!this.emailDomain && publicEmailDomain(this.emailDomain, includeBrandableDomains);
  }

  @action
  handleSubmit(ev: Event): void {
    ev.preventDefault();
    void this.signup();
  }

  private async signup(): Promise<void> {
    if (this.disabled) {
      return;
    }

    this.submitting = true;

    try {
      const user = await this.createUser();
      user.clearUserPassword();
      await this.login();
    } catch (err) {
      // @ts-expect-error
      const errorMessage = err?.errors?.[0]?.detail;

      if (errorMessage) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.notifications.error(errorMessage);
      }
    } finally {
      this.submitting = false;
    }
  }

  private async createUser(): Promise<User> {
    const { name, email, password } = this.model;

    return this.store
      .createRecord("user", {
        fullName: name || email,
        email: email,
        password: password
      })
      .save();
  }

  private login(): Promise<void> {
    const { email, password } = this.model;

    return this.session.authenticate("authenticator:biteable", email, password, /* firstSession */ true);
  }
}
