import { action } from "@ember/object";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Favorable from "client/models/favorable";
import type Favorite from "client/models/favorite";
import type SelectableAsset from "client/models/selectable-asset";
import type FavoritesService from "client/services/favorites";
import type PermissionsService from "client/services/permissions";
import type ProjectContentBarService from "client/services/project-content-bar";

interface Args {
  asset: SelectableAsset;
  selectAsset(asset: SelectableAsset): unknown;
  shouldInferAspectRatio?: boolean;
  assetPrice?: string;
  assetPurchased?: boolean;
  showFavorite?: boolean;
  teamFavorite?: boolean;
  unpublished?: boolean;
  loadingMessage?: string;
  hasAudio?: boolean;
  unfavorite?: (favorable: Favorable) => Promise<void>;
}

export default class PanelContentItem extends Component<Args> {
  @service
  declare favorites: FavoritesService;

  @service
  declare permissions: PermissionsService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @tracked
  hoverOnBrandable = false;

  @tracked
  asset: SelectableAsset;

  private clonedItem?: HTMLElement;

  styleNamespace = getStyleNamespace("project-content-bar/content-item");

  constructor(owner: Owner, args: Args) {
    super(owner, args);
    this.asset = args.asset;
  }

  @action
  selectAsset(): void {
    this.args.selectAsset(this.args.asset);
  }

  @action
  onMouseEnter(): void {
    this.hoverOnBrandable = this.args.asset.isBrandableTemplate;
  }

  @action
  onMouseLeave(): void {
    this.hoverOnBrandable = false;
  }

  @action
  async favorite(favorable: Favorable): Promise<void> {
    void this.projectContentBar.favorite(favorable, !!this.args.teamFavorite);
  }

  @action
  async unfavorite(favorable: Favorable): Promise<void> {
    if (this.args.unfavorite) {
      await this.args.unfavorite(favorable);
    } else {
      await this.projectContentBar.unfavorite(favorable);
    }
  }

  get favoriteRecord(): Favorite | undefined {
    return (this.asset as unknown as Favorable).favoriteRecord;
  }

  @action
  onDragStart(_: object, event: DragEvent): void {
    const target = event.target as HTMLElement;

    this.clonedItem = target.cloneNode(true) as HTMLElement;
    const { width, height } = target.getBoundingClientRect();

    Object.assign(this.clonedItem.style, {
      width: `${width}px`,
      height: `${height}px`
    });
    this.clonedItem.classList.add(`${this.styleNamespace}__DragImage`);
    document.body.appendChild(this.clonedItem);
    event.dataTransfer?.setDragImage(this.clonedItem, 0, 0);
    this.projectContentBar.startDragContent();
  }

  @action
  onDragEnd(): void {
    this.clonedItem?.remove();
    this.projectContentBar.stopDragContent();
  }

  get unpublished(): boolean {
    return !!this.args.unpublished && this.permissions.has("publish_template_content");
  }
}
