import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import BrandLogo from "client/lib/brand/brand-logo";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import getStyleNamespace from "client/lib/get-style-namespace";
import type UserAsset from "client/models/user-asset";
import { ASSET_LIBRARY_IMAGE_MIMETYPES } from "client/services/asset-library";
import type PermissionsService from "client/services/permissions";

const PER_PAGE = 20;
const ASSET_TYPE = "logo";

interface Args {
  brandStyleConfig: BrandStyleConfig;
  addLogo(logo: BrandLogo): unknown;
  onCancel(): unknown;
}

export default abstract class BrandEditAddLogoComponent extends Component<Args> {
  @service
  declare router: RouterService;

  @service
  private declare permissions: PermissionsService;

  @service
  declare store: Store;

  @tracked
  results: Array<UserAsset> = [];

  @tracked
  page = 1;

  @tracked
  totalCount = 0;

  @tracked
  adding = false;

  @tracked
  loading = false;

  styleNamespace = getStyleNamespace("brand/edit/add-logo");

  get brandStyleConfig(): BrandStyleConfig {
    return this.args.brandStyleConfig;
  }

  get canLoadNextPage(): boolean {
    return this.hasNextPage && !this.loading;
  }

  get hasNextPage(): boolean {
    return this.totalCount > this.page * PER_PAGE;
  }

  get emptyResults(): boolean {
    return !this.results.length;
  }

  get selectedUserAssetIds(): Array<string | undefined> {
    return this.brandStyleConfig.logos.map(({ userAssetId }) => userAssetId);
  }

  get acceptableMimeTypes(): string {
    if (this.permissions.has("feature_svg_upload")) {
      return ASSET_LIBRARY_IMAGE_MIMETYPES.toString();
    } else {
      return ASSET_LIBRARY_IMAGE_MIMETYPES.filter((e) => e !== "image/svg+xml").toString();
    }
  }

  @action
  onCancel(): void {
    this.args.onCancel();
  }

  @action
  didInsert(): void {
    if (this.emptyResults) {
      void this.loadResults();
    }
  }

  @action
  async selectUserAsset(userAsset: UserAsset): Promise<void> {
    if (this.adding) {
      return;
    }

    this.adding = true;

    const logo = new BrandLogo(userAsset, {});

    try {
      await this.args.addLogo(logo);

      this.onCancel();
    } finally {
      this.adding = false;
    }
  }

  @action
  addLogo(logo: BrandLogo): void {
    this.brandStyleConfig.addDefaultLogo(logo);
  }

  @action
  loadNextPage(): void {
    if (this.hasNextPage) {
      this.page++;
      void this.loadResults();
    }
  }

  @action
  async loadResults(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const storedLogos = await this.store.query("userAsset", {
        /* eslint-disable camelcase */
        per_page: PER_PAGE,
        type: ASSET_TYPE,
        page: this.page
        /* eslint-enable camelcase */
      });

      // @ts-expect-error
      this.totalCount = storedLogos.meta["total-count"];
      // @ts-expect-error
      this.results = this.results.concat(storedLogos);
    } finally {
      this.loading = false;
    }
  }
}
