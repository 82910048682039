import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import confetti from "canvas-confetti";
import { Colors } from "client/lib/colors";

export default class TrialStartIndexController extends Controller {
  @tracked
  loading = false;

  @action
  didInsertButton(): void {
    this.confetti();
  }

  get routeAfterTrial(): string {
    if (this.model.hasExistingBrand === true) {
      return "authenticated.home";
    } else {
      return "authenticated.brand.new";
    }
  }

  private confetti(): void {
    void confetti({
      particleCount: 150,
      spread: 100,
      origin: { y: 0.6 },
      colors: Object.values(Colors),
      disableForReducedMotion: true,
      zIndex: 105
    });
  }
}
