import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { Media } from "client/lib/editor-domain-model";
import { Text } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import {
  getIcon,
  getNameLabel,
  getThumbnailImgSrc,
  getThumbnailVideoSrc,
  getTypeLabel
} from "client/lib/timeline/element";

interface Args {
  element: Text | Media;
}

const THUMBNAIL_OFFSET_X = 22;

export default class AdvancedTimingLayerPreviewComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("advanced-timing/layer/preview");

  thumbnail?: HTMLElement;

  clonedItem?: HTMLElement;

  @action
  hover(event: MouseEvent): void {
    if (!this.thumbnail) {
      return;
    }
    this.removeClonedThumbnail();

    this.clonedItem = this.thumbnail.cloneNode(true) as HTMLElement;

    this.clonedItem.classList.add(`${this.styleNamespace}__ClonedThumbnail`);
    if (this.args.element instanceof Text) {
      this.clonedItem.innerHTML = this.trim(this.args.element.content, 144);
    }
    document.body.appendChild(this.clonedItem);

    this.updateClonedThumbnailPosition(event.clientX, event.clientY);
  }

  @action
  onMouseMove(event: MouseEvent): void {
    if (this.clonedItem) {
      this.updateClonedThumbnailPosition(event.clientX, event.clientY);
    }
  }

  @action
  removeClonedThumbnail(): void {
    if (this.clonedItem && this.clonedItem.parentNode) {
      this.clonedItem.parentNode.removeChild(this.clonedItem);
      this.clonedItem = undefined;
    }
  }

  updateClonedThumbnailPosition(x: number, y: number): void {
    if (this.clonedItem) {
      const { height } = this.clonedItem.getBoundingClientRect();

      Object.assign(this.clonedItem.style, {
        left: `${x + THUMBNAIL_OFFSET_X}px`,
        top: `${y - height / 2}px`
      });
    }
  }

  @action
  setThumbnailElement(element: HTMLElement): void {
    this.thumbnail = element;
  }

  get imgSrc(): string | undefined {
    return getThumbnailImgSrc(this.args.element);
  }

  get videoSrc(): string | undefined {
    return getThumbnailVideoSrc(this.args.element);
  }

  get icon(): string {
    return getIcon(this.args.element);
  }

  get label(): string {
    return getNameLabel(this.args.element) ?? getTypeLabel(this.args.element);
  }

  private trim(name: string, trimAfter = 9): string {
    return name.length > trimAfter ? name.slice(0, trimAfter - 2) + "..." : name;
  }
}
