import type { Project } from "@biteable/network-model";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type MobileService from "client/services/mobile";

interface AppHeaderArgs {
  project?: Project;
  title?: string;
  dashboard?: boolean;
}

export default class AppHeader extends Component<AppHeaderArgs> {
  @service
  declare router: RouterService;

  @service
  declare mobile: MobileService;

  styleNamespace = getStyleNamespace("app-header");

  get isEditor(): boolean {
    const { currentRouteName } = this.router;
    if (
      currentRouteName.includes("authenticated.project.style") ||
      currentRouteName.includes("authenticated.project.scenes") ||
      currentRouteName.includes("authenticated.project.export") ||
      currentRouteName.includes("authenticated.publish")
    ) {
      return false;
    } else {
      return true;
    }
  }

  get showPublish(): boolean {
    if (this.mobile.isMobile) {
      return this.isEditor && !!this.args.project;
    } else {
      return !!this.args.project;
    }
  }
}
