import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Plan from "client/models/plan";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type BrandStyleService from "client/services/brand-style";
import type PlansService from "client/services/plans";

export default class TrialRoute extends Route<Plan> {
  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @service
  declare brandStyle: BrandStyleService;

  @service
  declare plans: PlansService;

  async beforeModel(): Promise<void> {
    const hasBrand = await this.checkBrand();

    if (this.isTopTierUser && !hasBrand) {
      void this.router.transitionTo("authenticated.brand.new");
    } else if (this.ineligibleForTrial) {
      void this.router.transitionTo("authenticated.home");
    } else {
      void this.router.transitionTo("authenticated.trial.start");
    }
  }

  async model(): Promise<Plan> {
    return this.plans.getDefaultTrialPlan();
  }

  get plan(): Plan | undefined {
    return this.auth.currentSubscription?.plan;
  }

  get isTopTierUser(): boolean {
    return this.plan?.isTopTierPlan ?? false;
  }

  get ineligibleForTrial(): boolean {
    return this.userNotPermitted || this.hasUsedTopTierPlan || this.isTopTierUser;
  }

  get userNotPermitted(): boolean {
    return !this.user?.canManageSubscription;
  }

  get hasUsedTopTierPlan(): boolean {
    return this.user?.hasUsedTopTierPlan ?? false;
  }

  private async checkBrand(): Promise<boolean> {
    const brandStyle = await this.brandStyle.getBrand();
    return !!brandStyle;
  }

  get user(): User | undefined {
    return this.auth.currentUser;
  }
}
