import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type FloatingPanelsService from "client/services/floating-panels";
import type { FloatingPanelType } from "client/services/floating-panels";

interface Args {
  panel: FloatingPanelType;
}

export default class FloatingPanelComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panel");

  @service
  private declare floatingPanels: FloatingPanelsService;

  get hidden(): boolean {
    return !this.floatingPanels.active(this.args.panel);
  }

  @action
  close(): void {
    this.floatingPanels.toggle(this.args.panel);
  }
}
