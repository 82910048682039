import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ColorPickerBrandColorsArgs {
  selectColor: (color?: string) => void;
}

export default class ColorPickerBrandedComponent extends Component<ColorPickerBrandColorsArgs> {
  styleNamespace = getStyleNamespace("color-picker-branded/brand-colors");

  @action
  selectColor(color?: string): void {
    this.args.selectColor(color);
  }
}
