import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import type { RouteModel } from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { AvaStyles, AvaUseCases } from "client/authenticated/survey/data";
import type { Style } from "client/authenticated/survey/video-assistant/style/route";
import type { TrackingProperties } from "client/components/survey/nav/component";
import TrackingEvents from "client/events";
import removeObjectDuplicates from "client/lib/remove-object-duplicates";
import { SimpleRumTimer } from "client/lib/rum-timer";
import type AspectRatio from "client/models/aspect-ratio";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type AjaxService from "client/services/ajax";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";
import type VideoAssistantService from "client/services/video-assistant";
import MessageBus from "message-bus-client";

type AvaGeneratorResponse = {
  script?: string;
  media_search_keywords?: string; // eslint-disable-line camelcase
};

export default class VideoAssistantStyleController extends Controller {
  queryParams = ["referer", "generatorId"];

  STYLES_ORDER = [
    AvaStyles.Basic,
    AvaStyles.Civic,
    AvaStyles.Corporate,
    AvaStyles.Modern,
    AvaStyles.Silk,
    AvaStyles.Watercolor,
    AvaStyles.Festive
  ];

  messages = [
    "Conducting robot-related script sorcery...",
    "Teleporting your script from robot HQ to your screen...",
    "Sprinkling some magic dust atop your video...",
    "Weaving AI wizardry...",
    "Hold tight, our robot misplaced his wizard hat...",
    "Waving our digital wand...",
    "Assigning you an AI fairy godmother...",
    "Brewing a bubbling pot of pixel potion...",
    "Explaining the plot of Harry Potter 1–7 to the robots...",
    "Casting a robot spell for the perfect video script...",
    "Conjuring words and levitating letters..."
  ];

  @tracked
  referer?: string;

  @tracked
  generatorId?: string;

  @tracked
  loading = false;

  @service
  declare ajax: AjaxService;

  @service
  declare router: RouterService;

  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  @service
  declare tracking: TrackingService;

  private videoAssistantProductId?: string;

  @action
  async back(): Promise<void> {
    await this.router.transitionTo("authenticated.survey.video-assistant.description", {
      queryParams: { referer: this.referer, generatorId: this.generatorId }
    });
  }

  @action
  async continue(): Promise<void> {
    await this.startVideoScriptProcess();
  }

  @action
  selectAspectRatio(aspectRatio: AspectRatio): void {
    this.videoAssistant.aspectRatioId = aspectRatio.id;
    this.generatorId = undefined;
  }

  @action
  selectStyle(style: Style): void {
    this.videoAssistant.style = style.name;
    this.generatorId = undefined;
  }

  get styles(): Style[] {
    const stylesForAspectRatios = this.model.styles
      .filter((style: Style) => style.aspectRatio.slug === this.selectedAspectRatio?.slug)
      .sort((a: Style, b: Style) => {
        const getIndex = (index: number): number => {
          return index === -1 ? this.STYLES_ORDER.length : index;
        };

        const indexA = getIndex(this.STYLES_ORDER.indexOf(a.name));
        const indexB = getIndex(this.STYLES_ORDER.indexOf(b.name));

        return indexA - indexB;
      });

    return removeObjectDuplicates(stylesForAspectRatios, "name");
  }

  get selectedStyle(): string | undefined {
    return this.videoAssistant.style;
  }

  get aspectRatios(): AspectRatio[] {
    const allAspectRatios = this.model.styles
      .map((style: Style) => style.aspectRatio)
      .sort((a: AspectRatio, b: AspectRatio) => a.order - b.order);
    return removeObjectDuplicates(allAspectRatios, "name");
  }

  get selectedAspectRatio(): AspectRatio | undefined {
    return this.aspectRatios.find((aspectRatio) => aspectRatio.id === this.videoAssistant.aspectRatioId);
  }

  get header(): string {
    if (this.videoAssistant.useCase === AvaUseCases.Template) {
      return "Make a video";
    } else {
      return `${this.videoAssistant.job} ${this.videoAssistant.useCase}`;
    }
  }

  get subheader(): string {
    return `Make my video ${this.selectedAspectRatio?.name} ${
      this.selectedStyle ? `in the ${this.selectedStyle} style` : ""
    }`;
  }

  get trackingProperties(): TrackingProperties {
    return {
      style: this.selectedStyle,
      aspectRatioId: this.selectedAspectRatio?.name,
      referer: this.referer
    };
  }

  private async startVideoScriptProcess(): Promise<void> {
    const currentGenerator = await this.findCurrentGenerator();

    const timer = new SimpleRumTimer("video-assistant-generation");
    timer.meta = {
      generator: currentGenerator.name,
      slug: currentGenerator.slug,
      error: false
    };

    this.loading = true;

    this.trackGenerateVideoClick();
    const starterProjectId = await this.getStarterProjectId(currentGenerator);

    const userInputs = Object.entries(this.videoAssistant.fields).map(([key, value]) => ({
      key,
      value
    }));

    const body = {
      data: {
        attributes: {
          /* eslint-disable camelcase */
          generator_id: currentGenerator?.id,
          inputs: userInputs
          /* eslint-enable camelcase */
        }
      }
    };

    const channelName = "video-script:generation";
    const problemHandler = async (error: Error | string): Promise<void> => {
      MessageBus.unsubscribe(channelName, responseHandler);

      timer.meta["error"] = true;
      timer.sample();

      // eslint-disable-next-line no-console
      console.error(`Error with video-scripts API request: ${this.videoAssistantProductId},`, error);
      this.honeybadger.notify(error);

      this.loading = false;
      // Transition to the route and enter the fallback script flow.
      // The route relies on a videoAssistant record in the store, otherwise will use the fallback.
      // We're intentionally using only the first template, the UI does not
      // support template choice yet.
      await this.router.transitionTo(
        "authenticated.video-assistant.populate-template",
        starterProjectId as RouteModel,
        {
          queryParams: { referer: this.referer }
        }
      );
    };

    const responseHandler = async (response: AvaGeneratorResponse): Promise<void> => {
      MessageBus.unsubscribe(channelName, responseHandler);

      if (!response) {
        await problemHandler("Missing response from AVA generation");
        return;
      }

      const { script, media_search_keywords: mediaSearchKeywords } = response;
      if (!script || script.length === 0) {
        await problemHandler("Empty response from AVA generation");
        return;
      }

      try {
        const data = {
          id: "1",
          type: "videoAssistant",
          attributes: {
            rawScript: script,
            script: this.videoAssistant.parseVideoScriptToArray(script),
            mediaKeywords: mediaSearchKeywords
          }
        };

        this.store.push({ data });

        this.loading = false;
        this.videoAssistant.resetVideoAssistant();

        // We're intentionally using only the first template, the UI does not
        // support template choice yet.
        await this.router.transitionTo(
          "authenticated.video-assistant.populate-template",
          starterProjectId as RouteModel,
          {
            queryParams: { referer: this.referer }
          }
        );
      } finally {
        console.info(`VideoAssistant generated video successfully: ${this.videoAssistantProductId}`);
        timer.sample();
      }
    };

    MessageBus.subscribe(channelName, responseHandler);

    const response = await this.ajax
      .api("/video-scripts", {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(body)
      })
      .catch(problemHandler);

    if (response) {
      this.videoAssistantProductId = response["video_assistant_product_id"];
      console.info(`VideoAssistant generator started: ${this.videoAssistantProductId}`);
    }
  }

  async findCurrentGenerator(): Promise<VideoAssistantGenerator> {
    const videoAssistantGenerators = (await this.store.query("videoAssistantGenerator", {
      /* eslint-disable camelcase */
      job: this.videoAssistant.job,
      use_case: this.videoAssistant.useCase,
      style: this.selectedStyle,
      aspect_ratio: this.selectedAspectRatio?.slug,
      id: this.generatorId
      /* eslint-enable camelcase */
    })) as unknown as VideoAssistantGenerator[];

    const videoAssistantGenerator = videoAssistantGenerators[0];

    if (!videoAssistantGenerator) {
      this.notifications.error("There was a problem fetching a generator.");
      throw Error("Failed to fetch a generator");
    }

    return videoAssistantGenerator;
  }

  private async getStarterProjectId(currentGenerator: VideoAssistantGenerator): Promise<string> {
    const templates = await Promise.all(
      (
        await currentGenerator.templates
      ).map(async (template) => {
        return template;
      })
    );

    for (const template of templates) {
      const project = await template.project;
      const aspectRatio = await project.aspectRatio;

      if (project.style === this.videoAssistant.style && aspectRatio.slug === this.selectedAspectRatio?.slug) {
        return project.id;
      }
    }

    return templates[0]!.projectId;
  }

  private get stylesPerGenerator(): Style[] {
    return this.model.styles(({ generatorId }: Style) => generatorId === this.generatorId);
  }

  private trackGenerateVideoClick(eventProperties = {}): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_VIDEO_ASSISTANT_GENERATE_VIDEO, {
      referer: this.referer,
      ...eventProperties
    });
  }
}
