import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AccountSubscriptionRoute from "../route";
import type Subscription from "client/models/subscription";

export interface AccountSubscriptionCancelRouteModel {
  fullSubscription: Subscription;
}

export default class AccountSubscriptionCancelRoute extends Route<AccountSubscriptionCancelRouteModel> {
  @service
  declare router: RouterService;

  beforeModel(): void {
    const { fullSubscription, user } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    if (!fullSubscription || !user.canManageSubscription || fullSubscription.canceled) {
      this.redirectAway();
    }
  }

  model(): AccountSubscriptionCancelRouteModel {
    const { fullSubscription } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    return {
      fullSubscription: fullSubscription as Subscription
    };
  }

  private redirectAway(): void {
    void this.router.replaceWith("authenticated.account.subscription");
  }
}
