import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface OnboardingTileArgs {
  title: string;
  selectedValue?: string | number;
  value?: string | number;
  onSelect?: (value: string | number | undefined) => Promise<void> | void;
}

export default class OnboardingSurveyTileComponent extends Component<OnboardingTileArgs> {
  styleNamespace = getStyleNamespace("onboarding/survey-tile");

  @tracked
  loading = false;

  @action
  async handleClick(): Promise<void> {
    this.loading = true;

    try {
      await this.args.onSelect?.(this.args.value);
    } finally {
      this.loading = false;
    }
  }

  get selected(): boolean {
    if (this.args.selectedValue) {
      return this.args.selectedValue === this.args.value;
    }
    return false;
  }
}
