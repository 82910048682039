import type { AudioClip, AudioAsset } from "../audio";
import { EventRegister } from "../events/event-register";
import { SaveAction } from "../events/mutations/save-action";
import type { FontFamily } from "../font";
import type { Caption, Logo, Scene, Text, TextStyle } from "../scene";
import type { Watermark } from "../scene/watermark";
import { Timeline } from "./timeline";

export interface TimelineFacade {
  getAudioClips: () => Promise<AudioClip[]>;
  getScenes: () => Promise<Scene[]>;
  getAspectRatio: () => [width: number, height: number, widthPx: number, heightPx: number];
  getWatermark: () => Promise<Watermark | undefined>;
  getCustomFonts: () => Promise<FontFamily[]>;
  getBrandStyleId: () => string;
  getAspectRatioId: () => string;
  saveAudioClips: (timeline: Timeline) => Promise<void>;
  saveScene: (scene: Scene, saveOptions?: { delayCommitMs?: number }) => Promise<void>;
  saveSceneOrder: (timeline: Timeline) => Promise<void>;
  saveWatermark: (watermark: Watermark, saveOptions?: { delayCommitMs?: number }) => Promise<void>;
  getThumbnail: (scene: Scene) => Promise<HTMLImageElement>;
  getCaptionThumbnail: (caption: Caption) => Promise<HTMLImageElement>;
  newAudioClip: (asset: AudioAsset, category: string, offset: number, duration: number) => Promise<AudioClip>;
  newText: (caption: Caption, style?: TextStyle) => Promise<Text>;
  newLogo: (caption: Caption) => Promise<Logo>;
  newCaption: (scene: Scene, offset: number, duration: number, options: CaptionOptionArgs) => Promise<Caption>;
  newScene: (duration: number) => Promise<Scene>;
  duplicateScene: (sceneId: string) => Promise<Scene>;
  duplicateCaption: (scene: Scene, caption: Caption) => Promise<Caption>;
  transitionTo: (url: string) => Promise<void>;
  readonly currentURL: string;
  checkForTextOverflow: (caption: Caption, context: CanvasRenderingContext2D) => Promise<boolean>;
  duplicateElement: (elementId: string, parentZymbolGroupId?: string) => Promise<Logo | Text>;
}

// Default values mimic what the original implementation intended
export interface CaptionOptionArgs {
  centered?: boolean;
  withLogo?: boolean;
  withText?: boolean;
}

export class TimelineFactory {
  public eventRegister: EventRegister;

  constructor(public facade: TimelineFacade) {
    this.eventRegister = new EventRegister(facade);
  }

  public async buildTimeline(id: string): Promise<Timeline> {
    const scenes = this.facade.getScenes();
    const audioClips = this.facade.getAudioClips();
    const aspectRatio = this.facade.getAspectRatio();
    const brandStyleId = this.facade.getBrandStyleId();
    const aspectRatioId = this.facade.getAspectRatioId();
    const watermark = this.facade.getWatermark();
    const customFonts = this.facade.getCustomFonts();
    return new Timeline(
      id,
      await scenes,
      await audioClips,
      aspectRatio,
      await watermark,
      await customFonts,
      brandStyleId,
      aspectRatioId
    );
  }

  public async fireRebuildTimeline(timeline: Timeline): Promise<Timeline> {
    return await this.eventRegister.fire(new SaveAction(() => this.doRebuildTimeline(timeline)));
  }

  public async doRebuildTimeline(timeline: Timeline): Promise<Timeline> {
    const scenes = this.facade.getScenes();
    const audioClips = this.facade.getAudioClips();
    const watermark = this.facade.getWatermark();
    const customFonts = this.facade.getCustomFonts();

    const oldScenes = Array.from(timeline.scenes);
    for (const scene of oldScenes) {
      timeline._removeScene(scene);
    }

    for (const clip of Array.from(timeline.audioClips)) {
      timeline._removeAudioClip(clip);
    }

    for (const scene of await scenes) {
      const oldScene = oldScenes.find(({ id }) => id === scene.id);
      if (oldScene) {
        oldScene._update(scene);
        timeline._addScene(oldScene);
      } else {
        timeline._addScene(scene);
      }
    }

    for (const clip of await audioClips) {
      timeline._addAudioClip(clip);
    }

    timeline._watermark = await watermark;
    timeline._customFonts = await customFonts;

    return timeline;
  }
}
