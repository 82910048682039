import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

enum SpinnerPosition {
  START = "start",
  CENTER = "center"
}

interface SpinnerArgs {
  position?: string;
}

export default class SpinnerComponent extends Component<SpinnerArgs> {
  styleNamespace = getStyleNamespace("north-star/spinner");

  get position(): string {
    return this.args.position ?? SpinnerPosition.CENTER;
  }
}
