import Route from "@ember/routing/route";
import type { AccountSubscriptionCancelRouteModel } from "client/authenticated/account/subscription/cancel/route";
import type AccountSubscriptionCancelRoute from "client/authenticated/account/subscription/cancel/route";

export default class AccountSubscriptionCancelWarningRoute extends Route<AccountSubscriptionCancelRouteModel> {
  model(): AccountSubscriptionCancelRouteModel {
    const { fullSubscription } = this.modelFor<AccountSubscriptionCancelRoute>(
      "authenticated.account.subscription.cancel"
    );

    return {
      fullSubscription
    };
  }
}
