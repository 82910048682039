import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { FAVORABLE_TYPES } from "client/models/favorable";
import type { Attribution } from "client/models/stock-asset";

interface ProviderAttributionTagArgs {
  showTag: boolean;
  attribution?: Attribution;
  type: string;
}

export default class ProviderAttributionTagComponent extends Component<ProviderAttributionTagArgs> {
  styleNamespace = getStyleNamespace("provider-attribution-tag");

  get description(): string {
    return this.attribution?.author
      ? `Authored by ${this.attribution?.author} on ${this.provider}`
      : `Provided by ${this.provider}`;
  }

  get url(): string | undefined {
    if (this.attribution?.authorUrl) {
      return this.attribution.authorUrl;
    } else if (this.isUnsplash) {
      return "https://unsplash.com/";
    } else if (this.isPexels) {
      return "https://www.pexels.com/";
    } else {
      return "";
    }
  }

  private get provider(): string {
    if (this.attribution?.provider) {
      return this.attribution.provider;
    } else if (this.isUnsplash) {
      return "Unsplash";
    } else if (this.isPexels) {
      return "Pexels";
    } else {
      return "";
    }
  }

  private get attribution(): Attribution | undefined {
    return this.args.attribution;
  }

  private get isPexels(): boolean {
    return this.args.type === FAVORABLE_TYPES.PEXELS;
  }

  private get isUnsplash(): boolean {
    return this.args.type === FAVORABLE_TYPES.UNSPLASH;
  }
}
