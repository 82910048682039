import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { toInlineStyle } from "../component";
import cmsUrl from "client/lib/cms-url";
import type { Caption, EventRegister, Scene, StrictMutation } from "client/lib/editor-domain-model";
import {
  DeleteCaptionMutation,
  DuplicateCaptionMutation,
  AddNewCaptionMutation,
  VideoClip
} from "client/lib/editor-domain-model";
import { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";
import getScrollParentElement, { ScrollAxis } from "client/lib/get-scroll-parent-element";
import getStyleNamespace from "client/lib/get-style-namespace";
import { latestCaptionEnd } from "client/lib/timeline/caption";
import { BASE_CANVAS_SIZE } from "client/lib/timeline/ember-timeline-builder";
import { transaction } from "client/lib/transaction";
import type AdvancedEditorService from "client/services/advanced-editor";
import type MouseService from "client/services/mouse";
import { MouseEvents } from "client/services/mouse";
import type NotificationsService from "client/services/notifications";
import type PopoverService from "client/services/popover";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ProjectScenesService from "client/services/project-scenes";
import type { ActiveSceneChangedEventProperties } from "client/services/timeline-events";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";
import type ZymbolGroupsService from "client/services/zymbol-groups";

interface ProjectTimelineSceneArgs {
  scene: Scene;
  offset: number;
  order: number;
  isNew: boolean;
  isTemplate: boolean;
  isPublished: boolean;
  title?: string;
  projectId: string;
  isSelected: boolean;
  selectedCaptionId: string;
  eventRegister: EventRegister;
  pixelsPerSecond: number;
  thumbnailSrc?: string;
  mutate: (mutation: StrictMutation) => Caption | void;
  saveScene: (scene: Scene) => Promise<void>;
  reorder: (scene: Scene, newStartTime: number) => Promise<void>;
  saveOrder: () => Promise<void>;
  saveColorPreset: (scene: Scene) => Promise<void>;
  isGenerating?: boolean;
  isAddRouteActive?: boolean;
  isDraggable?: boolean;
  licensed?: boolean;
  showCostInfo?: boolean;
}

const ADD_BUTTON_MINIMUM_WIDTH = 32; // px
export const MIN_WIDTH_FOR_TRANSITIONS = 64; // px

const getNeighbouringCaptions = (caption: Caption): [prev?: Caption, next?: Caption] => {
  const { captions } = caption.scene;
  const order = captions.indexOf(caption);

  return [captions[order - 1], captions[order + 1]];
};

export default class ProjectTimelineSceneComponent extends Component<ProjectTimelineSceneArgs> {
  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare mouse: MouseService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare notifications: NotificationsService;

  @service
  declare popover: PopoverService;

  @tracked
  private isResizing = false;

  @tracked
  private _isDragging = false;

  @tracked
  isAnimating = false;

  @tracked
  disabled = false;

  @tracked
  draggedOffset?: number;

  startDragStartTime?: number;
  startDragParentScrollLeft?: number;

  isSubscribedToDragEvents = false;

  _element?: HTMLElement;
  scrollParentElement?: HTMLElement;

  private dragTransaction = new ExplicitTransaction();

  styleNamespace = getStyleNamespace("project-timeline/scene");

  constructor(owner: object, args: ProjectTimelineSceneArgs) {
    super(owner, args);

    this.timelineEvents.subscribe(TimelineEvents.ACTIVE_SCENE_CHANGED, this.onActiveSceneChanged);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
    this.scrollParentElement = getScrollParentElement(element, ScrollAxis.X);

    const { scene, isSelected, isNew } = this.args;
    if (isSelected) {
      this.onActiveSceneChanged({ scene, animate: isNew });
    }
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    try {
      this.timelineEvents.unsubscribe(TimelineEvents.ACTIVE_SCENE_CHANGED, this.onActiveSceneChanged);
    } catch (err) {
      // Ignore.
    }

    this.unsubscribeFromDragEvents();
  }

  @action
  subscribeToDragEvents(): void {
    if (!this.isDraggable) {
      return;
    }

    this.mouse.subscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
    this.mouse.subscribe(MouseEvents.DRAG, this.onMouseDrag);
    this.mouse.subscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
    this.isSubscribedToDragEvents = true;
  }

  @action
  unsubscribeFromDragEvents(): void {
    if (!this.isSubscribedToDragEvents) {
      return;
    }

    this.mouse.unsubscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
    this.mouse.unsubscribe(MouseEvents.DRAG, this.onMouseDrag);
    this.mouse.unsubscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
    this.isSubscribedToDragEvents = false;
  }

  @action
  onMouseUp(event: MouseEvent): void {
    this.mouse.onMouseUp(event);
    this.unsubscribeFromDragEvents();
  }

  @action
  onMouseDragStart(): void {
    this.isDragging = true;
    this.startDragStartTime = this.args.offset;
    this.startDragParentScrollLeft = this.scrollParentElement?.scrollLeft ?? 0;
    this.dragTransaction = new ExplicitTransaction();
  }

  @action
  async onMouseDrag(event: MouseEvent): Promise<void> {
    await this.doDrag(event);
  }

  @action
  async doDrag(event: MouseEvent): Promise<void> {
    if (!this.mouse.mouseDownX) {
      return;
    }

    const parentScrollDelta = (this.scrollParentElement?.scrollLeft ?? 0) - (this.startDragParentScrollLeft ?? 0);
    const mouseDelta = event.pageX - this.mouse.mouseDownX;

    this.draggedOffset = (this.startDragStartTime ?? 0) * this.args.pixelsPerSecond + parentScrollDelta + mouseDelta;
    await this.args.eventRegister.appendTransaction(
      this.dragTransaction,
      async () => await this.args.reorder(this.args.scene, this.draggedOffset! / this.args.pixelsPerSecond)
    );
  }

  @action
  async onMouseDragEnd(): Promise<void> {
    this.unsubscribeFromDragEvents();
    if (!this.dragTransaction.isEmpty()) {
      await this.args.eventRegister.appendTransaction(this.dragTransaction, () => this.args.saveOrder());
    }

    next(this, () => {
      this.isDragging = false;
    });

    this.popover.trigger("close");
  }

  set resizeDirection(direction: string | undefined) {
    this.mouse.resizeDirection = direction;
    this.isResizing = !!direction;
  }

  set isDragging(isDragging: boolean) {
    this.mouse.isDragging = isDragging;
    this._isDragging = isDragging;
  }

  get isDragging(): boolean {
    return this._isDragging;
  }

  @action
  onResize(direction?: string): void {
    this.resizeDirection = direction;
  }

  @action
  enable(enabled: boolean): void {
    this.disabled = !!enabled;
  }

  get isModifying(): boolean {
    return this.isResizing || this.isDragging;
  }

  get isDraggable(): boolean {
    return !this.isModifying && !!this.args.isDraggable;
  }

  get isCaptionSelected(): boolean {
    return this.args.scene.captions.some(({ id }) => id === this.args.selectedCaptionId);
  }

  get showActions(): boolean {
    return this.args.isSelected && !this.isDragging;
  }

  get showTransitionsMenu(): boolean {
    return this.args.order > 0 && this.pixelWidth >= MIN_WIDTH_FOR_TRANSITIONS;
  }

  @action
  onClick(): void {
    if (this.isModifying || this.disabled) {
      return;
    }

    void this.transitionToScene(this.args.scene);
  }

  @action
  onActiveSceneChanged({ scene, animate = false }: ActiveSceneChangedEventProperties): void {
    if (this.isDestroyed || this.isDestroying || !scene || scene.id !== this.args.scene.id) {
      return;
    }

    this.makeVisible();

    if (animate) {
      this.animate();
    }
  }

  @action
  makeVisible(): void {
    if (!this._element) {
      return;
    }

    this._element.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth"
    });
  }

  @action
  animate(): void {
    if (!this._element) {
      return;
    }

    this._element.addEventListener("animationend", this.stopAnimating);
    this.isAnimating = true;
  }

  @action
  stopAnimating(): void {
    if (!this._element) {
      return;
    }

    this.isAnimating = false;
    this._element.removeEventListener("animationend", this.stopAnimating);
  }

  @action
  async transitionToScene({ id }: { id: string }, selectFirstCaption = true): Promise<void> {
    if (selectFirstCaption) {
      void this.router.transitionTo("authenticated.project.scene.background", id);
    } else {
      const transition = this.router.transitionTo("authenticated.project.scene.background", id);
      transition.data = {
        options: {
          skipCaptionCheck: true
        }
      };
      void transition;
    }
  }

  @action
  async transitionToCaption({ id }: { id: string }): Promise<void> {
    if (this.isModifying || this.disabled) {
      return;
    }

    if (this.args.isAddRouteActive || this.args.scene !== this.advancedEditor.scene) {
      void this.router.transitionTo("authenticated.project.scene.caption", this.args.projectId, this.args.scene.id, id);
      return;
    }

    void this.router.transitionTo("authenticated.project.scene.caption", id);
  }

  @action
  @transaction
  async addCaption(event: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    // The add button can end up out of view but the popper instance still exists
    // and then is automatically closed whenever we try to open the menu again
    // once the button is back in view
    this.popover.trigger("close");

    const mutation = new AddNewCaptionMutation(
      this.args.scene,
      this.latestCaptionEnd,
      this.args.scene.duration - this.latestCaptionEnd,
      false
    );

    await mutation.prepare(this.args.eventRegister.facade);

    const caption = this.args.mutate(mutation);
    if (caption) {
      await this.args.saveScene(caption.scene);
      await this.transitionToCaption(caption);
    }
  }

  @action
  @transaction
  async duplicateCaption(caption: Caption, event: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    const mutation = new DuplicateCaptionMutation(this.args.scene, caption);
    await mutation.prepare(this.args.eventRegister.facade);
    const newCaption = this.args.mutate(mutation);
    await this.args.saveScene(caption.scene);

    if (newCaption) {
      return this.transitionToCaption(newCaption);
    } else {
      this.notifications.error("There was a problem duplicating the caption");
    }
  }

  @action
  @transaction
  async deleteCaption(caption: Caption): Promise<void> {
    const [prevCaption, nextCaption] = getNeighbouringCaptions(caption);

    this.args.mutate(new DeleteCaptionMutation(caption));

    await this.args.saveScene(caption.scene);

    if (prevCaption) {
      await this.transitionToCaption(prevCaption);
    } else if (nextCaption) {
      await this.transitionToCaption(nextCaption);
    } else {
      await this.transitionToScene(this.args.scene, false);
    }
  }

  @action
  editSceneInCms(): void {
    const url = cmsUrl(getOwner(this)!, `/scene/${this.args.scene.id}`);

    window.open(url, "_blank");
  }

  @action
  onClickReplaceBackground(event: Event): void {
    if (this.router.currentRouteName.startsWith("authenticated.project.scenes")) {
      void this.router.transitionTo("authenticated.project.scenes", {
        queryParams: { scene: this.args.scene.id }
      });
    } else {
      // so the content-bar won't be auto-closed
      event?.stopPropagation();
      void this.projectContentBar.startReplaceSceneBackground(this.args.scene);
    }
  }

  get pixelWidth(): number {
    return this.args.scene.duration * this.args.pixelsPerSecond - 2;
  }

  get pixelHeight(): number {
    return 94;
  }

  get backgroundImageUrl(): string | undefined {
    return this.args.thumbnailSrc;
  }

  get isGeneratingThumbnail(): boolean {
    return !this.args.thumbnailSrc || !!this.args.isGenerating;
  }

  get thumbnailPixelWidth(): number {
    const [width, height] = this.args.scene.aspectRatio;
    return Math.floor(BASE_CANVAS_SIZE / (height / width));
  }

  get shouldTileBackground(): boolean {
    return this.pixelWidth > this.thumbnailPixelWidth;
  }

  get backgroundSize(): string {
    return this.shouldTileBackground ? `${this.thumbnailPixelWidth}px` : "cover";
  }

  get backgroundPosition(): string {
    return `${this.shouldTileBackground ? "0%" : "50%"} 50%`;
  }

  get backgroundImage(): string {
    const { thumbnailSrc } = this.args;

    return thumbnailSrc ? `url(${thumbnailSrc})` : "none";
  }

  get backgroundColor(): string {
    return this.args.scene.color ?? "#fff";
  }

  get hasVideoBackground(): boolean {
    return this.args.scene.background.asset instanceof VideoClip && this.args.scene.background.hasContent;
  }

  get silhouetteStyle(): SafeString {
    return htmlSafe(`width: ${this.pixelWidth}px`);
  }

  get inlineStyle(): SafeString {
    let styles = `width: ${this.pixelWidth}px;`;

    if (this.isDragging) {
      styles = styles.concat(`left: ${this.draggedOffset ?? 0}px;`);
    }

    return htmlSafe(styles);
  }

  get backgroundStyle(): SafeString {
    const styles = `background-image: ${this.backgroundImage};
      background-size: ${this.backgroundSize};
      background-color: ${this.backgroundColor};
      background-position: ${this.backgroundPosition}`;

    return htmlSafe(styles);
  }

  get latestCaptionEnd(): number {
    return latestCaptionEnd(this.args.scene.captions);
  }

  get pixelSpaceAtEnd(): number {
    return Math.max(0, (this.args.scene.duration - this.latestCaptionEnd) * this.args.pixelsPerSecond);
  }

  get shouldShowAddButton(): boolean {
    return (
      !this.isModifying &&
      !this.disabled &&
      (this.args.isSelected || this.isCaptionSelected) &&
      this.pixelSpaceAtEnd >= ADD_BUTTON_MINIMUM_WIDTH
    );
  }

  get addButtonStyle(): SafeString {
    return toInlineStyle(this.latestCaptionEnd * this.args.pixelsPerSecond, "left");
  }
}
