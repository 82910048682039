import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { ProjectScenesFavoritesRouteModel } from "client/authenticated/project/scenes/favorites/route";
import type { InfiniteParams } from "client/components/infinite/component";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type Favorable from "client/models/favorable";
import type Favorite from "client/models/favorite";
import type { ArrayWithMeta } from "client/models/pagination";
import type SelectableAsset from "client/models/selectable-asset";
import SelectableMediaAsset from "client/models/selectable-media-asset";
import type StockLicense from "client/models/stock-license";
import type ProjectContentBarService from "client/services/project-content-bar";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export type QueryOptions = Record<string, string | number | boolean | undefined>;

export default abstract class ProjectScenesFavoritesBaseController extends Controller<ProjectScenesFavoritesRouteModel> {
  @tracked
  adding = false;

  @service
  private declare timelineEvents: TimelineEventsService;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @service
  declare store: Store;

  protected teamFavorite = false;

  abstract get otherQueryOptions(): QueryOptions;

  get stockLicenses(): Array<StockLicense> {
    return this.model.stockLicenses;
  }

  private get projectAspectRatio(): string {
    return this.model.project.aspectRatio.name;
  }

  @action
  async getResults(params: InfiniteParams): Promise<ArrayWithMeta<Favorite>> {
    // @ts-expect-error
    const results = (await this.store.query("favorite", {
      include: ["favorable"],
      page: params.page,
      per_page: params.perPage /* eslint-disable-line camelcase */,
      ...this.otherQueryOptions
    })) as ArrayWithMeta<Favorite>;

    this.transformResults(results);

    return results;
  }

  protected transformResults(results: ArrayWithMeta<Favorite>): void {
    results.forEach(({ favorable, metadata = {} }) => {
      // Manually override the asset's aspect-ratio to show in the ratio of the current project
      if (favorable instanceof SelectableMediaAsset) {
        Object.assign(favorable, {
          thumbAspectRatio: this.projectAspectRatio
        });
      }

      Object.assign(favorable.metadataForFavorite, metadata);
    });
  }

  @action
  async select(asset: SelectableAsset): Promise<void> {
    this.adding = true;

    try {
      const { scene, afterScene, timeline, eventRegister } = this.model;
      const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene);

      if (this.projectContentBar.isReplacing) {
        await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
      } else {
        await modifier.applyAsset(asset);
      }
    } finally {
      this.adding = false;
    }
  }

  @action
  async favorite(favorable: Favorable): Promise<void> {
    await favorable.favorite(this.teamFavorite);

    if (favorable.favorableType === "project-scenes") {
      this.timelineEvents.publish(TimelineEvents.SCENE_FAVORITE_CHANGED, {
        favorited: true,
        teamFavorited: this.teamFavorite,
        projectScene: favorable
      });
    }
  }

  @action
  async unfavorite(favorable: Favorable): Promise<void> {
    await favorable.unfavorite();

    if (favorable.favorableType === "project-scenes") {
      this.timelineEvents.publish(TimelineEvents.SCENE_FAVORITE_CHANGED, { favorited: false, projectScene: favorable });
    }
  }
}
