import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Model from "@ember-data/model";
import { TrackedArray } from "tracked-built-ins";
import type { ProjectScenesRouteModel } from "client/authenticated/project/scenes/route";
import type { EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import type Project from "client/models/project";
import type { InfiniteModel } from "client/routes/infinite";
import InfiniteRoute from "client/routes/infinite";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export interface LibraryContentModel<M extends Model = Model> extends InfiniteModel<M> {
  scene?: Scene;
  afterScene?: Scene;
  project: Project;
  eventRegister: EventRegister;
  timeline: Timeline;
  totalCount?: number;
}

export type LibraryContentParams = Record<string, string>;

export default abstract class LibraryContentRoute<
  M extends LibraryContentModel = LibraryContentModel
> extends InfiniteRoute<M> {
  @service
  declare tracking: TrackingService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  provider?: string;

  type?: string;

  errorNotificationMessage = "There was a problem retrieving results";

  get parentRouteName(): string {
    const parts = this.routeName.split(".");
    parts.pop();
    return parts.join(".");
  }

  beforeModel(transition: Transition): void {
    if (this.routeController) {
      this.routeController.set("meta", undefined);
    }

    return super.beforeModel(transition);
  }

  async model(params: LibraryContentParams): Promise<M> {
    const results = await this.loadResults(params);
    const { project, scene, afterScene, eventRegister, timeline } = this.modelFor(
      "authenticated.project.scenes"
    ) as ProjectScenesRouteModel;

    return {
      results: new TrackedArray(results),
      // @ts-expect-error
      totalCount: results.meta["total-count"],
      project,
      scene,
      afterScene,
      eventRegister,
      timeline
    } as unknown as M;
  }

  getParamsForResults(params?: LibraryContentParams): object {
    const { page, per_page } = this.routeController; // eslint-disable-line camelcase

    return {
      ...this.paramsFor(this.routeName),
      ...params,
      page,
      per_page // eslint-disable-line camelcase
    };
  }

  async loadResults(params?: LibraryContentParams): Promise<Array<Model>> {
    let results;

    try {
      results = await this.getResults(this.getParamsForResults(params));

      if (this.transformResults) {
        this.transformResults(results);
      }
    } catch (err) {
      results = this.emptyResults();
      // @ts-expect-error
      this.honeybadger.notify(err);
      this.notifications.error(this.errorNotificationMessage);
    }

    // @ts-expect-error
    if (results && results.meta) {
      // @ts-expect-error
      this.routeController.set("meta", results.meta);
    }

    return results;
  }

  abstract getResults(params: object): Promise<Array<Model>>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transformResults(_results: Array<Model>): void {
    return undefined;
  }

  emptyResults(): Array<Model> {
    return [];
  }
}
