import type Evented from "@ember/object/evented";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type { PositionalArgs, NamedArgs } from "ember-modifier";
import Modifier from "ember-modifier";

export default class ScrollTopModifier extends Modifier {
  element!: Element;

  @service
  declare router: RouterService & Evented;

  modify(element: Element, _positionalArgs: PositionalArgs<any>, _namedArgs: NamedArgs<any>): void {
    this.element = element;
    this.scrollToTop();
  }

  scrollToTop(): void {
    this.element.scrollTop = 0;
  }

  didReceiveArguments(): void {
    this.router.on("routeDidChange", this.scrollToTop);
  }

  willRemove(): void {
    this.router.off("routeDidChange", this.scrollToTop);
  }
}
