import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

interface AppNavAccountArgs {
  menuTarget: HTMLElement;
}

export default class AppNavAccountComponent extends Component<AppNavAccountArgs> {
  @service
  declare auth: AuthService;

  @tracked
  open = false;

  styleNamespace = getStyleNamespace("app-nav/account/menu");

  get user(): User | undefined {
    return this.auth.currentUser;
  }

  @action
  toggleMenu(): void {
    this.open = !this.open;
  }

  @action
  closeMenu(): void {
    this.open = false;
  }
}
