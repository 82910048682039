import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Element } from "client/lib/editor-domain-model";
import { Media, Text } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getIcon, getTypeLabel } from "client/lib/timeline/element";
import type AdvancedEditorService from "client/services/advanced-editor";

export default class FloatingPanelsStyleComponent extends Component {
  styleNamespace = getStyleNamespace("floating-panels/panels/style");

  @service
  private declare advancedEditor: AdvancedEditorService;

  get selectedElement(): Element | undefined {
    return this.advancedEditor.element;
  }

  get icon(): string {
    return this.selectedElement ? getIcon(this.selectedElement) : "icon/action/edit";
  }

  get label(): string {
    return this.selectedElement ? getTypeLabel(this.selectedElement) : "Style";
  }

  get elementIsMedia(): boolean {
    return this.selectedElement instanceof Media;
  }

  get elementIsText(): boolean {
    return this.selectedElement instanceof Text;
  }
}
