import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type { ProjectScenesRouteModel } from "client/authenticated/project/scenes/route";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import UserAsset from "client/models/user-asset";
import type ActiveStorageService from "client/services/active-storage";
import { FILE_UPLOAD_ERROR_NOTIFICATION } from "client/services/active-storage";
import type AssetLibraryService from "client/services/asset-library";
import type HoneybadgerService from "client/services/honeybadger";
import type LoaderService from "client/services/loader";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export default class ProjectScenesRouteAssetsUploadService extends Service {
  @service
  declare activeStorage: ActiveStorageService;

  @service
  declare assetLibrary: AssetLibraryService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare loader: LoaderService;

  @service
  declare router: RouterService;

  @service
  declare tracking: TrackingService;

  @action
  async onDropZone(model: ProjectScenesRouteModel, assetOwnerType = "user", ev: DragEvent): Promise<void> {
    try {
      if (ev.dataTransfer) {
        await this.uploadFiles(model, ev.dataTransfer?.files, assetOwnerType);
      }
    } catch (err) {
      this.notifications.error("Shucks! Something went wrong attempting to upload");
      // @ts-expect-error
      this.honeybadger.notify(err, "ScenesRoute");
    }
  }

  @action
  async onUpload(model: ProjectScenesRouteModel, assetOwnerType = "user"): Promise<void> {
    void this.router.transitionTo("authenticated.project.scenes.assets.library");

    const files = await this.activeStorage.showFilePicker({ accept: this.assetLibrary.acceptMimeTypes });
    await this.uploadFiles(model, files, assetOwnerType);
  }

  async uploadFiles(model: ProjectScenesRouteModel, files: FileList | File[], assetOwnerType = "user"): Promise<void> {
    try {
      const totalFiles = files.length;

      if (totalFiles > 10) {
        this.notifications.warning("You can only upload 10 files at a time. Please try again");
        return;
      }

      if (totalFiles === 1) {
        const asset = await this.assetLibrary.uploadFile(files, assetOwnerType);
        // This will be replaced with this.router.refresh(ScenesRoute.routeName) when upgrading to 4.x
        await this.refresh();

        if (asset && asset instanceof UserAsset) {
          this.loader.show("Adding scene", true);
          await this.addAssetToBackground(model, asset);
        }
      } else {
        await this.assetLibrary.uploadFiles(files, assetOwnerType);
        // This will be replaced with this.router.refresh(ScenesRoute.routeName) when upgrading to 4.x
        await this.refresh();
      }
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err, "ProjectScenesRouteAssetsUpload");
      this.notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
      throw err;
    } finally {
      this.loader.hide();
    }
  }

  async addAssetToBackground(model: ProjectScenesRouteModel, asset: UserAsset): Promise<void> {
    const { scene, afterScene, timeline, eventRegister } = model;

    return new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene).applyAsset(asset);
  }

  async refresh(): Promise<void> {
    await (getOwner(this).lookup("route:authenticated.project.scenes") as Route)?.refresh();
  }
}
