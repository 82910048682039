import { action } from "@ember/object";
import Component from "@glimmer/component";
import Folder from "client/models/folder";
import SelectableAsset from "client/models/selectable-asset";

interface Args {
  item: SelectableAsset | Folder;
  selectAsset(asset: SelectableAsset): unknown;
  selectFolder?(folder: Folder): unknown;
}

export default class PanelsUploadsContentItem extends Component<Args> {
  @action
  selectAsset(): void {
    if (this.args.item instanceof SelectableAsset) {
      this.args.selectAsset(this.args.item);
    }
  }

  get isFolder(): boolean {
    return this.args.item instanceof Folder;
  }
}
