import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import { SkuCode } from "client/models/plan";
import type Subscription from "client/models/subscription";
import type ValidatedCoupon from "client/models/validated-coupon";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type StripeService from "client/services/stripe";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export interface CheckoutPlanComponentArgs {
  plan: Plan;
  couponCode?: string;
  showCouponField?: boolean;

  onPurchase(subscription: Subscription): unknown;
}

export default class CheckoutPlanComponent extends Component<CheckoutPlanComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/checkout-plan");

  @service
  declare ajax: AjaxService;

  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare stripe: StripeService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare tracking: TrackingService;

  @tracked
  loading = false;

  @tracked
  error?: string;

  @tracked
  name?: string;

  @tracked
  nameError?: string;

  @tracked
  coupon?: ValidatedCoupon;

  @tracked
  token?: string;

  card?: stripe.elements.Element;

  @action
  didInsert(): void {
    this.addCaptchaCallback();
    this.addCaptchaScript();
  }

  @action
  setCoupon(coupon: ValidatedCoupon | undefined): void {
    this.coupon = coupon;
    this.upgrade.couponCode = coupon?.code;
  }

  @action
  async purchasePlan(): Promise<void> {
    this.executeCaptcha();
  }

  @action
  onFocus(): void {
    this.nameError = undefined;
  }

  @action
  setCard(card: stripe.elements.Element): void {
    this.card = card;
  }

  @action
  setError(error?: string): void {
    this.error = error;
  }

  get captchaKey(): string {
    return config.reCaptchaSecretKey;
  }

  get userNotAuthorised(): boolean {
    return !this.auth.currentUser?.canManageSubscription;
  }

  get totalPrice(): string {
    return this.coupon?.price ?? this.args.plan.formattedMonetaryUnits;
  }

  get title(): string {
    switch (this.args.plan.skuCode) {
      case SkuCode.PRO:
      case SkuCode.PLUS:
        return "You've chosen our most cost-effective plan!";

      case SkuCode.ULTIMATE:
        return "You've chosen our most popular plan";

      case SkuCode.TEAM:
      case SkuCode.PREMIUM:
        return "You've chosen our ultimate plan!";

      default:
        return "Get ready to make the perfect video!";
    }
  }

  private async chargeCard(): Promise<void> {
    if (!this.card) {
      return;
    }

    if (!this.name) {
      this.nameError = "Name is required";
    }

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_START_PLAN_PURCHASE, {
      ctaContext: "button - purchase modal - charge my card",
      planId: this.args.plan.id
    });

    this.loading = true;
    const stripe = this.stripe.getInstance();
    const { token, error } = await stripe.createToken(this.card, { name: this.name });

    if (error || !token) {
      this.handleChargeError(error);
    } else {
      await this.handleChargeSuccess(token);
    }
  }

  private async onCaptchaCallback(token: string): Promise<void> {
    try {
      this.loading = true;
      const isValid = await this.validateCaptcha(token);

      this.resetCaptcha();

      if (isValid) {
        await this.chargeCard();
      } else {
        this.notifications.error("You look like a bot. Please contact support to verify you're a human being.");
      }
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error);
    } finally {
      this.loading = false;
    }
  }

  private async validateCaptcha(token: string): Promise<boolean> {
    try {
      return this.ajax.api("/captcha", {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ token })
      });
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error);
      return false;
    }
  }

  private addCaptchaScript(): void {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://www.google.com/recaptcha/api.js";
    scriptTag.async = true;
    scriptTag.defer = true;

    document.body.appendChild(scriptTag);
  }

  private addCaptchaCallback(): void {
    (window as any).onCaptchaCallback = this.onCaptchaCallback.bind(this);
  }

  private resetCaptcha(): void {
    const grecaptcha = (window as any).grecaptcha;
    grecaptcha.reset();
  }

  private executeCaptcha(): void {
    const grecaptcha = (window as any).grecaptcha;
    grecaptcha.execute();
  }

  private handleChargeError(error: stripe.Error | undefined): void {
    this.error = error?.message ?? "There was an error with your card.";

    if (error && error.type !== "card_error") {
      // @ts-expect-error
      this.honeybadger.notify(error, {
        message: "Error creating stripe token",
        name: "CheckoutPlanComponent"
      });
    }
  }

  private async handleChargeSuccess(token: stripe.Token): Promise<void> {
    try {
      const subscription = await this.upgrade.purchasePlan({
        token,
        plan: this.args.plan,
        coupon: this.coupon
      });

      this.args.onPurchase(subscription);
    } catch (err) {
      // @ts-expect-error
      this.error = err.errors?.[0]?.detail ?? "There was an error charging your card";
    }
  }
}
