export default function isMobileDevice(): boolean {
  return hasMobileMediaQuery() && hasMobileUserAgent();
}

const hasMobileUserAgent = (): boolean => {
  const mobileUserAgents = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileUserAgents.test(navigator.userAgent);
};

const hasMobileMediaQuery = (): boolean => {
  return window.matchMedia("only screen and (max-width: 767px)").matches;
};
