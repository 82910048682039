import { getOwner } from "@ember/application";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type { ProjectRouteModel } from "client/authenticated/project/route";
import type { Scene } from "client/lib/editor-domain-model";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type AdvancedEditorService from "client/services/advanced-editor";
import type ProjectContentBarService from "client/services/project-content-bar";
import { ContentBarPanelType } from "client/services/project-content-bar";

export default class ProjectIndexRoute extends Route {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare router: RouterService;

  get selectedScene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  async beforeModel(transition: Transition): Promise<void> {
    const { timeline, eventRegister } = this.modelFor("authenticated.project") as ProjectRouteModel;

    void transition.then(async () => {
      if (this.selectedScene) {
        this.replaceWithEditor(this.selectedScene.id);
      } else {
        const firstScene = timeline.scenes[0] as Scene;

        if (firstScene) {
          this.replaceWithEditor(firstScene.id);
        } else {
          const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister);
          await modifier.applyBlankScene();
          const contentBarPanel = transition.to.queryParams["contentBarPanel"];
          void this.projectContentBar.show(contentBarPanel ?? ContentBarPanelType.SCENES);
          await this.resetQueryParams();
        }
      }
    });
  }

  private replaceWithEditor(sceneId: string): void {
    void this.router.replaceWith("authenticated.project.scene", sceneId);
  }

  private async resetQueryParams(): Promise<void> {
    void this.router.transitionTo({ queryParams: { contentBarPanel: undefined } });
  }
}
