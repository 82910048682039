import Model, { attr, belongsTo } from "@ember-data/model";
import type Subscription from "client/models/subscription";

export default class Trial extends Model {
  @attr("string")
  declare planId: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("subscription", { async: false, inverse: null })
  declare subscription?: Subscription;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    trial: Trial;
  }
}
