import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import nextVideoAction from "client/lib/video-state-based-action";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import type ProjectPreview from "client/models/project-preview";
import type FavoritesService from "client/services/favorites";
import type { LatestProjectPreviewsArray } from "client/services/latest-project-previews";
import type LatestProjectPreviewsService from "client/services/latest-project-previews";
import type StorageService from "client/services/storage";
import type SubscriptionsService from "client/services/subscriptions";

enum PrimaryTileComponent {
  FAVORITES = "discovery/dashboard/primary-actions/favorites",
  PUBLISHED_SCENES = "discovery/dashboard/primary-actions/published-scenes",
  SUGGESTED_TEMPLATES = "discovery/dashboard/primary-actions/suggested-templates",
  LATEST_TEMPLATES = "discovery/dashboard/primary-actions/latest-templates",
  STATE_BASED_ACTION = "discovery/dashboard/primary-actions/state-based-action"
}

const REQUIRED_FAVORITES_TO_SHOW = 5;

export default class DashboardPrimaryActions extends Component {
  @service
  declare favorites: FavoritesService;

  @service
  declare store: Store;

  @service
  declare storage: StorageService;

  @service
  declare latestProjectPreviews: LatestProjectPreviewsService;

  @service
  declare subscriptions: SubscriptionsService;

  @tracked
  showFavorites = false;

  @tracked
  projectCount = 0;

  @tracked
  priorityOneSeq = 0;

  @tracked
  latestProjects?: LatestProjectPreviewsArray;

  @tracked
  loading = false;

  @tracked
  pauseStateBasedAction = false;

  styleNamespace = getStyleNamespace("discovery/dashboard/primary-actions");

  @action
  async didInsert(): Promise<void> {
    this.loading = true;
    try {
      [this.projectCount, this.latestProjects, this.showFavorites] = await Promise.all([
        this.latestProjectPreviews.projectsCount(),
        this.latestProjectPreviews.projects(),
        this.enoughFavoritesToShow()
      ]);

      this.setPriorityOneSeq();
    } finally {
      this.loading = false;
    }
  }

  @action
  onClose(): void {
    this.pauseStateBasedAction = true;
  }

  get componentToShow(): PrimaryTileComponent {
    if (this.showStateBasedAction) {
      return PrimaryTileComponent.STATE_BASED_ACTION;
    }

    return this.priorityOneNext;
  }

  private get showStateBasedAction(): boolean {
    if (this.subscriptions.isTrialing && !!this.latestProject && !this.pauseStateBasedAction) {
      const nextAction = nextVideoAction(this.latestProject);
      return !!nextAction && this.latestProjectPreviews.showStateBasedActionTile(this.latestProject.id, nextAction);
    }

    return false;
  }

  private get priorityOneActions(): PrimaryTileComponent[] {
    const actions = [
      PrimaryTileComponent.SUGGESTED_TEMPLATES,
      PrimaryTileComponent.LATEST_TEMPLATES,
      PrimaryTileComponent.PUBLISHED_SCENES
    ];

    if (this.showFavorites) {
      actions.push(PrimaryTileComponent.FAVORITES);
    }

    return actions;
  }

  private setPriorityOneSeq(): void {
    const PRIORITY_L1_KEY = "priority_1_seq";

    const current = this.storage.getItem(PRIORITY_L1_KEY);
    const currentSeq = current ? parseInt(current) : Math.floor(Math.random() * 10);
    this.storage.setItem(PRIORITY_L1_KEY, (currentSeq + 1).toString());

    this.priorityOneSeq = currentSeq;
  }

  private get priorityOneNext(): PrimaryTileComponent {
    return this.priorityOneActions[this.priorityOneSeq % this.priorityOneActions.length]!;
  }

  private get latestProject(): ProjectPreview | undefined {
    return this.latestProjects?.[0];
  }

  private async enoughFavoritesToShow(): Promise<boolean> {
    const eligibleFavorites = await this.store.query("favorite", {
      /* eslint-disable camelcase */
      per_page: REQUIRED_FAVORITES_TO_SHOW,
      aspect_ratio_id: WIDE_ASPECT_RATIO_ID
      /* eslint-enable camelcase */
    });

    return eligibleFavorites.length === REQUIRED_FAVORITES_TO_SHOW;
  }
}
