import type Controller from "@ember/controller";
import { action } from "@ember/object";
import type Evented from "@ember/object/evented";
import type RouteInfo from "@ember/routing/-private/route-info";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type FolderController from "./controller";
import { FolderMessages } from "client/lib/data/folder-messages";
import type Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type { SortedPaginatedRouteParams } from "client/routes/paginated";
import PaginatedRoute from "client/routes/paginated";
import type FoldersService from "client/services/folders";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

interface FoldersFolderRouteModel {
  folder?: Folder;
  contents?: Array<FolderContent>;
  order: string;
  search?: string;
}

interface FoldersFolderRouteParams extends SortedPaginatedRouteParams {
  folderId: string;
  search: string;
}

export default class FoldersFolderRoute extends PaginatedRoute {
  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService & Evented;

  @tracked
  declare query: string;

  // eslint-disable-next-line camelcase
  async model({ folderId, page, per_page, order, search }: FoldersFolderRouteParams): Promise<FoldersFolderRouteModel> {
    order ??= this.folders.filterOrder;
    search ??= this.query;

    try {
      const folder = await this.store.findRecord("folder", folderId);

      if (!folder) {
        throw Error("Could not find folder");
      }

      // @ts-expect-error
      const contents = (await this.store.query("folderContent", {
        folderId,
        page,
        per_page, // eslint-disable-line camelcase
        order,
        search
      })) as FolderContent[];

      return {
        folder,
        contents,
        order,
        search
      };
    } catch (error) {
      this.notifications.error(FolderMessages.FOLDER_RESULTS_FAILURE);

      if (error instanceof Error) {
        this.honeybadger.notify(error, "FolderRoute");
      }

      // void this.router.transitionTo(FoldersRoute.routeName);
    }

    return { order };
  }

  afterModel(model: FoldersFolderRouteModel): void {
    this.folders.setActiveFolder(model.folder);
  }

  async setupController(controller: Controller, model: FoldersFolderRouteModel, transition: Transition): Promise<void> {
    super.setupController(controller, model, transition);

    const routeController = controller as FolderController;

    const { routeName } = this;
    const { order, search } = model;
    order ?? this.folders.filterOrder;

    Object.assign(routeController, {
      routeName,
      order,
      search
    });
  }

  @action
  onRouteWillChange(transition: Transition): void {
    if (!transition.to.find(({ name }: RouteInfo) => name === this.routeName)) {
      const order = (this.routeController as FolderController).order;
      if (order) {
        this.folders.filterOrder = order;
      }
    }
  }

  activate(): void {
    this.router.on("routeWillChange", this.onRouteWillChange);
  }

  deactivate(): void {
    this.router.off("routeWillChange", this.onRouteWillChange);
  }

  queryParams = {
    order: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    // eslint-disable-next-line camelcase
    per_page: {
      refreshModel: true
    },
    search: {
      refreshModel: true
    }
  };
}
