import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type { ProjectRouteModel } from "../route";
import TrackingEvents from "client/events";
import AnalyticsService from "client/services/analytics";

export default class ProjectSettingsRoute extends Route {
  trackPageName = TrackingEvents.EVENT_VIEW_RESIZE_DIALOG;

  async model(): Promise<Pick<ProjectRouteModel, "project">> {
    const { project } = this.modelFor("authenticated.project") as ProjectRouteModel;

    return { project };
  }

  @action
  didTransition(): void {
    AnalyticsService.page(this.trackPageName);
  }
}
