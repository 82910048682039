import { set } from "@ember/object";
import { service } from "@ember/service";
import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import type Store from "@ember-data/store";
import { cached, tracked } from "@glimmer/tracking";
import { last } from "lodash";
import type { HexColor, RenderZymbol, RenderScope, RenderProject } from "renderer-engine";
import { AudioClipCategory, AssetSizes, AssetTypes } from "renderer-engine";
import type AudioClip from "./audio-clip";
import { folderContainable, FolderContainableTypes } from "./folder-containable";
import hashObject from "client/lib/hash-object";
import { loadZymbolAsset } from "client/lib/project-serializer";
import type AspectRatio from "client/models/aspect-ratio";
import AudioTrack from "client/models/audio-track";
import type BrandStyle from "client/models/brand-style";
import type { ManyArray, RecordArray } from "client/models/ember-data-types";
import type Layer from "client/models/layer";
import type ProjectDigest from "client/models/project-digest";
import type ProjectEditAccess from "client/models/project-edit-access";
import type ProjectRender from "client/models/project-render";
import type ProjectScene from "client/models/project-scene";
import type ProjectShare from "client/models/project-share";
import type ProjectTemplate from "client/models/project-template";
import type StockLicense from "client/models/stock-license";
import type Team from "client/models/team";
import type User from "client/models/user";
import type VideoPage from "client/models/video-page";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type BrandStyleService from "client/services/brand-style";
import type ProjectScenesService from "client/services/project-scenes";
import type ProjectThumbnailUpdateService from "client/services/project-thumbnail-update";
import type ProjectsService from "client/services/projects";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

@folderContainable
export default class Project extends Model implements RenderProject {
  @service
  declare ajax: AjaxService;

  @service
  declare store: Store;

  @service
  declare projects: ProjectsService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service("projectScenes" as never)
  declare projectScenesService: ProjectScenesService;

  @service
  declare auth: AuthService;

  @service
  declare projectThumbnailUpdate: ProjectThumbnailUpdateService;

  @service
  private declare brandStyle: BrandStyleService;

  // Relationships

  @belongsTo("video-page", { async: true, inverse: "sourceProject" })
  declare videoPage?: VideoPage;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("team", { async: true, inverse: null })
  declare team?: Team;

  @belongsTo("project-share", { inverse: "project", async: false })
  declare projectShare?: ProjectShare;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("user", { async: true, inverse: null })
  declare user: User;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-digest", { async: true, inverse: null })
  declare projectDigest: ProjectDigest;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-template", { async: true, inverse: null })
  declare projectTemplate: ProjectTemplate;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("aspect-ratio", { async: false, inverse: null })
  declare aspectRatio: AspectRatio;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("zymbol", { async: false, inverse: null })
  declare userWatermark?: Zymbol;

  @belongsTo("project-render", { async: true, inverse: "project" })
  declare latestRender?: ProjectRender;

  @hasMany("audio-clip", { async: true, inverse: "project" })
  declare audioClips: ManyArray<AudioClip>;

  @hasMany("project-scene", { async: true, inverse: "project" })
  declare projectScenes: ManyArray<ProjectScene>;

  // eslint-disable-next-line no-null/no-null
  @hasMany("stock-license", { async: true, inverse: null })
  declare stockLicenses: ManyArray<StockLicense>;

  // Attributes

  @attr("boolean")
  declare isOwner: boolean;

  @attr("boolean", { defaultValue: true })
  declare showComments: boolean;

  @attr({ defaultValue: () => [] })
  declare projectSceneOrder: number[];

  @attr("string")
  declare title: string;

  @attr("string")
  declare style: string;

  @attr("string")
  declare brandStyleId: string;

  @attr("string", { defaultValue: "#FFFFFF" })
  declare canvasColor: HexColor;

  @attr("string")
  declare visualStyles?: string[];

  @attr("string")
  declare updatedAt: string;

  @attr("boolean")
  declare purchased: boolean;

  @attr("boolean")
  declare userWatermarkVisible: boolean;

  @attr("boolean")
  declare template: boolean;

  @attr("boolean")
  declare templateBrandable: boolean;

  @attr("string", { allowNull: true })
  declare posterUrl?: string;

  @attr("string", { allowNull: true })
  declare thumbnailSource?: string;

  @attr("string", { allowNull: true })
  declare videoUrl: string;

  @attr("boolean")
  declare published: boolean;

  get teamShared(): boolean {
    // @ts-expect-error
    return !!this.belongsTo("projectShare")?.id();
  }

  // Properties

  @tracked
  previousZymbolContentHash?: string;

  async audioClipsArray(validateAudible = true): Promise<AudioClip[]> {
    // @ts-expect-error
    const clips = (await this.audioClips) as AudioClip[];

    if (validateAudible) {
      await Promise.all(
        clips.map(async (ac) => {
          // eslint-disable-next-line no-null/no-null
          if ((await ac.audible) === null) {
            throw Error(`Audio clip ${ac.name} ` + "could not be loaded. Please try adding the audio again.");
          }
        })
      );
    }

    return clips;
  }

  get projectScenesZymbolsToRender(): RenderZymbol[][] {
    // @ts-expect-error
    const scenes = this.hasMany("projectScenes").value() || [];
    return scenes.map((scene) => scene.zymbolsToRender);
  }

  get contentHash(): string {
    // @ts-expect-error
    const digest = this.belongsTo("projectDigest").value() as ProjectDigest;
    return digest.contentHash;
  }

  get previews(): boolean {
    return !!this.posterUrl && !!this.videoUrl;
  }

  get lastProjectSceneId(): number {
    return last(this.projectSceneOrder) || 0;
  }

  get nonDeletedProjectScenes(): Array<ProjectScene> {
    // @ts-expect-error
    const scenes = this.hasMany("projectScenes").value() as Array<ProjectScene>;
    return scenes.filter(({ isDeleted }) => !isDeleted);
  }

  get nonEmptyProjectScenes(): Array<ProjectScene> {
    return this.nonDeletedProjectScenes.filter((projectScene) => projectScene?.hasZymbolGroups);
  }

  get hasNonEmptyProjectScenes(): boolean {
    return !!this.nonEmptyProjectScenes.length;
  }

  get sortedProjectScenes(): Array<ProjectScene> {
    return this.nonEmptyProjectScenes.sort((a: ProjectScene, b: ProjectScene) => a.order - b.order);
  }

  get firstProjectScene(): ProjectScene | undefined {
    return this.sortedProjectScenes[0];
  }

  get latestRenderWatermarked(): boolean {
    // @ts-expect-error
    return (this.belongsTo("latestRender").value() as ProjectRender)?.watermarked;
  }

  get latestRenderContentHash(): string {
    // @ts-expect-error
    return (this.belongsTo("latestRender").value() as ProjectRender)?.contentHash;
  }

  get hasOtherEditors(): boolean {
    return !!this.otherEditors.length;
  }

  get empty(): boolean {
    return this.duration === 0;
  }

  get paidLicenses(): StockLicense[] {
    // @ts-expect-error
    return ((this.hasMany("stockLicenses").value() as StockLicense[]) || []).filter(
      ({ purchased, licensed }) => licensed || purchased
    );
  }

  get unpaidLicenses(): StockLicense[] {
    // @ts-expect-error
    return ((this.hasMany("stockLicenses").value() as StockLicense[]) || []).filter(
      ({ purchased, licensed }) => !licensed && !purchased
    );
  }

  get posterContentHash(): string | undefined {
    const posterFrameScene = this.sortedProjectScenes.find((scene) => scene.containsPosterFrame);

    return posterFrameScene?.posterContentHash();
  }

  get unpaidLicensesCost(): number {
    return this.unpaidLicenses.length;
  }

  get unlicensedStockLicenses(): StockLicense[] {
    // @ts-expect-error
    return ((this.hasMany("stockLicenses").value() as StockLicense[]) || []).filter(({ licensed }) => !licensed);
  }

  get stockLicenseCost(): number {
    return this.unlicensedStockLicenses.length;
  }

  get renderOutdated(): boolean {
    return !this.renderIsCurrent;
  }

  @cached
  get zymbolsToRender(): RenderZymbol[] {
    const renderZympbols = ([] as Array<RenderZymbol>).concat(...this.projectScenesZymbolsToRender);
    if (this.userWatermarkZymbolsToRender) {
      renderZympbols.push(this.userWatermarkZymbolsToRender);
    }
    return renderZympbols.filter((z) => z.cfg && z.category);
  }

  get zymbolContentHash(): string {
    return hashObject(
      this.zymbolsToRender.map((z) => {
        const { x, y, width, height, layerOrder, cfg } = z;
        return { x, y, width, height, layerOrder, cfg };
      })
    );
  }

  get userWatermarkZymbolsToRender(): RenderZymbol | undefined {
    // @ts-expect-error
    const userWatermark = this.belongsTo("userWatermark").value() as Zymbol;
    if (!this.userWatermarkVisible || !userWatermark) {
      return;
    }
    const { cfg, x, y, width, height, assetOffset, category, layerOrder, customTimingOffset, customTimingDuration } =
      userWatermark;

    return {
      identifier: "Watermark(custom)",
      cfg,
      x,
      y,
      width,
      height,
      assetOffset,
      category,
      layerOrder,
      customTimingOffset,
      customTimingDuration,
      startTime: 0,
      duration: this.duration,
      assets: []
    };
  }

  get duration(): number {
    return Math.max(0, ...this.sortedProjectScenes.map((ps) => ps.endTime));
  }

  get renderIsCurrent(): boolean {
    return this.latestRenderContentHash === this.contentHash;
  }

  public async publish(): Promise<VideoPage> {
    const videoPage = await this.store
      .createRecord("videoPage", {
        title: this.title,
        sourceProjectId: this.id,
        sourceProjectType: "Project"
      })
      .save();

    await this.reload();

    return videoPage;
  }

  public async unpublish(videoPage: VideoPage): Promise<void> {
    await videoPage.destroyRecord();
    await this.reload();
  }

  get textLayers(): Layer[] {
    const projectScenes = this.sortedProjectScenes;
    const layers = projectScenes.map(({ textLayer }) => textLayer);
    return layers.filter((layer) => layer);
  }

  get captions(): ZymbolGroup[] {
    const textLayers = this.textLayers;
    const captions = textLayers.map((textLayer) => {
      // @ts-expect-error
      return textLayer.hasMany("zymbolGroups").value() as ZymbolGroup[];
    });

    return captions.flat();
  }

  get backgroundLayers(): Layer[] {
    // @ts-expect-error
    const projectScenes = this.hasMany("projectScenes").value();
    return projectScenes?.map((projectScene) => projectScene.belongsTo("backgroundLayer").value()) || [];
  }

  get backgrounds(): ZymbolGroup[] {
    const backgroundLayers = this.backgroundLayers;
    const backgrounds = backgroundLayers.map((backgroundLayer) => backgroundLayer.hasMany("zymbolGroups").value());
    // @ts-expect-error
    return backgrounds?.flat().filter((zg) => !!zg);
  }

  get layers(): Layer[] {
    // @ts-expect-error
    const projectScenes = this.hasMany("projectScenes").value();
    if (!projectScenes) {
      return [];
    }

    return projectScenes.map((projectScene: ProjectScene) => projectScene.layers).flat();
  }

  get zymbolGroups(): ZymbolGroup[] {
    // @ts-expect-error
    const projectScenes = this.hasMany("projectScenes").value() || [];
    return projectScenes
      .map((ps) => ps.zymbolGroups)
      .flat()
      .filter((zg) => !!zg);
  }

  get zymbols(): Zymbol[] {
    const zymbolGroups = this.zymbolGroups;
    if (!zymbolGroups) {
      return [];
    }

    // @ts-expect-error
    const zymbolGroupsZymbols = zymbolGroups.map((zg: ZymbolGroup) => zg.hasMany("zymbols").value()) as Zymbol[];
    return zymbolGroupsZymbols.flat().filter((zymbol) => !!zymbol);
  }

  get otherEditors(): ProjectEditAccess[] {
    return this.allEditors.filter(
      (editAccess) => editAccess.projectId === this.id && editAccess.userId !== this.auth.currentUser?.id
    );
  }

  async loadZymbolsToRenderDependencies(): Promise<void> {
    // this.hasMany("projectScenes").value();
    await this.projectScenes;
    this.layers;
    this.zymbolGroups;
    this.zymbols;
  }

  triggerProjectChangeEvent(): void {
    if (!this.isDestroyed && !this.isDestroying) {
      this.timelineEvents.publish(TimelineEvents.PROJECT_DID_CHANGE);
    }
  }

  get allEditors(): RecordArray<ProjectEditAccess> {
    return this.store.peekAll("projectEditAccess");
  }

  get canUploadThumbnail(): boolean {
    return !this.thumbnailSource || this.thumbnailSource === "browser";
  }

  async checkForPendingRender(): Promise<void> {
    // @ts-expect-error
    if (this.isNew) {
      return;
    }

    // Ignore for non-owners as we don't want to check templates for renders
    if (!(await this.isOwner)) {
      return;
    }

    const render = await this.latestRender;

    if (render && !render.completed) {
      void this.projects.monitorRenderProgress(render);
    }
  }

  async getAllZymbolsToRenderWithAssets(
    scope: RenderScope,
    elapsedTime: number,
    assetSize: AssetSizes = AssetSizes.RENDER,
    type: AssetTypes = AssetTypes.VIDEO
  ): Promise<RenderZymbol[]> {
    const zymbolsToRender = this.zymbolsToRender;
    await Project.loadZymbolAssets(scope, zymbolsToRender, elapsedTime, assetSize, type);
    return zymbolsToRender;
  }

  static async loadZymbolAssets(
    scope: RenderScope,
    zymbolsToRender: RenderZymbol[],
    elapsedTime: number,
    assetSize: AssetSizes = AssetSizes.RENDER,
    type: AssetTypes = AssetTypes.VIDEO,
    perSceneAssets = false
  ): Promise<void> {
    const assets = await Promise.all(
      zymbolsToRender.map((z) => loadZymbolAsset(z, assetSize, type, elapsedTime, perSceneAssets))
    );
    assets.forEach((a, i) => scope.setAssets(zymbolsToRender[i]!, a));
  }

  swapScenes(firstScene: ProjectScene, secondScene: ProjectScene): void {
    const firstSceneOrder = firstScene.order;
    const newSceneOrder = [...this.projectSceneOrder];
    newSceneOrder[secondScene.order] = Number(firstScene.id);
    newSceneOrder[firstSceneOrder] = Number(secondScene.id);

    set(this, "projectSceneOrder", newSceneOrder);
  }

  static createWithDefaults(
    store: Store,
    { title, ...properties }: Pick<Project, "aspectRatio"> & Partial<Project>
  ): Project {
    return store.createRecord("project", {
      title: title ?? this.generateProjectName(),
      ...properties
    });
  }

  static generateProjectName(): string {
    const adjectives = [
      "amazing",
      "beautiful",
      "eye-popping",
      "fabulous",
      "fancy",
      "fantastic",
      "great",
      "head-turning",
      "jaw-dropping",
      "joy-sparking",
      "pretty",
      "spectacular",
      "super",
      "unmissable",
      "wonderful"
    ];

    const index = Math.floor(Math.random() * adjectives.length);
    const adjective = adjectives[index];
    return `My ${adjective} video`;
  }

  async reloadStockLicenses(): Promise<void> {
    await this.stockLicenses.reload();
  }

  shutterstockLicensed(stockId: string): boolean {
    return !!this.paidLicenses.find((l) => l.stockAssetId.toString() === stockId);
  }

  get renderAspectRatio(): AspectRatio {
    // @ts-expect-error
    const latestRender = this.belongsTo("latestRender").value() as ProjectRender;
    if (latestRender) {
      return latestRender.belongsTo("aspectRatio").value() as AspectRatio;
    }
    // @ts-expect-error
    return this.belongsTo("aspectRatio").value();
  }

  get musicTrack(): AudioTrack | undefined {
    const audible = this.musicClip?.audible;
    return audible instanceof AudioTrack ? audible : undefined;
  }

  get musicClip(): AudioClip | undefined {
    // @ts-expect-error
    const clips = this.hasMany("audioClips").value();
    if (clips) {
      return clips.find((ac: AudioClip) => ac.category === AudioClipCategory.MUSIC);
    }
    return undefined;
  }

  async getSceneAtTime(time: number): Promise<ProjectScene | undefined> {
    const projectScenes = await this.projectScenes;

    return projectScenes.find((scene: ProjectScene) => scene.isTimeInRange(time));
  }

  async removeAudioClipsForAudioTrack(audioTrack: AudioTrack): Promise<void> {
    await Promise.all(
      (
        await this.audioClips
      ).map(async (ac: AudioClip) => {
        if (ac.audibleId === audioTrack.id) {
          return await ac.destroyRecord();
        }
        return Promise.resolve();
      })
    );
  }

  get hasNoAudio(): boolean {
    // @ts-expect-error
    const audioClips = this.hasMany("audioClips").value() as AudioClip[];
    const noAudioClips = !audioClips?.some((ac: AudioClip) => ac.category !== AudioClipCategory.MUSIC);
    const noZymbolFootageAudio = !this.zymbols.some((z) => !!z.cfg?.video?.hasAudio);
    return noZymbolFootageAudio && noAudioClips;
  }

  readonly containableType = FolderContainableTypes.PROJECT;

  get containableId(): string {
    return this.id;
  }

  public async generateThumbnail(): Promise<string | undefined> {
    return this.projectThumbnailUpdate.generateProjectThumbnail(this);
  }

  public get posterIsEmpty(): boolean {
    return !this.posterUrl;
  }

  public async getBrandStyle(): Promise<BrandStyle | undefined> {
    return await this.brandStyle.getBrand(this.brandStyleId);
  }

  public async duplicate(): Promise<Project> {
    const data = await this.ajax.api(`/projects/${this.id}/copy`, {
      method: "POST"
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.store.pushPayload(data);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const copy = this.store.peekRecord("project", data.data.id);

    if (!copy) {
      throw Error("Could not copy project");
    }

    return copy;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    project: Project;
  }
}
