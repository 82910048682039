import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SelectMenuComponentArgs {
  id: string;
  open: boolean;
  align?: "start" | "end" | "stretch";
}

export default class DropdownMenuMenuComponent extends Component<SelectMenuComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/dropdown-menu/menu");

  get align(): string {
    return this.args.align ?? "end";
  }
}
