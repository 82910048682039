import { attr } from "@ember-data/model";
import type { VideoConfig, Asset } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import TrackingEvents from "client/events";
import { getVideoDuration } from "client/lib/video";
import { favorable, FAVORABLE_TYPES } from "client/models/favorable";
import SelectableMediaAsset from "client/models/selectable-media-asset";

@favorable
export default class PexelsVideo extends SelectableMediaAsset {
  @attr("string")
  declare description: string;

  @attr("number")
  declare duration: number;

  @attr("string")
  declare downloadUrl: string;

  @attr("string")
  declare previewImageUrl: string;

  @attr("string")
  declare previewVideoUrl: string;

  @attr("string")
  declare thumbImageUrl: string;

  @attr("string")
  declare thumbVideoUrl: string;

  @attr("string")
  declare lowResVideoUrl: string;

  @attr("json")
  declare attribution: object;

  readonly zymbolCategory = ZymbolCategory.VIDEO;

  async asZymbolConfig(): Promise<Partial<VideoConfig> & { asset: Asset }> {
    const { id, previewImageUrl, previewVideoUrl, lowResVideoUrl } = this;

    return {
      asset: {
        id,
        type: "pexels-video"
      },
      duration: await getVideoDuration(previewVideoUrl),
      previewImageUrl,
      previewVideoUrl,
      lowResVideoUrl,
      downsizedUrlBase: undefined,
      url: previewVideoUrl,
      name: `pexels-${id}`,
      startTime: 0
    };
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_STOCK_CONTENT;

  get trackUsageData(): object {
    return {
      provider: "pexels",
      providerAssetId: this.id,
      type: "video",
      name: this.description
    };
  }

  readonly favorableType = FAVORABLE_TYPES.PEXELS_VIDEOS;

  get favorableProviderAssetId(): string {
    return this.id;
  }

  metadataForFavorite = {};
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    pexelsVideo: PexelsVideo;
  }
}
