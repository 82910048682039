import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface GridGuidesIntersectionComponentArgs {
  x: number;
  y: number;
}

export default class GridGuidesIntersectionComponent extends Component<GridGuidesIntersectionComponentArgs> {
  styleNamespace = getStyleNamespace("app/grid-guides/intersection");

  get style(): SafeString {
    const top = this.args.y || 0;
    const left = this.args.x || 0;

    return htmlSafe(`
      top: ${top * 100}%;
      left: ${left * 100}%;
    `);
  }
}
