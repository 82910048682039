import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type PlanFeature from "client/models/plan-feature";

interface PlansTableValueArgs {
  plan: Plan;
  planFeature: PlanFeature;
}

export default class PlansTableValueComponent extends Component<PlansTableValueArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-table-value");

  get value(): boolean | string {
    const pfv = this.args.plan?.planFeatureValues.find(({ planFeature }) => planFeature === this.args.planFeature);
    return pfv ? pfv.value : false;
  }
}
