import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { TimelineEvents } from "./timeline-events";
import TrackingEvents, { Locations } from "client/events";
import type { Timeline } from "client/lib/editor-domain-model";
import { Media, Scene, Background, Logo, Watermark } from "client/lib/editor-domain-model";
import type SceneAssetModifier from "client/lib/scene-asset-modifier";
import type Favorable from "client/models/favorable";
import type Favorite from "client/models/favorite";
import type Project from "client/models/project";
import ProjectScenePreview from "client/models/project-scene-preview";
import type SelectableAsset from "client/models/selectable-asset";
import SelectableTextStyle from "client/models/selectable-text-style";
import type AdvancedEditorService from "client/services/advanced-editor";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TimelineEventsService from "client/services/timeline-events";
import type TrackingService from "client/services/tracking";

enum CBState {
  OPEN = "open",
  CLOSE = "close"
}

export enum CBTriggeredBy {
  CONTENT_BAR = "content_bar",
  EDITOR = "editor"
}

export interface CBSelectAssetTrackingProperties {
  collection?: string;
  stack?: string;
  search?: string;
  location?: Locations;
}

export enum CBReplacingContext {
  BACKGROUND = "background",
  ELEMENT = "element",
  WATERMARK = "watermark"
}

type Context = Media | Scene;

interface CBTrackingProperties extends CBSelectAssetTrackingProperties {
  sceneId?: string;
}

export enum ContentBarPanelType {
  SCENES = "scenes",
  UPLOADS = "uploads",
  RECORDINGS = "recordings",
  STOCK = "stock",
  GRAPHICS = "graphics",
  SAGS = "sags",
  FAVORITES = "favorites"
}

const DefaultPanels: {
  [key: string]: string;
} = {
  [ContentBarPanelType.UPLOADS]: "uploads.user",
  [ContentBarPanelType.RECORDINGS]: "recordings.recordings",
  [ContentBarPanelType.STOCK]: "stock.videos",
  [ContentBarPanelType.FAVORITES]: "favorites.user"
};

const DefaultPanel = DefaultPanels[ContentBarPanelType.STOCK]!;

export default class ProjectContentBarService extends Service {
  @service
  private declare tracking: TrackingService;

  @service
  private declare router: RouterService;

  @service
  private declare advancedEditor: AdvancedEditorService;

  @service
  private declare timelineEvents: TimelineEventsService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare honeybadger: HoneybadgerService;

  @tracked
  private _panel = DefaultPanel;

  @tracked
  expanded = false;

  @tracked
  addMediaToNewScene = false;

  @tracked
  declare afterScene: Scene | undefined;

  private _operationContext?: Context;

  get project(): Project | undefined {
    return this.advancedEditor.project;
  }

  get timeline(): Timeline | undefined {
    return this.advancedEditor.timeline;
  }

  isCurrentPanel(panel: string): boolean {
    return this.panel.startsWith(panel) ?? false;
  }

  get panel(): string {
    return this._panel;
  }

  get isReplacing(): boolean {
    return !!this.operationContext;
  }

  @action
  hide(source: CBTriggeredBy = CBTriggeredBy.CONTENT_BAR): void {
    this.afterScene = undefined;
    this.addMediaToNewScene = false;

    this.finishAddOrReplace();

    if (this.expanded) {
      this.expanded = false;
      void this.trackContentBarOpenClose(CBState.CLOSE, source);
    }
  }

  @action
  show(panel?: string, source: CBTriggeredBy = CBTriggeredBy.CONTENT_BAR): void {
    const expanded = this.expanded;

    this._open(panel);

    if (expanded !== this.expanded) {
      void this.trackContentBarOpenClose(CBState.OPEN, source);
    }
  }

  private _open(panel?: string): void {
    if (panel && !this.isCurrentPanel(panel)) {
      this._panel = DefaultPanels[panel] ?? panel;
    }
    this.expanded = true;
  }

  private get operationContext(): Context | undefined {
    return this._operationContext;
  }

  @action
  async startAddOrReplaceMedia(media: Media | undefined): Promise<void> {
    this._operationContext = media;
    this._open(this.bestPanelTypeForOperation);
  }

  @action
  async startReplaceSceneBackground(scene: Scene | undefined): Promise<void> {
    this._operationContext = scene;
    this._open(this.bestPanelTypeForOperation);
  }

  @action
  async favorite(favorable: Favorable, team = false): Promise<Favorite> {
    const favorite = await favorable.favorite(team);

    if (favorable.favorableType === "project-scenes") {
      this.timelineEvents.publish(TimelineEvents.SCENE_FAVORITE_CHANGED, {
        favorited: true,
        teamFavorited: team,
        projectScene: favorable
      });
    }

    return favorite;
  }

  @action
  async unfavorite(favorable: Favorable): Promise<void> {
    await favorable.unfavorite();

    if (favorable.favorableType === "project-scenes") {
      this.timelineEvents.publish(TimelineEvents.SCENE_FAVORITE_CHANGED, { favorited: false, projectScene: favorable });
    }
  }

  @action
  startDragContent(): void {
    this.timelineEvents.publish(TimelineEvents.CONTENT_DRAG_START);
  }

  @action
  stopDragContent(): void {
    this.timelineEvents.publish(TimelineEvents.CONTENT_DRAG_STOP);
  }

  @action
  async onAssetSelected(
    modifier: SceneAssetModifier,
    asset?: SelectableAsset,
    trackProperties?: CBSelectAssetTrackingProperties
  ): Promise<void> {
    try {
      if (!asset) {
        await this.onAddBlankScene(modifier);
      } else {
        const context = this.operationContext;

        if (context) {
          if (context instanceof Scene || context instanceof Media) {
            await this.onAssetSelectedToReplace(modifier, asset, trackProperties);
          } else {
            throw new Error(`unknown content-bar context: ${(context as any).constructor.name}`);
          }
        } else {
          if (asset instanceof ProjectScenePreview || this.addMediaToNewScene) {
            await this.onSceneSelectedToAdd(modifier, asset, trackProperties);
          } else {
            await this.onAssetSelectedToAdd(modifier, asset, trackProperties);
          }
        }
      }
    } catch (error: any) {
      const message = error.errors?.[0]?.detail ?? error.message;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (!/TransitionAborted/.exec(message)) {
        this.notifications.warning("We were unable to add the content. Please try again");
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.honeybadger.notify(error, {
        message: `Could not add selected asset: ${message}`,
        name: "onAssetSelected"
      });
    }
  }

  @action
  async onAssetSelectedFromScenesPage(modifier: SceneAssetModifier, asset?: SelectableAsset): Promise<void> {
    await this.onAssetSelected(modifier, asset, { location: Locations.LOCATION_SCENES_PAGE });
    await this.transitionBackToEditor();
  }

  get replaceMediaType(): CBReplacingContext | undefined {
    if (!this.operationContext) {
      return undefined;
    } else if (this.operationContext instanceof Background || this.operationContext instanceof Scene) {
      return CBReplacingContext.BACKGROUND;
    } else if (this.operationContext instanceof Logo) {
      return CBReplacingContext.ELEMENT;
    } else if (this.operationContext instanceof Watermark) {
      return CBReplacingContext.WATERMARK;
    } else {
      return undefined;
    }
  }

  @action
  private async onAddBlankScene(modifier: SceneAssetModifier): Promise<void> {
    await modifier.applyBlankScene();

    void this.trackContentBarAddContent(undefined, {});
  }

  @action
  private async onSceneSelectedToAdd(
    modifier: SceneAssetModifier,
    asset: SelectableAsset,
    trackProperties?: CBSelectAssetTrackingProperties
  ): Promise<void> {
    await modifier.applyAsset(asset);

    void this.trackContentBarAddContent(asset, trackProperties);
  }

  @action
  private async onAssetSelectedToAdd(
    modifier: SceneAssetModifier,
    asset: SelectableAsset,
    trackProperties?: CBSelectAssetTrackingProperties
  ): Promise<void> {
    const { scene } = this.advancedEditor;

    if (scene) {
      if (asset instanceof SelectableTextStyle) {
        await modifier.addTextToScene(asset, scene);
      } else {
        await modifier.addAssetToCaption(asset, scene, this.advancedEditor.caption);
      }
      void this.trackContentBarAddContent(asset, { sceneId: scene?.id, ...trackProperties });
    }
  }

  private async onAssetSelectedToReplace(
    modifier: SceneAssetModifier,
    asset: SelectableAsset,
    trackProperties?: CBSelectAssetTrackingProperties
  ): Promise<void> {
    const context = this.operationContext;
    const { scene } = this.advancedEditor;

    if (scene) {
      let mediaToReplace = context;

      if (mediaToReplace instanceof Scene) {
        mediaToReplace = mediaToReplace.background;
      }

      if (!mediaToReplace) {
        throw new Error("unexpected null logo");
      }

      await modifier.applyAssetToMedia(asset, scene, mediaToReplace);
      void this.trackContentBarAddContent(asset, { sceneId: scene?.id, ...trackProperties });
    }
  }

  private get bestPanelTypeForOperation(): string | undefined {
    if (this.operationContext instanceof Media || this.operationContext instanceof Scene) {
      const original =
        this.operationContext instanceof Media ? this.operationContext.asset : this.operationContext.background?.asset;

      switch (original?.originalAsset?.type) {
        case "sag":
          return ContentBarPanelType.GRAPHICS;
        case "shutterstock-footage":
        case "zymbol-footage":
        case "pexels-video":
          return `${ContentBarPanelType.STOCK}.videos`;
        case "pexels-image":
          return `${ContentBarPanelType.STOCK}.images`;
      }

      switch (original?.constructor?.name) {
        case "Animation":
          return ContentBarPanelType.GRAPHICS;
        case "Image":
          return `${ContentBarPanelType.STOCK}.images`;
        case "VideoClip":
          return `${ContentBarPanelType.STOCK}.videos`;
      }
    }

    return;
  }

  private finishAddOrReplace(): void {
    this._operationContext = undefined;
  }

  private async trackContentBarOpenClose(state: CBState, triggeredBy: CBTriggeredBy): Promise<void> {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CONTENT_BAR_STATE_CHANGE, {
      projectId: this.advancedEditor.timeline?.id,
      state,
      triggeredBy
    });
  }

  private async transitionBackToEditor(): Promise<void> {
    const options = {
      queryParams: {
        showSelection: "chur"
      }
    };

    const { timeline, scene, caption } = this.advancedEditor;
    const contextType = this.replaceMediaType;

    if (timeline && scene) {
      if (scene) {
        if (contextType === CBReplacingContext.BACKGROUND) {
          if (caption) {
            void this.router
              .transitionTo(
                "authenticated.project.scene.caption.background",
                timeline.id,
                scene.id,
                caption.id,
                options
              )
              .followRedirects();
          } else {
            void this.router
              .transitionTo("authenticated.project.scene.background", timeline.id, scene.id, options)
              .followRedirects();
          }
          return;
        } else if (contextType === CBReplacingContext.WATERMARK) {
          if (caption) {
            void this.router
              .transitionTo("authenticated.project.scene.caption.watermark", timeline.id, scene.id, caption.id)
              .followRedirects();
          } else {
            void this.router
              .transitionTo("authenticated.project.scene.watermark", timeline.id, scene.id)
              .followRedirects();
          }
          return;
        } else if (contextType === CBReplacingContext.ELEMENT && caption) {
          void this.router
            .transitionTo(
              "authenticated.project.scene.caption.logo",
              timeline.id,
              scene.id,
              caption.id,
              this.operationContext!.id
            )
            .followRedirects();
          return;
        }
      }
    }

    void this.router.transitionTo("authenticated.project");
  }

  private async trackContentBarAddContent(asset?: SelectableAsset, properties?: CBTrackingProperties): Promise<void> {
    const context = this.operationContext ? "replace-content" : asset ? undefined : "add-blank-scene";
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CONTENT_BAR_ADD_CONTENT, {
      projectId: this.advancedEditor.timeline?.id,
      location: Locations.LOCATION_CONTENT_BAR,
      contentType: this.panel.split("."),
      context,
      mediaType: this.replaceMediaType,
      ...asset?.trackUsageData,
      ...properties
    });
  }
}
