import type Evented from "@ember/object/evented";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { ContributorTypes } from "client/models/folder";
import type FoldersService from "client/services/folders";
import { DEFAULT_FILTER_ORDER } from "client/services/folders";
import type TeamProjectActivityService from "client/services/team-project-activity";

export interface AssetLibraryLibraryRouteModel {
  order: string;
  type: ContributorTypes;
}

interface AssetLibraryLibraryRouteParams {
  type: ContributorTypes;
  order: string;
}

export default class AssetLibraryLibraryRoute extends Route<AssetLibraryLibraryRouteModel> {
  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare router: RouterService & Evented;

  @service
  private declare teamProjectActivity: TeamProjectActivityService;

  beforeModel(transition: Transition): void {
    const { type } = transition.to.params;
    const { order = DEFAULT_FILTER_ORDER } = transition.to.queryParams;

    this.folders.filterOrder = order;

    if (!type || !Object.values(ContributorTypes).includes(type as ContributorTypes)) {
      void this.router.transitionTo(this.routeName, ContributorTypes.USER);
      return;
    }
  }

  async model({ type, order }: AssetLibraryLibraryRouteParams): Promise<AssetLibraryLibraryRouteModel> {
    order ??= this.folders.filterOrder;

    return {
      order,
      type
    };
  }

  afterModel(): void {
    this.folders.setActiveFolder();
  }

  queryParams = {
    order: {
      refreshModel: true
    }
  };
}
