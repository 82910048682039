import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type ScreenDoorService from "client/services/screen-door";

export default class EditOnlyModeController extends Controller {
  @service
  declare router: RouterService;

  @service
  declare screenDoor: ScreenDoorService;

  @tracked
  loading = false;

  @action
  async confirmEditOnlyMode(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      this.screenDoor.setNextShow();
      void this.router.transitionTo("authenticated.home");
    } finally {
      this.loading = false;
    }
  }
}
