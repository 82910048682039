import Honeybadger from "@honeybadger-io/js";
import config from "client/config/environment";

const developmentEnvironments = ["development", "test"];

const initialize = (): void => {
  Honeybadger.configure({
    apiKey: config.honeybadgerApiKey,
    environment: config.environment,
    developmentEnvironments,
    // Override `developmentEnvironments` to explicitly enable/disable error reporting.
    // reportData: true,
    debug: developmentEnvironments.includes(config.environment),
    enableUncaught: true,
    maxErrors: 200,
    breadcrumbsEnabled: true,
    enableUnhandledRejection: false
  });
};

export default {
  name: "honeybadger",
  initialize
};
