import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface UISliderArgs {
  max: number;
  min: number;
  step: number;
  value: number;
  formattedValue?: string | number;
  onChange?: (value: number) => void;
}

export default class UISliderComponent extends Component<UISliderArgs> {
  private _element!: HTMLElement;

  styleNamespace = getStyleNamespace("ui/ui-slider");

  get min(): number {
    return this.args.min ?? 0;
  }

  get max(): number {
    return this.args.max ?? 100;
  }

  get step(): number {
    return this.args.step ?? 1;
  }

  get minValue(): number {
    return this.min;
  }

  get maxValue(): number {
    return this.max;
  }

  get inputElement(): HTMLInputElement | undefined {
    return this._element.querySelector(`input[type="range"]`) as HTMLInputElement;
  }

  get progressStyleAttr(): SafeString {
    const width = (this.args.value - this.min) / (this.max - this.min);
    return htmlSafe(`transform: scaleX(${width});`);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  onInput(): void {
    if (!this.inputElement) {
      return;
    }

    const value = Number(this.inputElement.value);

    if (this.args.value !== value) {
      if (this.args.onChange) {
        this.args.onChange(value);
      }
    }
  }
}
