import type routerService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { Bounds, TextConfig } from "renderer-engine";
import { ImageAnimations, ZymbolConfig, ZymbolCategory, ImageConfig, ZymbolGroupLayer } from "renderer-engine";
import { DEFAULT_LOGO_SIZE } from "client/lib/dimensions";
import { sortBy } from "client/models/collections";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";
import { MIN_ZYMBOL_GROUP_DURATION } from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type { IdentifiablePayload } from "client/services/ajax";
import { defaultZymbolCategoryMapping } from "client/services/layers";
import type NotificationsService from "client/services/notifications";
import type PlaybackEventsService from "client/services/playback-events";
import type ProjectScenesService from "client/services/project-scenes";

const ZYMBOL_GROUP_DUPLICATE_FAILURE_NOTIFICATION = "There was a problem duplicating this item";

export const getDefaultTextField = (canvasWidth?: number): Bounds => {
  // Need to update this if placeholder text is changed.
  const PLACEHOLDER_TEXT_PIXEL_WIDTH = 720;
  const width = canvasWidth ? PLACEHOLDER_TEXT_PIXEL_WIDTH / canvasWidth : 0.8;
  const height = 0.13;

  const x = (1 - width) / 2;
  const y = (1 - height) / 2;

  return {
    width,
    height,
    x,
    y
  };
};

export const calculateDimensions = async (
  zymbolGroup: ZymbolGroup,
  textConfigSourceZymbol?: Zymbol | undefined,
  centered = false
): Promise<Bounds> => {
  const { lastTextZymbol } = zymbolGroup;

  if (lastTextZymbol && !centered) {
    const { width, x } = zymbolGroup;
    const { height } = getDefaultTextField();
    const y = lastTextZymbol.y + lastTextZymbol.height + DEFAULT_Y_OFFSET;

    return { x, y, width, height };
  }

  if (textConfigSourceZymbol && textConfigSourceZymbol.isText && !centered) {
    const { width, height, x, y } = textConfigSourceZymbol;
    return { x, y, width, height };
  }

  const aspectRatio = await zymbolGroup.aspectRatio;
  const [canvasWidth] = aspectRatio.canvasDimensions;
  return { ...getDefaultTextField(canvasWidth) };
};

const DEFAULT_Y_OFFSET = 0.01;

export const FULL_FRAME_REGION_COORDS: Bounds = {
  x: 0,
  y: 0,
  width: 1,
  height: 1
};

export default class ZymbolGroupsService extends Service {
  @service
  declare store: Store;

  @service
  playbackEvents!: PlaybackEventsService;

  @service
  projectScenes!: ProjectScenesService;

  @service
  router!: routerService;

  @service
  ajax!: AjaxService;

  @service
  notifications!: NotificationsService;

  /**
   * Add a new Zymbol to this ZymbolGroup with a category as the default for the ZymbolGroup's layer
   */
  async createDefaultZymbol(zymbolGroup: ZymbolGroup): Promise<void> {
    const layerName = (await zymbolGroup.layer).name;

    if (layerName === ZymbolGroupLayer.TEXT) {
      await this.appendTextZymbol(zymbolGroup, await this.getTextConfigSourceZymbol(zymbolGroup));
      await zymbolGroup.reload();

      return;
    }

    const newZymbolProperties: Partial<Zymbol> = {
      cfg: new ZymbolConfig(),
      zymbolGroup,
      ...FULL_FRAME_REGION_COORDS,
      category: defaultZymbolCategoryMapping.get(layerName)
    };

    const zymbol = this.store.createRecord("zymbol", newZymbolProperties);

    await zymbol.save();
  }

  async createDefaultLogoZymbol(zymbolGroup: ZymbolGroup, centered = false): Promise<void> {
    const layerName = (await zymbolGroup.layer).name;

    if (layerName === ZymbolGroupLayer.TEXT) {
      await this.appendLogoZymbol(zymbolGroup, centered);
      await zymbolGroup.reload();

      return;
    }

    const newZymbolProperties: Partial<Zymbol> = {
      cfg: new ZymbolConfig(),
      zymbolGroup,
      ...FULL_FRAME_REGION_COORDS,
      category: defaultZymbolCategoryMapping.get(layerName)
    };

    const zymbol = this.store.createRecord("zymbol", newZymbolProperties);

    await zymbol.save();
  }

  async getTextConfigSourceZymbol(zymbolGroup: ZymbolGroup): Promise<Zymbol | undefined> {
    const projectScene = await zymbolGroup.projectScene;

    const neighbouringScenes = await Promise.all([
      projectScene,
      this.projectScenes.getPrevProjectScene(projectScene),
      this.projectScenes.getNextProjectScene(projectScene)
    ]);

    for (const scene of neighbouringScenes) {
      if (scene?.lastNonEmptyCaption?.firstTextZymbol) {
        return scene.lastNonEmptyCaption.firstTextZymbol;
      }
    }

    return;
  }

  async appendTextZymbol(
    zymbolGroup: ZymbolGroup,
    textConfigSourceZymbol?: Zymbol | undefined,
    textConfig?: Partial<TextConfig> | undefined,
    centered = false
  ): Promise<Zymbol> {
    const { x, y, width, height } = await calculateDimensions(zymbolGroup, textConfigSourceZymbol, centered);
    const newLayerOrder = zymbolGroup.get("zymbols").length;

    const cfg = {
      ...new ZymbolConfig(),
      text: this.isValidTextZymbol(textConfigSourceZymbol)
        ? textConfigSourceZymbol!.defaultTextConfig
        : zymbolGroup.defaultTextConfig
    };

    if (textConfig) {
      Object.assign(cfg.text, textConfig);
    }

    const zymbol = this.store.createRecord("zymbol", {
      cfg: cfg,
      category: ZymbolCategory.TEXT,
      zymbolGroup,
      x,
      y,
      width,
      height,
      layerOrder: newLayerOrder
    });
    await zymbol.save();
    return zymbol;
  }

  async appendLogoZymbol(zymbolGroup: ZymbolGroup, centered = false): Promise<Zymbol> {
    let x: number, y: number, width: number, height: number;
    const newLayerOrder = zymbolGroup.get("zymbols").length;

    // prevent getLogoSizeAndPosition() from being called twice, while still allowing x & y to be modified
    // eslint-disable-next-line
    ({ x, y, width, height } = await this.getLogoSizeAndPosition(zymbolGroup));

    if (centered) {
      x = 0.5 - width / 2.0;
      y = 0.5 - height / 2.0;
    }

    const image = new ImageConfig();
    image["animation"] = zymbolGroup.firstLogoZymbol?.cfg.image?.animation || ImageAnimations.NONE;

    const zymbol = this.store.createRecord("zymbol", {
      cfg: { ...new ZymbolConfig(), image },
      category: ZymbolCategory.IMAGE,
      zymbolGroup,
      x,
      y,
      width,
      height,
      layerOrder: newLayerOrder
    });

    await zymbol.save();
    return zymbol;
  }

  async getLogoSizeAndPosition(zymbolGroup: ZymbolGroup): Promise<Bounds> {
    const aspectRatio = await zymbolGroup.aspectRatio;

    const { width: arWidth, height: arHeight } = aspectRatio;
    const ar = arWidth / arHeight;

    const width = DEFAULT_LOGO_SIZE / ar;

    // Handles scenario where y and x are uninstatiated (NaN)
    if (isFinite(zymbolGroup.x) && isFinite(zymbolGroup.y)) {
      const y = zymbolGroup.y - DEFAULT_LOGO_SIZE - DEFAULT_Y_OFFSET;
      const x = zymbolGroup.x + zymbolGroup.width / 2 - width / 2;

      return {
        x,
        y,
        width,
        height: DEFAULT_LOGO_SIZE
      };
    } else {
      const aspectRatio = await zymbolGroup.aspectRatio;
      const [canvasWidth] = aspectRatio.canvasDimensions;
      const { x, y } = getDefaultTextField(canvasWidth);

      return {
        x,
        y,
        width,
        height: DEFAULT_LOGO_SIZE
      };
    }
  }

  async duplicateZymbolGroup(zymbolGroup: ZymbolGroup): Promise<ZymbolGroup> {
    try {
      const data = (await this.ajax.api(`/zymbol-groups/${zymbolGroup.id}/copy`, {
        method: "POST"
      })) as IdentifiablePayload;

      if (!data) {
        throw Error("Could not duplicate Zymbol Group");
      }

      this.store.pushPayload("zymbolGroup", data);
      const duplicateZymbolGroup = await this.store.findRecord("zymbolGroup", data.data.id, { reload: true });

      if (!duplicateZymbolGroup) {
        throw Error("Could not duplicate Zymbol Group");
      }

      const projectScene = await zymbolGroup.projectScene;
      await this.projectScenes.recalculateProjectSceneDuration(projectScene);

      return duplicateZymbolGroup;
    } catch (err) {
      this.notifications.error(ZYMBOL_GROUP_DUPLICATE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async duplicateZymbol(zymbol: Zymbol, zymbolGroupId?: string): Promise<Zymbol> {
    try {
      const optionalParams = zymbolGroupId ? `?zymbol_group_id=${zymbolGroupId}` : "";
      const data = (await this.ajax.api(`/zymbols/${zymbol.id}/copy${optionalParams}`, {
        method: "POST"
      })) as IdentifiablePayload;

      if (!data) {
        throw Error("Could not duplicate Zymbol Group");
      }

      this.store.pushPayload("zymbol", data);
      const duplicateZymbol = await this.store.findRecord("zymbol", data.data.id, { reload: true });

      if (!duplicateZymbol) {
        throw Error("Could not duplicate Zymbol Group");
      }

      return duplicateZymbol;
    } catch (err) {
      this.notifications.error(ZYMBOL_GROUP_DUPLICATE_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async updateDuration(zymbolGroup: ZymbolGroup, newDuration: number): Promise<void> {
    const clampedDuration = Math.max(
      Math.min(newDuration, await zymbolGroup.maximumDuration),
      MIN_ZYMBOL_GROUP_DURATION
    );

    if (zymbolGroup.duration !== clampedDuration) {
      zymbolGroup.set("duration", clampedDuration);
    }
  }

  async getPreviousCaptionAsync(zymbolGroup: ZymbolGroup): Promise<ZymbolGroup | undefined> {
    const project = await zymbolGroup.project;

    if (!project) {
      return;
    }

    const captions = await project.captions;

    if (captions) {
      return this.getPreviousCaption(zymbolGroup, captions);
    }

    return undefined;
  }

  getPreviousCaption(zymbolGroup: ZymbolGroup, captions: ZymbolGroup[]): ZymbolGroup | undefined {
    const index = captions.findIndex(({ id }) => id === zymbolGroup.id);

    if (index > 0) {
      return captions[index - 1];
    }

    return undefined;
  }

  async getNextCaptionAsync(zymbolGroup: ZymbolGroup): Promise<ZymbolGroup | undefined> {
    const project = await zymbolGroup.project;
    if (!project) {
      return;
    }

    const captions = project.captions;
    return this.getNextCaption(zymbolGroup, captions);
  }

  getNextCaption(zymbolGroup: ZymbolGroup, captions: ZymbolGroup[]): ZymbolGroup | undefined {
    const sortedCaptions = sortBy(captions, "startTime");
    const index = sortedCaptions.findIndex(({ id }) => id === zymbolGroup.id);

    if (index < sortedCaptions.length) {
      return sortedCaptions[index + 1];
    }

    return undefined;
  }

  createBackgroundZymbol(zymbolGroup: ZymbolGroup, category = ZymbolCategory.COLOR): Promise<Zymbol> {
    const zymbol = this.store.createRecord("zymbol", {
      region: 0,
      category,
      zymbolGroup,
      x: 0,
      y: 0,
      width: 1,
      height: 1
    });
    return zymbol.save();
  }

  scale(zymbolGroup: ZymbolGroup, position: Bounds): void {
    const { zymbols, width: groupWidth, height: groupHeight, x: groupX, y: groupY } = zymbolGroup;

    zymbols.forEach((zymbol) => {
      const relativeX = (zymbol.x - groupX) / groupWidth;
      const relativeY = (zymbol.y - groupY) / groupHeight;
      const relativeWidth = zymbol.width / groupWidth;
      const relativeHeight = zymbol.height / groupHeight;

      zymbol.setProperties({
        x: relativeX * position.width + position.x,
        y: relativeY * position.height + position.y,
        width: relativeWidth * position.width,
        height: relativeHeight * position.height
      });
    });
  }

  async transitionToCaption(caption: ZymbolGroup): Promise<void> {
    const [project, scene] = await Promise.all([caption.project, caption.projectScene]);
    void this.router.transitionTo("authenticated.project.scene.caption", project.id, scene.id, caption.id);
  }

  private isValidTextZymbol(zymbol: Zymbol | undefined): boolean {
    return !!zymbol && zymbol.isText;
  }
}
