// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";

/**
 * @deprecated This method should not be used. Avoid using PromiseProxies: https://embermap.com/notes/83-the-case-against-async-relationships
 */
const promiseObjectData = (promise: () => Promise<any>): DS.PromiseObject<any> => {
  return (DS.PromiseObject as any).create({
    promise: promise()
  });
};

export default promiseObjectData;
