import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import { ChangeSceneFilterColorMutation, SceneBackgroundColorMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AdvancedEditorService from "client/services/advanced-editor";

interface Args {
  eventRegister: EventRegister;
  scene: Scene;
}

export default class FloatingPanelsBackgroundComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("floating-panels/panels/style/background");

  @service
  private declare advancedEditor: AdvancedEditorService;

  get projectColors(): string[] | undefined {
    return this.advancedEditor.timeline?.sceneColors;
  }

  get backgroundColors(): string[] {
    return [this.args.scene.color];
  }

  @action
  async saveBackgroundColor(color: string): Promise<void> {
    const { eventRegister, scene } = this.args;
    eventRegister.fire(new SceneBackgroundColorMutation(scene, color));
    await eventRegister.facade.saveScene(scene);
  }

  get filterColors(): string[] {
    return this.args.scene.filterColor ? [this.args.scene.filterColor] : [];
  }

  @action
  async saveFilterColor(color: string): Promise<void> {
    const { eventRegister, scene } = this.args;
    eventRegister.fire(new ChangeSceneFilterColorMutation(scene, color));
    await eventRegister.facade.saveScene(scene);
  }
}
