export default function compactFormat(value: string, valueIfNaN: string): string {
  const PRECISION = 10;
  const ROUNDING = 1000;
  const THRESHOLD = 999;

  const input = parseInt(value, 10);

  if (!isFinite(input)) {
    return valueIfNaN ?? "";
  }

  if (input <= THRESHOLD) {
    return `${input}`;
  } else {
    return `${Math.round((input / ROUNDING) * PRECISION) / PRECISION}k`;
  }
}
