import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { TrackedArray, tracked } from "tracked-built-ins";
import TrackingEvents from "client/events";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ManyArray } from "client/models/ember-data-types";
import type Project from "client/models/project";
import type Team from "client/models/team";
import type TeamMembership from "client/models/team-membership";
import type AuthService from "client/services/auth";
import type CollaborationService from "client/services/collaboration";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export const ACCOUNT_ADD_TEAM_MEMBER_SUCCESS_NOTIFICATION = "User has been invited to your team";

interface TeamInviteArgs {
  trackingLocation?: string;
  onSendInvites?: (added?: string[], failed?: Map<string, string>) => void;
  project?: Project;
  hideInviteNotification?: boolean;
}

export default class TeamInviteComponent extends Component<TeamInviteArgs> {
  styleNamespace = getStyleNamespace("team-invite");

  tagInputElement!: HTMLElement;

  // service
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare collaboration: CollaborationService;

  @service
  declare tracking: TrackingService;

  @tracked
  submitting = false;

  @tracked
  valid = false;

  @tracked
  tags: string[] = new TrackedArray([]);

  @tracked
  projectShared = true;

  private submitReady: DeferredPromise<boolean> = defer();

  get teamMembers(): ManyArray<TeamMembership> {
    return this.team.teamMemberships;
  }

  get teamMemberEmails(): string[] {
    return this.teamMembers.map((teamMember) => teamMember.email);
  }

  get disableSubmitBtn(): boolean {
    return !this.valid || this.tags.length === 0;
  }

  private get team(): Team {
    return this.auth.currentTeam!;
  }

  @action onValidityChange(valid: boolean): void {
    this.valid = valid;
  }

  @action
  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();

    if (this.projectShared && this.args.project && !this.args.project.teamShared) {
      await this.shareProject();
    }

    const addedMembers: string[] = [];
    const failedMembers = new Map<string, string>();

    if (!(await this.beforeSubmit())) {
      return;
    }

    for (let i = this.tags.length - 1; i >= 0; i--) {
      const email = this.tags[i];
      this.tags = this.tags.slice(0, i);

      if (!email) {
        continue;
      }
      try {
        await this.team.addTeamMember(email, false);
        addedMembers.push(email);

        if (!this.args.hideInviteNotification) {
          this.notifications.success(ACCOUNT_ADD_TEAM_MEMBER_SUCCESS_NOTIFICATION);
        }
      } catch (error) {
        const message = (error as Error).message;
        failedMembers.set(email, message);

        if (!this.args.hideInviteNotification) {
          this.notifications.error(message);
        }
      }
    }

    this.afterSubmit();
    this.trackMembersAdded(addedMembers);
    this.args.onSendInvites?.(addedMembers, failedMembers);
  }

  private async shareProject(): Promise<void> {
    if (this.args.project) {
      await this.collaboration.shareProject(this.args.project);
      this.notifications.success("Your project has been shared with your team");
    }
  }

  /* eslint-disable camelcase */
  private trackMembersAdded(members_added: string[]): void {
    if (members_added.length && this.args.trackingLocation) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_INVITE_TEAM_MEMBERS, {
        account_type: this.auth.currentFullSubscription ? "paid" : "free",
        location: this.args.trackingLocation,
        team_id: this.team.id,
        team_size: this.team.memberCount,
        invites_sent: members_added.length,
        members_added
      });
    }
  }
  /* eslint-enable camelcase */

  @action
  onKeyUp(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    switch (event.code) {
      case EventKeys.SPACE:
        input.value = input.value.trim().replace(/^[,]+/, "").replace(/[,]+$/, "");
        break;
    }
  }

  @action
  async validateTag(tag: string): Promise<string[]> {
    const messages = [];
    if (this.teamMemberEmails.includes(tag)) {
      messages.push("This user is already on your team");
    } else if (!(await this.team.checkForExistingEmail(tag))) {
      messages.push("This user is already on another team");
    }
    return messages;
  }

  @action
  onSubmitReady(ready: boolean): void {
    this.submitReady.resolve(ready);
  }

  @action
  setTagInputElement(element: HTMLElement): void {
    this.tagInputElement = element;
  }

  private async beforeSubmit(): Promise<boolean> {
    this.submitReady = defer();
    this.submitting = true;

    const ready = await this.submitReady;
    if (!ready) {
      this.submitting = false;
    }

    return ready;
  }

  private afterSubmit(): void {
    this.submitting = false;
  }
}
