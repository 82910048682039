import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Project from "client/models/project";

export default class ProjectThumbnailGeneratorService extends Service {
  @service
  declare store: Store;

  private project?: Project;
  private hash?: string;

  private queue = Promise.resolve();

  public setProject(project: Project): void {
    this.project = project;
    this.hash = project.posterContentHash;
  }

  public clearProject(): void {
    this.project = undefined;
  }

  public generate(force?: boolean): void {
    const { project } = this;

    if (project && project.duration && (force || this.thumbnailIsStale)) {
      this.hash = this.projectHash;
      this.queue = this.queue.then(() => this.generateThumbnail(project));
    }
  }

  private async generateThumbnail(project: Project): Promise<void> {
    const url = await project?.generateThumbnail();

    if (url) {
      // Update the URL of the projectPreview if it's in the store
      const projectPreview = this.store.peekRecord("projectPreview", project.id);

      if (projectPreview) {
        projectPreview.set("posterUrl", url);
      }
    }
  }

  private get thumbnailIsStale(): boolean {
    return this.hashDidChange || this.emptyPoster;
  }

  private get emptyPoster(): boolean {
    return !!this.project?.posterIsEmpty;
  }

  private get projectHash(): string | undefined {
    return this.project?.posterContentHash;
  }

  private get hashDidChange(): boolean {
    const { project, hash, projectHash } = this;

    return !!(project && hash && projectHash && projectHash !== hash);
  }
}
