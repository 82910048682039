import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import PIXI from "pixi.js";
import TrackingEvents from "client/events";
import type MobileService from "client/services/mobile";
import type PermissionsService from "client/services/permissions";
import type TrackingService from "client/services/tracking";

interface TheBrowserCheckArgs {
  isEditor: boolean;
}

const CODECS = "vp9, vorbis";
const HIDDEN_LOCALSTORAGE_KEY = "biteable:hideBrowserCheck";

export const isWebMSupported = (): boolean => {
  const video = document.createElement("video");
  return video.canPlayType(`video/webm; codecs="${CODECS}"`) === "probably";
};

const agentHas = (keyword: string): boolean => {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
};

export const isSafari = (): boolean => {
  return (
    // https://www.browserstack.com/guide/create-browser-specific-css
    // @ts-expect-error
    (!!window.ApplePaySetupFeature || !!window.safari) &&
    agentHas("Safari") &&
    !agentHas("Chrome") &&
    !agentHas("CriOS")
  );
};

export default class TheBrowserCheckComponent extends Component<TheBrowserCheckArgs> {
  @service
  declare mobile: MobileService;

  @service
  declare permissions: PermissionsService;

  @service
  declare tracking: TrackingService;

  @tracked
  show = false;

  isWebMSupported = false;
  isWebGLSupported = false;
  isSafari = false;

  constructor(owner: object, args: TheBrowserCheckArgs) {
    super(owner, args);

    if (this.args.isEditor || !this.isHidden()) {
      this.isWebMSupported = isWebMSupported();
      this.isWebGLSupported = PIXI.utils.isWebGLSupported();
      this.isSafari = isSafari();

      this.show =
        (!this.isWebMSupported || !this.isWebGLSupported || this.isSafari) &&
        !this.mobile.isMobile &&
        !this.permissions.has("feature_hidden_browser_notification");
      if (this.show) {
        this.trackEvent(this.isWebMSupported, this.isWebGLSupported, this.isSafari);
      }
    }
  }

  @action
  close(): void {
    this.show = false;
    this.hide();
  }

  private hide(): void {
    if (localStorage) {
      localStorage.setItem(HIDDEN_LOCALSTORAGE_KEY, "true");
    }
  }

  private isHidden(): boolean {
    return localStorage && localStorage.getItem(HIDDEN_LOCALSTORAGE_KEY) === "true";
  }

  private trackEvent(isWebMSupported: boolean, isWebGLSupported: boolean, isSafari: boolean): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_BROWSER_MISSING_FEATURE, {
      isWebMSupported,
      isWebGLSupported,
      isSafari
    });
  }
}
