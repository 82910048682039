import Model, { attr } from "@ember-data/model";

export default class Card extends Model {
  @attr("string")
  declare brand: string;

  @attr("number")
  declare expMonth: string;

  @attr("number")
  declare expYear: string;

  @attr("number")
  declare last4: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    card: Card;
  }
}
