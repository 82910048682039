import Model, { attr } from "@ember-data/model";

export default class UserPreference extends Model {
  @attr("string")
  declare userId: string;

  @attr("string")
  declare brandStyleId: string;

  @attr("boolean")
  declare receiveCommentNotifications: boolean;
}
