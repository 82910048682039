import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { Colors } from "client/lib/colors";
import getStyleNamespace from "client/lib/get-style-namespace";

interface UserInitialArgs {
  userId?: string;
  userName: string;
  colored?: boolean;
  backgroundColor?: string;
  size?: "small" | "large";
}

const DEFAULT_COLOR = "#e6e7ed";

export default class UserInitialComponent extends Component<UserInitialArgs> {
  styleNamespace = getStyleNamespace("north-star/user-initial");

  get initial(): string {
    return this.args.userName?.[0]?.toUpperCase() ?? "U";
  }

  get backgroundColor(): string {
    if (this.args.backgroundColor) {
      return this.args.backgroundColor;
    }

    if (this.args.colored === false) {
      return DEFAULT_COLOR;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const colors = Object.values(Colors);

    let index;

    if (this.userId === undefined) {
      index = Math.floor(Math.random() * colors.length);
    } else {
      index = this.userId % colors.length;
    }

    return colors[index] ?? DEFAULT_COLOR;
  }

  get userId(): number | undefined {
    return this.args.userId === undefined ? undefined : parseInt(this.args.userId);
  }

  get styles(): SafeString {
    return htmlSafe(`background-color: ${this.backgroundColor}`);
  }
}
