import { tracked } from "@glimmer/tracking";
import type { Scene } from "./scene";

export class Transition {
  @tracked
  public left?: Scene;

  @tracked
  public right?: Scene;

  @tracked
  public name: string;

  constructor(name: string) {
    this.name = name;
  }
}
