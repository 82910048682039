import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type ValidatedCoupon from "client/models/validated-coupon";

interface CouponInputFieldComponentArgs {
  plan: Plan;
  couponCode?: string;
  coupon?: ValidatedCoupon;
  setCoupon: (coupon: ValidatedCoupon | undefined) => void;
}

export default class CouponInputFieldComponent extends Component<CouponInputFieldComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/coupon-input-field");

  @service
  declare store: Store;

  @tracked
  private couponCode = this.args.couponCode ?? "";

  @tracked
  couponError? = "";

  @tracked
  loading = false;

  @tracked
  dirty = false;

  @action
  didInsert(): void {
    void this.validateCoupon();
  }

  @action
  onInput(): void {
    this.couponError = undefined;
    this.dirty = true;
  }

  @action
  onChange(): void {
    void this.validateCoupon();
  }

  @action
  async submit(ev: Event): Promise<void> {
    ev.preventDefault();
    await this.validateCoupon();
  }

  @action
  planDidUpdate(): void {
    void this.validateCoupon();
  }

  @action
  couponDidUpdate(_: HTMLElement, [coupon]: [CouponInputFieldComponentArgs["coupon"]]): void {
    this.couponCode = coupon?.code ?? this.couponCode;
  }

  @action
  async validateCoupon(): Promise<void> {
    if (this.couponCode) {
      try {
        this.dirty = false;
        this.loading = true;

        // @ts-expect-error
        const [coupon] = (await this.store.query("validatedCoupon", {
          code: this.couponCode,
          plan_id: this.args.plan.id // eslint-disable-line camelcase
        })) as ValidatedCoupon[];

        if (coupon) {
          this.args.setCoupon(coupon);
        } else {
          this.args.setCoupon(undefined);
          this.couponError = "Invalid coupon code";
        }
      } catch (err) {
        // @ts-expect-error
        this.couponError = err.errors?.[0]?.detail ?? "Invalid coupon code";
        this.args.setCoupon(undefined);
      } finally {
        this.loading = false;
      }
    } else {
      this.args.setCoupon(undefined);
    }
  }

  get showButton(): boolean {
    return this.dirty || this.loading;
  }
}
