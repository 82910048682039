import { action } from "@ember/object";
import Component from "@glimmer/component";
import clamp from "lodash/clamp";
import round from "lodash/round";
import { DEFAULT_DUCKED_LEVEL } from "renderer-engine/dist/src/lib/audio/volume";
import { tracked } from "tracked-built-ins";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioClip from "client/models/audio-clip";

interface AppAudioClipVolumeArgs {
  audioClip: AudioClip;
  onVolumeChange: (volume: number) => void;
  onFadeInClick: () => void;
  onFadeOutClick: () => void;
  onMuteChange: (mute: boolean) => void;
}

export default class AppAudioClipVolumeComponent extends Component<AppAudioClipVolumeArgs> {
  styleNamespace = getStyleNamespace("app/audio-clip-volume");

  get isAudioClipBeingDucked(): boolean {
    return this.args.audioClip.audioDuckingLevel === DEFAULT_DUCKED_LEVEL;
  }

  set isAudioClipBeingDucked(_value: boolean) {
    // Empty implementation this is the sibling to the getter as built-in components need one but it does not need
    // to set a value that is handled by the @onAudioDuckingLevelChange argument
  }

  @action
  onVolumeChange(volume: number): void {
    if (isNaN(volume)) {
      return;
    }

    this.args.onVolumeChange(volume);

    if (volume !== 0 && this.args.audioClip.mute) {
      // unmute when setting the volume to an non-zero integer.
      this.args.onMuteChange(false);
    }

    this.inputValue = this.formattedValue;
  }

  @tracked
  inputValue = this.formattedValue; // sets input value to match formattedValue upon load

  @action
  updateVolume(): void {
    this.inputValue = round((this.args.audioClip?.volume || 0) * 5, 1);
  }

  @action
  inputVolume(): void {
    const volumeValue = this.convertFormattedValueToAudioClipVolume(this.inputValue);
    this.onVolumeChange(volumeValue);
  }

  private convertFormattedValueToAudioClipVolume(input: number): number {
    return clamp(input, 0.0, 10.0) / 5.0;
  }

  get formattedValue(): number {
    if (this.args.audioClip?.mute) {
      return 0;
    } else {
      return round((this.args.audioClip?.volume || 0) * 5, 1);
    }
  }

  @action
  onMuteClick(): void {
    let mute = !this.args.audioClip.mute;

    if (this.args.audioClip.volume === 0) {
      // set volume to 100% when unmuting from volume zero.
      this.onVolumeChange(1);
      mute = false;
    }

    this.args.onMuteChange(mute);
  }
}
