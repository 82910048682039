import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { convertEmsToFormSize, convertFormSizeToEms } from "renderer-engine";
import type { TextStyle, Text } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { FONT_SIZES } from "client/lib/text/fonts";
import type { EditableText } from "client/lib/text/text-editor";

interface Args {
  media: Text;
  onClose: () => void;
  editables: Map<string, EditableText>;
}

export default class ToolbarFontSizeComponent extends Component<Args> {
  @tracked
  targetElement?: HTMLElement;

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  @tracked
  fontSizeInput: number = this.fontSizeValue;

  FONT_SIZES = FONT_SIZES;

  styleNamespace = getStyleNamespace("toolbar/font/size");

  @action
  change(): void {
    if (this.fontSizeInput !== this.fontSizeValue) {
      this.updateFontSize(this.fontSizeInput);
    }
  }

  @action
  updateFontSize(fontSize = this.fontSizeInput): void {
    if (fontSize < 0 || fontSize > 400) {
      this.fontSizeInput = 16;
      this.editableText?.applyStyle({
        fontSize: convertFormSizeToEms(16)
      });
    } else {
      this.editableText?.applyStyle({
        fontSize: convertFormSizeToEms(fontSize)
      });
      this.fontSizeInput = fontSize;
    }
  }

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.media.id);
  }

  get textStyle(): TextStyle | undefined {
    return this.editableText?.textStyle;
  }

  get fontSizeValue(): number {
    return convertEmsToFormSize(this.textStyle?.fontSize ?? 1);
  }

  get isCustomFontSize(): boolean {
    return !this.FONT_SIZES.includes(this.fontSizeValue);
  }
}
