import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type AuthService from "client/services/auth";
import type UpgradeService from "client/services/upgrade";

export default class StartTrialOrUpgradeService extends Service {
  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @service
  declare upgrade: UpgradeService;

  @action
  public start(ctaContext: string): void {
    if (this.canStartTrial) {
      void this.router.transitionTo("authenticated.trial");
    } else {
      const analytics = { ctaContext };
      void this.upgrade.open({ analytics });
    }
  }

  get canStartTrial(): boolean {
    return !this.auth.currentSubscription && !this.auth.latestTrial;
  }
}
