import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AccountProfileRoute from "../route";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

export default class DeleteAccountRoute extends Route<User> {
  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  beforeModel(): void {
    if (!this.auth.canDestroyCurrentUser) {
      void this.router.replaceWith("authenticated.account.profile");
    }
  }

  model(): User {
    const { user } = this.modelFor<AccountProfileRoute>("authenticated.account.profile");

    return user;
  }
}
