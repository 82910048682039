import { Axis } from "../grid/axis";
import { Vector2 } from "../math";
import { SNAP_THRESHOLD } from "./snap-threshold";

export class Line extends Vector2 {
  constructor(readonly x: number, readonly y: number, public axis: Axis, public snapThreshold = SNAP_THRESHOLD) {
    super(x, y);
  }

  public get isHorizontal(): boolean {
    return this.axis === Axis.HORIZONTAL;
  }

  public get isVertical(): boolean {
    return this.axis === Axis.VERTICAL;
  }
}
