import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ScriptWorkstationService from "client/services/script-workstation";

export default class ScriptWorkstationOrderAnimationComponent extends Component<{ order: number }> {
  private outer?: HTMLElement;
  private inner?: HTMLElement;
  private oldOrder = NaN;

  @service
  declare scriptWorkstation: ScriptWorkstationService;

  styleNamespace = getStyleNamespace("script-workstation/order-animation");

  @action
  didInsertOuter(outer: HTMLElement): void {
    this.outer = outer;
    outer.style.order = "-1";

    new ResizeObserver(this.applyPositionStyle).observe(outer);

    if (outer.parentElement) {
      // This changes in `HTMLElement.style.order` in siblings
      new MutationObserver(this.applyPositionStyle).observe(outer.parentElement, {
        subtree: true,
        childList: true,
        attributeFilter: ["style"]
      });
    }

    this.didUpdateOrder();
  }

  @action
  didInsertInner(inner: HTMLElement): void {
    this.inner = inner;

    new ResizeObserver(() => {
      const innerBounds = inner.getBoundingClientRect();
      requestAnimationFrame(() => {
        if (this.outer) {
          this.outer.style.minHeight = `${innerBounds.height}px`;
        }
      });
    }).observe(inner);

    this.didUpdateOrder();
  }

  @action
  applyPositionStyle(): void {
    requestAnimationFrame(() => {
      if (!this.inner || !this.outer?.parentElement) {
        return;
      }

      const parentBounds = this.outer.parentElement.getBoundingClientRect();
      const outerBounds = this.outer.getBoundingClientRect();

      Object.assign(this.inner.style, {
        left: `${outerBounds.left - parentBounds.left}px`,
        top: `${outerBounds.top - parentBounds.top}px`,
        width: `${outerBounds.width}px`
      });
    });
  }

  @action
  didUpdateOrder(): void {
    if (this.args.order === this.oldOrder || !this.inner || !this.outer) {
      return;
    }

    // We don't transition into the initial position when this.oldOrder === NaN
    if (!isNaN(this.oldOrder)) {
      this.inner.style.position = "absolute";
      this.inner.style.transition = "ease-in-out top 200ms";
    }

    this.oldOrder = this.args.order;
    this.outer.style.order = `${this.args.order}`;
  }

  @action
  didTransitionEnd(): void {
    if (!this.inner || !this.outer) {
      return;
    }

    this.inner.style.position = "unset";
    this.inner.style.transition = "unset";
  }
}
