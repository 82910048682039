import Model, { attr, belongsTo } from "@ember-data/model";
import type Card from "./card";

export default class PaymentSource extends Model {
  @attr("string")
  declare stripeToken: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("card", { async: false, inverse: null })
  readonly card?: Card;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    paymentSource: PaymentSource;
  }
}
