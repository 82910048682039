import Model, { attr } from "@ember-data/model";

export default class UpcomingInvoice extends Model {
  @attr("string")
  declare amount: string;

  @attr("date")
  declare nextPaymentAt: Date;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    upcomingInvoice: UpcomingInvoice;
  }
}
