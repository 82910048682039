import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isMacOS } from "client/lib/user-agent";
import type TooltipService from "client/services/tooltip";

export default class TheTooltipComponent extends Component {
  @service
  declare tooltip: TooltipService;

  styleNamespace = getStyleNamespace("the-tooltip");

  get showMacShortcuts(): boolean {
    return isMacOS;
  }
}
