import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type ProjectContentBarService from "client/services/project-content-bar";

export default class PanelUploadsHeader extends Component {
  @service
  declare projectContentBar: ProjectContentBarService;

  get panel(): string | undefined {
    return this.projectContentBar.panel;
  }

  @action
  selectPanel(panel: string): void {
    void this.projectContentBar.show(panel);
  }
}
