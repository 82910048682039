import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import interact from "interactjs";
import type { Bounds } from "renderer-engine";
import getStyleNamespace from "client/lib/get-style-namespace";
import toPercent from "client/lib/to-percent";

export interface BoundingBoxArgs {
  bounds: Bounds;

  minSize?: number;

  dashedOutline?: boolean;
  snap?: boolean;
  draggable?: boolean;
  resizable?: boolean;

  onChange: (bounds: Bounds) => void;
  onDoubletap?: () => void;
}

export default abstract class BoundingBox<T extends BoundingBoxArgs> extends Component<T> {
  public interactable?: Interact.Interactable;

  public parentWidth = 0;
  public parentHeight = 0;

  styleNamespace = getStyleNamespace("tidal/project-canvas-bounding-box");

  @action
  didInsert(element: HTMLElement): void {
    this.interactable = this.createInteractable(element);

    if (element.parentElement) {
      ({ width: this.parentWidth, height: this.parentHeight } = element.parentElement.getBoundingClientRect());
    }

    window.addEventListener("keydown", this.handleKeypress.bind(this));
    window.addEventListener("keyup", this.handleKeyUp.bind(this));
    this.applyModifiers();
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    window.removeEventListener("keydown", this.handleKeypress);
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  @action
  onClick(e: MouseEvent): void {
    e.stopPropagation();
  }

  createInteractable(element: HTMLElement): Interact.Interactable | undefined {
    if (!element.parentElement) {
      return;
    }

    const { width, height } = element.parentElement.getBoundingClientRect();

    const interactable = interact(element).on("doubletap", this.doubletap.bind(this));

    const aspectRatioModifier = interact.modifiers.aspectRatio({
      ratio: "preserve"
    });
    interactable.resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },
      listeners: { move: this.resize.bind(this) },
      modifiers: [aspectRatioModifier]
    });

    const snapModifier = interact.modifiers.snap({
      offset: "parent",
      range: 30,
      targets: [
        { x: 0, y: 0 },
        { x: 0, y: height / 2 },
        { x: 0, y: height },
        { x: width / 2, y: 0 },
        { x: width / 2, y: height / 2 },
        { x: width / 2, y: height },
        { x: width, y: 0 },
        { x: width, y: height / 2 },
        { x: width, y: height }
      ],
      relativePoints: [
        { x: 0, y: 0 }, // snap relative to the element's top-left,
        { x: 0.5, y: 0 }, // top,
        { x: 1, y: 0 }, // top-right,
        { x: 1, y: 0.5 }, // right,
        { x: 1, y: 1 }, // bottom-right
        { x: 0.5, y: 1 }, // bottom
        { x: 0.5, y: 0.5 }, // center
        { x: 0, y: 1 }, // bottom-left
        { x: 0, y: 0.5 } // left
      ]
    });
    interactable.draggable({
      listeners: { move: this.move.bind(this) },
      modifiers: [snapModifier]
    });

    return interactable;
  }

  move(event: Interact.DragEvent): void {
    this.applyModifiers();

    const { x, y, width, height } = this.args.bounds;
    const newBounds = {
      x: x + event.dx / this.parentWidth,
      y: y + event.dy / this.parentHeight,
      width,
      height
    };

    this.args.onChange(newBounds);
  }

  resize(event: Interact.ResizeEvent): void {
    const { x, y, width, height } = this.args.bounds;
    if (
      !event.deltaRect ||
      (event.deltaRect.width < 0 && width <= this.minSize) ||
      (event.deltaRect.height < 0 && height <= this.minSize)
    ) {
      return;
    }

    this.applyModifiers();

    const { left: deltaLeft, top: deltaTop, width: deltaWidth, height: deltaHeight } = event.deltaRect;
    const newBounds = {
      x: x + deltaLeft / this.parentWidth,
      y: y + deltaTop / this.parentHeight,
      width: width + deltaWidth / this.parentWidth,
      height: height + deltaHeight / this.parentHeight
    };

    this.args.onChange(newBounds);
  }

  doubletap(): void {
    if (this.args.onDoubletap) {
      this.args.onDoubletap();
    }
  }

  handleKeypress(event: KeyboardEvent): void {
    const { shiftKey } = event;

    if (shiftKey && this.interactable) {
      this.interactable.draggable().lockAxis = "start";
    }
  }

  handleKeyUp(): void {
    if (this.interactable) {
      this.interactable.draggable().lockAxis = undefined;
    }
  }

  applyModifiers(): void {
    this.interactable?.draggable(!!this.args.draggable);
    this.interactable?.resizable(!!this.args.resizable);

    const modifiers = this.interactable?.draggable().modifiers;
    if (modifiers) {
      for (const modifier of modifiers) {
        if (modifier.name === "snap") {
          if (this.args.snap) {
            modifier.enable();
          } else {
            modifier.disable();
          }
        }
      }
    }
  }

  get minSize(): number {
    return this.args.minSize ?? 0.05;
  }

  get outlineStyle(): string {
    return this.args.dashedOutline ? "dashed" : "solid";
  }

  get boundsStyleAttr(): SafeString {
    const { x, y, width, height } = this.args.bounds;
    return htmlSafe(`
      left: ${toPercent(x)};
      top: ${toPercent(y)};
      width: ${toPercent(width)};
      height: ${toPercent(height)};
      outline-style: ${this.outlineStyle};
      outline-width: 2px;
    `) as any;
  }
}
