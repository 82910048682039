import { htmlSafe } from "@ember/template";
import type { SafeString } from "handlebars";
import { encode } from "html-entities";
import { getType } from "mime";
import match from "mime-match";

const LARGE_MAX_IMAGE_SIZE_MB = 50;
const LARGE_MAX_VIDEO_SIZE_MB = 700;
const LARGE_MAX_AUDIO_SIZE_MB = 250;

const MB_TO_BYTES = 1024 * 1024;

export type FileType = "image" | "audio" | "video";

export default class UploadValidator {
  constructor(private file: File, private accept?: string) {}

  get isValid(): boolean {
    return this.validFileSize && this.validFileType && !this.fileIsBlank;
  }

  get errorMessage(): SafeString | string {
    if (this.fileIsBlank) {
      return htmlSafe(
        encode(`Your ${this.fileType} file '${this.fileName}' contains 0 bytes. Please verify the upload file.`)
      );
    }

    if (!this.validFileSize) {
      const message = `Your ${this.fileType} file '${this.fileName}' exceeded the ${this.maxUploadSizeInMB}MB size limit. `;

      const learnMore =
        "<a target='_blank' href='https://biteable.com/pricing/#featureComparison'><u>Learn more</u></a";

      return htmlSafe(encode(message) + learnMore);
    }

    if (!this.validFileType) {
      return htmlSafe(encode(`${this.fileName} is an unsupported file type. Please try again`));
    }

    return htmlSafe("");
  }

  private get mimeType(): string | null {
    return this.file.type || getType(this.file.name);
  }

  private get validFileSize(): boolean {
    return this.file.size <= this.maxUploadSizeInMB * MB_TO_BYTES;
  }

  private get fileIsBlank(): boolean {
    return this.file.size === 0;
  }

  private get fileName(): string {
    return this.file.name;
  }

  private get validFileType(): boolean {
    if (!this.accept) {
      return true;
    }

    const mimeType = this.mimeType;
    if (mimeType) {
      return this.accept.split(",").some((s) => match(mimeType, s.trim()));
    }

    return false;
  }

  private get fileType(): FileType {
    if (this.mimeType?.startsWith("image")) {
      return "image";
    } else if (this.mimeType?.startsWith("audio")) {
      return "audio";
    } else {
      return "video";
    }
  }

  private get maxUploadSizeInMB(): number {
    if (this.fileType === "image") {
      return LARGE_MAX_IMAGE_SIZE_MB;
    } else if (this.fileType === "audio") {
      return LARGE_MAX_AUDIO_SIZE_MB;
    } else {
      return LARGE_MAX_VIDEO_SIZE_MB;
    }
  }
}
