import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import formatDuration from "client/lib/format-duration";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Props {
  length: number; // seconds
  divisionLength: number; // seconds
  subdivisionLength: number; // seconds
  pixelsPerSecond: number;
}

enum LineHeight {
  DIVISION = "100%",
  SUBDIVISION = "50%"
}

interface Line {
  x: number;
  height: LineHeight;
}

export default class SeekRulerComponent extends Component<Props> {
  styleNamespace = getStyleNamespace("generic/seek/ruler");
  get divisionsCount(): number {
    return Math.ceil(this.args.length / this.args.divisionLength);
  }

  get divisionWidth(): number {
    return this.args.divisionLength * this.args.pixelsPerSecond;
  }

  get subdivisionWidth(): number {
    return this.args.subdivisionLength * this.args.pixelsPerSecond;
  }

  get scalePattern(): Line[] {
    const subdivisionsCount = Math.floor(this.divisionWidth / this.subdivisionWidth);

    if (isNaN(subdivisionsCount)) {
      return [];
    }

    return [...Array(subdivisionsCount)].map((_, idx) => ({
      // Position lines at 0.5px coords so stroke fills pixel space.
      x: Math.floor(idx * this.subdivisionWidth) + 0.5,
      height: idx ? LineHeight.SUBDIVISION : LineHeight.DIVISION
    }));
  }

  get labels(): string[] {
    return [...Array(this.divisionsCount)].map((_, i) => {
      return formatDuration(i * this.args.divisionLength, false, false);
    });
  }

  get inlineStyle(): SafeString {
    const { length, pixelsPerSecond } = this.args;

    return htmlSafe(`width: ${length * pixelsPerSecond}px; --ruler-division-width: ${this.divisionWidth}px;`);
  }
}
