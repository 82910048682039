import Model, { attr } from "@ember-data/model";

export default class PasswordUpdateRequest extends Model {
  @attr("string")
  declare newPassword: string;

  @attr("string")
  declare currentPassword: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    passwordUpdateRequest: PasswordUpdateRequest;
  }
}
