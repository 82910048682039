import { service } from "@ember/service";
import { attr } from "@ember-data/model";
import type Store from "@ember-data/store";
import type { ImageConfig, VideoConfig } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type { FavoriteMetadata } from "./favorable";
import { favorable } from "./favorable";
import SelectableMediaAsset from "./selectable-media-asset";
import type { EventRegister, Media, Scene, Timeline } from "client/lib/editor-domain-model";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export interface Attribution {
  author: string;
  authorUrl: string;
  provider: string;
}

enum MediaTypes {
  VIDEO = "video",
  IMAGE = "image"
}

@favorable
export default class StockAsset extends SelectableMediaAsset {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @attr("string")
  mediaType!: MediaTypes;

  @attr("string")
  provider!: string;

  @attr("string")
  providerAssetId!: string;

  @attr("string")
  description?: string;

  @attr("number")
  duration?: number;

  @attr("string")
  thumbnailUrl?: string;

  @attr("string")
  previewUrl?: string;

  @attr("string")
  lowResUrl?: string;

  @attr("json")
  attribution?: Attribution;

  get favorableProviderAssetId(): string {
    return this.providerAssetId;
  }

  @attr("string")
  favorableType!: string;

  get thumbImageUrl(): string | undefined {
    return this.thumbnailUrl;
  }

  get thumbVideoUrl(): string | undefined {
    return this.previewUrl;
  }

  @service
  declare store: Store;

  get zymbolCategory(): ZymbolCategory {
    return this.mediaType === MediaTypes.VIDEO ? ZymbolCategory.VIDEO : ZymbolCategory.IMAGE;
  }

  asZymbolConfig(): Partial<VideoConfig | ImageConfig> {
    throw Error("A StockAsset's zymbol config cannot be used directly. You must use the underlying asset's config");
  }

  async trackAddToTimeline(timeline: Timeline, params = {}): Promise<void> {
    try {
      const asset = await this.getProviderAsset();

      if (asset) {
        // @ts-expect-error
        asset.setProperties(this.getPropertiesForAsset());

        await asset.trackAddToTimeline(timeline, params);
      }
    } catch (err) {
      this.notifications.error("There was a problem adding a new scene to your project");
      // @ts-expect-error
      this.honeybadger.notify(`Could not retrieve asset for StockAsset: ${this.id}`, err);
    }
  }

  async addToTimeline(timeline: Timeline, eventRegister: EventRegister, order?: number): Promise<Scene> {
    const asset = await this.getProviderAsset();

    return await asset.addToTimeline(timeline, eventRegister, order);
  }

  async addToMedia(scene: Scene, media: Media, eventRegister: EventRegister): Promise<void> {
    const asset = await this.getProviderAsset();

    await asset.addToMedia(scene, media, eventRegister);
  }

  private getProviderAsset(): Promise<SelectableMediaAsset> {
    return this.store.findRecord(this.getModelNameForProvider(), this.providerAssetId);
  }

  getPropertiesForAsset(): { searchId?: FavoriteMetadata } {
    if (this.provider === "shutterstock") {
      return {
        searchId: this.metadataForFavorite["searchId"]
      };
    } else {
      return {};
    }
  }

  private getModelNameForProvider(): string {
    switch (this.provider) {
      case "shutterstock": {
        return "shutterstockFootage";
      }
      case "unsplash": {
        return "unsplashPhoto";
      }
      case "storyblocks": {
        return "zymbolFootage";
      }
      case "pexels": {
        return this.mediaType === MediaTypes.VIDEO ? "pexelsVideo" : "pexelsPhoto";
      }
      default: {
        throw Error(`No known model exists for this provider: ${this.provider}`);
      }
    }
  }

  readonly trackUsageEvent = undefined;

  metadataForFavorite: FavoriteMetadata = {};
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    stockAsset: StockAsset;
  }
}
