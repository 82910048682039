import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { SafeString } from "handlebars";
import type { TimeSlice } from "client/lib/editor-domain-model";
import { ResizeHandles } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { VideoTrimCalculation } from "client/lib/video-trim/calculation";
import type MouseService from "client/services/mouse";
import { MouseEvents } from "client/services/mouse";

interface Args {
  trim: TimeSlice;
  onChange: ({ startOffset, duration }: Pick<TimeSlice, "startOffset" | "duration">) => void;
  captionDuration: number;
  assetDuration?: number;
  onDragEnd: () => Promise<void>;
}

export default class AdvancedTimingLayerResizerComponent extends Component<Args> {
  @service
  declare mouse: MouseService;

  @tracked
  trackWidth = 0;

  styleNamespace = getStyleNamespace("advanced-timing/layer/resizer");

  get startValue(): string {
    return `${this.startTime.toFixed(1)}s`;
  }

  get endValue(): string {
    return `${this.endTime.toFixed(1)}s`;
  }

  get startTime(): number {
    return this.args.trim.startOffset;
  }

  get duration(): number {
    return this.args.trim.duration;
  }

  @action
  didInsertTrackElement(element: HTMLElement): void {
    const resizeObserver = new ResizeObserver(() => (this.trackWidth = element.clientWidth));
    resizeObserver.observe(element);
  }

  originalTrim = this.args.trim;

  @action
  async onDragStart(): Promise<void> {
    this.originalTrim = this.args.trim;
  }

  @action
  async onDragEnd(): Promise<void> {
    this.unsubscribeFromDragEvents();
    void this.args.onDragEnd();
  }

  @action
  async onHandleDrag(handle: ResizeHandles, delta: number): Promise<void> {
    const calculation = new VideoTrimCalculation({
      duration: this.originalTrim.duration,
      startTime: this.originalTrim.startOffset,
      maxEndTime: this.args.captionDuration
    });

    switch (handle) {
      case ResizeHandles.START: {
        calculation.applyStartTimeDelta(delta);
        break;
      }
      case ResizeHandles.END: {
        calculation.applyDurationDelta(delta);
        break;
      }
    }

    this.trim(calculation);
  }

  get endTime(): number {
    return this.startTime + this.duration;
  }

  get trimStyles(): SafeString {
    const x = (this.startTime / this.args.captionDuration) * 100;
    const w = (this.duration / this.args.captionDuration) * 100;

    return htmlSafe(`left: ${x}%; width: ${w}%`);
  }

  get warningStyles(): SafeString {
    const x = this.args.assetDuration ? (this.args.assetDuration / this.duration) * 100 : 100;

    return htmlSafe(`left: ${x}%`);
  }

  get pixelsPerSecond(): number {
    return this.trackWidth / this.args.captionDuration;
  }

  private trim({ startTime: startOffset, duration }: { startTime: number; duration: number }): void {
    this.args.onChange({ startOffset, duration });
  }

  get inlineStyle(): SafeString {
    return htmlSafe(`left:${this.left.toFixed(2)}px; width:${this.width.toFixed(2)}px;`);
  }

  get width(): number {
    return this.duration * this.pixelsPerSecond;
  }

  get left(): number {
    return (this.startTime ?? 0) * this.pixelsPerSecond;
  }

  @action
  onMouseUp(event: MouseEvent): void {
    this.mouse.onMouseUp(event);
    this.unsubscribeFromDragEvents();
  }

  @action
  onBarDrag(event: MouseEvent): void {
    if (!this.mouse.mouseDownX) {
      return;
    }

    const calculation = new VideoTrimCalculation({
      duration: this.originalTrim.duration,
      startTime: this.originalTrim.startOffset,
      maxEndTime: this.args.captionDuration
    });

    const mouseDelta = (event.pageX - this.mouse.mouseDownX) / this.pixelsPerSecond;

    calculation.applyMoveDelta(mouseDelta);

    this.trim(calculation);
  }

  isSubscribedToDragEvents = false;

  @action
  subscribeToDragEvents(): void {
    this.mouse.subscribe(MouseEvents.DRAG_START, this.onDragStart);
    this.mouse.subscribe(MouseEvents.DRAG, this.onBarDrag);
    this.mouse.subscribe(MouseEvents.DRAG_END, this.onDragEnd);
    this.isSubscribedToDragEvents = true;
  }

  @action
  unsubscribeFromDragEvents(): void {
    if (!this.isSubscribedToDragEvents) {
      return;
    }

    this.mouse.unsubscribe(MouseEvents.DRAG_START, this.onDragStart);
    this.mouse.unsubscribe(MouseEvents.DRAG, this.onBarDrag);
    this.mouse.unsubscribe(MouseEvents.DRAG_END, this.onDragEnd);
    this.isSubscribedToDragEvents = false;
  }
}
