import { attr } from "@ember-data/model";
import { makeCORSUrl } from "renderer-engine";
import Audible from "./audible";

export default class AudioTrack extends Audible {
  @attr("string")
  declare previewUrl: string;

  @attr("string")
  declare fullPreviewUrl: string;

  @attr("string")
  declare filestackUrl: string;

  @attr("boolean")
  declare isRecent: boolean;

  @attr("boolean")
  declare shared: boolean;

  @attr("boolean")
  declare premium: boolean;

  @attr("number")
  declare duration: number;

  @attr("number", { allowNull: true })
  declare userId: number | null;

  get url(): string {
    return this.playbackUrl;
  }

  get playbackUrl(): string {
    return this.fullPreviewUrl || this.previewUrl;
  }

  get isBiteableTrack(): boolean {
    return !this.userId;
  }

  get cdnUrl(): string {
    return makeCORSUrl(this.previewUrl);
  }

  @attr("string")
  declare fileSignedId?: string;

  type: string = AudioTrack.modelName.toString();
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    audioTrack: AudioTrack;
  }
}
