import { action } from "@ember/object";
import { later } from "@ember/runloop";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { isPresent } from "client/lib/is-present";

interface NorthStarThumbnailArgs {
  image: string;
  video?: string;
  aspectRatio: string;
  credit?: string;
  creditUrl?: string;
  loadingMessage?: string;
  label?: string;
  preload?: "none" | "auto";
  disabled?: boolean;
  draggable?: boolean;
  size?: string;
  shouldInferAspectRatio?: boolean;
  onRemove?: () => void;
  onSelect?: () => void;
}

const DEFAULT_ASPECT_RATIO = "16:9";

export default class NorthStarThumbnailComponent extends Component<NorthStarThumbnailArgs> {
  styleNamespace = getStyleNamespace("north-star/thumbnail");

  videoElement?: HTMLVideoElement;

  @tracked
  playProgress = 0;

  @tracked
  imageLoading = false;

  @tracked
  videoLoading = false;

  @tracked
  videoLoaded = false;

  @tracked
  progress = 0;

  @tracked
  playing = false;

  @tracked
  showSceneAddedOverlay = false;

  @tracked
  sceneAddedOverlayIsClosing = false;

  @action
  handleClick(): void {
    this.args.onSelect?.();

    this.triggerSceneAddedOverlay();
  }

  @action
  triggerSceneAddedOverlay(): void {
    this.showSceneAddedOverlay = true;

    later(() => {
      if (this.showSceneAddedOverlay) {
        this.sceneAddedOverlayIsClosing = true;
        later(() => {
          this.showSceneAddedOverlay = false;
          this.sceneAddedOverlayIsClosing = false;
        }, 200);
      }
    }, 3000);
  }

  @action
  stopPropagation(ev: MouseEvent): void {
    ev.stopPropagation();
  }

  @action
  handleMouseEnter(): void {
    if (this.videoElement && this.hasVideo && this.isPreloadNone && !this.videoLoaded) {
      this.videoLoading = true;
      this.videoElement.autoplay = true;
      this.videoElement.load();
    } else {
      void this.play();
    }
  }

  @action
  handleMouseLeave(): void {
    this.pause();
  }

  @action
  handleRemove(ev: MouseEvent): void {
    ev.stopPropagation();
    ev.preventDefault();
    this.args.onRemove?.();
  }

  @action
  handleVideoDidInsert(element: HTMLVideoElement): void {
    this.videoElement = element;

    if (this.playing) {
      void this.play();
    }
  }

  @action
  handleTimeUpdate(ev: Event): void {
    const videoElement = ev.target as HTMLVideoElement;

    if (this.isDestroyed || this.isDestroying) {
      return;
    }

    if (this.videoLoading) {
      this.videoLoading = false;
      this.videoLoaded = true;
    }

    this.progress = videoElement.currentTime / videoElement.duration;
  }

  @action
  handleImageLoad(): void {
    this.imageLoading = false;
  }

  @action
  handleImageError(ev: Event): void {
    (ev.target as HTMLImageElement).src = "";
  }

  @action
  didInsert(): void {
    if (this.hasImage) {
      this.imageLoading = true;
    }

    if (this.hasVideo && !this.isPreloadNone) {
      this.videoLoading = true;
    }
  }

  @action
  handlePlay(): void {
    this.playing = true;
  }

  @action
  handlePause(): void {
    this.playing = false;
  }

  get selectable(): (() => void) | undefined {
    return this.args.onSelect;
  }

  get hasImage(): boolean {
    return !!this.args.image;
  }

  get draggable(): string {
    if (isPresent(this.args.draggable)) {
      return this.args.draggable.toString();
    }
    return "auto";
  }

  get hasVideo(): boolean {
    return !!this.args.video && !this.args.disabled;
  }

  get videoPreload(): string {
    if (this.args.preload) {
      return this.args.preload;
    } else {
      return this.hasImage ? "none" : "metadata";
    }
  }

  get isPreloadNone(): boolean {
    return this.videoPreload === "none";
  }

  get isLoading(): boolean {
    return this.videoLoading || this.imageLoading;
  }

  get aspectRatio(): string {
    if (this.args.shouldInferAspectRatio && !this.imageLoading) {
      return "auto";
    }

    return this.args.aspectRatio ?? DEFAULT_ASPECT_RATIO;
  }

  get progressStyles(): SafeString {
    return htmlSafe(`transform: scaleX(${this.progress})`);
  }

  get isPlaying(): boolean {
    return this.playing && this.hasVideo && !this.args.disabled;
  }

  get canRemove(): boolean {
    return !!this.args.onRemove;
  }

  async play(): Promise<void> {
    try {
      await this.videoElement?.play();
    } catch (e) {
      this.videoLoaded = false;
    }
  }

  pause(): void {
    if (this.videoElement) {
      this.videoElement.pause();
      this.videoElement.currentTime = 0;
    }
  }
}
