import type { AudioClip, AudioAsset } from "../../audio/audio-clip";
import type { Timeline } from "../../timeline";
import { AddAudioClipMutation } from "./add-audio-clip-mutation";

export class AddMusicMutation extends AddAudioClipMutation {
  constructor(public timeline: Timeline, public asset: AudioAsset) {
    super(timeline, asset, "music");
  }

  run(): AudioClip | undefined {
    if (this.musicAudioClipExists) {
      return;
    }

    return super.run();
  }

  get musicAudioClipExists(): boolean {
    return !!this.timeline.audioClips.find((ac) => ac.category === "music");
  }
}
