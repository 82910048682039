import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { ResetRouteModel } from "./route";
import type { ValidationResult } from "client/lib/validation";
import { validatePassword } from "client/lib/validation";
import type JsonApiError from "client/models/json-api-error";
import type User from "client/models/user";
import type SessionService from "client/services/-private/session";
import type AjaxService from "client/services/ajax";
import type { IdentifiablePayload } from "client/services/ajax";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export default class ResetController extends Controller<ResetRouteModel> {
  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare session: SessionService;

  @service
  declare store: Store;

  @service
  declare router: RouterService;

  @tracked
  submitting = false;

  get passwordValidation(): ValidationResult {
    return validatePassword(this.model);
  }

  get disabled(): boolean {
    const { isValid } = this.passwordValidation;

    return this.submitting || !isValid;
  }

  @action
  handleSubmit(ev: Event): void {
    ev.preventDefault();
    void this.submit();
  }

  async submit(): Promise<void> {
    if (this.disabled) {
      return;
    }

    this.submitting = true;

    try {
      const user = await this.updatePassword();

      await this.session.authenticate("authenticator:biteable", user.email, this.model.password);

      this.notifications.success("Your password has been updated");

      void this.router.transitionTo("authenticated.home");
    } catch (err) {
      const errorMessage = (err as JsonApiError).errors?.[0]?.detail;

      if (errorMessage) {
        this.notifications.error(errorMessage);
      }

      // @ts-expect-error
      this.honeybadger.notify(err, {
        name: "Password reset error"
      });
    } finally {
      this.submitting = false;
    }
  }

  async updatePassword(): Promise<User> {
    const { id, email, password } = this.model;

    const res = (await this.ajax.api("/passwords/update", {
      method: "POST",
      body: new URLSearchParams({ id, email, password })
    })) as IdentifiablePayload;
    this.store.pushPayload(res);

    return this.store.peekRecord("user", res.data.id)!;
  }
}
