import Model, { attr, belongsTo } from "@ember-data/model";
import type User from "./user";

export enum RecordProjectTypes {
  SELF = "self",
  COLLABORATIVE = "collaborative"
}

export const RECORD_ASSET_DELETE_FAILURE_NOTIFICATION = "Inconceivable! There was a problem deleting your recording";

export default class RecordProject extends Model {
  @attr("string")
  declare title: string;

  @attr("string")
  declare jwt: string;

  @attr("string")
  declare recordType: RecordProjectTypes;

  @attr("string")
  declare description: string;

  @attr("string")
  declare timeFrame: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("user", { async: true, inverse: null })
  declare user: User;

  static generateRecordProjectName(recordProjectType: RecordProjectTypes = RecordProjectTypes.SELF): string {
    return `My ${recordProjectType === RecordProjectTypes.COLLABORATIVE ? "request" : "recording"} ${new Date()
      .toISOString()
      .slice(0, 10)}`;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    recordProject: RecordProject;
  }
}
