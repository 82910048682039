import { service } from "@ember/service";
import JSONAPIAdapter from "@ember-data/adapter/json-api";
import type Store from "@ember-data/store";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from "ember-data";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";
import type RSVP from "rsvp";
import { resolve } from "rsvp";
import AdapterError = DS.AdapterError;
import config from "client/config/environment";
import type AjaxHeadersService from "client/services/ajax-headers";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

const HTTP_ERROR_HANDLERS: Array<{
  matches: (code: number) => boolean;
  message: string;
}> = [
  {
    matches: (code: number) => [429].includes(code),
    message: "Slow down, take a deep breath, you are sending too many requests to the Biteable servers"
  },
  {
    matches: (code: number) => code >= 500,
    message: "There was a problem on the Biteable servers"
  }
];

type Payload = Error | Record<string, unknown> | unknown[] | string | undefined;

export default class ApplicationAdapter extends JSONAPIAdapter {
  host = config.apiUrl;

  @service
  declare ajaxHeaders: AjaxHeadersService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  httpErrorHandlers = HTTP_ERROR_HANDLERS;

  get headers(): { [key: string]: string } {
    return this.ajaxHeaders.headers;
  }

  updateRecord(store: Store, type: ModelSchema, snapshot: DS.Snapshot<string | number>): RSVP.Promise<any> {
    const result = super.updateRecord(store, type, snapshot);

    if ((snapshot?.adapterOptions as any)?.ignoreResponse) {
      return resolve(undefined);
    } else {
      return result;
    }
  }

  // @ts-expect-error
  handleResponse(
    status: number,
    headers: Record<string, unknown>,
    payload: Payload,
    requestData: {}
  ): Payload | AdapterError {
    if (headers["x-maintenance-mode"] === "true") {
      window.location.href = "/503.html";
    }

    const errorHandler = this.httpErrorHandlers.find(({ matches }) => matches(status));

    if (errorHandler) {
      this.notifications.error(errorHandler.message);
    }

    // @ts-expect-error
    if (payload?.errors) {
      this.honeybadger.setContext({
        // @ts-expect-error
        errors: payload.errors,
        requestData
      });
    }

    // @ts-expect-error
    const response: Payload | AdapterError = super.handleResponse(status, headers, payload, requestData);
    // 204 no content has an empty response which causes an error
    if (response) {
      response["status"] = status;
    }
    return response;
  }

  shouldBackgroundReloadRecord(): boolean {
    return false;
  }
}
