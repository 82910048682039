import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { EventRegister, Media } from "client/lib/editor-domain-model";
import { Svg } from "client/lib/editor-domain-model";
import { ChangeSvgColorMutation } from "client/lib/editor-domain-model/events/mutations/change-svg-color-mutation";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";

interface Args {
  media: Media;
  eventRegister: EventRegister;
  brandStyle: BrandStyle;
  save: () => void;
}

export default class ToolbarSvgColorsComponent extends Component<Args> {
  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("toolbar/svg-colors");

  @action
  saveColor(color: string): void {
    const { eventRegister } = this.args;

    if (this.asset) {
      eventRegister.fire(new ChangeSvgColorMutation(this.asset, color));
    }

    this.args.save();
  }

  get asset(): Svg | undefined {
    if (this.args.media.asset instanceof Svg) {
      return this.args.media.asset;
    }

    return;
  }

  get tooltip(): string {
    return "Primary";
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get svgColors(): string[] {
    if (this.asset?.color) {
      return [this.asset?.color];
    } else {
      return [];
    }
  }

  get brandStyleConfig(): BrandStyleConfig | undefined {
    return this.brand?.style;
  }
}
