import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { TemplateRouteParams } from "client/authenticated/template/route";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import BrandApplier from "client/lib/brand-applier";
import { TimelineFactory } from "client/lib/editor-domain-model";
import { SimpleRumTimer } from "client/lib/rum-timer";
import { EmberTimelineBuilder } from "client/lib/timeline/ember-timeline-builder";
import type Project from "client/models/project";
import type AjaxService from "client/services/ajax";
import type LayersService from "client/services/layers";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type ProjectScenesService from "client/services/project-scenes";
import type ProjectsService from "client/services/projects";
import type TrackingService from "client/services/tracking";
import type UndoService from "client/services/undo";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export default class TemplateCopyRoute extends Route {
  @service
  declare projectScenes: ProjectScenesService;

  @service
  declare layers: LayersService;

  @service
  declare zymbolGroups: ZymbolGroupsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare projects: ProjectsService;

  @service
  declare tracking: TrackingService;

  @service
  declare permissions: PermissionsService;

  @service
  declare undo: UndoService;

  @service
  private declare ajax: AjaxService;

  @service
  private declare notifications: NotificationsService;

  /* eslint-disable camelcase */
  queryParams = {
    // Use the utm_source to track where the template import is coming from (eg. `www` or `app`)
    utm_source: {},
    category: {},
    category_type: {},
    query: {}
  };

  /* eslint-enable camelcase */

  async model({
    utm_source: source = "app",
    category,
    category_type, // eslint-disable-line camelcase
    query
  }: {
    [key: string]: string;
  }): Promise<Project | undefined> {
    const { projectId } = this.paramsFor("authenticated.template") as TemplateRouteParams;

    let timer;
    if (projectId === String(config.newBrandProjectId)) {
      timer = new SimpleRumTimer("starter-project-copy");
    }

    const project = this.modelFor("authenticated.template") as Project;

    let projectCopy;
    try {
      projectCopy = await this.projects.copyProject(project);

      const notification = project.template ? "Your video is ready to edit" : "Your video has been copied";

      this.notifications.success(notification);

      if (project.template) {
        if (this.permissions.has("feature_brand_apply") && project.templateBrandable) {
          await this.applyBrandToProject(projectCopy);
        }
        // eslint-disable-next-line camelcase
        this.trackTemplateUsage(project, projectCopy, { source, category, category_type, query });
      }
    } catch (err) {
      // This is catching a rethrow from copyProject that has already been handled
    }

    if (timer) {
      timer.sample();
    }

    return projectCopy;
  }

  private async applyBrandToProject(project: Project): Promise<void> {
    const timelineFactory = new TimelineFactory(
      new EmberTimelineBuilder(
        this.ajax,
        this.notifications,
        this.store,
        this.projects,
        this.projectScenes,
        this.layers,
        this.zymbolGroups,
        this.router,
        project
      )
    );
    this.undo.eventRegister = timelineFactory.eventRegister;

    const timeline = await timelineFactory.buildTimeline(project.id);
    const eventRegister = timelineFactory.eventRegister;

    const brand = await project.getBrandStyle();

    if (brand) {
      await brand.style.assetsLoaded;

      const brandApplier = new BrandApplier({ eventRegister });

      for (const scene of timeline.scenes) {
        brandApplier.applyBrandToScene(scene, brand.style);
      }

      await eventRegister.save(timeline);
    }
  }

  async afterModel(project: Project | undefined): Promise<void> {
    if (project === undefined) {
      await this.router.replaceWith("authenticated.templates");
    } else {
      await this.router.replaceWith("authenticated.project", project.id);
    }
  }

  trackTemplateUsage(template: Project, copy: Project, eventProperties = {}): void {
    /* eslint-disable camelcase */
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEMPLATE_COPIED, {
      template_id: template.id,
      project_id: copy.id,
      template_name: template.title,
      ...eventProperties
    });
    /* eslint-enable camelcase */
  }
}
