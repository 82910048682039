import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type HoneybadgerService from "./honeybadger";
import type Favorable from "client/models/favorable";
import type Favorite from "client/models/favorite";

export default class FavoritesService extends Service {
  @service
  declare store: Store;

  @service
  declare honeybadger: HoneybadgerService;

  private favoritesLoaded?: Promise<void>;

  public find(favorable?: Favorable): Favorite | undefined {
    if (!favorable) {
      return undefined;
    }

    const favorites = this.store.peekAll("favorite");
    const favorite = favorites.find((f) => {
      return (
        f.favorableProviderAssetId === favorable.favorableProviderAssetId && f.favorableType === favorable.favorableType
      );
    });

    if (favorite && !favorite.isDeleted) {
      return favorite;
    } else {
      return undefined;
    }
  }

  public findFavorite(favoriteId: number | string): Promise<Favorite | undefined> {
    return this.store.findRecord("favorite", favoriteId);
  }

  async loadAndFind(favorable?: Favorable): Promise<Favorite | undefined> {
    await this.peekOrLoad();
    return this.find(favorable);
  }

  private async load(): Promise<void> {
    try {
      let page = 1;
      let hasNextPage = false;

      do {
        hasNextPage = await this.loadPage(page);
        page++;
      } while (hasNextPage);
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify("Failed to load favorites", err);
    }
  }

  private async loadPage(page: number, perPage = 100): Promise<boolean> {
    // eslint-disable-next-line camelcase
    const favorites = await this.store.query("favorite", {
      per_page: perPage, // eslint-disable-line camelcase
      page
    });

    // @ts-expect-error
    return !!favorites.links["next"];
  }

  public async peekOrLoad(): Promise<void> {
    if (!this.favoritesLoaded) {
      this.favoritesLoaded = this.load();
    }

    await this.favoritesLoaded;
  }
}
