import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum UIPaginationJustify {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right"
}

interface UIPaginationArgs {
  adjacentPages: number;
  justify: UIPaginationJustify;
  page: number;
  perPage: number;
  routeName: string;
  total: number;
}

export default class UIPaginationComponent extends Component<UIPaginationArgs> {
  styleNamespace = getStyleNamespace("ui/ui-pagination");

  get justify(): UIPaginationJustify {
    return this.args.justify ?? UIPaginationJustify.CENTER;
  }

  /**
   * The number of pages adjacent to the current page to display. Should generally be an even
   * number so that the pages are placed evenly either side of the current page
   * @property
   */
  get adjacentPages(): number {
    return this.args.adjacentPages ?? 2;
  }

  get totalPages(): number {
    if (!this.args.total || !this.args.perPage) {
      return 0;
    }
    return Math.ceil(Number(this.args.total) / Number(this.args.perPage));
  }

  get pages(): number[] {
    const { totalPages, adjacentPages } = this;
    const page = this.args.page;
    const offset = Math.floor(adjacentPages / 2);

    let start = Math.max(1, page - offset);
    let end = Math.min(page + offset, totalPages);

    // If we couldn't add all the `adjacentPages` to the right because we hit the `pageLimit` then we
    // should add more links to the left to make up for it.
    if (end < page + offset) {
      start = Math.max(1, start - (page + offset - end));
    }

    // Likewise, if we couldn't add all the pages to the left then we should try to add more pages to
    // the right
    if (start > page - offset) {
      end = Math.min(totalPages, end + adjacentPages + (start - page - offset));
    }

    return Array.from(Array(end + 1 - start)).map((_, index: number) => start + index);
  }

  get first(): number | undefined {
    const [first] = this.pages;

    if (first && first > 1) {
      return 1;
    }

    return undefined;
  }

  get firstEllipsis(): boolean {
    const [firstPagesPage] = this.pages;
    return !!this.first && !!firstPagesPage && Math.abs(this.first - firstPagesPage) > 1;
  }

  get last(): number | undefined {
    const { totalPages, pages } = this;
    const [last] = [...pages].reverse();

    if (last && last < totalPages) {
      return totalPages;
    }

    return undefined;
  }

  get lastEllipsis(): boolean {
    const lastPagesPage = [...this.pages].pop();
    return !!this.last && !!lastPagesPage && Math.abs(this.last - lastPagesPage) > 1;
  }
}
