import { action } from "@ember/object";
import { debounce } from "@ember/runloop";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Element, EventRegister, Image, Scene } from "client/lib/editor-domain-model";
import { ChangeImageOpacityMutation } from "client/lib/editor-domain-model";
import { CoalescingTransactionManager } from "client/lib/editor-domain-model/events/coalescing-transaction-manager";
import getStyleNamespace from "client/lib/get-style-namespace";
import { saveElement } from "client/lib/timeline/element";

const COMMIT_DELAY_MS = 1000;

interface Args {
  eventRegister: EventRegister;
  image: Image;
  element: Element;
  scene?: Scene;
}

export default class ToolbarOpacityComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/opacity");

  @tracked
  targetElement?: HTMLElement;

  @action
  setupTarget(element: HTMLElement): void {
    this.targetElement = element;
  }

  get value(): number {
    return this.args.image.opacity ?? 1;
  }

  get formattedValue(): string | undefined {
    return `${Math.round(this.value * 100)}%`;
  }

  private manager = new CoalescingTransactionManager(this.args.eventRegister);

  @action
  async onOpacityChange(opacity: number): Promise<void> {
    const { eventRegister, image, element, scene } = this.args;

    await this.manager.transaction(async () => {
      eventRegister.fire(new ChangeImageOpacityMutation(image, opacity));
      await saveElement(eventRegister, element, scene, COMMIT_DELAY_MS);
    });

    debounce(this.manager, this.manager.new, COMMIT_DELAY_MS);
  }
}
