import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NotificationsService from "client/services/notifications";
import type { Prompt } from "client/services/prompts";

interface PromptDialogArgs {
  prompt: Prompt;
  onSubmit?: (value: string | undefined) => void;
}

export default class PromptDialogComponent extends Component<PromptDialogArgs> {
  @service
  declare notifications: NotificationsService;

  styleNamespace = getStyleNamespace("prompt-dialog");

  @action
  handleSubmit(ev: Event): void {
    ev.preventDefault();
    this.submit();
  }

  @action
  handleClose(): void {
    this.close();
  }

  @action
  handleDidInsert(): void {
    window.addEventListener("keydown", this.onDocumentKeydown);
  }

  @action
  onDocumentKeydown(ev: KeyboardEvent): void {
    if (ev.key === "Escape") {
      this.handleClose();
    }
  }

  @action
  handleBackdropClick(ev: MouseEvent): void {
    if (ev.target instanceof HTMLFormElement) {
      this.close();
    }
  }

  submit(): void {
    if (this.args.prompt.required && this.isEmptyValue) {
      this.notifications.error("Please enter a value to continue");
    } else {
      this.args.onSubmit?.(this.value);
    }
  }

  close(): void {
    this.args.onSubmit?.(undefined);
  }

  willDestroy(): void {
    super.willDestroy();
    window.removeEventListener("keydown", this.onDocumentKeydown);
  }

  value = "";

  get inputId(): string {
    return `${guidFor(this)}__Input`;
  }

  get isEmptyValue(): boolean {
    return !this.value.trim();
  }

  get inputLabel(): string {
    return this.args.prompt?.inputLabel ?? this.args.prompt.title;
  }

  get placeholder(): string {
    return this.args.prompt.inputPlaceholder ?? "Please enter a response";
  }

  get helpText(): string {
    return this.placeholder;
  }

  get submitLabel(): string {
    return this.args.prompt.submitLabel ?? "Submit";
  }
}
