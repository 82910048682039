import Model, { attr, belongsTo } from "@ember-data/model";
import type Project from "client/models/project";

export default class ProjectShare extends Model {
  @attr("string")
  declare projectId: string;

  @belongsTo("project", { async: true, inverse: "projectShare" })
  project?: Project;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectShare: ProjectShare;
  }
}
