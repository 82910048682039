import { setOwner } from "@ember/application";
import type Owner from "@ember/owner";
import type { ZymbolCategory, ImageConfig } from "renderer-engine";
import { AnimationFit } from "renderer-engine";
import ZymbolConfigFactory from "../scene/zymbol-config-factory";
import type TrackingEvents from "client/events";
import type BrandLogo from "client/lib/brand/brand-logo";
import SelectableMediaAsset from "client/models/selectable-media-asset";
import type UserAsset from "client/models/user-asset";

export default class SelectableBrandLogo extends SelectableMediaAsset {
  constructor(owner: Owner, public brandLogo: BrandLogo, private userAsset: UserAsset) {
    super();

    // Set the owner so that services can be injected
    setOwner(this, owner);
  }

  readonly thumbImageUrl = undefined;

  readonly thumbVideoUrl = undefined;

  get trackUsageEvent(): TrackingEvents | undefined {
    return this.userAsset.trackUsageEvent;
  }

  protected _thumbAspectRatio = "1:1";

  get trackUsageData(): {} {
    return this.userAsset.trackUsageData;
  }

  protected asZymbolConfig(): Partial<ImageConfig> {
    const imageConfig = this.userAsset.asImageConfig();

    const imageMod = new ZymbolConfigFactory().createImageMod(this.brandLogo.image.frame);

    imageConfig.animationFit = imageMod ? AnimationFit.FIT : AnimationFit.FILL;
    imageConfig.imageMod = imageMod;

    return imageConfig;
  }

  protected get zymbolCategory(): ZymbolCategory {
    return this.userAsset.zymbolCategory;
  }
}
