import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export enum SceneLibraryTypes {
  IMAGES = "images",
  VIDEOS = "videos",
  SHUTTERSTOCK = "shutterstock",
  PEXELS = "pexels",
  PEXELS_VIDEOS = "pexels-videos"
}

export default class ProjectScenesLibraryController extends Controller {
  @tracked
  library?: SceneLibraryTypes;

  @tracked
  query?: string;

  routeName = "authenticated.project.scenes.library";

  @service
  declare router: RouterService;

  @action
  search(query: string): void {
    void this.router.transitionTo(this.searchRouteName, { queryParams: { query } });
  }

  get searchPlaceholder(): string {
    if (this.library === SceneLibraryTypes.VIDEOS) {
      return "Search videos";
    } else {
      return `Search images`;
    }
  }

  get pexelsRouteName(): string {
    if (this.query) {
      return "authenticated.project.scenes.library.pexels.search";
    } else {
      return this.routeName + ".pexels";
    }
  }

  get videosRouteName(): string {
    if (this.query) {
      return "authenticated.project.scenes.library.videos.search";
    } else {
      return this.routeName + ".videos";
    }
  }

  get searchRouteName(): string {
    if (this.library === SceneLibraryTypes.VIDEOS) {
      return "authenticated.project.scenes.library.videos.search";
    } else {
      return "authenticated.project.scenes.library.pexels.search";
    }
  }
}
