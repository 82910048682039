import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Sag from "client/models/sag";
import type SelectableAsset from "client/models/selectable-asset";
import type PermissionsService from "client/services/permissions";

interface Args {
  searchKey: string;
  selectAsset(asset: SelectableAsset): void;
  exitSearch(): void;
}

export default class GraphicsSearchPanel extends Component<Args & ProjectContentBarPanelArgs> {
  @tracked
  searchKey = this.args.searchKey;

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  styleNamespace = getStyleNamespace("project-content-bar/panels/graphics/search-panel");

  @action
  search(key: string): void {
    if (key) {
      this.searchKey = key;
    } else {
      this.args.exitSearch();
    }
  }

  get params(): Record<string, string | number | boolean | undefined> {
    return {
      /* eslint-disable camelcase */
      search: this.searchKey,
      per_page: 24
      /* eslint-enable camelcase */
    };
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<SelectableAsset>> {
    // @ts-expect-error
    return this.store.query("sag", params) as Sag[];
  }
}
