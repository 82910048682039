const isNull = (value: unknown): boolean => {
  // eslint-disable-next-line no-null/no-null
  return value === null || value === undefined;
};

export default (object: Record<string, unknown>): Record<string, unknown> => {
  const dup: Record<string, unknown> = {};

  for (const key in object) {
    const value = object[key];

    if (!isNull(value)) {
      dup[key] = value;
    }
  }

  return dup;
};
