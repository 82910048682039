import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

export default class VideoOverlayUpgradeRequired extends Component {
  @service
  declare auth: AuthService;

  @service
  declare plans: PlansService;

  @service
  declare upgrade: UpgradeService;

  @service
  private declare permissions: PermissionsService;

  styleNamespace = getStyleNamespace("export/video-overlay/upgrade-required");

  get headerText(): string {
    if (this.canManageSubscription) {
      return "Pick a plan to finish your video";
    } else if (this.permissions.has("feature_edit_only_mode_notifications")) {
      return "Upgrade to publish your video";
    } else {
      return "The Publisher role is required to build your video";
    }
  }

  get editorRole(): boolean {
    return this.permissions.has("feature_editor_role_notifications");
  }

  get teamOwnerEmail(): string | undefined {
    return this.auth.currentTeam?.ownerEmail;
  }

  get topTierPlan(): Plan | undefined {
    return this.plans.getTopTierPlan();
  }

  get middleTierPlan(): Plan | undefined {
    return this.plans.getMiddleTierPlan();
  }

  get canManageSubscription(): boolean {
    return this.auth.canManageSubscription;
  }

  @action
  async openUpgradeModal(): Promise<void> {
    await this.upgrade.open();
  }
}
