import Model, { attr } from "@ember-data/model";

export enum PlanFeatureCodes {
  DOWNLOADS = "downloads",
  RESELLER_RIGHTS = "reseller_rights",
  PROJECTS = "projects",
  WATERMARK_DOWNLOADS = "watermark_downloads",
  FREE_SEATS = "free_seats",
  PREMIUM_CONTENT = "premium_content",
  BASIC_CONTENT = "basic_content",
  COMMERCIAL_RIGHTS = "commercial_rights",
  FOOTAGE_UPLOADS = "footage_uploads",
  HD = "hd",
  PRIVACY = "privacy",
  SUPPORT = "support",
  CUSTOM_WATERMARK = "watermark",
  TEAMS = "teams",
  COLLABORATION = "collaboration",
  UNLIMITED_VIDEO_EXPORTS = "unlimited_video_exports",
  ANIMATIONS = "animations",
  CLIPS = "clips",
  IMAGES = "images",
  MUSIC = "music",
  NO_BITEABLE_BRANDING = "no_biteable_branding",
  SEATS_INCLUDED = "seats_included",
  VIDEO_CREATION_ASSISTANCE = "video_creation_assistance",
  ALL_ULTIMATE_FEATURES = "all_ultimate_features",
  SHUTTERSTOCK_CREDITS = "shutterstock_credits",
  VOICEOVER_SERVICE = "voiceover_service",
  DEDICATED_ACCOUNT_SUPPORT = "dedicated_account_support",
  UPLOADS_WITH_AUDIO = "uploads_with_audio",
  PROJECT_ANALYTICS = "project_analytics_engagement",
  CALL_TO_ACTION = "call_to_action"
}

export default class PlanFeature extends Model {
  @attr("string")
  declare name: string;

  @attr("string")
  declare description: string;

  @attr("boolean")
  declare newFeature: boolean;

  @attr("string")
  declare featureCode: string;

  @attr("number")
  declare displayOrder: number;

  @attr("string")
  declare category: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    planFeature: PlanFeature;
  }
}
