import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { AudioAsset } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioTrack from "client/models/audio-track";
import type AudioTrackGenre from "client/models/audio-track-genre";
import type AudioTrackService from "client/services/audio-tracks";
import type HoneybadgerService from "client/services/honeybadger";

interface PublicAudioTrackListArgs {
  selectedTrack?: AudioTrack;
  onSelect: (audioAsset: AudioAsset) => Promise<void>;
  onCancel: () => void;
  onSave: () => Promise<void>;
}

interface SortOrder {
  label: string;
  value: string;
}

export default class PublicAudioTrackListComponent extends Component<PublicAudioTrackListArgs> {
  @service
  declare audioTracks: AudioTrackService;

  @service
  declare honeybadger: HoneybadgerService;

  @tracked
  genres: AudioTrackGenre[] = [];

  @tracked
  genreFilters: string[] = ["all"];

  @tracked
  tracks: AudioTrack[] = [];

  @tracked
  loading = false;

  @tracked
  loadingComplete = false;

  @tracked
  page = 1;

  perPage = 50;

  scrollTarget?: HTMLElement;

  defaultSortOrder: SortOrder = {
    label: "Long-Short",
    value: "desc"
  };

  sortOrders: SortOrder[] = [
    { label: "Name", value: "name" },
    this.defaultSortOrder,
    { label: "Short-Long", value: "asc" }
  ];

  @tracked
  sortBy: SortOrder = this.defaultSortOrder;

  styleNamespace = getStyleNamespace("public-audio-track-list");

  @action
  async fetchTracks(): Promise<void> {
    if (this.audioTracks.selectedGenres) {
      this.genreFilters = this.audioTracks.selectedGenres;
    }
    if (this.audioTracks.selectedSortOrder) {
      this.sortBy =
        this.sortOrders.find((sortOrder) => sortOrder.value === this.audioTracks.selectedSortOrder) ||
        this.defaultSortOrder;
    }
    const result = await this.audioTracks.getPublicAudioTracks(this.genreFilters);
    this.tracks = result.models;
  }

  @action
  async fetchGenres(): Promise<void> {
    this.genres = await this.audioTracks.fetchGenres();
  }

  @action
  async updateGenreFilters(genre: string): Promise<void> {
    this.restartPagination();
    if (genre === "all") {
      this.genreFilters = ["all"];
    } else {
      if (this.genreFilters.includes("all")) {
        this.genreFilters.splice(this.genreFilters.indexOf("all"), 1);
      }
      if (this.genreFilters.includes(genre)) {
        this.genreFilters.splice(this.genreFilters.indexOf(genre), 1);
        if (this.genreFilters.length === 0) {
          this.genreFilters = ["all"];
        }
      } else {
        this.genreFilters.push(genre);
      }
    }

    this.audioTracks.selectedGenres = this.genreFilters;
    // eslint-disable-next-line no-self-assign
    this.genreFilters = this.genreFilters;
    await this.applyViewFilters();
  }

  @action
  async updateSortBy(sortBy: string): Promise<void> {
    this.restartPagination();
    this.sortBy = this.sortOrders.find((sortOrder) => sortOrder.value === sortBy) || this.defaultSortOrder;
    this.audioTracks.selectedSortOrder = this.sortBy.value;
    await this.applyViewFilters();
  }

  @action
  async loadNextPage(): Promise<void> {
    this.page += 1;
    await this.applyViewFilters();
  }

  @action
  setScrollTarget(element: HTMLElement): void {
    this.scrollTarget = element;
  }

  async applyViewFilters(): Promise<void> {
    try {
      this.loading = true;
      const result = await this.audioTracks.getPublicAudioTracks(this.genreFilters, this.sortBy.value, {
        page: this.page,
        per_page: this.perPage // eslint-disable-line camelcase
      });

      if (this.page === 1) {
        this.tracks = result.models;
      } else {
        this.tracks = this.tracks.concat(result.models);
      }

      this.loadingComplete = this.page * this.perPage >= result.meta.totalCount;
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, {
        name: "GetPublicAudioTracks",
        context: { genres: this.genreFilters, sortBy: this.sortBy.value }
      });
      this.loadingComplete = true;
    } finally {
      this.loading = false;
    }
  }

  restartPagination(): void {
    this.page = 1;
    this.loadingComplete = false;
    if (this.scrollTarget) {
      this.scrollTarget.scrollTop = 0;
    }
  }
}
