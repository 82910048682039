import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { Caption, Scene } from "client/lib/editor-domain-model";
import type { EditableText } from "client/lib/text/text-editor";
import type PermissionsService from "client/services/permissions";

const STORAGE_KEY = "biteable:smartEditorIsActive";

export type SelectableObject = Scene | Caption | EditableText;

export default class SmartEditorService extends Service {
  @service
  declare permissions: PermissionsService;

  @tracked
  activeObject?: SelectableObject;

  @tracked
  private _isActive: boolean;

  constructor(properties?: object) {
    super(properties);

    this._isActive = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? "false");
  }

  get activeScene(): Scene | undefined {
    if (this.activeObject instanceof Scene) {
      return this.activeObject;
    } else if (this.activeObject instanceof Caption) {
      return this.activeObject.scene;
    } else if (this.activeObject?.caption) {
      return this.activeObject.caption.scene;
    }

    return undefined;
  }

  activate(): void {
    this._isActive = true;
    this.save();
  }

  deactivate(): void {
    this._isActive = false;
    this.save();
  }

  isActive(): boolean {
    return this._isActive;
  }

  isActiveScene(scene: Scene): boolean {
    if (!!this.activeObject && "scene" in this.activeObject) {
      return this.activeObject?.scene === scene;
    }
    if (!!this.activeObject && "caption" in this.activeObject) {
      return this.activeObject.caption.scene === scene;
    }
    return this.activeObject === scene;
  }

  isActiveCaption(caption: Caption): boolean {
    if (!!this.activeObject && "scene" in this.activeObject) {
      return this.activeObject === caption;
    }
    if (!!this.activeObject && "caption" in this.activeObject) {
      return this.activeObject.caption === caption;
    }
    return this.activeObject === caption.scene;
  }

  getNearestCaption(): Scene | Caption | undefined {
    const currentActiveObject = this.activeObject;
    let nextActiveObject;

    if (currentActiveObject instanceof Caption) {
      const captions = currentActiveObject.scene.captions;
      const captionIndex = captions.findIndex((caption) => caption.id === currentActiveObject.id);

      if (captionIndex + 1 < currentActiveObject.scene.captions.length) {
        nextActiveObject = captions[captionIndex + 1];
      } else {
        nextActiveObject = captions[captionIndex - 1];
      }
    } else if (currentActiveObject instanceof Scene) {
      nextActiveObject = currentActiveObject;
    }

    return nextActiveObject;
  }

  activeAsEditableText(): EditableText | undefined {
    if (this.activeObject && "caption" in this.activeObject && "applyStyle" in this.activeObject) {
      return this.activeObject;
    }
    return undefined;
  }

  activeAsCaption(): Caption | undefined {
    if (this.activeObject instanceof Caption) {
      return this.activeObject;
    }

    return this.activeAsEditableText()?.caption;
  }

  activeAsScene(): Scene | undefined {
    if (this.activeObject instanceof Scene) {
      return this.activeObject;
    }
    return this.activeAsCaption()?.scene;
  }

  findCaption(object: SelectableObject): Caption | undefined {
    if (object instanceof Scene && object.captions.length > 0) {
      return object.captions[0];
    } else if (object instanceof Caption) {
      return object;
    } else {
      return;
    }
  }

  private save(): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this._isActive));
  }
}
