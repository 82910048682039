import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { GOOGLE_FONTS } from "renderer-engine";
import { TrackedMap } from "tracked-built-ins";
import type { EventRegister, FontInfo, Scene, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getWeightAndStyleAsVariant } from "client/lib/text/fonts";
import type { EditableText } from "client/lib/text/text-editor";
import type AdvancedEditorService from "client/services/advanced-editor";
import type ProjectContentBarService from "client/services/project-content-bar";
import { ContentBarPanelType } from "client/services/project-content-bar";
import type ScriptWorkstationService from "client/services/script-workstation";

interface Args {
  eventRegister: EventRegister;
  timeline: Timeline;
}

export default class ScriptWorkstationComponent extends Component<Args> {
  sceneStyle = (scene: Scene): SafeString => {
    const order = this.args.timeline.sceneOrder(scene);
    return htmlSafe(`order: ${order}`);
  };

  @service
  declare scriptWorkstation: ScriptWorkstationService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare projectContentBar: ProjectContentBarService;

  styleNamespace = getStyleNamespace("script-workstation");

  editables = new TrackedMap<string, EditableText>();

  get fonts(): FontInfo[] {
    return [
      ...this.args.timeline.customFonts,
      ...GOOGLE_FONTS.map(({ family, variants, subsets }) => ({
        name: family,
        variants: variants.map(getWeightAndStyleAsVariant),
        subsets
      }))
    ];
  }

  @action
  onClick(ev: MouseEvent): void {
    ev.stopPropagation();
    this.projectContentBar.addMediaToNewScene = true;
    this.projectContentBar.show(ContentBarPanelType.SCENES);
  }

  @action
  async didInsert(): Promise<void> {
    const scene = this.advancedEditor.scene;
    if (scene) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }

  get scenes(): Scene[] {
    return this.args.timeline.scenes;
  }
}
