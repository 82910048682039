import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";
import type BrandStyleService from "client/services/brand-style";
import type UpgradeService from "client/services/upgrade";

interface BrandStyleSelectArgs {
  brandStyleId?: string;
  onUpdate: (id: string) => void;
  onCancel: () => void;
}

export default class BrandStyleSelectComponent extends Component<BrandStyleSelectArgs> {
  @service
  private declare brandStyle: BrandStyleService;

  @service
  private declare router: RouterService;

  @service
  private declare upgrade: UpgradeService;

  @tracked
  selectedBrandStyle?: BrandStyle;

  @tracked
  loading = true;

  @tracked
  brandStyles: BrandStyle[] = [];

  styleNamespace = getStyleNamespace("project/brand-style-select");

  @action
  async didInsert(): Promise<void> {
    this.loading = true;
    try {
      this.brandStyles = await this.brandStyle.getBrands();
      const checkedId = this.args.brandStyleId ?? this.brandStyle.defaultBrandId;

      if (checkedId) {
        this.selectedBrandStyle = this.brandStyles.find((brand) => brand.id === checkedId);
      } else {
        this.selectedBrandStyle = this.brandStyles[0];
      }

      if (this.selectedBrandStyle) {
        this.args.onUpdate(this.selectedBrandStyle.id);
      }
    } finally {
      this.loading = false;
    }
  }

  @action
  selectBrand(brandStyle: BrandStyle): void {
    this.selectedBrandStyle = brandStyle;
    this.args.onUpdate(this.selectedBrandStyle.id);
  }

  @action
  addBrand(): void {
    this.args.onCancel();
    void this.router.transitionTo("authenticated.brand.new", {
      queryParams: { returnPath: this.router.currentURL }
    });
  }

  @action
  upgradePlan(): void {
    this.args.onCancel();
    void this.upgrade.selectTopTierPlan({ context: "video settings - select brand" });
  }

  get anyBrand(): boolean {
    return this.brandStyles.length > 0;
  }
}
