import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Team from "client/models/team";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

export interface AccountTeamModel {
  team?: Team;
  user: User;
}

export default class AccountTeamRoute extends Route<AccountTeamModel> {
  @service
  declare auth: AuthService;

  @service
  declare store: Store;

  async model(): Promise<AccountTeamModel> {
    const user = this.modelFor("authenticated.account") as User;
    const team = await this.getTeam(user);

    return {
      team,
      user
    };
  }

  private async getTeam(user: User): Promise<Team | undefined> {
    if (user.onTeam && user.belongsTo("team").value()) {
      return user.belongsTo("team").value() as Team;
    } else {
      return undefined;
    }
  }
}
