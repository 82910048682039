import type { ImageConfig, SagConfig, SagColorOption, SvgConfig, VideoConfig, ZymbolConfigType } from "renderer-engine";
import { sagColorOptions, SagLayerMode, ZymbolCategory } from "renderer-engine";
import { imageConfigToImage, imageModToFrame } from "../timeline/image";
import * as DomainModel from "client/lib/editor-domain-model";
import { toColorBrandKey } from "client/lib/editor-domain-model";
import type Zymbol from "client/models/zymbol";

type ClipConfig = SagConfig | VideoConfig;

const getVideoTrimFromConfig = (config: ClipConfig): DomainModel.TimeSlice | undefined => {
  const { startTime, duration } = config;

  if (startTime !== undefined && duration !== undefined) {
    return new DomainModel.TimeSlice(startTime, duration);
  }

  return;
};

export default class AssetFactory {
  createAssetFromZymbol(zymbol: Zymbol): DomainModel.Asset | undefined {
    const { category } = zymbol;

    return this.createAssetFromConfig(zymbol.cfg?.[category], category);
  }

  createAssetFromConfig(config: ZymbolConfigType | undefined, category: ZymbolCategory): DomainModel.Asset | undefined {
    if (config) {
      if (category === ZymbolCategory.SAG) {
        return this.createAnimation(config as SagConfig);
      } else if (category === ZymbolCategory.VIDEO) {
        return this.createVideo(config as VideoConfig);
      } else if (category === ZymbolCategory.IMAGE) {
        return this.createImage(config as ImageConfig);
      } else if (category === ZymbolCategory.SVG) {
        return this.createSvg(config as SvgConfig);
      }
    }

    return undefined;
  }

  private createAnimation(config: SagConfig): DomainModel.Animation {
    const trim = getVideoTrimFromConfig(config);

    const {
      invertX,
      animationFit,
      uid,
      loopable,
      horizontalFlipping,
      sizingOptions,
      colorOptions,
      previewVideoUrl,
      previewImageUrl,
      originalSagId,
      asset,
      animation,
      playbackSpeed
    } = config;

    return new DomainModel.Animation(
      this.createAnimatedLayers(config),
      invertX,
      animationFit,
      uid,
      loopable,
      horizontalFlipping,
      sizingOptions,
      colorOptions,
      animation,
      playbackSpeed,
      trim,
      previewVideoUrl,
      previewImageUrl,
      originalSagId,
      asset
    );
  }

  private createVideo(config: VideoConfig): DomainModel.VideoClip {
    const trim = getVideoTrimFromConfig(config);

    const {
      hasAudio,
      mute,
      fadeIn,
      fadeOut,
      audioDuckingLevel,
      name,
      url,
      previewImageUrl,
      previewVideoUrl,
      lowResVideoUrl,
      downsizedUrlBase,
      encodingLevel,
      volume,
      invertX,
      animationFit,
      animation,
      loopable,
      asset,
      license
    } = config;

    return new DomainModel.VideoClip(
      { name, url, previewImageUrl, previewVideoUrl, lowResVideoUrl, downsizedUrlBase, encodingLevel },
      !!hasAudio,
      loopable,
      animation,
      { mute: !!mute, fadeIn: !!fadeIn, fadeOut: !!fadeOut, volume, audioDuckingLevel },
      trim,
      invertX,
      animationFit,
      asset,
      license,
      imageModToFrame(config?.imageMod)
    );
  }

  private createImage(config: ImageConfig): DomainModel.Image {
    return imageConfigToImage(config);
  }

  private createSvg(config: SvgConfig): DomainModel.Svg {
    const { animation, asset, name, url, previewImageUrl, opacity, color } = config;

    return new DomainModel.Svg(animation, name, url, previewImageUrl, opacity, color, asset);
  }

  private createAnimatedLayers(config: SagConfig): DomainModel.AnimatedLayer[] {
    const colorOptions = sagColorOptions(config);
    return config.layers.map((l, i) => {
      const layerMode = config.layerModes?.[i] ?? SagLayerMode.NONE;
      const colorBrandKey = toColorBrandKey(config.animationColorBrandKeys?.[i]);

      const colorOption = colorOptions.find((co) => co.targetLayers.includes(i + 1));

      const color = colorOption?.defaultColor || "#FFFFFF";
      const name = colorOption?.label || "";
      const role = colorOption?.colorRole || this.getDefaultColorRole(colorOptions, colorOption);

      const animationColor = new DomainModel.AnimationColor(name, role, color, colorBrandKey);
      return new DomainModel.AnimatedLayer(l.srcUrl, l.url, layerMode, animationColor);
    });
  }

  private getDefaultColorRole(colorOptions?: SagColorOption[], colorOption?: SagColorOption): string {
    // if any of the layers have a color option then dont use the default for the others
    if (colorOptions?.find((co) => co.colorRole)) {
      return "";
    }

    const index = colorOption && colorOptions?.indexOf(colorOption);
    switch (index) {
      case 0:
        return "Primary";
      case 1:
        return "Secondary";
      default:
        return "";
    }
  }
}
