import type { SceneState, CaptionStateKey } from "./scene-state";

export const calculatePositions = (
  originalState: SceneState,
  update: (movableCaptions: MovableCaption[]) => number
): SceneState => {
  const { captionsState } = originalState;
  const captions = Object.keys(captionsState).map(
    (id) => new MovableCaption(captionsState[id]!.duration, captionsState[id]!.offset, id)
  );
  captions
    .sort((a, b) => a.offset - b.offset)
    .forEach((c, i) => {
      c.prev = captions[i - 1];
      c.next = captions[i + 1];
    });

  const newSceneDuration = update(captions);
  const newCaptionsState: CaptionStateKey = {};

  for (const caption of captions) {
    newCaptionsState[caption.id] = {
      duration: caption.duration,
      offset: caption.offset
    };
  }

  return {
    sceneDuration: newSceneDuration,
    captionsState: newCaptionsState
  };
};

export class MovableCaption {
  next?: MovableCaption;
  prev?: MovableCaption;

  constructor(public duration: number, public offset: number, public id: string) {}

  get end(): number {
    return this.offset + this.duration;
  }

  set end(value: number) {
    this.offset = value - this.duration;
  }

  get middle(): number {
    return this.offset + this.duration / 2;
  }

  get isSingleCaption(): boolean {
    return !this.prev && !this.next;
  }

  newSceneDuration(sceneDuration: number, existingSceneDuration: number): number {
    sceneDuration = Math.max(0.5, sceneDuration);

    if (this.end <= sceneDuration) {
      return this.captionAlreadyFits(sceneDuration, existingSceneDuration);
    }

    if (this.offset + 0.5 <= sceneDuration) {
      return this.shrinkCaptiopn(sceneDuration);
    }

    this.duration = 0.5;

    if (!this.prev || this.prev.end <= sceneDuration - this.duration) {
      return this.shiftCaption(sceneDuration);
    }

    return this.shiftPrevCaption(sceneDuration, existingSceneDuration);
  }

  moveCaptionRightToFit(caption: MovableCaption, moveAll: boolean): number | undefined {
    if (caption.offset > this.end && (!moveAll || caption.offset < this.offset)) {
      return this.next?.moveCaptionRightToFit(caption, moveAll) || caption.end;
    }

    this.offset = Math.max(this.offset, caption.end);
    return this.next?.moveCaptionRightToFit(this, moveAll) || this.end;
  }

  updateOffset(offset: number, existingSceneDuration: number): number {
    offset = Math.max(0, offset);

    if (offset === this.offset) {
      return existingSceneDuration;
    }

    if (this.isSingleCaption) {
      this.offset = offset;
      return Math.max(this.end, existingSceneDuration);
    }

    // moving to the right
    if (offset > this.offset) {
      if (this.next) {
        const newEnd = offset + this.duration;
        if (newEnd <= this.next.offset) {
          this.offset = offset;
        } else if (newEnd <= this.next.middle) {
          this.end = this.next.offset;
        } else {
          this.offset = Math.max(offset, this.next.end);
          return Math.max(this.end, this.next.next?.moveCaptionRightToFit(this, false) || 0, existingSceneDuration);
        }
      } else {
        this.offset = offset;
      }
    } else {
      if (this.prev) {
        this.moveDownTo(offset, this.prev);
      } else {
        this.offset = offset;
      }
    }

    return Math.max(this.end, existingSceneDuration);
  }

  private captionAlreadyFits(sceneDuration: number, existingSceneDuration: number): number {
    const durationDiff = sceneDuration - existingSceneDuration;

    if (this.isSingleCaption && durationDiff > 0) {
      this.duration += durationDiff;
    }
    return sceneDuration;
  }

  private shrinkCaptiopn(sceneDuration: number): number {
    this.duration = sceneDuration - this.offset;
    return this.end;
  }

  private shiftCaption(sceneDuration: number): number {
    this.offset = sceneDuration - this.duration;
    return this.end;
  }

  private shiftPrevCaption(sceneDuration: number, existingSceneDuration: number): number {
    if (this.prev) {
      this.offset = this.prev.newSceneDuration(sceneDuration - 0.5, existingSceneDuration);
    }
    return this.end;
  }

  private moveDownTo(offset: number, previous: MovableCaption): void {
    if (offset >= previous.end) {
      this.offset = offset;
    } else if (offset > previous.middle) {
      this.offset = previous.end;
    } else if (previous.prev && offset <= previous.prev.middle) {
      return this.moveDownTo(offset, previous.prev);
    } else {
      this.end = previous.offset;
      this.offset = Math.max(previous.prev?.end || 0, this.offset);

      this.moveCaptionInFrontOf(previous);
    }
  }

  /**
   * Moves this caption to be just ahead of `previous` in the linked list then updates
   * all of the offsets to be correct
   */
  private moveCaptionInFrontOf(previous: MovableCaption): void {
    if (this.prev) {
      this.prev.next = this.next;
    }

    if (this.next) {
      this.next.prev = this.prev;
    }

    this.prev = previous.prev;
    if (this.prev) {
      this.prev.next = this;
    }
    this.next = previous;
    previous.prev = this;

    this.next.moveCaptionRightToFit(this, true);
  }
}
