import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { AvaUseCases } from "client/authenticated/survey/data";
import type { DescriptionDetails } from "client/authenticated/survey/video-assistant/description/route";
import type { TrackingProperties } from "client/components/survey/nav/component";
import type VideoAssistantPromptField from "client/lib/scripting/video-assistant-prompt-field";
import type StorageService from "client/services/storage";
import type VideoAssistantService from "client/services/video-assistant";

export default class VideoAssistantDescriptionController extends Controller {
  queryParams = ["referer", "generatorId"];

  @tracked
  referer?: string;

  @tracked
  generatorId?: string;

  @tracked
  loading = false;

  @service
  declare router: RouterService;

  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare storage: StorageService;

  @action
  async select(job: string): Promise<void> {
    this.videoAssistant.job = job;
    this.generatorId = undefined;
  }

  @action
  async back(): Promise<void> {
    await this.router.transitionTo("authenticated.survey.video-assistant.use-case", {
      queryParams: { referer: this.referer, generatorId: this.generatorId }
    });
  }

  @action
  async continue(): Promise<void> {
    this.loading = true;
    try {
      if (this.videoAssistantFormFields.every((field) => field.isValid && !!field.value)) {
        this.videoAssistant.fields = this.videoAssistantFormFields;

        this.cacheUserInputs();

        await this.router.transitionTo("authenticated.survey.video-assistant.style", {
          queryParams: { referer: this.referer, generatorId: this.generatorId }
        });
      }
    } finally {
      this.loading = false;
    }
  }

  get header(): string {
    if (this.selectedUseCase === AvaUseCases.Template) {
      return "Make a video";
    } else {
      return `${this.selectedJob} ${this.selectedUseCase}`;
    }
  }

  get selectedUseCase(): string | undefined {
    return this.videoAssistant.useCase;
  }

  get selectedJob(): string | undefined {
    return this.videoAssistant.job;
  }

  get videoAssistantFormFields(): VideoAssistantPromptField[] {
    return this.model.descriptionDetails.find((details: DescriptionDetails) => details.job === this.selectedJob)
      .videoAssistantFormFields;
  }

  get showJobSelection(): boolean {
    return this.model.descriptionDetails.length > 1;
  }

  get trackingProperties(): TrackingProperties {
    return { ...this.inputsToCache, job: this.selectedJob, referer: this.referer };
  }

  private cacheUserInputs(): void {
    this.storage.setItem(this.cacheKey, JSON.stringify(this.inputsToCache));
  }

  private get inputsToCache(): { [key: string]: string | undefined } {
    return this.videoAssistantFormFields.reduce((map, f) => {
      if (f.input?.key) {
        map[f.input.key] = f.value;
      }
      return map;
    }, {} as { [key: string]: string | undefined });
  }

  private get cacheKey(): string {
    return `${this.videoAssistant.useCase}__${this.videoAssistant.job}`;
  }
}
