import { action } from "@ember/object";
import Component from "@glimmer/component";
import { Crisp } from "crisp-sdk-web";

export default class CustomerSupportLinkComponent extends Component {
  @action
  contactSupport(): void {
    Crisp.chat.show();
    Crisp.chat.open();
  }
}
