import type Owner from "@ember/owner";
import EmberRouter from "@ember/routing/router";
import { scheduleOnce } from "@ember/runloop";
import config from "client/config/environment";
import AnalyticsService from "client/services/analytics";

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  constructor(owner: Owner) {
    super(owner);
    this.on("routeDidChange", (_transition) => {
      scheduleOnce("afterRender", this, AnalyticsService.page.bind(AnalyticsService));
    });
  }
}

Router.map(function () {
  this.route("delete-account");
  this.route("optout");

  this.route("unauthenticated", { path: "/" }, function () {
    this.route("forgot");
    this.route("login");
    this.route("reset");
    this.route("sign-up", { path: "/signup" });
    this.route("north-star");
  });

  // Need to find a way to unshadow the above
  // eslint-disable-next-line ember/no-shadow-route-definition
  this.route("authenticated", { path: "" }, function () {
    this.route("logout");

    this.route("account", function () {
      this.route("profile", function () {
        this.route("update-password");
        this.route("delete-account");
      });
      this.route("subscription", function () {
        this.route("invoices", function () {
          this.route("invoice", { path: "/:chargeId" });
        });
        this.route("payment");

        this.route("reactivate", { path: "/reactivate/:planId" });

        this.route("cancel", function () {
          this.route("warning");
          this.route("confirmation");
        });
      });
      this.route("team");
      this.route("communications");
    });

    this.route("asset-library", function () {
      this.route("library", { path: "/:type" });
      this.route("folder", { path: "/folder/:folderId" });

      this.route("record-requests", function () {
        this.route("record", { path: "/:recordProjectToken" });
      });

      this.route("my-recordings");
    });

    this.route("brand", function () {
      this.route("new", function () {
        this.route("import");
        this.route("upload");
      });
      this.route("edit", { path: "/:brandId" }, function () {
        this.route("import");
        this.route("upload");
      });
    });

    this.route("edit-only-mode");

    this.route("folders", function () {
      this.route("library", { path: "/:type" });
      this.route("folder", { path: "/folder/:folderId" });
    });

    this.route("home", function () {
      this.route("team");
    });

    this.route("inactive-team-member-mode", { path: "/inactive" });

    this.route("plans", function () {
      this.route("plan", { path: "/:planId" });
    });

    this.route("project", { path: "/project/:projectId" }, function () {
      this.route("copy");
      this.route("import-favorite", { path: "/import-favorite/:favoriteId" });
      this.route("import-scene", { path: "/import-scene/:sceneId" });
      this.route("scene", { path: "/scene/:sceneId" }, function () {
        this.route("background", function () {
          this.route("frame");
          this.route("trim");
        });

        this.route("caption", { path: "/caption/:zymbolGroupId" }, function () {
          this.route("text", { path: "/text/:zymbolId" });
          this.route("logo", { path: "/logo/:zymbolId" }, function () {
            this.route("frame");
            this.route("trim");
          });
          this.route("background");
          this.route("watermark");
        });

        this.route("voiceover", function () {
          this.route("select");
        });

        this.route("soundtrack");

        this.route("watermark", function () {
          this.route("frame");
          this.route("trim");
        });
      });
      this.route("scenes", function () {
        this.route("categories", function () {
          this.route("category", { path: "/:categorySlug" });
        });

        this.route("library", function () {
          this.route("videos", function () {
            this.route("search");
          });
          this.route("images", function () {
            this.route("search");
          });
          this.route("pexels", function () {
            this.route("search");
          });
        });

        this.route("favorites", function () {
          this.route("my", function () {
            this.route("current");
            this.route("all");
          });
          this.route("team", function () {
            this.route("all");
          });
        });

        this.route("shutterstock", function () {
          this.route("clips");
          this.route("library", function () {
            this.route("search");
          });
        });

        this.route("assets", function () {
          this.route("library");
        });

        this.route("record", function () {
          this.route("requests", function () {
            this.route("record", { path: "/:recordProjectToken" });
          });
          this.route("my-recordings");
        });

        this.route("animations");
      });
      this.route("settings");
      this.route("smart");
    });

    this.route("publish", { path: "/publish/:projectId" }, function () {
      this.route("endscreen");
    });

    this.route("survey", function () {
      this.route("video-assistant", function () {
        this.route("use-case");
        this.route("description");
        this.route("style");
      });
      this.route("onboarding");
      this.route("decide");
    });

    this.route("template", { path: "/template/:projectId" }, function () {
      this.route("copy");
    });

    this.route("templates", function () {
      this.route("category", { path: "/:category" });
      this.route("popular");
    });

    this.route("trial", function () {
      // For some reason the way trial routes are setup the below is needed to get the
      // Get Started button to render into the outlet of the route in the modal
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.route("start", function () {});
    });

    this.route("video-assistant", function () {
      this.route("populate-template", { path: "/template/:projectId" });
    });

    this.route("discovery", function () {
      this.route("content");
    });
  });

  this.route("not-found", { path: "/*" });
});
