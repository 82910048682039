import { tracked } from "@glimmer/tracking";
import type { VoiceoverSelectRouteModel } from "./route";
import InfiniteController from "client/controllers/infinite";

export default class VoiceoverSelectController extends InfiniteController<VoiceoverSelectRouteModel> {
  queryParams = ["addedAt", "replacedClipId"];

  @tracked
  addedAt?: string | undefined;

  @tracked
  replacedClipId?: string | undefined;

  // eslint-disable-next-line camelcase
  per_page = 16;
}
