import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectTemplate from "client/models/project-template";
import type TrackingService from "client/services/tracking";

interface TemplatePreviewThumbnailArgs {
  projectTemplate: ProjectTemplate;
}

export default class TemplatePreviewThumbnailComponent extends Component<TemplatePreviewThumbnailArgs> {
  @service
  declare tracking: TrackingService;

  video?: HTMLVideoElement;

  @tracked
  buffering = false;

  @tracked
  hover = false;

  @tracked
  playing = false;

  @tracked
  currentTime = 0;

  @tracked
  duration = 0;

  private previewed = false;

  styleNamespace = getStyleNamespace("discovery/template-preview/thumbnail");

  @action
  setVideoElement(videoElement: HTMLVideoElement): void {
    this.video = videoElement;
  }

  @action
  load(): void {
    if (this.video) {
      this.video.preload = "metadata";
    }
  }

  @action
  onMouseover(): void {
    this.hover = true;
    this.buffering = true;

    if (this.video) {
      this.video.muted = true;
      void this.video.play();
    }
  }

  @action
  onMouseleave(): void {
    this.hover = false;

    this.video?.pause();
  }

  @action
  onPlay(): void {
    this.playing = true;
  }

  @action
  onEnded(): void {
    this.onPause();
  }

  @action
  onTimeupdate({ target }: Event): void {
    this.previewed = true;

    if (target instanceof HTMLVideoElement) {
      const { duration, currentTime } = target;

      this.buffering = false;
      this.currentTime = currentTime;
      this.duration = duration;
    }
  }

  @action
  onPause(): void {
    this.playing = false;

    this.trackPreview();
  }

  @action
  onCanplay(): void {
    this.buffering = false;
  }

  willDestroy(): void {
    super.willDestroy();

    this.trackPreview();
  }

  private trackPreview(): void {
    const { currentTime, duration, previewed } = this;

    if (previewed && currentTime > 0 && duration > 0) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEMPLATE_PREVIEW, {
        currentTime,
        duration,
        ...this.args.projectTemplate.eventProperties
      });
    }
  }

  get progress(): number {
    return this.currentTime / this.duration;
  }

  get progressWidth(): string {
    return `${this.progress * 100}%`;
  }

  get posterUrl(): string {
    return this.args.projectTemplate.lowResThumbUrl;
  }

  get videoUrl(): string {
    return this.args.projectTemplate.lowResUrl;
  }

  get progressStyle(): SafeString {
    return htmlSafe(`width: ${this.progress * 100}%`);
  }

  get backdropStyles(): SafeString {
    return htmlSafe(`background-image: url(${this.posterUrl}`);
  }
}
