import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import type InfiniteController from "client/controllers/infinite";

export interface InfiniteModel<M = any> {
  results: M[];
}

export default abstract class InfiniteRoute<M extends InfiniteModel = InfiniteModel> extends Route<M> {
  get routeController(): InfiniteController<M> {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    return this.controllerFor(this.routeName);
  }

  abstract model(params?: object): M | Promise<M>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  abstract loadResults(params?: Record<string, string>): Promise<Array<any>>;

  @action
  loading(transition: Transition): boolean {
    const controller = this.routeController;

    if (!controller) {
      return true;
    }

    controller.set("currentlyLoading", true);
    transition.promise.finally(() => {
      controller.set("currentlyLoading", false);
    });
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeModel(_transition: Transition): void {
    if (this.routeController) {
      this.routeController.page = 1;
    }
  }

  setupController(controller: InfiniteController<M>, model: M, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.dataScroll = this;
  }

  resetController(controller: InfiniteController<M>, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.page = 1;
    }
  }
}
