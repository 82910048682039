import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import type ProjectTemplateController from "./controller";

export default class TemplatesRoute extends Route {
  queryParams = {
    search: {
      refreshModel: true
    },
    aspectRatio: {
      refreshModel: true
    },
    branded: {
      refreshModel: true
    }
  };

  resetController(controller: ProjectTemplateController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    controller.resetFilters();
  }
}
