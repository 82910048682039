import type { RecordProject } from "@biteable/network-model";
import Network from "@biteable/network-model";
import { service } from "@ember/service";
import { tracked, TrackedArray } from "tracked-built-ins";
import { RecordProjectTypes } from "client/models/record-project";
import type { InfiniteModel } from "client/routes/infinite";
import InfiniteRoute from "client/routes/infinite";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

export default class AssetLibraryRecordRequestsRoute extends InfiniteRoute<InfiniteModel> {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @tracked
  results = new TrackedArray<RecordProject>([]);

  async model(): Promise<InfiniteModel<RecordProject>> {
    const results = await this.loadResults();
    this.results = new TrackedArray<RecordProject>(results);
    return { results: this.results };
  }

  async loadResults(): Promise<Array<RecordProject>> {
    const { per_page, page = 1 } = this.routeController; // eslint-disable-line camelcase
    let recordProjects = new Array<RecordProject>();

    try {
      /* eslint-disable camelcase */
      const projects = await Network.store.findAll(
        "recordProject",
        { per_page, page, record_type: RecordProjectTypes.COLLABORATIVE },
        { force: true }
      );

      if (projects.meta) {
        this.routeController.meta = projects.meta;
      }

      recordProjects = projects.data;
    } catch (error) {
      this.notifications.error("Oh no! Could not load the record projects");
      if (error instanceof Error) {
        this.honeybadger.notify(error);
      }
    }

    return recordProjects;
  }
}
