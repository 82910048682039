import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Team from "client/models/team";
import type TeamMembership from "client/models/team-membership";
import type User from "client/models/user";
import type CollaborationService from "client/services/collaboration";

interface TeamMemberListArgs {
  team: Team;
  user: User;
}

enum MemberSort {
  NAME_DESC,
  NAME_ASC,
  ROLE_DESC,
  ROLE_ASC
}

export default class TeamMemberListComponent extends Component<TeamMemberListArgs> {
  @service
  declare collaboration: CollaborationService;

  @tracked
  sortBy?: MemberSort;

  styleNamespace = getStyleNamespace("account/team/member-list");

  @action
  sortByName(): void {
    this.sortBy = this.sortByNameDesc ? MemberSort.NAME_ASC : MemberSort.NAME_DESC;
  }

  get sortByNameDesc(): boolean {
    return this.sortBy === MemberSort.NAME_DESC;
  }

  @action
  sortByRole(): void {
    this.sortBy = this.sortByRoleDesc ? MemberSort.ROLE_ASC : MemberSort.ROLE_DESC;
  }

  get sortByRoleDesc(): boolean {
    return this.sortBy === MemberSort.ROLE_DESC;
  }

  private get team(): Team {
    return this.args.team;
  }

  @action
  invitePeople(): void {
    this.collaboration.showTeamInviteModal("account - users - invite people");
  }

  get teamActiveMembers(): Array<TeamMembership> {
    if (this.sortBy) {
      return this.team.sortedActiveMemberList.sort((a: TeamMembership, b: TeamMembership) => {
        switch (this.sortBy!) {
          case MemberSort.NAME_DESC: {
            return b.nameOrEmail.localeCompare(a.nameOrEmail);
          }
          case MemberSort.NAME_ASC: {
            return a.nameOrEmail.localeCompare(b.nameOrEmail);
          }
          case MemberSort.ROLE_DESC: {
            return b.roleSeq - a.roleSeq;
          }
          case MemberSort.ROLE_ASC: {
            return a.roleSeq - b.roleSeq;
          }
        }
      });
    }

    return this.team.sortedActiveMemberList;
  }

  get teamInactiveMembers(): Array<TeamMembership> {
    return this.team?.sortedInactiveMemberList?.sort((a, b) => {
      return a.nameOrEmail.localeCompare(b.nameOrEmail);
    });
  }

  get canInviteTeamMembers(): boolean {
    return this.team.canInviteTeamMembers;
  }

  get canManageTeam(): boolean {
    return this.args.user.canManageTeam;
  }

  get numberOfInputRows(): number {
    return 1;
  }
}
