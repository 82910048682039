import Component from "@glimmer/component";
import type SelectOptionComponent from "../option/component";
import type { SelectSizes } from "client/components/north-star/select/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SelectButtonComponentArgs {
  expanded: boolean;
  size: SelectSizes;
  id: string;
  selectedOption?: SelectOptionComponent;
  onClick(event: MouseEvent): unknown;
  onKeyDown(event: KeyboardEvent): unknown;
  cloneOption?: boolean;
}

export default class SelectButtonComponent extends Component<SelectButtonComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/select/button");

  get selectedOptionElement(): HTMLElement | undefined {
    if (this.cloneOption) {
      return this.args.selectedOption?.el;
    } else {
      return undefined;
    }
  }

  get cloneOption(): boolean {
    return this.args.cloneOption ?? true;
  }
}
