import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import { attr } from "@ember-data/model";
import type Store from "@ember-data/store";
import { favorable, FAVORABLE_TYPES } from "./favorable";
import TrackingEvents from "client/events";
import type { ColorPresetJSON } from "client/lib/brand/color-preset";
import cmsUrl from "client/lib/cms-url";
import type { EventRegister, Media, Scene, Timeline } from "client/lib/editor-domain-model";
import type ProjectScene from "client/models/project-scene";
import SelectableAsset from "client/models/selectable-asset";

@favorable
class ProjectScenePreview extends SelectableAsset {
  readonly trackUsageEvent = TrackingEvents.PROJECT_SCENE_PREVIEW_ADD;

  @service
  declare store: Store;

  @attr("boolean")
  declare template: boolean;

  @attr("string", { allowNull: true })
  declare thumbnailUrl?: string;

  @attr("string")
  declare previewUrl?: string;

  @attr("array")
  declare tags?: string[];

  @attr("array")
  declare stacks?: string[];

  @attr("date")
  declare publishedAt?: Date;

  @attr("string", { allowNull: true })
  declare title?: string;

  @attr("string")
  declare aspectRatioName: string;

  @attr("string")
  declare aspectRatioSlug: string;

  @attr("json")
  declare colorPreset?: ColorPresetJSON;

  @attr("string")
  declare backgroundColorBrandKey?: string;

  @attr("boolean")
  declare brandableTemplate: boolean;

  get tagList(): string {
    return (this.tags || []).join(", ");
  }

  get previewImageUrl(): string | undefined {
    return this.thumbnailUrl;
  }

  get previewVideoUrl(): string | undefined {
    return this.previewUrl;
  }

  get thumbImageUrl(): string | undefined {
    return this.thumbnailUrl;
  }

  get thumbVideoUrl(): string | undefined {
    return this.previewUrl;
  }

  get published(): boolean {
    return !!this.publishedAt;
  }

  get isBrandable(): boolean {
    return !!this.colorPreset;
  }

  get isBrandableTemplate(): boolean {
    return this.brandableTemplate;
  }

  get cmsUrl(): string {
    return cmsUrl(getOwner(this), `/scene/${this.id}`);
  }

  async getFullAsset(): Promise<ProjectScene> {
    return await this.store.findRecord("projectScene", this.id);
  }

  async trackAddToTimeline(timeline: Timeline, params = {}): Promise<void> {
    const fullAsset = await this.getFullAsset();

    await fullAsset.trackAddToTimeline(timeline, params);
  }

  async addToTimeline(timeline: Timeline, eventRegister: EventRegister, order?: number): Promise<Scene> {
    const fullAsset = await this.getFullAsset();

    return await fullAsset.addToTimeline(timeline, eventRegister, order);
  }

  async addToMedia(scene: Scene, media: Media, eventRegister: EventRegister): Promise<void> {
    const fullAsset = await this.getFullAsset();

    await fullAsset.addToMedia(scene, media, eventRegister);
  }

  get favorableProviderAssetId(): string {
    return this.id;
  }

  readonly favorableType = FAVORABLE_TYPES.PROJECT_SCENES;

  metadataForFavorite = {};
}

export default ProjectScenePreview;

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectScenePreview: ProjectScenePreview;
  }
}
