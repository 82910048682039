import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type CurrentUserService from "./current-user";
import type StorageService from "./storage";
import { SimpleRumTimer } from "client/lib/rum-timer";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectPreview from "client/models/project-preview";

export type LatestProjectPreviewsArray = ArrayWithMeta<ProjectPreview>;

export default class LatestProjectPreviewsService extends Service {
  @service
  private declare store: Store;

  @service
  private declare storage: StorageService;

  @service
  private declare currentUser: CurrentUserService;

  private latestProjects: Promise<LatestProjectPreviewsArray> | undefined;

  async projectsCount(): Promise<number> {
    void this.peekOrLoad();
    const totalCount = (await this.latestProjects)?.meta?.["total-count"];

    return totalCount || 0;
  }

  async hasAnyProject(): Promise<boolean> {
    const totalCount = await this.projectsCount();

    return totalCount > 0;
  }

  async latestProject(): Promise<ProjectPreview | undefined> {
    void this.peekOrLoad();
    const latestProjects = (await this.latestProjects) as unknown as Array<ProjectPreview>;
    return latestProjects?.[0];
  }

  async projects(): Promise<LatestProjectPreviewsArray> {
    void this.peekOrLoad();

    return await this.latestProjects!;
  }

  load(): void {
    this.latestProjects = this.loadProjects();
  }

  dismissStateBasedActionTile(id: string, state: string): void {
    this.storage.setItem(this.getStateBasedActionKey(id, state), String(false));
  }

  showStateBasedActionTile(id: string, state: string): boolean {
    return this.storage.getItem(this.getStateBasedActionKey(id, state)) !== String(false);
  }

  private async peekOrLoad(): Promise<void> {
    if (!this.latestProjects) {
      this.load();
    }
  }

  private async loadProjects(): Promise<LatestProjectPreviewsArray> {
    const timer = new SimpleRumTimer("home-index-route-model");

    try {
      // @ts-expect-error
      const projects = (await this.store.query("projectPreview", {
        /* eslint-disable camelcase */
        page: 1,
        per_page: 12,
        team_shared: false
        /* eslint-enable camelcase */
      })) as LatestProjectPreviewsArray;

      timer.meta = {
        userId: this.currentUser.user!.id
      };
      timer.sample();

      return projects;
    } catch (error) {
      return [] as LatestProjectPreviewsArray;
    }
  }

  private getStateBasedActionKey(projectId: string, state: string): string {
    return `${projectId}::${state}`;
  }
}
