import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type ShutterstockCredits from "client/models/shutterstock-credits";
import type ShutterstockPurchases from "client/models/shutterstock-purchases";
import type VideoWatchEventSummary from "client/models/video-watch-event-summary";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import type TrackingService from "client/services/tracking";

interface ExportActionsArgs {
  analyticsLoading: boolean;
  analyticsTimePeriod: boolean;
  failedStockLicensesPurchase: boolean;
  hasEndscreen: boolean;
  isRendering: boolean;
  onChangeAnalyticsTimePeriod: (value: string) => Promise<void>;
  openAnalytics: boolean;
  project: Project;
  renderRequired: boolean;
  renderOutdated: boolean;
  renderFailed: boolean;
  renderProjectForDebug: () => Promise<void>;
  renderProject: (ctaContext: string) => Promise<void>;
  renderStarting: boolean;
  renderSucceeded: boolean;
  setFailedStockLicensesPurchase: (value: boolean) => void;
  shutterstockCredits: ShutterstockCredits;
  shutterstockPurchases?: ShutterstockPurchases;
  videoWatchEventSummary: VideoWatchEventSummary;
  projectRender?: ProjectRender;
}

export default class ExportActionsComponent extends Component<ExportActionsArgs> {
  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("export-actions");

  @action
  onAnalyticsOpen(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_OPEN_ANALYTICS, {
      project_id: this.args.project.id // eslint-disable-line camelcase
    });
  }

  @action
  async onOpenEndscreenEditor(): Promise<void> {
    await this.router.transitionTo("authenticated.publish.endscreen");
  }

  get showRenderFailed(): boolean {
    return this.args.renderFailed && this.canRenderProject;
  }

  get canRenderProject(): boolean {
    return this.permissions.has("feature_project_render");
  }

  get sharingDisabled(): boolean {
    return !this.args.renderSucceeded || !this.canRenderProject;
  }

  get denyRebuild(): boolean {
    return !!this.args.shutterstockPurchases?.denyBuild || !this.canRenderProject;
  }

  get showDebugMenu(): boolean {
    return this.permissions.has("project_render_debug");
  }

  get showRenderedVideoActions(): boolean {
    const { isRendering, renderSucceeded } = this.args;

    return isRendering || renderSucceeded;
  }

  get showShutterstockActions(): boolean {
    const { renderOutdated, shutterstockCredits, shutterstockPurchases } = this.args;

    const showCreditsSystemActions = renderOutdated && shutterstockCredits?.allowBuild;
    const shutterstockPaymentRequired = !!shutterstockPurchases?.paymentRequired;

    return this.canRenderProject && (showCreditsSystemActions || shutterstockPaymentRequired);
  }
}
