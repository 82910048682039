import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { Notification, NotificationButton } from "client/services/notifications";
import type NotificationsService from "client/services/notifications";

export default class TheNotificationsComponent extends Component {
  @service
  declare notifications: NotificationsService;

  styleNamespace = getStyleNamespace("the-notifications");

  @action
  hide(): void {
    this.notifications.hide();
  }

  get notification(): Notification | undefined {
    return this.notifications.notification;
  }

  get button(): NotificationButton | undefined {
    return this.notification?.button;
  }
}
