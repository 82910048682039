import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Shape } from "renderer-engine";
import type BrandLogo from "client/lib/brand/brand-logo";
import type ColorPreset from "client/lib/brand/color-preset";
import type { Frame } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";
import type BrandStyleService from "client/services/brand-style";

interface BrandStylePreviewArgs {
  brandStyle?: BrandStyle;
}

export default class BrandStylePreviewComponent extends Component<BrandStylePreviewArgs> {
  @service
  declare brandStyle: BrandStyleService;

  @service
  declare store: Store;

  @tracked
  brand? = this.args.brandStyle;

  styleNamespace = getStyleNamespace("brand/brand-style-preview");

  @action
  async didInsert(): Promise<void> {
    this.brand ??= await this.getBrandStyle();
  }

  @action
  async didUpdateBrand(): Promise<void> {
    this.brand = this.args.brandStyle;
  }

  @action
  getColorPreset(): ColorPreset {
    return this.generator.next().value;
  }

  async getBrandStyle(): Promise<BrandStyle | undefined> {
    return await this.brandStyle.getBrand();
  }

  get colorPresets(): Array<ColorPreset> {
    return this.brand?.style.colorPresets ?? [];
  }

  get defaultLogo(): BrandLogo | undefined {
    return this.brand?.style.defaultLogo;
  }

  get frame(): Frame | undefined {
    return this.defaultLogo?.image?.frame;
  }

  get brandLogo(): string | undefined {
    return this.defaultLogo?.image?.previewUrl;
  }

  get emptyColorPresets(): boolean {
    return !this.colorPresets.length;
  }

  get frameStyles(): SafeString | undefined {
    const { frame } = this;

    const styles = {
      borderRadius: "0",
      overflow: "hidden",
      backgroundColor: frame?.color ?? "white"
    };

    const shape = frame?.shape ?? Shape.CIRCLE;

    switch (shape) {
      case Shape.CIRCLE: {
        styles.borderRadius = "50%";
        break;
      }
      case Shape.ROUNDED_RECT: {
        styles.borderRadius = "3px";
        break;
      }
      case Shape.NONE: {
        styles.overflow = "visible";
        styles.backgroundColor = "transparent";
        break;
      }
    }

    return htmlSafe(`
      background-color: ${styles.backgroundColor};
      border-radius: ${styles.borderRadius};
      overflow: ${styles.overflow}
    `);
  }

  get logoStyles(): SafeString {
    const { frame } = this;

    const offsetX = (frame?.offsetX ?? 0) * 100;
    const offsetY = (frame?.offsetY ?? 0) * 100;

    return htmlSafe(`transform: scale(${this.frame?.scale ?? 0.82}) translate(${-offsetX}%, ${-offsetY}%)`);
  }

  private generator = function* (this: BrandStylePreviewComponent): Generator<ColorPreset, ColorPreset, unknown> {
    let i = 0;

    while (true) {
      const index = i % this.colorPresets.length;
      const colorPreset = this.colorPresets[index]!;
      i++;

      yield colorPreset;
    }
  }.call(this);
}
