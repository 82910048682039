import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import PlaybackController from "client/lib/playback/playback";
import type PlaybackService from "client/services/playback";
import type TrackingService from "client/services/tracking";

export default class ProjectTimelinePlayback extends Component<{}> {
  @service
  declare playback: PlaybackService;

  @service
  declare tracking: TrackingService;

  playbackController: PlaybackController;

  styleNamespace = getStyleNamespace("project-timeline/playback");

  constructor(owner: object, args: object) {
    super(owner, args);
    this.playbackController = new PlaybackController(this.playback, this.tracking);
  }

  get isLoading(): boolean {
    return this.playback.buffering || this.playback.readying;
  }

  @action
  async play(startTime: number): Promise<void> {
    await this.playbackController.play(startTime);
  }

  @action
  async stop(): Promise<void> {
    await this.playbackController.stop();
  }
}
