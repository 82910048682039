import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type AccountSubscriptionCancelConfirmationController from "client/authenticated/account/subscription/cancel/confirmation/controller";
import type { AccountSubscriptionCancelRouteModel } from "client/authenticated/account/subscription/cancel/route";
import type AccountSubscriptionCancelRoute from "client/authenticated/account/subscription/cancel/route";

export default class AccountSubscriptionCancelConfirmationRoute extends Route<AccountSubscriptionCancelRouteModel> {
  @service
  declare router: RouterService;

  model(): AccountSubscriptionCancelRouteModel {
    const { fullSubscription } = this.modelFor<AccountSubscriptionCancelRoute>(
      "authenticated.account.subscription.cancel"
    );

    return {
      fullSubscription: fullSubscription
    };
  }

  resetController(
    controller: AccountSubscriptionCancelConfirmationController,
    isExiting: boolean,
    transition: Transition
  ): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.resetTransientState();
    }
  }
}
