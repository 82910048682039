import { attr, belongsTo } from "@ember-data/model";
import DiscountDetails from "client/models/discount-details";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";

export default class Discount extends DiscountDetails {
  @belongsTo("subscription", { async: false, inverse: "discount" })
  declare subscription: Subscription;

  @attr("boolean")
  declare couponValid: boolean;

  get price(): string | undefined {
    return this.plan ? this.calculatePrice(this.plan) : undefined;
  }

  get futurePrice(): string | undefined {
    return this.plan ? this.calculateFuturePrice(this.plan) : undefined;
  }

  get durationDescription(): string | undefined {
    return this.plan ? this.calculateDurationDescription(this.plan) : undefined;
  }

  // When changing a subscription, the subscription model is updated but discount expects the previous one, which no longer exists in the store.
  // To handle that situation, discount has to be able to handle a situation when subscription does not exist.
  private get plan(): Plan | undefined {
    return this.subscription?.plan;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    discount: Discount;
  }
}
