export class TimeSlice {
  constructor(public readonly startOffset: number, public readonly duration: number) {}

  get endOffset(): number {
    return this.startOffset + this.duration;
  }

  public equals(other?: TimeSlice): boolean {
    return this.startOffset === other?.startOffset && this.duration === other?.duration;
  }
}
