import type { Caption, Logo } from "../../scene";
import type { TimelineFacade } from "../../timeline";
import { VanillaMutation } from "./vanilla-mutation";

export class CaptionAddLogoMutation extends VanillaMutation<Caption> {
  private logo?: Logo;

  constructor(private caption: Caption) {
    super(caption);
  }

  async prepare(facade: TimelineFacade): Promise<Logo> {
    return (this.logo = await facade.newLogo(this.caption));
  }

  apply(target: Caption): void {
    if (!this.logo) {
      throw new Error("prepare() must be called before apply()");
    }

    target._appendLogoElement(this.logo);
  }
}
