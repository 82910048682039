import type Evented from "@ember/object/evented";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { FolderMessages } from "client/lib/data/folder-messages";
import type Folder from "client/models/folder";
import { ContributorTypes } from "client/models/folder";
import type FoldersService from "client/services/folders";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

interface AssetLibraryFolderRouteModel {
  folder?: Folder;
  order: string;
  type?: ContributorTypes;
}

interface AssetLibraryFolderRouteParams {
  order: string;
  folderId: string;
}

export default class AssetLibraryFolderRoute extends Route<AssetLibraryFolderRouteModel> {
  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare notifications: NotificationsService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService & Evented;

  async model({ folderId, order }: AssetLibraryFolderRouteParams): Promise<AssetLibraryFolderRouteModel> {
    order ??= this.folders.filterOrder;

    try {
      const folder = await this.store.findRecord("folder", folderId);
      return { folder, order, type: folder.contributorType };
    } catch (error) {
      this.notifications.error(FolderMessages.FOLDER_RESULTS_FAILURE);

      if (error instanceof Error) {
        this.honeybadger.notify(error, "AssetLibraryFolderRoute");
      }

      await this.router.transitionTo("authenticated.asset-library.library", ContributorTypes.USER);
      return { order };
    }
  }

  afterModel(model: AssetLibraryFolderRouteModel): void {
    this.folders.setActiveFolder(model.folder);
  }

  queryParams = {
    order: {
      refreshModel: true
    }
  };
}
