import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import { DuplicateSceneMutation, SceneOrderMutation, DeleteSceneMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { latestCaptionEnd } from "client/lib/timeline/caption";
import { transaction } from "client/lib/transaction";
import type AdvancedEditorService from "client/services/advanced-editor";
import type NotificationsService from "client/services/notifications";
import type ScriptWorkstationService from "client/services/script-workstation";

interface Args {
  eventRegister: EventRegister;
  scene: Scene;
  timeline: Timeline;
}

export default class ScriptWorkstationSceneComponentMenuComponent extends Component<Args> {
  @service
  declare notifications: NotificationsService;

  @service
  declare scriptWorkstation: ScriptWorkstationService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @tracked
  button?: HTMLElement;

  @tracked
  buttonIsActive = false;

  styleNamespace = getStyleNamespace("script-workstation/menu");

  @action
  didInsertButton(button: HTMLElement): void {
    this.button = button;
  }

  @action
  onButtonClick(): void {
    this.buttonIsActive = !this.buttonIsActive;
  }

  @action
  close(): void {
    this.buttonIsActive = false;
  }

  @action
  @transaction
  async duplicateScene(): Promise<void> {
    this.buttonIsActive = false;

    const { eventRegister, scene, timeline } = this.args;

    const mutation = new DuplicateSceneMutation(timeline, scene.id);
    await mutation.prepare(eventRegister.facade);
    const newScene = eventRegister.fire(mutation) as Scene;

    if (newScene) {
      // Move new scene to after the current
      eventRegister.fire(new SceneOrderMutation(timeline, newScene, timeline.sceneOrder(scene) + 1));
      await eventRegister.facade.saveSceneOrder(timeline);

      if (newScene.captions.length > 0) {
        this.advancedEditor.setScriptWorkstationElement(newScene.captions[0]!);
        await this.advancedEditor.transitionToCaption(newScene.captions[0]!, timeline);
      } else {
        this.advancedEditor.setScriptWorkstationElement(newScene);
        await this.advancedEditor.transitionToScene(newScene, timeline);
      }
    }
  }

  @action
  @transaction
  async deleteScene(): Promise<void> {
    const { eventRegister, scene, timeline } = this.args;

    eventRegister.fire(new DeleteSceneMutation(timeline, scene));
    await eventRegister.facade.saveSceneOrder(timeline);
  }

  get hasMultipleCaptions(): boolean {
    return this.args.scene.captions.length > 1;
  }

  get canCopyCaption(): boolean {
    const { scene } = this.args;

    const captionWithText = scene.captions.find((caption) => caption.texts.length > 0);
    return !!captionWithText;
  }

  get latestCaptionEnd(): number {
    return latestCaptionEnd(this.args.scene.captions);
  }
}
