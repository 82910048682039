import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TimestampComponentArgs {
  currentTime: number;
  duration: number;
}

export default class TimeStampComponent extends Component<TimestampComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/video-player/time-stamp");
}
