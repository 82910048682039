import type ApplicationInstance from "@ember/application/instance";
import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type CurrentUserService from "./current-user";
import type UpgradeService from "./upgrade";
import { UpgradeEvents } from "./upgrade";
import type Card from "client/models/card";

export default class PaymentSourceService extends Service {
  @tracked
  card?: Card;

  @tracked
  loaded = false;

  @tracked
  loading = false;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare store: Store;

  @service
  declare upgrade: UpgradeService;

  constructor(owner: ApplicationInstance) {
    super(owner);

    this.upgrade.on(UpgradeEvents.SUBSCRIPTION_PURCHASED, this.loadCard);
  }

  async getCard(): Promise<Card | undefined> {
    if (this.loaded) {
      return this.card;
    }

    try {
      this.loading = true;
      await this.loadCard();
    } finally {
      this.loaded = true;
      this.loading = false;
    }

    return this.card;
  }

  @action
  private async loadCard(): Promise<void> {
    try {
      this.card = await this.currentUser.user?.get("card");
    } catch {
      this.card = undefined;
    }
  }

  async addCard(stripeToken: stripe.Token): Promise<void> {
    const { card } = await this.store
      .createRecord("paymentSource", {
        stripeToken: stripeToken.id
      })
      .save();

    if (card) {
      this.card = card;
    }
  }

  get canAddCard(): boolean {
    return !!this.currentUser.user?.canManageSubscription && this.currentUser.user.hasPaidSubscription;
  }
}
