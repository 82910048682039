import type { Caption } from "../../scene";
import { CaptionOffsetChangeMutation } from "./caption-offset-mutation";
import type { TimelineMutation } from "./mutation";
import type { SceneState } from "./scene-state";
import { createState } from "./scene-state";

export class CaptionOffsetMutationFactory {
  private referenceState: SceneState;
  private grouped = false;

  constructor(private caption: Caption) {
    this.referenceState = createState(caption.scene);
  }

  createMutation(offset: number, snapAmount?: number): TimelineMutation {
    const mutation = new CaptionOffsetChangeMutation(
      this.grouped,
      this.caption,
      offset,
      this.referenceState,
      snapAmount
    );
    this.grouped = true;
    return mutation;
  }
}
