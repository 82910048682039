import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type FloatingPanelsService from "client/services/floating-panels";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ScriptWorkstationService from "client/services/script-workstation";

export default class PropertiesPanelService extends Service {
  @service
  private declare floatingPanels: FloatingPanelsService;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @service
  private declare scriptWorkstation: ScriptWorkstationService;

  @service
  private declare router: RouterService;

  @tracked
  private _visible = true;

  get visible(): boolean {
    return (
      this._visible &&
      !this.floatingPanels.visible &&
      !this.projectContentBar.expanded &&
      !this.scriptWorkstation.expanded
    );
  }

  @action
  open(): void {
    this.floatingPanels.hide();
    this.projectContentBar.hide();
    this.scriptWorkstation.collapse();
    this._visible = true;
  }

  @action
  close(): void {
    if (this.isVoiceover || this.isSoundtrack) {
      const newUrl = this.router.currentURL.replace(/\/[^/]*$/, "");
      void this.router.transitionTo(newUrl);
    }
    this._visible = false;
  }

  @action
  toggle(): void {
    this.visible ? this.close() : this.open();
  }

  get isVoiceover(): boolean {
    return this.router.currentRouteName === "authenticated.project.scene.voiceover.index";
  }

  get isSoundtrack(): boolean {
    return this.router.currentRouteName === "authenticated.project.scene.soundtrack";
  }
}
