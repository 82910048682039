import type { Caption, Text, TextStyle } from "../../scene";
import type { TimelineFacade } from "../../timeline";
import { VanillaMutation } from "./vanilla-mutation";

export class CaptionAddTextMutation extends VanillaMutation<Caption> {
  private text?: Text;

  constructor(private caption: Caption, private textStyle?: TextStyle) {
    super(caption);
  }

  async prepare(facade: TimelineFacade): Promise<Text> {
    return (this.text = await facade.newText(this.caption, this.textStyle));
  }

  apply(target: Caption): void {
    if (!this.text) {
      throw new Error("prepare() must be called before apply()");
    }

    target._appendTextElement(this.text);
  }
}
