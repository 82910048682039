import { action } from "@ember/object";
import { service } from "@ember/service";
import type { PositionalArgs, NamedArgs } from "ember-modifier";
import Modifier from "ember-modifier";
import type TooltipService from "client/services/tooltip";

interface TooltipSignature {
  Args: {
    Named: {
      position?: string;
      showOnFocus?: boolean;
      disabled?: boolean;
      enabled?: boolean;
      upgrade?: boolean;
      keyboardShortcut?: string;
    };
    Positional: [string];
  };
}

export default class TooltipModifier extends Modifier<TooltipSignature> {
  @service
  declare tooltip: TooltipService;

  element!: HTMLElement;

  private bound = false;
  private active = false;
  private mouseover = false;
  private showOnFocus = false;
  private position = "top";
  private disabled = false;
  private upgrade = false;
  private enabled = true;
  private message = "";
  private inserted = false;
  private keyboardShortcut = "";

  modify(
    element: Element,
    positionalArgs: PositionalArgs<TooltipSignature>,
    namedArgs: NamedArgs<TooltipSignature>
  ): void {
    if (!this.inserted) {
      this.element = element as HTMLElement;
      this.inserted = true;
      this.addEventListener();
    }

    const [message] = positionalArgs;
    const {
      upgrade = false,
      disabled = false,
      showOnFocus = false,
      position = "top",
      enabled = true,
      keyboardShortcut = ""
    } = namedArgs;

    this.showOnFocus = showOnFocus;
    this.position = position;
    this.message = message;
    this.disabled = disabled;
    this.upgrade = upgrade;
    this.enabled = enabled;
    this.keyboardShortcut = keyboardShortcut;

    if (!this.enabled) {
      this.hide();
    }
  }

  // If an element the tooltip is tracking becomes focused then close the tooltip
  private observer = new MutationObserver(([record]) => {
    if (record && !this.showOnFocus && this.containsActiveElement()) {
      this.hide();
    }
  });

  private containsActiveElement(): boolean {
    return this.element.contains(document.activeElement);
  }

  private get canShow(): boolean {
    return !this.disabled && this.enabled && !!this.message && !!this.element.parentElement;
  }

  @action
  mouseenter(): void {
    this.mouseover = true;
    this.show();
  }

  @action
  mouseleave(): void {
    this.mouseover = false;
    this.hide();
  }

  @action
  show(): void {
    if (!this.canShow) {
      return;
    }

    if (this.containsActiveElement() && !this.showOnFocus) {
      return;
    }

    if (!this.active && this.mouseover) {
      this.active = true;
      this.observer.observe(this.element, { attributes: true, subtree: true, childList: true });
      this.element.addEventListener("mouseleave", this.mouseleave);
      this.element.addEventListener("mousedown", () => {
        if (!this.showOnFocus) {
          this.hide();
        }
      });
    }

    this.tooltip.show(this.element, this.message, {
      position: this.position,
      upgrade: this.upgrade,
      keyboardShortcut: this.keyboardShortcut
    });
  }

  @action
  hide(): void {
    if (this.active) {
      this.active = false;
      this.observer.disconnect();
      this.element.removeEventListener("mouseleave", this.mouseleave);
      this.element.removeEventListener("mousedown", this.hide);
      this.tooltip.hide();
    }
  }

  private addEventListener(): void {
    if (!this.bound) {
      this.element.addEventListener("mouseenter", this.mouseenter);
      this.bound = true;
    }
  }
}
