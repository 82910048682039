import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type CurrentUserService from "client/services/current-user";
import type TrackingService from "client/services/tracking";

export default class ProjectStepsComponent extends Component {
  @service
  declare currentUser: CurrentUserService;

  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("tidal/project-steps");

  @action
  trackClickEditStep(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_EDIT_STEP, {});
  }
}
