export const wrapElement = (element: HTMLElement): HTMLElement => {
  const wrapper = document.createElement("div");

  element.parentElement?.insertBefore(wrapper, element);
  wrapper.appendChild(element);

  Object.assign(wrapper.style, {
    display: "grid"
  });

  return wrapper;
};

export const getElement = (elementOrSelector: string | HTMLElement): HTMLElement => {
  const element =
    elementOrSelector instanceof HTMLElement ? elementOrSelector : document.querySelector(elementOrSelector);

  if (!(element instanceof HTMLElement)) {
    throw Error(`An element matching selector "${elementOrSelector}" could not be found or is not a valid HTMLElement`);
  }

  return element;
};

export const resetParentHeight = (parent: HTMLElement): void => {
  parent.style.height = "auto";
};

// there is a time window that child element height changes won't trigger rerender immediately
// bigger height buffer will allow the layout isn't broken (going to an extra column) during the window
const HEIGHT_BUFFER = 32;
export const setParentHeight = (parent: HTMLElement, children: HTMLElement[], columns: number): void => {
  // check the last bunch of children, assume the height of the highest thumbnail is at most 4 times of the shortest
  const childRects = children.slice(-4 * columns).map((child) => child.getBoundingClientRect());
  const distanceToBottom = Math.max(...childRects.map(({ bottom }) => bottom));
  const parentRect = parent.getBoundingClientRect();
  const height = parentRect.height - (parentRect.bottom - distanceToBottom);

  parent.style.height = `${height + HEIGHT_BUFFER}px`;
  parent.style.minHeight = `${height + HEIGHT_BUFFER}px`;
};

export const getColumnGap = (parent: HTMLElement): number => {
  const styles = getComputedStyle(parent);
  const gap = styles.columnGap || styles.gap;
  const value = parseFloat(gap);

  return isNaN(value) ? 0 : value;
};

export const getColumnCount = (parentWidth: number, columnWidth: number, columnGap: number): number => {
  return Math.floor(parentWidth / (columnWidth + columnGap));
};

export const getChildWidthStyle = (columnCount: number, columnGap: number): string => {
  const gap = Math.ceil((columnGap * (columnCount - 1)) / columnCount);
  return `calc(${(1 / columnCount) * 100}% - ${gap + 1}px)`;
};

export const getChildren = (element: HTMLElement): HTMLElement[] => {
  return Array.from(element.children) as HTMLElement[];
};

export const addBreakElements = (parent: HTMLElement, columnCount: number): HTMLElement[] => {
  const breakElements: HTMLElement[] = [];

  for (let i = columnCount; i > 0; i--) {
    breakElements[i] = createBreakElement(parent, i);
  }

  return breakElements;
};

export const createBreakElement = (parent: HTMLElement, columnNumber: number): HTMLElement => {
  const element = document.createElement("div");

  Object.assign(element.style, {
    flexBasis: "100%",
    width: 0,
    margin: 0,
    order: columnNumber,
    height: 0
  });

  parent.appendChild(element);

  return element;
};

export const removeBreakElements = (breakElements: HTMLElement[]): void => {
  breakElements.forEach((element) => {
    element.remove();
  });
};
