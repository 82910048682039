import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface UIToggleButtonArgs {
  disabled?: boolean;
  label?: string;
  value?: boolean;
  tint?: boolean;
  onchange?: () => void;
}

export default class UIToggleButtonComponent extends Component<UIToggleButtonArgs> {
  styleNamespace = getStyleNamespace("ui/ui-toggle-button");

  get disabled(): boolean {
    return this.args.disabled ?? false;
  }

  get tint(): boolean {
    return this.args.tint ?? false;
  }

  @action
  click(): void {
    if (this.args.onchange) {
      this.args.onchange();
    }
  }

  get selected(): string {
    return this.args.value?.toString() ?? "false";
  }
}
