import Network from "@biteable/network-model";
import type ApplicationInstance from "@ember/application/instance";
import config from "client/config/environment";
import type AuthenticationTokenService from "client/services/authentication-token";

const initialize = (app: ApplicationInstance): void => {
  const auth = app.lookup("service:authentication-token") as AuthenticationTokenService;
  Network.configure({ basePath: config.apiUrl, getAuthToken: () => auth.authToken });
};

export default {
  name: "network-model",
  initialize
};
