import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import PlaybackController from "client/lib/playback/playback";
import type PlaybackService from "client/services/playback";
import type TrackingService from "client/services/tracking";

interface ProjectTimelineSeekArgs {
  duration: number;
  seekDuration: number;
  pixelsPerSecond: number;
}

export default class ProjectTimelineSeekComponent extends Component<ProjectTimelineSeekArgs> {
  @service
  declare playback: PlaybackService;

  @service
  declare tracking: TrackingService;

  playbackController: PlaybackController;

  constructor(owner: object, args: ProjectTimelineSeekArgs) {
    super(owner, args);
    this.playbackController = new PlaybackController(this.playback, this.tracking);
  }

  @action
  async play(startTime: number): Promise<void> {
    await this.playbackController.play(startTime);
  }

  @action
  async stop(): Promise<void> {
    await this.playbackController.stop();
  }
}
