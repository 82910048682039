import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { ZymbolCategory, ZymbolGroupLayer } from "renderer-engine";
import type Layer from "client/models/layer";
import type ZymbolGroup from "client/models/zymbol-group";
import type NotificationsService from "client/services/notifications";
import type ZymbolGroupsService from "client/services/zymbol-groups";

const PROJECT_LAYER_CREATION_FAILURE_NOTIFICATION = "There was a problem creating a new layer item";

export const defaultZymbolCategoryMapping = new Map<ZymbolGroupLayer, ZymbolCategory>([
  [ZymbolGroupLayer.TEXT, ZymbolCategory.TEXT],
  [ZymbolGroupLayer.FOREGROUND, ZymbolCategory.IMAGE],
  [ZymbolGroupLayer.BACKGROUND, ZymbolCategory.VIDEO]
]);

export default class LayersService extends Service {
  @service
  zymbolGroups!: ZymbolGroupsService;

  @service
  declare store: Store;

  @service
  notifications!: NotificationsService;

  async addTextZymbolGroup(layer: Layer, startTime: number, duration: number, withLogo = false): Promise<ZymbolGroup> {
    try {
      const zymbolGroup = await this.addEmptyZymbolGroup(layer, startTime, duration);
      await this.zymbolGroups.createDefaultZymbol(zymbolGroup);

      if (withLogo) {
        await this.zymbolGroups.appendLogoZymbol(zymbolGroup);
      }

      return zymbolGroup;
    } catch (err) {
      this.notifications.error(PROJECT_LAYER_CREATION_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async addLogoZymbolGroup(layer: Layer, startTime: number, duration: number, centered = false): Promise<ZymbolGroup> {
    try {
      const zymbolGroup = await this.addEmptyZymbolGroup(layer, startTime, duration);
      await this.zymbolGroups.createDefaultLogoZymbol(zymbolGroup, centered);

      return zymbolGroup;
    } catch (err) {
      this.notifications.error(PROJECT_LAYER_CREATION_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  async addEmptyZymbolGroup(layer: Layer, startTime: number, duration: number): Promise<ZymbolGroup> {
    const newZymbolGroupProperties: Partial<ZymbolGroup> = {
      startTime,
      layer,
      duration
    };

    const zymbolGroup = this.store.createRecord("zymbolGroup", newZymbolGroupProperties);
    await zymbolGroup.save();
    return zymbolGroup;
  }
}
