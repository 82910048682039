import Model, { attr, belongsTo } from "@ember-data/model";
import type ContentStack from "./content-stack";
import type Sag from "./sag";
import type Svg from "./svg";

export default class ContentTemplate extends Model {
  @belongsTo("content-stack", { async: true, inverse: "contentTemplates" })
  declare contentStack: ContentStack;

  @belongsTo("sag", { async: false, inverse: "contentTemplates" })
  declare sag: Sag;

  @belongsTo("svg", { async: false, inverse: "contentTemplates" })
  declare svg: Svg;

  @attr("string")
  declare name: string;

  @attr("number")
  declare displayOrder: number;

  @attr("boolean")
  declare published: boolean;

  get contentAsset(): Sag | Svg {
    return this.sag ?? this.svg;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    contentTemplate: ContentTemplate;
  }
}
