import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import { attr, belongsTo } from "@ember-data/model";
import type Store from "@ember-data/store";
import { cached, tracked } from "@glimmer/tracking";
import { last } from "lodash";
import type { ZymbolConfigType, HexColor, RenderZymbol, SceneTransition } from "renderer-engine";
import {
  DEFAULT_FRAME_RATE,
  ZymbolConfig,
  ZymbolCategory,
  ColorConfig,
  FilterConfig,
  ZymbolGroupLayer
} from "renderer-engine";
import type BackgroundLayer from "./background-layer";
import { favorable, FAVORABLE_TYPES } from "./favorable";
import type Favorite from "./favorite";
import type Layer from "./layer";
import type Project from "./project";
import SelectableAsset from "./selectable-asset";
import type TextLayer from "./text-layer";
import TrackingEvents from "client/events";
import type { ColorPresetJSON } from "client/lib/brand/color-preset";
import cmsUrl from "client/lib/cms-url";
import * as DomainModel from "client/lib/editor-domain-model";
import hashObject from "client/lib/hash-object";
import { restoreModel } from "client/lib/restore-model";
import AssetFactory from "client/lib/scene/asset-factory";
import type Zymbol from "client/models/zymbol";
import type ZymbolGroup from "client/models/zymbol-group";
import type AjaxService from "client/services/ajax";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";
import type ProjectScenesService from "client/services/project-scenes";
import type ZymbolGroupsService from "client/services/zymbol-groups";

const IS_NEW_TIME_DELTA = new Date(0).setMonth(2); // 2 months
const POSTER_FRAME = 60;
const IGNORE_THUMBNAIL_PROPERTIES = [
  "assets",
  "duration",
  "endsAtSceneEnd",
  "sceneTransitionIn",
  "sceneTransitionOut",
  "startTime"
];

@favorable
class ProjectScene extends SelectableAsset {
  @service
  declare projectScenes: ProjectScenesService;

  @service("zymbolGroups" as never)
  declare zymbolGroupsService: ZymbolGroupsService;

  @service
  declare auth: AuthService;

  @belongsTo("project", { async: true, inverse: "projectScenes" })
  declare project: Project;

  @belongsTo("text-layer", { inverse: "projectScene", async: false, polymorphic: true })
  declare textLayer: TextLayer;

  @belongsTo("background-layer", { inverse: "projectScene", async: false, polymorphic: true })
  declare backgroundLayer: BackgroundLayer;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("zymbol-group", { async: false, inverse: null })
  declare backgroundZymbolGroup: ZymbolGroup;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("zymbol", { async: false, inverse: null })
  declare backgroundZymbol: Zymbol;

  // This cannot be called favorite as it conflicts with the favorite() function mixed in favorable.ts
  // eslint-disable-next-line no-null/no-null
  @belongsTo("favorite", { async: false, inverse: null })
  declare faved: Favorite;

  @attr("string")
  declare transition?: SceneTransition;

  @attr("boolean")
  declare template: boolean;

  @attr("string", { allowNull: true })
  declare thumbnailUrl?: string;

  @attr("string")
  declare previewUrl?: string;

  @attr("array")
  declare visualStyles?: string[];

  @attr("array")
  declare tags?: string[];

  @attr("array")
  declare stacks?: string[];

  @attr("date")
  declare publishedAt?: Date;

  @attr("string", { allowNull: true })
  declare backgroundColor?: HexColor;

  @attr("string", { allowNull: true })
  declare filterColor?: HexColor;

  @attr("string", { allowNull: true })
  declare title?: string;

  @attr("string")
  declare aspectRatioName: string;

  @attr("string")
  declare aspectRatioSlug: string;

  @attr("json")
  declare aspectRatioDimensions: { width: number; height: number };

  @attr("json")
  declare colorPreset?: ColorPresetJSON;

  @attr("string")
  declare backgroundColorBrandKey?: string;

  @attr("boolean")
  declare brandableTemplate: boolean;

  get layers(): Array<Layer> {
    // @ts-expect-error
    return [this.belongsTo("backgroundLayer").value() as Layer, this.belongsTo("textLayer").value() as Layer].filter(
      (layer) => !!layer
    );
  }

  @cached
  get startTime(): number {
    return this.previousScenesDuration.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }

  get projectDuration(): number {
    // @ts-expect-error
    return (this.belongsTo("project").value() as Project)?.duration;
  }

  get isLast(): boolean {
    return this.endTime === this.projectDuration;
  }

  get previousScenesDuration(): number[] {
    return this.previousScenes.map(({ duration }) => duration);
  }

  get previousScenes(): ProjectScene[] {
    // @ts-expect-error
    const project = this.belongsTo("project").value() as Project;
    return project.sortedProjectScenes?.filter((ps) => ps.order !== -1 && ps.order < this.order) ?? [];
  }

  get order(): number {
    // @ts-expect-error
    const project = this.belongsTo("project").value() as Project;
    const projectSceneOrder = project?.projectSceneOrder || [];
    return projectSceneOrder.indexOf(Number(this.id));
  }

  get duration(): number {
    return Math.max(...this.layerDurations);
  }

  get layerDurations(): number[] {
    return this.layers.map((layer) => layer?.duration);
  }

  get tagList(): string {
    return (this.tags || []).join(", ");
  }

  get previewImageUrl(): string | undefined {
    return this.thumbnailUrl;
  }

  get previewVideoUrl(): string | undefined {
    return this.previewUrl;
  }

  get published(): boolean {
    return !!this.publishedAt;
  }

  get isNewScene(): boolean {
    if (!this.publishedAt) {
      return false;
    }

    return this.publishedAt.getTime() + IS_NEW_TIME_DELTA > new Date().getTime();
  }

  get endTime(): number {
    return this.startTime + this.duration;
  }

  get hasZymbolGroups(): boolean {
    return this.layers.some((layer) => layer.hasZymbolGroups);
  }

  get defaultPreviewTime(): number {
    return this.startTime + this.duration / 2;
  }

  isTimeInRange(time: number): boolean {
    const { startTime, endTime } = this;

    return time >= startTime && time < endTime;
  }

  get zymbolGroups(): Array<ZymbolGroup> {
    // @ts-expect-error
    return this.layers
      .map((l) => {
        return l.hasMany("zymbolGroups").value();
      })
      .flat();
  }

  get nonTextLayerZymbolGroups(): Array<ZymbolGroup> {
    // @ts-expect-error
    return this.layers
      .filter((l) => l.name !== ZymbolGroupLayer.TEXT)
      .map((l) => {
        return l.hasMany("zymbolGroups").value();
      })
      .flat();
  }

  get textLayerZymbolGroups(): Array<ZymbolGroup> {
    // @ts-expect-error
    return this.layers
      .filter((l) => l.name === ZymbolGroupLayer.TEXT)
      .map((l) => {
        return l.hasMany("zymbolGroups").value();
      })
      .flat();
  }

  get captions(): ZymbolGroup[] {
    // @ts-expect-error
    const textLayer = this.belongsTo("textLayer").value() as TextLayer;
    // @ts-expect-error
    return textLayer?.hasMany("zymbolGroups").value() as ZymbolGroup[];
  }

  get sortedCaptions(): ZymbolGroup[] {
    // Use slice to get a new array as sort mutates the array and that is not a good idea with Ember Data
    return this.captions?.slice()?.sort((a, b) => a.startTime - b.startTime);
  }

  get firstCaption(): ZymbolGroup | undefined {
    return this.sortedCaptions?.[0];
  }

  get lastCaption(): ZymbolGroup | undefined {
    return last(this.sortedCaptions);
  }

  get nonEmptyCaptions(): ZymbolGroup[] {
    return this.sortedCaptions?.filter(({ hasZymbols }) => hasZymbols);
  }

  get lastNonEmptyCaption(): ZymbolGroup | undefined {
    return last(this.nonEmptyCaptions);
  }

  get hasCaptions(): boolean {
    return !!this.nonEmptyCaptions?.length;
  }

  get cmsUrl(): string {
    return cmsUrl(getOwner(this), `/scene/${this.id}`);
  }

  get shutterstockId(): string {
    return this.backgroundZymbol?.shutterstockId ?? "";
  }

  get zymbolsToRender(): RenderZymbol[] {
    return new Array<RenderZymbol>().concat(
      ...[
        this.backgroundColorZymbolToRender,
        ...this.nonTextLayerZymbolsToRender,
        this.filterZymbolToRender,
        ...this.textLayerZymbolsToRender
      ]
    );
  }

  get textZymbolsToRender(): RenderZymbol[] {
    return new Array<RenderZymbol>().concat(...this.textLayerZymbolsToRender);
  }

  zymbolsToRenderHash?: string;

  public posterContentHash(): string {
    const zymbolsWithoutAssets = this.zymbolsToRender?.map((z) => ({ ...z, assets: [] })) || {};

    return hashObject(zymbolsWithoutAssets);
  }

  get containsPosterFrame(): boolean {
    const posterTime = POSTER_FRAME / DEFAULT_FRAME_RATE;

    if (this.isLast && this.startTime <= posterTime) {
      return true;
    } else {
      return this.startTime <= posterTime && this.endTime >= posterTime;
    }
  }

  @cached
  get backgroundColorZymbolToRender(): RenderZymbol[] {
    const color = new ColorConfig();
    color.backgroundColor = {
      rgb: this.backgroundColor || "#FFFFFF",
      alpha: 1
    };

    return [this.createZymbolToRender(ZymbolCategory.COLOR, color)];
  }

  get nonTextLayerZymbolsToRender(): RenderZymbol[][] {
    return this.nonTextLayerZymbolGroups.map((zymbolGroup) => zymbolGroup.zymbolsToRender);
  }

  get textLayerZymbolsToRender(): RenderZymbol[][] {
    return this.textLayerZymbolGroups.map((zymbolGroup) => zymbolGroup.zymbolsToRender);
  }

  get textLayerGraphicZymbolsToRender(): RenderZymbol[][] {
    return this.textLayerZymbolGroups.map((zymbolGroup) => zymbolGroup.graphicZymbolsToRender);
  }

  get sortedProjectScenes(): ProjectScene[] {
    return this.project.get("sortedProjectScenes");
  }

  get nextSceneTransition(): SceneTransition | undefined {
    const scenes = this.sortedProjectScenes;

    if (!scenes) {
      return undefined;
    }

    const index = scenes.indexOf(this);

    if (index !== -1) {
      const nextScene = scenes[index + 1];
      if (nextScene) {
        return nextScene.transition;
      }
    }

    return;
  }

  @cached
  get filterZymbolToRender(): RenderZymbol[] {
    if (!this.filterColor) {
      return [];
    }

    const filter = new FilterConfig();
    filter.color = this.filterColor;
    filter.composition = "multiply";

    return [this.createZymbolToRender(ZymbolCategory.FILTER, filter)];
  }

  get sceneTransitionIn(): SceneTransition | undefined {
    if (this.startTime !== 0) {
      return this.transition;
    }

    return;
  }

  get thumbImageUrl(): string | undefined {
    return this.previewImageUrl;
  }

  get thumbVideoUrl(): string | undefined {
    return this.previewImageUrl;
  }

  createZymbolToRender(category: ZymbolCategory, cfg: ZymbolConfigType): RenderZymbol {
    const config = new ZymbolConfig();
    // @ts-ignore - will
    config[category] = cfg;

    return {
      cfg: config,
      startTime: this.startTime,
      duration: this.duration,
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      layerOrder: 0,
      customTimingOffset: undefined,
      customTimingDuration: undefined,
      category,
      sceneTransitionIn: this.sceneTransitionIn,
      sceneTransitionOut: this.nextSceneTransition,
      sceneId: this.id,
      identifier: `ProjectScene(${this.id})`,
      assets: []
    };
  }

  async addToTimeline(
    timeline: DomainModel.Timeline,
    eventRegister: DomainModel.EventRegister,
    order?: number
  ): Promise<DomainModel.Scene> {
    const mutation = new DomainModel.DuplicateSceneMutation(timeline, this.id, order);
    await mutation.prepare(eventRegister.facade);

    return eventRegister.fire(mutation);
  }

  async addToMedia(
    _scene: DomainModel.Scene,
    media: DomainModel.Media,
    eventRegister: DomainModel.EventRegister
  ): Promise<void> {
    const config = this.backgroundZymbol.cfg[this.backgroundZymbol.category] ?? {};
    const domainAsset = new AssetFactory().createAssetFromConfig(config, this.backgroundZymbol.category);
    eventRegister.fire(new DomainModel.UpdateAssetMutation(media, domainAsset));

    eventRegister.fire(new DomainModel.ChangePositionMutation(media, DomainModel.Rect.fromRect(this.backgroundZymbol)));
    eventRegister.fire(new DomainModel.ChangeAssetOffsetMutation(media, this.backgroundZymbol.assetOffset));
  }

  static async restore(
    ajax: AjaxService,
    notifications: NotificationsService,
    store: Store,
    id: string
  ): Promise<ProjectScene> {
    try {
      return await restoreModel(ajax, store, "projectScene", id);
    } catch (err) {
      notifications.error("There was a problem restoring the scene");
      throw err;
    }
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_SCENE;

  get trackUsageData(): object {
    return {
      sceneId: this.id,
      sceneName: this.title,
      brandScene: this.isBrandScene,
      sceneStacks: this.stacks,
      sceneTags: this.isBrandScene ? this.tags : undefined
    };
  }

  get isBrandScene(): boolean {
    const REFERENCE_BRANDED_STACK_CODE = "reference-branded-scenes";
    const BRAND_STACK_CODE_SUFFIX = "-brand-scenes";

    return (
      this.stacks?.some((stack) => {
        return stack.endsWith(BRAND_STACK_CODE_SUFFIX) || stack === REFERENCE_BRANDED_STACK_CODE;
      }) ?? false
    );
  }

  get isBrandable(): boolean {
    return !!this.colorPreset;
  }

  get isBrandableTemplate(): boolean {
    return this.brandableTemplate;
  }

  get favorableProviderAssetId(): string {
    return this.id;
  }

  readonly favorableType = FAVORABLE_TYPES.PROJECT_SCENES;

  metadataForFavorite = {};
}

export default ProjectScene;

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectScene: ProjectScene;
  }
}
