import type { Project, ProjectScene } from "@biteable/network-model";
import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectSceneStack from "client/models/project-scene-stack";

interface CategoryPickerComponentArgs {
  project: Project;
  stacks: Array<ProjectSceneStack>;
  selectScene(projectScene: ProjectScene): unknown;
}

export default class CategoryPickerComponent extends Component<CategoryPickerComponentArgs> {
  @tracked
  showUnpublished = false;

  @tracked
  currentCategory?: string;

  @tracked
  emptyStacksCount = 0;

  styleNamespace = getStyleNamespace("add-scene/category-picker");

  @action
  toggleShowUnpublished(): void {
    this.showUnpublished = !this.showUnpublished;
  }

  @action
  onCategoryClick(category: string): void {
    this.currentCategory = category;
    const element = document.getElementById(category);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  @action
  setCurrentCategory(hoverCategory: string): void {
    if (hoverCategory !== this.currentCategory) {
      this.currentCategory = hoverCategory;
    }
  }

  @action
  stacksDidChange(): void {
    this.emptyStacksCount = 0;
  }

  @action
  onEmptyGroupLoaded(): void {
    this.emptyStacksCount++;
  }

  get stacks(): ProjectSceneStack[] {
    return this.showUnpublished ? this.args.stacks : this.args.stacks.filter((stack) => stack.published);
  }

  private get stacksCount(): number {
    return this.args.stacks.length;
  }

  get allStacksEmpty(): boolean {
    return this.stacksCount === this.emptyStacksCount;
  }
}
