import { attr, belongsTo } from "@ember-data/model";
import DiscountDetails from "client/models/discount-details";
import type Plan from "client/models/plan";

export default class ValidatedCoupon extends DiscountDetails {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("plan", { async: false, inverse: null })
  declare plan: Plan;

  @attr("string")
  code?: string;

  get price(): string {
    return this.calculatePrice(this.plan);
  }

  get futurePrice(): string {
    return this.calculateFuturePrice(this.plan);
  }

  get durationDescription(): string {
    return this.calculateDurationDescription(this.plan);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    validatedCoupon: ValidatedCoupon;
  }
}
