import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type { Timeline, TimelineFactory, Scene, Caption, EventRegister } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AjaxService from "client/services/ajax";
import type PermissionsService from "client/services/permissions";
import type PlaybackService from "client/services/playback";
import type TimelineService from "client/services/timeline";

export interface ProjectTimelineArgs {
  timeline: Timeline;
  timelineFactory: TimelineFactory;
  eventRegister: EventRegister;
  selectedSceneId?: string;
  selectedCaptionId?: string;
  project?: Project;
}

export const getSceneHalfwayPoint = (timeline: Timeline, scene: Scene): number =>
  timeline.sceneStartTime(scene) + scene.duration / 2;

export const getCaptionPreviewTime = (timeline: Timeline, caption: Caption): number =>
  timeline.sceneStartTime(caption.scene) + caption.offset + caption.duration / 2;

export const toInlineStyle = (pixels: number, property: string): SafeString =>
  htmlSafe(`${property}: ${pixels.toFixed(2)}px;`);

export default class ProjectTimelineComponent extends Component<ProjectTimelineArgs> {
  @service
  declare ajax: AjaxService;

  @service("timeline")
  declare timelineState: TimelineService;

  @service
  declare playback: PlaybackService;

  @service
  declare permissions: PermissionsService;

  @service
  declare advancedEditor: AdvancedEditorService;

  styleNamespace = getStyleNamespace("project-timeline");

  willDestroy(): void {
    super.willDestroy();
  }

  @action
  toggleTimelineCollapse(): void {
    this.advancedEditor.toggleTimelineCollapse();
  }

  @action
  async saveScene(scene: Scene): Promise<void> {
    await this.args.eventRegister.facade.saveScene(scene);
    this.resetSeekPosition();
  }

  @action
  async saveSceneOrder(): Promise<void> {
    await this.args.eventRegister.facade.saveSceneOrder(this.args.timeline);
    this.resetSeekPosition();
  }

  get collapsedTimeline(): boolean {
    return this.advancedEditor.collapsed;
  }

  private resetSeekPosition(): void {
    if (this.args.selectedCaptionId) {
      const selectedCaption = this.args.timeline.getCaptionById(this.args.selectedCaptionId);
      if (selectedCaption) {
        void this.playback.stop(getCaptionPreviewTime(this.args.timeline, selectedCaption));
      }
    } else if (this.args.selectedSceneId) {
      const scene = this.args.timeline.getSceneById(this.args.selectedSceneId);
      if (scene) {
        const caption = scene.captions[0];
        if (caption) {
          void this.playback.stop(getCaptionPreviewTime(this.args.timeline, caption));
        } else {
          void this.playback.stop(getSceneHalfwayPoint(this.args.timeline, scene));
        }
      }
    }
  }
}
