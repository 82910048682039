import { guidFor } from "@ember/object/internals";
import Component from "@glimmer/component";

interface AppBiteableLogo {
  showIcon?: boolean;
}

export default class AppBiteableLogoComponent extends Component<AppBiteableLogo> {
  elementID = guidFor(this);
}
