import { action } from "@ember/object";
import { isEqual } from "@ember/utils";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SelectOptionComponentArgs {
  onClick?(event: MouseEvent): unknown;
  onInsert?(component: SelectOptionComponent): unknown;
  onRemove?(component: SelectOptionComponent): unknown;
  onSelect?(component: SelectOptionComponent): unknown;

  selectedValue: unknown;
  value: unknown;
}

export default class SelectOptionComponent extends Component<SelectOptionComponentArgs> {
  private _element?: HTMLElement;
  styleNamespace = getStyleNamespace("north-star/select/option");

  get el(): HTMLElement | undefined {
    return this._element;
  }

  get checked(): string {
    return String(this.active);
  }

  get active(): boolean {
    return isEqual(this.args.selectedValue, this.args.value);
  }

  @action
  onClick(event: MouseEvent): void {
    this.args.onClick?.(event);
    this.args.onSelect?.(this);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
    this.args.onInsert?.(this);
  }

  @action
  didUpdateSelectedValue(): void {
    if (this.active) {
      this._element?.focus();
    }
  }

  willDestroy(): void {
    super.willDestroy();
    this.args.onRemove?.(this);
  }
}
