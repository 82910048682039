import { tracked } from "@glimmer/tracking";
import type { License, OriginalAsset } from "../../asset";
import { Asset } from "../../asset";
import type { Frame } from "../image/frame";
import type { TimeSlice } from "./time-slice";

export interface VideoAsset {
  name?: string;
  url?: string;
  previewImageUrl?: string;
  previewVideoUrl?: string;
  lowResVideoUrl?: string;
  downsizedUrlBase?: string;
  encodingLevel?: number;
}

export interface VolumeOptions {
  fadeIn?: boolean;
  fadeOut?: boolean;
  mute?: boolean;
  volume?: number;
  audioDuckingLevel?: number;
}

export class VideoClip extends Asset {
  @tracked
  public asset: VideoAsset;

  @tracked
  public hasAudio?: boolean;

  @tracked
  public mute = false;

  @tracked
  public fadeIn = false;

  @tracked
  public fadeOut = false;

  @tracked
  public audioDuckingLevel?: number;

  @tracked
  public volume?: number;

  @tracked
  public trim?: TimeSlice;

  @tracked
  _loopable: boolean;

  @tracked
  _animation: string;

  constructor(
    asset: VideoAsset,
    hasAudio: boolean,
    loopable: boolean,
    animation: string,
    volumeOptions?: VolumeOptions,
    trim?: TimeSlice,
    mirror?: boolean,
    objectFit?: string,
    originalAsset?: OriginalAsset,
    license?: License,
    frame?: Frame
  ) {
    super(mirror ?? false, objectFit ?? "fill", originalAsset, license, frame);
    this.asset = asset;
    this.hasAudio = hasAudio;
    this._loopable = loopable;
    this._animation = animation;
    Object.assign(this, volumeOptions);
    this.trim = trim;
  }

  get hasContent(): boolean {
    return !!this.asset.url;
  }

  get category(): string {
    return "video";
  }

  get formattedCategory(): string {
    return "video";
  }

  get previewImageUrl(): string | undefined {
    return this.asset?.previewImageUrl;
  }

  get loopable(): boolean {
    return this._loopable;
  }

  get animation(): string {
    return this._animation;
  }

  _reset(): void {
    super._reset();
    this.asset = {};
  }
}
