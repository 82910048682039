import type { Caption } from "client/lib/editor-domain-model";
import { Text, Logo } from "client/lib/editor-domain-model";

export type CaptionContent = Text | Logo;

export const isText = (item?: CaptionContent): item is Text => typeof item !== "undefined" && item instanceof Text;
export const isLogo = (item?: CaptionContent): item is Logo => typeof item !== "undefined" && item instanceof Logo;

export const isCaptionEmpty = ({ texts, logos }: Caption): boolean => texts.length === 0 && logos.length === 0;

export const latestCaptionEnd = (captions: Caption[]): number => {
  if (!captions.length) {
    return 0;
  }

  return Math.max(...captions.map((c) => c.end));
};
