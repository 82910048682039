import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class BrandEditModalService extends Service {
  @tracked
  showEditBrandModal = false;

  @action
  show(): void {
    this.showEditBrandModal = true;
  }

  @action
  hide(): void {
    this.showEditBrandModal = false;
  }
}
