import { setProperties } from "@ember/object";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { Blob } from "@rails/activestorage";
import { ProjectThumbnailBuilder } from "client/lib/project-thumbnail-builder";
import type Project from "client/models/project";
import type ProjectPreview from "client/models/project-preview";
import type ActiveStorageService from "client/services/active-storage";
import type HoneybadgerService from "client/services/honeybadger";

export default class ProjectThumbnailUpdateService extends Service {
  @service
  declare activeStorage: ActiveStorageService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare store: Store;

  async generateProjectThumbnail(project: Project | ProjectPreview): Promise<string | undefined> {
    if (project.canUploadThumbnail) {
      try {
        const projectThumbnailBuilder = new ProjectThumbnailBuilder({ project });
        await projectThumbnailBuilder.generate();
        project.posterUrl = await projectThumbnailBuilder.getCanvasDataUrl();
        void this.persistThumbnail(project, projectThumbnailBuilder);

        return project.posterUrl;
      } catch (err) {
        this.honeybadger.notify(err as Error, "ProjectThumbnailUpdateService");
      }
    }

    return undefined;
  }

  private async persistThumbnail(
    project: Project | ProjectPreview,
    projectThumbnailBuilder: ProjectThumbnailBuilder
  ): Promise<void> {
    if (!project.isNew && projectThumbnailBuilder) {
      const canvasBlob = await projectThumbnailBuilder.getBlob();
      if (canvasBlob) {
        const file = new File([canvasBlob], `project-${project.id}-thumbnail`);
        const blob: Blob & { key?: string } = await this.activeStorage.silentRawUploadFile(file);

        if (!blob.key) {
          throw Error("`key` is missing");
        }

        const projectThumbnail = this.store.createRecord("projectThumbnail");

        // Ember can fail to set a Model's properties when defined in `createRecord`. This fixes:
        setProperties(projectThumbnail, {
          projectId: project.id,
          key: blob.key
        });

        try {
          await projectThumbnail.save();
        } catch (err) {
          this.honeybadger.notify(err as Error, "ProjectThumbnailUpdateService");
        }
      }
    }
  }
}
