import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectRouteParams } from "client/authenticated/project/route";
import type ZymbolFootage from "client/models/zymbol-footage";
import type { LibrarySearchRouteParams } from "client/routes/library-search";
import LibrarySearchRoute from "client/routes/library-search";

export default class VideosSearchRoute extends LibrarySearchRoute {
  @service
  declare store: Store;

  searchParentRouteName = "authenticated.project.scenes.library";
  type = "video";
  provider = "storyblocks";

  errorNotificationMessage = "There was a problem retrieving videos";

  async getResults(params: LibrarySearchRouteParams): Promise<ZymbolFootage[]> {
    const { projectId } = this.paramsFor("authenticated.project") as ProjectRouteParams;

    // @ts-expect-error
    return this.store.query("zymbolFootage", {
      ...params,
      project_id: projectId // eslint-disable-line camelcase
    }) as ZymbolFootage[];
  }
}
