import { action } from "@ember/object";
import Component from "@glimmer/component";
import { TextDirection, detectTextDirection, GOOGLE_FONTS } from "renderer-engine";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { FontInfo, FontVariant, Text, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getWeightAndStyleAsVariant } from "client/lib/text/fonts";
import type { EditableText } from "client/lib/text/text-editor";
import { BOLD_WEIGHT, NORMAL_WEIGHT, isBoldWeight } from "client/lib/text/text-styles";
import type BrandStyle from "client/models/brand-style";

interface Args {
  media: Text;
  editables: Map<string, EditableText>;
  brandStyle?: BrandStyle;
  timeline: Timeline;
}
export default class ToolbarFontComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/font");

  get unorderedList(): boolean {
    return this.editableText?.textStyle.list === "bullet";
  }

  @action
  onUnorderedList(): void {
    if (this.supportsUnorderedList) {
      this.editableText?.applyStyle({
        list: this.unorderedList ? "" : "bullet"
      });
    }
  }

  @action
  onOrderedList(): void {
    if (this.supportsOrderedList) {
      this.editableText?.applyStyle({
        list: this.orderedList ? "" : "ordered"
      });
    }
  }

  @action
  onBold(): void {
    if (this.supportsBold) {
      this.editableText?.applyStyle({
        fontVariant: {
          weight: String(this.bold ? this.editableText?.textStyle.defaultWeight ?? NORMAL_WEIGHT : BOLD_WEIGHT)
        } as FontVariant
      });
    }
  }

  get orderedList(): boolean {
    return this.editableText?.textStyle.list === "ordered";
  }

  get bold(): boolean {
    const weight = this.editableText?.textStyle.fontVariant?.weight;
    return !!weight && (weight.toLowerCase() === "bold" || isBoldWeight(weight));
  }

  get italics(): boolean {
    return this.editableText?.textStyle.fontVariant?.style?.toLowerCase() === "italic";
  }

  @action
  onItalics(): void {
    if (this.supportsItalics) {
      this.editableText?.applyStyle({
        fontVariant: {
          style: this.italics ? "normal" : "italic"
        } as FontVariant
      });
    }
  }

  @action
  onChangeFontColor(color: string): void {
    this.editableText?.applyStyle({
      color
    });
  }

  @action
  onChangeBackgroundColor(color: string): void {
    this.editableText?.applyStyle({
      ribbonColor: color
    });
  }

  get textColors(): string[] {
    return this.editableText?.textStyle.color ? [this.editableText?.textStyle.color] : [];
  }

  get ribbonColors(): string[] {
    return this.editableText?.textStyle.ribbonColor ? [this.editableText?.textStyle.ribbonColor] : [];
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get brandStyleConfig(): BrandStyleConfig | undefined {
    return this.brand?.style;
  }

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.media.id);
  }

  get supportsBold(): boolean {
    return this.supports(({ weight }) => isBoldWeight(weight));
  }

  get supportsItalics(): boolean {
    return this.supports(({ style }) => style === "italic");
  }

  get supportsUnorderedList(): boolean {
    return !this.isRightToLeft;
  }

  get supportsOrderedList(): boolean {
    return !this.isRightToLeft;
  }

  get isRightToLeft(): boolean {
    return detectTextDirection(this.args.media.content) === TextDirection.RTL;
  }

  get variants(): FontVariant[] {
    const fontFamily = this.editableText?.textStyle.fontFamily;
    return this.fonts.find((f) => f.name === fontFamily)?.variants || [];
  }

  get fonts(): FontInfo[] {
    return [
      ...this.args.timeline.customFonts,
      ...GOOGLE_FONTS.map(({ family, variants, subsets }) => ({
        name: family,
        variants: variants.map(getWeightAndStyleAsVariant),
        subsets
      }))
    ];
  }

  private supports(enabled: (variant: FontVariant) => boolean): boolean {
    // The buttons are useful if both the enabled and disabled state exist,
    // e.g. bold and normal, or italics and non-italics.
    return !!this.variants.find(enabled) && !!this.variants.find((v) => !enabled(v));
  }
}
