import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import { isEmpty } from "@ember/utils";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Bounds, Size } from "renderer-engine";
import { SceneTransition, AnimationFit } from "renderer-engine";
import { generateNewCaption } from "../project-editor/component";
import TrackingEvents, { Locations } from "client/events";
import type { Caption, Element, EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import {
  Alignments,
  Background,
  ChangeAssetMirrorMutation,
  ChangeAssetOffsetMutation,
  ChangeObjectFitMutation,
  ChangePositionMutation,
  DeleteCaptionElementMutation,
  DeleteCaptionMutation,
  DuplicateElementMutation,
  getDefaultRect,
  Image,
  Logo,
  Media,
  MoveLayerMutation,
  Rect,
  ResetAssetMutation,
  SceneDurationMutationFactory,
  SetLayerOrderMutation,
  Text,
  UpdateAssetMutation,
  UpdateFrameMutation,
  VideoClip,
  Watermark
} from "client/lib/editor-domain-model";
import { CoalescingTransactionManager } from "client/lib/editor-domain-model/events/coalescing-transaction-manager";
import { ChangeAssetRotationMutation } from "client/lib/editor-domain-model/events/mutations/change-asset-rotation-mutation";
import { ResetMediaMutation } from "client/lib/editor-domain-model/events/mutations/reset-media-mutation";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { Grid } from "client/lib/grid-guides";
import { Rect as GridRect } from "client/lib/grid-guides";
import { getSnapLines } from "client/lib/grid-guides/snapper";
import MediaTypes from "client/lib/media-types";
import { Queue } from "client/lib/queue";
import { isCaptionEmpty } from "client/lib/timeline/caption";
import { getLayerOrderTrackingProperties } from "client/lib/timeline/element";
import {
  defaultLowerSizeLimit,
  defaultUpperSizeLimit,
  getClampedAssetOffset,
  getResetPosition
} from "client/lib/timeline/media";
import { transaction } from "client/lib/transaction";
import { getVideoDuration } from "client/lib/video";
import type Project from "client/models/project";
import type Layer from "client/models/zymbols/layer-order";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AdvancedTimingService from "client/services/advanced-timing";
import type ConfirmService from "client/services/confirm";
import type CopyPasteService from "client/services/copy-paste";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type PlaybackService from "client/services/playback";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ProjectsService from "client/services/projects";
import type { ProjectSettings } from "client/services/projects";
import type TrackingService from "client/services/tracking";

export enum Sources {
  RESIZER = "resizer",
  SCALER = "scaler",
  MOVER = "mover"
}

export enum NudgeDirections {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right"
}

export const canvasWidth = 1920;
export const canvasHeight = 1080;

const WATERMARK_DELETE_PROMPT = "watermark_delete_prompt";
const REMOVING_KEYS = ["Backspace", "Delete"];
const COMMIT_DELAY_MS = 1000;

export default class ProjectCanvasComponent extends Component<{
  project: Project;
  canvasBounds: Size;
  grid?: Grid;
  timeline: Timeline;
  eventRegister: EventRegister;
}> {
  elementIsText = (element: Element): boolean => {
    return element instanceof Text;
  };

  elementId = guidFor(this);

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare advancedTiming: AdvancedTimingService;

  @service
  private declare permissions: PermissionsService;

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare copyPaste: CopyPasteService;

  @service
  declare playback: PlaybackService;

  @service
  declare projects: ProjectsService;

  @service
  declare confirm: ConfirmService;

  @service
  declare notifications: NotificationsService;

  @service
  declare tracking: TrackingService;

  @service
  declare honeybadger: HoneybadgerService;

  styleNamespace = getStyleNamespace("tidal/project-canvas");

  get scene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  get background(): Background | undefined {
    return this.scene?.background;
  }

  get isBackground(): boolean | undefined {
    return this.background === this.selectedElement;
  }

  get hasRemovableBackground(): boolean {
    return !!this.background?.hasContent;
  }

  get backgroundIsEmpty(): boolean | undefined {
    return this.isBackground && !this.hasRemovableBackground;
  }

  get selectedCaption(): Caption | undefined {
    return this.advancedEditor.caption;
  }

  get selectedElement(): Element | undefined {
    return this.advancedEditor.element;
  }

  get showSelection(): boolean | undefined {
    return this.advancedEditor.showSelection;
  }

  get watermark(): Watermark | undefined {
    return this.args.project.userWatermarkVisible ? this.args.timeline.watermark : undefined;
  }

  get isPlaying(): boolean {
    return this.playback.playing;
  }

  queue = new Queue();

  @tracked
  mediaToCrop?: Media;

  get isCropping(): boolean {
    return !!this.mediaToCrop?.canCrop && this.mediaToCrop === this.selectedElement;
  }

  get isEditing(): boolean {
    return !this.isPlaying;
  }

  get showBackground(): boolean | undefined {
    return this.isBackground && this.showSelection;
  }

  canvasElement?: HTMLCanvasElement;

  @action
  async didInsertCanvas(canvasElement: HTMLCanvasElement): Promise<void> {
    this.canvasElement = canvasElement;

    await this.playback.setup(canvasElement);
  }

  @action
  async willDestroyCanvas(): Promise<void> {
    await this.playback.clear();
  }

  get canvasStyle(): SafeString {
    const b = this.args.canvasBounds;
    return htmlSafe(`width: ${b.width}px; height: ${b.height}px;`);
  }

  get elementsForIntersecting(): Element[] {
    const elements = [];
    if (this.selectedCaption) {
      elements.push(...this.selectedCaption.elements);
    }

    if (this.background) {
      elements.push(this.background);
    }

    if (this.watermark) {
      elements.push(this.watermark);
    }

    return elements;
  }

  @action
  copy(e: Event, origin?: string): void {
    if (this.backgroundIsEmpty) {
      return;
    }

    const elementToDup = this.selectedElement ?? this.background;

    if (elementToDup && this.copyPaste.allowCopyPaste) {
      e.preventDefault();

      this.copyPaste.copy(elementToDup, this.selectedCaption);
      this.notifications.success("Element copied");

      this.trackCopyAndPaste("copy", elementToDup, e, origin);
    }
  }

  @action
  async paste(e: Event, origin?: string): Promise<void> {
    if (e.target instanceof HTMLElement && (e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA")) {
      return;
    }

    try {
      if (this.copyPaste.copiedObject && this.copyPaste.allowCopyPaste) {
        e.preventDefault();

        const success = await this.duplicateElement(this.copyPaste.copiedObject, this.copyPaste.sourceCaption);
        if (success) {
          this.notifications.success("Element pasted");

          this.trackCopyAndPaste("paste", this.copyPaste.copiedObject, e, origin);
        } else {
          this.notifications.warning("Unable to paste element. Please try again");
        }
      }
    } catch (error: any) {
      if (error.message.indexOf("it has been destroyed") > 0) {
        this.notifications.warning("Unable to paste element. Copied object has been deleted");
      } else {
        this.notifications.warning("Unable to paste element. Please try again");
      }
    }
  }

  @action
  async delete(e: Event): Promise<void> {
    if (e.target && (e.target as HTMLElement).classList.contains("ql-editor")) {
      // Prevents Backspace on text editor from deleting the caption
      return;
    }

    if (
      document.activeElement &&
      (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")
    ) {
      // If it's a text input or textarea, just return and do nothing
      return;
    }

    e.preventDefault();
    if (this.selectedElement instanceof Logo) {
      await this.removeCaptionElement(this.selectedElement);
    } else if (this.selectedElement instanceof Background) {
      await this.removeBackground();
    } else if (this.selectedElement instanceof Watermark) {
      await this.hideWatermark();
    }
  }

  private manager = new CoalescingTransactionManager(this.args.eventRegister);

  @action
  async onCropChange(media: Media, bounds: Bounds): Promise<void> {
    await this.manager.transaction(async () => {
      const { eventRegister } = this.args;
      const { scene } = this;

      if (!scene) {
        return;
      }

      const assetOffset: [number, number] = [media.position.x - bounds.x, media.position.y - bounds.y];
      eventRegister.fire(new ChangeAssetOffsetMutation(media, assetOffset));

      await this.saveElement(media, scene);
    });
  }

  @action
  startCropping(element: Element): void {
    this.manager.new();
    if (element instanceof Media && element.canCrop) {
      this.mediaToCrop = element;
    }
  }

  @action
  endCropping(): void {
    this.mediaToCrop = undefined;
    this.manager.new();
  }

  @action
  @transaction
  async duplicateElement(element: Element, sourceCaption?: Caption): Promise<boolean> {
    const { eventRegister } = this.args;
    const { scene, selectedCaption } = this;

    if (!scene) {
      return false;
    }

    const caption = selectedCaption ?? (await generateNewCaption(scene, eventRegister));

    if (!caption) {
      return false;
    }

    const mutation = new DuplicateElementMutation(caption, element.id, sourceCaption?.id);
    await mutation.prepare(eventRegister.facade);
    const newElement = eventRegister.fire(mutation);
    await eventRegister.facade.saveScene(scene);

    if (newElement) {
      if (newElement instanceof Logo) {
        await this.advancedEditor.transitionToLogo(caption, newElement);
      } else {
        await this.advancedEditor.transitionToText(caption, newElement);
      }
      return true;
    } else {
      this.notifications.error("There was a problem duplicating the caption");
      return false;
    }
  }

  @action
  @transaction
  async moveToBackground(logo: Logo): Promise<void> {
    if (this.scene && this.background) {
      const { eventRegister } = this.args;

      await this.resetBackgroundAspectRatio();
      await eventRegister.fire(new UpdateAssetMutation(this.background, logo.asset));
      await this.removeCaptionElement(logo);
      await eventRegister.facade.saveScene(this.scene);
    }
  }

  @action
  @transaction
  async swapWithBackground(logo: Logo): Promise<void> {
    if (this.scene && this.background && this.selectedElement && this.selectedCaption) {
      const { eventRegister } = this.args;
      const position = logo.position;
      const originalLayerOrder = logo.layerOrder;

      await this.duplicateElement(this.background);
      await this.updateElementPosition(this.selectedElement, position);
      if (logo.asset) {
        await eventRegister.fire(new UpdateFrameMutation(logo.asset, undefined));
      }
      await eventRegister.fire(new UpdateAssetMutation(this.background, logo.asset));
      await this.removeCaptionElement(logo);
      await eventRegister.fire(
        new SetLayerOrderMutation(this.selectedElement, this.selectedCaption, originalLayerOrder)
      );
      await eventRegister.facade.saveScene(this.scene);
    }
  }

  @action
  async replace(media: Media, event: Event): Promise<void> {
    event?.stopPropagation();
    await this.projectContentBar.startAddOrReplaceMedia(media);
  }

  @action
  @transaction
  async resetAspectRatio(media: Media): Promise<void> {
    const { eventRegister } = this.args;

    if (this.scene) {
      if (media.asset) {
        await eventRegister.fire(new UpdateFrameMutation(media.asset, undefined));
      }

      let position;
      if (media instanceof Background) {
        position = await getResetPosition(media, this.scene, { alignMediaToCenter: true });
      } else {
        position = await getResetPosition(media, this.scene, {
          alignMediaToCenter: true,
          upperSizeLimit: defaultUpperSizeLimit,
          lowerSizeLimit: defaultLowerSizeLimit
        });
      }
      await eventRegister.fire(new ChangePositionMutation(media, position));
      await eventRegister.fire(new ChangeAssetOffsetMutation(media, undefined));
      if (media.asset) {
        await eventRegister.fire(new ChangeObjectFitMutation(media.asset, AnimationFit.FILL));
      }
      await eventRegister.facade.saveScene(this.scene);
    }
  }

  @action
  async resetBackgroundAspectRatio(): Promise<void> {
    const background = this.scene?.background;

    if (background?.asset) {
      await this.resetAspectRatio(background);
    }
  }

  @action
  @transaction
  async moveToForeground(background: Background): Promise<boolean> {
    await this.duplicateElement(background);
    await this.removeBackground();
    await this.moveToForegroundPosition();
    return true;
  }

  async moveToForegroundPosition(): Promise<void> {
    if (this.selectedElement && this.selectedElement instanceof Media && this.scene) {
      const position = await getResetPosition(this.selectedElement, this.scene, {
        alignMediaToCenter: true,
        upperSizeLimit: defaultUpperSizeLimit
      });
      await this.updateElementPosition(this.selectedElement, position);
    }
  }

  @action
  async editTiming(event: Event): Promise<void> {
    if (event) {
      event.stopPropagation();
    }

    if (this.scene && this.selectedCaption) {
      void this.advancedTiming.open(this.selectedCaption, this.scene);
    }
  }

  @action
  async fitToClip(logo: Logo): Promise<void> {
    if (this.scene) {
      if (new MediaTypes(logo).isVideo() && logo.asset instanceof VideoClip && logo.asset.asset.previewVideoUrl) {
        let duration = await getVideoDuration(logo.asset.asset.previewVideoUrl);

        duration = this.roundDownToIncrement(duration, 0.5);
        duration = this.subtractNextSceneTransition(duration, this.scene);

        const factory = new SceneDurationMutationFactory(this.scene);
        const mutation = factory.createMutation(duration);
        this.args.eventRegister.fire(mutation);
        await this.args.eventRegister.facade.saveScene(this.scene);
      }
    }
  }

  private subtractNextSceneTransition(duration: number, scene: Scene): number {
    const { timeline } = this.args;
    const scenes = Array.from(timeline.scenes);
    const nextScene = scenes[scenes.indexOf(scene) + 1];

    if (nextScene?.transition && nextScene.transition.name !== SceneTransition.NONE) {
      return Math.max(0, duration - 0.5);
    } else {
      return duration;
    }
  }

  private roundDownToIncrement(duration: number, increment: number): number {
    const f = Math.floor(duration);
    if (duration - f < increment) {
      return f;
    }
    return f + increment;
  }

  @action
  async removeBackground(): Promise<void> {
    const { eventRegister } = this.args;

    if (!this.scene) {
      return;
    }

    const { background } = this.scene;

    if (!background?.asset) {
      return;
    }

    eventRegister.fire(new ResetAssetMutation(background.asset));

    if (background instanceof Background) {
      eventRegister.fire(new ChangePositionMutation(background, getDefaultRect()));
      eventRegister.fire(new ChangeAssetOffsetMutation(background, undefined));
      eventRegister.fire(new ResetMediaMutation(background));
    }

    await eventRegister.facade.saveScene(this.scene);
  }

  @action
  @transaction
  async removeCaptionElement(element: Element): Promise<void> {
    const { eventRegister } = this.args;
    const { scene, selectedCaption: caption } = this;

    if (!scene || !caption) {
      return;
    }

    eventRegister.fire(new DeleteCaptionElementMutation(caption, element));

    const isOnlyChild = isCaptionEmpty(caption);
    if (isOnlyChild) {
      eventRegister.fire(new DeleteCaptionMutation(caption));
    }
    await eventRegister.facade.saveScene(scene);

    await this.onCaptionElementRemoved(isOnlyChild);
  }

  @action
  async onCaptionElementRemoved(isOnlyChild: boolean): Promise<void> {
    if (isOnlyChild) {
      this.advancedEditor.unsetCaption();
      await this.selectBackground();
    } else {
      await this.selectLastElementInCaption();
    }
  }

  @action
  async selectBackground(showSelected = true): Promise<void> {
    if (this.scene) {
      await this.advancedEditor.transitionToBackground(showSelected);
    }
  }

  @action
  async selectWatermark(): Promise<void> {
    if (this.scene) {
      await this.advancedEditor.transitionToWatermark();
    }
  }

  @action
  async select(element: Element): Promise<void> {
    if (this.selectedCaption) {
      if (element instanceof Text) {
        await this.advancedEditor.transitionToText(this.selectedCaption, element);
      } else if (element instanceof Logo) {
        await this.advancedEditor.transitionToLogo(this.selectedCaption, element);
      }
    }
  }

  @action
  moveStart(): void {
    this.manager.new();
  }

  @action
  moveEnd(): void {
    this.args.grid?.clearGuides();
    this.manager.new();
  }

  @action
  async move(element: Element, position: Bounds): Promise<void> {
    await this.manager.transaction(async () => {
      await this.updateElementPosition(element, position, Sources.MOVER);
    });
  }

  @tracked
  previousNudgeElement?: Element;

  @tracked
  previousNudgeDirection?: NudgeDirections;

  @action
  async nudge(nudgeDirection: NudgeDirections, nudgeSizeInPixels: number, e: Event): Promise<void> {
    // prevent interaction with quill
    if (e.target && (e.target as HTMLElement).classList.contains("ql-editor")) {
      return;
    }

    if (
      document.activeElement &&
      (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")
    ) {
      return;
    }

    e.preventDefault();

    if (this.selectedElement !== this.previousNudgeElement || nudgeDirection !== this.previousNudgeDirection) {
      this.manager.new();
    }

    let xDiff = 0;
    let yDiff = 0;

    switch (nudgeDirection) {
      case NudgeDirections.UP:
        yDiff -= nudgeSizeInPixels / canvasHeight;
        break;
      case NudgeDirections.DOWN:
        yDiff += nudgeSizeInPixels / canvasHeight;
        break;
      case NudgeDirections.LEFT:
        xDiff -= nudgeSizeInPixels / canvasWidth;
        break;
      case NudgeDirections.RIGHT:
        xDiff += nudgeSizeInPixels / canvasWidth;
        break;
      default:
        break;
    }

    const element = this.selectedElement;

    if (!element) {
      return;
    }

    const { x, y, width, height } = element.position;
    const newPosition = new Rect(x + xDiff, y + yDiff, width, height);

    await this.manager.transaction(async () => {
      await this.updateElementPosition(element, newPosition);
    });

    this.previousNudgeElement = this.selectedElement;
    this.previousNudgeDirection = nudgeDirection;
  }

  @action
  async flipAssetHorizontally(e: Event): Promise<void> {
    if (e.target && (e.target as HTMLElement).classList.contains("ql-editor")) {
      return;
    }

    if (
      document.activeElement &&
      (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")
    ) {
      return;
    }

    if (!this.scene || !(this.selectedElement instanceof Media) || !this.selectedElement.asset) {
      return;
    }

    const { eventRegister } = this.args;
    const asset = this.selectedElement.asset;

    eventRegister.fire(new ChangeAssetMirrorMutation(asset, !asset.mirror));
    await eventRegister.facade.saveScene(this.scene);
  }

  @action
  resizeStart(): void {
    this.manager.new();
  }

  @action
  async resize(element: Element, source: Sources, position: Bounds): Promise<void> {
    await this.manager.transaction(async () => {
      await this.updateElementPosition(element, position, source);
    });
  }

  @action
  resizeEnd(): void {
    this.manager.new();
  }

  @action
  async hideWatermark(): Promise<void> {
    if (isEmpty(sessionStorage.getItem(WATERMARK_DELETE_PROMPT))) {
      const confirmed = await this.confirm.open({
        title: "Delete the watermark?",
        message: "You can bring it back in video settings",
        confirmLabel: "Delete it",
        cancelLabel: "Cancel"
      });

      if (!confirmed) {
        return;
      }
    }

    await this.selectBackground();

    sessionStorage.setItem(WATERMARK_DELETE_PROMPT, "hide");
    await this.projects.updateProjectSettings(this.args.project, { userWatermarkVisible: false } as ProjectSettings);
  }

  async updateElementPosition(element: Element, position: Bounds, source?: Sources): Promise<void> {
    const { eventRegister } = this.args;
    const { scene } = this;

    if (!scene) {
      return;
    }

    if ((source === Sources.RESIZER || source === Sources.SCALER) && element instanceof Media && element.assetOffset) {
      const [offsetX, offsetY] = element.assetOffset;
      const xDelta = element.position.x - position.x;
      const yDelta = element.position.y - position.y;
      const assetOffset: [number, number] = [offsetX - xDelta, offsetY - yDelta];
      const clampedOffset = await getClampedAssetOffset(element, assetOffset, scene);

      eventRegister.fire(new ChangeAssetOffsetMutation(element, clampedOffset));
    }

    eventRegister.fire(new ChangePositionMutation(element, Rect.fromRect(position)));

    if (source === Sources.MOVER && this.args.grid) {
      const isNotSelected = (e: Element): boolean => e !== this.selectedElement;
      const getGridRect = (e: Element): GridRect => GridRect.fromRectLike(e.position);

      const gridRects = this.elementsForIntersecting.filter(isNotSelected).map(getGridRect);
      const snapLines = getSnapLines(gridRects, this.args.grid);
      const snappedRect = this.args.grid.snapRect(getGridRect(element), { snapLines });

      if (!this.supportsElementManipulation(element)) {
        const position = Rect.fromRect({ ...element.position, x: snappedRect.x, y: snappedRect.y });
        eventRegister.fire(new ChangePositionMutation(element, position));
      }

      this.args.grid.updateGuides(snappedRect, { snapLines, rects: gridRects });
    }

    await this.saveElement(element, scene);
  }

  async selectLastElementInCaption(): Promise<void> {
    if (!this.selectedCaption) {
      return;
    }

    const last = this.selectedCaption.elements[this.selectedCaption.elements.length - 1];

    if (last) {
      await this.select(last);
    } else {
      this.advancedEditor.unsetElement();
    }
  }

  @action
  @transaction
  async moveForward(element: Element, caption: Caption): Promise<void> {
    const { eventRegister } = this.args;

    const mutation = new MoveLayerMutation(element, caption, 1);
    eventRegister.fire(mutation);

    await eventRegister.facade.saveScene(caption.scene);
    await this.trackingOrderLayers("forward", element, caption);
  }

  @action
  @transaction
  async moveToFront(element: Element, caption: Caption): Promise<void> {
    const { eventRegister } = this.args;

    while (!this.highestLayer(element, caption)) {
      const mutation = new MoveLayerMutation(element, caption, 1);
      eventRegister.fire(mutation);
    }

    await eventRegister.facade.saveScene(caption.scene);
    await this.trackingOrderLayers("front", element, caption);
  }

  @action
  @transaction
  async moveToBack(element: Element, caption: Caption): Promise<void> {
    const { eventRegister } = this.args;

    while (!this.lowestLayer(element, caption)) {
      const mutation = new MoveLayerMutation(element, caption, -1);
      eventRegister.fire(mutation);
    }

    await eventRegister.facade.saveScene(caption.scene);
    await this.trackingOrderLayers("back", element, caption);
  }

  @action
  @transaction
  async moveBackward(element: Element, caption: Caption): Promise<void> {
    const { eventRegister } = this.args;

    const mutation = new MoveLayerMutation(element, caption, -1);
    eventRegister.fire(mutation);

    await eventRegister.facade.saveScene(caption.scene);
    await this.trackingOrderLayers("backward", element, caption);
  }

  @action
  highestLayer(element: Element, caption: Caption): boolean {
    return element === caption.elementsSortedByLayer[caption.elementsSortedByLayer.length - 1];
  }

  @action
  lowestLayer(element: Element, caption: Caption): boolean {
    return element === caption.elementsSortedByLayer[0];
  }

  @action
  getLayer(element: Element, caption: Caption): Layer {
    return {
      layerOrder: element.layerOrder,
      moveLayerForward: (): Promise<void> => this.moveForward(element, caption),
      moveLayerBackward: (): Promise<void> => this.moveBackward(element, caption),
      moveLayerToFront: (): Promise<void> => this.moveToFront(element, caption),
      moveLayerToBack: (): Promise<void> => this.moveToBack(element, caption),
      highestLayer: (): boolean => this.highestLayer(element, caption),
      lowestLayer: (): boolean => this.lowestLayer(element, caption)
    };
  }

  @action
  didInsert(): void {
    window.addEventListener("keydown", this.handleKeypress);
  }

  willDestroy(): void {
    super.willDestroy();

    window.removeEventListener("keydown", this.handleKeypress);
  }

  @action
  handleKeypress(event: KeyboardEvent): void {
    const { ctrlKey, shiftKey, key } = event;

    const hasWatermark = !!this.watermark && this.watermark === this.selectedElement;
    if (hasWatermark && REMOVING_KEYS.includes(event.key)) {
      void this.hideWatermark();
    }

    if (ctrlKey && shiftKey) {
      switch (key) {
        case "ArrowRight":
          void this.playback.moveForwardsFrame();
          break;
        case "ArrowLeft":
          void this.playback.moveBackwardsFrame();
          break;
      }
    }
  }

  @action
  async boundsMouseDown({ currentTarget, target }: MouseEvent): Promise<void> {
    if (target === currentTarget) {
      await this.selectBackground();
    }
  }

  private async saveElement(element: Element, scene: Scene): Promise<void> {
    const { eventRegister } = this.args;

    if (element instanceof Watermark) {
      await eventRegister.facade.saveWatermark(element, {
        delayCommitMs: COMMIT_DELAY_MS
      });
    } else {
      await eventRegister.facade.saveScene(scene, {
        delayCommitMs: COMMIT_DELAY_MS
      });
    }
  }

  @action
  getJustifyContent(element: Element): string | undefined {
    if (element instanceof Text) {
      const { yAlignment } = element;

      switch (yAlignment) {
        case Alignments.CENTER: {
          return "center";
        }
        case Alignments.BOTTOM: {
          return "flex-end";
        }
        case Alignments.TOP:
        default: {
          return "flex-start";
        }
      }
    }

    return;
  }

  private trackCopyAndPaste(action: string, element: Element, event: Event, copyPasteOrigin?: string): void {
    if (event instanceof KeyboardEvent) {
      copyPasteOrigin = "keyboard";
    }

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_COPY_AND_PASTE, {
      projectId: this.args.project.id,
      sceneId: this.scene?.id,
      elementId: element.id,
      elementType: element.formattedCategory,
      action: action,
      origin: copyPasteOrigin || "unknown"
    });

    if (copyPasteOrigin === undefined) {
      this.honeybadger.notify("Unknown Copy & Paste Origin", {
        context: {
          action: action,
          element: element.id,
          event: event.type
        }
      });
    }
  }

  private async trackingOrderLayers(
    direction: "backward" | "forward" | "back" | "front",
    element: Element,
    caption: Caption
  ): Promise<void> {
    const trackingProperties = getLayerOrderTrackingProperties(
      this.args.timeline,
      caption,
      element,
      direction,
      Locations.LOCATION_CANVAS
    );
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_CHANGE_ELEMENT_LAYERS, trackingProperties);
  }

  hasImageAsset(element: Element): boolean {
    if (element instanceof Logo && element.asset instanceof Image && element.asset?.hasContent) {
      return true;
    }

    return false;
  }

  @action
  async onRotateElement(element: Element, rotation: number): Promise<void> {
    await this.manager.transaction(async () => {
      const { eventRegister } = this.args;
      const { scene } = this;

      if (!scene) {
        return;
      }

      if (element instanceof Logo && element.asset instanceof Image) {
        eventRegister.fire(new ChangeAssetRotationMutation(element.asset, rotation));

        await this.saveElement(element, scene);
      }
    });
  }

  @action
  supportsElementManipulation(element: Element): boolean {
    return (
      this.permissions.has("feature_element_manipulation") && element instanceof Logo && element.asset instanceof Image
    );
  }
}
