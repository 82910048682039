import { tracked } from "@glimmer/tracking";
import type { ColorBrandKey } from "../../brand";

export class Frame {
  @tracked
  public shape: string;

  @tracked
  public color: string;

  @tracked
  public scale: number;

  @tracked
  // Negative value represents right, positive represents left
  public offsetX: number;

  @tracked
  // Negative value represents down, positive value represents up
  public offsetY: number;

  @tracked
  public colorBrandKey?: ColorBrandKey;

  constructor(
    shape: string,
    color: string,
    scale: number,
    offsetX: number,
    offsetY: number,
    colorBrandKey?: ColorBrandKey
  ) {
    this.shape = shape;
    this.color = color;
    this.scale = scale;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.colorBrandKey = colorBrandKey;
  }
}
