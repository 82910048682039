import { tracked } from "@glimmer/tracking";
import type { OriginalAsset } from "../../asset";
import { Asset } from "../../asset";

export class Svg extends Asset {
  @tracked
  _name?: string;

  @tracked
  _animation: string;

  @tracked
  _sourceUrl?: string;

  @tracked
  _previewUrl?: string;

  @tracked
  _opacity?: number;

  @tracked
  _color?: string;

  constructor(
    animation: string,
    name?: string,
    sourceUrl?: string,
    previewUrl?: string,
    opacity?: number,
    color?: string,
    originalAsset?: OriginalAsset
  ) {
    super(false, "fill", originalAsset);
    this._animation = animation;
    this._name = name;
    this._sourceUrl = sourceUrl;
    this._previewUrl = previewUrl;
    this._opacity = opacity;
    this._color = color;
  }

  get animation(): string {
    return this._animation;
  }

  get hasContent(): boolean {
    return !!this.sourceUrl;
  }

  get category(): string {
    return "svg";
  }

  get formattedCategory(): string {
    return "svg";
  }

  get previewImageUrl(): string | undefined {
    return this._previewUrl;
  }

  get name(): string | undefined {
    return this._name;
  }

  get color(): string | undefined {
    return this._color;
  }

  get sourceUrl(): string | undefined {
    return this._sourceUrl;
  }

  get previewUrl(): string | undefined {
    return this._previewUrl;
  }

  get opacity(): number | undefined {
    return this._opacity;
  }

  _reset(): void {
    super._reset();
    this._name = undefined;
    this._color = undefined;
    this._sourceUrl = undefined;
    this._previewUrl = undefined;
  }
}
