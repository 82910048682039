import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectPreview from "client/models/project-preview";
import type TeamProjectActivityService from "client/services/team-project-activity";

export interface HomeTeamModel {
  projects: ArrayWithMeta<ProjectPreview>;
}

export default class DiscoveryHomeTeamRoute extends Route<HomeTeamModel> {
  @service
  declare store: Store;

  @service
  private declare teamProjectActivity: TeamProjectActivityService;

  async model(): Promise<HomeTeamModel> {
    // @ts-expect-error
    const projects = (await this.store.query("projectPreview", {
      /* eslint-disable camelcase */
      page: 1,
      per_page: 12,
      team_shared: true
      /* eslint-enable camelcase */
    })) as ArrayWithMeta<ProjectPreview>;

    return {
      projects
    };
  }

  afterModel(): void {
    this.teamProjectActivity.setLastViewedAt(new Date());
  }
}
