import Model, { attr, belongsTo } from "@ember-data/model";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type VideoPage from "client/models/video-page";

export default class ProjectExport extends Model {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("project", { async: true, inverse: null })
  declare project: Project;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("project-render", { async: true, inverse: null })
  declare projectRender: ProjectRender;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("video-page", { async: true, inverse: null })
  declare videoPage: VideoPage;

  @attr("boolean")
  declare cacheReadEnabled: boolean;

  @attr("number")
  declare thumbnailFrame?: number;

  @attr("string")
  declare thumbnailPath?: string;
}
