import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type AjaxService from "client/services/ajax";
import type HoneybadgerService from "client/services/honeybadger";

interface OptoutModel {
  success: boolean;
}

export default class OptoutRoute extends Route<OptoutModel> {
  @service
  declare ajax: AjaxService;

  @service
  declare honeybadger: HoneybadgerService;

  queryParams = {
    token: {
      refreshModel: false
    }
  };

  async model(params: { token: string }): Promise<OptoutModel> {
    const { token } = params;
    let success = true;
    try {
      await this.ajax.api("/optout", {
        method: "POST",
        body: new URLSearchParams({ token })
      });
    } catch (error) {
      success = false;
      // @ts-expect-error
      this.honeybadger.notify(error);
    }

    return { success };
  }
}
