import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SoundtrackService from "client/services/soundtrack";

export default class SoundtrackChangeComponent extends Component {
  @service
  declare soundtrack: SoundtrackService;

  menuTarget!: HTMLElement;

  styleNamespace = getStyleNamespace("project-timeline/soundtrack-change");

  @action
  openSoundtrackModal(): void {
    this.soundtrack.open();
  }
}
