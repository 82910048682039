import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface PlanHighlightsArgs {
  highlights: string[];
  skuCode: string;
}

export default class PlanHighlights extends Component<PlanHighlightsArgs> {
  styleNamespace = getStyleNamespace("purchase/plan-highlights");
}
