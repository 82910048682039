import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";
import { SCREEN_DOOR_EXCLUDED_ROUTES } from "client/services/screen-door";
import type StartTrialOrUpgradeService from "client/services/start-trial-or-upgrade";

export default class EditOnlyModeComponent extends Component {
  styleNamespace = getStyleNamespace("edit-only-mode");

  @service
  declare startTrialOrUpgrade: StartTrialOrUpgradeService;

  @service
  declare permissions: PermissionsService;

  @service
  declare auth: AuthService;

  @service
  declare router: RouterService;

  get currentRouteName(): string | undefined {
    return this.router.currentRouteName;
  }

  get show(): boolean {
    return (this.editOnlyMode || this.editorRoleMode) && !this.currentRouteExcluded;
  }

  get editOnlyMode(): boolean {
    return this.permissions.has("feature_edit_only_mode_notifications");
  }

  get editorRoleMode(): boolean {
    return this.permissions.has("feature_editor_role_notifications");
  }

  get tooltip(): string {
    if (this.editorRoleMode) {
      return "As an Editor, you can create videos but not publish. Let your Account Owner know you're ready to publish videos 🎉";
    } else if (this.canManageSubscription) {
      return "Currently, you can edit and make videos. Upgrade to start publishing.";
    } else {
      return "Currently, you can edit and make videos. To publish, ask your account owner to upgrade.";
    }
  }

  get ctaTitle(): string {
    return this.startTrialOrUpgrade.canStartTrial ? "Start trial" : "Upgrade";
  }

  get currentRouteExcluded(): boolean {
    return SCREEN_DOOR_EXCLUDED_ROUTES.some((routeName) => this.currentRouteName?.startsWith(routeName));
  }

  get canManageSubscription(): boolean {
    return this.auth.currentUser?.canManageSubscription ?? false;
  }

  @action
  didInsert(): void {
    document.body.classList.add("-edit-only");
  }

  willDestroy(): void {
    super.willDestroy();
    document.body.classList.remove("-edit-only");
  }

  @action
  onButtonClick(): void {
    this.startTrialOrUpgrade.start("link - edit-only-mode - purchase");
  }
}
