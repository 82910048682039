import type { Caption } from "../../scene/caption";
import { TimelineMutation } from "./mutation";

export class ChangeCaptionFontFamilyMutation extends TimelineMutation {
  grouped = false;

  originalFontFamilies: Map<string, string | undefined> = new Map();

  constructor(public caption: Caption, private fontFamily: string) {
    super();
    for (const text of caption.texts) {
      // Assume all `TextStyle`s in a `Text` share the same font family (which is safe for now).
      this.originalFontFamilies.set(text.id, text._styles.find((s) => s.fontFamily)?.fontFamily);
    }
  }

  run(): void {
    for (const text of this.caption.texts) {
      for (const style of text.styles) {
        style._fontFamily = this.fontFamily;
      }
    }
  }

  revert(): void {
    for (const text of this.caption.texts) {
      if (this.originalFontFamilies.has(text.id)) {
        for (const style of text.styles) {
          style._fontFamily = this.originalFontFamilies.get(text.id)!;
        }
      }
    }
  }
}
