import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";

export default class ProjectScenesAssetsIndexRoute extends Route {
  @service
  declare router: RouterService;

  beforeModel(): void {
    void this.router.replaceWith("authenticated.project.scenes.assets.library");
  }
}
