import type Model from "@ember-data/model";

const getSavedAttr = <T extends Model, K extends keyof T>(model: T, attr: K): T[K] => {
  const attrs = model.changedAttributes();

  if (attr in attrs) {
    const [prev] = attrs[attr as string] as [T[K], T[K]];
    return prev;
  }

  return model[attr];
};

export default getSavedAttr;
