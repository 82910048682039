import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { makeCORSUrl, Assets, AssetSizes } from "renderer-engine";
import type { Asset, EventRegister, Media, Scene, TimeSlice } from "client/lib/editor-domain-model";
import {
  Animation,
  Background,
  SceneDurationMutationFactory,
  UpdateTrimMutation,
  VideoClip,
  Watermark
} from "client/lib/editor-domain-model";
import { transaction } from "client/lib/transaction";
import { getVideoDuration } from "client/lib/video";

interface VideoTrimModalComponentArgs {
  eventRegister: EventRegister;
  media: Media;
  scene: Scene;
  onClose: () => void;
}

export default class VideoTrimModalComponent extends Component<VideoTrimModalComponentArgs> {
  @service
  declare store: Store;

  @tracked
  videoDetails?: any;

  @action
  async didInsert(): Promise<void> {
    const { asset } = this;

    if (!(asset instanceof VideoClip || asset instanceof Animation)) {
      this.args.onClose();
      throw Error("Asset is not a video or an animation");
    }

    if (!asset.hasContent) {
      this.args.onClose();
      throw Error("Asset has no content");
    }

    if (asset instanceof VideoClip) {
      const videoUrl = await Assets.getVideoAssetUrl(asset.asset, AssetSizes.PREVIEW);
      const videoDuration = videoUrl ? await getVideoDuration(videoUrl) : undefined;
      this.videoDetails = { videoDuration: videoDuration, videoUrl: videoUrl };
    }

    if (asset instanceof Animation) {
      const videoUrl = asset.previewVideoUrl;
      const corsUrl = videoUrl ? makeCORSUrl(videoUrl) : undefined;
      const videoDuration = corsUrl ? await getVideoDuration(corsUrl) : undefined;
      this.videoDetails = { videoDuration: videoDuration, videoUrl: corsUrl };
    }
  }

  @action
  @transaction
  async onSave(trim: TimeSlice): Promise<void> {
    const { eventRegister, media, scene } = this.args;

    if (!(media.asset instanceof VideoClip || media.asset instanceof Animation)) {
      return;
    }

    eventRegister.fire(new UpdateTrimMutation(media.asset, trim));

    if (media instanceof Background) {
      const factory = new SceneDurationMutationFactory(scene);
      const mutation = factory.createMutation(trim.duration, 0.5);
      eventRegister.fire(mutation);
    }

    if (media instanceof Watermark) {
      await eventRegister.facade.saveWatermark(media);
    } else {
      await eventRegister.facade.saveScene(scene);
    }
  }

  get asset(): Asset | undefined {
    return this.args.media.asset;
  }
}
