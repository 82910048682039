import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type UpgradeService from "client/services/upgrade";

export default class PlansIndexRoute extends Route {
  @service
  declare upgrade: UpgradeService;

  beforeModel(): void {
    void this.upgrade.open({ transitionWithRouter: true });
  }
}
