import Route from "@ember/routing/route";
import { service } from "@ember/service";
import config from "client/config/environment";
import type SessionService from "client/services/session";

export default class UnauthenticatedRoute extends Route {
  @service
  declare session: SessionService;

  beforeModel(): void {
    const { routeAfterAuthentication } = config["ember-simple-auth"] as { routeAfterAuthentication: string };
    this.session.prohibitAuthentication(routeAfterAuthentication);
  }
}
