import { getOwner } from "@ember/application";
import type ApplicationInstance from "@ember/application/instance";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import isURL from "validator/es/lib/isURL";
import BrandQueryRequest from "client/lib/brand/brand-query-request";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyleService from "client/services/brand-style";
import type CurrentUserService from "client/services/current-user";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";

interface Args {
  url?: string;
  onBrandFetch(brandStyleConfig: BrandStyleConfig): unknown;

  fetchModal: boolean;
  showFetchModal(): unknown;
  hideFetchModal(): unknown;
}

export default class BrandEditFetchComponent extends Component<Args> {
  @service
  private declare brandStyle: BrandStyleService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare currentUser: CurrentUserService;

  @tracked
  fetching = false;

  @tracked
  failedFetching = false;

  @tracked
  creatingDefaultPalette = false;

  @tracked
  url = "";

  styleNamespace = getStyleNamespace("brand/edit/fetch");

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);

    const { user } = this.currentUser;

    this.url = (user?.hasPublicEmailDomain ? "" : user?.emailDomain) ?? "";
  }

  @action
  async createDefaultPalette(): Promise<void> {
    if (this.disabledCreatingDefaultPalette) {
      return;
    }

    this.creatingDefaultPalette = true;

    try {
      const universalBrandStyleConfig = await this.brandStyle.fetchUniversalBrand();

      if (universalBrandStyleConfig) {
        await this.args.onBrandFetch(universalBrandStyleConfig.style);
        this.args.hideFetchModal();
      }
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error);

      this.notifications.error("We were unable to create the default brand for you");
    } finally {
      this.creatingDefaultPalette = false;
    }
  }

  @action
  async onSubmit(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.disabledFetching) {
      return;
    }

    this.fetching = true;
    this.failedFetching = false;

    // @ts-expect-error
    const query = new BrandQueryRequest(getOwner(this), this.url);

    try {
      const brandStyleConfig = await query.invoke();
      await this.args.onBrandFetch(brandStyleConfig);

      this.args.hideFetchModal();
    } catch (error) {
      // @ts-expect-error
      if (error.status !== 404) {
        // 404 is a legit error the brand was missing do not report to Honeybadger in that case
        // @ts-expect-error
        this.honeybadger.notify(error);
      }

      this.failedFetching = true;
    } finally {
      this.fetching = false;
    }
  }

  get disabledFetching(): boolean {
    return this.fetching || this.hasInvalidURL;
  }

  get disabledCreatingDefaultPalette(): boolean {
    return this.fetching || this.creatingDefaultPalette;
  }

  private get hasInvalidURL(): boolean {
    return (
      !this.url ||
      !isURL(this.url, {
        require_protocol: false // eslint-disable-line camelcase
      })
    );
  }
}
