import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import { sortBy } from "client/models/collections";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type AuthService from "client/services/auth";
import type PlansService from "client/services/plans";
import type ScreenDoorService from "client/services/screen-door";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export default class PlansModalComponent extends Component {
  @service
  declare auth: AuthService;

  @service("plans")
  declare plansService: PlansService;

  @service
  declare screenDoor: ScreenDoorService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare tracking: TrackingService;

  @tracked
  loading = false;

  @tracked
  purchasedSubscription?: Subscription;

  get plans(): Array<Plan> {
    return sortBy(
      this.plansService.plans.filter((plan) => plan.offered),
      "monetaryUnits"
    );
  }

  get couponCode(): string | undefined {
    return this.upgrade.couponCode;
  }

  get showCouponField(): boolean | undefined {
    return this.upgrade.showCouponField;
  }

  get subscribedPlan(): Plan | undefined {
    return this.upgrade.subscribedPlan;
  }

  get selectedPlan(): Plan | undefined {
    return this.upgrade?.selectedPlan;
  }

  get hasPlans(): boolean {
    return !!this.plans?.length;
  }

  get currentPlan(): Plan | undefined {
    return this.currentSubscription?.plan;
  }

  get changingPlan(): Plan | undefined {
    return this.subscribedPlan && this.selectedPlan;
  }

  private get currentSubscription(): Subscription | undefined {
    return this.auth.currentSubscription;
  }

  @action
  async onPlanPurchased(purchasedSubscription: Subscription): Promise<void> {
    this.purchasedSubscription = purchasedSubscription;
    await this.upgrade.onPlanPurchased();
  }

  @action
  async close(): Promise<void> {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLOSE_PURCHASE_MODAL, {
      planId: this.selectedPlan?.id
    });

    this.upgrade.close();
  }

  @action
  async didInsert(): Promise<void> {
    this.loading = true;

    try {
      await this.auth.currentUser?.currentSubscription;
    } finally {
      this.loading = false;
    }
  }
}
