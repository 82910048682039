import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class RollUpAnimationComponent extends Component<{ show: boolean }> {
  private inner?: HTMLElement;

  private height = 0;

  @tracked
  private shown = this.args.show;

  styleNamespace = getStyleNamespace("roll-up-animation");

  get allowOverflow(): boolean {
    return this.shown && this.args.show;
  }

  @action
  didInsertInner(inner: HTMLElement): void {
    this.inner = inner;
    if (!this.args.show) {
      this.inner.style.maxHeight = "0";
    }
    this.updateStyle();
  }

  @action
  didEndTransition(): void {
    this.shown = this.args.show;
  }

  @action
  didInsertContent(content: HTMLElement): void {
    new ResizeObserver(() => {
      this.height = content.getBoundingClientRect().height;
      this.updateStyle();
    }).observe(content);
  }

  @action didUpdateShow(): void {
    this.updateStyle();
  }

  private updateStyle(): void {
    if (this.inner && this.height) {
      this.inner.style.transition = this.inner.style.marginTop ? "margin-top 200ms" : "";
      this.inner.style.marginTop = `${this.args.show ? 0 : -this.height}px`;
      this.inner.style.maxHeight = "unset";
    }
  }
}
