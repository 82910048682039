import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import InfiniteController from "client/controllers/infinite";
import type { Scene } from "client/lib/editor-domain-model";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type Favorable from "client/models/favorable";
import { FAVORABLE_TYPES } from "client/models/favorable";
import type SelectableAsset from "client/models/selectable-asset";
import type { LibraryContentModel } from "client/routes/library-content";
import type ProjectContentBarService from "client/services/project-content-bar";

export default class LibraryContentController<
  M extends LibraryContentModel = LibraryContentModel
> extends InfiniteController<M> {
  @tracked
  adding = false;

  // eslint-disable-next-line camelcase
  per_page = 36;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @action
  async select(asset: SelectableAsset): Promise<void> {
    this.adding = true;

    try {
      const { scene, afterScene, timeline, eventRegister } = this.model;
      const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene);

      if (this.projectContentBar.isReplacing) {
        await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
      } else {
        await modifier.applyAsset(asset);
      }
    } finally {
      this.adding = false;
    }
  }

  @action
  async favorite(favorable: Favorable): Promise<void> {
    await favorable.favorite();
    if (favorable.favorableType === FAVORABLE_TYPES.PROJECT_SCENES) {
      const timeline = this.model.timeline;
      const scene = timeline.scenes.find((s: Scene) => s.id === favorable.favorableProviderAssetId);
      if (scene) {
        scene.favorite({ favorited: true });
      }
    }
  }

  @action
  async unfavorite(favorable: Favorable): Promise<void> {
    await favorable.unfavorite();
    if (favorable.favorableType === FAVORABLE_TYPES.PROJECT_SCENES) {
      const timeline = this.model.timeline;
      const scene = timeline.scenes.find((s: Scene) => s.id === favorable.favorableProviderAssetId);
      if (scene) {
        scene.unfavorite();
      }
    }
  }
}
