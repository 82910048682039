import { action } from "@ember/object";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import TrackingEvents from "client/events";
import delay from "client/lib/delay";
import getStyleNamespace from "client/lib/get-style-namespace";
import { CaptionBurnStatus } from "client/models/caption-burn-request";
import type ProjectRender from "client/models/project-render";
import type ProjectTranscription from "client/models/project-transcription";
import { ProjectTranscriptionStatus } from "client/models/project-transcription";
import ProjectTranscriptionCaption from "client/models/project-transcription-caption";
import type ProjectTranscriptionCaptionUrl from "client/models/project-transcription-caption-url";
import type AjaxService from "client/services/ajax";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";
import MessageBus from "message-bus-client";

interface CreateCaptionsArgs {
  latestRender?: ProjectRender;
  projectTranscription?: ProjectTranscription;
  disabled: boolean;
  onEdit: () => Promise<void>;
}

interface CreateCaptionsMessageBusMessage {
  transcription_status: string;
  caption_burn_status: string;
}

export default class CreateCaptionsComponent extends Component<CreateCaptionsArgs> {
  @service
  declare ajax: AjaxService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  @tracked
  declare projectTranscription?: ProjectTranscription;

  styleNamespace = getStyleNamespace("create-captions");

  @tracked
  editingCaptions = false;

  @tracked
  loadingCaptions = false;

  @tracked
  loadingCaptionsError = false;

  @tracked
  captionsEditErrorMessage?: string;

  @tracked
  captions: ProjectTranscriptionCaption[] = new TrackedArray([]);

  captionUrls?: ProjectTranscriptionCaptionUrl;

  subscribedToMessageBus = false;

  constructor(owner: Owner, args: CreateCaptionsArgs) {
    super(owner, args);
    this.projectTranscription = args.projectTranscription;
  }

  get disabled(): boolean {
    return this.args.disabled || this.isBuilding;
  }

  @action
  async buildCaptions(): Promise<void> {
    try {
      if (this.args.latestRender && !this.createCaptionsDisabled) {
        this.projectTranscription = this.store.createRecord("projectTranscription", {
          projectRender: this.args.latestRender
        });
        await this.projectTranscription.save();
        await this.trackBuildCaptionsEvent(this.args.latestRender);
        this.notifications.success("Building captions");
        this.subscribeToMessageBus();
      }
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, "Publish/Captions");
      this.projectTranscription?.unloadRecord();
      this.projectTranscription = undefined;
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.notifications.error(error.errors.shift().detail);
      await this.args.latestRender?.reload();
    }
  }

  @action
  async openCaptionsModal(): Promise<void> {
    this.editingCaptions = true;
    await this.loadCaptions();
  }

  @action
  closeCaptionsModal(): void {
    this.loadingCaptions = false;
    this.loadingCaptionsError = false;
    this.captionsEditErrorMessage = undefined;
    this.editingCaptions = false;
  }

  async loadCaptions(): Promise<void> {
    try {
      this.loadingCaptions = true;

      this.captions = new TrackedArray([]);
      this.captionUrls = await this.store.findRecord("projectTranscriptionCaptionUrl", this.projectTranscription!.id, {
        reload: true
      });

      if (this.captionUrls) {
        const presignedUrl = this.captionUrls.vttReadUrl;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const response = await fetch(presignedUrl, {
          method: "GET"
        });
        const captionFileText = (await response.text()).trim();
        const captionsFileEntries = captionFileText.split("\n\n").slice(1);

        captionsFileEntries.forEach((entry: string): void => {
          const captionUnit = entry.split("\n");
          const caption = new ProjectTranscriptionCaption(
            Number(Number(captionUnit[0])),
            captionUnit[1]!,
            captionUnit.slice(2).join("\n")
          );
          this.captions.push(caption);
        });
      }
    } catch (error) {
      this.loadingCaptionsError = true;
      this.captionsEditErrorMessage = "Well that sucks, our little robots couldn't load your captions";
      // @ts-expect-error
      this.honeybadger.notify(error, "Publish/Captions");
    } finally {
      this.loadingCaptions = false;
    }
  }

  @action
  deleteCaption(caption: ProjectTranscriptionCaption): void {
    this.captions.splice(caption.index, 1);
    for (let index = caption.index; index < this.captions.length; index++) {
      const caption = this.captions[index];
      if (caption) {
        caption.index = index;
      }
    }
  }

  @action
  async saveCaptions(): Promise<void> {
    try {
      this.loadingCaptions = true;
      const captionsText = this.buildCaptionsText();
      await this.saveWebVttCaptions(captionsText);
      await this.saveSrtCaptions(captionsText);
      const captionBurnRequest = this.store.createRecord("captionBurnRequest", {
        projectTranscription: this.projectTranscription
      });
      await captionBurnRequest.save();
      this.closeCaptionsModal();
      this.subscribeToMessageBus();
      this.notifications.success("Updating captions in your video");
      await this.args.onEdit();
    } catch (error) {
      this.loadingCaptionsError = true;
      // @ts-ignore
      if (error.status === 422) {
        this.captionsEditErrorMessage =
          "It looks like there is a caption burn in progress please wait for that to finish";
      } else {
        this.captionsEditErrorMessage = "Well that sucks, our little robots couldn't save your captions";
        // @ts-expect-error
        this.honeybadger.notify(error, "Publish/Captions");
      }
      // Prevent the modal from closing so the user can see the error
      throw error;
    } finally {
      this.loadingCaptions = false;
    }
  }

  buildCaptionsText(): string {
    return this.captions.map((caption) => caption.toString()).join("\n\n");
  }

  async saveWebVttCaptions(captionsText: string): Promise<void> {
    if (this.captionUrls) {
      const webVtt = "WEBVTT\n\n".concat(captionsText);
      await this.writeToS3(this.captionUrls.vttWriteUrl, webVtt, "vtt");
    }
  }

  async saveSrtCaptions(captionsText: string): Promise<void> {
    if (this.captionUrls) {
      await this.writeToS3(this.captionUrls.srtWriteUrl, captionsText, "srt");
    }
  }

  async writeToS3(presignedUrl: string, content: string, extension: string): Promise<void> {
    const response = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Disposition": `attachment; filename="${this.attachmentBaseName}${extension}"`
      },
      body: content
    });

    if (!response.ok) {
      throw response.statusText;
    }
  }

  get createCaptionsDisabled(): boolean {
    return !!this.projectTranscription;
  }

  get captionsCreated(): boolean {
    return this.ctaStatus === "Created";
  }

  get channelName(): string {
    return `project:transcription:captions:edit:read:${this.projectTranscription!.id}`;
  }

  get isBuilding(): boolean {
    return (
      this.createCaptionsDisabled &&
      (!(this.projectTranscription?.isCompleted || this.projectTranscription?.isFailed) ||
        !!this.projectTranscription?.isBurning)
    );
  }

  get ctaStatus(): string {
    // eslint-disable-next-line no-null/no-null
    if (this.projectTranscription === null || typeof this.projectTranscription === "undefined") {
      return "Add";
    } else if (this.projectTranscription.isCompleted) {
      return "Created";
    } else if (this.projectTranscription.isFailed) {
      return "Failed Building";
    }
    return "Creating";
  }

  get editCtaStatus(): string {
    if (this.projectTranscription?.isBurning) {
      return "Burning";
    }
    return "Edit";
  }

  get attachmentBaseName(): string {
    return this.args.latestRender!.slugifiedName!.slice(0, -3);
  }

  @action
  async downloadSrtFile(): Promise<void> {
    try {
      const a = document.createElement("a");
      a.href = this.projectTranscription!.srtUrl;
      a.style.display = "none";
      document.body.append(a);
      await delay(100);
      a.click();
    } catch (error) {
      this.notifications.error("Error occurred trying to download SRT file please contact support");
    }
  }

  private async trackBuildCaptionsEvent(render: ProjectRender): Promise<void> {
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_BUILD_CAPTIONS, await render.buildDownloadEvent());
  }

  private subscribeToMessageBus(): void {
    if (!this.subscribedToMessageBus) {
      MessageBus.subscribe("project:transcription:update", async (message: CreateCaptionsMessageBusMessage) => {
        await this.projectTranscription?.reload();
        if (this.projectTranscription?.captionBurnRequest) {
          if (message.caption_burn_status === CaptionBurnStatus.complete) {
            this.notifications.success("Burned in captions video ready!");
          } else if (
            [CaptionBurnStatus.error, CaptionBurnStatus.canceled].some(
              (status: string) => status === this.projectTranscription?.captionBurnRequest?.get("status")
            )
          ) {
            this.notifications.error("Failed burning captions you will need to edit and save to try again");
          }
        } else {
          if (message.transcription_status === ProjectTranscriptionStatus.completed) {
            this.notifications.success("Captions ready!");
            await this.args.onEdit();
          } else if (message.transcription_status === ProjectTranscriptionStatus.failed) {
            this.notifications.error("Failed building captions you will need to rebuild your project to try again");
          }
        }
      });
      this.subscribedToMessageBus = true;
    }
  }
}
