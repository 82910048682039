import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type SurveyOnboardingController from "client/authenticated/survey/onboarding/controller";
import type UserTargetingData from "client/models/user-targeting-data";
import type AuthService from "client/services/auth";
import type HoneybadgerService from "client/services/honeybadger";

export type OnboardingSurveyRouteModel = UserTargetingData | undefined;

export default class SurveyOnboardingRoute extends Route<OnboardingSurveyRouteModel> {
  @service
  declare auth: AuthService;

  @service
  declare honeybadger: HoneybadgerService;

  async model(): Promise<OnboardingSurveyRouteModel> {
    try {
      return await this.auth.currentUser!.getTargetingData();
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, "SurveyOnboardingRoute");
    }
  }

  afterModel(userTargetingData: OnboardingSurveyRouteModel): void {
    // As the userTargetingData may have just been created, assign it to the AuthService
    this.auth.userTargetingData ??= userTargetingData;
  }

  setupController(
    controller: SurveyOnboardingController,
    model: OnboardingSurveyRouteModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    controller.orgType = model?.userType;
  }
}
