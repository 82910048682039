import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import { ContributorTypes } from "client/models/folder";
import type FoldersService from "client/services/folders";

interface Args {
  folder?: Folder;
  parentRouteName: string;
  title: string;
  setFolder: (folder: Folder) => void;
}

export interface Breadcrumb {
  label: string;
  id: string;
}

export default class AssetManagementBaseBreadcrumbsComponent extends Component<Args> {
  @service
  declare folders: FoldersService;

  @service
  declare store: Store;

  styleNamespace = getStyleNamespace("asset-management/base/breadcrumbs");

  @action
  async setFolder(folderId: string): Promise<void> {
    const folder = await this.store.findRecord("folder", folderId);
    if (folder) {
      this.args.setFolder(folder);
    }
  }

  get rootFolderName(): string {
    switch (this.args.folder?.contributorType) {
      case ContributorTypes.TEAM:
        return "Team uploads";
      case ContributorTypes.USER:
        return "My uploads";
      default:
        return "My uploads";
    }
  }

  get breadcrumbs(): Array<Breadcrumb> {
    if (!this.args.folder) {
      return [];
    }
    return this.folders.breadcrumbPath(this.args.folder);
  }
}
