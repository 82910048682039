import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";
import type ProjectContentBarService from "client/services/project-content-bar";
import type { CBSelectAssetTrackingProperties } from "client/services/project-content-bar";

interface Args {
  selectAsset(asset?: SelectableAsset, trackProperties?: CBSelectAssetTrackingProperties): unknown;
}

export default class ProjectContentBar extends Component<Args> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @tracked
  adding = false;

  @tracked
  _element?: HTMLElement;

  styleNamespace = getStyleNamespace("project-content-bar");

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  async selectAsset(asset: SelectableAsset, trackProperties?: CBSelectAssetTrackingProperties): Promise<void> {
    if (this.adding) {
      return;
    }

    this.adding = true;

    try {
      await this.args.selectAsset(asset, trackProperties);
    } finally {
      this.adding = false;
    }
  }

  @action
  scrollTop(): void {
    if (this._element) {
      this._element.scrollTop = 0;
    }
  }

  get panelComponent(): string | undefined {
    if (this.projectContentBar.expanded && this.projectContentBar.panel) {
      const [parent] = this.projectContentBar.panel.split(".");

      return `project-content-bar/panels/${parent}`;
    } else {
      return undefined;
    }
  }
}
