import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum TileSizes {
  SMALL = "small",
  DEFAULT = "default"
}

interface SurveyTileArgs {
  selected: boolean;
  label: string;
  iconSrc: string;
  onClick: () => Promise<void>;
  size?: TileSizes;
}

export default class SurveyTileComponent extends Component<SurveyTileArgs> {
  styleNamespace = getStyleNamespace("survey/tile");

  @tracked
  hovering = false;

  @action
  onMouseEnter(): void {
    this.hovering = true;
  }

  @action
  onMouseLeave(): void {
    this.hovering = false;
  }

  get size(): TileSizes {
    return this.args.size ?? TileSizes.DEFAULT;
  }
}
