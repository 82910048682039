import Component from "@glimmer/component";

interface HeartComponentArgs {
  fillColor?: string;
}

export default class HeartComponent extends Component<HeartComponentArgs> {
  get fillColor(): string {
    return this.args.fillColor ?? "currentColor";
  }
}
