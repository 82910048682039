import { service } from "@ember/service";
import AudioController from "client/controllers/audio";
import type { AudioRouteModel } from "client/routes/audio";
import type PropertiesPanelService from "client/services/properties-panel";
import type SoundtrackService from "client/services/soundtrack";

export default class SoundtrackController extends AudioController<AudioRouteModel> {
  @service
  declare soundtrack: SoundtrackService;

  @service
  declare propertiesPanel: PropertiesPanelService;
}
