import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { ROOT_FOLDER_ID } from "client/adapters/folder-content";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import type { ContributorTypes } from "client/models/folder";
import { FolderCategoryTypes } from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type SelectableAsset from "client/models/selectable-asset";
import UserAsset from "client/models/user-asset";
import type ActiveStorageService from "client/services/active-storage";
import { FILE_UPLOAD_ERROR_NOTIFICATION } from "client/services/active-storage";
import type AssetLibraryService from "client/services/asset-library";
import { ASSET_LIBRARY_VIDEO_MIMETYPES, ASSET_LIBRARY_IMAGE_MIMETYPES } from "client/services/asset-library";
import type FoldersService from "client/services/folders";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";

interface Args {
  selectAsset(asset: SelectableAsset): void;
  scrollTop(): unknown;
  assetOwnerType: ContributorTypes;
}

export default class UploadsBasePanel extends Component<Args & ProjectContentBarPanelArgs> {
  @service
  private declare store: Store;

  @service
  private declare activeStorage: ActiveStorageService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare assetLibrary: AssetLibraryService;

  @service
  private declare folders: FoldersService;

  @service
  private declare permissions: PermissionsService;

  @tracked
  currentFolder?: Folder;

  @tracked
  folderContentSync = false;

  styleNamespace = getStyleNamespace("project-content-bar/panels/uploads/base");

  private get folderId(): string {
    return this.currentFolder?.get("id") || ROOT_FOLDER_ID;
  }

  @action
  didInsert(): void {
    this.setFolder(undefined);
  }

  @action
  assetOwnerTypeChange(): void {
    this.setFolder(undefined);
  }

  @action
  setFolder(folder: Folder | undefined): void {
    this.currentFolder = folder;
    this.reloadResults();
  }

  @action
  clickUp(): void {
    this.setFolder(this.currentFolder?.get("parent"));
  }

  get parentName(): string {
    return this.currentFolder?.get("parent")?.get("name") ?? "..";
  }

  @action
  async handleUpload(): Promise<void> {
    const files = await this.activeStorage.showFilePicker({
      accept: this.acceptMimeTypes
    });

    await this.uploadFiles(files);
  }

  @action
  onDropZone(ev: DragEvent): void {
    if (ev.dataTransfer) {
      void this.onUploadDragAndDrop(ev.dataTransfer?.files);
    }
  }

  @action
  onDragEnter(_ev: DragEvent): void {
    this.args.scrollTop?.();
  }

  @action
  async onUploadDragAndDrop(files: FileList): Promise<void> {
    await this.uploadFiles(files);
  }

  private get acceptMimeTypes(): string {
    let mimeTypes = ASSET_LIBRARY_IMAGE_MIMETYPES;

    if (!this.permissions.has("feature_svg_upload")) {
      mimeTypes = mimeTypes.filter((e) => e !== "image/svg+xml");
    }

    return ASSET_LIBRARY_VIDEO_MIMETYPES.concat(mimeTypes).toString();
  }

  private async uploadFiles(files: FileList | File[]): Promise<void> {
    try {
      if (files.length > 10) {
        this.notifications.warning("You can only upload 10 files at a time. Please try again");
        return;
      }

      const assets = (await this.assetLibrary.uploadFiles(files, this.assetOwnerType)).filter(
        (asset) => asset instanceof UserAsset
      );

      if (this.currentFolder) {
        await Promise.all(
          assets.map(async (asset) => {
            await this.folders.addContent(this.currentFolder!, asset as UserAsset, this.assetOwnerType);
          })
        );
      }

      if (assets.length > 0) {
        this.reloadResults();
      }
    } catch (err) {
      this.notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
      // @ts-expect-error
      this.honeybadger.notify(err, "ProjectContentBarUploads");
    }
  }

  private reloadResults(): void {
    // eslint-disable-next-line no-self-assign
    this.params = {
      ...this.params,
      type: this.assetOwnerType,
      folderId: this.folderId
    };
  }

  @tracked
  params = {
    order: "created_at:desc",
    folderId: this.folderId,
    type: this.assetOwnerType,
    category: FolderCategoryTypes.USER_ASSETS
  };

  @action
  async loadResults(params: Record<string, string | number>): Promise<ArrayProxy<FolderContent>> {
    return await this.store.query("folderContent", params);
  }

  private get assetOwnerType(): ContributorTypes {
    return this.args.assetOwnerType;
  }
}
