import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import { SceneBackgroundColorMutation } from "client/lib/editor-domain-model";
import { transaction } from "client/lib/transaction";
import type PendingService from "client/services/pending";

interface ProjectSceneColorArgs {
  eventRegister: EventRegister;
  scene: Scene;
}

export default class ProjectSceneColorComponent extends Component<ProjectSceneColorArgs> {
  @service
  declare pending: PendingService;

  get backgroundColors(): string[] {
    return [this.args.scene.color];
  }

  @action
  @transaction
  async save(color: string): Promise<void> {
    const { eventRegister, scene } = this.args;

    eventRegister.fire(new SceneBackgroundColorMutation(scene, color));

    await this.pending.push(eventRegister.facade.saveScene(scene));
  }
}
