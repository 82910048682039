import { setOwner } from "@ember/application";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import SelectableAsset from "./selectable-asset";
import type TrackingEvents from "client/events";
import type { Timeline, EventRegister, Scene, Media, TextStyle } from "client/lib/editor-domain-model";
import { CaptionAddTextMutation } from "client/lib/editor-domain-model";
import { generateNewCaption } from "client/lib/scene-asset-modifier";
import type AdvancedEditorService from "client/services/advanced-editor";

export default class SelectableTextStyle extends SelectableAsset {
  @service
  declare advancedEditor: AdvancedEditorService;

  readonly thumbImageUrl = undefined;

  readonly thumbVideoUrl = undefined;

  constructor(owner: Owner, private textStyle: TextStyle) {
    super();

    // Set the owner so that services can be injected
    setOwner(this, owner);
  }

  get trackUsageEvent(): TrackingEvents | undefined {
    return undefined;
  }

  async addToTimeline(_timeline: Timeline, _eventRegister: EventRegister): Promise<Scene> {
    throw new Error("Method not implemented.");
  }

  async addToMedia(_scene: Scene, _media: Media, _eventRegister: EventRegister): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async addToScene(scene: Scene, eventRegister: EventRegister): Promise<void> {
    const caption = this.advancedEditor.caption ?? (await generateNewCaption(scene, eventRegister));

    if (caption) {
      const mutation = new CaptionAddTextMutation(caption, this.textStyle);
      const text = await mutation.prepare(eventRegister.facade);
      eventRegister.fire(mutation);

      await this.advancedEditor.transitionToText(caption, text);
    }
  }
}
