import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type { PanelQueryParams } from "../base-parent/component";
import BaseParentPanel from "../base-parent/component";
import type Favorite from "client/models/favorite";

export default class FavoritesPanel extends BaseParentPanel {
  @service
  private declare store: Store;

  @tracked
  category?: string;

  @action
  setCategory(category?: string): void {
    this.category = category;
  }

  get params(): PanelQueryParams {
    const scope = this.userFavorite ? this.category : undefined;
    const include =
      this.userFavorite && (!scope || scope === "stock-assets") ? ["favorable", "license"] : ["favorable"];

    return {
      /* eslint-disable camelcase */
      include,
      scope,
      per_page: 24,
      filter: this.childName
      /* eslint-enable camelcase */
    };
  }

  get userFavorite(): boolean {
    return this.childName === "user";
  }

  get favoriteCategories(): Record<string, string | undefined>[] {
    return [
      { label: "View all", query: undefined },
      { label: "Scenes", query: "project-scenes" },
      { label: "Stock", query: "stock-assets" },
      { label: "Graphics", query: "sags" }
    ];
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<Favorite>> {
    // @ts-expect-error
    const results = (await this.store.query("favorite", params)) as Favorite[];
    this.transformResults(results);
    return results;
  }

  private transformResults(results: Array<Favorite>): void {
    results.forEach(({ favorable, metadata = {} }) => {
      Object.assign(favorable.metadataForFavorite, metadata);
    });
  }
}
