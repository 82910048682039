import type { RecordProjectResponse } from "@biteable/network-model";
import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { ProjectScenesMyRecordingsRouteModel } from "./route";
import InfiniteController from "client/controllers/infinite";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import { RECORD_ASSET_DELETE_FAILURE_NOTIFICATION } from "client/models/record-project";
import type UserAsset from "client/models/user-asset";
import type NotificationsService from "client/services/notifications";
import type ProjectContentBarService from "client/services/project-content-bar";

export default class ProjectScenesMyRecordingsController extends InfiniteController<ProjectScenesMyRecordingsRouteModel> {
  @service
  declare notifications: NotificationsService;

  @service
  private declare projectContentBar: ProjectContentBarService;

  // eslint-disable-next-line camelcase
  per_page = 20;

  @action
  async selectUserAsset(asset: UserAsset): Promise<void> {
    const { scene, afterScene, timeline, eventRegister } = this.model;
    const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister, scene, afterScene);

    if (this.projectContentBar.isReplacing) {
      await this.projectContentBar.onAssetSelectedFromScenesPage(modifier, asset);
    } else {
      await modifier.applyAsset(asset);
    }
  }

  @action
  async removeRecording(selfRecording: RecordProjectResponse): Promise<void> {
    try {
      await selfRecording.destroy();

      await this.reloadResults();
    } catch (error: any) {
      this.notifications.error(RECORD_ASSET_DELETE_FAILURE_NOTIFICATION);
      throw error;
    }
  }

  async reloadResults(): Promise<void> {
    await (getOwner(this).lookup(`route:authenticated.project.scenes.record.my-recordings`) as Route)?.refresh();
  }
}
