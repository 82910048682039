import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectSceneStack from "client/models/project-scene-stack";
import type SelectableAsset from "client/models/selectable-asset";
import type { CBSelectAssetTrackingProperties } from "client/services/project-content-bar";

export class Placeholder {
  isPlaceholder = true;
  thumbnailHeight: number;
  thumbnailWidth: number;
  thumbAspectRatio: string;

  constructor(aspectRatio: AspectRatio | undefined) {
    this.thumbnailHeight = aspectRatio?.height ?? 9;
    this.thumbnailWidth = aspectRatio?.width ?? 16;
    this.thumbAspectRatio = `${aspectRatio?.width}:${aspectRatio?.height}`;
  }
}

interface Args {
  selectAsset(asset?: SelectableAsset, trackingProperties?: CBSelectAssetTrackingProperties): unknown;
  onStackLoaded(stack: ProjectSceneStack): void;
  onPlaceholdersLoaded(stack: ProjectSceneStack): void;
  stack: ProjectSceneStack;
  aspectRatioId: string;
  hasAddBlank: boolean;
  shouldLoadAllPages(stack: ProjectSceneStack): boolean;
}

export default class ScenesStackPanel extends Component<Args> {
  @service
  declare store: Store;

  styleNamespace = getStyleNamespace("project-content-bar/panels/scenes/stack");

  get params(): Record<string, string | number | undefined> {
    return {
      /* eslint-disable camelcase */
      stack: this.args.stack.code,
      aspect_ratio: this.args.aspectRatioId,
      per_page: 24
      /* eslint-enable camelcase */
    };
  }

  get shouldLoadAllPages(): boolean {
    return this.args.shouldLoadAllPages(this.args.stack);
  }

  @action
  onStackLoaded(): void {
    this.args.onStackLoaded(this.args.stack);
  }

  @action
  onPlaceholdersLoaded(): void {
    this.args.onPlaceholdersLoaded(this.args.stack);
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<ArrayWithMeta<SelectableAsset>> {
    // @ts-expect-error
    return this.store.query("projectScenePreview", params) as ArrayWithMeta<SelectableAsset>;
  }

  @action
  async loadPlaceholders(): Promise<Placeholder[]> {
    const aspectRatio =
      this.store.peekRecord("aspectRatio", this.args.aspectRatioId) ??
      (await this.store.findRecord("aspectRatio", this.args.aspectRatioId));

    const params = {
      /* eslint-disable camelcase */
      stack: this.args.stack.code,
      aspect_ratio: this.args.aspectRatioId,
      per_page: 1
      /* eslint-enable camelcase */
    };

    const results = await this.loadResults(params);
    const placeholdersCount = results.meta?.["total-count"] ?? results.meta?.totalCount;

    return new Array(placeholdersCount).fill(new Placeholder(aspectRatio));
  }
}
