import config from "client/config/environment";
import { isProdEnv } from "client/lib/env";

const insertBrownFont = (): void => {
  const brownFont = document.createElement("link");
  brownFont.href = "https://cdn.biteable.com/fonts/brown/style.css";
  brownFont.rel = "stylesheet";
  document.head.appendChild(brownFont);
};

const insertSegmentScript = (): void => {
  const segmentScript = document.createElement("script");
  segmentScript.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";}}();`;
  document.head.appendChild(segmentScript);
};

const insertStripeScript = (): void => {
  const stripeScript = document.createElement("script");
  stripeScript.src = "https://js.stripe.com/v3/";
  stripeScript.defer = true;
  document.body.appendChild(stripeScript);
};

const insertChurnKeyScript = (): void => {
  // @ts-ignore
  if (!window.churnkey || !window.churnkey.created) {
    // @ts-ignore
    window.churnkey = { created: true };
    const churnkeyScript = document.createElement("script");
    churnkeyScript.src = "https://assets.churnkey.co/js/app.js?appId=dpu8syza2";
    churnkeyScript.defer = true;
    document.body.appendChild(churnkeyScript);
  }
};

const insertVwoScript = (): void => {
  const link = document.createElement("link");
  link.rel = "preconnect";
  link.href = "https://dev.visualwebsiteoptimizer.com";
  document.head.appendChild(link);

  const vwoScript = document.createElement("script");
  vwoScript.id = "vwoCode";
  vwoScript.innerHTML = `window._vwo_code=window._vwo_code || (function() {
  var account_id=736359,
  version = 1.5,
  settings_tolerance=2000,
  library_tolerance=2500,
  use_existing_jquery=false,
  is_spa=1,
  hide_element='body',
  hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
  /* DO NOT EDIT BELOW THIS LINE */
  f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\\s?(_vis_opt_exp_\\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\\d+_combi=(?:\\d+,?)+\\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`;
  document.body.appendChild(vwoScript);
};

const insertCustomerIoScript = (): void => {
  const script = document.createElement("script");
  const dataSiteId = config.customerIoDataSiteId;
  script.innerHTML = `/* From client snippets */
    var _cio = _cio || [];
    window._cio = _cio;
    (function() {
      var a,b,c;a=function(f){return function(){_cio.push([f].
      concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
      "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
      var t = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id    = 'cio-tracker';
      t.setAttribute('data-site-id', '${dataSiteId}');
      t.setAttribute('data-use-in-app', 'true');
      t.src = 'https://assets.customer.io/assets/track.js';
      s.parentNode.insertBefore(t, s);
    })();`;

  document.body.appendChild(script);
};

export const addJSSnippets = (): void => {
  insertBrownFont();
  insertSegmentScript();
  insertStripeScript();
  insertChurnKeyScript();
  if (isProdEnv()) {
    insertCustomerIoScript();
    insertVwoScript();
  }
};
