import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type ProjectContentBarService from "client/services/project-content-bar";
import type ScriptWorkstationService from "client/services/script-workstation";

export type FloatingPanelType = "animate" | "layers" | "style";

export default class FloatingPanelsService extends Service {
  @service
  private declare projectContentBar: ProjectContentBarService;

  @service
  private declare scriptWorkstation: ScriptWorkstationService;

  @tracked
  private panel: FloatingPanelType | undefined;

  get visible(): boolean {
    return this.panel !== undefined && !this.projectContentBar.expanded && !this.scriptWorkstation.expanded;
  }

  active(panel: FloatingPanelType): boolean {
    return this.panel === panel && this.visible;
  }

  show(panel: FloatingPanelType): void {
    this.projectContentBar.hide();
    this.scriptWorkstation.collapse();
    this.panel = panel;
  }

  hide(): void {
    this.panel = undefined;
  }

  toggle(panel: FloatingPanelType): void {
    this.active(panel) ? this.hide() : this.show(panel);
  }
}
