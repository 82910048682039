import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import defer from "client/lib/defer";

export class ConfirmDialog {
  @tracked
  title?: string;

  @tracked
  message?: string;

  @tracked
  confirmLabel?: string;

  @tracked
  cancelLabel?: string;

  constructor(props: Partial<ConfirmDialog>) {
    Object.assign(this, props);
  }
}

export default class ConfirmService extends Service {
  @tracked
  dialog?: ConfirmDialog;

  private promise = defer<boolean>();

  public open(dialog: Partial<ConfirmDialog> = {}): Promise<boolean> {
    this.promise = defer();
    this.dialog = new ConfirmDialog(dialog);

    return this.promise;
  }

  public close(confirm: boolean): void {
    this.dialog = undefined;
    this.promise.resolve(confirm);
  }
}
