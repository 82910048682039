import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import sum from "lodash/sum";
import type { ContentTypeOption } from "../../search/component";
import type { ContentQueryParams } from "../base/component";
import TrackingEvents from "client/events";
import { CONTENT_TRACKING_TYPES, ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type SelectableAsset from "client/models/selectable-asset";
import type ContentsCuratorService from "client/services/contents-curator";
import { ContentType } from "client/services/contents-curator";
import type TrackingService from "client/services/tracking";

interface Args {
  type: ContentTypeOption;
}

const DEFAULT_PER_PAGE = 25;

export default class ContentCurationComponent extends Component<Args> {
  @service
  declare contentsCurator: ContentsCuratorService;

  @service
  declare tracking: TrackingService;

  private curatorConfigured = false;

  @action
  config(): void {
    this.curatorConfigured = false;
  }

  @action
  async loadResults(params: { page: number }): Promise<ArrayProxy<SelectableAsset> | undefined> {
    if (!this.curatorConfigured) {
      this.curatorConfigured = true;
      void this.contentsCurator.config(this.curatorConfiguration);
    }

    const results = await this.contentsCurator.load(params["page"]);

    this.track(params["page"]);

    return results;
  }

  get params(): ContentQueryParams {
    return {
      // eslint-disable-next-line camelcase
      per_page: this.perPage,
      type: this.args.type
    };
  }

  private get curatorConfiguration(): { [key in ContentType]?: number } {
    const type = this.args.type.values;

    return type.reduce((acc: { [key in ContentType]?: number }, label: ContentType) => {
      acc[label] = Math.floor(DEFAULT_PER_PAGE / type.length);
      return acc;
    }, {});
  }

  private get perPage(): number {
    return sum(Object.values(this.curatorConfiguration));
  }

  private track(page: number): void {
    if (page > 1) {
      const trackingHelper = this.createTrackingHelper(page);

      void this.tracking.sendAnalytics(TrackingEvents.EVENT_DISCOVER_LOAD_MORE, trackingHelper);
    }
  }

  private createTrackingHelper(page: number | undefined): ContentTrackingHelper {
    return new ContentTrackingHelper(
      {
        searchFrom: SEARCH_LOCATION.CONTENT_DISCOVERY,
        pageNumber: page,
        results: this.formatResults()
      },
      this.args.type.label
    );
  }

  private formatResults(): Record<string, number> {
    const { totalCounts } = this.contentsCurator;

    return {
      [CONTENT_TRACKING_TYPES.GRAPHICS]: totalCounts[ContentType.GRAPHICS] ?? 0,
      [CONTENT_TRACKING_TYPES.PROJECT_SCENES]: totalCounts[ContentType.PROJECT_SCENE] ?? 0,
      [CONTENT_TRACKING_TYPES.PEXELS_IMAGE]: totalCounts[ContentType.PEXELS_PHOTO] ?? 0,
      [CONTENT_TRACKING_TYPES.PEXELS_VIDEO]: totalCounts[ContentType.PEXELS_VIDEO] ?? 0,
      [CONTENT_TRACKING_TYPES.STORYBLOCKS]: totalCounts[ContentType.STORYBLOCKS_VIDEO] ?? 0
    };
  }
}
