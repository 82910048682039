import { tracked } from "@glimmer/tracking";

export default class ProjectTranscriptionCaption {
  @tracked
  index: number;

  @tracked
  timeframe: string;

  @tracked
  text: string;

  constructor(index: number, timeframe: string, text: string) {
    this.index = index;
    this.timeframe = timeframe;
    this.text = text;
  }

  toString(): string {
    return `${this.index}\n${this.timeframe}\n${this.text}`;
  }
}
