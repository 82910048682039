import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

type LinkTo = {
  route: string;
  models: Array<string>;
  query: Object;
  currentWhen: boolean | string;
};

export interface Breadcrumb {
  linkTo: Partial<LinkTo>;
  label: string;
  id: string;
}

interface BreadcrumbsArgs {
  breadcrumbs?: Breadcrumb[];
}

enum Truncate {
  NONE = "none",
  HALF = "half",
  TWO_THIRDS = "two-thirds",
  MIDDLE = "middle",
  REST = "rest"
}

export default class BreadcrumbsComponent extends Component<BreadcrumbsArgs> {
  @service
  declare router: RouterService;

  @tracked
  truncate = Truncate.NONE;

  @tracked
  declare endBreadcrumbs: Breadcrumb[];

  @tracked
  declare frontBreadcrumbs: Breadcrumb[];

  @tracked
  declare truncatedBreadcrumbs: Breadcrumb[];

  @tracked
  declare width: number;

  declare resizeObserver: ResizeObserver;

  styleNamespace = getStyleNamespace("north-star/breadcrumbs");

  @action
  gotoFolder(breadcrumb: Breadcrumb): void {
    this.router.transitionTo("authenticated.folders.folder", breadcrumb.id).catch((_reason) => {
      /* Allow empty */
    });
  }

  private executeTruncateStrategy(): void {
    if (!this.args.breadcrumbs) {
      return;
    }

    let lowerBound;
    let upperBound;
    switch (this.truncate) {
      case Truncate.HALF:
        lowerBound = Math.floor(this.args.breadcrumbs.length / 2) - 1;
        upperBound = lowerBound * 2 + 1;
        break;
      case Truncate.TWO_THIRDS:
        lowerBound = Math.floor(this.args.breadcrumbs.length / 3) - 1;
        upperBound = lowerBound * 3 + 1;
        break;
      case Truncate.MIDDLE:
        lowerBound = 1;
        upperBound = this.args.breadcrumbs.length - 1;
        break;
      case Truncate.REST:
        lowerBound = 1;
        upperBound = this.args.breadcrumbs.length;
        break;
      default:
        lowerBound = 0;
        upperBound = 0;
    }

    this.frontBreadcrumbs = this.args.breadcrumbs.slice(0, lowerBound);
    this.truncatedBreadcrumbs = this.args.breadcrumbs.slice(lowerBound, upperBound);
    this.endBreadcrumbs = this.args.breadcrumbs.slice(upperBound, this.args.breadcrumbs.length);
  }

  private resetTruncateStrategy(element: HTMLElement): void {
    if (
      element.parentElement &&
      element.clientWidth <= element.parentElement.clientWidth &&
      element.scrollHeight <= element.parentElement.scrollHeight
    ) {
      this.truncate = Truncate.NONE;
      element.style.setProperty("visibility", "visible");
    }
  }

  private setTruncateStrategy(element: HTMLElement): void {
    if (!this.args.breadcrumbs || !element.parentElement) {
      return;
    }

    element.style.setProperty("visibility", "hidden");
    switch (this.truncate) {
      case Truncate.NONE:
        this.truncate = this.args.breadcrumbs.length >= 4 ? Truncate.HALF : Truncate.MIDDLE;
        break;
      case Truncate.HALF:
        this.truncate = this.args.breadcrumbs.length >= 6 ? Truncate.TWO_THIRDS : Truncate.MIDDLE;
        break;
      case Truncate.TWO_THIRDS:
        this.truncate = this.args.breadcrumbs.length > 6 ? Truncate.MIDDLE : Truncate.REST;
        break;
      case Truncate.REST:
        element.style.setProperty("width", `${element.parentElement.clientWidth}`);
        break;
      default:
        this.truncate = Truncate.REST;
    }
  }

  @action
  didInsert(element: HTMLElement): void {
    this.resizeObserver = new ResizeObserver(() => (this.width = element.clientWidth));
    this.resizeObserver.observe(element);
    this.endBreadcrumbs = this.args.breadcrumbs as Breadcrumb[];
  }

  @action
  didUpdateWidth(element: HTMLElement): void {
    if (!this.args.breadcrumbs) {
      return;
    }

    if (
      element.parentElement &&
      (element.clientWidth >= element.parentElement.clientWidth ||
        element.scrollHeight >= element.parentElement.scrollHeight)
    ) {
      this.setTruncateStrategy(element);
      this.executeTruncateStrategy();
    }
    this.resetTruncateStrategy(element);
  }

  @action
  didUpdateBreadcrumbs(): void {
    this.endBreadcrumbs = this.args.breadcrumbs as Breadcrumb[];
  }
}
