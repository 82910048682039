export enum TextAnimationId {
  NONE = "NONE",
  LTR_REVEAL = "LTR_REVEAL",
  SCALE_DOWN = "SCALE_DOWN",
  FADE_IN = "FADE_IN",
  LTR_SWIPE = "LTR_SWIPE",
  LINE_RISE = "LINE_RISE",
  LETTER_RISE = "LETTER_RISE",
  LETTER_SLIDE_IN = "LETTER_SLIDE_IN",
  SLIDE_AND_BOUNCE = "SLIDE_AND_BOUNCE"
}
