import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface PlansComparisonTilesComponentArgs {
  changeInterval: () => void;
  interval: string;
  plansToCompare: Plan[];
  selectYearlyInterval: () => void;
  skuRetired: boolean;
}

export default class PlansComparisonTilesComponent extends Component<PlansComparisonTilesComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-comparison-tiles");
}
