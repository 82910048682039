import podNames from "ember-component-css/pod-names";

const getStyleNamespace = (componentName: string): string => {
  const podName = podNames[componentName];

  if (!podName) {
    // eslint-disable-next-line no-console
    console.error(`Could not find the podName for '${componentName}'`);
    return "";
  }

  return podName;
};

export default getStyleNamespace;
