import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  isEditor: boolean;
}

export default class AppHeaderProject extends Component<Args> {
  styleNamespace = getStyleNamespace("app-header/project");
}
