import { service } from "@ember/service";
import Component from "@glimmer/component";
import type Folder from "client/models/folder";
import type FoldersService from "client/services/folders";
import type { Breadcrumb } from "client/services/folders";

interface Args {
  folder?: Folder;
  parentRouteName: string;
  title: string;
}

export default class FolderContentBreadcrumbsComponent extends Component<Args> {
  @service
  declare folders: FoldersService;

  get breadcrumbs(): Array<Breadcrumb> {
    if (this.args.folder) {
      return this.folders.breadcrumbPath(this.args.folder);
    }
    return [];
  }
}
