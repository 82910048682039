import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { Instance, OptionsGeneric, Placement } from "@popperjs/core";
import { createPopper } from "@popperjs/core";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TooltipArgs {
  target: HTMLElement;
  message: string;
  title: string;
  position: "left" | "right" | "top" | "bottom";
}

const POPPER_OPTIONS: Partial<OptionsGeneric<object>> = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 10]
      }
    }
  ],
  strategy: "fixed"
};

export default class TooltipComponent extends Component<TooltipArgs> {
  popper?: Instance;
  styleNamespace = getStyleNamespace("north-star/tooltip");

  get position(): Placement {
    return this.args.position ?? "top";
  }

  @action
  onDidInsert(element: HTMLElement): void {
    this.createPopperInstance(element);
  }

  @action
  onDidUpdate(): void {
    this.popper?.forceUpdate();
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyPopperInstance();
  }

  private createPopperInstance(element: HTMLElement): void {
    this.popper = createPopper(this.args.target, element, {
      ...POPPER_OPTIONS,
      placement: this.position
    });
  }

  private destroyPopperInstance(): void {
    this.popper?.destroy();
  }
}
