import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  onClick?: () => void;
}

export default class ToolbarCoreButtonComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/core/button");

  @action
  onClick(): void {
    this.args.onClick?.();
  }
}
