import Service, { service } from "@ember/service";
import config from "client/config/environment";
import type AuthService from "client/services/auth";
import MessageBus from "message-bus-client";

export default class AjaxHeadersService extends Service {
  @service
  auth!: AuthService;

  @service
  cookies: any;

  get headers(): { [key: string]: string } {
    const headers: { [key: string]: string } = {
      accept: "application/json",
      "x-client-app-version": config["APP_VERSION"],
      "x-ga-client-id": this.cookies.read("_ga")
    };

    if (this.auth.isAuthenticated) {
      headers["authorization"] = `Bearer ${this.auth.authToken}`;
    }

    // Keep MessageBus headers up to date
    MessageBus.headers = headers;

    return headers;
  }
}
