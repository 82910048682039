import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Project from "client/models/project";

export interface TemplateRouteParams {
  projectId: string;
}

export default class TemplateRoute extends Route<Project> {
  @service
  declare store: Store;

  async model({ projectId }: TemplateRouteParams): Promise<Project> {
    return this.store.peekRecord("project", projectId) ?? this.store.findRecord("project", projectId);
  }
}
