import { service } from "@ember/service";
import Model, { attr, belongsTo } from "@ember-data/model";
import type Team from "client/models/team";
import type CurrentUserService from "client/services/current-user";

export enum TeamMembershipStatus {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export enum TeamMembershipRole {
  ADMIN = "admin",
  PUBLISHER = "publisher",
  EDITOR = "editor",
  TRIAL = "trial"
}

export enum TeamMembershipRoleName {
  ADMIN = "Owner",
  PUBLISHER = "Publisher",
  EDITOR = "Editor"
}

const Roles = {
  admin: {
    name: TeamMembershipRoleName.ADMIN,
    seq: 0
  },
  publisher: {
    name: TeamMembershipRoleName.PUBLISHER,
    seq: 1
  },
  trial: {
    name: TeamMembershipRoleName.PUBLISHER,
    seq: 2
  },
  editor: {
    name: TeamMembershipRoleName.EDITOR,
    seq: 3
  }
};

export default class TeamMembership extends Model {
  @service
  declare currentUser: CurrentUserService;

  @attr("string")
  declare userId: string;

  @attr("string")
  declare email: string;

  @attr("string")
  declare fullName: string;

  @attr("boolean")
  readonly teamOwner?: boolean;

  @attr("string")
  declare status: TeamMembershipStatus;

  @attr("array")
  declare roles: TeamMembershipRole[];

  @attr("boolean")
  readonly publisherTrial?: boolean;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("team", { async: false, inverse: null })
  declare team: Team;

  // one role only for now
  get role(): TeamMembershipRole {
    if (this.publisherTrial) {
      return TeamMembershipRole.TRIAL;
    }

    return this.roles[0] ?? TeamMembershipRole.EDITOR;
  }

  get roleName(): TeamMembershipRoleName {
    return Roles[this.role].name;
  }

  get roleSeq(): number {
    return Roles[this.role].seq;
  }

  get isCurrentUser(): boolean {
    return this.currentUser.isCurrentUser(this.userId);
  }

  get nameOrEmail(): string {
    return this.fullName || this.email;
  }

  get identity(): string {
    let descriptor = "";

    if (this.isCurrentUser) {
      descriptor = " (you)";
    } else if (this.teamOwner) {
      descriptor = " (team owner)";
    }

    return this.fullName + descriptor;
  }

  get isActive(): boolean {
    return this.status === TeamMembershipStatus.ACTIVE;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    teamMembership: TeamMembership;
  }
}
