import MediaTypes from "./media-types";
import type { Background, Logo, Text } from "client/lib/editor-domain-model";

export interface Actions {
  canCopy: boolean;
  canPaste: boolean;
  canDuplicate: boolean;
  canOrder: boolean;
  canMoveToBackground: boolean;
  canEditTiming: boolean;
  canAdjustFrame: boolean;
  canTrimClip: boolean;
  canFitToClipLength: boolean;
  canReplace: boolean;
  canResetAspectRatio: boolean;
  canDelete: boolean;
  canMoveToForeground: boolean;
  canFitToCanvas: boolean;
}

export const MediaAction = (media: Text | Logo | Background, isBackground = false): Actions => {
  const type = new MediaTypes(media);
  switch (true) {
    case isBackground && type.isGraphic():
      return new BackgroundActionsBase().BackgroundAnimatedActions();

    case isBackground && type.isVideo():
      return new BackgroundActionsBase().BackgroundVideoActions();

    case isBackground && (type.isImage() || type.isSvg()):
      return new BackgroundActionsBase().BackgroundImageActions();

    case !isBackground && type.isGraphic():
      return new ForegroundActionsBase().ForegroundAnimatedActions();

    case !isBackground && type.isVideo():
      return new ForegroundActionsBase().ForegroundVideoActions();

    case !isBackground && type.isImage():
      return new ForegroundActionsBase().ForegroundImageActions();

    case !isBackground && type.isSvg():
      return new ForegroundActionsBase().ForegroundSvgActions();

    case type.isText():
      return new TextActions().TextActions();
    default:
      return new MediaActionsBase().createActions({});
  }
};

class MediaActionsBase {
  createActions(overrides: Partial<Actions>): Actions {
    const defaultActions: Actions = {
      canCopy: true,
      canPaste: true,
      canDelete: true,
      canDuplicate: false,
      canOrder: false,
      canMoveToBackground: false,
      canEditTiming: false,
      canAdjustFrame: false,
      canTrimClip: false,
      canFitToClipLength: false,
      canReplace: false,
      canResetAspectRatio: false,
      canMoveToForeground: false,
      canFitToCanvas: false
    };

    return {
      ...defaultActions,
      ...overrides
    };
  }
}

class ForegroundActionsBase extends MediaActionsBase {
  ForegroundImageActions(): Actions {
    return this.createActions({
      canDuplicate: true,
      canOrder: true,
      canMoveToBackground: true,
      canEditTiming: true,
      canAdjustFrame: true,
      canTrimClip: false,
      canFitToClipLength: false,
      canReplace: true,
      canResetAspectRatio: true,
      canMoveToForeground: false,
      canFitToCanvas: false
    });
  }

  ForegroundSvgActions(): Actions {
    return this.createActions({
      canDuplicate: true,
      canOrder: true,
      canMoveToBackground: true,
      canEditTiming: true,
      canAdjustFrame: false,
      canTrimClip: false,
      canFitToClipLength: false,
      canReplace: true,
      canResetAspectRatio: true,
      canMoveToForeground: false,
      canFitToCanvas: false
    });
  }

  ForegroundVideoActions(): Actions {
    return this.createActions({
      canDuplicate: true,
      canOrder: true,
      canMoveToBackground: true,
      canEditTiming: true,
      canAdjustFrame: true,
      canTrimClip: true,
      canFitToClipLength: true,
      canReplace: true,
      canResetAspectRatio: true,
      canDelete: true,
      canMoveToForeground: false,
      canFitToCanvas: false
    });
  }

  ForegroundAnimatedActions(): Actions {
    return this.createActions({
      canDuplicate: true,
      canOrder: true,
      canMoveToBackground: true,
      canEditTiming: true,
      canAdjustFrame: false,
      canTrimClip: true,
      canFitToClipLength: false,
      canReplace: true,
      canResetAspectRatio: true,
      canDelete: true,
      canMoveToForeground: false,
      canFitToCanvas: false
    });
  }
}

class TextActions extends ForegroundActionsBase {
  TextActions(): Actions {
    return this.createActions({
      canCopy: true,
      canPaste: true,
      canDuplicate: true,
      canEditTiming: true,
      canOrder: true
    });
  }
}

class BackgroundActionsBase extends MediaActionsBase {
  BackgroundImageActions(): Actions {
    return this.createActions({
      canMoveToForeground: true,
      canFitToCanvas: true,
      canDuplicate: false,
      canOrder: false,
      canMoveToBackground: false,
      canEditTiming: false,
      canAdjustFrame: false,
      canTrimClip: false,
      canFitToClipLength: false,
      canReplace: true,
      canResetAspectRatio: false,
      canDelete: true
    });
  }

  BackgroundVideoActions(): Actions {
    return this.createActions({
      canMoveToForeground: true,
      canFitToCanvas: true,
      canDuplicate: false,
      canOrder: false,
      canMoveToBackground: false,
      canEditTiming: false,
      canAdjustFrame: false,
      canTrimClip: true,
      canFitToClipLength: true,
      canReplace: true,
      canResetAspectRatio: false,
      canDelete: true
    });
  }

  BackgroundAnimatedActions(): Actions {
    return this.createActions({
      canMoveToForeground: true,
      canFitToCanvas: false,
      canDuplicate: false,
      canOrder: false,
      canMoveToBackground: false,
      canEditTiming: false,
      canAdjustFrame: false,
      canTrimClip: true,
      canFitToClipLength: false,
      canReplace: true,
      canResetAspectRatio: false,
      canDelete: true
    });
  }
}
