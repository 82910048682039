import { service } from "@ember/service";
import Component from "@glimmer/component";
import type AuthService from "client/services/auth";

export default class PurchaseUserNotAuthorisedComponent extends Component {
  @service
  declare auth: AuthService;

  get teamOwnerEmail(): string | undefined {
    return this.auth.currentTeam?.ownerEmail;
  }
}
