import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type TrackingEvents from "client/events";
import type { UserTargetingEventProperties } from "client/models/user-targeting-data";
import type UserTargetingData from "client/models/user-targeting-data";
import AnalyticsService from "client/services/analytics";
import type HoneybadgerService from "client/services/honeybadger";
import type OnboardService from "client/services/onboard";
import type PromptsService from "client/services/prompts";
import type SessionService from "client/services/session";

const OTHER = "Other";

export default abstract class OnboardingStepController extends Controller<UserTargetingData> {
  abstract get onboardingDefaultProperty(): keyof UserTargetingData;

  abstract get onboardingOtherProperty(): keyof UserTargetingData | undefined;

  abstract get onboardingTrackingProperties(): Partial<UserTargetingEventProperties>;

  abstract otherPrompt?: {
    title: string;
    inputPlaceholder: string;
  };

  abstract selectEvent: TrackingEvents;

  abstract get previousStep(): string | undefined;

  abstract get nextStep(): string | undefined;

  @service
  declare prompts: PromptsService;

  @service
  declare session: SessionService;

  @service
  declare router: RouterService;

  @service
  declare onboard: OnboardService;

  @service
  declare honeybadger: HoneybadgerService;

  @tracked
  submitting = false;

  @tracked
  selected = false;

  @action
  async setOnboardingProperty(value: string): Promise<void> {
    // Prevent multiple submits
    if (this.selected) {
      return;
    }

    this.selected = true;
    this.setOnboardingPropertyValue(value);

    this.trackSelect();
    await this.continue();
  }

  @action
  async showOtherDialog(): Promise<void> {
    if (!this.onboardingOtherProperty || !this.otherPrompt) {
      return;
    }

    const { title, inputPlaceholder } = this.otherPrompt;

    const other = await this.prompts.show({
      title,
      inputPlaceholder,
      submitLabel: "Continue",
      required: true
    });

    if (other) {
      this.model.set(this.onboardingDefaultProperty, OTHER);
      this.model.set(this.onboardingOtherProperty, other);

      this.trackSelect();
      void this.continue();
    }
  }

  protected setOnboardingPropertyValue(value: string): void {
    this.model.set(this.onboardingDefaultProperty, value);

    if (this.onboardingOtherProperty) {
      this.model.set(this.onboardingOtherProperty, undefined);
    }
  }

  get onboardingProperty(): keyof UserTargetingData {
    if (this.onboardingOtherProperty && this.otherPropertyValue) {
      return this.onboardingOtherProperty;
    } else {
      return this.onboardingDefaultProperty;
    }
  }

  get isOtherSelected(): boolean {
    return this.defaultPropertyValue === OTHER || !!this.otherPropertyValue;
  }

  get propertyValue(): string | undefined {
    return this.model.get(this.onboardingProperty) as string | undefined;
  }

  get defaultPropertyValue(): string | undefined {
    return this.model.get(this.onboardingDefaultProperty) as string | undefined;
  }

  get otherPropertyValue(): string | undefined {
    if (this.onboardingOtherProperty) {
      return this.model.get(this.onboardingOtherProperty) as string | undefined;
    } else {
      return undefined;
    }
  }

  get disabled(): boolean {
    return !this.propertyValue;
  }

  protected async continue(): Promise<void> {
    this.submitting = true;

    try {
      if (this.nextStep) {
        await this.save();
        await this.router.transitionTo(this.nextStep);
      } else {
        await this.submit();
        await this.onboard.next();
      }
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, {
        message: "Error in the onboarding continue method",
        name: "Onboarding error"
      });
    } finally {
      this.submitting = false;
    }
  }

  private trackSelect(): void {
    AnalyticsService.track(this.selectEvent, this.onboardingTrackingProperties);
  }

  private async submit(): Promise<void> {
    await this.model.submit();
  }

  private async save(): Promise<void> {
    await this.model.saveTargetingData();
  }
}
