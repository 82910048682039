import { RendererEngineConfig } from "renderer-engine";
import config from "client/config/environment";
import { isGecko } from "client/lib/user-agent";
import { notify } from "client/services/honeybadger";

const initialize = (): void => {
  RendererEngineConfig.isGecko = isGecko;
  RendererEngineConfig.onError = notify;
};

export default {
  initialize
};
