import JSONAPISerializer from "@ember-data/serializer/json-api";
import type Store from "@ember-data/store";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from "ember-data";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from "ember-data/model";

interface BaseAttributeError {
  title?: string;
  detail?: string;
}

export default class ApplicationSerializer extends JSONAPISerializer {
  extractErrors(store: Store, typeClass: ModelSchema<keyof ModelRegistry>, payload: any, _id: string | number): any {
    const extracted = {
      base: new Array<any>()
    };

    payload.errors.forEach((error: BaseAttributeError) => {
      extracted.base.push(error.detail || error.title || "");
    });

    extracted.base.push(payload.errors?.[0]?.status);

    return extracted;
  }
}
