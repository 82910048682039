import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import Project from "client/models/project";

interface CreateProjectModalArgs {
  cancel(): unknown;
  createProject(project: Project): unknown;
}

export default class CreateProjectModalComponent extends Component<CreateProjectModalArgs> {
  @service
  declare store: Store;

  styleNamespace = getStyleNamespace("discovery/create-project-modal");

  @action
  createProject(_: Project | undefined, properties: Pick<Project, "aspectRatio"> & Partial<Project>): unknown {
    const project = Project.createWithDefaults(this.store, {
      ...properties
    });

    return this.args.createProject(project);
  }
}
