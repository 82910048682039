import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ExportService extends Service {
  @tracked
  declare isChooseThumbnail: boolean;

  hideChooseThumbnail(): void {
    this.isChooseThumbnail = false;
  }

  showChooseThumbnail(): void {
    this.isChooseThumbnail = true;
  }
}
