import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type AuthService from "client/services/auth";
import type OnboardService from "client/services/onboard";

export default class LogoutRoute extends Route {
  @service
  declare auth: AuthService;

  @service
  declare onboard: OnboardService;

  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    void this.auth.logout();
    // Ensure a user cannot get stuck in a waiting to onboard loop
    this.onboard.end();
    await this.router.transitionTo("unauthenticated.sign-up");
  }
}
