import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import type Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import isMailtoURI from "validator/es/lib/isMailtoURI";
import isURL from "validator/es/lib/isURL";
import TrackingEvents from "client/events";
import type CurrentUserService from "client/services/current-user";
import type NotificationsService from "client/services/notifications";
import { NotificationPosition } from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export default class PublishEndscreenController extends Controller {
  buttonId = `${guidFor(this)}__Button`;
  messageId = `${guidFor(this)}__Message`;

  @tracked
  ctaText = this.model?.overlay?.ctaText ?? "Find out more";

  @tracked
  ctaUrl = this.model?.overlay?.ctaUrl ?? "";

  @tracked
  isDirty = false;

  @tracked
  enableEndscreenCta = this.model?.overlay?.options.endscreenCta.enabled ?? true;

  @tracked
  enableBannerCta = this.model?.overlay?.options.bannerCta.enabled ?? true;

  @tracked
  message = this.model?.overlay?.message ?? "Take action before it's too late";

  @service
  declare currentUser: CurrentUserService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  get ctaTextErrorExcess(): number {
    return this.ctaText.length > 0 ? 20 - this.ctaText.length : 0;
  }

  get ctaTextError(): string {
    return this.ctaText.length > 0 ? "Button text must not exceed 20 characters" : "Please enter button text";
  }

  get disable(): boolean {
    const isCTAEnabled = this.enableBannerCta || this.enableEndscreenCta;
    const isEmptyURL = this.ctaUrl === "";
    const isInvalidContent = !(this.validUrl && this.validCtaText && this.validMessage);
    return isCTAEnabled && (isEmptyURL || (this.isDirty && isInvalidContent));
  }

  get validCtaText(): boolean {
    return this.ctaText.length > 0 && this.ctaText.length <= 20;
  }

  get validMessage(): boolean {
    return !this.message || this.message.length <= 60;
  }

  get validMessageErrorExcess(): number {
    return this.message.length > 0 ? 60 - this.message.length : 0;
  }

  get validUrl(): boolean {
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    return (
      isURL(this.ctaUrl, {
        protocols: ["http", "https"],
        require_protocol: true // eslint-disable-line camelcase
      }) || isMailtoURI(this.ctaUrl)
    );
    /* eslint-enable @typescript-eslint/no-unsafe-argument */
  }

  @action
  cancel(): void {
    this.ctaText = this.model?.overlay?.ctaText ?? "Find out more";
    this.ctaUrl = this.model?.overlay?.ctaUrl ?? "";
    this.enableEndscreenCta = this.model?.overlay?.options.endscreenCta.enabled ?? true;
    this.enableBannerCta = this.model?.overlay?.options.bannerCta.enabled ?? true;
    this.message = this.model?.overlay?.message ?? "Take action before it's too late";
    void this.router.transitionTo("authenticated.publish");
  }

  notify(): void {
    this.notifications.success("Call-to-action saved", {
      position: NotificationPosition.Top,
      timeout: 10000,
      button: {
        label: "Preview page",
        onClick: this.onPreviewClick
      }
    });
  }

  @action
  onPreviewClick(): void {
    const url: string = this.model.videoPage.get("url");
    window.open(url, "_blank", "noopener");
  }

  @action
  async onUpgrade(): Promise<void> {
    void this.router.transitionTo("authenticated.publish");
  }

  @action
  async save(): Promise<void> {
    const overlay = this.model.overlay;
    const videoPage = this.model.videoPage;

    try {
      if (overlay) {
        overlay.ctaText = this.ctaText;
        overlay.ctaUrl = this.ctaUrl;
        overlay.enabled = this.enableEndscreenCta;
        overlay.options = {
          endscreenCta: {
            enabled: this.enableEndscreenCta
          },
          bannerCta: {
            enabled: this.enableBannerCta
          }
        };
        overlay.message = this.message;

        await overlay.save();
      } else {
        videoPage.overlay = await this.store
          .createRecord("overlay", {
            ctaText: this.ctaText,
            ctaUrl: this.ctaUrl,
            enabled: this.enableEndscreenCta,
            message: this.message,
            name: this.model.project.title,
            user: this.currentUser.user,
            options: {
              endscreenCta: {
                enabled: this.enableEndscreenCta
              },
              bannerCta: {
                enabled: this.enableBannerCta
              }
            }
          })
          .save();
        await videoPage.save();
      }

      this.trackSaveEvent();

      this.notify();

      await this.refreshModel();

      await this.router.transitionTo("authenticated.publish");
    } catch (error) {
      this.notifications.error("There was a problem saving the call-to-action");
      throw error;
    }
  }

  @action
  toggleEnableBannerCta(): void {
    this.enableBannerCta = !this.enableBannerCta;
  }

  @action
  toggleEnableEndscreenCta(): void {
    this.enableEndscreenCta = !this.enableEndscreenCta;
  }

  @action
  markDirty(): void {
    this.isDirty = true;
  }

  async refreshModel(): Promise<void> {
    await (getOwner(this).lookup("route:authenticated.publish") as Route).refresh();
  }

  trackSaveEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.CALL_TO_ACTION_SAVE, {
      // eslint-disable-next-line camelcase
      project_id: this.model.project.id
    });
  }
}
