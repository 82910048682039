import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { ContributorTypes } from "client/models/folder";
import Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type FoldersService from "client/services/folders";
import type GalleryService from "client/services/gallery";

interface FolderPreviewBaseComponentArgs {
  folder: Folder;
  content: FolderContent;
  parentRouteName?: string;
  refresh: () => void;
  icon?: HTMLElement;
  setFolder: (folder: Folder) => void;
}

export default class FolderPreviewBaseComponent extends Component<FolderPreviewBaseComponentArgs> {
  @service
  declare gallery: GalleryService;

  @service
  declare folders: FoldersService;

  @tracked
  clonedItem?: HTMLElement;

  @tracked
  dragTarget = false;

  styleNamespace = getStyleNamespace("folders/folder-preview/base");

  @action
  async draggedOnto(content: FolderContent): Promise<void> {
    if (this.args.content instanceof Folder) {
      const folder = this.args.content as Folder;
      await this.folders.moveContent(
        folder,
        content,
        folder.contributable as ContributorTypes,
        folder.name,
        this.args.parentRouteName
      );

      this.dragTarget = false;
      this.gallery.dragging = false;

      this.args.refresh?.();
    }
  }

  @action
  draggedOver(): void {
    this.dragTarget = true;
  }

  @action
  draggedOut(): void {
    this.dragTarget = false;
  }

  @action
  onDragEnd(): void {
    this.gallery.dragging = false;
    this.clonedItem?.remove();
  }

  @action
  onDragStart(event: DragEvent): void {
    this.gallery.dragging = true;

    this.gallery.setDraggedFolder(this.args.folder);

    if (this.args.icon) {
      this.clonedItem = this.args.icon.cloneNode(true) as HTMLElement;
      this.clonedItem.classList.add(`${this.styleNamespace}__DragImage`);
      document.body.appendChild(this.clonedItem);
      event.dataTransfer?.setDragImage(this.clonedItem, 0, 0);
    }
  }

  @action
  onClick(): void {
    this.args.setFolder(this.args.folder);

    void this.args.refresh?.();
  }

  get routeName(): string {
    return `${this.args.parentRouteName}.folder`;
  }

  get isDragTarget(): boolean {
    return this.dragTarget;
  }
}
