import type User from "client/models/user";

export interface ProductOrder {
  user: User;
  orderId: string;
  monetaryUnits: number;
  currency: string;
  productId: string;
  sku: string;
  category: string;
  name: string;
}

export default class OrderCompleteEvent {
  order: ProductOrder;

  constructor(order: ProductOrder) {
    this.order = order;
  }

  properties(): object {
    /* eslint-disable camelcase */
    return {
      user_id: this.order.user.get("id"),
      email: this.order.user.get("email"),
      order_id: this.order.orderId,
      total: this.order.monetaryUnits,
      currency: this.order.currency,
      products: [
        {
          product_id: this.order.productId,
          sku: this.order.sku,
          category: this.order.category,
          name: this.order.name,
          price: this.order.monetaryUnits,
          quantity: 1
        }
      ]
    };
    /* eslint-enable camelcase */
  }
}
