import Adapter from "@ember-data/adapter";
import RSVP from "rsvp"; // eslint-disable-line import/no-named-as-default-member
import type { UnsplashPhotoPayload, UnsplashPhotoQueryPayload } from "../serializers/unsplash-photo";
import config from "client/config/environment";

const API_URL = "https://api.unsplash.com";

export interface IUnplashPaginatedParams {
  page?: number;
  per_page?: number;
}

export interface IUnsplashQueryParams extends IUnplashPaginatedParams {
  query?: string;
}

export default class UnsplashPhotoAdapter extends Adapter {
  host = API_URL;

  pathForType(): string {
    return "photos";
  }

  get headers(): {} {
    return {
      authorization: `Client-ID ${config.unsplashAccessKey}`
    };
  }

  updateRecord(): any {
    // eslint-disable-next-line no-console
    console.error("Failed to call `updateRecord` on `UnsplashPhoto`. An `UnsplashPhoto` cannot be updated");
  }

  deleteRecord(): any {
    // eslint-disable-next-line no-console
    console.error("Failed to call `deleteRecord` on `UnsplashPhoto`. An `UnsplashPhoto` cannot be deleted");
  }

  api(path: string, params?: {}): RSVP.Promise<any> {
    const url = new URL(this.host + path);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    return new RSVP.Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url.toString(), {
          method: "GET",
          mode: "cors",
          headers: this.headers
        });
        const data = await response.json();

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  // FIXME - will
  findRecord(_: any, __: any, id: string): RSVP.Promise<any> {
    return this.api(`/photos/${id}`);
  }

  findAll(): RSVP.Promise<UnsplashPhotoPayload[]> {
    return this.api("/photos", { page: 1, per_page: 12 }); // eslint-disable-line camelcase
  }

  // FIXME - will
  query(_: any, __: any, params: IUnsplashQueryParams): RSVP.Promise<UnsplashPhotoQueryPayload> {
    if (params.query && params.query.trim()) {
      return this.api("/search/photos", params);
    }

    delete params.query;
    return this.api("/photos", params);
  }
}
