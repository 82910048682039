import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
// import ScenesRoute from "client/authenticated/project/scenes/route";
import type ProjectExportController from "./controller";
import TrackingEvents from "client/events";
import type Comment from "client/models/comment";
import type Overlay from "client/models/overlay";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";
import type ProjectTranscription from "client/models/project-transcription";
import ShutterstockCredits from "client/models/shutterstock-credits";
import ShutterstockPurchases from "client/models/shutterstock-purchases";
import type AuthService from "client/services/auth";
import type CommentService from "client/services/comment";
import type CurrentUserService from "client/services/current-user";
import type PaymentSourceService from "client/services/payment-source";
import type ProjectThumbnailGeneratorService from "client/services/project-thumbnail-generator";
import type TrackingService from "client/services/tracking";

export interface PublishModel {
  project: Project;
  comments?: Array<Comment>;
  openAnalytics: boolean;
  hasEndscreen: boolean;
  latestRender?: ProjectRender;
  projectTranscription?: ProjectTranscription;
}

export default class PublishRoute extends Route<PublishModel> {
  @service
  declare auth: AuthService;

  @service
  declare comment: CommentService;

  @service
  declare currentUser: CurrentUserService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare paymentSource: PaymentSourceService;

  @service
  declare projectThumbnailGenerator: ProjectThumbnailGeneratorService;

  @service
  declare tracking: TrackingService;

  async model(params: { projectId: string }): Promise<PublishModel> {
    const project = await this.store.findRecord("project", params.projectId);

    await Promise.all(
      project
        .hasMany("projectScenes")
        .ids()
        .map((psi) => this.store.findRecord("projectScene", psi))
    );

    await Promise.all([project.projectDigest.reload(), project.stockLicenses.reload()]);

    const openAnalytics = new URLSearchParams(location.search).has("open_analytics");
    const overlay = await this.loadOverlay(project);

    const comments = await this.store.query("comment", {
      "parent-comment-id": null, // eslint-disable-line no-null/no-null
      "project-id": project.id
    });
    // @ts-expect-error
    this.comment.comments = comments;

    const latestRender = await project.latestRender;
    const projectTranscription = await latestRender?.projectTranscription;

    await this.trackPageView(project.id);

    return {
      project,
      comments: this.comment.comments,
      openAnalytics,
      hasEndscreen: !!overlay,
      latestRender,
      projectTranscription
    };
  }

  async afterModel(): Promise<void> {
    this.projectThumbnailGenerator.generate();
  }

  async setupController(
    controller: ProjectExportController,
    model: PublishModel,
    transition: Transition<unknown>
  ): Promise<void> {
    super.setupController(controller, model, transition);
    controller.latestRender = model.latestRender;
    controller.projectTranscription = model.projectTranscription;

    try {
      controller.analyticsLoading = true;
      const watchSummary = await this.store.queryRecord("videoWatchEventSummary", {
        // eslint-disable-next-line camelcase
        project_id: model.project.id
      });
      controller.videoWatchEventSummary = watchSummary;
    } finally {
      controller.analyticsLoading = false;
    }

    // reload user to get the latest shutterstock credits usage
    await this.auth.reloadUser();

    const hasPayPerClip = !!(await this.paymentSource.getCard());
    const shutterstockCredits = new ShutterstockCredits(this.auth.currentUser, model.project);
    const shutterstockPurchases = new ShutterstockPurchases(model.project, shutterstockCredits, hasPayPerClip);
    controller.shutterstockCredits = shutterstockCredits;
    controller.shutterstockPurchases = shutterstockPurchases;
    const latestRender = await model.latestRender;
    controller.latestRender = latestRender;
  }

  private async loadOverlay(project: Project): Promise<Overlay | undefined> {
    const videoPage = await project.videoPage;
    return videoPage?.overlay;
  }

  private async trackPageView(projectId: string): Promise<void> {
    // eslint-disable-next-line camelcase
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_VIEW_PUBLISH_PAGE, { project_id: projectId });
  }
}
