import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import Component from "@glimmer/component";
import iro from "@jaames/iro";

interface ColorSliderArgs {
  color?: string;
  onChange?(color: string): unknown;
  opacity?: boolean;
}

export default class ColorSliderComponent extends Component<ColorSliderArgs> {
  handleId = `${guidFor(this)}__Handle`;

  private color?: string;

  private colorPicker?: iro.ColorPicker;

  @action
  didInsert(element: HTMLElement, [color]: [string | undefined]): void {
    this.colorPicker = iro.ColorPicker(element, this.getPickerAttrs());

    this.setColor(color);

    this.colorPicker.on("input:end", (color: iro.Color) => {
      this.color = this.args.opacity ? color.hex8String.toUpperCase() : color.hexString.toUpperCase();

      this.args.onChange?.(this.color);
    });
  }

  @action
  didUpdateColor(_: HTMLElement, [color]: [string | undefined]): void {
    this.setColor(color);
  }

  private setColor(color: string | undefined): void {
    if (color && this.colorPicker) {
      if (this.args.opacity) {
        if (color.toLowerCase() === "black") {
          this.colorPicker.color.hex8String = "#000000FF";
        } else {
          this.colorPicker.color.hex8String = color;
        }
      } else {
        if (color.toLowerCase() === "black") {
          this.colorPicker.color.hexString = "#000000";
        } else {
          this.colorPicker.color.hexString = color;
        }
      }
    }

    this.color = color?.toUpperCase();
  }

  private getPickerAttrs(): Partial<object> {
    const attrs = {
      handleRadius: 4,
      handleSvg: `#${this.handleId}`,
      layout: [
        {
          component: iro.ui.Box,
          options: { boxHeight: 105 }
        },
        {
          component: iro.ui.Slider,
          options: { sliderType: "hue" }
        }
      ],
      margin: 2,
      sliderSize: 16,
      width: 164
    };

    if (this.args.opacity) {
      (attrs.layout as Array<object>).push({
        component: iro.ui.Slider,
        options: { borderColor: "#CCCEDA", borderWidth: 0.2, sliderType: "alpha" }
      });
    }

    return attrs;
  }
}
