import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import type { OnboardingSurveyRouteModel } from "client/authenticated/survey/onboarding/route";
import type OnboardingStepController from "client/controllers/onboarding-step";
import AnalyticsService from "client/services/analytics";
import type { TrackingPages } from "client/tracking/pages";

type OnboardingStepRouteModel = OnboardingSurveyRouteModel;

export default abstract class OnboardingStepRoute extends Route<OnboardingStepRouteModel> {
  abstract pageEvent: TrackingPages;

  @action
  didTransition(): void {
    AnalyticsService.page(this.pageEvent);
  }

  model(): OnboardingSurveyRouteModel {
    return this.modelFor("authenticated.survey.onboarding");
  }

  setupController(controller: OnboardingStepController, model: OnboardingStepRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    controller.selected = false;
  }
}
