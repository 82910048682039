import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { ContributorTypes } from "client/models/folder";
import type FoldersService from "client/services/folders";
import { DEFAULT_FILTER_ORDER } from "client/services/folders";

export default class AssetLibraryFolderLibraryController extends Controller {
  queryParams = ["order"];

  @service
  declare folders: FoldersService;

  @tracked
  order = DEFAULT_FILTER_ORDER;

  @tracked
  reload = false;

  @action
  onChangeOrder(order: string): void {
    this.order = order;
    this.folders.filterOrder = order;
  }

  @action
  refresh(): void {
    this.reload = !this.reload;
  }

  get title(): string {
    const { type } = this.model;

    switch (type) {
      case ContributorTypes.USER:
        return "My uploads";
      case ContributorTypes.TEAM:
        return "Team uploads";
      default:
        return "Library";
    }
  }
}
