import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { FontInfo, FontVariant, TextStyleProperties, TextStyle } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { BOLD_WEIGHT, isBoldWeight, NORMAL_WEIGHT } from "client/lib/text/text-styles";

interface Args {
  fonts: FontInfo[];
  textStyle: TextStyle;
  applyStyle: (style: TextStyleProperties) => void;
  active: boolean;
}

export default class ScriptWorkstationCaptionTextFormattingComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("script-workstation/caption/text-formatting");

  get variants(): FontVariant[] {
    const fontFamily = this.args.textStyle.fontFamily;
    return this.args.fonts.find((f) => f.name === fontFamily)?.variants || [];
  }

  private supports(enabled: (variant: FontVariant) => boolean): boolean {
    // The buttons are useful if both the enabled and disabled state exist,
    // e.g. bold and normal, or italics and non-italics.
    return !!this.variants.find(enabled) && !!this.variants.find((v) => !enabled(v));
  }

  get supportsBold(): boolean {
    return this.supports(({ weight }) => isBoldWeight(weight));
  }

  get supportsItalics(): boolean {
    return this.supports(({ style }) => style === "italic");
  }

  get bold(): boolean {
    const weight = this.args.textStyle.fontVariant?.weight;
    return !!weight && (weight.toLowerCase() === "bold" || isBoldWeight(weight));
  }

  get italics(): boolean {
    return this.args.textStyle.fontVariant?.style?.toLowerCase() === "italic";
  }

  get unorderedList(): boolean {
    return this.args.textStyle.list === "bullet";
  }

  get orderedList(): boolean {
    return this.args.textStyle.list === "ordered";
  }

  @action
  onBold(): void {
    this.args.applyStyle({
      fontVariant: {
        weight: String(this.bold ? this.args.textStyle.defaultWeight ?? NORMAL_WEIGHT : BOLD_WEIGHT)
      } as FontVariant
    });
  }

  @action
  onItalics(): void {
    this.args.applyStyle({
      fontVariant: {
        style: this.italics ? "normal" : "italic"
      } as FontVariant
    });
  }

  @action
  onUnorderedList(): void {
    this.args.applyStyle({
      list: this.unorderedList ? "" : "bullet"
    });
  }

  @action
  onOrderedList(): void {
    this.args.applyStyle({
      list: this.orderedList ? "" : "ordered"
    });
  }
}
