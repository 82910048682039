import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface PlansTableComponentArgs {
  changeInterval: () => void;
  interval: string;
  intervalPlans: Plan[];
  selectYearlyInterval: () => void;
}

export default class PlansTableComponent extends Component<PlansTableComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-table");
}
