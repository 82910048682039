import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { SafeString } from "handlebars";
import { TimeSlice, clamp, ResizeHandles } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { toFixed } from "client/lib/quick-math";
import { VideoTrimCalculation } from "client/lib/video-trim/calculation";

interface AppVideoTrimArgs {
  trim: TimeSlice;
  onChange: ({ startOffset, duration }: Pick<TimeSlice, "startOffset" | "duration">) => void;
  videoUrl: string;
  videoDuration: number;
  posterUrl?: string;
  muted?: boolean;
  hasAudio?: boolean;
}

export default class AppVideoTrimComponent extends Component<AppVideoTrimArgs> {
  @tracked
  videoElement?: HTMLVideoElement;

  @tracked
  trackWidth = 0;

  @tracked
  paused = true;

  @tracked
  currentTime = 0;

  styleNamespace = getStyleNamespace("app/video-trim");

  get startTime(): number {
    return this.args.trim.startOffset;
  }

  get duration(): number {
    return clamp(this.args.trim.duration, 0, this.args.videoDuration);
  }

  @action
  async togglePlayback(): Promise<void> {
    this.paused ? await this.play(this.startTime) : this.pause();
  }

  @action
  async play(startTime: number): Promise<void> {
    this.seek(startTime);

    if (this.videoElement) {
      await this.videoElement.play();
      this.paused = false;
    }
  }

  @action
  pause(): void {
    if (this.videoElement) {
      this.videoElement.pause();
      this.paused = true;
    }

    this.seek(this.startTime);
  }

  @action
  didInsertVideoElement(element: HTMLVideoElement): void {
    this.videoElement = element;

    element.currentTime = this.startTime;
    element.muted = !!this.args.muted;
    element.crossOrigin = "anonymous";

    element.src = this.args.videoUrl;
  }

  @action
  didInsertTrackElement(element: HTMLElement): void {
    const resizeObserver = new ResizeObserver(() => (this.trackWidth = element.clientWidth));
    resizeObserver.observe(element);
  }

  originalTrim = this.args.trim;

  @action
  async onDragStart(): Promise<void> {
    this.originalTrim = new TimeSlice(this.startTime, this.duration);
  }

  @action
  async onDrag(handle: ResizeHandles, delta: number): Promise<void> {
    const calculation = new VideoTrimCalculation({
      duration: this.originalTrim.duration,
      startTime: this.originalTrim.startOffset,
      maxEndTime: this.args.videoDuration
    });

    switch (handle) {
      case ResizeHandles.START: {
        calculation.applyStartTimeDelta(delta);
        break;
      }
      case ResizeHandles.END: {
        calculation.applyDurationDelta(delta);
        break;
      }
    }

    this.trim(calculation);
  }

  trim({ startTime: startOffset, duration }: { startTime: number; duration: number }): void {
    if (startOffset !== this.startTime || duration !== this.duration) {
      this.reset();
    }

    this.args.onChange({ startOffset, duration });
  }

  @action
  onVideoEnded(): void {
    this.reset();
  }

  @action
  onVideoTimeUpdate({ target }: Event): void {
    const video = target as HTMLVideoElement;
    const { currentTime } = video;

    // Check if video has reached end of trimmed duration and needs to stop
    if (currentTime >= this.endTime) {
      this.reset();
      return;
    }

    this.currentTime = currentTime;
  }

  seek(time: number): void {
    if (!isNaN(time) && this.videoElement) {
      this.videoElement.currentTime = toFixed(time, 2);
    }
  }

  @action
  reset(checkStartTime = false): void {
    if (checkStartTime && this.videoElement?.currentTime === this.startTime) {
      return;
    }

    this.pause();
  }

  get endTime(): number {
    return this.startTime + this.duration;
  }

  get startValue(): string {
    return `${this.startTime.toFixed(1)}s`;
  }

  get endValue(): string {
    return `${this.endTime.toFixed(1)}s`;
  }

  get trimStyles(): SafeString {
    const x = (this.startTime / this.args.videoDuration) * 100;
    const w = (this.duration / this.args.videoDuration) * 100;

    return htmlSafe(`left: ${x}%; width: ${w}%`);
  }

  get pixelsPerSecond(): number {
    return this.trackWidth / this.args.videoDuration;
  }
}
