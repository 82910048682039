import Model, { attr } from "@ember-data/model";

export default class ProjectThumbnail extends Model {
  @attr("string")
  declare projectId: string;

  @attr("string")
  declare key: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectThumbnail: ProjectThumbnail;
  }
}
