import type { Scene } from "../../scene";
import { StrictMutation } from "./mutation";

export class MoveBackgroundLayerMutation extends StrictMutation<void> {
  grouped = false;

  private originalLayerOrder: number;

  constructor(private scene: Scene, private direction: -1 | 1) {
    super();

    this.originalLayerOrder = scene.background.layerOrder;
  }

  run(): void {
    const { scene, direction } = this;
    const { background, minLayerOrder, maxLayerOrder } = scene;

    const nextLayerOrder = background.layerOrder + direction;

    background._layerOrder = this.clamp(nextLayerOrder, minLayerOrder, maxLayerOrder);
  }

  revert(): void {
    this.scene.background._layerOrder = this.originalLayerOrder;
  }
}
