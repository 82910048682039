import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type Comment from "client/models/comment";

export default class CommentService extends Service {
  @service
  declare store: Store;

  @tracked
  declare comments: Array<Comment>;

  async updateAll(projectId: string): Promise<void> {
    // @ts-expect-error
    this.comments = await this.store.query("comment", {
      "parent-comment-id": null, // eslint-disable-line no-null/no-null
      "project-id": projectId
    });
  }

  async delete(comment: Comment): Promise<void> {
    await comment.destroyRecord();
  }
}

declare module "@ember/service" {
  interface Registry {
    comments: CommentService;
  }
}
