import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type Overlay from "client/models/overlay";
import type Project from "client/models/project";
import type VideoPage from "client/models/video-page";

export interface PublishEndscreenRouteModel {
  overlay?: Overlay;
  project: Project;
  videoPage?: VideoPage;
}

export default class PublishEndscreenRoute extends Route {
  @service
  declare store: Store;

  async model(): Promise<PublishEndscreenRouteModel> {
    const { projectId } = this.paramsFor("authenticated.publish") as { projectId: string };
    const project = await this.loadProject(projectId);
    const videoPage = await project?.videoPage;
    const overlay = await videoPage?.overlay;

    return { overlay, project, videoPage };
  }

  private async loadProject(projectId: string): Promise<Project> {
    let project = this.store.peekRecord("project", projectId);
    if (!project) {
      project = await this.store.findRecord("project", projectId);
    }
    return project;
  }
}
