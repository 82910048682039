import serializer from "@ember-data/serializer";
import type Store from "@ember-data/store";
import type { ModelSchema } from "ember-data"; // eslint-disable-line ember/use-ember-data-rfc-395-imports
import ShutterstockFootage from "client/models/shutterstock-footage";

export interface ShutterstockQueryPayload {
  total_count: number;
  total_pages: number;
  data: ShutterstockPayload[];
  search_id: string;
  page: number;
  per_page: number;
}

export interface ShutterstockPayload {
  aspect: number;
  aspect_ratio: string;
  assets: {
    preview_jpg: { url: string };
    preview_mp4: { url: string };
    preview_webm: { url: string };
    thumb_jpg: { url: string };
    thumb_mp4: { url: string };
    thumb_webm: { url: string };
  };
  contributor: {
    id: string;
  };
  description: string;
  duration: number;
  has_model_release: boolean;
  id: string;
  media_type: string;
  search_id?: string;
}

enum RequestTypes {
  QUERY = "query",
  FIND_RECORD = "findRecord"
}

interface NormalizeResponse {
  id: string;
  type: string;
  attributes: Partial<ShutterstockFootage>;
}

export default class ShutterstockFootageSerializer extends serializer {
  normalize(
    _: ModelSchema,
    { id, aspect_ratio: aspectRatio, assets, description, duration, search_id }: ShutterstockPayload // eslint-disable-line camelcase
  ): NormalizeResponse {
    return {
      id,
      type: ShutterstockFootage.modelName as string,
      attributes: {
        aspectRatio,
        duration,
        description,

        previewImageUrl: assets.thumb_jpg.url,
        previewVideoUrl: assets.thumb_webm.url,

        lowResVideoUrl: assets.preview_webm.url,

        searchId: search_id // eslint-disable-line camelcase
      }
    };
  }

  normalizeResponse(
    _: Store,
    model: ModelSchema,
    payload: ShutterstockQueryPayload | ShutterstockPayload,
    __: string | number,
    requestType: string
  ): any {
    if (requestType === RequestTypes.QUERY) {
      const { data, total_count, search_id, page, per_page } = payload as ShutterstockQueryPayload; // eslint-disable-line camelcase

      const serialized = data.map((item) => this.normalize(model, { ...item, search_id })); // eslint-disable-line camelcase

      const meta = {
        page,
        "total-count": total_count, // eslint-disable-line camelcase
        "per-page": per_page // eslint-disable-line camelcase
      };

      return { data: serialized, included: [], meta };
    } else if (requestType === RequestTypes.FIND_RECORD) {
      const serialized = this.normalize(model, payload as ShutterstockPayload);

      return { data: serialized };
    }
  }
}
