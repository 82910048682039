import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PageTitleService from "client/services/page-title";

interface Args {
  hideNav?: boolean;
}

export default class AppPageComponent extends Component<Args> {
  @service
  declare pageTitle: PageTitleService;

  styleNamespace = getStyleNamespace("app-page");

  get title(): string {
    return this.pageTitle.title;
  }
}
