import type ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { TrackedArray } from "tracked-built-ins";
import type { ContentQueryParams } from "client/components/discovery/content/results/base/component";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import getStyleNamespace from "client/lib/get-style-namespace";
import { Lazy } from "client/lib/lazy";
import { SimpleRumTimer } from "client/lib/rum-timer";
import Favorite from "client/models/favorite";
import SelectableAsset from "client/models/selectable-asset";
import type CurrentUserService from "client/services/current-user";

type Result = SelectableAsset | Favorite;

interface Args {
  loadResults(params: ContentQueryParams): Promise<ArrayProxy<Result>>;
  params: ContentQueryParams;
  editor?: boolean;
}

const DEFAULT_COLUMN_WIDTH = 280;
export const DEFAULT_CONTENT_BAR_COLUMNS = 2;
const DEFAULT_EDITOR_CONTENT_BAR_COLUMNS = 3;

class AssetThumbnailDelegate {
  public ready: DeferredPromise<HTMLImageElement> = defer();
  public readonly image: HTMLImageElement;
  private readonly asset: SelectableAsset;

  constructor(asset: SelectableAsset) {
    this.asset = asset;
    this.image = new Image();

    const url = asset.thumbImageUrl;

    if (url) {
      this.image.onerror = (): void => {
        this.ready.resolve(this.image);
      };

      setTimeout(() => {
        if (this.ready.loading) {
          this.ready.resolve(this.image);
        }
      }, 10 * 1000);

      Object.assign(this.image, {
        onload: this.onload.bind(this),
        src: url
      });
    } else {
      this.ready.resolve(this.image);
    }
  }

  onload(): void {
    this.asset.thumbnailImageWidth = this.image.width;
    this.asset.thumbnailImageHeight = this.image.height;
    this.asset.thumbAspectRatio = `${this.image.width}:${this.image.height}`;

    this.ready.resolve(this.image);
  }
}

export default class InfiniteDisplayResultsComponent extends Component<Args> {
  @service
  declare currentUser: CurrentUserService;

  @tracked
  loading = false;

  @tracked
  resultsColumns: Result[][] = new TrackedArray([]);

  @tracked
  page = 1;

  @tracked
  totalResults = -1;

  @tracked
  error = false;

  /*
   * Each time a request is triggered store a unique identifier. When the response from the server is resolved we can
   * then determine whether the request has become stale.
   */
  private requestId?: number;
  /*
   * the height of each columns, used to allocate new item into the shortest column
   */
  private columnHeights: number[] = [];
  /*
   * column width, used to calculate the height (aspectRatio * columnWidth)
   */
  private columnWidth?: number;

  private resultsElement?: HTMLElement;

  private eventListener?: EventListener;

  private results: Result[] = [];

  styleNamespace = getStyleNamespace("discovery/content/infinite-display/results");

  @tracked
  columns = DEFAULT_CONTENT_BAR_COLUMNS;

  @tracked
  isInitialLoad = true;

  @tracked
  hasNextPage = true;

  @action
  paramsDidChange(): void {
    void this.loadFirstPage();
  }

  @action
  async didInsert(element: HTMLElement): Promise<void> {
    this.resultsElement = element;
    this.calculateColumns();

    void this.loadFirstPage();

    this.initializeResizing();
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    this.endResizing();
  }

  @action
  async loadFirstPage(): Promise<void> {
    this.resetState();

    await this.doLoadResults();
  }

  @action
  async loadNextPage(): Promise<void> {
    if (this.loading || !this.hasNextPage) {
      return;
    }

    this.page++;
    await this.doLoadResults();
  }

  get emptyResults(): boolean {
    return !this.loading && (this.totalResults === 0 || this.resultsColumns.every((column) => column.length === 0));
  }

  get hasError(): boolean {
    return this.error;
  }

  private getParams(): ContentQueryParams {
    return {
      ...this.args.params,
      page: this.page
    };
  }

  private getResultAsset(result: Result): SelectableAsset | undefined {
    if (result instanceof SelectableAsset) {
      return result;
    } else if (result instanceof Favorite) {
      return result.favorable as unknown as SelectableAsset;
    }
    return undefined;
  }

  private async preloadAssetThumbnails(results: Result[]): Promise<void> {
    const assets = results.map((result) => this.getResultAsset(result));

    await Promise.all(
      assets.map((asset) => {
        if (asset) {
          const delegate = new Lazy(() => new AssetThumbnailDelegate(asset));
          return delegate.get().ready;
        }
        return;
      })
    );
  }

  private async allocateResults(
    requestId: number,
    results: Array<Result> | undefined,
    isResizing = false
  ): Promise<void> {
    if (results) {
      if (!isResizing) {
        await this.preloadAssetThumbnails(results);
      }

      if (!this.isActiveRequest(requestId)) {
        return;
      }

      this.updateColumnWidthAndHeights();

      results.forEach((result) => {
        const index = this.findShortestColumn();

        this.resultsColumns[index]?.push(result);
        this.columnHeights[index] += this.heightAtSameWidth(result);
      });
    }
  }

  private async doLoadResults(): Promise<void> {
    const timer = new SimpleRumTimer("content-discovery-loading");
    timer.meta = {
      userId: this.currentUser.user?.id,
      type: this.args.params.type?.label,
      page: this.page
    };

    this.loading = true;
    try {
      const requestId = this.createRequestId();
      // @ts-expect-error
      const results = (await this.args.loadResults(this.getParams())) as Result[];
      timer.sample();

      if (this.isActiveRequest(requestId)) {
        // @ts-expect-error
        this.totalResults = results.meta["total-count"] ?? results.meta.totalCount;
        this.hasNextPage = results.length !== 0;
        await this.allocateResults(requestId, results);
        results.forEach((result) => this.results.push(result));
      }
    } catch (err) {
      this.error = true;
    } finally {
      this.loading = false;

      timer.meta["span"] = "page";
      timer.sample(true);
    }
  }

  private createRequestId(): number {
    this.requestId = Date.now();

    return this.requestId;
  }

  private clearRequestId(): void {
    this.requestId = undefined;
  }

  private isActiveRequest(requestId: number): boolean {
    return this.requestId === requestId;
  }

  private resetState(): void {
    this.clearRequestId();
    this.page = 1;
    this.totalResults = -1;
    this.error = false;
    this.resetResultColumns();
  }

  private findShortestColumn(): number {
    const minHeight = Math.min(...this.columnHeights);
    return Math.max(0, this.columnHeights.indexOf(minHeight));
  }

  private heightAtSameWidth(result: Result): number {
    let ratio = 9 / 16;
    const asset = this.getResultAsset(result);
    if (asset && asset.thumbnailImageHeight && asset.thumbnailImageWidth) {
      ratio = asset.thumbnailImageHeight / asset.thumbnailImageWidth;
    }

    return ratio * (this.columnWidth ?? 1);
  }

  private updateColumnWidthAndHeights(): void {
    if (this.resultsElement && this.resultsColumns.some((r) => r.length > 0)) {
      this.columnWidth = Array.from(this.resultsElement.children)[0]?.clientWidth;

      this.columnHeights = Array.from(this.resultsElement.children).map((child) => {
        const lastChild = child.children.item(child.children.length - 1) as HTMLElement;
        return lastChild ? lastChild.offsetTop + lastChild.offsetHeight : 0;
      });
    }
  }

  private resetResultColumns(): void {
    this.resultsColumns = new TrackedArray([]);
    this.columnHeights = [];
    this.columnWidth = undefined;

    for (let i = 0; i < this.columns; i++) {
      this.resultsColumns.push(new TrackedArray([]));
      this.columnHeights.push(0);
    }
  }

  private async handleResize(): Promise<void> {
    const previousColumns = this.columns;
    this.calculateColumns();

    if (this.columns !== previousColumns) {
      this.resetResultColumns();

      if (this.requestId) {
        await this.allocateResults(this.requestId, this.results, true);
      }
    }
  }

  private calculateColumns(): void {
    const columnWidth = this.columnWidth ?? DEFAULT_COLUMN_WIDTH;
    const elementWidth = this.resultsElement?.getBoundingClientRect().width;

    if (this.args.editor) {
      this.columns = DEFAULT_EDITOR_CONTENT_BAR_COLUMNS;
    } else if (elementWidth && elementWidth > columnWidth) {
      this.columns = Math.floor(elementWidth / columnWidth);
    } else {
      this.columns = 1;
    }
  }

  private initializeResizing(): void {
    this.eventListener = this.handleResize.bind(this);
    window.addEventListener("resize", this.eventListener);
  }

  private endResizing(): void {
    if (this.eventListener) {
      window.removeEventListener("resize", this.eventListener);
    }
  }
}
