import { service } from "@ember/service";
import Model, { attr, hasMany } from "@ember-data/model";
import formatMoney from "client/lib/format-money";
import type PlanFeatureValue from "client/models/plan-feature-value";
import type AuthService from "client/services/auth";
import type PermissionsService from "client/services/permissions";

export enum PlanInterval {
  MONTH = "month",
  YEAR = "year"
}

export const DEFAULT_PLAN_INTERVAL = PlanInterval.YEAR;

export enum SkuCode {
  FREE = "free",
  STARTER = "starter",
  PLUS = "plus",
  PRO = "pro",
  TEAM = "team",
  ULTIMATE = "ultimate",
  UNLIMITED = "unlimited",
  STORYTELLER = "storyteller",
  PREMIUM = "premium"
}

export enum PlanCurrencies {
  USD = "USD",
  AUD = "AUD"
}

const FREE_TIER = [SkuCode.FREE];

export const MIDDLE_TIER = [SkuCode.PLUS, SkuCode.PRO, SkuCode.ULTIMATE];

export const TOP_TIER = [SkuCode.TEAM, SkuCode.STORYTELLER, SkuCode.PREMIUM];

const TIER_LIST = [FREE_TIER, MIDDLE_TIER, TOP_TIER];

export default class Plan extends Model {
  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @attr("string")
  skuCode!: SkuCode;

  @attr("string")
  name!: string;

  @attr("string")
  currencySymbol!: string;

  @attr("string")
  currency!: string;

  @attr("number")
  monetaryUnits!: number;

  @attr("string")
  interval!: PlanInterval;

  @attr("number")
  intervalCount!: number;

  @attr("boolean")
  recurring!: boolean;

  @attr()
  highlights!: string[];

  @attr("string")
  subtitle!: string;

  @attr("string")
  tagline!: string;

  @attr("boolean")
  discontinued!: boolean;

  @hasMany("plan-feature-value", { async: false, inverse: "plan" })
  planFeatureValues!: PlanFeatureValue[];

  @attr("boolean")
  offered!: boolean;

  @attr("boolean")
  skuRetired!: boolean;

  @attr("number")
  declare publisherSeats: number;

  get displayName(): string {
    const period = this.isYearly ? "yearly" : "monthly";

    return `Biteable ${this.name} ${period} plan`;
  }

  get monthlyPrice(): string {
    return this.isMonthly ? this.formattedMonetaryUnits : this.oppositeIntervalFormattedMonetaryUnits;
  }

  get yearlyPrice(): string {
    return this.isYearly ? this.formattedMonetaryUnits : this.oppositeIntervalFormattedMonetaryUnits;
  }

  get monthlyMonetaryUnits(): number {
    return this.isMonthly ? this.monetaryUnits : Math.round((this.monetaryUnits / 12) * 100) / 100;
  }

  get upgradeable(): boolean {
    return !TOP_TIER.includes(this.skuCode);
  }

  get isTopTierPlan(): boolean {
    return TOP_TIER.includes(this.skuCode);
  }

  get isMiddleTierPlan(): boolean {
    return MIDDLE_TIER.includes(this.skuCode);
  }

  get currencyIndicator(): string {
    return this.currency === PlanCurrencies.AUD ? "A" : "";
  }

  get formattedMonetaryUnits(): string {
    return formatMoney(this.currencySymbol, this.monetaryUnits, this.currencyIndicator);
  }

  get billingInterval(): string {
    return this.isMonthly ? "" : `billed yearly at ${this.yearlyPrice}`;
  }

  get hasPublisherSeats(): boolean {
    return !!this.publisherSeats && this.publisherSeats > 1;
  }

  get isYearly(): boolean {
    return this.interval === PlanInterval.YEAR;
  }

  get isMonthly(): boolean {
    return this.interval === PlanInterval.MONTH;
  }

  get oppositeIntervalFormattedMonetaryUnits(): string {
    return this.oppositeIntervalFormattedValue(this.monetaryUnits);
  }

  get isFreePlan(): boolean {
    return this.skuCode === SkuCode.FREE;
  }

  public isPlan(skuCode: SkuCode, interval?: PlanInterval): boolean {
    if (interval) {
      return interval === this.interval && skuCode === this.skuCode;
    }

    return skuCode === this.skuCode;
  }

  public isSameTier(newPlan: Plan): boolean {
    return this.tierIndex(newPlan.skuCode) === this.tierIndex(this.skuCode);
  }

  public isHigherTier(newPlan: Plan): boolean {
    return this.tierIndex(newPlan.skuCode) > this.tierIndex(this.skuCode);
  }

  private tierIndex(skuCode: SkuCode): number {
    return TIER_LIST.findIndex((subArray) => subArray.includes(skuCode));
  }

  private oppositeIntervalFormattedValue(value: number, nearestDollar = false): string {
    const yearlyPrice = Math.round(value * 12 * 100) / 100;
    const monthlyPrice = Math.round((value / 12) * 100) / 100;
    const price = this.isMonthly ? yearlyPrice : monthlyPrice;

    return formatMoney(this.currencySymbol, nearestDollar ? Math.round(price) : price, this.currencyIndicator);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    plan: Plan;
  }
}
