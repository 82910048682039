import { action } from "@ember/object";
import type Owner from "@ember/owner";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectTranscriptionCaption from "client/models/project-transcription-caption";

interface EditCaptionsModalCaptionComponentArgs {
  caption: ProjectTranscriptionCaption;
  deleteCaption: (caption: ProjectTranscriptionCaption) => void;
}

export default class EditCaptionsModalCaptionComponent extends Component<EditCaptionsModalCaptionComponentArgs> {
  styleNamespace = getStyleNamespace("edit-captions-modal/caption");
  textElement!: HTMLElement;

  constructor(owner: Owner, args: EditCaptionsModalCaptionComponentArgs) {
    super(owner, args);
  }

  get captionStartTime(): string {
    return this.args.caption.timeframe.split(" --> ")[0]!;
  }

  get captionEndTime(): string {
    return this.args.caption.timeframe.split(" --> ")[1]!;
  }

  @action
  setTextElement(element: HTMLElement): void {
    this.textElement = element;
  }

  @action focusToText(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target;
    if (target instanceof HTMLInputElement) {
      target.focus();
    } else {
      this.textElement.focus();
    }
  }
}
