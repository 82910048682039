import { setOwner } from "@ember/application";
import type Owner from "@ember/owner";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { DEFAULT_PROJECT_SCENE_DURATION } from "renderer-engine";
import BrandApplier from "client/lib/brand-applier";
import type {
  ColorPreset,
  EventRegister,
  Media,
  Scene,
  Timeline,
  Caption,
  StrictMutation
} from "client/lib/editor-domain-model";
import {
  DeleteSceneMutation,
  CaptionAddLogoMutation,
  Watermark,
  AddNewCaptionMutation
} from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { transaction } from "client/lib/transaction";
import type SelectableAsset from "client/models/selectable-asset";
import type SelectableTextStyle from "client/models/selectable-text-style";
import UserAsset from "client/models/user-asset";
import type AdvancedEditorService from "client/services/advanced-editor";
import type BrandStyleService from "client/services/brand-style";
import type NotificationsService from "client/services/notifications";
import type PendingService from "client/services/pending";
import {
  PROJECT_SCENE_APPEND_FAILURE_NOTIFICATION,
  PROJECT_SCENE_APPEND_SUCCESS_NOTIFICATION
} from "client/services/projects";
import type SmartEditorService from "client/services/smart-editor";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export const DEFAULT_CAPTION_DURATION = 3; // seconds
export const generateNewCaption = async (scene: Scene, eventRegister: EventRegister): Promise<Caption | undefined> => {
  const mutation = new AddNewCaptionMutation(scene, 0, scene.duration, false, false);
  await mutation.prepare(eventRegister.facade);
  return eventRegister.fire(mutation as StrictMutation<Caption | undefined>);
};

export default class SceneAssetModifier {
  @service
  declare brandStyle: BrandStyleService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare pending: PendingService;

  @service
  declare smartEditor: SmartEditorService;

  constructor(
    owner: Owner,
    public timeline: Timeline,
    public eventRegister: EventRegister,
    public scene?: Scene,
    public afterScene?: Scene,
    public beforeScene?: Scene,
    public addMediaToNewScene?: boolean,
    public onClose?: () => void
  ) {
    setOwner(this, owner);
  }

  @transaction
  public async applyAsset(asset: SelectableAsset): Promise<void> {
    if (this.scene && !this.addMediaToNewScene) {
      await this.pending.push(this.applyAssetToScene(asset, this.scene));
    } else {
      await this.pending.push(this.applyAssetToTimeline(asset));
    }
  }

  @transaction
  public async applyBlankScene(): Promise<void> {
    try {
      await this.pending.push(this.addBlankSceneToTimeline());
    } catch (err) {
      this.notifications.error(PROJECT_SCENE_APPEND_FAILURE_NOTIFICATION);
      throw err;
    }
  }

  private newSceneOrder(): number | undefined {
    if (this.beforeScene) {
      return this.timeline.sceneOrder(this.beforeScene);
    } else {
      const scene = this.afterScene ?? this.activeScene;

      if (scene) {
        return this.timeline.sceneOrder(scene) + 1;
      }
    }

    return undefined;
  }

  private async addBlankSceneToTimeline(): Promise<void> {
    const { timeline, eventRegister } = this;
    const order = this.newSceneOrder();

    const mutation = new DomainModel.AddSceneMutation(timeline, DEFAULT_PROJECT_SCENE_DURATION, order);
    await mutation.prepare(eventRegister.facade);
    const newScene = eventRegister.fire(mutation) as Scene;

    await eventRegister.facade.saveSceneOrder(timeline);
    await eventRegister.facade.saveScene(newScene);

    await this.setActiveScene(newScene);
    void this.router.transitionTo("authenticated.project.scene", newScene.id).followRedirects();
  }

  private async applyAssetToScene(asset: SelectableAsset, scene: Scene): Promise<void> {
    await this.applyAssetToMedia(asset, scene, scene.background);

    this.notifications.success("Your scene has been updated");

    void this.router.transitionTo("authenticated.project.scene", scene.id).followRedirects();
  }

  public async applyAssetToMediaWithoutSaving(asset: SelectableAsset, scene: Scene, media: Media): Promise<void> {
    const { timeline, eventRegister } = this;

    await asset.trackAddToScene(timeline, scene);
    await asset.addToMedia(scene, media, eventRegister);

    if (asset.isBrandableTemplate && scene.colorPreset) {
      this.applyColorPresetToMedia(scene.colorPreset, media);
    }

    if (asset instanceof UserAsset && !!asset.thumbVideoUrl) {
      await this.fitCaptionDurationToAssetDuration(asset);
    }
  }

  public async addTextToScene(asset: SelectableTextStyle, scene: Scene): Promise<void> {
    const { eventRegister } = this;

    await asset.addToScene(scene, this.eventRegister);
    await eventRegister.facade.saveScene(scene);
  }

  public async addAssetToCaption(asset: SelectableAsset, scene: Scene, caption?: Caption): Promise<void> {
    const { eventRegister } = this;
    caption ??= await generateNewCaption(scene, eventRegister);

    if (caption) {
      const mutation = new CaptionAddLogoMutation(caption);
      const media = await mutation.prepare(eventRegister.facade);
      await this.applyAssetToMediaWithoutSaving(asset, scene, media);
      eventRegister.fire(mutation);
      await eventRegister.facade.saveScene(scene);
      await this.advancedEditor.transitionToLogo(caption, media);
    }
  }

  public async applyAssetToMedia(asset: SelectableAsset, scene: Scene, media: Media): Promise<void> {
    const { eventRegister } = this;

    await this.applyAssetToMediaWithoutSaving(asset, scene, media);

    await eventRegister.facade.saveScene(scene);

    if (media instanceof Watermark) {
      await eventRegister.facade.saveWatermark(media);
    }
  }

  private async applyAssetToTimeline(asset: SelectableAsset): Promise<void> {
    const { eventRegister, timeline } = this;
    const order = this.newSceneOrder();

    await asset.trackAddToTimeline(timeline);

    let duration;

    if (asset instanceof UserAsset && !!asset.thumbVideoUrl) {
      duration = await asset.getDurationForScene(true);
    }

    const newScene = await asset.addToTimeline(timeline, eventRegister, order, duration);

    if (asset.isBrandableTemplate) {
      await this.applyBrandToScene(timeline, newScene);
    }

    await eventRegister.facade.saveSceneOrder(timeline);
    await eventRegister.facade.saveScene(newScene);

    const activeSceneEmpty = !!this.activeScene && !this.activeScene.hasContent;
    if (activeSceneEmpty) {
      eventRegister.fire(new DeleteSceneMutation(timeline, this.activeScene));
      await eventRegister.facade.saveSceneOrder(timeline);
    }

    await this.setActiveScene(newScene);
    void this.router.transitionTo("authenticated.project.scene", newScene.id).followRedirects();
    this.notifications.success(PROJECT_SCENE_APPEND_SUCCESS_NOTIFICATION);

    if (activeSceneEmpty) {
      void this.router.transitionTo("authenticated.project.scene", newScene.id);
    } else if (this.afterScene || this.beforeScene) {
      void this.router.transitionTo("authenticated.project");
    }
  }

  private async applyBrandToScene(timeline: Timeline, scene: Scene): Promise<void> {
    const brand = await this.brandStyle.getBrand(timeline.brandStyleId);

    if (brand) {
      await brand.style.assetsLoaded;

      const brandApplier = new BrandApplier({ eventRegister: this.eventRegister });
      brandApplier.applyBrandToScene(scene, brand.style);
    }
  }

  private applyColorPresetToMedia(colorPreset: ColorPreset, media: Media): void {
    const brandApplier = new BrandApplier({ eventRegister: this.eventRegister });
    brandApplier.applyColorPresetToMedia(media, colorPreset);
  }

  private async fitCaptionDurationToAssetDuration(asset: UserAsset): Promise<void> {
    const duration = await asset.getDurationForScene(true);
    const caption = this.advancedEditor.caption;
    if (caption && duration > caption.duration) {
      await this.eventRegister.fire(new DomainModel.CaptionDurationChangeMutation(true, caption, duration));
    }
  }

  private get activeScene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  private async setActiveScene(scene: Scene): Promise<void> {
    this.advancedEditor.setScene(scene);
    this.smartEditor.activeObject = scene;
    this.onClose?.();
    this.timelineEvents.publish(TimelineEvents.ACTIVE_SCENE_CHANGED, { scene, animate: true });
  }
}
