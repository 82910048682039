import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import Favorable, { FAVORABLE_TYPES } from "client/models/favorable";
import type Favorite from "client/models/favorite";
import PexelsPhoto from "client/models/pexels-photo";
import ProjectScenePreview from "client/models/project-scene-preview";
import Sag from "client/models/sag";
import type SelectableAsset from "client/models/selectable-asset";
import Svg from "client/models/svg";
import type TrackingService from "client/services/tracking";

interface ContentItemArgs {
  asset: SelectableAsset;
  page?: number | string;
  selectAsset?: (asset: SelectableAsset) => void;
}

export default class ContentItemComponent extends Component<ContentItemArgs> {
  styleNamespace = getStyleNamespace("discovery/content/item");

  @service
  declare tracking: TrackingService;

  @action
  async toggleFavorite(event: MouseEvent | undefined): Promise<void> {
    event?.stopPropagation();

    if (this.isFavorited) {
      await this.unfavorite();
    } else {
      await this.favorite();
    }
  }

  get previewable(): boolean {
    return !this.args.selectAsset;
  }

  @action
  async favorite(): Promise<void> {
    this.trackFavorite();

    return void (await this.favorable?.favorite());
  }

  @action
  async unfavorite(): Promise<void> {
    return await this.favorable?.unfavorite();
  }

  get title(): string | undefined {
    return (
      (this.args.asset as unknown as PexelsPhoto).description ||
      (this.args.asset as unknown as ProjectScenePreview).title
    );
  }

  get type(): string {
    const assetType = (this.args.asset as unknown as Favorite).favorableType;

    return this.renameAssetType(assetType);
  }

  get isFavorable(): boolean {
    return !!this.favorable;
  }

  get isFavorited(): boolean {
    return !!this.favoriteRecord && !this.favoriteRecord.isDeleted;
  }

  get typeIndicatorIcon(): string {
    const asset = this.args.asset;

    if (asset instanceof ProjectScenePreview) {
      return "icon/content-discovery/scenes";
    } else if (asset instanceof Sag || asset instanceof Svg) {
      return "icon/content-discovery/graphics";
    } else if (asset instanceof PexelsPhoto) {
      return "icon/content-discovery/image";
    } else {
      return "icon/content-discovery/video";
    }
  }

  private get favorable(): Favorable | undefined {
    if (Favorable.trueFor(this.args.asset)) {
      return this.args.asset;
    }

    return undefined;
  }

  private get favoriteRecord(): Favorite | undefined {
    return this.favorable?.favoriteRecord;
  }

  private trackFavorite(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_ITEM_SAVED, {
      /* eslint-disable camelcase */
      asset_id: this.args.asset.id,
      page_number: this.args.page,
      type: this.type
      /* eslint-enable camelcase */
    });
  }

  private renameAssetType(assetType: string): string {
    switch (assetType) {
      case FAVORABLE_TYPES.PEXELS:
      case FAVORABLE_TYPES.UNSPLASH:
        return "photo";
      case FAVORABLE_TYPES.PEXELS_VIDEOS:
      case FAVORABLE_TYPES.VIDEO_BLOCKS:
        return "video";
      case FAVORABLE_TYPES.PROJECT_SCENES:
        return "scene";
      case FAVORABLE_TYPES.SAGS:
        return "animation";
      default:
        return assetType;
    }
  }
}
