import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PermissionsService from "client/services/permissions";
import type StorageService from "client/services/storage";
import type SubscriptionsService from "client/services/subscriptions";
import type TeamService from "client/services/team";
import type VisibilityChangeService from "client/services/visibility-change";

enum TileComponent {
  VIDEO_ASSISTANT = "discovery/dashboard/secondary-actions/video-assistant",
  INVITE_TEAM = "discovery/dashboard/secondary-actions/invite-team",
  TRIAL_COUNTDOWN = "discovery/dashboard/secondary-actions/trial-countdown",
  UPGRADE_PLAN = "discovery/dashboard/secondary-actions/upgrade-plan",
  WHATS_NEW = "discovery/dashboard/secondary-actions/whats-new"
}

export default class DashboardSecondaryActions extends Component {
  @service
  declare subscriptions: SubscriptionsService;

  @service
  declare permissions: PermissionsService;

  @service
  declare store: Store;

  @service
  declare storage: StorageService;

  @service
  declare team: TeamService;

  @service
  declare visibilityChange: VisibilityChangeService;

  @tracked
  loading = true;

  @tracked
  noProject = false;

  @tracked
  index = 0;

  @tracked
  componentsToShow: Array<TileComponent | undefined> = [];

  styleNamespace = getStyleNamespace("discovery/dashboard/secondary-actions");

  @action
  async didInsert(): Promise<void> {
    this.loading = true;

    try {
      this.componentsToShow = this.setComponentsToShow();
    } finally {
      this.loading = false;
    }
  }

  @action
  doDidUpdate(): void {
    this.componentsToShow = this.setComponentsToShow();
  }

  @action
  shuffleComponents(): void {
    if (this.index >= this.actionsCount - 1) {
      this.index = 0;
    } else {
      this.index++;
    }
  }

  @action
  async reload(): Promise<void> {
    await void this.didInsert();
    this.shuffleComponents();
  }

  get componentToShow(): TileComponent | undefined {
    return this.componentsToShow[this.index];
  }

  get componentIsDark(): boolean {
    return this.componentToShow === TileComponent.VIDEO_ASSISTANT;
  }

  get hide(): boolean {
    return !this.componentToShow;
  }

  get multipleActions(): boolean {
    return this.actionsCount > 1;
  }

  private get actionsCount(): number {
    return this.componentsToShow.length;
  }

  private get canShowUpgradePrompt(): boolean {
    return this.permissions.has("feature_edit_only_mode_notifications");
  }

  private get canShowTrialCountdown(): boolean {
    return this.subscriptions?.isTrialing;
  }

  private get canShowNewTrialCountdown(): boolean {
    return this.subscriptions.isNewlyTrialing;
  }

  private get canShowInviteTeam(): boolean {
    return this.team.canInviteTeamMembers && !this.team.hasMultipleMembers;
  }

  private get canShowWhatsNew(): boolean {
    const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
    const lastShowedAt = Number(this.storage.getSessionItem("whats_new_showed_at")) || undefined;
    const lastHiddenAt = this.visibilityChange.lastHiddenAt;
    const shownSinceLastHidden = !!lastShowedAt && (!lastHiddenAt || lastShowedAt > lastHiddenAt);
    const moreThanThirtyDays = !lastHiddenAt || Date.now() - Number(lastHiddenAt) >= THIRTY_DAYS;

    return !shownSinceLastHidden && moreThanThirtyDays;
  }

  private setComponentsToShow(): Array<TileComponent | undefined> {
    const actions = [TileComponent.VIDEO_ASSISTANT];

    if (this.canShowUpgradePrompt) {
      actions.push(TileComponent.UPGRADE_PLAN);
    }

    if (this.canShowWhatsNew) {
      actions.push(TileComponent.WHATS_NEW);
    }

    // This is a bit confusing:  If a trial is new (< 3 days old) then show it before (in priority)
    // the invite team prompt, otherwise show it after.
    if (this.canShowNewTrialCountdown) {
      actions.push(TileComponent.TRIAL_COUNTDOWN);
    }

    if (this.canShowInviteTeam) {
      actions.push(TileComponent.INVITE_TEAM);
    }

    if (this.canShowTrialCountdown) {
      actions.push(TileComponent.TRIAL_COUNTDOWN);
    }

    return [...new Set(actions)];
  }
}
