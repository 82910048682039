import Model, { attr, hasMany } from "@ember-data/model";
import { cached } from "@glimmer/tracking";
import type { ZymbolGroupLayer } from "renderer-engine";
import type { ManyArray } from "client/models/ember-data-types";
import type ZymbolGroup from "client/models/zymbol-group";

export default class Layer extends Model {
  @hasMany("zymbol-group", { async: true, inverse: "layer", as: "layer" })
  declare zymbolGroups: ManyArray<ZymbolGroup>;

  @attr("string")
  name!: ZymbolGroupLayer;

  get duration(): number {
    return isFinite(this.maxDuration) ? this.maxDuration : 0;
  }

  @cached
  get maxDuration(): number {
    return Math.max(...this.zymbolGroupEndTimes);
  }

  @cached
  get zymbolGroupEndTimes(): number[] {
    return (
      // @ts-expect-error
      this.hasMany("zymbolGroups")
        .value()
        ?.map((zg) => zg.endTime) ?? []
    );
  }

  get hasZymbolGroups(): boolean {
    // @ts-expect-error
    return !!this.hasMany("zymbolGroups").value()?.length;
  }

  get firstZymbolGroup(): ZymbolGroup | undefined {
    // @ts-expect-error
    const zymbolGroups = this.hasMany("zymbolGroups").value();
    if (!zymbolGroups?.length) {
      return undefined;
    }
    // @ts-expect-error
    const [firstZymbolGroup] = zymbolGroups.sort((a, b) => a.startTime - b.startTime);
    return firstZymbolGroup;
  }

  async removeZymbolGroup(zymbolGroup: ZymbolGroup): Promise<void> {
    const zymbolGroups = await this.zymbolGroups;
    // @ts-expect-error
    const index = zymbolGroups.findIndex((zg) => zg === zymbolGroup);
    // @ts-expect-error
    zymbolGroups.splice(index, 1);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    layer: Layer;
  }
}
