import { action } from "@ember/object";
import { service } from "@ember/service";
import type { NamedArgs, PositionalArgs } from "ember-modifier";
import Modifier from "ember-modifier";
import TrackingEvents from "client/events";
import type TrackingService from "client/services/tracking";

interface TrackSignature {
  Args: {
    Named: {};
    Positional: [string, string];
  };
}

export default class TrackModifier extends Modifier<TrackSignature> {
  @service
  declare tracking: TrackingService;

  element!: Element;
  eventName?: string;
  ctaContext?: string;

  private bound = false;

  modify(
    element: Element,
    positionalArgs: PositionalArgs<TrackSignature>,
    _namedArgs: NamedArgs<TrackSignature>
  ): void {
    this.removeEventListener();
    this.element = element;
    this.addEventListener();
    this.eventName = positionalArgs[0];
    this.ctaContext = positionalArgs[1] ?? "editor";
  }

  @action
  private track(): void {
    if (!this.eventName) {
      return;
    }

    const event = TrackingEvents[this.eventName as keyof typeof TrackingEvents];

    if (!event) {
      return;
    }

    void this.tracking.sendAnalytics(event, {
      ctaContext: this.ctaContext
    });
  }

  private addEventListener(): void {
    this.element.addEventListener("mousedown", this.track);
    this.bound = true;
  }

  private removeEventListener(): void {
    if (this.bound && this.element) {
      this.element.removeEventListener("mousedown", this.track);
      this.bound = false;
    }
  }
}
