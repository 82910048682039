import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ContentStack from "client/models/content-stack";
import type ContentTemplate from "client/models/content-template";
import type SelectableAsset from "client/models/selectable-asset";
import type PermissionsService from "client/services/permissions";

interface Args {
  collectionName: string;
  stack: ContentStack;
  selectCollection(): unknown;
  selectAsset(asset: SelectableAsset): void;
}

export default class GraphicsStackPanel extends Component<Args & ProjectContentBarPanelArgs> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/graphics/stack-panel");

  @service
  declare store: Store;

  @service
  declare permissions: PermissionsService;

  get params(): Record<string, string | number | boolean | undefined> {
    return {
      /* eslint-disable camelcase */
      stack_id: this.args.stack.id,
      per_page: 24,
      include_unpublished: this.permissions.has("publish_template_content")
      /* eslint-enable camelcase */
    };
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<ContentTemplate>> {
    // @ts-expect-error
    return (await this.store.query("contentTemplate", params)) as ContentTemplate[];
  }
}
