import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type AccountSubscriptionRoute from "../../route";
import type InvoiceController from "./controller";
import type Charge from "client/models/charge";
import type User from "client/models/user";

export interface InvoiceModel {
  charge: Charge;
  user: User;
}

export interface InvoiceParams {
  chargeId: string;
}

export default class InvoiceRoute extends Route<InvoiceModel> {
  @service
  declare store: Store;

  async model({ chargeId }: InvoiceParams): Promise<InvoiceModel> {
    const charge = await this.store.findRecord("charge", chargeId);
    const { user } = this.modelFor<AccountSubscriptionRoute>("authenticated.account.subscription");

    return {
      charge,
      user
    };
  }

  setupController(controller: InvoiceController, model: InvoiceModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    controller.invoice.email ||= model.user.email;
  }
}
