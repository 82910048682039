import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import Folder from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import ProjectPreview from "client/models/project-preview";
import UserAsset from "client/models/user-asset";
import type AuthService from "client/services/auth";
import type { FolderRouteSuffix } from "client/services/folders";

interface FolderContentListArgs {
  contents: Array<FolderContent>;
  folder?: Folder;
  title?: string;
  perPage: number;
  page: number;
  parentRouteName: string;
  childRouteName: FolderRouteSuffix;
  total: number;
  filterDetail?: string;
  order: string;
  onChangeOrder: (order: string) => unknown;
  showSharedProjects?: boolean;
  search: (query: string) => unknown;
  query?: string;
}

export default class FolderContentListComponent extends Component<FolderContentListArgs> {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("folder-content/list");

  get routeName(): string {
    return this.args.parentRouteName + "." + this.args.childRouteName;
  }

  get showSortOrder(): boolean {
    return !!this.args.onChangeOrder;
  }

  get hasTeam(): boolean {
    return this.auth.hasTeam;
  }

  get offset(): number {
    const { perPage, page, contents } = this.args;
    return perPage * (page - 1) + (contents.length > 0 ? 1 : 0);
  }

  get end(): number {
    const { contents } = this.args;
    return this.offset + Math.max(Number(contents.length) - 1, 0);
  }

  get folders(): Array<FolderContent> {
    return this.args.contents.filter((item) => item instanceof Folder);
  }

  get projects(): Array<FolderContent> {
    return this.args.contents.filter((item) => item instanceof ProjectPreview);
  }

  get userAssets(): Array<FolderContent> {
    return this.args.contents.filter((item) => item instanceof UserAsset);
  }
}
