import { FontStyles, getVariantsForFont } from "renderer-engine";
import config from "client/config/environment";
import type { FontVariant } from "client/lib/editor-domain-model";

export const DEFAULT_FONT_WEIGHTS = ["400", "300", "500"];

export const LINE_HEIGHTS = [0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 3, 4, 5, 6];
export const FONT_SIZES = [6, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 30, 36, 72];

const FONT_WEIGHT_MAPPING: { [key: string]: string } = {
  100: "Thin",
  "100normal": "Thin",
  200: "Extra Light",
  "200normal": "Extra Light",
  300: "Light",
  "300normal": "Light",
  400: "Normal",
  "400normal": "Normal",
  500: "Medium",
  "500normal": "Medium",
  600: "Semi Bold",
  "600normal": "Semi Bold",
  700: "Bold",
  "700normal": "Bold",
  800: "Extra Bold",
  "800normal": "Extra Bold",
  900: "Black",
  "900normal": "Black",
  "100italic": "Thin Italic",
  "200italic": "Extra Light Italic",
  "300italic": "Light Italic",
  "400italic": "Italic",
  "500italic": "Medium Italic",
  "600italic": "Semi Bold Italic",
  "700italic": "Bold Italic",
  "800italic": "Extra Bold Italic",
  "900italic": "Black Italic",
  italic: "Italic"
};

export const stripQuotes = (fontName: string): string => fontName.replace(/"/g, "");

export const numbersToDescriptions = (variants: string[]): string[] =>
  variants.map((variant) => numberToDescription(variant));
export const numberToDescription = (variant: string): string => FONT_WEIGHT_MAPPING[variant] ?? variant;

export const descriptionToNumber = (description: string): string =>
  Object.keys(FONT_WEIGHT_MAPPING).find((key) => FONT_WEIGHT_MAPPING[key] === description) ?? description;

export const getWeightAndStyle = (description: string): [string, string] => {
  const fontVariant = descriptionToNumber(description);
  let weight = fontVariant;
  let style = "normal";

  if (!weight) {
    return [weight, style];
  }

  if (weight.includes("italic")) {
    weight = fontVariant.replace("italic", "");
    style = "italic";
  }

  return [weight, style];
};

export const getWeightAndStyleAsVariant = (description: string): FontVariant => {
  const [weight, style] = getWeightAndStyle(description);
  return { weight, style };
};

export const getDefaultFontWeight = (fontName: string): string =>
  DEFAULT_FONT_WEIGHTS.find((weight) => getVariantsForFont(stripQuotes(fontName)).includes(weight)) ??
  DEFAULT_FONT_WEIGHTS[0]!;

export const stringifyVariant = ({ style, weight }: FontVariant): string => {
  if (style === FontStyles.NORMAL) {
    return weight;
  } else if (weight === "400") {
    return style;
  } else {
    return `${weight}${style}`;
  }
};

export const isFontVariantAvailable = (fontName: string, variant: FontVariant): boolean => {
  const variants = getVariantsForFont(stripQuotes(fontName));
  return variants.includes(stringifyVariant(variant));
};

export const getDefaultFontVariant = (fontName: string): FontVariant => {
  return {
    weight: getDefaultFontWeight(fontName),
    style: FontStyles.NORMAL
  };
};

export const getFontFamilyPreviewUrl = (family: string, isCustom = false): string => {
  const basePath = `${config.rootURL}assets/images/font-previews/`;
  const fileName = `${family.replace(/\s/g, "")}.png`;

  if (isCustom) {
    return `${basePath}custom/${fileName}`;
  }

  return `${basePath}${fileName}`;
};
