import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type FloatingPanelsService from "client/services/floating-panels";

export default class FloatingPanelsComponent extends Component {
  styleNamespace = getStyleNamespace("floating-panels/panels");

  @service
  private declare floatingPanels: FloatingPanelsService;

  get hidden(): boolean {
    return !this.floatingPanels.visible;
  }
}
