import { Crisp } from "crisp-sdk-web";
import config from "client/config/environment";
import { isTestEnv } from "client/lib/env";

class AnalyticsService {
  private _disabled = false;

  public get disabled(): boolean {
    return this._disabled || typeof analytics === "undefined" || isTestEnv();
  }

  public disable(): void {
    this._disabled = true;
  }

  public enable(): void {
    this._disabled = false;
  }

  initialize(): void {
    if (this.disabled) {
      return;
    }

    try {
      this.load(config.segmentApiKey, {});
      analytics.page({}, {});
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Crisp.configure(config.crispWebsiteId);
      Crisp.chat.hide();
      Crisp.chat.onChatClosed(() => {
        Crisp.chat.hide();
      });
      if ($crisp) {
        $crisp.push(["safe", true]);
      }
    } catch {
      // eslint-disable-next-line no-empty
    }
  }

  page(properties?: object, options?: SegmentAnalytics.SegmentOpts): void;
  page(name?: string, properties?: object, options?: SegmentAnalytics.SegmentOpts): void;
  page(
    name?: string | object,
    properties?: object | SegmentAnalytics.SegmentOpts,
    options?: SegmentAnalytics.SegmentOpts
  ): void {
    if (this.disabled) {
      return;
    }

    if (typeof name !== "string") {
      options = properties;
      properties = name;
      name = undefined;
    }

    analytics.page(name, properties, options);
  }

  identify(userId: string, traits?: object, options?: SegmentAnalytics.SegmentOpts): void {
    if (this.disabled) {
      return;
    }

    analytics.identify(userId, traits, options);
  }

  track(event: string, properties?: object, options?: SegmentAnalytics.SegmentOpts): void {
    if (this.disabled) {
      return;
    }

    analytics.track(event, properties, options);
  }

  load(writeKey: string, options?: SegmentAnalytics.SegmentOpts): void {
    if (this.disabled) {
      return;
    }

    analytics.load(writeKey, options);
  }
}

export default new AnalyticsService();
