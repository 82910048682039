import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectTemplate from "client/models/project-template";
import type ProjectTemplateSettingsModalService from "client/services/project-template-settings-modal";
import type TrackingService from "client/services/tracking";

interface TemplatePreviewArgs {
  projectTemplate: ProjectTemplate;
  size?: string;
  eventProperties?: Record<string, string>;
  onSelect?(): unknown;
}

export default class TemplatePreviewComponent extends Component<TemplatePreviewArgs> {
  @service
  declare router: RouterService;

  @service
  declare projectTemplateSettingsModal: ProjectTemplateSettingsModalService;

  @service
  declare tracking: TrackingService;

  @tracked
  previewing = false;

  styleNamespace = getStyleNamespace("discovery/template-preview");

  @action
  openPreview(): void {
    this.args.onSelect?.();
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEMPLATE_PREVIEW_OPEN, this.eventProperties);
    this.previewing = true;
  }

  @action
  closePreview(): void {
    this.previewing = false;
  }

  @action
  async editProject(): Promise<void> {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_SELECT_TEMPLATE, this.eventProperties);
    this.previewing = false;

    await this.router.transitionTo("authenticated.template.copy", this.args.projectTemplate.projectId);
  }

  private get eventProperties(): object {
    return {
      ...this.args.projectTemplate.eventProperties,
      ...this.args.eventProperties
    };
  }

  get showActions(): boolean {
    return this.args.size !== "small";
  }

  get projectTemplate(): ProjectTemplate {
    return this.args.projectTemplate;
  }

  get title(): string {
    return this.projectTemplate.title;
  }

  get brandable(): boolean {
    return this.projectTemplate.isBrandable;
  }

  @action
  async openTemplateSettings(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    await this.projectTemplateSettingsModal.open(this.args.projectTemplate.projectId);
  }
}
