import Route from "@ember/routing/route";
import type ScenesLibraryController from "../../controller";
import { VideosCategories } from "client/lib/data/library-categories";
import type LibraryCategory from "client/models/library-category";

export default class ProjectScenesLibraryVideosIndexRoute extends Route {
  beforeModel(): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor<ScenesLibraryController>("authenticated.project.scenes.library").set("query", "");
  }

  model(): {
    categories: LibraryCategory[];
  } {
    return {
      categories: VideosCategories
    };
  }
}
