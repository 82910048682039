import { clamp, toFixed } from "client/lib/quick-math";

interface VideoTrimCalculationArgs {
  startTime: number;
  duration: number;
  maxEndTime: number;
}

export class VideoTrimCalculation {
  public startTime: number;
  public duration: number;
  public maxEndTime: number;

  private MIN_DURATION = 0.5;

  constructor({ startTime, duration, maxEndTime }: VideoTrimCalculationArgs) {
    this.startTime = startTime;
    this.duration = duration;
    this.maxEndTime = maxEndTime;
  }

  public applyStartTimeDelta(timeDelta: number): void {
    const newStartTime = this.startTime + timeDelta;
    const minStartTime = 0;
    const maxStartTime = this.startTime + this.duration - this.MIN_DURATION;

    const newDuration = this.duration - timeDelta;
    const minDuration = this.MIN_DURATION;
    const maxDuration = this.startTime + this.duration;

    this.startTime = toFixed(clamp(newStartTime, minStartTime, maxStartTime));
    this.duration = toFixed(clamp(newDuration, minDuration, maxDuration));
  }

  public applyDurationDelta(timeDelta: number): void {
    const newDuration = this.duration + timeDelta;
    const minDuration = this.MIN_DURATION;
    const maxDuration = this.maxEndTime - this.startTime;

    this.duration = toFixed(clamp(newDuration, minDuration, maxDuration));
  }

  public applyMoveDelta(timeDelta: number): void {
    const newStartTime = this.startTime + timeDelta >= 0 ? this.startTime + timeDelta : 0;
    const minStartTime = 0;
    const maxStartTime = this.maxEndTime - this.duration;

    const newDuration = this.duration;
    const minDuration = this.MIN_DURATION;
    const maxDuration = this.maxEndTime - this.startTime;

    this.startTime = toFixed(clamp(newStartTime, minStartTime, maxStartTime));
    this.duration = toFixed(clamp(newDuration, minDuration, maxDuration));
  }
}
