import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  onClick(): unknown;
}

export default class AddBlankScene extends Component<Args> {
  styleNamespace = getStyleNamespace("project-content-bar/panels/scenes/add-blank");
}
