import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type { StyleOption } from "client/authenticated/survey/data";
import { styleOptions, AvaStyles } from "client/authenticated/survey/data";
import type { VideoAssistantModel } from "client/authenticated/survey/video-assistant/route";
import removeObjectDuplicates from "client/lib/remove-object-duplicates";
import type AspectRatio from "client/models/aspect-ratio";
import { AspectRatios } from "client/models/aspect-ratio";
import type ProjectTemplate from "client/models/project-template";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type NotificationsService from "client/services/notifications";
import type VideoAssistantService from "client/services/video-assistant";

export interface VideoAssistantStyleModel {
  styles: Style[];
}

export interface Style {
  name: AvaStyles;
  aspectRatio: AspectRatio;
  styleOptions?: StyleOption;
  generatorId: string;
}

export default class VideoAssistantStyleRoute extends Route {
  STYLES_ORDER = [AvaStyles.Modern, AvaStyles.Corporate, AvaStyles.Silk, AvaStyles.Basic];

  @service
  declare router: RouterService;

  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare notifications: NotificationsService;

  async beforeModel(transition: Transition): Promise<void> {
    const { referer, generatorId } = transition.to.queryParams;
    const { job, useCase, fields } = this.videoAssistant;

    if (!job || !fields) {
      await this.transitionBack("authenticated.survey.video-assistant.description", referer, generatorId);
      return;
    } else if (!useCase) {
      await this.transitionBack("authenticated.survey.video-assistant.use-case", referer, generatorId);
      return;
    }

    if (generatorId) {
      const model = this.modelFor("authenticated.survey.video-assistant") as VideoAssistantModel;
      const preselectedGenerator = model.preselectedGenerator;

      const videoAssistantGeneratorFields = await preselectedGenerator?.videoAssistantGeneratorFields;
      const fieldsFilled = videoAssistantGeneratorFields?.every((field) => fields[field.key]?.length ?? 0 > 0);

      if (preselectedGenerator?.job !== job || !fieldsFilled) {
        await this.transitionBack("authenticated.survey.video-assistant.description", referer, generatorId);
        return;
      } else if (preselectedGenerator?.useCase !== useCase) {
        await this.transitionBack("authenticated.survey.video-assistant.use-case", referer, generatorId);
        return;
      }
    }
  }

  async model({ generatorId }: { generatorId: string }): Promise<VideoAssistantStyleModel> {
    const model = this.modelFor("authenticated.survey.video-assistant") as VideoAssistantModel;

    const generators = await this.findGenerators(model, generatorId);
    const styles = await this.generateStyles(generators);

    if (styles.length === 0) {
      this.notifications.error("Something went wrong, please contact support.");
    }

    this.setInitialValues(styles, generatorId);

    return { styles };
  }

  private async findGenerators(
    { videoAssistantGenerators, preselectedGenerator }: VideoAssistantModel,
    generatorId: string
  ): Promise<Array<VideoAssistantGenerator>> {
    const generators = [
      ...videoAssistantGenerators.filter(
        ({ job, useCase }) => useCase === this.videoAssistant.useCase && job === this.videoAssistant.job
      )
    ];

    if (generatorId && !!preselectedGenerator) {
      generators.push(preselectedGenerator);
    }

    return removeObjectDuplicates(generators, "job") as VideoAssistantGenerator[];
  }

  private async generateStyles(generators: Array<VideoAssistantGenerator>): Promise<Style[]> {
    return (await Promise.all(generators.map(async (generator) => await this.mapStyles(generator)))).flat().slice();
  }

  private async mapStyles(generator: VideoAssistantGenerator): Promise<Style[]> {
    const templates = await generator.templates;

    return Promise.all(templates.map(async (template: ProjectTemplate) => this.createStyle(template, generator))).then(
      (createdStyles: any[]) => {
        return createdStyles.sort((a: Style, b: Style) => {
          const getIndex = (index: number): number => {
            return index === -1 ? this.STYLES_ORDER.length : index;
          };

          const indexA = getIndex(this.STYLES_ORDER.indexOf(a.name));
          const indexB = getIndex(this.STYLES_ORDER.indexOf(b.name));

          return indexA - indexB;
        });
      }
    );
  }

  private async createStyle(template: ProjectTemplate, generator: VideoAssistantGenerator): Promise<Style> {
    const project = await template.project;

    return {
      name: project.style as AvaStyles,
      aspectRatio: await template.aspectRatio,
      styleOptions: styleOptions(project.style),
      generatorId: generator.id
    };
  }

  private async transitionBack(
    routeName: string,
    referer: string | undefined,
    generatorId: string | undefined
  ): Promise<void> {
    await this.router.transitionTo(routeName, {
      queryParams: { referer: referer, generatorId: generatorId }
    });
  }

  private setInitialValues(styles: Style[], generatorId: string | undefined): void {
    if (generatorId) {
      const preselectedStyle = styles.find((style) => style.generatorId === generatorId);

      this.videoAssistant.aspectRatioId = preselectedStyle?.aspectRatio.id;
      this.videoAssistant.style = preselectedStyle?.name;
    } else if (!this.videoAssistant.aspectRatioId) {
      const defaultAspectRatio = styles.find(
        (style) => style.aspectRatio.name === AspectRatios.WIDESCREEN
      )?.aspectRatio;

      this.videoAssistant.aspectRatioId = defaultAspectRatio?.id || styles[0]!.aspectRatio.id;
      this.videoAssistant.style = undefined;
    }
  }
}
