import Model, { attr, hasMany, belongsTo } from "@ember-data/model";
import type ContentCollection from "./content-collection";
import type ContentTemplate from "./content-template";
import type { ManyArray } from "./ember-data-types";

export default class ContentStack extends Model {
  @hasMany("content-template", { async: true, inverse: "contentStack" })
  declare contentTemplates: ManyArray<ContentTemplate>;

  @belongsTo("content-collection", { async: true, inverse: "contentStacks" })
  declare contentCollection: ContentCollection;

  @attr("string")
  declare name: string;

  @attr("number")
  declare displayOrder: number;

  @attr("number")
  declare publishedTemplateCount: number;

  @attr("boolean")
  declare published: boolean;

  @attr("string")
  declare posterUrl: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    contentStack: ContentStack;
  }
}
