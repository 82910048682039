import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { leaveToPreviousClient } from "client/routes/leave-to-previous-client";

export default class SmartRoute extends Route {
  beforeModel(transition: Transition): void {
    const { projectId } = this.paramsFor("authenticated.project") as { projectId: string };
    const activeScene = transition.to.queryParams["activeScene"];

    if (activeScene) {
      leaveToPreviousClient(`project/${projectId}/smart?activeScene=${activeScene}`);
      return;
    }
    leaveToPreviousClient(`project/${projectId}/smart`);
  }
}
