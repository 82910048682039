import { datadogRum } from "@datadog/browser-rum";
import config from "client/config/environment";
import { isProdEnv } from "client/lib/env";

const initialize = (): void => {
  datadogRum.init({
    applicationId: "c2024de0-521d-4af0-9e7b-3c69d581243c",
    clientToken: "pub8c5687151fd12b176bfed7a05e1c017d",
    site: "datadoghq.com",
    service: "biteable-client",
    env: config.environment,
    version: config["APP_VERSION"],
    sessionSampleRate: isProdEnv() ? 75 : 100,
    trackUserInteractions: true,
    allowedTracingUrls: [config.apiUrl]
  });
};

export default {
  name: "datadog",
  initialize
};
