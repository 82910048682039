import { tracked } from "@glimmer/tracking";

export interface FontVariant {
  weight: string;
  style: string;
}

export interface FontInfo {
  name: string;
  variants: FontVariant[];
  subsets: string[];
}

export class FontFamily {
  @tracked
  _name: string;

  @tracked
  _variants: FontVariant[];

  @tracked
  _subsets: string[];

  constructor(public readonly id: string, name: string, variants: FontVariant[], subsets: string[]) {
    this._name = name;
    this._variants = variants;
    this._subsets = subsets;
  }

  get name(): string {
    return this._name;
  }

  get variants(): FontVariant[] {
    return this._variants;
  }

  get subsets(): string[] {
    return this._subsets;
  }
}
