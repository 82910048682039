import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

enum LibraryType {
  personal = "personal",
  team = "team"
}

enum Size {
  medium = "medium",
  large = "large"
}

interface EmptyLibraryComponentArgs {
  onUpload: () => Promise<void>;
  descriptionText: string;
  titleText: string;
  disableUpload?: boolean;
  type: LibraryType;
  size: Size;
}

export default class EmptyLibraryComponent extends Component<EmptyLibraryComponentArgs> {
  styleNamespace = getStyleNamespace("asset-management/empty-library");
}
