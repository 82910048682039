import Inflector from "ember-inflector";

export function initialize(): void {
  const inflector = Inflector.inflector;

  inflector.uncountable("user-targeting-data");
}

export default {
  name: "custom-inflector-rules",
  initialize
};
