import Transform from "@ember-data/serializer/transform";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

export default class JsonTransform extends Transform {
  serialize(deserialized?: Record<string, any>): object | undefined {
    if (deserialized) {
      return snakecaseKeys(deserialized, { deep: true });
    } else {
      return undefined;
    }
  }

  deserialize(serialized?: object): object | undefined {
    if (serialized) {
      return camelcaseKeys(serialized, { deep: true });
    } else {
      return undefined;
    }
  }
}

declare module "ember-data/types/registries/transform" {
  export default interface TransformRegistry {
    json: JsonTransform;
  }
}
