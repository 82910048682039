import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class ProjectCanvasAction extends Component<{
  action: (e: PointerEvent) => void;
  title: string;
}> {
  styleNamespace = getStyleNamespace("tidal/project-canvas-action");

  get noClick(): boolean {
    return !this.args.action;
  }

  @action
  mouseDown(ev: MouseEvent): void {
    ev.stopPropagation();
  }

  @action
  click(e: PointerEvent): void {
    if (this.args.action) {
      this.args.action(e);
    }
  }
}
