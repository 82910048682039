import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type TemplatesIndexController from "./controller";
import TrackingEvents from "client/events";
import type { ArrayWithMeta } from "client/models/pagination";
import type ProjectTemplate from "client/models/project-template";
import type TrackingService from "client/services/tracking";

interface TemplatesParams {
  search?: string;
  aspectRatio?: string;
  branded?: boolean;
}

export interface TemplatesIndexModel {
  projectTemplates: ArrayWithMeta<ProjectTemplate>;
}

export const loadProjectTemplates = async (
  store: Store,
  {
    page = 1,
    search,
    aspectRatio,
    branded,
    category
  }: {
    page?: number;
  } & TemplateQueryParams
): Promise<Array<ProjectTemplate>> => {
  // @ts-expect-error
  return store.query("projectTemplate", {
    /* eslint-disable camelcase */
    page,
    per_page: 48,
    search,
    aspect_ratio_id: aspectRatio,
    brand: branded ? "branded" : undefined,
    category
    /* eslint-enable camelcase */
  }) as Array<ProjectTemplate>;
};

interface TemplateQueryParams {
  category?: string;
  search?: string;
  aspectRatio?: string;
  branded?: boolean;
}

export default class TemplatesIndexRoute extends Route {
  @service
  declare store: Store;

  @service
  declare tracking: TrackingService;

  protected getTemplateParams(): TemplateQueryParams {
    const { search, aspectRatio, branded } = this.paramsFor("authenticated.templates") as TemplatesParams;

    return {
      search,
      aspectRatio,
      branded
    };
  }

  async model(_params: TemplateQueryParams): Promise<TemplatesIndexModel> {
    const projectTemplates = await loadProjectTemplates(this.store, this.getTemplateParams());

    return {
      projectTemplates
    };
  }

  afterModel({ projectTemplates }: TemplatesIndexModel): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_FILTER_TEMPLATES, {
      templateCount: projectTemplates.meta?.["total-count"]
    });
  }

  setupController(controller: TemplatesIndexController, model: TemplatesIndexModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (model.projectTemplates.meta) {
      const { "total-count": totalCount, "per-page": perPage } = model.projectTemplates.meta;

      Object.assign(controller, {
        ...this.getTemplateParams(),
        totalCount,
        perPage,
        projectTemplates: model.projectTemplates
      });
    }
  }

  resetController(controller: TemplatesIndexController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.page = 1;
      controller.projectTemplates = [];
    }
  }
}
