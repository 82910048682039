import Service, { service } from "@ember/service";
import type AuthService from "./auth";
import TrackingEvents, { Locations } from "client/events";
import type Team from "client/models/team";
import type TeamMembership from "client/models/team-membership";
import { TeamMembershipStatus, TeamMembershipRole } from "client/models/team-membership";
import type TrackingService from "client/services/tracking";

export default class TeamService extends Service {
  @service
  private declare auth: AuthService;

  @service
  private declare tracking: TrackingService;

  get team(): Team | undefined {
    return this.auth.currentTeam;
  }

  get canInviteTeamMembers(): boolean {
    return !!this.team?.canInviteTeamMembers;
  }

  get canPurchaseSeats(): boolean {
    return !!this.team?.canPurchaseSeats;
  }

  async updateTeamMemberStatus(member: TeamMembership, status: TeamMembershipStatus): Promise<boolean> {
    if (member.status === status) {
      return true;
    }

    try {
      member.status = status;
      if (status === TeamMembershipStatus.ACTIVE) {
        member.roles = [TeamMembershipRole.EDITOR];
      }
      await member.save();
    } catch {
      member.rollbackAttributes();
      return false;
    }
    return true;
  }

  async updateTeamMemberRole(member: TeamMembership, role: TeamMembershipRole): Promise<"success" | "failure"> {
    try {
      member.roles = [role];
      await member.save();
      return "success";
    } catch (err) {
      member.rollbackAttributes();
      return "failure";
    }
  }

  trackTeamMemberRoleChange(teamMember: TeamMembership, from: TeamMembershipRole, to: TeamMembershipRole): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_CHANGE_TEAM_ROLE, {
      /* eslint-disable camelcase */
      account_type: this.auth.currentFullSubscription ? "paid" : "free",
      location: Locations.LOCATION_TEAM_SETTINGS,
      team_id: this.team?.id,
      team_size: this.team?.memberCount,
      changed_count: 1,
      members_changed: [teamMember.email],
      user_ids_changed: [teamMember.userId],
      role_changed_from: from,
      role_changed_to: to
      /* eslint-enable camelcase */
    });
  }

  trackTeamMemberStatusChange(teamMember: TeamMembership): void {
    const event = teamMember.isActive
      ? TrackingEvents.EVENT_REACTIVATE_TEAM_ACCESS
      : TrackingEvents.EVENT_REMOVE_TEAM_ACCESS;

    void this.tracking.sendAnalytics(event, {
      /* eslint-disable camelcase */
      account_type: this.auth.currentFullSubscription ? "paid" : "free",
      location: Locations.LOCATION_TEAM_SETTINGS,
      team_id: this.team?.id,
      team_size: this.team?.memberCount,
      changed_count: 1,
      members_changed: [teamMember.email],
      user_ids_changed: [teamMember.userId]
      /* eslint-enable camelcase */
    });
  }

  get hasMultipleMembers(): boolean {
    return this.team?.hasMultipleMembers ?? false;
  }
}

declare module "@ember/service" {
  interface Registry {
    team: TeamService;
  }
}
