import { getOwner } from "@ember/application";
import { attr, hasMany } from "@ember-data/model";
import type { Asset, SagConfig, SagAssetConfig } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import type ContentTemplate from "./content-template";
import type { ManyArray } from "./ember-data-types";
import type { FavoriteMetadata } from "./favorable";
import { favorable, FAVORABLE_TYPES } from "./favorable";
import SelectableMediaAsset from "./selectable-media-asset";
import cmsUrl from "client/lib/cms-url";

@favorable
export default class Sag extends SelectableMediaAsset {
  @hasMany("content-template", { async: false, inverse: "sag" })
  declare contentTemplates: ManyArray<ContentTemplate>;

  @attr("json")
  cfg!: SagAssetConfig;

  @attr("string")
  name!: string;

  @attr("date")
  updatedAt!: Date;

  @attr("string")
  declare thumbVideoUrl: string;

  @attr("string")
  declare thumbImageUrl: string;

  get previewImageUrl(): string {
    return `${this.cfg.previewImageUrl}?lastmod=${this.updatedAt.getTime()}`;
  }

  get previewVideoUrl(): string {
    return `${this.cfg.previewVideoUrl}?lastmod=${this.updatedAt.getTime()}`;
  }

  get cmsUrl(): string {
    return cmsUrl(getOwner(this), `/sag/${this.id}`);
  }

  readonly zymbolCategory = ZymbolCategory.SAG;
  protected _thumbAspectRatio = "1:1";

  get isBrandableTemplate(): boolean {
    return !!this.cfg.animationColorBrandKeys;
  }

  asZymbolConfig(): Partial<SagConfig> & { asset: Asset } {
    return {
      ...this.cfg,
      asset: {
        id: this.id,
        type: "sag"
      },
      startTime: 0
    };
  }

  get trackUsageData(): object {
    return {
      elementId: this.id,
      type: "sag",
      name: this.name
    };
  }

  readonly trackUsageEvent = undefined;

  readonly favorableType = FAVORABLE_TYPES.SAGS;

  get favorableProviderAssetId(): string {
    return this.id;
  }

  metadataForFavorite: FavoriteMetadata = {};
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    sag: Sag;
  }
}
