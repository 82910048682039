import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

type Enqueued<T> = Promise<T>;

export default class PendingService extends Service {
  @tracked
  isLoading = false;

  queue = Promise.resolve();

  async push<T>(promise: Enqueued<T>): Enqueued<T> {
    this.queue = this.queue.then(async () => {
      this.isLoading = true;

      try {
        await promise;
      } finally {
        this.isLoading = false;
      }
    });

    return promise;
  }
}
