import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { NotFoundError } from "@ember-data/adapter/error";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import delay from "client/lib/delay";
import type JsonApiError from "client/models/json-api-error";
import type UserDestroy from "client/models/user-destroy";
import type AuthService from "client/services/auth";

export default class DeleteAccountController extends Controller {
  queryParams = ["token"];

  @service
  declare auth: AuthService;

  @service
  declare store: Store;

  token = undefined;

  @tracked
  userDestroy?: UserDestroy;

  @tracked
  userDestroyed = false;

  @tracked
  errors?: Array<string> = undefined;

  @tracked
  loading = false;

  get multipleErrors(): boolean {
    if (this.errors?.length) {
      return this.errors.length > 1;
    }

    return false;
  }

  get accountName(): string | undefined {
    return this.userDestroy?.fullName;
  }

  @action
  async didInsert(): Promise<void> {
    this.loading = true;

    try {
      this.userDestroy = await this.store.findRecord("userDestroy", this.token!);
    } catch (err) {
      if (err instanceof NotFoundError) {
        this.errors = ["Your token is incorrect or has expired."];
      } else {
        this.errors = [(err as JsonApiError).errors?.[0]?.detail].filter((v): v is string => !!v);
      }
    } finally {
      this.loading = false;
    }
  }

  @action
  async deleteAccount(): Promise<void> {
    this.loading = true;

    try {
      await this.userDestroy!.destroyRecord();
      this.userDestroyed = true;
      this.loading = false;

      if (this.auth.isAuthenticated) {
        await this.stayOnDeleteConfirmation();
        void this.auth.logout();
      }
    } catch (err) {
      this.errors = [(err as JsonApiError).errors?.[0]?.detail].filter((v): v is string => !!v);
    } finally {
      this.loading = false;
    }
  }

  async stayOnDeleteConfirmation(): Promise<void> {
    return delay(3000);
  }
}
