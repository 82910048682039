export default function formatTime(time: number, precision = 0, padding = 1, separator = "."): string {
  if (!time || isNaN(time)) {
    return "0:00";
  }

  const minutes = String(Math.floor(time / 60)).padStart(padding, "0");
  const seconds = (time % 60)
    .toFixed(precision)
    .padStart(2 + (precision > 0 ? precision + 1 : 0), "0")
    .replace(".", separator);
  return `${minutes}:${seconds}`;
}
