import { tracked } from "@glimmer/tracking";
import type { TaskGenerator } from "ember-concurrency";
import { task } from "ember-concurrency";
import { perform } from "ember-concurrency-ts";
import type { Caption, Scene, TimelineFacade } from "client/lib/editor-domain-model";

export default class SceneThumbnail {
  @tracked
  public isGenerating?: boolean;

  @tracked
  public imageSrc?: string;

  constructor(private scene: Scene) {}

  generateThumbnail(facade: TimelineFacade, caption?: Caption): void {
    this.isGenerating = true;
    void perform(this.generateThumbnailTask, facade, caption);
  }

  @task({ restartable: true })
  private *generateThumbnailTask(facade: TimelineFacade, caption?: Caption): TaskGenerator<void> {
    let thumbnail: HTMLImageElement;
    if (caption) {
      thumbnail = yield facade.getCaptionThumbnail(caption);
    } else {
      thumbnail = yield facade.getThumbnail(this.scene);
    }

    this.isGenerating = false;

    this.imageSrc = thumbnail.src;
  }
}
