import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  label: string;
  route: string;
}

export default class AppNavLinkComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("app-nav/link");
}
