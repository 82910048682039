import Route from "@ember/routing/route";
import type ScenesLibraryController from "../controller";
import { SceneLibraryTypes } from "../controller";

export default class ProjectScenesLibraryPexelsRoute extends Route {
  model(): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor<ScenesLibraryController>("authenticated.project.scenes.library").set(
      "library",
      SceneLibraryTypes.PEXELS
    );
  }
}
