import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface UIFieldGroupArgs {
  columns?: number;
}

export default class UIFieldGroupComponent extends Component<UIFieldGroupArgs> {
  styleNamespace = getStyleNamespace("ui/ui-field-group");

  get columns(): number {
    return this.args.columns ?? 6;
  }
}
