export const isColorDark = (hexColor: string): boolean => {
  const luma = lumaFromHex(hexColor);

  return luma < 0.5;
};

/**
 * @returns {number} A value between (0, 1)
 */
export const lumaFromHex = (hexColor: string): number => {
  // https://en.wikipedia.org/wiki/Luma_(video)
  // https://www.telestream.net/pdfs/whitepapers/wp-Different-Color-Spaces-QC.pdf
  const [r, g, b] = hexToRGB(hexColor);
  const luma = 0.2125 * r + 0.7154 * g + 0.0721 * b;

  return luma / 255;
};

export const filterForLightColors = (hexColors: Array<string>): Array<string> => {
  const lightColors = hexColors.filter((color) => {
    return !isColorDark(color);
  });
  if (lightColors.length > 0) {
    sortLightToDark(lightColors);
  }

  return lightColors;
};

export const filterForDarkColors = (hexColors: Array<string>): Array<string> => {
  const darkColors = hexColors.filter((color) => {
    return isColorDark(color);
  });
  if (darkColors.length > 0) {
    sortDarkToLight(darkColors);
  }

  return darkColors;
};

export const pickRandomDarkColor = (hexColors: Array<string>): string => {
  if (hexColors.length === 0) {
    throw "Expected list of hex colors with length greater than 0";
  }
  const darkColors = hexColors.filter((color) => {
    return isColorDark(color);
  });
  // If dark color found, pick a random one
  if (darkColors.length > 0) {
    return pickRandomColorFromList(darkColors);
  }

  // If none of the colors qualify as dark, return the darkest one
  return findDarkestColor(hexColors);
};

export const pickRandomLightColor = (hexColors: Array<string>): string => {
  if (hexColors.length === 0) {
    throw "Expected list of hex colors with length greater than 0";
  }
  const lightColors = hexColors.filter((color) => {
    return !isColorDark(color);
  });
  // If light color found, pick a random one
  if (lightColors.length > 0) {
    return pickRandomColorFromList(lightColors);
  }

  // If none of the colors qualify as light, return the lightest one
  return findLightestColor(hexColors);
};

export const pickRandomColorFromList = (hexColors: Array<string>): string => {
  if (hexColors.length === 0) {
    throw "Expected list of hex colors with length greater than 0";
  }
  const index = Math.floor(Math.random() * hexColors.length);

  return hexColors[index]!;
};

export const shuffleColors = (hexColors: Array<string>): Array<string> => {
  const newlist: Array<string> = [];
  const oldlist = hexColors.slice();
  while (oldlist.length > 0) {
    const value = oldlist.splice(Math.floor(Math.random() * oldlist.length), 1)[0]!;
    newlist.push(value);
  }

  return newlist;
};

export const sortDarkToLight = (hexColors: Array<string>): void => {
  if (hexColors.length === 0) {
    throw "Expected list of hex colors with length greater than 0";
  }
  // Sorts the array in place
  hexColors.sort((color1, color2) => {
    return lumaFromHex(color1) - lumaFromHex(color2);
  });
};

export const sortLightToDark = (hexColors: Array<string>): void => {
  if (hexColors.length === 0) {
    throw "Expected list of hex colors with length greater than 0";
  }
  // Sorts the array in place
  hexColors.sort((color1, color2) => {
    return lumaFromHex(color2) - lumaFromHex(color1);
  });
};

export const findLightestColors = (hexColors: Array<string>, count: number): Array<string> => {
  const lightToDark = hexColors.slice();
  sortLightToDark(lightToDark);

  return lightToDark.slice(0, count);
};

export const findDarkestColors = (hexColors: Array<string>, count: number): Array<string> => {
  const darkToLight = hexColors.slice();
  sortDarkToLight(darkToLight);

  return darkToLight.slice(0, count);
};

export const findDarkestColor = (hexColors: Array<string>): string => {
  if (hexColors.length === 0) {
    throw "Expected hexColors.length > 0";
  }
  const colors = hexColors.slice();
  sortDarkToLight(colors);

  return colors[0]!;
};

export const findLightestColor = (hexColors: Array<string>): string => {
  if (hexColors.length === 0) {
    throw "Expected hexColors.length > 0";
  }
  const colors = hexColors.slice();
  sortLightToDark(colors);

  return colors[0]!;
};

export const hexToRGB = (hex: string): [number, number, number] => {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length !== 6) {
    throw "Only six-digit hex colors are allowed.";
  }
  const aRgbHex = hex.match(/.{1,2}/g);
  const aRgb: [number, number, number] = [
    parseInt(aRgbHex![0]!, 16),
    parseInt(aRgbHex![1]!, 16),
    parseInt(aRgbHex![2]!, 16)
  ]!;

  return aRgb;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const [r, g, b] = hexToRGB(hex);

  return rgbToHSL(r, g, b);
};

/**
 * @returns {number} h is a value within (0, 360)
 * @returns {number} s is a value within (0, 1)
 * @returns {number} l is a value within (0, 1)
 */
export const rgbToHSL = (r: number, g: number, b: number): { h: number; s: number; l: number } => {
  // https://css-tricks.com/converting-color-spaces-in-javascript/
  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  // Calculate hue
  let h;
  if (delta === 0) {
    // No difference
    h = 0;
  } else if (cmax === r) {
    // Red is max
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    // Green is max
    h = (b - r) / delta + 2;
  } else {
    // Blue is max
    h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if (h < 0) {
    h += 360;
  }

  // Calculate lightness
  const l = (cmax + cmin) / 2;

  // Calculate saturation
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  // s = +(s * 100).toFixed(1);
  // l = +(l * 100).toFixed(1);
  return { h, s, l };
};

export const filterForContrast = (
  baseHex: string,
  hexColors: Array<string>,
  contrast?: WCAGContrast
): Array<string> => {
  return hexColors.filter((hexColor) => sufficientContrast(baseHex, hexColor, contrast));
};

// https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
export const wcagLumaFromHex = (hexColor: string): number => {
  const rgb = hexToRGB(hexColor);

  const [r, g, b] = rgb.map((v) => {
    v /= 255;

    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  }) as [number, number, number];

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luma;
};

// https://stackoverflow.com/questions/13586999/color-difference-similarity-between-two-values-with-js
export const colorDistance = (hexA: string, hexB: string): number => {
  const rgbA = hexToRGB(hexA);
  const rgbB = hexToRGB(hexB);

  const labA = rgbToLab(rgbA);
  const labB = rgbToLab(rgbB);
  const deltaL = labA[0] - labB[0];
  const deltaA = labA[1] - labB[1];
  const deltaB = labA[2] - labB[2];
  const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
  const deltaC = c1 - c2;
  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
  const sc = 1.0 + 0.045 * c1;
  const sh = 1.0 + 0.015 * c1;
  const deltaLKlsl = deltaL / 1.0;
  const deltaCkcsc = deltaC / sc;
  const deltaHkhsh = deltaH / sh;
  const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;

  return i < 0 ? 0 : Math.sqrt(i);
};

export const rgbToLab = ([r, g, b]: [number, number, number]): [number, number, number] => {
  (r = r / 255), (g = g / 255), (b = b / 255);

  let x, y, z;

  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
  return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
};

// https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
export const relativeContrast = (hexA: string, hexB: string): number => {
  const lumaA = wcagLumaFromHex(hexA);
  const lumaB = wcagLumaFromHex(hexB);

  const MOD = 0.05;

  if (lumaA > lumaB) {
    return (lumaB + MOD) / (lumaA + MOD);
  } else {
    return (lumaA + MOD) / (lumaB + MOD);
  }
};

// https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o#compare-with-wcag-requirements
export enum WCAGContrast {
  AAA = 1 / 7,
  AA = 1 / 4.5,
  A = 1 / 3,
  CUSTOM = 1 / 1.5
}

export const sufficientContrast = (hexA: string, hexB: string, contrast = WCAGContrast.A): boolean => {
  const ratio = relativeContrast(hexA, hexB);

  const pass = ratio < contrast;

  return pass;
};
