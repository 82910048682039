import Model, { attr } from "@ember-data/model";

export default class ProjectDigest extends Model {
  @attr("string")
  contentHash!: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectDigest: ProjectDigest;
  }
}
