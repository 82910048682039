import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import TrackingEvents from "client/events";
import type { Timeline, Scene } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type GridService from "client/services/grid";
import type TrackingService from "client/services/tracking";

interface Args {
  timeline: Timeline;
  scene: Scene;
}

export default class AppHeaderProjectToggleGridComponent extends Component<Args> {
  @service
  declare grid: GridService;

  @service
  declare tracking: TrackingService;

  styleNamespace = getStyleNamespace("app-header/project/toggle-grid");

  get tooltipMessage(): string {
    return this.grid.grid?.presenting ? "Toggle grid off" : "Toggle grid on";
  }

  @action
  async toggleGrid(): Promise<void> {
    if (this.grid.grid) {
      this.grid.grid.presenting = !this.grid.grid.presenting;
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_CLICK_GRID_TOGGLE, {
        projectId: this.args.timeline.id,
        sceneId: this.args.scene.id,
        gridState: this.grid.grid.presenting ? "on" : "off",
        editor: "advanced"
      });
    }
  }
}
