import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { BrandIndexModel } from "./route";
import type BrandStyle from "client/models/brand-style";
import type ConfirmService from "client/services/confirm";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type PlansService from "client/services/plans";
import type UpgradeService from "client/services/upgrade";

export default class BrandIndexController extends Controller<BrandIndexModel> {
  @service
  private declare confirm: ConfirmService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  private declare notifications: NotificationsService;

  @service
  private declare router: RouterService;

  @service
  private declare permissions: PermissionsService;

  @service
  private declare plans: PlansService;

  @service
  private declare upgrade: UpgradeService;

  @service
  declare store: Store;

  get featureMultipleBrands(): boolean {
    return this.permissions.has("feature_multiple_brands");
  }

  get addBrandDisabled(): boolean {
    return this.model.brandStyles.length > 0 && !this.featureMultipleBrands;
  }

  @action
  navigateToBrandImport(): void {
    if (!this.addBrandDisabled) {
      void this.router.transitionTo("authenticated.brand.new");
    }
  }

  @action
  editBrand(model: BrandStyle): void {
    void this.router.transitionTo("authenticated.brand.edit", model.id, {
      queryParams: { fetchResult: undefined }
    });
  }

  @action
  upgradeToTopTierPlan(): void {
    void this.upgrade.selectTopTierPlan({ context: "brand index - add brand" });
  }

  @action
  async deleteBrand(brand: BrandStyle): Promise<void> {
    const confirmed = await this.confirm.open({
      title: "Delete this brand",
      message: `Are you sure you want to delete branding for ${brand.style.name}?`,
      confirmLabel: "Delete"
    });

    if (confirmed) {
      try {
        const id = brand.id;
        await brand.destroyRecord();
        this.store
          .peekAll("project")
          .filter((project) => project.brandStyleId === id)
          .forEach((project) => project.reload());
        this.notifications.success("Brand deleted");
      } catch (error) {
        brand.rollbackAttributes();
        // @ts-expect-error
        if (error.errors[0]?.detail) {
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.notifications.error(error.errors[0]?.detail);
        } else {
          this.notifications.error("Brand deleted");
        }
        // @ts-expect-error
        this.honeybadger.notify(error, {
          message: `"Could not delete brand" ${brand.id}`,
          name: "DeleteBrand"
        });
      }
    }
  }

  @action
  async setAsDefault(brand: BrandStyle): Promise<void> {
    if (brand.isDefault) {
      return;
    }

    try {
      await brand.setAsDefault();

      this.notifications.success(`Brand ${brand.style.name ?? brand.id} is now your default, excellent choice!`);
    } catch (error) {
      this.notifications.error(`Sorry but we cannot set brand ${brand.style.name ?? brand.id} as your default`);
      // @ts-expect-error
      this.honeybadger.notify(error, {
        message: "Could not set default brand",
        name: "SetDefaultBrand"
      });
    }
  }

  get topTierPlanName(): string {
    return this.plans.getTopTierPlan()?.name ?? "our best plan";
  }

  get tooltipCTA(): string {
    return `Unlock Multiple Brands with ${this.topTierPlanName}`;
  }
}
