import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ButtonLinkArgs {
  disabled?: boolean;
  hover?: string;
  model?: any;
  models?: any[];
  query?: any;
  route: string;
  size?: string;
  type?: string;
  variant?: string;
}

export default class ButtonLinkComponent extends Component<ButtonLinkArgs> {
  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("north-star/button");

  get variant(): string {
    return this.args.variant ?? "default";
  }

  get size(): string {
    return this.args.size ?? "default";
  }

  get hover(): string {
    return this.args.hover ?? "false";
  }

  @action
  click(): void {
    if (!this.args.disabled) {
      const { route, model, models, query } = this.args;
      if (model) {
        if (query) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          void this.router.transitionTo(route, model, { queryParams: query });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          void this.router.transitionTo(route, model);
        }
      } else if (models) {
        if (query) {
          void this.router.transitionTo(route, models, { queryParams: query });
        } else {
          void this.router.transitionTo(route, models);
        }
      } else {
        if (query) {
          void this.router.transitionTo(route, [], { queryParams: query });
        } else {
          void this.router.transitionTo(route);
        }
      }
    }
  }
}
