import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked, TrackedArray } from "tracked-built-ins";
import type { DeferredPromise } from "client/lib/defer";
import defer from "client/lib/defer";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TagInputArgs {
  messageOnDuplicate?: string;
  messageOnEmpty?: string;
  inline?: boolean;
  inputType?: string;
  placeholder?: string;
  tags: Array<unknown>;
  onKeyUp?: (event: KeyboardEvent) => void;
  onValidityChange?: (validity: boolean) => void;
  validateTag?: (tag: string) => Promise<string[]>;
  validateTags?: (tags: unknown[]) => string[];
  willSubmit?: boolean;
  onSubmitReady?: (ready: boolean) => void;
}

export default class TagInput extends Component<TagInputArgs> {
  styleNamespace = getStyleNamespace("north-star/tag-input");

  declare input: HTMLInputElement;

  @tracked
  inline: boolean;

  @tracked
  markedForDeletion = false;

  @tracked
  tags: Array<unknown>;

  @tracked
  validationMessages: string[] = new TrackedArray([]);

  @tracked
  private tagErrors: Array<string[]> = new TrackedArray([]);

  private tagValidations: Array<DeferredPromise<boolean>> = [];

  constructor(owner: object, args: TagInputArgs) {
    super(owner, args);
    this.tags = args.tags;
    this.inline = args.inline ?? true;
  }

  @action
  setFocused(element: HTMLFieldSetElement): void {
    const elem = element.querySelector("[autofocus]") as HTMLElement;

    if (elem) {
      elem.focus();
    }
  }

  @action
  async beforeSubmit(): Promise<void> {
    if (!this.args.willSubmit) {
      return;
    }

    this.addInputAsTag(true);
    this.validateTags(true);
    if (this.validationMessages.length > 0) {
      this.args.onSubmitReady?.(false);
      return;
    }

    const validations = await Promise.all(this.tagValidations);
    this.args.onSubmitReady?.(validations.every((ready) => ready));
  }

  private addInputAsTag(submitting = false): void {
    const valid = this.input.checkValidity();
    if (!valid) {
      return;
    }

    if (this.inputIsDuplicate()) {
      if (submitting) {
        this.args.onSubmitReady?.(false);
        return;
      }
    } else if (this.input.value && !/^\s+$/.test(this.input.value)) {
      this.tags.push(this.input.value.trim());
      this.input.value = "";
      this.tagErrors.push([]);
      this.tagValidations.push(defer());
    }
  }

  private inputIsDuplicate(): boolean {
    return !!this.args.messageOnDuplicate && this.tags.includes(this.input.value.trim());
  }

  get hasValidTags(): boolean {
    return this.validationMessages.length === 0 && this.tagMessages.length === 0;
  }

  private removeLastTag(): void {
    if (this.markedForDeletion && this.tags.length > 0) {
      this.removeTag(this.tags.length - 1);
    }

    this.markedForDeletion = !this.markedForDeletion;
  }

  @action
  onBlur(): void {
    this.addInputAsTag();
    this.validateTags();
  }

  @action
  onClickContainer(): void {
    this.input.focus();
  }

  @action
  onFocus(): void {
    this.validateTags();
  }

  /**
   * prevent keys
   */
  @action
  onKeyDown(event: KeyboardEvent): boolean {
    let isKeyAllowed = true;
    switch (event.code) {
      case EventKeys.ENTER:
      case EventKeys.SPACE:
        isKeyAllowed = false;
        break;
    }
    return isKeyAllowed;
  }

  /**
   * perform actions
   */
  @action
  onKeyUp(event: KeyboardEvent): void {
    this.args.onKeyUp?.(event);
    switch (event.code) {
      case EventKeys.BACKSPACE:
        if (!this.input.value) {
          this.removeLastTag();
          this.validateTags();
        }
        break;
      case EventKeys.ENTER:
      case EventKeys.SPACE:
        this.addInputAsTag();
        this.validateTags();
        break;
      default:
        this.markedForDeletion = false;
    }
  }

  @action
  removeTag(index: number): void {
    this.tags.splice(index, 1);
    this.tagErrors.splice(index, 1);
    this.tagValidations.splice(index, 1);
    this.validateTags();
    this.input.focus();
  }

  @action
  onValidated(tag: string, valid: boolean, errors: string[]): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      if (!valid) {
        this.tagErrors[index] = errors;
      }
      this.tagValidations[index]?.resolve(valid);
    }
  }

  @action
  onValidityChange(): void {
    this.args.onValidityChange?.(this.hasValidTags);
  }

  @action
  setInputElement(element: HTMLInputElement): void {
    this.input = element;
  }

  get inputType(): string {
    return this.args.inputType ?? "text";
  }

  get tagMessages(): string[] {
    return this.tagErrors.flat().filter((e) => e.trim());
  }

  get allMessages(): string[] {
    return [...new Set([...this.validationMessages, ...this.tagMessages])];
  }

  private validateTags(beforeSubmit = false): void {
    this.validationMessages = new TrackedArray([]);
    // input
    if (beforeSubmit && !!this.args.messageOnEmpty && this.tags.length === 0) {
      this.validationMessages.push(this.args.messageOnEmpty);
    }
    if (this.inputIsDuplicate()) {
      this.validationMessages.push(this.args.messageOnDuplicate!);
    }
    if (!this.input.checkValidity()) {
      this.validationMessages.push(`Invalid ${this.inputType}`);
    }
    // tags
    if (this.args.validateTags) {
      this.validationMessages.push(...this.args.validateTags(this.tags));
    }
  }
}
