import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AspectRatio from "client/models/aspect-ratio";

interface AspectRatioSelectComponentArgs {
  aspectRatio: AspectRatio;
  aspectRatios: AspectRatio[];
  updateAspectRatio: (aspectRatio: AspectRatio) => {};
  labelPosition?: "bottom" | "right";
}

export default class AspectRatioSelectComponent extends Component<AspectRatioSelectComponentArgs> {
  styleNamespace = getStyleNamespace("aspect-ratio-select");

  get labelPosition(): string {
    return this.args.labelPosition ?? "bottom";
  }

  @action
  updateAspectRatio(aspectRatio: AspectRatio): void {
    this.args.updateAspectRatio(aspectRatio);
  }
}
