import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type VoiceoverRoute from "../route";
import type UserAsset from "client/models/user-asset";
import type { AudioRouteModel } from "client/routes/audio";
import type { InfiniteModel } from "client/routes/infinite";
import InfiniteRoute from "client/routes/infinite";

export interface VoiceoverSelectRouteModel extends AudioRouteModel, InfiniteModel<UserAsset> {
  results: UserAsset[];
  replacedClipId: string | undefined;
  addedAt: string | undefined;
}

export default class VoiceoverSelectRoute extends InfiniteRoute<VoiceoverSelectRouteModel> {
  @service
  declare store: Store;

  async model(params: {
    addedAt: string | undefined;
    replacedClipId: string | undefined;
  }): Promise<VoiceoverSelectRouteModel> {
    const baseModel = this.modelFor<VoiceoverRoute>("authenticated.project.scene.voiceover");
    const results = await this.loadResults();
    const model: VoiceoverSelectRouteModel = {
      ...baseModel,
      results,
      ...params
    };
    return model;
  }

  async loadResults(): Promise<Array<UserAsset>> {
    const { per_page, page = 1 } = this.routeController; // eslint-disable-line camelcase
    // @ts-expect-error
    const results = (await this.store.query("userAsset", {
      per_page, // eslint-disable-line camelcase
      page,
      type: "audio"
    })) as UserAsset[];

    // @ts-expect-error
    if (results.meta) {
      // @ts-expect-error
      this.routeController.meta = results.meta;
    }

    return results;
  }
}
