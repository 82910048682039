import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface AppFilterOptionArgs {
  disabled?: boolean;
  models?: {}[];
  route: string;
}

export default class AppFilterOptionComponent extends Component<AppFilterOptionArgs> {
  styleNamespace = getStyleNamespace("app/filter-option");

  get models(): {}[] {
    return this.args.models ?? [];
  }
}
