import Serializer from "@ember-data/serializer";
import type Store from "@ember-data/store";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelSchema } from "ember-data";
import BrandQuery from "client/models/brand-query";

type BrandQueryData = Record<string, any>;

interface BrandQueryJSONAPIResponse {
  id?: string;
  type?: string;
  attributes: BrandQueryData;
}

export default class BrandQuerySerializer extends Serializer {
  normalize(_model: ModelSchema, payload: BrandQueryData): BrandQueryJSONAPIResponse {
    return {
      type: BrandQuery.modelName as string,
      attributes: {
        ...payload
      }
    };
  }

  normalizeResponse(
    _: Store,
    model: ModelSchema,
    payload: BrandQueryData,
    id: string | number,
    requestType: string
  ): {
    data: BrandQueryJSONAPIResponse | {};
  } {
    switch (requestType) {
      case "findRecord": {
        return {
          data: {
            id,
            ...this.normalize(model, payload)
          }
        };
      }
      default: {
        return {
          data: {}
        };
      }
    }
  }
}
