import Route from "@ember/routing/route";
import type ProjectScenesLibraryController from "../../controller";
import categories from "./model";
import type LibraryCategory from "client/models/library-category";

export default class ImagesIndexRoute extends Route {
  beforeModel(): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controllerFor<ProjectScenesLibraryController>("authenticated.project.scenes.library").set("query", "");
  }

  model(): {
    categories: LibraryCategory[];
  } {
    return {
      categories
    };
  }
}
