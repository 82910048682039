import { UserAsset as NemUserAsset } from "@biteable/network-model/dist/src/com/biteable/network/models";
import type MutableArray from "@ember/array/mutable";
import { set } from "@ember/object";
import { service } from "@ember/service";
import Model, { attr, hasMany } from "@ember-data/model";
import type Store from "@ember-data/store";
import camelcaseKeys from "camelcase-keys";
import type BrandStyle from "./brand-style";
import BrandLogo from "client/lib/brand/brand-logo";
import BrandStyleConfig from "client/lib/brand/brand-style-config";
import clone from "client/lib/clone";
import { Frame } from "client/lib/editor-domain-model";
import type { ManyArray } from "client/models/ember-data-types";
import type UserAsset from "client/models/user-asset";
import { UserAssetTypes } from "client/models/user-asset";

interface JsonApiLogo {
  userAssetId: string;
  frame: JsonApiFrame;
  defaultLogo: boolean;
}

interface JsonApiFrame {
  color: string;
  offsetX: number;
  offsetY: number;
  scale: number;
  shape: string;
}

export default class GlobalBrandStyle extends Model {
  @service
  declare store: Store;

  @attr("json")
  declare style: object & { logos?: JsonApiLogo[] };

  // eslint-disable-next-line no-null/no-null
  @hasMany("userAssets", { async: false, inverse: null })
  declare userAssets: ManyArray<UserAsset>;

  toBrandStyle(): BrandStyle {
    const style = clone(camelcaseKeys(this.style));
    const logosJson = style.logos;
    // Prevent BrandStyleConfig.fromJSON from attempting to fetch UserAssets from the API as it does not
    // have permission to. They are returned as included in the GlobalBrandStyle response and can be used
    // to create UserAssets for this BrandStyle
    delete style["logos"];

    const computedStyle = BrandStyleConfig.fromJSON(style);
    if (logosJson) {
      const brandLogos = this.computeBrandLogos(logosJson, this.userAssets);
      computedStyle.logos = brandLogos as BrandLogo[];
    }

    const brandStyle = this.store.createRecord("brandStyle", {});
    set(brandStyle, "style", computedStyle);

    return brandStyle;
  }

  private computeBrandLogos(
    logosJson: JsonApiLogo[],
    userAssets: MutableArray<UserAsset>
  ): Array<BrandLogo | undefined> {
    return logosJson
      .map((logo: JsonApiLogo) => {
        const sourceAsset = userAssets.find((userAsset: UserAsset) => {
          return userAsset.id === logo.userAssetId;
        });

        if (sourceAsset) {
          const jsonApiFrame = logo.frame;
          const frame = new Frame(
            jsonApiFrame.shape,
            jsonApiFrame.color,
            jsonApiFrame.scale,
            jsonApiFrame.offsetX,
            jsonApiFrame.offsetY
          );
          const userAsset = new NemUserAsset({
            name: sourceAsset.name,
            filestackUrl: sourceAsset.filestackUrl,
            mimeType: sourceAsset.mimeType,
            assetType: UserAssetTypes.LOGO,
            hasAudio: false
          });

          return new BrandLogo(userAsset, { frame: frame, defaultLogo: logo.defaultLogo });
        }
        return undefined;
      })
      .filter((brandLogo) => brandLogo);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    globalBrandStyle: GlobalBrandStyle;
  }
}
