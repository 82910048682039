import type { Grid } from "../grid/grid";
import type { Line } from "../grid/line";
import { Lines } from "../grid/lines";
import { SNAP_THRESHOLD } from "../grid/snap-threshold";
import type { Rect } from "../math";
import { numberForComparison } from "../math";
import { Vector2 } from "../math/vector2";

export const getSnapLines = (rects: Rect[], grid: Grid): Line[] => {
  const snapLines: Line[] = [];

  const threshold = grid?.presenting ? SNAP_THRESHOLD * 0.2 : SNAP_THRESHOLD;
  for (const rect of rects) {
    snapLines.push(...Lines.rectLines(rect, threshold));
  }

  return snapLines;
};

/*
  Snapper assumes a normalized unit interval coordinate system (e.g. 1x1).
*/
export class Snapper {
  public static snapToClosestPoint(
    targetPoints: Vector2[],
    snapPoints: Vector2[],
    snapThreshold: number
  ): Vector2 | undefined {
    // Declare closest pair
    let closestPair: { targetPoint: Vector2; snapPoint: Vector2 } | undefined;
    // Iterate over all target points
    for (const targetPoint of targetPoints) {
      // Iterate over all possible snap points for the current target point
      for (const snapPoint of snapPoints) {
        // Get distance between the current snap point and the current target point
        const distance = snapPoint.subtract(targetPoint).length;
        let shortestDistance = Number.MAX_SAFE_INTEGER;
        // Get the current shortest distance if it exists
        if (closestPair) {
          shortestDistance = Snapper.getPairResultantVector(closestPair).length;
        }
        // If distance is smaller than threshold and shortest distance recorded
        if (distance <= snapThreshold && distance < shortestDistance) {
          // Shorter distance found
          closestPair = { targetPoint, snapPoint };
        }
      }
    }

    if (closestPair) {
      return Snapper.getPairResultantVector(closestPair);
    }

    return undefined;
  }

  public static snapToClosestLine(lines: Line[], snapLines: Line[]): Vector2 {
    let horizontalSnap: number | undefined;
    let verticalSnap: number | undefined;

    for (const snapLine of snapLines) {
      for (const line of lines) {
        const snap = snapLine.subtract(line);
        if (snapLine.isHorizontal && line.isHorizontal) {
          verticalSnap = Snapper.closest(snap.y, snapLine, verticalSnap);
        } else if (snapLine.isVertical && line.isVertical) {
          horizontalSnap = Snapper.closest(snap.x, snapLine, horizontalSnap);
        }
      }
    }

    return new Vector2(horizontalSnap || 0, verticalSnap || 0);
  }

  public static closest(snap: number, snapLine: Line, closest?: number): number | undefined {
    const difference = numberForComparison(snap);
    const underThreshold = difference <= snapLine.snapThreshold;
    const closer = difference < numberForComparison(closest || Number.MAX_SAFE_INTEGER);
    if (underThreshold && closer) {
      return snap;
    }

    return closest;
  }

  public static getPairResultantVector(pair: { snapPoint: Vector2; targetPoint: Vector2 }): Vector2 {
    return pair.snapPoint.subtract(pair.targetPoint);
  }
}
