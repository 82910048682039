import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import Moveable, { type RectInfo } from "moveable";
import { ResizeFrom, ScaleFrom } from "renderer-engine";

interface Args {
  target: HTMLElement;
  rotatable: boolean;
  scalable: boolean;
  draggable: boolean;
  resizable: boolean;
  show: boolean;

  onRotate: (rotation: number, moveableRect: RectInfo) => void;
  onRotateStart: () => void;
  onRotateEnd: () => void;
  onDrag?: (clientX: number, clientY: number) => void;
  onDragStart?: (clientX: number, clientY: number) => void;
  onDragEnd?: () => void;
  onResize?: (clientX: number, clientY: number, direction: ResizeFrom | ScaleFrom) => void;
  onResizeStart?: (clientX: number, clientY: number) => void;
  onResizeEnd?: () => void;
  onScale?: (clientX: number, clientY: number, direction: ResizeFrom | ScaleFrom) => void;
  onScaleStart?: (clientX: number, clientY: number) => void;
  onScaleEnd?: () => void;
}

export default class MoveableComponent extends Component<Args> {
  @tracked
  moveable?: Moveable;

  @action
  didInsert(): void {
    this.initializeMoveable();
    this.didUpdateShow();
  }

  @action
  didUpdateShow(): void {
    if (this.args.show) {
      this.showMoveable();
    } else {
      this.hideMoveable();
    }
  }

  private initializeMoveable(): void {
    try {
      if (this.args.target && this.args.target.parentElement) {
        this.moveable = new Moveable(this.args.target.parentElement, {
          // General options
          target: this.args.target,
          origin: false,
          keepRatio: true,
          renderDirections: this.renderDirections(this.args.resizable, this.args.scalable),
          // For rotation
          rotatable: this.args.rotatable,
          rotationPosition: "bottom",
          // For dragging
          draggable: this.args.draggable,
          // For resizing and scaling
          useResizeObserver: this.args.resizable,
          resizable: this.args.resizable
            ? {
                keepRatio: false
              }
            : false
        });

        this.setupRotateEventHandlers();
        this.setupDragEventHandlers();
        this.setupResizeEventHandlers();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error creating Moveable Manager", error);
    }
  }

  private renderDirections(resizable: boolean, scalable: boolean): string[] {
    if (resizable && scalable) {
      return ["n", "e", "s", "w", "nw", "ne", "sw", "se"];
    } else if (resizable) {
      return ["n", "e", "s", "w"];
    } else if (scalable) {
      return ["nw", "ne", "sw", "se"];
    } else {
      return [];
    }
  }

  private setupRotateEventHandlers(): void {
    this.moveable
      ?.on("rotateStart", () => {
        if (this.args.onRotateStart) {
          this.args.onRotateStart();
        }
      })
      .on("rotate", ({ rotation }) => {
        if (this.args.onRotate) {
          this.args.onRotate(rotation, this.moveable!.getRect());
        }
      })
      .on("rotateEnd", () => {
        if (this.args.onRotateEnd) {
          this.args.onRotateEnd();
        }
      });
  }

  private setupDragEventHandlers(): void {
    this.moveable
      ?.on("dragStart", (inputEvent) => {
        if (this.args.onDragStart && inputEvent.inputEvent instanceof MouseEvent) {
          this.args.onDragStart(inputEvent.clientX, inputEvent.clientY);
        }
      })
      .on("drag", ({ clientX, clientY }) => {
        if (this.args.onDrag) {
          this.args.onDrag(clientX, clientY);
        }
      })
      .on("dragEnd", () => {
        if (this.args.onDragEnd) {
          this.args.onDragEnd();
        }
      });
  }

  private setupResizeEventHandlers(): void {
    this.moveable
      ?.on("resizeStart", ({ inputEvent, direction }) => {
        if (this.isScaling(direction)) {
          if (this.args.onScaleStart && inputEvent instanceof MouseEvent) {
            this.args.onScaleStart(inputEvent.clientX, inputEvent.clientY);
          }
        } else {
          if (this.args.onResizeStart && inputEvent instanceof MouseEvent) {
            this.args.onResizeStart(inputEvent.clientX, inputEvent.clientY);
          }
        }
      })
      .on("resize", ({ clientX, clientY, direction }) => {
        if (this.isScaling(direction)) {
          if (this.args.onScale) {
            this.args.onScale(clientX, clientY, this.convertMoveableDirection(direction));
          }
        } else {
          if (this.args.onResize) {
            this.args.onResize(clientX, clientY, this.convertMoveableDirection(direction));
          }
        }
      })
      .on("resizeEnd", () => {
        if (this.args.onResizeEnd) {
          this.args.onResizeEnd();
        }

        if (this.args.onScaleEnd) {
          this.args.onScaleEnd();
        }
      });
  }

  private showMoveable(): void {
    if (this.moveable) {
      this.moveable.hideDefaultLines = false;
      this.moveable.rotatable = this.args.rotatable;
      this.moveable.draggable = this.args.draggable;
      this.moveable.resizable = this.args.resizable;
    }
  }

  private hideMoveable(): void {
    if (this.moveable) {
      this.moveable.hideDefaultLines = true;
      this.moveable.rotatable = false;
      this.moveable.draggable = false;
      this.moveable.resizable = false;
    }
  }

  private isScaling(direction: number[]): boolean {
    return direction[0] !== 0 && direction[1] !== 0;
  }

  private convertMoveableDirection(direction: number[]): ResizeFrom | ScaleFrom {
    if (direction[0] === -1 && direction[1] === -1) {
      return ScaleFrom.TOP_LEFT;
    } else if (direction[0] === 1 && direction[1] === -1) {
      return ScaleFrom.TOP_RIGHT;
    } else if (direction[0] === -1 && direction[1] === 1) {
      return ScaleFrom.BOTTOM_LEFT;
    } else if (direction[0] === 1 && direction[1] === 1) {
      return ScaleFrom.BOTTOM_RIGHT;
    }

    if (direction[0] === 1) {
      return ResizeFrom.RIGHT;
    } else if (direction[0] === -1) {
      return ResizeFrom.LEFT;
    } else if (direction[1] === 1) {
      return ResizeFrom.BOTTOM;
    } else if (direction[1] === -1) {
      return ResizeFrom.TOP;
    }

    return ResizeFrom.RIGHT;
  }
}
