import { tracked } from "@glimmer/tracking";
import type { ColorBrandKey } from "../../brand";
import type { FontVariant } from "../../font";
import { CharacterRange } from "./character-range";

export enum Alignments {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
  TOP = "top",
  BOTTOM = "bottom",
  TOP_LEFT = "topLeft",
  TOP_RIGHT = "topRight",
  BOTTOM_LEFT = "bottomLeft",
  BOTTOM_RIGHT = "bottomRight"
}

export type BulletList = "" | "bullet" | "ordered";

export interface TextStyleProperties {
  fontFamily?: string;
  fontVariant?: FontVariant;
  color?: string;
  fontSize?: number;
  range?: CharacterRange;
  ribbonColor?: string;
  lineHeight?: number;
  capitalize?: boolean;
  alignment?: Alignments;
  yAlignment?: Alignments;
  colorBrandKey?: ColorBrandKey;
  ribbonColorBrandKey?: ColorBrandKey;
  list?: BulletList;
  defaultWeight?: string;
}

type StyleValue<T> = {
  value: T;
  range: CharacterRange;
};

export class TextStyle {
  @tracked
  _fontFamily?: string;

  @tracked
  _fontVariant?: FontVariant;

  @tracked
  _color?: string;

  @tracked
  _ribbonColor?: string;

  @tracked
  _lineHeight?: number;

  @tracked
  _fontSize?: number;

  @tracked
  _range?: CharacterRange;

  @tracked
  _capitalize?: boolean;

  @tracked
  _alignment?: Alignments;

  @tracked
  _yAlignment?: Alignments;

  @tracked
  _colorBrandKey?: ColorBrandKey;

  @tracked
  _ribbonColorBrandKey?: ColorBrandKey;

  @tracked
  _list?: BulletList;

  @tracked
  _defaultWeight?: string;

  provider?: string;

  constructor(properties: TextStyleProperties) {
    this._fontFamily = properties.fontFamily;
    this._fontVariant = properties.fontVariant;
    this._color = properties.color;
    this._ribbonColor = properties.ribbonColor;
    this._lineHeight = properties.lineHeight;
    this._fontSize = properties.fontSize;
    this._range = properties.range;
    this._capitalize = properties.capitalize;
    this._alignment = properties.alignment;
    this._yAlignment = properties.yAlignment;
    this._colorBrandKey = properties.colorBrandKey;
    this._ribbonColorBrandKey = properties.ribbonColorBrandKey;
    this._list = properties.list;
    this._defaultWeight = properties.defaultWeight;
  }

  public get fontFamily(): string | undefined {
    return this._fontFamily;
  }

  public get fontVariant(): FontVariant | undefined {
    return this._fontVariant;
  }

  public get weight(): string | undefined {
    return this._fontVariant?.weight;
  }

  public get fontStyle(): string | undefined {
    return this._fontVariant?.style;
  }

  public get color(): string | undefined {
    return this._color;
  }

  public get ribbonColor(): string | undefined {
    return this._ribbonColor;
  }

  public get lineHeight(): number | undefined {
    return this._lineHeight;
  }

  public get fontSize(): number | undefined {
    return this._fontSize;
  }

  public get range(): CharacterRange | undefined {
    return this._range;
  }

  public get capitalize(): boolean | undefined {
    return this._capitalize;
  }

  public get alignment(): Alignments | undefined {
    return this._alignment;
  }

  public get yAlignment(): Alignments | undefined {
    return this._yAlignment;
  }

  public get colorBrandKey(): ColorBrandKey | undefined {
    return this._colorBrandKey;
  }

  public get ribbonColorBrandKey(): ColorBrandKey | undefined {
    return this._ribbonColorBrandKey;
  }

  public get list(): BulletList | undefined {
    return this._list;
  }

  public get defaultWeight(): string | undefined {
    return this._defaultWeight;
  }

  public toJSON(): Partial<TextStyle> {
    const { fontFamily, weight, fontStyle, lineHeight, fontSize, fontVariant } = this;

    return {
      fontFamily,
      weight,
      fontStyle,
      lineHeight,
      fontSize,
      fontVariant
    };
  }

  public static getStylePropertyForContent<T>(
    styles: TextStyle[],
    get: (s: TextStyle) => T | undefined,
    type: "text" | "line",
    content: string,
    range: CharacterRange
  ): T | undefined {
    const values = [];
    for (const s of styles) {
      const paragraphRange = s.range ? TextStyle.paragraphRange(s.range, content) : range;
      if (range.intersects(paragraphRange)) {
        const value = get(s);
        if (value !== undefined) {
          values.push({ value: value, range: paragraphRange });
        }
      }
    }

    const flatValues = TextStyle.flattenValues(values)
      .slice(range.startOffset, range.endOffset)
      .filter((v, i) => v && (type !== "text" || content[i] !== "\n"));

    return flatValues[0]?.value;
  }

  /** If the range specifies a newline then we treat it as a paragraph style.
   * The same goes for a range that is after the end of the text as there is
   * an implied new line */
  private static paragraphRange(range: CharacterRange, content: string): CharacterRange {
    if (range.startOffset > content.length) {
      for (let i = range.startOffset; i < range.endOffset; i++) {
        if (content[i] !== "\n") {
          return range;
        }
      }
    }

    if (range.startOffset === 0) {
      return range;
    }

    return CharacterRange.startAndEndOffset(content.lastIndexOf("\n", range.startOffset - 1) + 1, range.endOffset);
  }

  private static flattenValues<T>(values: StyleValue<T>[]): { value: T; json: string }[] {
    const flatValues = Array(values.reduce((a, { range }) => Math.max(a, range.endOffset), 0));

    [...values].reverse().forEach(({ value, range }) => {
      // Doing a stringify `value`, rather than for each character ends up
      // being a lot faster.
      flatValues.fill({ value, json: JSON.stringify(value) }, range.startOffset, range.endOffset);
    });

    return flatValues;
  }
}
