import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioTrack from "client/models/audio-track";
import { ASSET_LIBRARY_AUDIO_MIMETYPES } from "client/services/asset-library";

interface AudioTrackListArgs {
  tracks: AudioTrack[];
  selectedTrack?: AudioTrack;
  onUpload?: () => Promise<void>;
  onDelete?: (track: AudioTrack) => Promise<void>;
  setScrollTarget?: (element: HTMLElement) => void;
  loading: boolean;
  loadingComplete: boolean;
  loadNextPage: () => Promise<void>;
}

export default class AudioTrackListComponent extends Component<AudioTrackListArgs> {
  styleNamespace = getStyleNamespace("audio-track-list");

  @action
  setScrollTarget(element: HTMLElement): void {
    this.args.setScrollTarget?.(element);
  }

  onDeleteBound = this.onDelete.bind(this);

  async onDelete(track: AudioTrack): Promise<void> {
    if (this.args.onDelete) {
      await this.args.onDelete(track);
    }
  }

  get canUpload(): boolean {
    return !!this.args.onUpload;
  }

  get acceptMimeTypes(): string {
    return ASSET_LIBRARY_AUDIO_MIMETYPES.toString();
  }
}
