import { service } from "@ember/service";
import Model, { attr } from "@ember-data/model";
import formatMoney from "client/lib/format-money";
import type Plan from "client/models/plan";
import type PermissionsService from "client/services/permissions";

export enum COUPON_DURATION {
  ONCE = "once",
  FOREVER = "forever",
  REPEATING = "repeating"
}

export default class DiscountDetails extends Model {
  @attr("number")
  percentOff?: number;

  @attr("number")
  amountOffFullUnits?: number;

  @attr("string")
  duration?: string;

  @attr("number")
  durationInMonths?: number;

  @service
  declare permissions: PermissionsService;

  get isForever(): boolean {
    return this.duration === COUPON_DURATION.FOREVER;
  }

  get isOnce(): boolean {
    return this.duration === COUPON_DURATION.ONCE;
  }

  get description(): string {
    return `Coupon: ${this.valueOff} off ${this.discountInterval}`;
  }

  get tagline(): string {
    return this.percentOff ? `${this.percentOff}%` : `$${this.amountOffFullUnits}`;
  }

  calculatePrice(plan: Plan): string {
    const { monetaryUnits, currencySymbol, currencyIndicator } = plan;
    let price = monetaryUnits;

    if (this.amountOffFullUnits) {
      price -= Number(this.amountOffFullUnits);
    } else if (this.percentOff) {
      price *= (100 - Number(this.percentOff)) / 100;
    }

    return formatMoney(currencySymbol, price, currencyIndicator);
  }

  calculateFuturePrice(plan: Plan): string {
    const { formattedMonetaryUnits, interval } = plan;
    const futurePrice = formattedMonetaryUnits;

    if (this.isForever) {
      return "forever";
    } else {
      return `and ${futurePrice}/${interval} ongoing`;
    }
  }

  calculateDurationDescription(plan: Plan): string {
    let description = "";

    if (this.showDurationInMonths(plan)) {
      description += `for first ${this.durationInMonths} months`;
    } else if (this.showRawDuration) {
      description += this.duration!;
    } else if (this.isForever) {
      description += plan.interval;
    }

    return description;
  }

  private showDurationInMonths(plan: Plan): boolean {
    return !!this.durationInMonths && plan.isMonthly;
  }

  private get showRawDuration(): boolean {
    return !!this.duration && !this.isForever && !this.durationInMonths;
  }

  private get discountInterval(): string | undefined {
    if (this.durationInMonths) {
      return `for ${this.durationInMonths} ${this.durationInMonths > 1 ? "months" : "month"}`;
    }
    return this.duration;
  }

  private get valueOff(): string {
    return this.percentOff ? `${this.percentOff}%` : `$${this.amountOffFullUnits}`;
  }
}
