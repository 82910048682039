import Component from "@glimmer/component";
import type Project from "client/models/project";
import type ProjectRender from "client/models/project-render";

interface ExportPreviewComponentArgs {
  denyBuild: boolean;
  hideButton: boolean;
  isRendering: boolean;
  project: Project;
  renderFailed: boolean;
  renderProject: (ctaContext: string, thumbnailFrame?: number) => Promise<void>;
  upgradeRequired: boolean;
  renderRequired: boolean;
  renderStarting: boolean;
  showOverlay: boolean;
  statusText: Promise<string>;
  projectRender?: ProjectRender;
}

export default class ExportPreviewComponent extends Component<ExportPreviewComponentArgs> {
  get captionsUrl(): string | undefined {
    return this.args.projectRender?.projectTranscription?.get("vttUrl");
  }

  get captionsLanguage(): string | undefined {
    const detectedLanguage = this.args.projectRender?.projectTranscription?.get("detectedLanguage");
    if (detectedLanguage) {
      return detectedLanguage.split("-")[0];
    }
    return undefined;
  }
}
