import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type MobileService from "client/services/mobile";

export default class EditorMobileWarningComponent extends Component {
  @service
  declare mobile: MobileService;

  styleNamespace = getStyleNamespace("editor-mobile-warning");

  @action
  dismissModal(): void {
    this.mobile.dismissEditorMobileWarning();
  }
}
