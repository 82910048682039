import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";

const DEFAULT_ASPECT_RATIO = WIDE_ASPECT_RATIO_ID;

export default class TemplatesController extends Controller {
  queryParams = ["search", "aspectRatio", "branded"];

  @service
  declare router: RouterService;

  @tracked
  search = "";

  @tracked
  aspectRatio = WIDE_ASPECT_RATIO_ID;

  @tracked
  branded = false;

  @action
  async updateSearch(search: string): Promise<void> {
    if (this.router.currentRouteName.startsWith("authenticated.templates.category")) {
      try {
        await this.router.replaceWith("authenticated.templates", {
          queryParams: {
            search
          }
        });
      } catch {
        // Transition always gets aborted and we do not want it log stuff
      }
    } else {
      this.search = search;
    }

    await this.transitionIfRequired();
  }

  @action
  async updateAspectRatio(aspectRatioId: string): Promise<void> {
    this.aspectRatio = aspectRatioId;

    await this.transitionIfRequired();
  }

  @action
  async updateBranded(branded: boolean): Promise<void> {
    this.branded = branded;

    // We only support 16:9 for branded scenes
    if (branded) {
      this.aspectRatio = DEFAULT_ASPECT_RATIO;
    }

    await this.transitionIfRequired();
  }

  resetFilters(): void {
    this.search = "";
    this.aspectRatio = DEFAULT_ASPECT_RATIO;
    this.branded = false;
  }

  private async transitionIfRequired(): Promise<void> {
    const routeName = this.router.currentRouteName;

    if (this.filtered && routeName.startsWith("authenticated.templates.popular")) {
      try {
        await this.router.transitionTo("authenticated.templates.index");
      } catch {
        // This transition always gets aborted too and we do not want to log stuff
      }
    }
  }

  private get filtered(): boolean {
    return !!(this.branded || this.aspectRatio !== DEFAULT_ASPECT_RATIO || this.search);
  }
}
