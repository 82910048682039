import type { Scene } from "../../scene";
import type { TimelineMutation } from "./mutation";
import { SceneDurationMutation } from "./scene-duration-mutation";
import type { SceneState } from "./scene-state";
import { createState } from "./scene-state";

export class SceneDurationMutationFactory {
  private referenceState: SceneState;
  private grouped = false;

  constructor(private scene: Scene) {
    this.referenceState = createState(scene);
  }

  createMutation(duration: number, snapAmount?: number): TimelineMutation {
    const mutation = new SceneDurationMutation(this.grouped, this.scene, duration, this.referenceState, snapAmount);
    this.grouped = true;
    return mutation;
  }
}
