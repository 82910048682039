import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import { AvaReferer } from "client/authenticated/survey/data";
import { RecordProjectTypes } from "client/models/record-project";
import type NotificationsService from "client/services/notifications";
import type { ContentBarPanelType } from "client/services/project-content-bar";

export default class NewVideoActionsService extends Service {
  referer = AvaReferer.Manual;

  @service
  declare router: RouterService;

  @service
  declare notifications: NotificationsService;

  @service
  declare store: Store;

  @action
  async startFromVideoAssistant(): Promise<void> {
    await this.router.transitionTo("authenticated.survey.video-assistant.use-case", {
      queryParams: { referer: this.referer }
    });
  }

  @action
  async startFromScratch(contentBarPanel: ContentBarPanelType | undefined = undefined): Promise<void> {
    await this.router.transitionTo({ queryParams: { create: true, contentBarPanel: contentBarPanel } });
  }

  @action
  async startFromTemplate(): Promise<void> {
    // This transition always aborts and logs an error because the beforeModel hook
    // calls resetFilters() on the TemplatesController and each queryParameter refreshes
    // the model so the model hook gets called and due to native Promises aborts the inflight transition.
    // Try as I might using thenables seemingly in all the right places I could not get it to stop throwing
    // and logging the abort so here we are using a void transition
    void this.router.transitionTo("authenticated.templates.popular");
  }

  @action
  async createSelfRecordProject(): Promise<void> {
    await this.router.transitionTo({ queryParams: { createRecord: true, recordProjectType: RecordProjectTypes.SELF } });
  }

  @action
  async createCollaborativeRecordProject(): Promise<void> {
    await this.router.transitionTo({
      queryParams: { createRecord: true, recordProjectType: RecordProjectTypes.COLLABORATIVE }
    });
  }
}
