import type { AnimationOptions } from "client/components/app/animation-dropdown/component";
import type { Locations } from "client/events";
import { ImageType, getImageAnimationOptions } from "client/lib/data/animation-image";
import TEXT_SCENE_ANIMATIONS from "client/lib/data/animation-text";
import type { Asset, Caption, Element, EventRegister, Scene, Timeline } from "client/lib/editor-domain-model";
import {
  Animation,
  Background,
  ChangeAssetAnimationMutation,
  ChangeTextAnimationMutation,
  Image,
  Logo,
  Media,
  Svg,
  Text,
  VideoClip,
  Watermark
} from "client/lib/editor-domain-model";

export const canAnimate = (element?: Element): boolean => {
  if (element && (backgroundImage(element) || animateableLogo(element))) {
    return element.hasContent;
  } else if (element instanceof Text) {
    return true;
  }

  return false;
};

export const getAnimationOptions = (element: Element): AnimationOptions | undefined => {
  if (element instanceof Background) {
    return getImageAnimationOptions(ImageType.BACKGROUND);
  } else if (element instanceof Logo) {
    return getImageAnimationOptions(ImageType.LOGO);
  } else if (element instanceof Text) {
    return TEXT_SCENE_ANIMATIONS;
  }

  return;
};

export const getAnimation = (element: Element): string | undefined => {
  if (element instanceof Media) {
    const { asset } = element;

    if (asset instanceof Animation || asset instanceof Image || asset instanceof VideoClip || asset instanceof Svg) {
      return asset.animation;
    }
  } else if (element instanceof Text) {
    return element.animation;
  }

  return;
};

export const setAnimation = (eventRegister: EventRegister, element: Element, animation: string): void => {
  if (element instanceof Media) {
    const { asset } = element;

    if (asset instanceof Animation || asset instanceof Image || asset instanceof VideoClip || asset instanceof Svg) {
      eventRegister.fire(new ChangeAssetAnimationMutation(asset, animation));
    }
  } else if (element instanceof Text) {
    eventRegister.fire(new ChangeTextAnimationMutation(element, animation));
  }
};

export const saveElement = async (
  eventRegister: EventRegister,
  element: Element,
  scene?: Scene,
  delayCommitMs?: number
): Promise<void> => {
  if (element instanceof Watermark) {
    await eventRegister.facade.saveWatermark(element, { delayCommitMs });
  } else if (scene) {
    await eventRegister.facade.saveScene(scene, { delayCommitMs });
  }
};

const getAsset = (element: Element): Asset | undefined => {
  return element instanceof Media ? element.asset : undefined;
};

export const getThumbnailImgSrc = (element: Element): string | undefined => {
  const asset = getAsset(element);

  if (asset) {
    const { previewImageUrl } = asset;

    if (previewImageUrl) {
      return previewImageUrl;
    }

    if (asset instanceof Image) {
      return asset.sourceUrl;
    }
  }

  return undefined;
};

export const getThumbnailVideoSrc = (element: Element): string | undefined => {
  const asset = getAsset(element);

  return asset instanceof VideoClip ? asset.asset.previewVideoUrl : undefined;
};

export const getIcon = (element: Element): string => {
  const asset = getAsset(element);

  if (element instanceof Text) {
    return "icon/text";
  }

  if (element instanceof Background) {
    return "icon/backgrounds/background";
  }

  if (element instanceof Watermark) {
    return "icon/backgrounds/watermark";
  }

  if (asset instanceof Animation) {
    return "icon/emoji/smiley-outline";
  }

  if (asset instanceof VideoClip) {
    return "icon/file/video-outlined";
  }

  return "icon/file/image-outlined";
};

export const getNameLabel = (element: Element): string | undefined => {
  const asset = getAsset(element);

  if (element instanceof Text) {
    return element.content;
  }

  if (asset instanceof Image) {
    return asset.name;
  }

  if (asset instanceof VideoClip) {
    return asset.asset.name;
  }

  return undefined;
};

export const getTypeLabel = (element: Element): string => {
  const asset = getAsset(element);

  if (element instanceof Text) {
    return "Text";
  }

  if (element instanceof Background) {
    return "Background";
  }

  if (element instanceof Watermark) {
    return "Watermark";
  }

  if (asset instanceof Animation) {
    return "Graphic";
  }

  if (asset instanceof VideoClip) {
    return "Video";
  }

  if (asset instanceof Svg) {
    return "Scalable graphic";
  }

  return "Image";
};

export const getLayerOrderTrackingProperties = (
  timeline: Timeline,
  caption: Caption,
  element: Element,
  direction: "backward" | "forward" | "back" | "front",
  location: Locations
): {} => {
  return {
    projectId: timeline.id,
    sceneId: caption.scene.id,
    elementId: element.id,
    direction,
    endingLayer: element.layerOrder,
    totalElementsInScene: caption.elements.length,
    location
  };
};

const animateableLogo = (element: Element): boolean => {
  return element instanceof Logo;
};

const backgroundImage = (element: Element): boolean => {
  return element instanceof Background && element.asset instanceof Image;
};
