import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { encode } from "html-entities";
import config from "client/config/environment";
import TrackingEvents from "client/events";
import getStyleNamespace from "client/lib/get-style-namespace";
import type TrackingService from "client/services/tracking";

interface EmbedCodeComponentArgs {
  embedUrl: string;
  projectId: number;
  projectTitle: string;
  publishedUrl: string;
}

const NOTIFICATION_DURATION = 3000;

export default class EmbedCodeComponent extends Component<EmbedCodeComponentArgs> {
  @service
  declare tracking: TrackingService;

  @tracked
  code?: HTMLElement;

  @tracked
  autoplay = false;

  @tracked
  changeCopyButtonMessage? = false;

  styleNamespace = getStyleNamespace("socials/embed-code");

  get embedCode(): string {
    const { embedUrl, publishedUrl, projectTitle } = this.args;

    const src = `${embedUrl}${this.embedQueryString}`;
    const link = `<p><a href="${publishedUrl}">${encode(projectTitle)}</a> on <a href="${
      config.mainSiteUrl
    }">Biteable</a>.</p>`;

    return `<iframe
        frameborder="0"
        width="560"
        height="315"
        src="${src}"
        allowfullscreen="true"
        allow="autoplay"></iframe>
        ${link}`;
  }

  @action
  setCodeElement(element: HTMLElement): void {
    this.code = element;
  }

  @action
  async onCopyCode(): Promise<void> {
    this.trackCopyCodeEvent();
    await this.copyCode();
  }

  @action
  toggleAutoplay(): void {
    this.autoplay = !this.autoplay;
  }

  private async copyCode(): Promise<void> {
    const codeElement = this.code;

    if (!codeElement) {
      return;
    }

    const { clipboard } = navigator;

    if (clipboard) {
      await clipboard.writeText(codeElement.innerText);
    } else {
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      textarea.value = codeElement.innerText;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }

    this.toggleCopyButtonMessage();
  }

  private get embedQueryString(): string {
    const query = Object.entries({ autoplay: this.autoplay })
      .filter(([, value]) => !!value)
      .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value))
      .join("&");

    if (!query) {
      return "";
    }

    return `?${query}`;
  }

  private copyButtonMessageTimeout?: NodeJS.Timeout;

  private toggleCopyButtonMessage(): void {
    this.changeCopyButtonMessage = true;

    if (this.copyButtonMessageTimeout) {
      clearTimeout(this.copyButtonMessageTimeout);
    }

    this.copyButtonMessageTimeout = setTimeout(() => {
      this.changeCopyButtonMessage = false;
    }, NOTIFICATION_DURATION);
  }

  private trackCopyCodeEvent(): void {
    void this.tracking.sendAnalytics(TrackingEvents.EVENT_COPY_EMBED_CODE, {
      project_id: this.args.projectId // eslint-disable-line camelcase
    });
  }
}
