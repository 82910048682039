import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import type Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { debounce } from "@ember/runloop";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { AudioClipCategory } from "renderer-engine";
import { toggleDuckedLevel } from "renderer-engine";
import type { AudioClip } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";
import { SaveAction } from "client/lib/editor-domain-model/events/mutations/save-action";
import { transaction } from "client/lib/transaction";
import type Audible from "client/models/audible";
import type AudioTrack from "client/models/audio-track";
import type { AudioRouteModel } from "client/routes/audio";
import type AudioTracksService from "client/services/audio-tracks";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export default abstract class AudioController<T extends AudioRouteModel> extends Controller<T> {
  @tracked
  declare category?: AudioClipCategory;

  @service
  declare audioTracks: AudioTracksService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare router: RouterService;

  get audioClips(): AudioClip[] {
    return this.model.timeline.audioClips.filter((clip: AudioClip) => clip.category === this.category);
  }

  get audioClip(): AudioClip | undefined {
    return this.audioClips[0];
  }

  @action
  async deleteTrack(track?: AudioTrack): Promise<void> {
    if (!track) {
      return;
    }

    await this.audioTracks.deleteAudioTrack(track);

    this.timelineEvents.publish(TimelineEvents.TIMELINE_UPDATED, { transaction: new ExplicitTransaction() });
    await this.onRefresh();
  }

  @action
  @transaction
  async add(audible: Audible, category: AudioClipCategory): Promise<DomainModel.AudioClip | undefined> {
    const { eventRegister, timeline } = this.model;
    const mutation = new DomainModel.AddAudioClipMutation(timeline, audible, category);
    await mutation.prepare(eventRegister.facade);
    const audioClip = this.mutate(mutation);

    await this.save();

    return audioClip || undefined;
  }

  @action
  @transaction
  async remove(): Promise<void> {
    const { timeline } = this.model;

    if (this.audioClip) {
      this.mutate(new DomainModel.DeleteAudioClipMutation(timeline, this.audioClip));
      await this.save();
    }
  }

  @action
  mutate<T>(mutation: DomainModel.TimelineMutation<T>): T | void {
    this.model.eventRegister?.fire<T | void>(mutation);
  }

  @action
  onVolumeChange(value: number): void {
    const { audioClip } = this;
    if (audioClip) {
      this.mutate(new DomainModel.AudioClipVolumeMutation(audioClip, value));
      debounce(this, this.save, 100, false);
    }
  }

  @action
  onAudioDuckingLevelChange(): void {
    const { audioClip } = this;
    if (audioClip) {
      const { audioDuckingLevel } = audioClip;

      this.mutate(new DomainModel.AudioClipDuckingLevelMutation(audioClip, toggleDuckedLevel(audioDuckingLevel)));
      debounce(this, this.save, 100, false);
    }
  }

  @action
  async onMuteChange(value: boolean): Promise<void> {
    const { audioClip } = this;
    if (audioClip) {
      this.mutate(new DomainModel.AudioClipMuteMutation(audioClip, value));
      await this.save();
    }
  }

  @action
  async toggleFadeIn(): Promise<void> {
    const { audioClip } = this;
    if (audioClip) {
      this.mutate(new DomainModel.AudioClipFadeInMutation(audioClip, !audioClip.fadeIn));
      await this.save();
    }
  }

  @action
  async toggleFadeOut(): Promise<void> {
    const { audioClip } = this;
    if (audioClip) {
      this.mutate(new DomainModel.AudioClipFadeOutMutation(audioClip, !audioClip.fadeOut));
      await this.save();
    }
  }

  @action
  async save(): Promise<void> {
    const { eventRegister, timeline } = this.model;
    await eventRegister.facade.saveAudioClips(timeline);
    eventRegister.fire(new SaveAction(() => this.timelineEvents.publish(TimelineEvents.AUDIO_CHANGED)));
  }

  @action
  async onRefresh(): Promise<void> {
    const currentRouteName = this.router.currentRouteName;
    await (getOwner(this).lookup(`route:${currentRouteName}`) as Route)?.refresh();
  }
}
