import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { AnimatedLayer, Asset, EventRegister, Media } from "client/lib/editor-domain-model";
import { Animation, AnimationColorMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";

interface Args {
  media: Media;
  eventRegister: EventRegister;
  brandStyle?: BrandStyle;
  save: () => void;
}

interface AnimationColor {
  name: string;
  clips: AnimatedLayer[];
  colors: string[];
}

export default class ToolbarGraphicColorsComponent extends Component<Args> {
  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("toolbar/graphic-colors");

  @action
  saveColor(clips: AnimatedLayer[], color: string): void {
    const { eventRegister } = this.args;

    for (const clip of clips) {
      eventRegister.fire(new AnimationColorMutation(clip, color));
    }

    this.args.save();
  }

  get asset(): Asset | undefined {
    return this.args.media.asset;
  }

  get hasAnimation(): boolean {
    return this.asset instanceof Animation;
  }
  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get brandStyleConfig(): BrandStyleConfig | undefined {
    return this.brand?.style;
  }

  get animationColors(): AnimationColor[] {
    const animationColors = [] as AnimationColor[];

    if (!this.hasAnimation) {
      return animationColors;
    }

    const { clips } = this.asset as Animation;

    for (const clip of clips) {
      if (!clip.style.name) {
        continue;
      }

      const animationColor = animationColors.find((color) => color.name === clip.style.name);

      if (animationColor) {
        animationColor.clips.push(clip);
        animationColor.colors.push(clip.style.color);
      } else {
        animationColors.push({
          name: clip.style.name,
          clips: [clip],
          colors: [clip.style.color]
        });
      }
    }

    return animationColors;
  }
}
