// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import DS from "ember-data";

/**
 * @deprecated This method should not be used. Avoid using PromiseProxies: https://embermap.com/notes/83-the-case-against-async-relationships
 *
 * 2023-08-02 I don't know that this is still a deprecation, EmberMap ember data store front seems to have stalled and ember data has progressed
 * a lot
 */
const promiseArrayData = (promise: () => Promise<any>): DS.PromiseArray<any> => {
  return (DS.PromiseArray as any).create({
    promise: promise()
  });
};

export default promiseArrayData;
