import Model, { attr, belongsTo } from "@ember-data/model";
import type VideoAssistantGenerator from "./video-assistant-generator";

export default class VideoAssistantGeneratorField extends Model {
  @attr("string")
  declare key: string;

  @attr("string")
  declare name: string;

  @attr("string")
  declare description: string;

  @attr("number")
  declare maxLength: number;

  @attr("boolean")
  declare multiline: boolean;

  @belongsTo("video-assistant-generator", { async: true, inverse: "videoAssistantGeneratorFields" })
  declare videoAssistantGenerator: VideoAssistantGenerator;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    videoAssistantGeneratorField: VideoAssistantGeneratorField;
  }
}
