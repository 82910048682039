import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type ProjectSceneRoute from "client/authenticated/project/scene/route";
import type ZymbolGroup from "client/models/zymbol-group";

export type BackgroundTransitionOptions = {
  skipCaptionCheck: boolean;
};

export default class ProjectSceneBackgroundIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(transition: Transition): Promise<void> {
    if ((transition.data?.["options"] as BackgroundTransitionOptions)?.skipCaptionCheck) {
      return;
    }

    const { projectScene } = this.modelFor<ProjectSceneRoute>("authenticated.project.scene");
    const caption = await projectScene.firstCaption;

    if (caption && caption.hasZymbols) {
      void this.router.replaceWith("authenticated.project.scene.caption.background", (caption as ZymbolGroup).id);
    }
  }
}
