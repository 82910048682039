import { action } from "@ember/object";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { TrackedMap } from "tracked-built-ins";
import type { ProjectRouteModel } from "client/authenticated/project/route";
import TrackingEvents from "client/events";
import type { Scene } from "client/lib/editor-domain-model";
import type { EditableText } from "client/lib/text/text-editor";
import type AspectRatio from "client/models/aspect-ratio";
import type BrandStyle from "client/models/brand-style";
import type Favorable from "client/models/favorable";
import type ProjectScene from "client/models/project-scene";
import type AdvancedEditorService from "client/services/advanced-editor";
import AnalyticsService from "client/services/analytics";
import type AuthService from "client/services/auth";
import type FavoritesService from "client/services/favorites";
import type PermissionsService from "client/services/permissions";
import type PlaybackService from "client/services/playback";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

export interface ProjectSceneRouteModel extends ProjectRouteModel {
  projectScene: ProjectScene;
  scene: Scene;
  aspectRatio: AspectRatio;
  brandStyle?: BrandStyle;
  editables: Map<string, EditableText>;
}

export interface ProjectSceneRouteParams {
  sceneId: string;
}

export default class ProjectSceneRoute extends Route<ProjectSceneRouteModel> {
  @service
  declare auth: AuthService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare favorites: FavoritesService;

  @service
  declare permissions: PermissionsService;

  @service
  declare timelineEvents: TimelineEventsService;

  @service
  declare store: Store;

  @service
  declare playback: PlaybackService;

  @service
  declare router: RouterService;

  trackPageName = TrackingEvents.EVENT_VIEW_EDITOR;

  private editables = new TrackedMap<string, EditableText>();

  @action
  didTransition(): void {
    const { project } = this.modelFor("authenticated.project") as ProjectRouteModel;

    /* eslint-disable camelcase */
    AnalyticsService.page(this.trackPageName, {
      project_id: project.id,
      team_id: this.auth.currentTeam?.id
    });
    /* eslint-enable camelcase */
  }

  @action
  error(error: any): boolean {
    const [first] = error?.errors ?? [];

    if (first && first.status === 404) {
      void this.router.replaceWith("authenticated.project");
      return false;
    }

    return true;
  }

  async model({ sceneId }: ProjectSceneRouteParams): Promise<ProjectSceneRouteModel> {
    const model = this.modelFor("authenticated.project") as ProjectRouteModel;

    const { project, timeline } = model;

    const projectScene = (await project.projectScenes).find(({ id }) => id === sceneId) as ProjectScene & Favorable;
    const scene = timeline.getSceneById(sceneId);
    const brandStyle = await project.getBrandStyle();

    if (!projectScene || !scene) {
      void this.router.replaceWith("authenticated.project");
      throw Error("Scene does not exist on project");
    }

    return {
      ...model,
      projectScene,
      scene,
      brandStyle,
      editables: this.editables
    };
  }

  afterModel({ projectScene, scene }: ProjectSceneRouteModel): void {
    this.advancedEditor.setScene(scene);

    next(this, this.seekToScenePreviewTime, projectScene);
  }

  seekToScenePreviewTime(projectScene: ProjectSceneRouteModel["projectScene"]): void {
    this.timelineEvents.publish(TimelineEvents.ACTIVE_SCENE_CHANGED, { scene: projectScene });

    if (!projectScene.hasCaptions) {
      void this.playback.stop(projectScene.defaultPreviewTime);
    }
  }
}
