import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface RecordProjectTimeFrameArgs {
  timeFrame: string;
  updateTimeFrame(timeFrame: string): void;
}

export default class RecordProjectTimeFrameComponent extends Component<RecordProjectTimeFrameArgs> {
  constructor(owner: object, args: RecordProjectTimeFrameArgs) {
    super(owner, args);
  }

  styleNamespace = getStyleNamespace("discovery/create-record-project-modal/time-frame");
  timeFrameInput!: HTMLInputElement;

  @tracked
  open = false;

  cannedResponses = ["As soon as possible", "Within 2 days", "Within a week"];

  @action
  didInsert(): void {
    document.addEventListener("click", this.globalClick);
  }

  willDestroy(): void {
    super.willDestroy();
    document.removeEventListener("click", this.globalClick);
  }

  @action
  onOther(): void {
    this.timeFrameInput.focus();
    this.args.updateTimeFrame("");
  }

  @action
  globalClick(): void {
    this.toggleMenu(false);
  }

  @action
  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();

    this.toggleMenu();
  }

  toggleMenu(open = !this.open): void {
    if (this.open !== open) {
      this.open = open;
    }
  }

  @action
  setTimeframeInputElement(element: HTMLInputElement): void {
    this.timeFrameInput = element;
  }

  get notIncludedInCannedResponse(): boolean {
    return !this.cannedResponses.includes(this.args.timeFrame);
  }

  get placeholder(): string {
    return this.args.timeFrame === "" ? "Enter a date" : "As soon as possible";
  }

  get opaque(): boolean {
    return this.args.timeFrame !== "";
  }

  get isInputReadOnly(): boolean {
    return this.args.timeFrame === undefined || this.cannedResponses.includes(this.args.timeFrame);
  }
}
