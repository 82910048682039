import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import type { Line } from "client/lib/grid-guides";
import { Axis } from "client/lib/grid-guides/grid/axis";

interface GridGuidesLineComponentArgs {
  line: Line;
}

export default class GridGuidesLineComponent extends Component<GridGuidesLineComponentArgs> {
  get style(): SafeString {
    const { width, height } = this.axisStyle;
    const top = this.args.line.y || 0;
    const left = this.args.line.x || 0;

    return htmlSafe(`
      position: absolute;
      top: ${top * 100}%;
      left: ${left * 100}%;
      height: ${height};
      width: ${width};
      background-color: #D88296;
    `);
  }

  get axisStyle(): { width: string; height: string } {
    if (this.args.line.axis === Axis.VERTICAL) {
      return { width: "2px", height: "100%" };
    }
    return { width: "100%", height: "2px" };
  }
}
