import Dexie from "dexie";
import type UnsplashPhoto from "client/models/unsplash-photo";

const DATABASE_NAME = "BiteableUnsplash";

export interface StoredUnsplashPhoto extends Partial<UnsplashPhoto> {
  selectedAt?: Date;
  uid?: number;
}

export default class UnsplashDatabase extends Dexie {
  public unsplashPhotos!: Dexie.Table<StoredUnsplashPhoto, number>;

  constructor() {
    super(DATABASE_NAME);
    this.version(1).stores({
      unsplashPhotos: "++uid, id, selectedAt"
    });
  }
}
