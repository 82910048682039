import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";

const TITLE_CHARS = 25;

interface ProjectTitleEditArgs {
  project: Project;
}

export default class ProjectTitleEditComponent extends Component<ProjectTitleEditArgs> {
  styleNamespace = getStyleNamespace("app-header/project/title/edit");

  get projectTitle(): { start: string; end: string } {
    const title = this.args.project.title;

    const pos = Math.min(title.length / 2, Math.floor(TITLE_CHARS / 2));
    const end = title.slice(-pos);
    const start = title.slice(0, title.length - end.length);

    return { start, end };
  }
}
