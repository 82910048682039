import type ArrayProxy from "@ember/array/proxy";
import type { RouteModel } from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Model from "@ember-data/model";
import LoadingRoute from "client/routes/loading";

interface QueryMeta {
  meta: {
    page: number;
    "per-page": number;
    "total-count": number;
  };
}

export interface PaginatedRouteParams {
  // eslint-disable-next-line camelcase
  per_page: number;
  page: number;
}

export interface SortedPaginatedRouteParams extends PaginatedRouteParams {
  order: string;
}

export const ROUTE_QUERY_PARAMS = {
  page: {
    refreshModel: true
  },
  // eslint-disable-next-line camelcase
  per_page: {
    refreshModel: true
  }
};

export default abstract class PaginatedRoute<T = RouteModel> extends LoadingRoute<T> {
  @service
  declare router: RouterService;
  queryParams = ROUTE_QUERY_PARAMS;

  /**
   * Check if values for any params in `keys` changed in the provided transition.
   */
  didQueryParamsChange(transition: Transition, keys: string[]): boolean {
    if (!transition.from || !transition.from.queryParams) {
      return false;
    }

    return keys.reduce(
      (changed: boolean, param) =>
        changed || (transition as any).queryParams[param] !== transition.from!.queryParams[param],
      false
    );
  }

  /**
   * Check if we're on a page with empty items and if so redirect to the last page
   */
  checkEmptyPagination<T extends Model>({ length, meta }: ArrayProxy<T> & QueryMeta): void {
    if (meta && meta.page > 1 && !length) {
      const lastPage = Math.ceil(meta["total-count"] / meta["per-page"]);
      void this.router.transitionTo(this.routeName, { queryParams: { page: lastPage } });
    }
  }
}
