import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface PlansTableHeaderArgs {
  changeInterval: () => void;
  interval: string;
  intervalPlans: Plan[];
  selectYearlyInterval: () => void;
}

export default class PlansTableHeaderComponent extends Component<PlansTableHeaderArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-table-header");
}
