import Route from "@ember/routing/route";
import { service } from "@ember/service";
import type { ProjectSceneRouteModel } from "client/authenticated/project/scene/route";
import type ProjectSceneRoute from "client/authenticated/project/scene/route";
import type { Watermark } from "client/lib/editor-domain-model";
import type Zymbol from "client/models/zymbol";
import type { ModelFrom } from "client/routes/model-from";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AuthService from "client/services/auth";

export interface ProjectSceneWatermarkRouteModel extends ProjectSceneRouteModel {
  watermark?: Watermark;
  zymbol: Zymbol;
}

export default class ProjectSceneWatermarkRoute extends Route<ProjectSceneWatermarkRouteModel> {
  @service
  declare auth: AuthService;

  @service
  declare advancedEditor: AdvancedEditorService;

  get isUserSubscribed(): boolean {
    return !!this.auth.currentUser?.subscribed;
  }

  async model(): Promise<ProjectSceneWatermarkRouteModel> {
    const model = this.modelFor<ProjectSceneRoute>("authenticated.project.scene");
    const { watermark } = model.timeline;

    const zymbol = await model.project.userWatermark!;

    return {
      ...model,
      watermark,
      zymbol
    };
  }

  async afterModel({ watermark }: ModelFrom<ProjectSceneWatermarkRoute>): Promise<void> {
    if (watermark) {
      void this.advancedEditor.setElement(watermark);
    }
  }
}
