import Service, { service } from "@ember/service";
import config from "client/config/environment";
import type User from "client/models/user";
import type AuthService from "client/services/auth";

export type StripeAccount = "US";

export default class StripeService extends Service {
  @service
  declare auth: AuthService;

  private instance?: stripe.Stripe;

  private get currentUser(): User | undefined {
    return this.auth.currentUser;
  }

  private get stripeAccount(): StripeAccount {
    return this.currentUser?.stripeAccount ?? "US";
  }

  public getInstance(): stripe.Stripe {
    if (!this.instance) {
      this.instance = this.createInstance();
    }

    return this.instance;
  }

  private createInstance(): stripe.Stripe {
    if (this.instance) {
      return this.instance;
    }

    const token = config.stripeToken[this.stripeAccount];

    if (!token) {
      throw Error(`No stripe token defined for the account ${this.stripeAccount}`);
    }

    return Stripe(token);
  }
}
