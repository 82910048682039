import Component from "@glimmer/component";
import type { SelectSizes } from "client/components/north-star/select/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface SelectButtonComponentArgs {
  id?: string;
  expanded: boolean;
  size: SelectSizes;
  onClick(event: MouseEvent): unknown;
}

export default class SelectButtonComponent extends Component<SelectButtonComponentArgs> {
  styleNamespace = getStyleNamespace("north-star/dropdown-menu/button");
}
