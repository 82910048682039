import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { later } from "@ember/runloop";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  captionsLoading: boolean;
  captionsCount: number;
  onCancel?(): unknown;
  onSave(): Promise<void>;
  onDownloadSrtFile(): Promise<void>;
}

export default class EditCaptionsComponent extends Component<Args> {
  modalId = `${guidFor(this)}__InnerModalId`;
  styleNamespace = getStyleNamespace("edit-captions-modal");

  @tracked
  opening = false;

  @tracked
  closing = false;

  clickTargeted?: HTMLElement;

  get hideBottomCtas(): boolean {
    return this.args.captionsLoading || this.args.captionsCount < 7;
  }

  get className(): string {
    const opening = this.opening ? "--opening" : "";
    const closing = this.closing ? "--closing" : "";
    return `${this.styleNamespace} ${opening} ${closing}`;
  }

  @action
  didInsert(): void {
    this.onDocumentKeyup = this.onDocumentKeyup.bind(this);
    document.body.addEventListener("keyup", this.onDocumentKeyup);
    document.body.addEventListener("mousedown", this.onMouseDown, false);
    document.body.addEventListener("mouseup", this.onMouseUp, false);
    this.opening = true;
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    document.body.removeEventListener("keyup", this.onDocumentKeyup);
    document.body.removeEventListener("mousedown", this.onMouseDown);
    document.body.removeEventListener("mouseup", this.onMouseUp);
  }

  @action
  async save(): Promise<void> {
    try {
      await this.args.onSave();
      this.close();
    } catch {
      /* empty */
    }
  }

  @action
  close(): void {
    if (this.closing) {
      return;
    }

    this.closing = true;
    later(() => this.args.onCancel?.(), 200);
  }

  onMouseDown({ target }: MouseEvent): void {
    this.clickTargeted = target as HTMLElement;
  }

  onMouseUp(): void {
    const inner = document.getElementById(this.modalId);
    if (this.clickTargeted === inner) {
      this.close();
    }
  }

  private onDocumentKeyup({ code }: KeyboardEvent): void {
    if (code === EventKeys.ESC) {
      this.close();
    }
  }
}
