import Model, { attr, belongsTo } from "@ember-data/model";
import type StockAsset from "client/models/stock-asset";

export interface StockLicenseDetails {
  licensed: boolean;
  purchased: boolean;
  stockAssetId: string;
}

export default class StockLicense extends Model {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("stock-asset", { async: false, inverse: null })
  declare stockAsset: StockAsset;

  @attr("boolean")
  declare licensed: boolean;

  @attr("boolean")
  declare purchased: boolean;

  get stockAssetId(): string {
    return this.stockAsset.providerAssetId;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    stockLicense: StockLicense;
  }
}
