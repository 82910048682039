import Application from "@ember/application";
import loadInitializers from "ember-load-initializers";
import Resolver from "ember-resolver";
import { addJSSnippets } from "./snippets";
import config from "client/config/environment";

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
  // declare modulePrefix: string;
  // declare podModulePrefix: string;
  // declare Resolver: Resolver;

  // LOG_TRANSITIONS = true;
  // LOG_TRANSITIONS_INTERNAL = true;

  constructor(args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(args);
    addJSSnippets();
    // this.modulePrefix = config.modulePrefix;
    // this.podModulePrefix = config.podModulePrefix;
    // Object.defineProperty(this, "Resolver", Resolver);
  }
}
loadInitializers(App, config.modulePrefix);
