const DEFAULT_DIMENSIONS = "24";

/**
 * Validates and fixes a file SVG has width and height in the SVG xml.
 *
 * @param file - The file object with the SVG attached.
 * @returns The amended SVG file with width and height.
 */
export async function validateSvgFile(file: File): Promise<File> {
  const svgContent = await readFile(file);
  const amendedSvgContent = amendSvg(svgContent);
  return svgContentToFile(amendedSvgContent, file.name, file.type);
}

/**
 * Takes in a File object and converts its contents to a string
 *
 * @param file - A File object.
 * @returns A string representation of file contents.
 */
export async function readFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      resolve(reader.result as string);
    };
    reader.onerror = (error): void => {
      reject(error);
    };
    reader.readAsText(file);
  });
}

/**
 * Ingests a string representing an svg markup and adds width and height
 * using the viewbox
 *
 * @param svgContent - A string representing the svg markup.
 * @returns A string representing the svg markup with width and height attr.
 */
export function amendSvg(svgContent: string): string {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");
  const svgElement = svgDoc.documentElement;

  if (!svgElement.hasAttribute("width") || !svgElement.hasAttribute("height")) {
    if (svgElement.hasAttribute("viewBox")) {
      const viewBox = svgElement.getAttribute("viewBox")!.split(" ");
      const viewBoxWidth = parseFloat(viewBox[2] ?? DEFAULT_DIMENSIONS);
      const viewBoxHeight = parseFloat(viewBox[3] ?? DEFAULT_DIMENSIONS);

      if (!svgElement.hasAttribute("width")) {
        svgElement.setAttribute("width", viewBoxWidth.toString());
      }
      if (!svgElement.hasAttribute("height")) {
        svgElement.setAttribute("height", viewBoxHeight.toString());
      }
    } else {
      throw new Error("SVG must have either width/height attributes or a viewBox attribute.");
    }
  }

  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
}

function svgContentToFile(svgContent: string, filename: string, mimeType: string): File {
  const blob = new Blob([svgContent], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
}
