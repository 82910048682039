import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { Background, Text } from "client/lib/editor-domain-model";
import { Logo } from "client/lib/editor-domain-model";
import type HoneybadgerService from "client/services/honeybadger";

interface Args {
  media: Text | Logo | Background;
}

export default class MediaActionsAdjustFrameComponent extends Component<Args> {
  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare router: RouterService;

  @action
  adjustFrame(): void {
    this.router.transitionTo("authenticated.project.scene.caption.logo.frame").catch((reason): void => {
      // @ts-expect-error
      this.honeybadger.notify(reason);
    });
  }

  get adjustFrameText(): string {
    if (this.args.media instanceof Logo && !!this.args.media.asset?.frame) {
      return "Adjust frame";
    } else {
      return "Add frame";
    }
  }
}
