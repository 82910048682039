import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Alignment, YAlignment } from "renderer-engine";
import {
  GOOGLE_FONT_FAMILIES,
  TextDirection,
  detectTextDirection,
  DEFAULT_LINE_HEIGHT,
  convertEmsToFormSize,
  convertFormSizeToEms
} from "renderer-engine";
import type { AnimationOptions } from "client/components/app/animation-dropdown/component";
import type { TextAnimationId } from "client/lib/data/animation-text-ids";
import type { BulletList, Caption, EventRegister, Text, TextStyle } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import {
  stripQuotes,
  DEFAULT_FONT_WEIGHTS,
  FONT_SIZES,
  LINE_HEIGHTS,
  numberToDescription,
  getFontFamilyPreviewUrl,
  getWeightAndStyleAsVariant,
  stringifyVariant
} from "client/lib/text/fonts";
import type { EditableText } from "client/lib/text/text-editor";
import { getAnimation, getAnimationOptions, saveElement, setAnimation } from "client/lib/timeline/element";
import { transaction } from "client/lib/transaction";
import type Font from "client/models/font";
import type { FontFamilyOption } from "client/models/font-family";
import type FontsService from "client/services/fonts";

interface ProjectEditTextArgs {
  eventRegister: EventRegister;
  text: Text;
  caption: Caption;
  fonts?: Font[];
  editables: Map<string, EditableText>;
}

export default class ProjectEditTextComponent extends Component<ProjectEditTextArgs> {
  @service
  declare fonts: FontsService;

  @tracked
  loadingFontFamily = false;

  @tracked
  fontVariants: string[] = [];

  fontSizes = FONT_SIZES;
  lineHeights = LINE_HEIGHTS;
  defaultLineHeight = DEFAULT_LINE_HEIGHT;

  styleNamespace = getStyleNamespace("tidal/project-edit/text");

  constructor(owner: any, args: ProjectEditTextArgs) {
    super(owner, args);
  }

  get textStyle(): TextStyle | undefined {
    return this.editableText?.textStyle;
  }

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.text.id);
  }

  get isRightToLeftText(): boolean {
    return detectTextDirection(this.args.text.content) === TextDirection.RTL;
  }

  get fontSizeValue(): number {
    return convertEmsToFormSize(this.textStyle?.fontSize ?? 1);
  }

  get lineHeightValue(): number {
    return this.textStyle?.lineHeight ?? this.defaultLineHeight;
  }

  get customFontFamilies(): Font[] | undefined {
    return this.args.fonts;
  }

  get hasEditor(): boolean {
    return !!this.editableText;
  }

  get capitalizeToggleTo(): boolean {
    return !this.textStyle?.capitalize;
  }

  get backgroundSwatchColor(): [string] | undefined {
    return this.textStyle?.ribbonColor ? [this.textStyle?.ribbonColor] : undefined;
  }

  get fontFamilies(): FontFamilyOption[] {
    // Google font families
    return GOOGLE_FONT_FAMILIES.map((family: string) => {
      return {
        name: family,
        imageUrl: getFontFamilyPreviewUrl(family)
      };
    });
  }

  get fontVariant(): string | undefined {
    return this.textStyle?.fontVariant && numberToDescription(stringifyVariant(this.textStyle.fontVariant));
  }

  get availableFontVariant(): string {
    return !this.fontVariant || !this.isFontWeightAvailable() ? this.getDefaultFontWeight() : this.fontVariant;
  }

  get bulletToggleTo(): string {
    return this.textStyle?.list === "bullet" ? "" : "bullet";
  }

  get orderedToggleTo(): string {
    return this.textStyle?.list === "ordered" ? "" : "ordered";
  }

  get animations(): AnimationOptions | undefined {
    return getAnimationOptions(this.args.text);
  }

  get animation(): string | undefined {
    return getAnimation(this.args.text);
  }

  @action
  updateFontSize(fontSize: number): void {
    this.editableText?.applyStyle({
      fontSize: convertFormSizeToEms(fontSize)
    });
  }

  @action
  updateFontVariant(description: string): void {
    this.editableText?.applyStyle({
      fontVariant: getWeightAndStyleAsVariant(description)
    });
  }

  @action
  updateLineHeight(height: number): void {
    this.editableText?.applyStyle({
      lineHeight: height
    });
  }

  @action
  async updateFont(font: string): Promise<void> {
    await this.loadFontFamily(font);

    this.editableText?.applyStyle({
      fontFamily: font,
      fontVariant: getWeightAndStyleAsVariant(this.availableFontVariant)
    });
  }

  @action
  onChangeCapitalize(capitalize: boolean): void {
    this.editableText?.applyStyle({
      capitalize
    });
  }

  @action
  onChangeBackgroundColor(color: string): void {
    this.editableText?.applyStyle({
      ribbonColor: color
    });
  }

  @action
  onChangeFontColor(color: string): void {
    this.editableText?.applyStyle({
      color
    });
  }

  @action
  onChangeList(list: BulletList): void {
    this.editableText?.applyStyle({
      list
    });
  }

  @action
  onAlignChange(align: Alignment): void {
    this.editableText?.applyStyle({
      alignment: align
    });
  }

  @action
  onYAlignChange(align: YAlignment): void {
    this.editableText?.applyStyle({
      yAlignment: align
    });
  }

  @action
  @transaction
  async setAnimation(animationId: TextAnimationId): Promise<void> {
    const { eventRegister, text, caption } = this.args;

    setAnimation(eventRegister, text, animationId);

    await saveElement(eventRegister, text, caption.scene);
  }

  @action
  async handleFontChange(): Promise<void> {
    if (!this.textStyle?.fontFamily) {
      return;
    }

    await this.loadFontFamily(this.textStyle.fontFamily);
  }

  async loadFontFamily(chosenFont: string): Promise<void> {
    const fontFamily = stripQuotes(chosenFont);
    this.loadingFontFamily = true;
    try {
      await this.fonts.loadFont(fontFamily);
      this.fontVariants = this.fonts.getVariantNames(fontFamily);
    } finally {
      this.loadingFontFamily = false;
    }
  }

  getDefaultFontWeight(): string {
    for (const weight of DEFAULT_FONT_WEIGHTS) {
      const weightDescription = numberToDescription(weight);

      if (this.fontVariants.includes(weightDescription)) {
        return weightDescription;
      }
    }

    return numberToDescription(DEFAULT_FONT_WEIGHTS[0]!);
  }

  isFontWeightAvailable(): boolean {
    return !!this.fontVariant && this.fontVariants.includes(this.fontVariant);
  }
}
