import type Controller from "@ember/controller";
import { action } from "@ember/object";
import type Evented from "@ember/object/evented";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type FolderLibraryController from "./controller";
import { ROOT_FOLDER_ID } from "client/adapters/folder-content";
import { ContributorTypes, FolderCategoryTypes } from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type { PaginatedRouteParams, SortedPaginatedRouteParams } from "client/routes/paginated";
import PaginatedRoute from "client/routes/paginated";
import type FoldersService from "client/services/folders";
import type TeamProjectActivityService from "client/services/team-project-activity";

export interface FoldersLibraryRouteModel {
  contents: Array<FolderContent>;
  order: string;
  type: ContributorTypes;
  search: string;
}

interface FolderLibraryRouteParams extends SortedPaginatedRouteParams {
  type: ContributorTypes;
  search: string;
}

export default class FoldersLibraryRoute extends PaginatedRoute<FoldersLibraryRouteModel> {
  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare router: RouterService & Evented;

  @service
  private declare teamProjectActivity: TeamProjectActivityService;

  @tracked
  declare query: string;

  beforeModel(transition: Transition): void {
    const { type } = transition.to.params;

    if (!type || !Object.values(ContributorTypes).includes(type as ContributorTypes)) {
      void this.router.transitionTo(this.routeName, ContributorTypes.USER);
      return;
    }
  }

  // eslint-disable-next-line camelcase
  async model({ type, page, per_page, order, search }: FolderLibraryRouteParams): Promise<FoldersLibraryRouteModel> {
    order ??= this.folders.filterOrder;
    search ??= this.query;

    // @ts-expect-error
    const contents = (await this.store.query("folderContent", {
      folderId: ROOT_FOLDER_ID,
      type,
      page,
      // eslint-disable-next-line camelcase
      per_page,
      order,
      category: FolderCategoryTypes.PROJECTS,
      search: search
    })) as Array<FolderContent>;

    return {
      contents,
      order,
      type,
      search
    };
  }

  afterModel({ contents }: FoldersLibraryRouteModel, transition: Transition): void {
    this.teamProjectActivity.setLastViewedAt(new Date());

    this.folders.setActiveFolder();

    const { page } = this.paramsFor(this.routeName) as PaginatedRouteParams;

    if (!contents.length && page && page > 1) {
      void transition.abort();
      void this.router.replaceWith(this.routeName, { queryParams: { page: 1, order: "created_at:asc" } });
    }
  }

  async setupController(
    controller: Controller,
    model: FoldersLibraryRouteModel,
    transition: Transition
  ): Promise<void> {
    super.setupController(controller, model, transition);

    const routeController = controller as FolderLibraryController;

    const { routeName } = this;
    const { order, search } = model;
    order ?? this.folders.filterOrder;

    Object.assign(routeController, {
      routeName,
      order,
      search
    });
  }

  @action
  onRouteWillChange(): void {
    const order = (this.routeController as FolderLibraryController).order;
    if (order) {
      this.folders.filterOrder = order;
    }
  }

  activate(): void {
    this.router.on("routeWillChange", this.onRouteWillChange);
  }

  deactivate(): void {
    this.router.off("routeWillChange", this.onRouteWillChange);
  }

  queryParams = {
    order: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    // eslint-disable-next-line camelcase
    per_page: {
      refreshModel: true
    },
    search: {
      refreshModel: true
    }
  };
}
