import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Bounds, AssetOffset } from "renderer-engine";
import { Assets, AssetSizes, AssetTypes } from "renderer-engine";
import AssetCrop from "client/lib/asset-crop";
import type { Media } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getAssetContainer } from "client/lib/timeline/media";

interface ProjectEditorCanvasCropArgs {
  media: Media;
  canvas: HTMLCanvasElement;
  x: number;
  y: number;
  width: number;
  height: number;
  offsetX?: number;
  offsetY?: number;
  onChange?: (bounds: Bounds) => void;
  onCancel?: () => void;
}

export default class ProjectEditorCanvasCropComponent extends Component<ProjectEditorCanvasCropArgs> {
  @tracked
  asset?: HTMLImageElement;

  styleNamespace = getStyleNamespace("tidal/project-canvas-crop");

  handleKeypressBound = this.handleKeypress.bind(this);

  @action
  async didInsert(): Promise<void> {
    await this.loadImage();
    window.addEventListener("keydown", this.handleKeypressBound);
  }

  willDestroy(): void {
    super.willDestroy();
    window.removeEventListener("keydown", this.handleKeypressBound);
  }

  @action
  handleKeypress(event: KeyboardEvent): void {
    if (event.key === "Escape" && this.args.onCancel) {
      this.args.onCancel();
    }
  }

  get assetUrl(): string | undefined {
    return this.asset?.src;
  }

  get offset(): AssetOffset {
    let { offsetX, offsetY } = this.args;

    if ((offsetX === undefined || offsetY === undefined) && this.asset) {
      const { width, height } = AssetCrop.getAssetSize(this.containerBounds, this.asset, this.args.canvas);
      // Set the starting offsets to keep the asset centred
      offsetX = (width - this.containerBounds.width) * 0.5;
      offsetY = (height - this.containerBounds.height) * 0.5;
    }

    return [offsetX ?? 0, offsetY ?? 0];
  }

  get containerBounds(): Bounds {
    const { x, y, width, height } = this.args;
    return { x, y, width, height };
  }

  get assetBounds(): Bounds | undefined {
    if (this.asset) {
      return AssetCrop.getAssetBounds(this.containerBounds, this.asset, this.args.canvas, this.offset);
    }

    return;
  }

  @action
  async onChange(bounds: Bounds): Promise<void> {
    if (this.args.onChange) {
      const clampedBounds = AssetCrop.fillContainer(bounds, this.containerBounds);
      this.args.onChange(clampedBounds);
    }
  }

  async loadImage(): Promise<void> {
    const assetContainer = getAssetContainer(this.args.media.asset);

    if (!assetContainer) {
      return;
    }

    const [asset] = await Assets.loadAsset(assetContainer, AssetSizes.PREVIEW, AssetTypes.IMAGE);
    this.asset = asset?.get() as HTMLImageElement;
  }
}
