import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { AudioAsset } from "client/lib/editor-domain-model";
import { ExplicitTransaction } from "client/lib/editor-domain-model/events/mutations/explicit-transaction";
import getStyleNamespace from "client/lib/get-style-namespace";
import type AudioTrack from "client/models/audio-track";
import { ASSET_LIBRARY_AUDIO_MIMETYPES } from "client/services/asset-library";
import type AudioTrackService from "client/services/audio-tracks";
import type HoneybadgerService from "client/services/honeybadger";
import type TimelineEventsService from "client/services/timeline-events";
import { TimelineEvents } from "client/services/timeline-events";

interface UserAudioTrackListArgs {
  selectedTrack?: AudioTrack;
  onSelect: (audioAsset: AudioAsset) => Promise<void>;
  onCancel: () => void;
  onSave: () => Promise<void>;
}

export default class UserAudioTrackListComponent extends Component<UserAudioTrackListArgs> {
  @service
  declare audioTracks: AudioTrackService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare timelineEvents: TimelineEventsService;

  @tracked
  tracks: AudioTrack[] = [];

  @tracked
  loading = false;

  @tracked
  loadingComplete = false;

  @tracked
  page = 1;

  perPage = 50;

  styleNamespace = getStyleNamespace("user-audio-track-list");

  constructor(owner: any, args: UserAudioTrackListArgs) {
    super(owner, args);
  }

  @action
  async fetchTracks(): Promise<void> {
    this.page = 0;
    await this.loadNextPage();
  }

  @action
  async loadNextPage(): Promise<void> {
    try {
      this.loading = true;
      this.page += 1;
      const result = await this.audioTracks.getCurrentUsersUploadedTracks({
        page: this.page,
        per_page: this.perPage // eslint-disable-line camelcase
      });

      if (this.page === 1) {
        this.tracks = result.models;
      } else {
        this.tracks = this.tracks.concat(result.models);
      }

      this.loadingComplete = this.page * this.perPage >= result.meta.totalCount;
    } catch (error) {
      // @ts-expect-error
      this.honeybadger.notify(error, {
        name: "GetUserAudioTracks"
      });
      this.loadingComplete = true;
    } finally {
      this.loading = false;
    }
  }

  @action
  async onUpload(): Promise<void> {
    await this.fetchTracks();
  }

  @action
  async deleteTrack(track: AudioTrack): Promise<void> {
    const deletedId = await this.audioTracks.deleteAudioTrack(track);
    if (deletedId) {
      const index = this.tracks.findIndex(({ id }) => deletedId === id);
      this.tracks.splice(index, 1);
    }
    this.timelineEvents.publish(TimelineEvents.TIMELINE_UPDATED, { transaction: new ExplicitTransaction() });
    await this.fetchTracks();
  }

  get acceptMimeTypes(): string {
    return ASSET_LIBRARY_AUDIO_MIMETYPES.toString();
  }
}
