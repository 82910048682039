import ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { uniq } from "lodash";
import sum from "lodash/sum";
import type { ContentTypeOption } from "../../../search/component";
import type { ContentQueryParams } from "../../base/component";
import TrackingEvents from "client/events";
import { CONTENT_TRACKING_TYPES, ContentTrackingHelper, SEARCH_LOCATION } from "client/lib/content-tracking-helper";
import type SearchableContent from "client/models/searchable-content";
import type SelectableAsset from "client/models/selectable-asset";
import { ContentType } from "client/services/contents-curator";
import type HoneybadgerService from "client/services/honeybadger";
import type TrackingService from "client/services/tracking";

interface Args {
  query: string;
  type: ContentTypeOption;
}

const DEFAULT_PER_PAGE_PER_TYPE = 15;
const BITEABLE_CONTENT = [ContentType.GRAPHICS, ContentType.PROJECT_SCENE];
type SearchType = "searchableContent" | ContentType;
const SEARCHABLE_CONTENT = "searchableContent";

export default class ContentSearchCompoundComponent extends Component<Args> {
  @service
  declare store: Store;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  declare tracking: TrackingService;

  @tracked
  emptyResults = false;

  private totalCounts: { [key in SearchType]?: number } = {};

  @action
  didUpdateParams(): void {
    this.emptyResults = false;
  }

  @action
  async loadResults(params: ContentQueryParams): Promise<ArrayProxy<SelectableAsset>> {
    const { page, query } = params;

    const results = await Promise.all(
      this.searchTypes.map(async (searchType) => {
        return await this.searchContent(searchType, query ?? "", page ?? 1);
      })
    );

    this.emptyResults = this.totalCount === 0;
    const content = this.combineResults(results);

    this.track(page, query);

    return ArrayProxy.create({
      content,
      meta: { "per-page": content.length, page: page, "total-count": this.totalCount }
    }) as ArrayProxy<SelectableAsset>;
  }

  private async searchContent(searchType: SearchType, query: string, page: number): Promise<SelectableAsset[]> {
    const total = this.totalCounts[searchType];
    if (typeof total === "number" && (page - 1) * DEFAULT_PER_PAGE_PER_TYPE > total) {
      return [];
    }

    try {
      const results = await this.store.query(searchType, {
        // eslint-disable-next-line camelcase
        per_page: DEFAULT_PER_PAGE_PER_TYPE,
        [this.searchKey(searchType)]: query,
        page
      });

      // @ts-expect-error
      this.totalCounts[searchType] = results.meta["total-count"] ?? results.meta.totalCount;

      if (searchType === SEARCHABLE_CONTENT) {
        return (results as unknown as SearchableContent[]).map((searchableContent) => searchableContent.searchable);
      } else {
        return results.map((r) => r) as unknown as SelectableAsset[];
      }
    } catch (err) {
      this.honeybadger.notify(err as Error);
      return [];
    }
  }

  private combineResults(results: SelectableAsset[][]): SelectableAsset[] {
    const content: SelectableAsset[] = [];
    for (let index = 0; ; index++) {
      let hasContent = false;

      results.forEach((result) => {
        if (result[index]) {
          content.push(result[index]!);
          hasContent = true;
        }
      });

      if (!hasContent) {
        break;
      }
    }

    return content;
  }

  private get searchTypes(): SearchType[] {
    const types = this.args.type.values.map((contentType: ContentType) => {
      return BITEABLE_CONTENT.includes(contentType) ? SEARCHABLE_CONTENT : contentType;
    });

    return uniq(types);
  }

  private searchKey(searchType: SearchType): string {
    return searchType === SEARCHABLE_CONTENT ? "search" : "query";
  }

  private get totalCount(): number {
    return sum(Object.values(this.totalCounts));
  }

  get params(): ContentQueryParams {
    return {
      // eslint-disable-next-line camelcase
      per_page: DEFAULT_PER_PAGE_PER_TYPE * this.searchTypes.length,
      query: this.args.query,
      type: this.args.type
    };
  }

  private track(page: number | undefined, query: string | undefined): void {
    const trackingHelper = this.createTrackingHelper(query, page);

    if (page === 1) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_SEARCH_CONDUCTED, trackingHelper);
    } else {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_DISCOVER_LOAD_MORE, trackingHelper);
    }
  }

  private createTrackingHelper(query: string | undefined, page: number | undefined): ContentTrackingHelper {
    return new ContentTrackingHelper(
      {
        search: query,
        searchFrom: SEARCH_LOCATION.CONTENT_DISCOVERY,
        results: this.formatResults(),
        pageNumber: page
      },
      this.args.type.label
    );
  }

  private formatResults(): Record<string, number> {
    return {
      [CONTENT_TRACKING_TYPES.BITEABLE_CONTENT]: this.totalCounts[SEARCHABLE_CONTENT] ?? 0,
      [CONTENT_TRACKING_TYPES.PEXELS_IMAGE]: this.totalCounts[ContentType.PEXELS_PHOTO] ?? 0,
      [CONTENT_TRACKING_TYPES.PEXELS_VIDEO]: this.totalCounts[ContentType.PEXELS_VIDEO] ?? 0,
      [CONTENT_TRACKING_TYPES.STORYBLOCKS]: this.totalCounts[ContentType.STORYBLOCKS_VIDEO] ?? 0
    };
  }
}
