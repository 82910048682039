import type { ExecutionPhase } from "./mutation";
import { StrictMutation } from "./mutation";

export class SaveAction<T> extends StrictMutation<T> {
  grouped = true;
  undoable = true;
  executionPhase: ExecutionPhase = "save";

  constructor(private f: () => T) {
    super();
  }

  run(): T {
    return this.f();
  }

  revert(): T {
    return this.f();
  }
}

export class CoalescingSaveAction<T> extends SaveAction<T> {
  private args: any[];

  constructor(f: () => T, ...args: any[]) {
    super(f);
    this.args = args;
  }

  isSupercededBy(mutation: StrictMutation) {
    if (!(mutation instanceof CoalescingSaveAction) || this.args.length !== mutation.args.length) {
      return false;
    }

    for (const i of this.args) {
      if (this.args[i] !== mutation.args[i]) {
        return false;
      }
    }

    return true;
  }
}

export class CleanupAction<T> extends SaveAction<T> {
  constructor(f: () => T) {
    super(f);
    this.executionPhase = "cleanup";
  }
}
