import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Plan from "client/models/plan";

interface PlansTableFooterComponentArgs {
  intervalPlans: Plan[];
}

export default class PlansTableFooterComponent extends Component<PlansTableFooterComponentArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-table-footer");
}
