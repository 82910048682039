import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import type Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { iconSrc } from "client/authenticated/survey/data";
import type { VideoAssistantModel } from "client/authenticated/survey/video-assistant/route";
import removeObjectDuplicates from "client/lib/remove-object-duplicates";
import VideoAssistantPromptField from "client/lib/scripting/video-assistant-prompt-field";
import type VideoAssistantGenerator from "client/models/video-assistant-generator";
import type NotificationsService from "client/services/notifications";
import type StorageService from "client/services/storage";
import type VideoAssistantService from "client/services/video-assistant";

export interface DescriptionDetails {
  job: string;
  iconSrc?: string;
  videoAssistantFormFields?: VideoAssistantPromptField[];
}

interface VideoAssistantDescriptionModel {
  descriptionDetails: DescriptionDetails[] | undefined;
}

export default class VideoAssistantDescriptionRoute extends Route {
  @service
  declare videoAssistant: VideoAssistantService;

  @service
  declare router: RouterService;

  @service
  declare storage: StorageService;

  @service
  declare store: Store;

  @service
  declare notifications: NotificationsService;

  async beforeModel(transition: Transition): Promise<void> {
    const model = this.modelFor("authenticated.survey.video-assistant") as VideoAssistantModel;
    const { generatorId } = transition.to.queryParams;

    if (this.shouldSetUseCase(model, generatorId)) {
      this.videoAssistant.useCase = model.preselectedGenerator!.useCase;
    }

    if (this.shouldRedirectBack(model, generatorId)) {
      await this.transitionBack(transition);
    }
  }

  async model({ generatorId }: { generatorId: string }): Promise<VideoAssistantDescriptionModel> {
    const model = this.modelFor("authenticated.survey.video-assistant") as VideoAssistantModel;

    const generators = await this.findGenerators(model, generatorId);
    const descriptionDetails = await this.generateDescriptionDetails(generators);
    this.setInitialValues(generators, model.preselectedGenerator, generatorId);

    return { descriptionDetails };
  }

  async afterModel({ descriptionDetails }: VideoAssistantDescriptionModel, transition: Transition): Promise<void> {
    if (descriptionDetails?.length === 0) {
      this.notifications.error("Something went wrong, please choose another use case or contact support.");
      await this.transitionBack(transition);
    }
  }

  private shouldSetUseCase({ preselectedGenerator }: VideoAssistantModel, generatorId: string | undefined): boolean {
    return !!(generatorId && preselectedGenerator && this.videoAssistant.useCase !== preselectedGenerator.useCase);
  }

  private shouldRedirectBack({ preselectedGenerator }: VideoAssistantModel, generatorId: string | undefined): boolean {
    if (generatorId && preselectedGenerator) {
      return this.videoAssistant.useCase !== preselectedGenerator.useCase;
    }

    return !this.videoAssistant.useCase;
  }

  private async findGenerators(
    { videoAssistantGenerators, preselectedGenerator }: VideoAssistantModel,
    generatorId: string
  ): Promise<Array<VideoAssistantGenerator>> {
    const generators = [...videoAssistantGenerators.filter(({ useCase }) => useCase === this.videoAssistant.useCase)];

    if (generatorId && !!preselectedGenerator) {
      generators.push(preselectedGenerator);
    }

    return removeObjectDuplicates(generators, "job") as VideoAssistantGenerator[];
  }

  private async generateDescriptionDetails(generators: VideoAssistantGenerator[]): Promise<DescriptionDetails[]> {
    return Promise.all(
      generators
        .filter(({ job }) => job)
        .map(async (generator) => ({
          job: generator?.job,
          iconSrc: iconSrc(generator?.job),
          videoAssistantFormFields: await this.videoAssistantFormFields(generator)
        }))
    );
  }

  private setInitialValues(
    generators: VideoAssistantGenerator[],
    preselectedGenerator: VideoAssistantGenerator | undefined,
    generatorId: string
  ): void {
    if (generatorId && preselectedGenerator) {
      this.videoAssistant.job = preselectedGenerator.job;
    } else if (!this.videoAssistant.job) {
      this.videoAssistant.job = generators[0]?.job;
    }
  }

  async videoAssistantFormFields(
    generator: VideoAssistantGenerator | undefined
  ): Promise<VideoAssistantPromptField[] | undefined> {
    const inputs = this.cachedUserInputs(`${generator?.useCase}__${generator?.job}`);
    const videoAssistantGeneratorFields = await generator?.videoAssistantGeneratorFields;

    return videoAssistantGeneratorFields?.map(
      (field) => new VideoAssistantPromptField({ input: field, value: inputs[field.key] })
    );
  }

  private cachedUserInputs(generatorName: string): { [key: string]: string } {
    const item = this.storage.getItem(generatorName);
    return item ? JSON.parse(item) : {};
  }

  private async transitionBack(transition: Transition): Promise<void> {
    const { referer, generatorId } = transition.to.queryParams;

    await this.router.transitionTo("authenticated.survey.video-assistant.use-case", {
      queryParams: { referer: referer, generatorId: generatorId }
    });
  }

  queryParams = {
    generatorId: {
      refreshModel: true
    }
  };
}
