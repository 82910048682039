import Model, { attr } from "@ember-data/model";
import { isGoogleFont } from "renderer-engine";

interface BrandQueryImage {
  type: string;
  theme?: string;
  formats: Array<{
    src: string;
    background?: string;
    format: string;
    height: number;
    width: number;
    size: number;
  }>;
}

interface BrandImage {
  src: string;
  format: string;
}

interface BrandQueryFont {
  name: string;
  type: string;
  origin: string;
  originId?: string;
  weights: Array<string>;
}

export default class BrandQuery extends Model {
  @attr("string")
  declare name: string;

  @attr("string")
  declare domain: string;

  @attr("array")
  declare colors: Array<{
    hex: string;
    type: string;
    brightness: number;
  }>;

  @attr("array")
  declare fonts: Array<BrandQueryFont>;

  @attr("array")
  declare logos: Array<BrandQueryImage>;

  get colorHexValues(): string[] {
    return this.colors.map((color) => color.hex);
  }

  get logoImages(): BrandImage[] {
    const images: BrandImage[] = [];

    for (const logo of this.logos) {
      for (const { format, src } of logo.formats) {
        if (format !== "svg") {
          images.push({ format, src });
        }
      }
    }
    return images;
  }

  // Support one font only (body) at the current stage
  get mainFont(): BrandQueryFont | undefined {
    const fontsByType = new Map(this.fonts.map((font) => [font.type, font]));
    return fontsByType.get("body") ?? fontsByType.get("title");
  }

  get isMainFontGoogleFont(): boolean {
    return !!this.mainFont && isGoogleFont(this.mainFont.name);
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    brandQuery: BrandQuery;
  }
}
