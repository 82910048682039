import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Placement } from "@popperjs/core";
import Dexie from "dexie";
import { GOOGLE_FONT_FAMILIES } from "renderer-engine";
import config from "client/config/environment";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getFontFamilyPreviewUrl } from "client/lib/text/fonts";
import Font from "client/models/font";
import type { FontFamilyOption } from "client/models/font-family";
import type FontsService from "client/services/fonts";

const CUSTOM_FONTS_HIDDEN_TOKEN = `${config.localStoragePersistentPrefix}::custom_fonts_hidden`;

interface Args {
  fonts?: FontFamilyOption[];
  position?: Placement;
  values: string[];
  updateFont: (font: string) => void;
  disabled?: boolean;
  onClose?: () => void;
}

const VIEWABLE_FONT_COUNT = 30;
const RECENT_FONTS_DISPLAY_LIMIT = 5;

interface SelectedFontFamilyOption extends FontFamilyOption {
  id?: number;
  date: Date;
}

export default class FontFamilyDropdown extends Component<Args> {
  @service
  declare store: Store;

  @service
  declare fonts: FontsService;

  @tracked
  _hideCustomFonts = localStorage.getItem(CUSTOM_FONTS_HIDDEN_TOKEN) === String(true);

  get hideCustomFonts(): boolean {
    return this._hideCustomFonts;
  }

  set hideCustomFonts(hideCustomFonts: boolean) {
    this._hideCustomFonts = hideCustomFonts;

    if (hideCustomFonts) {
      localStorage.setItem(CUSTOM_FONTS_HIDDEN_TOKEN, String(true));
    } else {
      localStorage.removeItem(CUSTOM_FONTS_HIDDEN_TOKEN);
    }
  }

  @tracked
  fontsCollection: FontFamilyOption[] = this.args.fonts ?? this.fontFamilyOptions;

  @tracked
  filteredFonts: FontFamilyOption[] = this.fontsCollection;

  @tracked
  viewableFonts: FontFamilyOption[] = this.filteredFonts.slice(0, VIEWABLE_FONT_COUNT);

  @tracked
  recentFonts?: SelectedFontFamilyOption[] = [];

  declare selectedFonts: Dexie.Table<SelectedFontFamilyOption, number>;

  styleNamespace = getStyleNamespace("font-family-dropdown/popover");

  private intersectionObserver = new IntersectionObserver(
    ([entry]: IntersectionObserverEntry[]) => {
      if (entry?.isIntersecting) {
        this.loadMoreFonts();
      }
    },
    { threshold: [0] }
  );

  constructor(owner: object, args: Args) {
    super(owner, args);
    const db = new Dexie("biteable-app");

    db.version(1).stores({
      selectedFonts: `++id, name, imageUrl, date`
    });

    this.selectedFonts = db.table("selectedFonts");
  }

  @action
  toggleCustomFonts(ev: MouseEvent): void {
    ev.stopPropagation();
    this.hideCustomFonts = !this.hideCustomFonts;
  }

  @action
  async didInsert(): Promise<void> {
    await this.setRecentFonts();
  }

  @action
  registerListener(element: HTMLElement): void {
    this.intersectionObserver.observe(element);
  }

  @action
  unregisterListener(element: HTMLElement): void {
    this.intersectionObserver.unobserve(element);
  }

  @action
  setFontValue(): void {
    this.filteredFonts = this.fontsCollection;
  }

  @action
  loadImage(image: HTMLImageElement, src: string): void {
    const parentEl = image.parentElement;
    if (parentEl) {
      parentEl.classList.add("-loading");
      image.onload = (): void => parentEl.classList.remove("-loading");
    }
    image.src = src;
  }

  @action
  onClose(): void {
    this.args.onClose?.();
    this.fonts.clearSearchResults();
  }

  @action
  search(value: string): void {
    const regex = value.trim();
    this.fonts.search(regex);
    this.filteredFonts = this.getFilteredFonts(regex);
  }

  @action
  async selectFont(font: FontFamilyOption): Promise<void> {
    if (font instanceof Font) {
      await this.saveFontToRecentlyUsed({ name: font.name });
    } else {
      await this.saveFontToRecentlyUsed(font);
    }

    this.args.updateFont(font.name);
    this.filteredFonts = this.fontsCollection;
  }

  private getFilteredFonts(regEx: string): FontFamilyOption[] {
    const regex = new RegExp(`^${regEx}`, "i");
    return this.fontsCollection.filter((family: { name: string }) => {
      return regex.test(family.name);
    });
  }

  private loadMoreFonts(): void {
    void this.fonts.loadMoreFonts();
  }

  private fontIsPresent(font: SelectedFontFamilyOption): boolean {
    return (
      !!this.filteredFonts.find((filteredFont) => filteredFont.name === font.name) ||
      !!this.fonts.customFonts.find((customFont) => customFont.name === font.name)
    );
  }

  private get fontFamilyOptions(): FontFamilyOption[] {
    return GOOGLE_FONT_FAMILIES.map((family: string) => {
      return {
        name: family,
        imageUrl: getFontFamilyPreviewUrl(family)
      };
    });
  }

  private async saveFontToRecentlyUsed(font: FontFamilyOption): Promise<void> {
    const existing = this.selectedFonts.where("name").equals(font.name);

    if (await existing.count()) {
      void existing.modify({ date: new Date() });
    } else {
      void this.selectedFonts.add({
        ...font,
        date: new Date()
      });
    }
  }

  private async setRecentFonts(): Promise<void> {
    const recentFonts = await this.selectedFonts
      .orderBy("date")
      .reverse()
      .distinct()
      .limit(RECENT_FONTS_DISPLAY_LIMIT)
      .toArray();

    this.recentFonts = recentFonts.filter(this.fontIsPresent.bind(this));
  }
}
