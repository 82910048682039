import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NotificationsService from "client/services/notifications";

interface CopyLinkButtonArgs {
  videoPageUrl: string;
}
export default class CopyLinkButtonComponent extends Component<CopyLinkButtonArgs> {
  @service
  declare notifications: NotificationsService;

  styleNamespace = getStyleNamespace("asset-thumbnail/copy-link-button");

  @action
  notifyCopyLinkSuccess(): void {
    this.notifications.success("Link copied to clipboard");
  }
}
