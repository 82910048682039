import ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import type SelectableAsset from "client/models/selectable-asset";
import type NewVideoActionsService from "client/services/new-video-actions";
import type RecordProjectsService from "client/services/record-projects";

interface Args {
  onSelectPanel(panel: string): void;
  selectAsset(asset: SelectableAsset): void;
}

export default class RecordingsPanel extends Component<Args & ProjectContentBarPanelArgs> {
  @service
  declare recordProjects: RecordProjectsService;

  @service
  declare newVideoActions: NewVideoActionsService;

  get params(): Record<string, number> {
    return {
      per_page: 24 // eslint-disable-line camelcase
    };
  }

  @action
  async loadResults(params: Record<string, string | number>): Promise<Array<SelectableAsset>> {
    const results = await this.recordProjects.loadMyRecordingsWithAssets(
      params["page"] as number,
      params["per_page"] as number
    );
    const content = results.map(({ userAsset }) => userAsset);

    // @ts-expect-error
    return ArrayProxy.create({ content, meta: results.meta }) as ArrayProxy<SelectableAsset>;
  }

  @action
  async startRecording(): Promise<void> {
    return this.newVideoActions.createSelfRecordProject();
  }
}
