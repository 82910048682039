import Controller from "@ember/controller";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import type { ContentTypeOption } from "client/components/discovery/content/search/component";
import { ALL_CONTENTS } from "client/services/contents-curator";

export default class DiscoveryContentController extends Controller {
  @tracked
  query = "";

  @tracked
  type: ContentTypeOption = { label: "all", values: ALL_CONTENTS };

  @action
  onSelectType(type: ContentTypeOption): void {
    this.type = type;
  }

  @action
  updateQuery(query: string): void {
    this.query = query;
  }
}
