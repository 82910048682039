import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type UpgradeService from "client/services/upgrade";

interface SubscriptionScheduleArgs {
  subscriptionSchedule?: SubscriptionSchedule;
}

export default class LatestSubscriptionSubscriptionScheduleComponent extends Component<SubscriptionScheduleArgs> {
  @tracked
  loading = false;

  @service
  declare upgrade: UpgradeService;

  @action
  async cancelSubscriptionSchedule(): Promise<void> {
    try {
      this.loading = true;
      const subscriptionSchedule = this.args.subscriptionSchedule;

      if (subscriptionSchedule) {
        await this.upgrade.removeSubscriptionSchedule(subscriptionSchedule);
      }
    } finally {
      this.loading = false;
    }
  }
}
