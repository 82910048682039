import type { Canvas, RenderingContext2D, RenderZymbol } from "renderer-engine";
import {
  CanvasUtils,
  RenderScope,
  getZymbolsRequiredForFrame,
  isOffscreenCanvas,
  prepareAssets,
  renderProject,
  AssetSizes,
  AssetTypes,
  DownloadUtils,
  DEFAULT_FRAME_RATE
} from "renderer-engine";
import { Queue } from "client/lib/queue";
import secondsToFrames from "client/lib/seconds-to-frames";
import Project from "client/models/project";
import type ProjectPreview from "client/models/project-preview";

export interface ProjectThumbnailBuilderOptions {
  project: Project | ProjectPreview;
  canvas?: Canvas;
  zymbolsToRender?: RenderZymbol[];
  frameNumber?: number;
  type?: AssetTypes;
  mimeType?: string;
  perScenesAssets?: boolean;
  filterZymbols?: (zymbol: RenderZymbol) => boolean;
  zymbolModifier?: (zymbols: RenderZymbol[]) => RenderZymbol[];
  canvasDimensions?: [number, number];
}

const queue = new Queue();
const DEFAULT_FRAME_NUMBER = 65;

export class ProjectThumbnailBuilder {
  constructor(private options: ProjectThumbnailBuilderOptions) {}

  private canvas!: Canvas;

  async generate(): Promise<void> {
    await this.loadEmberModels();

    const frame = this.getFrame();
    const context = await this.getContext();
    const scope = new RenderScope("ProjectThumbnail", true);
    const zymbolsToRender = await this.getZymbolsToRenderForFrame(scope, frame);
    await prepareAssets(zymbolsToRender, frame, (prepare) => DownloadUtils.prepareAsset(scope, prepare));
    renderProject(scope, zymbolsToRender, frame, context, { disableTransitions: true });
  }

  private async loadEmberModels(): Promise<void> {
    if (this.options.zymbolsToRender) {
      return;
    }

    // calling loadZymbolsToRenderDependencies on multiple projects at the same time
    // was hanging ember data, so do them sequentially
    void queue.add(() => this.options.project.loadZymbolsToRenderDependencies());
    await queue.wait();
  }

  private getFrame(): number {
    const totalFrames = secondsToFrames(this.options.project.duration);
    return Math.max(Math.min(totalFrames - 1, this.options.frameNumber || DEFAULT_FRAME_NUMBER), 0);
  }

  async getBlob(): Promise<Blob | undefined> {
    const canvas = this.canvas;
    const mimeType = this.options.mimeType || "image/jpeg";

    if (isOffscreenCanvas(canvas)) {
      return await canvas.convertToBlob({ type: mimeType, quality: 0.92 });
    }

    return;
  }

  async getCanvasDataUrl(): Promise<string> {
    const canvas = this.canvas;
    const mimeType = this.options.mimeType || "image/jpeg";

    if (isOffscreenCanvas(canvas)) {
      const blob = await canvas.convertToBlob({ type: mimeType, quality: 0.92 });
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.addEventListener("load", () => resolve(reader.result as string));
        reader.addEventListener("error", (e) => reject(e));
        reader.readAsDataURL(blob);
      });
    }

    return canvas.toDataURL(mimeType);
  }

  async getImage(): Promise<HTMLImageElement> {
    const img = new Image();
    const dataUrl = await this.getCanvasDataUrl();
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = dataUrl;
    });

    return img;
  }

  private async getContext(): Promise<RenderingContext2D> {
    const { canvasDimensions, project } = this.options;
    const [width, height] = canvasDimensions ? canvasDimensions : (await project.aspectRatio).canvasDimensions;

    if (this.options.canvas) {
      this.canvas = this.options.canvas;
      this.canvas.width = width;
      this.canvas.height = height;
    } else {
      this.canvas = CanvasUtils.newCanvasContext({ width: width, height: height }).canvas;
    }

    // Cannot use @ts-expect-error below, at least not without some tsconfig or other setting changes, I have no idea
    // what settings they would be! Basically this errors as an error or the directive @ts-expect-error errors because
    // this does not error and both stop app build and it flaps between them so best to just flat out ignore
    // @ts-ignore
    return this.canvas.getContext("2d")!;
  }

  private getOriginalZymbolsToRenderForFrame(frame: number): RenderZymbol[] {
    if (this.options.zymbolsToRender) {
      return this.options.zymbolsToRender;
    } else {
      const [zymbolsToRender] = getZymbolsRequiredForFrame(this.options.project.zymbolsToRender, frame);
      return zymbolsToRender;
    }
  }

  private async getZymbolsToRenderForFrame(scope: RenderScope, frame: number): Promise<RenderZymbol[]> {
    const { type = AssetTypes.IMAGE, perScenesAssets = false, filterZymbols, zymbolModifier } = this.options;
    let zymbolsToRender: RenderZymbol[] = JSON.parse(JSON.stringify(this.getOriginalZymbolsToRenderForFrame(frame)));

    await Project.loadZymbolAssets(
      scope,
      zymbolsToRender,
      frame / DEFAULT_FRAME_RATE,
      AssetSizes.PREVIEW,
      type,
      perScenesAssets
    );

    if (filterZymbols) {
      zymbolsToRender = zymbolsToRender.filter(filterZymbols);
    }

    if (zymbolModifier) {
      zymbolsToRender = zymbolModifier(zymbolsToRender);
    }
    return zymbolsToRender;
  }
}
