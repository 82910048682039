import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

enum DisclosureToggleLayout {
  DEFAULT = "default",
  GRID = "grid"
}

interface DisclosureComponentArgs {
  disabled: boolean;
  onOpen?: () => void;
  open: boolean;
  toggleLayout: string;
}

export default class DisclosureComponent extends Component<DisclosureComponentArgs> {
  styleNamespace = getStyleNamespace("export-actions/disclosure");

  @tracked
  open: boolean;

  constructor(owner: object, args: DisclosureComponentArgs) {
    super(owner, args);

    this.open = args.open ?? false;
  }

  @action
  onClick(event: MouseEvent): void {
    event.preventDefault();

    if (this.args.disabled) {
      return;
    }

    if (!this.open) {
      this.args.onOpen?.();
    }

    this.toggleDisclosure();
  }

  toggleDisclosure({ open } = { open: !this.open }): void {
    this.open = open;
  }

  get disabled(): string {
    return String(this.args.disabled);
  }

  get expanded(): string {
    return String(this.open);
  }

  get toggleLayout(): string {
    return this.args.toggleLayout || DisclosureToggleLayout.DEFAULT;
  }
}
