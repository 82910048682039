import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type VideoPlayerService from "client/services/video-player";

interface VideoPlayerComponentArgs {
  posterUrl: string;
  videoUrl: string;
  captionsUrl?: string;
  captionsLanguage?: string;
}

export default class VideoPlayerComponent extends Component<VideoPlayerComponentArgs> {
  declare videoElement: HTMLVideoElement;

  @service
  declare videoPlayer: VideoPlayerService;

  @tracked
  duration = 0;

  @tracked
  isLoading = true;

  @tracked
  isMuted = false;

  @tracked
  volume = 1;

  styleNamespace = getStyleNamespace("north-star/video-player");

  @action
  didInsertVideo(videoElement: HTMLVideoElement): void {
    this.videoElement = videoElement;
    this.videoPlayer.player = this.videoElement;
  }

  @action
  reset(): void {
    this.videoPlayer.reset();
  }

  @action
  setCurrentTime({ target }: Event): void {
    const { valueAsNumber: currentTime } = target as HTMLInputElement;
    this.videoPlayer.setCurrentTime(currentTime);
  }

  @action
  setVolume({ target }: Event): void {
    const { valueAsNumber: volume } = target as HTMLInputElement;
    this.videoElement.volume = volume;
  }

  @action
  toggleMute(): void {
    if (this.isMuted) {
      this.videoElement.muted = false;
    } else {
      this.videoElement.muted = true;
    }

    this.isMuted = !this.isMuted;
  }

  @action
  async togglePlayback(): Promise<void> {
    void this.videoPlayer.togglePlayback();
  }

  @action
  updateCurrentTime({ target }: Event): void {
    const { currentTime } = target as HTMLVideoElement;
    this.videoPlayer.setCurrentTime(currentTime);
  }

  @action
  updateCurrentVolume({ target }: Event): void {
    const { volume } = target as HTMLVideoElement;

    this.volume = volume;
  }

  @action
  updateDuration({ target }: Event): void {
    const { currentTime, duration } = target as HTMLVideoElement;

    if (currentTime > duration) {
      this.videoPlayer.setCurrentTime(0);
    }

    this.duration = duration;
  }

  @action
  updateBuffering({ target }: Event): void {
    const { readyState } = target as HTMLVideoElement;

    if (readyState <= HTMLVideoElement.prototype.HAVE_CURRENT_DATA) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }

  @action
  async fullscreen(): Promise<void> {
    if (!document.fullscreenElement) {
      await this.videoElement.requestFullscreen();
    }
  }
}
