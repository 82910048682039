import { clamp } from "client/lib/quick-math";

export const DEFAULT_PIXELS_PER_SECOND = 40;
const MAX_PIXELS_PER_SECOND = 400;

export interface TimelineDimensionsArgs {
  /**
   * Timeline content duration in seconds.
   */
  duration: number;
  /**
   * The width of the timeline in pixels.
   */
  width: number;
  /**
   * Number of seconds to add to the end of the timeline.
   */
  padding?: number;
  /**
   * The minimum duration the timeline should be in seconds.
   */
  minDuration?: number;
  /**
   * The visual scale factor of the timeline in pixels per second.
   */
  pixelsPerSecond?: number;
}

export default class TimelineDimensions {
  private padding = 0;

  /**
   * Provides visual dimensions for rendering a timeline.
   */
  constructor(protected args: TimelineDimensionsArgs) {
    this.padding = args.padding || 0;
  }

  /**
   * Returns the length of a second in pixels for rendering purposes.
   */
  public get pixelsPerSecond(): number {
    if (this.args.pixelsPerSecond) {
      return this.clampPixelsPerSecond(this.args.pixelsPerSecond);
    }

    return this.clampPixelsPerSecond(this.args.width / this.duration);
  }

  /**
   * Returns the visual duration for rendering purposes.
   */
  public get duration(): number {
    if (this.args.minDuration) {
      return this.clampDuration(Math.max(this.args.duration, this.args.minDuration));
    }

    return this.clampDuration(this.args.duration);
  }

  private clampPixelsPerSecond(value?: number): number {
    if (!value) {
      return DEFAULT_PIXELS_PER_SECOND;
    }

    return clamp(value, 0, MAX_PIXELS_PER_SECOND);
  }

  private clampDuration(value: number): number {
    return clamp(value + this.padding, 0 + this.padding, Infinity);
  }
}
