import type { Caption, Scene } from "../../scene";
import { calculatePositions } from "./movable-caption";
import { TimelineMutation } from "./mutation";
import type { SceneState } from "./scene-state";
import { createState } from "./scene-state";

export class CaptionOffsetChangeMutation extends TimelineMutation {
  private scene: Scene;
  private originalState: SceneState;

  constructor(
    public grouped: boolean,
    public caption: Caption,
    private offset: number,
    private referenceState: SceneState,
    public snapAmount?: number
  ) {
    super();
    this.scene = this.caption.scene;
    this.originalState = createState(this.scene);
  }

  run(): void {
    const newSceneState = calculatePositions(this.referenceState, (movableCaptions) => {
      const caption = movableCaptions.find((c) => c.id === this.caption.id);
      return caption?.updateOffset(this.offset, this.referenceState.sceneDuration) || this.referenceState.sceneDuration;
    });

    Object.keys(newSceneState.captionsState)
      .map((id) => this.scene.captions.find((c) => c.id === id))
      .forEach((caption) => {
        if (caption && newSceneState.captionsState[caption.id]) {
          caption._setOffset(this.snap(newSceneState.captionsState[caption.id]!.offset));
          caption._setDuration(this.snap(newSceneState.captionsState[caption.id]!.duration));
        }
      });

    this.scene._setDuration(this.snap(newSceneState.sceneDuration));
  }

  revert(): void {
    this.scene._duration = this.originalState.sceneDuration;
    Object.keys(this.referenceState.captionsState)
      .map((id) => this.scene.captions.find((c) => c.id === id))
      .forEach((caption) => {
        if (caption && this.originalState.captionsState[caption.id]) {
          caption._offset = this.snap(this.originalState.captionsState[caption.id]!.offset);
          caption._duration = this.snap(this.originalState.captionsState[caption.id]!.duration);
        }
      });
  }
}
