import { action } from "@ember/object";
import type Owner from "@ember/owner";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

const STORAGE_KEY = "biteable:brandedToggles";
const defaultToggles = { cta: true, customColor: false, projectColor: false };

export default class CollapsibleService extends Service {
  @tracked
  ctaOpen: boolean;

  @tracked
  customColor: boolean;

  @tracked
  projectColor: boolean;

  @tracked
  private brandToggles;

  constructor(owner: Owner) {
    super(owner);

    this.brandToggles = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? JSON.stringify(defaultToggles));
    this.ctaOpen = this.brandToggles.cta;
    this.customColor = this.brandToggles.customColor;
    this.projectColor = this.brandToggles.projectColor;
  }

  @action
  toggleCta(): void {
    this.ctaOpen = !this.ctaOpen;
    this.brandToggles.cta = this.ctaOpen;
    this.saveSettings();
  }

  @action
  toggleCustom(): void {
    this.customColor = !this.customColor;
    this.brandToggles.customColor = this.customColor;
    this.saveSettings();
  }

  @action
  toggleProject(): void {
    this.projectColor = !this.projectColor;
    this.brandToggles.projectColor = this.projectColor;
    this.saveSettings();
  }

  private saveSettings(): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.brandToggles));
  }
}
