import { detectTextDirection, DEFAULT_FONT_WEIGHT, EMOJI_FONT_FAMILY, FALLBACK_FONT_FAMILY } from "renderer-engine";
import type { Text } from "client/lib/editor-domain-model";

export const DEFAULT_BACKGROUND_COLOR = "rgba(255,255,255,0.3)";

export const setEditorStyles = (container: HTMLElement, plain: boolean, text: Text): void => {
  const qlEditor = container.querySelector(".ql-editor") as HTMLElement;

  Object.assign<CSSStyleDeclaration, Partial<CSSStyleDeclaration>>(qlEditor.style, {
    fontSize: "1em",
    position: "relative",
    fontFamily: `Brown, ${FALLBACK_FONT_FAMILY}, ${EMOJI_FONT_FAMILY}`,
    fontWeight: DEFAULT_FONT_WEIGHT,
    lineHeight: "normal",
    outline: "none",
    tabSize: "4",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    overflow: "visible",
    direction: detectTextDirection(text.content),
    backgroundColor: DEFAULT_BACKGROUND_COLOR
  });
  if (!plain) {
    qlEditor.style.background = `repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      rgba(255,255,255, 0.25) 20px,
      rgba(255,255,255, 0.25) 50px
    )`;
  }
};
