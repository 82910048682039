import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Size, Bounds, Position } from "renderer-engine";
import { Shape } from "renderer-engine";
import type { Asset, Media } from "client/lib/editor-domain-model";
import { Image, VideoClip, Frame } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { getThumbnailVideoSrc } from "client/lib/timeline/element";

const MIN_SCALE = 10;
const MAX_SCALE = 200;

const percentToScaleDecimal = (percent: number): number => {
  const minmax = Math.max(Math.min(MAX_SCALE, percent), MIN_SCALE);
  const rounded = Math.round(minmax);

  return rounded / 100;
};

interface AdjustFrameComponentArgs {
  asset: Asset;
  media: Media;
  onCancel(): unknown;
  onSave(frame: Frame): unknown;
}

export default class AdjustFrameComponent extends Component<AdjustFrameComponentArgs> {
  @tracked
  frame: Frame;

  @tracked
  mediaElement?: HTMLImageElement | HTMLVideoElement;

  @tracked
  overlayMaskId = `${guidFor(this)}__OverlayMask`;

  @tracked
  frameElement?: HTMLElement;

  @tracked
  scaleWithPercent = this.getScaleWithPercent();

  styleNamespace = getStyleNamespace("adjust-frame");

  constructor(owner: object, args: AdjustFrameComponentArgs) {
    super(owner, args);

    const { shape = Shape.CIRCLE, color = "#FFFFFF", scale = 0.82, offsetX = 0, offsetY = 0 } = args.asset.frame ?? {};

    this.frame = new Frame(shape, color, scale, offsetX, offsetY);
  }

  get region(): Size {
    const imageRatio = this.mediaHeight / this.mediaWidth;
    const width = this.frame.scale * this.frameRatio * Math.min(1, 1 / imageRatio);
    const height = this.frame.scale * Math.min(1, imageRatio);

    return {
      width,
      height
    };
  }

  get frameRatio(): number {
    return this.frameHeight / this.frameWidth;
  }

  get showBackgroundControls(): boolean {
    return this.shape !== Shape.NONE;
  }

  get position(): Bounds {
    return this.frameToRegion();
  }

  get mediaWidth(): number {
    if (this.mediaElement instanceof HTMLImageElement) {
      return this.mediaElement?.naturalWidth;
    } else if (this.mediaElement instanceof HTMLVideoElement) {
      return this.mediaElement?.videoWidth;
    } else {
      return 1;
    }
  }

  get mediaHeight(): number {
    if (this.mediaElement instanceof HTMLImageElement) {
      return this.mediaElement?.naturalHeight;
    } else if (this.mediaElement instanceof HTMLVideoElement) {
      return this.mediaElement?.videoHeight;
    } else {
      return 0.5625;
    }
  }

  get frameWidth(): number {
    return this.frameElement?.clientWidth ?? 0;
  }

  get frameHeight(): number {
    return this.frameElement?.clientHeight ?? 0;
  }

  get maskSize(): number {
    if (this.shape === Shape.CIRCLE) {
      return this.frameHeight / 2;
    } else {
      return this.frameHeight;
    }
  }

  get maskX(): number {
    if (this.shape === Shape.CIRCLE) {
      return this.frameWidth / 2;
    } else {
      return this.frameWidth / 2 - this.maskSize / 2;
    }
  }

  get maskY(): number {
    if (this.shape === Shape.CIRCLE) {
      return this.maskSize;
    } else {
      return 0;
    }
  }

  get imageUrl(): string | undefined {
    const { asset } = this.args;

    if (asset instanceof Image) {
      return asset.previewUrl ?? asset.sourceUrl;
    }

    if (asset instanceof VideoClip) {
      return asset.previewImageUrl;
    }

    return undefined;
  }

  get videoSrc(): string | undefined {
    return getThumbnailVideoSrc(this.args.media);
  }

  get scale(): number {
    return Math.round(this.frame.scale * 100);
  }

  set scale(percent: number) {
    this.frame.scale = percentToScaleDecimal(percent);
    this.scaleWithPercent = this.getScaleWithPercent();
  }

  get frameStyles(): SafeString | undefined {
    if (this.frame.shape !== Shape.NONE) {
      return htmlSafe(`
        background-color: ${this.frame.color ?? "transparent"};
        clip-path: url(#${this.overlayMaskId});
      `);
    } else {
      return undefined;
    }
  }

  get shape(): string {
    return this.frame.shape;
  }

  get color(): string {
    return this.frame.color;
  }

  get minScale(): number {
    return MIN_SCALE;
  }

  get maxScale(): number {
    return MAX_SCALE;
  }

  private getScaleWithPercent(): string {
    return `${this.scale}%`;
  }

  @action
  didInsertFrame(element: HTMLElement): void {
    this.frameElement = element;
  }

  @action
  onMediaLoad({ target: media }: Event): void {
    if (media instanceof HTMLImageElement) {
      this.mediaElement = media;
    } else if (media instanceof HTMLVideoElement) {
      this.mediaElement = media;
    }
  }

  @action
  onScaleInput({ target }: Event): void {
    if (target instanceof HTMLInputElement) {
      target.value = target.value.replace(/[^0-9]+/g, "");
    }
  }

  @action
  onScaleFocus({ target }: Event): void {
    if (target instanceof HTMLInputElement) {
      target.select();
    }
  }

  @action
  onScaleKeydown(event: KeyboardEvent): void {
    if (event.target instanceof HTMLInputElement && event.key === "Enter") {
      event.target.blur();
      this.onScaleBlur(event);
    }
  }

  @action
  onScaleBlur({ target }: Event): void {
    if (target instanceof HTMLInputElement) {
      const scale = parseInt(target.value);

      if (isNaN(scale)) {
        // eslint-disable-next-line no-self-assign
        this.scale = this.scale;
      } else {
        this.scale = scale;
      }

      // Bugfix: without this, repepeatedly focusing and blurring the field wouldn't update
      // the input value on blur (ie. appending the percent)
      target.value = this.scaleWithPercent;
    }
  }

  @action
  move(region: Bounds): void {
    const { offsetX, offsetY } = this.regionToFrame(region);

    Object.assign(this.frame, { offsetX, offsetY });
  }

  @action
  setFrameShape(shape: Shape): void {
    this.frame.shape = shape;
  }

  @action
  updateFrameColor(color: string): void {
    this.frame.color = color;
  }

  @action
  onSave(): void {
    this.args.onSave(this.frame);
    this.args.onCancel();
  }

  private frameToRegion(): Bounds {
    const { width, height } = this.region;
    const { x, y } = this.getOriginOffset({ width, height });
    const { offsetX, offsetY } = this.frame;

    return {
      x: x + -(offsetX * width),
      y: y + -(offsetY * height),
      width,
      height
    };
  }

  private getOriginOffset({ width, height }: Size): Position {
    return {
      x: 0.5 - width / 2,
      y: 0.5 - height / 2
    };
  }

  private regionToFrame(region: Bounds): Pick<Frame, "offsetX" | "offsetY"> {
    const { x, y, width, height } = region;
    const originOffset = this.getOriginOffset(region);
    const offsetX = -((x - originOffset.x) / width);
    const offsetY = -((y - originOffset.y) / height);

    return { offsetX, offsetY };
  }
}
