import { action } from "@ember/object";
import { service } from "@ember/service";
import DynamicIslandActionComponent from "client/components/discovery/dashboard/dynamic-island/action/component";
import type UpgradeService from "client/services/upgrade";

export default class UpgradePlanSecondaryActionComponent extends DynamicIslandActionComponent {
  actionName = "upgrade plan";

  @service
  declare upgrade: UpgradeService;

  @action
  openUpgradeModal(): void {
    if (this.upgrade.canUpgrade) {
      this.onInteraction();
      void this.upgrade.open();
    }
  }
}
