import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type PendingService from "client/services/pending";

export default class ThePendingComponent extends Component {
  elementId = guidFor(this);

  @service
  declare pending: PendingService;

  @tracked
  isCompleted = false;

  styleNamespace = getStyleNamespace("the-pending");

  get isLoading(): boolean {
    return this.pending.isLoading;
  }

  @action
  onIsLoadingChanged(): void {
    this.isCompleted = !this.isLoading;
  }
}
