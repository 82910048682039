import Adapter from "@ember-data/adapter";
import RSVP from "rsvp"; // eslint-disable-line import/no-named-as-default-member
import config from "client/config/environment";
import type { PexelsVideoQueryPayload } from "client/serializers/pexels-video";

const API_URL = "https://api.pexels.com/videos/";

export interface PexelsVideosQueryParams {
  page?: number;
  per_page?: number;
  query?: string;
}

export default class PexelsVideoAdapter extends Adapter {
  host = API_URL;

  pathForType(): string {
    return "videos";
  }

  get headers(): {} {
    return {
      authorization: `${config.pexelsAccessKey}`,
      accept: "application/json"
    };
  }

  findRecord(_: any, __: any, id: string): RSVP.Promise<any> {
    return this.api(`videos/${id}`);
  }

  updateRecord(): any {
    // eslint-disable-next-line no-console
    console.error("Failed to call `updateRecord` on `PexelsVideo`. A `PexelsVideo` cannot be updated");
  }

  deleteRecord(): any {
    // eslint-disable-next-line no-console
    console.error("Failed to call `deleteRecord` on `PexelsVideo`. A `PexelsVideo` cannot be deleted");
  }

  api(path: string, params?: {}): RSVP.Promise<any> {
    const url = new URL(this.host + path);

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    return new RSVP.Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url.toString(), {
          method: "GET",
          mode: "cors",
          headers: this.headers
        });
        const data = await response.json();

        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  query(_: any, __: any, params: PexelsVideosQueryParams): RSVP.Promise<PexelsVideoQueryPayload> {
    if (params.query && params.query.trim()) {
      return this.api("/search", params);
    }

    delete params.query;

    return this.api("popular", params);
  }
}
