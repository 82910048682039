import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { CONTENT_LABELS } from "../search/component";
import type { ContentTypeOption } from "client/components/discovery/content/search/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type NewVideoActionsService from "client/services/new-video-actions";
import { ContentBarPanelType } from "client/services/project-content-bar";

interface ContentResultsArgs {
  query: string;
  type: ContentTypeOption;
}

export default class ContentResultsComponent extends Component<ContentResultsArgs> {
  styleNamespace = getStyleNamespace("discovery/content/results");

  @service
  declare newVideoActions: NewVideoActionsService;

  get querying(): boolean {
    return !!this.args.query.length;
  }

  @action
  async startFromScratch(): Promise<void> {
    await this.newVideoActions.startFromScratch(ContentBarPanelType.FAVORITES);
  }

  get labels(): string[] {
    return CONTENT_LABELS;
  }

  get label(): string {
    return this.args.type.label;
  }
}
