import { action } from "@ember/object";
import { service } from "@ember/service";
import DynamicIslandActionComponent from "client/components/discovery/dashboard/dynamic-island/action/component";
import TrackingEvents from "client/events";
import type SubscriptionsService from "client/services/subscriptions";
import type TrackingService from "client/services/tracking";
import type UpgradeService from "client/services/upgrade";

export default class DashboardSecondaryActionsTrialCountdown extends DynamicIslandActionComponent {
  @service
  declare subscriptions: SubscriptionsService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare tracking: TrackingService;

  actionName = "trial countdown";

  @action
  async onPurchase(): Promise<void> {
    this.onInteraction();

    void this.tracking.sendAnalytics(TrackingEvents.EVENT_START_TRIAL_PURCHASE, {
      ctaContext: "button - trial countdown - purchase"
    });
    void this.upgrade.open();
  }

  get daysTillTrialEnd(): number {
    return this.subscriptions.currentSubscription?.daysTillTrialEnd ?? 0;
  }

  get canManageSubscription(): boolean {
    return this.subscriptions.canManage;
  }
}
