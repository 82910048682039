import type Store from "@ember-data/store";
import { pluralize } from "ember-inflector";
import type AjaxService from "client/services/ajax";
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from "ember-data/types/registries/model";

export const toKebabCase = (str: string): string | undefined =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("-");

export async function restoreModel<K extends keyof ModelRegistry>(
  ajax: AjaxService,
  store: Store,
  modelName: K,
  id: string
): Promise<ModelRegistry[K]> {
  const kebab = toKebabCase(modelName.toString());
  const plural = pluralize(kebab!);

  const data = await ajax.api(`/${plural}/${id}/restore`, {
    method: "POST"
  });

  if (!data) {
    throw Error("Could not restore ${model.modelName}");
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  store.pushPayload(modelName, data);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const restored = await store.findRecord(modelName, data.data.id, { reload: true });

  if (!restored) {
    throw Error("Could not restore ${model.modelName}");
  }

  return restored;
}
