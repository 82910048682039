import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import type User from "client/models/user";
import type AuthService from "client/services/auth";
import type NotificationsService from "client/services/notifications";

export default class DeleteAccountController extends Controller<User> {
  @service
  declare auth: AuthService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare store: Store;

  @tracked
  loading = false;

  @action
  async deleteAccount(): Promise<void> {
    if (this.loading || !this.auth.canDestroyCurrentUser) {
      return;
    }

    this.loading = true;

    try {
      const userDestroy = this.store.createRecord("userDestroy", {});
      await userDestroy.save();
      this.notifications.success("An email with instructions and a delete link has been sent to your email address");
    } catch (err) {
      this.notifications.error("There was a problem deleting your account, please contact support");
    } finally {
      this.loading = false;
      this.cancel();
    }
  }

  @action
  cancel(): void {
    void this.router.transitionTo("authenticated.account.profile");
  }
}
