import Service, { service } from "@ember/service";
import type Store from "@ember-data/store";
import defer from "client/lib/defer";
import type Category from "client/models/category";

type CategoriesArray = Category[];

export default class TemplateCategoriesService extends Service {
  @service
  private declare store: Store;

  private categoriesLoaded = defer();

  private categories: CategoriesArray = [];

  async featuredCategorySlugs(): Promise<string[]> {
    return (await this.nonEmptyCategories()).filter((category) => category.featured).map((category) => category.slug);
  }

  async nonEmptyCategories(): Promise<CategoriesArray> {
    await this.categoriesLoaded;

    return this.categories.filter((category) => category.templateCount > 0);
  }

  async load(): Promise<void> {
    this.categories = await this.loadCategories();
    this.categoriesLoaded.resolve(undefined);
  }

  private async loadCategories(): Promise<CategoriesArray> {
    try {
      const categories = await this.store.query("category", {
        /* eslint-disable camelcase */
        per_page: 99
        /* eslint-enable camelcase */
      });
      // @ts-expect-error
      return categories;
    } catch (error) {
      return [];
    }
  }
}
