import { SimpleRumTimer } from "client/lib/rum-timer";

/**
 * Marks a method as a RUM action.
 *
 * RUM actions send a custom RUM (Real User Monitoring) action to Datadog when called.
 *
 * Go to {@link https://app.datadoghq.com/rum/explorer | Real User Monitoring} and add these facets to use them in dashboards:
 *
 *   - `@context.metrics.${name}.duration` (measure, double, second (s))
 *   - `@context.metrics.${name}.status` (facet, string)
 *
 * See {@link https://docs.datadoghq.com/real_user_monitoring/guide/send-rum-custom-actions/ | Send RUM Custom Actions} for more details.
 *
 * @param name - The name of the RUM action
 * @returns The property descriptor
 */
export function rumAction(name: string) {
  return function <T>(
    _target: Object,
    _propertyKey: string,
    descriptor: TypedPropertyDescriptor<(this: Object, ...args: any[]) => Promise<T>>
  ): PropertyDescriptor {
    const originalMethod = descriptor.value!;
    descriptor.value = async function (this: Object, ...args: any[]): Promise<T> {
      const timer = new SimpleRumTimer(name);
      try {
        const result = await originalMethod.apply(this, args);
        timer.meta["status"] = "succeeded";
        return result;
      } catch (err) {
        timer.meta["status"] = "failed";
        throw err;
      } finally {
        timer.sample();
      }
    };
    return descriptor;
  };
}
