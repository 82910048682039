import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { ROOT_FOLDER_ID } from "client/adapters/folder-content";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Folder from "client/models/folder";
import { ContributorTypes } from "client/models/folder";
import type FolderContent from "client/models/folder-content";
import type AuthService from "client/services/auth";
import type CollaborationService from "client/services/collaboration";
import type FoldersService from "client/services/folders";
import { AUTO_TOGGLE_DRAGGED_OVER_DELAY, FoldersEvents } from "client/services/folders";
import type PermissionsService from "client/services/permissions";
import type TeamService from "client/services/team";

interface FolderLibraryArgs {
  folders: Folder[];
  type: ContributorTypes;
  activeFolder: Folder;
  onAddFolder: (parent?: Folder) => void;
  onDeleteFolder: (folder: Folder) => void;
  refresh: () => void;
  title?: string;
  compact?: boolean;
  parentRouteName: string;
  activeType: ContributorTypes;
}

export default class FolderLibraryComponent extends Component<FolderLibraryArgs> {
  elementId = guidFor(this);

  @service
  declare collaboration: CollaborationService;

  @service
  declare team: TeamService;

  @service
  declare store: Store;

  @service
  declare folders: FoldersService;

  @service
  declare auth: AuthService;

  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  // Loads a third-party API panel-actions from ember-collapsible-panel
  @service
  declare panelActions: any;

  @tracked
  hoveringOverTargetFolder = false;

  @tracked
  interval?: ReturnType<typeof setInterval>;

  @tracked
  compact = this.args.compact ?? false;

  styleNamespace = getStyleNamespace("folders/library");

  constructor(owner: object, args: FolderLibraryArgs) {
    super(owner, args);
    this.folders.subscribe(FoldersEvents.ACTIVE_FOLDER_CHANGED, this.onActiveFolderChanged);
  }

  willDestroy(): void {
    super.willDestroy();
    this.folders.unsubscribe(FoldersEvents.ACTIVE_FOLDER_CHANGED, this.onActiveFolderChanged);
  }

  @action
  async draggedOver(): Promise<void> {
    if (!this.panelActions._panelFor(this.panelName).isOpen) {
      this.interval = setInterval(() => this.expandPanel(), AUTO_TOGGLE_DRAGGED_OVER_DELAY);
    }
    this.hoveringOverTargetFolder = true;
  }

  @action
  async draggedOut(): Promise<void> {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    this.hoveringOverTargetFolder = false;
    await this.args.refresh?.();
  }

  @action
  async draggedOnto(content: FolderContent): Promise<void> {
    await this.folders.moveContent(
      { id: ROOT_FOLDER_ID },
      content,
      this.args.type,
      this.title,
      this.args.parentRouteName
    );
    await this.args.refresh?.();
  }

  @action
  onActiveFolderChanged(): void {
    const { activeFolder } = this.folders;

    if (activeFolder?.contributable === this.args.type) {
      this.expandPanel();
    }
  }

  @action
  addFolder(): void {
    this.args.onAddFolder();
    this.expandPanel();
  }

  @action
  expandPanel(): void {
    this.panelActions.open(this.panelName);
  }

  @action
  toggleExpandButton(event: Event): void {
    if (!this.canAddFolder) {
      return;
    }

    if (event.target instanceof Element && event.target.className === `${this.styleNamespace}__Action`) {
      event.preventDefault();
      event.stopPropagation();
      this.panelActions.toggle(this.panelName);
    }
  }

  get isActiveRoute(): boolean {
    return this.args.activeType === this.args.type;
  }

  get routeName(): string {
    return this.args.parentRouteName + ".library";
  }

  get panelName(): string {
    return `${this.args.type}-panel`;
  }

  get title(): string {
    if (this.args.title) {
      return this.args.title;
    }
    switch (this.args.type) {
      case ContributorTypes.TEAM:
        return "Team";
      case ContributorTypes.USER:
        return "Personal";
    }
  }

  get tooltipTitle(): string {
    if (this.args.type === ContributorTypes.USER) {
      return "New personal folder";
    } else if (this.args.type === ContributorTypes.TEAM) {
      if (!this.canAddFolder) {
        return "Invite people to create a team folder";
      }

      return "New team folder";
    } else {
      return "";
    }
  }

  get activeFolder(): Folder | undefined {
    return this.folders.activeFolder;
  }

  private get canAddFolder(): boolean {
    if (this.args.type === ContributorTypes.TEAM) {
      return this.team.hasMultipleMembers;
    } else {
      return true;
    }
  }

  get showInvitePeople(): boolean {
    return !this.canAddFolder && this.team.canInviteTeamMembers;
  }

  @action
  invitePeople(): void {
    this.collaboration.showTeamInviteModal("projects - team folder - invite people");
  }
}
