import { getOwner } from "@ember/application";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { ProjectRouteModel } from "./route";
import TrackingEvents from "client/events";
import type { Caption, Scene } from "client/lib/editor-domain-model";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import { transaction } from "client/lib/transaction";
import type SelectableAsset from "client/models/selectable-asset";
import type AdvancedEditorService from "client/services/advanced-editor";
import type HoneybadgerService from "client/services/honeybadger";
import type MobileService from "client/services/mobile";
import type NotificationsService from "client/services/notifications";
import type PermissionsService from "client/services/permissions";
import type ProjectContentBarService from "client/services/project-content-bar";
import type { CBSelectAssetTrackingProperties } from "client/services/project-content-bar";
import type SoundtrackService from "client/services/soundtrack";
import type TrackingService from "client/services/tracking";
import type ZymbolGroupsService from "client/services/zymbol-groups";

export default class ProjectController extends Controller<ProjectRouteModel> {
  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare notifications: NotificationsService;

  @service
  declare tracking: TrackingService;

  @service
  declare soundtrack: SoundtrackService;

  @service
  declare permissions: PermissionsService;

  @service
  declare router: RouterService;

  @service
  private declare zymbolGroups: ZymbolGroupsService;

  @service
  private declare honeybadger: HoneybadgerService;

  @service
  declare mobile: MobileService;

  @action
  async hideSharedProjectDialog(): Promise<void> {
    await this.trackOpeningSharedProject();

    this.showSharedProjectDialog = false;
  }

  @tracked
  showSharedProjectDialog = false;

  @tracked
  isExternalNavigation = false;

  @tracked
  _hideTimeline = false;

  @action
  @transaction
  async onAssetSelected(asset?: SelectableAsset, trackProperties?: CBSelectAssetTrackingProperties): Promise<void> {
    const { timeline, eventRegister } = this.model;

    const modifier = new SceneAssetModifier(
      getOwner(this),
      timeline,
      eventRegister,
      undefined,
      this.projectContentBar.afterScene,
      undefined,
      this.projectContentBar.addMediaToNewScene,
      () => {
        // By not passing a close function, the content bar will stay open on add new scene.
      }
    );

    await this.projectContentBar.onAssetSelected(modifier, asset, trackProperties);
  }

  get showContentBar(): boolean {
    return this.router.currentRouteName.startsWith("authenticated.project.scene" + ".");
  }

  get showMobileWarning(): boolean {
    return this.mobile.isMobile && !this.mobile.isEditorMobileWarningDismissed;
  }

  get contentBarExpanded(): boolean {
    return this.showContentBar && this.projectContentBar.expanded;
  }

  get collapsedTimeline(): boolean {
    return this.advancedEditor.collapsed;
  }

  get hideTimeline(): boolean {
    return this._hideTimeline;
  }

  set hideTimeline(hide: boolean) {
    this._hideTimeline = hide;
  }

  get selectedScene(): Scene | undefined {
    return this.advancedEditor.scene;
  }

  get selectedCaption(): Caption | undefined {
    const { caption, element } = this.advancedEditor;

    if (caption && element && !caption.elements.includes(element)) {
      return;
    } else {
      return caption;
    }
  }

  async trackOpeningSharedProject(): Promise<void> {
    const {
      model: { project }
    } = this;

    const projectTeam = await project.team;

    if (projectTeam) {
      /* eslint-disable camelcase */
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEAM_VIEW_PROJECT, {
        projectId: project.id,
        team_id: projectTeam.id,
        team_owner_id: projectTeam.ownerId,
        team_size: projectTeam.memberCount
      });
      /* eslint-enable camelcase */
    }
  }
}
