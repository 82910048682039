import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import { MaxMessageLength } from "client/models/comment";
import type Project from "client/models/project";
import type AuthService from "client/services/auth";
import type CommentService from "client/services/comment";
import type VideoPlayerService from "client/services/video-player";

interface ExportCommentsReplyArgs {
  hasTimestamp?: boolean;
  parentCommentId?: string;
  placeholder?: string;
  project: Project;
  onSubmit?: () => void;
  renderRequired: boolean;
  isRendering: boolean;
}

export default class ExportCommentsReplyComponent extends Component<ExportCommentsReplyArgs> {
  readonly maxMessageLength = MaxMessageLength;

  @service
  declare auth: AuthService;

  @service
  declare comment: CommentService;

  @service
  declare store: Store;

  @service
  declare videoPlayer: VideoPlayerService;

  @tracked
  declare currentTime: number;

  @tracked
  declare message: string;

  @tracked
  showMaxLengthTooltip = false;

  styleNamespace = getStyleNamespace("export-comments/reply");

  get hasTimestamp(): boolean {
    return this.args.hasTimestamp ?? true;
  }

  get placeholder(): string {
    const defaultPlaceholder = this.args.renderRequired
      ? "Update the video to leave a new comment"
      : "Leave a comment here";
    return this.args.placeholder ?? defaultPlaceholder;
  }

  get isInputDisabled(): boolean {
    return this.args.renderRequired || this.args.isRendering;
  }

  private async reset(): Promise<void> {
    this.message = "";
    this.showMaxLengthTooltip = false;
    this.currentTime = 0;
    await this.videoPlayer.autoResume();
  }

  @action
  didUpdateCurrentTime(): void {
    if (this.message) {
      this.currentTime = this.videoPlayer.currentTime;
    }
  }

  @action
  async onBlurInput(): Promise<void> {
    if (!this.message) {
      await this.reset();
    }
  }

  @action
  onClickInput(): void {
    this.currentTime = this.videoPlayer.currentTime;
    this.videoPlayer.autoPause();
  }

  @action
  onKeyUpInput(): void {
    if (this.message) {
      this.showMaxLengthTooltip = this.message.length >= this.maxMessageLength;
    }
  }

  @action
  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
    this.args.onSubmit?.();
    const { parentCommentId } = this.args;
    const data = {
      createdAt: Date.now(),
      email: this.auth.currentUser?.email,
      message: this.message,
      parentCommentId,
      projectId: this.args.project.id,
      timestamp: parentCommentId ? undefined : Math.round(this.videoPlayer.currentTime)
    };

    const comment = this.store.createRecord("comment", data);
    await comment.save();
    await this.comment.updateAll(this.args.project.id);

    await this.reset();
  }
}
