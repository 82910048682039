import { action } from "@ember/object";
import { guidFor } from "@ember/object/internals";
import { later } from "@ember/runloop";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { EventKeys } from "client/lib/event-keys";
import getStyleNamespace from "client/lib/get-style-namespace";

/**
 * @deprecated use ModalSize
 */
export type ModalSizes = ModalSize;

export enum ModalSize {
  X_SMALL = "x-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  X_LARGE = "x-large",
  XX_LARGE = "xx-large"
}

export enum ModalOrder {
  DEFAULT = "default",
  TOP = "top"
}

interface ModalComponentArgs {
  confirmDisabled?: boolean;
  blurBackdrop?: boolean;
  hideButtons: boolean;
  dismissible?: boolean;
  noScroll?: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  size?: string;
  order?: ModalOrder;
  noMargin?: boolean;
  header?: string;

  onCancel?(): unknown;
  onConfirm?(): unknown;
}

export default class ModalComponent extends Component<ModalComponentArgs> {
  modalId = `${guidFor(this)}__InnerModalId`;
  styleNamespace = getStyleNamespace("north-star/modal");

  noScroll = this.args.noScroll ?? false;

  @tracked
  opening = false;

  @tracked
  closing = false;

  clickTargeted?: HTMLElement;

  @action
  didInsert(): void {
    this.onDocumentKeyup = this.onDocumentKeyup.bind(this);
    document.body.addEventListener("keyup", this.onDocumentKeyup);
    document.body.addEventListener("mousedown", this.onMouseDown, false);
    document.body.addEventListener("mouseup", this.onMouseUp, false);
    this.opening = true;
  }

  @action
  willDestroy(): void {
    super.willDestroy();
    document.body.removeEventListener("keyup", this.onDocumentKeyup);
    document.body.removeEventListener("mousedown", this.onMouseDown);
    document.body.removeEventListener("mouseup", this.onMouseUp);
  }

  @action
  confirm(): void {
    this.args.onConfirm && this.args.onConfirm();
    this.close();
  }

  @action
  close(): void {
    if (!this.dismissible) {
      return;
    }

    if (this.closing) {
      return;
    }

    this.closing = true;
    later(() => this.args.onCancel?.(), 200);
  }

  @action
  onMouseDown({ target }: MouseEvent): void {
    this.clickTargeted = target as HTMLElement;
  }

  @action
  onMouseUp(): void {
    const inner = document.getElementById(this.modalId);
    if (this.clickTargeted === inner) {
      this.close();
    }
  }

  private onDocumentKeyup({ code }: KeyboardEvent): void {
    if (code === EventKeys.ESC) {
      this.close();
    }
  }

  get dismissible(): boolean {
    return this.args.dismissible ?? true;
  }

  get cancelButtonLabel(): string {
    return this.args.cancelButtonLabel || "Cancel";
  }

  get confirmButtonLabel(): string {
    return this.args.confirmButtonLabel || "Confirm";
  }

  get className(): string {
    const opening = this.opening ? "--opening" : "";
    const closing = this.closing ? "--closing" : "";
    const blur = this.args.blurBackdrop ? "--blur" : "";

    return `${this.styleNamespace} ${blur} ${opening} ${closing}`;
  }

  get size(): string {
    return this.args.size ?? ModalSize.SMALL;
  }

  get showButtons(): boolean {
    const { onConfirm, onCancel, hideButtons } = this.args;
    return !!onConfirm && !!onCancel && !hideButtons;
  }
}
