import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import DynamicIslandActionComponent from "../../dynamic-island/action/component";
import getColumnCount from "client/lib/get-column-count";
import getStyleNamespace from "client/lib/get-style-namespace";
import { WIDE_ASPECT_RATIO_ID } from "client/models/aspect-ratio";
import Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";
import type BrandStyleService from "client/services/brand-style";

export default class PublishedScenesComponent extends DynamicIslandActionComponent {
  @service
  declare store: Store;

  @service
  declare router: RouterService;

  @service
  declare brandStyle: BrandStyleService;

  @tracked
  projectScenes?: Array<ProjectScene>;

  @tracked
  loading = false;

  @tracked
  adding = false;

  @tracked
  columnCount = 0;

  actionName = "fresh from the studio";

  styleNamespace = getStyleNamespace("discovery/dashboard/primary-actions/published-scenes");

  get visiblePublishedScenes(): ProjectScene[] | undefined {
    return this.projectScenes?.slice(0, this.columnCount);
  }

  @action
  async didInsert(): Promise<void> {
    super.didInsert();

    try {
      this.loading = true;

      await Promise.all([
        this.loadRecentlyPublishedScenes(),
        // Have to load the brand into the store to allow the brandable scenes to be branded
        this.loadBrand()
      ]);
    } finally {
      this.loading = false;
    }
  }

  @action
  didInsertGrid(element: HTMLElement): void {
    new ResizeObserver(() => requestAnimationFrame(() => this.calculateColumnCount(element))).observe(element);

    void this.calculateColumnCount(element);
  }

  @action
  async onSelectScene(projectScene: ProjectScene): Promise<void> {
    this.onInteraction();

    this.adding = true;

    try {
      const project = await this.createProject(projectScene.aspectRatioSlug);

      await this.router.transitionTo("authenticated.project.import-scene", project.id, projectScene.id);
    } finally {
      this.loading = false;
    }
  }

  private async createProject(aspectRatioSlug: string): Promise<Project> {
    const aspectRatio = await this.store.findRecord("aspectRatio", aspectRatioSlug);

    return Project.createWithDefaults(this.store, {
      aspectRatio
    }).save();
  }

  private calculateColumnCount(element: HTMLElement): void {
    this.columnCount = getColumnCount(element);
  }

  private async loadBrand(): Promise<void> {
    this.store.peekAll("brandStyle").length || (await this.brandStyle.getBrand());
  }

  private async loadRecentlyPublishedScenes(): Promise<void> {
    // @ts-expect-error
    this.projectScenes = (await this.store.query("projectScene", {
      /* eslint-disable camelcase */
      per_page: 12,
      published: true,
      order: "published_at:desc",
      aspect_ratio: WIDE_ASPECT_RATIO_ID
      /* eslint-enable camelcase */
    })) as ProjectScene[];
  }
}
