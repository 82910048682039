import LoadingController from "client/controllers/loading";

const PER_PAGE = 24;

export const CONTROLLER_QUERY_PARAMS = ["per_page", "page"];

export default abstract class PaginatedController<M = any> extends LoadingController<M> {
  queryParams = CONTROLLER_QUERY_PARAMS;

  page = 1;

  // eslint-disable-next-line camelcase
  per_page: number = PER_PAGE;
}
