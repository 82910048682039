import Controller from "@ember/controller";
import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import type { ProjectScenesRouteModel } from "./route";
import type ProjectSceneStackCategory from "client/models/project-scene-stack-category";
import type AuthService from "client/services/auth";
import type ProjectScenesRouteAssetsUploadService from "client/services/project-scenes-route-assets-upload";

export default class ProjectScenesController extends Controller<ProjectScenesRouteModel> {
  queryParams = ["scene", "afterScene"];

  @tracked
  scene?: string;

  @tracked
  afterScene?: string;

  @service
  declare router: RouterService;

  @service
  declare auth: AuthService;

  @service
  declare projectScenesRouteAssetsUpload: ProjectScenesRouteAssetsUploadService;

  @tracked
  isFilePickerOpen = false;

  @tracked
  uploadMenuOpen = false;

  @tracked
  uploadMenuAnchor?: HTMLElement;

  public resetTransientState(): void {
    this.scene = undefined;
    this.afterScene = undefined;
  }

  @action
  toggleUploadMenu(): void {
    this.uploadMenuOpen = !this.uploadMenuOpen;
  }

  @action
  setUploadMenuAnchor(element?: HTMLElement): void {
    if (element) {
      this.uploadMenuAnchor = element;
    }
  }

  @action
  closeUploadMenu(): void {
    this.uploadMenuOpen = false;
  }

  @action
  async onUpload(assetOwnerType = "user"): Promise<void> {
    await this.projectScenesRouteAssetsUpload.onUpload(this.model, assetOwnerType);
  }

  get hasTeam(): boolean {
    return this.auth.hasTeam;
  }

  get categories(): ProjectSceneStackCategory[] {
    return this.model.categories;
  }

  get addingScene(): boolean {
    return !this.model.scene;
  }

  get currentRouteName(): string {
    return this.router.currentRouteName;
  }
}
