import type Controller from "@ember/controller";
import Route from "@ember/routing/route";
import type Transition from "@ember/routing/transition";
import { AudioClipCategory } from "renderer-engine";
import type { ProjectSceneRouteModel } from "client/authenticated/project/scene/route";
import type AudioController from "client/controllers/audio";
import type { AudioClip, EventRegister, Timeline } from "client/lib/editor-domain-model";
import type Project from "client/models/project";
import type ProjectScene from "client/models/project-scene";

export interface AudioRouteModel {
  project: Project;
  projectScene: ProjectScene;
  timeline: Timeline;
  eventRegister: EventRegister;
  audioClip?: AudioClip;
}

export default abstract class AudioRoute<T extends AudioRouteModel> extends Route<T> {
  category = AudioClipCategory.MUSIC;

  get parentRouteName(): string {
    const parts = this.routeName.split(".");
    parts.pop();
    return parts.join(".");
  }

  async model(): Promise<T> {
    const { timeline, eventRegister, project, projectScene } = this.modelFor(
      "authenticated.project.scene"
    ) as ProjectSceneRouteModel;

    return {
      project,
      projectScene,
      eventRegister,
      timeline
    } as T;
  }

  setupController(controller: Controller, model: T, transition: Transition): void {
    super.setupController(controller, model, transition);

    (controller as AudioController<T>).set("category", this.category);
  }
}
