import { getOwner } from "@ember/application";
import { action } from "@ember/object";
import type Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import { tracked } from "@glimmer/tracking";
import { ROOT_FOLDER_ID } from "client/adapters/folder-content";
import TrackingEvents from "client/events";
import { FolderMessages } from "client/lib/data/folder-messages";
import { getFolderContainableFromDragContent } from "client/lib/folders";
import Folder, { ContributorTypes, FolderCategoryTypes } from "client/models/folder";
import type FolderContainable from "client/models/folder-containable";
import { FolderContainableTypes } from "client/models/folder-containable";
import type FolderContent from "client/models/folder-content";
import Project from "client/models/project";
import ProjectPreview from "client/models/project-preview";
import type AjaxService from "client/services/ajax";
import BaseEventService from "client/services/base-events";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export const DEFAULT_FOLDER_NAME = "Untitled";
export const AUTO_TOGGLE_DRAGGED_OVER_DELAY = 750;

export enum FolderRouteSuffix {
  LIBRARY = "library",
  FOLDER = "folder"
}

type FolderOptions = {
  type?: ContributorTypes;
  name?: string;
  parent?: Folder;
  category?: FolderCategoryTypes;
};

export enum FoldersEvents {
  ACTIVE_FOLDER_CHANGED = "ACTIVE_FOLDER_CHANGED"
}

export interface Breadcrumb {
  label: string;
  id: string;
}

export const DEFAULT_FILTER_ORDER = "created_at:desc";

export default class FoldersService extends BaseEventService {
  eventLogPrefix = "FOLDERS";

  publish(eventName: FoldersEvents, payload?: any): void {
    super.publish(eventName, payload);
  }

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare store: Store;

  @service
  declare ajax: AjaxService;

  @service
  declare notifications: NotificationsService;

  @service
  declare router: RouterService;

  @service
  declare tracking: TrackingService;

  @tracked
  activeFolder?: Folder;

  _filterOrder: string = DEFAULT_FILTER_ORDER;

  set filterOrder(order: string) {
    if (!order || this.filterOrder === order) {
      return;
    }

    this._filterOrder = order;
  }

  get filterOrder(): string {
    return this._filterOrder;
  }

  isRootFolder(folder: Folder): boolean {
    return !folder.get("id") || folder.get("id") === ROOT_FOLDER_ID;
  }

  getRootName = (folder: Folder): string => {
    switch (folder.contributorType) {
      case ContributorTypes.TEAM:
        return "Team";
      case ContributorTypes.USER:
        return "Personal";
    }
  };

  getRoute(parentRoute: string, folder: Folder): string {
    return this.isRootFolder(folder)
      ? parentRoute + "." + FolderRouteSuffix.LIBRARY
      : parentRoute + "." + FolderRouteSuffix.FOLDER;
  }

  getPath(folder: Folder): Array<Folder> {
    const folders: Array<Folder> = [];
    let _folder = folder;

    while (_folder) {
      folders.push(_folder);
      _folder = _folder.get("parent");
    }

    return folders.reverse();
  }

  @action
  setActiveFolder(folder?: Folder): void {
    if (this.activeFolder !== folder) {
      this.activeFolder = folder;
      this.publish(FoldersEvents.ACTIVE_FOLDER_CHANGED);
    } else {
      this.activeFolder = undefined;
    }
  }

  public async create({
    name = DEFAULT_FOLDER_NAME,
    type = ContributorTypes.USER,
    parent,
    category = FolderCategoryTypes.PROJECTS
  }: FolderOptions): Promise<[folder: Folder, type: ContributorTypes]> {
    const contributable = parent?.contributable ?? type;

    const folder = await this.store
      .createRecord("folder", {
        parent,
        name,
        contributable,
        category
      })
      .save();

    return [folder, contributable as ContributorTypes];
  }

  public async update(folder: Folder): Promise<void> {
    try {
      await folder.save();
      await this.reloadContent();
    } catch (err) {
      this.notifications.error(FolderMessages.FOLDER_UPDATE_FAILURE);
      throw err;
    }

    return;
  }

  public async delete(folder: Folder): Promise<void> {
    const { contributable } = folder;
    await folder.destroyRecord({
      adapterOptions: {
        data: {
          contributable
        }
      }
    });
  }

  public async addContent(
    folder: Folder | { id: string },
    containable: FolderContainable,
    type: ContributorTypes
  ): Promise<FolderContent | boolean> {
    const isRootFolder = folder.id === ROOT_FOLDER_ID;
    const { containableType, containableId } = containable;

    if (containableType === FolderContainableTypes.FOLDER && containableId === folder.id) {
      // Disallow adding a folder to itself
      return false;
    }

    const content = this.store.createRecord("folderContent");
    content.setProperties({
      containableType,
      containableId
    });

    content.contributorType = type;

    if (!isRootFolder && folder instanceof Folder) {
      content.set("folder", folder);
    }

    if (isRootFolder) {
      const data = content.serialize();

      await this.ajax.api(
        `/folder-contents/${ROOT_FOLDER_ID}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify(data)
        },
        "text"
      );
      return true;
    }

    return await content.save();
  }

  public async reloadContent(): Promise<void> {
    await (getOwner(this).lookup("route:authenticated.folders.folder") as Route).refresh();
    await (getOwner(this).lookup("route:authenticated.folders.library") as Route).refresh();
  }

  public async moveContent(
    folder: Folder | { id: string },
    content: FolderContent,
    type: ContributorTypes,
    destination: string,
    parentRouteName?: string
  ): Promise<void> {
    let result;
    const containable = await getFolderContainableFromDragContent(content);

    if (!containable) {
      return;
    }

    try {
      result = await this.addContent(folder, containable, type);
      if (type === ContributorTypes.TEAM) {
        this.trackDragToShare(folder, containable);
      }
    } catch (e: any) {
      this.notifications.error(FolderMessages.FOLDER_MOVE_FAILURE);

      if (e instanceof Error) {
        this.honeybadger.notify(e);
      }

      return;
    }

    if (result) {
      const successMessage = await this.notificationMessage(content, destination);
      if (parentRouteName) {
        const destinationUrl = this.buildDestinationUrl(parentRouteName, folder, folder.id === ROOT_FOLDER_ID, type);
        this.notifications.success(successMessage, {
          button: {
            label: "View folder",
            onClick: () => this.onDestinationClick(destinationUrl)
          }
        });
      } else {
        this.notifications.success(successMessage);
      }

      await this.reloadContent();
    }
  }

  public breadcrumbPath(folder: Folder): Array<Breadcrumb> {
    const crumbs: Array<Breadcrumb> = [];

    if (folder) {
      const path = this.getPath(folder);
      for (let i = 1; i < path.length; i++) {
        const folder: any = path[i];
        crumbs.push({
          label: folder.get("name"),
          id: folder.get("id")
        });
      }
    }
    return crumbs;
  }

  private buildDestinationUrl(
    parentRouteName: string,
    folder: Folder | { id: string },
    isDestinationRootFolder: boolean,
    type: ContributorTypes
  ): string {
    const destinationPath = isDestinationRootFolder
      ? this.router.urlFor(parentRouteName + "." + FolderRouteSuffix.LIBRARY, type)
      : this.router.urlFor(parentRouteName + "." + FolderRouteSuffix.FOLDER, folder.id);
    const { protocol, host } = window.location;
    const destinationUrl = `${protocol}//${host}${destinationPath}`;

    return destinationUrl;
  }

  private async notificationMessage(content: FolderContent, destination: string): Promise<string> {
    if (content instanceof ProjectPreview) {
      const project = await content.project;

      if (project instanceof Project) {
        return `1 video successfully moved to ${destination}`;
      } else {
        return `1 folder successfully moved to ${destination}`;
      }
    } else if (content instanceof Folder) {
      return `1 folder successfully moved to ${destination}`;
    } else {
      return `Item successfully moved to ${destination}`;
    }
  }

  private onDestinationClick(url: string): any {
    window.open(url, "_self");
  }

  private trackDragToShare(folder: Folder | { id: string }, containable: FolderContainable): void {
    if (
      containable.containableType === FolderContainableTypes.PROJECT &&
      !(containable as Project).teamShared &&
      (folder.id === ROOT_FOLDER_ID || (folder as Folder)?.contributorType === ContributorTypes.TEAM)
    ) {
      void this.tracking.sendAnalytics(TrackingEvents.EVENT_TEAM_SHARE_PROJECT, {
        projectId: containable.id,
        source: "project-drag-drop"
      });
    }
  }
}

declare module "@ember/service" {
  interface Registry {
    folders: FoldersService;
  }
}
