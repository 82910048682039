import Model, { attr } from "@ember-data/model";

export default class ProjectSceneStack extends Model {
  @attr("string")
  declare posterUrl: string;

  @attr("number")
  declare displayOrder: number;

  @attr("string")
  declare name: string;

  @attr("string")
  declare code: string;

  @attr("boolean")
  declare published: boolean;

  @attr("string")
  declare categorySlug: string;

  @attr("boolean")
  declare brandable: boolean;

  @attr("number")
  declare count?: number;

  get branded(): boolean {
    return this.code === "reference-v2-brand-scenes";
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    projectSceneStack: ProjectSceneStack;
  }
}
