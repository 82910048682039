import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { TaskGenerator } from "ember-concurrency";
import { task } from "ember-concurrency";
import { perform } from "ember-concurrency-ts";
import type { SafeString } from "handlebars";
import { SAMPLES_PER_SECOND, AudioClipCategory } from "renderer-engine";
import type { Timeline, TimelineMutation, AudioClip, EventRegister } from "client/lib/editor-domain-model";
import { AudioClipOffsetMutation, DeleteAudioClipMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Project from "client/models/project";
import type AudioTracksService from "client/services/audio-tracks";
import type AuthService from "client/services/auth";
import type MouseService from "client/services/mouse";
import { MouseEvents } from "client/services/mouse";
import type PropertiesPanelService from "client/services/properties-panel";
import type VoiceoverSelectModalService from "client/services/voiceover-select-modal";

interface AudioTrackClipArgs {
  audioClip: AudioClip;
  category?: string;
  projectDuration: number;
  pixelsPerSecond: number;
  timeline: Timeline;
  eventRegister: EventRegister;
  onResize?: (resizing: boolean) => void;
  onSelected?: () => void;
  onHover?: () => void;
  isSelected: boolean;
  project: Project;
  sceneId?: string;
  mutate: (mutation: TimelineMutation) => void;
  save: (audioClip: AudioClip) => void;
  enableDragging: boolean;
  enableResizing?: boolean;
}

interface SVGProps {
  width: number;
  viewBoxLeft: number;
  viewBoxWidth: number;
}

export default class AudioTrackClipComponent extends Component<AudioTrackClipArgs> {
  @service
  declare router: RouterService;

  @service
  declare mouse: MouseService;

  @service
  declare audioTracks: AudioTracksService;

  @service
  declare auth: AuthService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @service
  private declare voiceoverSelectModal: VoiceoverSelectModalService;

  @tracked
  private _isDragging = false;

  @tracked
  private _isResizing = false;

  @tracked
  declare waveform?: number[];

  originalOffset!: number;
  isSubscribedToDragEvents = false;

  styleNamespace = getStyleNamespace("app/audio-track-clip");

  @task({ drop: true })
  *generateWaveform(audioClip: AudioClip): TaskGenerator<void> {
    this.waveform = undefined;
    this.waveform = yield this.audioTracks.generateWaveform(audioClip.sourceUrl);
  }

  @action
  async didInsert(): Promise<void> {
    void perform(this.generateWaveform, this.args.audioClip);
  }

  @action
  async didUpdateDuration(): Promise<void> {
    if (
      this.args.category === AudioClipCategory.VOICEOVER &&
      this.waveformDuration > 0 &&
      this.args.audioClip.duration <= 0
    ) {
      this.args.audioClip._duration = this.waveformDuration;
    }
  }

  @action
  onResize(direction?: string): void {
    this._isResizing = !!direction;
    this.args.onResize?.(this._isResizing);
  }

  @action
  subscribeToDragEvents(): void {
    if (this.args.enableDragging) {
      this.mouse.subscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
      this.mouse.subscribe(MouseEvents.DRAG, this.onMouseDrag);
      this.mouse.subscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
      this.isSubscribedToDragEvents = true;
    }
  }

  @action
  onMouseUp(event: MouseEvent): void {
    if (this.args.enableDragging) {
      this.mouse.onMouseUp(event);
      this.unsubscribeFromDragEvents();
    }
  }

  @action
  onMouseDragStart(): void {
    const { audioClip } = this.args;
    if (audioClip) {
      this.isDragging = true;
      this.originalOffset = audioClip.offset;
    }
  }

  @action
  onMouseDrag(event: MouseEvent): void {
    if (!this.mouse.mouseDownX) {
      return;
    }

    const { audioClip } = this.args;

    if (audioClip) {
      const mouseDelta = event.pageX - this.mouse.mouseDownX;

      this.args.mutate(
        new AudioClipOffsetMutation(audioClip, this.originalOffset + mouseDelta / this.args.pixelsPerSecond)
      );
    }
  }

  @action
  onMouseDragEnd(): void {
    this.unsubscribeFromDragEvents();

    next(this, () => {
      this.isDragging = false;
    });

    if (this.args.audioClip) {
      this.args.save(this.args.audioClip);
    }
  }

  @action
  unsubscribeFromDragEvents(): void {
    if (!this.isSubscribedToDragEvents) {
      return;
    }

    this.mouse.unsubscribe(MouseEvents.DRAG_START, this.onMouseDragStart);
    this.mouse.unsubscribe(MouseEvents.DRAG, this.onMouseDrag);
    this.mouse.unsubscribe(MouseEvents.DRAG_END, this.onMouseDragEnd);
    this.isSubscribedToDragEvents = false;
  }

  set isDragging(isDragging: boolean) {
    this.mouse.isDragging = isDragging;
    this._isDragging = isDragging;
  }

  get isDragging(): boolean {
    return this._isDragging;
  }

  get isResizing(): boolean {
    return this._isResizing;
  }

  get duration(): number {
    const { audioClip, projectDuration } = this.args;
    return audioClip.duration > 0 ? audioClip.duration : projectDuration;
  }

  get width(): number {
    return this.duration * this.args.pixelsPerSecond;
  }

  get left(): number {
    return (this.args.audioClip?.offset ?? 0) * this.args.pixelsPerSecond;
  }

  get inlineStyle(): SafeString {
    return htmlSafe(`left:${this.left.toFixed(2)}px; width:${this.width.toFixed(2)}px;`);
  }

  get isGeneratingWaveform(): boolean {
    return !this.waveform;
  }

  get sampleWidth(): number {
    return SAMPLES_PER_SECOND / this.args.pixelsPerSecond;
  }

  get waveformDuration(): number {
    return (this.waveform?.length ?? 0) / SAMPLES_PER_SECOND - (this.args.audioClip.trimStart ?? 0);
  }

  get waveformPath(): string {
    const ph = 16;

    if (!this.waveform) {
      return "";
    }
    return this.waveform.map((w, i) => `${i ? "L" : "M"} ${i} ${ph} L ${i + 1 / 2} ${ph - w * ph}`).join(" ");
  }

  get waveforms(): SVGProps[] {
    const { audioClip, pixelsPerSecond } = this.args;
    const { duration, waveformDuration } = this;

    if (!waveformDuration || !audioClip) {
      return [];
    }

    const waveCount = audioClip.loop ? Math.ceil(duration / waveformDuration) : 1;
    return [...Array(waveCount)].map((_, i) => {
      const length = Math.min(duration - i * waveformDuration, waveformDuration, duration);

      return {
        width: pixelsPerSecond * length,
        viewBoxLeft: pixelsPerSecond * audioClip.trimStart * this.sampleWidth,
        viewBoxWidth: pixelsPerSecond * length * this.sampleWidth
      };
    });
  }

  @action
  async onClick(): Promise<void> {
    const { category, sceneId, onSelected } = this.args;

    onSelected?.();

    if (sceneId) {
      this.propertiesPanel.open();
      await this.router.transitionTo(`authenticated.project.scene.${category}`, sceneId, {
        queryParams: { audioClipId: this.args.audioClip.id }
      });
    }
  }

  @action
  async removeAudioClip(): Promise<void> {
    const { audioClip, mutate, save, sceneId, timeline, category } = this.args;

    if (!timeline || !audioClip || !sceneId) {
      return;
    }

    mutate(new DeleteAudioClipMutation(timeline, audioClip));
    save(audioClip);

    await this.router.transitionTo(`authenticated.project.scene.${category}`, sceneId, {
      queryParams: { audioClipId: undefined }
    });
  }

  get showNarrationUpgrade(): boolean {
    if (
      this.args.audioClip.audibleType === "Narration" &&
      !this.auth.currentFullOrTrialSubscription?.trialing &&
      !this.auth.currentFullSubscription?.plan.isTopTierPlan
    ) {
      return true;
    }

    return false;
  }

  @action
  async openVoiceoverSelectModal(replacedClipId: string): Promise<void> {
    const { project, audioClip, timeline, eventRegister, sceneId } = this.args;
    this.voiceoverSelectModal.setModel({
      project,
      audioClip,
      timeline,
      eventRegister,
      replacedClipId,
      sceneId
    });
    await this.voiceoverSelectModal.open();
  }
}
