import { isCORSUrl } from "renderer-engine";

export const getVideoDuration = (url: string, defaultDuration = 3): Promise<number> => {
  return new Promise<number>((resolve) => {
    const video = document.createElement("video");

    if (url) {
      if (isCORSUrl(url)) {
        video.crossOrigin = "anonymous";
      }

      video.addEventListener("loadedmetadata", () => {
        // This addresses a bug in Chrome when duration is declared to be Infinity
        // it seems to stem from Chrome itself when a video is recorded in it
        // see https://stackoverflow.com/a/52375262 for more info.
        // Chrome bug here https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        // it is declared Won't Fix
        if (video.duration === Infinity) {
          video.addEventListener("durationchange", () => resolve(video.duration));
          video.currentTime = Number.MAX_SAFE_INTEGER;
        } else {
          resolve(video.duration);
        }
      });
      video.addEventListener("error", () => resolve(defaultDuration));
      video.src = url;
    } else {
      resolve(defaultDuration);
    }
  });
};
