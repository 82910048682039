import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface NorthStarLinkArgs {
  route?: string;
}

export default class NorthStarLinkComponent extends Component<NorthStarLinkArgs> {
  styleNamespace = getStyleNamespace("north-star/link");
}
