import type { Caption, Scene } from "../../scene";
import { calculatePositions } from "./movable-caption";
import { TimelineMutation } from "./mutation";
import type { SceneState } from "./scene-state";
import { createState } from "./scene-state";

export class SceneDurationMutation extends TimelineMutation {
  private _duration!: number;
  private captions: Caption[];
  private originalState: SceneState;

  constructor(
    public grouped: boolean,
    private scene: Scene,
    duration: number,
    private referenceState: SceneState,
    public snapAmount?: number
  ) {
    super();
    this.captions = [...this.scene.captions];
    this.originalState = createState(scene);
    this.duration = duration;
  }

  set duration(value: number) {
    if (value < 0.5) {
      value = 0.5;
    }

    this._duration = value;
  }

  get duration(): number {
    return this.snap(this._duration);
  }

  run(): void {
    if (!this.captions.length) {
      this.scene._duration = this.snap(this.duration);
    } else {
      const newSceneState = calculatePositions(this.referenceState, (movableCaptions) => {
        const lastCaption = movableCaptions[movableCaptions.length - 1]!;
        return lastCaption.newSceneDuration(this.duration, this.referenceState.sceneDuration);
      });

      Object.keys(newSceneState.captionsState)
        .map((id) => this.scene.captions.find((c) => id === c.id))
        .forEach((caption) => {
          if (caption && newSceneState.captionsState[caption.id]) {
            caption._setOffset(this.snap(newSceneState.captionsState[caption.id]!.offset));
            caption._setDuration(this.snap(newSceneState.captionsState[caption.id]!.duration));
          }
        });
      this.scene._setDuration(this.snap(newSceneState.sceneDuration));
    }
  }

  revert(): void {
    this.scene._duration = this.originalState.sceneDuration;
    if (this.captions.length) {
      Object.keys(this.originalState.captionsState)
        .map((id) => this.scene.captions.find((c) => id === c.id))
        .forEach((caption) => {
          if (caption && this.originalState.captionsState[caption.id]) {
            caption._offset = this.snap(this.originalState.captionsState[caption.id]!.offset);
            caption._duration = this.snap(this.originalState.captionsState[caption.id]!.duration);
          }
        });
    }
  }
}
