import Service, { service } from "@ember/service";
import type AuthService from "./auth";
import type User from "client/models/user";
import type UserTargetingData from "client/models/user-targeting-data";

export const USER_FORBIDDEN_NOTIFICATION = "You are not authorized to perform this action";

export default class CurrentUserService extends Service {
  @service
  declare auth: AuthService;

  get user(): User | undefined {
    return this.auth.currentUser;
  }

  isCurrentUser(userId: string): boolean {
    return this.user?.id === userId;
  }

  get targetingData(): UserTargetingData | undefined {
    return this.auth.userTargetingData;
  }

  async reload(): Promise<void> {
    await this.auth.reload();
  }
}
