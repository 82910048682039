import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { VirtualElement } from "@popperjs/core";
import type { Logo, Text } from "client/lib/editor-domain-model";
import type { Actions } from "client/lib/media-actions";
import { MediaAction } from "client/lib/media-actions";
import { isMacOS } from "client/lib/user-agent";

interface Args {
  media: Text | Logo;
  isBackground?: boolean;
  onCopy: () => void;
  onPaste: () => void;
  onDuplicate: () => void;
  onReplace: () => void;
  onResetAspectRatio: () => void;
  onMoveToBackground: () => void;
  onMoveToForeground: () => void;
  onRemove: () => void;
  onMoveLayerForward: () => void;
  onMoveLayerBackward: () => void;
  onMoveLayerToFront: () => void;
  onMoveLayerToBack: () => void;
  highestLayer?: boolean;
  lowestLayer?: boolean;
  onClose: () => void;
}

export default class MediaActionsRightClickMenuComponent extends Component<Args> {
  @tracked
  submenuIsOpen = false;

  @action
  openSubmenu(): void {
    this.submenuIsOpen = true;
  }

  @action
  closeSubmenu(): void {
    this.submenuIsOpen = false;
    this.args.onClose();
  }

  @tracked
  submenuTarget?: VirtualElement;

  @action
  setSubmenuTarget(element: HTMLElement): void {
    this.submenuTarget = element;
  }

  get showMacShortcuts(): boolean {
    return isMacOS;
  }

  get mediaActions(): Actions {
    return MediaAction(this.args.media, this.args.isBackground);
  }
}
