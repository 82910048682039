enum TrackingEvents {
  // sign-up events
  EVENT_PAGE_SIGN_UP = "Sign Up",

  // onboarding events
  EVENT_CREATE_PROFILE = "create profile",
  EVENT_SELECT_ORG_TYPE = "select org type",
  EVENT_SELECT_ORG_SIZE = "select org size",
  EVENT_SELECT_ROLE = "select role",

  // template events
  EVENT_TEMPLATE_COPIED = "copied template",
  EVENT_SELECT_TEMPLATE = "select template",
  EVENT_FILTER_TEMPLATES = "filter: templates",
  EVENT_FILTER_ASPECT_RATIO = "filter: aspect ratio",
  EVENT_SELECT_ASPECT_RATIO = "dashboard: select aspect ratio",
  EVENT_VIEW_TUTORIAL_VIDEO = "view tutorial video",
  EVENT_HIDE_DASHBOARD_SECTION = "close tile",
  EVENT_TEMPLATE_PREVIEW_OPEN = "open preview",
  EVENT_TEMPLATE_PREVIEW = "preview template",

  // editor events
  EVENT_CLICK_ADD_SCENE = "click add scene",
  EVENT_SELECT_SCENE = "select scene",
  EVENT_SELECT_STOCK_CONTENT = "select stock content",
  EVENT_SELECT_USER_ASSET = "select user asset",
  EVENT_BACKGROUND_TRIM_VIDEO = "background: trim video",
  EVENT_PLAY_PREVIEW = "play preview",
  EVENT_PREVIEW_SCENE = "preview scene",
  EVENT_CLICK_UPGRADE_LINK = "click upgrade link",
  EVENT_VIEW_INCREASE_PLANS = "click expansion link",
  EVENT_SELECT_SUBSCRIPTION_PLAN = "select subscription plan",
  EVENT_CLICK_GRID_TOGGLE = "click grid toggle",
  EVENT_CLICK_ADVANCED_LINK = "click advanced link",
  EVENT_CLICK_SMART_LINK = "click smart link",
  EVENT_CONTENT_BAR_ADD_CONTENT = "add content",
  EVENT_CONTENT_BAR_STATE_CHANGE = "content bar state change",

  // steps
  EVENT_CLICK_ADD_STEP = "subway navigation: click add",
  EVENT_CLICK_STYLE_STEP = "subway navigation: click style",
  EVENT_CLICK_EDIT_STEP = "subway navigation: click edit",
  EVENT_CLICK_PUBLISH_STEP = "subway navigation: click publish",

  // export events
  EVENT_BUILD_VIDEO = "build video",
  EVENT_DOWNLOAD_VIDEO = "download video",
  EVENT_DOWNLOAD_GIF = "download gif",
  EVENT_BUILD_CAPTIONS = "build captions",
  EVENT_CLICK_OPEN_ANALYTICS = "click open analytics",

  // navigation events
  EVENT_VIEW_CREATE_DIALOG = "Create Dialog",
  EVENT_VIEW_RESIZE_DIALOG = "Editor: Resize Dialog",
  EVENT_VIEW_EDITOR = "view editor",
  EVENT_VIEW_PUBLISH_PAGE = "view publish page",

  // project preview events
  EVENT_CLICK_EDIT_PROJECT = "project preview: clicked to edit",
  EVENT_CLICK_SHARE_PROJECT = "project preview: clicked to share",
  EVENT_CLICK_ANALYTICS_PROJECT = "project preview: clicked to view analytics",
  EVENT_CLICK_COMMENT_COUNT = "project preview: clicked to view comment",

  // sharing events
  EVENT_CLICK_EMBED_VIDEO = "click embed video",
  EVENT_CLICK_COPY_LINK = "click copy link",
  EVENT_COPY_EMBED_CODE = "copy embed code",

  // collaboration events
  EVENT_TEAM_SHARE_PROJECT = "team: share project",
  EVENT_TEAM_UNSHARE_PROJECT = "team: unshare project",
  EVENT_TEAM_VIEW_PROJECT = "team: view project",

  // Purchase
  BITEABLE_ORDER_COMPLETED = "Order Completed",
  EVENT_CLOSE_PURCHASE_MODAL = "close purchase modal",
  EVENT_START_PLAN_PURCHASE = "start plan purchase",

  // feature check events
  EVENT_BROWSER_MISSING_FEATURE = "browser missing feature",

  // Call-to-action actions
  CALL_TO_ACTION_SAVE = "save cta",
  CALL_TO_ACTION_ADD = "click add cta",

  // Brand set up events
  EVENT_BRAND_QUERY_USER_REQUESTED = "brand query: user requested",
  EVENT_BRAND_QUERY_AUTO_REQUESTED = "brand query: auto requested",

  // Brand edit events
  EVENT_BRAND_SAVE = "save brand",

  // Trial
  EVENT_START_TRIAL = "start trial",
  // Specific Segment SaaS event, like Order Completed is a Segment ecommerce event
  EVENT_TRIAL_STARTED = "Trial Started",
  EVENT_START_TRIAL_PURCHASE = "start trial purchase",
  EVENT_CONTACT_BITEABLE = "contact Biteable",

  // Edit-only Mode
  EVENT_EDIT_ONLY_MODE_CONFIRM = "confirm edit-only mode",

  // Account
  EVENT_CANCEL_SUBSCRIPTION = "cancel subscription",
  EVENT_START_REACTIVATING_SUBSCRIPTION = "start reactivating subscription",

  EVENT_REACTIVATE_ACTIVE_SUBSCRIPTION = "reactivate active subscription",
  EVENT_REACTIVATE_ENDED_SUBSCRIPTION = "reactivate ended subscription",
  EVENT_INVITE_TEAM_MEMBERS = "invite team members",
  EVENT_REMOVE_TEAM_ACCESS = "remove team access",
  EVENT_REACTIVATE_TEAM_ACCESS = "reactivate team access",
  EVENT_CHANGE_TEAM_ROLE = "change team role",

  // Searching
  EVENT_SEARCH_CONDUCTED = "search conducted",

  // Content discovery
  EVENT_DISCOVER_LOAD_MORE = "discover load more",
  EVENT_ITEM_SAVED = "item saved",

  // Add Scene
  PROJECT_SCENE_PREVIEW_ADD = "adding project-scene-preview asset - incorrect model",

  // Record
  EVENT_CLICK_COPY_LINK_RECORD_PROJECT = "click copy link record project url",

  // Video Assistant
  EVENT_VIDEO_ASSISTANT_STARTED = "videoassistant_started",
  EVENT_VIDEO_ASSISTANT_GENERATE_VIDEO = "videoassistant_generatevideo_clicked",
  EVENT_VIDEO_ASSISTANT_EDITOR_OPENED = "videoassistant_editor_opened",
  EVENT_VIDEO_ASSISTANT_WIZARD_CHOICE_MADE = "videoassistant_wizard_next_clicked",

  // Seats Purchase
  EVENT_OPEN_SEATS_PURCHASE_MODAL = "open seats purchase modal",
  EVENT_COMPLETE_SEATS_PURCHASE = "complete seats purchase",
  EVENT_CHANGE_SEATS_QUANTITY = "seats purchase - change quantity",

  // Element Timing
  EVENT_CHANGE_ELEMENT_TIMING = "element timing changed",

  // Order Layers
  EVENT_CHANGE_ELEMENT_LAYERS = "change element layer",

  // Copy and Paste
  EVENT_COPY_AND_PASTE = "copy and paste element"
}

export default TrackingEvents;

export enum CTAContext {
  CTA_CONTEXT_BUTTON_BANNER_SUBSCRIBE = "button - nav - upgrade",
  CTA_CONTEXT_BUTTON_BANNER_UPGRADE_NOW = "button - banner - upgrade now",
  CTA_CONTEXT_BUTTON_EXPORT_GET_FULL_ANALYTICS = "button - export - get full analytics",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_BUILD_VIDEO = "button - export left side - build video",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_PPC_BUILD_VIDEO = "button - export left side - shutterstock PPC build video",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_REBUILD_FAILED = "button - export left side - rebuild failed render",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_UPDATE_YOUR_VIDEO = "button - export left side - shutterstock update your video",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_PPC_UPDATE_YOUR_VIDEO = "button - export left side - shutterstock PPC update your video",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_RESTART = "button - export left side - shutterstock restart",
  CTA_CONTEXT_BUTTON_EXPORT_LEFT_SIDE_SHUTTERSTOCK_PPC_RESTART = "button - export left side - shutterstock PPC restart",
  CTA_CONTEXT_BUTTON_EXPORT_BELOW_PREVIEW_UPDATE = "button - export below preview - update your video",
  CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_BUILD_VIDEO = "button - export video overlay - build video",
  CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_RESTART_BUILD = "button - export video overlay - restart build",
  CTA_CONTEXT_BUTTON_EXPORT_VIDEO_OVERLAY_UPDATE = "button - export video overlay - update your video",
  CTA_CONTEXT_AFTER_THUMBNAIL_SELECT = "button - after thumbnail select - build video",
  CTA_CONTEXT_AFTER_THUMBNAIL_UPLOAD = "button - after thumbnail upload - build video",
  CTA_CONTEXT_LINK_THUMBNAIL_UPGRADE = "link - thumbnail - upgrade"
}

export enum Locations {
  LOCATION_EXPORT_COMMENTS = "EXPORT COMMENTS",
  LOCATION_EXPORT_PAGE = "EXPORT PAGE",
  LOCATION_TEAM_SETTINGS = "TEAM SETTINGS",
  LOCATION_CONTENT_BAR = "CONTENT BAR",
  LOCATION_SCENES_PAGE = "SCENES PAGE",
  LOCATION_CANVAS = "CANVAS",
  LOCATION_LAYERS_PANEL = "LAYERS PANEL"
}
