import Model, { attr } from "@ember-data/model";
import type { StripeAccount } from "client/services/stripe";

export default class Receipt extends Model {
  @attr("string")
  declare chargeId: string;

  @attr("string")
  declare stripeAccount: StripeAccount;

  @attr("string")
  declare userId: string;

  @attr("string")
  declare orgName: string;

  @attr("string")
  declare contactName: string;

  @attr("string")
  declare contactEmail: string;

  @attr("string")
  declare orgAddress: string;

  @attr("string")
  declare vat: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    receipt: Receipt;
  }
}
