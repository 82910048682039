import { tracked } from "@glimmer/tracking";
import type { Asset } from "../asset";
import type { ElementArgs } from "./element";
import { Element } from "./element";

export interface MediaArgs extends ElementArgs {
  asset?: Asset;
  mediaReplaceable?: boolean;
  assetOffset?: [number, number];
}

export abstract class Media extends Element {
  /** @internal */
  @tracked
  _asset?: Asset;

  /** @internal */
  @tracked
  _assetOffset?: [number, number];

  /** @internal */
  @tracked
  _mediaReplaceable?: boolean;

  constructor(args: MediaArgs) {
    super(args);

    const { asset, assetOffset, mediaReplaceable } = args;
    this._asset = asset;
    this._assetOffset = assetOffset;
    this._mediaReplaceable = mediaReplaceable;
  }

  get asset(): Asset | undefined {
    return this._asset;
  }

  get assetOffset(): [number, number] | undefined {
    return this._assetOffset;
  }

  get mediaReplaceable(): boolean | undefined {
    return this._mediaReplaceable;
  }

  get assetOffsetX(): number | undefined {
    return this.assetOffset?.[0];
  }

  get assetOffsetY(): number | undefined {
    return this.assetOffset?.[1];
  }

  get colors(): string[] {
    return this.asset?.colors ?? [];
  }

  get canResize(): boolean {
    return !this.asset?.frame;
  }

  get canCrop(): boolean {
    return !!this.asset && this.asset.objectFit === "fill" && this.asset.hasContent;
  }

  get hasContent(): boolean {
    return !!this.asset?.hasContent;
  }

  get category(): string {
    if (!this.asset) {
      return "blank";
    }

    return this.asset.category;
  }

  get formattedCategory(): string {
    if (!this.asset) {
      return "empty";
    }

    return this.asset.formattedCategory;
  }

  /** @internal */
  _update(media: Media): void {
    super._update(media);
    this._asset = media._asset;
    this._assetOffset = media._assetOffset;
    this._mediaReplaceable = media._mediaReplaceable;
  }

  /** @internal */
  _reset(): void {
    this._asset = undefined;
  }
}
