export const getDefaultRect = (): Rect => new Rect(0, 0, 1, 1);

export class Rect {
  constructor(
    public readonly x: number,
    public readonly y: number,
    public readonly width: number,
    public readonly height: number
  ) {}

  public static fromRect({ x, y, width, height }: Pick<Rect, "x" | "y" | "width" | "height">) {
    return new Rect(x, y, width, height);
  }

  public equals(other: Rect): boolean {
    const r1 = this; // eslint-disable-line @typescript-eslint/no-this-alias
    const r2 = other;
    return r1.x === r2.x && r1.y === r2.y && r1.width === r2.width && r1.height === r2.height;
  }

  public get fillsCanvas(): boolean {
    return this.x <= 0 && this.y <= 0 && this.x + this.width >= 1 && this.y + this.height >= 1;
  }
}
