import { service } from "@ember/service";
import Model, { attr, belongsTo } from "@ember-data/model";
import type { FavoriteMetadata } from "./favorable";
import type Favorable from "./favorable";
import type TeamFavorite from "./team-favorite";
import type FavoritesService from "client/services/favorites";

export default class Favorite extends Model {
  @service
  declare favorites: FavoritesService;

  @attr("string")
  declare favorableType: string;

  @attr("string")
  declare favorableProviderAssetId: string;

  @attr("string")
  declare stockLicenseId: string;

  // eslint-disable-next-line no-null/no-null
  @belongsTo("favorable", { async: false, inverse: null, polymorphic: true })
  declare favorable: Favorable;

  @belongsTo("team-favorite", { async: false, inverse: "favorite" })
  declare teamFavorite: TeamFavorite;

  @attr("json")
  declare metadata: FavoriteMetadata;

  async unfavorite(): Promise<void> {
    await this.destroyRecord();
  }

  get assetPurchased(): boolean {
    return !!this.stockLicenseId;
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    favorite: Favorite;
  }
}
