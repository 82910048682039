import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import config from "client/config/environment";
import getStyleNamespace from "client/lib/get-style-namespace";
import RecordProject, { RecordProjectTypes } from "client/models/record-project";

interface CreateRecordProjectModalArgs {
  cancel(): unknown;
  recordProjectType: RecordProjectTypes;
}

export default class CreateRecordProjectModalComponent extends Component<CreateRecordProjectModalArgs> {
  styleNamespace = getStyleNamespace("discovery/create-record-project-modal");
  titleInput!: HTMLInputElement;

  RecordProjectTypes = RecordProjectTypes;

  @service
  declare store: Store;

  @tracked
  declare description?: string;

  @tracked
  title = RecordProject.generateRecordProjectName(this.args.recordProjectType);

  @tracked
  timeFrame = "As soon as possible";

  @tracked
  saving = false;

  get validDescription(): boolean {
    return !this.description || this.description.length <= 2500;
  }

  get headerTitle(): string {
    return this.args.recordProjectType === RecordProjectTypes.SELF ? "New recording" : "New recording request";
  }

  @action
  didInsert(element: HTMLInputElement): void {
    this.titleInput = element;
    this.titleInput.focus();
  }

  @action
  updateTimeFrame(timeFrame: string): void {
    this.timeFrame = timeFrame;
  }

  @action
  async save(ev: Event): Promise<void> {
    ev.preventDefault();

    if (this.saving) {
      return;
    }

    this.saving = true;

    try {
      const recordProject = await this.store
        .createRecord("recordProject", {
          title: this.title,
          recordType: this.args.recordProjectType,
          description: this.description,
          timeFrame: this.timeFrame
        })
        .save();

      if (this.args.recordProjectType === RecordProjectTypes.SELF) {
        const url = `${config.recordUrl}/record/me/${recordProject.id}-${recordProject.jwt}`;
        window.open(url);
      }
      if (this.args.recordProjectType === RecordProjectTypes.COLLABORATIVE) {
        const url = `${config.recordUrl}/record/${recordProject.id}-${recordProject.jwt}`;
        window.open(url);
      }
      this.args.cancel();
    } finally {
      this.saving = false;
    }
  }
}
