import { getOwner } from "@ember/application";
import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import type { ProjectRouteModel } from "client/authenticated/project/route";
import SceneAssetModifier from "client/lib/scene-asset-modifier";
import type HoneybadgerService from "client/services/honeybadger";
import type ProjectsService from "client/services/projects";

export interface ProjectImportSceneRouteParams {
  sceneId: string;
}

export default class ProjectImportSceneRoute extends Route {
  @service
  declare store: Store;

  @service
  declare router: RouterService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare projects: ProjectsService;

  async model({ sceneId }: ProjectImportSceneRouteParams): Promise<void> {
    const asset = await this.store.findRecord("projectScene", sceneId);
    const { timeline, eventRegister } = this.modelFor("authenticated.project") as ProjectRouteModel;

    try {
      const modifier = new SceneAssetModifier(getOwner(this), timeline, eventRegister);

      await modifier.applyAsset(asset);
    } catch (err) {
      // @ts-expect-error
      this.honeybadger.notify(err);
    }

    void this.router.replaceWith("authenticated.project");
  }
}
