import { getOwner } from "@ember/application";
import ArrayProxy from "@ember/array/proxy";
import { action } from "@ember/object";
import { service } from "@ember/service";
import type Store from "@ember-data/store";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ProjectContentBarPanelArgs } from "client/components/project-content-bar/panel/component";
import SelectableBrandLogo from "client/lib/assets/selectable-brand-logo";
import type SelectableAsset from "client/models/selectable-asset";
import type BrandEditModalService from "client/services/brand-edit-modal";
import type ProjectContentBarService from "client/services/project-content-bar";

interface Args {
  selectAsset(asset: SelectableAsset): void;
}

export default class PanelAllUploadsPanel extends Component<Args & ProjectContentBarPanelArgs> {
  @service
  private declare store: Store;

  @service
  private declare projectContentBar: ProjectContentBarService;

  @service
  private declare brandEditModal: BrandEditModalService;

  @tracked
  params = {};

  @action
  async loadResults(_params: Record<string, string | number>): Promise<ArrayProxy<SelectableBrandLogo>> {
    const selectableBrandLogos: SelectableBrandLogo[] = [];
    const brand = await this.projectContentBar.project?.getBrandStyle();

    if (brand) {
      await brand.style.assetsLoaded;

      await Promise.all(
        brand.style.logos.map(async (brandLogo) => {
          if (brandLogo.userAssetId) {
            const userAsset = await this.store.findRecord("userAsset", brandLogo.userAssetId);
            const selectableBrandLogo = new SelectableBrandLogo(getOwner(this)!, brandLogo, userAsset);
            selectableBrandLogos.push(selectableBrandLogo);
          }
        })
      );
    }

    return ArrayProxy.create({
      content: selectableBrandLogos,
      meta: { totalCount: selectableBrandLogos.length }
    }) as ArrayProxy<SelectableBrandLogo>;
  }

  @action
  editBrand(): void {
    void this.brandEditModal.show();
  }
}
