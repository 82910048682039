import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import { PlanInterval } from "client/models/plan";

interface PlansToggleArgs {
  changeInterval: () => void;
  interval: string;
  selectYearlyInterval?: () => unknown;
}

export default class PlansToggleComponent extends Component<PlansToggleArgs> {
  styleNamespace = getStyleNamespace("purchase/plans-toggle");

  get isMonthly(): boolean {
    return this.args.interval === PlanInterval.MONTH;
  }

  get isYearly(): boolean {
    return this.args.interval === PlanInterval.YEAR;
  }
}
