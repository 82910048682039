import { clamp, snap, toFixed } from "../../math";
import type { TimelineFacade } from "../../timeline";

export interface Mutation<T> {
  grouped: boolean;
  undoable: boolean;
  run(t: T): void;
  revert(t: T): void;
}

export type ExecutionPhase = "update" | "save" | "route" | "cleanup";

export abstract class StrictMutation<T = any> implements Mutation<TimelineFacade> {
  abstract grouped: boolean;
  undoable = true;
  snapAmount?: number;
  executionPhase: ExecutionPhase = "update";
  abstract run(): T;
  abstract revert(): T;
  prepare?(facade: TimelineFacade): void;

  clamp(num: number, min: number, max: number): number {
    return clamp(num, min, max);
  }

  round(num: number, precision = 2): number {
    return toFixed(num, precision);
  }

  snap(n: number): number {
    if (!this.snapAmount) {
      return n;
    }
    return snap(n, this.snapAmount);
  }

  isSupercededBy(_mutation: StrictMutation) {
    return false;
  }
}

/**
 * @deprecated This class suffers from always making the result nullable,
 * which is undesirable. It is better to use StrictMutation<T | void> directly
 * to explicitly allow void only when needed.
 */
export abstract class TimelineMutation<T = any> extends StrictMutation<T | void> {}
