import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { Caption, EventRegister, Text, Timeline } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { EditableText } from "client/lib/text/text-editor";
import type AdvancedEditorService from "client/services/advanced-editor";
import type ScriptWorkstationService from "client/services/script-workstation";

interface Args {
  text: Text;
  caption: Caption;
  eventRegister: EventRegister;
  timeline: Timeline;
  editables: Map<string, EditableText>;
  shiftFocus: (direction: -1 | 1) => void;
}

export default class ScriptWorkstationTextArea extends Component<Args> {
  @service
  declare advancedEditor: AdvancedEditorService;

  @service
  declare scriptWorkstation: ScriptWorkstationService;

  styleNamespace = getStyleNamespace("script-workstation/scene/caption/text-area");

  get editableText(): EditableText | undefined {
    return this.args.editables.get(this.args.text.id);
  }

  @action
  async onFocusIn(): Promise<void> {
    if (this.editableText && this.advancedEditor.scriptWorkstationElement !== this.editableText) {
      this.advancedEditor.setScriptWorkstationElement(this.editableText);
      void this.advancedEditor.transitionToText(this.args.caption, this.args.text);

      window.requestAnimationFrame(() => {
        document.execCommand("selectAll", false);
      });
    }
  }

  @tracked
  showEmptyTextArea = false;

  @action
  setShowEmptyTextArea(newState: boolean): void {
    this.showEmptyTextArea = newState;
  }

  @action
  registerEditable(text: Text, editable: EditableText): void {
    this.args.editables.set(text.id, editable);
  }
}
