import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  hideValue?: boolean;
  value: number;
  max: number;
  min: number;
  step: number;
  formattedValue: string | number | undefined;
  onChange?: (value: number) => void;
}

export default class ToolbarCoreSliderComponent extends Component<Args> {
  private _element!: HTMLElement;

  styleNamespace = getStyleNamespace("toolbar/core/slider");

  get min(): number {
    return this.args.min ?? 0;
  }

  get max(): number {
    return this.args.max ?? 100;
  }

  get step(): number {
    return this.args.step ?? 1;
  }

  get hideValue(): boolean {
    return !!this.args.hideValue;
  }

  get formattedValue(): string | number | undefined {
    return this.args.formattedValue;
  }

  get minValue(): number {
    return this.min;
  }

  get maxValue(): number {
    return this.max;
  }

  get inputElement(): HTMLInputElement | undefined {
    return this._element.querySelector(`input[type="range"]`) as HTMLInputElement;
  }

  get progressStyleAttr(): SafeString {
    const width = (this.args.value - this.min) / (this.max - this.min);
    return htmlSafe(`transform: scaleX(${width});`);
  }

  @action
  didInsert(element: HTMLElement): void {
    this._element = element;
  }

  @action
  onInput(): void {
    if (!this.inputElement) {
      return;
    }

    const value = Number(this.inputElement.value);

    if (this.args.value !== value) {
      if (this.args.onChange) {
        this.args.onChange(value);
      }
    }
  }
}
