import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { SafeString } from "handlebars";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Props {
  getNext?: () => string;
  initial?: string;
  messages: string[];
  // The duration (in ms) to wait before transitioning.
  delay?: number;
  // The length of time (in ms) the transition animation takes to complete.
  duration?: number;
}

export default class TextLoop extends Component<Props> {
  @tracked
  isActive = false;

  @tracked
  curr = this.initial;

  @tracked
  next = this.getNextMessage();

  styleNamespace = getStyleNamespace("text-loop");

  cycle = (): void => {
    this.isActive = true;

    setTimeout(() => {
      this.isActive = false;
      this.curr = this.next;
      this.next = this.getNextMessage();
    }, this.duration);
  };

  get initial(): string {
    return this.args.initial || this.getNextMessage();
  }

  get delay(): number {
    return this.args.delay || 8000;
  }

  get duration(): number {
    return this.args.duration || 300;
  }

  get style(): SafeString {
    return htmlSafe(`--duration: ${this.duration}ms`);
  }

  getRandomMessage(): string {
    return this.args.messages[Math.floor(Math.random() * this.args.messages.length)]!;
  }

  getNextMessage(): string {
    return this.args.getNext?.() ?? this.getRandomMessage();
  }

  @action
  start(): void {
    setInterval(this.cycle, this.delay);
  }
}
