import type { Project } from "@biteable/network-model";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Comment from "client/models/comment";

interface ExportCommentsThreadsArgs {
  comments: Array<Comment>;
  hasReplyForm?: boolean;
  hasTimestamp?: boolean;
  isThreaded?: boolean;
  parentCommentId?: string;
  project: Project;
  renderRequired: boolean;
  isRendering: boolean;
}

export default class ExportCommentsThreadsComponent extends Component<ExportCommentsThreadsArgs> {
  styleNamespace = getStyleNamespace("export-comments/threads");

  get isThreaded(): boolean {
    return this.args.isThreaded ?? true;
  }
}
