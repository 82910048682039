import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import formatMoney from "client/lib/format-money";
import getStyleNamespace from "client/lib/get-style-namespace";
import recalculate from "client/lib/recalculate-aspect-ratio";
import type Favorable from "client/models/favorable";
import { FAVORABLE_TYPES } from "client/models/favorable";
import type Favorite from "client/models/favorite";
import type SelectableAsset from "client/models/selectable-asset";
import type StockLicense from "client/models/stock-license";
import Svg from "client/models/svg";
import type UserAsset from "client/models/user-asset";
import type AuthService from "client/services/auth";
import type VideoPlayerService from "client/services/video-player";

interface AssetThumbnailArgs {
  asset: SelectableAsset;
  assetPrice?: string;
  assetPurchased?: boolean;
  disabled?: boolean;
  caption?: string;
  captionUrl?: string;
  thumbImageUrl?: string;
  loadingMessage?: string;
  preload?: "none" | "auto";
  noFavoriteIcon?: boolean;
  favorite?: Favorite;
  unfavoritedState?: boolean;
  downloadable?: boolean;
  previewable?: boolean;
  previewOnSelect?: boolean;
  addToTimeline?: boolean;
  description?: string;
  aspectRatio?: string;
  selected?: boolean;
  showAssetName?: boolean;
  title?: string;
  author?: string;
  type?: string;
  showOptionsMenu?: boolean;
  isTeamFavorite?: boolean;
  videoPageUrl?: string;
  shouldInferAspectRatio?: boolean;
  stockLicenses?: StockLicense[];

  onFavorite?: (asset: SelectableAsset) => void;
  onUnfavorite?: (favorite: Favorite) => void;
  onSelect?: (asset: SelectableAsset) => void;
  onRemove?: (asset: SelectableAsset) => void;
}

const DEFAULT_MAX_ASSET_NAME_LENGTH = 31;
const MAX_ASSET_NAME_LENGTH_FOR_FOUR_BY_THREE = 19;
const MAX_ASSET_NAME_LENGTH_FOR_ONE_BY_ONE = 14;

export default class AssetThumbnailComponent extends Component<AssetThumbnailArgs> {
  @service
  declare auth: AuthService;

  @service
  declare videoPlayer: VideoPlayerService;

  @tracked
  previewing = false;

  @tracked
  previewOnSelect = this.args.previewOnSelect ?? false;

  @tracked
  isHover = false;

  styleNamespace = getStyleNamespace("asset-thumbnail");

  get assetPrice(): string | undefined {
    return this.args.assetPrice ?? this.computeAssetPrice;
  }

  get assetPurchased(): boolean {
    return this.args.assetPurchased ?? this.computeAssetPurchased;
  }

  get computeAssetPrice(): string | undefined {
    const favorableType = (this.args.asset as unknown as Favorable).favorableType;
    if (favorableType === FAVORABLE_TYPES.SHUTTERSTOCK) {
      const user = this.auth.currentUser!;
      return formatMoney(" ", user.shutterstockPrice, user.shutterstockCurrency);
    }
    return undefined;
  }

  get computeAssetPurchased(): boolean {
    const assetId = (this.args.asset as unknown as Favorable).favorableProviderAssetId;
    return !!this.args.stockLicenses?.find((license) => assetId && license.stockAsset.providerAssetId === assetId);
  }

  get showShutterstockPriceTag(): boolean {
    return !!this.assetPrice || this.assetPurchased;
  }

  @action
  openPreview(): void {
    this.previewing = true;
  }

  @action
  closePreview(): void {
    this.previewing = false;
    (this.asset as UserAsset).assetType === "video" && this.videoPlayer.reset();
  }

  @action
  handleFavorite(): void {
    if (this.isFavorite) {
      this.unfavorite();
    } else {
      this.favorite();
    }
  }

  @action
  handleSelect(): void {
    if (!this.isUnfavorited && !this.args.disabled && !!this.args.onSelect) {
      this.args.onSelect?.(this.asset);
      if (this.previewing) {
        this.closePreview();
      }
    }
    if (this.previewOnSelect && !this.previewing) {
      this.openPreview();
    }
  }

  @action
  handleRemove(): void {
    if (this.canRemove) {
      this.args.onRemove?.(this.asset);
    }
  }

  @action
  onMouseEnter(): void {
    this.isHover = true;
  }

  @action
  onMouseLeave(): void {
    this.isHover = false;
  }

  asset: SelectableAsset;

  @tracked
  unfavorited = false;

  @tracked
  showAssetName = this.args.showAssetName || false;

  @tracked
  declare assetName: string;

  @tracked
  declare trimmedAssetName: string;

  constructor(owner: object, args: AssetThumbnailArgs) {
    super(owner, args);

    // Store the asset on the component as if it's "unfavorited" the parent favorite will be destroyed.
    // By storing the asset we can continue to reference it.
    this.asset = this.args.asset;

    if (this.showAssetName) {
      this.assetName = (this.args.asset as UserAsset).name;
      this.trimmingAssetNameBasedOnAspectRatio();
    }
  }

  get showProviderAttribution(): boolean {
    if (this.args.asset) {
      return [FAVORABLE_TYPES.PEXELS, FAVORABLE_TYPES.PEXELS_VIDEOS, FAVORABLE_TYPES.UNSPLASH]
        .map((ft) => ft.toString())
        .includes((this.args.asset as unknown as Favorable).favorableType);
    }
    return false;
  }

  private trimmingAssetNameBasedOnAspectRatio(): void {
    switch (this.aspectRatio) {
      case "4:3":
        this.trimAssetName(MAX_ASSET_NAME_LENGTH_FOR_FOUR_BY_THREE);
        break;
      case "1:1":
        this.trimAssetName(MAX_ASSET_NAME_LENGTH_FOR_ONE_BY_ONE);
        break;
      default:
        this.trimAssetName(DEFAULT_MAX_ASSET_NAME_LENGTH);
        break;
    }
  }

  private trimAssetName(maxLength: number): void {
    this.trimmedAssetName =
      this.assetName.length < maxLength ? this.assetName : this.assetName.substring(0, maxLength - 3) + "...";
  }

  private unfavorite(): void {
    if (this.canUnfavorite) {
      this.unfavorited = true;
      this.args.onUnfavorite?.(this.args.favorite!);
    }
  }

  private favorite(): void {
    if (this.canFavorite) {
      this.unfavorited = false;
      this.args.onFavorite?.(this.asset);
    }
  }

  get isFavorite(): boolean {
    return !!this.favoriteRecord && !this.favoriteRecord.isDeleted;
  }

  get favoriteRecord(): Favorite | undefined {
    return this.args.favorite || (this.asset as unknown as Favorable).favoriteRecord;
  }

  get isFavorable(): boolean {
    return !this.args.noFavoriteIcon && (this.canFavorite || this.canUnfavorite) && !this.args.isTeamFavorite;
  }

  get canSelect(): boolean {
    return (!this.isUnfavorited && !this.args.disabled && !!this.args.onSelect) || this.previewOnSelect;
  }

  get canFavorite(): boolean {
    return !this.args.disabled && !!this.args.onFavorite && !this.isFavorite;
  }

  get canUnfavorite(): boolean {
    return !this.args.disabled && !!this.args.onUnfavorite && this.isFavorite;
  }

  get defaultRemoveButton(): boolean {
    return this.canRemove && !this.args.showOptionsMenu;
  }

  get canRemove(): boolean {
    return !this.args.disabled && !!this.args.onRemove;
  }

  get aspectRatio(): string {
    const aspectRatio = this.args.aspectRatio ?? this.asset.thumbAspectRatio;

    return recalculate(aspectRatio);
  }

  get image(): string | undefined {
    return this.args.thumbImageUrl ?? this.asset.thumbImageUrl;
  }

  get video(): string | undefined {
    return this.asset.thumbVideoUrl;
  }

  get isUnfavorited(): boolean {
    return !!this.args.unfavoritedState && this.unfavorited;
  }

  get disabled(): boolean {
    return this.args.disabled || this.isUnfavorited;
  }

  get isSvg(): boolean {
    return this.args.asset instanceof Svg;
  }
}
