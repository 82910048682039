import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type { ProjectSceneWatermarkRouteModel } from "client/authenticated/project/scene/watermark/route";

export default class ProjectSceneWatermarkIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    const { projectScene } = this.modelFor("authenticated.project.scene.watermark") as ProjectSceneWatermarkRouteModel;
    const caption = await projectScene.firstCaption;

    if (caption && caption.hasZymbols) {
      void this.router.transitionTo("authenticated.project.scene.caption.watermark", caption.id);
    }
  }
}
