import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { EventRegister, Scene } from "client/lib/editor-domain-model";
import { ChangeSceneFilterColorMutation, SceneBackgroundColorMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import type BrandStyle from "client/models/brand-style";

interface Args {
  scene: Scene;
  eventRegister: EventRegister;
  brandStyle?: BrandStyle;
  save: () => void;
}

export default class ToolbarBackgroundColorsComponent extends Component<Args> {
  @service
  declare router: RouterService;

  styleNamespace = getStyleNamespace("toolbar/background-colors");

  @action
  saveBackgroundColor(color: string): void {
    const { eventRegister, scene } = this.args;
    eventRegister.fire(new SceneBackgroundColorMutation(scene, color));
    this.args.save();
  }

  @action
  saveFilterColor(color: string): void {
    const { eventRegister, scene } = this.args;
    eventRegister.fire(new ChangeSceneFilterColorMutation(scene, color));
    this.args.save();
  }

  get brand(): BrandStyle | undefined {
    return this.args.brandStyle;
  }

  get brandStyleConfig(): BrandStyleConfig | undefined {
    return this.brand?.style;
  }

  get backgroundColors(): string[] {
    return this.args.scene?.color ? [this.args.scene.color] : [];
  }

  get filterColors(): string[] {
    return this.args.scene?.filterColor ? [this.args.scene.filterColor] : [];
  }
}
