import { action } from "@ember/object";
import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class DragAndDropService extends Service {
  @tracked
  content: any;

  @action
  onDragScroll(event: DragEvent, selector?: string): void {
    let { scrollY } = window;
    let scrollableObject: { scrollTo: Function } = window;
    if (selector) {
      scrollableObject = document.querySelector(selector) as HTMLElement;
      scrollY = (scrollableObject as HTMLElement)?.scrollTop;
    }

    const THRESHOLD = 100;
    const isBottomScroll = event.clientY > window.innerHeight - THRESHOLD;
    const isTopScroll = scrollY > 0 && event.clientY < THRESHOLD;
    let options: ScrollToOptions = {};

    if (isBottomScroll) {
      options = { top: scrollY + 5 };
    } else if (isTopScroll) {
      options = { top: scrollY - 5 };
    }

    scrollableObject.scrollTo(options);
  }
}
