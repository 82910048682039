import { action } from "@ember/object";
import Component from "@glimmer/component";
import type { Placement } from "@popperjs/core";
import { Alignments } from "renderer-engine";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  xAlignment: Alignments;
  yAlignment: Alignments;
  position?: Placement;
  setTextAlignment: (axis: "x" | "y", alignment: Alignments) => Promise<void>;
}

export default class ToolbarFontAlignmentPopoverComponent extends Component<Args> {
  styleNamespace = getStyleNamespace("toolbar/font/alignment/popover");

  get xLeftAligned(): boolean {
    return this.args.xAlignment === Alignments.LEFT;
  }

  get xCenterAligned(): boolean {
    return this.args.xAlignment === Alignments.CENTER;
  }

  get xRightAligned(): boolean {
    return this.args.xAlignment === Alignments.RIGHT;
  }

  get yTopAligned(): boolean {
    return this.args.yAlignment === Alignments.TOP;
  }

  get yMiddleAligned(): boolean {
    return this.args.yAlignment === Alignments.CENTER;
  }

  get yBottomAligned(): boolean {
    return this.args.yAlignment === Alignments.BOTTOM;
  }

  @action
  async setXLeft(): Promise<void> {
    await this.args.setTextAlignment("x", Alignments.LEFT);
  }

  @action
  async setXCenter(): Promise<void> {
    await this.args.setTextAlignment("x", Alignments.CENTER);
  }

  @action
  async setXRight(): Promise<void> {
    await this.args.setTextAlignment("x", Alignments.RIGHT);
  }

  @action
  async setYTop(): Promise<void> {
    await this.args.setTextAlignment("y", Alignments.TOP);
  }

  @action
  async setYMiddle(): Promise<void> {
    await this.args.setTextAlignment("y", Alignments.CENTER);
  }

  @action
  async setYBottom(): Promise<void> {
    await this.args.setTextAlignment("y", Alignments.BOTTOM);
  }
}
