import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type VideoWatchEventSummary from "client/models/video-watch-event-summary";

interface AnalyticsSummaryComponentArgs {
  data: VideoWatchEventSummary;
  showUpgrade: boolean;
}

export default class AnalyticsSummaryComponent extends Component<AnalyticsSummaryComponentArgs> {
  styleNamespace = getStyleNamespace("analytics-summary");
}
