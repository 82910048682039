import type { FontVariant } from "../../font";
import type { TextStyle } from "../../scene";
import { InlineMutation } from "./vanilla-mutation";

export class ChangeTextStyleFontMutation extends InlineMutation<TextStyle> {
  constructor(public style: TextStyle, fontFamily?: string, fontVariant?: FontVariant) {
    super(style, (s) => {
      s._fontFamily = fontFamily;
      s._fontVariant = fontVariant;
    });
  }
}
