import { service } from "@ember/service";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type { OwnerTypes } from "client/models/media-asset";
import type AuthService from "client/services/auth";

interface AssetManagementBaseHeaderComponentArgs {
  order: string;
  ownerType: OwnerTypes;
  setOwnerType: () => void;
  onChangeOrder: (order: string) => void;
  title?: string;
  hideToggle?: boolean;
}

export default class AssetManagementBaseHeaderComponent extends Component<AssetManagementBaseHeaderComponentArgs> {
  @service
  declare auth: AuthService;

  styleNamespace = getStyleNamespace("asset-management/base/header");

  get hasTeam(): boolean {
    return this.auth.hasTeam || false;
  }
}
