import { action } from "@ember/object";
import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

interface Args {
  title: string;

  action: (e: PointerEvent) => void;
}

export default class CanvasActionsButton extends Component<Args> {
  styleNamespace = getStyleNamespace("canvas/actions/button");

  @action
  mouseDown(ev: MouseEvent): void {
    ev.stopPropagation();
  }

  @action
  onClick(e: PointerEvent): void {
    if (this.args.action) {
      this.args.action(e);
    }
  }

  get noClick(): boolean {
    return !this.args.action;
  }
}
