import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class ProjectTimelineActionsGroupBlockOptionsComponent extends Component {
  @tracked
  sceneMenuOpen = false;

  @tracked
  menuAnchor?: HTMLElement;

  @tracked
  isDragging = false;

  styleNamespace = getStyleNamespace("project-timeline/actions-group/other-options");

  @action
  setMenuAnchor(element: HTMLElement): void {
    this.menuAnchor = element;
  }

  @action
  toggleSceneMenu(): void {
    this.sceneMenuOpen = !this.sceneMenuOpen;
  }

  @action
  closeSceneMenu(): void {
    this.sceneMenuOpen = false;
  }
}
