import Model, { belongsTo } from "@ember-data/model";
import type SelectableAsset from "./selectable-asset";

export default class SearchableContent extends Model {
  // eslint-disable-next-line no-null/no-null
  @belongsTo("selectable-asset", { polymorphic: true, async: false, inverse: null })
  declare searchable: SelectableAsset;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    searchableContent: SearchableContent;
  }
}
