import { action } from "@ember/object";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import moment from "moment";
import getStyleNamespace from "client/lib/get-style-namespace";
import type Discount from "client/models/discount";
import type Plan from "client/models/plan";
import type Subscription from "client/models/subscription";
import type SubscriptionSchedule from "client/models/subscription-schedule";
import type AuthService from "client/services/auth";
import type UpgradeService from "client/services/upgrade";

interface CheckoutPlanConfirmationArgs {
  subscription: Subscription;
}

export default class CheckoutPlanConfirmationComponent extends Component<CheckoutPlanConfirmationArgs> {
  styleNamespace = getStyleNamespace("purchase/checkout-plan-confirmation");

  @service
  declare auth: AuthService;

  @service
  declare upgrade: UpgradeService;

  @service
  declare router: RouterService;

  @action
  async close(): Promise<void> {
    this.upgrade.close();
  }

  @action
  async addTeamMembers(): Promise<void> {
    this.upgrade.close();
    void this.router.transitionTo("authenticated.account.team");
  }

  @action
  async makeVideos(): Promise<void> {
    void this.close();
    void this.router.transitionTo("authenticated.home");
  }

  get discount(): Discount | undefined {
    return this.subscription.discount;
  }

  get subscription(): Subscription {
    return this.args.subscription;
  }

  get plan(): Plan {
    return this.args.subscription.plan;
  }

  get subscriptionSchedule(): SubscriptionSchedule | undefined {
    return this.subscription.subscriptionSchedule;
  }

  get hasSubscriptionSchedule(): boolean {
    return !!this.subscriptionSchedule;
  }

  get firstPaymentMessage(): string {
    return this.isPlanChanged ? this.changedPlanFirstPaymentMessage : this.newSubscriptionFirstPaymentMessage;
  }

  get scheduledPaymentMessage(): string | void {
    const nextPlan = this.subscriptionSchedule?.nextPlan;

    if (nextPlan) {
      const message = `Your card on file will be charged ${nextPlan.formattedMonetaryUnits}`;
      return this.generateNextPaymentMessage(
        this.subscriptionSchedule?.scheduledPhaseStartDate,
        nextPlan.isMonthly,
        message
      );
    }
  }

  get nextPaymentMessage(): string {
    const message = this.discount ? this.discountPriceMessage : this.getStandardPriceMessage(this.plan);
    return this.generateNextPaymentMessage(this.currentPeriodEnd, this.plan.isMonthly, message);
  }

  private generateNextPaymentMessage(periodEnd: Date | undefined, isMonthlyPlan: boolean, message: string): string {
    const renewalDate = moment(periodEnd).format("Do");
    const renewalMonth = moment(periodEnd).format("MMMM");

    const paymentPeriod = isMonthlyPlan ? `${renewalDate} of each month` : `${renewalDate} of ${renewalMonth}, yearly`;

    return `${message} on the ${paymentPeriod}. Cancel anytime.`;
  }

  get showTeamButton(): boolean {
    return !!(this.auth.currentUser?.canManageTeam && this.plan.hasPublisherSeats);
  }

  private get discountPriceMessage(): string {
    if (this.discount?.isForever) {
      return `We will charge you the discount price of ${this.price}`;
    } else if (this.discount?.isOnce || this.discountPaymentsCount < 1) {
      return `We will charge you the standard price of ${this.plan.formattedMonetaryUnits}`;
    } else {
      const payment = this.discountPaymentsCount === 1 ? "payment" : `${this.discountPaymentsCount} payments`;
      return `Your next ${payment} will be charged at the discount price of ${this.price}. After this, you will be charged the standard price of ${this.plan.formattedMonetaryUnits}. We will charge you`;
    }
  }

  private getStandardPriceMessage(plan: Plan): string {
    return `We will charge you ${plan.formattedMonetaryUnits}`;
  }

  private get changedPlanFirstPaymentMessage(): string {
    return `Your card was charged your ${
      this.isMonthlyPlan ? "monthly" : "yearly"
    } amount, minus any unused portion of your previous billing cycle.`;
  }

  private get newSubscriptionFirstPaymentMessage(): string {
    if (this.discount) {
      return `We applied your discount, charged your card ${this.price}, and sent a receipt to your inbox.`;
    }

    return `We have charged your card ${this.plan.formattedMonetaryUnits} and sent a receipt to your inbox.`;
  }

  private get price(): string {
    return this.discount?.price ?? this.plan.formattedMonetaryUnits;
  }

  private get isMonthlyPlan(): boolean {
    return this.plan.isMonthly;
  }

  private get isPlanChanged(): boolean {
    return this.upgrade.planImmediatelyChanged;
  }

  private get currentPeriodEnd(): Date | undefined {
    return this.subscription.currentPeriodEnd;
  }

  private get discountPaymentsCount(): number {
    if (this.discount?.durationInMonths) {
      return this.isMonthlyPlan ? this.discount.durationInMonths - 1 : Math.floor(this.discount.durationInMonths / 12);
    }

    return 0;
  }
}
