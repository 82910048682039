import Evented from "@ember/object/evented";
import Service from "@ember/service";

const DEBUGGING_LOCALSTORAGE_KEY = "biteable:showEventLogging";

export default abstract class BaseEventService extends Service.extend(Evented) {
  abstract eventLogPrefix: string;

  publish(eventName: string, ...args: any[]): void {
    if (window.localStorage.getItem(DEBUGGING_LOCALSTORAGE_KEY)) {
      // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
      console.info(`${this.eventLogPrefix}:${eventName}`, ...args);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.trigger(eventName, ...args);
  }

  subscribe(eventName: string, handler: (...args: any[]) => void): void {
    this.on(eventName, handler);
  }

  unsubscribe(eventName: string, handler: (...args: any[]) => void): void {
    this.off(eventName, handler);
  }
}
