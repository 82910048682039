import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { AudioClipCategory } from "renderer-engine";
import type { AudioClip, AudioAsset, EventRegister, Timeline } from "client/lib/editor-domain-model";
import { AddAudioClipMutation, AudioClipAssetMutation, DeleteAudioClipMutation } from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";
import { transaction } from "client/lib/transaction";
import type AudioTrackService from "client/services/audio-tracks";
import type HoneybadgerService from "client/services/honeybadger";
import type NotificationsService from "client/services/notifications";
import type PropertiesPanelService from "client/services/properties-panel";

interface SoundtrackChangeModalComponentArgs {
  eventRegister: EventRegister;
  timeline: Timeline;
  onCloseModal: () => void;
}

export default class SoundtrackChangeModalComponent extends Component<SoundtrackChangeModalComponentArgs> {
  @service
  declare audioTracks: AudioTrackService;

  @service
  declare honeybadger: HoneybadgerService;

  @service
  declare notifications: NotificationsService;

  @service
  declare propertiesPanel: PropertiesPanelService;

  @tracked
  shownTrackList: "public" | "upload" = "public";

  styleNamespace = getStyleNamespace("project-timeline/soundtrack-change/soundtrack-change-modal");

  @action
  selectPublic(): void {
    this.shownTrackList = "public";
  }

  @action
  selectUpload(): void {
    this.shownTrackList = "upload";
  }

  get audioClip(): AudioClip | undefined {
    return this.args.timeline.audioClips.find((audioClip) => audioClip.category === AudioClipCategory.MUSIC);
  }

  @tracked
  audioAssetUpdate?: {
    audioAsset?: AudioAsset;
  };

  @action
  @transaction
  async onSave(): Promise<void> {
    this.args.onCloseModal();

    if (!this.audioAssetUpdate) {
      return;
    }

    const audioAsset = this.audioAssetUpdate.audioAsset;
    if (audioAsset === this.audioClip?.asset) {
      return;
    }

    if (audioAsset) {
      if (this.audioClip) {
        this.args.eventRegister.fire(new AudioClipAssetMutation(this.audioClip, audioAsset));
      } else {
        const add = new AddAudioClipMutation(this.args.timeline, audioAsset, AudioClipCategory.MUSIC);
        await add.prepare(this.args.eventRegister.facade);
        this.args.eventRegister.fire(add);
      }
    } else {
      if (this.audioClip) {
        this.args.eventRegister.fire(new DeleteAudioClipMutation(this.args.timeline, this.audioClip));
      }
    }
    this.audioAssetUpdate = undefined;

    await this.args.eventRegister.facade.saveAudioClips(this.args.timeline);
    this.propertiesPanel.open();
    this.notifications.success("Your project's soundtrack has been updated");
  }

  @action
  async onSoundtrackSelect(audioAsset: AudioAsset): Promise<void> {
    this.audioAssetUpdate = { audioAsset };
  }

  get selectedTrack(): AudioAsset | undefined {
    return this.audioAssetUpdate ? this.audioAssetUpdate?.audioAsset : this.audioClip?.asset;
  }
}
