import Model, { attr } from "@ember-data/model";
import type { RenderZymbol } from "renderer-engine";

export default class ThumbnailScene extends Model {
  @attr("json", { defaultValue: () => [] })
  declare zymbolsToRender: RenderZymbol[];
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    thumbnailScene: ThumbnailScene;
  }
}
