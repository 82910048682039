import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export default class BreadcrumbsItemComponent extends Component<{
  separator?: boolean;
}> {
  styleNamespace = getStyleNamespace("north-star/breadcrumbs/item");

  get separator(): string {
    return String(this.args.separator ?? true);
  }
}
