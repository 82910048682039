import { attr } from "@ember-data/model";
import type { VideoConfig, Asset } from "renderer-engine";
import { ZymbolCategory } from "renderer-engine";
import TrackingEvents from "client/events";
import { favorable, FAVORABLE_TYPES } from "client/models/favorable";
import SelectableMediaAsset from "client/models/selectable-media-asset";

@favorable
export default class ShutterstockFootage extends SelectableMediaAsset {
  @attr("string")
  description!: string;

  @attr("number")
  duration!: number;

  @attr("string")
  aspectRatio!: string;

  @attr("string")
  previewImageUrl!: string;

  @attr("string")
  previewVideoUrl!: string;

  @attr("string")
  lowResVideoUrl!: string;

  @attr("string")
  searchId?: string;

  get thumbImageUrl(): string {
    return this.previewImageUrl;
  }

  get thumbVideoUrl(): string {
    return this.previewVideoUrl;
  }

  get favorableProviderAssetId(): string {
    return this.id;
  }

  readonly favorableType = FAVORABLE_TYPES.SHUTTERSTOCK;
  readonly zymbolCategory = ZymbolCategory.VIDEO;

  asZymbolConfig(): Partial<VideoConfig> & { asset: Asset } {
    const { id, previewImageUrl, previewVideoUrl, lowResVideoUrl, duration, searchId } = this;

    return {
      asset: {
        id,
        type: "shutterstock-footage"
      },
      duration,
      previewImageUrl,
      previewVideoUrl,
      lowResVideoUrl,
      downsizedUrlBase: undefined,
      url: lowResVideoUrl,
      name: `shutterstock-${id}`,
      startTime: 0,
      license: {
        id,
        licensor: "shutterstock",
        metadata: {
          searchId
        }
      }
    };
  }

  readonly trackUsageEvent = TrackingEvents.EVENT_SELECT_STOCK_CONTENT;

  get trackUsageData(): object {
    return {
      provider: "shutterstock",
      providerAssetId: this.id,
      type: "video",
      name: this.description
    };
  }

  get metadataForFavorite(): { searchId: string | undefined } {
    return {
      searchId: this.searchId
    };
  }
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    shutterstockFootage: ShutterstockFootage;
  }
}
