import Component from "@glimmer/component";
import getStyleNamespace from "client/lib/get-style-namespace";

export enum UIGridGap {
  NONE = "none",
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
  HUGE = "huge"
}

export enum UIGridAlign {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom"
}

interface UIGridArgs {
  align?: UIGridAlign;
  columns?: number;
  gap?: UIGridGap;
}

export default class UIGridComponent extends Component<UIGridArgs> {
  styleNamespace = getStyleNamespace("ui/ui-grid");

  get align(): UIGridAlign {
    return this.args.align ?? UIGridAlign.CENTER;
  }

  get columns(): number {
    return this.args.columns ?? 6;
  }

  get gap(): UIGridGap {
    return this.args.gap ?? UIGridGap.DEFAULT;
  }
}
