import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import type { ContentTypeOption } from "client/components/discovery/content/search/component";
import getStyleNamespace from "client/lib/get-style-namespace";
import type SelectableAsset from "client/models/selectable-asset";
import { ALL_CONTENTS } from "client/services/contents-curator";

export interface ContentQueryParams {
  query?: string;
  per_page?: number;
  page?: number;
  type?: ContentTypeOption;
}

interface ContentResultsBaseArgs {
  load: (params: Record<string, string | number>) => Promise<Array<SelectableAsset>>;
  params: ContentQueryParams;
  emptyResults?: boolean;
}

const DEFAULT_PER_PAGE = 25;
export default class ContentResultsBaseComponent extends Component<ContentResultsBaseArgs> {
  styleNamespace = getStyleNamespace("discovery/content/results/base");

  @tracked
  page?: number | string;

  @action
  async load(params: Record<string, string | number>): Promise<Array<SelectableAsset>> {
    this.page = params["page"];
    return this.args.load(params);
  }

  get perPage(): number {
    return this.args.params["per_page"] ?? DEFAULT_PER_PAGE;
  }

  get showNotification(): boolean {
    return !!this.query?.length && this.args.emptyResults === true;
  }

  get query(): string | undefined {
    return this.args.params.query;
  }

  get notification(): string {
    const { query, type } = this.args.params;

    const baseMessage = `We couldn't find anything related to "${query}", check out `;
    const specificContentMessage =
      type?.values.length === ALL_CONTENTS.length ? "the latest content from Biteable Studio" : `other ${type?.label}`;

    return baseMessage + specificContentMessage;
  }
}
