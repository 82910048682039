import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface TagInputTagArgs {
  tag: string;
  onClose: () => void;
  onValidated?: (tag: string, valid: boolean, errors: string[]) => void;
  validateTag?: (tag: string) => Promise<string[]>;
}

export default class Tag extends Component<TagInputTagArgs> {
  styleNamespace = getStyleNamespace("north-star/tag-input/tag");

  @tracked
  messages: string[] = [];

  @action
  async validate(): Promise<void> {
    if (this.args.validateTag) {
      this.messages = await this.args.validateTag(this.args.tag);
    }

    this.args.onValidated?.(this.args.tag, this.valid, this.messages);
  }

  get valid(): boolean {
    return this.messages.length === 0;
  }
}
