import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";

interface BrandFontFamilySelectArgs {
  font?: string;
  onChange(fontFamily: string): unknown;
}

export default class BrandFontFamilySelectComponent extends Component<BrandFontFamilySelectArgs> {
  @tracked
  font = this.args.font ?? "";

  styleNamespace = getStyleNamespace("brand/font-family-select");

  @action
  onChange(font?: string): void {
    if (font) {
      this.args.onChange(font);
    }
  }
}
