import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { upperFirst } from "lodash";
import { SceneTransition, SCENE_TRANSITIONS } from "renderer-engine";
import deSnakeCase from "client/helpers/de-snake-case";
import type { Scene, TimelineMutation } from "client/lib/editor-domain-model";
import * as DomainModel from "client/lib/editor-domain-model";
import getStyleNamespace from "client/lib/get-style-namespace";

interface ProjectTimelineTransitionsArgs {
  title: string;
  scene: Scene;
  mutate: (mutation: TimelineMutation) => void;
  saveScene: (scene: Scene) => void;
  onClose(): unknown;
}

export default class ProjectTimelineTransitionsComponent extends Component<ProjectTimelineTransitionsArgs> {
  @tracked
  transitionsMenuOpen = false;

  @tracked
  menuAnchor?: HTMLElement;

  styleNamespace = getStyleNamespace("project-timeline/transitions");

  sceneTransitions = SCENE_TRANSITIONS;

  get hasTransition(): boolean {
    const { transition } = this.args.scene;
    return !!transition && transition.name !== SceneTransition.NONE;
  }

  get transitionDescription(): string {
    if (this.hasTransition) {
      const { transition } = this.args.scene;
      return `${upperFirst(deSnakeCase(transition!.name))} selected`;
    } else {
      return "None selected";
    }
  }

  get tooltip(): string {
    if (this.hasTransition) {
      return this.args.title;
    } else {
      return `Add ${this.args.title.toLowerCase()}`;
    }
  }

  @action
  setMenuAnchor(element: HTMLElement): void {
    this.menuAnchor = element;
  }

  @action
  toggleTransitionsMenu(): void {
    this.transitionsMenuOpen = !this.transitionsMenuOpen;
  }

  @action
  closeTransitionsMenu(): void {
    this.transitionsMenuOpen = false;
  }

  @action
  selectTransition(transition?: string): void {
    const mutation = new DomainModel.SceneTransitionMutation(
      this.args.scene,
      new DomainModel.Transition(transition ?? SceneTransition.NONE)
    );
    this.args.mutate(mutation);
    this.args.saveScene(this.args.scene);
  }
}
