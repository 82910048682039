import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";
import type ProjectThumbnailUpdateService from "client/services/project-thumbnail-update";

interface ProjectPreviewThumbnailArgs {
  projectPreview: ProjectPreview;
}

const EMPTY_IMAGE_DATA_URI =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mM8c+7WfwAIVwN1ZWQpkwAAAABJRU5ErkJggg==";

export default class ProjectPreviewThumbnailComponent extends Component<ProjectPreviewThumbnailArgs> {
  @service
  declare projectThumbnailUpdate: ProjectThumbnailUpdateService;

  @tracked
  loading = false;

  styleNamespace = getStyleNamespace("discovery/project-preview/thumbnail");

  @action
  async loadThumbnail(): Promise<void> {
    this.loading = true;

    try {
      await this.args.projectPreview.generateThumbnail();
    } finally {
      this.loading = false;
    }
  }

  get posterUrl(): string {
    return this.args.projectPreview.posterUrl ?? EMPTY_IMAGE_DATA_URI;
  }

  get backgroundStyles(): SafeString | undefined {
    if (this.posterUrl) {
      return htmlSafe(`background-image: url(${this.posterUrl})`);
    } else {
      return undefined;
    }
  }
}
