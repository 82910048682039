import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import type { SafeString } from "@ember/template/-private/handlebars";
import { isEmpty } from "@ember/utils";
import Component from "@glimmer/component";
import { lumaFromHex } from "client/lib/color";
import getStyleNamespace from "client/lib/get-style-namespace";
import { parseHex } from "client/lib/rgb-hex";

interface ColorPickerBrandedSwatchArgs {
  onClick?: (color?: string) => void;
  color?: string;
  opacity?: number;
  currentColors?: (string | undefined)[];
}

export default class ColorPickerBrandedSwatchComponent extends Component<ColorPickerBrandedSwatchArgs> {
  styleNamespace = getStyleNamespace("color-picker-branded/color-swatch");

  @action
  selectColor(): void {
    this.args.onClick?.(this.args.color);
  }

  get hasColor(): boolean {
    return !isEmpty(this.args.color);
  }

  get checkmarkColor(): string {
    const hexValue = parseHex(this.args.color)?.value;

    if (!hexValue || lumaFromHex(hexValue) < 0.9) {
      return "#FFFFFF";
    } else {
      return "#3C3C3B";
    }
  }

  get selected(): boolean {
    // If swatch button is transparent, select the transparent swatch picker.
    if (this.args.currentColors?.length === 0 && !this.args.color) {
      return true;
    }

    const currentColorsNoAlpha = this.args.currentColors?.map((x) => x?.substring(0, 7).toUpperCase());

    return currentColorsNoAlpha?.includes(this.args.color?.toUpperCase()) ?? false;
  }

  get opacity(): number {
    if (typeof this.args.opacity === "number") {
      return Math.max(0, Math.min(1, this.args.opacity));
    } else {
      return 1;
    }
  }

  get colorStyles(): SafeString | undefined {
    if (this.hasColor) {
      return htmlSafe(`background-color: ${this.args.color}; opacity: ${this.opacity}`);
    } else {
      return undefined;
    }
  }
}
