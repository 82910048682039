import type BrandLogo from "client/lib/brand/brand-logo";
import type { TextStyles } from "client/lib/brand/brand-style-config";
import type BrandStyleConfig from "client/lib/brand/brand-style-config";
import type { ColorPresetJSON } from "client/lib/brand/color-preset";
import type ColorPreset from "client/lib/brand/color-preset";
import type {
  AnimatedLayer,
  ColorBrandKey,
  TextStyleBrandKey,
  EventRegister,
  Logo,
  Scene,
  StrictMutation,
  Text,
  TextStyle,
  Media
} from "client/lib/editor-domain-model";
import {
  Animation,
  AnimationColorMutation,
  ChangeImageUrlMutation,
  ChangeTextStyleColorMutation,
  ChangeTextStyleFontMutation,
  ChangeTextStyleRibbonColorMutation,
  Frame,
  FrameColorMutation,
  Image,
  SceneBackgroundColorMutation,
  SceneColorPresetMutation,
  UpdateFrameMutation
} from "client/lib/editor-domain-model";
import { isBlockTextStyle } from "client/lib/text/text-styles";

/**
 * The grayscale reference color preset
 */
export const DEFAULT_COLOR_PRESET = Object.freeze({
  hash: "cd1d98939ca71babcb2de6797a435535e1a95aa9",
  background: "#F2F2F5",
  primary: "#313035",
  secondary: "#8A898E",
  text: "#1D1C1F"
});

export default class BrandApplier {
  private eventRegister;
  private computeColorBrandKeys;
  private referenceColorPreset;

  constructor({
    eventRegister,
    computeColorBrandKeys = false,
    referenceColorPreset = DEFAULT_COLOR_PRESET
  }: {
    eventRegister?: EventRegister;
    computeColorBrandKeys?: boolean;
    referenceColorPreset?: ColorPresetJSON;
  }) {
    this.eventRegister = eventRegister;
    this.computeColorBrandKeys = computeColorBrandKeys;
    this.referenceColorPreset = referenceColorPreset;
  }

  public applyBrandToScene(scene: Scene, brand: BrandStyleConfig, colorPreset?: ColorPreset): void {
    const chosenColor = colorPreset || BrandApplier.getColorPreset(brand.colorPresets);

    if (chosenColor) {
      const colorPresetJson = chosenColor.toJSON();

      if (colorPresetJson) {
        this.applyColorPresetToScene(scene, colorPresetJson);
      }
    }

    this.applyBrandTextStylesToScene(scene, brand.textStyles);

    const brandLogo = this.getBrandLogo(brand.logos);

    if (brandLogo) {
      this.applyBrandLogoToScene(scene, brandLogo);
    }
  }

  public applyColorPresetToScene(scene: Scene, colorPreset: ColorPresetJSON): void {
    const colorBrandKey = this.getColorBrandKey(scene.color, scene.colorBrandKey);

    if (colorBrandKey) {
      const brandColor = this.getBrandColor(colorBrandKey, colorPreset);

      if (brandColor) {
        this.fire(new SceneBackgroundColorMutation(scene, brandColor));
      }
    }

    this.applyColorPresetToMedia(scene.background, colorPreset);

    for (const caption of scene.captions) {
      for (const text of caption.texts) {
        for (const textStyle of text.styles) {
          this.applyColorPresetToTextStyle(textStyle, colorPreset);
        }
      }
      for (const logo of caption.logos) {
        if (!this.logoIsReplaceable(logo) && logo.asset instanceof Image && logo.asset.frame) {
          this.applyColorPresetToFrame(logo.asset.frame, colorPreset);
        } else if (logo.asset instanceof Animation) {
          this.applyColorPresetToMedia(logo, colorPreset);
        }
      }
    }

    this.fire(new SceneColorPresetMutation(scene, colorPreset));
  }

  public applyColorPresetToMedia(media: Media, colorPreset: ColorPresetJSON): void {
    if (media.asset instanceof Animation) {
      for (const animatedLayer of media.asset.clips) {
        this.applyColorPresetToAnimatedLayer(animatedLayer, colorPreset);
      }
    }
  }

  private applyColorPresetToAnimatedLayer(animatedLayer: AnimatedLayer, colorPreset: ColorPresetJSON): void {
    const colorBrandKey = this.getColorBrandKey(animatedLayer.style.color, animatedLayer.style.colorBrandKey);

    if (colorBrandKey) {
      const brandColor = this.getBrandColor(colorBrandKey, colorPreset);

      if (brandColor) {
        this.fire(new AnimationColorMutation(animatedLayer, brandColor));
      }
    }
  }

  private applyColorPresetToTextStyle(textStyle: TextStyle, colorPreset: ColorPresetJSON): void {
    const colorBrandKey = this.getColorBrandKey(textStyle.color, textStyle.colorBrandKey);

    if (colorBrandKey) {
      const brandColor = this.getBrandColor(colorBrandKey, colorPreset);

      if (brandColor) {
        this.fire(new ChangeTextStyleColorMutation(textStyle, brandColor));
      }
    }

    const ribbonColorBrandKey = this.getColorBrandKey(textStyle.ribbonColor, textStyle.ribbonColorBrandKey);

    if (ribbonColorBrandKey) {
      const ribbonBrandColor = this.getBrandColor(ribbonColorBrandKey, colorPreset);

      if (ribbonBrandColor) {
        this.fire(new ChangeTextStyleRibbonColorMutation(textStyle, ribbonBrandColor));
      }
    }
  }

  private applyColorPresetToFrame(frame: Frame, colorPreset: ColorPresetJSON): void {
    const colorBrandKey = this.getColorBrandKey(frame.color, frame.colorBrandKey);

    if (colorBrandKey) {
      const brandColor = this.getBrandColor(colorBrandKey, colorPreset);

      if (brandColor) {
        this.fire(new FrameColorMutation(frame, brandColor));
      }
    }
  }

  private applyBrandTextStylesToScene(scene: Scene, brandTextStyles: TextStyles): void {
    for (const caption of scene.captions) {
      for (const text of caption.texts) {
        this.applyBrandTextStylesToText(text, brandTextStyles);
      }
    }
  }

  public applyBrandTextStylesToText(text: Text, brandTextStyles: TextStyles): void {
    const blockTextStyle = text.styles.find(isBlockTextStyle);

    if (blockTextStyle) {
      const textStyleBrandKey = this.getTextStyleBrandKey(blockTextStyle);

      if (textStyleBrandKey) {
        const brandTextStyle = this.getBrandTextStyle(textStyleBrandKey, brandTextStyles);

        if (brandTextStyle) {
          this.fire(
            new ChangeTextStyleFontMutation(blockTextStyle, brandTextStyle.fontFamily, blockTextStyle.fontVariant)
          );
        }
      }
    }
  }

  private applyBrandLogoToScene(scene: Scene, brandLogo: BrandLogo): void {
    for (const caption of scene.captions) {
      for (const logo of caption.logos) {
        this.applyBrandLogoToLogo(logo, brandLogo);
      }
    }
  }

  private applyBrandLogoToLogo(logo: Logo, brandLogo: BrandLogo): void {
    if (this.logoIsReplaceable(logo)) {
      if (logo.asset instanceof Image && brandLogo?.image) {
        this.fire(
          new ChangeImageUrlMutation(logo.asset, logo.asset.name, brandLogo.image.sourceUrl, brandLogo.image.previewUrl)
        );
        this.fire(
          new UpdateFrameMutation(
            logo.asset,
            brandLogo.image.frame
              ? new Frame(
                  brandLogo.image.frame.shape,
                  brandLogo.image.frame.color,
                  brandLogo.image.frame.scale,
                  brandLogo.image.frame.offsetX,
                  brandLogo.image.frame.offsetY
                )
              : undefined
          )
        );
      }
    }
  }

  public static getColorPreset(colorPresets: ColorPreset[]): ColorPreset | undefined {
    return colorPresets[Math.floor(Math.random() * colorPresets.length)];
  }

  private getBrandColor(colorBrandKey: ColorBrandKey, colorPreset: ColorPresetJSON): string | undefined {
    if (colorBrandKey === "background") {
      return colorPreset.background;
    } else if (colorBrandKey === "primary") {
      return colorPreset.primary;
    } else if (colorBrandKey === "secondary") {
      return colorPreset.secondary;
    } else if (colorBrandKey === "text") {
      return colorPreset.text;
    } else {
      return undefined;
    }
  }

  private getBrandTextStyle(textStyleBrandKey: TextStyleBrandKey, brandTextStyles: TextStyles): TextStyle | undefined {
    return brandTextStyles[textStyleBrandKey];
  }

  private getBrandLogo(brandLogos: BrandLogo[]): BrandLogo | undefined {
    return brandLogos.find((brandLogo) => brandLogo.defaultLogo);
  }

  private getColorBrandKey(
    color: string | undefined,
    colorBrandKey: ColorBrandKey | undefined
  ): ColorBrandKey | undefined {
    return this.computeColorBrandKeys ? this.getColorBrandKeyFromColor(color) : colorBrandKey;
  }

  private getColorBrandKeyFromColor(color: string | undefined): ColorBrandKey | undefined {
    if (color) {
      if (this.colorIsReference(color, this.referenceColorPreset.background)) {
        return "background";
      } else if (this.colorIsReference(color, this.referenceColorPreset.primary)) {
        return "primary";
      } else if (this.colorIsReference(color, this.referenceColorPreset.secondary)) {
        return "secondary";
      } else if (this.colorIsReference(color, this.referenceColorPreset.text)) {
        return "text";
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  private getTextStyleBrandKey(blockTextStyle: TextStyle): TextStyleBrandKey | undefined {
    const { fontSize } = blockTextStyle;

    if (fontSize) {
      // https://www.notion.so/biteable/Default-Text-Properties-bde3c4b621c94ab08af085b0d055964b
      if (fontSize <= 0.5) {
        return "body";
      } else if (fontSize <= 0.625) {
        return "subheader";
      } else if (fontSize <= 1.125) {
        return "header";
      } else {
        return "title";
      }
    } else {
      return undefined;
    }
  }

  private logoIsReplaceable(logo: Logo): boolean {
    return logo.asset instanceof Image && !!logo.asset.name?.toLowerCase().includes("logo");
  }

  private colorIsReference(color: string, referenceColor: string): boolean {
    return color.substring(0, 7).toUpperCase() === referenceColor.substring(0, 7).toUpperCase();
  }

  /**
   * For the BrandApplier to be reusable to apply branding to greyscale scenes
   * the EventRegister needs to be optional so as not to fire mutations that
   * would persist the changes via the API. In order to not have to check
   * for the existence of EventRegister inside each apply* method in this class
   * it is preferable to wrap mutation fire calls in an internal method, hence
   * this.
   */
  private fire(mutation: StrictMutation): void {
    if (this.eventRegister) {
      this.eventRegister.fire(mutation);
    } else {
      mutation.run();
    }
  }
}
