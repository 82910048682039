export class Queue {
  private queue: Promise<any> = Promise.resolve();

  add(fn: () => any): Promise<any> {
    this.queue = this.queue.then(fn).catch(() => undefined);
    return this.queue;
  }

  async wait(): Promise<void> {
    await this.queue;
    return;
  }
}
