import { setOwner } from "@ember/application";
import type Owner from "@ember/owner";
import { service } from "@ember/service";
import round from "lodash/round";
import TrackingEvents from "client/events";
import type { TimeSlice, EventRegister, Text, Logo } from "client/lib/editor-domain-model";
import { ChangeCustomTimingMutation } from "client/lib/editor-domain-model";
import MediaTypes from "client/lib/media-types";
import type Project from "client/models/project";
import type AdvancedEditorService from "client/services/advanced-editor";
import type AdvancedTimingService from "client/services/advanced-timing";
import type NotificationsService from "client/services/notifications";
import type TrackingService from "client/services/tracking";

export default class ElementTimingModifier {
  @service
  declare notifications: NotificationsService;

  @service
  declare advancedTiming: AdvancedTimingService;

  @service
  declare tracking: TrackingService;

  @service
  declare advancedEditor: AdvancedEditorService;

  constructor(
    owner: Owner,
    public eventRegister: EventRegister,
    public referenceElement: Text | Logo,
    public timeSlice: TimeSlice,
    public project: Project
  ) {
    setOwner(this, owner);
  }

  public async modifyCustomTiming(): Promise<void> {
    const { duration, startOffset } = this.timeSlice;
    const { eventRegister } = this;
    const { element, scene, caption } = this.advancedEditor;
    const id = this.referenceElement.id;

    if (!element || !scene || !caption) {
      return;
    }

    const selectedElement = caption.elements.find((element) => element.id === id);

    if (!selectedElement) {
      return;
    }

    let roundedDuration: number | undefined = round(duration || 0, 1);
    const roundedOffset: number | undefined = round(startOffset || 0, 1);

    if (duration) {
      roundedDuration = adjustDurationForElement(roundedDuration, roundedOffset, this.advancedTiming.caption.duration);
    }

    eventRegister.fire(new ChangeCustomTimingMutation(selectedElement, roundedOffset, roundedDuration));
    await eventRegister.facade.saveScene(scene);
    await this.trackTimingChange(roundedDuration, roundedOffset);
  }

  private async trackTimingChange(duration: number | undefined, offset: number | undefined): Promise<void> {
    await this.tracking.sendAnalytics(TrackingEvents.EVENT_CHANGE_ELEMENT_TIMING, {
      projectId: this.project.id,
      sceneId: this.advancedTiming.caption.scene.id,
      elementId: this.referenceElement.id,
      elementType: new MediaTypes(this.referenceElement).mediaType,
      elementDuration: duration,
      elementOffset: offset,
      blockDuration: this.advancedTiming.caption.duration,
      elementCount: this.elementCount
    });
  }

  private get elementCount(): number {
    return this.advancedTiming.caption.texts.length + this.advancedTiming.caption.logos.length;
  }
}

/**
 * Adjusts the duration based on the type and conditions of the element.
 * @param duration - the original rounded duration value.
 * @param offset - the rounded offset value.
 * @param captionDuration - the duration of the caption.
 * @returns The adjusted duration value.
 */
export const adjustDurationForElement = (
  duration: number,
  offset: number,
  captionDuration: number
): number | undefined => {
  if (duration + offset >= captionDuration) {
    return undefined;
  }

  return duration > 0.5 ? duration : 0.5;
};
