import Route from "@ember/routing/route";
import type RouterService from "@ember/routing/router-service";
import { service } from "@ember/service";
import type { ProjectSceneRouteModel } from "client/authenticated/project/scene/route";

export default class ProjectSceneIndexRoute extends Route {
  @service
  declare router: RouterService;

  async beforeModel(): Promise<void> {
    const { projectScene } = this.modelFor("authenticated.project.scene") as ProjectSceneRouteModel;
    const caption = projectScene.firstCaption;

    if (caption && caption.hasZymbols) {
      void this.router.replaceWith("authenticated.project.scene.caption", caption.id);
    } else {
      void this.router.replaceWith("authenticated.project.scene.background");
    }
  }
}
