import Transform from "@ember-data/serializer/transform";

export default class TupleTransform extends Transform {
  serialize(deserialized: [unknown, unknown]): [unknown, unknown] {
    return deserialized;
  }

  deserialize(serialized: [unknown, unknown]): [unknown, unknown] {
    return serialized;
  }
}

declare module "ember-data/types/registries/transform" {
  export default interface TransformRegistry {
    tuple: TupleTransform;
  }
}
