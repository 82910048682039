export class CharacterRange {
  constructor(public readonly startOffset: number, public readonly length: number) {}

  get endOffset(): number {
    return this.startOffset + this.length;
  }

  static startAndEndOffset(startOffset: number, endOffset: number): CharacterRange {
    return new CharacterRange(startOffset, endOffset - startOffset);
  }

  intersection(range: CharacterRange): CharacterRange {
    const startOffset = Math.max(this.startOffset, range.startOffset);
    const endOffset = Math.min(this.endOffset, range.endOffset);
    const length = endOffset - startOffset;
    return length >= 0 ? new CharacterRange(startOffset, length) : new CharacterRange(0, 0);
  }

  intersects(range: CharacterRange): boolean {
    return !!this.intersection(range).length;
  }

  includes(offset: number) {
    return offset >= this.startOffset && offset < this.endOffset;
  }

  includesRange(range: CharacterRange) {
    return this.includes(range.startOffset) && (this.includes(range.endOffset) || this.endOffset === range.endOffset);
  }

  subtract(subtrahend: CharacterRange): CharacterRange[] {
    if (subtrahend.includesRange(this)) {
      return [];
    }

    if (subtrahend.includes(this.startOffset)) {
      return [CharacterRange.startAndEndOffset(subtrahend.endOffset, this.endOffset)];
    }

    if (subtrahend.includes(this.endOffset) || subtrahend.endOffset === this.endOffset) {
      return [CharacterRange.startAndEndOffset(this.startOffset, subtrahend.startOffset)];
    }

    if (this.includesRange(subtrahend)) {
      return [
        CharacterRange.startAndEndOffset(this.startOffset, subtrahend.startOffset),
        CharacterRange.startAndEndOffset(subtrahend.endOffset, this.endOffset)
      ];
    }

    return [this];
  }

  static normalise(ranges: CharacterRange[]): CharacterRange[] {
    if (ranges.length <= 1) {
      return ranges;
    }
    const sorted = [...ranges].sort((a, b) => a.startOffset - b.startOffset);

    const result = sorted.slice(0, 1);
    sorted.slice(1).forEach((range) => {
      if (result[result.length - 1]!.endOffset === range.startOffset) {
        result[result.length - 1] = CharacterRange.startAndEndOffset(
          result[result.length - 1]!.startOffset,
          range.endOffset
        );
      } else {
        result.push(range);
      }
    });
    return result;
  }
}
