import Model, { attr, hasMany } from "@ember-data/model";
import type ProjectTemplate from "./project-template";
import type { ManyArray } from "client/models/ember-data-types";
import type VideoAssistantGeneratorField from "client/models/video-assistant-generator-field";

export default class VideoAssistantGenerator extends Model {
  @attr("string")
  declare name: string;

  @attr("string")
  declare slug: string;

  @attr("string")
  declare description: string;

  @attr("string")
  declare fallbackScript: string;

  @attr("string")
  declare prompt: string;

  @attr("boolean")
  declare published: boolean;

  @attr("string")
  declare job: string;

  @attr("string")
  declare useCase: string;

  // eslint-disable-next-line no-null/no-null
  @hasMany("project-template", { async: true, inverse: null })
  declare templates: ManyArray<ProjectTemplate>;

  @hasMany("video-assistant-generator-field", { async: true, inverse: "videoAssistantGenerator" })
  declare videoAssistantGeneratorFields: ManyArray<VideoAssistantGeneratorField>;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    videoAssistantGenerator: VideoAssistantGenerator;
  }
}
