import { action } from "@ember/object";
import { service } from "@ember/service";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectContentBarService from "client/services/project-content-bar";
import { CBTriggeredBy, CBReplacingContext } from "client/services/project-content-bar";
import type StorageService from "client/services/storage";

export interface ProjectContentBarPanelArgs {
  adding: boolean;
}

const PANELS_SHOWING_TIP = ["uploads", "recordings", "stock", "graphics", "favorites"];
const CONTENT_BAR_TIP_DISMISSED_KEY = "contentBarTipDismissed";
export default class ProjectContentBarPanel extends Component<ProjectContentBarPanelArgs> {
  styleNamespace = getStyleNamespace("project-content-bar/panel");

  @service
  declare projectContentBar: ProjectContentBarService;

  @service
  declare storage: StorageService;

  @tracked
  tipDismissed?: boolean;

  private bound = false;
  private _element?: HTMLElement;

  @action
  didInsert(element: HTMLElement): void {
    this._element ??= element;
    this.addDocumentListeners();
  }

  @action
  unregisterListener(): void {
    this.removeDocumentListeners();
  }

  get indicatorIcon(): string {
    switch (this.projectContentBar.replaceMediaType) {
      case CBReplacingContext.ELEMENT:
      case CBReplacingContext.WATERMARK:
        return "icon/content-bar/stock";
      default:
        return "icon/content-bar/scenes";
    }
  }

  get indicatorMessage(): string | undefined {
    if (this.projectContentBar.replaceMediaType) {
      return `Replacing ${this.projectContentBar.replaceMediaType}`;
    }

    return undefined;
  }

  get showContentTip(): boolean {
    const tipDismissed = !!this.storage.getItem(CONTENT_BAR_TIP_DISMISSED_KEY) || this.tipDismissed;
    if (tipDismissed) {
      this.dismissContentTip();
      return false;
    } else {
      return PANELS_SHOWING_TIP.some((panel) => this.projectContentBar.panel.startsWith(panel));
    }
  }

  @action
  dismissContentTip(): void {
    this.tipDismissed = true;
    this.storage.setItem(CONTENT_BAR_TIP_DISMISSED_KEY, "true");
  }

  private addDocumentListeners(): void {
    if (!this.bound) {
      document.addEventListener("keydown", this.onDocumentKeydown);
      document.addEventListener("click", this.onDocumentClick);

      this.bound = true;
    }
  }

  private removeDocumentListeners(): void {
    if (this.bound) {
      document.removeEventListener("keydown", this.onDocumentKeydown);
      document.removeEventListener("click", this.onDocumentClick);

      this.bound = false;
    }
  }

  @action
  onDocumentKeydown(ev: KeyboardEvent): void {
    if (ev.key === "Escape") {
      void this.hideByClickContentBar();
    }
  }

  @action
  onDocumentClick(ev: MouseEvent): void {
    // checking pointerType so press Enter won't close the CB
    if (this._element && (ev as PointerEvent).pointerType) {
      // DON't use _element.contains(...), it fails on dynamic DOM tree (e.g node is removed after mouse click)
      const rect = this._element.getBoundingClientRect();
      const clickedInsideOfContentBar = ev.clientX >= rect.left && ev.clientX <= rect.right && ev.clientY >= rect.top;
      const clickedInsideOfToolbar = ev.target instanceof Element && ev.target.closest("#Smart-Toolbar");

      if (!clickedInsideOfContentBar && !clickedInsideOfToolbar) {
        void this.hideByClickEditor();
      }
    }
  }

  @action
  hideByClickContentBar(): void {
    void this.projectContentBar.hide(CBTriggeredBy.CONTENT_BAR);
  }

  private hideByClickEditor(): void {
    void this.projectContentBar.hide(CBTriggeredBy.EDITOR);
  }
}
