import Model, { attr } from "@ember-data/model";

export default class UserDestroy extends Model {
  @attr("string")
  declare fullName: string;
}

declare module "ember-data/types/registries/model" {
  export default interface ModelRegistry {
    userDestroy: UserDestroy;
  }
}
