import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import getStyleNamespace from "client/lib/get-style-namespace";
import type ProjectPreview from "client/models/project-preview";

interface ProjectPreviewShareDialogArgs {
  projectPreview: ProjectPreview;
  onOpen?(): unknown;
}

export default class ProjectPreviewShareDialogComponent extends Component<ProjectPreviewShareDialogArgs> {
  @tracked
  showShareDialog = false;

  styleNamespace = getStyleNamespace("discovery/project-preview/share");

  @action
  toggleShareDialog(): void {
    this.showShareDialog = !this.showShareDialog;
  }

  @action
  closeShareDialog(): void {
    this.showShareDialog = false;
  }
}
